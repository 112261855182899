import {Reducer} from 'redux';
import {forgotPasswordActions, ForgotPasswordActionTypes, IForgotPasswordState} from 'ForgotPassword/store/types';

const initialState: IForgotPasswordState['data'] = {
  message: null
};

export const dataForgotPasswordReducer: Reducer<IForgotPasswordState['data'], forgotPasswordActions> = (
  state = initialState,
  action: forgotPasswordActions
) => {
  switch (action.type) {
    case ForgotPasswordActionTypes.SUCCESS: {
      return {...state, message: action.payload};
    }
    default: {
      return state;
    }
  }
};
