import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';

import {InputField, SelectField, TextAreaField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {castToOption, getStringKeysOption} from 'Common/helpers/OptionHelper';
import {RequiredBy} from 'Common/types';
import {Combination} from 'Common/constants/Combination';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {IAdminAbilityRule} from 'Admin/AdminPhenotypes/models/IAdminAbilityRule';
import {IUpdateAbilityRuleRequest} from 'Admin/AdminPhenotypes/service/models/IServerAbilityRule';
import {IAbilityGroupAdvanced} from 'Dictionaries/models/IAbilityGroupAdvanced';

import {
  convertToServer,
  convertToServerUpdating,
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/AbilityRules/AbilityRuleForm/validation';
import {ITraitSequence} from 'Admin/AdminPhenotypes/models/ITraitSequence';
import {useTraitSequenceEditor} from 'SequenceEditor/hooks/useTraitSequenceEditor';
import {AbilityType} from 'Common/constants/AbilityType';
import {AbilityState} from 'Common/constants/AbilityState';
import {IAggregatedAbility} from 'Dictionaries/models/IAggregatedAbility';
import {IAffectionType} from 'Dictionaries/models/IAffectionType';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

interface IOwnProps extends IPhenotypeFormExternalProps<IAdminAbilityRule, IUpdateAbilityRuleRequest> {
  abilityGroupDictionary: ISimpleDictionary<IAbilityGroupAdvanced>;
  aggregatedAbilityDictionary: ISimpleDictionary<IAggregatedAbility>;
  affectionTypeDictionary: ISimpleDictionary<IAffectionType>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function AbilityRuleForm(props: Props) {
  const {
    itemId,
    item,
    dictionary,
    abilityGroupDictionary,
    aggregatedAbilities,
    aggregatedAbilitiesLoading,
    aggregatedAbilityDictionary,
    abilityGroup,
    abilityGroupLoading,
    affectionTypeDictionary,
    affectionTypes,
    affectionTypesLoading,
    ...rest
  } = props;
  const {setFieldValue, values} = props;

  const isLoading = abilityGroupLoading.isRequesting || aggregatedAbilitiesLoading.isRequesting;

  const {getItem} = dictionary.actions;
  const {
    actions: {getItems: getAbilityGroups},
  } = abilityGroupDictionary;
  const {
    actions: {getItems: getAggregatedAbilities},
  } = aggregatedAbilityDictionary;
  const {
    actions: {getItems: getAffectionTypes},
  } = affectionTypeDictionary;

  useEffect(() => {
    getAbilityGroups();
    getAggregatedAbilities();
    getAffectionTypes();
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem, getAbilityGroups, getAggregatedAbilities, getAffectionTypes]);

  const onSuccessSequenceEditor = useCallback(
    (type: Combination, sequences: ITraitSequence[]) => {
      setFieldValue('type', type);
      setFieldValue('sequences', sequences);
    },
    [setFieldValue]
  );

  const {renderRuleField, renderSequenceEditorModal} = useTraitSequenceEditor(
    values.type,
    values?.sequences ?? [],
    onSuccessSequenceEditor
  );

  return (
    <DictionaryForm {...rest} itemPreloading={isLoading}>
      {renderSequenceEditorModal()}

      <InputField name="name" label="Name" placeholder="Name" />
      <InputField
        name="positionIndex"
        label="Order in Report"
        placeholder="Order in Report"
        className="w-50"
        isRequired
      />
      <SelectField name="groupId" label="Group" options={castToOption(abilityGroup)} />
      <SelectField
        name="affectionTypeId"
        label="Affection type"
        placeholder="Affection type"
        options={castToOption(affectionTypes)}
      />
      <SelectField
        name="aggregatorId"
        label="Ability"
        placeholder="Ability"
        options={castToOption(aggregatedAbilities)}
      />
      <SelectField name="abilityType" label="Type" options={getStringKeysOption(AbilityType)} />
      <SelectField name="abilityState" label="State" options={getStringKeysOption(AbilityState)} />
      <TextAreaField name="description.value" label="Description" placeholder="Description" />
      <InputField name="externalDescriptionUrl" label="URL" placeholder="External URL" />

      {renderRuleField()}
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  const {abilityGroupDictionary, aggregatedAbilityDictionary, affectionTypeDictionary} = ownProps;

  return {
    abilityGroup: abilityGroupDictionary.selectors.selectItems(state),
    abilityGroupLoading: abilityGroupDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    aggregatedAbilities: aggregatedAbilityDictionary.selectors.selectItems(state),
    aggregatedAbilitiesLoading: aggregatedAbilityDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    affectionTypes: affectionTypeDictionary.selectors.selectItems(state),
    affectionTypesLoading: affectionTypeDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    ...makePhenotypeFormSelectors(state, ownProps.dictionary),
  };
};

const AbilityAdvancedFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting
      ? {
          ...item,
          affectionTypeId: item.affectionType?.id || null,
          aggregatorId: item.aggregator?.id || null,
          groupId: item.group?.id || null,
        }
      : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(AbilityRuleForm);

const connector = connect(mapStateToProps);
export default connect(mapStateToProps)(memo(AbilityAdvancedFormFormik));
