import * as React from 'react';
import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import {withCurrency} from 'Common/helpers/withCurrency';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon from 'Icon/components/ColoredIcon';

const PriceRoot = styled.div`
  padding: 20px 8px 0 24px;
  font-family: ${Typography.family.openSans};
  font-size: ${Typography.size.size18};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const Label = styled.div<{bold?: boolean}>`
  font-weight: ${({bold}) => (bold ? 600 : 'normal')};
  text-transform: capitalize;
`;

const Value = styled.div<{large?: boolean}>`
  font-weight: 600;
  font-size: ${({large}) => (large ? Typography.size.size24 : Typography.size.size18)};
`;

const ResultValues = styled.div`
  margin-top: 40px;
`;

const Logo = styled(ColoredIcon)`
  margin-top: 2px;
  margin-right: 8px;
`;

export enum PackageType {
  Package,
  Test,
  Coupon,
}

interface IProps {
  packagesPrices: IPackage[];
  total: number;
  isAssociation: boolean;
}

interface IPackage {
  name: string;
  price: number;
  type: PackageType;
}

function PackagesPrice(props: IProps) {
  const {packagesPrices, total, isAssociation} = props;

  const logo: Record<PackageType, {name: IconName; color?: string}> = {
    [PackageType.Coupon]: {name: IconName.Coupon},
    [PackageType.Package]: {name: isAssociation ? IconName.Business : IconName.Person, color: ColorPalette.gray56},
    [PackageType.Test]: {name: IconName.Notebook3, color: ColorPalette.gray56},
  };

  return (
    <PriceRoot>
      {packagesPrices.map(({name, price, type}, i) => {
        return (
          <Row key={i}>
            <Label className="d-flex align-items-start">
              <Logo {...logo[type]} stroke={false} fill={false} />
              {name}
            </Label>
            <Value>{withCurrency(price)}</Value>
          </Row>
        );
      })}
      <ResultValues>
        <Row>
          <Label bold={true}>Packages total</Label>
          <Value large={true}>{withCurrency(total)}</Value>
        </Row>
      </ResultValues>
    </PriceRoot>
  );
}

export default React.memo(PackagesPrice);
