import {IAncestryRelatedHorse} from './IAncestryRelatedHorse';

export enum KinshipDegreestring {
  Clone = 'Clone',
  First = 'First',
  Second = 'Second',
  Third = 'Third',
}

export interface IAncestryKinshipHorse extends IAncestryRelatedHorse {
  degree: KinshipDegreestring;
}
