import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {
  createUserOrderActions,
  createUserOrderActionTypes,
  getAssociationEmployeesActions,
  getAssociationEmployeesActionTypes,
  getUserCouponActions,
  getUserCouponActionTypes,
  getUserHorsesActions,
  getUserHorsesActionTypes,
  getUserSummaryActions,
  getUserSummaryActionTypes,
  IAdminAssociationCreateUserOrderState,
  resetAdminUserOrderActions,
  resetAdminUserOrderActionTypes,
  resetUserCouponActionTypes,
  resetUserSummaryActionTypes,
} from './types';
import AdminCreateUserOrderService from 'Admin/AdminAssociations/services/AdminCreateUserOrderService';

import {ICreateOrderRequest} from 'Admin/AdminAssociations/models/Order/createOrder/ICreateOrderRequest';
import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import {ITestSummaryRequest} from 'Admin/AdminAssociations/services/types/summary';
import {IRequestParams} from 'Common/models/IRequestParams';
import {ICheckCouponRequest} from 'BusinessPortal/services/types/coupon';

export const getUserHorses =
  (organizationId: number, params?: IRequestParams): ActionResult<IAppState, Promise<void>, getUserHorsesActions> =>
  async (dispatch) => {
    dispatch(action(getUserHorsesActionTypes.REQUEST));
    try {
      const res = await AdminCreateUserOrderService.getHorses(organizationId, params);
      dispatch(action(getUserHorsesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getUserHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserSummary =
  (request: ITestSummaryRequest): ActionResult<IAppState, Promise<void>, getUserSummaryActions> =>
  async (dispatch) => {
    dispatch(action(getUserSummaryActionTypes.REQUEST));
    try {
      const res = await AdminCreateUserOrderService.getSummary(request);
      dispatch(action(getUserSummaryActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getUserSummaryActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserCoupon =
  (
    request: ICheckCouponRequest,
    organizationId: number
  ): ActionResult<IAppState, Promise<IOrderCoupon>, getUserCouponActions> =>
  async (dispatch) => {
    dispatch(action(getUserCouponActionTypes.REQUEST));
    try {
      const res = await AdminCreateUserOrderService.getCoupon(organizationId, request);
      dispatch(action(getUserCouponActionTypes.SUCCESS, res));
      return res;
    } catch (error) {
      const err = getErrorMessage(error);
      dispatch(action(getUserCouponActionTypes.FAILURE, err));
      throw err;
    }
  };

export const getAssociationEmployees =
  (organizationId: number): ActionResult<IAppState, Promise<void>, getAssociationEmployeesActions> =>
  async (dispatch) => {
    dispatch(action(getAssociationEmployeesActionTypes.REQUEST));
    try {
      const res = await AdminCreateUserOrderService.getAssociationEmployees(organizationId);
      dispatch(action(getAssociationEmployeesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAssociationEmployeesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createUserOrder =
  (request: ICreateOrderRequest): ActionResult<IAppState, Promise<void>, createUserOrderActions> =>
  async (dispatch) => {
    dispatch(action(createUserOrderActionTypes.REQUEST));
    try {
      await AdminCreateUserOrderService.createOrder(request);
      dispatch(action(createUserOrderActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createUserOrderActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetUserOrder =
  (): ActionResult<IAdminAssociationCreateUserOrderState, void, resetAdminUserOrderActions> => async (dispatch) => {
    dispatch(action(resetAdminUserOrderActionTypes.RESET));
  };

export const resetUserCoupon = () => action(resetUserCouponActionTypes.RESET);
export const resetUserSummary = () => action(resetUserSummaryActionTypes.RESET);
