import React from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import {InputField} from 'Common/components/FormFields/index';
import {MutableField, MutableFieldsGroup} from 'Common/components/StyledComponents/StyledComponents';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {BaseStepLayoutChildrenProps} from 'Order/components/CreateOrder/parts/BaseStepLayout';
import {IContactInformation} from 'Order/models/IContactInformation';
import {StepLabel, StepValue, StepInfoWrapper} from 'Order/components/CreateOrder/common/styled';
import {getStringifyAddress} from 'Common/helpers/getStringifyAddress';
import {breakpoints} from 'Common/constants/Breakpoints';

const Hint = styled.div`
  width: 100%;
  margin-bottom: 16px;
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.gray44};

  @media ${breakpoints.sm} {
    width: 350px;
  }
`;

interface IProps {
  userInfo: IContactInformation;
}

type AllProps = IProps & BaseStepLayoutChildrenProps;

const ContactInformation = (props: AllProps) => {
  const {isEdit, userInfo} = props;

  if (!isEdit) {
    return (
      <StepInfoWrapper>
        <div className="d-flex flex-column" style={{marginRight: 100}}>
          <StepLabel>Address</StepLabel>
          <StepValue>{userInfo.address && getStringifyAddress(userInfo.address)}</StepValue>
        </div>
        <div className="d-flex flex-column">
          <StepLabel>Phone number</StepLabel>
          <StepValue>{userInfo.phoneNumber}</StepValue>
        </div>
      </StepInfoWrapper>
    );
  }

  return (
    <>
      <Hint>Please let us know, where we can reach you, in case our lab needs to clarify some information.</Hint>
      <InputField name="phoneNumber" label="Phone number" placeholder="Phone number" isRequired />
      <InputField name="address.street" label="Street address" placeholder="Enter your address" isRequired />
      <MutableFieldsGroup indent="24px" className="d-flex justify-content-between">
        <MutableField width={50} style={{minWidth: 100}}>
          <InputField name="address.city" label="City" placeholder="City" isRequired />
        </MutableField>
        <MutableField width={50}>
          <InputField name="address.state" label="State" placeholder="State" />
        </MutableField>
      </MutableFieldsGroup>
      <MutableFieldsGroup indent="24px" className="d-flex justify-content-between">
        <MutableField width={50}>
          <InputField name="address.country" label="Country" placeholder="Country" isRequired />
        </MutableField>
        <MutableField width={50} style={{minWidth: 100}}>
          <InputField name="address.zip" label="ZIP / POSTAL" placeholder="ZIP / POSTAL" isRequired />
        </MutableField>
      </MutableFieldsGroup>
    </>
  );
};

export default ContactInformation;
export type ContactInformationProps = AllProps;
