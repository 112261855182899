import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import {breakpoints} from 'Common/constants/Breakpoints';
import ColorPalette from 'Common/constants/ColorPalette';

export const SummaryContainer = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 5;

  background: ${Theme.color.white};
  box-shadow: 0px 0.498106px 2.21381px rgba(0, 0, 0, 0.0140573), 0px 1.19702px 5.32008px rgba(0, 0, 0, 0.0201946),
    0px 2.25388px 10.0172px rgba(0, 0, 0, 0.025), 0px 4.02054px 17.869px rgba(0, 0, 0, 0.0298054),
    0px 7.51997px 33.4221px rgba(0, 0, 0, 0.0359427), 0px 18px 80px rgba(0, 0, 0, 0.05);

  @media ${breakpoints.md} {
    width: 35%;
    top: 0;
    align-self: flex-start;
    box-shadow: none;
  }
`;

export const SummaryBackground = styled.div`
  @media ${breakpoints.md} {
    width: 100%;
    height: 100vh;
    background: ${ColorPalette.gray49};
    position: absolute;
    z-index: -1;
  }
`;
