import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';
import {
  BusinessPortalOwnerActions,
  createOwnerActionTypes,
  deleteOwnerActionTypes,
  deleteOwnerAvatarActionTypes,
  getOwnerDetailsActionTypes,
  getOwnersActionTypes,
  IBusinessPortalOwnerState,
  resetOwnerDetailsActionTypes,
  updateOwnerActionTypes,
  updateOwnerAvatarActionTypes,
} from '../types';

export const communicationBusinessPortalOwnerReducer = combineReducers<
  IBusinessPortalOwnerState['communications'],
  BusinessPortalOwnerActions
>({
  ownersLoading: makeCommunicationReducerFromEnum(getOwnersActionTypes),
  ownerCreating: makeCommunicationReducerFromEnum(createOwnerActionTypes),
  ownerUpdating: makeCommunicationReducerFromEnum(updateOwnerActionTypes),
  ownerDetailsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getOwnerDetailsActionTypes),
    makeResetCommunicationReducer(resetOwnerDetailsActionTypes.RESET),
  ]),
  ownerAvatarUpdating: makeCommunicationReducerFromEnum(updateOwnerAvatarActionTypes),
  ownerAvatarDeleting: makeCommunicationReducerFromEnum(deleteOwnerAvatarActionTypes),
  ownerDeleting: makeCommunicationReducerFromEnum(deleteOwnerActionTypes),
});
