import {ICreatedOrder} from 'BusinessPortal/models/order/createOrder/ICreatedOrder';
import {ICreateOrderRequest} from 'BusinessPortal/models/order/createOrder/ICreateOrderRequest';
import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';
import {IOrderSummary} from 'BusinessPortal/models/order/createOrder/IOrderSummary';
import {
  convertCreatedOrderToClient,
  convertCreateOrderRequestToServer,
  convertOrderHorseToClient,
} from 'BusinessPortal/services/converters/createOrder/order';
import {ITestSummaryRequest} from 'BusinessPortal/services/types/summary';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IRequestParams} from 'Common/models/IRequestParams';
import {ICheckCouponRequest} from 'BusinessPortal/services/types/coupon';
import {convertOrderSummaryToClient} from 'BusinessPortal/services/converters/createOrder/summary';
import {convertCouponToClient} from 'BusinessPortal/services/converters/createOrder/coupon';
import {IServerOrderHorse} from './types/order';

const getHorses = async (params?: IRequestParams): Promise<IOrderHorse[]> => {
  const tempParams: IRequestParams = {
    ...params,
    currentPage: 1,
    countPerPage: 2147483647,
  };

  const response = await axiosWrapper.get<IListResponse<IServerOrderHorse>>(`/AssociationOrderHorses`, {
    params: tempParams,
  });
  return response.data.data.map(convertOrderHorseToClient);
};

const getSummary = async (data: ITestSummaryRequest): Promise<IOrderSummary> => {
  const response = await axiosWrapper.post<IObjectResponse<IOrderSummary>>(`/AssociationOrdersSummary`, data);
  return convertOrderSummaryToClient(response.data.data);
};

const getCoupon = async (data: ICheckCouponRequest): Promise<IOrderCoupon> => {
  const response = await axiosWrapper.post<IObjectResponse<IOrderCoupon>>(`/AssociationCouponCheck`, data);
  return convertCouponToClient(response.data.data);
};

const createOrder = async (request: ICreateOrderRequest): Promise<ICreatedOrder> => {
  const response = await axiosWrapper.post<IObjectResponse<ICreatedOrder>>(
    `/AssociationOrderCreate`,
    convertCreateOrderRequestToServer(request)
  );
  return convertCreatedOrderToClient(response.data.data);
};

export default {
  getHorses,
  getSummary,
  getCoupon,
  createOrder,
};
