import React from 'react';
import styled, {keyframes} from 'styled-components';

import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import staticSvg from './images/static.svg';
import movableSvg from './images/movable.svg';

const loading = keyframes`
  0% {
    background-position: -225px, center;
  }  
  100% {
    background-position: 0px, center;
    }
`;

const LoadingStaticIcon = styled.div`
  width: 250px;
  height: 100px;
  background-image: url(${staticSvg});
  z-index: 10;
`;

const LoadingMovableIcon = styled.div`
  &::before {
    content: '';
    display: block;
    width: 250px;
    height: 100px;
    background: linear-gradient(
      to right,
      ${ColorPalette.white0} 0%,
      ${ColorPalette.white1} 20%,
      ${ColorPalette.white1} 80%,
      ${ColorPalette.white0} 100%
    );
  }
  width: 250px;
  height: 100px;

  margin-bottom: 50px;
  background-image: url(${movableSvg});
  z-index: 11;

  animation: ${loading} 4s linear infinite;
`;

const LoadingHeader = styled.div`
  font-family: ${Typography.family.openSans};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size23};
  line-height: 32px;
  text-align: center;
`;

const LoadingHeaderText = styled.div`
  margin-bottom: 10px;
`;

const LoadingSubHeaderText = styled.div`
  color: ${ColorPalette.gray2};
`;

const LongTimeLoading: React.FC = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <LoadingStaticIcon className="position-absolute" />
      <LoadingMovableIcon className="d-flex align-items-center justify-content-center overflow-hidden" />
      <LoadingHeader>
        <LoadingHeaderText>Searching for probable variants</LoadingHeaderText>
        <LoadingSubHeaderText>It might take a few minutes</LoadingSubHeaderText>
      </LoadingHeader>
    </div>
  );
};

export default LongTimeLoading;
