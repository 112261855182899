import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';

export const StepInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > :not(:first-child) {
    margin-top: 24px;
  }

  @media ${breakpoints.sm} {
    flex-direction: row;
    > :not(:first-child) {
      margin-top: 0;
    }
  }
`;

export const StepLabel = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 12px;
  letter-spacing: 0.5px;
  color: ${ColorPalette.gray44};
  margin-bottom: 8px;

  @media ${breakpoints.sm} {
    margin-bottom: 12px;
  }
`;

export const StepValue = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size14};
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${Theme.color.black};
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${ColorPalette.gray2};
`;
