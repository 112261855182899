import Typography from 'Common/constants/Typography';

export const FORM_CONTROL_CONTAINER_STYLE = {fontSize: Typography.size.size18, fontWeight: 500, marginBottom: 16};

export const FIELD_CONTROL_CONTAINER_STYLE = {
  minWidth: 180,
  margin: '0 0 0 16px'
};

export const COMMON_FIELD_STYLE = {marginBottom: 16};
