import React from 'react';
import styled from 'styled-components';

import {ICommercialType} from 'Dictionaries/models/ICommercialType';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {ItemContainer, ItemWrapper, LabelBase, ValueWrapper} from 'UserProfile/components/TabsData/common/styled';

const StyledCommercials = styled.div`
  padding-left: 90px;
`;

const SimpleCommercials = styled.div`
  margin-bottom: 5px;
`;

interface IProps {
  horseCommercials: ICommercialType[];
}

const CommercialityContent = (props: IProps) => {
  const {horseCommercials} = props;
  const {isDesktop} = useMediaQuery();

  if (isDesktop) {
    return (
      <StyledCommercials className="d-flex flex-wrap">
        {horseCommercials?.map((item, i) => (
          <ItemWrapper className="d-flex align-self-center" key={i}>
            <ItemContainer>{item.name}</ItemContainer>
          </ItemWrapper>
        ))}
      </StyledCommercials>
    );
  }

  if (horseCommercials.length) {
    return (
      <SimpleCommercials className="d-flex flex-row w-100">
        <LabelBase>Available for</LabelBase>
        <ValueWrapper className="d-flex w-100">{horseCommercials.map((item) => item.name).join(', ')}</ValueWrapper>
      </SimpleCommercials>
    );
  }

  return null;
};

export default CommercialityContent;
