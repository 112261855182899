import {combineReducers, Reducer} from 'redux';

import {IUserState} from '../types';
import {communicationUserReducer} from './communications';
import {dataUserReducer} from './data';

export const userReducer: Reducer<IUserState> = combineReducers<IUserState>({
  communications: communicationUserReducer,
  data: dataUserReducer
});
