import {IUser} from 'UserProfile/models/IUser';
import {IUserRequest} from '../serverModels/IUserRequest';

export function convertClientUserRequestToServer(clientRequest: IUser): string {
  const user: IUserRequest = {
    ...clientRequest,
    avatar: clientRequest.avatar && clientRequest.avatar.id !== 0 ? {id: clientRequest.avatar.id} : null
  };

  return JSON.stringify(user);
}
