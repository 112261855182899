import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {IDescription, IShortDescriptionEntity} from 'Common/models/IDescriptionEntity';
import {IUpdateGeneRequest} from 'Admin/AdminPhenotypes/service/models/IGene';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues extends IShortDescriptionEntity {
  id?: IEntity['id'];
  name: string;
  groups: number[];
  title: string;
}

export const initialValue: IFormValues = {
  name: '',
  groups: [],
  description: {value: ''} as IDescription,
  title: ''
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  groups: Yup.array().of(Yup.number()),
  title: Yup.string()
});

export const convertToServer = (values: IFormValues): Omit<IUpdateGeneRequest, 'id'> => {
  return {
    ...values,
    description: {value: values.description?.value || ''},
    groups: values.groups.map(x => ({id: x}))
  };
};

export const convertToServerUpdating = (values: IFormValues): IUpdateGeneRequest => {
  return {
    ...convertToServer(values),
    id: values.id!
  };
};
