import {combineReducers, Reducer} from 'redux';

import {IAdminAssociationOrderState} from '../types';
import {communicationOrdersReducer} from '../reducer/communications';
import {dataOrdersReducer} from '../reducer/data';
import {uiOrdersReducer} from '../reducer/ui';

export const ordersReducer: Reducer<IAdminAssociationOrderState> = combineReducers<IAdminAssociationOrderState>({
  communications: communicationOrdersReducer,
  data: dataOrdersReducer,
  ui: uiOrdersReducer,
});
