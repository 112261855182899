import React, {useCallback, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared/index';
import {InjectedStripeProps, withStripe} from 'Common/helpers/withStripe';
import {IAppState} from 'Common/store/IAppState';
import {Nullable} from 'Common/types';
import {actions, selectors} from 'StripeSubscription/store/index';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {StripeSubscriptionModule} from 'StripeSubscription/store/stripeSubscriptionModule';
import StripeFields from 'Payment/components/Stripe/PaymentFields/StripeFields';
import Loading from 'Loading/components/Loading';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {getCommonErrors, getStringErrorDetails} from 'Common/helpers/ErrorHelper';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {breakpoints} from 'Common/constants/Breakpoints';

const STRIPE_LOADING_SCRIPT_ERROR = 'Error on loading Stripe components. Please refresh the page.';

const SaveCardButton = styled(ModalWindowButton)`
  width: 100%;

  @media ${breakpoints.sm} {
    width: 168px;
  }
`;

interface IExternalProps {
  subscriptionId: Nullable<number>;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = InjectedStripeProps & IExternalProps & IConnected;

const ChangeDefaultPaymentMethodForm = (props: AllProps) => {
  const {elements, stripe} = props;
  const {
    onSuccess,
    subscriptionId,
    retryInvoiceRequesting,
    retryInvoice,
    setupIntentClientSecretLoading,
    changeDefaultPaymentMethodRequesting,
    changeDefaultPaymentMethod,
    getSetupIntentClientSecret,
    refreshSubscriptionFromStripe,
    refreshSubscriptionFromStripeRequesting,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState<string>();

  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
  const [isCardExpiryComplete, setIsCardExpiryComplete] = useState(false);
  const [isCardCVCComplete, setIsCardCVCComplete] = useState(false);

  const isCardComplete = isCardNumberComplete && isCardExpiryComplete && isCardCVCComplete;

  const [cardNumberError, setCardNumberError] = useState('');
  const [cardExpiryError, setCardExpiryError] = useState('');
  const [cardCvcError, setCardCvcError] = useState('');

  const cardError = `${cardNumberError} ${cardExpiryError} ${cardCvcError}`;

  const handleOnCardNumberChange = useCallback((event: stripe.elements.ElementChangeResponse) => {
    setCardNumberError(event.error?.message || '');
    setIsCardNumberComplete(event.complete);
  }, []);

  const handleOnCardExpiryChange = useCallback((event: stripe.elements.ElementChangeResponse) => {
    setCardExpiryError(event.error?.message || '');
    setIsCardExpiryComplete(event.complete);
  }, []);

  const handleOnCardCVCChange = useCallback((event: stripe.elements.ElementChangeResponse) => {
    setCardCvcError(event.error?.message || '');
    setIsCardCVCComplete(event.complete);
  }, []);

  const stripeLoadingScriptError = (!stripe || !elements) && STRIPE_LOADING_SCRIPT_ERROR;

  const isDisableSubmit = !isCardComplete;

  const isLoading = !stripe || !elements;
  const isButtonLoading =
    isSubmitting ||
    retryInvoiceRequesting.isRequesting ||
    changeDefaultPaymentMethodRequesting.isRequesting ||
    setupIntentClientSecretLoading.isRequesting ||
    refreshSubscriptionFromStripeRequesting.isRequesting;

  const errorInfo =
    setupIntentClientSecretLoading.error ||
    changeDefaultPaymentMethodRequesting.error ||
    changeDefaultPaymentMethodRequesting.error;
  const onError = useCallback(() => {
    const error = getStringErrorDetails(errorInfo);
    if (!error) {
      const commonErrors = getCommonErrors(errorInfo);
      commonErrors && setStatus(commonErrors);
      return;
    }

    setStatus(error);
  }, [setStatus, errorInfo]);

  useErrorCommunicationToToast(setupIntentClientSecretLoading);
  useErrorCommunicationToToast(retryInvoiceRequesting);
  useErrorCommunicationToToast(refreshSubscriptionFromStripeRequesting);
  useOnSuccessCommunication(changeDefaultPaymentMethodRequesting, onSuccess);
  useOnErrorCommunication(changeDefaultPaymentMethodRequesting, onError);

  const onSuccessAfterPaymentProblem = useCallback(async () => {
    subscriptionId && (await refreshSubscriptionFromStripe(subscriptionId));
    onSuccess();
  }, [subscriptionId, refreshSubscriptionFromStripe, onSuccess]);

  const error = stripeLoadingScriptError || status;

  const onClickSaveCard = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsSubmitting(true);
    try {
      const cardNumber = elements.getElement('cardNumber');

      if (subscriptionId) {
        if (cardNumber) {
          const {error, paymentMethod} = await stripe.createPaymentMethod('card', cardNumber);
          if (error) {
            throw Error(error.message);
          }

          const paymentMethodId = paymentMethod?.id;
          if (paymentMethodId) {
            const result = await retryInvoice(paymentMethodId, subscriptionId);
            if (paymentMethodId) {
              const {error} = await stripe.confirmCardPayment(result.clientSecret, {payment_method: paymentMethodId});

              if (error) {
                throw Error(error.message);
              }

              await onSuccessAfterPaymentProblem();
            }
          }
        }
      } else {
        const setupIntentClientSecret = await getSetupIntentClientSecret();
        if (cardNumber) {
          const {error, paymentMethod} = await stripe.createPaymentMethod('card', cardNumber);
          if (error) {
            throw Error(error.message);
          }

          if (paymentMethod?.id) {
            const {error} = await stripe.confirmCardSetup(setupIntentClientSecret, {payment_method: paymentMethod.id});
            if (error) {
              throw Error(error.message);
            }

            await changeDefaultPaymentMethod(paymentMethod.id);
            onSuccess();
          }
        }
      }
    } catch (e) {
      setStatus(e.message);
    }

    setIsSubmitting(false);
  }, [
    changeDefaultPaymentMethod,
    elements,
    getSetupIntentClientSecret,
    onSuccess,
    onSuccessAfterPaymentProblem,
    retryInvoice,
    stripe,
    subscriptionId,
  ]);

  return (
    <>
      <ModalWindowHeader>Change payment method</ModalWindowHeader>
      {isLoading && <Loading />}
      <div className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent style={{marginBottom: 40}}>
          <StripeFields
            onCardNumberChange={handleOnCardNumberChange}
            onCardExpiryChange={handleOnCardExpiryChange}
            onCardCVCChange={handleOnCardCVCChange}
          />
          {cardError && <ErrorMessage>{cardError}</ErrorMessage>}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </ModalWindowFormContent>
        <ModalWindowFooter className="d-flex justify-content-center">
          <SaveCardButton isLoading={isButtonLoading} disabled={isDisableSubmit} onClick={onClickSaveCard}>
            Save card
          </SaveCardButton>
        </ModalWindowFooter>
      </div>
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  retryInvoiceRequesting: selectors.selectCommunication(state, 'retryInvoiceRequesting'),
  changeDefaultPaymentMethodRequesting: selectors.selectCommunication(state, 'changeDefaultPaymentMethodRequesting'),
  setupIntentClientSecretLoading: selectors.selectCommunication(state, 'setupIntentClientSecretLoading'),
  refreshSubscriptionFromStripeRequesting: selectors.selectCommunication(
    state,
    'refreshSubscriptionFromStripeRequesting'
  ),
});

const mapDispatchToProps = {
  retryInvoice: actions.retryInvoice,
  changeDefaultPaymentMethod: actions.changeDefaultPaymentMethod,
  getSetupIntentClientSecret: actions.getSetupIntentClientSecret,
  refreshSubscriptionFromStripe: actions.refreshSubscriptionFromStripe,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(withStripe(ChangeDefaultPaymentMethodForm));

export default withDynamicModules(Connected, StripeSubscriptionModule);
