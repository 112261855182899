import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import ColorPalette from 'Common/constants/ColorPalette';
import {EventName} from 'Common/constants/EventName';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import LocalStorageService from 'Common/services/LocalStorageService';
import {IPackageGroup} from 'Dictionaries/models/IPackageGroup';
import {IconName} from 'Icon/components/Icon';
import {IHorseTest} from 'Order/models/IHorseTest';
import {IOrderHorse} from 'Order/models/IOrderHorse';
import {IOrderPackage} from 'Order/models/IOrderPackage';
import {StepInfoWrapper, StepLabel, StepValue} from '../common/styled';
import {BaseStepLayoutChildrenProps} from './BaseStepLayout';
import CategoryView from './TestSelection/CategoryView';
import CompareForm, {COMPARE_PACKAGES_KEY} from './TestSelection/CompareForm/CompareForm';
import OrderPackageCard from './TestSelection/OrderPackageCard/OrderPackageCard';
import OrderPackageContainer from './TestSelection/OrderPackageContainer';
import OrderPackageGroupCard from './TestSelection/OrderPackageGroup/OrderPackageGroupCard';
import SearchBarTestSelection from './TestSelection/SearchBarTestSelection';
import {sortBySorter} from 'Common/helpers/Sorter/sortBySorter';
import {packageSorter} from 'Order/constants/PackageSorter';

const Root = styled.div`
  margin-bottom: 24px;
`;

const Actions = styled.div`
  top: -40px;
  right: 0;
`;

const TrashIcon = styled(IconButton)`
  margin-left: 16px;
`;

const CountComparasing = styled.span`
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size12};
  font-weight: ${Typography.weight.medium500};
  top: -8px;
  right: -12px;
`;

interface IProps {
  horses: IOrderHorse[];
  orderPackages: IOrderPackage[];
  packageGroups: IPackageGroup[];
  tests: IHorseTest[];
  onClearShoppingCart(): void;
}

interface ITestsProps {
  onSuccess(packageId: number): void;
  onOpenCard(packageId: number): void;
}

type AllProps = IProps & ITestsProps & BaseStepLayoutChildrenProps;

function TestSelection(props: AllProps) {
  const {orderPackages, packageGroups, horses, isEdit, tests, onClearShoppingCart} = props;
  const {onSuccess, onOpenCard} = props;

  const [orderGroupId, setOrderGroupId] = useState<number>();
  const [sortedOrderPackages, setSortedOrderPackages] = useState<IOrderPackage[]>([]);
  const [filteredOrderPackages, setFilteredOrderPackages] = useState<IOrderPackage[]>([]);
  const [sortedPackageGroups, setSortedPackageGroups] = useState<IPackageGroup[]>([]);
  const [filteredPackageGroups, setFilteredPackageGroups] = useState<IPackageGroup[]>([]);
  const [isOpenCompareForm, setIsOpenCompareForm] = useState(false);
  const [packagesForCompare, setPackagesForCompare] = useState<IOrderPackage[]>([]);

  const {listen} = useEventBus();

  const onChangeComparePackages = useCallback(() => {
    const packagesForCompare: number[] = LocalStorageService.getItem(COMPARE_PACKAGES_KEY) || [];
    const dataToCompare = orderPackages.filter((x) => packagesForCompare.includes(x.id));
    setPackagesForCompare(dataToCompare);
  }, [orderPackages]);

  useEffect(() => {
    onChangeComparePackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderPackages]);

  useEffect(() => {
    const eventComparePackagesChanged = listen(EventName.AddPackageToCompare, onChangeComparePackages);

    return () => {
      eventComparePackagesChanged.stopListening();
    };
  }, [listen, onChangeComparePackages]);

  useEffect(() => {
    const sorted = sortBySorter<IPackageGroup>(packageSorter, packageGroups);
    setFilteredPackageGroups(sorted);
    setSortedPackageGroups(sorted);
  }, [packageGroups]);

  useEffect(() => {
    const sorted = sortBySorter<IOrderPackage>(packageSorter, orderPackages);
    setFilteredOrderPackages(sorted);
    setSortedOrderPackages(sorted);
  }, [orderPackages]);

  const bundles = useMemo(() => {
    return filteredOrderPackages.filter((x) => x.isBundle).filter((value, idx, self) => self.indexOf(value) === idx);
  }, [filteredOrderPackages]);

  const viewCategory = useCallback((id: number) => {
    setOrderGroupId(id);
  }, []);

  const onBackButtonClick = useCallback(() => {
    setOrderGroupId(0);
  }, []);

  const onSearchSuccess = useCallback((filterOrderPackages: IOrderPackage[], filterPackageGroups: IPackageGroup[]) => {
    const sortedGroups = sortBySorter<IPackageGroup>(packageSorter, filterPackageGroups);
    const sortedPackages = sortBySorter<IOrderPackage>(packageSorter, filterOrderPackages);

    setFilteredPackageGroups(sortedGroups);
    setFilteredOrderPackages(sortedPackages);
  }, []);

  const openComparetForm = useCallback(() => {
    setIsOpenCompareForm(true);
  }, []);

  const closeCompareForm = useCallback(() => {
    setIsOpenCompareForm(false);
  }, []);

  if (!isEdit) {
    const horsePackages = orderPackages
      .filter((pack) => tests.find((test) => test.packageId !== 0 && test.packageId === pack.id))
      .map((i) => i.name);
    const horsesNames = horses
      .filter((horse) => tests.find((test) => test.horses?.find((p) => p === horse.id)))
      .map((i) => i.name);
    const uniquePackages = horsePackages.filter((value, idx, self) => self.indexOf(value) === idx);

    return (
      <StepInfoWrapper>
        {horsesNames?.length !== 0 && (
          <div className="d-flex flex-column" style={{marginRight: 100}}>
            <StepLabel>{horsesNames?.length > 1 ? 'Horses' : 'Horse'}</StepLabel>
            <StepValue>{horsesNames.map((i) => i).join(', ')}</StepValue>
          </div>
        )}
        {uniquePackages?.length !== 0 && (
          <div className="d-flex flex-column">
            <StepLabel>{uniquePackages?.length > 1 ? 'Packages' : 'Package'}</StepLabel>
            <StepValue>{uniquePackages.map((i) => i).join(', ')}</StepValue>
          </div>
        )}
      </StepInfoWrapper>
    );
  }

  return (
    <Root className="position-relative">
      <ModalWindow isOpen={isOpenCompareForm} onClose={closeCompareForm} maxWidth="830px">
        <CompareForm orderPackages={packagesForCompare} />
      </ModalWindow>

      <Actions className="d-flex position-absolute">
        <Tooltip content="Compare packages">
          <div className="position-relative">
            <IconButton
              name={IconName.CompareBars}
              fill={true}
              stroke={false}
              size={24}
              color={packagesForCompare.length > 0 ? ColorPalette.green2 : ColorPalette.gray48}
              hoverColor={ColorPalette.green2}
              onClick={openComparetForm}
            />
            {packagesForCompare.length > 0 && (
              <CountComparasing className="position-absolute badge badge-danger">
                {packagesForCompare.length}
              </CountComparasing>
            )}
          </div>
        </Tooltip>

        <Tooltip content="Empty the shopping cart">
          <div>
            <TrashIcon
              name={IconName.Garbage}
              fill={true}
              stroke={false}
              size={24}
              color={ColorPalette.gray48}
              hoverColor={ColorPalette.red7}
              onClick={onClearShoppingCart}
            />
          </div>
        </Tooltip>
      </Actions>

      {(!orderGroupId || orderGroupId === 0) && (
        <>
          <SearchBarTestSelection
            orderPackagesForFilter={sortedOrderPackages}
            packageGroupsForFilter={sortedPackageGroups}
            onSuccess={onSearchSuccess}
          />

          <OrderPackageContainer title="Bundles">
            {bundles.map((bundle, i) => {
              return (
                <OrderPackageCard
                  key={i}
                  orderPackage={bundle}
                  isDetailShow={true}
                  horses={horses}
                  tests={tests}
                  onOpenCard={onOpenCard}
                  onSuccess={onSuccess}
                />
              );
            })}
          </OrderPackageContainer>

          <OrderPackageContainer title="Categories">
            {filteredPackageGroups.map((packageGroup, i) => {
              const testAmount = filteredOrderPackages
                .filter((value, idx, self) => self.indexOf(value) === idx)
                .filter((x) => x.groups.some((gr) => gr.id === packageGroup.id)).length;
              if (testAmount === 0) {
                return '';
              }

              return (
                <OrderPackageGroupCard
                  key={i}
                  orderPackageGroup={packageGroup}
                  testAmount={testAmount}
                  viewCategory={viewCategory}
                />
              );
            })}
          </OrderPackageContainer>

          {/* <OrderPackageContainer title="Other options">dummy options</OrderPackageContainer> */}
        </>
      )}

      {orderGroupId && orderGroupId !== 0 ? (
        <CategoryView
          orderPackages={orderPackages.filter((x) => x.groups.some((gr) => gr.id === orderGroupId))}
          categoryName={packageGroups.find((gr) => gr.id === orderGroupId)?.name || ''}
          onBackButtonClick={onBackButtonClick}
          horses={horses}
          tests={tests}
          onOpenCard={onOpenCard}
          onSuccess={onSuccess}
        />
      ) : (
        ''
      )}
    </Root>
  );
}

export default memo(TestSelection);
export type TestSelectionProps = AllProps;
export type TestSelectionActionProps = ITestsProps;
