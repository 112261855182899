import {Reducer} from 'redux';

import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';

import {IBuildHorseState, Actions, getFoalsActionTypes} from '../types';

const initialState: IBuildHorseState['ui'] = {
  pagination: {countPerPage: MIN_COUNT_PER_PAGE, currentPage: 1, pageCount: 1, totalItemsCount: 1}
};

export const uiBuildHorseReducer: Reducer<IBuildHorseState['ui'], Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case getFoalsActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
