import {IDistance} from './Distance';

export enum GenePoolsType {
  AllPublic = 'AllPublic',
  MyHorses = 'MyHorses',
  Farms = 'Farms',
  Registries = 'Registries',
  LocationRadius = 'LocationRadius',
  Favorite = 'Favorite',
  ExcludedHorses = 'ExcludedHorses'
}

export type IGenePools = Record<GenePoolsType, number>;

export interface IGenePoolsRequest {
  distance?: IDistance;
  associations?: number[];
}
