import React, {memo} from 'react';

import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {useDictionaries} from 'Common/store/useDictionaries';
import DictionaryLayout, {
  DictionaryFormProps,
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import TestForm from './TestForm/TestForm';
import {ITest} from 'Dictionaries/models/ITest';
import {IDictionary} from 'DictionaryFactory/types/dictionary';

function Tests() {
  const {adminTest: testDictionary, genes: geneDictionary} = useDictionaries();

  const wrappedTestForm = (props: DictionaryFormProps<IDictionary<ITest, ITest>>) => {
    return <TestForm {...props} geneDictionary={geneDictionary} />;
  };

  return (
    <div>
      <DictionaryLayout dictionary={testDictionary} DictionaryForm={wrappedTestForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<ITest> header="ID" dataKey="id" render={({id}) => `#${id}`} expandOnClick={true} width={100} />
            <Cell<ITest> header="Name" dataKey="name" render={({name}) => name} width="20%" />
            <Cell<ITest> header="Title" dataKey="title" sortable={false} render={({title}) => title} width="20%" />
            <Cell<ITest> header="Gene" dataKey="gene" render={({gene}) => gene?.name} width="10%" />
            <Cell<ITest>
              header="Mutation Variant"
              dataKey="mutationAllele"
              render={({mutationAllele}) => mutationAllele}
              width="10%"
            />
            <Cell<ITest>
              header="Reference Variant"
              dataKey="referenceAllele"
              render={({referenceAllele}) => referenceAllele}
              width="10%"
            />
            <Cell<ITest>
              header="Description"
              dataKey="description"
              render={({description}) => description?.value}
              width="20%"
            />
            <Cell<ITest>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(Tests);
