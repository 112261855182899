import {combineReducers} from 'redux';

import {
  downloadDiagnosticReportActionTypes,
  getAbilitiesActionTypes,
  getAbilityDetailedActionTypes,
  getCoatColorActionTypes,
  getCoatColorDetailedActionTypes,
  getGeneticVariantsActionTypes,
  getHealthVariantDetailedActionTypes,
  getHealthVariantsActionTypes,
  getHorseBreedPanelsActionTypes,
  getHorseDetailsActionTypes,
  getHorseInfoActionTypes,
  getHorseOwnerActionTypes,
  getSummaryAbilitiesActionTypes,
  getSummaryColorsActionTypes,
  getSummaryHealthIssuesActionTypes,
  IOnlineReportState,
  OnlineReportActions,
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationReducer = combineReducers<IOnlineReportState['communications'], OnlineReportActions>({
  horseInfoLoading: makeCommunicationReducerFromEnum(getHorseInfoActionTypes),
  horseOwnerLoading: makeCommunicationReducerFromEnum(getHorseOwnerActionTypes),
  horseDetailsLoading: makeCommunicationReducerFromEnum(getHorseDetailsActionTypes),
  summaryAbilitiesLoading: makeCommunicationReducerFromEnum(getSummaryAbilitiesActionTypes),
  summaryColorsLoading: makeCommunicationReducerFromEnum(getSummaryColorsActionTypes),
  summaryHealthIssuesLoading: makeCommunicationReducerFromEnum(getSummaryHealthIssuesActionTypes),
  coatColorLoading: makeCommunicationReducerFromEnum(getCoatColorActionTypes),
  coatColorsDetailedLoading: makeCommunicationReducerFromEnum(getCoatColorDetailedActionTypes),
  healthVariantsLoading: makeCommunicationReducerFromEnum(getHealthVariantsActionTypes),
  healthVariantsDetailedLoading: makeCommunicationReducerFromEnum(getHealthVariantDetailedActionTypes),
  abilitiesLoading: makeCommunicationReducerFromEnum(getAbilitiesActionTypes),
  abilityDetailedLoading: makeCommunicationReducerFromEnum(getAbilityDetailedActionTypes),
  downloadDiagnosticReportLoading: makeCommunicationReducerFromEnum(downloadDiagnosticReportActionTypes),
  geneticVariantsLoading: makeCommunicationReducerFromEnum(getGeneticVariantsActionTypes),
  horseBreedPanelsLoading: makeCommunicationReducerFromEnum(getHorseBreedPanelsActionTypes),
});
