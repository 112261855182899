import {IModule} from 'redux-dynamic-modules';

import {IOnlineReportState} from './types';
import {onlineReportReducer} from './reducer';

export interface IOnlineReportModuleState {
  onlineReport: IOnlineReportState;
}

export const OnlineReportModule: IModule<IOnlineReportModuleState> = {
  id: 'onlineReport',
  reducerMap: {
    onlineReport: onlineReportReducer
  }
};
