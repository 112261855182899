import React, {memo} from 'react';

import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {InputField} from 'Common/components/FormFields/index';

const PhoneNumbers: React.FC = () => {
  return (
    <FormControlContainer title="Phone numbers">
      <FieldControlContainer className="w-50">
        <InputField id="phone" name="phone" label="Phone Number" placeholder="Phone Number" />
      </FieldControlContainer>
      <FieldControlContainer className="w-50">
        <InputField name="mobile" label="Mobile Number" placeholder="+1 (234) 567-89-99" />
      </FieldControlContainer>
    </FormControlContainer>
  );
};

export default memo(PhoneNumbers);
