import {memo, useCallback, useEffect, useState} from 'react';

import SearchBar from 'Common/components/SearchBar/SearchBar';
import {IOrderPackage} from 'Order/models/IOrderPackage';

interface IProps {
  orderPackagesForFilter: IOrderPackage[];
  onSuccess(filteredOrderPackages: IOrderPackage[]): void;
}

function SearchBarCategory(props: IProps) {
  const {orderPackagesForFilter, onSuccess} = props;

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOrderPackages, setFilteredOrderPackages] = useState(orderPackagesForFilter);

  useEffect(() => {
    onSuccess(filteredOrderPackages);
  }, [filteredOrderPackages, onSuccess]);

  useEffect(() => {
    return () => setSearchQuery('');
  }, []);

  const handleChangeFilter = useCallback(
    (value) => {
      if (value === '') {
        setSearchQuery(value);
        setFilteredOrderPackages(orderPackagesForFilter);
        return;
      }

      const filteredPackages = orderPackagesForFilter.filter(
        (item) =>
          item.description?.toLowerCase().includes(value.toLowerCase()) ||
          item.name.toLowerCase().includes(value.toLowerCase())
      );

      setSearchQuery(value);
      setFilteredOrderPackages(filteredPackages);
    },
    [orderPackagesForFilter]
  );

  return <SearchBar onChange={handleChangeFilter} value={searchQuery} placeholder="Click here to search packages..." />;
}

export default memo(SearchBarCategory);
