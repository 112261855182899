import React, {memo, useCallback} from 'react';

import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {IMenuItemValued} from 'Common/models/IMenuItemValued';
import Actions from 'Common/components/Actions/Actions';
import {IAdminPurchasableTest} from 'Admin/AdminAssociations/models/IAdminPurchasableTest';

const iconProps = {color: ColorPalette.red7};

enum AdminPurchasableTestActionValue {
  EditPurchasableTest = 'editPurchasableTest',
}

const menuItems: IMenuItemValued<AdminPurchasableTestActionValue>[] = [
  {
    value: AdminPurchasableTestActionValue.EditPurchasableTest,
    label: 'Edit purchasable test',
    icon: {name: IconName.Edit, ...iconProps},
  },
];

interface IActionProps {
  purchasableTest: IAdminPurchasableTest;
  editAction: (id: number) => void;
}

export const PurchasableTestActions = memo(({purchasableTest, editAction}: IActionProps) => {
  const {id} = purchasableTest;

  const handleEditAction = useCallback(() => editAction(id), [id, editAction]);

  return (
    <Actions<AdminPurchasableTestActionValue> menuItems={menuItems} editPurchasableTest={() => handleEditAction()} />
  );
});
