import {combineReducers} from 'redux';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  contactRequestActionTypes,
  ContactRequestsActions,
  IContactRequestState
} from 'UserProfile/store/contactRequest/types';

export const communicationReducer = combineReducers<IContactRequestState['communications'], ContactRequestsActions>({
  sendContactRequesting: makeCommunicationReducerFromEnum(contactRequestActionTypes)
});
