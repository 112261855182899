import React, {memo, useMemo} from 'react';

import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {IMenuItem} from 'Common/components/DropDown/common/types';
import AdminActions from 'Admin/AdminDashboard/components/shared/AdminActions/AdminActions';
import {IHorseTransferAdmin} from 'Admin/AdminDashboard/models/Transfer/IHorseTransferAdmin';
import {TransferStatus} from 'Common/constants/TransferStatus';

const iconProps = {color: ColorPalette.red7};

const commonMenuItems: IMenuItem[] = [
  {value: 'showTransferDetails', label: 'Transfer details', icon: {name: IconName.ShowInfo, ...iconProps}},
  {value: 'changeOwner', label: 'Accept transfer', icon: {name: IconName.Exchange, ...iconProps}},
  {
    value: 'rejectHorseTransfer',
    label: 'Reject transfer',
    icon: {name: IconName.Delete, ...iconProps},
    divided: true,
  },
];

interface IExternalProps {
  horseTransfer: IHorseTransferAdmin;
  changeOwnerHandler: (transferId: number) => void;
  rejectHorseTransferHandler: (transferId: number) => void;
  showHorseTransferDetailsHandler: (transferId: number) => void;
}

type AllProps = IExternalProps;

const HorseTransferActions = (props: AllProps) => {
  const {id, status} = props.horseTransfer;

  const {changeOwnerHandler, rejectHorseTransferHandler, showHorseTransferDetailsHandler} = props;

  const isComplete = status === TransferStatus.Completed;
  const isRejected = status === TransferStatus.Rejected;

  const mapAvailableByMenuItem = useMemo(
    () => ({
      changeOwner: !isRejected && !isComplete,
      rejectHorseTransfer: !isRejected && !isComplete,
      showTransferDetails: true,
    }),
    [isComplete, isRejected]
  );

  const actionsHandler = {
    changeOwner: () => changeOwnerHandler(id),
    rejectHorseTransfer: () => rejectHorseTransferHandler(id),
    showTransferDetails: () => showHorseTransferDetailsHandler(id),
  };

  const onSelectHandler = (type: keyof typeof actionsHandler) => actionsHandler[type]();

  const shownMenuItems = useMemo(
    () =>
      commonMenuItems
        .map((item) => ({...item, availabilityKey: mapAvailableByMenuItem[item.value]}))
        .filter((item) => item.availabilityKey),
    [mapAvailableByMenuItem]
  );

  return <AdminActions menuItems={shownMenuItems} onSelectHandler={onSelectHandler} />;
};

export default memo(HorseTransferActions);
export type HorseTransferActionsProps = IExternalProps;
