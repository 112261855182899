import React, {useCallback, useState, useMemo} from 'react';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import {Combination, CombinationView} from 'Common/constants/Combination';
import SequenceEditor from 'SequenceEditor/components/SequenceEditor';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {IFormSequenceItem} from 'SequenceEditor/models/IFormSequenceItem';
import {INamedEntity} from 'Common/models/INamedEntity';
import {ISimpleDictionary} from 'DictionaryFactory/types';

const highlightStyle: React.CSSProperties = {
  fontWeight: 500,
  background: 'none',
  color: ColorPalette.gray44
};

const CombinationWord = styled.span`
  font-weight: ${Typography.weight.medium500};
  color: ${ColorPalette.gray44};
`;

export const Hint = styled.div`
  margin-bottom: 8px;
  color: ${ColorPalette.gray3};
  font-size: ${Typography.size.size14};
  line-height: 24px;
`;

export function useSequenceEditor(
  type: Combination,
  sequences: IFormSequenceItem[],
  dictionary: ISimpleDictionary<INamedEntity>,
  onSuccess: (type: Combination, sequences: IFormSequenceItem[], dictionaryItems: INamedEntity[]) => void,
  getStringifySequences?: (sequences: IFormSequenceItem[]) => string[],
  includeText?: string,
  stateText?: string
) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openSequenceEditor = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onCloseSequenceEditor = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onSuccessSequenceEditor = useCallback(
    (type: Combination, sequences: IFormSequenceItem[], dictionaryItems: INamedEntity[]) => {
      setIsModalOpen(false);
      onSuccess(type, sequences, dictionaryItems);
    },
    [onSuccess]
  );

  const getSequencesAsFormattedText = useMemo(() => {
    return getStringifySequences ? getStringifySequences(sequences) : [];
  }, [getStringifySequences, sequences]);

  function renderRuleField() {
    return (
      <>
        <FieldControlContainer label="Rule" style={{marginBottom: 0}}>
          <Hint className="d-flex flex-column">
            {getSequencesAsFormattedText.length > 0 && (
              <div>
                Must match
                <CombinationWord>{` ${CombinationView[type]} `}</CombinationWord>
                of the following:
              </div>
            )}
            {getSequencesAsFormattedText.map((text, ind) => (
              <Highlighter
                key={ind}
                searchWords={[' And ', ' Or ']}
                textToHighlight={`Sequence ${ind + 1}: ${text}`}
                highlightStyle={highlightStyle}
              />
            ))}
          </Hint>
        </FieldControlContainer>
        <PrimaryButton onClick={openSequenceEditor} type="button" style={{marginBottom: 16}}>
          Edit rule
        </PrimaryButton>
      </>
    );
  }

  function renderSequenceEditorModal() {
    return (
      <ModalWindow isOpen={isModalOpen} onClose={onCloseSequenceEditor}>
        <SequenceEditor
          type={type}
          sequences={sequences}
          dictionary={dictionary}
          onSubmit={onSuccessSequenceEditor}
          includeText={includeText}
          stateText={stateText}
        />
      </ModalWindow>
    );
  }

  return {getSequencesAsFormattedText, openSequenceEditor, renderRuleField, renderSequenceEditorModal};
}
