import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import ColorPalette from 'Common/constants/ColorPalette';
import {dateDiffInYearsOrMonth} from 'Common/helpers/DateHelper';
import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IFoundHorseForm} from './validation';

const Root = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${ColorPalette.gray48};
`;

const HorseDescription = styled.div`
  margin-left: 16px;
  overflow: hidden;
`;

const HorseName = styled.div`
  color: ${Theme.color.black};
`;

const HorseDetails = styled.div`
  color: ${ColorPalette.gray44};
`;

const Deleted = styled.span`
  color: ${Theme.color.primary};
  margin-right: 12px;
`;

interface IProps {
  horse: IFoundHorseForm;
  onReload(id: number): void;
  onDelete(id: number): void;
}

function ExcludeListItem(props: IProps) {
  const {horse, onDelete, onReload} = props;
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteIconColor, setDeleteIconColor] = useState(ColorPalette.white9);

  const horseDetails = horse
    ? [horse.gender, horse.dateOfBirth ? dateDiffInYearsOrMonth(horse.dateOfBirth) : ''].filter(Boolean).join(', ')
    : [];

  const onDeleteClick = useCallback(() => {
    setIsDeleted(true);
    horse.isDeleted = true;
    onDelete(horse.id);
  }, [horse, onDelete]);

  const onReloadClick = useCallback(() => {
    setIsDeleted(false);
    horse.isDeleted = false;
    onReload(horse.id);
  }, [horse, onReload]);

  const handleOnMouseEnter = useCallback(() => {
    setDeleteIconColor(ColorPalette.gray44);
  }, []);

  const handleOnMouseLeave = useCallback(() => {
    setDeleteIconColor(ColorPalette.white9);
  }, []);

  return (
    <Root
      className="d-flex justify-content-between align-items-center"
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <div className="d-flex align-items-center">
        <Avatar
          avatarSize={AvatarSize.Custom}
          customSizeAvatar={64}
          customSizeIcon={48}
          defaultAvatarProfile={DefaultAvatarProfile.Horse}
          url={horse.avatar && horse.avatar.url}
          style={{opacity: isDeleted ? 0.2 : 1}}
        />
        <HorseDescription>
          <HorseName>{horse.name}</HorseName>
          <HorseDetails>{horseDetails}</HorseDetails>
        </HorseDescription>
      </div>
      <div className="d-flex align-items-center">
        {isDeleted ? (
          <>
            <Deleted>Deleted</Deleted>
            <IconButton
              name={IconName.Reload}
              color={ColorPalette.gray44}
              size={16}
              fill={true}
              stroke={false}
              hoverColor={Theme.color.primary}
              onClick={onReloadClick}
            />
          </>
        ) : (
          <IconButton
            name={IconName.Close}
            color={deleteIconColor}
            size={16}
            fill={true}
            stroke={false}
            hoverColor={Theme.color.primary}
            onClick={onDeleteClick}
          />
        )}
      </div>
    </Root>
  );
}

export default memo(ExcludeListItem);
