import * as Yup from 'yup';

import {Salutation} from 'Common/constants/Salutation';
import {IImage} from 'Image/models/IImage';
import {IUserFullInfo} from 'Admin/AdminDashboard/models/User/IUserFullInfo';
import {IPersonalPrivacySettings} from 'UserProfile/models/IUserPrivacySettings';
import {ICoordinatedLocation} from 'Common/models/ICoordinatedLocation';
import {IAddress} from 'Common/models/IAddress';
import {IWithUserMapFieldProps} from 'UserProfile/components/shared/UserMapField/UserMapField';

export type IUserFormValues = IWithUserMapFieldProps &
  Omit<
    IUserFullInfo,
    'isAgreement' | 'id' | 'countHorses' | 'countVerifiedTests' | 'agreementBah' | 'ancestryAgreement'
  >;

export const UserFullInfoUpdateInitialValue: IUserFormValues = {
  avatar: null,
  email: '',
  firstName: '',
  lastName: '',
  mobile: '',
  phone: '',
  salutation: Salutation.Unknown,
  privacySettings: {
    isPublicEmail: false,
    isPublicLocation: false,
    isPublicPhone: false,
  },
  location: null,
  userAddress: null,
  address: '',
  isManualAddressMode: false,
};

export const UserFullInfoUpdateSchema = Yup.object().shape<IUserFormValues>({
  avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
  email: Yup.string(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  mobile: Yup.string(),
  phone: Yup.string(),
  salutation: Yup.mixed<Salutation>(),
  privacySettings: Yup.object().shape<IPersonalPrivacySettings>({
    isPublicPhone: Yup.boolean(),
    isPublicLocation: Yup.boolean(),
    isPublicEmail: Yup.boolean(),
  }),
  location: Yup.object()
    .shape<ICoordinatedLocation>({
      id: Yup.number(),
      latitude: Yup.number(),
      longitude: Yup.number(),
    })
    .nullable(),
  userAddress: Yup.mixed<IAddress>(),
  address: Yup.string().test('address', 'Unknown address', function () {
    return this.parent.userAddress;
  }),
  isManualAddressMode: Yup.boolean(),
});
