import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  AdminPermissionsActions,
  getAdminPermissionsActionTypes,
  getCurrentAdminPermissionsActionTypes,
  IAdminPermissionsState,
  resetAdminPermissionsActions,
  resetAdminPermissionsActionTypes,
  setAdminPermissionsActionTypes,
} from './types';
import AdminPermissionsService from '../services/AdminPermissionsService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IAdminPermissionsRequest} from 'Admin/common/Permissions/services/models/IAdminPermissionsRequest';
import {IAppState} from 'Common/store/IAppState';

export const getCurrentAdminPermissions =
  (): ActionResult<IAdminPermissionsState, Promise<void>, AdminPermissionsActions> => async (dispatch) => {
    dispatch(action(getCurrentAdminPermissionsActionTypes.REQUEST));
    try {
      const permissions = await AdminPermissionsService.getCurrentAdminPermissions();
      dispatch(action(getCurrentAdminPermissionsActionTypes.SUCCESS, permissions.data));
    } catch (error) {
      dispatch(action(getCurrentAdminPermissionsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAdminPermissions =
  (id: number): ActionResult<IAdminPermissionsState, void, AdminPermissionsActions> =>
  async (dispatch) => {
    dispatch(action(getAdminPermissionsActionTypes.REQUEST));
    try {
      const permissions = await AdminPermissionsService.getAdminPermissions(id);
      dispatch(action(getAdminPermissionsActionTypes.SUCCESS, permissions.data));
    } catch (error) {
      dispatch(action(getAdminPermissionsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setAdminPermissions =
  (data: IAdminPermissionsRequest): ActionResult<IAdminPermissionsState, void, AdminPermissionsActions> =>
  async (dispatch) => {
    dispatch(action(setAdminPermissionsActionTypes.REQUEST));
    try {
      const permissions = await AdminPermissionsService.setAdminPermissions(data);
      dispatch(action(setAdminPermissionsActionTypes.SUCCESS, permissions.data));
    } catch (error) {
      dispatch(action(setAdminPermissionsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetAdminPermissions = (): ActionResult<IAppState, void, resetAdminPermissionsActions> => (dispatch) => {
  dispatch(action(resetAdminPermissionsActionTypes.RESET));
};
