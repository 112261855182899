import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.userPrintableReportPreferences;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectUserPrintableReportPreferences(state: IAppState) {
  return selectState(state).data.userPrintableReportPreferences;
}
