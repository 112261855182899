class LocalStorageService {
  public getItem<T>(key: string): T | null {
    const value = window.localStorage.getItem(key);
    if (value) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  public removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }

  public setItem(key: string, value: unknown): void {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
}

export default new LocalStorageService();
