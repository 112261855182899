import {combineReducers} from 'redux';

import {
  GenotypeListActions,
  getGenotypeListActionTypes,
  resetGenotypeListActionTypes,
  IGenotypeListState,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

export const communicationGenotypesReducer = combineReducers<IGenotypeListState['communications'], GenotypeListActions>(
  {
    genotypeLoading: composeReducers([
      makeCommunicationReducerFromEnum(getGenotypeListActionTypes),
      makeResetCommunicationReducer(resetGenotypeListActionTypes.RESET),
    ]),
  }
);
