import * as Yup from 'yup';

import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IUpdateAdminEmployeePassword} from 'Admin/AdminAssociations/services/types/employee';

export const initialValue: IUpdateAdminEmployeePassword = {
  id: 0,
  newPassword: '',
  confirmPassword: '',
};

export const validationSchema = Yup.object().shape<IUpdateAdminEmployeePassword>({
  id: Yup.number().required(REQUIRED_FIELD),
  newPassword: Yup.string()
    .required('New password is required')
    .oneOf([Yup.ref('confirmPassword')], 'Passwords must match'),
  confirmPassword: Yup.string()
    .required('Repeat new password is required')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});
