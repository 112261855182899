import React, {memo} from 'react';

import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {useDictionaries} from 'Common/store/useDictionaries';
import DictionaryLayout from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import ColorsForm from 'Admin/AdminReference/components/Colors/ColorForm/ColorForm';
import {IShortColor} from 'Dictionaries/models/IColor';
import {HAS_COLOR_IMAGE_ICON} from 'Admin/AdminPhenotypes/components/share/StyledComponents';

function Colors() {
  const {adminColors: dictionary} = useDictionaries();

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={ColorsForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IShortColor> header="ID" dataKey="id" render={({id}) => `#${id}`} expandOnClick={true} width="10%" />
            <Cell<IShortColor> header="Name" dataKey="name" render={({name}) => name} width="25%" />
            <Cell<IShortColor> header="Image" render={({image}) => (image ? HAS_COLOR_IMAGE_ICON : '')} width="15%" />
            <Cell<IShortColor>
              header="Description"
              dataKey="description"
              render={({description}) => description?.value}
            />
            <Cell<IShortColor>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(Colors);
