import React, {memo} from 'react';
import {Form, withFormik} from 'formik';
import {FormikProps} from 'formik/dist/types';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {Checkbox} from 'Common/components/Controls';
import {
  IFormValues,
  initialValue,
  validationSchema
} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseHealthInfoSection/validation';
import {convertToClient} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseHealthInfoSection/converters';
import {IHorseHealthInfoSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseHealthInfoSection/models/IHorseHealthInfoSectionData';

type IExternalProps = IEditableSection<IHorseHealthInfoSectionData>;

type AllProps = FormikProps<IFormValues> & IExternalProps;

const HorseHealthInfoSectionShow = (props: AllProps) => {
  const {data} = props;

  return (
    <Form>
      {data!.inconclusiveHealthIssues.map(issue1 => {
        const isChecked = !!data!.horseHealthIssues?.map(i => i.id).find(issue2 => issue1.id === issue2);
        return (
          <Checkbox
            disabled={!isChecked}
            key={issue1.id}
            name={String(issue1.id)}
            checked={isChecked}
            label={issue1.name}
            onChange={console.log}
          />
        );
      })}
    </Form>
  );
};

const HorseHealthInfoSectionShowFormik = withFormik<IExternalProps, IFormValues>({
  mapPropsToValues: ({data}) => (data ? convertToClient(data) : initialValue),
  validationSchema: validationSchema,
  handleSubmit: console.log,
  enableReinitialize: true
})(HorseHealthInfoSectionShow);

export default memo(HorseHealthInfoSectionShowFormik);
