import {ICoupon} from 'Coupon/models/ICoupon';
import {ICouponRequest} from 'Admin/AdminDashboard/components/Coupons/CouponForm/validation';
import {convertPriceToClient, convertPriceToServer} from 'Common/helpers/convertPrices';
import {DiscountType} from 'Coupon/constants/DiscountType';
import {convertToServerDate, convertUTCToClientDate} from 'Common/helpers/DateHelper';

export function convertCouponToClient(coupon: ICoupon): ICoupon {
  const {amount, discountType, startDate, expiryDate} = coupon;
  return {
    ...coupon,
    amount: discountType === DiscountType.Percentage ? amount : convertPriceToClient(amount),
    startDate: startDate ? convertUTCToClientDate(startDate) : null,
    expiryDate: expiryDate ? convertUTCToClientDate(expiryDate) : null,
  };
}

export function convertCouponToRequest(coupon: ICouponRequest): ICouponRequest {
  return {
    ...coupon,
    amount: coupon.discountType === DiscountType.Percentage ? coupon.amount : convertPriceToServer(coupon.amount),
    startDate: convertToServerDate(coupon.startDate, true),
    expiryDate: convertToServerDate(coupon.expiryDate, true),
  };
}
