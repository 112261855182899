import {AbilityState} from 'Common/constants/AbilityState';

export const mapAbilityValues: Record<AbilityState, number> = {
  [AbilityState.Low]: 1,
  [AbilityState.Medium]: 2,
  [AbilityState.High]: 3
};

export const mapValueToAbilities: Record<number, AbilityState> = {
  1: AbilityState.Low,
  2: AbilityState.Medium,
  3: AbilityState.High
};
