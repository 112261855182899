import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';

import {IListResponse, IPagination} from 'Common/models/IResponse';
import {IPackage} from 'Dictionaries/models/IPackage';

const STATE_NAME = 'adminPackages';

export interface IAdminPackagesState {
  data: {
    packages: IPackage[];
    packageDetail: IPackage | null;
  };
  communications: {
    packagesLoading: ICommunication;
    packageCreating: ICommunication;
    packageUpdating: ICommunication;
    packageDeleting: ICommunication;
    packageDetailsLoading: ICommunication;
    changePackageStatusRequesting: ICommunication;
    packageCartSettingsUpdating: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getPackagesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_PACKAGES');

export const getPackageByIdActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_PACKAGE_BY_ID');

export const createPackageActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_PACKAGE');

export const updatePackageActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_PACKAGE');

export const deletePackageActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_PACKAGE');

export const changePackageStatusActionTypes = makeCommunicationActionType(STATE_NAME, 'CHANGE_PACKAGE_STATUS');

export const updatePackageCartSettingsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_PACKAGE_CART_SETTINGS'
);

export const resetPackageDetailsActionTypes = makeResetCommunicationActionType(STATE_NAME, 'PACKAGE_DETAIL');
export type resetPackageDetailsActions = MakeResetCommunicationActions<typeof resetPackageDetailsActionTypes>;

export type getPackagesActions = MakeCommunicationActions<
  typeof getPackagesActionTypes,
  {success: IListResponse<IPackage>}
>;

export type getPackageByIdActions = MakeCommunicationActions<typeof getPackageByIdActionTypes, {success: IPackage}>;

export type changePackageStatusActions = MakeCommunicationActions<
  typeof changePackageStatusActionTypes,
  {success: {packageId: number}}
>;

export type createPackageActions = MakeCommunicationActions<typeof createPackageActionTypes, {}>;

export type updatePackageActions = MakeCommunicationActions<typeof updatePackageActionTypes, {}>;

export type updatePackageCartSettingsActions = MakeCommunicationActions<
  typeof updatePackageCartSettingsActionTypes,
  {}
>;

export type deletePackageActions = MakeCommunicationActions<typeof deletePackageActionTypes, {}>;

export type AdminPackagesActions =
  | getPackagesActions
  | getPackageByIdActions
  | createPackageActions
  | updatePackageActions
  | updatePackageCartSettingsActions
  | deletePackageActions
  | changePackageStatusActions
  | resetPackageDetailsActions;
