import React from 'react';
import styled from 'styled-components';

import {IPackageTest} from 'Dictionaries/models/IPackage';
import ColoredIcon, {IColoredIconProps as IconProps} from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import withoutProps from 'Common/helpers/WithoutProps';
import {CellSeveral} from 'Common/components/StyledComponents/StyledComponents';
import {DropDownInfo} from 'Common/components/DropDown';

const InfoWrapper = styled.div`
  cursor: pointer;
`;

const ToggleIcon = styled(withoutProps(['isOpen'])(ColoredIcon))<{isOpen: boolean}>`
  margin-left: 10px;
  transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'unset')};
`;

const toggleProps: IconProps = {
  size: 12,
  name: IconName.ArrowDown,
  color: ColorPalette.green1,
  fill: true,
  stroke: false
};

interface ITestsMore {
  tests: IPackageTest[] | null;
}

export const TestsMoreDropDown = React.memo((props: ITestsMore) => {
  const {tests} = props;

  if (!tests || tests.length === 0) {
    return <CellSeveral>No tests</CellSeveral>;
  }

  return (
    <DropDownInfo style={{width: 400, fontSize: 18}} info={tests.map(item => item.name).join(', ')} alignRight={false}>
      {({isOpen}) => (
        <InfoWrapper>
          {tests.length} tests
          <ToggleIcon {...toggleProps} isOpen={isOpen} />
        </InfoWrapper>
      )}
    </DropDownInfo>
  );
});
