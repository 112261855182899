import {ADMIN_NAVIGATION_ROUTES, BUSINESS_PORTAL_NAVIGATION_ROUTES} from 'Common/components/Navigation';
import ColorPalette from 'Common/constants/ColorPalette';
import {INavigation} from 'Common/models/INavigation';

const linkStyle = {color: ColorPalette.black3, hoverColor: ColorPalette.black1, activeColor: ColorPalette.red11};

const navLinksItems: INavigation[] = [
  {label: 'Dashboard', value: '/', exact: true},
  {label: 'My profile', value: '/user/user-profile/:userId', needUserId: true},
  {label: 'Build-a-Horse', value: '/build-a-horse'},
  {label: 'Find-a-Horse', value: '/find-a-horse'},
  {label: 'Home', value: 'http://www.etalondx.com/', isExternalUrl: true},
];

const userMenuLinks: INavigation[] = [
  {
    label: 'Profile settings',
    value: '/account-details/personal-details',
    style: linkStyle,
    isMenuItemAsLink: true,
  },
];

function getMenuNavLinks(links: INavigation[], currentUserId?: number, currentUserPermissions?: string[]) {
  return links.map(({label, needUserId, value, exact, isExternalUrl, isMenuItemAsLink}) => {
    return {
      label,
      value: needUserId ? value?.replace(':userId', `${currentUserId}`) : value,
      exact,
      isExternalUrl,
      isMenuItemAsLink: isMenuItemAsLink !== undefined ? isMenuItemAsLink : false,
    };
  });
}

export function getUserMenuLinks(currentUserId?: number): INavigation[] {
  return getMenuNavLinks(userMenuLinks, currentUserId);
}

export function getHeadersNavLinks(currentUserId?: number, currentUserPermissions?: string[]): INavigation[] {
  return getMenuNavLinks(navLinksItems, currentUserId, currentUserPermissions);
}

export function getHeadersNavMenu(currentUserPermissions?: string[], isDevelopmentAdmin?: boolean): INavigation[] {
  return ADMIN_NAVIGATION_ROUTES.filter(
    (item) =>
      (!item.onlyForDevelopmentAdmin || (item.onlyForDevelopmentAdmin && isDevelopmentAdmin)) &&
      item.permissions?.every((permission) => currentUserPermissions?.includes(permission))
  ).map(({value, label}) => {
    return {value, label, isMenuItemAsLink: true};
  });
}

export function getBusinessPortalHeadersNavMenu(currentUserPermissions?: string[]): INavigation[] {
  const routes = BUSINESS_PORTAL_NAVIGATION_ROUTES.filter((route) => !!route.icon && !!route.order)
    .sort((a, b) => a.order! - b.order!)
    .filter(
      (item) =>
        !item.permissions || item.permissions?.every((permission) => currentUserPermissions?.includes(permission))
    );

  const bottomRoutes = BUSINESS_PORTAL_NAVIGATION_ROUTES.filter((route) => !!route.icon && !!route.orderBottom)
    .sort((a, b) => a.orderBottom! - b.orderBottom!)
    .filter(
      (item) =>
        !item.permissions || item.permissions?.every((permission) => currentUserPermissions?.includes(permission))
    );

  return routes.concat(bottomRoutes);
}
