import {IServerUserHorseOrder} from 'Horse/services/types/horse';
import {parseOrderStatus} from 'Order/helpers/parseOrderStatus';
import {IUserHorseOrder} from 'Horse/models/IUserHorse';

export function convertServerHorseLastOrderToClient(horse: IServerUserHorseOrder): IUserHorseOrder {
  return {
    ...horse,
    ...parseOrderStatus({
      paymentStatus: horse.paymentStatus,
      sampleStatus: horse.sampleStatus,
      reviewStatus: horse.reviewStatus,
      resultsStatus: horse.resultsStatus,
      releaseStatus: horse.releaseStatus
    })
  };
}
