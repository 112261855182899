import {IModule} from 'redux-dynamic-modules';

import {IBusinessPortalOrderState} from 'BusinessPortal/store/order/types';
import {businessPortalOrderReducer} from 'BusinessPortal/store/order/reducer';

export interface IBusinessPortalOrderModuleState {
  businessPortalOrder: IBusinessPortalOrderState;
}

export const BusinessPortalOrderModule: IModule<IBusinessPortalOrderModuleState> = {
  id: 'businessPortalOrder',
  reducerMap: {
    businessPortalOrder: businessPortalOrderReducer,
  },
};
