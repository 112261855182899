import {Reducer} from 'redux';

import {MainPageHorsesActions, getMainPageHorsesActionTypes, IMainPageHorsesState} from '../types';

const initialStateList: IMainPageHorsesState['data'] = {
  horses: []
};

export const dataReducer: Reducer<IMainPageHorsesState['data'], MainPageHorsesActions> = (
  state = initialStateList,
  action: MainPageHorsesActions
) => {
  switch (action.type) {
    case getMainPageHorsesActionTypes.SUCCESS: {
      return {...state, horses: action.payload.data};
    }
    default: {
      return state;
    }
  }
};
