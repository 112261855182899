import React, {memo, useState, useMemo, useCallback, useEffect} from 'react';
import styled from 'styled-components';
import ReactPlayer, {ReactPlayerProps} from 'react-player/lazy';

import Icon, {IconName} from 'Icon/components/Icon';
import Loading from 'Loading/components/Loading';
import ColorPalette from 'Common/constants/ColorPalette';
import {usePrevious} from 'Common/helpers/hooks/usePrevious';

const Root = styled.div`
  min-height: 304px;
`;

const Fallback = styled.div`
  background: ${ColorPalette.gray49};
`;

function VideoPlayer(props: ReactPlayerProps) {
  const {url, ...rest} = props;
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const prevUrl = usePrevious(url);

  useEffect(() => {
    if (url !== prevUrl) {
      setIsVideoLoaded(false);
    }
  }, [prevUrl, url]);

  const fallbackLoading = useMemo(
    () => (
      <Fallback className="d-flex justify-content-center align-items-center w-100 h-100">
        <Icon name={IconName.PlayArrow} size={64} />
      </Fallback>
    ),
    []
  );

  const onReady = useCallback(() => {
    setIsVideoLoaded(true);
  }, []);

  return (
    <Root className="d-flex flex-column position-relative w-100 h-100">
      {!isVideoLoaded && <Loading />}
      <ReactPlayer onReady={onReady} url={url} fallback={fallbackLoading} {...rest} />
    </Root>
  );
}

VideoPlayer.defaultProps = {
  width: '100%',
  height: '304px',
  light: false,
  controls: true
};

export default memo(VideoPlayer);
