import React, {useCallback, useState} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import {Nullable} from 'Common/types';
import AdministratorForm from 'Admin/AdminAdvancedDashboard/components/Administrators/AdministatorForm/AdministratorForm';
import {FormType} from 'Common/constants/FormType';

interface IUseModalParams {
  onSuccess?(): void;
}

export function useAdministratorModal(params: IUseModalParams) {
  const {onSuccess} = params;

  const [updatedId, setUpdatedId] = useState<number | null>();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const openUpdateAdministratorModal = useCallback(
    (id?: Nullable<number>) => {
      setIsUpdateModalOpen(true);
      setUpdatedId(id);
    },
    [setIsUpdateModalOpen]
  );

  const openCreateAdministratorModal = useCallback(() => {
    setIsCreateModalOpen(true);
  }, []);

  const closeModals = useCallback(() => {
    setIsUpdateModalOpen(false);
    setIsCreateModalOpen(false);
  }, []);

  const onSuccessHandler = useCallback(() => {
    closeModals();
    onSuccess && onSuccess();
  }, [onSuccess, closeModals]);

  const updateAdministratorModal = (
    <ModalWindow isOpen={isUpdateModalOpen} onClose={closeModals}>
      <AdministratorForm administratorId={updatedId!} onSuccess={onSuccessHandler} type={FormType.edit} />
    </ModalWindow>
  );

  const createAdministratorModal = (
    <ModalWindow isOpen={isCreateModalOpen} onClose={closeModals}>
      <AdministratorForm onSuccess={onSuccessHandler} type={FormType.create} />
    </ModalWindow>
  );

  return {
    openUpdateAdministratorModal,
    openCreateAdministratorModal,
    updateAdministratorModal,
    createAdministratorModal,
    updatedId
  };
}
