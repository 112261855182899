import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {actions, selectors} from 'Admin/AdminSettings/stores/adminHtmlTemplates/index';
import {InputField, SelectField} from 'Common/components/FormFields/index';
import FileUploaderField from 'Common/components/FormFields/FileUploaderField';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {ModalWindowButton, ModalWindowFooter, ModalWindowHeader} from 'Common/components/Modal/shared';

import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {getStringKeysOption} from 'Common/helpers/OptionHelper';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {AdminHtmlTemplatesModule} from 'Admin/AdminSettings/stores/adminHtmlTemplates/adminHtmlTemplatesModule';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {convertFormValues, IFormValues, initialValue, validationSchema} from './validation';
import {HtmlTemplateType} from 'Admin/AdminSettings/constants/HtmlTemplateType';
import {FormType} from 'Common/constants/FormType';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';

const sortedHtmlTemplateTypes = getStringKeysOption(HtmlTemplateType).sort((a, b) => sortByStringKey(a, b, 'label'));

const formHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add template',
  edit: 'Update template',
};

const buttonHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add template',
  edit: 'Save template',
};

const FormLayout = styled.div`
  margin: 24px 24px 24px 40px;
`;

interface IExternalProps {
  type?: HtmlTemplateType;
  name?: string;
  formType: FormType;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const HtmlTemplateForm = (props: AllProps) => {
  const {formType} = props;
  const {templateUploading, onSuccess} = props;
  const {status, isSubmitting, setErrors} = props;

  const errorInfo = templateUploading.error;

  useOnSuccessCommunication(templateUploading, onSuccess);
  useErrorCommunicationToToast(templateUploading);

  useEffect(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  const isRequesting = templateUploading.isRequesting;
  const isValid = props.submitCount === 0 || props.isValid;

  const header = formHeaderByType[formType];
  const saveButtonHeader = buttonHeaderByType[formType];

  const isEdit = formType === FormType.edit;

  return (
    <>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      {isRequesting && <Loading />}
      <Form className="d-flex flex-column justify-content-center">
        <FormLayout>
          <SelectField
            isRequired={true}
            name="type"
            label="Type"
            options={sortedHtmlTemplateTypes}
            isDisabled={isEdit}
            memoized={true}
          />
          <InputField name="name" label="Name" placeholder={!isEdit ? 'Template name' : ''} memoized={true} />
          <FileUploaderField
            isRequired={true}
            acceptableTypes={['.html', '.css', '.htm']}
            name="uploadedFile"
            label="Template file"
            placeholder="Select a file to upload"
          />
        </FormLayout>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" disabled={!isValid} isLoading={isSubmitting}>
            {saveButtonHeader}
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const HtmlTemplateFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({type, name}: OuterProps) => ({...initialValue, type, name}),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {uploadTemplate} = formikBag.props;
    await uploadTemplate(convertFormValues(values));
  },
  enableReinitialize: true,
})(HtmlTemplateForm);

const mapStateToProps = (state: IAppState) => ({
  templateUploading: selectors.selectCommunication(state, 'templateUploading'),
});

const mapDispatchToProps = {
  uploadTemplate: actions.uploadTemplate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(HtmlTemplateFormFormik);

export default withDynamicModules(Connected, AdminHtmlTemplatesModule);
