import {OrderStatusField} from 'Order/models/IOrderStatusFields';
import {OrderStatus} from 'Common/constants/OrderStatus';
import {IOrderStatusFields} from 'BusinessPortal/models/order/createOrder/IOrderStatusFields';
import {IOrderStatus} from 'BusinessPortal/models/order/IOrderStatus';

export function parseOrderStatus(statuses: IOrderStatusFields): IOrderStatus {
  const {sampleStatus, paymentStatus, releaseStatus} = statuses;
  const currentStatus = getCurrentStatus(statuses);

  return {
    status: currentStatus,
    hasPayment: paymentStatus === OrderStatusField.Ready,
    hasSample: sampleStatus === OrderStatusField.Ready,
    hasRelease: releaseStatus === OrderStatusField.Ready,
  };
}

function getCurrentStatus({paymentStatus, sampleStatus, releaseStatus}: IOrderStatusFields) {
  if (paymentStatus === OrderStatusField.Waiting) {
    return OrderStatus.orderPlaced;
  }
  if (sampleStatus === OrderStatusField.Waiting) {
    return OrderStatus.paymentReceived;
  }
  if (releaseStatus === OrderStatusField.Waiting) {
    return OrderStatus.sampleReceived;
  }

  return OrderStatus.releaseReady;
}
