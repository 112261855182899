import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminAssociationPaymentUpdate;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectPaymentDetails(state: IAppState) {
  return selectState(state).data.paymentDetails;
}

export function selectPaymentHorses(state: IAppState) {
  return selectState(state).data.paymentDetailsHorses;
}
