import * as React from 'react';
import styled from 'styled-components';

import {CellAlign} from '../constants/CellAlign';
import {SortOrder} from '../constants/SortOrder';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

const SortIcon = styled.div<Pick<IHeaderCellProps, 'sortOrder'>>`
  display: inline-block;
  margin-left: 8px;
  ::before,
  ::after {
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${Theme.color.gray};
    content: '';
  }

  ::before {
    margin-bottom: 6px;
    transform: rotate(180deg);
    border-top-color: ${props => (props.sortOrder === 'asc' ? Theme.color.black : Theme.color.gray)};
  }

  ::after {
    border-top-color: ${props => (props.sortOrder === 'desc' ? Theme.color.black : Theme.color.gray)};
  }
`;

const HeaderCellContent = styled.span<Pick<IHeaderCellProps, 'sortOrder' | 'sortable'>>`
  cursor: ${props => (props.sortable ? 'pointer' : 'unset')};
  color: ${props => (props.sortable && props.sortOrder ? Theme.color.black : Theme.color.gray)};

  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  text-transform: uppercase;
`;

export interface IHeaderCellProps<T = {}> {
  header: string;
  dataKey?: keyof T;
  align?: CellAlign;
  onClick?(dataKey: keyof T): void;
  sortOrder?: SortOrder | null;
  sortable?: boolean;
}

export default function HeaderCell<T>(props: IHeaderCellProps<T>) {
  const {dataKey, align, header, sortable, sortOrder, onClick} = props;
  const handleClick = React.useCallback(() => dataKey && onClick && onClick(dataKey), [onClick, dataKey]);
  return (
    <td align={align || CellAlign.Left}>
      <HeaderCellContent
        className="d-inline-flex align-items-center"
        sortable={sortable}
        sortOrder={sortOrder}
        onClick={handleClick}
      >
        {header}
        {sortable && <SortIcon sortOrder={sortOrder} />}
      </HeaderCellContent>
    </td>
  );
}
