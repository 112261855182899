import {useEffect, useState} from 'react';

export function useScript(url: string, id?: string, onLoad?: () => void, onError?: (e: any) => void) {
  const [isLoaded, setIsLoaded] = useState(false);

  function loadScript(script: HTMLScriptElement) {
    return new Promise<void>(function(resolve, reject) {
      script.addEventListener('load', function() {
        resolve();
      });
      script.addEventListener('error', function(e) {
        reject(e);
      });
      document.body.appendChild(script);
    });
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    if (id) {
      script.id = id;
    }

    loadScript(script)
      .then(() => {
        onLoad && onLoad();
        setIsLoaded(true);
      })
      .catch(e => {
        onError && onError(e);
      });

    return () => {
      document.body.removeChild(script);
    };
  }, [onLoad, onError, url, id]);

  return {isLoaded};
}
