import * as Yup from 'yup';

import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IAdminAssociationPackageRequest} from 'Admin/AdminAssociations/models/IAdminAssociationPackage';
import {IAdminOrganizationPriceRequest} from 'Admin/AdminAssociations/models/IAdminOrganizationPrice';

export interface IFormValues extends IAdminAssociationPackageRequest {
  isSpecialPrices: boolean;
}

export const initialValue: IFormValues = {
  id: 0,
  abbreviation: null,
  name: '',
  commonPrice: 0,
  tests: [],
  description: '',
  statusChangeDate: null,
  reportTypes: [],
  specialPrices: [],
  isAvailabilityAllOrganizations: true,
  isSpecialPrices: false,
  groups: [],
  isBundle: false,
  positionIndex: 0,
};

export const validationSchema = Yup.object().shape<IFormValues>({
  id: Yup.number(),
  abbreviation: Yup.string().nullable(),
  name: Yup.string().required(REQUIRED_FIELD),
  commonPrice: Yup.number(),
  tests: Yup.array().of(Yup.number()),
  description: Yup.string(),
  statusChangeDate: Yup.string().nullable(),
  reportTypes: Yup.array(),
  specialPrices: Yup.array().of(
    Yup.object().shape<IAdminOrganizationPriceRequest>({
      price: Yup.number(),
      organizations: Yup.array().of(Yup.number()).required(REQUIRED_FIELD),
    })
  ),
  isAvailabilityAllOrganizations: Yup.boolean(),
  isSpecialPrices: Yup.boolean().test('isSpecialPrices', 'At least one price must be added', function (value: boolean) {
    if (value && this.parent.specialPrices.length === 0) {
      return false;
    }
    return true;
  }),
  groups: Yup.array().of(Yup.number()),
  isBundle: Yup.boolean(),
  positionIndex: Yup.number(),
});
