import React from 'react';
import styled, {css} from 'styled-components';

const baseMargins = css`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Page = styled.div<IOwnProps>`
  width: 100%;
  ${props => !props.withoutPaddings && baseMargins}
`;

interface IOwnProps {
  withoutPaddings?: boolean;
}

type Props = IOwnProps & React.HTMLAttributes<HTMLDivElement>;

const PageWrapper = (props: Props) => {
  const {children, className, withoutPaddings} = props;
  return (
    <Page withoutPaddings={withoutPaddings} className={className}>
      {children}
    </Page>
  );
};

export default PageWrapper;
