import styled from 'styled-components';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

export const Section = styled.div`
  page-break-inside: avoid;
`;

export const SectionHeader = styled.div`
  padding: 0 20px;
  margin-bottom: 30px;
  height: 48px;
  background: ${Theme.color.black} !important;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
`;

export const SectionContent = styled.div`
  padding: 0 10px;
`;

export const Header = styled.div`
  margin-left: 10px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  align-items: center;
  color: ${Theme.color.white};
`;

export const Label = styled.div`
  margin-bottom: 8px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 12px;
  align-items: center;
  letter-spacing: 0.5px;
  color: ${Theme.color.black};
  opacity: 0.5;
`;

export const PageBreak = styled.div`
  page-break-before: always;
`;
