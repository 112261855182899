import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {Nullable} from 'Common/types';
import {HeightUnits} from 'HorseProfile/constants/HeightUnits';
import {parseHorseHeight} from 'Common/helpers/parseHorseHeight';
import {ICommercialType} from 'Dictionaries/models/ICommercialType';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import HorseAbilities from './HorseAbilities';
import {IShortAbility} from 'Dictionaries/models/IAbilities';

const Root = styled.div`
  overflow: hidden;
`;

const Label = styled.div<{disabled?: boolean}>`
  font-family: ${Typography.family.ubuntu};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 12px;
  color: ${ColorPalette.gray44};

  @media ${breakpoints.md} {
    justify-self: end;
  }
`;

const Value = styled.div`
  width: 100%;
  font-family: ${Typography.family.ubuntu};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  color: ${ColorPalette.black1};
  justify-self: end;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;

  @media ${breakpoints.md} {
    justify-self: start;
    text-align: left;
  }
`;

const BaseCharacteristics = styled.div<{hasColor?: boolean}>`
  display: grid;
  grid-gap: 8px 0;
  grid-template-rows: 24px ${p => (p.hasColor ? '24px' : '')};
  grid-template-columns: 50% 50%;
  align-items: baseline;

  @media ${breakpoints.md} {
    grid-gap: 8px;
    grid-template-rows: 24px;
    grid-template-columns: 84px 78px ${p => (p.hasColor ? '30px minmax(26px, auto)' : '')};
  }
`;

const CommercialityWrapper = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: 84px auto;
  grid-template-rows: 24px;
  align-items: baseline;

  @media ${breakpoints.md} {
    display: block;
    margin-left: 92px;
    margin-top: 24px;
  }
`;

const ItemWrapper = styled.div`
  background: ${ColorPalette.gray49};
  border-radius: 3px;
  padding: 8px 24px;
  margin: 0 1px 8px 0;
  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  text-align: center;
  color: ${ColorPalette.black1};
`;

const AbilitiesWrapper = styled.div`
  margin-top: 8px;
`;

interface IProps {
  height: Nullable<number>;
  heightUnit: HeightUnits;
  color?: string;
  temperament?: IShortAbility;
  gait?: IShortAbility;
  speed?: IShortAbility;
  commercialTypes: ICommercialType[];
}

function PartnerCharacteristic(props: IProps) {
  const {height, heightUnit, color, commercialTypes, temperament, gait, speed} = props;
  const {isDesktop} = useMediaQuery();

  const hasCommerciality = commercialTypes.length > 0;

  const renderCommerciality = () =>
    isDesktop ? (
      <div className="d-flex flex-wrap">
        {commercialTypes.map((item, i) => (
          <ItemWrapper className="d-flex align-self-center" key={i}>
            {item.name}
          </ItemWrapper>
        ))}
      </div>
    ) : (
      <>
        <Label>Available for</Label>
        <Value className="w-100 text-right">{commercialTypes.map(item => item.name).join(', ')}</Value>
      </>
    );

  return (
    <Root>
      <BaseCharacteristics hasColor={!!color}>
        <Label>Height</Label>
        <Value>{height ? parseHorseHeight(height, heightUnit) : '-'}</Value>
        {color && (
          <>
            <Label>Color</Label>
            <Value>{color}</Value>
          </>
        )}
      </BaseCharacteristics>
      <AbilitiesWrapper>
        <HorseAbilities temperament={temperament} gait={gait} speed={speed} showIndicators={isDesktop} />
      </AbilitiesWrapper>
      <div></div>
      {hasCommerciality && <CommercialityWrapper>{renderCommerciality()}</CommercialityWrapper>}
    </Root>
  );
}

export default memo(PartnerCharacteristic);
