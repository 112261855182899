import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';
import {Nullable} from 'Common/types';
import {IHorseTransferDetails} from 'Horse/models/IHorseTransfer';

const STATE_NAME = 'horseTransfer';

export interface IHorseTransferState {
  data: {
    horseTransferDetails: Nullable<IHorseTransferDetails>;
  };
  communications: {
    horseTransferDetailsLoading: ICommunication;
    horseTransferCreating: ICommunication;
  };
}

export const getHorseTransferDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_TRANSFER_DETAILS');
export type getHorseTransferDetailsActions = MakeCommunicationActions<
  typeof getHorseTransferDetailsActionTypes,
  {success: Nullable<IHorseTransferDetails>}
>;

export const createHorseTransferActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_HORSE_TRANSFER');
export type createHorseTransferActions = MakeCommunicationActions<typeof createHorseTransferActionTypes, {}>;

export type Actions = getHorseTransferDetailsActions | createHorseTransferActions;
