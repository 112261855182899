import {action} from 'typesafe-actions';
import {IAppState} from 'Common/store/IAppState';

import {ActionResult} from 'Common/store/store';
import ColorsAdminDataService from 'Admin/AdminReference/store/uploadImage/service';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IImage} from 'Image/models/IImage';
import {ImageActions, uploadImageActionTypes} from 'Image/store/types';

// TODO used by Color/Modifier-Combinations and ColorForm
export const uploadColorImage = (
  uploadedFile: FormData
): ActionResult<IAppState, Promise<IImage>, ImageActions> => async dispatch => {
  dispatch(action(uploadImageActionTypes.REQUEST));
  try {
    const res = await ColorsAdminDataService.uploadColorImage(uploadedFile);
    dispatch(action(uploadImageActionTypes.SUCCESS, res.data));
    return res.data;
  } catch (error) {
    const err = getErrorMessage(error);
    dispatch(action(uploadImageActionTypes.FAILURE, err));
    throw err;
  }
};
