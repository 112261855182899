import React, {useEffect} from 'react';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps} from 'formik/dist/types';
import styled from 'styled-components';

import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {actions, selectors} from 'BusinessPortal/store/horse';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import UserSearchField from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearchField';
import {
  ISetOwnerFormValues,
  setOwnerInitialValue,
  setOwnerValidationSchema,
} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseOwnerSection/HorseOwnerSectionEdit/common/validation';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {ChangeOwnerFormButton} from './common/styled';
import {IUserOption} from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearch';
import {RequiredBy} from 'Common/types';
import {IOwnerInfoSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/parts/OwnerGeneralInfoSection/models/IOwnerInfoSectionData';
import BusinessPortalOwnerService from 'BusinessPortal/services/BusinessPortalOwnerService';

const defaultUserOptionProps: IUserOption = {
  value: 0,
  label: '',
  lastName: '',
  email: '',
  id: 0,
  avatar: null,
  firstName: '',
};

const SubmitButton = styled(PrimaryButton)`
  margin-top: 16px;
`;

interface IExternalProps extends RequiredBy<IOwnerInfoSectionData, 'horseId'> {
  onSuccess?(): void;
  onChangeForm?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<ISetOwnerFormValues> & OuterProps;

const HorseOwnerSectionEditFindForm = (props: AllProps) => {
  const {values, isValid, isSubmitting, setStatus, status} = props;
  const {owner, onSuccess, onChangeForm, setHorseOwnerRequesting} = props;

  const isRequesting = setHorseOwnerRequesting.isRequesting;

  useErrorCommunicationToToast(setHorseOwnerRequesting);
  useOnSuccessCommunication(setHorseOwnerRequesting, () => onSuccess && onSuccess());

  useEffect(() => {
    if (values.id === owner?.id) {
      setStatus('error');
    } else {
      setStatus();
    }
  }, [owner?.id, setStatus, values.id]);

  if (isRequesting) {
    return <Loading />;
  }

  return (
    <Form>
      <UserSearchField
        name="id"
        action={BusinessPortalOwnerService.findOwner}
        isRequired={true}
        defaultValue={{...defaultUserOptionProps, ...values}}
      />
      <ChangeOwnerFormButton onClick={onChangeForm}>Want to create a new user?</ChangeOwnerFormButton>
      <SubmitButton
        className="w-100"
        size="medium"
        type="submit"
        disabled={!isValid || status}
        isLoading={isSubmitting}
      >
        Save changes
      </SubmitButton>
    </Form>
  );
};

const HorseOwnerInfoSectionEditFindFormFormik = withFormik<OuterProps, ISetOwnerFormValues>({
  mapPropsToValues: ({owner}) =>
    owner ? {...setOwnerInitialValue, id: owner.id, value: owner.id, avatar: owner.avatar} : setOwnerInitialValue,
  validationSchema: setOwnerValidationSchema,
  handleSubmit: async (values, formikBag) => {
    const {horseId, setHorseOwner} = formikBag.props;
    values.id && (await setHorseOwner(horseId, values.id));
  },
  enableReinitialize: true,
})(HorseOwnerSectionEditFindForm);

const mapStateToProps = (state: IAppState) => ({
  setHorseOwnerRequesting: selectors.selectCommunication(state, 'setHorseOwnerRequesting'),
});

const mapDispatchToProps = {
  setHorseOwner: actions.setHorseOwner,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(HorseOwnerInfoSectionEditFindFormFormik);
