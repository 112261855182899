import React, {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {IUser} from 'UserProfile/models/IUser';
import {breakpoints, size} from 'Common/constants/Breakpoints';
import MainSection from 'Shared/components/UserProfile/parts/ProfileInfo/parts/MainSection';
import StatisticsSection from 'Shared/components/UserProfile/parts/ProfileInfo/parts/StatisticsSection';
import ContactSection from 'Shared/components/UserProfile/parts/ProfileInfo/parts/ContactSection/ContactSection';
import {useMatchMediaQuery, useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {IAppState} from 'Common/store/IAppState';
import {actions as currentUserActions, selectors as currentUserSelectors} from 'UserProfile/store/currentUser/index';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import Switch from 'Common/components/Controls/Switch';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import Loading from 'Loading/components/Loading';
import HintIcon from 'Common/components/HintIcont/HintIcon';

const CHANGE_PROFILE_TYPE_TOOLTIP =
  'Enabling this option will allow other users to see this user profile. Also, it gives you an opportunity to share it';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${breakpoints.sm} {
    flex-direction: row;
  }

  @media ${breakpoints.lg} {
    flex-direction: column;
    position: static;
  }
`;

const SwitchWrapper = styled.div`
  margin: 10px 0 0 -24px;
`;

const SwitchText = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 18px;
  color: ${Theme.color.black};
`;

interface IProps {
  user: IUser;
  isCurrentUser: boolean;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IProps;

function ProfileInfo(props: AllProps) {
  const {
    user,
    isCurrentUser,
    getProfileType,
    userProfileType,
    updateProfileType,
    userProfileTypeLoading,
    userProfileTypeUpdating,
  } = props;
  const {countHorses, countVerifiedTests} = user;
  const {isDesktop, isMobile} = useMediaQuery();

  const isLoading = userProfileTypeLoading.isRequesting;

  const [isPublicCurrentUserProfileType, setIsPublicCurrentUserProfileType] = useState<boolean>();

  useEffect(() => {
    if (user && isCurrentUser) {
      getProfileType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userProfileType) {
      setIsPublicCurrentUserProfileType(userProfileType.isPublic);
    }
  }, [userProfileType]);

  const onError = useCallback(() => {
    const commonErrors = getCommonErrors(userProfileTypeUpdating.error);

    if (commonErrors) {
      setIsPublicCurrentUserProfileType(!isPublicCurrentUserProfileType);
    }
  }, [isPublicCurrentUserProfileType, userProfileTypeUpdating.error]);

  useOnErrorCommunication(userProfileTypeUpdating, onError);

  const onChangeUserProfileType = useCallback(async () => {
    isPublicCurrentUserProfileType !== undefined && (await updateProfileType(!isPublicCurrentUserProfileType));
    setIsPublicCurrentUserProfileType(!isPublicCurrentUserProfileType);
  }, [isPublicCurrentUserProfileType, updateProfileType]);

  const isRowRootSize = useMatchMediaQuery(`(min-width: ${size.sm}px) and (max-width: ${size.lg - 1}px)`);

  const renderChangeProfileTypeSwitch = () => {
    if (isPublicCurrentUserProfileType === undefined || !isCurrentUser) {
      return null;
    }

    return (
      <SwitchWrapper
        className={`d-flex flex-row ${!isRowRootSize && isDesktop && `justify-content-center`} align-items-center`}
      >
        <Switch
          onChange={onChangeUserProfileType}
          checked={isPublicCurrentUserProfileType}
          textRight={<SwitchText>{isPublicCurrentUserProfileType ? 'Public' : 'Private'} profile</SwitchText>}
        />
        <HintIcon tooltip={CHANGE_PROFILE_TYPE_TOOLTIP} />
      </SwitchWrapper>
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!user) {
    return <span>empty user</span>;
  }

  return (
    <>
      <Root>
        <MainSection user={user} isCurrentUser={isCurrentUser} />

        <div className="w-100">
          {!isMobile && isCurrentUser && (
            <StatisticsSection countHorses={countHorses} countVerifiedTests={countVerifiedTests} />
          )}
          {!isRowRootSize && renderChangeProfileTypeSwitch()}
          <ContactSection user={user} />
        </div>
      </Root>
      {isRowRootSize && renderChangeProfileTypeSwitch()}
    </>
  );
}

const mapStateToProps = (state: IAppState) => ({
  userProfileType: currentUserSelectors.selectCurrentUserProfileType(state),
  userProfileTypeLoading: currentUserSelectors.selectCommunication(state, 'userProfileTypeLoading'),
  userProfileTypeUpdating: currentUserSelectors.selectCommunication(state, 'userProfileTypeUpdating'),
});

const mapDispatchToProps = {
  getProfileType: currentUserActions.getCurrentUserProfileType,
  updateProfileType: currentUserActions.updateCurrentUserProfileType,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(ProfileInfo));
