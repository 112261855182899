import React, {memo} from 'react';
import styled, {css} from 'styled-components';

import ColoredNavLink from 'Common/components/Controls/Links/ColoredNavLink';
import {getHeadersNavLinks} from 'Common/components/Header/common';
import {Nullable} from 'Common/types';
import {IUser} from 'UserProfile/models/IUser';
import {Permission} from 'Permissions/constants/Permission';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {breakpoints} from 'Common/constants/Breakpoints';
import ColoredExternalLink from 'Common/components/Controls/Links/ColoredExternalLink';

const Root = styled.div<{isFooter: boolean}>`
  ${props =>
    props.isFooter
      ? `
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 20px;
    width: 100%;
    :first-child {
      padding: 0 40px;
    }

    @media ${breakpoints.sm} {
      grid-column-gap: 0;
      :first-child {
        padding: 0;
      }
    }
    `
      : `
      flex-grow: 1;
      justify-content: flex-start;
      flex-wrap: nowrap;
      align-items: center;
  `}

  
  @media ${breakpoints.md} {
    justify-content: center;
    display: flex;
  }
`;

const linkStyle = css`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  letter-spacing: 0.2px;
  padding: 0.7rem;

  @media ${breakpoints.md} {
    padding: 0.5rem 1rem;
  }
`;

const NavLink = styled(ColoredNavLink)`
  ${linkStyle};
`;

const ExternalLink = styled(ColoredExternalLink)`
  ${linkStyle};
`;

interface IProps {
  user: Nullable<IUser>;
  userPermissions: Permission[];
  isFooter?: boolean;
}

function NavLinks(props: IProps) {
  const {isFooter, user, userPermissions} = props;

  const links = user && getHeadersNavLinks(user.id, userPermissions);

  return (
    <Root className="nav" isFooter={!!isFooter}>
      {links?.map((item, i) => {
        if (item.isExternalUrl) {
          return (
            <ExternalLink key={i} className="nav-link" href={item.value}>
              {item.label}
            </ExternalLink>
          );
        }
        return (
          <NavLink key={i} className="nav-link" exact={item.exact} to={item.value}>
            {item.label}
          </NavLink>
        );
      })}
    </Root>
  );
}

export default memo(NavLinks);
