import React, {useCallback, useMemo} from 'react';
import {FieldArray, FieldArrayRenderProps} from 'formik';
import styled from 'styled-components';

import {IOrderHorse} from 'Order/models/IOrderHorse';
import {IHorseTest} from 'Admin/AdminDashboard/models/Order/IHorseTest';
import {IconName} from 'Icon/components/Icon';
import {SelectField} from 'Common/components/FormFields/index';
import {castToOption} from 'Common/helpers/OptionHelper';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {MutableField, MutableFieldsGroup} from 'Common/components/StyledComponents/StyledComponents';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';
import {sortByName} from 'Common/helpers/sortByName';
import {IOrderPackage} from 'Admin/AdminDashboard/models/Order/IOrderPackage';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';
import {IPackageGroup} from 'Dictionaries/models/IPackageGroup';
import {sortBySorter} from 'Common/helpers/Sorter/sortBySorter';
import {packageSorter} from 'Order/constants/PackageSorter';
import PackageSelect from './PackageSelect';

const initialHorseTest: IHorseTest = {packages: [], horseId: 0};

const AddHorseButton = styled(PrimaryButton)`
  margin-bottom: 24px;
`;

const Divider = styled.div`
  margin: 10px 0;
  border: 1px solid ${ColorPalette.white9};
`;

interface IOrderPackageGroup {
  group: string;
  values: IOrderPackage[];
}

export interface IPackageOptionGroup {
  label: string;
  options: IDictionaryOptionType[];
}

interface IProps {
  horses: IOrderHorse[];
  tests: IHorseTest[];
  orderPackages: IOrderPackage[];
  packageGroups: IPackageGroup[];
}

function PackageSection(props: IProps) {
  const {horses, tests, orderPackages, packageGroups} = props;

  const renderRemoveButton = useCallback((arrayHelper: FieldArrayRenderProps, index: number) => {
    return (
      <IconButton
        onClick={() => arrayHelper.remove(index)}
        name={IconName.Clear}
        color={ColorPalette.gray48}
        size={16}
        fill={true}
        stroke={false}
        hoverColor={ColorPalette.red7}
      />
    );
  }, []);

  const sortedPackageGroups = sortBySorter<IPackageGroup>(packageSorter, packageGroups);

  const sortedPackages = useCallback(
    (values: IOrderPackage[]) => sortBySorter<IOrderPackage>(packageSorter, values),
    []
  );

  const convertPackageToOption = useCallback((packageOption: IOrderPackage): IDictionaryOptionType => {
    const {id, name} = packageOption;

    return {
      value: id,
      label: name,
    };
  }, []);

  const bundlesGroup: IOrderPackageGroup = useMemo(
    () => ({
      group: 'Bundles',
      values: sortedPackages(orderPackages.filter((x) => x.isBundle)),
    }),
    [orderPackages, sortedPackages]
  );

  const packagesByGroups: IOrderPackageGroup[] = useMemo(() => {
    return sortedPackageGroups.map((group) => ({
      group: group.name,
      values: sortedPackages(orderPackages.filter((pack) => pack.groups.some((x) => x.id === group.id))),
    }));
  }, [orderPackages, sortedPackageGroups, sortedPackages]);

  const otherPackagesGroup: IOrderPackageGroup = useMemo(() => {
    return {
      group: 'Other packages',
      values: sortedPackages(orderPackages.filter((pack) => pack.groups.length === 0)),
    };
  }, [orderPackages, sortedPackages]);

  const options = useMemo(
    () =>
      ([] as IPackageOptionGroup[])
        .concat([{label: bundlesGroup.group, options: bundlesGroup.values.map(convertPackageToOption)}])
        .concat(packagesByGroups.map((x) => ({label: x.group, options: x.values.map(convertPackageToOption)})))
        .concat([{label: otherPackagesGroup.group, options: otherPackagesGroup.values.map(convertPackageToOption)}]),
    [bundlesGroup, convertPackageToOption, otherPackagesGroup, packagesByGroups]
  );

  const uniqueHorses = useMemo(
    () =>
      castToOption(
        horses
          .map((horse) => (tests.find((test) => test.horseId === horse.id) ? {...horse, isInvisible: true} : horse))
          .sort(sortByName)
      ),
    [horses, tests]
  );

  return (
    <div>
      <FieldArray
        name="tests"
        render={(arrayHelper) => (
          <div className="flex-column">
            {tests.map((_, index, arr) => {
              const onSelectPackage = (packages: (string | number)[]) => {
                arrayHelper.form.setFieldValue(`tests[${index}].packages`, packages);
              };

              return (
                <div className="d-flex flex-column" key={index}>
                  <MutableFieldsGroup className="flex-row" indent="30px">
                    <MutableField width={100}>
                      <MutableField width={100}>
                        <SelectField
                          isRequired={true}
                          name={`tests[${index}].horseId`}
                          options={uniqueHorses}
                          label="Horse"
                        />
                      </MutableField>

                      <PackageSelect index={index} options={options} tests={tests} onSelect={onSelectPackage} />
                    </MutableField>
                    {arr.length > 1 && (
                      <MutableField width={5} className="align-self-center" style={{marginTop: 20}}>
                        <div>{renderRemoveButton(arrayHelper, index)}</div>
                      </MutableField>
                    )}
                  </MutableFieldsGroup>

                  <Divider />
                </div>
              );
            })}
            <AddHorseButton type="button" size="small" onClick={() => arrayHelper.push({...initialHorseTest})}>
              Add Horse
            </AddHorseButton>
          </div>
        )}
      />
    </div>
  );
}

export default PackageSection;
