import {IServerPurchasableTest, IServerPurchasableTestRequest} from '../types/purchasableTest';
import {
  IAdminPurchasableTest,
  IAdminPurchasableTestRequest,
} from 'Admin/AdminAssociations/models/IAdminPurchasableTest';
import {EntityStatus} from 'Common/constants/EntityStatus';

export function convertPurchasableTestToClient(value: IServerPurchasableTest): IAdminPurchasableTest {
  return {
    ...value,
    commonPrice: value.commonPrice / 100,
    isEnabled: value.status === EntityStatus.Active,
    isArchived: value.status === EntityStatus.Archived,
    specialPrices: value.specialPrices.map((x) => ({
      price: x.price / 100,
      organizations: x.organizations,
    })),
  };
}

export function convertPurchasableTestToRequest(value: IAdminPurchasableTestRequest): IServerPurchasableTestRequest {
  const dataToSave: Omit<IAdminPurchasableTestRequest, 'isArchived' | 'isEnabled' | 'status' | 'test'> = value;
  delete dataToSave['isArchived'];
  delete dataToSave['isEnabled'];
  delete dataToSave['status'];
  delete dataToSave['test'];

  return {
    ...dataToSave,
    commonPrice: dataToSave.commonPrice * 100,
    specialPrices: dataToSave.specialPrices.map((x) => {
      return {
        price: x.price * 100,
        organizations: x.organizations,
      };
    }),
  };
}
