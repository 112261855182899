export enum Interval {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year'
}

export const mapPriorityByInterval: Record<Interval, number> = {
  [Interval.Day]: 0,
  [Interval.Week]: 1,
  [Interval.Month]: 2,
  [Interval.Year]: 3
};
