import {Reducer} from 'redux';

import {
  AdminAssociationHorseActions,
  getAdminAssociationHorseDetailsActionTypes,
  getAdminAssociationHorseHealthIssuesActionTypes,
  getAdminAssociationHorseMediaActionTypes,
  getAdminAssociationHorseOwnerActionTypes,
  getAdminAssociationHorseParentageActionTypes,
  getAdminAssociationHorseRegistriesActionTypes,
  getAdminAssociationHorsesActionTypes,
  getAdminAssociationHorseSampleActionTypes,
  IAdminAssociationHorseState,
  resetAdminAssociationHorseDetailsActionTypes,
  resetAdminAssociationHorseHealthIssuesActionTypes,
  resetAdminAssociationHorseMediaActionTypes,
  resetAdminAssociationHorseOwnerActionTypes,
  resetAdminAssociationHorseParentageActionTypes,
  resetAdminAssociationHorseRegistriesActionTypes,
  resetAdminAssociationHorsesActionTypes,
  resetAdminAssociationHorseSampleActionTypes,
} from '../types';

const initialState: IAdminAssociationHorseState['data'] = {
  horses: [],
  horseDetails: null,
  horseOwner: null,
  horseParentage: null,
  horseRegistries: null,
  horseHealthIssues: null,
  horseMedia: null,
  horseSample: null,
};

export const dataAdminAssociationHorseReducer: Reducer<
  IAdminAssociationHorseState['data'],
  AdminAssociationHorseActions
> = (state = initialState, action: AdminAssociationHorseActions) => {
  switch (action.type) {
    case getAdminAssociationHorsesActionTypes.SUCCESS: {
      return {...state, horses: action.payload.data};
    }
    case getAdminAssociationHorseDetailsActionTypes.SUCCESS: {
      return {...state, horseDetails: action.payload};
    }
    case getAdminAssociationHorseOwnerActionTypes.SUCCESS: {
      return {...state, horseOwner: action.payload};
    }
    case getAdminAssociationHorseParentageActionTypes.SUCCESS: {
      return {...state, horseParentage: action.payload};
    }
    case getAdminAssociationHorseRegistriesActionTypes.SUCCESS: {
      return {...state, horseRegistries: action.payload};
    }
    case getAdminAssociationHorseHealthIssuesActionTypes.SUCCESS: {
      return {...state, horseHealthIssues: action.payload};
    }
    case getAdminAssociationHorseMediaActionTypes.SUCCESS: {
      return {...state, horseMedia: action.payload};
    }
    case getAdminAssociationHorseSampleActionTypes.SUCCESS: {
      return {...state, horseSample: action.payload};
    }

    case resetAdminAssociationHorseMediaActionTypes.RESET: {
      return {...state, horseMedia: initialState.horseMedia};
    }
    case resetAdminAssociationHorseDetailsActionTypes.RESET: {
      return {...state, horseDetails: initialState.horseDetails};
    }
    case resetAdminAssociationHorseOwnerActionTypes.RESET: {
      return {...state, horseOwner: initialState.horseOwner};
    }
    case resetAdminAssociationHorseParentageActionTypes.RESET: {
      return {...state, horseParentage: initialState.horseParentage};
    }
    case resetAdminAssociationHorseRegistriesActionTypes.RESET: {
      return {...state, horseRegistries: initialState.horseRegistries};
    }
    case resetAdminAssociationHorseHealthIssuesActionTypes.RESET: {
      return {...state, horseHealthIssues: initialState.horseHealthIssues};
    }
    case resetAdminAssociationHorsesActionTypes.RESET: {
      return {...state, horses: initialState.horses};
    }
    case resetAdminAssociationHorseSampleActionTypes.RESET: {
      return {...state, horseSample: initialState.horseSample};
    }

    default: {
      return state;
    }
  }
};
