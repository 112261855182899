import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';
import {IFoalProfile} from 'FoalProfile/models/IFoalProfile';
import {IFoalParent} from 'FoalProfile/models/IFoalParent';
import {Nullable} from 'Common/types';

const STATE_NAME = 'buildFoal';

export interface IBuildFoalState {
  data: {
    foal: Nullable<IFoalProfile>;
    firstParent: Nullable<IFoalParent>;
    secondParent: Nullable<IFoalParent>;
  };
  communications: {
    buildFoalRequesting: ICommunication;
    firstParentLoading: ICommunication;
    secondParentLoading: ICommunication;
  };
}

export const buildFoalRequestActionTypes = makeCommunicationActionType(STATE_NAME, 'BUILD_FOAL_REQUEST');
export type buildFoalRequestActions = MakeCommunicationActions<
  typeof buildFoalRequestActionTypes,
  {success: IFoalProfile}
>;

export const firstParentActionTypes = makeCommunicationActionType(STATE_NAME, 'FIRST_PARENT_REQUEST');
export type firstParentRequestActions = MakeCommunicationActions<typeof firstParentActionTypes, {success: IFoalParent}>;

export const secondParentActionTypes = makeCommunicationActionType(STATE_NAME, 'SECOND_PARENT_REQUEST');
export type secondParentRequestActions = MakeCommunicationActions<
  typeof secondParentActionTypes,
  {success: IFoalParent}
>;

export type BuildFoalActions = buildFoalRequestActions | firstParentRequestActions | secondParentRequestActions;
