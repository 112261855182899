import React, {memo, useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {IAppState} from 'Common/store/IAppState';
import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {Pagination} from 'Common/components/Controls';
import Loading from 'Loading/components/Loading';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminOwners';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {AvatarCell} from 'Admin/AdminDashboard/components/shared';
import Actions from 'Common/components/Actions/Actions';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import usePermissions from 'Permissions/hooks/usePermissions';
import {VisitorType} from 'Common/constants/VisitorType';
import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import {IMenuItemValued} from 'Common/models/IMenuItemValued';
import {AdminAssociationOwnerModule} from 'Admin/AdminAssociations/store/adminOwners/adminAssociationOwnerModule';
import {IAdminAssociationOwner} from 'Admin/AdminAssociations/models/IAdminAssociationOwner';
import {useAdminOwnerModal} from 'Admin/AdminAssociations/helpers/hooks/useAdminOwnerModal';

const CONFIRM_DELETE_OWNER = 'Are you sure you want to remove the owner?';

const iconProps = {color: ColorPalette.red7};

enum OwnerActionValue {
  UpdateOwner = 'updateOwner',
  DeleteOwner = 'deleteOwner',
}

const menuItems: IMenuItemValued<OwnerActionValue>[] = [
  {
    value: OwnerActionValue.UpdateOwner,
    label: 'Edit Owner',
    icon: {name: IconName.Edit, ...iconProps},
  },
  {
    value: OwnerActionValue.DeleteOwner,
    label: 'Delete Owner',
    icon: {name: IconName.Garbage, ...iconProps},
  },
];

const AddButton = styled(PrimaryButton)`
  height: 50px;
  margin-left: 55px;
`;

const TableWrapper = styled.div`
  margin-top: 50px;
`;

type IConnected = ConnectedProps<typeof connector>;

const Owners = (props: IConnected) => {
  const {owners, ownersLoading, getOwners, deleteOwner, ownerDeleting, pagination} = props;

  const {isVisitorType} = usePermissions();

  const {
    params,
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    searchBar,
    deleteModal,
    openDeleteModal,
  } = useCommonAdminPageData<IAdminAssociationOwner>({
    getItems: getOwners,
    searchBarPlaceholder: 'Search for owners by any keyword',
    deleteParams: {communication: ownerDeleting, confirmDescription: CONFIRM_DELETE_OWNER, action: deleteOwner},
  });

  const reloadAdminOwners = useCallback(() => {
    getOwners(params);
  }, [getOwners, params]);

  const {createAdminOwnerModal, updateAdminOwnerModal, openCreateAdminOwnerModal, openUpdateAdminOwnerModal} =
    useAdminOwnerModal({
      onSuccess: reloadAdminOwners,
    });

  const isLoading = ownersLoading.isRequesting;
  const isAdminVisitor = isVisitorType(VisitorType.Admin);
  const isShowPagination = owners.length > 0;

  return (
    <AdminPageLayout>
      {createAdminOwnerModal}
      {updateAdminOwnerModal}
      {deleteModal}
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
        {isAdminVisitor && <AddButton onClick={openCreateAdminOwnerModal}>+ Create Owner</AddButton>}
      </div>
      <TableWrapper className="position-relative">
        {isLoading && <Loading />}
        <Table<IAdminAssociationOwner> data={owners} rowKey="id" sorting={sorting} onChangeSorting={changeSorting}>
          <Cell<IAdminAssociationOwner>
            header="ID"
            dataKey="id"
            render={({id}) => `#${id}`}
            expandOnClick={true}
            width={100}
          />
          <Cell<IAdminAssociationOwner>
            header="Name"
            dataKey="name"
            render={({id, name, avatar}) => (
              <AvatarCell
                type="owner"
                avatarUrl={avatar?.url}
                label={name}
                onLabelClick={() => openUpdateAdminOwnerModal(id)}
                profileUrl={`/admin-associations/user/user-profile/${id}`}
              />
            )}
            width="20%"
          />
          <Cell<IAdminAssociationOwner> header="Email" dataKey="email" render={({email}) => email} width="20%" />
          <Cell<IAdminAssociationOwner> header="Phone" dataKey="phone" render={({phone}) => phone} width="20%" />
          <Cell<IAdminAssociationOwner>
            header="Horses count"
            dataKey="countHorses"
            render={({countHorses}) => countHorses}
            width="10%"
          />
          <Cell<IAdminAssociationOwner>
            header="Organization"
            dataKey="organization"
            render={({organization}) => {
              const abbreviation = organization?.abbreviation ? ` (${organization.abbreviation})` : '';
              return (
                <>
                  {organization?.name}
                  {abbreviation}
                </>
              );
            }}
            width="20%"
          />

          <Cell<IAdminAssociationOwner>
            header="Actions"
            align={CellAlign.Right}
            width={100}
            render={(adminOwner) => (
              <Actions<OwnerActionValue>
                menuItems={menuItems}
                updateOwner={() => openUpdateAdminOwnerModal(adminOwner.id)}
                deleteOwner={() => openDeleteModal(adminOwner.id)}
              />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </TableWrapper>
    </AdminPageLayout>
  );
};

const mapStateToProps = (state: IAppState) => ({
  owners: selectors.selectOwners(state),
  ownersLoading: selectors.selectCommunication(state, 'ownersLoading'),
  ownerDeleting: selectors.selectCommunication(state, 'ownerDeleting'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getOwners: actions.getOwners,
  deleteOwner: actions.deleteOwner,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(Owners));
export default withDynamicModules(Connected, AdminAssociationOwnerModule);
