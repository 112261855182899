import {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import OrderHorses from 'Admin/AdminDashboard/components/Orders/OrderHorses/OrderHorses';
import OrderInfoHorseHeader from 'Admin/AdminDashboard/components/Orders/OrderInfoModal/OrderInfoHeader/OrderInfoHeader/OrderInfoHeader';
import withOrderDetailsActions, {
  IWithOrderDetailsActionsProps,
} from 'Admin/AdminDashboard/helpers/withOrderDetailsActions';
import {IOrderHorseDetailsAdmin} from 'Admin/AdminDashboard/models/Order/IOrderHorseDetailsAdmin';
import {AdminOrdersModule} from 'Admin/AdminDashboard/store/adminOrders/adminOrdersModule';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminOrders/index';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import {ITableProps} from 'Common/components/Table/Table';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import withDate from 'Common/helpers/withDate';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';

const tableProps: Partial<ITableProps<IOrderHorseDetailsAdmin>> = {
  expandedRowStyles: {
    toggle: {backgroundColor: 'transparent'},
    expandedRow: {boxShadow: 'none'},
    content: {paddingLeft: '20px'},
  },
};

const OrderInfoModalRoot = styled.div`
  padding: 24px 24px 32px;
  font-family: ${Typography.family.openSans};
`;

const Header = styled.div`
  padding-bottom: 18px;
  margin-bottom: 24px;
  border-bottom: solid 1px ${ColorPalette.gray10};
  font-size: ${Typography.size.size24};
  line-height: 33px;
  text-align: center;
`;

interface IOwnProps {
  orderId: number;
}

interface IOwnProps {
  isOpen: boolean;
  orderId: number;
  onClose(): void;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IOwnProps & IWithOrderDetailsActionsProps;

function OrderInfoModal(props: Props) {
  const {
    isOpen,
    orderId,
    getOrderDetails,
    orderDetails,
    orderDetailsLoading,
    onClose,
    onSuccess,
    getOrderStatusesDetails,
    orderStatusesDetails,
    orderStatusesDetailsLoading,
    resetOrderStatusDetails,
  } = props;

  useEffect(() => {
    return () => resetOrderStatusDetails();
  }, [resetOrderStatusDetails]);

  useEffect(() => {
    getOrderDetails(orderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!orderDetails) {
      return;
    }

    const fetch = async (orderId: number, horseId: number) => {
      await getOrderStatusesDetails(orderId, horseId);
    };

    orderDetails.horsesDetails.forEach((horseDetail) => {
      fetch(orderId, horseDetail.id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderStatusesDetails, orderDetails]);

  return (
    <ModalWindow isOpen={isOpen} onClose={onClose} maxWidth="80%">
      <Scrollbar minHeight={250} maxHeight="calc(100vh - 64px)">
        <OrderInfoModalRoot>
          {(() => {
            if (orderDetailsLoading.error) {
              return <span>Error on order details loading</span>;
            }

            if (!orderDetails || orderDetailsLoading.isRequesting || orderStatusesDetailsLoading.isRequesting) {
              return <Loading />;
            }

            const {horsesDetails, paymentDetails} = orderDetails!;
            const createDate = withDate(orderDetails.createDate) || 'invalid date';

            const headerProps = {
              horsesDetails,
              orderDetails,
              onSuccess: onSuccess,
              horseStatuses: orderStatusesDetails,
              statuses: orderStatusesDetails.length > 0 && orderStatusesDetails[0].statuses,
            };

            return (
              <>
                <Header>Order info</Header>
                <OrderInfoHorseHeader {...headerProps} />
                <OrderHorses
                  orderId={orderId}
                  createDate={createDate}
                  owner={orderDetails?.owner.name}
                  priceDetails={paymentDetails}
                  horsesDetails={horsesDetails}
                  tableProps={tableProps}
                />
              </>
            );
          })()}
        </OrderInfoModalRoot>
      </Scrollbar>
    </ModalWindow>
  );
}

const mapStateToProps = (state: IAppState) => ({
  orderDetails: selectors.selectOrderDetails(state),
  orderDetailsLoading: selectors.selectCommunication(state, 'orderDetailsLoading'),
});

const mapDispatchToProps = {
  getOrderDetails: actions.getOrderDetails,
  resetOrderStatusDetails: actions.resetOrderStatusDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(OrderInfoModal));
export default withDynamicModules(withOrderDetailsActions(Connected), AdminOrdersModule);
