import {IModule} from 'redux-dynamic-modules';

import {IFavoriteHorseState} from './types';
import {favoriteHorsesReducer} from './reducer/index';

export interface IFavoriteHorseModuleState {
  favoriteHorses: IFavoriteHorseState;
}

export const FavoriteHorseModule: IModule<IFavoriteHorseModuleState> = {
  id: 'favoriteHorse',
  reducerMap: {
    favoriteHorses: favoriteHorsesReducer,
  },
};
