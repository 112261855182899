import React, {memo} from 'react';
import {Helmet} from 'react-helmet';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import AdminTabs from 'Admin/common/AdminTabs';
import AdminContent from 'Admin/common/AdminContent';
import {ITab} from 'Common/components/Tabs/ITab';
import Genes from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/Genes/Genes';
import GeneGroups from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/GeneGroups/GeneGroups';
import Tests from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/Tests/Tests';
import Traits from './Traits/Traits';

const basePath = '/admin-phenotypes';

const adminData: ITab[] = [
  {section: 'genes', title: 'Genes', Component: Genes},
  {section: 'gene-groups', title: 'Gene Groups', Component: GeneGroups},

  {section: 'tests', title: 'Tests', Component: Tests},
  {section: 'traits', title: 'Traits', Component: Traits},
];

function AdminPhenotypes() {
  return (
    <BaseLayout withoutPaddings={true} widthByContent={true} isBackButtonDenied={true}>
      <Helmet>
        <title>Phenotypes</title>
      </Helmet>

      <AdminTabs tabs={adminData} basePath={basePath} isSort={true} />
      <AdminContent tabs={adminData} basePath={basePath} isSort={true} />
    </BaseLayout>
  );
}

export default memo(AdminPhenotypes);
