import {IAppState} from 'Common/store/IAppState';
import {ICommunication} from 'Common/store/utils/communication';
import {ISimpleDictionary} from 'DictionaryFactory/types';

export interface IFacetSelectors<T> {
  items: T[];
  itemsLoading: ICommunication;
}

export function makeFacetSelectors<T>(state: IAppState, dictionary: ISimpleDictionary<T>): IFacetSelectors<T> {
  const {selectors} = dictionary;
  return {
    items: selectors.selectItems(state),
    itemsLoading: selectors.selectCommunication(state, 'itemsLoading'),
  };
}
