import {action} from 'typesafe-actions';

import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {
  changeReviewStatusActions,
  changeReviewStatusActionTypes,
  deleteGeneticNoteActions,
  deleteGeneticNoteActionTypes,
  getAbilitiesActions,
  getAbilitiesActionTypes,
  getAbilityDetailedActions,
  getAbilityDetailedActionTypes,
  getCoatColorActions,
  getCoatColorActionTypes,
  getCoatColorDetailedActions,
  getCoatColorDetailedActionTypes,
  getGeneticVariantsActions,
  getGeneticVariantsActionTypes,
  getHealthVariantDetailedActions,
  getHealthVariantDetailedActionTypes,
  getHealthVariantsActions,
  getHealthVariantsActionTypes,
  getHorseBreedPanelsActions,
  getHorseBreedPanelsActionTypes,
  getHorseDetailsActions,
  getHorseDetailsActionTypes,
  getHorseInfoActions,
  getHorseInfoActionTypes,
  getHorseOwnerActions,
  getHorseOwnerActionTypes,
  getRequiredTestsActions,
  getRequiredTestsActionTypes,
  getReviewSummaryAbilitiesActions,
  getReviewSummaryAbilitiesActionTypes,
  getReviewSummaryColorsActions,
  getReviewSummaryColorsActionTypes,
  getReviewSummaryHealthIssuesActions,
  getReviewSummaryHealthIssuesActionTypes,
  getSummaryAbilitiesActions,
  getSummaryAbilitiesActionTypes,
  getSummaryColorsActions,
  getSummaryColorsActionTypes,
  getSummaryHealthIssuesActions,
  getSummaryHealthIssuesActionTypes,
  resetOnlineReportDataActions,
  resetOnlineReportDataActionTypes,
  resetRequiredTestsActions,
  resetRequiredTestsActionTypes,
  updateGeneticNoteActions,
  updateGeneticNoteActionTypes,
} from './types';
import OnlineReportsService from 'Admin/AdminDashboard/services/OnlineReportsService';
import {IUpdateGeneticNote} from 'Admin/AdminDashboard/models/IUpdateGeneticNote';
import {IRequestChangeReviewStatus} from 'Admin/AdminDashboard/store/adminOrders/types';
import {IGeneticNote} from 'OnlineReport/models/Summary/IGeneticNote';

export const getHorseInfo =
  (horseId: number): ActionResult<IAppState, void, getHorseInfoActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseInfoActionTypes.REQUEST));
      const res = await OnlineReportsService.getHorseInfo(horseId);
      dispatch(action(getHorseInfoActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHorseInfoActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseOwner =
  (horseId: number): ActionResult<IAppState, void, getHorseOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseOwnerActionTypes.REQUEST));
      const res = await OnlineReportsService.getHorseOwner(horseId);
      dispatch(action(getHorseOwnerActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHorseOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseDetails =
  (horseId: number): ActionResult<IAppState, void, getHorseDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseDetailsActionTypes.REQUEST));
      const res = await OnlineReportsService.getHorseDetails(horseId);
      dispatch(action(getHorseDetailsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHorseDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getSummaryAbilities =
  (horseId: number, orderId?: number): ActionResult<IAppState, void, getSummaryAbilitiesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getSummaryAbilitiesActionTypes.REQUEST));
      const res = await OnlineReportsService.getSummaryAbilities(horseId, orderId);
      dispatch(action(getSummaryAbilitiesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getSummaryAbilitiesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getSummaryColors =
  (horseId: number, orderId?: number): ActionResult<IAppState, void, getSummaryColorsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getSummaryColorsActionTypes.REQUEST));
      const res = await OnlineReportsService.getSummaryColors(horseId, orderId);
      dispatch(action(getSummaryColorsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getSummaryColorsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getSummaryHealthIssues =
  (horseId: number, orderId?: number): ActionResult<IAppState, void, getSummaryHealthIssuesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getSummaryHealthIssuesActionTypes.REQUEST));
      const res = await OnlineReportsService.getSummaryHealthIssues(horseId, orderId);
      dispatch(action(getSummaryHealthIssuesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getSummaryHealthIssuesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getRequiredTests =
  (orderId: number, horseId: number): ActionResult<IAppState, void, getRequiredTestsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getRequiredTestsActionTypes.REQUEST));
      const res = await OnlineReportsService.getRequiredTests(orderId, horseId);
      dispatch(action(getRequiredTestsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getRequiredTestsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getGeneticVariants =
  (horseId: number, orderId?: number): ActionResult<IAppState, void, getGeneticVariantsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getGeneticVariantsActionTypes.REQUEST));
      const res = await OnlineReportsService.getGeneticVariants(horseId, orderId);
      dispatch(action(getGeneticVariantsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getGeneticVariantsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getReviewSummaryAbilities =
  (horseId: number, orderId: number): ActionResult<IAppState, void, getReviewSummaryAbilitiesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getReviewSummaryAbilitiesActionTypes.REQUEST));
      const res = await OnlineReportsService.getReviewSummaryAbilities(horseId, orderId);
      dispatch(action(getReviewSummaryAbilitiesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getReviewSummaryAbilitiesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getReviewSummaryColors =
  (horseId: number, orderId: number): ActionResult<IAppState, void, getReviewSummaryColorsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getReviewSummaryColorsActionTypes.REQUEST));
      const res = await OnlineReportsService.getReviewSummaryColors(horseId, orderId);
      dispatch(action(getReviewSummaryColorsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getReviewSummaryColorsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getReviewSummaryHealthIssues =
  (horseId: number, orderId: number): ActionResult<IAppState, void, getReviewSummaryHealthIssuesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getReviewSummaryHealthIssuesActionTypes.REQUEST));
      const res = await OnlineReportsService.getReviewSummaryHealthIssues(horseId, orderId);
      dispatch(action(getReviewSummaryHealthIssuesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getReviewSummaryHealthIssuesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateGeneticNote =
  (data: IUpdateGeneticNote): ActionResult<IAppState, void, updateGeneticNoteActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateGeneticNoteActionTypes.REQUEST));
      const res = await OnlineReportsService.updateGeneticNote(data);
      dispatch(action(updateGeneticNoteActionTypes.SUCCESS, {...data, id: res}));
    } catch (error) {
      dispatch(action(updateGeneticNoteActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteGeneticNote =
  (note: IGeneticNote): ActionResult<IAppState, void, deleteGeneticNoteActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteGeneticNoteActionTypes.REQUEST));
      await OnlineReportsService.deleteGeneticNote(note.id);
      dispatch(action(deleteGeneticNoteActionTypes.SUCCESS, note));
    } catch (error) {
      dispatch(action(deleteGeneticNoteActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const changeReviewStatus =
  (data: IRequestChangeReviewStatus): ActionResult<IAppState, void, changeReviewStatusActions> =>
  async (dispatch) => {
    try {
      dispatch(action(changeReviewStatusActionTypes.REQUEST));
      await OnlineReportsService.changeReviewStatus(data);
      dispatch(action(changeReviewStatusActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(changeReviewStatusActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetRequiredTestsResults =
  (): ActionResult<IAppState, void, resetRequiredTestsActions> => async (dispatch) => {
    dispatch(action(resetRequiredTestsActionTypes.RESET));
  };

export const getCoatColor =
  (horseId: number, orderId?: number): ActionResult<IAppState, void, getCoatColorActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getCoatColorActionTypes.REQUEST));
      const res = await OnlineReportsService.getCoatColor(horseId, orderId);
      dispatch(action(getCoatColorActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getCoatColorActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getCoatColorDetailed =
  (colorId: number): ActionResult<IAppState, void, getCoatColorDetailedActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getCoatColorDetailedActionTypes.REQUEST));
      const res = await OnlineReportsService.getCoatColorDetailed(colorId);
      dispatch(action(getCoatColorDetailedActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getCoatColorDetailedActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHealthVariants =
  (horseId: number, orderId?: number): ActionResult<IAppState, void, getHealthVariantsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHealthVariantsActionTypes.REQUEST));
      const res = await OnlineReportsService.getHealthVariants(horseId, orderId);
      dispatch(action(getHealthVariantsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHealthVariantsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHealthVariantDetailed =
  (
    healthIssueId: number,
    isAggregated: boolean = false
  ): ActionResult<IAppState, void, getHealthVariantDetailedActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHealthVariantDetailedActionTypes.REQUEST));
      const res = await OnlineReportsService.getHealthVariantDetailed(healthIssueId, isAggregated);
      dispatch(action(getHealthVariantDetailedActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHealthVariantDetailedActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAbilities =
  (horseId: number, orderId?: number): ActionResult<IAppState, void, getAbilitiesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAbilitiesActionTypes.REQUEST));
      const res = await OnlineReportsService.getAbilities(horseId, orderId);
      dispatch(action(getAbilitiesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAbilitiesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAbilityDetailed =
  (abilityId: number, isAggregated: boolean = false): ActionResult<IAppState, void, getAbilityDetailedActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAbilityDetailedActionTypes.REQUEST));
      const res = await OnlineReportsService.getAbilityDetailed(abilityId, isAggregated);
      dispatch(action(getAbilityDetailedActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAbilityDetailedActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseBreedPanels =
  (horseId: number, orderId?: number): ActionResult<IAppState, void, getHorseBreedPanelsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseBreedPanelsActionTypes.REQUEST));
      const result = await OnlineReportsService.getHorseBreedPanel(horseId, orderId);
      dispatch(action(getHorseBreedPanelsActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getHorseBreedPanelsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetOnlineReportResults =
  (): ActionResult<IAppState, void, resetOnlineReportDataActions> => async (dispatch) => {
    dispatch(action(resetOnlineReportDataActionTypes.RESET));
  };
