import React, {memo} from 'react';
import styled from 'styled-components';
import {Instance} from 'tippy.js';

import Tooltip, {TooltipType} from 'Common/components/Tooltip/Tooltip';
import {copyToClipboard} from 'Common/helpers/copyToClipboard';

export const BlockedCopyZone = styled.div``;
BlockedCopyZone.displayName = 'BlockedCopyZone';

const CopyWrapper = styled.div`
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

interface IProps {
  textToCopy: string;
  successfulMessage?: string;
  className?: string;
}

const CopyZone = (props: React.PropsWithChildren<IProps>) => {
  const {textToCopy, successfulMessage, children, className} = props;

  const handleOnCopy = () => {
    copyToClipboard(textToCopy);
  };

  const handleOnShow = (instance: Instance) => {
    setTimeout(() => {
      instance.hide();
    }, 1500);
  };

  return (
    <>
      {React.Children.map(children, (element: any) =>
        element?.type?.displayName === 'BlockedCopyZone' ? (
          element
        ) : (
          <Tooltip content={successfulMessage} tooltipType={TooltipType.Alert} trigger="click" onShow={handleOnShow}>
            <CopyWrapper className={className} onClick={handleOnCopy}>
              {element}
            </CopyWrapper>
          </Tooltip>
        )
      )}
    </>
  );
};

export default memo(CopyZone);
