import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';

import {InputField, SelectField, TextAreaField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {castToOption} from 'Common/helpers/OptionHelper';
import {RequiredBy} from 'Common/types';
import {Combination} from 'Common/constants/Combination';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {IAdminDangerAdvanced} from 'Admin/AdminPhenotypes/models/IAdminDangerAdvanced';
import {IUpdateDangerAdvancedRequest} from 'Admin/AdminPhenotypes/service/models/IServerDangerAdvanced';
import {IDangerGroupAdvanced} from 'Dictionaries/models/IDangerGroupAdvanced';

import {
  convertToServer,
  convertToServerUpdating,
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/DangersAdvanced/DangerAdvancedForm/validation';
import {useTraitSequenceEditor} from 'SequenceEditor/hooks/useTraitSequenceEditor';
import {ITraitSequence} from 'Admin/AdminPhenotypes/models/ITraitSequence';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

interface IOwnProps extends IPhenotypeFormExternalProps<IAdminDangerAdvanced, IUpdateDangerAdvancedRequest> {
  dangerGroupDictionary: ISimpleDictionary<IDangerGroupAdvanced>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function DangerAdvancedForm(props: Props) {
  const {itemId, item, dictionary, dangerGroupDictionary, dangerGroup, dangerGroupLoading, ...rest} = props;
  const {setFieldValue, values} = props;
  const {getItem} = dictionary.actions;
  const {
    actions: {getItems: getDangerGroups},
  } = dangerGroupDictionary;

  useEffect(() => {
    getDangerGroups();
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem, getDangerGroups]);

  const onSuccessSequenceEditor = useCallback(
    (type: Combination, sequences: ITraitSequence[]) => {
      setFieldValue('type', type);
      setFieldValue('sequences', sequences);
    },
    [setFieldValue]
  );

  const {renderRuleField, renderSequenceEditorModal} = useTraitSequenceEditor(
    values.type,
    values?.sequences ?? [],
    onSuccessSequenceEditor
  );

  return (
    <DictionaryForm {...rest} itemPreloading={dangerGroupLoading.isRequesting}>
      {renderSequenceEditorModal()}

      <InputField name="name" label="Name" placeholder="Name" />
      <SelectField name="groupId" label="Group" options={castToOption(dangerGroup)} />
      <TextAreaField name="description.value" label="Description" placeholder="Description" />

      {renderRuleField()}
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  const {dangerGroupDictionary} = ownProps;

  return {
    dangerGroup: dangerGroupDictionary.selectors.selectItems(state),
    dangerGroupLoading: dangerGroupDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    ...makePhenotypeFormSelectors(state, ownProps.dictionary),
  };
};

const DangerAdvancedFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting ? {...item, groupId: item.group?.id || null} : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(DangerAdvancedForm);

const connector = connect(mapStateToProps);
export default connector(memo(DangerAdvancedFormFormik));
