import React, {useEffect, useRef} from 'react';
import styled, {FlattenSimpleInterpolation} from 'styled-components';
import classnames from 'classnames';

import ColorPalette from 'Common/constants/ColorPalette';
import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import markSvg from 'Icon/checkbox-mark.svg';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';

const CheckboxRoot = styled.div`
  .custom-control-input ~ .custom-control-label::before {
    border: 1px solid ${ColorPalette.black1};
    border-radius: 2px;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: ${Theme.color.primary};
    border: 0px solid ${Theme.color.primary};
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0px 0px 0px 2px ${Theme.color.control.shadow};
    border: 1px solid ${ColorPalette.black1};
  }
  .custom-control-input:focus:checked ~ .custom-control-label::before {
    box-shadow: 0px 0px 0px 2px ${Theme.color.control.shadow};
    border: none;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url(${markSvg}) !important;
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    pointer-events: none;
    background-color: ${Theme.color.control.shadow};
    border: 0px solid ${Theme.color.control.shadow};
  }
  .custom-control-input:disabled ~ .custom-control-label::before {
    pointer-events: none;
    background-color: ${Theme.color.white};
    border-color: ${Theme.color.control.disabled};
  }
  .custom-control-input:indeterminate ~ .custom-control-label::before {
    background-color: ${Theme.color.primary};
    border: 0px solid ${Theme.color.primary};
  }
`;

export interface IStyledLabelProps {
  styledCss?: FlattenSimpleInterpolation;
}

const StyledLabel = styled.div<IStyledLabelProps>`
  ::before,
  ::after {
    top: -0.05rem;
    width: 1.125rem;
    height: 1.125rem;
  }
  padding-left: 8px;
  font-size: ${Typography.size.size14};
  line-height: ${Typography.size.size16};
  font-family: ${Theme.font.primary};
  ${(p: IStyledLabelProps) => p.styledCss};
`;

interface IOwnProps {
  defaultChecked?: boolean;
  checked?: boolean;
  labelStyledCss?: FlattenSimpleInterpolation;
  tooltip?: string;
  indeterminate?: boolean;
}

type Props = Omit<IBaseControlProps, 'name'> & IOwnProps & React.InputHTMLAttributes<HTMLInputElement>;

function Checkbox(props: Props) {
  const {
    className,
    style,
    inputStyle,
    labelStyledCss,
    inputClassName,
    labelClassName,
    labelStyle,
    indeterminate,
    ...other
  } = props;

  const checkRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkRef.current) {
      checkRef.current.indeterminate = !!indeterminate;
    }
  }, [indeterminate]);

  return (
    <CheckboxRoot className={classnames('custom-control', 'custom-checkbox', className)} style={style}>
      <Tooltip content={props.tooltip}>
        <label>
          <input
            {...other}
            type="checkbox"
            className={classnames('form-control', 'custom-control-input', inputClassName)}
            style={inputStyle}
            ref={checkRef}
          />
          <StyledLabel
            className={classnames('custom-control-label', labelClassName)}
            style={labelStyle}
            styledCss={labelStyledCss}
          >
            {props.label}
          </StyledLabel>
        </label>
      </Tooltip>
    </CheckboxRoot>
  );
}

export default Checkbox;
export type CheckboxProps = Props;
