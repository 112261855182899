import {IModule} from 'redux-dynamic-modules';

import {IBusinessPortalHorseProfileState} from 'BusinessPortal/store/horseProfile/types';
import {adminAssociationHorseProfileReducer} from 'BusinessPortal/store/horseProfile/reducer';

export interface IBusinessPortalHorseProfileModuleState {
  businessPortalHorseProfile: IBusinessPortalHorseProfileState;
}

export const BusinessPortalHorseProfileModule: IModule<IBusinessPortalHorseProfileModuleState> = {
  id: 'businessPortalHorseProfile',
  reducerMap: {
    businessPortalHorseProfile: adminAssociationHorseProfileReducer,
  },
};
