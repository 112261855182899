import {HtmlTemplateType} from 'Admin/AdminSettings/constants/HtmlTemplateType';
import {IUploadHtmlTemplateRequest} from 'Admin/AdminSettings/models/IUploadHtmlTemplateRequest';
import * as Yup from 'yup';

export interface IFormValues {
  name?: string;
  type?: HtmlTemplateType;
  uploadedFile?: File;
}

export const initialValue: IFormValues = {
  name: '',
  type: undefined,
  uploadedFile: undefined
};

export const validationSchema = Yup.object().shape<IFormValues>({
  name: Yup.string(),
  type: Yup.mixed<HtmlTemplateType>().required('Template type is required'),
  uploadedFile: Yup.mixed<File>().required('Template file is required')
});

export function convertFormValues(values: IFormValues): IUploadHtmlTemplateRequest {
  const {uploadedFile, type, name} = values;

  return {
    name: name || '',
    type: type!,
    uploadedFile: uploadedFile!
  };
}
