import React, {memo, useCallback} from 'react';

import withField from './withField';
import ValidationError from './ValidationError';
import {IFieldProps} from 'Common/models/IFieldProps';
import TabSelect, {IProps as TabSelectProps} from 'Common/components/Controls/TabSelect/TabSelect';

type Props = Omit<TabSelectProps, 'onSelect' | 'selectedValue'> & IFieldProps<number | string>;

function TabSelectField(props: Props) {
  const {
    field: {name, value},
    form,
    ...other
  } = props;

  const onSelect = useCallback(
    (value: number | string) => {
      form.setFieldValue(name, value);
    },
    [form, name]
  );

  return (
    <div>
      <TabSelect {...other} selectedValue={value} onSelect={onSelect} />
      <ValidationError name={name} />
    </div>
  );
}

export default memo(withField(TabSelectField));
