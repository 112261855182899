import {combineReducers} from 'redux';

import {IAdminEmployeeState} from '../types';
import {dataAdminEmployeesReducer} from './data';
import {communicationAdminEmployeesReducer} from './communications';
import {uiAdminEmployeesReducer} from './ui';

export const adminEmployeesReducer = combineReducers<IAdminEmployeeState>({
  communications: communicationAdminEmployeesReducer,
  data: dataAdminEmployeesReducer,
  ui: uiAdminEmployeesReducer,
});
