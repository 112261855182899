import React, {memo, useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useHistory} from 'react-router';

import {IAppState} from 'Common/store/IAppState';
import {Pagination} from 'Common/components/Controls';
import {Cell, Table} from 'Common/components/Table/Table';
import Loading from 'Loading/components/Loading';
import {actions, BusinessPortalHorseModule, selectors} from 'BusinessPortal/store/horse';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import AvatarCellHorse from 'Admin/AdminDashboard/components/Orders/shared/AvatarCellHorse';
import {IWithHorseActionsProps} from 'Admin/AdminDashboard/helpers/withHorseActions';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IHorseBP} from 'BusinessPortal/models/horse/IHorseBP';
import {
  BusinessPortalCellContent,
  BusinessPortalHeaderCell,
  BusinessPortalSearch,
  BusinessPortalTableWrapper,
} from 'BusinessPortal/components/common/BusinessPortalTable';
import Typography from 'Common/constants/Typography';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {useHorseFormSlidePanel} from 'BusinessPortal/helpers/hooks/useHorseFormSlidePanel';
import {searchBarStyle} from 'BusinessPortal/components/common/styled';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useOwnerFormSlidePanel} from 'BusinessPortal/helpers/hooks/useOwnerFormSlidePanel';
import EmptyState, {EmptyStateType} from '../shared/EmptyState/EmptyState';
import Actions from 'Common/components/Actions/Actions';
import withFavoriteHorseActions, {
  IWithFavoriteHorseActionsProps,
  getWithFavoriteHorseActionsModules,
} from 'Common/helpers/withFavoriteHorseActions';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {getMenuItems, HorsesActionValue} from './HorsesAction';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const CONFIRM_DELETE_HORSE = 'Are you sure you want to remove the horse?';

type IConnected = ConnectedProps<typeof connector>;
type HorsesProps = IConnected & IWithHorseActionsProps & IWithFavoriteHorseActionsProps;

function Horses(props: HorsesProps) {
  const {
    horses,
    getHorses,
    pagination,
    horsesLoading: {isRequesting},
    horsesLoading,
    resetHorses,
    horseDeleting,
    deleteHorse,
    addFavoriteHorse,
    favoriteHorseAdding,
    deleteFavoriteHorse,
    favoriteHorseDeleting,
  } = props;

  const [isNoData, setIsNoData] = useState<boolean>(false);

  const {
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    params,
    setSelectedId,
    selectedId,
    searchBar,
    searchQuery,
    openDeleteModal,
    deleteModal,
    isDeleteModalOpen,
  } = useCommonAdminPageData<IHorseBP>({
    getItems: getHorses,
    searchBarPlaceholder: 'Search for horses by any keyword',
    searchBarStyle: searchBarStyle,
    deleteParams: {communication: horseDeleting, confirmDescription: CONFIRM_DELETE_HORSE, action: deleteHorse},
  });

  const history = useHistory();
  const {isMobile, isTablet} = useMediaQuery();

  useEffect(() => {
    return () => resetHorses();
  }, [resetHorses]);

  const updateHorses = useCallback(() => {
    getHorses(params);
  }, [getHorses, params]);

  useOnSuccessCommunication(horsesLoading, () => {
    if (horses.length < 1 && !searchQuery) {
      setIsNoData(true);
      return;
    }
    setIsNoData(false);
  });

  const hasHorses = horses.length > 0 && !isRequesting;

  const {horseFormSlidePanel, openHorseFormSlidePanel, closeHorseFormSlidePanel} = useHorseFormSlidePanel({
    onSuccessForm: updateHorses,
    shadow: true,
    transition: true,
  });

  const {ownerFormSlidePanel, openOwnerFormSlidePanel, closeOwnerFormSlidePanel} = useOwnerFormSlidePanel({
    onSuccessForm: updateHorses,
    shadow: true,
    transition: true,
  });

  const closeAllSlidePanels = useCallback(() => {
    setSelectedId(null);
    closeHorseFormSlidePanel();
    closeOwnerFormSlidePanel();
  }, [closeHorseFormSlidePanel, closeOwnerFormSlidePanel, setSelectedId]);

  const openHorseFormSlidePanelHandler = useCallback(
    (horseId?: number) => {
      closeAllSlidePanels();
      openHorseFormSlidePanel(horseId);
      setSelectedId(horseId || null);
    },
    [closeAllSlidePanels, openHorseFormSlidePanel, setSelectedId]
  );

  const openOwnerFormSlidePanelHandler = useCallback(
    (ownerId: number) => {
      closeAllSlidePanels();
      openOwnerFormSlidePanel(ownerId);
    },
    [closeAllSlidePanels, openOwnerFormSlidePanel]
  );

  const redirectToCreateOrderPage = useCallback(
    (id: number) => history.push(`/business-portal/order-tests/${id}`),
    [history]
  );

  useEffect(() => {
    if (selectedId && !isDeleteModalOpen) {
      openHorseFormSlidePanelHandler(selectedId);
    }
  }, [isDeleteModalOpen, openHorseFormSlidePanelHandler, selectedId]);

  useErrorCommunicationToToast(favoriteHorseAdding, undefined, 1);
  useErrorCommunicationToToast(favoriteHorseDeleting, undefined, 2);

  const onAddFavoriteHorseHandler = useCallback(
    async (id: number) => {
      await addFavoriteHorse(id);
      updateHorses();
    },
    [addFavoriteHorse, updateHorses]
  );

  const onDeleteFavoriteHorseHandler = useCallback(
    async (id: number) => {
      await deleteFavoriteHorse(id);
      updateHorses();
    },
    [deleteFavoriteHorse, updateHorses]
  );

  return (
    <>
      {horseFormSlidePanel}
      {ownerFormSlidePanel}
      {deleteModal}
      <BusinessPortalSearch>
        {searchBar}
        {hasHorses && (
          <PrimaryButton onClick={() => openHorseFormSlidePanelHandler()} size="small">
            + Add a horse
          </PrimaryButton>
        )}
      </BusinessPortalSearch>
      <BusinessPortalTableWrapper>
        {isRequesting && <Loading />}
        <>
          <Table<IHorseBP>
            data={horses}
            rowKey="id"
            sorting={sorting}
            onChangeSorting={changeSorting}
            expandable={false}
            disableRow={() => false}
            customHeaderCell={BusinessPortalHeaderCell}
            // not needed for expanded styles but also affect models row
            expandedRowStyles={{expandedRow: {height: 'auto'}}}
            tableStyles={{fontSize: Typography.size.size16, fontFamily: Typography.family.ubuntu}}
            isMobile={isMobile || isTablet}
          >
            <Cell<IHorseBP>
              header="Horse"
              dataKey="name"
              sortable={false}
              render={({name, id, avatar, isArchived, isDeleted}) => (
                <BusinessPortalCellContent>
                  <AvatarCellHorse
                    type="horse"
                    label={name}
                    avatarUrl={avatar?.url}
                    isArchived={isArchived}
                    isDeleted={isDeleted}
                    isGeneticDataHidden={false}
                    profileUrl={`/business-portal/horse/${id}`}
                    onLabelClick={() => openHorseFormSlidePanelHandler(id)}
                  />
                </BusinessPortalCellContent>
              )}
              width="30%"
            />
            <Cell<IHorseBP>
              header="Owner"
              dataKey="owner"
              sortable={false}
              render={({owner: {id, name, avatar}}) => (
                <BusinessPortalCellContent>
                  <AvatarCellHorse
                    type="owner"
                    label={name}
                    avatarUrl={avatar?.url}
                    profileUrl={`/business-portal/user/association-owner-profile/${id}`}
                    isArchived={false}
                    isDeleted={false}
                    isGeneticDataHidden={false}
                    onLabelClick={() => openOwnerFormSlidePanelHandler(id)}
                  />
                </BusinessPortalCellContent>
              )}
              width="30%"
            />
            <Cell<IHorseBP>
              header="Registry number"
              dataKey="registryNumber"
              sortable={false}
              render={({registryNumber}) => <BusinessPortalCellContent>{registryNumber}</BusinessPortalCellContent>}
              width="18%"
            />
            <Cell<IHorseBP>
              header="Actions"
              sortable={false}
              align={CellAlign.Right}
              width="7%"
              render={(horse) => (
                <Actions<HorsesActionValue>
                  menuItems={getMenuItems(horse)}
                  addFavorite={() => onAddFavoriteHorseHandler(horse.id)}
                  deleteFavorite={() => onDeleteFavoriteHorseHandler(horse.id)}
                  createOrder={() => redirectToCreateOrderPage(horse.id)}
                  editHorse={() => openHorseFormSlidePanelHandler(horse.id)}
                  deleteHorse={() => openDeleteModal(horse.id)}
                />
              )}
            />
          </Table>

          {hasHorses && (
            <Pagination
              pagination={pagination}
              onPageSelect={handlePageSelect}
              onChangeCountPerPage={handleCountPerPage}
            />
          )}

          {isNoData && <EmptyState type={EmptyStateType.Horse} onClick={openHorseFormSlidePanelHandler} />}
        </>
      </BusinessPortalTableWrapper>
    </>
  );
}

const mapStateToProps = (state: IAppState) => ({
  pagination: selectors.selectPagination(state),
  horses: selectors.selectHorses(state),
  horsesLoading: selectors.selectCommunication(state, 'horsesLoading'),
  horseDeleting: selectors.selectCommunication(state, 'horseDeleting'),
});

const mapDispatchToProps = {
  getHorses: actions.getHorses,
  deleteHorse: actions.deleteHorse,
  resetHorses: actions.resetHorses,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const Connected = withFavoriteHorseActions(connector(memo(Horses)));

export default withDynamicModules(Connected, [BusinessPortalHorseModule, ...getWithFavoriteHorseActionsModules()]);
