import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {changeEmailActions, changeEmailActionTypes, IAdminChangeEmailState} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IChangeEmail} from 'Admin/AdminDashboard/services/types/user';
import UserDataService from 'Admin/AdminDashboard/services/UserDataService';

export const changeUserEmail = (
  params: IChangeEmail
): ActionResult<IAdminChangeEmailState, void, changeEmailActions> => async dispatch => {
  try {
    dispatch(action(changeEmailActionTypes.REQUEST));
    await UserDataService.changeUserEmail(params);
    dispatch(action(changeEmailActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(changeEmailActionTypes.FAILURE, getErrorMessage(error)));
  }
};
