import {useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Redirect, Switch, useHistory, useLocation} from 'react-router-dom';

import {AdminPermissionsModule} from 'Admin/common/Permissions/store/AdminPermissionsModule';
import {
  actions as adminPermissionsAction,
  selectors as adminPermissionsSelectors,
} from 'Admin/common/Permissions/store/index';
import TocAgreement from 'Auth/components/Agreement/TocAgreement';
import LoginForm from 'Auth/components/LoginForm/LoginForm';
import AuthService from 'Auth/services/AuthService';
import {AuthModule} from 'Auth/store/authModule';
import {actions as authActions, selectors as authSelectors} from 'Auth/store/index';
import AppAuthRoutingAdmin from 'Common/components/AppAuthRoutingAdmin';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import {VisitorType} from 'Common/constants/VisitorType';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {ILocationState} from 'Common/models/ILocationState';
import {IAppState} from 'Common/store/IAppState';
import DictionariesProvider from 'Common/store/useDictionaries';
import {Nullable} from 'Common/types';
import ForgotPassword from 'ForgotPassword/components/ForgotPassword';
import Loading from 'Loading/components/Loading';
import mailConfirmationRoutes from 'MailConfirmation/routes/routes';
import Maintain from 'Maintain/components/Maintain';
import useServiceMode from 'Maintain/hooks/useServiceMode';
import {ServiceMode} from 'Maintain/models/IServiceMode';
import {selectors as serviceModeSelectors} from 'Maintain/store/index';
import {Permission} from 'Permissions/constants/Permission';
import {PermissionsContext} from 'Permissions/hooks/usePermissions';
import {actions as permissionsAction, selectors as permissionsSelectors} from 'Permissions/store';
import ResetPassword from 'ResetPassword/components/ResetPassword';
import SignupForm from 'Signup/components/SignupForm';
import {actions as userActions, selectors as userSelectors} from 'UserProfile/store/currentUser';
import AppAuthRouting from './AppAuthRouting';
import AppAuthRoutingAssociationEmployee from './AppAuthRoutingAssociationEmployee';
import AppRoute from './AppRoute/AppRoute';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

export const DEVELOPMENT_ADMIN_EMAIL = 'admin@etalondx.com';

const maintainRouts = (
  <Switch>
    <AppRoute path="/maintain" component={Maintain} />
    <AppRoute path="/login" component={LoginForm} />
    <AppRoute component={Maintain} />
  </Switch>
);

type IConnected = ConnectedProps<typeof connector>;

function App(props: IConnected) {
  const {
    getCurrentUser,
    getCurrentPermissions,
    getCurrentAdminPermissions,
    getCurrentAssociationEmployee,
    userPermissions,
    adminPermissions,
    resetVisitorType,
    resetCurrentUser,
    resetCurrentPermissions,
    resetCurrentAssociationEmployee,
    currentUser,
    currentUserLoading,
    serviceMode,
    visitorType,
    currentAdmin,
    serviceModeLoading,
    currentAdminLoading,
    currentAssociationEmployeeLoading,
    resetCurrentAdmin,
    getCurrentAdmin,
    currentAssociationEmployee,
  } = props;
  const [isInitializing, setIsInitializing] = useState(true);
  const [isMaintain, setIsMaintain] = useState(false);
  const [isClearLocation, setIsClearLocation] = useState(false);

  const {isMobile} = useMediaQuery();
  const history = useHistory();
  const location = useLocation<ILocationState>();
  useOnSuccessCommunication(currentUserLoading, () => {
    if (isMaintain) {
      setIsMaintain(false);
    }
  });

  useOnSuccessCommunication(currentAdminLoading, () => {
    if (isMaintain) {
      setIsMaintain(false);
    }
  });

  useOnSuccessCommunication(currentAssociationEmployeeLoading, () => {
    if (isMaintain) {
      setIsMaintain(false);
    }
  });

  useOnSuccessCommunication(serviceModeLoading, () => {
    const isUser = !!currentUser && !currentAdmin;
    if (isUser && serviceMode.mode === ServiceMode.UsersOff) {
      setIsMaintain(true);
      history.replace('/maintain');
    } else {
      setIsMaintain(false);
    }
  });

  const {startServiceModeTimer, stopServiceModeTimer, renderNotification} = useServiceMode();

  const isDevelopmentAdmin = DEVELOPMENT_ADMIN_EMAIL === currentAdmin?.email;

  const hasPermission = useCallback(
    (permission: Permission | Permission[] | string | string[]) => {
      const permissions = userPermissions.map((i) => i as string).concat(adminPermissions);

      if (Array.isArray(permission)) {
        return permission.every((p) => permissions.includes(p));
      }

      return permissions.includes(permission);
    },
    [adminPermissions, userPermissions]
  );

  const isVisitorType = useCallback(
    (type: VisitorType) => {
      return type === visitorType?.type || type === AuthService.takeVisitorType();
    },
    [visitorType]
  );

  useEffect(() => {
    if (!!currentAdmin) {
      startServiceModeTimer();
    } else {
      stopServiceModeTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdmin]);

  useEffect(() => {
    const onLogout = (clearLocation?: boolean) => {
      setIsClearLocation(!!clearLocation);
      resetCurrentUser();
      resetCurrentAdmin();
      resetCurrentAssociationEmployee();
      resetCurrentPermissions();
      resetVisitorType();
    };

    const onMaintain = () => {
      setIsMaintain(true);

      const noNeedToRedirect = location.pathname === '/login' && AuthService.isAuthorized();
      if (!noNeedToRedirect) {
        history.replace('/maintain');
      }
      onLogout();
    };
    AuthService.init(onLogout, onMaintain);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!AuthService.isAuthorized()) {
      setIsInitializing(false);
      return;
    }

    (async () => {
      const localVisitorType = visitorType?.type || AuthService.takeVisitorType();

      switch (localVisitorType) {
        case VisitorType.Admin:
          await Promise.all([getCurrentAdmin(), getCurrentAdminPermissions()]);
          break;
        case VisitorType.User:
          await Promise.all([getCurrentPermissions(), getCurrentUser()]);
          break;
        case VisitorType.AssociationEmployee:
          await Promise.all([getCurrentPermissions(), getCurrentAssociationEmployee()]);
          break;
      }

      setIsInitializing(false);
    })();
  }, [
    getCurrentAdmin,
    getCurrentAdminPermissions,
    getCurrentAssociationEmployee,
    getCurrentPermissions,
    getCurrentUser,
    visitorType?.type,
  ]);

  const handleCloseAgreement = () => {
    AuthService.logout();
  };

  const isAuthorized =
    (AuthService.isAuthorized() && AuthService.takeVisitorType() === VisitorType.AssociationEmployee) ||
    !!currentUser ||
    !!currentAdmin;

  const getRoleSpecificRoutes = (): Nullable<JSX.Element> => {
    if (!isAuthorized) {
      return null;
    }

    const localVisitorType = visitorType?.type || AuthService.takeVisitorType();

    switch (localVisitorType) {
      case VisitorType.Admin:
        return <AppAuthRoutingAdmin />;
      case VisitorType.User:
        return <AppAuthRouting />;
      case VisitorType.AssociationEmployee:
        return <AppAuthRoutingAssociationEmployee />;
    }

    return null;
  };

  return (
    <PermissionsContext.Provider value={{hasPermission, isVisitorType, isDevelopmentAdmin}}>
      <DictionariesProvider>
        {isInitializing ? (
          <Loading />
        ) : (
          <>
            <ModalWindow
              isOpen={
                !!(currentUser && !currentUser.isAgreement) ||
                !!(currentAssociationEmployee && !currentAssociationEmployee.isAgreement)
              }
              onClose={handleCloseAgreement}
              maxHeight={isMobile ? '800px' : '900px'}
              maxWidth="800px"
            >
              <TocAgreement />
            </ModalWindow>

            {renderNotification}

            <Switch>
              {isMaintain && maintainRouts}
              <AppRoute path="/signup" component={SignupForm} />
              {mailConfirmationRoutes}
              <AppRoute path="/forgot-password" component={ForgotPassword} />
              <AppRoute path="/reset-password" component={ResetPassword} />
              {!isAuthorized && <AppRoute path="/login" component={LoginForm} />}
              {getRoleSpecificRoutes()}
              <Redirect to={{pathname: '/login', state: {from: isClearLocation ? {pathname: '/'} : location}}} />
            </Switch>
          </>
        )}
      </DictionariesProvider>
    </PermissionsContext.Provider>
  );
}

const mapDispatchToProps = {
  getCurrentUser: userActions.getCurrentUser,
  getCurrentAdmin: userActions.getCurrentAdmin,
  getCurrentAssociationEmployee: userActions.getCurrentAssociationEmployee,
  getCurrentPermissions: permissionsAction.getCurrentPermissions,
  getCurrentAdminPermissions: adminPermissionsAction.getCurrentAdminPermissions,
  resetCurrentUser: userActions.resetCurrentUser,
  resetCurrentAdmin: userActions.resetCurrentAdmin,
  resetCurrentAssociationEmployee: userActions.resetCurrentAssociationEmployee,
  resetCurrentPermissions: permissionsAction.resetCurrentPermissions,
  resetVisitorType: authActions.resetVisitorType,
};

const mapStateToProps = (state: IAppState) => ({
  currentUser: userSelectors.selectCurrentUser(state),
  userPermissions: permissionsSelectors.selectCurrentPermissions(state),
  currentUserLoading: userSelectors.selectCommunication(state, 'currentUserLoading'),
  userPermissionsLoading: permissionsSelectors.selectCommunication(state, 'currentPermissionsLoading'),

  currentAssociationEmployee: userSelectors.selectCurrentAssociationEmployee(state),
  currentAssociationEmployeeLoading: userSelectors.selectCommunication(state, 'currentAssociationEmployeeLoading'),

  currentAdmin: userSelectors.selectCurrentAdmin(state),
  currentAdminLoading: userSelectors.selectCommunication(state, 'currentUserLoading'),
  adminPermissions: adminPermissionsSelectors.selectCurrentAdminPermissions(state),
  adminPermissionsLoading: adminPermissionsSelectors.selectCommunication(state, 'currentAdminPermissionsLoading'),

  serviceMode: serviceModeSelectors.selectServiceMode(state),
  serviceModeLoading: serviceModeSelectors.selectCommunication(state, 'serviceModeLoading'),
  visitorType: authSelectors.selectVisitorType(state),
  visitorTypeLoading: authSelectors.selectCommunication(state, 'visitorTypeLoading'),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(App);
export default withDynamicModules(Connected, [AuthModule, AdminPermissionsModule]);
