import React, {memo} from 'react';
import styled, {css, FlattenSimpleInterpolation, StyledComponent} from 'styled-components';
import classNames from 'classnames';
import {TippyProps} from '@tippy.js/react';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import IconedLoading from 'Common/components/IconedLoading/IconedLoading';
import ColoredIcon, {IColoredIconProps} from 'Icon/components/ColoredIcon';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {hexToRgba} from 'Common/helpers/hexToRgba';

type ButtonType = 'contained' | 'outlined' | 'hollowed';
type ButtonSize = 'small' | 'medium';

const ContainedButtonStyles = css`
  background: ${Theme.color.control.bg};
  color: ${Theme.color.white};

  &:hover:not(:disabled):not(.primary-button__loading) {
    background: ${Theme.color.control.hover};
  }
  &:active:not(:disabled):not(.primary-button__loading) {
    background: ${Theme.color.control.active};
  }
  &:disabled {
    background-color: ${Theme.color.control.disabled};
  }
`;

const OutlineButtonStyles = css`
  background-color: ${Theme.color.white};
  border: 2px solid ${Theme.color.primary};
  color: ${Theme.color.black};
  border-color: ${Theme.color.primary};

  &:hover:not(:disabled):not(.primary-button__loading) {
    background-color: ${hexToRgba(Theme.color.primary, 0.08)};
  }
  &:active:not(:disabled):not(.primary-button__loading) {
    background-color: ${Theme.color.white};
    border-color: ${Theme.color.control.active};
  }
  &:disabled {
    border-color: ${Theme.color.control.disabled};
    color: ${ColorPalette.gray44};
  }
`;

const HollowButtonStyles = css`
  background-color: ${Theme.color.white} !important;
  color: ${Theme.color.black};
`;

const buttonStyleByType: Record<ButtonType, FlattenSimpleInterpolation> = {
  contained: ContainedButtonStyles,
  outlined: OutlineButtonStyles,
  hollowed: HollowButtonStyles,
};

const Button = styled.button<{variant?: ButtonType; size?: ButtonSize; fullWidth?: boolean}>`
  ${(p) =>
    p.size === 'small'
      ? `
    height: 32px;
    min-height: 32px;
    font-size: ${Typography.size.size14};
    line-height: 16px;
    `
      : `
    height: 48px;
    min-height: 48px;
    font-size: ${Typography.size.size16};
    line-height: 18px;
    `};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 168px;
  width: ${(p) => (p.fullWidth ? '100%' : 'unset')};
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  font-weight: ${Typography.weight.bold700};

  font-family: ${Theme.font.primary};
  outline: none !important;

  &.primary-button__loading {
    cursor: unset;
    pointer-events: none;
  }
  ${({variant}) => (variant ? buttonStyleByType[variant] : ContainedButtonStyles)}
`;

const IconWrapper = styled.div<{iconPosition?: 'left' | 'right'}>`
  ${(props) => props.iconPosition === 'left' && 'margin-right: 16px;'}
  ${(props) => props.iconPosition === 'right' && 'margin-left: 16px;'}
`;

interface Props extends React.ButtonHTMLAttributes<any> {
  variant?: ButtonType;
  size?: 'small' | 'medium';
  isLoading?: boolean;
  icon?: IColoredIconProps;
  iconStyle?: React.CSSProperties;
  tooltip?: string;
  tooltipStyled?: StyledComponent<React.ForwardRefExoticComponent<TippyProps>, any>;
  fullWidth?: boolean;
  iconPosition?: 'left' | 'right';
}

function PrimaryButton(props: React.PropsWithChildren<Props>) {
  const {
    variant = 'contained',
    size = 'medium',
    children,
    tooltip,
    icon,
    iconStyle,
    isLoading: propsIsLoading,
    disabled,
    className,
    tooltipStyled,
    iconPosition = 'left',
    ...rest
  } = props;

  const isContained = variant === 'contained';
  const isLoading = propsIsLoading && !disabled;

  const loader = <IconedLoading color={isContained ? 'white' : 'primary'} />;

  const TooltipComponent = tooltipStyled ?? Tooltip;

  const content = tooltip ? (
    <TooltipComponent content={tooltip}>
      <div>{children}</div>
    </TooltipComponent>
  ) : (
    children
  );

  return (
    <Button
      variant={variant}
      disabled={disabled}
      size={size}
      {...rest}
      className={classNames(className, isLoading ? 'primary-button__loading' : '')}
    >
      {(() => {
        if (icon) {
          return (
            <>
              {iconPosition === 'right' && content}
              <IconWrapper iconPosition={iconPosition}>
                {isLoading ? (
                  loader
                ) : (
                  <ColoredIcon
                    fill={true}
                    stroke={false}
                    color={isContained ? Theme.color.white : Theme.color.primary}
                    {...icon}
                    style={iconStyle}
                  />
                )}
              </IconWrapper>
              {iconPosition === 'left' && content}
            </>
          );
        }

        return (
          <>
            {isLoading && <div className="position-absolute">{loader}</div>}
            <div className={isLoading ? 'invisible' : ''}>{content}</div>
          </>
        );
      })()}
    </Button>
  );
}

export default memo(PrimaryButton);
export type ButtonProps = Props;
