import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  AdminUserFullInfoUpdateActions,
  getUserActionTypes,
  IAdminUserFullInfoUpdateState,
  updateUserActionTypes,
} from '../types';

export const communicationUserFullInfoUpdateReducer = combineReducers<
  IAdminUserFullInfoUpdateState['communications'],
  AdminUserFullInfoUpdateActions
>({
  userLoading: makeCommunicationReducerFromEnum(getUserActionTypes),
  userUpdating: makeCommunicationReducerFromEnum(updateUserActionTypes),
});
