import React, {memo, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import Theme from 'Common/constants/Theme';
import ColorPalette from 'Common/constants/ColorPalette';
import {zIndex} from 'Common/constants/ZIndex';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import {ElementPosition} from 'FoalProfile/models/IElementPosition';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';
import {breakpoints} from 'Common/constants/Breakpoints';
import Typography from 'Common/constants/Typography';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const Root = styled.div<{
  isOpen: boolean;
  position: ElementPosition;
  width: string;
  shadow: boolean;
  transition: boolean;
}>`
  z-index: ${zIndex.modal};
  visibility: ${({isOpen}) => (isOpen ? 'visible' : 'hidden')};
  width: ${({isOpen}) => (isOpen ? '100%' : 0)};
  position: fixed;
  top: 0;
  bottom: 0;
  ${({position}) => (position === ElementPosition.Left ? 'left: 0' : 'right: 0')};
  transform: translateX(${({isOpen, position}) => (isOpen ? 0 : position === ElementPosition.Left ? -100 : 100)}%);
  transition: ${({transition}) => (transition ? '0.2s' : '0s')} ease-out;
  background: ${Theme.color.white};
  padding: 0;
  box-shadow: ${({shadow}) =>
    shadow
      ? `0 100px 80px ${hexToRgba(ColorPalette.black0, 0.14)},
    0px 12px 10px ${hexToRgba(ColorPalette.black0, 0.07)}`
      : 0};

  @media ${breakpoints.md} {
    width: ${({isOpen, width}) => (isOpen ? width : 0)};
  }
`;

const ScrollbarWrapper = styled.div<{isOpen: boolean}>`
  display: ${({isOpen}) => (isOpen ? 'inherit' : 'none')};
`;

const ChildrenWrapper = styled.div`
  margin-top: 100px;
`;

const TitleWrapper = styled.div`
  width: 90%;
  padding: 32px 0;
  background: white;
  position: fixed;
  z-index: ${zIndex.modal};
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size24};
  line-height: 32px;
  color: ${Theme.color.black};
`;

const ClickableTitle = styled.div`
  width: fit-content;
`;

const CloseButton = styled.div`
  position: fixed;
  z-index: ${zIndex.modal + 1};
  top: 32px;
  right: 32px;
`;

interface IProps {
  title: string | JSX.Element;
  isOpen: boolean;
  onClose?(): void;
  position: ElementPosition.Left | ElementPosition.Right;
  width?: string;
  shadow?: boolean;
  transition?: boolean;
  closableTitle?: boolean;
}

type AllProps = React.PropsWithChildren<IProps>;

const SlidePanel = (props: AllProps) => {
  const {
    title,
    closableTitle,
    isOpen,
    shadow = false,
    transition = false,
    position,
    width = '600px',
    onClose,
    children,
  } = props;

  const {isMobile} = useMediaQuery();

  const scrollPaddings = useMemo(() => {
    return isMobile ? '0 16px 16px 16px' : '0 32px 16px 32px';
  }, [isMobile]);

  const onClickTitle = useCallback(() => {
    closableTitle && onClose && onClose();
  }, [closableTitle, onClose]);

  return (
    <Root className="d-flex" isOpen={isOpen} position={position} width={width} shadow={shadow} transition={transition}>
      <ScrollbarWrapper className="w-100 h-100" isOpen={isOpen}>
        {isOpen && (
          <Scrollbar renderViewStyle={{padding: scrollPaddings}} maxHeight="100%" autoHeight={false}>
            <TitleWrapper>
              <ClickableTitle onClick={onClickTitle}>{title}</ClickableTitle>
            </TitleWrapper>
            {onClose && (
              <CloseButton className="align-self-end">
                <IconButton
                  name={IconName.Close}
                  onClick={onClose}
                  color={ColorPalette.gray31}
                  hoverColor={ColorPalette.black2}
                  fill={true}
                  stroke={false}
                  size={20}
                />
              </CloseButton>
            )}
            <ChildrenWrapper>{children}</ChildrenWrapper>
          </Scrollbar>
        )}
      </ScrollbarWrapper>
    </Root>
  );
};

export default memo(SlidePanel);
