import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse} from 'Common/models/IResponse';
import {IUserOrderRequest, IServerUserOrder} from './serverModels/IUserOrderRequest';
import {IUserOrder} from 'UserProfile/models/IUserOrder';
import {convertServerUserOrder} from './converter/UserOrderConverter';

const getUserOrders = async (params: IUserOrderRequest): Promise<IListResponse<IUserOrder>> => {
  const response = await axiosWrapper.get<IListResponse<IServerUserOrder>>(`/UserProfileOrders`, {params});
  return {...response.data, data: response.data.data.map(convertServerUserOrder)};
};

export default {
  getUserOrders,
};
