import {Reducer} from 'redux';

import {
  AdminAssociationOwnerActions,
  getAdminAssociationOwnerDetailsActionTypes,
  getAdminAssociationOwnersActionTypes,
  IAdminAssociationOwnerState,
  resetAdminAssociationOwnerDetailsActionTypes,
  resetAdminAssociationOwnersActionTypes,
} from '../types';

const initialState: IAdminAssociationOwnerState['data'] = {
  owners: [],
  ownerDetails: null,
};

export const dataAdminAssociationOwnerReducer: Reducer<
  IAdminAssociationOwnerState['data'],
  AdminAssociationOwnerActions
> = (state = initialState, action: AdminAssociationOwnerActions) => {
  switch (action.type) {
    case getAdminAssociationOwnersActionTypes.SUCCESS: {
      return {...state, owners: action.payload.data};
    }
    case getAdminAssociationOwnerDetailsActionTypes.SUCCESS: {
      return {...state, ownerDetails: action.payload};
    }

    case resetAdminAssociationOwnerDetailsActionTypes.RESET: {
      return {...state, ownerDetails: initialState.ownerDetails};
    }
    case resetAdminAssociationOwnersActionTypes.RESET: {
      return {...state, owners: initialState.owners};
    }

    default: {
      return state;
    }
  }
};
