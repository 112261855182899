import React, {memo, useEffect} from 'react';
import {FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {RequiredBy} from 'Common/types';
import {
  convertToServer,
  convertToServerUpdating,
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminReference/components/Colors/ColorForm/validation';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {ImageUploaderField, InputField, TextAreaField} from 'Common/components/FormFields/index';
import {selectors as imageSelectors} from 'Image/store/index';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {IconName} from 'Icon/components/Icon';
import * as actions from 'Admin/AdminReference/store/uploadImage/actions';
import {IShortColor} from 'Dictionaries/models/IColor';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {ImageModule} from 'Image/store/imageModule';
import {IUpdateShortColorRequest} from 'Admin/AdminReference/services/models/IServerShortColor';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

type IOwnProps = IPhenotypeFormExternalProps<IShortColor, IUpdateShortColorRequest>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function ColorForm(props: Props) {
  const {itemId, item, dictionary, imageLoading, imageUploading, ...rest} = props;
  const {getItem} = dictionary.actions;

  useEffect(() => {
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem]);

  const isLoading = imageLoading.isRequesting || imageUploading.isRequesting;

  return (
    <DictionaryForm {...rest} itemPreloading={isLoading}>
      <InputField
        isRequired={true}
        name="name"
        type="text"
        label="Name"
        placeholder="Name"
        className="w-50"
        autoComplete="off"
      />
      <TextAreaField
        name="description.value"
        label="Description"
        placeholder="Add description to notice some facts about color"
      />
      <FieldControlContainer label="Coat type image">
        <ImageUploaderField
          name="image"
          iconProps={{name: IconName.RearedHorse, width: 48, height: 60}}
          title={'Add coat image'}
        />
      </FieldControlContainer>
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  return {
    ...makePhenotypeFormSelectors(state, ownProps.dictionary),
    imageLoading: imageSelectors.selectCommunication(state, 'imageLoading'),
    imageUploading: imageSelectors.selectCommunication(state, 'imageUploading'),
  };
};

const ColorFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting ? item : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, uploadColorImage, dictionary} = formikBag.props;

    formikBag.setSubmitting(true);

    const uploadImage = async (file: File) => {
      const formData = new FormData();
      formData.append('uploadedFile', file);
      return uploadColorImage(formData);
    };

    if (values.image?.file) {
      values.image = await uploadImage(values.image.file);
    }

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(ColorForm);

const mapDispatchToProps = {
  uploadColorImage: actions.uploadColorImage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(ColorFormFormik));
export default withDynamicModules(Connected, ImageModule);
