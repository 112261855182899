import {action} from 'typesafe-actions';

import OrderDataService from 'Admin/AdminDashboard/services/OrderDataService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';

import {
  changeSampleActions,
  changeSampleActionTypes,
  chooseAnotherSampleActions,
  chooseAnotherSampleActionTypes,
  deleteOrderActions,
  deleteOrderActionTypes,
  downloadReportsActions,
  downloadReportsActionTypes,
  downloadSubmissionFormActions,
  downloadSubmissionFormActionTypes,
  getHorseSamplesActions,
  getHorseSamplesActionTypes,
  getOrderDetailsActions,
  getOrderDetailsActionTypes,
  getOrdersActions,
  getOrdersActionTypes,
  getOrderStatusesDetailsActions,
  getOrderStatusesDetailsActionTypes,
  IReleaseOrderHorse,
  IRequestChangeSample,
  IRequestChooseAnotherSample,
  IRequestNewSampleParams,
  IRequestSetSampleStatus,
  releaseOrderHorseActions,
  releaseOrderHorseActionTypes,
  requestNewSampleActions,
  requestNewSampleActionTypes,
  resetChangeSampleCommunicationActions,
  resetChangeSampleCommunicationActionTypes,
  resetChooseAnotherSampleCommunicationActions,
  resetChooseAnotherSampleCommunicationActionTypes,
  resetOrderDetailsActions,
  resetOrderDetailsActionTypes,
  resetOrderStatusesDetailsActions,
  resetOrderStatusesDetailsActionTypes,
  setSampleStatusActions,
  setSampleStatusActionTypes,
} from './types';

export const getOrders =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getOrdersActions> =>
  async (dispatch) => {
    dispatch(action(getOrdersActionTypes.REQUEST));
    try {
      const res = await OrderDataService.getOrders(params);
      dispatch(action(getOrdersActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getOrdersActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };

export const getOrderDetails =
  (orderId: number): ActionResult<IAppState, Promise<void>, getOrderDetailsActions> =>
  async (dispatch) => {
    dispatch(action(getOrderDetailsActionTypes.REQUEST));
    try {
      const res = await OrderDataService.getOrderDetails(orderId);
      dispatch(action(getOrderDetailsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getOrderDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const releaseOrderHorse =
  (data: IReleaseOrderHorse): ActionResult<IAppState, Promise<void>, releaseOrderHorseActions> =>
  async (dispatch) => {
    dispatch(action(releaseOrderHorseActionTypes.REQUEST));
    try {
      await OrderDataService.releaseOrderHorse(data);
      dispatch(action(releaseOrderHorseActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(releaseOrderHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteOrder =
  (orderId: number): ActionResult<IAppState, Promise<void>, deleteOrderActions> =>
  async (dispatch) => {
    dispatch(action(deleteOrderActionTypes.REQUEST));
    try {
      await OrderDataService.deleteOrder(orderId);
      dispatch(action(deleteOrderActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteOrderActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const requestNewSample =
  (params: IRequestNewSampleParams): ActionResult<IAppState, Promise<void>, requestNewSampleActions> =>
  async (dispatch) => {
    dispatch(action(requestNewSampleActionTypes.REQUEST));
    try {
      await OrderDataService.requestNewSample(params);
      dispatch(action(requestNewSampleActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(requestNewSampleActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setSampleStatus =
  (params: IRequestSetSampleStatus): ActionResult<IAppState, Promise<void>, setSampleStatusActions> =>
  async (dispatch) => {
    dispatch(action(setSampleStatusActionTypes.REQUEST));
    try {
      await OrderDataService.setSampleStatus(params);
      dispatch(action(setSampleStatusActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(setSampleStatusActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const changeSample =
  (params: IRequestChangeSample): ActionResult<IAppState, Promise<void>, changeSampleActions> =>
  async (dispatch) => {
    dispatch(action(changeSampleActionTypes.REQUEST));
    try {
      await OrderDataService.changeSample(params);
      dispatch(action(changeSampleActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(changeSampleActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const chooseAnotherSample =
  (params: IRequestChooseAnotherSample): ActionResult<IAppState, Promise<void>, chooseAnotherSampleActions> =>
  async (dispatch) => {
    dispatch(action(chooseAnotherSampleActionTypes.REQUEST));
    try {
      await OrderDataService.chooseAnotherSample(params);
      dispatch(action(chooseAnotherSampleActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(chooseAnotherSampleActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseSamples =
  (horseId: number): ActionResult<IAppState, Promise<void>, getHorseSamplesActions> =>
  async (dispatch) => {
    dispatch(action(getHorseSamplesActionTypes.REQUEST));
    try {
      const res = await OrderDataService.getHorseSamples(horseId);
      dispatch(action(getHorseSamplesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHorseSamplesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const downloadReports =
  (orderId: number): ActionResult<IAppState, Promise<void>, downloadReportsActions> =>
  async (dispatch) => {
    dispatch(action(downloadReportsActionTypes.REQUEST));
    try {
      await OrderDataService.downloadReports(orderId);
      dispatch(action(downloadReportsActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(downloadReportsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const downloadOrderSubmissionForm =
  (orderId: number): ActionResult<IAppState, Promise<void>, downloadSubmissionFormActions> =>
  async (dispatch) => {
    dispatch(action(downloadSubmissionFormActionTypes.REQUEST));
    try {
      await OrderDataService.downloadOrderSubmissionForm(orderId);
      dispatch(action(downloadSubmissionFormActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(downloadSubmissionFormActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const downloadHorseSubmissionForm =
  (orderId: number, horseId: number): ActionResult<IAppState, Promise<void>, downloadSubmissionFormActions> =>
  async (dispatch) => {
    dispatch(action(downloadSubmissionFormActionTypes.REQUEST));
    try {
      await OrderDataService.downloadHorseSubmissionForm(orderId, horseId);
      dispatch(action(downloadSubmissionFormActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(downloadSubmissionFormActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrderStatusesDetails =
  (orderId: number, horseId: number): ActionResult<IAppState, Promise<void>, getOrderStatusesDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOrderStatusesDetailsActionTypes.REQUEST));
      const data = await OrderDataService.getOrderStatusesDetails(orderId, horseId);
      dispatch(action(getOrderStatusesDetailsActionTypes.SUCCESS, {orderId, horseId, statuses: data}));
    } catch (error) {
      dispatch(action(getOrderStatusesDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetChangeSampleCommunication =
  (): ActionResult<IAppState, void, resetChangeSampleCommunicationActions> => (dispatch) => {
    dispatch(action(resetChangeSampleCommunicationActionTypes.RESET));
  };

export const resetChooseAnotherSampleCommunication =
  (): ActionResult<IAppState, void, resetChooseAnotherSampleCommunicationActions> => (dispatch) => {
    dispatch(action(resetChooseAnotherSampleCommunicationActionTypes.RESET));
  };

export const resetOrderDetails = (): ActionResult<IAppState, void, resetOrderDetailsActions> => (dispatch) => {
  dispatch(action(resetOrderDetailsActionTypes.RESET));
};

export const resetOrderStatusDetails =
  (): ActionResult<IAppState, void, resetOrderStatusesDetailsActions> => (dispatch) => {
    dispatch(action(resetOrderStatusesDetailsActionTypes.RESET));
  };
