import {getFieldErrors} from 'Common/helpers/ErrorHelper';

const UNKNOWN_IMAGE_ERROR = 'Some error with uploading image';

export function retrieveImageError(error: any) {
  const fieldErrors = getFieldErrors(error);
  if (fieldErrors) {
    return Object.values(fieldErrors)?.[0];
  }

  if (error?.title) {
    return error.title;
  }
  return UNKNOWN_IMAGE_ERROR;
}
