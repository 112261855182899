import {HeightLimits, HeightUnits} from 'HorseProfile/constants/HeightUnits';

export function isValidHeight(value: number, unit: HeightUnits) {
  const currentUnitLimits = HeightLimits[unit];
  const isValidForLimits = value >= currentUnitLimits.min && value <= currentUnitLimits.max;

  if (unit === HeightUnits.Hands) {
    return isValidHandsHeight(value) && isValidForLimits;
  }

  return isValidForLimits;
}

export function isValidHandsHeight(value: number) {
  return Math.round((value % 1) * 100) / 100 <= 0.3;
}
