import {combineReducers} from 'redux';

import {getServiceModeActionTypes, IServiceModeState, ServiceModeActions} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';

export const communicationServiceModeReducer = combineReducers<IServiceModeState['communications'], ServiceModeActions>(
  {
    serviceModeLoading: makeCommunicationReducerFromEnum(getServiceModeActionTypes)
  }
);
