import {IModule} from 'redux-dynamic-modules';

import {IAdminAncestryReportState} from './types';
import {adminAncestryReportReducer} from './reducer';

export interface IAdminAncestryReportModuleState {
  adminAncestryReport: IAdminAncestryReportState;
}

export const AdminAncestryReportModule: IModule<IAdminAncestryReportModuleState> = {
  id: 'adminAncestryReport',
  reducerMap: {
    adminAncestryReport: adminAncestryReportReducer,
  },
};
