const TOUR_STEPS = [
  {
    target: '#fah-filters',
    title: 'Welcome to the Find-a-Horse guide!',
    content: (
      <div>
        <p>The options on the left will allow you to select the characteristics of the horse you want to find.</p>
        <p>You can use this tool to find horses to buy, lease or breed.</p>
        <p>
          For the purpose of this guide, let's see what options would help us find a show jumping horse we could buy.
        </p>
      </div>
    ),
    placement: 'right' as const,
    styles: {tooltip: {width: '475px'}},
  },
  {
    target: '#fah-filters > div:first-child > div:first-child',
    content: `Here you can select the horse's gender.`,
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '380px'}},
  },
  {
    target: '#gene-pools',
    content: (
      <div>
        <p>In Gene Pools we can exclude some horses from our search result.</p>
        <p> Feel free to expand this section and explore the possible filters.</p>
        <p>
          For now, let's select <strong>"Lease"</strong>, <strong>"Open to offers"</strong> and <strong>"Sale"</strong>{' '}
          so we can query all the horses that are publicly available for purchase or leasing.
        </p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '550px'}},
  },
  {
    target: '#abilities',
    content: (
      <div>
        <p>
          You can select characteristics for your horse such as <strong>Speed</strong>, <strong>Temperament</strong> and{' '}
          <strong>Gait (DMRT3)</strong>.
        </p>
        <p>For the purpose of this tour we will skip this step for now.</p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '450px'}},
  },
  {
    target: '#colors',
    content: (
      <div>
        <p>Here you can select your desired color and modifiers.</p>
        <p>
          Try typing <strong>"Palomino"</strong> for <strong>Color</strong>.
        </p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '450px'}},
  },
  {
    target: '#features',
    content: (
      <div>
        <p>Here you can select breeds and disciplines of the parents.</p>
        <p>(Remember that "breed" and "discipline" are not currently genetically verified values).</p>
        <p>
          Try typing <strong>"Show jumper"</strong> as a discipline.
        </p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '470px'}},
  },
  {
    target: '#health',
    content: (
      <div>
        <p>This option can be used to exclude undesired genetic carriers or variants.</p>
        <p>Let's skip this step for now.</p>
        <p>
          (Note that even if you skip this step, potential health issues or mismatches will be flagged by our system for
          your review).
        </p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '580px'}},
  },
  {
    target: '#fah-search > div:first-child',
    content: (
      <div>
        <p>If you already know the name of the horse you want to find, you can type its name here.</p>
        <p>If it is available in the database, it will show up on the list below.</p>
      </div>
    ),
    placement: 'bottom' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '420px'}},
  },
  {
    target: '#fah-search > div:first-child button',
    content: (
      <p>
        Click <strong>"Search"</strong> to view your results.
      </p>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '#fah-search-results > div:nth-child(2) > div:first-child',
    content: `Your first result.`,
    placement: 'left' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '300px'}},
  },
  {
    target: '#fah-search-results > div:nth-child(2) > div:first-child > div:first-child > div:nth-child(3)',
    content: (
      <div>
        <p>
          If you want to use this horse for breeding you can click <strong>"Build with this horse"</strong> which will
          take you to the Build-a-Horse page.
        </p>
        <p>
          You can also add this horse to your <strong>favorites</strong>, it's a good way to bookmark horses you are
          interested in.
        </p>
      </div>
    ),
    placement: 'left' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '420px'}},
  },
  {
    target: '#fah-search-results > div:nth-child(2) > div:first-child > div:first-child > div:nth-child(2)',
    content: `This horse's height and genetic attributes in regards to ability.`,
    placement: 'left' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '350px'}},
  },
  {
    target: '#fah-search-results > div:nth-child(2) > div:first-child > div:first-child > div:first-child',
    content: (
      <div>
        <p>Quick info such as name, breed, gender and age appears here.</p>
        <p>Click this horse's picture or name to go to its detailed profile.</p>
      </div>
    ),
    placement: 'bottom-start' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '500px'}},
  },
  {
    target: 'body',
    content: (
      <div>
        <p>This concludes our tour.</p>
        <p>(To restart this tour simply refresh the page).</p>
      </div>
    ),
    disableBeacon: true,
    disableOverlay: true,
    styles: {tooltip: {width: '500px'}},
  },
];

export default TOUR_STEPS;
