import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {IGenotypeGroup} from 'Genotype/models/IGenotypeGroup';

const STATE_NAME = 'genotypeList';

export interface IGenotypeListState {
  data: {
    genotypes: IGenotypeGroup[];
  };
  communications: {
    genotypeLoading: ICommunication;
  };
}

export const getGenotypeListActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_GENOTYPES');
export type getGenotypeListActions = MakeCommunicationActions<
  typeof getGenotypeListActionTypes,
  {success: IGenotypeGroup[]}
>;

export const resetGenotypeListActionTypes = makeResetCommunicationActionType(STATE_NAME, 'GENOTYPES');
export type resetGenotypeListActions = MakeResetCommunicationActions<typeof resetGenotypeListActionTypes>;

export type GenotypeListActions = getGenotypeListActions | resetGenotypeListActions;
