import {AxiosPromise} from 'axios';
import {withAvatar} from 'Common/helpers/withAvatar';
import {ILocation} from 'Common/models/ICoordinatedLocation';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IImageRequest} from 'Image/models/IImage';
import {IAdminEmployee} from '../models/IAdminEmployee';
import {
  ICreateAdminEmployee,
  IServerEmployee,
  IUpdateAdminEmployee,
  IUpdateAdminEmployeeEmail,
  IUpdateAdminEmployeePassword,
} from './types/employee';

const getAdminEmployees = async (params?: IRequestParams): Promise<IListResponse<IAdminEmployee>> => {
  const response = await axiosWrapper.get<IListResponse<IServerEmployee>>(`/AssociationEmployeeAdmin`, {params});
  return {...response.data, data: response.data.data.map(withAvatar)};
};

const getAdminEmployee = async (id: number): Promise<IAdminEmployee> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerEmployee>>(`/AssociationEmployeeDetailsAdmin/${id}`);
  return withAvatar(response.data.data);
};

const createAdminEmployee = async (data: ICreateAdminEmployee): Promise<number> => {
  const response = await axiosWrapper.post<IObjectResponse<number>>(`/AssociationEmployeeCreateAdmin`, data);
  return response.data.data;
};

const updateAdminEmployee = async (data: IUpdateAdminEmployee): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(`/AssociationEmployeeUpdateAdmin`, data);
};

const uploadAdminEmployeeAvatar = async (employeeId: number, file: File): Promise<void> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('employeeId', `${employeeId}`);

  await axiosWrapper.post<IObjectResponse<IImageRequest>>(`/AssociationEmployeeAvatarUpdateAdmin`, formData);
};

const deleteAdminEmployeeAvatar = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/AssociationEmployeeAvatarDeleteAdmin/${id}`);
};

const changeAdminEmployeeStatus = async (id: number, isActive: boolean): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AssociationEmployeeStatusUpdateAdmin`, {id, isActive});
};

const updateAdminEmployeePassword = async (data: IUpdateAdminEmployeePassword): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AssociationEmployeePasswordUpdateAdmin`, data);
};

const updateAdminEmployeeEmail = async (data: IUpdateAdminEmployeeEmail): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AssociationEmployeeEmailUpdateAdmin`, data);
};

const getAdminEmployeeGeoLocation = (employeeId: number): AxiosPromise<IObjectResponse<ILocation>> => {
  return axiosWrapper.get<IObjectResponse<ILocation>>(`/AssociationEmployeeGeoPointAdmin/${employeeId}`);
};

const updateAdminEmployeeGeoLocation = async (employeeId: number, data: ILocation): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AssociationEmployeeGeoPointUpdateAdmin`, {employeeId, ...data});
};

export default {
  getAdminEmployees,
  getAdminEmployee,
  createAdminEmployee,
  updateAdminEmployee,
  uploadAdminEmployeeAvatar,
  deleteAdminEmployeeAvatar,
  changeAdminEmployeeStatus,
  updateAdminEmployeePassword,
  updateAdminEmployeeEmail,
  getAdminEmployeeGeoLocation,
  updateAdminEmployeeGeoLocation,
};
