import React, {useCallback, useState} from 'react';
import WarningModalWindow, {ModalTypes} from 'Common/components/Modal/WarningModal';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {ICommunication, initialCommunication} from 'Common/store/utils/communication/index';

const DELETING_ITEM_ERROR = 'There is an error in removing';

interface IProps {
  deleteCommunication?: ICommunication;
  deleteAction?(selectedId?: number): void;
  onSuccess?(): void;
  confirmDescription?: string;
}

export function useDeleteModal(props: IProps) {
  const {deleteCommunication, confirmDescription, deleteAction, onSuccess} = props;

  const [isDeleteErrorModalOpen, setIsDeleteErrorModalOpen] = useState(false);
  const closeDeleteErrorModal = useCallback(() => setIsDeleteErrorModalOpen(false), []);

  useOnErrorCommunication(deleteCommunication || initialCommunication, () => setIsDeleteErrorModalOpen(true));

  const [selectedId, setSelectedId] = useState<number | null>(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const openDeleteModal = useCallback((id?: number) => {
    id && setSelectedId(id);
    setIsDeleteModalOpen(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setSelectedId(null);
    setIsDeleteModalOpen(false);
  }, []);

  const confirmDelete = useCallback(async () => {
    closeDeleteModal();
    if (selectedId) {
      deleteAction && (await deleteAction(selectedId));
    } else {
      deleteAction && (await deleteAction());
    }
    onSuccess && onSuccess();
  }, [selectedId, onSuccess, closeDeleteModal, deleteAction]);

  const deleteModal = (
    <>
      <WarningModalWindow
        modalType={ModalTypes.Confirm}
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onSuccess={confirmDelete}
      >
        {confirmDescription}
      </WarningModalWindow>
      <WarningModalWindow
        modalType={ModalTypes.Alert}
        isOpen={isDeleteErrorModalOpen}
        onClose={closeDeleteErrorModal}
        onSuccess={closeDeleteErrorModal}
      >
        {DELETING_ITEM_ERROR}
      </WarningModalWindow>
    </>
  );

  return {
    openDeleteModal,
    closeDeleteModal,
    selectedId,
    setSelectedId,
    isDeleteModalOpen,
    closeDeleteErrorModal,
    deleteModal
  };
}
