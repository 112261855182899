import {Reducer} from 'redux';

import {IAdminPurchasableTestState, AdminPurchasableTestsActions, getPurchasableTestsActionTypes} from '../types';

const initialState: IAdminPurchasableTestState['ui'] = {
  pagination: {countPerPage: 0, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiAdminPurchasableTestsReducer: Reducer<IAdminPurchasableTestState['ui'], AdminPurchasableTestsActions> = (
  state = initialState,
  action: AdminPurchasableTestsActions
) => {
  switch (action.type) {
    case getPurchasableTestsActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
