import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {Permission} from 'Permissions/constants/Permission';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.permissions;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectCurrentPermissions(state: IAppState) {
  return selectState(state).data.currentPermissions;
}

export function selectIsUserAdmin(state: IAppState) {
  return selectState(state).data.currentPermissions.includes(Permission.Admin);
}
