import React, {memo} from 'react';
import {FieldArray} from 'formik';

import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import SpecialPrice from './SpecialPrice';
import {IAdminOrganizationPriceRequest} from 'Admin/AdminAssociations/models/IAdminOrganizationPrice';
import {IFoundOrganization} from 'Admin/AdminAssociations/models/IFoundOrganization';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {IconedField} from 'Common/components/StyledComponents/StyledComponents';
import {CheckboxField, InputField} from 'Common/components/FormFields';
import {Hint, PriceIcon, titleStyle} from './styled';
import Nebula from 'Common/components/Layout/Nebula';

interface IProps {
  specialPrices: IAdminOrganizationPriceRequest[];
  organizations: IFoundOrganization[];
  isAvailabilityAllOrganizations: boolean;
  isSpecialPrices: boolean;
}

function SpecialPriceField(props: IProps) {
  const {specialPrices, organizations, isAvailabilityAllOrganizations, isSpecialPrices} = props;

  return (
    <FormControlContainer title="Availability and prices" titleStyle={titleStyle}>
      <Hint className="w-50">
        By selecting both options test is able for all users but with special offer for specific users or groups of
        users
      </Hint>

      <CheckboxField name="isAvailabilityAllOrganizations" label="All business users" />

      <IconedField>
        <InputField
          inputStyle={{textIndent: 15}}
          name="commonPrice"
          type="text"
          label="Price"
          placeholder="Price"
          className="w-25"
          autoComplete="off"
          disabled={!isAvailabilityAllOrganizations}
        />
        <PriceIcon>$</PriceIcon>
      </IconedField>

      <CheckboxField name="isSpecialPrices" label="Specific users" />

      <FieldControlContainer>
        <FieldArray
          name="specialPrices"
          render={(arrayHelper) => {
            return (
              <Nebula active={!isSpecialPrices}>
                {specialPrices.map((price, i) => {
                  const onDeletePrice = () => arrayHelper.remove(i);
                  const selectedOrganizations = specialPrices.flatMap((x) => x.organizations);

                  return (
                    <SpecialPrice
                      key={i}
                      organizations={organizations}
                      selectedOrganizations={selectedOrganizations}
                      onDelete={onDeletePrice}
                      index={i}
                    />
                  );
                })}
                <PrimaryButton type="button" size="small" variant="outlined" onClick={() => arrayHelper.push({})}>
                  + Add price
                </PrimaryButton>
              </Nebula>
            );
          }}
        />
      </FieldControlContainer>
    </FormControlContainer>
  );
}

export default memo(SpecialPriceField);
