import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';

const LogoWrapper = styled.div`
  width: 28px;
`;

const Logo = styled(ColoredIcon)`
  margin-top: 2px;
  margin-right: 8px;
`;

interface IProps {
  packages: string[];
  tests: string[];
}

function PackageSection(props: IProps) {
  const {packages, tests} = props;

  const isPackages = packages.length > 0;
  const isTests = tests.length > 0;

  return (
    <>
      {isPackages && (
        <div className="d-flex align-items-start">
          <LogoWrapper className="d-flex align-items-center">
            <Logo name={IconName.Business} color={ColorPalette.gray56} stroke={false} fill={true} />
          </LogoWrapper>
          {packages.join(', ')}
        </div>
      )}
      {isTests && (
        <div className="d-flex align-items-start">
          <LogoWrapper className="d-flex align-items-center">
            <Logo name={IconName.Notebook3} color={ColorPalette.gray56} stroke={false} fill={true} />
          </LogoWrapper>
          {tests.join(', ')}
        </div>
      )}
    </>
  );
}

export default memo(PackageSection);
