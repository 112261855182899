import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IRequestParams} from 'Common/models/IRequestParams';

import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import BusinessPortalOrderService from 'BusinessPortal/services/BusinessPortalOrderService';
import {
  BusinessPortalOrderActions,
  deleteOrderActions,
  deleteOrderActionTypes,
  downloadOrderGroupSubmissionFormActions,
  downloadOrderGroupSubmissionFormActionTypes,
  downloadOrderSubmissionFormActions,
  downloadOrderSubmissionFormActionTypes,
  getOrderDetailsActions,
  getOrderDetailsActionTypes,
  getOrderPackagesDetailsActions,
  getOrderPackagesDetailsActionTypes,
  getOrderReportsActions,
  getOrderReportsActionTypes,
  getOrdersActions,
  getOrdersActionTypes,
  getOrderStatusesActions,
  getOrderStatusesActionTypes,
  resetOrderDetailsActionTypes,
  resetOrdersActionTypes,
} from './types';

export const getOrders =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getOrdersActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOrdersActionTypes.REQUEST));
      const orders = await BusinessPortalOrderService.getOrders(params);
      dispatch(action(getOrdersActionTypes.SUCCESS, orders));
    } catch (error) {
      dispatch(action(getOrdersActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrderDetails =
  (orderId: number): ActionResult<IAppState, Promise<void>, getOrderDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOrderDetailsActionTypes.REQUEST));
      const data = await BusinessPortalOrderService.getOrderDetails(orderId);
      dispatch(action(getOrderDetailsActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getOrderDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrderPackagesDetails =
  (orderId: number): ActionResult<IAppState, Promise<void>, getOrderPackagesDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOrderPackagesDetailsActionTypes.REQUEST));
      const data = await BusinessPortalOrderService.getOrderPackagesDetails(orderId);
      dispatch(action(getOrderPackagesDetailsActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getOrderPackagesDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrderReports =
  (orderId: number): ActionResult<IAppState, Promise<void>, getOrderReportsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOrderReportsActionTypes.REQUEST));
      const data = await BusinessPortalOrderService.getReports(orderId);
      dispatch(action(getOrderReportsActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getOrderReportsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrderStatuses =
  (orderId: number): ActionResult<IAppState, Promise<void>, getOrderStatusesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOrderStatusesActionTypes.REQUEST));
      const data = await BusinessPortalOrderService.getOrderStatuses(orderId);
      dispatch(action(getOrderStatusesActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getOrderStatusesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const downloadSubmissionForm =
  (orderId: number): ActionResult<IAppState, Promise<void>, downloadOrderSubmissionFormActions> =>
  async (dispatch) => {
    try {
      dispatch(action(downloadOrderSubmissionFormActionTypes.REQUEST));
      const data = await BusinessPortalOrderService.downloadSubmissionForm(orderId);
      dispatch(action(downloadOrderSubmissionFormActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(downloadOrderSubmissionFormActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const downloadGroupSubmissionForm =
  (token: string): ActionResult<IAppState, Promise<void>, downloadOrderGroupSubmissionFormActions> =>
  async (dispatch) => {
    try {
      dispatch(action(downloadOrderGroupSubmissionFormActionTypes.REQUEST));
      const data = await BusinessPortalOrderService.downloadGroupSubmissionForm(token);
      dispatch(action(downloadOrderGroupSubmissionFormActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(downloadOrderGroupSubmissionFormActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteOrder =
  (orderId: number): ActionResult<IAppState, Promise<void>, deleteOrderActions> =>
  async (dispatch) => {
    dispatch(action(deleteOrderActionTypes.REQUEST));
    try {
      await BusinessPortalOrderService.deleteOrder(orderId);
      dispatch(action(deleteOrderActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteOrderActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetOrders = (): ActionResult<IAppState, void, BusinessPortalOrderActions> => (dispatch) => {
  dispatch(action(resetOrdersActionTypes.RESET));
};

export const resetOrderDetails = (): ActionResult<IAppState, void, BusinessPortalOrderActions> => (dispatch) => {
  dispatch(action(resetOrderDetailsActionTypes.RESET));
};
