import {IResponseError} from 'Common/models/IError';
import {FieldSpecificErrors} from 'Common/models/FieldSpecificErrors';

type ErrorsConverter<T, E> = (errors: FieldSpecificErrors<T>) => FieldSpecificErrors<E>;

export function getCommonErrors<T = undefined>(responseError: IResponseError<T> | undefined): string | null {
  const commonErrors: string[] = [];

  if (responseError && responseError.errors) {
    if (Array.isArray(responseError.errors)) {
      responseError.errors.forEach((x) => commonErrors.push(x.description));
      return commonErrors.join(', ');
    }
  }

  return null;
}

export function getFieldErrors<T>(responseError: IResponseError<T> | undefined): FieldSpecificErrors<T> | null;
export function getFieldErrors<T, E>(
  responseError: IResponseError<T> | undefined,
  converter?: ErrorsConverter<T, E>
): FieldSpecificErrors<E> | null;
export function getFieldErrors<T, E>(
  responseError: IResponseError<T> | undefined,
  converter?: ErrorsConverter<T, E>
): FieldSpecificErrors<T> | FieldSpecificErrors<E> | null {
  const errors: FieldSpecificErrors<T> = {};

  if (!responseError || !responseError.errors || Array.isArray(responseError.errors)) {
    return null;
  }

  for (const fieldName of Object.keys(responseError.errors)) {
    if ((responseError.errors as any)[fieldName]) {
      const correctFieldName =
        fieldName &&
        fieldName
          .split('.')
          .map((item) => item.charAt(0).toLowerCase() + item.slice(1))
          .join('.');
      errors[correctFieldName] = (responseError.errors as any)[fieldName].join(' ');
    }
  }

  return converter ? converter(errors) : errors;
}

// TODO: Probably this function should return string or be renamed
export function getErrorMessage(error: any) {
  if (!error) {
    return 'empty error';
  }

  if (error instanceof String) {
    return error;
  }

  if (error.response?.data) {
    return error.response.data;
  }

  return String(error);
}

export function getErrorCode(errorResponse: IResponseError) {
  return errorResponse?.errors?.[0]?.errorCode;
}

export function getErrorDetails<T = undefined>(responseError: IResponseError<T> | undefined): string[] | null {
  const errorDetails: any[] = [];
  if (responseError?.errors) {
    if (Array.isArray(responseError.errors)) {
      responseError.errors.forEach((x) => {
        if (Array.isArray(x.details)) {
          return x.details?.forEach((y) => errorDetails.push(y));
        } else if (typeof x.details === 'string') {
          return errorDetails.push(x.details);
        } else if (typeof x.details === 'object' && x.details !== null) {
          const newDetals = {};
          Object.keys(x.details).forEach((key) => (newDetals[key] = x.details[key]));
          return errorDetails.push(newDetals);
        }
      });

      return errorDetails;
    }
  }

  return null;
}

export function getStringErrorDetails<T = undefined>(responseError: IResponseError<T> | undefined): string | undefined {
  const errors = getErrorDetails(responseError);
  return errors
    ?.map((errorString) => (errorString.trim().slice(-1) === '.' ? errorString : `${errorString}.`))
    .join(' ');
}
