import {Reducer} from 'redux';

import {
  AdminOrganizationsActions,
  changeAdminOrganizationStatusActionTypes,
  getAdminOrganizationActionTypes,
  getAdminOrganizationAvatarActionTypes,
  getAdminOrganizationsActionTypes,
  IAdminOrganizationState,
  resetAdminOrganizationActionTypes,
} from '../types';

const initialState: IAdminOrganizationState['data'] = {
  adminOrganizations: [],
  adminOrganization: null,
  adminOrganizationAvatar: null,
};

export const dataAdminOrganizationsReducer: Reducer<IAdminOrganizationState['data'], AdminOrganizationsActions> = (
  state = initialState,
  action: AdminOrganizationsActions
) => {
  switch (action.type) {
    case getAdminOrganizationsActionTypes.SUCCESS: {
      return {...state, adminOrganizations: action.payload.data};
    }

    case getAdminOrganizationActionTypes.SUCCESS: {
      return {...state, adminOrganization: action.payload};
    }

    case changeAdminOrganizationStatusActionTypes.SUCCESS: {
      const {id} = action.payload;
      return {
        ...state,
        adminOrganizations: state.adminOrganizations.map((x) => (x.id !== id ? x : {...x, isActive: !x.isActive})),
      };
    }

    case getAdminOrganizationAvatarActionTypes.SUCCESS: {
      return {...state, adminOrganizationAvatar: action.payload};
    }

    case resetAdminOrganizationActionTypes.RESET: {
      return {
        ...state,
        adminOrganization: initialState.adminOrganization,
      };
    }

    default: {
      return state;
    }
  }
};
