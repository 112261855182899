import {combineReducers, Reducer} from 'redux';

import {IAdminPackagesState} from '../types';
import {communicationAdminPackagesReducer} from './communications';
import {dataAdminPackagesReducer} from './data';
import {uiAdminPackagesReducer} from './ui';

export const adminPackagesReducer: Reducer<IAdminPackagesState> = combineReducers<IAdminPackagesState>({
  communications: communicationAdminPackagesReducer,
  data: dataAdminPackagesReducer,
  ui: uiAdminPackagesReducer
});
