import React from 'react';

export interface IToggleChildrenProps {
  isOpen?: boolean;
}

export type RenderToggle = React.ReactElement<any> | ((props: IToggleChildrenProps) => React.ReactElement<any>);

interface IToggleProps {
  'aria-expanded': boolean;
  children: RenderToggle;
  onClick(): void;
}

export const Toggle = React.forwardRef((props: IToggleProps, ref) => {
  const {children, onClick} = props;

  const renderProps = children as IToggleProps['children'];
  const isOpen = props['aria-expanded'];

  if (typeof renderProps === 'function') {
    const el = renderProps({isOpen});

    return React.cloneElement(el, {
      ...el.props,
      ref,
      onClick
    });
  }

  return React.cloneElement(renderProps, {
    ...renderProps.props,
    ref,
    onClick
  });
});
