import {combineReducers} from 'redux';

import {
  changeCouponStatusActionTypes,
  CouponsActions,
  createCouponActionTypes,
  deleteCouponActionTypes,
  generateNewCodeActionTypes,
  getCouponByIdActionTypes,
  getCouponsActionTypes,
  IAdminCouponState,
  resetCouponDetailsActionTypes,
  updateCouponActionTypes,
} from 'Admin/AdminDashboard/store/adminCoupons/types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication/index';
import composeReducers from 'Common/store/composeReducers';

export const communicationCouponsReducer = combineReducers<IAdminCouponState['communications'], CouponsActions>({
  couponsLoading: makeCommunicationReducerFromEnum(getCouponsActionTypes),
  couponCreating: makeCommunicationReducerFromEnum(createCouponActionTypes),
  couponUpdating: makeCommunicationReducerFromEnum(updateCouponActionTypes),
  couponDeleting: makeCommunicationReducerFromEnum(deleteCouponActionTypes),
  changeCouponStatusRequesting: makeCommunicationReducerFromEnum(changeCouponStatusActionTypes),
  generateNewCodeRequesting: makeCommunicationReducerFromEnum(generateNewCodeActionTypes),
  couponDetailsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getCouponByIdActionTypes),
    makeResetCommunicationReducer(resetCouponDetailsActionTypes.RESET),
  ]),
});
