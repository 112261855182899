import React from 'react';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {FormikProps} from 'formik/dist/types';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {IFormValues, initialValue, validationSchema} from './validation';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {IAssociationPageGeneralInformation} from 'BusinessPortal/models/IAssociationPageGeneralInformation';
import {actions, selectors} from 'BusinessPortal/store/associationPage';
import {Nullable} from 'Common/types';
import {InputField, TextAreaField} from 'Common/components/FormFields';
import {Hint} from 'SequenceEditor/hooks/useSequenceEditor';
import styled from 'styled-components';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';

const DescriptionLabel = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  color: ${ColorPalette.black0};
  margin-bottom: 8px;
`;

interface IProps {
  generalInformation: Nullable<IAssociationPageGeneralInformation>;
}

type IExternalProps = IEditableSection<IProps>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const GeneralInformationEdit = (props: AllProps) => {
  const {isValid, isSubmitting} = props;
  const {onSuccess, generalInformationUpdating} = props;

  const isRequesting = generalInformationUpdating.isRequesting;

  useOnSuccessCommunication(generalInformationUpdating, () => onSuccess && onSuccess());
  useErrorCommunicationToToast(generalInformationUpdating);

  if (isRequesting) {
    return <Loading />;
  }

  return (
    <Form>
      <InputField
        isRequired={true}
        name="name"
        type="text"
        label="Association name"
        placeholder="Association name"
        autoComplete="off"
        memoized={true}
      />
      <InputField
        name="abbreviation"
        type="text"
        label="Abbreviation"
        placeholder="Abbreviation"
        autoComplete="off"
        memoized={true}
      />
      <InputField name="country" type="text" label="Country" placeholder="Country" autoComplete="off" memoized={true} />
      <InputField name="address" type="text" label="Address" placeholder="Address" autoComplete="off" memoized={true} />

      <DescriptionLabel>Description</DescriptionLabel>
      <Hint>Brief information about your association. Your description will appear in the association page header</Hint>
      <TextAreaField name="description" placeholder="Tell customers few words about your association" memoized={true} />

      <PrimaryButton className="w-100" size="medium" type="submit" disabled={!isValid} isLoading={isSubmitting}>
        Save changes
      </PrimaryButton>
    </Form>
  );
};

const GeneralInformationEditFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({data}) => data?.generalInformation || initialValue,
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {updateGeneralInformation} = formikBag.props;
    await updateGeneralInformation(values);
  },
  enableReinitialize: true,
})(GeneralInformationEdit);

const mapStateToProps = (state: IAppState) => ({
  generalInformationUpdating: selectors.selectCommunication(state, 'generalInformationUpdating'),
});

const mapDispatchToProps = {
  updateGeneralInformation: actions.updateGeneralInformation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(GeneralInformationEditFormik);
