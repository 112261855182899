import * as Yup from 'yup';

import IFoundExcludeHorse from 'ExcludeHorse/models/IFoundExcludeHorse';

export interface IFoundHorseForm extends IFoundExcludeHorse {
  isDeleted: boolean;
}

export interface IFormValues {
  excludedHorses: IFoundHorseForm[];
}

export const initialValue: IFormValues = {
  excludedHorses: [],
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  excludedHorses: Yup.array(),
});

export const convertFoundHorsesToFormValues = (values: IFoundExcludeHorse[]): IFormValues => {
  return {excludedHorses: values.map((x) => ({...x, isDeleted: false}))};
};
