import {IOrderDetails} from 'Admin/AdminDashboard/models/Order/IOrderDetails';
import {IOrderHistory} from 'Admin/AdminDashboard/components/shared/OrderHistory/parts/common';
import {IOrderHorseStatuses} from 'Admin/AdminDashboard/models/Order/IOrderHorseStatuses';
import {IOrderStatused} from 'Common/models/IOrderStatused';
import {OrderStatus} from 'Common/constants/OrderStatus';

export function convertStatusDetails(
  orderId: number,
  horseId: number,
  statuses: IOrderHorseStatuses[]
): IOrderStatused {
  const item = statuses.find((x) => x.orderId === orderId && x.horseId === horseId)?.statuses;

  return (
    item || {
      status: OrderStatus.orderPlaced,
      hasNotResults: false,
      hasPayment: false,
      hasRelease: false,
      hasSample: false,
    }
  );
}

export function convertOrderDetailsToOrderHistory(
  orderDetails: IOrderDetails,
  horseStatuses: IOrderHorseStatuses[]
): IOrderHistory {
  return {
    ...orderDetails,
    horses: orderDetails.horsesDetails.map((x) => ({
      ...x,
      ...convertStatusDetails(orderDetails.id, x.id, horseStatuses),
    })),
  };
}
