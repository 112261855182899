import {Reducer} from 'redux';

import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';
import {
  AdminAssociationOwnerActions,
  getAdminAssociationOwnersActionTypes,
  IAdminAssociationOwnerState,
} from '../types';

const initialState: IAdminAssociationOwnerState['ui'] = {
  pagination: {countPerPage: MIN_COUNT_PER_PAGE, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiAdminAssociationOwnerReducer: Reducer<IAdminAssociationOwnerState['ui'], AdminAssociationOwnerActions> =
  (state = initialState, action: AdminAssociationOwnerActions) => {
    switch (action.type) {
      case getAdminAssociationOwnersActionTypes.SUCCESS: {
        return {...state, pagination: action.payload.pagination};
      }
      default: {
        return state;
      }
    }
  };
