import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

const STATE_NAME = 'signup';

export interface ISignupState {
  communications: {
    signUpLoading: ICommunication;
  };
}

export const SignupActionTypes = makeCommunicationActionType(STATE_NAME, 'SIGNUP');
export type SignupActions = MakeCommunicationActions<typeof SignupActionTypes, {}>;

export type signupActions = SignupActions;
