import {size} from 'Common/constants/Breakpoints';
import {IColoredIconProps} from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';

export const mediumMdSm = size.md - (size.md - size.sm) / 2;

export const mediumMdSmBreakpoint = `(min-width: ${mediumMdSm}px)`;

export const mediumMdSmQuery = `(min-width: ${size.sm}px) and (max-width: ${mediumMdSm - 1}px)`;

export const userProfileMinDesktopQuery = `(min-width: ${size.lg}px)`;

export const addToFavoriteIconProps: IColoredIconProps = {name: IconName.Star, color: ColorPalette.yellow9, size: 16};

export const deleteToFavoriteIconProps: IColoredIconProps = {
  name: IconName.Star,
  color: ColorPalette.white1,
  size: 16,
  fill: false
};
