import React, {memo} from 'react';
import styled from 'styled-components';

import {hexToRgba} from 'Common/helpers/hexToRgba';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {AncestryReportSection} from './common/styled';

const links = [
  {
    name: 'Click here for more about Ancestry/Composition Testing',
    url: 'https://www.etalondx.com/equine-ancestry-horse-composition',
  },
  {
    name: 'Click here for more about Color and Health Variant Testing',
    url: 'https://www.etalondx.com/horse-genes-coat-color',
  },
  {name: 'Click here for more about how colors work', url: 'https://www.etalondx.com/horse-color-simulator'},
  {name: 'Click here for more about Etalon Genetic Services', url: 'https://etalondiagnostics.com/pages/tests'},
  {
    name: 'Click here to request a genetic consult call about your results',
    url: 'https://etalondiagnostics.com/pages/contact',
  },
];

export const Header = styled.h3`
  font-family: ${Theme.font.secondary};
  padding: 32px 0 16px 0;
  font-size: ${Typography.size.size25};
  font-weight: ${Typography.weight.bold700};
  margin: 0;
`;

export const LinkWrapper = styled.div`
  padding: 8px 0;
`;

export const Link = styled.a`
  display: inline-block;
  text-decoration: none;
  border: 2px solid ${hexToRgba(Theme.color.primary, 0.2)};
  padding: 16px 24px;
  border-radius: 60px;
  color: ${Theme.color.primary};
  font-weight: bold;
`;

const GlossaryOfTermsLinks = () => (
  <AncestryReportSection className="d-flex w-100 text-center flex-column">
    <Header>Links for additional information and services</Header>
    {links.map((link, i) => (
      <LinkWrapper className="d-flex justify-content-center" key={i}>
        <Link href={link.url}>{link.name}</Link>
      </LinkWrapper>
    ))}
  </AncestryReportSection>
);

export default memo(GlossaryOfTermsLinks);
