import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import * as Yup from 'yup';

const BOOLEAN_REQUIRED = 'Must be checked to assure your agreement with this transaction';

export interface IFormValues {
  horseId: number;
  providedFirstName: string;
  providedLastName: string;
  providedEmail: string;
  providedPhone: string;
  signature: string;

  releaseHorseProfile: boolean;
  releaseHorseResults: boolean;
  releaseHorseSample: boolean;
  isAgreeStatements: boolean;
  isAgreeRights: boolean;
  isSubmitRequest: boolean;
  horseName: string;
  defaultHorseName: string;
}

export const initialValue: IFormValues = {
  horseId: 0,
  providedFirstName: '',
  providedLastName: '',
  providedEmail: '',
  providedPhone: '',
  signature: '',

  releaseHorseProfile: false,
  releaseHorseResults: false,
  releaseHorseSample: false,
  isAgreeStatements: false,
  isAgreeRights: false,
  isSubmitRequest: false,
  horseName: '',
  defaultHorseName: '',
};

export const validationSchema = Yup.object().shape<IFormValues>({
  horseId: Yup.number().required(REQUIRED_FIELD),
  providedFirstName: Yup.string().required(REQUIRED_FIELD),
  providedLastName: Yup.string().required(REQUIRED_FIELD),
  providedEmail: Yup.string().email('Email not valid').required(REQUIRED_FIELD),
  providedPhone: Yup.string().required(REQUIRED_FIELD),
  signature: Yup.string().required(REQUIRED_FIELD),
  releaseHorseProfile: Yup.boolean().required(BOOLEAN_REQUIRED).oneOf([true], BOOLEAN_REQUIRED),
  releaseHorseResults: Yup.boolean().required(BOOLEAN_REQUIRED).oneOf([true], BOOLEAN_REQUIRED),
  releaseHorseSample: Yup.boolean().required(BOOLEAN_REQUIRED).oneOf([true], BOOLEAN_REQUIRED),
  isAgreeStatements: Yup.boolean().required(BOOLEAN_REQUIRED).oneOf([true], BOOLEAN_REQUIRED),
  isAgreeRights: Yup.boolean().required(BOOLEAN_REQUIRED).oneOf([true], BOOLEAN_REQUIRED),
  isSubmitRequest: Yup.boolean().required(BOOLEAN_REQUIRED).oneOf([true], BOOLEAN_REQUIRED),
  horseName: Yup.string()
    .required(REQUIRED_FIELD)
    .test('Horse name checking', 'Please type correct Horse name', function (value) {
      return value === this.parent.defaultHorseName;
    }),
  defaultHorseName: Yup.string(),
});
