import {IModule} from 'redux-dynamic-modules';

import {IAdministratorState} from './types';
import {administratorsReducer} from './reducer';

export interface IAdministratorsModuleState {
  adminAdministrators: IAdministratorState;
}

export const AdministratorsModule: IModule<IAdministratorsModuleState> = {
  id: 'adminAdministrators',
  reducerMap: {
    adminAdministrators: administratorsReducer
  }
};
