import * as React from 'react';
import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import {HeaderTitle, Value} from 'Admin/common/styled/StyledComponents';

const DetailsRoot = styled.div`
  padding-left: 20px;
  font-family: ${Typography.family.openSans};
`;

const Section = styled.div`
  margin-top: 20px;
  font-weight: 600;
`;

interface IProps {
  records: IDetailRecord[];
  rootClassName?: string;
  sectionStyle?: React.CSSProperties;
}

interface IDetailRecord {
  title: string;
  value?: string | string[] | React.ReactElement;
}

export default React.memo(function Details(props: IProps) {
  const {records, rootClassName, sectionStyle} = props;
  return (
    <DetailsRoot className={rootClassName}>
      {records.map(({title, value}) => (
        <Section key={title} style={sectionStyle}>
          <HeaderTitle>{title}</HeaderTitle>
          {Array.isArray(value) ? value.map((v, i) => <Value key={i}>{v}</Value>) : <Value>{value}</Value>}
        </Section>
      ))}
    </DetailsRoot>
  );
});
