import React, {memo, useCallback} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import ReactDatetime, {ViewMode, DatetimepickerProps} from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment, {Moment} from 'moment';
import InputMask from 'react-input-mask';

import ColorPalette from 'Common/constants/ColorPalette';
import {inputCss} from 'Common/components/Controls/styles';
import {IconName} from 'Icon/components/Icon';
import {FRONTEND_DATE, FRONTEND_TIME} from 'Common/constants/Date';
import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import FieldControlContainer from '../Layout/FieldControlContainer';
import ColoredIcon from 'Icon/components/ColoredIcon';

const InputGroup = styled.div`
  position: relative;
  input {
    ${inputCss};
    padding-right: 40px;
    height: 44px;
  }
`;

const CalendarIcon = styled(ColoredIcon)<{disabled?: boolean}>`
  position: absolute;
  right: 12px;
  top: 8px;
  z-index: 0;
  cursor: ${props => (props.disabled ? 'unset' : 'pointer')};
`;

const TypedDateTime = ReactDatetime as React.ComponentType<
  ReactDatetime.DatetimepickerProps & {
    renderInput?: (props: any, openCalendar: () => void, closeCalendar: () => void) => void;
    closeOnTab?: boolean;
  }
>;

interface IOwnProps {
  type?: string;
  showTime?: boolean;
  futureEnabled?: boolean;
  isValidDate?: (date: Date) => boolean;
  viewMode?: ViewMode;
  dateFormat?: string | boolean;
  timeFormat?: string | boolean;
  iconName?: IconName;
  value: Moment | string;
  onChange: DatetimepickerProps['onChange'];
  onBlur?: DatetimepickerProps['onBlur'];
}

export type Props = IBaseControlProps & IOwnProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

function DateTime(props: Props) {
  const {
    isValidDate,
    viewMode,
    showTime,
    timeFormat,
    dateFormat,
    disabled,
    placeholder,
    futureEnabled,
    onChange,
    onBlur,
    value
  } = props;

  const inputProps = React.useMemo(
    () => ({
      className: classnames('form-control'),
      placeholder: placeholder ?? FRONTEND_DATE,
      disabled
    }),
    [placeholder, disabled]
  );

  const checkIsValidDate = useCallback(
    (current: Date) => {
      if (futureEnabled) {
        return true;
      }
      const now = moment();
      return moment(current).isBefore(now);
    },
    [futureEnabled]
  );

  const renderMaskedInput = useCallback(
    (props: any, openCalendar: () => void, closeCalendar: () => void) => {
      const focusDatePicker = () => {
        if (!disabled) {
          openCalendar();
        }
      };

      const maskOfInput = showTime ? '99-99-9999 99:99 aa' : '99-99-9999';

      const fakeEvent = () => {
        return;
      };

      return (
        <div>
          <InputMask mask={maskOfInput} {...props} onInput={closeCalendar} onClick={fakeEvent} onFocus={fakeEvent} />
          <CalendarIcon
            disabled={disabled}
            name={props.iconName ? props.iconName : IconName.Calendar}
            size={24}
            onClick={focusDatePicker}
            color={ColorPalette.gray3}
            hoverColor={ColorPalette.black1}
            fill={true}
            stroke={false}
          />
        </div>
      );
    },
    [disabled, showTime]
  );

  return (
    <FieldControlContainer {...props}>
      <div className={classnames(props.inputClassName)} style={props.inputStyle}>
        <InputGroup>
          <TypedDateTime
            inputProps={inputProps}
            dateFormat={dateFormat || FRONTEND_DATE}
            timeFormat={showTime ? timeFormat || FRONTEND_TIME : false}
            isValidDate={isValidDate || checkIsValidDate}
            viewMode={viewMode}
            closeOnSelect={true}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            renderInput={renderMaskedInput}
          />
        </InputGroup>
      </div>
    </FieldControlContainer>
  );
}

export default memo(DateTime);
export type DateTimeProps = Props;
