import {useCallback} from 'react';

import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {ICommunication} from 'Common/store/utils/communication/types';
import {useToast} from 'Common/helpers/hooks/useToast';

export function useErrorCommunicationToToast(
  communication: ICommunication,
  message?: string,
  toastId?: string | number
) {
  const {addToast} = useToast(toastId);

  const errorInfo = communication.error;

  const onError = useCallback(() => {
    if (message) {
      addToast(message, 'error');
      return;
    }

    const commonErrors = getCommonErrors(errorInfo);
    if (commonErrors) {
      addToast(commonErrors, 'error');
    }
  }, [errorInfo, addToast, message]);

  useOnErrorCommunication(communication, onError);
}
