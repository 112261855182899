import React, {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

import {actions, selectors} from 'Admin/AdminSettings/stores/adminHtmlTemplates/index';
import {HtmlTemplateType} from 'Admin/AdminSettings/constants/HtmlTemplateType';
import {ModalWindowButton, ModalWindowFooter, ModalWindowHeader} from 'Common/components/Modal/shared';
import Loading from 'Loading/components/Loading';

import {IAppState} from 'Common/store/IAppState';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {TextArea} from 'Common/components/Controls';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import {copyToClipboard} from 'Common/helpers/copyToClipboard';
import {FieldLabel, FieldValue} from 'Admin/common/styled/StyledComponents';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import CopyZone from 'Common/components/CopyZone/CopyZone';

const textAreaStyle = {minHeight: 200};
const iconCopy = {name: IconName.FileCopy, stroke: false};

const Root = styled.div`
  margin: 24px 24px 24px 40px;
`;

const PreviewScroll = styled(Scrollbar)`
  height: 30vh !important;
  max-height: 548px;
  border: 1px solid ${ColorPalette.gray48};
  border-radius: 4px;
`;

export const Field = styled.div`
  margin-bottom: 16px;
`;

const Button = styled(IconButton)`
  margin-left: 8px;
`;

interface IProps {
  type?: HtmlTemplateType;
  name?: string;
  onClose(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IProps;

function HtmlTemplateView(props: AllProps) {
  const {getHtmlTemplate, resetTemplateResults, onClose, templateLoading, type, name, template} = props;
  const isRequesting = templateLoading.isRequesting;

  const [templateText, setTemplateTExt] = useState('');

  const htmlPreview = parse(DOMPurify.sanitize(templateText));

  useEffect(() => {
    if (type) {
      getHtmlTemplate(type, name);
    }

    return () => {
      resetTemplateResults();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTemplateTExt(template);
  }, [template]);

  useErrorCommunicationToToast(templateLoading);

  const handleOnCopy = useCallback(() => {
    copyToClipboard(templateText);
  }, [templateText]);

  return (
    <>
      <ModalWindowHeader>View template</ModalWindowHeader>
      {isRequesting && <Loading />}
      <Root>
        <div className="row">
          <Field className="col-6">
            <FieldLabel>Type</FieldLabel>
            {type && (
              <div className="d-flex align-items-center">
                <CopyZone
                  className="d-flex align-items-center"
                  textToCopy={type}
                  successfulMessage="Type has been copied to your clipboard"
                >
                  <FieldValue>{type}</FieldValue>
                  <Button name={IconName.FileCopy} size={16} stroke={false} fill={true} color={ColorPalette.gray44} />
                </CopyZone>
              </div>
            )}
          </Field>
          <Field className="col-6">
            <FieldLabel>Name</FieldLabel>
            {name && (
              <div className="d-flex align-items-center">
                <CopyZone
                  className="d-flex align-items-center"
                  textToCopy={name}
                  successfulMessage="Name has been copied to your clipboard"
                >
                  <FieldValue>{name}</FieldValue>
                  <Button name={IconName.FileCopy} size={16} stroke={false} fill={true} color={ColorPalette.gray44} />
                </CopyZone>
              </div>
            )}
          </Field>
        </div>

        <TextArea label="Template" value={templateText} readOnly={true} inputStyle={textAreaStyle} />

        <FieldControlContainer label="Preview">
          <PreviewScroll autoHeight={false}>
            <div>{htmlPreview}</div>
          </PreviewScroll>
        </FieldControlContainer>

        <ModalWindowFooter>
          <ModalWindowButton type="button" variant="outlined" onClick={handleOnCopy} icon={iconCopy}>
            Copy
          </ModalWindowButton>

          <ModalWindowButton type="button" onClick={onClose}>
            Close
          </ModalWindowButton>
        </ModalWindowFooter>
      </Root>
    </>
  );
}

const mapStateToProps = (state: IAppState) => ({
  template: selectors.selectHtmlTemplate(state),
  templateLoading: selectors.selectCommunication(state, 'templateLoading'),
});

const mapDispatchToProps = {
  getHtmlTemplate: actions.getHtmlTemplate,
  resetTemplateResults: actions.resetTemplateResults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(HtmlTemplateView));
