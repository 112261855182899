import {memo, useCallback, useEffect, useMemo} from 'react';

import withOrderDetailsActions, {
  IWithOrderDetailsActionsProps,
} from 'Admin/AdminDashboard/helpers/withOrderDetailsActions';
import {OrderStatusList} from 'Admin/shared/components/OrderStatus';
import {IAdditionalButton} from 'Admin/shared/components/OrderStatus/parts/OrderStatusDropdownView';
import {OrderStatus} from 'Common/constants/OrderStatus';
import Loading from 'Loading/components/Loading';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';
import {IOrderHorseStatuses} from 'Admin/AdminDashboard/models/Order/IOrderHorseStatuses';
import {findStatusByOrderIdHorseId} from 'Admin/AdminDashboard/helpers/findStatusByOrderIdHorseId';

interface IOwnProps {
  orderId: number;
  horseId: number;
  statusItemsFunc: {};
  isClearStatuses?: boolean;
  openRequiredTestsModal(): void;
}

type Props = IWithOrderDetailsActionsProps & IOwnProps;

function StatusDetails(props: Props) {
  const {
    getOrderStatusesDetails,
    orderId,
    horseId,
    orderStatusesDetails,
    orderStatusesDetailsLoading,
    setSampleStatusRequesting,
    statusItemsFunc,
    openRequiredTestsModal,
    resetOrderStatusDetails,
    isClearStatuses,
  } = props;

  const {emit} = useEventBus();

  const orderStatuses = findStatusByOrderIdHorseId(horseId, orderId, orderStatusesDetails);

  const getStatusDetails = useCallback(() => {
    if (!!orderId && !!horseId) {
      getOrderStatusesDetails(orderId, horseId);
    }
  }, [getOrderStatusesDetails, horseId, orderId]);

  useEffect(() => {
    if (!orderStatuses) {
      getStatusDetails();
    }
  }, [getStatusDetails, orderStatuses]);

  useEffect(() => {
    return () => {
      if (isClearStatuses) {
        resetOrderStatusDetails();
      }
    };
  }, [isClearStatuses, resetOrderStatusDetails]);

  useEffect(() => {
    if (orderStatuses) {
      emit<IOrderHorseStatuses>(EventName.OrderStatusChanged, {
        orderId,
        horseId,
        statuses: orderStatuses.statuses,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatuses]);

  const unCompletedStatuses = useMemo(() => {
    const uncompleted = [];
    if (orderStatuses?.statuses.hasNotResults) {
      uncompleted.push(OrderStatus.resultsReady);
    }
    if (!orderStatuses?.statuses.hasSample) {
      uncompleted.push(OrderStatus.sampleReceived);
    }
    return uncompleted;
  }, [orderStatuses]);

  const additionalButtons: Partial<Record<OrderStatus, IAdditionalButton>> = {
    [OrderStatus.sampleReceived]: {name: 'Check results', onClick: openRequiredTestsModal},
  };

  const isLoading = orderStatusesDetailsLoading.isRequesting;

  return (
    <div className="position-relative">
      {isLoading && <Loading />}
      {orderStatuses && (
        <OrderStatusList
          status={orderStatuses.statuses.status}
          onChangeOrderStatus={statusItemsFunc[orderStatuses.statuses.status]}
          isLoading={setSampleStatusRequesting.isRequesting}
          completedStatuses={orderStatuses.statuses.hasSample ? OrderStatus.sampleReceived : undefined}
          uncompletedStatuses={unCompletedStatuses}
          additionalButtons={additionalButtons}
          statusUpdateDates={orderStatuses.statuses.statusUpdateDates}
        />
      )}
    </div>
  );
}

export default memo(withOrderDetailsActions(StatusDetails));
