import React, {useCallback} from 'react';

import {IAdminActionsProps} from 'Admin/AdminDashboard/helpers/hooks/common';
import {useConfirmModal} from 'Common/helpers/hooks/useConfirmModal';

const CONFIRM_SYNC_FROM_STRIPE =
  'All information about subscriptions for this user will be requested from Stripe and updated. Are you sure you want to overwrite all subscriptions?';
const CONFIRM_CANCEL_SUBSCRIPTION = 'Are you sure you want to cancel the subscription?';

export interface ISyncSubscriptionActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(userId: number): void;
}

export interface ICancelSubscriptionActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(userId: number, subscriptionId: number): void;
}

interface ISubscriptionActionsPartsProps {
  syncSubscriptionHandler: Omit<ISyncSubscriptionActionProps, 'onSuccess'>;
  cancelSubscriptionHandler: Omit<ICancelSubscriptionActionProps, 'onSuccess'>;
}

interface IProps {
  data: ISubscriptionActionsPartsProps;
  onSuccess(): void;
}

export function useSubscriptionActions(props: IProps) {
  const {
    onSuccess,
    data: {syncSubscriptionHandler, cancelSubscriptionHandler}
  } = props;

  const onSyncSubscriptions = useCallback(
    async (userId: number) => {
      await syncSubscriptionHandler.action(userId);
      onSuccess();
    },
    [onSuccess, syncSubscriptionHandler]
  );

  const onCancelSubscription = useCallback(
    async (userId: number, id: number) => {
      await cancelSubscriptionHandler.action(userId, id);
      onSuccess();
    },
    [onSuccess, cancelSubscriptionHandler]
  );

  const {openConfirmModal: openSyncSubscriptionsModal, confirmModal: confirmSyncSubscriptionsModal} = useConfirmModal({
    confirmDescription: CONFIRM_SYNC_FROM_STRIPE,
    confirmCommunication: syncSubscriptionHandler.communication,
    successMessage: 'Subscriptions successfully updated',
    onConfirmAction: onSyncSubscriptions,
    confirmId: 'syncConfirm'
  });

  const {openConfirmModal: openCancelSubscriptionModal, confirmModal: confirmCancelSubscriptionModal} = useConfirmModal(
    {
      confirmDescription: CONFIRM_CANCEL_SUBSCRIPTION,
      confirmCommunication: cancelSubscriptionHandler.communication,
      successMessage: 'Subscription successfully canceled',
      onConfirmAction: onCancelSubscription,
      confirmId: 'cancelConfirm'
    }
  );

  const subscriptionActionsModal = (
    <>
      {confirmSyncSubscriptionsModal}
      {confirmCancelSubscriptionModal}
    </>
  );

  return {
    subscriptionActionsModal,
    openSyncSubscriptionsModal,
    openCancelSubscriptionModal
  };
}
