import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import PermissionsService from 'Permissions/services/PermissionsService';

import {
  resetCurrentPermissionsActionTypes,
  getCurrentPermissionsActionTypes,
  IPermissionsState,
  PermissionsActions,
} from './types';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const getCurrentPermissions =
  (): ActionResult<IPermissionsState, Promise<void>, PermissionsActions> => async (dispatch) => {
    dispatch(action(getCurrentPermissionsActionTypes.REQUEST));
    try {
      const permissions = await PermissionsService.getCurrentPermissions();
      dispatch(action(getCurrentPermissionsActionTypes.SUCCESS, permissions.data));
    } catch (error) {
      dispatch(action(getCurrentPermissionsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetCurrentPermissions = (): ActionResult<IAppState, void, PermissionsActions> => (dispatch) => {
  dispatch(action(resetCurrentPermissionsActionTypes.RESET));
};
