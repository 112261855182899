import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import {IAncestryPopulationComparison} from 'OnlineReport/models/shared/IAncestryPopulationComparison';
import {IAncestryProbabilities} from 'OnlineReport/models/shared/IAncestryProbabilities';
import {getApiByVisitorType} from 'Common/helpers/getApiByVisitorType';
import {VisitorType} from 'Common/constants/VisitorType';
import {IAncestryRelatedHorse} from 'OnlineReport/models/Ancestry/IAncestryRelatedHorse';
import {IAncestryKinshipHorse} from 'OnlineReport/models/Ancestry/IAncestryKinshipHorse';
import {IAncestryPrivacy} from 'OnlineReport/models/Ancestry/IAncestryPrivacy';

const getPopulation = async (horseId: number): Promise<IAncestryPopulation[]> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AncestryBreeds`,
    [VisitorType.AssociationEmployee]: `/AncestryBreedsAssociation`,
  });

  const result = await axiosWrapper.get<IListResponse<IAncestryPopulation>>(`${api}/${horseId}`);
  return result.data.data;
};

const getPopulationComparison = async (horseId: number): Promise<IAncestryPopulationComparison> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AncestryPcaPoint`,
    [VisitorType.AssociationEmployee]: `/AncestryPcaPointAssociation`,
  });

  const result = await axiosWrapper.get<IObjectResponse<IAncestryPopulationComparison>>(`${api}/${horseId}`);
  return result.data.data;
};

const getAncestryProbabilities = async (horseId: number): Promise<IAncestryProbabilities> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AncestryProbabilities`,
    [VisitorType.AssociationEmployee]: `/AncestryProbabilitiesAssociation`,
  });

  const result = await axiosWrapper.get<IObjectResponse<IAncestryProbabilities>>(`${api}/${horseId}`);
  return result.data.data;
};

const getAncestryRelatedHorses = async (horseId: number): Promise<IAncestryRelatedHorse[]> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AncestryRelatedUser`,
    [VisitorType.AssociationEmployee]: `/AncestryRelatedAssociation`,
  });

  const result = await axiosWrapper.get<IListResponse<IAncestryRelatedHorse>>(`${api}/${horseId}`);
  return result.data.data;
};

const getAncestryKinshipHorses = async (horseId: number): Promise<IAncestryKinshipHorse[]> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AncestryKinshipUser`,
    [VisitorType.AssociationEmployee]: `/AncestryKinshipAssociation`,
  });

  const result = await axiosWrapper.get<IListResponse<IAncestryKinshipHorse>>(`${api}/${horseId}`);
  return result.data.data;
};

const getAncestryHorsePrivacy = async (privateId: string): Promise<IAncestryPrivacy> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AncestryPrivacyValidateUser`,
    [VisitorType.AssociationEmployee]: `/AncestryPrivacyValidateEmployee`,
  });

  const result = await axiosWrapper.get<IObjectResponse<IAncestryPrivacy>>(api, {params: {privateId}});
  return result.data.data;
};

const sendHorsePublicityRequest = async (privateId: string, message: string): Promise<void> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/HorsePublicityRequest`,
    [VisitorType.AssociationEmployee]: `/HorsePublicityRequestAssociation`,
  });

  const result = await axiosWrapper.post<IObjectResponse<void>>(api, {privateId, message});
  return result.data.data;
};

export default {
  getPopulation,
  getPopulationComparison,
  getAncestryProbabilities,
  getAncestryRelatedHorses,
  getAncestryKinshipHorses,
  getAncestryHorsePrivacy,
  sendHorsePublicityRequest,
};
