import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {IOnSuccessedEntity} from 'Common/models/IOnSuccessedEntity';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';

export type ButtonPosition = 'Right' | 'Left';

const defaultIconProps = {size: 32, fill: false, stroke: false};

const ClickableArea = styled.div<{position: string}>`
  cursor: pointer;
  flex-direction: ${({position}) => (position === 'Left' ? 'row' : 'row-reverse')};
`;

const Text = styled.div`
  margin: 0 8px;
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${Theme.color.white};
`;

interface IProps extends IOnSuccessedEntity {
  position: ButtonPosition;
  isActive: boolean;
  isShowLabel?: boolean;
  isShowShadow?: boolean;
  labelActive: string;
  labelInactive: string;
  iconNameActive: IconName;
  iconNameActiveHover: IconName;
  iconNameInactive: IconName;
  iconNameInactiveHover: IconName;

  onActiveAction(e: any): void;
  onInactiveAction(e: any): void;
}

function TwoActionButton(props: IProps) {
  const {
    isActive,
    onSuccess,
    position,
    isShowLabel = true,
    labelActive,
    labelInactive,
    iconNameActive,
    iconNameActiveHover,
    iconNameInactive,
    iconNameInactiveHover,
    onActiveAction,
    onInactiveAction
  } = props;

  const [isHover, setHover] = useState(false);

  const onActionHandler = useCallback(
    async e => {
      isActive ? await onActiveAction(e) : await onInactiveAction(e);
      onSuccess && onSuccess();
    },
    [isActive, onInactiveAction, onActiveAction, onSuccess]
  );

  const handleOnMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const handleOnMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <>
      <ClickableArea
        className="d-flex align-items-center justify-content-start"
        position={position}
        onClick={onActionHandler}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        {isHover ? (
          <ColoredIcon {...defaultIconProps} name={isActive ? iconNameActiveHover : iconNameInactiveHover} />
        ) : (
          <ColoredIcon {...defaultIconProps} name={isActive ? iconNameActive : iconNameInactive} />
        )}
        {isShowLabel && <Text>{isActive ? labelActive : labelInactive}</Text>}
      </ClickableArea>
    </>
  );
}

export default memo(TwoActionButton);
