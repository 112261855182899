import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

import {
  getAdminNotificationsActionTypes,
  IAdminNotificationState,
  updateAdminNotificationsActionTypes,
  AdminNotificationsActions
} from '../types';

export const communicationAdminNotificationsReducer = combineReducers<
  IAdminNotificationState['communications'],
  AdminNotificationsActions
>({
  adminNotificationsLoading: makeCommunicationReducerFromEnum(getAdminNotificationsActionTypes),
  adminNotificationsUpdating: makeCommunicationReducerFromEnum(updateAdminNotificationsActionTypes)
});
