import React, {memo} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import Theme from 'Common/constants/Theme';

import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import Typography from 'Common/constants/Typography';

const InputLayout = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .custom-control-input ~ .custom-control-label::before {
    border: 2px solid ${Theme.color.black};
  }
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:active ~ .custom-control-label::before {
    border-color: ${Theme.color.primary};
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-color: ${Theme.color.black};
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0px 0px 0px 2px ${Theme.color.control.shadow};
  }
  .custom-control-input:disabled ~ .custom-control-label::before,
  .custom-control-input:disabled ~ .custom-control-label::after {
    opacity: 0.4;
    pointer-events: none;
  }
`;

const StyledLabel = styled.div`
  ::before,
  ::after {
    background-image: none !important;
    border-radius: 50%;
    pointer-events: unset;
  }

  ::before {
    top: 1px;
    left: -22px;
    width: 16px;
    height: 16px;
    background-color: unset !important;
  }

  ::after {
    top: 5px;
    left: -18px;
    width: 8px;
    height: 8px;
  }

  padding-left: 4px;
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size16};
  line-height: ${Typography.size.size18};
`;

interface IOwnProps {
  value?: string | number;
  checked?: boolean;
}

type Props = IBaseControlProps & IOwnProps & React.HTMLAttributes<HTMLInputElement>;

function Radiobutton(props: Props) {
  const {className, style, inputStyle, value, checked, inputClassName, labelClassName, labelStyle, label, ...other} =
    props;

  return (
    <InputLayout className={classnames('custom-control', 'custom-radio', className)} style={style}>
      <label>
        <input
          type="radio"
          className={classnames('custom-control-input', inputClassName)}
          style={inputStyle}
          {...other}
          value={value}
          checked={checked && !other.disabled}
        />
        <StyledLabel className={classnames('custom-control-label', labelClassName)} style={labelStyle}>
          {label}
        </StyledLabel>
      </label>
    </InputLayout>
  );
}

export default memo(Radiobutton);
export type RadioButtonProps = Props;
