import {IAddress} from 'Common/models/IAddress';

export enum GoogleAddressType {
  Zip = 'postal_code',
  HouseNumber = 'street_number',
  Street = 'route',
  City = 'locality',
  State = 'administrative_area_level_1',
  Country = 'country'
}

export const parseGoogleAddress = (address: google.maps.GeocoderAddressComponent[]): IAddress => {
  const parsedAddress: IAddress = {
    country: '',
    state: '',
    city: '',
    street: '',
    zip: ''
  };

  address.map(component => {
    return component.types.map(type => {
      switch (type) {
        case GoogleAddressType.Zip:
          parsedAddress.zip = component.short_name;
          break;
        case GoogleAddressType.HouseNumber:
          parsedAddress.street = component.short_name;
          break;
        case GoogleAddressType.Street:
          parsedAddress.street += parsedAddress.street.length > 0 ? ` ${component.short_name}` : component.short_name;
          break;
        case GoogleAddressType.City:
          parsedAddress.city = component.short_name;
          break;
        case GoogleAddressType.State:
          parsedAddress.state = component.short_name;
          break;
        case GoogleAddressType.Country:
          parsedAddress.country = component.short_name;
          break;
      }
      return 0;
    });
  });

  return parsedAddress;
};
