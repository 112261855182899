import React, {useCallback} from 'react';
import styled from 'styled-components';

import {DropDownMenu} from 'Common/components/DropDown/index';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {withCurrency} from 'Common/helpers/withCurrency';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IOrderPriceSummaryAdmin} from 'Admin/AdminDashboard/models/Order/IOrderPriceSummaryAdmin';
import useOnUpdateOrder from 'Admin/AdminDashboard/components/Orders/OrderHorses/useOnUpdateOrder';
import withDate from 'Common/helpers/withDate';
import {useUpdatePaymentModal} from 'Admin/shared/helpers/hooks/useOrderActions/useUpdatePaymentModal';

const menuItems = [
  {label: 'Update payment', value: 'updatePayment', icon: {name: IconName.Money, color: ColorPalette.red7}},
];

const PriceDetailsRoot = styled.div`
  position: relative;
  padding: 10px 25px 10px 85px;
  background-color: ${ColorPalette.gray22};
`;

const FieldTitle = styled.div`
  font-weight: 600;
  font-size: ${Typography.size.size14};
  color: ${ColorPalette.gray23};
`;

const FieldValue = styled.div<{alignRight?: boolean}>`
  margin-top: 8px;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size18};
  color: ${ColorPalette.black1};
  text-align: ${({alignRight}) => (alignRight ? 'right' : 'unset')};
  text-transform: capitalize;
`;

const SettingsIcon = styled(ColoredIcon)`
  cursor: pointer;
`;

interface IPriceDetailsProps extends IOrderPriceSummaryAdmin {
  orderId: number;
}

export default React.memo(PriceDetails);

function PriceDetails(props: IPriceDetailsProps) {
  const {paymentDate, paymentMethod, payee, couponDiscount, giftCard, total, orderId, couponCodes} = props;

  const {onUpdateOrder} = useOnUpdateOrder();

  const {updatePaymentModal, openUpdatePaymentModal} = useUpdatePaymentModal({onSuccess: onUpdateOrder, orderId});

  const openUpdatePaymentModalHandler = useCallback(() => {
    openUpdatePaymentModal();
  }, [openUpdatePaymentModal]);

  const fields = [
    {title: 'Payment date', value: paymentDate ? withDate(paymentDate) : 'Unpaid'},
    {title: 'Payment Method', value: paymentMethod},
    {title: 'Payee', value: payee || 'Etalon'},
    {
      title: 'Coupon Discount',
      value: `${couponCodes?.length > 0 ? '(' + couponCodes.join(', ') + ')' : ''} ${withCurrency(couponDiscount)}`,
      alignRight: true,
    },
    {title: 'Gift Card Payment', value: withCurrency(giftCard), alignRight: true},
    {title: 'Total', value: withCurrency(total), alignRight: true},
  ];

  return (
    <>
      {updatePaymentModal}
      <PriceDetailsRoot className="d-flex justify-content-between align-items-center">
        {fields.map(({title, value, alignRight}, i) => (
          <div key={i}>
            <FieldTitle>{title}</FieldTitle>
            <FieldValue alignRight={alignRight}>{value}</FieldValue>
          </div>
        ))}
        <DropDownMenu items={menuItems} onSelect={openUpdatePaymentModalHandler} alignRight={true}>
          {({isOpen}) => (
            <SettingsIcon
              name={IconName.Settings}
              size={24}
              color={isOpen ? ColorPalette.black1 : ''}
              fill={true}
              stroke={false}
            />
          )}
        </DropDownMenu>
      </PriceDetailsRoot>
    </>
  );
}
