import React, {memo} from 'react';

import Range, {RangeProps} from 'Common/components/Controls/Range';
import {IFieldProps} from 'Common/models/IFieldProps';

import withField from './withField';
import ValidationError from './ValidationError';

type IProps = IFieldProps<number[]> & Omit<RangeProps, 'onChange'>;

function SingleRangeField(props: IProps) {
  const {field, form, ...rest} = props;

  const onChange = React.useCallback(
    (values: number[]) => {
      form.setFieldValue(field.name, values[0]);
    },
    [field.name, form]
  );

  return (
    <>
      <Range {...rest} {...field} onChange={onChange} />
      <ValidationError name={field.name} />
    </>
  );
}

export default memo(withField(SingleRangeField));
