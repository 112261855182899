import React from 'react';
import styled from 'styled-components';

import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';

import Theme from 'Common/constants/Theme';
import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';
import {DiscountType} from 'Coupon/constants/DiscountType';
import {Divider} from 'BusinessPortal/components/common/styled';
import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {sortByName} from 'Common/helpers/sortByName';

const iconButtonProps = {
  color: ColorPalette.gray24,
  hoverColor: Theme.color.black,
  size: 14,
  stroke: false,
  fill: true,
};

const Text = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;

const Title = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size18};
  line-height: 16px;
  margin-bottom: 16px;
`;

const Label = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 12px;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
  color: ${Theme.color.gray};
`;

const Coupon = styled.div`
  padding: 20px 0;
  margin: 8px 0;
  box-shadow: 0 40px 32px -25px rgba(164, 164, 164, 0.1);
`;

const HorseNamesTitle = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 18px;
  letter-spacing: 0.2px;
  color: ${ColorPalette.gray56};
  margin-right: 12px;
`;

const HorseNames = styled(HorseNamesTitle)`
  color: ${Theme.color.black};
`;

interface IProps {
  coupons: IOrderCoupon[];
  horses: IOrderHorse[];
  onDeleteCoupon(couponId: number): void;
  onEditHorsesList(coupon?: IOrderCoupon): void;
}

function Discount(props: IProps) {
  const {coupons, horses, onEditHorsesList, onDeleteCoupon} = props;

  const noCoupons = coupons.length === 0;

  return (
    <>
      {!noCoupons && (
        <>
          <Title className="d-flex align-self-start">Coupons and gift cards</Title>
          <div className="d-flex w-100">
            <Label className="w-25">Code</Label>
            <Label className="w-50" style={{marginLeft: -5}}>
              Type
            </Label>
            <Label className="w-25 d-flex justify-content-center" style={{marginLeft: -5}}>
              Discount
            </Label>
          </div>
        </>
      )}
      {coupons?.map((coupon, i) => {
        const {packages: couponPackages, tests, code, type, amount, horses: couponHorses} = coupon;
        const couponForAllPackagesAndTests = couponPackages.length === 0 && tests.length === 0;
        const packages = couponForAllPackagesAndTests
          ? 'For all packages and tests'
          : couponPackages
              .sort(sortByName)
              .concat(tests.sort(sortByName))
              .map((i) => i.name)
              .join(', ');
        return (
          <Coupon key={i} className="w-100">
            <div className="d-flex w-100 align-items-baseline">
              <Text className="w-25">{code}</Text>
              <Text className="w-50">{packages}</Text>
              <Text className="w-25 d-flex justify-content-center">
                {type === DiscountType.Percentage ? `${amount}%` : `$${amount}`}
              </Text>
              <IconButton {...iconButtonProps} name={IconName.Clear} onClick={() => onDeleteCoupon(coupon.id)} />
            </div>
            <Divider />
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <HorseNamesTitle>Applied to:</HorseNamesTitle>
                <HorseNames>{couponHorses?.map((i) => horses.find((j) => j.id === i)?.name).join(', ')}</HorseNames>
              </div>
              <IconButton {...iconButtonProps} name={IconName.Edit} onClick={() => onEditHorsesList(coupon)} />
            </div>
          </Coupon>
        );
      })}
    </>
  );
}

export default Discount;
export type DiscountShowProps = IProps;
