import React from 'react';
import styled from 'styled-components';

import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import ColorPalette from 'Common/constants/ColorPalette';
import {IAvailabilityCriteria} from 'Common/models/IAvailabilityCriteria';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

const availabilityCriteriaDescriptionByKey: Record<keyof IAvailabilityCriteria, string> = {
  isNotGelding: 'Not a gelding',
  isOlderThenTwoYears: 'Older then 2 years',
  hasTestResults: 'DNA results are ready',
  hasPublicProfile: 'Horse profile is public'
};

const Root = styled.div`
  padding: 18px;

  > :not(:last-child) {
    margin-bottom: 12px;
  }
`;

const CriteriaMarkIconBase = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 2px;
  margin-right: 10px;
`;

const SuccessfulCriteriaMark = styled(CriteriaMarkIconBase)`
  background: ${ColorPalette.yellow9};
`;

const FailedCriteriaMark = styled(CriteriaMarkIconBase)`
  border: 1px solid ${ColorPalette.gray48};
`;

const Description = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 12px;
  letter-spacing: 0.5px;
  color: ${Theme.color.black};
`;

interface IProps {
  criteria: IAvailabilityCriteria;
}

const BahAccessCriteria = ({criteria}: IProps) => {
  const renderCriteriaIcon = (criteriaValue: boolean) => {
    const Mark = criteriaValue ? SuccessfulCriteriaMark : FailedCriteriaMark;
    const iconProps = criteriaValue
      ? {name: IconName.CheckMark, color: ColorPalette.black1}
      : {name: IconName.Close, color: ColorPalette.gray44};

    return (
      <Mark className="d-flex justify-content-center align-items-center">
        <ColoredIcon size={8} fill={true} stroke={false} {...iconProps} />
      </Mark>
    );
  };

  return (
    <Root>
      {Object.entries(criteria).map(([key, value], i) => {
        return (
          <div key={i} className="d-flex align-items-center">
            {renderCriteriaIcon(value)}
            <Description>{availabilityCriteriaDescriptionByKey[key]}</Description>
          </div>
        );
      })}
    </Root>
  );
};

export default BahAccessCriteria;
