import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';
import {IShortAbility} from 'Dictionaries/models/IAbilities';
import {IHorseReport, IUserHorseOrder} from 'Horse/models/IUserHorse';
import {Nullable} from 'Common/types';
import {IGenotypeGroup} from 'Genotype/models/IGenotypeGroup';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';

function selectState(state: IAppState) {
  return state.businessPortalHorseProfile;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectHorseParantageReport(state: IAppState): Nullable<IHorseReport> {
  return selectState(state).data.horseParantageReport;
}

export function selectHorseAbilities(state: IAppState): IShortAbility[] {
  return selectState(state).data.horseAbilities;
}

export function selectHorseGenotypeGroups(state: IAppState): IGenotypeGroup[] {
  return selectState(state).data.horseGenotypeGroups;
}

export function selectHorseBreedPanel(state: IAppState): IHorseBreedPanel[] {
  return selectState(state).data.horseBreedPanel;
}

export function selectHorseLastOrder(state: IAppState): Nullable<IUserHorseOrder> {
  return selectState(state).data.horseLastOrder;
}

export function selectHorseOwner(state: IAppState) {
  return selectState(state).data.horseOwner;
}

export function selectOwnerHorses(state: IAppState) {
  return selectState(state).data.ownerHorses;
}
