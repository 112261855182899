import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

import {
  AdminAssociationHorseProfileActions,
  getHorseAbilitiesActionTypes,
  getHorseBreedPanelActionTypes,
  getHorseGenotypeGroupsActionTypes,
  getHorseLastOrderActionTypes,
  getHorseOwnerActionTypes,
  getHorseParantageReportActionTypes,
  getOwnerHorsesAtionTypes,
  IAdminAssociationHorseProfileState,
} from '../types';

export const communicationHorseReducer = combineReducers<
  IAdminAssociationHorseProfileState['communications'],
  AdminAssociationHorseProfileActions
>({
  horseAbilitiesLoading: makeCommunicationReducerFromEnum(getHorseAbilitiesActionTypes),
  horseParantageReportLoading: makeCommunicationReducerFromEnum(getHorseParantageReportActionTypes),
  horseGenotypeGroupsLoading: makeCommunicationReducerFromEnum(getHorseGenotypeGroupsActionTypes),
  horseBreedPanelLoading: makeCommunicationReducerFromEnum(getHorseBreedPanelActionTypes),
  horseLastOrderLoading: makeCommunicationReducerFromEnum(getHorseLastOrderActionTypes),
  horseOwnerLoading: makeCommunicationReducerFromEnum(getHorseOwnerActionTypes),
  ownerHorsesLoading: makeCommunicationReducerFromEnum(getOwnerHorsesAtionTypes),
});
