import React, {memo} from 'react';

import DictionaryLayout from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import {useDictionaries} from 'Common/store/useDictionaries';
import CommercialTypeForm from './CommercialTypeForm/CommercialTypeForm';
import {Cell} from 'Common/components/Table/Table';
import {ICommercialType} from 'Dictionaries/models/ICommercialType';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

const CommercialTypes = () => {
  const {adminCommercialType: dictionary} = useDictionaries();

  return (
    <DictionaryLayout dictionary={dictionary} DictionaryForm={CommercialTypeForm}>
      {({onDelete, onEdit}) => (
        <>
          <Cell<ICommercialType>
            header="ID"
            dataKey="id"
            render={({id}) => `#${id}`}
            expandOnClick={true}
            width="10%"
          />
          <Cell<ICommercialType> header="Name" dataKey="name" render={({name}) => name} width="25%" />
          <Cell<ICommercialType>
            header="Genders"
            dataKey="genders"
            render={({genders}) => genders.join(', ')}
            width="25%"
            sortable={false}
          />

          <Cell<ICommercialType>
            header="Actions"
            align={CellAlign.Right}
            width={100}
            render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
          />
        </>
      )}
    </DictionaryLayout>
  );
};

export default memo(CommercialTypes);
