import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IRequestParams} from 'Common/models/IRequestParams';
import {axiosWrapper} from 'Common/services/AxiosWrapper';

import {
  IServerAssociationOrderAdmin,
  IServerAssociationOrderDetailsAdmin,
  IServerAssociationOrderPaymentDetailsAdmin,
  IServerAssociationOrderReportDetailsAdmin,
  IServerAssociationOrderStatusesAdmin,
  IServerAssociationOrderTestsDetailsAdmin,
} from './types/order';
import {
  convertOrderAdminToClient,
  convertOrderDetailsToClient,
  convertPaymentDetails,
  convertReportDetails,
  convertSampleDetails,
  convertSampleStatusRequest,
  convertStatusDetails,
  convertTestsDetails,
} from './converters/orders';
import {downloadResponse} from 'Common/helpers/downloadHelper';
import {IAdminAssociationOrder} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrder';
import {IAdminAssociationOrderDetails} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderDetails';
import {IAdminAssociationOrderPaymentDetails} from '../models/Order/IAdminAssociationOrderPaymentDetails';
import {
  IReleaseOrderHorse,
  IRequestChangeSample,
  IRequestNewSampleParams,
  IRequestSetSampleStatus,
} from 'Admin/AdminAssociations/store/adminOrders';
import {IAdminAssociationOrderReportDetails} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderReportDetails';
import {IUploadTestReportRequest} from 'Admin/shared/models/IUploadTestReportRequest';
import {convertToFormData} from 'Common/helpers/convertToFormData';
import {IRequiredTest} from 'Admin/AdminDashboard/models/IRequiredTest';
import {IOrderStatused} from 'Common/models/IOrderStatused';
import {ISampleAdmin} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderHorseDetails';
import {IAdminAssociationOrderTestsDetails} from '../models/Order/IAdminAssociationOrderTestsDetails';
import {IHorseSample} from 'Admin/AdminDashboard/models/Order/IHorseSample';

const getOrders = async (params?: IRequestParams): Promise<IListResponse<IAdminAssociationOrder>> => {
  const response = await axiosWrapper.get<IListResponse<IServerAssociationOrderAdmin>>(`/AssociationOrdersAdmin`, {
    params,
  });
  return {...response.data, data: response.data.data.map(convertOrderAdminToClient)};
};

const getOrderDetails = async (orderId: number): Promise<IAdminAssociationOrderDetails> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerAssociationOrderDetailsAdmin>>(
    `/AssociationOrderDetailsAdmin/${orderId}`
  );
  return convertOrderDetailsToClient(response.data.data);
};

const getOrderPaymentDetails = async (orderId: number): Promise<IAdminAssociationOrderPaymentDetails> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerAssociationOrderPaymentDetailsAdmin>>(
    `/AssociationOrderPaymentDetailsAdmin/${orderId}`
  );
  return convertPaymentDetails(response.data.data);
};

const uploadTestResults = async (uploadedFile: FormData): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/TestResultsAdmin/Upload`, uploadedFile);
};

const deleteOrder = async (id: number): Promise<void> => {
  await axiosWrapper.delete<void>(`/AssociationOrderDeleteAdmin/${id}`);
};

const downloadOrderSubmissionForm = async (orderId: number): Promise<void> => {
  const response = await axiosWrapper.get<void>(`/AssociationOrderSubmissionFormAdmin/${orderId}`, {
    responseType: 'blob',
  });

  downloadResponse(response, 'submission-form');
};

const getOrderRequiredTests = async (orderId: number): Promise<IListResponse<IRequiredTest>> => {
  const response = await axiosWrapper.get<IListResponse<IRequiredTest>>(
    `/AssociationOrderRequiredTestsAdmin/${orderId}`
  );
  return response.data;
};

const changeSample = async (params: IRequestChangeSample): Promise<void> => {
  await axiosWrapper.post<void>(`/AssociationSampleUpdate/ChangeSID`, params);
};

const getOrderReportDetails = async (orderId: number): Promise<IListResponse<IAdminAssociationOrderReportDetails>> => {
  const response = await axiosWrapper.get<IListResponse<IServerAssociationOrderReportDetailsAdmin>>(
    `/AssociationOrderReportDetailsAdmin/${orderId}`
  );
  return {...response.data, data: response.data.data.map(convertReportDetails)};
};

const uploadOrderReport = async (params: IUploadTestReportRequest): Promise<void> => {
  const {orderId, horseId, isSendReport, uploadedFile} = params;

  await axiosWrapper.post<IObjectResponse<null>>('/AssociationOrderReportUpload', convertToFormData(uploadedFile), {
    params: {orderId, horseId, isSendReport},
  });
};

const deleteOrderReport = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>('/AssociationOrderReportDelete', {
    data: {reportId: id},
  });
};

const setSampleStatus = async (params: IRequestSetSampleStatus): Promise<void> => {
  await axiosWrapper.post<void>(`/AssociationSampleStatusUpdateAdmin`, convertSampleStatusRequest(params));
};

const getHorseSamples = async (horseId: number): Promise<IHorseSample> => {
  const response = await axiosWrapper.get<IListResponse<IHorseSample>>(`/AssociationSampleAdmin/${horseId}`);
  return response.data.data[0] || null;
};

const requestNewSample = async (params: IRequestNewSampleParams): Promise<void> => {
  await axiosWrapper.post<void>(`/AssociationSampleResubmitAdmin`, params);
};

const releaseOrderHorse = async (data: IReleaseOrderHorse): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<void>>(`/AssociationOrderReleaseAdmin`, data);
};

const getOrderStatusesDetails = async (orderId: number): Promise<IOrderStatused> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerAssociationOrderStatusesAdmin>>(
    `/AssociationOrderStatusesDetailsAdmin/${orderId}`
  );
  return convertStatusDetails(response.data.data);
};

const getOrderSampleDetails = async (orderId: number): Promise<ISampleAdmin> => {
  const response = await axiosWrapper.get<IObjectResponse<ISampleAdmin>>(
    `/AssociationOrderSampleDetailsAdmin/${orderId}`
  );
  return convertSampleDetails(response.data.data);
};

const getOrderTestsDetails = async (orderId: number): Promise<IAdminAssociationOrderTestsDetails> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerAssociationOrderTestsDetailsAdmin>>(
    `/AssociationOrderTestsDetailsAdmin/${orderId}`
  );
  return convertTestsDetails(response.data.data);
};

export default {
  getOrders,
  getOrderDetails,
  getOrderPaymentDetails,
  uploadTestResults,
  deleteOrder,
  downloadOrderSubmissionForm,
  getOrderRequiredTests,
  getHorseSamples,
  changeSample,
  getOrderReportDetails,
  uploadOrderReport,
  deleteOrderReport,
  setSampleStatus,
  requestNewSample,
  releaseOrderHorse,
  getOrderStatusesDetails,
  getOrderSampleDetails,
  getOrderTestsDetails,
};
