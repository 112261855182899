import React from 'react';
import styled from 'styled-components';
import {ToastContainer as ToastifyContainer, ToastContainerProps, ToastOptions, cssTransition} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';

const toastAnimation = cssTransition({
  enter: 'animationIn',
  exit: 'animationOut',
  duration: 300
});

const defaultOptions: ToastOptions = {
  autoClose: 5000,
  hideProgressBar: true,
  position: 'bottom-left',
  transition: toastAnimation
};

const WrappedToastContainer = styled(ToastifyContainer).attrs({})`
  .Toastify__toast-container {
  }
  &.Toastify__toast-container--bottom-left {
  }
  &.Toastify__toast-container--top-center {
    padding: 16px;
    width: 100vw;

    @media ${breakpoints.sm} {
      padding: 0 32px 16px 32px;
      left: 0;
      margin-left: 0;
    }
  }
  .Toastify__toast {
    opacity: 1;
    border-radius: 4px;
    font-family: ${Theme.font.primary};
    font-style: normal;
    font-weight: ${Typography.weight.medium500};
    font-size: ${Typography.size.size12};
    line-height: 16px;
    text-align: center;
    min-height: 40px;
  }
  .Toastify__toast--error {
    background: ${ColorPalette.red9};
    border: 1px solid ${ColorPalette.red10};
    box-sizing: border-box;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06), 0px 0px 8px rgba(0, 0, 0, 0.04);
  }
  .Toastify__toast--warning {
    background: ${ColorPalette.yellow10};
    border: 1px solid ${ColorPalette.yellow11};
    box-sizing: border-box;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06), 0px 0px 8px rgba(0, 0, 0, 0.04);
    letter-spacing: 0.2px;
    font-size: ${Typography.size.size10};
    line-height: 16px;
    color: ${Theme.color.black};
  }
  .Toastify__toast--success {
    background: ${ColorPalette.green3};
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06), 0px 0px 8px rgba(0, 0, 0, 0.04);
  }
  .Toastify__toast--info {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
  button[aria-label='close'] {
    display: none;
  }

  /* Animation */
  @keyframes animationIn {
    from {
      opacity: 0;
      top: -10px;
    }
    to {
      opacity: 1;
      top: 0;
    }
  }

  @keyframes animationOut {
    from {
      opacity: 1;
      top: 0;
    }
    to {
      opacity: 0;
      top: -10px;
    }
  }

  @media ${breakpoints.md} {
    @keyframes animationIn {
      from {
        opacity: 0;
        bottom: -10px;
      }
      to {
        opacity: 1;
        bottom: 0;
      }
    }

    @keyframes animationOut {
      from {
        opacity: 1;
        bottom: 0;
      }
      to {
        opacity: 0;
        bottom: -10px;
      }
    }
  }

  .animationIn {
    animation-name: animationIn;
  }

  .animationOut {
    animation-name: animationOut;
  }
`;

export const ToastContainer = ({className, ...rest}: ToastContainerProps & {className?: string}) => {
  return (
    <div className={className}>
      <WrappedToastContainer {...defaultOptions} {...rest} />
    </div>
  );
};
