import React from 'react';
import styled from 'styled-components';

import {SidebarComponent, SidebarLabel} from 'HorseProfile/components/shared/StyledComponents';
import Map from 'Common/components/Map/Map';
import {Nullable} from 'Common/types';
import {ICoordinatedLocation} from 'Common/models/ICoordinatedLocation';
import Typography from 'Common/constants/Typography';

const Address = styled.div`
  width: 250px;
  font-family: ${Typography.family.roboto};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size13};
  line-height: 24px;
`;

interface IProps {
  className?: string;
  position: Nullable<ICoordinatedLocation>;
  address: string;
}

function HorseLocation(props: IProps) {
  const {className, position, address} = props;

  return (
    <SidebarComponent className={className}>
      {(() => {
        if (!position) {
          return <SidebarLabel>The horse's location is unknown</SidebarLabel>;
        }

        return (
          <>
            <SidebarLabel>This horse is located at</SidebarLabel>
            <Address>{address}</Address>
            <Map position={position} />
          </>
        );
      })()}
    </SidebarComponent>
  );
}

export default HorseLocation;
