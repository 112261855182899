import React from 'react';
import withGoogleMap, {WithGoogleMapProps} from 'react-google-maps/lib/withGoogleMap';

import {compose, withProps} from 'recompose';
import {defaultMapStyle} from 'Common/helpers/Map/constants';
import {IStyled} from 'Common/models/IStyled';

type IProps = Omit<WithGoogleMapProps, 'mapElement'> & IStyled;

export function withGMap<T>(Component: React.ComponentType<T>) {
  const wrapped = (props: T) => <Component {...props} />;

  wrapped.displayName = `withGoogleMap(${Component.displayName || Component.name || 'Component'})`;

  return compose<any, T & Partial<IProps>>(
    withProps((props: Partial<IProps>) => ({
      containerElement: <div style={{...defaultMapStyle, ...props.style}} />,
      mapElement: <div style={{height: `100%`}} />
    })),
    withGoogleMap
  )(wrapped);
}

export type IWithGoogleMapProps = IProps;
