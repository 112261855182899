import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {IShortDescriptionEntity} from 'Common/models/IDescriptionEntity';
import {Combination} from 'Common/constants/Combination';
import {IGenotypeSequence} from 'Admin/AdminPhenotypes/models/IGenotypeSequence';
import {IUpdateGenotypeCombinationRequest} from 'Admin/AdminPhenotypes/service/models/IGenotypeCombination';
import {IIndexed} from 'Common/models/IIndexed';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues extends IShortDescriptionEntity, IIndexed {
  id?: IEntity['id'];
  alleleNames: string;
  type: Combination;
  sequences?: IGenotypeSequence[];
}

export const initialValue: IFormValues = {
  alleleNames: '',
  type: Combination.And,
  description: {value: ''},
  sequences: [],
  positionIndex: 0,
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  alleleNames: Yup.string().required(REQUIRED_FIELD),
  positionIndex: Yup.number().required(REQUIRED_FIELD),
});

export const convertToServer = (values: IFormValues): Omit<IUpdateGenotypeCombinationRequest, 'id'> => {
  return {
    ...values,
    description: {value: values.description?.value || ''},
  };
};

export const convertToServerUpdating = (values: IFormValues): IUpdateGenotypeCombinationRequest => {
  return {
    ...convertToServer(values),
    id: values.id!,
  };
};
