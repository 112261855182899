import React, {memo, useCallback} from 'react';

import {IPackage} from 'Dictionaries/models/IPackage';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {IMenuItemValued} from 'Common/models/IMenuItemValued';
import Actions from 'Common/components/Actions/Actions';

const iconProps = {color: ColorPalette.red7};

enum AdminPackageActionValue {
  ShowPackage = 'showPackage',
  EditPackage = 'editPackage',
  UseAsBase = 'useAsBase',
  EditShoppingCartSettings = 'editShoppingCartSettings',
}

const menuItems: IMenuItemValued<AdminPackageActionValue>[] = [
  {
    value: AdminPackageActionValue.ShowPackage,
    label: 'Package details',
    icon: {name: IconName.ShowInfo, ...iconProps},
  },
  {
    value: AdminPackageActionValue.EditPackage,
    label: 'Edit package',
    icon: {name: IconName.Edit, ...iconProps},
  },
  {
    value: AdminPackageActionValue.EditShoppingCartSettings,
    label: 'Edit settings for shopping cart',
    icon: {name: IconName.ShoppingCart, ...iconProps},
  },
  {
    value: AdminPackageActionValue.UseAsBase,
    label: 'Use as base for new',
    icon: {name: IconName.ControlPointDuplicate, ...iconProps},
  },
];

interface IActionProps {
  packageInfo: IPackage;
  showAction: (id: number) => void;
  editAction: (id: number) => void;
  addBased: (id: number) => void;
  editCartSettings: (id: number) => void;
}

export const PackageActions = memo(
  ({packageInfo, showAction, editAction, addBased, editCartSettings}: IActionProps) => {
    const {id, isArchived, isEnabled} = packageInfo;

    const handleShowAction = useCallback(() => showAction(id), [id, showAction]);
    const handleEditAction = useCallback(() => editAction(id), [id, editAction]);
    const handleAddBasedAction = useCallback(() => addBased(id), [id, addBased]);
    const handleEditCartSettingsAction = useCallback(() => editCartSettings(id), [id, editCartSettings]);

    const isEditDisabled = isArchived || isEnabled;

    const activeMenuItems = menuItems.map((item) => {
      let isDisabled = false;
      switch (item.value) {
        case AdminPackageActionValue.EditPackage:
          isDisabled = isEditDisabled;
          break;
      }

      return {...item, isDisabled};
    });

    return (
      <Actions<AdminPackageActionValue>
        menuItems={activeMenuItems}
        showPackage={() => handleShowAction()}
        editPackage={() => handleEditAction()}
        useAsBase={() => handleAddBasedAction()}
        editShoppingCartSettings={() => handleEditCartSettingsAction()}
      />
    );
  }
);
