import React, {memo} from 'react';

import {OrderInfoHeaderProps} from 'Admin/AdminDashboard/components/Orders/OrderInfoModal/OrderInfoHeader/OrderInfoHeader/common/models';
import AvatarCell from 'Admin/AdminDashboard/components/shared/AvatarCell/AvatarCell';
import {USER_SUBSCRIPTION_MARKED_ICON} from 'Common/constants/avatarMarkedIcon';

interface IProps {
  onEdit(): void;
}

type AllProps = IProps & OrderInfoHeaderProps;

const HorseName = (props: AllProps) => {
  const {orderDetails, onEdit} = props;

  const {id, name, avatar, hasSubscription} = orderDetails.owner;

  return (
    <AvatarCell
      type="owner"
      onLabelClick={onEdit}
      label={name}
      avatarUrl={avatar?.url}
      profileUrl={`/admin/user/user-profile/${id}`}
      isMarkedIcon={hasSubscription ?? false}
      markedIcon={USER_SUBSCRIPTION_MARKED_ICON}
    />
  );
};

export default memo(HorseName);
