import React, {memo, useCallback} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IconName} from 'Icon/components/Icon';
import {IPackageGroup} from 'Dictionaries/models/IPackageGroup';

const Root = styled.div`
  min-height: 120px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.02), 0 12px 28px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${ColorPalette.gray2};
  background-color: ${ColorPalette.white0};
`;

const Title = styled.span`
  font-family: ${Theme.font.secondary};
  font-size: ${Typography.size.size20};
  font-weight: ${Typography.weight.semiBold600};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${ColorPalette.black1};
`;

const Description = styled(Title)`
  font-size: ${Typography.size.size16};
  font-weight: ${Typography.weight.normal400};
  line-height: 1.41;
  color: ${ColorPalette.gray3};
  margin: 8px 0;
`;

const ViewButton = styled(PrimaryButton)`
  max-width: 100px;
  margin-top: 32px;
`;

interface IProps {
  orderPackageGroup: IPackageGroup;
  testAmount: number;
  viewCategory(id: number): void;
}

function OrderPackageGroupCard(props: IProps) {
  const {
    orderPackageGroup: {description, name, id},
    testAmount,
    viewCategory,
  } = props;

  const handleViewCategory = useCallback(() => {
    viewCategory(id);
  }, [id, viewCategory]);

  return (
    <Root className="d-flex flex-column justify-content-between">
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <Title>{name}</Title>
        </div>
        {description && <Description>{description}</Description>}
      </div>
      <ViewButton
        icon={{name: IconName.Back, size: 14}}
        iconStyle={{transform: 'rotate(180deg)'}}
        className="d-flex justify-content-between"
        iconPosition="right"
        onClick={handleViewCategory}
      >
        View {testAmount} tests
      </ViewButton>
    </Root>
  );
}

export default memo(OrderPackageGroupCard);
