import {IFormValues} from './validation';
import {
  IAdminPurchasableTest,
  IAdminPurchasableTestRequest,
} from 'Admin/AdminAssociations/models/IAdminPurchasableTest';

export function convertToClient(value: IAdminPurchasableTest): IFormValues {
  return {
    ...value,
    specialPrices: value.specialPrices.map((price) => ({
      price: price.price,
      organizations: price.organizations.map((org) => org.id),
    })),
    isSpecialPrices: value.specialPrices && value.specialPrices.length > 0,
  };
}

export function convertToServerUpdating(value: IFormValues): IAdminPurchasableTestRequest {
  const dataToSave: Omit<IFormValues, 'isSpecialPrices'> = {
    ...value,
    specialPrices: value.isSpecialPrices ? value.specialPrices : [],
  };
  delete dataToSave['isSpecialPrices'];

  return {
    ...dataToSave,
  };
}
