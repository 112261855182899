import {IPaymentDetailsForm} from './validation';
import {OrderStatusField} from 'Order/models/IOrderStatusFields';
import {
  IAdminPaymentDetails,
  IAdminPaymentDetailsHorse,
  PaymentStatus,
} from 'Admin/AdminAssociations/models/IAdminPaymentDetails';

export function convertPaymentDetailsToPaymentDetailsForm(
  orderId: number,
  paymentDetails: IAdminPaymentDetails,
  paymentDetailsHorses: IAdminPaymentDetailsHorse[]
): IPaymentDetailsForm {
  return {
    ...paymentDetails,
    status: paymentDetails.status && convertOrderStatusFieldToPaymentStatus(paymentDetails.status),
    sendEmail: true,
  };
}

export function convertPaymentDetailsFormToPaymentDetails(
  orderId: number,
  paymentDetails: IPaymentDetailsForm
): IAdminPaymentDetails {
  const dataToSave: IAdminPaymentDetails = {
    ...paymentDetails,
    status: paymentDetails.status && convertPaymentStatusToOrderStatusField(paymentDetails.status),
    orderIds: [orderId].concat(paymentDetails.orderIds || []),
  };

  delete dataToSave['connectedOrders'];

  return dataToSave;
}

function convertOrderStatusFieldToPaymentStatus(orderStatusField: OrderStatusField): PaymentStatus {
  if (orderStatusField === OrderStatusField.Waiting) {
    return PaymentStatus.Waiting;
  }

  return PaymentStatus.Ready;
}

function convertPaymentStatusToOrderStatusField(paymentStatus: PaymentStatus): OrderStatusField {
  if (paymentStatus === PaymentStatus.Waiting) {
    return OrderStatusField.Waiting;
  }

  return OrderStatusField.Ready;
}
