import {useCallback, useState} from 'react';

import CreateUserForm from 'Admin/AdminDashboard/components/Users/CreateUser/CreateUserForm';
import ModalWindow from 'Common/components/Modal/ModalWindow';

interface ICreateUserModalParams {
  onSuccess?(): void;
}

export function useCreateUserModal(params: ICreateUserModalParams) {
  const {onSuccess} = params;
  const [isOpen, setIsOpen] = useState(false);

  const openCreateUserModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeCreateUserModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSuccess = useCallback(() => {
    closeCreateUserModal();
    onSuccess && onSuccess();
  }, [closeCreateUserModal, onSuccess]);

  const createUserModal = (
    <ModalWindow isOpen={isOpen} onClose={closeCreateUserModal}>
      {<CreateUserForm onSuccess={handleSuccess} />}
    </ModalWindow>
  );

  return {
    createUserModal,
    openCreateUserModal,
  };
}
