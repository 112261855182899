import React, {memo, useCallback} from 'react';
import HorseOrderButtons from 'Shared/components/UserProfile/parts/UserHorsesTab/UserHorse/UserHorseButtons/HorseOrderButtons';
import FavoriteHorseButtons from 'FavoriteHorse/components/FavoriteHorseButtons';
import UserHorseOverviewLayout, {
  UserHorseOverviewProps,
} from 'UserProfile/components/shared/UserHorseOverviewLayout/UserHorseOverviewLayout';
import {ProfileType} from 'Common/constants/ProfileType';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import withFavoriteHorseActions, {
  getWithFavoriteHorseActionsModules,
  IWithFavoriteHorseActionsProps,
} from 'Common/helpers/withFavoriteHorseActions';
import {isAdminVisitor} from 'Common/helpers/isAdminVisitor';
import {VisitorType} from 'Common/constants/VisitorType';

type IExternalProps = Omit<UserHorseOverviewProps, 'isEmptyOrder'>;

type AllProps = IExternalProps & IWithFavoriteHorseActionsProps;

function UserHorse(props: AllProps) {
  const {addFavoriteHorse, deleteFavoriteHorse, onSuccess, isOwnHorse, horse, visitorType} = props;
  const {id, order, isFavorite, profileType} = horse;

  const isPublic = profileType === ProfileType.Public;

  const onAddFavoriteHorse = useCallback(async () => {
    await addFavoriteHorse(id);
  }, [id, addFavoriteHorse]);

  const onDeleteFavoriteHorse = useCallback(async () => {
    await deleteFavoriteHorse(id);
  }, [id, deleteFavoriteHorse]);

  const isShowOrders = [VisitorType.AssociationEmployee, VisitorType.User].includes(visitorType) && isOwnHorse;
  return (
    <UserHorseOverviewLayout horse={horse} isOwnHorse={isOwnHorse} onSuccess={onSuccess} visitorType={visitorType}>
      <div className="d-flex w-100 flex-column">
        {isShowOrders && <HorseOrderButtons horseId={id} order={order} visitorType={visitorType} />}
        {!isAdminVisitor() && (isOwnHorse || isPublic) && (
          <FavoriteHorseButtons
            isOwnFavorite={!!isFavorite}
            addFavoriteHorseHandler={onAddFavoriteHorse}
            deleteFavoriteHorseHandler={onDeleteFavoriteHorse}
          />
        )}
      </div>
    </UserHorseOverviewLayout>
  );
}

const Connected = withFavoriteHorseActions(memo(UserHorse));

export default withDynamicModules(Connected, getWithFavoriteHorseActionsModules());
