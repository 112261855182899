import React, {memo, useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {DropDownMenu, IMenuItem} from 'Common/components/DropDown/index';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {withCurrency} from 'Common/helpers/withCurrency';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import withDate from 'Common/helpers/withDate';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminOrders/index';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';

const PriceDetailsRoot = styled.div`
  position: relative;
  padding: 10px 25px 10px 85px;
  background-color: ${ColorPalette.gray22};
`;

const FieldTitle = styled.div`
  font-weight: 600;
  font-size: ${Typography.size.size14};
  color: ${ColorPalette.gray23};
`;

const FieldValue = styled.div<{alignRight?: boolean}>`
  margin-top: 8px;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size18};
  color: ${ColorPalette.black1};
  text-align: ${({alignRight}) => (alignRight ? 'right' : 'unset')};
  text-transform: capitalize;
`;

const SettingsIcon = styled(ColoredIcon)`
  cursor: pointer;
`;

interface IOwnProps {
  orderId: number;
  menuItems: IMenuItem[];
  onSelectMenu(value: string): void;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IOwnProps;

function PaymentDetails(props: Props) {
  const {orderId, getOrderPaymentDetails, orderPaymentDetails, orderPaymentDetailsLoading, menuItems, onSelectMenu} =
    props;

  const {listen} = useEventBus();

  const getDetails = useCallback(() => {
    if (orderId) {
      getOrderPaymentDetails(orderId);
    }
  }, [getOrderPaymentDetails, orderId]);

  useEffect(() => {
    getOrderPaymentDetails(orderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const eventUpdatePayment = listen(EventName.UpdatePayment, getDetails);

    return () => {
      eventUpdatePayment.stopListening();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {coupon, paymentDate, paymentMethod, status, totalDiscount, totalPrice, transaction} = orderPaymentDetails;

  const fields = [
    {title: 'Payment date', value: paymentDate ? withDate(paymentDate) : '-'},
    {title: 'Status', value: status, alignRight: true},
    {title: 'Payment Method', value: paymentMethod},
    {
      title: 'Coupon Discount',
      value: `${coupon ? '(' + coupon + ')' : ''} ${withCurrency(totalDiscount)}`,
      alignRight: true,
    },
    {title: 'Total', value: withCurrency(totalPrice), alignRight: true},
    {title: 'Transaction ID', value: transaction || '-'},
  ];

  const isLoading = orderPaymentDetailsLoading.isRequesting;

  return (
    <div className="position-relative">
      {isLoading && <Loading />}
      <PriceDetailsRoot className="d-flex justify-content-between align-items-center">
        {fields.map(({title, value, alignRight}, i) => (
          <div key={i}>
            <FieldTitle>{title}</FieldTitle>
            <FieldValue alignRight={alignRight}>{value}</FieldValue>
          </div>
        ))}
        <DropDownMenu items={menuItems} onSelect={onSelectMenu} alignRight={true}>
          {({isOpen}) => (
            <SettingsIcon
              name={IconName.Settings}
              color={isOpen ? ColorPalette.black1 : ''}
              hoverColor={ColorPalette.black1}
              fill={true}
              stroke={false}
            />
          )}
        </DropDownMenu>
      </PriceDetailsRoot>
    </div>
  );
}

const mapStateToProps = (state: IAppState) => ({
  orderPaymentDetails: selectors.selectOrderPaymentDetails(state),
  orderPaymentDetailsLoading: selectors.selectCommunication(state, 'orderPaymentDetailsLoading'),
});

const mapDispatchToProps = {
  getOrderPaymentDetails: actions.getOrderPaymentDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default memo(connector(PaymentDetails));
