import {combineReducers} from 'redux';

import {SubmissionFormActions, ISubmissionFormState, downloadSubmissionFormActionTypes} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationSubmissionFormReducer = combineReducers<
  ISubmissionFormState['communications'],
  SubmissionFormActions
>({
  downloadFormRequesting: makeCommunicationReducerFromEnum(downloadSubmissionFormActionTypes)
});
