import {IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IAssociationPageGeneralInformation} from 'BusinessPortal/models/IAssociationPageGeneralInformation';
import {IAssociationPageContactInformation} from 'BusinessPortal/models/IAssociationPageContactInformation';
import {IImage} from 'Image/models/IImage';
import {withImageBaseUrl} from 'Common/helpers/withImageBaseUrl';

const getGeneralInformation = async (): Promise<IAssociationPageGeneralInformation> => {
  const response = await axiosWrapper.get<IObjectResponse<IAssociationPageGeneralInformation>>(
    `/AssociationGeneralInformation`
  );
  return response.data.data;
};

const updateGeneralInformation = async (data: IAssociationPageGeneralInformation): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(`/AssociationGeneralInformationUpdate`, data);
};

const getContactInformation = async (): Promise<IAssociationPageContactInformation> => {
  const response = await axiosWrapper.get<IObjectResponse<IAssociationPageContactInformation>>(
    `/AssociationContactInformation`
  );
  return response.data.data;
};

const updateContactInformation = async (data: IAssociationPageContactInformation): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(`/AssociationContactInformationUpdate`, data);
};

const getOrganizationAvatar = async (): Promise<IImage> => {
  const response = await axiosWrapper.get<IObjectResponse<IImage>>(`/AssociationOrganizationAvatar`);
  return withImageBaseUrl(response.data.data);
};

const updateOrganizationAvatar = async (uploadedFile: File): Promise<void> => {
  const formData = new FormData();
  formData.append('file', uploadedFile);

  await axiosWrapper.post<IObjectResponse<null>>('/AssociationOrganizationAvatarUpdate/', formData);
};

export default {
  getGeneralInformation,
  updateGeneralInformation,
  getContactInformation,
  updateContactInformation,
  getOrganizationAvatar,
  updateOrganizationAvatar,
};
