import React, {memo, useMemo} from 'react';
import styled from 'styled-components';

import {SwitchFieldWrapper, SwitchWrapper} from 'Common/components/StyledComponents/StyledComponents';
import SwitchField from 'Common/components/FormFields/SwitchField';
import {DateTimeField} from 'Common/components/FormFields';
import {IUserPermission} from 'Admin/AdminDashboard/models/User/IUserPermissions';

const SelectedWrapper = styled.div`
  margin-top: 16px;
`;

interface IProps {
  permission: IUserPermission;
  permissions: IUserPermission[];
  fieldName: string;
  isDisableLimits?: boolean;
  onClick?(fieldName: string, value: boolean): void;
}

function PermissionSwitcher(props: IProps) {
  const {permission, onClick, fieldName, isDisableLimits, permissions} = props;
  const index = useMemo(() => permissions.findIndex((x) => x.code === permission.code), [permission, permissions]);

  return (
    <SwitchWrapper className="d-flex flex-column">
      <div className="d-flex flex-row">
        <div className="align-self-center w-100">{permission.description}</div>
        <SwitchFieldWrapper>
          <SwitchField name={permission.code} onClick={onClick} checked={permission.isEnable} />
        </SwitchFieldWrapper>
      </div>

      {!isDisableLimits && (
        <>
          <SelectedWrapper className="d-flex flex-row align-items-center w-75">
            <DateTimeField
              name={`${fieldName}[${index}].expiryDate`}
              label="Available until"
              showTime={true}
              futureEnabled={true}
              disabled={!permission.isEnable}
            />
          </SelectedWrapper>
        </>
      )}
    </SwitchWrapper>
  );
}

export default memo(PermissionSwitcher);
