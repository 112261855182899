import React from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import {breakpoints} from 'Common/constants/Breakpoints';
import Typography from 'Common/constants/Typography';
import {ReactComponent as FindHorseLogo} from 'SubscriptionWelcome/img/subscription-find-horse.svg';
import {ReactComponent as HorseParametersLogo} from 'SubscriptionWelcome/img/subscription-parameters.svg';
import {ReactComponent as BuildFoalLogo} from 'SubscriptionWelcome/img/subscription-build-foal.svg';
import {ReactComponent as FlexibleFiltersLogo} from 'SubscriptionWelcome/img/flexible-filters.svg';
import GeneticCalculatorLogo from 'SubscriptionWelcome/img/genetic-calculator.png';
import {ReactComponent as FoalConstructorSvg} from 'SubscriptionWelcome/img/foal-constructor.svg';
import FoalConstructorPng from 'SubscriptionWelcome/img/foal-constructor.png';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import ColorPalette from 'Common/constants/ColorPalette';
import {
  BaseContainer,
  DescriptionLabel,
  HeaderLabel
} from 'SubscriptionWelcome/components/BahSubscription/parts/styled';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const SectionBase = styled.div`
  max-width: 1100px;
`;

const Container = styled(BaseContainer)`
  background-color: ${Theme.color.white};
  color: ${Theme.color.black};
`;

const HowItWorksSubSection = styled.div<{marginBottom?: number; isDesktopReverse?: boolean}>`
  margin-bottom: 30px;
  flex-direction: column;

  @media ${breakpoints.md} {
    margin-bottom: ${({marginBottom}) => marginBottom || 0}px;
    flex-direction: ${({isDesktopReverse}) => (isDesktopReverse ? 'row-reverse' : 'row')};
  }
`;

const HowItWorksLabel = styled.div`
  @media ${breakpoints.sm} {
    max-width: 528px;
  }

  @media ${breakpoints.md} {
    max-width: 100%;
  }
`;

const HowItWorksDescription = styled.div`
  max-width: 528px;
`;

const BodyDescriptionLabel = styled.div<{size?: number}>`
  padding: 0 30px;
  margin-top: 30px;
  text-align: center;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;

  @media ${breakpoints.sm} {
    margin-top: 15px;
    font-size: ${Typography.size.size14};
    line-height: 18px;
  }

  @media ${breakpoints.md} {
    margin-top: 30px;
    font-size: ${Typography.size.size20};
    line-height: 32px;
  }
`;

const Illustration = styled.div`
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  position: inherit;
  margin-bottom: 70px;
  background-color: ${Theme.color.white};
  top: -50px;
  height: 1040px;
  padding: 66px 40px;
  border-radius: 4px;
  box-shadow: 0px 100px 75px -70px ${hexToRgba(ColorPalette.black0, 0.05)};

  @media ${breakpoints.sm} {
    top: -35px;
    width: 85%;
    height: 250px;
    flex-direction: row;
  }

  @media ${breakpoints.md} {
    width: 100%;
    height: 390px;
  }
`;

const Divider = styled.div`
  width: 105%;
  margin: 50px 0;
  border: 2px solid ${ColorPalette.gray49};

  @media ${breakpoints.sm} {
    width: 0;
    height: 105%;
    margin: 0;
    transform: rotate(180deg);
  }
`;

const VideoWrapper = styled.div`
  @media ${breakpoints.sm} {
    margin-right: 30px;
  }
`;

const Body = () => {
  const {isMobile, isTablet} = useMediaQuery();

  const logoWidth = isTablet ? 83 : 145;

  return (
    <Container className="d-flex w-100 justify-content-center">
      <SectionBase className="d-flex flex-column position-relative w-100 justify-content-between align-items-center">
        <Illustration className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column align-items-center">
            <FindHorseLogo width={logoWidth} />
            <BodyDescriptionLabel>Find the perfect match for your horse</BodyDescriptionLabel>
          </div>
          <Divider />
          <div className="d-flex flex-column align-items-center">
            <HorseParametersLogo width={logoWidth} />
            <BodyDescriptionLabel>Build your horse based on science</BodyDescriptionLabel>
          </div>
          <Divider />
          <div className="d-flex flex-column align-items-center">
            <BuildFoalLogo width={logoWidth} />
            <BodyDescriptionLabel>Match or find available horses</BodyDescriptionLabel>
          </div>
        </Illustration>

        <HowItWorksLabel className="w-100">
          <HeaderLabel id="autoScrollDestination" isMain={true} className="w-100">
            How it works
          </HeaderLabel>
        </HowItWorksLabel>

        <HowItWorksSubSection
          className="d-flex w-100 justify-content-between align-items-center align-self-center"
          marginBottom={60}
        >
          <HowItWorksDescription>
            <HeaderLabel>Flexible filters</HeaderLabel>
            <DescriptionLabel>
              Build-a-Horse provides the ability to choose the attributes/traits you would like to have in your future
              foal. Set parameters such as color, speed, discipline, and gait to refine your Build.
            </DescriptionLabel>
          </HowItWorksDescription>
          <div className="d-flex w-100 justify-content-center">
            <FlexibleFiltersLogo width={isTablet ? '70%' : '100%'} height={isMobile ? 230 : '100%'} />
          </div>
        </HowItWorksSubSection>
        <HowItWorksSubSection
          className="d-flex w-100 justify-content-between align-items-center align-self-center"
          marginBottom={100}
          isDesktopReverse={true}
        >
          <HowItWorksDescription>
            <HeaderLabel>Genetic calculator</HeaderLabel>
            <DescriptionLabel>
              Once you've chosen your attributes, click "Build" and see potential matching sires and dams that can be
              crossed to create your perfect foal. Refining your filter will narrow down your choices, but beware, some
              choices may be more plentiful while others quite rare! Once you've chosen a breeding pair, you may contact
              the owner and begin your journey to your perfect foal.
            </DescriptionLabel>
          </HowItWorksDescription>
          <div>
            <img src={GeneticCalculatorLogo} alt={''} style={{width: isMobile ? 245 : 335}} />
          </div>
        </HowItWorksSubSection>
        <HowItWorksSubSection
          className="d-flex w-100 justify-content-between align-items-center align-self-center"
          marginBottom={100}
        >
          <HowItWorksDescription>
            <HeaderLabel>Future foal preview</HeaderLabel>
            <DescriptionLabel>
              Choose to match your own horses or specific horses within the database. Build-a-Horse will provide you
              with color, speed and health probabilities while providing warnings to avoid dangerous crosses. The Future
              foal preview will also provide a color demo tool to see how each color and pattern may appear.
            </DescriptionLabel>
          </HowItWorksDescription>
          <div>
            {isMobile ? (
              <img src={FoalConstructorPng} alt={''} style={{width: '100%'}} />
            ) : (
              <FoalConstructorSvg width={450} />
            )}
          </div>
        </HowItWorksSubSection>
        <HowItWorksSubSection
          className="d-flex w-100 justify-content-between align-items-center align-self-center"
          marginBottom={100}
          isDesktopReverse={true}
        >
          <HowItWorksDescription>
            <HeaderLabel>Build-a-Horse tour</HeaderLabel>
            <DescriptionLabel>
              Please view the video for a tour of the Build-a-Horse platform. Using this unique tool you will be able to
              choose traits (colors, health, performance) for your future foal and see the potential sire and dam
              matches which may produce your “build”. Custom match sires and dams within your own herd or the herds of
              others using the platform for foal prediction. Looking for a horse already on the ground? Specify traits
              for your ideal horse, mare or stallion, and search for already existing horses using, “Find-a-Horse”.
            </DescriptionLabel>
          </HowItWorksDescription>
          <VideoWrapper className="d-flex w-100 justify-content-center">
            <iframe
              title="Welcome to an Evolution in Equine Genetics"
              width={isMobile ? '100%' : 495}
              height="280"
              src="https://www.youtube.com/embed/Xa_d6jyyEV8"
            />
          </VideoWrapper>
        </HowItWorksSubSection>
      </SectionBase>
    </Container>
  );
};

export default Body;
