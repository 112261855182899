import {combineReducers} from 'redux';

import {
  AdminAssociationHorseActions,
  createAdminAssociationHorseActionTypes,
  createAdminAssociationHorseImageMediaActionTypes,
  createAdminAssociationHorseVideoMediaActionTypes,
  deleteAdminAssociationHorseActionTypes,
  deleteAdminAssociationHorseAvatarActionTypes,
  deleteAdminAssociationHorseMediaActionTypes,
  getAdminAssociationHorseDetailsActionTypes,
  getAdminAssociationHorseHealthIssuesActionTypes,
  getAdminAssociationHorseMediaActionTypes,
  getAdminAssociationHorseOwnerActionTypes,
  getAdminAssociationHorseParentageActionTypes,
  getAdminAssociationHorseRegistriesActionTypes,
  getAdminAssociationHorsesActionTypes,
  getAdminAssociationHorseSampleActionTypes,
  IAdminAssociationHorseState,
  resetAdminAssociationHorseDetailsActionTypes,
  resetAdminAssociationHorseHealthIssuesActionTypes,
  resetAdminAssociationHorseMediaActionTypes,
  resetAdminAssociationHorseOwnerActionTypes,
  resetAdminAssociationHorseParentageActionTypes,
  resetAdminAssociationHorseRegistriesActionTypes,
  setAdminAssociationHorseOwnerActionTypes,
  updateAdminAssociationHorseAvatarActionTypes,
  updateAdminAssociationHorseDetailsActionTypes,
  updateAdminAssociationHorseHealthIssuesActionTypes,
  updateAdminAssociationHorseParentageActionTypes,
  updateAdminAssociationHorseRegistriesActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

export const communicationAdminAssociationHorseReducer = combineReducers<
  IAdminAssociationHorseState['communications'],
  AdminAssociationHorseActions
>({
  horsesLoading: makeCommunicationReducerFromEnum(getAdminAssociationHorsesActionTypes),
  horseCreating: makeCommunicationReducerFromEnum(createAdminAssociationHorseActionTypes),
  horseDetailsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getAdminAssociationHorseDetailsActionTypes),
    makeResetCommunicationReducer(resetAdminAssociationHorseDetailsActionTypes.RESET),
  ]),
  horseDetailsUpdating: makeCommunicationReducerFromEnum(updateAdminAssociationHorseDetailsActionTypes),
  horseOwnerLoading: composeReducers([
    makeCommunicationReducerFromEnum(getAdminAssociationHorseOwnerActionTypes),
    makeResetCommunicationReducer(resetAdminAssociationHorseOwnerActionTypes.RESET),
  ]),
  setHorseOwnerRequesting: makeCommunicationReducerFromEnum(setAdminAssociationHorseOwnerActionTypes),
  horseParentageLoading: composeReducers([
    makeCommunicationReducerFromEnum(getAdminAssociationHorseParentageActionTypes),
    makeResetCommunicationReducer(resetAdminAssociationHorseParentageActionTypes.RESET),
  ]),
  horseParentageUpdating: makeCommunicationReducerFromEnum(updateAdminAssociationHorseParentageActionTypes),
  horseRegistriesLoading: composeReducers([
    makeCommunicationReducerFromEnum(getAdminAssociationHorseRegistriesActionTypes),
    makeResetCommunicationReducer(resetAdminAssociationHorseRegistriesActionTypes.RESET),
  ]),
  horseRegistriesUpdating: makeCommunicationReducerFromEnum(updateAdminAssociationHorseRegistriesActionTypes),
  horseHealthIssuesLoading: composeReducers([
    makeCommunicationReducerFromEnum(getAdminAssociationHorseHealthIssuesActionTypes),
    makeResetCommunicationReducer(resetAdminAssociationHorseHealthIssuesActionTypes.RESET),
  ]),
  horseHealthIssuesUpdating: makeCommunicationReducerFromEnum(updateAdminAssociationHorseHealthIssuesActionTypes),
  horseAvatarUpdating: makeCommunicationReducerFromEnum(updateAdminAssociationHorseAvatarActionTypes),
  horseAvatarDeleting: makeCommunicationReducerFromEnum(deleteAdminAssociationHorseAvatarActionTypes),
  horseMediaLoading: composeReducers([
    makeCommunicationReducerFromEnum(getAdminAssociationHorseMediaActionTypes),
    makeResetCommunicationReducer(resetAdminAssociationHorseMediaActionTypes.RESET),
  ]),
  horseMediaDeleting: makeCommunicationReducerFromEnum(deleteAdminAssociationHorseMediaActionTypes),
  horseImageMediaCreating: makeCommunicationReducerFromEnum(createAdminAssociationHorseImageMediaActionTypes),
  horseVideoMediaCreating: makeCommunicationReducerFromEnum(createAdminAssociationHorseVideoMediaActionTypes),
  horseDeleting: makeCommunicationReducerFromEnum(deleteAdminAssociationHorseActionTypes),
  horseSampleLoading: makeCommunicationReducerFromEnum(getAdminAssociationHorseSampleActionTypes),
});
