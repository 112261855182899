import {axiosWrapper} from 'Common/services/AxiosWrapper';

import {IBuildFoalRequest} from 'FoalProfile/models/IBuildFoalRequest';
import {IFoalProfile} from 'FoalProfile/models/IFoalProfile';

import {convertFoalProfileToClient, convertFoalParentToClient} from './converters/convertFoalProfileToClient';
import {IServerFoalProfile} from './models/IServerFoalProfile';
import {IObjectResponse} from 'Common/models/IResponse';
import {IFoalParent} from 'FoalProfile/models/IFoalParent';
import {VisitorType} from 'Common/constants/VisitorType';
import {ApiByType, getApiByVisitorType} from 'Common/helpers/getApiByVisitorType';

const buildFoalRequest = async (params: IBuildFoalRequest): Promise<IFoalProfile> => {
  const apis: ApiByType = {
    [VisitorType.User]: `/BuildFoal`,
    [VisitorType.AssociationEmployee]: `/BuildFoalAssociation`,
  };

  const api = getApiByVisitorType(apis);

  const response = await axiosWrapper.get<IObjectResponse<IServerFoalProfile>>(api, {
    params,
  });
  return convertFoalProfileToClient(response.data.data);
};

const getParent = async (horseId: number): Promise<IFoalParent> => {
  const apis: ApiByType = {
    [VisitorType.User]: `/BuildFoalFullParent`,
    [VisitorType.AssociationEmployee]: `/BuildFoalFullParentAssociation`,
  };

  const api = getApiByVisitorType(apis);

  const response = await axiosWrapper.get<IObjectResponse<IFoalParent>>(`${api}/${horseId}`);
  return convertFoalParentToClient(response.data.data);
};

export default {
  buildFoalRequest,
  getParent,
};
