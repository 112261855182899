import styled from 'styled-components';
import ColorPalette from 'Common/constants/ColorPalette';

export const Cell = styled.div<{width?: string}>`
  width: ${props => props.width};
  text-overflow: ellipsis;
`;

export const GrayCell = styled(Cell)`
  color: ${ColorPalette.gray32};
`;
