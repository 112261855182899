import {IHorseBP} from 'BusinessPortal/models/horse/IHorseBP';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import {IAvailabilityMenuItemValued, IMenuItemValued} from 'Common/models/IMenuItemValued';
import {IconName} from 'Icon/components/Icon';

export enum HorsesActionValue {
  AddFavorite = 'addFavorite',
  DeleteFavorite = 'deleteFavorite',
  CreateOrder = 'createOrder',
  EditHorse = 'editHorse',
  DeleteHorse = 'deleteHorse',
}

const commonMenuItems: IMenuItemValued<HorsesActionValue>[] = [
  {
    value: HorsesActionValue.CreateOrder,
    label: 'Create order',
    icon: {name: IconName.OrderTesting, color: ColorPalette.gray44, stroke: false, fill: true},
  },
  {
    value: HorsesActionValue.EditHorse,
    label: 'Edit',
    icon: {name: IconName.Edit, color: ColorPalette.gray44, stroke: false, fill: true},
  },
  {
    value: HorsesActionValue.AddFavorite,
    label: 'Add to Favorites',
    icon: {
      name: IconName.Star,
      color: ColorPalette.gray44,
      size: 16,
      stroke: true,
      fill: false,
    },
  },
  {
    value: HorsesActionValue.DeleteFavorite,
    label: 'Remove from Favorites',
    icon: {name: IconName.Star, color: ColorPalette.gray44, size: 16},
  },
  {
    value: HorsesActionValue.DeleteHorse,
    label: 'Delete horse',
    icon: {name: IconName.Garbage, color: Theme.color.gray},
    style: {color: Theme.color.gray, hoverColor: Theme.color.primary, activeColor: ColorPalette.red11},
    divided: true,
  },
];

const getMapAvailableByMenuItem = (horse: IHorseBP): Record<HorsesActionValue, boolean> => ({
  [HorsesActionValue.AddFavorite]: !horse.isFavorite,
  [HorsesActionValue.DeleteFavorite]: horse.isFavorite,
  [HorsesActionValue.CreateOrder]: true,
  [HorsesActionValue.EditHorse]: true,
  [HorsesActionValue.DeleteHorse]: true,
});

export const getMenuItems = (horse: IHorseBP): IAvailabilityMenuItemValued<Partial<HorsesActionValue>>[] => {
  const mapAvailableByMenuItem = getMapAvailableByMenuItem(horse);
  return commonMenuItems
    .map((item, id) => ({...item, id, availabilityKey: mapAvailableByMenuItem[item.value]}))
    .filter((item) => item.availabilityKey);
};
