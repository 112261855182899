import React, {memo, useEffect} from 'react';
import styled from 'styled-components';
import {Form, FormikProps, withFormik} from 'formik';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import Nebula from 'Common/components/Layout/Nebula';
import {CheckboxGroupField, InputField, TextAreaField} from 'Common/components/FormFields/index';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {SampleRequestReason} from 'Admin/shared/components/RequestSampleForm/SampleRequestReason';
import ColorPalette from 'Common/constants/ColorPalette';
import {Checkbox} from 'Common/components/Controls/index';

import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';

import {initialValue, IRequestSampleForm, validationSchema} from 'Admin/shared/components/RequestSampleForm/validation';
import withDistributorStrategy from 'Common/helpers/strategy/withDistributorStrategy';
import {IDispatch, IState, requestSampleFormDistributor} from './distributor';
import {IRequestNewSampleParams as IRequestNewSampleParamsAdmin} from 'Admin/AdminDashboard/store/adminOrders/index';
import {IRequestNewSampleParams as IRequestNewSampleParamsAdminAssociationAdmin} from 'Admin/AdminAssociations/store/adminOrders';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';

const sectionTitleStyle: React.CSSProperties = {fontWeight: 'normal', color: ColorPalette.gray18};

const SCheckboxGroupField = styled(CheckboxGroupField)`
  margin-bottom: 0;
`;

const reasons = [
  {value: SampleRequestReason.Compromised, label: 'Sample compromised'},
  {value: SampleRequestReason.Incorrect, label: 'Incorrect sample submitted'},
  {value: SampleRequestReason.Insufficient, label: 'Insufficient'},
  {value: SampleRequestReason.Other, label: 'Other'},
];

interface IExternalProps {
  orderId: number;
  horseId?: number;
  onSuccess(): void;
}

type IRequestNewSampleParams = IRequestNewSampleParamsAdmin | IRequestNewSampleParamsAdminAssociationAdmin;

type IConnected = IState & IDispatch<IRequestNewSampleParams>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IRequestSampleForm> & OuterProps;

function RequestSampleForm(props: AllProps) {
  const {sampleRequesting, status, setStatus, onSuccess, isValid, isSubmitting} = props;

  const errorInfo = sampleRequesting.error;
  const isRequesting = sampleRequesting.isRequesting;

  useOnSuccessCommunication(sampleRequesting, onSuccess);

  useEffect(() => {
    const commonError = getCommonErrors(errorInfo);
    commonError && setStatus(commonError);
  }, [errorInfo, setStatus]);

  const hasOtherReason = !!props.values.reasons?.find((reason) => reason === SampleRequestReason.Other);

  return (
    <>
      <ModalWindowHeader>Resubmit sample</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          <Nebula active={isRequesting} style={{opacity: 0.5}}>
            <FormControlContainer title="Select the reason" titleStyle={sectionTitleStyle} isRequired={true}>
              <SCheckboxGroupField name="reasons" isNumericValues={false}>
                {reasons.map(({value, label}, i) => (
                  <Checkbox
                    key={i}
                    name={String(value)}
                    label={label}
                    checked={!!props.values.reasons?.find((reason) => reason === value)}
                  />
                ))}
              </SCheckboxGroupField>
              <InputField
                name="otherReason"
                type="text"
                placeholder="Describe reason"
                className="w-50"
                disabled={!hasOtherReason}
              />
            </FormControlContainer>
            <FormControlContainer title="Add a note" titleStyle={sectionTitleStyle}>
              <TextAreaField name="description" placeholder="You can add a note to the request" />
            </FormControlContainer>
          </Nebula>
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" disabled={!isValid} isLoading={isSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const RequestSampleFormFormik = withFormik<OuterProps, IRequestSampleForm>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    const {horseId, orderId, requestSample} = formikBag.props;
    const {description, reasons, otherReason} = values;

    const finalReason = (reasons as string[])
      .filter((r) => r !== SampleRequestReason.Other)
      .concat(otherReason ? [otherReason] : [])
      .join(', ');

    const horseIdParam = horseId && {horseId: horseId!};
    const params = {
      orderId,
      description,
      reason: finalReason,
      ...horseIdParam,
    };

    await requestSample(params);
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(RequestSampleForm);

export default withDistributorStrategy<IState, IDispatch<IRequestNewSampleParams>>(
  memo(RequestSampleFormFormik),
  requestSampleFormDistributor,
  SelectedStrategy.AdminAssociation
);
