import {memo, useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {DropDownMenu, IOrderedMenuItem} from 'Common/components/DropDown';
import Theme from 'Common/constants/Theme';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {IFilterProperty} from 'FacetFilter/models/IFilterProperty';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';

const AddButton = styled(ColoredIcon)`
  cursor: pointer;
`;

interface IProps {
  values: IFilterProperty[];
  onClick(property: string): void;
}

function FacetDropDown(props: IProps) {
  const {values, onClick} = props;

  const menuItems = useMemo(() => {
    // TODO: Add icons
    const items: IOrderedMenuItem[] = values.map((x, ind) => ({
      id: ind,
      value: x.property,
      label: x.title,
    }));

    const shownMenuItems = items.sort((a, b) => sortByStringKey(a, b, 'id'));

    return shownMenuItems;
  }, [values]);

  const onSelectHandler = useCallback(
    (property) => {
      onClick(property);
    },
    [onClick]
  );

  return (
    <>
      <DropDownMenu items={menuItems} onSelect={onSelectHandler}>
        {() => (
          <AddButton
            name={IconName.AddCircleFilled}
            size={28}
            stroke={false}
            fill={true}
            color={Theme.color.control.bg}
            hoverColor={Theme.color.control.hover}
          />
        )}
      </DropDownMenu>
    </>
  );
}

export default memo(FacetDropDown);
