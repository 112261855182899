import {IModule} from 'redux-dynamic-modules';

import {userPrintableReportPreferencesReducer} from './reducer';
import {IUserPrintableReportPreferencesState} from './types';

export interface IUserPrintableReportPreferencesModuleState {
  userPrintableReportPreferences: IUserPrintableReportPreferencesState;
}

export const UserPrintableReportPreferencesModule: IModule<IUserPrintableReportPreferencesModuleState> = {
  id: 'userPrintableReportPreferences',
  reducerMap: {
    userPrintableReportPreferences: userPrintableReportPreferencesReducer,
  },
};
