import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {ISubscriptionAdmin} from '../models/ISubscriptionAdmin';
import {convertServerSubscriptionToClient} from './converters/subscription';
import {IServerSubscriptionAdmin} from './models/subscription';

const getStripeSubscriptions = async (params?: IRequestParams): Promise<IListResponse<ISubscriptionAdmin>> => {
  const response = await axiosWrapper.get<IListResponse<IServerSubscriptionAdmin>>('/StripeSubscriptionAdmin', {
    params,
  });
  return {...response.data, data: response.data.data.map(convertServerSubscriptionToClient)};
};

const overwriteFromStripe = async (userId: number): Promise<void> => {
  await axiosWrapper.post<void>(`/StripeSubscriptionOverwriteAdmin`, {userId});
};

const cancelSubscriptions = async (userId: number, subscriptionId: number): Promise<void> => {
  await axiosWrapper.post<void>(`/StripeSubscriptionCancelAdmin`, {userId, subscriptionId});
};

export default {
  getStripeSubscriptions,
  overwriteFromStripe,
  cancelSubscriptions,
};
