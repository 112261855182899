import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {downloadResponse} from 'Common/helpers/downloadHelper';
import {isAdminVisitor} from 'Common/helpers/isAdminVisitor';

const SUBMISSION_FORM_FILENAME = 'submission-form';

const downloadOrderSubmissionForm = async (token: string): Promise<void> => {
  const response = await axiosWrapper.get(`/OrderSubmissionForm`, {responseType: 'blob', params: {token}});
  downloadResponse(response, SUBMISSION_FORM_FILENAME);
};

const downloadHorseSubmissionForm = async (orderId: number, horseId: number): Promise<void> => {
  const response = await axiosWrapper.get(isAdminVisitor() ? `/HorseSubmissionFormAdmin` : `/HorseSubmissionForm`, {
    params: {orderId, horseId},
    responseType: 'blob',
  });
  downloadResponse(response, SUBMISSION_FORM_FILENAME);
};

export default {
  downloadOrderSubmissionForm,
  downloadHorseSubmissionForm,
};
