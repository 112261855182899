import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';
import {IMediaResource} from 'Common/models/IMediaResource';

const STATE_NAME = 'gallery';

export interface IGalleryState {
  data: {
    gallery: IMediaResource[];
  };
  communications: {
    galleryLoading: ICommunication;
    galleryUpdating: ICommunication;
    avatarUpdating: ICommunication;
    avatarDeleting: ICommunication;
  };
}

export const getGalleryActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_GALLERY');
export type getGalleryActions = MakeCommunicationActions<typeof getGalleryActionTypes, {success: IMediaResource[]}>;

export const addAvatarActionTypes = makeCommunicationActionType(STATE_NAME, 'ADD_AVATAR');
export type addAvatarActions = MakeCommunicationActions<typeof addAvatarActionTypes, {}>;

export const addImagesToGalleryActionTypes = makeCommunicationActionType(STATE_NAME, 'ADD_IMAGES_TO_GALLERY');
export type addImagesToGalleryActions = MakeCommunicationActions<
  typeof addImagesToGalleryActionTypes,
  {success: IMediaResource[]}
>;

export const addVideoToGalleryActionTypes = makeCommunicationActionType(STATE_NAME, 'ADD_VIDEO_TO_GALLERY');
export type addVideoToGalleryActions = MakeCommunicationActions<
  typeof addVideoToGalleryActionTypes,
  {success: IMediaResource}
>;

export const deleteAvatarActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_AVATAR');
export type deleteAvatarActions = MakeCommunicationActions<typeof deleteAvatarActionTypes, {}>;

export const deleteMediaFromGalleryActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_MEDIA_FROM_GALLERY');
export type deleteMediaFromGalleryActions = MakeCommunicationActions<
  typeof deleteMediaFromGalleryActionTypes,
  {success: {mediaIds: number[]}}
>;

export type GalleryActions =
  | getGalleryActions
  | addAvatarActions
  | deleteAvatarActions
  | addImagesToGalleryActions
  | addVideoToGalleryActions
  | deleteMediaFromGalleryActions;
