import {Reducer} from 'redux';

import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';
import {BusinessPortalOwnerActions, getOwnersActionTypes, IBusinessPortalOwnerState} from '../types';

const initialState: IBusinessPortalOwnerState['ui'] = {
  pagination: {countPerPage: MIN_COUNT_PER_PAGE, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiBusinessPortalOwnerReducer: Reducer<IBusinessPortalOwnerState['ui'], BusinessPortalOwnerActions> = (
  state = initialState,
  action: BusinessPortalOwnerActions
) => {
  switch (action.type) {
    case getOwnersActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
