import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IRequestParams} from 'Common/models/IRequestParams';
import HorseAdminDataService from 'Admin/AdminDashboard/services/HorseDataService';

import {
  activateSampleIdActions,
  activateSampleIdActionTypes,
  createHorseActions,
  createHorseActionTypes,
  createSampleActions,
  createSampleActionTypes,
  deleteHorseActions,
  deleteHorseActionTypes,
  getHorseActions,
  getHorseActionTypes,
  getHorsesActions,
  getHorsesActionTypes,
  getHorseSamplesActionTypes,
  getHorsesSamplesActions,
  getShortHorseActions,
  getShortHorseActionTypes,
  hideAncestryReportActions,
  hideAncestryReportActionTypes,
  hideGeneticDataActions,
  hideGeneticDataActionTypes,
  horseArchiveAction,
  horseArchiveActionTypes,
  IArchiveHorseParams,
  IHideDataParams,
  resetHorseActions,
  resetHorseActionTypes,
  resetHorseDetailsActions,
  resetHorseDetailsActionTypes,
  resetHorseSamplesActions,
  resetHorseSamplesActionTypes,
  updateHorseActions,
  updateHorseActionTypes,
  updateSampleIdActions,
  updateSampleIdActionTypes,
} from './types';
import {IHorseRequestParams} from 'Horse/models/IHorseRequest';

import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const getHorses =
  (params?: IRequestParams): ActionResult<IAppState, void, getHorsesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorsesActionTypes.REQUEST));
      const horses = await HorseAdminDataService.getHorses(params);
      dispatch(action(getHorsesActionTypes.SUCCESS, horses));
    } catch (error) {
      dispatch(action(getHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getShortHorse =
  (horseId: number): ActionResult<IAppState, void, getShortHorseActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getShortHorseActionTypes.REQUEST));
      const horse = await HorseAdminDataService.getShortHorse(horseId);
      dispatch(action(getShortHorseActionTypes.SUCCESS, horse));
    } catch (error) {
      dispatch(action(getShortHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorse =
  (horseId: number): ActionResult<IAppState, void, getHorseActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseActionTypes.REQUEST));
      const horse = await HorseAdminDataService.getHorse(horseId);
      dispatch(action(getHorseActionTypes.SUCCESS, horse));
    } catch (error) {
      dispatch(action(getHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createHorse =
  (params: IHorseRequestParams): ActionResult<IAppState, Promise<number>, createHorseActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createHorseActionTypes.REQUEST));
      const data = await HorseAdminDataService.createHorse(params);
      dispatch(action(createHorseActionTypes.SUCCESS));
      return data;
    } catch (error) {
      dispatch(action(createHorseActionTypes.FAILURE, getErrorMessage(error)));
      // need for horse form submit
      throw error.response.data;
    }
  };

export const updateHorse =
  (params: IHorseRequestParams): ActionResult<IAppState, Promise<void>, updateHorseActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateHorseActionTypes.REQUEST));
      await HorseAdminDataService.updateHorse(params);
      dispatch(action(updateHorseActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateHorseActionTypes.FAILURE, getErrorMessage(error)));
      // need for horse form submit
      throw error.response.data;
    }
  };

export const deleteHorse =
  (horseId: number): ActionResult<IAppState, void, deleteHorseActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteHorseActionTypes.REQUEST));
      await HorseAdminDataService.deleteHorse(horseId);
      dispatch(action(deleteHorseActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setArchiveHorse =
  (params: IArchiveHorseParams): ActionResult<IAppState, void, horseArchiveAction> =>
  async (dispatch) => {
    try {
      const {horseId, isArchived} = params;
      dispatch(action(horseArchiveActionTypes.REQUEST));
      isArchived
        ? await HorseAdminDataService.archiveHorse(horseId)
        : await HorseAdminDataService.restoreHorse(horseId);
      dispatch(action(horseArchiveActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(horseArchiveActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetHorseResults = (): ActionResult<IAppState, void, resetHorseActions> => (dispatch) => {
  dispatch(action(resetHorseActionTypes.RESET));
};

export const resetHorseDetails = (): ActionResult<IAppState, void, resetHorseDetailsActions> => (dispatch) => {
  dispatch(action(resetHorseDetailsActionTypes.RESET));
};

export const setHideGeneticData =
  (params: IHideDataParams): ActionResult<IAppState, void, hideGeneticDataActions> =>
  async (dispatch) => {
    try {
      const {horseId, isHidden} = params;
      dispatch(action(hideGeneticDataActionTypes.REQUEST));
      await HorseAdminDataService.hideGeneticData(horseId, isHidden);
      dispatch(action(hideGeneticDataActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(hideGeneticDataActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateSampleId =
  (id: number, sampleId: string): ActionResult<IAppState, void, updateSampleIdActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateSampleIdActionTypes.REQUEST));
      await HorseAdminDataService.updateSampleId(id, sampleId);
      dispatch(action(updateSampleIdActionTypes.SUCCESS, sampleId));
    } catch (error) {
      dispatch(action(updateSampleIdActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const activateSampleId =
  (sampleId: string): ActionResult<IAppState, void, activateSampleIdActions> =>
  async (dispatch) => {
    try {
      dispatch(action(activateSampleIdActionTypes.REQUEST));
      await HorseAdminDataService.activateSampleId(sampleId);
      dispatch(action(activateSampleIdActionTypes.SUCCESS, sampleId));
    } catch (error) {
      dispatch(action(activateSampleIdActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createSample =
  (sampleId: string, horseId: number): ActionResult<IAppState, void, createSampleActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createSampleActionTypes.REQUEST));
      await HorseAdminDataService.createSample(sampleId, horseId);
      dispatch(action(createSampleActionTypes.SUCCESS, sampleId));
    } catch (error) {
      dispatch(action(createSampleActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseSamples =
  (horseId: number): ActionResult<IAppState, void, getHorsesSamplesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseSamplesActionTypes.REQUEST));
      const samples = await HorseAdminDataService.getHorseSamples(horseId);
      dispatch(action(getHorseSamplesActionTypes.SUCCESS, samples));
    } catch (error) {
      dispatch(action(getHorseSamplesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setHideAncestryReport =
  (params: IHideDataParams): ActionResult<IAppState, void, hideAncestryReportActions> =>
  async (dispatch) => {
    try {
      const {horseId, isHidden} = params;
      dispatch(action(hideAncestryReportActionTypes.REQUEST));
      await HorseAdminDataService.hideAncestryReport(horseId, isHidden);
      dispatch(action(hideAncestryReportActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(hideAncestryReportActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetHorseSamples = (): ActionResult<IAppState, void, resetHorseSamplesActions> => (dispatch) => {
  dispatch(action(resetHorseSamplesActionTypes.RESET));
};
