import {combineReducers} from 'redux';

import {
  AdminOnlineReportActions,
  changeReviewStatusActionTypes,
  deleteGeneticNoteActionTypes,
  getAbilitiesActionTypes,
  getAbilityDetailedActionTypes,
  getCoatColorActionTypes,
  getCoatColorDetailedActionTypes,
  getGeneticVariantsActionTypes,
  getHealthVariantDetailedActionTypes,
  getHealthVariantsActionTypes,
  getHorseBreedPanelsActionTypes,
  getHorseDetailsActionTypes,
  getHorseInfoActionTypes,
  getHorseOwnerActionTypes,
  getRequiredTestsActionTypes,
  getReviewSummaryAbilitiesActionTypes,
  getReviewSummaryColorsActionTypes,
  getReviewSummaryHealthIssuesActionTypes,
  getSummaryAbilitiesActionTypes,
  getSummaryColorsActionTypes,
  getSummaryHealthIssuesActionTypes,
  IAdminOnlineReportState,
  resetRequiredTestsActionTypes,
  updateGeneticNoteActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

export const communicationReducer = combineReducers<
  IAdminOnlineReportState['communications'],
  AdminOnlineReportActions
>({
  horseInfoLoading: makeCommunicationReducerFromEnum(getHorseInfoActionTypes),
  horseOwnerLoading: makeCommunicationReducerFromEnum(getHorseOwnerActionTypes),
  horseDetailsLoading: makeCommunicationReducerFromEnum(getHorseDetailsActionTypes),
  summaryAbilitiesLoading: makeCommunicationReducerFromEnum(getSummaryAbilitiesActionTypes),
  summaryColorsLoading: makeCommunicationReducerFromEnum(getSummaryColorsActionTypes),
  summaryHealthIssuesLoading: makeCommunicationReducerFromEnum(getSummaryHealthIssuesActionTypes),
  coatColorLoading: makeCommunicationReducerFromEnum(getCoatColorActionTypes),
  coatColorsDetailedLoading: makeCommunicationReducerFromEnum(getCoatColorDetailedActionTypes),
  healthVariantsLoading: makeCommunicationReducerFromEnum(getHealthVariantsActionTypes),
  healthVariantsDetailedLoading: makeCommunicationReducerFromEnum(getHealthVariantDetailedActionTypes),
  abilitiesLoading: makeCommunicationReducerFromEnum(getAbilitiesActionTypes),
  abilityDetailedLoading: makeCommunicationReducerFromEnum(getAbilityDetailedActionTypes),
  geneticNoteUpdating: makeCommunicationReducerFromEnum(updateGeneticNoteActionTypes),
  requiredTestsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getRequiredTestsActionTypes),
    makeResetCommunicationReducer(resetRequiredTestsActionTypes.RESET),
  ]),
  geneticNoteDeleting: makeCommunicationReducerFromEnum(deleteGeneticNoteActionTypes),
  reviewSummaryAbilitiesLoading: makeCommunicationReducerFromEnum(getReviewSummaryAbilitiesActionTypes),
  reviewSummaryColorsLoading: makeCommunicationReducerFromEnum(getReviewSummaryColorsActionTypes),
  reviewSummaryHealthIssuesLoading: makeCommunicationReducerFromEnum(getReviewSummaryHealthIssuesActionTypes),
  changeReviewStatusRequesting: makeCommunicationReducerFromEnum(changeReviewStatusActionTypes),
  geneticVariantsLoading: makeCommunicationReducerFromEnum(getGeneticVariantsActionTypes),
  horseBreedPanelsLoading: makeCommunicationReducerFromEnum(getHorseBreedPanelsActionTypes),
});
