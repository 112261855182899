import React, {memo} from 'react';
import styled from 'styled-components';

import EditableSection from 'BusinessPortal/components/common/EditableSection/EditableSection';
import ChangePasswordShow from './ChangePasswordShow';
import ChangePasswordEdit from './ChangePasswordEdit';

const Root = styled.div`
  max-width: 576px;
  margin-bottom: 46px;
`;

const ChangePassword = () => {
  return (
    <Root className="position-relative">
      <EditableSection
        title="Password"
        data={null}
        editComponent={ChangePasswordEdit}
        showComponent={ChangePasswordShow}
      />
    </Root>
  );
};

export default memo(ChangePassword);
