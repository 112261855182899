import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {IAdminPermissions} from 'Common/models/IAdminPermissions';

const STATE_NAME = 'adminPermissions';

export interface IAdminPermissionsState {
  data: {
    currentAdminPermissions: string[];
    adminPermissions: IAdminPermissions[];
  };
  communications: {
    currentAdminPermissionsLoading: ICommunication;
    adminPermissionsLoading: ICommunication;
    adminPermissionsUpdating: ICommunication;
  };
}

export const getCurrentAdminPermissionsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_CURRENT_ADMIN_PERMISSIONS'
);

export type getCurrentAdminPermissionsActions = MakeCommunicationActions<
  typeof getCurrentAdminPermissionsActionTypes,
  {success: string[]}
>;

export const getAdminPermissionsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ADMIN_PERMISSIONS');

export type getAdminPermissionsActions = MakeCommunicationActions<
  typeof getAdminPermissionsActionTypes,
  {success: IAdminPermissions[]}
>;

export const setAdminPermissionsActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_ADMIN_PERMISSIONS');

export type setAdminPermissionsActions = MakeCommunicationActions<typeof setAdminPermissionsActionTypes, {}>;

export const resetAdminPermissionsActionTypes = makeResetCommunicationActionType(STATE_NAME, 'ADMIN_PERMISSIONS');
export type resetAdminPermissionsActions = MakeResetCommunicationActions<typeof resetAdminPermissionsActionTypes>;

export type AdminPermissionsActions =
  | getCurrentAdminPermissionsActions
  | getAdminPermissionsActions
  | setAdminPermissionsActions
  | resetAdminPermissionsActions;
