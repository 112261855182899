import {IPhenotypeFilter} from 'OnlineReport/models/shared/IPhenotypeFilter';
import React from 'react';
import GroupElementsSection from './GroupElementsSection';
import GroupSection from './GroupSection';

export interface IProps {
  phenotypeFilter?: IPhenotypeFilter;
}

type AllProps = React.ComponentProps<typeof GroupSection> & React.ComponentProps<typeof GroupElementsSection> & IProps;

const Group = ({elements, phenotypeFilter, ...props}: AllProps) => {
  const visibleElements =
    phenotypeFilter === undefined ||
    (phenotypeFilter.advancedIds === undefined && phenotypeFilter.aggregatedIds === undefined)
      ? elements
      : elements.filter((element) =>
          element.isAggregated && element.aggregatorId
            ? phenotypeFilter.aggregatedIds.includes(element.aggregatorId)
            : phenotypeFilter.advancedIds.includes(element.id)
        );

  return (
    <>
      {visibleElements.length > 0 ? (
        <GroupSection {...props}>
          <GroupElementsSection elements={visibleElements} {...props} />
        </GroupSection>
      ) : null}
    </>
  );
};

export default Group;
