import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {ISegment} from 'Common/models/ISegment';

const HOVERED_OPACITY = '.4';

const BodySegment = styled.div<{color: string; hovered?: boolean}>`
  background-color: ${prop => prop.color};
  ${prop => prop.hovered && `opacity: ${HOVERED_OPACITY}`};
  height: 100%;
  width: 100%;
  transform: skew(-35deg);
  flex-grow: 1;
  position: relative;

  :not(:first-child) {
    margin-left: 3px;
  }
`;

const SegmentLabel = styled.span<{color: string}>`
  text-align: center;
  color: ${prop => prop.color};
  font-size: ${Typography.size.size13};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) skew(35deg);
`;

export interface ISegmentProps extends ISegment {
  needInlineLabel?: boolean;
  hovered?: boolean;
  selected?: boolean;
  current?: boolean;
}

const Segment = (props: ISegmentProps) => {
  const segmentColor: string = props.color ? props.color : ColorPalette.red1;
  const labelColor: string = props.labelColor ? props.labelColor : ColorPalette.black1;
  const {label, needInlineLabel, hovered} = props;

  const renderLabel = () => {
    return (
      <SegmentLabel key={`label${props.id}`} color={labelColor}>
        {props.label}
      </SegmentLabel>
    );
  };

  return (
    <BodySegment color={segmentColor} hovered={hovered}>
      {label && needInlineLabel && renderLabel()}
    </BodySegment>
  );
};

export default memo(Segment);
