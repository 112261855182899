import React, {memo, useCallback} from 'react';
import {Moment} from 'moment';
import styled from 'styled-components';

import DateTime, {Props as DateTimeProps} from 'Common/components/Controls/DateTime';
import {IFieldProps} from 'Common/models/IFieldProps';
import {FRONTEND_DATE, FRONTEND_DATE_TIME} from 'Common/constants/Date';
import {isValidDate, convertToClientDate} from 'Common/helpers/DateHelper';

import withField from './withField';
import ValidationError from './ValidationError';

const InputLayout = styled.div`
  position: relative;
`;

type Props = IFieldProps<Moment | string> & Omit<DateTimeProps, 'value' | 'onChange'>;

function DateTimeField(props: Props) {
  const {
    field: {name, value},
    form,
    futureEnabled,
    showTime,
    dateFormat,
    ...rest
  } = props;

  const onChange = useCallback(
    (date: Moment | string) => {
      const dateFormatConvertTo = showTime ? FRONTEND_DATE_TIME : dateFormat ? String(dateFormat) : FRONTEND_DATE;
      date && isValidDate(date, dateFormatConvertTo, futureEnabled)
        ? form.setFieldValue(name, convertToClientDate(date, dateFormatConvertTo))
        : form.setFieldValue(name, date);
    },
    [form, name, futureEnabled, showTime, dateFormat]
  );

  const onBlur = useCallback(() => {
    if (!form.touched[name]) {
      form.setFieldTouched(name, true);
    }
  }, [form, name]);

  return (
    <InputLayout>
      <DateTime
        {...rest}
        futureEnabled={futureEnabled}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        showTime={showTime}
      />
      <ValidationError name={name} />
    </InputLayout>
  );
}

export default memo(withField(DateTimeField));
