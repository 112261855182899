import {combineReducers, Reducer} from 'redux';

import {communicationHorseReducer} from 'BusinessPortal/store/horseProfile/reducer/communications';
import {dataHorseReducer} from 'BusinessPortal/store/horseProfile/reducer/data';
import {IBusinessPortalHorseProfileState} from 'BusinessPortal/store/horseProfile/types';

export const adminAssociationHorseProfileReducer: Reducer<IBusinessPortalHorseProfileState> =
  combineReducers<IBusinessPortalHorseProfileState>({
    communications: communicationHorseReducer,
    data: dataHorseReducer,
  });
