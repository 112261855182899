import React, {memo} from 'react';
import AvatarCellHorse from 'Admin/AdminDashboard/components/Orders/shared/AvatarCellHorse';
import {dateDiffInYearsOrMonth} from 'Common/helpers/DateHelper';
import styled from 'styled-components';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';

const Root = styled.div`
  margin: 8px 0;
`;

const HorseName = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${Theme.color.black};
  margin-bottom: 4px;
`;

const HorseInfo = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 12px;
  letter-spacing: 0.5px;
  color: ${ColorPalette.gray44};
`;

interface IProps {
  horse?: IOrderHorse;
}

const OrderHorse = (props: IProps) => {
  const {horse} = props;

  return (
    <Root className="d-flex align-items-center">
      <AvatarCellHorse
        type="horse"
        label=""
        avatarUrl={horse?.avatar?.url}
        isArchived={false}
        isDeleted={false}
        isGeneticDataHidden={false}
      />
      <div className="d-flex w-100 flex-column">
        <HorseName>
          {horse?.name} ({horse?.ownerName})
        </HorseName>
        <HorseInfo>
          {horse?.gender}
          {horse?.dateOfBirth && `, ${dateDiffInYearsOrMonth(horse?.dateOfBirth)}`}
        </HorseInfo>
      </div>
    </Root>
  );
};

export default memo(OrderHorse);
