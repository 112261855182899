import {IModule} from 'redux-dynamic-modules';

import {IAdminOrdersState} from 'Admin/AdminDashboard/store/adminOrders/types';
import {ordersReducer} from 'Admin/AdminDashboard/store/adminOrders/reducer/index';

export interface IOrdersModuleState {
  adminOrders: IAdminOrdersState;
}

export const AdminOrdersModule: IModule<IOrdersModuleState> = {
  id: 'adminOrders',
  reducerMap: {
    adminOrders: ordersReducer,
  },
};
