import styled from 'styled-components';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

export const ChangeOwnerFormButton = styled.div`
  width: fit-content;
  cursor: pointer;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 18px;
  color: ${Theme.color.primary};
  letter-spacing: 0.2px;
  margin-top: -8px;
`;
