import {Reducer} from 'redux';

import {
  BusinessPortalHorseActions,
  resetHorseDetailsActionTypes,
  resetHorseHealthIssuesActionTypes,
  resetHorseMediaActionTypes,
  resetHorseOwnerActionTypes,
  resetHorseParentageActionTypes,
  resetHorseRegistriesActionTypes,
  resetHorsesActionTypes,
  getHorseDetailsActionTypes,
  getHorseHealthIssuesActionTypes,
  getHorseMediaActionTypes,
  getHorseOwnerActionTypes,
  getHorseParentageActionTypes,
  getHorseRegistriesActionTypes,
  getHorsesActionTypes,
  IBusinessPortalHorseState,
  updateHorseProfileTypeActionTypes,
  updateHorseAncestryProfileTypeActionTypes,
} from '../types';

const initialState: IBusinessPortalHorseState['data'] = {
  horses: [],
  horseDetails: null,
  horseOwner: null,
  horseParentage: null,
  horseRegistries: null,
  horseHealthIssues: null,
  horseMedia: null,
};

export const dataBusinessPortalHorseReducer: Reducer<IBusinessPortalHorseState['data'], BusinessPortalHorseActions> = (
  state = initialState,
  action: BusinessPortalHorseActions
) => {
  switch (action.type) {
    case getHorsesActionTypes.SUCCESS: {
      return {...state, horses: action.payload.data};
    }
    case getHorseDetailsActionTypes.SUCCESS: {
      return {...state, horseDetails: action.payload};
    }
    case getHorseOwnerActionTypes.SUCCESS: {
      return {...state, horseOwner: action.payload};
    }
    case getHorseParentageActionTypes.SUCCESS: {
      return {...state, horseParentage: action.payload};
    }
    case getHorseRegistriesActionTypes.SUCCESS: {
      return {...state, horseRegistries: action.payload};
    }
    case getHorseHealthIssuesActionTypes.SUCCESS: {
      return {...state, horseHealthIssues: action.payload};
    }
    case getHorseMediaActionTypes.SUCCESS: {
      return {...state, horseMedia: action.payload};
    }
    case updateHorseProfileTypeActionTypes.SUCCESS: {
      if (!state.horseDetails) {
        return {...state};
      }

      return {
        ...state,
        horseDetails: {...state.horseDetails, profileType: action.payload},
      };
    }
    case updateHorseAncestryProfileTypeActionTypes.SUCCESS: {
      if (!state.horseDetails) {
        return {...state};
      }

      return {...state, horseDetails: {...state.horseDetails, ancestryProfileType: action.payload}};
    }

    case resetHorseMediaActionTypes.RESET: {
      return {...state, horseMedia: initialState.horseMedia};
    }
    case resetHorseDetailsActionTypes.RESET: {
      return {...state, horseDetails: initialState.horseDetails};
    }
    case resetHorseOwnerActionTypes.RESET: {
      return {...state, horseOwner: initialState.horseOwner};
    }
    case resetHorseParentageActionTypes.RESET: {
      return {...state, horseParentage: initialState.horseParentage};
    }
    case resetHorseRegistriesActionTypes.RESET: {
      return {...state, horseRegistries: initialState.horseRegistries};
    }
    case resetHorseHealthIssuesActionTypes.RESET: {
      return {...state, horseHealthIssues: initialState.horseHealthIssues};
    }
    case resetHorsesActionTypes.RESET: {
      return {...state, horses: initialState.horses};
    }

    default: {
      return state;
    }
  }
};
