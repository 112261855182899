import {combineReducers, Reducer} from 'redux';

import {dataMailConfirmationReducer} from './data';
import {IMailConfirmationState} from '../types';
import {communicationMailConfirmationReducer} from './communication';

export const mailConfirmationReducer: Reducer<IMailConfirmationState> = combineReducers<IMailConfirmationState>({
  communications: communicationMailConfirmationReducer,
  data: dataMailConfirmationReducer
});
