import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {changePasswordActions, changePasswordActionTypes, IAdminChangePasswordState} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IChangePasswordAdmin} from 'Admin/AdminDashboard/services/types/user';
import UserDataService from 'Admin/AdminDashboard/services/UserDataService';

export const changeUserPassword = (
  params: IChangePasswordAdmin
): ActionResult<IAdminChangePasswordState, void, changePasswordActions> => async dispatch => {
  try {
    dispatch(action(changePasswordActionTypes.REQUEST));
    await UserDataService.changeUserPassword(params);
    dispatch(action(changePasswordActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(changePasswordActionTypes.FAILURE, getErrorMessage(error)));
  }
};
