import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import TestReportService from 'Admin/AdminDashboard/services/TestReportService';
import {IUploadTestReportRequest} from 'Admin/shared/models/IUploadTestReportRequest';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {
  deleteReportActions,
  deleteReportActionTypes,
  uploadReportActions,
  uploadReportActionTypes,
} from 'Admin/AdminDashboard/store/common/testReport/types';

export const uploadReport =
  (data: IUploadTestReportRequest): ActionResult<IAppState, void, uploadReportActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadReportActionTypes.REQUEST));
      await TestReportService.uploadReport(data);
      dispatch(action(uploadReportActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(uploadReportActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteReport =
  (id: number): ActionResult<IAppState, void, deleteReportActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteReportActionTypes.REQUEST));
      await TestReportService.deleteReport(id);
      dispatch(action(deleteReportActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteReportActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
