import React, {useCallback, useState} from 'react';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import ChangePassword from 'Admin/AdminDashboard/components/Users/ChangePassword/ChangePassword';

interface IProps {
  onSuccess(): void;
}

export function useChangeUserPasswordModal(props: IProps) {
  const {onSuccess} = props;

  const [selectedId, setSelectedId] = useState<number>();
  const [selectedName, setSelectedName] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');

  const [isChangeUserPasswordOpen, setIsChangeUserPasswordOpen] = useState(false);
  const openChangeUserPasswordModal = useCallback(
    (userId: number, userName: string, userEmail: string) => {
      setSelectedId(userId);
      setSelectedName(userName);
      setSelectedEmail(userEmail);
      setIsChangeUserPasswordOpen(true);
    },
    [setSelectedId]
  );
  const closeChangeUserPasswordModal = useCallback(() => setIsChangeUserPasswordOpen(false), []);
  const onSuccessChangeUserPassword = useCallback(() => {
    closeChangeUserPasswordModal();
    onSuccess();
  }, [onSuccess, closeChangeUserPasswordModal]);

  const changeUserPasswordModal = (
    <ModalWindow isOpen={isChangeUserPasswordOpen && !!selectedId} onClose={closeChangeUserPasswordModal}>
      <ChangePassword userName={selectedName} userEmail={selectedEmail} onSuccess={onSuccessChangeUserPassword} />
    </ModalWindow>
  );

  return {
    openChangeUserPasswordModal,
    changeUserPasswordModal
  };
}
