import {IMailConfirmation} from 'MailConfirmation/models/IMailConfirmation';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IObjectResponse} from 'Common/models/IResponse';

const confirmEmail = async (data: IMailConfirmation): Promise<string> => {
  const response = await axiosWrapper.patch<IObjectResponse<string>>(`/ConfirmEmail`, data);
  return response?.data?.data;
};

const requestNewMailConfirmation = async (email: string): Promise<void> => {
  await axiosWrapper.patch<IObjectResponse<void>>(`/MailConfirmation`, {
    email,
  });
};

export default {
  confirmEmail,
  requestNewMailConfirmation,
};
