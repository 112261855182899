import {useHistory} from 'react-router-dom';

import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import {useAdminOwnerModal} from '../useAdminOwnerModal';
import {
  ISetSampleStatusActionProps,
  useSetSampleStatusAction,
} from 'Admin/shared/helpers/hooks/useOrderActions/useSetSampleStatusAction';
import {useConfirmModal} from 'Common/helpers/hooks/useConfirmModal';
import {useReleaseOrderHorseAction} from 'Admin/shared/helpers/hooks/useOrderActions/useReleaseOrderHorseAction';
import {useUploadReportModal} from 'Admin/shared/helpers/hooks/useOrderActions/useUploadReportModal';
import {useUpdatePaymentModal} from 'Admin/shared/helpers/hooks/useOrderActions/useUpdatePaymentModal';
import {IReleaseOrderHorse, IRequestChangeReviewStatus} from 'Admin/AdminAssociations/store/adminOrders';
import {IAdminActionsProps} from 'Admin/AdminDashboard/helpers/hooks/common';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {IOrderStatused} from 'Common/models/IOrderStatused';
import {OrderStatus} from 'Common/constants/OrderStatus';
import {useCallback} from 'react';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';
import {useRequiredTestsModal} from 'Admin/shared/helpers/hooks/useOrderActions/useRequiredTestsModal';
import {IRequiredTest} from 'Admin/AdminDashboard/models/IRequiredTest';

const iconProps = {color: ColorPalette.red7};

const menuItems = {
  editOwner: {
    label: 'Edit owner',
    value: 'editOwner',
    icon: {name: IconName.Edit, ...iconProps, fill: true, stroke: false, color: ColorPalette.white7},
  },
  updatePayment: {label: 'Update payment', value: 'updatePayment', icon: {name: IconName.Money, ...iconProps}},
  sampleReceived: {
    label: 'Mark sample as received',
    value: 'sampleReceived',
    icon: {name: IconName.Truck, ...iconProps},
  },
  sampleNotReceived: {
    label: 'Sample not received',
    value: 'sampleNotReceived',
    icon: {name: IconName.Truck, ...iconProps},
  },
  uploadReport: {label: 'Upload report', value: 'uploadReport', icon: {name: IconName.FilledCheckbox, ...iconProps}},
  downloadForm: {label: 'Submission form', value: 'downloadForm', icon: {name: IconName.Description, ...iconProps}},
  dismissReview: {label: 'Dismiss review', value: 'dismissReview', icon: {name: IconName.Description, ...iconProps}},
};

export interface IDismissReviewActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(data: IRequestChangeReviewStatus): void;
}

export interface IReleaseActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(data: IReleaseOrderHorse): void;
}

export interface IDownloadFormActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(orderId: number, horseId: number): void;
}

export interface IRequiredTestsActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(orderId: number): void;
}

export interface IOwnerEditActionProps extends Omit<IAdminActionsProps, 'action' | 'communication'> {}

interface IOrderDetailsActionsPartsProps {
  setSampleStatusAction: Omit<ISetSampleStatusActionProps, 'onSuccess'>;
  dismissReviewAction: Omit<IDismissReviewActionProps, 'onSuccess'>;
  releaseOrderAction: Omit<IReleaseActionProps, 'onSuccess'>;
  downloadFormAction: Omit<IDownloadFormActionProps, 'onSuccess'>;
  requiredTestsAction: Omit<IRequiredTestsActionProps, 'onSuccess'>;
  ownerEditAction: IOwnerEditActionProps;
}

interface IProps {
  horseId: number;
  orderId: number;
  ownerId?: number;
  horseName?: string;
  ownerName?: string;
  orderStatusesDetails?: IOrderStatused;
  requiredTests?: IRequiredTest[];
  data: IOrderDetailsActionsPartsProps;
  onSuccess(): void;
}

export function useOrderDetailsActions(props: IProps) {
  const {
    horseId,
    orderId,
    ownerId,
    horseName,
    ownerName,
    orderStatusesDetails,
    onSuccess,
    data: {
      dismissReviewAction,
      setSampleStatusAction,
      releaseOrderAction,
      downloadFormAction,
      requiredTestsAction,
      ownerEditAction,
    },
    requiredTests = [],
  } = props;
  const history = useHistory();
  const {emit} = useEventBus();

  const {updateAdminOwnerModal, openUpdateAdminOwnerModal} = useAdminOwnerModal({onSuccess: ownerEditAction.onSuccess});

  const {updatePaymentModal, openUpdatePaymentModal} = useUpdatePaymentModal({onSuccess, orderId});

  const {openSampleNotReceivedModal, openSampleReceivedModal, setSampleStatusModal} = useSetSampleStatusAction({
    action: setSampleStatusAction.action,
    onSuccess: () => {
      emit(EventName.SampleChanged);
      onSuccess();
    },
  });

  useErrorCommunicationToToast(setSampleStatusAction.communication);

  const {confirmModal: dismissReviewConfirmModal, openConfirmModal: openDismissReviewConfirmModal} = useConfirmModal({
    confirmDescription: 'Are you sure you want to dismiss review?',
    confirmCommunication: dismissReviewAction.communication,
    successMessage: 'Review successfully dismissed',
    onConfirmAction: dismissReviewAction.action,
    confirmId: 'dismissReviewConfirm',
    onSuccess: () => {
      emit(EventName.ReleaseOrder);
      onSuccess();
    },
  });

  const {openReleaseOrderHorseModal, releaseOrderHorseModal} = useReleaseOrderHorseAction({
    onSuccess: () => {
      emit(EventName.ReleaseOrder);
      onSuccess();
    },
    communication: releaseOrderAction.communication,
    action: releaseOrderAction.action,
    orderId,
  });

  const {uploadReportModal, openUploadReportModal} = useUploadReportModal({
    onSuccess: () => {
      emit(EventName.ReportUpload);
      onSuccess();
    },
    horseId,
    orderId,
    horseName: horseName || '',
    ownerName: ownerName || '',
  });

  const redirectToReviewOnlineReport = () => {
    history.push(`/review-admin-associations/online-report/${horseId}/${orderId}`);
  };

  const downloadSubmissionForm = useCallback(() => {
    downloadFormAction.action(orderId, horseId);
  }, [downloadFormAction, horseId, orderId]);

  const {openRequiredTestsModal, requiredTestsModal} = useRequiredTestsModal({
    orderId,
    horseName: horseName || '',
    ownerName: ownerName || '',
    requiredTests,
    communication: requiredTestsAction.communication,
    action: requiredTestsAction.action,
  });

  const menuItemsFunc = {
    editOwner: () => openUpdateAdminOwnerModal(ownerId),
    updatePayment: () => openUpdatePaymentModal(orderId),
    sampleReceived: () => openSampleReceivedModal(orderId),
    sampleNotReceived: () => openSampleNotReceivedModal(orderId),
    dismissReview: () => openDismissReviewConfirmModal({orderId, reviewStatus: 'Waiting'}),
    reviewReady: openReleaseOrderHorseModal,
    uploadReport: () => openUploadReportModal(),
    downloadForm: downloadSubmissionForm,
  };

  const ownerActions = [
    {
      icon: menuItems.editOwner.icon,
      onClick: menuItemsFunc.editOwner,
    },
  ];

  const statusItemsFunc = {
    orderPlaced: menuItemsFunc.updatePayment,
    paymentReceived: menuItemsFunc.sampleReceived,
    sampleReceived: redirectToReviewOnlineReport,
    resultsReady: redirectToReviewOnlineReport,
    reviewReady: menuItemsFunc.reviewReady,
  };

  const availableSampleFunc = orderStatusesDetails?.hasSample ? menuItems.sampleNotReceived : menuItems.sampleReceived;

  const statusMenu = {
    items: [menuItems.updatePayment, availableSampleFunc]
      .concat(orderStatusesDetails?.hasPayment ? [menuItems.uploadReport] : [])
      .concat(orderStatusesDetails?.status === OrderStatus.reviewReady ? [menuItems.dismissReview] : []),
    onSelect: (item: string) => menuItemsFunc[item](),
  };

  const paymentMenu = {
    items: [menuItems.updatePayment],
    onSelect: (item: string) => menuItemsFunc[item](),
  };

  const sampleMenu = {
    items: [menuItems.downloadForm].concat([menuItems.updatePayment, availableSampleFunc]),
    onSelect: (item: string) => menuItemsFunc[item](),
  };

  return {
    statusItemsFunc,
    ownerActions,
    statusMenu,
    paymentMenu,
    sampleMenu,
    updateAdminOwnerModal,
    updatePaymentModal,
    setSampleStatusModal,
    dismissReviewConfirmModal,
    releaseOrderHorseModal,
    uploadReportModal,
    openUploadReportModal,
    openRequiredTestsModal,
    requiredTestsModal,
  };
}
