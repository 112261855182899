import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

import {
  Actions,
  createOrderActionTypes,
  getCouponActionTypes,
  getHorsesActionTypes,
  getSummaryActionTypes,
  IBusinessPortalCreateOrderState,
} from 'BusinessPortal/store/createOrder/types';

export const communicationReducer = combineReducers<IBusinessPortalCreateOrderState['communications'], Actions>({
  horsesLoading: makeCommunicationReducerFromEnum(getHorsesActionTypes),
  summaryLoading: makeCommunicationReducerFromEnum(getSummaryActionTypes),
  couponLoading: makeCommunicationReducerFromEnum(getCouponActionTypes),
  creatingOrder: makeCommunicationReducerFromEnum(createOrderActionTypes),
});
