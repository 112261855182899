import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {downloadRulesBackupActions, downloadRulesBackupActionTypes, IAdminRulesBackupState} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import AdminRulesBackupService from 'Admin/AdminAdvancedDashboard/services/AdminRulesBackupService';

export const downloadRulesBackup = (): ActionResult<
  IAdminRulesBackupState,
  Promise<void>,
  downloadRulesBackupActions
> => async dispatch => {
  dispatch(action(downloadRulesBackupActionTypes.REQUEST));
  try {
    const res = await AdminRulesBackupService.downloadRulesBackup();
    dispatch(action(downloadRulesBackupActionTypes.SUCCESS, res));
  } catch (error) {
    dispatch(action(downloadRulesBackupActionTypes.FAILURE, getErrorMessage(error)));
  }
};
