import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';
import {Nullable} from 'Common/types';
import {IOnlineReportCoatColor} from 'OnlineReport/models/CoatColor/IOnlineReportCoatColor';
import {IOnlineReportCoatColorDetailed} from 'OnlineReport/models/CoatColor/IOnlineReportCoatColorDetailed';
import {IOnlineReportHealthVariants} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariants';
import {IOnlineReportHealthVariantDetailed} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariantDetailed';
import {IOnlineReportAbility} from 'OnlineReport/models/PerformanceAndAbilities/IOnlineReportAbility';
import {IOnlineReportAbilityDetailed} from 'OnlineReport/models/PerformanceAndAbilities/IOnlineReportAbilityDetailed';
import {IOnlineReportHorseInfo} from 'OnlineReport/models/shared/IOnlineReportHorseInfo';
import {IOnlineReportHorseDetails} from 'OnlineReport/models/shared/IOnlineReportHorseDetails';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import {IOnlineReportSummary} from 'OnlineReport/models/Summary/IOnlineReportSummary';
import {IOnlineReportGeneticVariants} from 'OnlineReport/models/Summary/IOnlineReportGenotype';

const STATE_NAME = 'onlineReport';

export interface IOnlineReportState {
  data: {
    horseInfo: Nullable<IOnlineReportHorseInfo>;
    horseOwner: Nullable<string>;
    horseDetails: Nullable<IOnlineReportHorseDetails>;
    summaryAbilities: Nullable<IOnlineReportSummary>;
    summaryColors: Nullable<IOnlineReportSummary>;
    summaryHealthIssues: Nullable<IOnlineReportSummary>;
    geneticVariants: Nullable<IOnlineReportGeneticVariants>;
    horseBreedPanels: IHorseBreedPanel[];

    coatColors: Nullable<IOnlineReportCoatColor[]>;
    coatColorsDetailed: Nullable<IOnlineReportCoatColorDetailed[]>;

    healthVariants: Nullable<IOnlineReportHealthVariants[]>;
    healthVariantsDetailed: Nullable<IOnlineReportHealthVariantDetailed[]>;

    abilities: Nullable<IOnlineReportAbility[]>;
    abilityDetailed: Nullable<IOnlineReportAbilityDetailed[]>;
  };
  communications: {
    horseInfoLoading: ICommunication;
    horseOwnerLoading: ICommunication;
    horseDetailsLoading: ICommunication;
    summaryAbilitiesLoading: ICommunication;
    summaryColorsLoading: ICommunication;
    summaryHealthIssuesLoading: ICommunication;
    coatColorLoading: ICommunication;
    coatColorsDetailedLoading: ICommunication;
    healthVariantsLoading: ICommunication;
    healthVariantsDetailedLoading: ICommunication;
    abilitiesLoading: ICommunication;
    abilityDetailedLoading: ICommunication;
    downloadDiagnosticReportLoading: ICommunication;
    geneticVariantsLoading: ICommunication;
    horseBreedPanelsLoading: ICommunication;
  };
}

export const getHorseInfoActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_INFO');
export type getHorseInfoActions = MakeCommunicationActions<
  typeof getHorseInfoActionTypes,
  {success: IOnlineReportHorseInfo}
>;

export const getHorseOwnerActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_OWNER');
export type getHorseOwnerActions = MakeCommunicationActions<typeof getHorseOwnerActionTypes, {success: string}>;

export const getHorseDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_DETAILS');
export type getHorseDetailsActions = MakeCommunicationActions<
  typeof getHorseDetailsActionTypes,
  {success: IOnlineReportHorseDetails}
>;

export const getSummaryAbilitiesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_SUMMARY_ABILITIES');
export type getSummaryAbilitiesActions = MakeCommunicationActions<
  typeof getSummaryAbilitiesActionTypes,
  {success: IOnlineReportSummary}
>;

export const getSummaryColorsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_SUMMARY_COLORS');
export type getSummaryColorsActions = MakeCommunicationActions<
  typeof getSummaryColorsActionTypes,
  {success: IOnlineReportSummary}
>;

export const getSummaryHealthIssuesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_SUMMARY_HEALTH_ISSUES');
export type getSummaryHealthIssuesActions = MakeCommunicationActions<
  typeof getSummaryHealthIssuesActionTypes,
  {success: IOnlineReportSummary}
>;

export const getGeneticVariantsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_GENETIC_VARIANTS');
export type getGeneticVariantsActions = MakeCommunicationActions<
  typeof getGeneticVariantsActionTypes,
  {success: IOnlineReportGeneticVariants}
>;

export const getCoatColorActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_COAT_COLOR');
export type getCoatColorActions = MakeCommunicationActions<
  typeof getCoatColorActionTypes,
  {success: IOnlineReportCoatColor[]}
>;

export const getCoatColorDetailedActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_COAT_COLOR_DETAILED');
export type getCoatColorDetailedActions = MakeCommunicationActions<
  typeof getCoatColorDetailedActionTypes,
  {success: IOnlineReportCoatColorDetailed}
>;

export const getHealthVariantsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HEALTH_VARIANTS');
export type getHealthVariantsActions = MakeCommunicationActions<
  typeof getHealthVariantsActionTypes,
  {success: IOnlineReportHealthVariants[]}
>;

export const getHealthVariantDetailedActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_HEALTH_VARIANT_DETAILED'
);
export type getHealthVariantDetailedActions = MakeCommunicationActions<
  typeof getHealthVariantDetailedActionTypes,
  {success: IOnlineReportHealthVariantDetailed}
>;

export const getAbilitiesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ABILITIES');
export type getAbilitiesActions = MakeCommunicationActions<
  typeof getAbilitiesActionTypes,
  {success: IOnlineReportAbility[]}
>;

export const getAbilityDetailedActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ABILITY_DETAILED');
export type getAbilityDetailedActions = MakeCommunicationActions<
  typeof getAbilityDetailedActionTypes,
  {success: IOnlineReportAbilityDetailed}
>;

export const downloadDiagnosticReportActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DOWNLOAD_DIAGNOSTIC_REPORT'
);
export type downloadDiagnosticReportActions = MakeCommunicationActions<typeof downloadDiagnosticReportActionTypes, {}>;

export const getHorseBreedPanelsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_BREED_PANEL');
export type getHorseBreedPanelsActions = MakeCommunicationActions<
  typeof getHorseBreedPanelsActionTypes,
  {success: IHorseBreedPanel[]}
>;

export type OnlineReportActions =
  | getHorseInfoActions
  | getHorseOwnerActions
  | getHorseDetailsActions
  | getSummaryAbilitiesActions
  | getSummaryColorsActions
  | getSummaryHealthIssuesActions
  | getCoatColorActions
  | getCoatColorDetailedActions
  | getHealthVariantsActions
  | getHealthVariantDetailedActions
  | getAbilitiesActions
  | getAbilityDetailedActions
  | downloadDiagnosticReportActions
  | getGeneticVariantsActions
  | getHorseBreedPanelsActions;
