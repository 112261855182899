import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  getEmployeeGeoLocationActions,
  getEmployeeGeoLocationActionTypes,
  getEmployeeProfileActions,
  getEmployeeProfileActionTypes,
  IBusinessPortalEmployeeProfileState,
  updateEmployeeEmailActions,
  updateEmployeeEmailActionTypes,
  updateEmployeeGeoLocationActions,
  updateEmployeeGeoLocationActionTypes,
  updateEmployeePasswordActions,
  updateEmployeePasswordActionTypes,
  updateEmployeeProfileActions,
  updateEmployeeProfileActionTypes,
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import BusinessPortalEmployeeService from 'BusinessPortal/services/BusinessPortalEmployeeProfileService';
import {IAppState} from 'Common/store/IAppState';
import {ILocation} from 'Common/models/ICoordinatedLocation';
import {IServerEmployeePasswordUpdate, IServerEmployeeProfileUpdate} from 'BusinessPortal/services/types/employee';

export const getEmployeeProfile =
  (): ActionResult<IBusinessPortalEmployeeProfileState, void, getEmployeeProfileActions> => async (dispatch) => {
    dispatch(action(getEmployeeProfileActionTypes.REQUEST));

    await BusinessPortalEmployeeService.getEmployeeProfile()
      .then((res) => {
        dispatch(action(getEmployeeProfileActionTypes.SUCCESS, res.data.data));
      })
      .catch((error) => {
        dispatch(action(getEmployeeProfileActionTypes.FAILURE, getErrorMessage(error)));
      });
  };

export const updateEmployeeProfile =
  (data: IServerEmployeeProfileUpdate): ActionResult<IAppState, Promise<void>, updateEmployeeProfileActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateEmployeeProfileActionTypes.REQUEST));
      await BusinessPortalEmployeeService.updateEmployeeProfile(data);
      dispatch(action(updateEmployeeProfileActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateEmployeeProfileActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getEmployeeGeoLocation =
  (): ActionResult<IBusinessPortalEmployeeProfileState, void, getEmployeeGeoLocationActions> => async (dispatch) => {
    dispatch(action(getEmployeeGeoLocationActionTypes.REQUEST));

    await BusinessPortalEmployeeService.getEmployeeGeoLocation()
      .then((res) => {
        dispatch(action(getEmployeeGeoLocationActionTypes.SUCCESS, res.data.data));
      })
      .catch((error) => {
        dispatch(action(getEmployeeGeoLocationActionTypes.FAILURE, getErrorMessage(error)));
      });
  };

export const updateEmployeeGeoLocation =
  (data: ILocation): ActionResult<IAppState, Promise<void>, updateEmployeeGeoLocationActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateEmployeeGeoLocationActionTypes.REQUEST));
      await BusinessPortalEmployeeService.updateEmployeeGeoLocation(data);
      dispatch(action(updateEmployeeGeoLocationActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateEmployeeGeoLocationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateEmployeeEmail =
  (email: string): ActionResult<IAppState, Promise<void>, updateEmployeeEmailActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateEmployeeEmailActionTypes.REQUEST));
      await BusinessPortalEmployeeService.updateEmployeeEmail(email);
      dispatch(action(updateEmployeeEmailActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateEmployeeEmailActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateEmployeePassword =
  (data: IServerEmployeePasswordUpdate): ActionResult<IAppState, Promise<void>, updateEmployeePasswordActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateEmployeePasswordActionTypes.REQUEST));
      await BusinessPortalEmployeeService.updateEmployeePassowrd(data);
      dispatch(action(updateEmployeePasswordActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateEmployeePasswordActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
