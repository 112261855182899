import {IModule} from 'redux-dynamic-modules';

import {IFacetFilterState} from './types';
import {reducer} from './reducer';

export interface IFacetFilterModuleState {
  facetFilter: IFacetFilterState;
}

export const FacetFilterModule: IModule<IFacetFilterModuleState> = {
  id: 'facetFilter',
  reducerMap: {
    facetFilter: reducer,
  },
};
