import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.businessPortalOrderPayment;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectOrderPaymentToken(state: IAppState) {
  return selectState(state).data.orderPaymentToken;
}

export function selectPaymentHorses(state: IAppState) {
  return selectState(state).data.paymentHorses;
}

export function selectPaymentSummary(state: IAppState) {
  return selectState(state).data.paymentSummary;
}
