import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import UserDataService from 'Admin/AdminDashboard/services/UserDataService';
import {getUserActions, getUserActionTypes, updateUserActions, updateUserActionTypes} from './types';
import {IAppState} from 'Common/store/IAppState';
import {IUserUpdateRequest} from 'Admin/AdminDashboard/services/types/user';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {resetUserDetailsActions, resetUserDetailsActionTypes} from 'Admin/AdminDashboard/store/adminUsers/users';

export const getUserFullInfo =
  (userId: number): ActionResult<IAppState, void, getUserActions> =>
  async (dispatch) => {
    dispatch(action(getUserActionTypes.REQUEST));
    try {
      const user = await UserDataService.getUserFullInfo(userId);
      dispatch(action(getUserActionTypes.SUCCESS, user));
    } catch (error) {
      dispatch(action(getUserActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateUser =
  (user?: IUserUpdateRequest): ActionResult<IAppState, void, updateUserActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateUserActionTypes.REQUEST));
      await UserDataService.updateUserFullInfo(user);
      dispatch(action(updateUserActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateUserActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetUser = (): ActionResult<IAppState, void, resetUserDetailsActions> => (dispatch) => {
  dispatch(action(resetUserDetailsActionTypes.RESET));
};
