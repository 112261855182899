import React, {memo, ReactNode} from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import ColorPalette from 'Common/constants/ColorPalette';
import BreakableSection from 'Common/components/BreakableSection/BreakableSection';
import {breakpoints} from 'Common/constants/Breakpoints';
import Typography from 'Common/constants/Typography';
import HeaderIcon from '../img/etalon-icon.svg';
import useSectionContainer from './useSectionContainer';

const Wrapper = styled.div`
  background: ${Theme.color.white};
  padding: 32px 0;
  z-index: 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Divider = styled.div`
  margin-top: -2px;
  height: 3px;
  background: radial-gradient(50% 550% at 50% 50%, #f4f5f7 100%, rgba(244, 245, 247, 0) 100%);
`;

const Header = styled.h2`
  padding: 12px 0;
  border-bottom: 2px solid ${Theme.color.primary};
  font-size: ${Typography.size.size24};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size32};
  }
`;

const IconLogo = styled.div`
  width: 48px;
  height: 24px;
  background-image: url(${HeaderIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 16px;
`;

const IdRow = styled.div<{isPrintMode?: boolean}>`
  display: ${({isPrintMode}) => (isPrintMode ? 'flex' : 'none')};
  padding-bottom: 16px;
`;

const IdText = styled.span`
  color: ${ColorPalette.gray58};
`;

interface IProps {
  header: string;
  children?: ReactNode;
}

const SectionContainer = (props: IProps) => {
  const {header, children} = props;

  const {staticData, isPrintable} = useSectionContainer();

  const haveStaticData = staticData?.sampleId && staticData?.horseName && staticData?.ownerName;

  return (
    <BreakableSection>
      <Wrapper className="d-flex w-100 flex-column">
        {haveStaticData && (
          <IdRow className="w-100 justify-content-between" isPrintMode={isPrintable}>
            <IdText>
              {staticData.ownerName} • {staticData.horseName}
            </IdText>
            <IdText>Horse ID: {staticData.sampleId}</IdText>
          </IdRow>
        )}
        <div className="w-100">
          <Header className="d-inline-flex align-items-center m-0">
            <IconLogo />
            {header}
          </Header>
          {isPrintable && <Divider />}
        </div>
        {children}
      </Wrapper>
    </BreakableSection>
  );
};

export default memo(SectionContainer);
