import {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {Title} from '../parts/styled';
import {IFacetProps} from '../Facet';
import {OperationDropDown} from '../parts/OperationDropDown';

import {Input} from 'Common/components/Controls';
import {IFilterRequest} from 'FacetFilter/models/IFilterRequest';

const inputStyle = {height: 28};

const FilterInput = styled(Input)`
  width: 120px;
  margin-bottom: 0;
`;

interface IProps extends IFacetProps {
  inputType?: string;
}

function StringFacet(props: IProps) {
  const {
    filterProperty,
    filterPropertyOperations,
    filterRequest = {
      property: filterProperty.property,
      operation: filterPropertyOperations?.operations[0].operation || '',
      value: '',
    },
    onSuccess,
    inputType,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [request, setRequest] = useState<IFilterRequest>(filterRequest);

  useEffect(() => {
    setRequest(filterRequest);
    setInputValue(filterRequest?.value || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (request.value) {
      onSuccess(request);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const onInputChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;
      setInputValue(value);
      setRequest({...request, value});
    },
    [request]
  );

  const onOperationSelect = useCallback(
    (operation: string) => {
      setRequest({...request, operation});
    },
    [request]
  );

  return (
    <>
      <Title>{filterProperty.title}</Title>
      {filterPropertyOperations && (
        <OperationDropDown
          filterOperations={filterPropertyOperations}
          onSelect={onOperationSelect}
          defaultOperation={filterRequest.operation}
        />
      )}
      <FilterInput
        value={inputValue}
        placeholder="Type..."
        autoComplete="off"
        onChange={onInputChange}
        inputStyle={inputStyle}
        autoFocus
        type={inputType || 'text'}
      />
    </>
  );
}

export default memo(StringFacet);
