import React, {useCallback, useState} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import HorseFormEdit from 'Admin/AdminAssociations/components/Horses/HorseForm/HorseFormEdit';
import AssociationHorseFormCreate from 'Shared/components/Association/AssociationHorseFormCreate';
import ChangeSampleForm from 'Admin/shared/components/ChangeSampleForm/ChangeSampleForm';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import {IHorseSample} from 'Admin/AdminAssociations/models/Order/IHorseSample';

interface IUseModalParams {
  sample?: IHorseSample;
  onSuccess?(): void;
}

export function useAdminHorseModal(params: IUseModalParams) {
  const {sample, onSuccess} = params;

  const [horseId, setHorseId] = useState<number>();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditSampleModalOpen, setIsEditSampleModalOpen] = useState(false);

  const openEditSampleModal = useCallback(() => setIsEditSampleModalOpen(true), []);
  const closeEditSampleModal = useCallback(() => setIsEditSampleModalOpen(false), []);

  const openUpdateAdminHorseModal = useCallback(
    (horseId?: number) => {
      setIsUpdateModalOpen(true);
      setHorseId(horseId);
    },
    [setIsUpdateModalOpen]
  );

  const openCreateAdminHorseModal = useCallback(() => {
    setIsCreateModalOpen(true);
  }, []);

  const closeModals = useCallback(() => {
    setIsUpdateModalOpen(false);
    setIsCreateModalOpen(false);
  }, []);

  const onSuccessHandler = useCallback(() => {
    closeModals();
    onSuccess && onSuccess();
  }, [onSuccess, closeModals]);

  const updateAdminHorseModal = (
    <ModalWindow isOpen={isUpdateModalOpen} onClose={closeModals}>
      <HorseFormEdit onSuccess={onSuccessHandler} horseId={horseId!} />
    </ModalWindow>
  );

  const createAdminHorseModal = (
    <ModalWindow isOpen={isCreateModalOpen} onClose={closeModals}>
      <AssociationHorseFormCreate onSuccess={onSuccessHandler} isAdmin={true} />
    </ModalWindow>
  );

  const changeSampleFormModal = (
    <ModalWindow isOpen={isEditSampleModalOpen} onClose={closeEditSampleModal}>
      {sample && (
        <ChangeSampleForm
          adminVisitorType={SelectedStrategy.AdminAssociation}
          sample={{...sample, receivedDate: ''}}
          onSuccess={closeEditSampleModal}
        />
      )}
    </ModalWindow>
  );

  return {
    openUpdateAdminHorseModal,
    openCreateAdminHorseModal,
    openEditSampleModal,
    updateAdminHorseModal,
    createAdminHorseModal,
    changeSampleFormModal,
    horseId,
  };
}
