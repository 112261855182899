import {IAdminOrganization} from 'Admin/AdminAssociations/models/IAdminOrganization';
import {EntityStatus} from 'Common/constants/EntityStatus';
import {IServerOrganization, OrganizationStatus} from '../types/organizations';

export function convertOrganizationToClient(values: IServerOrganization): IAdminOrganization {
  return {
    ...values,
    isActive: values.status === OrganizationStatus.Activated,
  };
}

export function convertOrganizationStatusToServer(isActive: boolean): OrganizationStatus {
  return isActive ? OrganizationStatus.Activated : OrganizationStatus.Disabled;
}

export function convertStatus(isEnabled: boolean, isArchived: boolean = false) {
  return isArchived ? EntityStatus.Archived : isEnabled ? EntityStatus.Active : EntityStatus.Disabled;
}
