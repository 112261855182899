import React, {memo, useMemo} from 'react';
import styled from 'styled-components';

import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {BorderedBlock, Label} from 'HorseProfile/components/shared/StyledComponents';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import BreedPanelList from 'BreedPanel/components/BreedPanelList';
import ColorPalette from 'Common/constants/ColorPalette';

const Root = styled.div`
  margin-top: 16px;
`;

const BorderWrapper = styled(BorderedBlock)`
  background-color: ${ColorPalette.white0};
  padding: 16px;
`;

const LabelBlack = styled(Label)`
  color: ${Theme.color.black};
`;

const GensWrapper = styled.div`
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size16};
  color: ${Theme.color.black};
  font-weight: ${Typography.weight.medium500};
  line-height: 20px;
  letter-spacing: -0.2px;
`;

const PanelLabel = styled(Label)`
  margin-bottom: 8px;
`;

interface IProps {
  horseBreedPanel: IHorseBreedPanel;
}

function BreedPanel(props: IProps) {
  const {horseBreedPanel} = props;

  const accordingTo = useMemo(() => {
    if (horseBreedPanel.breeds.length === 1) {
      return (
        <div className="d-flex align-items-center">
          <Label>According to breed&nbsp;</Label>
          <LabelBlack>{horseBreedPanel.breeds[0].name}</LabelBlack>
        </div>
      );
    }
    return <Label>According to the horse breeds</Label>;
  }, [horseBreedPanel.breeds]);

  return (
    <Root>
      <div className="d-flex justify-content-between align-items-center">
        <Tooltip content={horseBreedPanel.description}>
          <PanelLabel>{horseBreedPanel.name}</PanelLabel>
        </Tooltip>
        {accordingTo}
      </div>
      <BorderWrapper>
        <GensWrapper className="d-flex flex-grow-1 flex-wrap">
          <BreedPanelList panelTraits={horseBreedPanel.traits} />
        </GensWrapper>
      </BorderWrapper>
    </Root>
  );
}

export default memo(BreedPanel);
