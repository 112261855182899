export function calculate(r: number, g: number, b: number, angle: number) {
  // Hold your breath because what follows isn't flowers.

  const matrix = [
    // Just remember this is the identity matrix for
    1,
    0,
    0, // Reds
    0,
    1,
    0, // Greens
    0,
    0,
    1, // Blues
  ];

  // Luminance coefficients.
  const lumR = 0.2126;
  const lumG = 0.7152;
  const lumB = 0.0722;

  // Hue rotate coefficients.
  const hueRotateR = 0.143;
  const hueRotateG = 0.14;
  const hueRotateB = 0.283;

  const cos = Math.cos((angle * Math.PI) / 180);
  const sin = Math.sin((angle * Math.PI) / 180);

  matrix[0] = lumR + (1 - lumR) * cos - lumR * sin;
  matrix[1] = lumG - lumG * cos - lumG * sin;
  matrix[2] = lumB - lumB * cos + (1 - lumB) * sin;

  matrix[3] = lumR - lumR * cos + hueRotateR * sin;
  matrix[4] = lumG + (1 - lumG) * cos + hueRotateG * sin;
  matrix[5] = lumB - lumB * cos - hueRotateB * sin;

  matrix[6] = lumR - lumR * cos - (1 - lumR) * sin;
  matrix[7] = lumG - lumG * cos + lumG * sin;
  matrix[8] = lumB + (1 - lumB) * cos + lumB * sin;

  function clamp(num: number) {
    return Math.round(Math.max(0, Math.min(255, num)));
  }

  const R = clamp(matrix[0] * r + matrix[1] * g + matrix[2] * b);
  const G = clamp(matrix[3] * r + matrix[4] * g + matrix[5] * b);
  const B = clamp(matrix[6] * r + matrix[7] * g + matrix[8] * b);

  return `rgb(${R}, ${G}, ${B})`;
}
