import React, {memo, PropsWithChildren} from 'react';
import styled from 'styled-components';

import Icon, {IconName} from 'Icon/components/Icon';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {VisitorType} from 'Common/constants/VisitorType';
import BusinessPortalLayout from 'BusinessPortal/components/common/BusinessPortalLayout/BusinessPortalLayout';
import ColorPalette from 'Common/constants/ColorPalette';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';

const Root = styled.div`
  height: 100%;
  padding: 16px;
`;

const Content = styled.div`
  max-width: 410px;
  margin: auto;
  text-align: center;
`;

const OrderSuccessLayout = ({children}: PropsWithChildren<any>) => {
  const {isVisitorType} = useVisitorTypeService();
  const isAssociation = isVisitorType(VisitorType.AssociationEmployee);

  const renderContent = (
    <Root className="d-flex">
      <Content className="d-flex flex-column align-items-center">
        <Icon name={IconName.FilledCheckbox} size={80} />
        {children}
      </Content>
    </Root>
  );

  return isAssociation ? (
    <BusinessPortalLayout backgroundColor={ColorPalette.white0} withoutPaddings={true}>
      {renderContent}
    </BusinessPortalLayout>
  ) : (
    <BaseLayout withoutPaddings={true} isBackButtonDenied={true}>
      {renderContent}
    </BaseLayout>
  );
};

export default memo(OrderSuccessLayout);
