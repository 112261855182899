import * as Yup from 'yup';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {ICreateHorseOwnerBP} from 'BusinessPortal/models/owner/IOwnerBP';
import {IUserOption} from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearch';
import {IImage} from 'Image/models/IImage';

export type ICreateOwnerFormValues = Omit<ICreateHorseOwnerBP, 'address' | 'phone'>;

export const createOwnerInitialValue: ICreateOwnerFormValues = {
  organizationId: 0,
  firstName: '',
  lastName: '',
  email: '',
};

export const createOwnerValidationSchema = Yup.object().shape<ICreateOwnerFormValues>({
  organizationId: Yup.number().required(),
  firstName: Yup.string().required(REQUIRED_FIELD),
  lastName: Yup.string().required(REQUIRED_FIELD),
  email: Yup.string().email('Email not valid').required('Email is required'),
});

export interface ISetOwnerFormValues extends Pick<IUserOption, 'firstName' | 'avatar'> {
  id?: number;
}

export const setOwnerInitialValue: ISetOwnerFormValues = {
  firstName: '',
  avatar: null,
};

export const setOwnerValidationSchema = Yup.object().shape<ISetOwnerFormValues>({
  id: Yup.number().required(REQUIRED_FIELD),
  firstName: Yup.string(),
  avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
});
