import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {bindActionCreators} from 'redux';

import {IAppState} from 'Common/store/IAppState';
import {actions as horseActions, HorseActions, selectors as horseSelectors} from 'Horse/store/horse';
import {
  actions as adminHorseActions,
  AdminHorsesActions,
  AdminHorsesModule,
  selectors as adminHorseSelectors,
} from 'Admin/AdminDashboard/store/adminHorses';
import {Nullable} from 'Common/types';
import {FormType} from 'Common/constants/FormType';
import HorseProfileFormInner from 'HorseProfile/components/HorseProfileForm/HorseProfileFormInner';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {GalleryModule} from 'Gallery/store/galleryModule';
import {HorseModule} from 'Horse/store/horse/horseModule';

import {IHorseEditFormFields} from './HorseProfileFormInnerValidation';
import withDynamicModules from 'Common/helpers/withDynamicModules';

interface IExternalProps {
  type: FormType;
  isAdmin: boolean;
  horseId: Nullable<number>;
  ownerId?: number;
  externalError?: string;
  resetHorseAfterCloseModal?: boolean;
  onSuccess?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalProps;

const HorseProfileForm = (props: AllProps) => {
  const {
    horseId,
    type,
    resetHorseAfterCloseModal,
    horseLoading,
    horseCreating,
    horseUpdating,
    getHorse,
    updateHorse,
    createHorse,
    resetHorse,
    horse,
    externalError,
    ...rest
  } = props;

  const onSubmit = useCallback(
    async (fields: IHorseEditFormFields) => {
      if (type === FormType.create) {
        return await createHorse({fields});
      }
      if (type === FormType.edit && horseId) {
        await updateHorse({fields, horseId});
        return horseId;
      }
      throw Error('Empty horse at form submit');
    },
    [type, horseId, createHorse, updateHorse]
  );

  useEffect(() => {
    !horse && horseId && getHorse(horseId);
  }, [horse, horseId, getHorse]);

  useEffect(() => {
    if (resetHorseAfterCloseModal) {
      return () => resetHorse();
    }
    return;
  }, [resetHorseAfterCloseModal, resetHorse]);

  const horseError = getCommonErrors(horseLoading.error) || externalError;
  const horseRequesting = [horseLoading].some((item) => item.isRequesting);

  return (
    <HorseProfileFormInner
      horse={horse}
      onSubmit={onSubmit}
      isLoading={horseRequesting}
      externalError={horseError}
      type={type}
      {...rest}
    />
  );
};

const adminMapStateToProps = (state: IAppState) => ({
  horse: adminHorseSelectors.selectHorse(state),
  horseLoading: adminHorseSelectors.selectCommunication(state, 'horseLoading'),
  horseCreating: adminHorseSelectors.selectCommunication(state, 'horseCreating'),
  horseUpdating: adminHorseSelectors.selectCommunication(state, 'horseUpdating'),
});

const userMapStateToProp = (state: IAppState) => ({
  horse: horseSelectors.selectHorse(state),
  horseLoading: horseSelectors.selectCommunication(state, 'horseLoading'),
  horseCreating: horseSelectors.selectCommunication(state, 'horseCreating'),
  horseUpdating: horseSelectors.selectCommunication(state, 'horseUpdating'),
});

const mapStateToProps = (state: IAppState, externalProps: IExternalProps) =>
  externalProps.isAdmin ? adminMapStateToProps : userMapStateToProp;

const adminMapDispatchToProps = {
  getHorse: adminHorseActions.getHorse,
  createHorse: adminHorseActions.createHorse,
  updateHorse: adminHorseActions.updateHorse,
  resetHorse: adminHorseActions.resetHorseResults,
};

const userMapDispatchToProps = {
  getHorse: horseActions.getHorse,
  createHorse: horseActions.createHorse,
  updateHorse: horseActions.updateHorse,
  resetHorse: horseActions.resetHorseResults,
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, undefined, AdminHorsesActions | HorseActions>,
  externalProps: IExternalProps
) =>
  externalProps.isAdmin
    ? bindActionCreators(adminMapDispatchToProps, dispatch)
    : bindActionCreators(userMapDispatchToProps, dispatch);

HorseProfileForm.defaultProps = {
  horseId: null,
  resetHorseAfterCloseModal: true,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(HorseProfileForm));
export default withDynamicModules(Connected, [GalleryModule, HorseModule, AdminHorsesModule]);
