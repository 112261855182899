import React, {useState, useCallback} from 'react';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import UserPermissionsUpdate from 'Admin/shared/components/UserPermissionsUpdate/UserPermissionsUpdate';

interface IProps {
  onSuccess(): void;
}

export function useUpdateUserPermissionsModal(props: IProps) {
  const {onSuccess} = props;

  const [selectedId, setSelectedId] = useState<number>();
  const [selectedUserName, setSelectedUserName] = useState('');

  const [isUpdateUserPermissionsModalOpen, setIsUpdateUserPermissionsModalOpen] = useState(false);
  const openModalUserPermissionsModal = useCallback(
    (userId: number, userName: string) => {
      setSelectedId(userId);
      setSelectedUserName(userName);
      setIsUpdateUserPermissionsModalOpen(true);
    },
    [setSelectedId]
  );
  const closeModalUserPermissionsModal = useCallback(() => setIsUpdateUserPermissionsModalOpen(false), []);
  const onSuccessUpdateUserPermissions = useCallback(() => {
    closeModalUserPermissionsModal();
    onSuccess();
  }, [closeModalUserPermissionsModal, onSuccess]);

  const updateUserPermissionsModal = (
    <ModalWindow isOpen={isUpdateUserPermissionsModalOpen && !!selectedId} onClose={closeModalUserPermissionsModal}>
      <UserPermissionsUpdate
        userId={selectedId!}
        userName={selectedUserName}
        onSuccess={onSuccessUpdateUserPermissions}
      />
    </ModalWindow>
  );

  return {
    openModalUserPermissionsModal,
    updateUserPermissionsModal,
  };
}
