import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {findHorseActionTypes, Actions} from './types';
import {IPaginationRequest} from 'Common/models/IPaginationRequest';
import {IFindHorseRequest} from 'FindHorse/models/FindHorseRequest';
import FindHorseDataService from 'FindHorse/services/FindHorseDataService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IAppState} from 'Common/store/IAppState';

export const findHorse = (
  request: IFindHorseRequest,
  pagination: IPaginationRequest
): ActionResult<IAppState, void, Actions> => async dispatch => {
  try {
    dispatch(action(findHorseActionTypes.REQUEST));
    const response = await FindHorseDataService.findHorse(request, pagination);
    dispatch(action(findHorseActionTypes.SUCCESS, response));
  } catch (error) {
    dispatch(action(findHorseActionTypes.FAILURE, getErrorMessage(error)));
  }
};
