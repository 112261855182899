import React, {useCallback, useEffect, useState} from 'react';

import SlidePanel from 'Common/components/SlidePanel/SlidePanel';
import {ElementPosition} from 'FoalProfile/models/IElementPosition';
import {FormType} from 'Common/constants/FormType';
import OwnerInfoSectionEdit from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/parts/OwnerGeneralInfoSection/OwnerGeneralInfoSectionEdit';
import OwnerFormEdit from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/OwnerFormEdit';

const formHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Create an Owner',
  edit: 'Owner details'
};

interface IProps {
  title?: JSX.Element;
  onSuccessForm?(): void;
  shadow?: boolean;
  transition?: boolean;
  closableTitle?: boolean;
}

export function useOwnerFormSlidePanel(props: IProps) {
  const {title, closableTitle, shadow, transition, onSuccessForm} = props;

  const [isOpenSlidePanel, setIsOpenSlidePanel] = useState(false);
  const [formHasChanges, setFormHasChanges] = useState<boolean>(false);

  const [ownerId, setOwnerId] = useState<number>();

  const [formType, setFormType] = useState<FormType>(FormType.edit);

  useEffect(() => {
    const formType = ownerId ? FormType.edit : FormType.create;
    setFormType(formType);
  }, [ownerId]);

  const openOwnerFormSlidePanel = useCallback((ownerId?: number) => {
    setIsOpenSlidePanel(true);
    setOwnerId(ownerId);
  }, []);

  const closeOwnerFormSlidePanel = useCallback(async () => {
    setIsOpenSlidePanel(false);
    ownerId && formHasChanges && onSuccessForm && (await onSuccessForm());
    setFormHasChanges(false);
  }, [formHasChanges, onSuccessForm, ownerId]);

  const onSuccessEditFormHandler = useCallback(async () => {
    setFormHasChanges(true);
  }, []);

  const onSuccessCreateFormHandler = useCallback(async () => {
    await closeOwnerFormSlidePanel();
    onSuccessForm && (await onSuccessForm());
  }, [closeOwnerFormSlidePanel, onSuccessForm]);

  const header = formHeaderByType[formType];

  const ownerFormSlidePanel = (
    <SlidePanel
      title={title || header}
      isOpen={isOpenSlidePanel}
      closableTitle={closableTitle}
      position={ElementPosition.Right}
      onClose={closeOwnerFormSlidePanel}
      shadow={shadow}
      transition={transition}
    >
      {!ownerId && <OwnerInfoSectionEdit data={null} onSuccess={onSuccessCreateFormHandler} />}
      {ownerId && <OwnerFormEdit ownerId={ownerId} onSuccess={onSuccessEditFormHandler} />}
    </SlidePanel>
  );

  return {openOwnerFormSlidePanel, ownerFormSlidePanel, closeOwnerFormSlidePanel, ownerId};
}
