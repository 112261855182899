import React, {memo} from 'react';

import {SectionLabel, SectionHint} from './shared';
import {ExpansionPanel, Section} from 'Common/components/ExpansionPanel/ExpansionPanel';
import {SelectField} from 'Common/components/FormFields';
import {castToOption} from 'Common/helpers/OptionHelper';
import {IColor} from 'Dictionaries/models/IColor';
import {IColorModifier} from 'Dictionaries/models/IColorModifier';
import SelectedValues from 'Filters/components/shared/SelectedValues/SelectedValues';

interface IProps {
  colors: IColor[];
  modifiers: IColorModifier[];
  selectedCount: number;
}

function ParentFeatures(props: IProps) {
  const {colors, modifiers, selectedCount} = props;
  return (
    <ExpansionPanel title="Colors" headerContent={<SectionHint count={selectedCount} />}>
      <Section>
        <SectionLabel>Color</SectionLabel>
        <SelectField name="color" options={castToOption(colors)} blurInputOnSelect={true} isClearable={true} />
      </Section>
      <Section>
        <SectionLabel>Modifier</SectionLabel>
        <SelectField
          isMulti={true}
          name="colorModifiers"
          options={castToOption(modifiers)}
          controlShouldRenderValue={false}
          blurInputOnSelect={true}
          isClearable={false}
        />
        <SelectedValues name="colorModifiers" options={castToOption(modifiers)} />
      </Section>
    </ExpansionPanel>
  );
}

export default memo(ParentFeatures);
