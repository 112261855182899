import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';

import {IOrderHorse} from 'Order/models/IOrderHorse';
import {ITestSummary} from 'Order/models/ITestSummary';
import {IOrderCoupon} from 'Order/models/IOrderCoupon';
import {ICreatedOrder} from 'Order/models/ICreatedOrder';
import {Nullable} from 'Common/types';
import {IContactInformation} from 'Order/models/IContactInformation';
import {IOrderPackage} from 'Order/models/IOrderPackage';

const STATE_NAME = 'createOrder';

export interface ICreateOrderState {
  data: {
    horses: IOrderHorse[];
    summary: Nullable<ITestSummary>;
    coupon: Nullable<IOrderCoupon>;
    createdOrder: Nullable<ICreatedOrder>;
    contactInformation: Nullable<IContactInformation>;
    orderPackages: IOrderPackage[];
  };
  communications: {
    horsesLoading: ICommunication;
    summaryLoading: ICommunication;
    couponLoading: ICommunication;
    creatingOrder: ICommunication;
    contactInformationLoading: ICommunication;
    contactInformationUpdating: ICommunication;
    orderPackagesLoading: ICommunication;
  };
}

export const getHorsesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSES');
export type getHorsesActions = MakeCommunicationActions<typeof getHorsesActionTypes, {success: IOrderHorse[]}>;

export const getSummaryActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_SUMMARY');
export type getSummaryActions = MakeCommunicationActions<typeof getSummaryActionTypes, {success: ITestSummary}>;

export const getCouponActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_COUPON');
export type getCouponActions = MakeCommunicationActions<typeof getCouponActionTypes, {success: IOrderCoupon}>;

export const createOrderActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_ORDER');
export type createOrderActions = MakeCommunicationActions<typeof createOrderActionTypes, {success: ICreatedOrder}>;

export const getContactInformationActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_CONTACT_INFORMATION');
export type getContactInformationActions = MakeCommunicationActions<
  typeof getContactInformationActionTypes,
  {success: IContactInformation}
>;

export const getOrderPackagesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_PACKAGES');
export type getOrderPackagesActions = MakeCommunicationActions<
  typeof getOrderPackagesActionTypes,
  {success: IOrderPackage[]}
>;

export const updateContactInformationActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_CONTACT_INFORMATION'
);
export type updateContactInformationActions = MakeCommunicationActions<typeof updateContactInformationActionTypes, {}>;

export const resetCouponActionTypes = makeResetCommunicationActionType(STATE_NAME, 'REMOVE_COUPON');
export type resetCouponActions = MakeResetCommunicationActions<typeof resetCouponActionTypes>;

export const resetSummaryActionTypes = makeResetCommunicationActionType(STATE_NAME, 'REMOVE_SUMMARY');
export type resetSummaryActions = MakeResetCommunicationActions<typeof resetSummaryActionTypes>;

export type Actions =
  | getHorsesActions
  | getSummaryActions
  | getCouponActions
  | createOrderActions
  | getContactInformationActions
  | updateContactInformationActions
  | resetCouponActions
  | resetSummaryActions
  | getOrderPackagesActions;
