import {IModule} from 'redux-dynamic-modules';

import {userPrivacySettingsReducer} from './reducer';
import {IUserPrivacySettingsState} from './types';

export interface IUserPrivacySettingsModuleState {
  userPrivacySettings: IUserPrivacySettingsState;
}

export const UserPrivacySettingsModule: IModule<IUserPrivacySettingsModuleState> = {
  id: 'userPrivacySettings',
  reducerMap: {
    userPrivacySettings: userPrivacySettingsReducer,
  },
};
