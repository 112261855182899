import {IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';

const uploadTestResults = async (uploadedFile: FormData): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/TestResultsAdmin/Upload`, uploadedFile);
};

export default {
  uploadTestResults,
};
