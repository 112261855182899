import {parseOrderStatus} from 'Order/helpers/parseOrderStatus';
import {IServerUserOrder} from '../serverModels/IUserOrderRequest';
import {IUserOrder} from 'UserProfile/models/IUserOrder';
import {convertPriceToClient} from 'Common/helpers/convertPrices';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {withAvatar} from 'Common/helpers/withAvatar';

export function convertServerUserOrder(order: IServerUserOrder): IUserOrder {
  return {
    ...withAvatar(withBirthDate(order)),
    order: {
      ...parseOrderStatus(order.order),
      id: order.order.id,
      createDate: convertUTCToClientDate(order.order.createDate) || '',
      amount: convertPriceToClient(order.order.amount),
      packages: order.order.packages,
      reports: order.order.reports,
      token: order.order.token,
    },
  };
}
