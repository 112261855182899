import {combineReducers, Reducer} from 'redux';
import {IAdminPermissionsState} from '../types';

import {communicationAdminPermissionsReducer} from './communications';
import {dataAdminPermissionsReducer} from './data';

export const adminPermissionsReducer: Reducer<IAdminPermissionsState> = combineReducers<IAdminPermissionsState>({
  communications: communicationAdminPermissionsReducer,
  data: dataAdminPermissionsReducer
});
