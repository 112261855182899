import React, {memo, useCallback} from 'react';

import Switch, {SwitchProps} from 'Common/components/Controls/Switch';

interface IProps {
  itemId?: number;
  onChangeHandler(value: boolean, itemId?: number): void;
}

type AllProps = IProps & Omit<SwitchProps, 'onChange'>;

export const SwitchButton = memo((props: AllProps) => {
  const {itemId, onChangeHandler, ...rest} = props;

  const onChange = useCallback((value: boolean) => onChangeHandler(value, itemId), [itemId, onChangeHandler]);

  return <Switch {...rest} onChange={onChange} />;
});
