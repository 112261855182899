import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {IAppState} from 'Common/store/IAppState';
import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import {AdminUsersModule} from 'Admin/AdminDashboard/store/adminUsers/users/adminUsersModule';
import {Pagination} from 'Common/components/Controls';
import Loading from 'Loading/components/Loading';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {actions, selectors} from 'Admin/AdminAdvancedDashboard/store/adminAdministrators/administrators';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import withUserActions, {
  getWithUserActionsModules,
  IWithUserActionsProps,
} from 'Admin/AdminDashboard/helpers/withUserActions';
import {getWithHorseActionsModules} from 'Admin/AdminDashboard/helpers/withHorseActions';
import {AvatarCell} from 'Admin/AdminDashboard/components/shared';
import {IAdministrator} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdministrator';
import Actions from 'Common/components/Actions/Actions';
import {AdministratorsModule} from 'Admin/AdminAdvancedDashboard/store/adminAdministrators/administrators/administratorsModule';
import {useAdministratorActions} from 'Admin/AdminAdvancedDashboard/helpers/hooks/useAdministratorActions/useAdministratorActions';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import usePermissions from 'Permissions/hooks/usePermissions';
import {VisitorType} from 'Common/constants/VisitorType';
import {SwitchButton} from 'Common/components/Controls/Buttons/SwitchButton';
import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import {IMenuItemValued} from 'Common/models/IMenuItemValued';

const iconProps = {color: ColorPalette.red7};

enum AdministratorActionValue {
  UpdateAdministrator = 'updateAdministrator',
  UpdateAdministratorNotifications = 'updateAdministratorNotifications',
  UpdateAdministratorPermissions = 'updateAdministratorPermissions',
  UpdateAdministratorPassword = 'updateAdministratorPassword',
}

const menuItems: IMenuItemValued<AdministratorActionValue>[] = [
  {
    value: AdministratorActionValue.UpdateAdministrator,
    label: 'Edit administrator',
    icon: {name: IconName.Edit, ...iconProps},
  },
  {
    value: AdministratorActionValue.UpdateAdministratorNotifications,
    label: 'Notifications',
    icon: {name: IconName.Notification, ...iconProps},
  },
  {
    value: AdministratorActionValue.UpdateAdministratorPermissions,
    label: 'Permissions',
    icon: {name: IconName.Permission, ...iconProps},
  },
  {
    value: AdministratorActionValue.UpdateAdministratorPassword,
    label: 'Change password',
    icon: {name: IconName.Lock, ...iconProps},
  },
];

const AddButton = styled(PrimaryButton)`
  height: 50px;
  margin-left: 55px;
`;

const AdministratorsTable = styled.div`
  margin-top: 50px;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IWithUserActionsProps;

const Administrators = (props: AllProps) => {
  const {
    administrators,
    administratorsLoading,
    changeAdministratorStatus,
    administratorStatusUpdating,
    getAdministrators,
    pagination,
    userUpdating,
    userDeleting,
  } = props;

  const {isVisitorType} = usePermissions();

  const {params, changeSorting, sorting, handlePageSelect, handleCountPerPage, deleteModal, searchBar} =
    useCommonAdminPageData<IAdministrator>({
      getItems: getAdministrators,
      searchBarPlaceholder: 'Search for administrators by any keyword',
    });

  const reloadAdministrators = useCallback(() => {
    getAdministrators(params);
  }, [getAdministrators, params]);

  const {
    userActionsModal,
    openUpdateAdministratorModal,
    openCreateAdministratorModal,
    openUpdateUserNotificationModal,
    openUpdateAdministratorPermissionsModal,
    openUpdateAdministratorPasswordModal,
  } = useAdministratorActions({
    onSuccess: reloadAdministrators,
    data: {
      updateUserInfoHandler: {communication: userUpdating},
    },
  });

  const changeStatus = useCallback(
    (value: boolean, adminId: number) => {
      changeAdministratorStatus(adminId, value);
    },
    [changeAdministratorStatus]
  );

  const formBlockers =
    administratorsLoading.isRequesting || userDeleting.isRequesting || administratorStatusUpdating.isRequesting;

  const isShowPagination = administrators.length > 0;

  const isAdminVisitor = isVisitorType(VisitorType.Admin);

  return (
    <AdminPageLayout>
      {deleteModal}
      {userActionsModal}
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
        {isAdminVisitor && <AddButton onClick={openCreateAdministratorModal}>+ Create administrator</AddButton>}
      </div>
      <AdministratorsTable className="position-relative">
        {formBlockers && <Loading />}
        <Table<IAdministrator> data={administrators} rowKey="id" sorting={sorting} onChangeSorting={changeSorting}>
          <Cell<IAdministrator>
            header="Active"
            dataKey="isActive"
            render={({id, isActive}) => <SwitchButton itemId={id} onChangeHandler={changeStatus} checked={isActive} />}
            width="10%"
          />
          <Cell<IAdministrator> header="ID" dataKey="id" render={({id}) => `#${id}`} expandOnClick={true} width="10%" />
          <Cell<IAdministrator>
            header="User"
            dataKey="name"
            render={({id, name, avatar}) => (
              <AvatarCell
                type="owner"
                avatarUrl={avatar?.url}
                label={name}
                onLabelClick={() => openUpdateAdministratorModal(id)}
              />
            )}
            width="20%"
          />
          <Cell<IAdministrator> header="Email" dataKey="email" render={({email}) => email} width="20%" />
          <Cell<IAdministrator> header="Phone" dataKey="phone" render={({phone}) => phone} width="20%" />
          <Cell<IAdministrator>
            header="Notifications"
            dataKey="notifications"
            render={({notifications}) =>
              notifications &&
              notifications
                .map((x) => x.name)
                .filter(Boolean)
                .join(',')
            }
            width="20%"
          />
          <Cell<IAdministrator>
            header="Actions"
            align={CellAlign.Right}
            width="10%"
            render={(administrator) => (
              <Actions<AdministratorActionValue>
                menuItems={menuItems}
                updateAdministrator={() => openUpdateAdministratorModal(administrator.id)}
                updateAdministratorNotifications={() => openUpdateUserNotificationModal(administrator.id)}
                updateAdministratorPermissions={() => openUpdateAdministratorPermissionsModal(administrator)}
                updateAdministratorPassword={() => openUpdateAdministratorPasswordModal(administrator)}
              />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </AdministratorsTable>
    </AdminPageLayout>
  );
};

const mapStateToProps = (state: IAppState) => ({
  administrators: selectors.selectAdministrators(state),
  administratorsLoading: selectors.selectCommunication(state, 'administratorsLoading'),
  administratorStatusUpdating: selectors.selectCommunication(state, 'administratorStatusUpdating'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getAdministrators: actions.getAdministrators,
  changeAdministratorStatus: actions.changeAdministratorStatus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(withUserActions(Administrators));

export default withDynamicModules(Connected, [
  AdminUsersModule,
  AdministratorsModule,
  getWithUserActionsModules(),
  getWithHorseActionsModules(),
]);
