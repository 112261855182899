import {IAbbreviationEntity} from 'Common/models/IAbbreviationEntity';
import {IStringDescription} from 'Common/models/IDescriptionEntity';
import {INamedEntity} from 'Common/models/INamedEntity';
import {Nullable} from 'Common/types';

export enum OrganizationStatus {
  Disabled = 'Disabled',
  Activated = 'Activated',
}

export interface IServerOrganization extends INamedEntity, IStringDescription, IAbbreviationEntity {
  country: Nullable<string>;
  websiteUrl: Nullable<string>;
  phoneNumber: Nullable<string>;
  address: Nullable<string>;
  email: string;
  status: OrganizationStatus;
}

export type ICreateAdminOrganization = Omit<IServerOrganization, 'id'>;

export type IUpdateAdminOrganization = Omit<IServerOrganization, 'status'>;
