import React, {memo, useCallback} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {IAppState} from 'Common/store/IAppState';
import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {initialValue, validationSchema} from './validation';
import {Field, FieldLabel, FieldValue} from 'Admin/common/styled/StyledComponents';
import PasswordField from 'Common/components/FormFields/PasswordField';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminEmployees';
import {IUpdateAdminEmployeePassword} from 'Admin/AdminAssociations/services/types/employee';
import {IAdminEmployee} from 'Admin/AdminAssociations/models/IAdminEmployee';

const Root = styled.div`
  margin: 0 24px 40px 0;
`;

interface IExternalProps {
  employee: IAdminEmployee;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;
type OuterProps = IExternalProps & IConnected;
type AllProps = FormikProps<IUpdateAdminEmployeePassword> & OuterProps;

function ChangePasswordForm(props: AllProps) {
  const {isSubmitting, setErrors, isValid} = props;
  const {employee, adminEmployeePasswordUpdating, onSuccess} = props;

  const errorInfo = adminEmployeePasswordUpdating.error;
  const isRequesting = adminEmployeePasswordUpdating.isRequesting;

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(adminEmployeePasswordUpdating, onSuccess);
  useOnErrorCommunication(adminEmployeePasswordUpdating, onError);
  useCommunicationToToast(adminEmployeePasswordUpdating, 'Employee password has been changed');

  return (
    <>
      <ModalWindowHeader>Change employee password</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <Root>
          <ModalWindowFormContent>
            {isRequesting && <Loading />}
            <div className="row">
              <Field className="col-4">
                <FieldLabel>Employee</FieldLabel>
                <FieldValue>{employee.name}</FieldValue>
              </Field>
              <Field className="col-4">
                <FieldLabel>Employee Email</FieldLabel>
                <FieldValue>{employee.email}</FieldValue>
              </Field>
            </div>

            <PasswordField name="newPassword" label="New password" placeholder="New password" isRequired={true} />
            <PasswordField
              name="confirmPassword"
              label="Repeat new password"
              placeholder="Repeat new password"
              isRequired={true}
            />
          </ModalWindowFormContent>
        </Root>
        <ModalWindowFooter>
          <ModalWindowButton type="submit" isLoading={isSubmitting} disabled={!isValid}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const ChangePasswordFormFormik = withFormik<OuterProps, IUpdateAdminEmployeePassword>({
  mapPropsToValues: ({employee}) => (employee ? {id: employee.id, newPassword: '', confirmPassword: ''} : initialValue),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    await formikBag.props.updateAdminEmployeePassword({
      id: formikBag.props.employee.id,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    });
  },
  enableReinitialize: true,
})(ChangePasswordForm);

const mapStateToProps = (state: IAppState) => ({
  adminEmployeePasswordUpdating: selectors.selectCommunication(state, 'adminEmployeePasswordUpdating'),
});

const mapDispatchToProps = {
  updateAdminEmployeePassword: actions.updateAdminEmployeePassword,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(memo(ChangePasswordFormFormik));
