import {IHorseTest} from 'Order/models/IHorseTest';
import {IHorseTestRequest} from 'Order/models/IHorseTestRequest';
import {IOrderHorse} from 'Order/models/IOrderHorse';
import {IHorsePayment} from 'Payment/models/IOrderSummary';

export function convertTestsToIHorseTestRequest(tests: IHorseTest[]): IHorseTestRequest[] {
  const uniqueHorses = tests
    .flatMap((x) => x.horses.map((y) => y))
    .filter((value, idx, self) => self.indexOf(value) === idx);

  const result: IHorseTestRequest[] = uniqueHorses.map((uniqHorse) => {
    const packages = tests.filter((x) => x.horses.some((h) => h === uniqHorse)).map((pack) => pack.packageId);
    return {horseId: uniqHorse, packages};
  });

  return result;
}

export function convertTestsToIHorsePayment(tests: IHorseTest[], horses: IOrderHorse[]): IHorsePayment[] {
  const uniqueHorses = tests
    .flatMap((x) => x.horses.map((y) => y))
    .filter((value, idx, self) => self.indexOf(value) === idx);

  const result: IHorsePayment[] = uniqueHorses.map((uniqHorse) => {
    const packages = tests.filter((x) => x.horses.some((h) => h === uniqHorse)).map((pack) => pack.packageId);
    const horseName = horses.find((x) => x.id === uniqHorse)?.name || '';
    return {name: horseName, packages};
  });

  return result;
}
