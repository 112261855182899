import {combineReducers} from 'redux';

import {ImageActions, uploadImageActionTypes, IImageState, getImageActionTypes, deleteImageActionTypes} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

export const communicationImageReducer = combineReducers<IImageState['communications'], ImageActions>({
  imageLoading: makeCommunicationReducerFromEnum(getImageActionTypes),
  imageUploading: composeReducers([
    makeCommunicationReducerFromEnum(uploadImageActionTypes),
    makeCommunicationReducerFromEnum(deleteImageActionTypes)
  ])
});
