import {ISubscriptionAdmin} from 'Admin/AdminDashboard/models/ISubscriptionAdmin';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {IServerSubscriptionAdmin} from 'Admin/AdminDashboard/services/models/subscription';
import {withAvatar} from 'Common/helpers/withAvatar';
import {withUserName} from 'Common/helpers/withUserName';

export function convertServerSubscriptionToClient(subscription: IServerSubscriptionAdmin): ISubscriptionAdmin {
  return {
    ...subscription,
    startDate: convertUTCToClientDate(subscription.startDate) || '',
    currentPeriodEnd: convertUTCToClientDate(subscription.currentPeriodEnd) || '',
    user: {...withUserName(withAvatar(subscription.user))},
  };
}
