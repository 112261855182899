import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {actions, selectors} from 'Admin/AdminAssociations/store/adminOrders/index';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {ITableProps} from 'Common/components/Table/Table';
import Theme from 'Common/constants/Theme';
import {IAssociationOrderHorseAdmin} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderHorseDetails';
import {RecordDetails} from 'Admin/AdminDashboard/components/shared';
import {OwnerDetails, PackageDetails, PaymentDetails, ReportDetails, SampleDetails, StatusDetails} from './parts';
import withOrderDetailsActions, {
  IWithOrderDetailsActionsProps,
} from 'Admin/AdminAssociations/helpers/withOrderDetailsActions';
import {useOrderDetailsActions} from 'Admin/AdminAssociations/helpers/hooks/useOrderActions/useOrderDetailsActions';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';
import {IOrderStatused} from 'Common/models/IOrderStatused';

const PaymentDetailsWrapper = styled.div`
  margin-top: 28px;
`;

const Column = RecordDetails.Column;

const tableProps: Partial<ITableProps<IAssociationOrderHorseAdmin>> = {
  expandedRowStyles: {
    toggle: {backgroundColor: 'transparent', marginLeft: 68},
    toggleIcon: {color: Theme.color.black},
    expandedRow: {boxShadow: 'none'},
    content: {paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px'},
  },
};

interface IOwnProps {
  orderId: number;
  onChange(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IOwnProps & IWithOrderDetailsActionsProps;

function OrderDetails(props: Props) {
  const {
    orderDetails,
    getOrderDetails,
    resetOrderDetails,
    orderId,
    orderDetailsLoading,
    onChange,
    changeReviewStatus,
    changeReviewStatusRequesting,
    releaseOrderHorse,
    orderHorseReleaseRequesting,
    setSampleStatus,
    setSampleStatusRequesting,
    downloadFormRequesting,
    downloadSubmissionForm,
    getOrderRequiredTests,
    requiredTests,
    requiredTestsLoading,
  } = props;

  const [statuses, setStatuses] = useState<IOrderStatused>();

  const {listen} = useEventBus();

  const getDetails = useCallback(() => {
    getOrderDetails(orderId);
  }, [getOrderDetails, orderId]);

  const onSetStatuses = useCallback((data: IOrderStatused) => {
    setStatuses(data);
  }, []);

  useEffect(() => {
    getOrderDetails(orderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetOrderDetails, []);

  useEffect(() => {
    const eventOrderStatusChanged = listen(EventName.OrderStatusChanged, onSetStatuses);

    return () => {
      eventOrderStatusChanged.stopListening();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    updateAdminOwnerModal,
    ownerActions,
    statusMenu,
    paymentMenu,
    sampleMenu,
    openUploadReportModal,
    updatePaymentModal,
    setSampleStatusModal,
    dismissReviewConfirmModal,
    releaseOrderHorseModal,
    uploadReportModal,
    requiredTestsModal,
    statusItemsFunc,
    openRequiredTestsModal,
  } = useOrderDetailsActions({
    orderId,
    horseId: orderDetails.horse.id,
    ownerId: orderDetails.owner.id,
    horseName: orderDetails.horse.name,
    ownerName: orderDetails.owner.name,
    orderStatusesDetails: statuses,
    requiredTests,
    onSuccess: onChange,
    data: {
      dismissReviewAction: {action: changeReviewStatus, communication: changeReviewStatusRequesting},
      releaseOrderAction: {action: releaseOrderHorse, communication: orderHorseReleaseRequesting},
      setSampleStatusAction: {action: setSampleStatus, communication: setSampleStatusRequesting},
      downloadFormAction: {action: downloadSubmissionForm, communication: downloadFormRequesting},
      requiredTestsAction: {action: getOrderRequiredTests, communication: requiredTestsLoading},
      ownerEditAction: {onSuccess: getDetails},
    },
  });

  const isLoading = orderDetailsLoading.isRequesting;

  if (orderDetailsLoading.error) {
    return <span>Error on order details loading</span>;
  }

  return (
    <>
      {updateAdminOwnerModal}
      {updatePaymentModal}
      {setSampleStatusModal}
      {dismissReviewConfirmModal}
      {releaseOrderHorseModal}
      {uploadReportModal}
      {requiredTestsModal}

      <div style={{...tableProps?.expandedRowStyles?.content}}>
        <RecordDetails>
          <Column label="Owner info" actions={ownerActions}>
            <div className="position-relative">
              {isLoading && <Loading />}
              <OwnerDetails owner={orderDetails.owner} />
            </div>
          </Column>
        </RecordDetails>
        <RecordDetails>
          <Column label="Status" width="23%" menu={statusMenu}>
            <StatusDetails
              orderId={orderId}
              statusItemsFunc={statusItemsFunc}
              openRequiredTestsModal={openRequiredTestsModal}
            />
          </Column>
          <Column label="Packages Details" width="23%" menu={paymentMenu}>
            <PackageDetails orderId={orderId} openRequiredTestsModal={openRequiredTestsModal} />
          </Column>
          <Column label="Sample" width="23%" menu={sampleMenu}>
            <SampleDetails orderId={orderId} onSuccess={onChange} />
          </Column>
          <Column label="Reports" width="23%">
            <ReportDetails
              orderId={orderId}
              horseId={orderDetails.horse.id}
              openUploadReportModal={openUploadReportModal}
            />
          </Column>
        </RecordDetails>
        <PaymentDetailsWrapper>
          <PaymentDetails menuItems={paymentMenu.items} onSelectMenu={paymentMenu.onSelect} orderId={orderId} />
        </PaymentDetailsWrapper>
      </div>
    </>
  );
}

const mapStateToProps = (state: IAppState) => ({
  orderDetails: selectors.selectOrderDetails(state),
  orderDetailsLoading: selectors.selectCommunication(state, 'orderDetailsLoading'),
});

const mapDispatchToProps = {
  getOrderDetails: actions.getOrderDetails,
  resetOrderDetails: actions.resetOrderDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default React.memo(connector(withOrderDetailsActions(OrderDetails)));
