import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {FRONTEND_DATE_TIME} from 'Common/constants/Date';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import withDate from 'Common/helpers/withDate';
import {
  IOrderStatusUpdateDateField,
  OrderStatusUpdateDate,
  statusUpdateDateLabelByType,
} from 'Order/models/IOrderStatusUpdateDateField';

const StatusDate = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  color: ${ColorPalette.gray48};
  margin-left: 30px;
`;

const StatusDateWrapper = styled.div`
  top: 30px;
`;

interface IProps {
  statusUpdateDates?: IOrderStatusUpdateDateField;
  statusUpdateDate?: OrderStatusUpdateDate[];
}

function OrderUpdateDate(props: IProps) {
  const {statusUpdateDate, statusUpdateDates} = props;

  return (
    <>
      {statusUpdateDate && statusUpdateDate.length > 0 && (
        <StatusDateWrapper className="position-absolute">
          {statusUpdateDate.map(
            (date, i) =>
              statusUpdateDates &&
              statusUpdateDates[date] && (
                <StatusDate key={i}>{`${
                  statusUpdateDateLabelByType[date] ? statusUpdateDateLabelByType[date] + ': ' : ''
                } ${withDate(statusUpdateDates[date], FRONTEND_DATE_TIME)}`}</StatusDate>
              )
          )}
        </StatusDateWrapper>
      )}
    </>
  );
}

export default memo(OrderUpdateDate);
