import React, {useCallback, useState} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import ChangeEmailForm from 'Admin/AdminAssociations/components/Employees/ChangeEmailForm/ChangeEmailForm';
import {IAdminEmployee} from 'Admin/AdminAssociations/models/IAdminEmployee';

interface IProps {
  onSuccess(): void;
}

export function useUpdateAdminEmployeeEmailModal(props: IProps) {
  const {onSuccess} = props;

  const [selectedId, setSelectedId] = useState(0);
  const [selectedAdminEmployee, setSelectedAdminEmployee] = useState<IAdminEmployee>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openUpdateAdminEmployeeEmailModal = useCallback(
    (employee: IAdminEmployee) => {
      setSelectedId(employee.id);
      setSelectedAdminEmployee(employee);
      setIsModalOpen(true);
    },
    [setSelectedId]
  );
  const closeUpdateAdminEmployeeEmailModal = useCallback(() => setIsModalOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeUpdateAdminEmployeeEmailModal();
    onSuccess();
  }, [closeUpdateAdminEmployeeEmailModal, onSuccess]);

  const updateAdminEmployeeEmailModal = (
    <ModalWindow isOpen={isModalOpen && !!selectedId} onClose={closeUpdateAdminEmployeeEmailModal}>
      <ChangeEmailForm employee={selectedAdminEmployee!} onSuccess={handleSuccess} />
    </ModalWindow>
  );

  return {
    openUpdateAdminEmployeeEmailModal,
    updateAdminEmployeeEmailModal,
  };
}
