import React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {ExcludeHorseModule} from 'ExcludeHorse/store/excludeHorseModule';
import {actions as excludeActions, selectors as excludeSelectors} from 'ExcludeHorse/store/index';

type IConnected = ConnectedProps<typeof connector>;

export type IWithExcludeHorseActionsProps = IConnected;

function withExcludeHorseActions<T>(Component: React.ComponentType<T>) {
  const wrapped = (props: any) => <Component {...props} />;

  wrapped.displayName = `withExcludeHorseActions(${Component.displayName || Component.name || 'Component'})`;
  return connector(wrapped);
}

const mapStateToProps = (state: IAppState) => ({
  excludeHorseAdding: excludeSelectors.selectCommunication(state, 'excludeHorseAdding'),
  excludeHorseDeleting: excludeSelectors.selectCommunication(state, 'excludeHorseDeleting'),
});

const mapDispatchToProps = {
  addExcludeHorse: excludeActions.addExcludeHorse,
  deleteExcludeHorse: excludeActions.deleteExcludeHorse,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

function getWithExcludeHorseActionsModules() {
  return [ExcludeHorseModule];
}

export {getWithExcludeHorseActionsModules, withExcludeHorseActions as default};
