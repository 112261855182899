import {ICommunication} from './types';

const initialCommunication: ICommunication = {
  error: undefined,
  isRequesting: false,
  isSuccess: false
};

export function getInitialCommunication<E>(): ICommunication<E> {
  return {isRequesting: false, isSuccess: false, error: undefined};
}

export default initialCommunication;
