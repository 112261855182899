import React from 'react';
import {Prompt} from 'react-router-dom';
import history from 'Common/helpers/History';
import {Location} from 'history';

import WarningModal, {ModalTypes} from 'Common/components/Modal/WarningModal';

interface IState {
  modalVisible: boolean;
  lastLocation?: Location;
  confirmedNavigation: boolean;
}

interface ILocalProps {
  when: boolean;
  modalType: ModalTypes;
  hideCloseButton?: boolean;
  maxHeight?: string;
  minWidth?: string;
  maxWidth?: string;
  modalTitle?: string;
  successButtonText?: string;
  cancelButtonText?: string;
}

class RouteLeavingGuard extends React.Component<ILocalProps> {
  public static defaultProps = {
    successButtonText: 'Leave'
  };

  public state: IState = {
    modalVisible: false,
    confirmedNavigation: false
  };

  private navigate = (): void => {
    if (this.state.lastLocation) {
      history.push(this.state.lastLocation.pathname);
    }
  };

  private showModal = (nextLocation: Location): void => {
    this.setState({
      modalVisible: true,
      lastLocation: nextLocation
    });
  };

  private handlerOnClose = (): void =>
    this.setState({
      modalVisible: false
    });

  private handlerOnSuccess = (): void => {
    this.setState(
      {
        confirmedNavigation: true,
        modalVisible: false
      },
      () => {
        this.navigate();
      }
    );
  };

  private handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (this.state.confirmedNavigation) {
      return true;
    }
    this.showModal(nextLocation);
    return false;
  };

  public render() {
    const {when} = this.props;
    const {modalVisible} = this.state;

    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <WarningModal
          isOpen={modalVisible}
          modalTitle={this.props.modalTitle}
          modalType={this.props.modalType}
          successButtonText={this.props.successButtonText}
          cancelButtonText={this.props.cancelButtonText}
          hideCloseButton={this.props.hideCloseButton}
          maxHeight={this.props.maxHeight}
          maxWidth={this.props.maxWidth}
          onClose={this.handlerOnClose}
          onSuccess={this.handlerOnSuccess}
        >
          {this.props.children}
        </WarningModal>
      </>
    );
  }
}

export default RouteLeavingGuard;
