import {combineReducers, Reducer} from 'redux';

import {IAuthState} from '../types';
import {communicationLoginReducer} from './communications';
import {dataLoginReducer} from 'Auth/store/reducer/data';

export const authReducer: Reducer<IAuthState> = combineReducers<IAuthState>({
  data: dataLoginReducer,
  communications: communicationLoginReducer
});
