export enum OrderReportStatus {
  NotPaid = 'NotPaid',
  Waiting = 'WaitingSample',
  NotOrdered = 'NotOrdered',
  Processing = 'Processing',
  Complete = 'Complete',
}

export const mapReportStatusToLabel: Record<OrderReportStatus, string> = {
  [OrderReportStatus.NotPaid]: 'Waiting for payment',
  [OrderReportStatus.Waiting]: 'Waiting for sample',
  [OrderReportStatus.NotOrdered]: 'Not ordered',
  [OrderReportStatus.Processing]: 'Processing',
  [OrderReportStatus.Complete]: '',
};
