import {getEnvParams} from 'Common/helpers/getEnvParams';
import {MediaType} from 'Common/models/IMediaResource';

export function withImageBaseUrl<T extends {url: string; type?: MediaType}>(model: T) {
  const {imagesBaseUrl} = getEnvParams();

  return {
    ...model,
    url: model.type !== undefined && model.type === MediaType.Video ? model.url : `${imagesBaseUrl}/${model.url}`,
  };
}
