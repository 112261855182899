import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.adminHorses;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectHorses(state: IAppState) {
  return selectState(state).data.horses;
}

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectShortHorse(state: IAppState) {
  return selectState(state).data.shortHorse;
}

export function selectHorse(state: IAppState) {
  return selectState(state).data.horse;
}

export function selectHorseSamples(state: IAppState) {
  return selectState(state).data.horseSamples;
}
