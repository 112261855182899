import React, {memo} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'ExcludeHorse/store/index';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {searchBarStyle} from 'BusinessPortal/components/common/styled';
import {IUserHorse} from 'Horse/models/IUserHorse';
import {
  BusinessPortalCellContent,
  BusinessPortalHeaderCell,
  BusinessPortalSearch,
  BusinessPortalTableWrapper,
} from 'BusinessPortal/components/common/BusinessPortalTable';
import Loading from 'Loading/components/Loading';
import {Cell, Table} from 'Common/components/Table/Table';
import Typography from 'Common/constants/Typography';
import {AvatarCell} from 'Admin/AdminDashboard/components/shared';
import {Pagination} from 'Common/components/Controls';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import withExcludeHorseActions, {
  getWithExcludeHorseActionsModules,
  IWithExcludeHorseActionsProps,
} from 'Common/helpers/withExcludeHorseActions';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import ExcludeHorseButton from 'ExcludeHorse/components/ExcludeHorseButton';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IWithExcludeHorseActionsProps;

function Excluded(props: AllProps) {
  const {
    excludedHorses,
    getExcludeHorses,
    excludeHorsesLoading,
    addExcludeHorse,
    excludeHorseAdding,
    deleteExcludeHorse,
    excludeHorseDeleting,
    pagination,
  } = props;

  const {changeSorting, sorting, handlePageSelect, handleCountPerPage, searchBar} = useCommonAdminPageData<IUserHorse>({
    getItems: getExcludeHorses,
    searchBarPlaceholder: 'Search for horses by any keyword',
    searchBarStyle: searchBarStyle,
  });

  const isLoading = [excludeHorsesLoading].some((x) => x.isRequesting);

  const hasHorses = excludedHorses.length > 0 && !isLoading;

  useErrorCommunicationToToast(excludeHorseAdding, undefined, 1);
  useErrorCommunicationToToast(excludeHorseDeleting, undefined, 2);

  const {isMobile, isTablet} = useMediaQuery();

  return (
    <>
      <BusinessPortalSearch>{searchBar}</BusinessPortalSearch>
      <BusinessPortalTableWrapper>
        {isLoading && <Loading />}
        <>
          <Table<IUserHorse>
            data={excludedHorses}
            rowKey="id"
            sorting={sorting}
            onChangeSorting={changeSorting}
            expandable={false}
            disableRow={() => false}
            customHeaderCell={BusinessPortalHeaderCell}
            // not needed for expanded styles but also affect models row
            expandedRowStyles={{expandedRow: {height: 'auto'}}}
            tableStyles={{fontSize: Typography.size.size16, fontFamily: Typography.family.ubuntu}}
            isMobile={isMobile || isTablet}
          >
            <Cell<IUserHorse>
              header="Horse"
              dataKey="name"
              sortable={false}
              render={({name, id, avatar}) => (
                <BusinessPortalCellContent>
                  <AvatarCell
                    type="horse"
                    label={name}
                    avatarUrl={avatar?.url}
                    profileUrl={`/business-portal/horse/${id}`}
                  />
                </BusinessPortalCellContent>
              )}
              width="60%"
            />
            <Cell<IUserHorse>
              header="Actions"
              sortable={false}
              align={CellAlign.Right}
              width="20%"
              render={({id, isExcluded}) => (
                <BusinessPortalCellContent className="d-flex justify-content-end">
                  <ExcludeHorseButton
                    isExcluded={isExcluded}
                    onAddExcludedHorse={() => addExcludeHorse(id)}
                    onDeleteExcludedHorse={() => deleteExcludeHorse(id)}
                  />
                </BusinessPortalCellContent>
              )}
            />
          </Table>

          {hasHorses && (
            <Pagination
              pagination={pagination}
              onPageSelect={handlePageSelect}
              onChangeCountPerPage={handleCountPerPage}
            />
          )}
        </>
      </BusinessPortalTableWrapper>
    </>
  );
}

const mapStateToProps = (state: IAppState) => ({
  excludedHorses: selectors.selectExcludeHorses(state),
  excludeHorsesLoading: selectors.selectCommunication(state, 'excludeHorsesLoading'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getExcludeHorses: actions.getExcludeHorses,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const Connected = withExcludeHorseActions(connector(memo(Excluded)));

export default withDynamicModules(Connected, getWithExcludeHorseActionsModules());
