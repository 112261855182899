import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {Pagination} from 'Common/components/Controls';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import Loading from 'Loading/components/Loading';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminPurchasableTests';
import {FormType} from 'Common/constants/FormType';
import {SwitchButton} from 'Common/components/Controls/Buttons/SwitchButton';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAdminPurchasableTest} from 'Admin/AdminAssociations/models/IAdminPurchasableTest';
import {PurchasableTestActions} from './parts/PurchasableTestActions';
import {AdminPurchasableTestsModule} from 'Admin/AdminAssociations/store/adminPurchasableTests/adminPurchasableTestModule';
import PurchasableTestForm from './PurchasableTestForm/PurchasableTestForm';
import {ArchivedLabel} from '../shared/styled';

const PurchasableTestTable = styled.div`
  margin-top: 50px;
`;

const Switch = styled(SwitchButton)`
  padding-left: 0;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const PurchasableTests = (props: AllProps) => {
  const {
    purchasableTests,
    getPurchasableTests,
    purchasableTestsLoading,
    changePurchasableTestStatus,
    changePurchasableTestStatusRequesting,
  } = props;

  const {
    selectedId,
    setSelectedId,
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    deleteModal,
    reloadItems,
    searchBar,
  } = useCommonAdminPageData<IAdminPurchasableTest>({
    searchBarPlaceholder: 'Search for test by any keyword',
    getItems: getPurchasableTests,
  });

  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [formType, setFormType] = useState<FormType>(FormType.create);

  const isRequesting = [purchasableTestsLoading, changePurchasableTestStatusRequesting].some((i) => i.isRequesting);

  const handleEditPurchasableTest = (id: number) => {
    setFormType(FormType.edit);
    setSelectedId(id);
    setIsModalEditOpen(true);
  };

  const closeCreateOrEditPurchasableTestModal = useCallback(() => {
    setIsModalEditOpen(false);
  }, []);

  const onSuccessCreateOrEditPurchasableTest = useCallback(() => {
    closeCreateOrEditPurchasableTestModal();
    reloadItems();
  }, [closeCreateOrEditPurchasableTestModal, reloadItems]);

  const changeStatus = useCallback(
    (value: boolean, id: number) => {
      changePurchasableTestStatus(id, value);
    },
    [changePurchasableTestStatus]
  );

  const isShowPagination = purchasableTests.length > 0;

  return (
    <AdminPageLayout>
      {deleteModal}
      <ModalWindow isOpen={isModalEditOpen} onClose={closeCreateOrEditPurchasableTestModal}>
        <PurchasableTestForm
          onSuccess={onSuccessCreateOrEditPurchasableTest}
          testId={selectedId || undefined}
          type={formType}
        />
      </ModalWindow>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
      </div>
      <PurchasableTestTable className="position-relative">
        {isRequesting && <Loading />}
        <Table<IAdminPurchasableTest>
          data={purchasableTests}
          disableRow={(item) => !item.isEnabled}
          rowKey="id"
          sorting={sorting}
          onChangeSorting={changeSorting}
        >
          <Cell<IAdminPurchasableTest>
            header="Enable"
            dataKey="isEnabled"
            align={CellAlign.Left}
            render={({id, isEnabled, isArchived}) => {
              return isArchived ? (
                <ArchivedLabel>Archived</ArchivedLabel>
              ) : (
                <Switch itemId={id} onChangeHandler={changeStatus} checked={isEnabled} />
              );
            }}
            width="7%"
          />
          <Cell<IAdminPurchasableTest> header="ID" dataKey="id" render={({id}) => `#${id}`} width={100} />
          <Cell<IAdminPurchasableTest> header="Test" dataKey="test" render={({test}) => test.name} width="15%" />
          <Cell<IAdminPurchasableTest> header="Displayed name" dataKey="name" render={({name}) => name} width="20%" />
          <Cell<IAdminPurchasableTest>
            header="Available for"
            dataKey="specialPrices"
            sortable={false}
            render={({specialPrices, isAvailabilityAllOrganizations}) => {
              const allBusinessUsers = isAvailabilityAllOrganizations ? ['All business users'] : [];
              const specialOrganizations = specialPrices.map((x) => x.organizations.map((y) => y.name)).flat();
              return [...allBusinessUsers, ...specialOrganizations].join(', ');
            }}
            width="20%"
          />
          <Cell<IAdminPurchasableTest>
            header="Price"
            dataKey="commonPrice"
            render={({commonPrice, specialPrices}) => {
              const prices = specialPrices
                .map((x) => x.price)
                .flat()
                .concat(commonPrice);
              return specialPrices.length > 0 ? `$${Math.min(...prices)} - $${Math.max(...prices)}` : `$${commonPrice}`;
            }}
            width="15%"
          />

          <Cell<IAdminPurchasableTest>
            header="Actions"
            align={CellAlign.Right}
            width={100}
            render={(item) => <PurchasableTestActions purchasableTest={item} editAction={handleEditPurchasableTest} />}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={props.pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </PurchasableTestTable>
    </AdminPageLayout>
  );
};

const mapStateToProps = (state: IAppState) => ({
  purchasableTests: selectors.selectPurchasableTests(state),
  purchasableTestsLoading: selectors.selectCommunication(state, 'purchasableTestsLoading'),
  changePurchasableTestStatusRequesting: selectors.selectCommunication(state, 'changePurchasableTestStatusRequesting'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getPurchasableTests: actions.getPurchasableTests,
  changePurchasableTestStatus: actions.changePurchasableTestStatus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(PurchasableTests);

export default withDynamicModules(Connected, AdminPurchasableTestsModule);
