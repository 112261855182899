import {IModule} from 'redux-dynamic-modules';

import {paymentDetailsUpdateReducer} from './reducer/index';
import {IAdminAssociationPaymentUpdateState} from './types';

export interface IAdminAssociationPaymentUpdateModuleState {
  adminAssociationPaymentUpdate: IAdminAssociationPaymentUpdateState;
}

export const AdminAssociationPaymentUpdateModule: IModule<IAdminAssociationPaymentUpdateModuleState> = {
  id: 'adminAssociationPaymentUpdate',
  reducerMap: {
    adminAssociationPaymentUpdate: paymentDetailsUpdateReducer,
  },
};
