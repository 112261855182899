import React, {useState, CSSProperties, memo} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import ColorPalette from 'Common/constants/ColorPalette';
import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import RequiredMark from 'Common/helpers/RequiredMark';
import Typography from 'Common/constants/Typography';
import Icon, {IconName} from 'Icon/components/Icon';

import UploadPanel from './UploadPanel';
import {inputCss, controlLayout, inputLabelCss} from './styles';

const LARGE_FILE_ERROR = `File size cannot exceed 25 Mb`;

const ControlLayout = styled.div`
  padding-left: 0;
  ${controlLayout}

  .input-has-error {
    border: 1px solid ${ColorPalette.red4} !important;
  }
  .input-has-error:focus {
    box-shadow: 0px 0px 0px 2px ${ColorPalette.red5} !important;
  }
`;

const StyledLabel = styled.label`
  padding-left: 0;
  ${inputLabelCss}
`;

const StyledInput = styled.div`
  height: 48px;
  width: 100%;
  ${inputCss}
`;

const Placeholder = styled.span`
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size13};
  line-height: 24px;
  color: ${ColorPalette.gray3};
`;

const IconUpload = styled(Icon)`
  margin-right: 11px;
`;

const ErrorMessage = styled.div`
  margin-top: 8px;
  color: ${ColorPalette.red1};
  font-size: ${Typography.size.size12};
`;

interface IOwnProps {
  minSize?: number;
  maxSize?: number;
  acceptableTypes?: string | string[];
  style?: CSSProperties;
  id?: string;
  isShowError?: boolean;
  acceptPartialSelection?: boolean;
  onBlur?(): void;
  onError?(error: string): void;
}

type OnChangeHandler =
  | {
      isMultiple: true;
      onChange: MultipleFileHandler;
    }
  | {
      isMultiple?: false;
      onChange: SingleFileHandler;
    };

type SingleFileHandler = (value: File) => void;
type MultipleFileHandler = (value: File[]) => void;

type Props = IBaseControlProps & IOwnProps & OnChangeHandler;

function FileUploader(props: Props) {
  const {onChange, isShowError, maxSize, acceptableTypes, isMultiple, onError, acceptPartialSelection} = props;
  const [fileNames, setFileNames] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleSelectFiles = (files: File[]) => {
    if (files.length === 0) {
      return;
    }
    setError(null);
    setFileNames(files.map(file => file.name).join(','));

    if (isMultiple) {
      (onChange as MultipleFileHandler)(files);
    } else {
      (onChange as SingleFileHandler)(files[0]);
    }
  };

  const handleRejectFiles = (files: File[]) => {
    const someFileIsTooLarge = maxSize && files.some(file => file.size > maxSize);
    const rejectError = (() => {
      if (someFileIsTooLarge) {
        return LARGE_FILE_ERROR;
      }
      if (!!acceptableTypes) {
        return `Accepted file formats: ${(Array.isArray(acceptableTypes) ? acceptableTypes : [acceptableTypes]).join(
          ', '
        )}`;
      }
      return null;
    })();

    if (!rejectError) {
      return;
    }

    if (isShowError) {
      setError(rejectError);
    }
    if (onError) {
      onError(rejectError);
    }
  };

  return (
    <ControlLayout className={classNames('custom-control', props.className)} style={props.style} role="group">
      {props.label && (
        <StyledLabel className={classNames(props.labelClassName)} htmlFor={props.id} style={props.labelStyle}>
          {props.label} <RequiredMark isRequired={props.isRequired} />
        </StyledLabel>
      )}
      <UploadPanel
        id={`file-upload-${props.id}`}
        onSelectFiles={handleSelectFiles}
        onRejectFiles={handleRejectFiles}
        height="100%"
        width="100%"
        minSize={props.minSize}
        maxSize={props.maxSize}
        acceptableTypes={props.acceptableTypes}
        inputStyle={{border: 0}}
        multiple={isMultiple}
        acceptPartialSelection={acceptPartialSelection}
      >
        <StyledInput
          className={classNames(
            'form-control',
            'custom-control-input',
            'position-relative',
            'd-flex',
            'align-items-center',
            props.inputClassName
          )}
          style={props.inputStyle}
        >
          <IconUpload name={IconName.CloudUpload} size={24} />
          {!fileNames ? <Placeholder>{props.placeholder}</Placeholder> : <span>{fileNames}</span>}
        </StyledInput>
      </UploadPanel>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </ControlLayout>
  );
}

FileUploader.defaultProps = {
  isShowError: true,
  minSize: 0,
  maxSize: 26214400
};

export default memo(FileUploader);
export type FileUploaderProps = Props;
