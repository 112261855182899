import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication/index';
import {IUserHorse} from 'Horse/models/IUserHorse';
import {IListResponse, IPagination} from 'Common/models/IResponse';

import IFoundExcludeHorse from 'ExcludeHorse/models/IFoundExcludeHorse';

const STATE_NAME = 'excludeHorse';

export interface IExcludeHorseState {
  data: {
    excludeHorses: IUserHorse[];
    simpleExcludeHorses: IFoundExcludeHorse[];
  };
  communications: {
    excludeHorsesLoading: ICommunication;
    excludeHorseAdding: ICommunication;
    excludeHorseDeleting: ICommunication;
    simpleExcludeHorsesLoading: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getExcludeHorsesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_EXCLUDE_HORSES');
export type getExcludeHorsesActions = MakeCommunicationActions<
  typeof getExcludeHorsesActionTypes,
  {success: IListResponse<IUserHorse>}
>;

export const addExcludeHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'ADD_EXCLUDE_HORSE');
export type addExcludeHorseActions = MakeCommunicationActions<typeof addExcludeHorseActionTypes, {success: number}>;

export const deleteExcludeHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_EXCLUDE_HORSE');
export type deleteExcludeHorseActions = MakeCommunicationActions<
  typeof deleteExcludeHorseActionTypes,
  {success: number}
>;

export const getSimpleExcludeHorsesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_SIMPLE_EXCLUDE_HORSES');
export type getSimpleExcludeHorsesActions = MakeCommunicationActions<
  typeof getSimpleExcludeHorsesActionTypes,
  {success: IListResponse<IFoundExcludeHorse>}
>;

export const resetSimpleExcludeHorsesActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'SIMPLE_EXCLUDE_HORSES'
);
export type resetSimpleExcludeHorsesActions = MakeResetCommunicationActions<typeof resetSimpleExcludeHorsesActionTypes>;

export type ExcludeHorsesActions =
  | getExcludeHorsesActions
  | addExcludeHorseActions
  | deleteExcludeHorseActions
  | getSimpleExcludeHorsesActions
  | resetSimpleExcludeHorsesActions;
