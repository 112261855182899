import {IPaymentDetails} from 'Admin/AdminDashboard/models/IPaymentDetails';
import {convertUTCToClientDate, convertToServerDate} from 'Common/helpers/DateHelper';
import {FRONTEND_DATE} from 'Common/constants/Date';

export function convertPaymentDetailsToClient(paymentDetails: IPaymentDetails): IPaymentDetails {
  return {
    ...paymentDetails,
    receivedDate: paymentDetails.receivedDate
      ? convertUTCToClientDate(paymentDetails.receivedDate, FRONTEND_DATE)
      : null
  };
}

export function convertPaymentDetailsToRequest(paymentDetails: IPaymentDetails): IPaymentDetails {
  return {
    ...paymentDetails,
    receivedDate: convertToServerDate(paymentDetails.receivedDate, true)
  };
}
