import React, {memo} from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import ColorPalette from 'Common/constants/ColorPalette';

import {Gender} from 'Common/constants/Gender';
import {RadiobuttonField} from 'Common/components/FormFields';

import {SectionLabel} from './shared';

const genders = [Gender.Gelding, Gender.Mare, Gender.Stallion];

const radioStyles = {marginBottom: 6};
const labelStyles = {marginBottom: 12};

const Root = styled.div`
  border-radius: 10px;
  background: ${Theme.color.white};
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.05);
`;

const Section = styled.div`
  padding: 16px;

  :not(:first-child) {
    border-top: 1px solid ${ColorPalette.gray47};
  }
`;

interface IProps {
  variant: 'bah' | 'fah';
}

function BaseTraits(props: IProps) {
  const {variant} = props;

  const isBah = variant === 'bah';

  return (
    <Root>
      {!isBah && (
        <Section>
          <SectionLabel style={labelStyles}>Gender</SectionLabel>
          <RadiobuttonField name="gender" value="all" label="Any" style={radioStyles} />
          {genders.map((gender, i) => (
            <RadiobuttonField key={i} name="gender" value={gender} label={gender} style={radioStyles} />
          ))}
        </Section>
      )}
    </Root>
  );
}

export default memo(BaseTraits);
