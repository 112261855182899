import {Reducer} from 'redux';

import {IAdminCouponState, CouponsActions, getCouponsActionTypes} from 'Admin/AdminDashboard/store/adminCoupons/types';

const initialState: IAdminCouponState['ui'] = {
  pagination: {countPerPage: 0, currentPage: 1, pageCount: 1, totalItemsCount: 1}
};

export const uiCouponsReducer: Reducer<IAdminCouponState['ui'], CouponsActions> = (
  state = initialState,
  action: CouponsActions
) => {
  switch (action.type) {
    case getCouponsActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
