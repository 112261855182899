import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {INamedEntity} from 'Common/models/INamedEntity';

export interface IFormValues {
  id?: IEntity['id'];
  name: string;
}

export const initialValue: IFormValues = {
  name: ''
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required('Field is required')
});

export const convertToServerUpdating = (values: IFormValues): INamedEntity => {
  return {
    ...values,
    id: values.id!
  };
};
