import {Reducer} from 'redux';

import {getAdminNotificationsActionTypes, IAdminNotificationState, AdminNotificationsActions} from '../types';

const initialState: IAdminNotificationState['data'] = {
  adminNotifications: {id: 0, name: '', email: '', notificationTypes: []}
};

export const dataAdminNotificationsReducer: Reducer<IAdminNotificationState['data'], AdminNotificationsActions> = (
  state = initialState,
  action: AdminNotificationsActions
) => {
  switch (action.type) {
    case getAdminNotificationsActionTypes.REQUEST: {
      return {...state, adminNotifications: initialState.adminNotifications};
    }
    case getAdminNotificationsActionTypes.SUCCESS: {
      return {...state, adminNotifications: action.payload};
    }
    default: {
      return state;
    }
  }
};
