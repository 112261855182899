import React, {memo, useMemo} from 'react';

import {OrderStatus} from 'Common/constants/OrderStatus';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {IMenuItem, IOrderedMenuItem} from 'Common/components/DropDown/common/types';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {getWorstHorseStatus} from 'Common/helpers/getWorstHorseStatus';
import {IOrderHistory} from 'Admin/AdminDashboard/components/shared/OrderHistory/parts/common';
import AdminActions from 'Admin/AdminDashboard/components/shared/AdminActions/AdminActions';
import {OrderStatusField} from 'Order/models/IOrderStatusFields';
import {IRequestChangeReviewStatus} from 'Admin/AdminDashboard/store/adminOrders/types';

enum OrderMenuItemValue {
  OpenOrder = 'openOrder',
  OpenLiveReport = 'openLiveReport',
  ReviewLiveReport = 'reviewLiveReport',
  SubmissionForm = 'submissionForm',
  UpdatePayment = 'updatePayment',
  SampleNotReceived = 'sampleNotReceived',
  SampleReceived = 'sampleReceived',
  RequestNewSample = 'requestNewSample',
  DownloadResults = 'downloadResults',
  DismissReview = 'dismissReview',
  DeleteOrder = 'deleteOrder',
}

const iconProps = {color: ColorPalette.red7};
const deleteIconProps = {name: IconName.Garbage, color: ColorPalette.gray44};
const deleteTextProps = {color: ColorPalette.gray44, hoverColor: ColorPalette.red9, activeColor: ColorPalette.red11};

const commonMenuItems: Record<OrderMenuItemValue, Omit<IOrderedMenuItem, 'value'>> = {
  [OrderMenuItemValue.OpenOrder]: {label: 'Show order info', icon: {name: IconName.Info, ...iconProps}, id: 1},
  [OrderMenuItemValue.OpenLiveReport]: {
    label: 'Open live report',
    icon: {name: IconName.Notebook, ...iconProps, fill: false, stroke: true},
    id: 2,
  },
  [OrderMenuItemValue.ReviewLiveReport]: {
    label: 'Review live report',
    icon: {name: IconName.InsertComment, ...iconProps},
    id: 2,
  },
  [OrderMenuItemValue.SubmissionForm]: {
    label: 'Submission form',
    icon: {name: IconName.Description, ...iconProps},
    id: 3,
  },
  [OrderMenuItemValue.UpdatePayment]: {label: 'Update payment', icon: {name: IconName.Money, ...iconProps}, id: 4},
  [OrderMenuItemValue.SampleNotReceived]: {
    label: 'Sample not received',
    icon: {name: IconName.Truck, ...iconProps},
    id: 5,
  },
  [OrderMenuItemValue.SampleReceived]: {
    label: 'Mark sample as received',
    icon: {name: IconName.Truck, ...iconProps},
    id: 5,
  },
  [OrderMenuItemValue.RequestNewSample]: {
    label: 'Resubmit sample',
    icon: {name: IconName.Truck, ...iconProps},
    id: 5,
  },
  [OrderMenuItemValue.DownloadResults]: {
    label: 'Download results',
    icon: {name: IconName.Upload, ...iconProps},
    id: 99,
  },
  [OrderMenuItemValue.DismissReview]: {
    label: 'Dismiss review',
    icon: {name: IconName.InsertComment, ...iconProps},
    id: 99,
  },
  [OrderMenuItemValue.DeleteOrder]: {
    label: 'Delete order',
    icon: {...deleteIconProps},
    style: {...deleteTextProps},
    divided: true,
    id: 100,
  },
};

interface IExternalProps {
  order: IOrderHistory;
  openOrderHandler?(orderId: number): void;
  deleteOrderHandler(orderId: number): void;
  openLiveReportHandler(horseId: number, orderId?: number): string;
  submissionFormHandler(orderId: number): void;
  downloadResultsHandler(orderId: number): void;
  sampleReceivedHandler(orderId: number, horseId: number): void;
  sampleNotReceivedHandler(orderId: number, horseId: number): void;
  requestNewSampleHandler(orderId: number, horseId: number): void;
  updatePaymentHandler(orderId: number): void;
  dismissReviewHandler(data: IRequestChangeReviewStatus): void;
}

type AllProps = IExternalProps;

const OrderActions = (props: AllProps) => {
  const {id, horses} = props.order;
  const {
    openOrderHandler,
    deleteOrderHandler,
    openLiveReportHandler,
    submissionFormHandler,
    sampleNotReceivedHandler,
    sampleReceivedHandler,
    requestNewSampleHandler,
    downloadResultsHandler,
    updatePaymentHandler,
    dismissReviewHandler,
  } = props;

  const mapAvailableByMenuItem: Record<OrderMenuItemValue, boolean> = useMemo(() => {
    const orderStatus = getWorstHorseStatus(horses) || OrderStatus.orderPlaced;
    const singleHorse = horses.length === 1;
    const hasSample = horses[0]?.hasSample;

    return {
      [OrderMenuItemValue.OpenOrder]: !!openOrderHandler,
      [OrderMenuItemValue.OpenLiveReport]: singleHorse && orderStatus === OrderStatus.releaseReady,
      [OrderMenuItemValue.ReviewLiveReport]: singleHorse && hasSample && orderStatus !== OrderStatus.releaseReady,
      [OrderMenuItemValue.SubmissionForm]: !!submissionFormHandler,
      [OrderMenuItemValue.UpdatePayment]: true,
      [OrderMenuItemValue.SampleNotReceived]: singleHorse && hasSample,
      [OrderMenuItemValue.SampleReceived]: singleHorse && !hasSample,
      [OrderMenuItemValue.RequestNewSample]: singleHorse,
      [OrderMenuItemValue.DownloadResults]: horses.some((item) => item.hasRelease),
      [OrderMenuItemValue.DismissReview]: singleHorse && orderStatus === OrderStatus.reviewReady,
      [OrderMenuItemValue.DeleteOrder]: orderStatus === OrderStatus.orderPlaced,
    };
  }, [horses, openOrderHandler, submissionFormHandler]);

  const actionsHandler: Record<OrderMenuItemValue, () => void | string> = useMemo(
    () => ({
      [OrderMenuItemValue.OpenOrder]: () => openOrderHandler!(id),
      [OrderMenuItemValue.OpenLiveReport]: () => openLiveReportHandler(horses[0].id),
      [OrderMenuItemValue.ReviewLiveReport]: () => openLiveReportHandler(horses[0].id, id),
      [OrderMenuItemValue.SubmissionForm]: () => submissionFormHandler(id),
      [OrderMenuItemValue.UpdatePayment]: () => updatePaymentHandler(id),
      [OrderMenuItemValue.SampleNotReceived]: () => sampleNotReceivedHandler(id, horses[0].id),
      [OrderMenuItemValue.SampleReceived]: () => sampleReceivedHandler(id, horses[0].id),
      [OrderMenuItemValue.RequestNewSample]: () => requestNewSampleHandler(id, horses[0].id),
      [OrderMenuItemValue.DownloadResults]: () => downloadResultsHandler(id),
      [OrderMenuItemValue.DismissReview]: () =>
        dismissReviewHandler({orderId: id, horseId: horses[0].id, reviewStatus: OrderStatusField.Waiting}),
      [OrderMenuItemValue.DeleteOrder]: () => deleteOrderHandler(id),
    }),
    [
      deleteOrderHandler,
      dismissReviewHandler,
      downloadResultsHandler,
      horses,
      id,
      openLiveReportHandler,
      openOrderHandler,
      requestNewSampleHandler,
      sampleNotReceivedHandler,
      sampleReceivedHandler,
      submissionFormHandler,
      updatePaymentHandler,
    ]
  );

  const onSelectHandler = (type: keyof typeof actionsHandler) => actionsHandler[type]();

  const shownMenuItems: IMenuItem[] = useMemo(
    () =>
      Object.entries(commonMenuItems)
        .map(([key, value]) => {
          // If open Online Report/Reveiw then make menu item as a link
          if (key === OrderMenuItemValue.OpenLiveReport || key === OrderMenuItemValue.ReviewLiveReport) {
            const handlerValue = actionsHandler[key]();
            return {
              ...value,
              value: handlerValue as string,
              isMenuItemAsLink: true,
              availabilityKey: mapAvailableByMenuItem[key],
            };
          }
          return {...value, value: key, availabilityKey: mapAvailableByMenuItem[key]};
        })
        .filter((item) => item.availabilityKey)
        .sort((a, b) => sortByStringKey(a, b, 'id')),
    [actionsHandler, mapAvailableByMenuItem]
  );

  return <AdminActions menuItems={shownMenuItems} onSelectHandler={onSelectHandler} />;
};

export default memo(OrderActions);
export type OrderActionsProps = IExternalProps;
