import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {getUserActions, getUserActionTypes, IUserState} from './types';
import UserService from 'UserProfile/services/UserService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const getUser =
  (id: number): ActionResult<IUserState, void, getUserActions> =>
  async (dispatch) => {
    dispatch(action(getUserActionTypes.REQUEST));

    await UserService.getUser(id)
      .then((res) => {
        dispatch(action(getUserActionTypes.SUCCESS, res.data));
      })
      .catch((error) => {
        dispatch(action(getUserActionTypes.FAILURE, getErrorMessage(error)));
      });
  };
