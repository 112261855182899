import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication/index';
import {IListResponse, IPagination} from 'Common/models/IResponse';

import {IHtmlTemplate} from 'Admin/AdminSettings/models/IHtmlTemplate';
import {resetCouponDetailsActions} from 'Admin/AdminDashboard/store/adminCoupons';

const STATE_NAME = 'adminHtmlTemplate';

export interface IAdminHtmlTemplateState {
  data: {
    templates: IHtmlTemplate[];
    template: string;
  };
  communications: {
    templatesLoading: ICommunication;
    templateLoading: ICommunication;
    templateUploading: ICommunication;
    templateDeleting: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getHtmlTemplatesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HTML_TEMPLATES');

export type getHtmlTemplatesActions = MakeCommunicationActions<
  typeof getHtmlTemplatesActionTypes,
  {success: IListResponse<IHtmlTemplate>}
>;

export const getHtmlTemplateActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HTML_TEMPLATE');

export type getHtmlTemplateActions = MakeCommunicationActions<typeof getHtmlTemplateActionTypes, {success: string}>;

export const uploadHtmlTemplateActionTypes = makeCommunicationActionType(STATE_NAME, 'UPLOAD_HTML_TEMPLATE');

export type uploadHtmlTemplateActions = MakeCommunicationActions<typeof uploadHtmlTemplateActionTypes, {}>;

export const deleteHtmlTemplateActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_HTML_TEMPLATE');

export type deleteHtmlTemplateActions = MakeCommunicationActions<typeof deleteHtmlTemplateActionTypes, {}>;

export const resetTemplateActionTypes = makeResetCommunicationActionType(STATE_NAME, 'TEMPLATE');
export type resetTemplateActions = MakeResetCommunicationActions<typeof resetTemplateActionTypes>;

export type HtmlTemplatesActions =
  | getHtmlTemplatesActions
  | uploadHtmlTemplateActions
  | deleteHtmlTemplateActions
  | getHtmlTemplateActions
  | resetCouponDetailsActions
  | resetTemplateActions;
