import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import {ITabPagesData} from 'Common/components/Tabs/ITab';
import Tabs from 'Common/components/Tabs/Tabs';
import {ILinkHoverStyle} from 'Common/components/Controls/Links/HorizontalMenuLink';

const tabStyle: React.CSSProperties = {
  width: '100%',
  textAlign: 'center',
  margin: '0 2px',
  backgroundColor: ColorPalette.gray52,
  maxWidth: '400px'
};

const linkStyle: React.CSSProperties = {
  backgroundColor: hexToRgba(ColorPalette.white0, 0.6),
  color: ColorPalette.gray45,
  borderRadius: '8px 8px 0px 0px',
  fontSize: 20
};
const activeLinkStyle: React.CSSProperties = {
  backgroundColor: hexToRgba(ColorPalette.white0, 1),
  color: ColorPalette.black1
};
const hoverLinkStyle: ILinkHoverStyle = {
  backgroundColor: hexToRgba(ColorPalette.white0, 0.8),
  color: ColorPalette.black1
};

const Root = styled.div<{sidePadding?: string}>`
  padding: 32px 70px 0;
  background: ${ColorPalette.gray49};
`;

interface IProps {
  isSort?: boolean;
}

type Props = ITabPagesData & IProps;

function AdminTabs(props: Props) {
  const {tabs, isSort} = props;

  return (
    <Root>
      <Tabs
        tabs={tabs}
        tabStyle={tabStyle}
        linkStyle={linkStyle}
        activeLinkStyle={activeLinkStyle}
        hoverLinkStyle={hoverLinkStyle}
        isSort={isSort}
      />
    </Root>
  );
}

export default memo(AdminTabs);
