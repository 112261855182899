import * as React from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {memoWithGeneric} from 'Common/helpers/react/memoWithGeneric';

const Tr = styled.tr<{expanded?: boolean; disabled?: boolean; expandable?: boolean}>`
  height: 80px;
  background: ${props => (props.expanded ? 'white' : 'unset')};
  box-shadow: ${props =>
    props.expanded
      ? `0px 3px 3.62304px rgba(0, 0, 0, 0.00696822), 
      0px 7px 10.0172px rgba(0, 0, 0, 0.01), 
      0px 16px 24.1177px rgba(0, 0, 0, 0.0130318), 
      0px 61px 80px rgba(0, 0, 0, 0.02)`
      : 'unset'};
  position: relative;
  color: ${props => (props.disabled ? ColorPalette.gray21 : 'unset')};

  & > td {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 100%;
      height: 2px;
      background: ${ColorPalette.white9};
    }

    ${({expandable}) =>
      expandable
        ? `
      &:first-child::after {
        left: 25px;
      }
      &:last-child::after {
        right: 25px;
      }
  `
        : ''}
  }

  ${props =>
    props.expanded &&
    ` > td {
      position: relative;
      border-bottom: unset;
      z-index: auto;

      &::after {
        display: none;
      }
    }`}
`;

const ExpandedContent = styled.td`
  padding-top: 20px;
  padding-bottom: 40px;
`;

interface IRowProps<T> {
  row: T;
  order: number;
  expandable: boolean;
  expanded?: boolean;
  expandContent?: JSX.Element | string;
  disabled?: boolean;
  styles?: ExpandedRowStyles;
  renderRow(args: IRenderRowArgs<T>): any;
  renderExpandContent?(row: T): React.ReactNode;
}

export type ExpandedRowStyles = {[key in 'content' | 'toggle' | 'toggleIcon' | 'expandedRow']?: React.CSSProperties};

export interface IRenderRowArgs<T> {
  row: T;
  order: number;
  disabled: boolean;
  expandable: boolean;
  expanded: boolean;
}

export default memoWithGeneric(function Row<T>(props: React.PropsWithChildren<IRowProps<T>>) {
  const {renderExpandContent, expandable, expanded, disabled, renderRow, row, styles, order} = props;

  return (
    <>
      <Tr expanded={expanded} disabled={disabled} expandable={expandable} style={styles?.expandedRow}>
        {renderRow({row, disabled: !!disabled, expandable, expanded: !!expanded, order})}
      </Tr>
      {expanded && renderExpandContent && (
        <Tr expanded={expanded} expandable={expandable} style={styles?.expandedRow}>
          <ExpandedContent colSpan={100} style={styles?.content}>
            {renderExpandContent(row)}
          </ExpandedContent>
        </Tr>
      )}
    </>
  );
});
