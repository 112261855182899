import React from 'react';
import styled from 'styled-components';

import {MainLayout} from 'Common/components/StyledComponents/ManageData';
import {ITabPagesData} from 'Common/components/Tabs/ITab';
import TabsContent from 'Common/components/Tabs/TabsContent';

const Content = styled.div`
  padding: 30px 0;
`;

interface IProps {
  isSort?: boolean;
}

type Props = ITabPagesData & IProps;

const AdminContent = (props: Props) => {
  const {tabs, basePath, isSort} = props;

  return (
    <MainLayout>
      <Content>
        <TabsContent tabs={tabs} basePath={basePath} isSort={isSort} />
      </Content>
    </MainLayout>
  );
};

export default AdminContent;
