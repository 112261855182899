import {combineReducers, Reducer} from 'redux';

import {IAdminOrdersState} from 'Admin/AdminDashboard/store/adminOrders/types';
import {communicationOrdersReducer} from 'Admin/AdminDashboard/store/adminOrders/reducer/communications';
import {dataOrdersReducer} from 'Admin/AdminDashboard/store/adminOrders/reducer/data';
import {uiOrdersReducer} from 'Admin/AdminDashboard/store/adminOrders/reducer/ui';

export const ordersReducer: Reducer<IAdminOrdersState> = combineReducers<IAdminOrdersState>({
  communications: communicationOrdersReducer,
  data: dataOrdersReducer,
  ui: uiOrdersReducer,
});
