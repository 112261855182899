import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

import {IUserPrivacySettings} from 'UserProfile/models/IUserPrivacySettings';
import {Nullable} from 'Common/types';

const STATE_NAME = 'userPrivacySettings';

export interface IUserPrivacySettingsState {
  data: {
    userPrivacySettings: Nullable<IUserPrivacySettings>;
  };
  communications: {
    userPrivacySettingsLoading: ICommunication;
    userPrivacySettingsUpdating: ICommunication;
  };
}

export const getUserPrivacySettingsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_PRIVACY_SETTINGS');
export type getUserPrivacySettingsActions = MakeCommunicationActions<
  typeof getUserPrivacySettingsActionTypes,
  {success: IUserPrivacySettings}
>;

export const updateUserPrivacySettingsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_USER_PRIVACY_SETTINGS'
);
export type updateUserPrivacySettingsActions = MakeCommunicationActions<
  typeof updateUserPrivacySettingsActionTypes,
  {}
>;

export type UserPrivacySettingsActions = getUserPrivacySettingsActions | updateUserPrivacySettingsActions;
