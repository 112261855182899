import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

const STATE_NAME = 'submissionForm';

export interface ISubmissionFormState {
  communications: {
    downloadFormRequesting: ICommunication;
  };
}

export const downloadSubmissionFormActionTypes = makeCommunicationActionType(STATE_NAME, 'DOWNLOAD_FORM');
export type downloadSubmissionFormActions = MakeCommunicationActions<typeof downloadSubmissionFormActionTypes, {}>;

export type SubmissionFormActions = downloadSubmissionFormActions;
