import React, {memo} from 'react';
import styled from 'styled-components';

import CopyZone from 'Common/components/CopyZone/CopyZone';
import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';

const COPY_SAMPLE_ID_MESSAGE = 'Sample ID has been copied to your clipboard';

const Button = styled(IconButton)`
  margin-left: 8px;
`;

interface IProps {
  id?: number;
  sampleId?: string;
  hasSample?: boolean;
  onEditClick(): void;
}

function SampleId(props: IProps) {
  const {sampleId, hasSample = true, onEditClick} = props;

  if (!sampleId) {
    return <>-</>;
  }

  return (
    <div className="d-flex align-items-center">
      <CopyZone className="d-flex align-items-center" textToCopy={sampleId} successfulMessage={COPY_SAMPLE_ID_MESSAGE}>
        {sampleId}
        <Button name={IconName.FileCopy} size={16} stroke={false} fill={true} color={ColorPalette.gray44} />
      </CopyZone>
      {hasSample && (
        <Button
          name={IconName.Edit}
          size={16}
          stroke={false}
          fill={true}
          color={ColorPalette.gray44}
          onClick={onEditClick}
        />
      )}
    </div>
  );
}

export default memo(SampleId);
