import React, {useEffect, useRef, FunctionComponent, cloneElement} from 'react';

interface IProps {
  onClickAway(): void;
  children: React.ReactElement<any>;
}

const ClickAwayListener: FunctionComponent<IProps> = ({onClickAway, children}) => {
  const node = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent): void => {
      if (node.current && node.current.contains(event.target as Node)) {
        return;
      }
      onClickAway();
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  });

  return cloneElement(children, {
    ...children.props,
    ref: node
  });
};

export default ClickAwayListener;
