export default {
  black0: '#000',
  black1: '#181B26',
  black2: 'rgba(24, 27, 38, 0.6)',
  black3: '#2E3243',
  black4: 'rgba(24, 27, 38, 0.4)',
  black5: 'rgba(24, 27, 38, 0.75)',
  black6: '#3A3F52',
  black7: 'rgba(24, 27, 38, 0.95)',
  black8: 'rgba(24, 27, 38, 0.3)',
  black9: '#14151F',

  gray0: '#F7F7F7',
  gray1: '#C2C2C2',
  gray2: '#EBEBEB',
  gray3: '#949494',
  gray4: 'rgba(0, 0, 0, 0.15)',
  gray5: '#5D5D5D',
  gray6: '#E5E5E5',
  gray7: '#c4c4c4',
  gray8: '#5D5F67',
  gray9: '#BABABA',
  gray10: '#EEEEEE',
  gray11: '#E6E6E6',
  gray12: 'rgba(0, 0, 0, 0.08)',
  gray13: '#AAAAAA',
  gray14: 'rgba(24, 27, 38, 0.25)',
  gray15: '#DEDFE2',
  gray16: '#F2F2F2',
  gray17: '#C7C7C7',
  gray18: '#ABABAB',
  gray19: '#D7D7D7',
  gray20: '#E2E2E2',
  gray21: '#D1D1D4',
  gray22: '#F9F9F9',
  gray23: '#D2D2D2',
  gray24: '#D0D0D0',
  gray25: '#E4E4E4',
  gray26: '#DCDCDC',
  gray27: '#B9B9B9',
  gray28: '#B8B8B8',
  gray29: '#DFDFDF',
  gray30: '#96989D',
  gray31: '#AAB8C6',
  gray32: '#AFB2BB',
  gray33: '#FAFAFD',
  gray34: '#FCFCFC',
  gray35: '#91939F',
  gray36: '#8C8C8C',
  gray37: '#CCCCCC',
  gray38: '#F8F8F8',
  gray39: '#E6E6E7',
  gray40: 'rgba(225, 226, 229, 0.15)',
  gray41: '#B7B9BE',
  gray42: '#696F83',
  gray43: 'rgba(255, 255, 255, 0.1)',
  gray44: '#CACBCD',
  gray45: '#BCBEC5',
  gray46: '#F7F7FB',
  gray47: '#eeeff6',
  gray48: '#DEDFE1',
  gray49: '#F4F6F8',
  gray50: 'rgba(0, 0, 0, 0.04)',
  gray51: 'rgba(0, 0, 0, 0.06)',
  gray52: '#F5F6F8',
  gray53: '#E2E7F4',
  gray54: '#C1C2C3',
  gray55: '#AEAEAE',
  gray56: '#737475',
  gray57: '#EDEEEF',
  gray58: '#686A79',

  white0: '#FFFFFF',
  white1: 'rgba(255, 255, 255, 0)',
  white2: '#F1F1F1',
  white3: 'rgba(255, 255, 255, 0.802083)',
  white4: 'rgba(255, 255, 255, 0.2)',
  white5: 'rgba(24, 27, 38, 0.02)',
  white6: '#A5A6A9',
  white7: '#C6C6C6',
  white8: '#A2A6B6',
  white9: '#EDEEF0',
  white10: 'rgba(255, 255, 255, 0.3)',
  white11: '#8B8D92',
  white12: '#A5A5A5',

  yellow0: '#FFD41B',
  yellow1: '#FFB200',
  yellow2: '#FFC909',
  yellow3: '#FEDA3F',
  yellow4: '#FED31B',
  yellow5: '#FFD81B',
  yellow6: '#FFD41BCC',
  yellow7: 'rgba(255, 178, 0, 0.1)',
  yellow8: 'rgba(255, 212, 27, 0.8)',
  yellow9: '#FFB142',
  yellow10: '#FFD98D',
  yellow11: '#ffb142',
  yellow12: '#FFF5D9',
  yellow13: '#FC8D00',
  yellow14: '#F79E32',

  blue0: '#4169E1',
  blue1: '#1A83FF',
  blue2: '#616198',
  blue3: '#2C2C54',
  blue4: '#001666',

  red0: '#C47272',
  red1: '#D3212D',
  red2: '#6B0D0D',
  red3: 'rgba(107, 13, 13, 0.2)',
  red4: '#FF5A25',
  red5: 'rgba(255, 90, 37, 0.25);',
  red6: 'rgba(255, 90, 37, 0.8)',
  red7: '#B33939',
  red8: '#FF2E00',
  red9: '#e89989',
  red10: 'rgba(214, 42, 42, 0.4)',
  red11: '#992933',
  red12: '#801C2E',
  red13: '#D62A2A',
  red14: '#A92929',
  red15: '#847272',

  green0: '#00DEA4',
  green1: '#00CC99',
  green2: '#218C74',
  green3: '#77dcb2',
  green4: '#006624',

  brown0: '#AC623B',
  brown1: '#5D0C0C',
  brown2: '#74241a',

  orange0: '#FF6700',

  violet0: '#5A00A0',

  transparent0: 'transparent',
  transparent1: 'rgba(0, 0, 0, 0)',
  transparent2: 'rgba(0, 0, 0, 0.12)',
  transparent3: 'rgba(0, 0, 0, 0.03)',
  transparent4: 'rgba(0, 0, 0, 0.27)',
  transparent5: 'rgba(0, 0, 0, 0.05)',
  transparent6: 'rgba(255, 255, 255, 0.05)',
  transparent7: 'rgba(255, 255, 255, 0.07)',

  bootstrap: {
    outline: 'rgba(255,212,27,0.25)',
    boxShadow: 'rgba(0,123,255,.25)',
  },
};
