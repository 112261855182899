import {Reducer} from 'redux';

import {IAdminChangeEmailState, changeEmailActions} from '../types';

const initialState: IAdminChangeEmailState['data'] = {
  message: null
};

export const dataChangeEmailReducer: Reducer<IAdminChangeEmailState['data'], changeEmailActions> = (
  state = initialState,
  action: changeEmailActions
) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};
