import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import {breakpoints} from 'Common/constants/Breakpoints';

import IElementPosition, {ElementPosition} from 'FoalProfile/models/IElementPosition';
import AbilitySegment, {IAbilitySegmentProps, SegmentFilling} from 'Common/components/AbilityIndicator/AbilitySegment';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {AbilityState} from 'Common/constants/AbilityState';
import {SegmentPosition} from 'Common/constants/SegmentPosition';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {IShortAbility} from 'Dictionaries/models/IAbilities';

const IndicatorLayout = styled.div`
  margin-bottom: 10px;

  @media ${breakpoints.sm} {
    margin-bottom: 7px;
  }
`;

const IndicatorTitle = styled.div<IElementPosition>`
  font-size: ${Typography.size.size13};
  color: ${ColorPalette.gray3};

  overflow: hidden;
  text-overflow: ellipsis;

  ${prop => (prop.position === ElementPosition.Left ? `margin-right: auto;` : `margin-left: auto;`)};

  @media ${breakpoints.sm} {
    margin-right: 0;
    margin-left: 0;
  }
`;

const Segments = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
`;

const AlleleName = styled.div`
  color: ${ColorPalette.white2};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size13};
  min-width: 110px;
`;

const IndicatorTooltip = styled.div`
  display: none;

  @media ${breakpoints.sm} {
    display: block;
  }
`;

interface IProps {
  elementPosition: ElementPosition;
  title: string;
  ability?: IShortAbility;
}

const AbilityIndicator = (props: IProps) => {
  const {elementPosition, title, ability} = props;
  const {isTablet} = useMediaQuery();

  const titleOrder = elementPosition === ElementPosition.Left ? 'order-1' : 'order-3';
  const alleleOrder = elementPosition === ElementPosition.Left ? 'order-3' : 'order-1';
  const indicatorPosition = elementPosition === ElementPosition.Left ? 'justify-content-end' : 'justify-content-start';

  const segment1: IAbilitySegmentProps = React.useMemo(
    () => ({
      segmentPosition: SegmentPosition.Left,
      segmentFilled: ability?.state ? SegmentFilling.Filled : SegmentFilling.EmptyDark
    }),
    [ability]
  );

  const segment2: IAbilitySegmentProps = React.useMemo(
    () => ({
      segmentPosition: SegmentPosition.Center,
      segmentFilled:
        ability?.state === AbilityState.Medium || ability?.state === AbilityState.High
          ? SegmentFilling.Filled
          : SegmentFilling.EmptyDark
    }),
    [ability]
  );

  const segment3: IAbilitySegmentProps = React.useMemo(() => {
    return {
      segmentPosition: SegmentPosition.Right,
      segmentFilled: ability?.state === AbilityState.High ? SegmentFilling.Filled : SegmentFilling.EmptyDark
    };
  }, [ability]);

  return (
    <IndicatorLayout className={classNames('d-flex', 'align-items-center', indicatorPosition)}>
      <IndicatorTitle position={elementPosition} className={titleOrder}>
        {title}
      </IndicatorTitle>
      <IndicatorTooltip className="order-2">
        <Tooltip content={ability?.description}>
          <Segments>
            <AbilitySegment
              {...segment1}
              style={isTablet ? {marginRight: 0} : {marginRight: -2}}
              additionalSectionProps={{width: isTablet ? 40 : 85}}
              isTablet={isTablet}
            />
            <AbilitySegment
              {...segment2}
              style={{marginLeft: -2, marginRight: -2}}
              additionalSectionProps={{width: isTablet ? 40 : 85}}
              isTablet={isTablet}
            />
            <AbilitySegment
              {...segment3}
              style={isTablet ? {marginLeft: -1} : {marginLeft: -2}}
              additionalSectionProps={{width: isTablet ? 40 : 85}}
              isTablet={isTablet}
            />
          </Segments>
        </Tooltip>
      </IndicatorTooltip>
      <Tooltip content={ability?.description}>
        <AlleleName className={classNames('d-flex', indicatorPosition, alleleOrder)}>{ability?.name}</AlleleName>
      </Tooltip>
    </IndicatorLayout>
  );
};

export default AbilityIndicator;
