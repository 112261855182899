import {IUpdateHorseDetailsBP} from 'BusinessPortal/models/horse/IHorseDetailsBP';
import {convertToServerDate, convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {IUserHorse, IUserHorseOrder} from 'Horse/models/IUserHorse';
import {IServerUserHorse} from 'Horse/services/types/horse';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {parseOrderStatus} from 'Order/helpers/parseOrderStatus';
import {convertPriceToClient} from 'Common/helpers/convertPrices';
import {IServerHorseBP, IServerUserHorseOrderShort} from 'BusinessPortal/services/types/horse';
import {withAvatar} from 'Common/helpers/withAvatar';
import {IHorseBP} from 'BusinessPortal/models/horse/IHorseBP';
import {withUserName} from 'Common/helpers/withUserName';

export function convertHorseLastOrderToClient(horse: IServerUserHorseOrderShort): IUserHorseOrder {
  return {
    ...horse,
    ...parseOrderStatus(horse.status),
    packages: horse.packagesAndTests,
    createDate: '',
    amount: 0,
    reports: [],
    token: '',
    hasNotResults: false,
  };
}

export function convertServerUserHorse(horse: IServerUserHorse): IUserHorse {
  return {
    ...withAvatar(withBirthDate(horse)),
    order: horse.order
      ? {
          ...parseOrderStatus(horse.order),
          id: horse.order.id,
          createDate: convertUTCToClientDate(horse.order.createDate) || '',
          amount: convertPriceToClient(horse.order.amount),
          packages: horse.order.packages,
          reports: horse.order.reports,
          token: horse.order.token,
        }
      : null,
  };
}

export const convertHorseToServer = (horse: IUpdateHorseDetailsBP): IUpdateHorseDetailsBP => {
  return {
    ...horse,
    dateOfBirth: convertToServerDate(horse.dateOfBirth),
  };
};

export function convertHorsesToClient(horse: IServerHorseBP): IHorseBP {
  return {...withAvatar(horse), owner: {...withUserName(withAvatar(horse.owner))}};
}
