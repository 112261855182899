import * as R from 'ramda';
import {IMediaResource} from 'Common/models/IMediaResource';

type DeleteFromGallery = (horseId: number, imageIds: number[], isAdmin?: boolean) => void | Promise<void>;

export async function deleteImagesFromGallery(
  horseId: number,
  originGallery: IMediaResource[],
  newGallery: IMediaResource[],
  deleteFromGallery: DeleteFromGallery,
  isAdmin?: boolean
) {
  const imagesForRemoving = R.differenceWith((a, b) => a.id === b.id, originGallery, newGallery);

  if (imagesForRemoving.length === 0) {
    return;
  }

  await deleteFromGallery(
    horseId,
    imagesForRemoving.map(image => image.id),
    isAdmin
  );
}
