import ColorPalette from './ColorPalette';
import Typography from './Typography';

export default {
  color: {
    primary: ColorPalette.red7,
    white: ColorPalette.white0,
    black: ColorPalette.black1,
    gray: ColorPalette.gray44,

    control: {
      bg: ColorPalette.red7,
      hover: ColorPalette.red11,
      active: '#801C2E',
      disabled: '#E3E4E5',
      shadow: `rgba(179, 57, 57, 0.25)`
    }
  },
  font: {
    /** Ubuntu */
    primary: Typography.family.ubuntu,
    /** Source Sans Pro */
    secondary: Typography.family.sourceSansPro
  }
};
