import {combineReducers} from 'redux';

import {
  IFacetFilterState,
  Actions,
  getFilterPropertyOperationsActionTypes,
  getPackagesFiltersActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationFacetFilterReducer = combineReducers<IFacetFilterState['communications'], Actions>({
  filterPropertyOperationsLoading: makeCommunicationReducerFromEnum(getFilterPropertyOperationsActionTypes),
  packageFiltersLoading: makeCommunicationReducerFromEnum(getPackagesFiltersActionTypes),
});
