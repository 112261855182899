import {ICommunication} from 'Common/store/utils/communication';
import {usePrevious} from './usePrevious';

export function useOnErrorCommunication<E>(
  communication: ICommunication<E>,
  onError: (error?: ICommunication<E>['error']) => void
) {
  const prevCommunication = usePrevious<ICommunication<E>>(communication);

  if (prevCommunication?.isRequesting && !communication.isRequesting && !communication.isSuccess) {
    setTimeout(() => onError(communication.error), 0); // setTimeout is needed because useEffect executes after render
  }
}
