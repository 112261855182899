import {IApiParamsConverterByMethod} from 'DictionaryFactory/types';

export const baseApiParamsConverterByMethod: IApiParamsConverterByMethod = {
  get: params => ({params}),
  post: fields => ({items: [fields]}),
  put: fields => ({items: [fields]}),
  delete: id => ({data: [id]})
};

export const apiParamsConverterByMethod: IApiParamsConverterByMethod = {
  get: params => ({params}),
  post: fields => fields,
  put: fields => fields,
  delete: id => ({data: [id]})
};
