import {IModule} from 'redux-dynamic-modules';
import {IAuthState} from './types';
import {authReducer} from './reducer';

interface IAuthModule {
  auth: IAuthState;
}

export const AuthModule: IModule<IAuthModule> = {
  id: 'auth',
  reducerMap: {
    auth: authReducer
  }
};
