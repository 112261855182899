import {Reducer} from 'redux';

import {
  IAdminHorseTransferState,
  Actions,
  getHorseTransfersActionTypes,
  getHorseTransferDetailsActionTypes,
  resetHorseTransferDetailsActionTypes,
} from '../types';

const initialState: IAdminHorseTransferState['data'] = {
  horseTransfers: [],
  horseTransferDetails: null,
};

export const dataHorseTransferReducer: Reducer<IAdminHorseTransferState['data'], Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case getHorseTransfersActionTypes.SUCCESS: {
      return {...state, horseTransfers: action.payload.data};
    }
    case getHorseTransferDetailsActionTypes.SUCCESS: {
      return {...state, horseTransferDetails: action.payload};
    }

    case resetHorseTransferDetailsActionTypes.RESET: {
      return {...state, horseTransferDetails: initialState.horseTransferDetails};
    }

    default: {
      return state;
    }
  }
};
