import React, {memo} from 'react';
import styled from 'styled-components';
import {goBack} from 'connected-react-router';
import {connect, ConnectedProps} from 'react-redux';

import Typography from 'Common/constants/Typography';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import classNames from 'classnames';
import {zIndex} from 'Common/constants/ZIndex';
import Theme from 'Common/constants/Theme';

const BackButtonComponent = styled.div<{isDarkBackground?: boolean}>`
  cursor: pointer;
  color: ${(props) => (props.isDarkBackground ? Theme.color.white : Theme.color.black)};
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-size: ${Typography.size.size14};
  font-weight: ${Typography.weight.medium500};
  stroke-width: 0;
  margin-left: 20px;
  z-index: ${zIndex.backButton};
  :hover * {
    color: ${Theme.color.primary} !important;
  }
  :active * {
    color: ${Theme.color.control.active} !important;
    fill: ${Theme.color.control.active} !important;
  }

  @media print {
    display: none !important;
  }
`;

interface IProps {
  isDarkBackground?: boolean;
  isShowLabel?: boolean;
  onClick?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IProps & React.InputHTMLAttributes<HTMLElement>;

const BackButton = (props: AllProps) => {
  const {goBack, onClick, className, style, isDarkBackground, isShowLabel = true} = props;

  return (
    <BackButtonComponent
      onClick={onClick || goBack}
      className={classNames('d-flex', 'align-items-center', className)}
      style={style}
      isDarkBackground={isDarkBackground}
    >
      <ColoredIcon name={IconName.Back} size={14} color={Theme.color.primary} fill={true} style={{marginRight: 5}} />
      {isShowLabel && <span>Back</span>}
    </BackButtonComponent>
  );
};

const mapDispatchToProps = {
  goBack,
};

const connector = connect(null, mapDispatchToProps);
export default memo(connector(BackButton));
