import {Form, FormikProps, withFormik} from 'formik';
import {memo, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {DynamicModuleLoader} from 'redux-dynamic-modules';
import {ThunkDispatch} from 'redux-thunk';
import styled from 'styled-components';

import FileUploaderField from 'Common/components/FormFields/FileUploaderField';
import {CheckboxField, SelectField} from 'Common/components/FormFields/index';
import {ModalWindowButton, ModalWindowFooter, ModalWindowHeader} from 'Common/components/Modal/shared';
import {ErrorMessage, FieldHint} from 'Common/components/StyledComponents/StyledComponents';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {castToOption} from 'Common/helpers/OptionHelper';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import {IAppState} from 'Common/store/IAppState';
import {IReportType} from 'Dictionaries/models/IReportType';
import Loading from 'Loading/components/Loading';
import {convertFormValues} from './converters';
import {IDispatch, IState, uploadReportFormDistributor} from './distributor';
import {IFormValues, initialValue, validationSchema} from './validation';

const FormLayout = styled.div`
  margin: 24px 24px 24px 40px;
`;

const HorseOwner = styled.div`
  font-family: ${Typography.family.openSans};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size18};
  line-height: 25px;
`;

const HorseOwnerLabel = styled(HorseOwner)`
  color: ${ColorPalette.gray18};
`;

const HorseOwnerValue = styled(HorseOwner)`
  margin-top: 8px;
  color: ${ColorPalette.black1};
`;

const HorseOwnerLayout = styled.div`
  margin-bottom: 16px;
`;

const HorseLabelLayout = styled.div`
  margin-right: 175px;
`;

const UploadReportField = styled.div`
  display: grid;
  grid-template-columns: auto 224px;
  grid-gap: 16px;
`;

const SendReportField = styled.div`
  margin-top: 28px;
`;

const ReportTypeWrapper = styled.div`
  margin-top: 29px;
`;
interface IExternalProps {
  horseId: number;
  orderId: number;
  horseName: string;
  ownerName: string;
  selectedStrategy: SelectedStrategy;
  onSuccess(): void;
}

type IConnected = IState & IDispatch;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const UploadReportFormLayout = (props: AllProps) => {
  const {horseName, ownerName} = props;
  const {horseTestResultUploading, onSuccess} = props;
  const {status, isSubmitting, setStatus, setErrors} = props;

  const errorInfo = horseTestResultUploading.error;

  useOnSuccessCommunication(horseTestResultUploading, onSuccess);

  useEffect(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    const commonErrors = getCommonErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }

    if (commonErrors) {
      setStatus(commonErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  const isRequesting = horseTestResultUploading?.isRequesting;
  const isValid = props.submitCount === 0 || props.isValid;

  const reportTypes: IReportType[] = [{id: 1, name: 'Parentage'}];
  const options = castToOption(reportTypes);

  return (
    <>
      <ModalWindowHeader>Upload report</ModalWindowHeader>
      {isRequesting && <Loading />}
      <Form className="d-flex flex-column justify-content-center">
        <FormLayout>
          <HorseOwnerLayout className="d-flex">
            <HorseLabelLayout className="d-flex flex-column">
              <HorseOwnerLabel>Horse</HorseOwnerLabel>
              <HorseOwnerValue>{horseName}</HorseOwnerValue>
            </HorseLabelLayout>
            <div className="d-flex flex-column">
              <HorseOwnerLabel>Owner</HorseOwnerLabel>
              <HorseOwnerValue>{ownerName}</HorseOwnerValue>
            </div>
          </HorseOwnerLayout>
          <UploadReportField>
            <FileUploaderField
              isRequired={true}
              acceptableTypes=".pdf"
              name="uploadedFile"
              label="Test results"
              placeholder="Select a file to upload"
            />
            <ReportTypeWrapper>
              <SelectField name="reportType" options={options} />
            </ReportTypeWrapper>
          </UploadReportField>
          <SendReportField>
            <CheckboxField name="isSendReport" label="Send report" style={{marginBottom: 8}} />
            <FieldHint>
              Enabling this option, will allow you to share your report status with the customer via email.
            </FieldHint>
          </SendReportField>
        </FormLayout>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" disabled={!isValid} isLoading={isSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const UploadReportFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({horseId, orderId}: OuterProps) => ({...initialValue, horseId, orderId}),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {horseId, orderId, uploadReport} = formikBag.props;
    await uploadReport(convertFormValues(values, horseId, orderId));
  },
  enableReinitialize: true,
})(UploadReportFormLayout);

const mapStateToProps = (state: IAppState, externalProps: IExternalProps) =>
  uploadReportFormDistributor[externalProps.selectedStrategy].state;

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppState, undefined, any>, externalProps: IExternalProps) =>
  bindActionCreators(uploadReportFormDistributor[externalProps.selectedStrategy].dispatch as any, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(UploadReportFormFormik) as unknown as (props: IExternalProps) => JSX.Element;

const Exported = (externalProps: IExternalProps) => {
  const currentStrategy = externalProps.selectedStrategy;

  const modules = uploadReportFormDistributor[currentStrategy].modules || [];

  return (
    <DynamicModuleLoader modules={[modules]}>
      <Connected {...externalProps} selectedStrategy={currentStrategy} />
    </DynamicModuleLoader>
  );
};

export default memo(Exported);
