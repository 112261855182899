import {combineReducers} from 'redux';

import {IAdminOrganizationState} from '../types';
import {dataAdminOrganizationsReducer} from './data';
import {communicationAdminOrganizationsReducer} from './communications';
import {uiAdminOrganizationsReducer} from './ui';

export const administratorsReducer = combineReducers<IAdminOrganizationState>({
  communications: communicationAdminOrganizationsReducer,
  data: dataAdminOrganizationsReducer,
  ui: uiAdminOrganizationsReducer,
});
