import {combineReducers, Reducer} from 'redux';

import {IBuildFoalState} from '../types';
import {communicationBuildFoalReducer} from './communications';
import {dataBuildFoalReducer} from './data';

export const buildFoalReducer: Reducer<IBuildFoalState> = combineReducers<IBuildFoalState>({
  communications: communicationBuildFoalReducer,
  data: dataBuildFoalReducer
});
