import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IPaginationRequest} from 'Common/models/IPaginationRequest';
import {
  changeAdministratorStatusActions,
  changeAdministratorStatusActionTypes,
  createAdministratorActions,
  createAdministratorActionTypes,
  deleteAdministratorAvatarActions,
  deleteAdministratorAvatarActionTypes,
  getAdministratorActions,
  getAdministratorActionTypes,
  getAdministratorAvatarActions,
  getAdministratorAvatarActionTypes,
  getAdministratorsActions,
  getAdministratorsActionTypes,
  resetAdministratorActions,
  resetAdministratorActionTypes,
  setAdministratorPasswordActions,
  setAdministratorPasswordActionTypes,
  updateAdministratorActions,
  updateAdministratorActionTypes,
  uploadAdministratorAvatarActions,
  uploadAdministratorAvatarActionTypes,
} from './types';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import AdministratorDataService from 'Admin/AdminAdvancedDashboard/services/AdministratorDataService';
import {
  ICreateAdministrator,
  IUpdateAdministrator,
  IUpdateAdministratorPassword,
} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdministrator';
import {IEntity} from 'Common/models/IEntity';

export const getAdministrators =
  (params?: IPaginationRequest): ActionResult<IAppState, void, getAdministratorsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdministratorsActionTypes.REQUEST));
      const result = await AdministratorDataService.getAdministrators(params);
      dispatch(action(getAdministratorsActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getAdministratorsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAdministrator =
  (id: number): ActionResult<IAppState, void, getAdministratorActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdministratorActionTypes.REQUEST));
      const result = await AdministratorDataService.getAdministrator(id);
      dispatch(action(getAdministratorActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getAdministratorActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createAdministrator =
  (data: ICreateAdministrator): ActionResult<IAppState, Promise<IEntity>, createAdministratorActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createAdministratorActionTypes.REQUEST));
      const res = await AdministratorDataService.createAdministrator(data);
      dispatch(action(createAdministratorActionTypes.SUCCESS));
      return res;
    } catch (error) {
      const err = getErrorMessage(error);
      dispatch(action(createAdministratorActionTypes.FAILURE, err));
      throw err;
    }
  };

export const updateAdministrator =
  (data: IUpdateAdministrator): ActionResult<IAppState, void, updateAdministratorActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdministratorActionTypes.REQUEST));
      await AdministratorDataService.updateAdministrator(data);
      dispatch(action(updateAdministratorActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateAdministratorActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAdministratorAvatar =
  (id: number): ActionResult<IAppState, void, getAdministratorAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdministratorAvatarActionTypes.REQUEST));
      const result = await AdministratorDataService.getAdministratorAvatar(id);
      dispatch(action(getAdministratorAvatarActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getAdministratorAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const uploadAdministratorAvatar =
  (adminId: number, uploadedFile: File): ActionResult<IAppState, void, uploadAdministratorAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadAdministratorAvatarActionTypes.REQUEST));
      await AdministratorDataService.uploadAdministratorAvatar(adminId, uploadedFile);
      dispatch(action(uploadAdministratorAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(uploadAdministratorAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteAdministratorAvatar =
  (id: number): ActionResult<IAppState, void, deleteAdministratorAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteAdministratorAvatarActionTypes.REQUEST));
      const result = await AdministratorDataService.deleteAdministratorAvatar(id);
      dispatch(action(deleteAdministratorAvatarActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(deleteAdministratorAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setAdministratorPassword =
  (data: IUpdateAdministratorPassword): ActionResult<IAppState, void, setAdministratorPasswordActions> =>
  async (dispatch) => {
    try {
      dispatch(action(setAdministratorPasswordActionTypes.REQUEST));
      const result = await AdministratorDataService.setAdministratorPassword(data);
      dispatch(action(setAdministratorPasswordActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(setAdministratorPasswordActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const changeAdministratorStatus =
  (id: number, isActive: boolean): ActionResult<IAppState, void, changeAdministratorStatusActions> =>
  async (dispatch) => {
    try {
      dispatch(action(changeAdministratorStatusActionTypes.REQUEST));
      await AdministratorDataService.changeAdministratorStatus(id, isActive);
      dispatch(action(changeAdministratorStatusActionTypes.SUCCESS, {adminId: id}));
    } catch (error) {
      dispatch(action(changeAdministratorStatusActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetAdministrator = (): ActionResult<IAppState, void, resetAdministratorActions> => async (dispatch) => {
  dispatch(action(resetAdministratorActionTypes.RESET));
};
