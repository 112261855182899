import React, {memo} from 'react';

import {OrderInfoHeaderProps} from 'Admin/AdminDashboard/components/Orders/OrderInfoModal/OrderInfoHeader/OrderInfoHeader/common/models';
import AvatarCellHorse from 'Admin/AdminDashboard/components/Orders/shared/AvatarCellHorse';
import {isAvailableForBah} from 'Common/helpers/isAvailableForBah';
import {HORSE_GRANTED_MARKED_ICON} from 'Common/constants/avatarMarkedIcon';

interface IProps {
  onEdit(): void;
}

type AllProps = IProps & OrderInfoHeaderProps;

const HorseName = (props: AllProps) => {
  const {horsesDetails, onEdit} = props;

  const {id: horseId, name: horseName, avatar, isArchived, isDeleted, availabilityCriteria} = horsesDetails[0];

  const orderHasSeveralHorses = horsesDetails.length > 1;

  if (orderHasSeveralHorses) {
    return <>{horsesDetails.length} Horses</>;
  }

  return (
    <AvatarCellHorse
      label={horseName}
      type="horse"
      profileUrl={`/admin/horse/${horseId}`}
      avatarUrl={avatar?.url}
      onLabelClick={onEdit}
      isArchived={isArchived}
      isDeleted={isDeleted}
      isMarkedIcon={isAvailableForBah(availabilityCriteria)}
      markedIcon={HORSE_GRANTED_MARKED_ICON}
      isGeneticDataHidden={false}
    />
  );
};

export default memo(HorseName);
