import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
} from 'Common/store/utils/communication/index';
import {IUserHorse} from 'Horse/models/IUserHorse';
import {IListResponse, IPagination} from 'Common/models/IResponse';

const STATE_NAME = 'favoriteHorse';

export interface IFavoriteHorseState {
  data: {
    favoriteHorses: IUserHorse[];
  };
  communications: {
    favoriteHorsesLoading: ICommunication;
    favoriteHorseAdding: ICommunication;
    favoriteHorseDeleting: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getFavoriteHorsesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_FAVORITE_HORSES');

export type getFavoriteHorsesActions = MakeCommunicationActions<
  typeof getFavoriteHorsesActionTypes,
  {success: IListResponse<IUserHorse>}
>;

export const addFavoriteHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'ADD_FAVORITE_HORSE');

export type addFavoriteHorseActions = MakeCommunicationActions<typeof addFavoriteHorseActionTypes, {success: number}>;

export const deleteFavoriteHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_FAVORITE_HORSES');

export type deleteFavoriteHorseActions = MakeCommunicationActions<
  typeof deleteFavoriteHorseActionTypes,
  {success: number}
>;

export type FavoriteHorsesActions = getFavoriteHorsesActions | addFavoriteHorseActions | deleteFavoriteHorseActions;
