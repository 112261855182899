import React, {memo} from 'react';
import styled from 'styled-components';

import GoToContainer from 'BusinessPortal/components/common/GoToContainer/GoToContainer';
import Theme from 'Common/constants/Theme';
import OwnerInfoSectionShow from 'BusinessPortal/components/BusinessPortalDashboard/shared/OwnerInfoSectionShow/OwnerInfoSectionShow';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {useOwnerFormSlidePanel} from 'BusinessPortal/helpers/hooks/useOwnerFormSlidePanel';
import NoData from 'BusinessPortal/components/common/NoData/NoData';
import GoToBack from 'BusinessPortal/components/common/GoToBack/GoToBack';
import {IHorseOwnerSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseOwnerSection/models/IHorseOwnerSectionData';
import {convertUserName} from 'Common/helpers/convertUserName';

const GoToWrapper = styled.div`
  width: 106%;
  margin-left: -16px;
  margin-bottom: 8px;
`;

const HorseOwnerSectionShow = (props: IEditableSection<IHorseOwnerSectionData>) => {
  const {data, onSuccess} = props;

  const {ownerFormSlidePanel, openOwnerFormSlidePanel} = useOwnerFormSlidePanel({
    shadow: false,
    transition: false,
    onSuccessForm: onSuccess,
    title: <GoToBack title={data?.horseName} />,
    closableTitle: true,
  });

  if (!data?.owner) {
    return <NoData />;
  }

  const {id, firstName, lastName, avatar} = data.owner;

  return (
    <>
      {ownerFormSlidePanel}
      <GoToWrapper>
        <GoToContainer
          name={convertUserName(firstName, lastName)}
          avatar={avatar}
          onClick={() => openOwnerFormSlidePanel(id)}
          fixedIcon={false}
          hasShadow={false}
          iconProps={{color: Theme.color.black}}
        />
      </GoToWrapper>
      <OwnerInfoSectionShow {...props} />
    </>
  );
};

export default memo(HorseOwnerSectionShow);
