import {IModule} from 'redux-dynamic-modules';

import {IAdminAssociationOrderState} from './types';
import {ordersReducer} from './reducer/index';

export interface IAssociationOrdersModuleState {
  adminAssociationOrders: IAdminAssociationOrderState;
}

export const AdminAssociationOrdersModule: IModule<IAssociationOrdersModuleState> = {
  id: 'adminAssociationOrders',
  reducerMap: {
    adminAssociationOrders: ordersReducer,
  },
};
