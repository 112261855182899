import React, {useCallback} from 'react';
import {PaginationProps} from 'Common/components/Controls/Pagination/Pagination';
import styled from 'styled-components';
import {debounce} from 'lodash';

import ColorPalette from 'Common/constants/ColorPalette';
import {PaginationTextBase} from 'Common/components/Controls/Pagination/parts/common';
import Input from 'Common/components/Controls/Input';
import {isNonNumeric} from 'Common/helpers/isNonNumeric';

const Root = styled.div`
  input[type='number'].custom-control-input::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Label = styled(PaginationTextBase)`
  color: ${ColorPalette.gray54};
  margin-right: 16px;
`;

const InputField = styled(Input)`
  width: 48px;
  margin-bottom: 0;
`;

const SelectPageSection = (props: PaginationProps) => {
  const {
    onPageSelect,
    pagination: {pageCount},
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    debounce((page: string) => {
      onPageSelect(+page < pageCount ? +page : pageCount);
    }, 500),
    [pageCount, onPageSelect]
  );

  const changePageHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      delayedQuery(e.currentTarget.value);
    },
    [delayedQuery]
  );

  const validateSelectPageInput = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    const isFirstZero = e.currentTarget.value.length === 0 && e.key === '0';
    if (isNonNumeric(e) || isFirstZero) {
      e.preventDefault();
    }
  }, []);

  return (
    <Root className="d-flex justify-content-end align-items-center">
      <Label>Jump to</Label>
      <InputField onChange={changePageHandler} onKeyPress={validateSelectPageInput} />
    </Root>
  );
};

export default SelectPageSection;
