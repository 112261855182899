import React, {useCallback, useEffect, useMemo} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Form, FormikProps, withFormik} from 'formik';

import {IConnectedReduxProps} from 'Common/store/store';
import {actions, selectors} from 'UserProfile/store/currentUser/index';
import {ImageUploaderField, InputField, SelectField} from 'Common/components/FormFields/index';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {IAppState} from 'Common/store/IAppState';
import {getNumberKeysOption} from 'Common/helpers/OptionHelper';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import Loading from 'Loading/components/Loading';
import {Salutation} from 'Common/constants/Salutation';
import {IconName} from 'Icon/components/Icon';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {actions as imageActions} from 'Image/store/index';
import {ModalTypes} from 'Common/components/Modal/WarningModal';
import RouteLeavingGuard from 'Common/components/Modal/RouteLeavingGuard';
import {ErrorMessage, MutableField, MutableFieldsGroup} from 'Common/components/StyledComponents/StyledComponents';
import {initialValue, IUserPersonalDetailFormValues, validationSchema} from './validation';
import {Button, FormLayout, SettingsTitle} from '../styled';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useToast} from 'Common/helpers/hooks/useToast';
import UserMapField from 'UserProfile/components/shared/UserMapField/UserMapField';
import {
  withUserMapFieldPropsToClient,
  withUserMapFieldPropsToRequest,
} from 'UserProfile/components/shared/UserMapField/withUserMapFieldProps';

const salutations = getNumberKeysOption(Salutation).filter((item) => item.value !== Salutation[Salutation.Unknown]);

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnectedReduxProps & IConnected;

type AllProps = FormikProps<IUserPersonalDetailFormValues> & OuterProps;

function PersonalDetails(props: AllProps) {
  const {setErrors, status, dirty, isSubmitting} = props;
  const {getUser, currentUserLoading, userUpdating} = props;

  const userLoadingError = currentUserLoading?.error;
  const userUpdatingError = userUpdating?.error;

  const isLoading = currentUserLoading.isRequesting;

  useEffect(() => {
    getUser();
  }, [getUser]);

  const submitError = React.useMemo(() => {
    if (!status) {
      return null;
    }
    return getCommonErrors(status) || `Error: ${String(status)}`;
  }, [status]);

  const userUpdatingFormError = React.useMemo(() => {
    if (!userUpdatingError) {
      return null;
    }
    return getCommonErrors(userUpdatingError) || `Error: ${String(userUpdatingError)}`;
  }, [userUpdatingError]);

  const fieldErrors = useMemo(() => userUpdatingError && getFieldErrors(userUpdatingError), [userUpdatingError]);
  useEffect(() => {
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [fieldErrors, setErrors]);

  const {addToast} = useToast();
  const onSuccess = useCallback(() => {
    addToast('Changes have been successfully saved');
    getUser();
  }, [addToast, getUser]);
  useOnSuccessCommunication(userUpdating, onSuccess);

  const isValid = props.submitCount === 0 || props.isValid;
  const isDisableSubmit = !isValid || !dirty;

  return (
    <>
      <RouteLeavingGuard when={dirty} modalType={ModalTypes.Confirm}>
        All unsaved data will be lost. Are you sure you want to leave the page?
      </RouteLeavingGuard>

      <SettingsTitle>Edit Profile</SettingsTitle>

      <FormLayout className="d-flex flex-column position-relative">
        {isLoading && <Loading />}

        <Form>
          <FormControlContainer title="General information">
            <MutableFieldsGroup indent="16px" className="d-flex justify-content-between">
              <MutableField width={25} keepSize={true} style={{minWidth: 100}}>
                <SelectField
                  name="salutation"
                  label="Salutation"
                  placeholder="Salutation"
                  options={salutations}
                  isClearable={true}
                />
              </MutableField>
              <MutableField width={75}>
                <InputField name="firstName" label="First Name" placeholder="First Name" />
              </MutableField>
            </MutableFieldsGroup>
            <InputField name="lastName" label="Last Name" placeholder="Last Name" />
            <InputField type="email" name="email" label="Email" placeholder="Email" disabled={true} />
            <InputField name="phone" label="Phone Number" placeholder="Phone Number" />
            <InputField name="mobile" label="Mobile Number" placeholder="Mobile Number" />

            <FieldControlContainer label="Upload photo">
              <ImageUploaderField id="avatar" name="avatar" title="Add profile image" iconName={IconName.Person} />
            </FieldControlContainer>
          </FormControlContainer>

          <FormControlContainer title="Additional information" style={{marginBottom: 16}}>
            <UserMapField />
          </FormControlContainer>

          <div className="d-flex flex-column align-items-start">
            <Button type="submit" disabled={isDisableSubmit} isLoading={isSubmitting}>
              Save profile
            </Button>
            <ErrorMessage className="w-100 align-self-center">{submitError || userUpdatingFormError}</ErrorMessage>
          </div>
        </Form>

        {userLoadingError && <ErrorMessage>{getCommonErrors(userLoadingError)}</ErrorMessage>}
      </FormLayout>
    </>
  );
}

const PersonalDetailsFormik = withFormik<OuterProps, IUserPersonalDetailFormValues>({
  mapPropsToValues: ({currentUser: user}) => (user ? withUserMapFieldPropsToClient(user) : initialValue),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    try {
      formikBag.setSubmitting(true);

      const {id} = formikBag.props.currentUser!;

      const uploadAvatar = async (avatar: File) => {
        const formData = new FormData();
        formData.append('uploadedFile', avatar);
        return formikBag.props.uploadImage(formData);
      };

      if (values.avatar?.isLocal && values.avatar?.file) {
        values.avatar = await uploadAvatar(values.avatar.file);
      }

      await formikBag.props.updateUser(withUserMapFieldPropsToRequest(id, values));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
    } catch (error) {
      formikBag.setStatus(error);
      formikBag.setSubmitting(false);
    }
  },

  enableReinitialize: true,
})(PersonalDetails);

const mapStateToProps = (state: IAppState) => ({
  currentUser: selectors.selectCurrentUser(state),
  currentUserLoading: selectors.selectCommunication(state, 'currentUserLoading'),
  userUpdating: selectors.selectCommunication(state, 'userUpdating'),
});

const mapDispatchToProps = {
  getUser: actions.getCurrentUser,
  updateUser: actions.editUserInfo,
  uploadImage: imageActions.uploadImage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(PersonalDetailsFormik);
