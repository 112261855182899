import {combineReducers, Reducer} from 'redux';

import {IAdminPurchasableTestState} from '../types';
import {communicationAdminPurchasableTestsReducer} from './communications';
import {dataAdminPurchasableTestsReducer} from './data';
import {uiAdminPurchasableTestsReducer} from './ui';

export const adminPurchasableTestsReducer: Reducer<IAdminPurchasableTestState> =
  combineReducers<IAdminPurchasableTestState>({
    communications: communicationAdminPurchasableTestsReducer,
    data: dataAdminPurchasableTestsReducer,
    ui: uiAdminPurchasableTestsReducer,
  });
