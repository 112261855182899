import {combineReducers} from 'redux';

import {IHorseFiltersState} from '../types';
import {communicationGenePoolsReducer} from './communications';
import {dataGenePoolsReducer} from './data';

export const reducer = combineReducers<IHorseFiltersState>({
  communications: communicationGenePoolsReducer,
  data: dataGenePoolsReducer
});
