import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';

import UserOrders from 'UserProfile/components/TabsData/UserOrders/UserOrders';
import UserProfile, {UserProfileRouteProps} from 'Shared/components/UserProfile/UserProfile';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import UserFavoriteHorses from './TabsData/UserFavoriteHorses/UserFavoriteHorses';
import {ITab} from 'Common/components/Tabs/ITab';
import UserExcludeHorses from 'UserProfile/components/TabsData/UserExcludeHorses/UserExcludeHorses';
import {IUserProfileSectionProps} from 'Shared/models/IUserProfileSectionProps';
import {VisitorType} from 'Common/constants/VisitorType';
import {IAppState} from 'Common/store/IAppState';
import {actions as currentUserActions, selectors as currentUserSelectors} from 'UserProfile/store/currentUser';
import {UserProfileType} from 'Shared/models/UserProfileType';
import {PREFIX_USER_PROFILE_TYPE} from 'Common/components/Navigation';

const ownProfileTabs: ITab<IUserProfileSectionProps>[] = [
  {section: 'tests', title: 'Tests', Component: UserOrders},
  {section: 'favorites', title: 'Favorites', Component: UserFavoriteHorses},
  {section: 'excluded', title: 'Excluded', Component: UserExcludeHorses},
];

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & RouteComponentProps<{id: string}> & UserProfileRouteProps;

function UserProfileLayout(props: Props) {
  const {
    currentUser,
    match: {
      params: {id, userProfileType},
    },
  } = props;

  const userId = Number(id);
  const isOwnProfile = currentUser?.id === userId;
  const tabs = isOwnProfile ? ownProfileTabs : [];
  const history = useHistory();

  // If old link is opened, then replace the link: /user/111 -> /user/user-profile/111
  useEffect(() => {
    if (userProfileType !== PREFIX_USER_PROFILE_TYPE[UserProfileType.User] && !isNaN(+userProfileType)) {
      history.replace(`/user/user-profile/${userProfileType}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseLayout withoutPaddings={true} backButtonStyle={{marginTop: 20}}>
      <UserProfile
        additionalTabs={tabs}
        visitorType={VisitorType.User}
        userProfileType={userProfileType as UserProfileType}
      />
    </BaseLayout>
  );
}

const mapStateToProps = (state: IAppState) => ({
  currentUser: currentUserSelectors.selectCurrentUser(state),
  currentUserLoading: currentUserSelectors.selectCommunication(state, 'userLoading'),
});

const mapDispatchToProps = {
  getCurrentUser: currentUserActions.getCurrentUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = withRouter(connector(memo(UserProfileLayout)));
export default Connected;
