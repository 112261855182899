import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.stripeSubscription;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectDefaultPaymentMethod(state: IAppState) {
  return selectState(state).data.defaultPaymentMethod;
}

export function selectCreatedSubscription(state: IAppState) {
  return selectState(state).data.createdSubscription;
}

export function selectUserSubscriptions(state: IAppState) {
  return selectState(state).data.userSubscriptions;
}

export function selectUserScheduledSubscriptions(state: IAppState) {
  return selectState(state).data.userScheduledSubscription;
}
