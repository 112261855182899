import React, {memo, useEffect, useState} from 'react';
import styled from 'styled-components';

import {breakpoints} from 'Common/constants/Breakpoints';
import {IMediaResource, MediaType} from 'Common/models/IMediaResource';
import {isImageExists} from 'Common/helpers/ImagesHelper';
import Icon, {IconName} from 'Icon/components/Icon';
import {getYouTubeThumbnailUrl} from 'Common/helpers/getYouTubeThumbnailUrl';
import ColorPalette from 'Common/constants/ColorPalette';
import {hexToRgba} from 'Common/helpers/hexToRgba';

const BorderWrapper = styled.div`
  border: 1px ${ColorPalette.gray49} solid;
  border-radius: 5px;
  min-width: 99px;
`;

const Image = styled(BorderWrapper)<{url: string; isSelected?: boolean}>`
  width: 100%;
  padding-top: 100%;
  @media ${breakpoints.sm} {
    padding-top: 75%;
  }
  border-radius: 5px;
  background-image: url(${({url}) => `'${url}'`});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${({isSelected}) =>
    isSelected
      ? `
  opacity: 0.5;
  `
      : `
  cursor: pointer;
  `};
`;
const ThumbnailPlayIcon = styled.div`
  z-index: 2;
  width: 26px;
  height: 26px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${hexToRgba(ColorPalette.black1, 0.3)};
  border-radius: 50%;
`;

interface IProps {
  media: IMediaResource;
  onSelect(url: string): void;
  selectedUrl: string | null;
}

function GalleryImage(props: IProps) {
  const {media, onSelect, selectedUrl} = props;
  const [isUrlExists, setIsUrlExists] = useState(true);

  const mediaUrl = media.type === MediaType.Image ? media.url : getYouTubeThumbnailUrl(media.url);

  useEffect(() => {
    let isMounted = true;

    if (mediaUrl) {
      isImageExists(mediaUrl)
        .then(() => {
          if (isMounted) {
            setIsUrlExists(true);
          }
        })
        .catch(() => {
          if (isMounted) {
            setIsUrlExists(false);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [mediaUrl]);

  if (!isUrlExists) {
    return (
      <BorderWrapper className="d-flex w-100 h-100 position-absolute justify-content-center align-items-center">
        <Icon name={IconName.BrokenImage} size={24} />
      </BorderWrapper>
    );
  }

  return (
    <Image
      className="d-flex flex-column justify-content-center align-items-center position-relative"
      onClick={() => onSelect(media.url)}
      url={String(mediaUrl)}
      isSelected={selectedUrl === media.url}
    >
      {media.type === MediaType.Video && (
        <ThumbnailPlayIcon className="position-absolute d-flex justify-content-center align-items-center">
          <Icon name={IconName.PlayIconPreview} size={24} />
        </ThumbnailPlayIcon>
      )}
    </Image>
  );
}

export default memo(GalleryImage);
