import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {Nullable} from 'Common/types';
import {IHorseTransferDetails} from 'Horse/models/IHorseTransfer';

export function convertHorseTranserDetailsToClient(
  horseTranserDetails: Nullable<IHorseTransferDetails>
): Nullable<IHorseTransferDetails> {
  return horseTranserDetails
    ? {...horseTranserDetails, createDate: convertUTCToClientDate(horseTranserDetails.createDate) || ''}
    : null;
}
