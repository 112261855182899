import {combineReducers} from 'redux';

import {IBusinessPortalOwnerState} from '../types';
import {communicationBusinessPortalOwnerReducer} from './communications';
import {dataBusinessPortalOwnerReducer} from './data';
import {uiBusinessPortalOwnerReducer} from './ui';

export const businessPortalOwnerReducer = combineReducers<IBusinessPortalOwnerState>({
  communications: communicationBusinessPortalOwnerReducer,
  data: dataBusinessPortalOwnerReducer,
  ui: uiBusinessPortalOwnerReducer
});
