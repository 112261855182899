import React, {memo} from 'react';
import styled from 'styled-components';

import {PlaceholderTitle, PlaceholderDescription} from '../shared/StyledComponents';
import {ReactComponent as Image} from './img/placeholder2.svg';
import {AnimatedInitiate} from 'Common/components/StyledComponents/StyledComponents';

const ImageWrapper = styled(Image)`
  top: 120px;
`;

function PlaceHolder2() {
  return (
    <AnimatedInitiate className="d-flex w-100 flex-column justify-content-end align-items-center position-relative">
      <ImageWrapper className="w-100 h-100 position-absolute" />
      <PlaceholderTitle>Build your dream horse!</PlaceholderTitle>
      <PlaceholderDescription maxWidth={290}>
        Match the perfect sire and dam to build or find your dream horse.
      </PlaceholderDescription>
    </AnimatedInitiate>
  );
}

export default memo(PlaceHolder2);
