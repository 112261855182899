import {IModule} from 'redux-dynamic-modules';

import {horseReducer} from './reducer';
import {IHorseState} from './types';

export interface IHorseModuleState {
  horse: IHorseState;
}

export const HorseModule: IModule<IHorseModuleState> = {
  id: 'horse',
  reducerMap: {
    horse: horseReducer
  }
};
