import {IModule} from 'redux-dynamic-modules';

import {IOrderPaymentState} from './types';
import {orderPaymentReducer} from './reducer';

export interface IOrderPaymentModuleState {
  orderPayment: IOrderPaymentState;
}

export const OrderPaymentModule: IModule<IOrderPaymentModuleState> = {
  id: 'orderPayment',
  reducerMap: {
    orderPayment: orderPaymentReducer
  }
};
