import {combineReducers, Reducer} from 'redux';

import {IBuildHorseState} from '../types';
import {communicationBuildHorseReducer} from './communications';
import {dataBuildHorseReducer} from './data';
import {uiBuildHorseReducer} from './ui';

export const reducer: Reducer<IBuildHorseState> = combineReducers<IBuildHorseState>({
  communications: communicationBuildHorseReducer,
  data: dataBuildHorseReducer,
  ui: uiBuildHorseReducer
});
