import React, {memo} from 'react';
import styled from 'styled-components';
import {IProbabilityEntity} from 'Common/models/IProbabilityEntity';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Tooltip, {TooltipProps} from 'Common/components/Tooltip/Tooltip';
import getProbabilityColor from 'Common/helpers/getProbabilityColor';

const ProbabilityItemLayout = styled.div`
  display: grid;
  grid-template-columns: 36px auto;
  column-gap: 12px;
  width: 100%;
  :focus {
    outline: none;
  }
`;

const DetailsPriorityProbability = styled.div<{color?: string; alignValueRight?: boolean}>`
  font-size: ${Typography.size.size13};
  font-weight: ${Typography.weight.medium500};
  line-height: 24px;
  text-align: ${p => (p.alignValueRight ? 'right' : 'unset')};
  color: ${p => p.color || 'inherit'};
`;

const ProbabilityName = styled.div<{isDanger?: boolean}>`
  font-size: ${Typography.size.size13};
  line-height: 24px;
  color: ${p => (p.isDanger ? ColorPalette.red1 : ColorPalette.gray3)};
  color: ${ColorPalette.black1};
`;

function renderProbability(prob: number, higherIsBetter?: boolean, alignValueRight?: boolean) {
  const probValue = `${prob}%`;

  const color = getProbabilityColor(prob, higherIsBetter);

  return (
    <DetailsPriorityProbability color={color} alignValueRight={alignValueRight}>
      {probValue}
    </DetailsPriorityProbability>
  );
}

export interface IProps {
  probability: IProbabilityEntity;
  higherIsBetter: boolean;
  showTooltip?: boolean;
  alignValueRight?: boolean;
  tooltipConfig?: TooltipProps;
}

const ProbabilityItem = (props: IProps) => {
  const {probability, higherIsBetter, alignValueRight, showTooltip, tooltipConfig} = props;

  return (
    <Tooltip {...tooltipConfig} content={showTooltip ? probability.description : ''}>
      <ProbabilityItemLayout>
        {/* If probability is not exist then set it to 100% */}
        {renderProbability(probability.probability || 100, higherIsBetter, alignValueRight)}
        <ProbabilityName isDanger={probability.isDanger}>{probability.name}</ProbabilityName>
      </ProbabilityItemLayout>
    </Tooltip>
  );
};

ProbabilityItem.defaultProps = {
  showTooltip: true
};

export default memo(ProbabilityItem);
