import React, {memo, useEffect} from 'react';
import styled from 'styled-components';
import {Form, FormikBag, FormikProps, withFormik} from 'formik';
import {bindActionCreators} from 'redux';
import {connect, ConnectedProps} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';

import Nebula from 'Common/components/Layout/Nebula';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {IAppState} from 'Common/store/IAppState';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {CheckboxField} from 'Common/components/FormFields';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Loading from 'Loading/components/Loading';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import {OnlineReportActions} from 'OnlineReport/store';
import {AdminOnlineReportActions} from 'Admin/AdminDashboard/store/adminOnlineReport';
import PrintSelectionCheckboxGroup from './PrintSelectionCheckboxGroup';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';

import {getValidationScheme, IFormValues} from './validation';
import {printSelectionFormDistributor} from './distributor';
import {formikPropsToValues} from './converters';
import {useOnlineReportErrors} from 'OnlineReport/hooks/useOnlineReportErrors';

const onlineReportErrorStyle: React.CSSProperties = {fontSize: '14px', lineHeight: '16px', color: ColorPalette.red0};

const FormWrapper = styled.div`
  max-height: 500px;
`;

export const FormHint = styled.p`
  color: ${ColorPalette.gray3};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  padding-bottom: 16px;
`;

export const PaddedCheckbox = styled(CheckboxField)`
  margin-left: 8px;
`;

interface IExternalProps {
  horseId: number;
  reportType: OnlineReportType;
  isLoading?: boolean;
  onSubmit(fields: IFormValues): Promise<void>;
  onSuccess?(fields: IFormValues): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const PrintSelectionFormik = (props: AllProps) => {
  const {
    isSubmitting,
    horseId,
    getAllTraits,
    getPrintableReportPreferences,
    coatColors,
    healthVariants,
    abilities,
    loading,
    coatColorLoading,
    healthVariantsLoading,
    abilitiesLoading,
  } = props;

  // TODO: adding `getAllTraits` or `getPrintableReportPreferences` in dependencies causes
  // infinite render glitch. not sure why but maybe something to do with the distributor?
  // @ts-ignore
  useEffect(() => {
    getAllTraits(+horseId);
    getPrintableReportPreferences();
  }, [horseId]); // eslint-disable-line react-hooks/exhaustive-deps

  const isFetchingData = loading.isRequesting as boolean;

  const error = loading.error;

  const {isOnlineReportError: isOnlineReportErrorCoatColor, onlineReportErrors: onlineReportErrorsCoatColor} =
    useOnlineReportErrors({
      horseId: +horseId,
      error: coatColorLoading.error,
      messageStyle: onlineReportErrorStyle,
    });

  const {isOnlineReportError: isOnlineReportErrorHealth, onlineReportErrors: onlineReportErrorsHealth} =
    useOnlineReportErrors({
      horseId: +horseId,
      error: healthVariantsLoading.error,
      messageStyle: onlineReportErrorStyle,
    });

  const {isOnlineReportError: isOnlineReportErrorAbility, onlineReportErrors: onlineReportErrorsAbility} =
    useOnlineReportErrors({
      horseId: +horseId,
      error: abilitiesLoading.error,
      messageStyle: onlineReportErrorStyle,
    });

  return (
    <>
      <ModalWindowHeader>Customize printed report</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          <FormWrapper>
            {isFetchingData && <Loading />}
            <FormHint>
              Choose which parts of your horse's report you would like to make visible on the printed report. Expand a
              section to change the visibility for individual traits. This will only temporarily affect the printed
              report format.
            </FormHint>
            <Nebula active={isFetchingData || isSubmitting}>
              <PrintSelectionCheckboxGroup label="Summary">
                <PaddedCheckbox name={`summary.variantSummary.isVisible`} label={'Variant summary'} />
                <PaddedCheckbox name={`summary.abilities.isVisible`} label={'Performance and Abilities'} />
                <PaddedCheckbox name={`summary.coatColors.isVisible`} label={'Coat color'} />
                <PaddedCheckbox name={`summary.healthVariants.isVisible`} label={'Health Variants'} />
              </PrintSelectionCheckboxGroup>
              <PrintSelectionCheckboxGroup
                label={
                  isOnlineReportErrorCoatColor ? (
                    <div className="d-flex">Coat color:&nbsp; {onlineReportErrorsCoatColor}</div>
                  ) : (
                    'Coat color'
                  )
                }
              >
                {coatColors
                  ?.filter((group) => group.partialColors.length > 0)
                  .map((group, i) => (
                    <PrintSelectionCheckboxGroup key={i} label={group.name}>
                      {group.partialColors
                        .filter((group) => group.traits.length > 0)
                        .map((group, i) => (
                          <PaddedCheckbox
                            name={`partialColors.traits.${group.id.toString()}.isVisible`}
                            label={group.name}
                          />
                        ))}
                    </PrintSelectionCheckboxGroup>
                  ))}
              </PrintSelectionCheckboxGroup>
              <PrintSelectionCheckboxGroup
                label={
                  isOnlineReportErrorHealth ? (
                    <div className="d-flex">Health Variants:&nbsp; {onlineReportErrorsHealth}</div>
                  ) : (
                    'Health Variants'
                  )
                }
              >
                {healthVariants
                  ?.filter((group) => group.healthVariants.length > 0)
                  .map((group, i) => (
                    <PrintSelectionCheckboxGroup key={i} label={group.name}>
                      {group.healthVariants
                        .filter((group) => group.traits.length > 0)
                        .map((group, i) => (
                          <PaddedCheckbox
                            name={
                              group.isAggregated && group.aggregatorId
                                ? `aggregatedHealthIssues.traits.${group.aggregatorId.toString()}.isVisible`
                                : `advancedHealthIssues.traits.${group.id.toString()}.isVisible`
                            }
                            label={group.name}
                          />
                        ))}
                    </PrintSelectionCheckboxGroup>
                  ))}
              </PrintSelectionCheckboxGroup>
              <PrintSelectionCheckboxGroup
                label={
                  isOnlineReportErrorAbility ? (
                    <div className="d-flex">Performance and Abilities:&nbsp; {onlineReportErrorsAbility}</div>
                  ) : (
                    'Performance and Abilities'
                  )
                }
              >
                {abilities
                  ?.filter((group) => group.abilities.length > 0)
                  .map((group, i) => (
                    <PrintSelectionCheckboxGroup key={i} label={group.name}>
                      {group.abilities
                        .filter((group) => group.traits.length > 0)
                        .map((group, i) => (
                          <PaddedCheckbox
                            name={
                              group.isAggregated && group.aggregatorId
                                ? `aggregatedAbilities.traits.${group.aggregatorId.toString()}.isVisible`
                                : `advancedAbilities.traits.${group.id.toString()}.isVisible`
                            }
                            label={group.name}
                          />
                        ))}
                    </PrintSelectionCheckboxGroup>
                  ))}
              </PrintSelectionCheckboxGroup>
            </Nebula>
          </FormWrapper>
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{getCommonErrors(error)}</ErrorMessage>
          <PaddedCheckbox name="rememberChoices" label="Remember my choices" />
          <ModalWindowButton type="submit" hidden={false} disabled={false} isLoading={false}>
            Print selection
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const handleSubmit = async (values: IFormValues, formikBag: FormikBag<OuterProps, IFormValues>) => {
  const {onSuccess, onSubmit} = formikBag.props;
  formikBag.setStatus(null);

  try {
    await onSubmit(values);
    formikBag.setSubmitting(true);
  } catch (error) {
    formikBag.setStatus(error);
    return;
  }

  onSuccess && onSuccess(values);
};

const PrintSelectionWithForm = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: formikPropsToValues,
  validationSchema: getValidationScheme,
  handleSubmit,
  enableReinitialize: true,
})(PrintSelectionFormik);

const mapStateToProps = (state: IAppState, externalProps: IExternalProps) => {
  const {reportType} = externalProps;

  const distributor = printSelectionFormDistributor[reportType];
  return {...distributor?.state(state, externalProps)};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, undefined, OnlineReportActions | AdminOnlineReportActions>,
  externalProps: IExternalProps
) => {
  const {reportType} = externalProps;

  const distributor = printSelectionFormDistributor[reportType];
  const dispatchToProps = {...distributor.dispatch};

  return bindActionCreators(dispatchToProps, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const PrintSelectionFormInner = connector(memo(PrintSelectionWithForm));

export default PrintSelectionFormInner;
