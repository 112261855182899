import {IModule} from 'redux-dynamic-modules';

import {IAdminReportState} from './types';
import {adminReportsReducer} from './reducer';

export interface IAdminReportsModuleState {
  adminReports: IAdminReportState;
}

export const AdminReportsModule: IModule<IAdminReportsModuleState> = {
  id: 'adminReports',
  reducerMap: {
    adminReports: adminReportsReducer
  }
};
