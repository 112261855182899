import {combineReducers, Reducer} from 'redux';

import {IAdminNotificationState} from '../types';
import {communicationAdminNotificationsReducer} from './communications';
import {dataAdminNotificationsReducer} from './data';

export const adminNotificationsReducer: Reducer<IAdminNotificationState> = combineReducers<IAdminNotificationState>({
  communications: communicationAdminNotificationsReducer,
  data: dataAdminNotificationsReducer
});
