import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';
import {Link} from 'react-router-dom';

import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon from 'Icon/components/ColoredIcon';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {CSSProperties} from 'react';
import withoutProps from 'Common/helpers/WithoutProps';
import {IColoredText} from 'Common/components/DropDown/common/types';

const defaultDropdownsItemsStyle = {
  fontFamily: Theme.font.primary,
  fontStyle: 'normal',
  fontWeight: Typography.weight.normal400 as any,
  fontSize: Typography.size.size14,
  lineHeight: '16px',
  letterSpacing: 0.2,
  color: Theme.color.black,
};

export const Menu = styled(Dropdown.Menu)<{itemsStyle?: CSSProperties}>`
  padding: 16px 0;
  box-shadow: 0px 0px 20px ${ColorPalette.transparent2};
  border-radius: 4px;
  border: none;
`;

export const MenuItem = styled(withoutProps(['hoverColor', 'activeColor'])(Dropdown.Item))<{props: IColoredText}>`
  ${{...defaultDropdownsItemsStyle}}

  padding: 0 16px 0 24px;
  height: 32px;
  border-radius: 4px;
  &:hover {
    background-color: ${ColorPalette.gray49};
    color: ${(props) => props.hoverColor ?? 'unset'} !important;
  }
  &:active {
    background-color: ${ColorPalette.gray49};
    color: ${(props) => props.activeColor ?? 'unset'} !important;
  }
`;

export const MenuItemLink = styled(withoutProps(['hoverColor', 'activeColor'])(Link))<{props: IColoredText}>`
  ${{...defaultDropdownsItemsStyle}}

  padding: 0 16px 0 24px;
  height: 32px;
  width: 100%;
  white-space: nowrap;
  border-radius: 4px;
  &:hover {
    background-color: ${ColorPalette.gray49};
    color: ${(props) => props.hoverColor ?? 'unset'} !important;
    text-decoration: none;
  }
  &:active {
    background-color: ${ColorPalette.gray49};
    color: ${(props) => props.activeColor ?? 'unset'} !important;
  }
`;

export const Divider = styled(Dropdown.Divider)`
  width: 80%;
  margin: 2px 10%;
`;

export const MenuItemIcon = styled(ColoredIcon)`
  margin-right: 16px;
`;
