import {ICommunication, MakeCommunicationActions} from 'Common/store/utils/communication/types';

import {makeCommunicationActionType} from 'Common/store/utils/communication/index';

const STATE_NAME = 'testReport';

export interface ITestReportState {
  communications: {
    reportUploading: ICommunication;
    reportDeleting: ICommunication;
  };
}

export const uploadReportActionTypes = makeCommunicationActionType(STATE_NAME, 'UPLOAD_REPORT');
export const deleteReportActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_REPORT');

export type deleteReportActions = MakeCommunicationActions<typeof deleteReportActionTypes, {}>;

export type uploadReportActions = MakeCommunicationActions<typeof uploadReportActionTypes, {}>;

export type Action = uploadReportActions | deleteReportActions;
