import {combineReducers} from 'redux';

import {
  Actions,
  getOrderPaymentTokenActionTypes,
  getPaymentHorsesActionTypes,
  getPaymentSummaryActionTypes,
  IBusinessPortalOrderPaymentState,
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationReducer = combineReducers<IBusinessPortalOrderPaymentState['communications'], Actions>({
  paymentHorsesLoading: makeCommunicationReducerFromEnum(getPaymentHorsesActionTypes),
  paymentSummaryLoading: makeCommunicationReducerFromEnum(getPaymentSummaryActionTypes),
  orderPaymentTokenLoading: makeCommunicationReducerFromEnum(getOrderPaymentTokenActionTypes),
});
