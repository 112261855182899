import {IServerFindHorseRequest} from '../serverModel/FindHorseRequest';
import {GenePoolsType} from 'Filters/models/GenePools';
import {convertClientDistanceToServer} from 'Filters/services/converters/Distance';
import {IFindHorseRequest} from 'FindHorse/models/FindHorseRequest';
import {convertAbilityToServer} from 'Common/helpers/convertAbilities';
import {convertHealthVariantToAvoidToServer} from 'BuildHorse/services/converters/healthVariantsToAvoid';

export function convertFindHorseRequestToServer(request: IFindHorseRequest): IServerFindHorseRequest {
  const {
    gender,
    abilities,
    breeds,
    disciplines,
    sourceTypes,
    distance,
    healthVariantsToAvoid,
    color,
    colorModifiers,
    horseName,
    associations,
    commercialTypes,
    ageFrom,
    ageTo,
    isHideExcludedHorses,
  } = request;

  return {
    gender,
    abilities: {
      Gait: convertAbilityToServer(abilities.gait),
      Speed: convertAbilityToServer(abilities.speed),
      Temperament: convertAbilityToServer(abilities.temperament),
    },
    breeds,
    disciplines,
    distance: sourceTypes.includes(GenePoolsType.LocationRadius) ? convertClientDistanceToServer(distance) : {},
    healthVariantsToAvoid: healthVariantsToAvoid.map(convertHealthVariantToAvoidToServer),
    sourceTypes,
    colors: color ? [color] : [],
    modifiers: colorModifiers,
    specificHorseName: horseName,
    associations: associations || [],
    commercialTypes,
    ageFrom,
    ageTo,
    isHideExcludedHorses,
  };
}
