import {IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IServiceMode} from '../models/IServiceMode';
import {convertServiceModeToClient} from './converters/serviceMode';

const getOperatingModeControl = async (): Promise<IServiceMode> => {
  const response = await axiosWrapper.get<IObjectResponse<IServiceMode>>(`/CurrentModeService`);
  return convertServiceModeToClient(response.data.data);
};

export default {getOperatingModeControl};
