import {combineReducers, Reducer} from 'redux';

import {IAncestryReportState} from '../types';
import {communicationReducer} from './communications';
import {dataReducer} from './data';

export const ancestryReportReducer: Reducer<IAncestryReportState> = combineReducers<IAncestryReportState>({
  communications: communicationReducer,
  data: dataReducer,
});
