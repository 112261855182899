import React, {memo} from 'react';

import Carousel from 'Common/components/Carousel/Carousel';
import {IMediaResource} from 'Common/models/IMediaResource';
import GalleryImage from './GalleryImage';

interface IProps {
  horseMedia: IMediaResource[];
  onSelect(media: IMediaResource): void;
  selectedUrl: string | null;
}

function HorseGallery(props: IProps) {
  const {horseMedia, onSelect, selectedUrl} = props;

  return (
    <Carousel>
      {horseMedia.map((media, i) => {
        return <GalleryImage key={i} media={media} onSelect={() => onSelect(media)} selectedUrl={selectedUrl} />;
      })}
    </Carousel>
  );
}

export default memo(HorseGallery);
