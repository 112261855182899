import {Nullable} from 'Common/types';
import {IImage} from 'Image/models/IImage';
import {withImageBaseUrl} from './withImageBaseUrl';

export function withImage<T extends {image?: Nullable<IImage>}>(model: T) {
  const {image} = model;

  if (!image) {
    return model;
  }

  return {
    ...model,
    image: {...withImageBaseUrl(image)},
  };
}
