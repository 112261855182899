export interface IOrderStatusFields {
  paymentStatus: OrderStatusField;
  sampleStatus: OrderStatusField;
  resultsStatus: OrderStatusField;
  reviewStatus: OrderStatusField;
  releaseStatus: OrderStatusField;
}

export enum OrderStatusField {
  Waiting = 'Waiting',
  Ready = 'Ready'
}
