import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {IStringDescription} from 'Common/models/IDescriptionEntity';
import {IIndexed} from 'Common/models/IIndexed';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IPackageGroup} from 'Dictionaries/models/IPackageGroup';
import {IUpdatePackageGroupRequest} from 'Admin/AdminReference/services/models/IServerPackageGroup';

export interface IFormValues extends IStringDescription, IIndexed {
  id?: IEntity['id'];
  name: string;
}

export const initialValue: IFormValues = {
  name: '',
  description: '',
  positionIndex: 0,
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  positionIndex: Yup.number().required(REQUIRED_FIELD),
});

export const convertToFormValues = (values: IPackageGroup): IFormValues => {
  return {
    ...values,
  };
};

export const convertToServer = (values: IFormValues): Omit<IUpdatePackageGroupRequest, 'id'> => {
  return {
    ...values,
    description: values.description || '',
  };
};

export const convertToServerUpdating = (values: IFormValues): IUpdatePackageGroupRequest => {
  return {
    ...convertToServer(values),
    id: values.id!,
  };
};
