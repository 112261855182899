import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse} from 'Common/models/IResponse';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import {isAdminVisitor} from 'Common/helpers/isAdminVisitor';

const getHorseBreedPanel = async (horseId: number, orderId?: number): Promise<IListResponse<IHorseBreedPanel>> => {
  const response = await axiosWrapper.get<IListResponse<IHorseBreedPanel>>(
    (isAdminVisitor() ? (orderId ? '/HorsePanelsReviewAdmin' : '/HorsePanelsAdmin') : 'HorsePanels') +
      (orderId ? `/Review` : `/${horseId}`),
    orderId ? {params: {horseId, orderId}} : {}
  );
  return response.data;
};

export default {
  getHorseBreedPanel,
};
