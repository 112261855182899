import {combineReducers} from 'redux';

import {ICreateOrderState} from '../types';
import {communicationReducer} from './communications';
import {dataReducer} from './data';

export const ordersReducer = combineReducers<ICreateOrderState>({
  communications: communicationReducer,
  data: dataReducer
});
