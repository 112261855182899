import * as Yup from 'yup';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IUpdateHorseMediaBP} from 'BusinessPortal/models/horse/IHorseMediaBP';
import {IMediaResource} from 'Common/models/IMediaResource';

export type IFormValues = IUpdateHorseMediaBP;

export const initialValue: IFormValues = {
  horseId: 0,
  gallery: []
};

export const validationSchema = Yup.object().shape<IFormValues>({
  horseId: Yup.number().required(REQUIRED_FIELD),
  gallery: Yup.array().of(Yup.mixed<IMediaResource>())
});
