import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IRequestParams} from 'Common/models/IRequestParams';

import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {ICreateHorseOwnerBP, IUpdateHorseOwnerBP} from 'BusinessPortal/models/owner/IOwnerBP';
import {
  AdminAssociationOwnerActions,
  createAdminAssociationOwnerActions,
  createAdminAssociationOwnerActionTypes,
  deleteAdminAssociationOwnerActions,
  deleteAdminAssociationOwnerActionTypes,
  deleteAdminAssociationOwnerAvatarActions,
  deleteAdminAssociationOwnerAvatarActionTypes,
  getAdminAssociationOwnerDetailsActions,
  getAdminAssociationOwnerDetailsActionTypes,
  getAdminAssociationOwnersActions,
  getAdminAssociationOwnersActionTypes,
  resetAdminAssociationOwnerDetailsActionTypes,
  resetAdminAssociationOwnersActionTypes,
  updateAdminAssociationOwnerActions,
  updateAdminAssociationOwnerActionTypes,
  updateAdminAssociationOwnerAvatarActions,
  updateAdminAssociationOwnerAvatarActionTypes,
} from './types';
import BusinessPortalOwnerService from 'BusinessPortal/services/BusinessPortalOwnerService';

export const getOwners =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getAdminAssociationOwnersActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminAssociationOwnersActionTypes.REQUEST));
      const owners = await BusinessPortalOwnerService.getOwners(params, true);
      dispatch(action(getAdminAssociationOwnersActionTypes.SUCCESS, owners));
    } catch (error) {
      dispatch(action(getAdminAssociationOwnersActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOwnerDetails =
  (ownerId: number): ActionResult<IAppState, Promise<void>, getAdminAssociationOwnerDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminAssociationOwnerDetailsActionTypes.REQUEST));
      const data = await BusinessPortalOwnerService.getOwnerDetails(ownerId, true);
      dispatch(action(getAdminAssociationOwnerDetailsActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getAdminAssociationOwnerDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createOwner =
  (data: ICreateHorseOwnerBP): ActionResult<IAppState, Promise<number>, createAdminAssociationOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createAdminAssociationOwnerActionTypes.REQUEST));
      const ownerId = await BusinessPortalOwnerService.createOwner(data, true);
      dispatch(action(createAdminAssociationOwnerActionTypes.SUCCESS));
      return ownerId;
    } catch (error) {
      const err = getErrorMessage(error);
      dispatch(action(createAdminAssociationOwnerActionTypes.FAILURE, err));
      throw err;
    }
  };

export const updateOwner =
  (data: IUpdateHorseOwnerBP): ActionResult<IAppState, Promise<void>, updateAdminAssociationOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdminAssociationOwnerActionTypes.REQUEST));
      await BusinessPortalOwnerService.updateOwner(data, true);
      dispatch(action(updateAdminAssociationOwnerActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateAdminAssociationOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateOwnerAvatar =
  (
    ownerId: number,
    uploadedFile: File
  ): ActionResult<IAppState, Promise<void>, updateAdminAssociationOwnerAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdminAssociationOwnerAvatarActionTypes.REQUEST));
      await BusinessPortalOwnerService.updateOwnerAvatar(ownerId, uploadedFile, true);
      dispatch(action(updateAdminAssociationOwnerAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateAdminAssociationOwnerAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteOwnerAvatar =
  (ownerId: number): ActionResult<IAppState, Promise<void>, deleteAdminAssociationOwnerAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteAdminAssociationOwnerAvatarActionTypes.REQUEST));
      await BusinessPortalOwnerService.deleteOwnerAvatar(ownerId, true);
      dispatch(action(deleteAdminAssociationOwnerAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteAdminAssociationOwnerAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteOwner =
  (ownerId: number): ActionResult<IAppState, Promise<void>, deleteAdminAssociationOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteAdminAssociationOwnerActionTypes.REQUEST));
      await BusinessPortalOwnerService.deleteOwner(ownerId, true);
      dispatch(action(deleteAdminAssociationOwnerActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteAdminAssociationOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetOwners = (): ActionResult<IAppState, void, AdminAssociationOwnerActions> => (dispatch) => {
  dispatch(action(resetAdminAssociationOwnersActionTypes.RESET));
};

export const resetOwnerDetails = (): ActionResult<IAppState, void, AdminAssociationOwnerActions> => (dispatch) => {
  dispatch(action(resetAdminAssociationOwnerDetailsActionTypes.RESET));
};
