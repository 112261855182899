import React, {memo} from 'react';
import styled from 'styled-components';

import ImageUploader, {ImageUploaderProps} from 'Image/components/ImageUploader';
import {IImage} from 'Image/models/IImage';
import {IFieldProps} from 'Common/models/IFieldProps';

import withField from './withField';
import ValidationError from './ValidationError';

const InputLayout = styled.div`
  position: relative;
`;

type Props = IFieldProps<IImage | null> & Omit<ImageUploaderProps, 'value' | 'onChange' | 'onDelete' | 'isMultiple'>;

function ImageUploaderField(props: Props) {
  const {
    field,
    form: {setFieldValue, setFieldTouched, touched},
    inputStyle,
    inputClassName,
    ...rest
  } = props;

  const onChange = React.useCallback(
    (image: IImage) => {
      setFieldValue(field.name, image);
    },
    [setFieldValue, field]
  );

  const onDelete = React.useCallback(() => {
    setFieldValue(field.name, null);
  }, [setFieldValue, field]);

  const onBlur = React.useCallback(() => {
    !touched?.[field.name] && setFieldTouched(field.name, true);
  }, [touched, field, setFieldTouched]);

  return (
    <InputLayout style={inputStyle} className={inputClassName}>
      <ImageUploader {...rest} {...field} onChange={onChange} onBlur={onBlur} onDelete={onDelete} />
      <ValidationError name={field.name} withoutLifting={true} />
    </InputLayout>
  );
}

export default memo(withField(ImageUploaderField));
