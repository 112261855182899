import React, {memo} from 'react';
import styled from 'styled-components';

import {PlaceholderDescription, PlaceholderTitle} from '../shared/StyledComponents';
import {ReactComponent as Image} from './img/placeholder1.svg';
import {AnimatedInitiate} from 'Common/components/StyledComponents/StyledComponents';

const ImageWrapper = styled(Image)`
  top: 120px;
`;

function PlaceHolder1() {
  return (
    <AnimatedInitiate className="d-flex flex-column justify-content-end align-items-center position-relative">
      <ImageWrapper className="w-100 h-100 position-absolute" />
      <PlaceholderTitle>Comprehensive diagnostic reports!</PlaceholderTitle>
      <PlaceholderDescription>Horse genetics made easy and affordable</PlaceholderDescription>
    </AnimatedInitiate>
  );
}

export default memo(PlaceHolder1);
