import * as React from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';

const percentForMargin = 3;

const cardMargin = (percentForMargin / 2).toFixed(1);

const GridItemStyles = (itemAtRow: number) => {
  const width = 100 / itemAtRow - percentForMargin;
  const margin = cardMargin;

  return `
    width: ${width}%;
    margin-left: ${margin}%;
    margin-right: ${margin}%;
  `;
};

const GridRoot = styled.div`
  margin: 0 -${cardMargin}%;
`;

const GridItemRoot = styled.div`
  width: 100%;
  min-height: 240px;
  margin-bottom: 24px;
  padding: 24px 24px 32px;
  border: 1px solid ${ColorPalette.gray29};
  border-radius: 4px;

  @media ${breakpoints.sm} {
    ${GridItemStyles(2)}
  }

  @media ${breakpoints.md} {
    ${GridItemStyles(3)}
  }

  @media ${breakpoints.lg} {
    ${GridItemStyles(4)}
  }

  @media ${breakpoints.xl} {
    ${GridItemStyles(5)}
  }
`;

interface IProps {
  items: React.ReactElement[];
}

function Grid(props: IProps) {
  const {items} = props;
  return (
    <GridRoot className="d-flex flex-wrap">
      {items.map((item, i) => (
        <GridItemRoot className="d-flex" key={i}>
          {item}
        </GridItemRoot>
      ))}
    </GridRoot>
  );
}

export default Grid;
