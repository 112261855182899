import {combineReducers, Reducer} from 'redux';

import {communicationStaticResourcesReducer} from './communications';
import {dataStaticResourcesReducer} from './data';
import {IAdminStaticResourcesState} from '../types';
import {uiStaticResourcesReducer} from './ui';

export const staticResourcesReducer: Reducer<IAdminStaticResourcesState> = combineReducers<IAdminStaticResourcesState>({
  communications: communicationStaticResourcesReducer,
  data: dataStaticResourcesReducer,
  ui: uiStaticResourcesReducer
});
