import {Reducer} from 'redux';

import {
  IAdminAssociationPackagesState,
  AdminAssociationPackagesActions,
  getAdminAssociationPackagesActionTypes,
} from '../types';

const initialState: IAdminAssociationPackagesState['ui'] = {
  pagination: {countPerPage: 0, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiAdminAssociationPackagesReducer: Reducer<
  IAdminAssociationPackagesState['ui'],
  AdminAssociationPackagesActions
> = (state = initialState, action: AdminAssociationPackagesActions) => {
  switch (action.type) {
    case getAdminAssociationPackagesActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
