import {combineReducers} from 'redux';

import {IAdminCreateUserOrderState} from '../types';
import {communicationReducer} from './communications';
import {dataReducer} from './data';

export const adminCreateUserOrderReducer = combineReducers<IAdminCreateUserOrderState>({
  communications: communicationReducer,
  data: dataReducer,
});
