import React, {useCallback, useState} from 'react';
import * as R from 'ramda';

import {ExpansionPanel, ShadowType} from 'Common/components/ExpansionPanel/ExpansionPanel';
import GroupElementHeader from 'OnlineReport/components/shared/GroupElementHeader';
import {OnlineReportGroupType} from 'OnlineReport/components/shared/OnlineReportGroupType';
import {ElementPosition} from 'FoalProfile/models/IElementPosition';
import {IOnlineReportGroupElement} from 'OnlineReport/models/shared/IOnlineReportGroupElement';
import GroupElementBody from './GroupElementBody';
import {PhenotypeState} from 'OnlineReport/constants/PhenotypeState ';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import styled from 'styled-components';
import ColorPalette from 'Common/constants/ColorPalette';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const Divider = styled.div`
  border-bottom: 1px solid ${ColorPalette.gray13};
`;

const PrintableGroupSectionContainer = styled.div`
  padding: 24px 0;
  page-break-inside: avoid;
`;

interface IProps {
  isPrintable?: boolean;
  elements: IOnlineReportGroupElement[];
  type: OnlineReportGroupType;
  reportType: OnlineReportType;
}

const GroupElementsSection = (props: IProps) => {
  const {isPrintable = false, elements, type, reportType} = props;

  const {isMobile} = useMediaQuery();

  const [currentOpenElementIds, setCurrentOpenElementIds] = useState<number[]>();

  const maxAllelesPairCount = useCallback(
    (groupElements: IOnlineReportGroupElement[]) =>
      R.apply(
        Math.max,
        groupElements.map((item) => item.traits.length)
      ),
    []
  );

  const setCurrentOpenElementIdHandler = useCallback(
    (currentElementId: number) => {
      !currentOpenElementIds || currentOpenElementIds?.length === 0
        ? setCurrentOpenElementIds([currentElementId])
        : setCurrentOpenElementIds([...currentOpenElementIds, currentElementId]);
    },
    [currentOpenElementIds]
  );

  return (
    <>
      {elements
        .sort((a, b) => sortByStringKey(a, b, 'positionIndex'))
        .map((element, idx) => {
          const isNotTested = element.state === PhenotypeState.NotTested;

          const onExpansionPanelClick = (isOpen: boolean) => {
            if (isOpen) {
              setCurrentOpenElementIdHandler(element.id);
            }
          };

          if (isPrintable) {
            return (
              <React.Fragment key={element.id}>
                <PrintableGroupSectionContainer>
                  <GroupElementHeader
                    isPrintable
                    maxAllelesPairCount={maxAllelesPairCount(elements)}
                    groupElement={element}
                    groupElementType={type}
                  />
                  {!isNotTested && (
                    <GroupElementBody element={element} isOpen={true} type={type} reportType={reportType} />
                  )}
                </PrintableGroupSectionContainer>
                <Divider />
              </React.Fragment>
            );
          }

          return (
            <ExpansionPanel
              key={element.id}
              onClick={onExpansionPanelClick}
              headerContent={
                <GroupElementHeader
                  maxAllelesPairCount={maxAllelesPairCount(elements)}
                  groupElement={element}
                  groupElementType={type}
                />
              }
              arrowSide={isMobile ? ElementPosition.Bottom : ElementPosition.Left}
              hasBodySeparator={false}
              hasElementSeparator={idx !== elements.length - 1}
              shadowType={ShadowType.OnlyOpen}
              isDisabled={isNotTested}
              isShadowOnMouseHover={true}
            >
              <>
                <GroupElementBody
                  element={element}
                  isOpen={!!currentOpenElementIds?.find((i) => i === element.id)}
                  type={type}
                  reportType={reportType}
                />
              </>
            </ExpansionPanel>
          );
        })}
    </>
  );
};

export default GroupElementsSection;
