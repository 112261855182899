import React, {memo} from 'react';
import styled from 'styled-components';

import {isAvailableForBah} from 'Common/helpers/isAvailableForBah';
import AvatarCellHorse from 'Admin/AdminDashboard/components/Orders/shared/AvatarCellHorse';
import {IHorseDetails} from 'Admin/AdminDashboard/models/Horse/IHorseDetails';
import HorseActions, {HorseActionsProps} from 'Admin/AdminDashboard/components/Horses/HorseActions';
import {HORSE_GRANTED_MARKED_ICON} from 'Common/constants/avatarMarkedIcon';

const EmptyListMessage = styled.div`
  padding-top: 16px;
  padding-left: 8px;
`;

const HorseItem = styled.div`
  padding: 16px 0px 16px 22px;
`;

interface IProps {
  horses?: IHorseDetails[];
}

type AllProps = IProps & Omit<HorseActionsProps, 'horse'>;

const UserHorsesSection = (props: AllProps) => {
  const {horses, ...rest} = props;

  if (!horses || horses.length === 0) {
    return <EmptyListMessage>No horses</EmptyListMessage>;
  }

  return (
    <>
      {horses.map((horse, i) => (
        <HorseItem className="d-flex align-items-center" key={i}>
          <AvatarCellHorse
            type="horse"
            onLabelClick={() => rest.editHandler(horse.id)}
            label={horse.name}
            avatarUrl={horse.avatar?.url}
            profileUrl={`/admin/horse/${horse.id}`}
            isArchived={horse.isArchived}
            isDeleted={false}
            isMarkedIcon={isAvailableForBah(horse.availabilityCriteria)}
            markedIcon={HORSE_GRANTED_MARKED_ICON}
            isGeneticDataHidden={horse.isGeneticDataHidden}
            sampleId={horse.sample?.sampleId}
          />
          <HorseActions horse={horse} {...rest} />
        </HorseItem>
      ))}
    </>
  );
};

export default memo(UserHorsesSection);
