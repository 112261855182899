import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import {
  BusinessPortalAssociationPageActions,
  getContactInformationActionTypes,
  getGeneralInformationActionTypes,
  getOrganizationAvatarActionTypes,
  IBusinessPortalAssociationPageState,
  resetContactInformationActionTypes,
  resetGeneralInformationActionTypes,
  updateContactInformationActionTypes,
  updateGeneralInformationActionTypes,
  updateOrganizationAvatarActionTypes,
} from '../types';
import composeReducers from 'Common/store/composeReducers';

export const communicationBusinessPortalAssociationPageReducer = combineReducers<
  IBusinessPortalAssociationPageState['communications'],
  BusinessPortalAssociationPageActions
>({
  generalInformationLoading: composeReducers([
    makeCommunicationReducerFromEnum(getGeneralInformationActionTypes),
    makeResetCommunicationReducer(resetGeneralInformationActionTypes.RESET),
  ]),
  generalInformationUpdating: makeCommunicationReducerFromEnum(updateGeneralInformationActionTypes),
  contactInformationLoading: composeReducers([
    makeCommunicationReducerFromEnum(getContactInformationActionTypes),
    makeResetCommunicationReducer(resetContactInformationActionTypes.RESET),
  ]),
  contactInformationUpdating: makeCommunicationReducerFromEnum(updateContactInformationActionTypes),
  organizationAvatarLoading: makeCommunicationReducerFromEnum(getOrganizationAvatarActionTypes),
  organizationAvatarUpdating: makeCommunicationReducerFromEnum(updateOrganizationAvatarActionTypes),
});
