import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

import {
  BusinessPortalHorseProfileActions,
  getHorseAbilitiesActionTypes,
  getHorseBreedPanelActionTypes,
  getHorseGenotypeGroupsActionTypes,
  getHorseLastOrderActionTypes,
  getHorseOwnerActionTypes,
  getHorseParantageReportActionTypes,
  getOwnerHorsesAtionTypes,
  IBusinessPortalHorseProfileState,
} from '../types';

export const communicationHorseReducer = combineReducers<
  IBusinessPortalHorseProfileState['communications'],
  BusinessPortalHorseProfileActions
>({
  horseAbilitiesLoading: makeCommunicationReducerFromEnum(getHorseAbilitiesActionTypes),
  horseParantageReportLoading: makeCommunicationReducerFromEnum(getHorseParantageReportActionTypes),
  horseGenotypeGroupsLoading: makeCommunicationReducerFromEnum(getHorseGenotypeGroupsActionTypes),
  horseBreedPanelLoading: makeCommunicationReducerFromEnum(getHorseBreedPanelActionTypes),
  horseLastOrderLoading: makeCommunicationReducerFromEnum(getHorseLastOrderActionTypes),
  horseOwnerLoading: makeCommunicationReducerFromEnum(getHorseOwnerActionTypes),
  ownerHorsesLoading: makeCommunicationReducerFromEnum(getOwnerHorsesAtionTypes),
});
