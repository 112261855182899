import React from 'react';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';
import {FormatOptionLabelMeta} from 'react-select/dist/declarations/src/Select';
import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import {Gender} from 'Common/constants/Gender';
import {IWithBirthDate} from 'Common/models/IWithBirthDate';

const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const MainFields = styled.div`
  overflow: hidden;
  margin-left: 8px;
`;

const HorseName = styled.div`
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MinorInformation = styled.span`
  font-size: ${Typography.size.size12};
  line-height: 12px;
  color: ${ColorPalette.gray44};
`;

export interface IHorseOption extends IDictionaryOptionType, IWithBirthDate {
  avatarUrl?: string;
  gender: Gender;
}

export default function renderSearchItem(option: IHorseOption, labelMeta: FormatOptionLabelMeta<IHorseOption>) {
  const {label, avatarUrl, dateOfBirth, gender} = option;
  return (
    <Root>
      <Avatar
        avatarSize={AvatarSize.Custom}
        defaultAvatarProfile={DefaultAvatarProfile.Horse}
        customSizeAvatar={32}
        url={avatarUrl}
      />
      <MainFields>
        <HorseName>
          <Highlighter
            searchWords={[labelMeta.inputValue]}
            textToHighlight={label}
            highlightStyle={{background: ColorPalette.gray53, margin: 0, padding: 0}}
          />
        </HorseName>
        <MinorInformation>
          {gender}
          {gender && dateOfBirth && ', '}
          {dateOfBirth}
        </MinorInformation>
      </MainFields>
    </Root>
  );
}
