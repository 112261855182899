import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {actions} from 'BusinessPortal/store/horse';
import HorseInfoSection from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseInfoSection/HorseInfoSection';
import Header from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/Header';
import HorseHealthInfoSection from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseHealthInfoSection/HorseHealthInfoSection';
import RegistriesAndParentageSection from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/RegistrationAndParentageSection/RegistrationAndParentageSection';
import HorseMediaSection from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseMediaSection/HorseMediaSection';
import HorseOwnerSection from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseOwnerSection/HorseOwnerSection';

interface IExternalProps {
  horseId: number;
  onSuccess(): void;
}

type IConnectProps = ConnectedProps<typeof connector>;

type AllProps = IExternalProps & IConnectProps;

const HorseFormEdit = (props: AllProps) => {
  const {
    horseId,
    resetHorseDetails,
    resetHorseOwner,
    resetHorseParentage,
    resetHorseRegistries,
    resetHorseHealthIssues,
    resetHorseMedia,
    onSuccess,
  } = props;

  useEffect(() => {
    return () => {
      resetHorseDetails();
      resetHorseOwner();
      resetHorseParentage();
      resetHorseRegistries();
      resetHorseHealthIssues();
      resetHorseMedia();
    };
  }, [
    resetHorseDetails,
    resetHorseOwner,
    resetHorseHealthIssues,
    resetHorseParentage,
    resetHorseRegistries,
    resetHorseMedia,
  ]);

  return (
    <>
      <Header horseId={horseId} onSuccess={onSuccess} />
      <HorseOwnerSection horseId={horseId} onSuccess={onSuccess} />
      <HorseInfoSection horseId={horseId} onSuccess={onSuccess} />
      <RegistriesAndParentageSection horseId={horseId} onSuccess={onSuccess} />
      <HorseMediaSection horseId={horseId} onSuccess={onSuccess} />
      <HorseHealthInfoSection horseId={horseId} onSuccess={onSuccess} />
    </>
  );
};

const mapDispatchToProps = {
  resetHorseDetails: actions.resetHorseDetails,
  resetHorseOwner: actions.resetHorseOwner,
  resetHorseParentage: actions.resetHorseParentage,
  resetHorseRegistries: actions.resetHorseRegistries,
  resetHorseHealthIssues: actions.resetHorseHealthIssues,
  resetHorseMedia: actions.resetHorseMedia,
};

const connector = connect(null, mapDispatchToProps);
export default connector(memo(HorseFormEdit));
