import {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {TextBase} from 'BusinessPortal/components/common/styled';
import {actions, selectors} from 'BusinessPortal/store/owner';
import Typography from 'Common/constants/Typography';
import {IAppState} from 'Common/store/IAppState';
import {IconName} from 'Icon/components/Icon';
import ImageUploader from 'Image/components/ImageUploader';
import {IImage} from 'Image/models/IImage';
import Loading from 'Loading/components/Loading';
import {IOwnerSectionBP} from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/models/IOwnerSectionBP';
import NoData from 'BusinessPortal/components/common/NoData/NoData';
import {convertUserName} from 'Common/helpers/convertUserName';

const Name = styled(TextBase)`
  font-size: ${Typography.size.size20};
  line-height: 32px;
  letter-spacing: 0;
  margin-left: 24px;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IOwnerSectionBP;

const OwnerShortInfoSection = (props: AllProps) => {
  const {ownerDetails, onSuccess, deleteOwnerAvatar, ownerAvatarDeleting, ownerAvatarUpdating, updateOwnerAvatar} =
    props;

  const updateOwnerAvatarHandler = useCallback(
    async (image: IImage) => {
      image.file && ownerDetails && (await updateOwnerAvatar(ownerDetails.id, image.file));
      onSuccess && onSuccess();
    },
    [onSuccess, ownerDetails, updateOwnerAvatar]
  );

  const deleteHorseAvatarHandler = useCallback(async () => {
    ownerDetails && (await deleteOwnerAvatar(ownerDetails.id));
    onSuccess && onSuccess();
  }, [deleteOwnerAvatar, onSuccess, ownerDetails]);

  const isRequesting = ownerAvatarUpdating.isRequesting || ownerAvatarDeleting.isRequesting;

  if (!ownerDetails) {
    return <NoData />;
  }

  return (
    <div className="d-flex align-items-center position-relative">
      {isRequesting && <Loading />}
      <ImageUploader
        width="104px"
        height="104px"
        isRound={true}
        actionButtonsContainerHeight="100%"
        value={ownerDetails.avatar}
        iconProps={{name: IconName.OwnerDefaultAvatar, width: 100, height: 100}}
        onDelete={deleteHorseAvatarHandler}
        onChange={updateOwnerAvatarHandler}
      />
      <Name>{convertUserName(ownerDetails.firstName, ownerDetails.lastName)}</Name>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  ownerAvatarUpdating: selectors.selectCommunication(state, 'ownerAvatarUpdating'),
  ownerAvatarDeleting: selectors.selectCommunication(state, 'ownerAvatarDeleting'),
});

const mapDispatchToProps = {
  updateOwnerAvatar: actions.updateOwnerAvatar,
  deleteOwnerAvatar: actions.deleteOwnerAvatar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(OwnerShortInfoSection);
