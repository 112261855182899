import {Form, FormikProps, withFormik} from 'formik';
import {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {InputField, TextAreaField} from 'Common/components/FormFields/index';
import {ModalWindowFormContent} from 'Common/components/Modal/shared/index';
import {ModalWindowButton, ModalWindowFooter, ModalWindowHeader} from 'Common/components/Modal/shared/StyledComponents';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {IContactRequest} from 'UserProfile/models/IContactRequest';
import {ContactRequestModule} from 'UserProfile/store/contactRequest/contactRequestModule';
import {actions, selectors} from 'UserProfile/store/contactRequest/index';
import {selectors as userSelectors} from 'UserProfile/store/currentUser/index';
import {initialValue, validationSchema} from './validation';

const Hint = styled.div`
  margin-bottom: 32px;
  align-items: center;
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${ColorPalette.black0};
`;

interface IExternalProps {
  targetUserId: number;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IContactRequest> & OuterProps;

const ContactRequestFormLayout = (props: AllProps) => {
  const {sendContactRequesting, currentUser, onSuccess} = props;
  const {isSubmitting, isValid, setFieldValue, setErrors} = props;

  const errorInfo = sendContactRequesting.error;
  const isLoading = sendContactRequesting.isRequesting;

  const DEMO_WARNING = 'Demo mode: no actual email will be sent';

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(sendContactRequesting, onSuccess);
  useOnErrorCommunication(sendContactRequesting, onError);
  useCommunicationToToast(sendContactRequesting, 'Your message was successfully sent to the user');

  useEffect(() => {
    if (currentUser) {
      setFieldValue('email', currentUser.email);
    }
  }, [currentUser, setFieldValue]);

  return (
    <>
      <ModalWindowHeader>Contact information request</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {(isSubmitting || isLoading) && <Loading />}
          <Hint>
            This user's contact information is private. You can submit this request with your information and describe
            in detail your interest and/or purpose along with a way to contact you. They can then respond so you can
            connect and talk about horses!
          </Hint>
          <InputField name="email" label="Your e-mail" isRequired={true} />
          <InputField name="phone" label="Phone number (optional)" />
          <TextAreaField name="message" label="Message" />
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{DEMO_WARNING}</ErrorMessage>
          <ModalWindowButton type="submit" disabled={isLoading || !isValid} isLoading={isSubmitting}>
            Send request
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const ContactRequestForm = withFormik<OuterProps, IContactRequest>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    await formikBag.props.sendContactRequest({
      ...values,
      userId: formikBag.props.targetUserId,
    });
  },
  enableReinitialize: true,
})(ContactRequestFormLayout);

const mapStateToProps = (state: IAppState) => ({
  sendContactRequesting: selectors.selectCommunication(state, 'sendContactRequesting'),
  currentUser: userSelectors.selectCurrentUser(state),
});

const mapDispatchToProps = {
  sendContactRequest: actions.sendContactRequest,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(ContactRequestForm));

export default withDynamicModules(Connected, ContactRequestModule);
