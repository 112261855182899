import * as Yup from 'yup';

import {IUserCreateAdmin} from 'Admin/AdminDashboard/models/User/IUserCreateAdmin';
import {Salutation} from 'Common/constants/Salutation';

export type IFormValues = IUserCreateAdmin;

export const initialValue: IFormValues = {
  salutation: Salutation.Unknown,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  mobilePhone: '',
};

export const validationSchema = Yup.object().shape<IFormValues>({
  salutation: Yup.mixed<Salutation>(),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Email not valid').required('Email is required'),
  password: Yup.string().required('Password is required'),
  mobilePhone: Yup.string()
    .required('Phone number is required')
    .test('Digits only', 'The field should have digits only', (value) => /^\d+$/.test(value))
    .test(
      'Check length',
      'The length of the phone number must be 5 to 16 digits',
      (value) => value?.length >= 5 && value?.length <= 16
    ),
});
