import React, {useMemo, useState} from 'react';

import {getGenotypesByType} from 'Common/helpers/getGenotypesByType';
import {GenotypeType} from 'Genotype/constants/GenotypeType';
import {Nullable} from 'Common/types';
import GenotypeList from 'Genotype/component/GenotypeList';
import {IPhenotypeProperty} from 'OnlineReport/models/Summary/IPhenotypeProperty';
import {GenotypeLabel, GenotypeValue} from 'OnlineReport/shared/styles';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import BreedPanelList from 'BreedPanel/components/BreedPanelList';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import {IRequiredTest} from 'Admin/AdminDashboard/models/IRequiredTest';
import {GeneType} from 'Admin/AdminPhenotypes/constants/GeneType';
import {IOnlineReportSummary} from 'OnlineReport/models/Summary/IOnlineReportSummary';
import {IOnlineReportGeneticVariants} from 'OnlineReport/models/Summary/IOnlineReportGenotype';

export function useSummaryGenotypes(
  summaryAbilities: Nullable<IOnlineReportSummary>,
  summaryColors: Nullable<IOnlineReportSummary>,
  summaryHealthIssues: Nullable<IOnlineReportSummary>,
  horseBreedPanels: IHorseBreedPanel[],
  geneticVariants: Nullable<IOnlineReportGeneticVariants>,
  requiredTests: IRequiredTest[]
) {
  const abilities = useMemo(() => summaryAbilities?.results || [], [summaryAbilities]);
  const coats = useMemo(() => summaryColors?.results || [], [summaryColors]);
  const healthIssues = useMemo(() => summaryHealthIssues?.results || [], [summaryHealthIssues]);

  const [hasSummaryGenotypes, setHasSummaryGenotypes] = useState(false);

  const summaryGenotypes = useMemo(() => {
    const genotypeGroups = geneticVariants?.results || [];

    return genotypeGroups
      .sort((a, b) => sortByStringKey(a, b, 'positionIndex'))
      .map((group, idx) => {
        const genotypes = getGenotypesByType(genotypeGroups, group.name as GenotypeType);

        if (genotypes.length > 0) {
          setHasSummaryGenotypes(true);

          return (
            <div key={idx} className="d-flex flex-column">
              <GenotypeLabel>{group.name}:</GenotypeLabel>
              <GenotypeValue>
                <GenotypeList genotypes={genotypes} />
              </GenotypeValue>
            </div>
          );
        }

        return null;
      });
  }, [geneticVariants]);

  function renderPhenotypeProperties(props: IPhenotypeProperty[]) {
    if (props.length === 0) {
      return null;
    }

    return props.map((prop, idx) => (
      <div className="d-flex flex-column" key={idx}>
        <GenotypeLabel>{prop.name}:</GenotypeLabel>
        <GenotypeValue>{prop.description}</GenotypeValue>
      </div>
    ));
  }

  const abilityProperties = useMemo(() => {
    return renderPhenotypeProperties(abilities);
  }, [abilities]);

  const coatColorProperties = useMemo(() => {
    return renderPhenotypeProperties(coats);
  }, [coats]);

  const healthIssueProperties = useMemo(() => {
    return renderPhenotypeProperties(healthIssues);
  }, [healthIssues]);

  const horseBreedPanelGenotypes = useMemo(() => {
    if (!horseBreedPanels || horseBreedPanels.length < 1) {
      return null;
    }

    setHasSummaryGenotypes(true);

    return horseBreedPanels
      .sort((a, b) => sortByStringKey(a, b, 'positionIndex'))
      .map((panel, index) => (
        <div className="d-flex flex-column" key={index}>
          <GenotypeLabel>{panel.name}:</GenotypeLabel>
          <GenotypeValue>
            <BreedPanelList panelTraits={panel.traits} />
          </GenotypeValue>
        </div>
      ));
  }, [horseBreedPanels]);

  const requiredTestProperties = useMemo(() => {
    if (!requiredTests || requiredTests.length < 1) {
      return null;
    }

    const inconclusiveTestsGroup = requiredTests.filter((x) => x.state === GeneType.Inconclusive);
    const notPerformedTestsGroup = requiredTests.filter((x) => x.state === GeneType.NotTested);

    const isInconclusiveTestsGroup = inconclusiveTestsGroup && inconclusiveTestsGroup.length > 0;
    const isNotPerformedTestsGroup = notPerformedTestsGroup && notPerformedTestsGroup.length > 0;

    return (
      <>
        {isInconclusiveTestsGroup && (
          <>
            <GenotypeLabel>Inconclusive tests:</GenotypeLabel>
            <GenotypeValue>{inconclusiveTestsGroup.map((x) => x.name).join(', ')}</GenotypeValue>
          </>
        )}
        {isNotPerformedTestsGroup && (
          <>
            <GenotypeLabel>Not performed tests:</GenotypeLabel>
            <GenotypeValue>{notPerformedTestsGroup.map((x) => x.name).join(', ')}</GenotypeValue>
          </>
        )}
      </>
    );
  }, [requiredTests]);

  return {
    abilityProperties,
    coatColorProperties,
    summaryGenotypes,
    hasSummaryGenotypes,
    healthIssueProperties,
    horseBreedPanelGenotypes,
    requiredTestProperties,
  };
}
