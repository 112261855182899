import {combineReducers} from 'redux';

import {
  BuildFoalActions,
  buildFoalRequestActionTypes,
  firstParentActionTypes,
  IBuildFoalState,
  secondParentActionTypes
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationBuildFoalReducer = combineReducers<IBuildFoalState['communications'], BuildFoalActions>({
  buildFoalRequesting: makeCommunicationReducerFromEnum(buildFoalRequestActionTypes),
  firstParentLoading: makeCommunicationReducerFromEnum(firstParentActionTypes),
  secondParentLoading: makeCommunicationReducerFromEnum(secondParentActionTypes)
});
