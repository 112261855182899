export enum GenotypeType {
  Color = 'Color',
  Danger = 'Danger',
  Gait = 'Gait',
  Modifier = 'Modifier',
  Partial = 'Partial',
  Speed = 'Speed',
  SimpleColor = 'SimpleColor',
  Temperament = 'Temperament',
  Health = 'Health',
  Height = 'Height',
  Other = 'Other'
}
