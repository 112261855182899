import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  getServiceModeActions,
  getServiceModeActionTypes,
  IAdminServiceModeState,
  setServiceModeActions,
  setServiceModeActionTypes
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import AdminServiceModeDataService from 'Admin/AdminAdvancedDashboard/services/AdminServiceModeDataService';
import {IAdminServiceModeRequest} from 'Admin/AdminAdvancedDashboard/models/IAdminServiceMode';

export const getServiceMode = (): ActionResult<
  IAdminServiceModeState,
  Promise<void>,
  getServiceModeActions
> => async dispatch => {
  dispatch(action(getServiceModeActionTypes.REQUEST));
  try {
    const res = await AdminServiceModeDataService.getOperatingModeControl();
    dispatch(action(getServiceModeActionTypes.SUCCESS, res));
  } catch (error) {
    dispatch(action(getServiceModeActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const setServiceMode = (
  request: IAdminServiceModeRequest
): ActionResult<IAdminServiceModeState, void, setServiceModeActions> => async dispatch => {
  try {
    dispatch(action(setServiceModeActionTypes.REQUEST));
    await AdminServiceModeDataService.setOperatingModeControl(request);
    dispatch(action(setServiceModeActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(setServiceModeActionTypes.FAILURE, getErrorMessage(error)));
  }
};
