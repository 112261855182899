import {combineReducers} from 'redux';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  addFavoriteHorseActionTypes,
  deleteFavoriteHorseActionTypes,
  FavoriteHorsesActions,
  getFavoriteHorsesActionTypes,
  IFavoriteHorseState,
} from '../types';

export const communicationReducer = combineReducers<IFavoriteHorseState['communications'], FavoriteHorsesActions>({
  favoriteHorsesLoading: makeCommunicationReducerFromEnum(getFavoriteHorsesActionTypes),
  favoriteHorseAdding: makeCommunicationReducerFromEnum(addFavoriteHorseActionTypes),
  favoriteHorseDeleting: makeCommunicationReducerFromEnum(deleteFavoriteHorseActionTypes),
});
