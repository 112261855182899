import {useUpdateUserPermissionsModal} from 'Admin/shared/helpers/hooks/useUserActions/useUpdateUserPermissionsModal';
import {useAdminEmployeeModal, useUpdateAdminEmployeeEmailModal, useUpdateAdminEmployeePasswordModal} from './parts';

interface IProps {
  onSuccess(): void;
}

export function useAdminEmployeeActions(props: IProps) {
  const {onSuccess} = props;

  const {
    createAdminEmployeeModal,
    updateAdminEmployeeModal,
    openCreateAdminEmployeeModal,
    openUpdateAdminEmployeeModal,
  } = useAdminEmployeeModal({onSuccess});

  const {openUpdateAdminEmployeePasswordModal, updateAdminEmployeePasswordModal} = useUpdateAdminEmployeePasswordModal(
    {}
  );

  const {openUpdateAdminEmployeeEmailModal, updateAdminEmployeeEmailModal} = useUpdateAdminEmployeeEmailModal({
    onSuccess,
  });

  const {openModalUserPermissionsModal, updateUserPermissionsModal} = useUpdateUserPermissionsModal({
    onSuccess,
  });

  return {
    createAdminEmployeeModal,
    updateAdminEmployeeModal,
    openCreateAdminEmployeeModal,
    openUpdateAdminEmployeeModal,
    openUpdateAdminEmployeePasswordModal,
    updateAdminEmployeePasswordModal,
    openUpdateAdminEmployeeEmailModal,
    updateAdminEmployeeEmailModal,
    openModalUserPermissionsModal,
    updateUserPermissionsModal,
  };
}
