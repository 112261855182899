import {breakpoints} from 'Common/constants/Breakpoints';
import styled from 'styled-components';

export const BusinessPortalCellContent = styled.div`
  padding: 4px 8px;

  @media ${breakpoints.sm} {
    padding: 8px 15px;
  }
`;

export const BusinessPortalSearch = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 16px;
  align-content: center;
  margin-bottom: 16px;

  flex-shrink: 0;

  @media ${breakpoints.sm} {
    height: 48px;
    grid-gap: 50px;
    grid-template-columns: auto 168px;
    margin-bottom: 0;
  }
`;

export const BusinessPortalTableWrapper = styled.div`
  margin-top: 8px;

  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
