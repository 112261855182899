import React, {memo} from 'react';

import Radiobutton, {RadioButtonProps} from 'Common/components/Controls/Radiobutton';
import {IFieldProps} from 'Common/models/IFieldProps';

import withField from './withField';

type IProps = IFieldProps<string | number> & RadioButtonProps;

function RadiobuttonField(props: IProps) {
  const {field, form, value, ...rest} = props;

  return <Radiobutton {...rest} {...field} value={value} checked={value?.toString() === field.value?.toString()} />;
}

export default memo(withField(RadiobuttonField));
