import React, {memo, useCallback, useEffect} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {RequiredBy} from 'Common/types';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage, FieldHint} from 'Common/components/StyledComponents/StyledComponents';
import {IAppState} from 'Common/store/IAppState';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {CheckboxField, ImageUploaderField, InputField} from 'Common/components/FormFields';
import Loading from 'Loading/components/Loading';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminEmployees';
import PasswordField from 'Common/components/FormFields/PasswordField';
import {FormType} from 'Common/constants/FormType';
import {createValidationSchema, IFormValues, initialValue, updateValidationSchema} from './validation';
import {convertToServerCreating, convertToServerUpdating} from './converters';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {IconName} from 'Icon/components/Icon';
import OrganizationSearchField from './parts/OrganizationSearchField';
import GeoLocationEdit from './parts/EmployeeMapField/EmployeeMapField';
import {ILocation} from 'Common/models/ICoordinatedLocation';

const formHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add Employee',
  edit: 'Update Employee',
};

const buttonHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add',
  edit: 'Save',
};

const CheckboxWrapper = styled.div`
  margin-bottom: 10px;
`;

interface IExternalProps {
  id?: number;
  type: FormType;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function EmployeeForm(props: AllProps) {
  const {status, setStatus, values, setFieldValue} = props;
  const {id, type} = props;
  const {
    getAdminEmployee,
    resetAdminEmployee,
    adminEmployeeLoading,
    adminEmployeeCreating,
    adminEmployeeUpdating,
    adminEmployeeAvatarUploading,
    adminEmployeeAvatarDeleting,
    adminEmployeeGeoLocationLoading,
    adminEmployeeGeoLocationUpdating,
  } = props;

  const errorInfo =
    adminEmployeeLoading.error ||
    adminEmployeeUpdating.error ||
    adminEmployeeCreating.error ||
    adminEmployeeAvatarUploading.error ||
    adminEmployeeAvatarDeleting.error ||
    adminEmployeeGeoLocationLoading.error ||
    adminEmployeeGeoLocationUpdating.error;

  const isRequesting = [
    adminEmployeeLoading,
    adminEmployeeUpdating,
    adminEmployeeCreating,
    adminEmployeeAvatarUploading,
    adminEmployeeAvatarDeleting,
    adminEmployeeGeoLocationLoading,
    adminEmployeeGeoLocationUpdating,
  ].some((i) => i.isRequesting);

  useEffect(() => {
    const commonError = getCommonErrors(errorInfo);
    commonError && setStatus(commonError);
  }, [errorInfo, setStatus]);

  useEffect(() => {
    const fetch = async (id: number) => {
      await getAdminEmployee(id);
    };
    if (id) {
      fetch(id);
    }

    return resetAdminEmployee;
  }, [id, getAdminEmployee, resetAdminEmployee]);

  const onSetLocation = useCallback(
    (geoLocation: ILocation) => {
      setFieldValue('location', geoLocation);
    },
    [setFieldValue]
  );

  const header = formHeaderByType[type];
  const saveButtonHeader = buttonHeaderByType[type];

  const isCreate = type === FormType.create;

  return (
    <>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent minHeight={isCreate ? 500 : undefined}>
          {isRequesting && <Loading />}

          <InputField isRequired={true} name="name" type="text" label="Name" placeholder="Name" className="w-50" />

          {isCreate && (
            <>
              <OrganizationSearchField name="organizationId" label="Organization" isRequired />
              <InputField
                isRequired={true}
                name="email"
                type="text"
                label="Email"
                placeholder="Email"
                className="w-50"
              />
              <PasswordField
                name="password"
                label="Password"
                placeholder="Password"
                autoComplete="new-password"
                isRequired={true}
              />
            </>
          )}

          <InputField name="phoneNumber" type="text" label="Phone" placeholder="Phone" className="w-50" />
          <InputField name="address" type="text" label="Address" placeholder="Address" className="w-50" />

          <FieldControlContainer label="Image">
            <ImageUploaderField
              name="avatar"
              iconProps={{name: IconName.RearedHorse, width: 48, height: 60}}
              title="Add image"
              isShowBlurredBackground={false}
            />
          </FieldControlContainer>

          <GeoLocationEdit employeeId={values.id} onSuccess={onSetLocation} />

          {isCreate && (
            <CheckboxWrapper className="d-flex flex-column justify-content-start align-items-start">
              <CheckboxField name="isActive" label="Active Employee" />
              <FieldHint>Enabling this option will allow the Employee to be active</FieldHint>
            </CheckboxWrapper>
          )}
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" isLoading={props.isSubmitting}>
            {saveButtonHeader}
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const AdminEmployeeFormWithFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({adminEmployee, adminEmployeeLoading}) => {
    const value =
      adminEmployee && !adminEmployeeLoading.isRequesting
        ? {...adminEmployee, location: null, locationAddress: ''}
        : initialValue;

    return value;
  },
  validationSchema: ({type}: IExternalProps) =>
    type === FormType.edit ? updateValidationSchema : createValidationSchema,
  handleSubmit: async (values, formikBag) => {
    const {
      type,
      createAdminEmployee,
      updateAdminEmployee,
      uploadAdminEmployeeAvatar,
      deleteAdminEmployeeAvatar,
      adminEmployee,
      onSuccess,
      updateAdminEmployeeGeoLocation,
    } = formikBag.props;

    formikBag.setSubmitting(true);

    try {
      if (type === FormType.create) {
        const createdAdminEmployeeId = await createAdminEmployee(convertToServerCreating(values));
        if (!!values.avatar?.file && createdAdminEmployeeId) {
          await uploadAdminEmployeeAvatar(createdAdminEmployeeId, values.avatar.file);
        }

        if (createdAdminEmployeeId && values.location) {
          await updateAdminEmployeeGeoLocation(createdAdminEmployeeId, values.location);
        }
      }

      if (type === FormType.edit) {
        await updateAdminEmployee(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
        if (!!values.id && !!adminEmployee?.avatar && !values.avatar) {
          await deleteAdminEmployeeAvatar(values.id);
        }
        if (!!values.id && !!values.avatar?.file) {
          await uploadAdminEmployeeAvatar(values.id, values.avatar.file);
        }
        if (!!values.id && !!values.location) {
          await updateAdminEmployeeGeoLocation(values.id, values.location);
        }
      }

      onSuccess && onSuccess();
    } catch (err) {
      formikBag.setStatus(err);
      return;
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(EmployeeForm);

const mapStateToProps = (state: IAppState) => ({
  adminEmployee: selectors.selectAdminEmployee(state),
  adminEmployeeLoading: selectors.selectCommunication(state, 'adminEmployeeLoading'),
  adminEmployeeCreating: selectors.selectCommunication(state, 'adminEmployeeCreating'),
  adminEmployeeUpdating: selectors.selectCommunication(state, 'adminEmployeeUpdating'),
  adminEmployeeAvatarUploading: selectors.selectCommunication(state, 'adminEmployeeAvatarUploading'),
  adminEmployeeAvatarDeleting: selectors.selectCommunication(state, 'adminEmployeeAvatarDeleting'),
  adminEmployeeGeoLocationLoading: selectors.selectCommunication(state, 'adminEmployeeGeoLocationLoading'),
  adminEmployeeGeoLocationUpdating: selectors.selectCommunication(state, 'adminEmployeeGeoLocationUpdating'),
});

const mapDispatchToProps = {
  getAdminEmployee: actions.getAdminEmployee,
  createAdminEmployee: actions.createAdminEmployee,
  updateAdminEmployee: actions.updateAdminEmployee,
  resetAdminEmployee: actions.resetAdminEmployee,
  uploadAdminEmployeeAvatar: actions.uploadAdminEmployeeAvatar,
  deleteAdminEmployeeAvatar: actions.deleteAdminEmployeeAvatar,
  updateAdminEmployeeGeoLocation: actions.updateAdminEmployeeGeoLocation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(AdminEmployeeFormWithFormik));
