import {Reducer} from 'redux';

import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';

import {IFindHorseState, Actions, findHorseActionTypes} from '../types';

const initialState: IFindHorseState['ui'] = {
  pagination: {countPerPage: MIN_COUNT_PER_PAGE, currentPage: 1, pageCount: 1, totalItemsCount: 1}
};

export const uiFindHorseReducer: Reducer<IFindHorseState['ui'], Actions> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case findHorseActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
