import {combineReducers} from 'redux';

import {
  AdminUsersActions,
  deleteUserByIdActionTypes,
  getUsersActionTypes,
  getUserByIdActionTypes,
  IAdminUsersState,
  resetUserDetailsActionTypes,
  createUserActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication/index';
import composeReducers from 'Common/store/composeReducers';

export const communicationUsersReducer = combineReducers<IAdminUsersState['communications'], AdminUsersActions>({
  usersLoading: makeCommunicationReducerFromEnum(getUsersActionTypes),
  userDetailsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getUserByIdActionTypes),
    makeResetCommunicationReducer(resetUserDetailsActionTypes.RESET),
  ]),
  deleteUserLoading: makeCommunicationReducerFromEnum(deleteUserByIdActionTypes),
  userCreating: makeCommunicationReducerFromEnum(createUserActionTypes),
});
