import React, {memo} from 'react';
import styled from 'styled-components';

import Nebula from 'Common/components/Layout/Nebula';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import Avatar, {AvatarProps} from 'Common/components/Avatar/Avatar';

const ARCHIVED_LABEL = 'Archived';
const DELETED_LABEL = 'Deleted';

const DisabledSticker = styled.span`
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size12};
  font-weight: ${Typography.weight.medium500};
  z-index: 1;
  margin-right: 8px;
`;

interface IProps {
  isArchived?: boolean;
  isDeleted?: boolean;
}

type AllProps = AvatarProps & IProps;

function AvatarHorse(props: AllProps) {
  const {isArchived, isDeleted, ...rest} = props;
  const isNebulaActive = isArchived || isDeleted;

  return (
    <Nebula active={isNebulaActive} className="position-relative">
      <>
        <div className="position-absolute d-flex">
          {isArchived && <DisabledSticker className="badge badge-warning">{ARCHIVED_LABEL}</DisabledSticker>}
          {isDeleted && <DisabledSticker className="badge badge-danger">{DELETED_LABEL}</DisabledSticker>}
        </div>
        <Avatar {...rest} />
      </>
    </Nebula>
  );
}

export default memo(AvatarHorse);
