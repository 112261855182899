import * as Yup from 'yup';

import {PaymentMethod} from 'Common/constants/PaymentMethod';
import {IHorseOrder} from 'BusinessPortal/models/order/createOrder/IHorseOrder';
import {ICreateOrderRequest} from 'Admin/AdminAssociations/models/Order/createOrder/ICreateOrderRequest';

import {CouponError} from 'BusinessPortal/services/constants/CouponError';
import {FieldSpecificErrors} from 'Common/models/FieldSpecificErrors';
import {IResponseError} from 'Common/models/IError';
import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import {ITestSummaryRequest} from 'Admin/AdminAssociations/services/types/summary';
import {IUseAssociationCreateOrderDiscountFormValues} from 'Shared/components/AssociationCreateOrderDiscount/hooks/useAssociationCreateOrderDiscount';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues extends IUseAssociationCreateOrderDiscountFormValues {
  sendEmailToUser: boolean;
  employeeId: number;
}

export const initialValues: IFormValues = {
  organizationId: 0,
  orders: [{horseId: 0, packages: [], tests: []}],
  coupons: [],
  paymentMethod: PaymentMethod.Stripe,
  sendEmailToUser: false,
  employeeId: 0,
};

export const validationSchema = Yup.object().shape<Pick<IFormValues, 'orders' | 'sendEmailToUser' | 'employeeId'>>({
  orders: Yup.array().of(
    Yup.object().shape({
      horseId: Yup.number().required('Horse is required').moreThan(0, 'Horse is required'),
      packages: Yup.array()
        .of(Yup.number())
        .test('packages', 'Packages or tests should be selected', function (value: number[]) {
          return value.length !== 0 || this.parent.tests.length !== 0;
        }),
      tests: Yup.array()
        .of(Yup.number())
        .of(Yup.number())
        .test('tests', 'Packages or tests should be selected', function (value: number[]) {
          return value.length !== 0 || this.parent.packages.length !== 0;
        }),
    })
  ),
  sendEmailToUser: Yup.boolean().test('sendEmailToUser', 'Send email to should be selected', function (value: boolean) {
    if (!value) return true;
    return this.parent.employeeId && this.parent.employeeId.length !== 0;
  }),
  employeeId: Yup.number().required(REQUIRED_FIELD).moreThan(0, REQUIRED_FIELD),
});

export function convertFormValuesToRequest(values: IFormValues): ICreateOrderRequest {
  const {orders, paymentMethod, isPaid, organizationId, sendEmailToUser, employeeId} = values;
  return {
    organizationId: organizationId || 0,
    isPaid,
    paymentMethod,
    horses: orders,
    sendEmailToUser,
    employeeId,
  };
}

export function convertTestSummaryToServer(
  horses: IHorseOrder[],
  coupons: IOrderCoupon[]
): Omit<ITestSummaryRequest, 'organizationId'> {
  return {
    orders: horses.map((horse) => ({
      ...horse,
      couponId: coupons.find((i) => (i.horses?.find((j) => j === horse.horseId) ? i.id : undefined))?.id || undefined,
    })),
  };
}

const couponErrors: Record<CouponError, string> = {
  [CouponError.EntityNotFound]: `Coupon not found.`,
  [CouponError.CannotBeAppliedCoupon]: `Coupon doesn't contain selected packages and can’t be used. You can use another coupon or change packages.`,
  [CouponError.CouponIsDisabled]: `Coupon is inactive.`,
  [CouponError.ExpiryCoupon]: `Coupon is expired.`,
};

const DEFAULT_COUPON_ERROR = 'Error on loading coupon.';

export function getCouponError(errorResponse: IResponseError): string {
  const error = errorResponse?.errors?.[0];
  if (error?.errorCode) {
    return couponErrors[error.errorCode] || DEFAULT_COUPON_ERROR;
  }
  return error?.description || DEFAULT_COUPON_ERROR;
}

export type ServerErrors = Record<string, string>;
export type FormErrors = IFormValues;

export function convertErrors(
  serverErrors: FieldSpecificErrors<ServerErrors> | undefined
): FieldSpecificErrors<IFormValues> {
  if (!serverErrors) {
    return {paymentMethod: '', orders: ''};
  }

  const convertedErrors = {};
  Object.keys(serverErrors).forEach((key) => {
    const newKey = key.replace('horses', 'orders');
    convertedErrors[newKey] = serverErrors[key];
  });

  return convertedErrors;
}
