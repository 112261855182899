import {IOrderHistory} from 'Admin/AdminDashboard/components/shared/OrderHistory/parts/common';
import {IOrderAdmin} from 'Admin/AdminDashboard/models/Order/IOrderAdmin';

export function convertOrderAdminToOrderHistory(order: IOrderAdmin): IOrderHistory {
  return {
    ...order,
    createDate: order.date,
    horses: order.horses.map(item => ({...item}))
  };
}
