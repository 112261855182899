import {ICommunication} from './types';

/**
 * Composes ICommunication states for multiple selectors.
 * `isRequesting` will be set to `true` if any of the keys have a true `isRequesting`.
 * `isSuccess` will be `true` only if all keys' `isSuccess` are true.
 * `error` will be set to the first non-null value of `error` found.
 */
const composeCommunication =
  <S, K>(
    communications: [
      {
        selectCommunication: (state: S, key: K) => ICommunication;
      },
      K
    ][]
  ) =>
  (state: S): ICommunication =>
    communications.reduce<ICommunication>(
      ({isRequesting, isSuccess, error}, [selector, key]) => ({
        isRequesting: isRequesting || selector.selectCommunication(state, key).isRequesting,
        isSuccess: isSuccess && selector.selectCommunication(state, key).isSuccess,
        error: error || selector.selectCommunication(state, key).error,
      }),
      {
        isRequesting: false,
        isSuccess: true,
        error: undefined,
      }
    );

export default composeCommunication;
