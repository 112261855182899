import {IModule} from 'redux-dynamic-modules';

import {signupReducer} from './reducer';
import {ISignupState} from './types';

export interface ISignupModuleState {
  signup: ISignupState;
}

export const SignupModule: IModule<ISignupModuleState> = {
  id: 'signup',
  reducerMap: {
    signup: signupReducer
  }
};
