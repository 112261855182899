import {combineReducers} from 'redux';

import {IAdminHorsesState} from '../types';
import {communicationHorsesReducer} from './communications';
import {dataHorsesReducer} from './data';
import {uiHorsesReducer} from './ui';

export const horsesReducer = combineReducers<IAdminHorsesState>({
  communications: communicationHorsesReducer,
  data: dataHorsesReducer,
  ui: uiHorsesReducer,
});
