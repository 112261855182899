import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';

import {convertPaymentDetailsToClient, convertPaymentDetailsToRequest} from './converters/payment';
import {IAdminPaymentDetails, IAdminPaymentDetailsHorse} from 'Admin/AdminAssociations/models/IAdminPaymentDetails';
import {IServerPaymentUpdateRequest} from './types/payment';

const getPaymentDetails = async (orderId: number): Promise<IAdminPaymentDetails> => {
  const response = await axiosWrapper.get<IObjectResponse<IAdminPaymentDetails>>(
    `/AssociationOrderPaymentStatusDetailsAdmin/${orderId}`
  );
  return convertPaymentDetailsToClient(response.data.data);
};

const getPaymentDetailsHorses = async (orderId: number): Promise<IListResponse<IAdminPaymentDetailsHorse>> => {
  const response = await axiosWrapper.get<IListResponse<IAdminPaymentDetailsHorse>>(
    `/AssociationRelatedOrdersAdmin/${orderId}`
  );
  return response.data;
};

const updatePaymentDetails = async (data: IAdminPaymentDetails): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<IServerPaymentUpdateRequest>>(
    `/AssociationOrderPaymentStatusUpdate`,
    convertPaymentDetailsToRequest(data)
  );
};

export default {
  getPaymentDetails,
  getPaymentDetailsHorses,
  updatePaymentDetails,
};
