import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {PayPalButton as PayPalButtonComponent, PayPalButtonProps} from 'react-paypal-button-v2';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {getEnvParams} from 'Common/helpers/getEnvParams';
import Loading from 'Loading/components/Loading';
import Nebula from 'Common/components/Layout/Nebula';
import {useLogger} from 'Common/helpers/hooks/useLogger';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {convertToLoggerContext} from 'Common/helpers/convertToLoggerContext';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Payment/store/orderPayment';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';

const buttonStyle = {height: 48, color: 'blue'};
const nebulaStyle = {opacity: 0.5};
const DISABLE_FUNDING = 'credit,bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort,venmo';
const PAYPAL_EMPTY_KEY = 'PayPal clientId key is empty. For now order`s payment via PayPal is not allowed.';
const PAYPAL_ERROR = 'Error during PayPal payment';

const {payPalClientId} = getEnvParams();

const Root = styled.div<{variants: variantTypes}>`
  position: relative;
  overflow: hidden;
  height: ${(prop) => (prop.variants === 'all' ? '110px' : '48px')};
`;

const ButtonContainer = styled.div<{variants: variantTypes}>`
  position: absolute;
  width: 100%;
  ${(prop) => prop.variants === 'card-only' && 'top: -62px;'}
`;

type variantTypes = 'all' | 'card-only' | 'paypal-only';

const TypedPayPalButtonComponent = PayPalButtonComponent as React.ComponentType<
  PayPalButtonProps & {
    onShippingChange?: (data: any, actions: any) => void;
    onClick?: (data: any, actions: any) => void;
    onInit?: (data: any, actions: any) => void;
    onCancel?: (data: any) => void;
  }
>;

interface IProps {
  disabled?: boolean;
  hidden?: boolean;
  variants?: variantTypes;
  token?: string;
  onSuccess?(details: any, data: any): void;
  onClick?(): void;
  onClickError?(): void;
  onCancel?(): void;
  onInit?(): void;
  onError?(error?: any): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IProps & IConnected;

function PayPalButton(props: AllProps) {
  const {
    onSuccess,
    onClick,
    onClickError,
    onInit,
    onCancel,
    onError,
    disabled,
    hidden,
    variants = 'all',
    token,
    payPalOrderLoading,
    getPayPalOrder,
  } = props;

  const [isButtonLoaded, setIsButtonLoaded] = useState(false);
  const [error, setError] = useState<string>();
  const {logger} = useLogger();
  const orderToken = useRef<string>(String(token) || '');

  const isLoaded = (!disabled && isButtonLoaded) || payPalOrderLoading.isRequesting;
  const onButtonReady = useCallback(() => {
    setIsButtonLoaded(true);
  }, []);

  const options = useMemo(() => {
    if (!payPalClientId) {
      console.error(PAYPAL_EMPTY_KEY);
      setError(PAYPAL_EMPTY_KEY);
      logger.error('PayPal error', convertToLoggerContext({description: PAYPAL_EMPTY_KEY}));
      return;
    }

    return {clientId: payPalClientId, disableFunding: DISABLE_FUNDING};
  }, [logger]);

  useEffect(() => {
    if (orderToken) {
      orderToken.current = token || '';
    }
  }, [token]);

  const payPalOrderError = payPalOrderLoading.error;
  const payPalOrderCommonErrors = useMemo(() => {
    return getCommonErrors(payPalOrderError);
  }, [payPalOrderError]);

  const onPayPalOrderError = useCallback(() => {
    if (payPalOrderCommonErrors) {
      onError && onError(payPalOrderCommonErrors || PAYPAL_ERROR);
    }
  }, [onError, payPalOrderCommonErrors]);

  useOnErrorCommunication(payPalOrderLoading, onPayPalOrderError);

  const handleOnError = useCallback(
    (error) => {
      logger.error('PayPal error', convertToLoggerContext(error));
      setError(error.message || PAYPAL_ERROR);
      onError && onError(error.message || PAYPAL_ERROR);
    },
    [logger, onError]
  );

  const handleOnClick = useCallback(
    async (data: any, actions: any) => {
      if (onClick) {
        try {
          await onClick();
          return actions.resolve();
        } catch (error) {
          logger.error('PayPal onClick error', convertToLoggerContext(error));
          onClickError && onClickError();
          return actions.reject();
        }
      }
    },
    [logger, onClick, onClickError]
  );

  const handleOnInit = useCallback(
    (data: any, actions: any) => {
      if (!onInit) {
        return;
      }

      actions.disable();
      onInit();
      actions.enable();
    },
    [onInit]
  );

  const handleOnCancel = useCallback(
    (data: any) => {
      onCancel && onCancel();
    },
    [onCancel]
  );

  const handleOnSuccess = useCallback(
    (details: any, data: any) => {
      if (onSuccess) {
        onSuccess({...details, token}, data);
      }
    },
    [onSuccess, token]
  );

  const onCreateOrder = useCallback(
    async (data: any, actions: any) => {
      if (!orderToken.current) {
        actions.reject();
        return;
      }
      try {
        const res = await getPayPalOrder(orderToken.current);
        if (res === null) {
          actions.reject();
          return;
        }
        return res.payPalOrderId;
      } catch (e) {
        logger.error('PayPal create order error', convertToLoggerContext(e));
        actions.reject();
        return;
      }
    },
    [getPayPalOrder, logger]
  );

  function onShippingChange(data: any, actions: any) {
    // Workaround for showing Credit/Debit card in the separate window
    return actions.resolve();
  }

  return (
    <>
      {!hidden && (
        <Root className="position-relative" variants={variants}>
          <ButtonContainer variants={variants}>
            <Nebula active={disabled} style={nebulaStyle}>
              {!isLoaded && !error && <Loading />}
              {!error && (
                <TypedPayPalButtonComponent
                  onSuccess={handleOnSuccess}
                  onError={handleOnError}
                  options={options}
                  onButtonReady={onButtonReady}
                  style={buttonStyle}
                  shippingPreference="NO_SHIPPING"
                  onShippingChange={onShippingChange}
                  onClick={handleOnClick}
                  createOrder={onCreateOrder}
                  onInit={handleOnInit}
                  onCancel={handleOnCancel}
                />
              )}
            </Nebula>
          </ButtonContainer>

          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Root>
      )}
    </>
  );
}

PayPalButton.defaultProps = {
  disabled: false,
};

const mapStateToProps = (state: IAppState) => ({
  payPalOrder: selectors.selectPayPalOrder(state),
  payPalOrderLoading: selectors.selectCommunication(state, 'payPalOrderLoading'),
});

const mapDispatchToProps = {
  getPayPalOrder: actions.getPayPalOrder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(PayPalButton));
