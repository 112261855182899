import React, {memo} from 'react';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import {VisitorType} from 'Common/constants/VisitorType';
import UserProfile, {UserProfileRouteProps} from 'Shared/components/UserProfile/UserProfile';
import {UserProfileType} from 'Shared/models/UserProfileType';

const AdminAssociationOwnerProfileLayout = (props: UserProfileRouteProps) => {
  const {
    match: {
      params: {userProfileType},
    },
  } = props;

  return (
    <BaseLayout withoutPaddings={true} backButtonStyle={{marginTop: 20}}>
      <UserProfile visitorType={VisitorType.AssociationAdmin} userProfileType={userProfileType as UserProfileType} />
    </BaseLayout>
  );
};

export default memo(AdminAssociationOwnerProfileLayout);
