import React, {memo} from 'react';
import styled from 'styled-components';
import Typography from 'Common/constants/Typography';
import {breakpoints} from 'Common/constants/Breakpoints';

const Root = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-size: ${Typography.size.size16};
  line-height: 28px;
  padding-top: 8px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size20};
    line-height: 32px;
    padding-top: 0;
  }
`;

const Title = styled.p`
  font-weight: ${Typography.weight.medium500};
`;

const Text = styled.p`
  font-weight: ${Typography.weight.normal400};
  margin-bottom: 32px;
`;

function GeneralInfo() {
  return (
    <Root>
      <Title>Variant</Title>
      <Text>
        One of two or more alternative forms of a gene that arise by mutation and are found at the same place on a
        chromosome
      </Text>

      <Title>Variants: Heterozygous vs. Homozygous?</Title>
      <Text>
        Variant calls are written in a way that denotes their origin and whether they are DOMINANT (uppercase) or
        recessive (lowercase). For example, at MC1R (also known as extension), Black is dominant and thus written as "E"
        whereas Red is recessive and thus denoted as "e". Therefore, an EE horse is homozygous for Black (and thus
        appears black), an ee horse is homozygous for Red (appears Red), and an Ee horse is heterozygous (shows the
        dominant variant, thus is Black).
      </Text>

      <Title>Gene</Title>
      <Text>
        A unit of heredity that is transferred from a parent to offspring and is thought to determine some
        characteristic of the offspring.
      </Text>

      <Title>Genotype</Title>
      <Text>The genetic constitution or make up of an individual organism</Text>

      <Title>Heterozygous</Title>
      <Text>A pair of genes which are different (not the same). One is typically dominant and one recessive</Text>

      <Title>Phenotype:</Title>
      <Text>
        The observable or visible characteristics of an individual resulting from their genotype or the interaction of
        their various genes and environment
      </Text>

      <Text style={{paddingTop: 32}}>
        The results depicted in this report do not constitute veterinary or medical advice. Any medical of veterinary
        advice should be sought from your veterinarian regarding these results or any health issues or questions you may
        have about your animal. Breed, sex, gene interaction, unknown genes and individual variances may impact the
        results, phenotypes, and behaviors in any animal in unknown and unpredictable ways. Please be advised that your
        animals' health is important to us and you should feel free to contact us should you have any further questions
        or feedback on our diagnostic platform, results reporting, or general questions. We value your input and thank
        you!
      </Text>
    </Root>
  );
}

export default memo(GeneralInfo);
