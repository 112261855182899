import React, {memo, useCallback} from 'react';
import styled from 'styled-components';

import UserHorseOverviewLayout, {
  UserHorseOverviewProps,
} from 'UserProfile/components/shared/UserHorseOverviewLayout/UserHorseOverviewLayout';
import withExcludeHorseActions, {
  getWithExcludeHorseActionsModules,
  IWithExcludeHorseActionsProps,
} from 'Common/helpers/withExcludeHorseActions';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {ContainerBase} from 'Shared/components/UserProfile/parts/UserHorsesTab/UserHorse/UserHorseButtons/styled';
import {breakpoints} from 'Common/constants/Breakpoints';
import {mediumMdSmBreakpoint} from 'UserProfile/components/TabsData/common/constants';
import ExcludeHorseButton from 'ExcludeHorse/components/ExcludeHorseButton';

const ButtonsWrapper = styled(ContainerBase)`
  width: 100%;
  flex-direction: column;

  @media ${breakpoints.sm} {
    width: 60%;
    justify-content: flex-start;
  }

  @media ${mediumMdSmBreakpoint} {
    width: 168px;
    flex-direction: column;
    align-items: center;
  }

  @media ${breakpoints.md} {
    align-self: start;
  }
`;

type IExternalProps = Omit<UserHorseOverviewProps, 'isEmptyOrder'>;

type AllProps = IExternalProps & IWithExcludeHorseActionsProps;

function UserExcludeHorse(props: AllProps) {
  const {
    horse: {id, isExcluded},
    excludeHorseDeleting,
    addExcludeHorse,
    deleteExcludeHorse,
    onSuccess,
    isOwnHorse,
    horse,
    visitorType,
  } = props;

  useErrorCommunicationToToast(excludeHorseDeleting, undefined, 1);

  const onAddExcludedHorse = useCallback(async () => {
    await addExcludeHorse(id);
  }, [id, addExcludeHorse]);

  const onDeleteExcludedHorse = useCallback(async () => {
    await deleteExcludeHorse(id);
  }, [id, deleteExcludeHorse]);

  return (
    <UserHorseOverviewLayout horse={horse} isOwnHorse={isOwnHorse} onSuccess={onSuccess} visitorType={visitorType}>
      <ButtonsWrapper>
        <ExcludeHorseButton
          isExcluded={isExcluded}
          onAddExcludedHorse={onAddExcludedHorse}
          onDeleteExcludedHorse={onDeleteExcludedHorse}
        />
      </ButtonsWrapper>
    </UserHorseOverviewLayout>
  );
}

const Connected = withExcludeHorseActions(memo(UserExcludeHorse));

export default withDynamicModules(Connected, getWithExcludeHorseActionsModules());
