import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  getHorsesByOwnerActions,
  getHorsesByOwnerActionTypes,
  getOwnerActions,
  getOwnerActionTypes,
  IBusinessPortalOwnerProfileState,
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import BusinessPortalOwnerDataService from 'BusinessPortal/services/BusinessPortalOwnerDataService';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IAppState} from 'Common/store/IAppState';

export const getUser =
  (
    id: number,
    isGeneralUser: boolean,
    isAssociationOwner?: boolean
  ): ActionResult<IBusinessPortalOwnerProfileState, void, getOwnerActions> =>
  async (dispatch) => {
    dispatch(action(getOwnerActionTypes.REQUEST));

    await BusinessPortalOwnerDataService.getUser(id, isGeneralUser, isAssociationOwner)
      .then((res) => {
        dispatch(action(getOwnerActionTypes.SUCCESS, res.data.data));
      })
      .catch((error) => {
        dispatch(action(getOwnerActionTypes.FAILURE, getErrorMessage(error)));
      });
  };

export const getHorses =
  (
    params: IRequestParams,
    id: number,
    isGeneralUser: boolean
  ): ActionResult<IAppState, Promise<void>, getHorsesByOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorsesByOwnerActionTypes.REQUEST));
      const res = await BusinessPortalOwnerDataService.getHorses(params, id, isGeneralUser);
      dispatch(action(getHorsesByOwnerActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getHorsesByOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAssociationUser =
  (id: number): ActionResult<IBusinessPortalOwnerProfileState, void, getOwnerActions> =>
  async (dispatch) => {
    dispatch(action(getOwnerActionTypes.REQUEST));

    await BusinessPortalOwnerDataService.getAssociationUser(id)
      .then((res) => {
        dispatch(action(getOwnerActionTypes.SUCCESS, res.data.data));
      })
      .catch((error) => {
        dispatch(action(getOwnerActionTypes.FAILURE, getErrorMessage(error)));
      });
  };

export const getAssociationHorses =
  (params: IRequestParams, organizationId: number): ActionResult<IAppState, Promise<void>, getHorsesByOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorsesByOwnerActionTypes.REQUEST));
      const res = await BusinessPortalOwnerDataService.getAssociationHorses(params, organizationId);
      dispatch(action(getHorsesByOwnerActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getHorsesByOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
