import React, {useCallback, useEffect} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {initialValue, IFormValues, validationSchema} from './validation';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {IAppState} from 'Common/store/IAppState';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {ICommunication} from 'Common/store/utils/communication/types';
import {FormType} from 'Common/constants/FormType';
import {CheckboxField, InputField, SelectField, TextAreaField} from 'Common/components/FormFields';
import {actions as packageActions, selectors as packageSelectors} from 'Admin/AdminAssociations/store/adminPackages';
import Loading from 'Loading/components/Loading';
import {ITest} from 'Dictionaries/models/ITest';
import {castToOption} from 'Common/helpers/OptionHelper';
import {convertPackageFormToPackageRequest, convertPackageToPackageForm} from './converters';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {IAdminAssociationPackage} from 'Admin/AdminAssociations/models/IAdminAssociationPackage';
import {Nullable} from 'Common/types';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {IFoundOrganization} from 'Admin/AdminAssociations/models/IFoundOrganization';
import {useDictionaries} from 'Common/store/useDictionaries';
import SpecialPriceField from '../../shared/SpecialPriceField';
import {IReportType} from 'Dictionaries/models/IReportType';
import {useToast} from 'Common/helpers/hooks/useToast';
import {IPackageGroup} from 'Dictionaries/models/IPackageGroup';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import HintIcon from 'Common/components/HintIcont/HintIcon';

const CHECKBOX_VALIDATION_MESSAGE = 'At least one option must be selected';

const formHeaderByType: Record<FormType.create | FormType.edit | FormType.createBased, string> = {
  create: 'Add package',
  edit: 'Edit item',
  createBased: 'Add package',
};

const buttonHeaderByType: Record<FormType.create | FormType.edit | FormType.createBased, string> = {
  create: 'Add',
  edit: 'Save',
  createBased: 'Add',
};

interface IExternalDictionaries {
  adminOrganizationDictionary: ISimpleDictionary<IFoundOrganization>;
  testDictionary: ISimpleDictionary<ITest>;
  reportTypeDictionary: ISimpleDictionary<IReportType>;
  packageGroupsDictionary: ISimpleDictionary<IPackageGroup>;
}

interface IExternalProps extends IExternalDictionaries {
  packageInfo: Nullable<IAdminAssociationPackage>;
  type: FormType;
  externalCommunication: ICommunication;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const PackageForm = (props: AllProps) => {
  const {status, setStatus, setErrors, isSubmitting, values, handleSubmit} = props;
  const {
    adminOrganizationDictionary,
    testDictionary,
    reportTypeDictionary,
    organizations,
    reportTypes,
    tests,
    type,
    externalCommunication,
    packageGroupsDictionary,
    packageGroups,
    onSuccess,
  } = props;
  const {packageCreating, packageUpdating} = props;

  const errorInfo = externalCommunication.error || packageCreating.error || packageUpdating.error;
  const isRequesting = [externalCommunication, packageCreating, packageUpdating].some((i) => i.isRequesting);

  const header = formHeaderByType[type];
  const saveButtonHeader = buttonHeaderByType[type];

  useOnSuccessCommunication(packageUpdating, onSuccess);
  useOnSuccessCommunication(packageCreating, onSuccess);

  const {
    actions: {getItems: getOrganizations},
  } = adminOrganizationDictionary;
  const {
    actions: {getItems: getTests},
  } = testDictionary;
  const {
    actions: {getItems: getReportTypes},
  } = reportTypeDictionary;
  const {
    actions: {getItems: getPackageGroups},
  } = packageGroupsDictionary;

  useEffect(() => {
    getOrganizations();
    getTests();
    getReportTypes();
    getPackageGroups();
  }, [getOrganizations, getPackageGroups, getReportTypes, getTests]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    const fieldErrors = getFieldErrors(errorInfo);

    if (commonErrors) {
      setStatus(commonErrors);
    }

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  const {addToast} = useToast();

  const onSaveClick = useCallback(() => {
    if (!values.isAvailabilityAllOrganizations && !values.isSpecialPrices) {
      addToast(CHECKBOX_VALIDATION_MESSAGE, 'error');
      return;
    }
    handleSubmit();
  }, [addToast, handleSubmit, values.isAvailabilityAllOrganizations, values.isSpecialPrices]);

  return (
    <>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {isRequesting && <Loading />}
          <InputField isRequired={true} name="name" type="text" label="Name" placeholder="Name" autoComplete="off" />
          <InputField
            name="abbreviation"
            type="text"
            label="Abbreviation"
            placeholder="Abbreviation"
            className="w-50"
            autoComplete="off"
          />
          <SelectField isMulti={true} name="tests" label="Tests" options={castToOption(tests)} menuPosition="fixed" />
          <SelectField
            isMulti={true}
            name="reportTypes"
            label="Report Types"
            options={castToOption(reportTypes)}
            className="w-50"
          />
          <TextAreaField
            name="description"
            label="Description"
            placeholder="Add description to notice some facts about package"
          />

          <SpecialPriceField
            specialPrices={values.specialPrices}
            organizations={organizations}
            isAvailabilityAllOrganizations={values.isAvailabilityAllOrganizations}
            isSpecialPrices={values.isSpecialPrices}
          />

          <FormControlContainer title="Package settings for Shopping cart">
            <div className="d-flex w-100 align-items-center">
              <div className="w-50">
                <SelectField
                  isMulti={true}
                  name="groups"
                  label="Package groups"
                  options={castToOption(packageGroups)}
                  menuPosition="fixed"
                />
              </div>
              <HintIcon tooltip="Which 'Categories' it belongs to in Shopping cart" />
            </div>

            <div className="d-flex w-100 align-items-center">
              <div className="w-50">
                <InputField name="positionIndex" label="Order in Category" placeholder="Order in Category" />
              </div>
            </div>

            <CheckboxField name="isBundle" label="Included to 'Bundles'" style={{marginBottom: 8}} />
          </FormControlContainer>
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="button" onClick={onSaveClick} isLoading={isSubmitting}>
            {saveButtonHeader}
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const PackageFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({packageInfo, type}) => (packageInfo ? convertPackageToPackageForm(packageInfo) : initialValue),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const convertedValues = convertPackageFormToPackageRequest(values);
    const request =
      formikBag.props.type === FormType.create || formikBag.props.type === FormType.createBased
        ? formikBag.props.createPackage
        : formikBag.props.updatePackage;

    formikBag.setSubmitting(true);
    await request(convertedValues);
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(PackageForm);

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {adminOrganizationDictionary, testDictionary, reportTypeDictionary, packageGroupsDictionary} = props;
  const {selectors: organizationSelectors} = adminOrganizationDictionary;
  const {selectors: testSelectors} = testDictionary;
  const {selectors: reportTypeSelectors} = reportTypeDictionary;
  const {selectors: packageGroupSelectors} = packageGroupsDictionary;

  return {
    packageCreating: packageSelectors.selectCommunication(state, 'packageCreating'),
    packageUpdating: packageSelectors.selectCommunication(state, 'packageUpdating'),
    organizations: organizationSelectors.selectItems(state),
    organizationsLoading: organizationSelectors.selectCommunication(state, 'itemsLoading'),
    tests: testSelectors.selectItems(state),
    testsLoading: testSelectors.selectCommunication(state, 'itemsLoading'),
    reportTypes: reportTypeSelectors.selectItems(state),
    reportTypesLoading: reportTypeSelectors.selectCommunication(state, 'itemsLoading'),
    packageGroups: packageGroupSelectors.selectItems(state),
    packageGroupsLoading: packageGroupSelectors.selectCommunication(state, 'itemsLoading'),
  };
};

const mapDispatchToProps = {
  createPackage: packageActions.createPackage,
  updatePackage: packageActions.updatePackage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(PackageFormFormik);
const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {adminOrganizations, tests, reportTypes, associationPackageGroups} = useDictionaries();

  return (
    <Connected
      {...externalProps}
      testDictionary={tests}
      adminOrganizationDictionary={adminOrganizations}
      reportTypeDictionary={reportTypes}
      packageGroupsDictionary={associationPackageGroups}
    />
  );
};

export default Exported;
