import React, {memo, useEffect, useState} from 'react';
import {Form, withFormik} from 'formik';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps} from 'formik/dist/types';

import {InputField} from 'Common/components/FormFields';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {actions, selectors} from 'BusinessPortal/store/horse';
import {useDictionaries} from 'Common/store/useDictionaries';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {IAssociation} from 'Dictionaries/models/IAssociation';
import RegistriesList from 'HorseProfile/components/HorseProfileForm/parts/RegistriesList/RegistriesList';
import {Parentage} from 'HorseProfile/constants/Parentage';
import {
  IFormValues,
  initialValue,
  validationSchema
} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/RegistrationAndParentageSection/validation';
import {Divider, TextBase} from 'BusinessPortal/components/common/styled';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {convertToClient} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/RegistrationAndParentageSection/converters';
import {IRegistrationAndParentageSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/RegistrationAndParentageSection/models/IRegistrationAndParentageSectionData';

const ParentageSection = styled(TextBase)`
  font-size: ${Typography.size.size20};
  line-height: 32px;
  letter-spacing: 0;
  margin-bottom: 16px;
`;

const ParentRole = styled.div`
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  text-transform: uppercase;
  color: ${Theme.color.black};
  margin-bottom: 16px;
`;

interface IExternalDictionaries {
  associationDictionary: ISimpleDictionary<IAssociation>;
}

type IExternalProps = IExternalDictionaries & IEditableSection<IRegistrationAndParentageSectionData>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const HorseInfoSectionEdit = (props: AllProps) => {
  const {isValid, isSubmitting, values} = props;
  const {onSuccess, data, horseRegistriesUpdating, horseParentageUpdating} = props;
  const {associationDictionary, associations, dictionaryLoading} = props;

  const {
    actions: {getItems: getAssociations}
  } = associationDictionary;

  useEffect(() => {
    getAssociations();
  }, [getAssociations]);

  const isRequesting = horseRegistriesUpdating.isRequesting || horseParentageUpdating.isRequesting || dictionaryLoading;

  const [isRegistriesSuccessUpdating, setIsRegistriesSuccessUpdating] = useState(false);
  const [isParentageSuccessUpdating, setIsParentageSuccessUpdating] = useState(false);

  useOnSuccessCommunication(horseRegistriesUpdating, () => setIsRegistriesSuccessUpdating(true));
  useOnSuccessCommunication(horseParentageUpdating, () => setIsParentageSuccessUpdating(true));

  useEffect(() => {
    if (isRegistriesSuccessUpdating && isParentageSuccessUpdating) {
      onSuccess && onSuccess();
    }
  }, [isParentageSuccessUpdating, isRegistriesSuccessUpdating, onSuccess]);

  if (isRequesting) {
    return <Loading />;
  }

  return (
    <Form>
      <RegistriesList fieldName="registries" registries={values.registries || []} associations={associations} />
      <Divider />

      <ParentageSection>Parentage</ParentageSection>
      <ParentRole>Sire</ParentRole>
      <InputField
        name={`parents[${data?.parentage?.findIndex(i => i.parent === Parentage.Sire)}].name`}
        label="Name"
        placeholder="Name"
        memoized={true}
      />
      <RegistriesList
        fieldName={`parents[${data?.parentage?.findIndex(i => i.parent === Parentage.Sire)}].registries`}
        registries={values.parents.find(i => i.parent === Parentage.Sire)?.registries || []}
        associations={associations}
      />
      <Divider />

      <ParentRole>Dam</ParentRole>
      <InputField
        name={`parents[${data?.parentage?.findIndex(i => i.parent === Parentage.Dam)}].name`}
        label="Name"
        placeholder="Name"
        memoized={true}
      />
      <RegistriesList
        fieldName={`parents[${data?.parentage?.findIndex(i => i.parent === Parentage.Dam)}].registries`}
        registries={values.parents.find(i => i.parent === Parentage.Dam)?.registries || []}
        associations={associations}
      />
      <Divider />

      <PrimaryButton className="w-100" size="medium" type="submit" disabled={!isValid} isLoading={isSubmitting}>
        Save changes
      </PrimaryButton>
    </Form>
  );
};

const HorseInfoSectionFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({data}) => (data ? convertToClient(data) : initialValue),
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {updateHorseRegistries, updateHorseParentage} = formikBag.props;
    await updateHorseRegistries({horseId: values.horseId, registries: values.registries});
    await updateHorseParentage({horseId: values.horseId, parents: values.parents});
  },
  enableReinitialize: true
})(HorseInfoSectionEdit);

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {associationDictionary} = props;
  const {selectors: associationSelectors} = associationDictionary;

  return {
    associations: associationSelectors.selectItems(state),
    horseRegistriesUpdating: selectors.selectCommunication(state, 'horseRegistriesUpdating'),
    horseParentageUpdating: selectors.selectCommunication(state, 'horseParentageUpdating'),
    dictionaryLoading: associationSelectors.selectCommunication(state, 'itemsLoading').isRequesting
  };
};

const mapDispatchToProps = {
  updateHorseRegistries: actions.updateHorseRegistries,
  updateHorseParentage: actions.updateHorseParentage
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(HorseInfoSectionFormik);
const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {associations} = useDictionaries();

  return <Connected associationDictionary={associations} {...externalProps} />;
};

export default memo(Exported);
