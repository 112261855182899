import axios, {AxiosInstance, AxiosResponse} from 'axios';
import * as R from 'ramda';
import {datadogLogs} from '@datadog/browser-logs';

import LocalStorageService from 'Common/services/LocalStorageService';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {Token} from 'Common/constants/Token';
import {ILoginSuccess} from 'Auth/models/ILoginSuccess';
import {IObjectResponse} from 'Common/models/IResponse';
import {IForgotPassword} from 'ForgotPassword/models/IForgotPassword';
import {IResetPassword} from 'ResetPassword/models/IResetPassword';
import {getEnvParams} from 'Common/helpers/getEnvParams';
import {IAuthCredentials} from 'Auth/models/IAuthCredentials';
import {VisitorType} from 'Common/constants/VisitorType';
import {IVisitor} from 'Common/models/IVisitor';
import {Nullable} from 'Common/types';
import {convertToLoggerContext} from 'Common/helpers/convertToLoggerContext';

const MAINTAIN_STATUS_CODE = 503;
const NOT_FOUND_STATUS_CODE = 404;
const UNAUTHORIZED_STATUS_CODE = 401;

type Storage = typeof LocalStorageService;

class AuthService {
  private interceptorId?: number;
  private onLogout?: (clearLocation?: boolean) => void;
  private onMaintain?: () => void;

  constructor(private axiosInstance: AxiosInstance, private storage: Storage) {}

  public init(logout: () => void, onMaintain: () => void) {
    this.onLogout = logout;
    this.onMaintain = onMaintain;

    this.createResponseInterceptor();
  }

  public saveTokens(accessToken: string, refreshToken: string) {
    this.storage.setItem(Token.AccessToken, accessToken);
    this.storage.setItem(Token.RefreshToken, refreshToken);
  }

  public removeTokens() {
    this.storage.removeItem(Token.AccessToken);
    this.storage.removeItem(Token.RefreshToken);
  }

  public takeVisitorType(): Nullable<VisitorType> {
    return this.storage.getItem('visitorType');
  }

  public saveVisitorType(type: VisitorType) {
    this.storage.setItem('visitorType', type);
  }

  public removeVisitorType() {
    this.storage.removeItem('visitorType');
  }

  public removeOnlineReportType() {
    this.storage.removeItem('onlineReportType');
  }

  public removeDistributorStrategy() {
    this.storage.removeItem('distributorStrategy');
  }

  public async login(payload: IAuthCredentials): Promise<AxiosResponse<IObjectResponse<ILoginSuccess>>> {
    const {baseUrl} = getEnvParams();
    const response = await this.axiosInstance.post<IObjectResponse<ILoginSuccess>>(`${baseUrl}/api/Authentication`, {
      ...payload,
    });

    if (response.status !== 200) {
      throw Error(response.statusText);
    }

    const {tokenAccess, tokenRefresh} = response.data.data;
    this.saveTokens(tokenAccess, tokenRefresh);
    this.axiosInstance.defaults.headers.Authorization = `Bearer ${tokenAccess}`;
    this.createResponseInterceptor();

    return response;
  }

  public async getVisitorType(email: string): Promise<IVisitor> {
    const response = await this.axiosInstance.get<IObjectResponse<IVisitor>>(`/IdentityVisitor/${email}`);
    this.saveVisitorType(response.data.data.type);
    return response.data.data;
  }

  public logout(clearLocation?: boolean) {
    this.removeTokens();
    this.removeVisitorType();
    this.removeOnlineReportType();
    this.removeDistributorStrategy();
    this.axiosInstance.defaults.headers.Authorization = '';
    this.onLogout && this.onLogout(clearLocation);
  }

  public isAuthorized() {
    return !!(
      this.storage.getItem(Token.AccessToken) &&
      this.storage.getItem(Token.RefreshToken) &&
      this.storage.getItem('visitorType')
    );
  }

  public forgotPassword(data: IForgotPassword): Promise<AxiosResponse<IObjectResponse<string>>> {
    return this.axiosInstance.post<IObjectResponse<string>>(`/ForgotPassword`, data);
  }

  public resetPassword(data: IResetPassword): Promise<AxiosResponse<IObjectResponse<string>>> {
    return this.axiosInstance.post<IObjectResponse<string>>(`/ResetPassword`, data);
  }

  private createResponseInterceptor() {
    this.removeResponseInterceptor();

    this.interceptorId = this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const errorResponse = error.response;

        if (
          errorResponse?.status !== UNAUTHORIZED_STATUS_CODE &&
          errorResponse?.status !== MAINTAIN_STATUS_CODE &&
          errorResponse?.status !== NOT_FOUND_STATUS_CODE
        ) {
          try {
            if (
              error.request.responseType === 'blob' &&
              errorResponse.data instanceof Blob &&
              errorResponse.data.type &&
              errorResponse.data.type.toLowerCase().indexOf('json') !== -1
            ) {
              const convertBlob = async () => {
                return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.readAsText(errorResponse.data);

                  reader.onloadend = async () => {
                    error.response.data = JSON.parse(String(reader.result));
                    resolve(error);
                  };

                  reader.onerror = async () => {
                    reject(error);
                  };
                });
              };

              await convertBlob();
            }
          } finally {
            throw error;
          }
        }

        if (errorResponse.status === MAINTAIN_STATUS_CODE) {
          this.onMaintain && this.onMaintain();
          throw error;
        }

        if (errorResponse.status === NOT_FOUND_STATUS_CODE) {
          datadogLogs.logger.error(
            `API not found (404): ${error.request.responseURL}`,
            convertToLoggerContext(errorResponse)
          );
        }

        try {
          const {tokenAccess} = await this.refreshTokens();

          error.response.config.headers.Authorization = `Bearer ${tokenAccess}`;
          this.axiosInstance.defaults.headers.Authorization = `Bearer ${tokenAccess}`;
          return axios(error.response.config);
        } catch (err) {
          this.logout();
          throw err;
        } finally {
          this.createResponseInterceptor();
        }
      }
    );
  }

  private removeResponseInterceptor() {
    if (!R.isNil(this.interceptorId)) {
      this.axiosInstance.interceptors.response.eject(this.interceptorId);
      this.interceptorId = undefined;
    }
  }

  private async refreshTokens() {
    const {baseUrl} = getEnvParams();
    const refreshToken = this.storage.getItem(Token.RefreshToken);
    const response = await this.axiosInstance.post<IObjectResponse<{tokenAccess: string; tokenRefresh: string}>>(
      `${baseUrl}/api/RefreshToken`,
      {
        tokenRefresh: refreshToken,
      }
    );

    const {tokenAccess, tokenRefresh} = response.data.data;
    this.saveTokens(tokenAccess, tokenRefresh);

    return {tokenAccess, tokenRefresh};
  }
}

export default new AuthService(axiosWrapper, LocalStorageService);
