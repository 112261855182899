import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.excludeHorses;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectExcludeHorses(state: IAppState) {
  return selectState(state).data.excludeHorses;
}

export function selectSimpleExcludeHorses(state: IAppState) {
  return selectState(state).data.simpleExcludeHorses;
}
