import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';

import {IListResponse} from 'Common/models/IResponse';
import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';

const STATE_NAME = 'horseBreedPanel';

export interface IHorseBreedPanelState {
  data: {
    horseBreedPanels: IHorseBreedPanel[];
  };
  communications: {
    horseBreedPanelsLoading: ICommunication;
  };
}

export const getHorseBreedPanelsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_BREED_PANEL');
export type getHorseBreedPanelsActions = MakeCommunicationActions<
  typeof getHorseBreedPanelsActionTypes,
  {success: IListResponse<IHorseBreedPanel>}
>;

export const resetHorseBreedPanelsActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSE_BREED_PANEL');
export type resetHorseBreedPanelsActions = MakeResetCommunicationActions<typeof resetHorseBreedPanelsActionTypes>;

export type HorseBreedPanelsActions = getHorseBreedPanelsActions | resetHorseBreedPanelsActions;
