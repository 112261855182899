import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import OrderDataService from 'Admin/AdminDashboard/services/OrderDataService';
import AdminOrderDataService from 'Admin/AdminAssociations/services/AdminOrderDataService';

import {
  changeSampleActions,
  changeSampleActionTypes,
  deleteOrderActions,
  deleteOrderActionTypes,
  deleteReportActions,
  deleteReportActionTypes,
  downloadReportsActions,
  downloadReportsActionTypes,
  downloadSubmissionFormActions,
  downloadSubmissionFormActionTypes,
  getOrderDetailsActions,
  getOrderDetailsActionTypes,
  getOrderPaymentDetailsActions,
  getOrderPaymentDetailsActionTypes,
  getOrderReportDetailsActions,
  getOrderReportDetailsActionTypes,
  getOrderRequiredTestsActions,
  getOrderRequiredTestsActionTypes,
  getOrdersActions,
  getOrdersActionTypes,
  getOrderSampleDetailsActions,
  getOrderSampleDetailsActionTypes,
  getOrderStatusesDetailsActions,
  getOrderStatusesDetailsActionTypes,
  getOrderTestsDetailsActions,
  getOrderTestsDetailsActionTypes,
  IReleaseOrderHorse,
  IRequestChangeSample,
  IRequestNewSampleParams,
  IRequestSetSampleStatus,
  releaseOrderHorseActions,
  releaseOrderHorseActionTypes,
  requestNewSampleActions,
  requestNewSampleActionTypes,
  resetChangeSampleCommunicationActions,
  resetChangeSampleCommunicationActionTypes,
  resetChooseAnotherSampleCommunicationActions,
  resetChooseAnotherSampleCommunicationActionTypes,
  resetOrderDetailsActions,
  resetOrderDetailsActionTypes,
  setSampleStatusActions,
  setSampleStatusActionTypes,
  uploadReportActions,
  uploadReportActionTypes,
  uploadTestResultsActions,
  uploadTestResultsActionTypes,
} from './types';
import {IUploadTestReportRequest} from 'Admin/shared/models/IUploadTestReportRequest';

export const getOrders =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getOrdersActions> =>
  async (dispatch) => {
    dispatch(action(getOrdersActionTypes.REQUEST));
    try {
      const res = await AdminOrderDataService.getOrders(params);
      dispatch(action(getOrdersActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getOrdersActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };

export const getOrderDetails =
  (orderId: number): ActionResult<IAppState, Promise<void>, getOrderDetailsActions> =>
  async (dispatch) => {
    dispatch(action(getOrderDetailsActionTypes.REQUEST));
    try {
      const res = await AdminOrderDataService.getOrderDetails(orderId);
      dispatch(action(getOrderDetailsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getOrderDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrderPaymentDetails =
  (orderId: number): ActionResult<IAppState, Promise<void>, getOrderPaymentDetailsActions> =>
  async (dispatch) => {
    dispatch(action(getOrderPaymentDetailsActionTypes.REQUEST));
    try {
      const res = await AdminOrderDataService.getOrderPaymentDetails(orderId);
      dispatch(action(getOrderPaymentDetailsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getOrderPaymentDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const uploadTestResults =
  (uploadedFile: FormData): ActionResult<IAppState, void, uploadTestResultsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadTestResultsActionTypes.REQUEST));
      await AdminOrderDataService.uploadTestResults(uploadedFile);
      dispatch(action(uploadTestResultsActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(uploadTestResultsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const releaseOrderHorse =
  (data: IReleaseOrderHorse): ActionResult<IAppState, Promise<void>, releaseOrderHorseActions> =>
  async (dispatch) => {
    dispatch(action(releaseOrderHorseActionTypes.REQUEST));
    try {
      await AdminOrderDataService.releaseOrderHorse(data);
      dispatch(action(releaseOrderHorseActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(releaseOrderHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteOrder =
  (orderId: number): ActionResult<IAppState, Promise<void>, deleteOrderActions> =>
  async (dispatch) => {
    dispatch(action(deleteOrderActionTypes.REQUEST));
    try {
      await AdminOrderDataService.deleteOrder(orderId);
      dispatch(action(deleteOrderActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteOrderActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const requestNewSample =
  (params: IRequestNewSampleParams): ActionResult<IAppState, Promise<void>, requestNewSampleActions> =>
  async (dispatch) => {
    dispatch(action(requestNewSampleActionTypes.REQUEST));
    try {
      await AdminOrderDataService.requestNewSample(params);
      dispatch(action(requestNewSampleActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(requestNewSampleActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setSampleStatus =
  (params: IRequestSetSampleStatus): ActionResult<IAppState, Promise<void>, setSampleStatusActions> =>
  async (dispatch) => {
    dispatch(action(setSampleStatusActionTypes.REQUEST));
    try {
      await AdminOrderDataService.setSampleStatus(params);
      dispatch(action(setSampleStatusActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(setSampleStatusActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const changeSample =
  (params: IRequestChangeSample): ActionResult<IAppState, Promise<void>, changeSampleActions> =>
  async (dispatch) => {
    dispatch(action(changeSampleActionTypes.REQUEST));
    try {
      await AdminOrderDataService.changeSample(params);
      dispatch(action(changeSampleActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(changeSampleActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const downloadReports =
  (orderId: number): ActionResult<IAppState, Promise<void>, downloadReportsActions> =>
  async (dispatch) => {
    dispatch(action(downloadReportsActionTypes.REQUEST));
    try {
      await OrderDataService.downloadReports(orderId);
      dispatch(action(downloadReportsActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(downloadReportsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const downloadOrderSubmissionForm =
  (orderId: number): ActionResult<IAppState, Promise<void>, downloadSubmissionFormActions> =>
  async (dispatch) => {
    dispatch(action(downloadSubmissionFormActionTypes.REQUEST));
    try {
      await AdminOrderDataService.downloadOrderSubmissionForm(orderId);
      dispatch(action(downloadSubmissionFormActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(downloadSubmissionFormActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const downloadHorseSubmissionForm =
  (orderId: number, horseId: number): ActionResult<IAppState, Promise<void>, downloadSubmissionFormActions> =>
  async (dispatch) => {
    dispatch(action(downloadSubmissionFormActionTypes.REQUEST));
    try {
      await OrderDataService.downloadHorseSubmissionForm(orderId, horseId);
      dispatch(action(downloadSubmissionFormActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(downloadSubmissionFormActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrderRequiredTests =
  (orderId: number): ActionResult<IAppState, Promise<void>, getOrderRequiredTestsActions> =>
  async (dispatch) => {
    dispatch(action(getOrderRequiredTestsActionTypes.REQUEST));
    try {
      const res = await AdminOrderDataService.getOrderRequiredTests(orderId);
      dispatch(action(getOrderRequiredTestsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getOrderRequiredTestsActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };

export const getOrderReportDetails =
  (orderId: number): ActionResult<IAppState, Promise<void>, getOrderReportDetailsActions> =>
  async (dispatch) => {
    dispatch(action(getOrderReportDetailsActionTypes.REQUEST));
    try {
      const res = await AdminOrderDataService.getOrderReportDetails(orderId);
      dispatch(action(getOrderReportDetailsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getOrderReportDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const uploadReport =
  (data: IUploadTestReportRequest): ActionResult<IAppState, void, uploadReportActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadReportActionTypes.REQUEST));
      await AdminOrderDataService.uploadOrderReport(data);
      dispatch(action(uploadReportActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(uploadReportActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteReport =
  (id: number): ActionResult<IAppState, void, deleteReportActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteReportActionTypes.REQUEST));
      await AdminOrderDataService.deleteOrderReport(id);
      dispatch(action(deleteReportActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteReportActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrderStatusesDetails =
  (orderId: number): ActionResult<IAppState, Promise<void>, getOrderStatusesDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOrderStatusesDetailsActionTypes.REQUEST));
      const data = await AdminOrderDataService.getOrderStatusesDetails(orderId);
      dispatch(action(getOrderStatusesDetailsActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getOrderStatusesDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrderSampleDetails =
  (orderId: number): ActionResult<IAppState, Promise<void>, getOrderSampleDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOrderSampleDetailsActionTypes.REQUEST));
      const data = await AdminOrderDataService.getOrderSampleDetails(orderId);
      dispatch(action(getOrderSampleDetailsActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getOrderSampleDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrderTestsDetails =
  (orderId: number): ActionResult<IAppState, Promise<void>, getOrderTestsDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOrderTestsDetailsActionTypes.REQUEST));
      const data = await AdminOrderDataService.getOrderTestsDetails(orderId);
      dispatch(action(getOrderTestsDetailsActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getOrderTestsDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetChangeSampleCommunication =
  (): ActionResult<IAppState, void, resetChangeSampleCommunicationActions> => (dispatch) => {
    dispatch(action(resetChangeSampleCommunicationActionTypes.RESET));
  };

export const resetChooseAnotherSampleCommunication =
  (): ActionResult<IAppState, void, resetChooseAnotherSampleCommunicationActions> => (dispatch) => {
    dispatch(action(resetChooseAnotherSampleCommunicationActionTypes.RESET));
  };

export const resetOrderDetails = (): ActionResult<IAppState, void, resetOrderDetailsActions> => (dispatch) => {
  dispatch(action(resetOrderDetailsActionTypes.RESET));
};
