import {IModule} from 'redux-dynamic-modules';

import {mailConfirmationReducer} from './reducer';
import {IMailConfirmationState} from './types';

export interface IMailConfirmationModuleState {
  mailConfirmationData: IMailConfirmationState;
}

export const MailConfirmationModule: IModule<IMailConfirmationModuleState> = {
  id: 'mailConfirmationData',
  reducerMap: {
    mailConfirmationData: mailConfirmationReducer
  }
};
