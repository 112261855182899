import React from 'react';

import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon from 'Icon/components/ColoredIcon';

export const HAS_COLOR_IMAGE_ICON = (
  <ColoredIcon name={IconName.ImageFrame} size={32} color={ColorPalette.green1} stroke={false} fill={true} />
);

export const NO_COLOR_IMAGE_ICON = (
  <ColoredIcon name={IconName.ImageFrame} size={32} color={ColorPalette.white9} stroke={false} fill={true} />
);

export const HAS_RULE_ICON = (
  <ColoredIcon name={IconName.DoneMark} color={ColorPalette.green1} size={32} stroke={false} fill={true} />
);

export const NO_RULE_ICON = (
  <ColoredIcon name={IconName.DoneMark} size={32} color={ColorPalette.white9} stroke={false} fill={true} />
);

export const BROKEN_RULE_ICON = (
  <ColoredIcon name={IconName.DoneMark} color={ColorPalette.red4} size={32} stroke={false} fill={true} />
);
