import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {IAppState} from 'Common/store/IAppState';
import Icon, {IconName} from 'Icon/components/Icon';
import {FileUploader, Pagination} from 'Common/components/Controls/index';
import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import ColorPalette from 'Common/constants/ColorPalette';
import {withCurrency} from 'Common/helpers/withCurrency';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {
  actions as associationOrdersAction,
  selectors as associationOrdersSelectors,
} from 'Admin/AdminAssociations/store/adminOrders/index';
import Loading from 'Loading/components/Loading';
import {ExpandedRowStyles} from 'Common/components/Table/view/Row';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import WarningModal, {ModalTypes} from 'Common/components/Modal/WarningModal';
import {getCommonErrors, getErrorDetails} from 'Common/helpers/ErrorHelper';
import {AdminTestResultsModule} from 'Admin/AdminDashboard/store/adminTestResults/adminTestResultsModule';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import withDate from 'Common/helpers/withDate';
import OrderDetails from 'Admin/AdminAssociations/components/Orders/OrderDetails/OrderDetails';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {OrderStatus} from 'Common/constants/OrderStatus';
import {OrderStatusDropdown} from 'Admin/AdminAssociations/components/Orders/OrderStatus';
import OrderActions from 'Admin/AdminAssociations/components/Orders/OrderActions';
import {useCreateOrderModal} from 'Admin/AdminDashboard/helpers/hooks/useCreateOrderModal';
import AvatarCellHorse from 'Admin/AdminDashboard/components/Orders/shared/AvatarCellHorse';
import {useOrderActions} from 'Admin/AdminAssociations/helpers/hooks/useOrderActions/useOrderActions';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import withOrderActions, {
  getWithOrderActionsModules,
  IWithOrderActionsProps,
} from 'Admin/AdminAssociations/helpers/withOrderActions';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {
  actions as adminOnlineReportActions,
  selectors as adminOnlineReportSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/index';
import {IAdminAssociationOrder} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrder';
import {useAdminHorseModal} from 'Admin/AdminAssociations/helpers/hooks/useAdminHorseModal';
import {AdminAssociationOwnerModule} from 'Admin/AdminAssociations/store/adminOwners';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import PackageSection from './parts/PackageSection';
import {AvatarCell} from 'Admin/AdminDashboard/components/shared';
import {useAdminOwnerModal} from 'Admin/AdminAssociations/helpers/hooks/useAdminOwnerModal';
import {useAncestryUploadModal} from 'Admin/shared/helpers/hooks/useOrderActions/useAncestryUploadModal';
import {setOnlineReportTypeToStorage} from 'OnlineReport/helpers/setOnlineReportTypeToStorage';

const expandedRowStyles: ExpandedRowStyles = {content: {padding: 0}};

const AddButton = styled(PrimaryButton)`
  margin-left: 24px;
  position: relative;
`;

const OrderTable = styled.div`
  margin-top: 50px;
`;

const ErrorDetails = styled.div`
  margin-top: 20px;
`;

const CouponTooltip = styled.div`
  margin-right: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const CouponName = styled.div`
  margin: 2px 0;
`;

const fileUploaderSizeStyle: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 0,
  opacity: 0,
  height: 50,
  width: 176,
};

const modalResponseHeader: Record<'Alert' | 'Success', string> = {
  Alert: 'Alert',
  Success: 'Success',
};

const modalSuccessResponseMessage = 'File uploaded successfully';

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IWithOrderActionsProps;

const Orders = (props: AllProps) => {
  const {
    getOrderList,
    uploadTestResults,
    orderDeleting,
    deleteOrder,
    downloadFormRequesting,
    testResultsUploading,
    ordersLoading,
    getOrderDetails,
    downloadSubmissionForm,
    setSampleStatusRequesting,
    setSampleStatus,
    newSampleRequesting,
    changeReviewStatus,
    changeReviewStatusRequesting,
    orders,
  } = props;

  const {params, selectedId, setSelectedId, changeSorting, sorting, handleCountPerPage, handlePageSelect, searchBar} =
    useCommonAdminPageData<IAdminAssociationOrder>({
      getItems: getOrderList,
      searchBarPlaceholder: 'Search for orders by any keyword',
    });

  const reloadOrders = useCallback(() => {
    getOrderList(params);
    if (selectedId) {
      getOrderDetails(selectedId);
    }
  }, [getOrderDetails, getOrderList, params, selectedId]);

  useEffect(() => {
    // This necessary for Review status changing
    setOnlineReportTypeToStorage(OnlineReportType.ReviewAdminAssociation);
  }, []);

  useEffect(() => {
    if (selectedId) {
      setSelectedId(selectedId);
    }
  }, [selectedId, setSelectedId]);

  const {
    openDeleteModal,
    orderActionsModal,
    openSampleReceivedModal,
    openSampleNotReceivedModal,
    openRequestNewSampleModal,
    openUpdatePaymentModal,
    dismissReviewConfirmModal,
    openDismissReviewConfirmModal,
  } = useOrderActions({
    data: {
      deleteOrderAction: {action: deleteOrder, communication: orderDeleting},
      downloadSubmissionFormAction: {communication: downloadFormRequesting},
      setSampleStatusAction: {action: setSampleStatus, communication: setSampleStatusRequesting},
      requestNewSampleAction: {communication: newSampleRequesting},
      dismissReviewAction: {action: changeReviewStatus, communication: changeReviewStatusRequesting},
    },
    onSuccess: reloadOrders,
  });

  const history = useHistory();

  const openLiveReport = useCallback(
    (horseId: number, orderId?: number) => {
      if (orderId) {
        history.push(`/review-admin-associations/online-report/${horseId}/${orderId}`);
      } else {
        history.push(`/admin-associations/online-report/${horseId}/0`);
      }
    },
    [history]
  );

  const {createOrderModal, openCreateOrderModal} = useCreateOrderModal({onSuccess: reloadOrders, isBusiness: true});
  const {updateAdminHorseModal, openUpdateAdminHorseModal} = useAdminHorseModal({
    onSuccess: reloadOrders,
  });
  const {updateAdminOwnerModal, openUpdateAdminOwnerModal} = useAdminOwnerModal({
    onSuccess: reloadOrders,
  });
  const {ancestryUploadModal, openAncestryUploadModal} = useAncestryUploadModal({onSuccess: reloadOrders});

  const [isTestResultMessageModalOpen, setIsTestResultMessageModalOpen] = useState(false);
  const openTestResultsMessageModal = useCallback(() => setIsTestResultMessageModalOpen(true), []);
  const closeTestResultsMessageModal = useCallback(() => setIsTestResultMessageModalOpen(false), []);

  const [fileLoadError, setFileLoadError] = useState<string | null>(null);
  const closeFileLoadErrorModal = useCallback(() => setFileLoadError(null), []);

  const uploadFile = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append('uploadedFile', file);
      uploadTestResults(formData);
    },
    [uploadTestResults]
  );

  useOnSuccessCommunication(testResultsUploading, () => {
    reloadOrders();
    openTestResultsMessageModal();
  });
  useOnErrorCommunication(testResultsUploading, openTestResultsMessageModal);
  const isTestResultSuccessUpload = !testResultsUploading.error;
  const messageTestResultUploadEnd = isTestResultSuccessUpload
    ? modalSuccessResponseMessage
    : getCommonErrors(testResultsUploading.error) || 'Error on load test results';

  const errorDetails = !isTestResultSuccessUpload ? getErrorDetails(testResultsUploading.error) : '';

  const renderExpandContent = useCallback(
    ({id}: IAdminAssociationOrder) => {
      return <OrderDetails orderId={id} onChange={reloadOrders} />;
    },
    [reloadOrders]
  );

  const renderOrderStatus = useCallback(
    ({id, horse, statuses, owner, statusUpdateDates}: IAdminAssociationOrder) => {
      const {id: horseId, name} = horse;
      const {status, hasSample, hasNotResults} = statuses;

      return (
        <OrderStatusDropdown
          status={status}
          horseId={horseId}
          orderId={id}
          horseName={name}
          ownerName={owner.name}
          onChange={reloadOrders}
          completedStatuses={hasSample ? OrderStatus.sampleReceived : undefined}
          uncompletedStatuses={hasNotResults ? OrderStatus.resultsReady : undefined}
          statusUpdateDates={statusUpdateDates}
        />
      );
    },
    [reloadOrders]
  );

  const renderPrice = useCallback(({finalPrice, coupon}: IAdminAssociationOrder) => {
    return (
      <div className="d-flex justify-content-end">
        {coupon && (
          <CouponTooltip>
            <Tooltip content={<CouponName>{coupon}</CouponName>}>
              <div>
                <Icon name={IconName.Coupon} />
              </div>
            </Tooltip>
          </CouponTooltip>
        )}
        {withCurrency(finalPrice)}
      </div>
    );
  }, []);

  const setSelectedOrderId = useCallback((id: string | number) => setSelectedId(Number(id)), [setSelectedId]);

  const isLoading = ordersLoading.isRequesting;

  const isShowPagination = orders.length > 0;

  return (
    <div>
      {updateAdminHorseModal}
      {updateAdminOwnerModal}
      {createOrderModal}
      {orderActionsModal}
      {dismissReviewConfirmModal}
      {ancestryUploadModal}
      <WarningModal
        isOpen={!!fileLoadError}
        modalType={ModalTypes.Alert}
        modalTitle={modalResponseHeader.Alert}
        onClose={closeFileLoadErrorModal}
        onSuccess={closeFileLoadErrorModal}
      >
        {fileLoadError}
      </WarningModal>
      <WarningModal
        isOpen={isTestResultMessageModalOpen}
        modalType={ModalTypes.Alert}
        modalTitle={isTestResultSuccessUpload ? modalResponseHeader.Success : modalResponseHeader.Alert}
        onClose={closeTestResultsMessageModal}
        onSuccess={closeTestResultsMessageModal}
      >
        <Scrollbar maxHeight="40vh">
          {messageTestResultUploadEnd}
          {errorDetails && (
            <ErrorDetails>
              <ul>
                {errorDetails.map((err) => {
                  return <li>{err} </li>;
                })}
              </ul>
            </ErrorDetails>
          )}
        </Scrollbar>
      </WarningModal>
      <AdminPageLayout className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
        <AddButton
          isLoading={testResultsUploading.isRequesting}
          icon={{name: IconName.Upload, color: ColorPalette.red7}}
          variant="outlined"
          size="medium"
        >
          <FileUploader
            style={fileUploaderSizeStyle}
            onChange={uploadFile}
            acceptableTypes={['.xlsx']}
            isShowError={false}
            onError={setFileLoadError}
          />
          Upload Diagnostic
        </AddButton>
        <AddButton
          variant="outlined"
          onClick={openAncestryUploadModal}
          icon={{name: IconName.Upload, color: ColorPalette.red7}}
        >
          Upload Ancestry
        </AddButton>
        <AddButton size="medium" onClick={openCreateOrderModal}>
          + Create order
        </AddButton>
      </AdminPageLayout>
      <OrderTable className="position-relative">
        {isLoading && <Loading />}
        <Table<IAdminAssociationOrder>
          data={orders}
          rowKey="id"
          sorting={sorting}
          onChangeSorting={changeSorting}
          expandable={true}
          renderExpandContent={renderExpandContent}
          expandedRowStyles={expandedRowStyles}
          onExpand={setSelectedOrderId}
          defaultExpandedRow={String(selectedId)}
        >
          <Cell<IAdminAssociationOrder>
            header="ID"
            dataKey="id"
            render={({id}) => `#${id}`}
            expandOnClick={true}
            width={100}
          />
          <Cell<IAdminAssociationOrder>
            header="Date"
            dataKey="createDate"
            render={({createDate}) => withDate(createDate)}
            width="10%"
          />
          <Cell<IAdminAssociationOrder>
            header="Association"
            dataKey="organization"
            render={({organization}) => organization.name}
            width="15%"
          />
          <Cell<IAdminAssociationOrder>
            header="Owner"
            dataKey="owner"
            render={({owner}) => (
              <AvatarCell
                type="owner"
                avatarUrl={owner.avatar?.url}
                profileUrl={`/admin-associations/user/user-profile/${owner.id}`}
                label={owner.name}
                onLabelClick={() => openUpdateAdminOwnerModal(owner.id)}
              />
            )}
            width="15%"
          />
          <Cell<IAdminAssociationOrder>
            header="Horse"
            render={({horse}) => {
              return (
                <AvatarCellHorse
                  type="horse"
                  label={horse.name}
                  avatarUrl={horse.avatar?.url}
                  onLabelClick={() => openUpdateAdminHorseModal(horse.id)}
                  profileUrl={`/admin-associations/horse/${horse.id}`}
                  isArchived={horse.isArchived}
                  isDeleted={horse.isDeleted}
                  sampleId={horse.sampleId}
                  isGeneticDataHidden={horse.isGeneticDataHidden}
                />
              );
            }}
            width="15%"
          />
          <Cell<IAdminAssociationOrder> header="Status" render={renderOrderStatus} width="15%" memoized={false} />
          <Cell<IAdminAssociationOrder>
            header="Package"
            render={({packages, tests}) => {
              return <PackageSection packages={packages} tests={tests} />;
            }}
            width="10%"
          />
          <Cell<IAdminAssociationOrder>
            header="Price"
            dataKey="finalPrice"
            render={renderPrice}
            width="5%"
            align={CellAlign.Right}
          />
          <Cell<IAdminAssociationOrder>
            header="Actions"
            width={100}
            align={CellAlign.Right}
            render={(order) => (
              <OrderActions
                orderId={order.id}
                horseId={order.horse.id}
                statuses={order.statuses}
                deleteOrderHandler={openDeleteModal}
                updatePaymentHandler={openUpdatePaymentModal}
                openLiveReportHandler={openLiveReport}
                submissionFormHandler={downloadSubmissionForm}
                sampleReceivedHandler={openSampleReceivedModal}
                sampleNotReceivedHandler={openSampleNotReceivedModal}
                requestNewSampleHandler={openRequestNewSampleModal}
                dismissReviewHandler={openDismissReviewConfirmModal}
              />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={props.pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </OrderTable>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  orders: associationOrdersSelectors.selectOrders(state),
  ordersLoading: associationOrdersSelectors.selectCommunication(state, 'ordersLoading'),
  testResultsUploading: associationOrdersSelectors.selectCommunication(state, 'testResultsUploading'),
  changeReviewStatusRequesting: adminOnlineReportSelectors.selectCommunication(state, 'changeReviewStatusRequesting'),
  pagination: associationOrdersSelectors.selectPagination(state),
});

const mapDispatchToProps = {
  getOrderList: associationOrdersAction.getOrders,
  getOrderDetails: associationOrdersAction.getOrderDetails,
  uploadTestResults: associationOrdersAction.uploadTestResults,
  changeReviewStatus: adminOnlineReportActions.changeReviewStatus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(withOrderActions(Orders)), [
  AdminTestResultsModule,
  AdminAssociationOwnerModule,
  getWithOrderActionsModules(),
]);
