import {combineReducers, Reducer} from 'redux';

import {communicationAncestryUploadReducer} from './communications';
import {IAdminAncestryUploadState} from '../types';
import {dataAncestryUploadReducer} from './data';

export const ancestryUploadReducer: Reducer<IAdminAncestryUploadState> = combineReducers<IAdminAncestryUploadState>({
  communications: communicationAncestryUploadReducer,
  data: dataAncestryUploadReducer,
});
