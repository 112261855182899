import {IFoalProfile} from 'FoalProfile/models/IFoalProfile';

import {IServerFoalProfile} from '../models/IServerFoalProfile';
import {convertFoalAbilityToClient, convertFoalProbabilityToClient} from 'BuildHorse/services/converters/foal';
import {IFoalParent} from 'FoalProfile/models/IFoalParent';
import {withAvatar} from 'Common/helpers/withAvatar';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {withImage} from 'Common/helpers/withImage';

export function convertFoalProfileToClient(response: IServerFoalProfile): IFoalProfile {
  const res = {
    ...response,
    abilities: convertFoalAbilityToClient(response.abilities),
  };

  Object.keys(res).forEach((x) => {
    res[x] = convertFoalProbabilityToClient(res[x]);
  });
  return res;
}

export function convertFoalParentToClient(parent: IFoalParent): IFoalParent {
  return {
    ...withAvatar(withBirthDate(parent)),
    owner: withAvatar(parent.owner),
    genotypeGroups: parent.genotypeGroups.map(withImage),
  };
}
