import {Reducer} from 'redux';

import {
  getStaticResourceActionTypes,
  getStaticResourcesActionTypes,
  IAdminStaticResourcesState,
  resetStaticResourceActionTypes,
  StaticResourcesActions,
  updateStaticResourceActionTypes,
  createStaticResourceActionTypes,
  uploadStaticResourceFileActionTypes,
} from '../types';

const initialState: IAdminStaticResourcesState['data'] = {
  staticResources: [],
  staticResource: null,
};

export const dataStaticResourcesReducer: Reducer<IAdminStaticResourcesState['data'], StaticResourcesActions> = (
  state = initialState,
  action: StaticResourcesActions
) => {
  switch (action.type) {
    case getStaticResourcesActionTypes.SUCCESS: {
      return {...state, staticResources: action.payload.data};
    }
    case getStaticResourceActionTypes.SUCCESS:
    case updateStaticResourceActionTypes.SUCCESS:
    case createStaticResourceActionTypes.SUCCESS:
    case uploadStaticResourceFileActionTypes.SUCCESS: {
      return {...state, staticResource: action.payload};
    }
    case resetStaticResourceActionTypes.RESET: {
      return {...state, staticResource: initialState.staticResource};
    }
    default: {
      return state;
    }
  }
};
