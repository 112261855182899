import {Reducer} from 'redux';

import {getPermissionsByUserIdActionTypes, IUserPermissionsState, UserPermissionsActions} from '../types';

const initialState: IUserPermissionsState['data'] = {
  userPermissions: {userId: '', permissions: []}
};

export const dataUserPermissionsReducer: Reducer<IUserPermissionsState['data'], UserPermissionsActions> = (
  state = initialState,
  action: UserPermissionsActions
) => {
  switch (action.type) {
    case getPermissionsByUserIdActionTypes.REQUEST: {
      return {...state, userPermissions: initialState.userPermissions};
    }
    case getPermissionsByUserIdActionTypes.SUCCESS: {
      return {...state, userPermissions: action.payload};
    }
    default: {
      return state;
    }
  }
};
