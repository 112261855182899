import ColorPalette from 'Common/constants/ColorPalette';

export default function getProbabilityColor(prob: number, higherIsBetter?: boolean) {
  if (higherIsBetter) {
    if (prob > 75 && prob <= 100) {
      return ColorPalette.green2;
    }
    if (prob > 50 && prob <= 75) {
      return ColorPalette.yellow9;
    }
    return ColorPalette.red7;
  }

  if (prob > 25 && prob <= 100) {
    return ColorPalette.red7;
  }
  if (prob > 12 && prob <= 25) {
    return ColorPalette.yellow9;
  }

  return ColorPalette.green2;
}
