import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.adminNotifications;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectAdminNotifications(state: IAppState) {
  return selectState(state).data.adminNotifications;
}
