import {IModule} from 'redux-dynamic-modules';

import {changeEmailReducer} from './reducer';
import {IAdminChangeEmailState} from './types';

export interface IAdminChangeEmailModuleState {
  adminChangeEmail: IAdminChangeEmailState;
}

export const AdminChangeEmailModule: IModule<IAdminChangeEmailModuleState> = {
  id: 'adminChangeEmail',
  reducerMap: {
    adminChangeEmail: changeEmailReducer
  }
};
