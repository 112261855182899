import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import HorseProfileForm from 'HorseProfile/components/HorseProfileForm/HorseProfileForm';
import {FormType} from 'Common/constants/FormType';
import {Nullable} from 'Common/types';

interface IUseHorseModalParams {
  ownerId?: Nullable<number>;
  horseId?: Nullable<number>;
  onSuccess?(): void;
  onSuccessUpdate?(): void;
  onSuccessCreate?(): void;
}

export function useHorseModal(params: IUseHorseModalParams) {
  const {horseId, ownerId, onSuccess} = params;

  const [updatedHorseId, setUpdatedHorseId] = useState<number | null>();
  const [isUpdateHorseModalOpen, setIsUpdateHorseModalOpen] = useState(false);
  const [isCreateHorseModalOpen, setIsCreateHorseModalOpen] = useState(false);

  const onSuccessUpdate = params.onSuccessUpdate || onSuccess;
  const onSuccessCreate = params.onSuccessCreate || onSuccess;

  const openUpdateHorseModal = useCallback(
    (horseId?: Nullable<number>) => {
      setIsUpdateHorseModalOpen(true);
      setUpdatedHorseId(horseId);
    },
    [setIsUpdateHorseModalOpen]
  );

  const openCreateHorseModal = useCallback(() => {
    setIsCreateHorseModalOpen(true);
  }, []);

  const closeHorseModals = useCallback(() => {
    setIsUpdateHorseModalOpen(false);
    setIsCreateHorseModalOpen(false);
  }, []);

  const handleSuccessUpdate = useCallback(() => {
    closeHorseModals();
    onSuccessUpdate && onSuccessUpdate();
  }, [onSuccessUpdate, closeHorseModals]);

  const handleSuccessCreate = useCallback(() => {
    closeHorseModals();
    onSuccessCreate && onSuccessCreate();
  }, [onSuccessCreate, closeHorseModals]);

  const updateHorseModal = (
    <ModalWindow isOpen={isUpdateHorseModalOpen} onClose={closeHorseModals}>
      <HorseProfileForm
        type={FormType.edit}
        ownerId={ownerId || undefined}
        horseId={horseId || updatedHorseId}
        onSuccess={handleSuccessUpdate}
        isAdmin={true}
      />
    </ModalWindow>
  );

  const createHorseModal = (
    <ModalWindow isOpen={isCreateHorseModalOpen} onClose={closeHorseModals}>
      <HorseProfileForm
        type={FormType.create}
        ownerId={ownerId || undefined}
        onSuccess={handleSuccessCreate}
        isAdmin={true}
      />
    </ModalWindow>
  );

  return {openUpdateHorseModal, openCreateHorseModal, updateHorseModal, createHorseModal, updatedHorseId};
}
