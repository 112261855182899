import {Reducer} from 'redux';

import {
  HorseBreedPanelsActions,
  getHorseBreedPanelsActionTypes,
  IHorseBreedPanelState,
  resetHorseBreedPanelsActionTypes,
} from '../types';

const initialState: IHorseBreedPanelState['data'] = {
  horseBreedPanels: [],
};

export const dataHorseBreedPanelsReducer: Reducer<IHorseBreedPanelState['data'], HorseBreedPanelsActions> = (
  state = initialState,
  action: HorseBreedPanelsActions
) => {
  switch (action.type) {
    case getHorseBreedPanelsActionTypes.SUCCESS: {
      return {...state, horseBreedPanels: action.payload.data};
    }
    case resetHorseBreedPanelsActionTypes.RESET: {
      return {...state, horseBreedPanels: initialState.horseBreedPanels};
    }
    default: {
      return state;
    }
  }
};
