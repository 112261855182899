import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IContactRequest} from 'UserProfile/models/IContactRequest';
import {
  contactRequestActions,
  contactRequestActionTypes,
  IContactRequestState
} from 'UserProfile/store/contactRequest/types';
import UserService from 'UserProfile/services/UserService';

export const sendContactRequest = (
  data: IContactRequest
): ActionResult<IContactRequestState, void, contactRequestActions> => async dispatch => {
  try {
    dispatch(action(contactRequestActionTypes.REQUEST));
    await UserService.sendContactRequest(data);
    dispatch(action(contactRequestActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(contactRequestActionTypes.FAILURE, getErrorMessage(error)));
  }
};
