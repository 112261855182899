import React, {memo} from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';

type LoaderVariant = 'primary' | 'white';

const Root = styled.div<{color: LoaderVariant; size: number}>`
  height: ${p => `${p.size}px`};
  width: ${p => `${p.size}px`};
  position: relative;
  transform: translateX(-50%);

  :before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0.2em solid rgba(255, 255, 255, 0.1);
  }

  :after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    animation: loader 0.6s linear;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: ${p => (p.color === 'primary' ? Theme.color.primary : Theme.color.white)} transparent transparent;
    border-style: solid;
    border-width: 0.2em;
  }

  @keyframes loader {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

interface IProps {
  color?: LoaderVariant;
  size?: number;
  className?: string;
}

function IconedLoading(props: IProps) {
  const {color = 'primary', size = 24, className} = props;
  return <Root color={color} size={size} className={className} />;
}

export default memo(IconedLoading);
