import {IUserHorse} from 'Horse/models/IUserHorse';
import {dateDiffInYearsOrMonth} from 'Common/helpers/DateHelper';
import {IHorseProfile} from 'Horse/models/IHorseProfile';
import {IOwnerHorse} from 'Shared/models/IOwnerHorse';

export const getHorseInfo = (horse: IUserHorse | IHorseProfile | IOwnerHorse) => {
  const breed = 'breeds' in horse ? horse.breeds && horse.breeds.map((br) => br.name).join(', ') : '';
  const age = horse.dateOfBirth && dateDiffInYearsOrMonth(horse.dateOfBirth);
  return {breed, age};
};
