import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';
import {FieldArray, FieldArrayRenderProps} from 'formik';

import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';
import {SelectField} from 'Common/components/FormFields';
import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IHorseOrder} from 'BusinessPortal/models/order/createOrder/IHorseOrder';
import {castToOption} from 'Common/helpers/OptionHelper';
import Theme from 'Common/constants/Theme';
import {breakpoints} from 'Common/constants/Breakpoints';
import {sortByName} from 'Common/helpers/sortByName';
import {BaseStepLayoutChildrenProps} from 'BusinessPortal/components/BusinessPortalDashboard/Orders/CreateOrder/parts/BaseStepLayout';
import {RequiredBy} from 'Common/types';
import {INamedEntity} from 'Common/models/INamedEntity';
import TestsSelectionShow from 'BusinessPortal/components/BusinessPortalDashboard/Orders/CreateOrder/parts/TestsSelection/TestsSelectionShow';

const initialHorseTest: RequiredBy<IHorseOrder, 'tests' | 'packages'> = {horseId: 0, packages: [], tests: []};

const RemoveButtonWrapper = styled.div`
  padding-top: 40px;
  display: none;
  @media ${breakpoints.md} {
    display: block;
  }
`;

const RemoveButtonHorseWrapper = styled.div`
  padding-top: 40px;
  display: block;
  @media ${breakpoints.md} {
    display: none;
  }
`;

const RemoveButton = styled(IconButton)`
  bottom: 30px;
  margin-left: 16px;
`;

const HorseTests = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  :first-child {
    margin-top: 8px;
  }

  @media ${breakpoints.md} {
    flex-direction: row;
  }
`;

const AddHorseButton = styled(PrimaryButton)`
  margin-top: 24px;
`;

const LeftColumn = styled.div`
  width: 100%;

  @media ${breakpoints.sm} {
    width: 40%;
    margin-right: 24px;
  }
`;

const RightColumn = styled.div`
  width: 100%;
  margin-top: 13px;

  > :not(:first-child) {
    margin-top: 8px;
  }

  @media ${breakpoints.sm} {
    width: 60%;
  }
  @media ${breakpoints.md} {
    margin-top: 0;
  }
`;

interface IProps {
  horses: IOrderHorse[];
  packages: INamedEntity[];
  orders: IHorseOrder[];
  tests: INamedEntity[];
}

type AllProps = IProps & BaseStepLayoutChildrenProps;

function TestsSelection(props: AllProps) {
  const {isEdit, horses, packages, tests, orders} = props;

  const renderRemoveButton = useCallback((arrayHelper: FieldArrayRenderProps, index: number) => {
    return (
      <RemoveButton
        onClick={() => arrayHelper.remove(index)}
        name={IconName.Clear}
        color={Theme.color.primary}
        size={14}
        fill={true}
        stroke={false}
        hoverColor={Theme.color.control.active}
      />
    );
  }, []);

  const uniqueHorses = useMemo(
    () =>
      castToOption(
        horses
          .map((horse) => (orders.find((order) => order.horseId === horse.id) ? {...horse, isInvisible: true} : horse))
          .map((horse) => ({...horse, name: `${horse.name} (${horse.ownerName})`}))
          .sort(sortByName)
      ),
    [horses, orders]
  );

  const packagesOptions = useMemo(() => castToOption(packages.sort(sortByName)), [packages]);
  const testsOptions = useMemo(() => castToOption(tests.sort(sortByName)), [tests]);

  if (!isEdit) {
    return <TestsSelectionShow {...{tests, orders, horses, packages}} />;
  }

  return (
    <FieldArray
      name="orders"
      render={(arrayHelper) => (
        <div className="flex-column">
          {orders.map((_, index, arr) => (
            <HorseTests key={index}>
              <LeftColumn className="d-flex">
                <div className="w-100">
                  <SelectField
                    style={{marginBottom: 0}}
                    name={`orders[${index}].horseId`}
                    options={uniqueHorses}
                    label="Horse"
                    isRequired={true}
                  />
                </div>
                {arr.length > 1 && (
                  <RemoveButtonHorseWrapper className="align-self-start">
                    {renderRemoveButton(arrayHelper, index)}
                  </RemoveButtonHorseWrapper>
                )}
              </LeftColumn>
              <RightColumn className="d-flex flex-column">
                <div className="w-100">
                  <SelectField
                    style={{marginBottom: 0}}
                    isMulti={true}
                    name={`orders[${index}].packages`}
                    options={packagesOptions}
                    label="Packages"
                  />
                </div>
                <div className="w-100">
                  <SelectField
                    style={{marginBottom: 0}}
                    isMulti={true}
                    name={`orders[${index}].tests`}
                    options={testsOptions}
                    label="Additional Tests"
                  />
                </div>
              </RightColumn>
              {arr.length > 1 && (
                <RemoveButtonWrapper className="align-self-start">
                  {renderRemoveButton(arrayHelper, index)}
                </RemoveButtonWrapper>
              )}
            </HorseTests>
          ))}
          <AddHorseButton size="small" variant="outlined" onClick={() => arrayHelper.push({...initialHorseTest})}>
            + Add horse
          </AddHorseButton>
        </div>
      )}
    />
  );
}

export default TestsSelection;
export type TestSelectionProps = AllProps;
