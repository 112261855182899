import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';
import {IFoal} from 'Common/models/IFoal';
import {IPagination, IListResponse} from 'Common/models/IResponse';

const STATE_NAME = 'buildHorse';

export interface IBuildHorseState {
  data: {
    foals: IFoal[];
  };
  communications: {
    foalsLoading: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getFoalsActionTypes = makeCommunicationActionType(STATE_NAME, 'BUILDS_HORSE_REQUEST');
export type getFoalsActions = MakeCommunicationActions<typeof getFoalsActionTypes, {success: IListResponse<IFoal>}>;

export type Actions = getFoalsActions;
