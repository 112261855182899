import {Reducer} from 'redux';

import {IOrderAdmin} from 'Admin/AdminDashboard/models/Order/IOrderAdmin';
import {IOrderDetails} from 'Admin/AdminDashboard/models/Order/IOrderDetails';
import {
  AdminOrdersActions,
  changeSampleActionTypes,
  chooseAnotherSampleActionTypes,
  getHorseSamplesActionTypes,
  getOrderDetailsActionTypes,
  getOrdersActionTypes,
  getOrderStatusesDetailsActionTypes,
  IAdminOrdersState,
  resetOrderDetailsActionTypes,
  resetOrderStatusesDetailsActionTypes,
} from '../types';
import {IOrderHorseStatuses} from 'Admin/AdminDashboard/models/Order/IOrderHorseStatuses';

const initialStateList: IAdminOrdersState['data'] = {
  orders: [],
  orderDetails: null,
  horseSamples: [],
  orderStatusesDetails: [],
};

export const dataOrdersReducer: Reducer<IAdminOrdersState['data'], AdminOrdersActions> = (
  state = initialStateList,
  action: AdminOrdersActions
) => {
  switch (action.type) {
    case getOrdersActionTypes.SUCCESS: {
      return {...state, orders: action.payload.data};
    }

    case getOrderDetailsActionTypes.SUCCESS: {
      return {...state, orderDetails: action.payload};
    }

    case getHorseSamplesActionTypes.SUCCESS: {
      return {...state, horseSamples: action.payload};
    }

    case resetOrderDetailsActionTypes.RESET: {
      return {...state, orderDetails: initialStateList.orderDetails};
    }

    case changeSampleActionTypes.SUCCESS: {
      if (!state.orderDetails) {
        return {...state};
      }
      const {orderDetails, orders} = state;
      const {sampleId, id} = action.payload;
      const newOrderDetails: IOrderDetails = {
        ...orderDetails,
        horsesDetails: orderDetails.horsesDetails.map((d) =>
          d.sample?.id === action.payload.id ? {...d, sample: {...d.sample, sampleId}} : d
        ),
      };
      const newOrders: IOrderAdmin[] = orders.map((order) => ({
        ...order,
        horses: order.horses.map((horse) => {
          if (!horse.sample) {
            return horse;
          }
          return horse.sample.id !== id ? horse : {...horse, sample: {...horse.sample, sampleId}};
        }),
      }));

      return {
        ...state,
        orderDetails: newOrderDetails,
        orders: newOrders,
      };
    }

    case chooseAnotherSampleActionTypes.SUCCESS: {
      if (!state.orderDetails) {
        return {...state};
      }
      const {orderDetails, orders} = state;
      const {orderId, currentSampleId, targetSampleId} = action.payload;
      const newOrderDetails: IOrderDetails = {
        ...orderDetails,
        horsesDetails: orderDetails.horsesDetails.map((d) =>
          d.sample?.sampleId === action.payload.currentSampleId && orderDetails?.id === orderId
            ? {...d, sample: {...d.sample, sampleId: targetSampleId}}
            : d
        ),
      };

      const newOrders: IOrderAdmin[] = orders.map((order) => ({
        ...order,
        horses: order.horses.map((horse) => {
          if (!horse.sample) {
            return horse;
          }

          return horse.sample.sampleId === currentSampleId && orderDetails?.id === order.id
            ? {...horse, sample: {...horse.sample, sampleId: targetSampleId}}
            : horse;
        }),
      }));

      return {
        ...state,
        orderDetails: newOrderDetails,
        orders: newOrders,
      };
    }

    case getOrderStatusesDetailsActionTypes.SUCCESS: {
      const {orderId, horseId} = action.payload;

      const newOrderStatusesDetials: IOrderHorseStatuses[] = state.orderStatusesDetails.map((x) => x);
      const foundIndex = newOrderStatusesDetials.findIndex((x) => x.horseId === horseId && x.orderId === orderId);
      if (foundIndex !== -1) {
        newOrderStatusesDetials.splice(foundIndex, 1);
      }
      newOrderStatusesDetials.push(action.payload);

      return {...state, orderStatusesDetails: newOrderStatusesDetials};
    }

    case resetOrderStatusesDetailsActionTypes.RESET: {
      return {...state, orderStatusesDetails: initialStateList.orderStatusesDetails};
    }

    default: {
      return state;
    }
  }
};
