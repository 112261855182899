import {ICommunicationActionTypes} from './types';

export function makeCommunicationActionTypes<R extends string, S extends string, F extends string>({
  REQUEST: request,
  SUCCESS: success,
  FAILURE: failure,
}: ICommunicationActionTypes<R, S, F>): ICommunicationActionTypes<R, S, F> {
  return {REQUEST: request, SUCCESS: success, FAILURE: failure};
}

export default function makeCommunicationActionType<S extends string, C extends string>(state: S, communication: C) {
  type requestType = `@@${S}/${C}_REQUEST`;
  type successType = `@@${S}/${C}_SUCCESS`;
  type failureType = `@@${S}/${C}_FAILURE`;

  return makeCommunicationActionTypes({
    REQUEST: `@@${state}/${communication}_REQUEST` as requestType,
    SUCCESS: `@@${state}/${communication}_SUCCESS` as successType,
    FAILURE: `@@${state}/${communication}_FAILURE` as failureType,
  });
}
