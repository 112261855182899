import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import Theme from 'Common/constants/Theme';

export const ModalWindowHeader = styled.div`
  width: calc(100% - 48px);
  margin: 0 auto;
  padding: 24px 32px 16px 16px;
  color: ${ColorPalette.black0};
  font-family: ${Typography.family.openSans};
  font-size: ${Typography.size.size24};
  line-height: 33px;
  border-bottom: 1px solid ${ColorPalette.gray10};
`;

export const ModalWindowContent = styled.div`
  height: 100%;
  width: calc(100% - 48px);
  margin: 0 auto;
  padding: 32px 16px;
  font-size: ${Typography.size.size18};
  line-height: 25px;
  color: ${ColorPalette.black1};
  font-family: ${Theme.font.primary};
`;

export const ModalWindowFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 24px 24px;
  border-top: 1px solid ${ColorPalette.gray10};
`;

export const ModalWindowButton = styled(PrimaryButton)`
  width: 150px;
  height: 48px;
  margin-left: 20px;
  min-width: 50px;

  :first-child {
    margin-left: 0;
  }
`;
