import React, {useCallback, useState} from 'react';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import ChangeEmail from 'Admin/AdminDashboard/components/Users/ChangeEmail/ChangeEmail';

interface IProps {
  onSuccess(): void;
}

export function useChangeUserEmailModal(props: IProps) {
  const {onSuccess} = props;

  const [selectedId, setSelectedId] = useState<number>();
  const [selectedName, setSelectedName] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');

  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false);
  const openChangeUserEmailModal = useCallback(
    (userId: number, userName: string, userEmail: string) => {
      setSelectedId(userId);
      setSelectedName(userName);
      setSelectedEmail(userEmail);
      setIsChangeEmailModalOpen(true);
    },
    [setSelectedId]
  );
  const closeChangeUserEmailModal = useCallback(() => setIsChangeEmailModalOpen(false), []);
  const onSuccessChangeUserEmail = useCallback(() => {
    closeChangeUserEmailModal();
    onSuccess();
  }, [closeChangeUserEmailModal, onSuccess]);

  const changeUserEmailModal = (
    <ModalWindow isOpen={isChangeEmailModalOpen && !!selectedId} onClose={closeChangeUserEmailModal}>
      <ChangeEmail userName={selectedName} oldEmail={selectedEmail} onSuccess={onSuccessChangeUserEmail} />
    </ModalWindow>
  );

  return {
    openChangeUserEmailModal,
    changeUserEmailModal
  };
}
