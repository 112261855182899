import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import Tooltip from 'Common/components/Tooltip/Tooltip';

const Logo = styled(ColoredIcon)`
  margin-right: 8px;
`;

interface IProps {
  packages: string[];
}

function PackageSection(props: IProps) {
  const {packages} = props;

  const packagesValue = packages.join(', ');

  return (
    <>
      {packages.length > 1 ? (
        <Tooltip content={packagesValue}>
          <div>{`${packages.length} packages`}</div>
        </Tooltip>
      ) : (
        <>
          <Logo name={IconName.Person} color={ColorPalette.gray56} stroke={false} fill={true} />
          {packagesValue}
        </>
      )}
    </>
  );
}

export default memo(PackageSection);
