import React from 'react';
import styled from 'styled-components';

import FoalParents from './FoalParents';
import FoalCard from './FoalCard';
import {IFoal} from 'Common/models/IFoal';
import {breakpoints} from 'Common/constants/Breakpoints';
import ColorPalette from 'Common/constants/ColorPalette';

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${ColorPalette.gray2};
  box-sizing: border-box;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  @media ${breakpoints.md} {
    flex-direction: row;
    padding: 10px;
  }
`;

const FoalParentsContainer = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 200px;

  @media ${breakpoints.md} {
    height: 100%;
    width: 50%;
  }
`;

interface IProps {
  foal: IFoal;
}

const PotentialFoal = (props: IProps) => {
  const {foal} = props;
  return (
    <Root>
      <FoalParentsContainer>
        <FoalParents foalParents={foal.parents} />
      </FoalParentsContainer>
      <FoalCard foal={foal} />
    </Root>
  );
};

export default PotentialFoal;
