import React, {memo} from 'react';

import Tooltip from 'Common/components/Tooltip/Tooltip';
import {IPanelTrait} from 'BreedPanel/models/IHorseBreedPanel';

interface IProps {
  panelTraits: IPanelTrait[];
}

function BreedPanelList(props: IProps) {
  const {panelTraits} = props;

  return (
    <>
      {panelTraits.map((trait, index) => {
        const isLast = panelTraits.length - 1 === index;
        return (
          <Tooltip content={trait.description} key={index}>
            <div className="d-flex">
              <div>{`${trait.geneName || ''} ${trait.alleleNames || ''}`}</div>
              {!isLast ? <div>,&nbsp;</div> : ''}
            </div>
          </Tooltip>
        );
      })}
    </>
  );
}

export default memo(BreedPanelList);
