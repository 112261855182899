import {IFormValues} from './validation';
import {
  IAdminAssociationPackage,
  IAdminAssociationPackageRequest,
} from 'Admin/AdminAssociations/models/IAdminAssociationPackage';

export function convertPackageToPackageForm(packageInfo: IAdminAssociationPackage): IFormValues {
  return {
    ...packageInfo,
    tests: packageInfo.tests?.map((item) => item.id) || [],
    reportTypes: packageInfo?.reportTypes.map((item) => item.id) || [],
    specialPrices: packageInfo?.specialPrices.map((price) => ({
      price: price.price,
      organizations: price?.organizations?.map((org) => org.id) || [],
    })),
    isSpecialPrices: packageInfo.specialPrices && packageInfo.specialPrices.length > 0,
    groups: packageInfo.groups?.map((item) => item.id) || [],
  };
}

export function convertPackageFormToPackageRequest(packageInfo: IFormValues): IAdminAssociationPackageRequest {
  const dataToSave: Omit<IFormValues, 'isSpecialPrices'> = {
    ...packageInfo,
    specialPrices: packageInfo.isSpecialPrices ? packageInfo.specialPrices : [],
  };
  delete dataToSave['isSpecialPrices'];
  return {
    ...dataToSave,
  };
}
