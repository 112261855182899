import * as Yup from 'yup';

import {IPackage} from 'Dictionaries/models/IPackage';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues extends Omit<IPackage, 'tests' | 'association' | 'reportTypes' | 'groups'> {
  tests: number[];
  association: number | null;
  reportTypes: number[];
  groups: number[];
}

export const initialValue: IFormValues = {
  id: 0,
  abbreviation: null,
  name: '',
  association: null,
  price: 0,
  tests: [],
  description: '',
  isUsed: false,
  isEnabled: false,
  isArchived: false,
  statusChangeDate: null,
  reportTypes: [],
  groups: [],
  isBundle: false,
  positionIndex: 0,
};

export const validationSchema = Yup.object().shape<IFormValues>({
  id: Yup.number(),
  abbreviation: Yup.string().nullable(),
  name: Yup.string().required(REQUIRED_FIELD),
  association: Yup.number().nullable(),
  price: Yup.number(),
  tests: Yup.array().of(Yup.number()),
  description: Yup.string(),
  isUsed: Yup.boolean(),
  isEnabled: Yup.boolean(),
  isArchived: Yup.boolean(),
  statusChangeDate: Yup.string().nullable(),
  reportTypes: Yup.array().of(Yup.number()),
  groups: Yup.array().of(Yup.number()),
  isBundle: Yup.boolean(),
  positionIndex: Yup.number(),
});
