import styled, {css} from 'styled-components';

import Typography from 'Common/constants/Typography';

export const FilterWrapper = styled.div`
  margin-bottom: 42px;
`;

export const FilterSubHeaderWrapper = styled.div`
  margin-bottom: 10px;
`;

export const CheckboxGroupWrapper = styled.div`
  margin-top: 20px;
`;

export const FilterCheckboxLabelStyle = css`
  font-weight: ${Typography.weight.medium500};
`;

export const SubHeader = styled.h5`
  font-family: ${Typography.family.openSans};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size23};
  line-height: 32px;
  letter-spacing: -0.4px;
  margin-bottom: 25px;
`;
