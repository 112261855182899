import {IAppState} from 'Common/store/IAppState';
import {ICommunication} from 'Common/store/utils/communication';
import {
  actions as adminActions,
  IRequestChangeSample as IRequestChangeSampleAdmin,
  selectors as adminSelectors,
} from 'Admin/AdminDashboard/store/adminOrders/index';

import {
  actions as adminAssociationsActions,
  IRequestChangeSample as IRequestChangeSampleAssociationAdmin,
  selectors as adminAssociationsSelectors,
} from 'Admin/AdminAssociations/store/adminOrders';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';

export interface IState {
  changeSampleRequesting: ICommunication;
}

export interface IDispatch<T> {
  changeSample(params: T): void;
  resetChangeSampleCommunication(): void;
}

const adminDistributor: IDistributor<IState, IDispatch<IRequestChangeSampleAdmin>> = {
  state: (state: IAppState) => ({
    changeSampleRequesting: adminSelectors.selectCommunication(state, 'changeSampleRequesting'),
  }),
  dispatch: {
    changeSample: adminActions.changeSample,
    resetChangeSampleCommunication: adminActions.resetChangeSampleCommunication,
  },
};

const adminAssociationDistributor: IDistributor<IState, IDispatch<IRequestChangeSampleAssociationAdmin>> = {
  state: (state: IAppState) => ({
    changeSampleRequesting: adminAssociationsSelectors.selectCommunication(state, 'changeSampleRequesting'),
  }),
  dispatch: {
    changeSample: adminAssociationsActions.changeSample,
    resetChangeSampleCommunication: adminAssociationsActions.resetChangeSampleCommunication,
  },
};

export const changeSampleFormDistributor: Record<SelectedStrategy, IDistributor<IState, IDispatch<any>>> = {
  [SelectedStrategy.Admin]: adminDistributor,
  [SelectedStrategy.AdminAssociation]: adminAssociationDistributor,
};
