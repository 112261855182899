import {IOwnerDetailsBP} from 'BusinessPortal/models/owner/IOwnerDetailsBP';
import {withAvatar} from 'Common/helpers/withAvatar';
import {withUserName} from 'Common/helpers/withUserName';
import {IServerOwnerDetailsBP} from '../types/owner';

export function convertOwnerDetails(owner: IServerOwnerDetailsBP): IOwnerDetailsBP {
  return {
    ...withUserName(withAvatar(owner)),
    horses: owner.horses.map(withAvatar),
  };
}
