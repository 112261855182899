import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.buildHorse;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectFoals(state: IAppState) {
  return selectState(state).data.foals;
}

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}
