import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';

import {
  Actions,
  createUserOrderActionTypes,
  getOrderPackagesActionTypes,
  getUserCouponActionTypes,
  getUserHorsesActionTypes,
  getUserPackagesActionTypes,
  getUserSummaryActionTypes,
  IAdminCreateUserOrderState,
} from '../types';

export const communicationReducer = combineReducers<IAdminCreateUserOrderState['communications'], Actions>({
  userHorsesLoading: makeCommunicationReducerFromEnum(getUserHorsesActionTypes),
  userPackagesLoading: makeCommunicationReducerFromEnum(getUserPackagesActionTypes),
  userSummaryLoading: makeCommunicationReducerFromEnum(getUserSummaryActionTypes),
  userCouponLoading: makeCommunicationReducerFromEnum(getUserCouponActionTypes),
  creatingUserOrder: makeCommunicationReducerFromEnum(createUserOrderActionTypes),
  orderPackagesLoading: makeCommunicationReducerFromEnum(getOrderPackagesActionTypes),
});
