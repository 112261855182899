import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  Action,
  deleteReportActionTypes,
  ITestReportState,
  uploadReportActionTypes
} from 'Admin/AdminDashboard/store/common/testReport/types';

export const communicationReducer = combineReducers<ITestReportState['communications'], Action>({
  reportUploading: makeCommunicationReducerFromEnum(uploadReportActionTypes),
  reportDeleting: makeCommunicationReducerFromEnum(deleteReportActionTypes)
});
