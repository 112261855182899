import {Reducer} from 'redux';

import {
  BusinessPortalOrderActions,
  getOrderDetailsActionTypes,
  getOrderPackagesDetailsActionTypes,
  getOrderReportsActionTypes,
  getOrdersActionTypes,
  getOrderStatusesActionTypes,
  IBusinessPortalOrderState,
  resetOrderDetailsActionTypes,
  resetOrdersActionTypes,
} from '../types';

const initialState: IBusinessPortalOrderState['data'] = {
  orders: [],
  orderDetails: null,
  orderPackagesDetails: null,
  orderReports: [],
  orderStatuses: null,
};

export const dataBusinessPortalOrderReducer: Reducer<IBusinessPortalOrderState['data'], BusinessPortalOrderActions> = (
  state = initialState,
  action: BusinessPortalOrderActions
) => {
  switch (action.type) {
    case getOrdersActionTypes.SUCCESS: {
      return {...state, orders: action.payload.data};
    }
    case getOrderDetailsActionTypes.SUCCESS: {
      return {...state, orderDetails: action.payload};
    }
    case getOrderPackagesDetailsActionTypes.SUCCESS: {
      return {...state, orderPackagesDetails: action.payload};
    }
    case getOrderReportsActionTypes.SUCCESS: {
      return {...state, orderReports: action.payload};
    }
    case getOrderStatusesActionTypes.SUCCESS: {
      return {...state, orderStatuses: action.payload};
    }

    case resetOrderDetailsActionTypes.RESET: {
      return {...state, orderDetails: initialState.orderDetails};
    }
    case resetOrdersActionTypes.RESET: {
      return {...state, orders: initialState.orders};
    }

    default: {
      return state;
    }
  }
};
