import React from 'react';

import DeleteFavoriteHorseButton from 'UserProfile/components/TabsData/common/DeleteFavoriteHorseButton';
import AddFavoriteHorseButton from 'UserProfile/components/TabsData/common/AddFavoriteHorseButton';
import {MissingOrderContainer} from 'Shared/components/UserProfile/parts/UserHorsesTab/UserHorse/UserHorseButtons/styled';

interface IProps {
  isOwnFavorite: boolean;
  addFavoriteHorseHandler(): void;
  deleteFavoriteHorseHandler(): void;
}

const FavoriteHorseButtons = (props: IProps) => {
  const {isOwnFavorite, addFavoriteHorseHandler, deleteFavoriteHorseHandler} = props;

  if (isOwnFavorite) {
    return (
      <MissingOrderContainer className="d-flex">
        <DeleteFavoriteHorseButton onClick={deleteFavoriteHorseHandler} />
      </MissingOrderContainer>
    );
  }

  return (
    <MissingOrderContainer className="d-flex">
      <AddFavoriteHorseButton onClick={addFavoriteHorseHandler} />
    </MissingOrderContainer>
  );
};

export default FavoriteHorseButtons;
