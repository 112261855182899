import {Nullable} from 'Common/types';
import {ICommunication} from 'Common/store/utils/communication';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {
  IReleaseOrderHorse as IReleaseOrderHorseAdmin,
  IRequestSetSampleStatus as IRequestSetSampleStatusAdmin,
} from 'Admin/AdminDashboard/store/adminOrders';
import {IAppState} from 'Common/store/IAppState';
import {
  actions as onlineReportActions,
  selectors as onlineReportSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/index';
import {actions as adminActions, selectors as adminSelectors} from 'Admin/AdminDashboard/store/adminOrders/index';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import {
  IReleaseOrderHorse as IReleaseOrderHorseAdminAssociation,
  IRequestSetSampleStatus as IRequestSetSampleStatusAdminAssociation,
} from 'Admin/AdminAssociations/store/adminOrders';
import {AdminOnlineReportModule} from 'Admin/AdminDashboard/store/adminOnlineReport/adminOnlineReportModule';
import {
  actions as adminAssociationsActions,
  selectors as adminAssociationsSelectors,
} from 'Admin/AdminAssociations/store/adminOrders';
import {IRequiredTest} from 'Admin/AdminDashboard/models/IRequiredTest';

export interface IState {
  requiredTests: Nullable<IRequiredTest[]>;
  setSampleStatusRequesting: ICommunication;
  orderHorseReleaseRequesting: ICommunication;
  requiredTestsLoading: ICommunication;
}

export interface IDispatch<TRequestSetSampleStatus, TReleaseOrderHorse> {
  setSampleStatus(params: TRequestSetSampleStatus): void;
  releaseOrderHorse(data: TReleaseOrderHorse): void;
  getRequiredTests(orderId: number, horseId: number): void;
}

const adminDistributor: IDistributor<IState, IDispatch<IRequestSetSampleStatusAdmin, IReleaseOrderHorseAdmin>> = {
  state: (state: IAppState) => ({
    requiredTests: onlineReportSelectors.selectRequiredTests(state),
    setSampleStatusRequesting: adminSelectors.selectCommunication(state, 'setSampleStatusRequesting'),
    orderHorseReleaseRequesting: adminSelectors.selectCommunication(state, 'orderHorseReleaseRequesting'),
    requiredTestsLoading: onlineReportSelectors.selectCommunication(state, 'requiredTestsLoading'),
  }),
  dispatch: {
    setSampleStatus: adminActions.setSampleStatus,
    releaseOrderHorse: adminActions.releaseOrderHorse,
    getRequiredTests: onlineReportActions.getRequiredTests,
  },
  modules: AdminOnlineReportModule,
};

const adminAssociationDistributor: IDistributor<
  IState,
  IDispatch<IRequestSetSampleStatusAdminAssociation, IReleaseOrderHorseAdminAssociation>
> = {
  state: (state: IAppState) => ({
    requiredTests: adminAssociationsSelectors.selectOrderRequiredTests(state),
    setSampleStatusRequesting: adminAssociationsSelectors.selectCommunication(state, 'setSampleStatusRequesting'),
    orderHorseReleaseRequesting: adminAssociationsSelectors.selectCommunication(state, 'orderHorseReleaseRequesting'),
    requiredTestsLoading: adminAssociationsSelectors.selectCommunication(state, 'requiredTestsLoading'),
  }),
  dispatch: {
    setSampleStatus: adminAssociationsActions.setSampleStatus,
    releaseOrderHorse: adminAssociationsActions.releaseOrderHorse,
    getRequiredTests: adminAssociationsActions.getOrderRequiredTests,
  },
};

export const orderStatusDropdownDistributor: Record<SelectedStrategy, IDistributor<IState, IDispatch<any, any>>> = {
  [SelectedStrategy.Admin]: adminDistributor,
  [SelectedStrategy.AdminAssociation]: adminAssociationDistributor,
};
