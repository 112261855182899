import React, {memo, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useFormikContext} from 'formik';

import {SectionLabel, SectionHint} from './shared';
import {ExpansionPanel, Section} from 'Common/components/ExpansionPanel/ExpansionPanel';
import {SelectField, CheckboxField} from 'Common/components/FormFields';
import {castToOption} from 'Common/helpers/OptionHelper';
import {IBreed} from 'Dictionaries/models/IBreed';
import {IDiscipline} from 'Dictionaries/models/IDiscipline';
import SelectedValues from 'Filters/components/shared/SelectedValues/SelectedValues';
import {IOption} from 'Common/models/IOption';

import {IFormValues} from '../formConfig';

const selectStyles = {marginBottom: 0};

const AgeFields = styled.div`
  display: grid;
  grid-template-rows: 48px;
  grid-template-columns: 45% 10% 45%;
  margin-bottom: 20px;
`;

const Dash = styled.span`
  align-self: center;
  justify-self: center;
`;

const HINT: React.ReactElement = (
  <span>
    Here you can select breeds and disciplines of the parents. Remember, "breed" and "discipline" are{' '}
    <strong>not</strong> genetically verified values.
  </span>
);

interface IProps {
  breeds: IBreed[];
  disciplines: IDiscipline[];
  selectedCount: number;
}

function ParentFeatures(props: IProps) {
  const {breeds, disciplines, selectedCount} = props;
  const {values, setFieldValue} = useFormikContext<Partial<IFormValues>>();
  const [isClearableFrom, setIsClearableFrom] = useState(true);

  const {ageFrom, ageTo, includeTwoYears} = values;

  useEffect(
    () => {
      if (!includeTwoYears && (!ageFrom || (ageFrom && ageFrom < 2))) {
        setFieldValue('ageFrom', 2);
        setIsClearableFrom(false);
      }
      if (!includeTwoYears && ageFrom === 2) {
        setIsClearableFrom(false);
      } else {
        setIsClearableFrom(true);
      }
      if (!ageTo || !ageFrom) {
        return;
      }
      if (ageFrom > ageTo) {
        setFieldValue('ageTo', ageFrom);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ageFrom]
  );

  useEffect(
    () => {
      if (!ageTo || !ageFrom) {
        return;
      }
      if (ageFrom > ageTo) {
        setFieldValue('ageFrom', ageTo);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ageTo]
  );

  useEffect(() => {
    if (includeTwoYears) {
      setFieldValue('ageFrom', null);
      setIsClearableFrom(true);
      return;
    }
    setIsClearableFrom(false);
    if (ageFrom && ageFrom > 1) {
      return;
    }
    setFieldValue('ageFrom', 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeTwoYears]);

  const ageOptions: IOption[] = useMemo(() => {
    const sliced = includeTwoYears ? 1 : 2;

    return Array.from({length: 31})
      .map((_, i) => ({label: `${i} year${i > 1 ? 's' : ''}`, value: i}))
      .slice(sliced);
  }, [includeTwoYears]);

  return (
    <ExpansionPanel title="Features" headerContent={<SectionHint count={selectedCount} hint={HINT} />}>
      <Section>
        <SectionLabel>Breed</SectionLabel>
        <SelectField
          isMulti={true}
          name="breeds"
          options={castToOption(breeds)}
          controlShouldRenderValue={false}
          blurInputOnSelect={true}
          isClearable={false}
        />
        <SelectedValues name="breeds" options={castToOption(breeds)} />
      </Section>
      <Section>
        <SectionLabel>Discipline</SectionLabel>
        <SelectField
          isMulti={true}
          name="disciplines"
          options={castToOption(disciplines)}
          controlShouldRenderValue={false}
          blurInputOnSelect={true}
          isClearable={false}
          menuPlacement="top"
        />
        <SelectedValues name="disciplines" options={castToOption(disciplines)} />
      </Section>
      <Section>
        <SectionLabel>Age</SectionLabel>
        <AgeFields>
          <SelectField
            name="ageFrom"
            options={ageOptions}
            style={selectStyles}
            placeholder="From"
            isClearable={isClearableFrom}
          />
          <Dash>–</Dash>
          <SelectField name="ageTo" options={ageOptions} style={selectStyles} placeholder="To" isClearable={true} />
        </AgeFields>
        <CheckboxField name="includeTwoYears" label="Include horses under 2 years" />
      </Section>
    </ExpansionPanel>
  );
}

export default memo(ParentFeatures);
