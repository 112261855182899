import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {
  cancelSubscriptionActionTypes,
  getSubscriptionsActionTypes,
  IAdminSubscriptionState,
  overwriteFromStripeActionTypes,
  SubscriptionsActions
} from '../types';

export const communicationAdminSubscriptionsReducer = combineReducers<
  IAdminSubscriptionState['communications'],
  SubscriptionsActions
>({
  subscriptionsLoading: makeCommunicationReducerFromEnum(getSubscriptionsActionTypes),
  subscriptionsOverwriting: makeCommunicationReducerFromEnum(overwriteFromStripeActionTypes),
  subscriptionCanceling: makeCommunicationReducerFromEnum(cancelSubscriptionActionTypes)
});
