import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';
import withDate from 'Common/helpers/withDate';

import {actions, selectors} from 'Admin/AdminSettings/stores/adminHtmlTemplates/index';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import Loading from 'Loading/components/Loading';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {IHtmlTemplate} from 'Admin/AdminSettings/models/IHtmlTemplate';
import {AdminHtmlTemplatesModule} from 'Admin/AdminSettings/stores/adminHtmlTemplates/adminHtmlTemplatesModule';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import Pagination from 'Common/components/Controls/Pagination/Pagination';
import HtmlTemplateForm from './HtmlTemplateForm';
import {HtmlTemplateType} from 'Admin/AdminSettings/constants/HtmlTemplateType';
import {FormType} from 'Common/constants/FormType';
import {FRONTEND_DATE_TIME_24H} from 'Common/constants/Date';
import HtmlTemplateView from './HtmlTemplateView';

const CONFIRM_DELETE_HTML_TEMPLATE = 'Are you sure you want to remove the template?';

const actionButtonProps = {color: ColorPalette.gray28, fill: true, stroke: false, size: 24};

const AddTemplateButton = styled(PrimaryButton)`
  width: 176px;
  height: 50px;
  margin-left: 55px;
`;

const TemplateTable = styled.div`
  margin-top: 50px;
`;

const ActionButton = styled(IconButton)`
  margin-left: 12px;
`;

interface IActionProps {
  id: number;
  type: HtmlTemplateType;
  name: string;
  showAction: (type: HtmlTemplateType, name: string) => void;
  deleteAction: (id: number) => void;
  editAction: (type: HtmlTemplateType, name: string) => void;
}

const Actions = React.memo((props: IActionProps) => {
  const {id, name, type, editAction, deleteAction, showAction} = props;

  const handleEditAction = useCallback(() => editAction(type, name), [editAction, name, type]);
  const handleDeleteAction = useCallback(() => deleteAction(id), [id, deleteAction]);
  const handleShowAction = useCallback(() => showAction(type, name), [name, showAction, type]);

  return (
    <div className="d-flex align-items-center justify-content-end">
      <ActionButton name={IconName.ShowInfo} {...actionButtonProps} onClick={handleShowAction} />
      <ActionButton name={IconName.Edit} {...actionButtonProps} onClick={handleEditAction} />
      <ActionButton name={IconName.Garbage} {...actionButtonProps} onClick={handleDeleteAction} />
    </div>
  );
});

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const HtmlTemplates = (props: AllProps) => {
  const {getHtmlTemplates, templatesLoading, templates, deleteHtmlTemplate, templateDeleting, pagination} = props;

  const {
    openDeleteModal,
    reloadItems,
    changeSorting,
    sorting,
    deleteModal,
    searchBar,
    handlePageSelect,
    handleCountPerPage,
  } = useCommonAdminPageData<IHtmlTemplate>({
    getItems: getHtmlTemplates,
    deleteParams: {
      communication: templateDeleting,
      confirmDescription: CONFIRM_DELETE_HTML_TEMPLATE,
      action: deleteHtmlTemplate,
    },
    searchBarPlaceholder: 'Search for templates by any keyword',
  });

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [formType, setFormType] = useState<FormType>(FormType.create);
  const [templateType, setTemplateType] = useState<HtmlTemplateType>();
  const [templateName, setTemplateName] = useState<string>();
  const [isShowModalOpen, setIsShowModalOpen] = useState(false);

  const isRequesting = [templatesLoading, templateDeleting].some((i) => i.isRequesting);

  const isShowPagination = templates.length > 0;

  const handleAddTemplate = () => {
    setFormType(FormType.create);
    setTemplateType(undefined);
    setTemplateName(undefined);
    setIsEditModalOpen(true);
  };

  const handleEditTemplate = (type: HtmlTemplateType, name: string) => {
    setFormType(FormType.edit);
    setTemplateType(type);
    setTemplateName(name);
    setIsEditModalOpen(true);
  };

  const closeEditModal = useCallback(() => {
    setIsEditModalOpen(false);
  }, []);

  const onSuccessSubmitHtmlTemplateForm = useCallback(() => {
    closeEditModal();
    reloadItems();
  }, [closeEditModal, reloadItems]);

  const handleShowTemplate = (type: HtmlTemplateType, name: string) => {
    setTemplateType(type);
    setTemplateName(name);
    setIsShowModalOpen(true);
  };

  const closeShowModal = useCallback(() => {
    setIsShowModalOpen(false);
  }, []);

  return (
    <AdminPageLayout>
      {deleteModal}
      <ModalWindow isOpen={isEditModalOpen} onClose={closeEditModal}>
        <HtmlTemplateForm
          type={templateType}
          name={templateName}
          formType={formType}
          onSuccess={onSuccessSubmitHtmlTemplateForm}
        />
      </ModalWindow>

      <ModalWindow isOpen={isShowModalOpen} onClose={closeShowModal}>
        <HtmlTemplateView type={templateType} name={templateName} onClose={closeShowModal} />
      </ModalWindow>

      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
        <AddTemplateButton onClick={handleAddTemplate}>+ Add template</AddTemplateButton>
      </div>
      <TemplateTable className="position-relative">
        {isRequesting && <Loading />}
        <Table<IHtmlTemplate> data={templates} rowKey="id" sorting={sorting} onChangeSorting={changeSorting}>
          <Cell<IHtmlTemplate> header="ID" dataKey="id" render={({id}) => `#${id}`} width="10%" />
          <Cell<IHtmlTemplate> header="Type" dataKey="type" width="30%" render={({type}) => type} />
          <Cell<IHtmlTemplate> header="Name" dataKey="name" render={({name}) => name} width="30%" />
          <Cell<IHtmlTemplate>
            header="Updated at"
            dataKey="updatedDate"
            width="20%"
            render={({updatedDate}) => `${withDate(updatedDate, FRONTEND_DATE_TIME_24H)}`}
          />

          <Cell<IHtmlTemplate>
            header="Actions"
            align={CellAlign.Right}
            width={100}
            render={({id, type, name}) => (
              <Actions
                id={id}
                type={type}
                name={name}
                editAction={handleEditTemplate}
                deleteAction={openDeleteModal}
                showAction={handleShowTemplate}
              />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </TemplateTable>
    </AdminPageLayout>
  );
};

const mapStateToProps = (state: IAppState) => ({
  templates: selectors.selectHtmlTemplates(state),
  templatesLoading: selectors.selectCommunication(state, 'templatesLoading'),
  templateDeleting: selectors.selectCommunication(state, 'templateDeleting'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getHtmlTemplates: actions.getHtmlTemplates,
  deleteHtmlTemplate: actions.deleteHtmlTemplate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(HtmlTemplates));

export default withDynamicModules(Connected, AdminHtmlTemplatesModule);
