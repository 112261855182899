import * as Yup from 'yup';
import {ITest} from 'Dictionaries/models/ITest';
import {IDescription, IShortDescriptionEntity} from 'Common/models/IDescriptionEntity';
import {IEntity} from 'Common/models/IEntity';
import {Nullable} from 'Common/types';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues extends IShortDescriptionEntity {
  id?: IEntity['id'];
  name: string;
  mutationAllele: Nullable<string>;
  referenceAllele: Nullable<string>;
  gene: Nullable<number>;
  title: Nullable<string>;
}

export const initialValue: IFormValues = {
  name: '',
  title: null,
  description: {value: ''},
  mutationAllele: '',
  referenceAllele: '',
  gene: null,
};

export const validationSchema = Yup.object().shape<IFormValues>({
  name: Yup.string(),
  title: Yup.string().nullable(),
  description: Yup.mixed<IDescription>(),
  mutationAllele: Yup.string().nullable(),
  referenceAllele: Yup.string().nullable(),
  gene: Yup.number().nullable().required(REQUIRED_FIELD),
});

export const convertToServer = (values: IFormValues): Omit<ITest, 'id'> => {
  return {
    ...values,
    gene: values.gene ? {id: values.gene} : null,
    description: {value: values.description?.value || ''},
  };
};

export const convertToServerUpdating = (values: IFormValues): ITest => {
  return {
    ...convertToServer(values),
    id: values.id!,
  };
};
