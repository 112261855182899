import {IUser} from 'UserProfile/models/IUser';
import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';
import {IUserHorse} from 'Horse/models/IUserHorse';
import {IPagination} from 'Common/models/IResponse';

const STATE_NAME = 'businessPortalOwnerProfile';

export interface IBusinessPortalOwnerProfileState {
  data: {
    owner: IUser | null;
    horsesByOwner: IUserHorse[];
  };
  communications: {
    ownerLoading: ICommunication;
    horsesByOwnerLoading: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getOwnerActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_OWNER');
export type getOwnerActions = MakeCommunicationActions<typeof getOwnerActionTypes, {success: IUser}>;

export const getHorsesByOwnerActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSES_BY_OWNER');
export type getHorsesByOwnerActions = MakeCommunicationActions<
  typeof getHorsesByOwnerActionTypes,
  {success: IUserHorse[]}
>;

export type OwnerProfileActions = getOwnerActions | getHorsesByOwnerActions;
