import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.adminAdministrators;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectAdministrators(state: IAppState) {
  return selectState(state).data.administrators;
}

export function selectAdministrator(state: IAppState) {
  return selectState(state).data.administrator;
}

export function selectAdministratorAvatar(state: IAppState) {
  return selectState(state).data.administratorAvatar;
}

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}
