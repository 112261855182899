import {PaymentMethod} from 'Common/constants/PaymentMethod';
import {IEntity} from 'Common/models/IEntity';
import {OrderStatusField} from 'Order/models/IOrderStatusFields';
import {Nullable} from 'Common/types';

export interface IPaymentDetails extends IEntity {
  receivedDate: Nullable<string>;
  paymentMethod: PaymentMethod;
  payee: Nullable<string>;
  transactionId: string;
  paymentStatus: Nullable<OrderStatusField>;
  timestamp: string;
  sendEmail: boolean;
}

export enum PaymentStatus {
  Waiting = 'Waiting',
  Ready = 'Paid',
}
