import {BROKEN_RULE_ICON, HAS_RULE_ICON, NO_RULE_ICON} from '../components/share/StyledComponents';
import {ITraitSequence} from '../models/ITraitSequence';

export function getRuleIcon(sequences?: ITraitSequence[]) {
  return sequences && sequences.length > 0
    ? sequences.some(s => s.traits.length === 0)
      ? BROKEN_RULE_ICON
      : HAS_RULE_ICON
    : NO_RULE_ICON;
}
