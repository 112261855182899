import {Reducer} from 'redux';

import {HtmlTemplatesActions, getHtmlTemplatesActionTypes, IAdminHtmlTemplateState} from '../types';

const initialState: IAdminHtmlTemplateState['ui'] = {
  pagination: {countPerPage: 0, currentPage: 1, pageCount: 1, totalItemsCount: 1}
};

export const uiHtmlTemplatesReducer: Reducer<IAdminHtmlTemplateState['ui'], HtmlTemplatesActions> = (
  state = initialState,
  action: HtmlTemplatesActions
) => {
  switch (action.type) {
    case getHtmlTemplatesActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination || initialState.pagination};
    }
    default: {
      return state;
    }
  }
};
