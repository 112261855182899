import {memo, useCallback, useEffect, useMemo, useState} from 'react';

import {Title} from '../parts/styled';
import {IFacetProps} from '../Facet';
import {Select} from 'Common/components/Controls';
import {IFilterRequest} from 'FacetFilter/models/IFilterRequest';
import {getStringKeysOption} from 'Common/helpers/OptionHelper';
import {Enumeration, Nullable} from 'Common/types';
import {ActionMeta, PropsValue} from 'react-select/dist/declarations/src/types';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';
import {OperationDropDown} from '../parts/OperationDropDown';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {selectStyles} from './styled';

const style = {
  marginBottom: 0,
};

interface IProps extends IFacetProps {
  enumeration: Enumeration;
}

function EnumFacet(props: IProps) {
  const {
    enumeration,
    filterProperty,
    filterPropertyOperations,
    filterRequest = {
      property: filterProperty.property,
      operation: filterPropertyOperations?.operations[0].operation || '',
      value: '',
    },
    onSuccess,
  } = props;

  const [request, setRequest] = useState<IFilterRequest>(filterRequest);
  const [selectedEnumValue, setSelectedEnumValue] = useState<Nullable<PropsValue<IDictionaryOptionType>>>(null);

  useEffect(() => {
    setRequest(filterRequest);
    const findOption = options.find((x) => x.value === filterRequest.value);
    setSelectedEnumValue(findOption || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = useMemo(
    () => getStringKeysOption(enumeration).sort((a, b) => sortByStringKey(a, b, 'label')),
    [enumeration]
  );

  useEffect(() => {
    if (selectedEnumValue) {
      onSuccess(request);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const onSelectOption = useCallback(
    (enumValue: PropsValue<IDictionaryOptionType>, action: ActionMeta<IDictionaryOptionType>) => {
      if (action.action === 'select-option' && enumValue) {
        const {value} = enumValue as IDictionaryOptionType;
        setSelectedEnumValue(enumValue as IDictionaryOptionType);
        setRequest({...request, value: value.toString()});
      }
    },
    [request]
  );

  const onOperationSelect = useCallback(
    (operation: string) => {
      setRequest({...request, operation});
    },
    [request]
  );

  return (
    <>
      <Title>{filterProperty.title}</Title>
      {filterPropertyOperations && (
        <OperationDropDown
          filterOperations={filterPropertyOperations}
          onSelect={onOperationSelect}
          defaultOperation={filterRequest.operation}
        />
      )}
      <Select
        options={options}
        value={selectedEnumValue}
        onChange={onSelectOption}
        style={style}
        styles={selectStyles}
      />
    </>
  );
}

export default memo(EnumFacet);
