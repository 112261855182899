import React, {memo, useEffect} from 'react';

import {ISampleAdmin} from 'Admin/AdminDashboard/models/Order/IOrderHorseDetailsAdmin';

import {IAppState} from 'Common/store/IAppState';
import {actions, IRequestChooseAnotherSample, selectors} from 'Admin/AdminDashboard/store/adminOrders/index';
import {AdminOrdersModule} from 'Admin/AdminDashboard/store/adminOrders/adminOrdersModule';
import {connect, ConnectedProps} from 'react-redux';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import Loading from 'Loading/components/Loading';
import {SelectField} from 'Common/components/FormFields/index';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {Form, FormikProps, withFormik} from 'formik';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {convertSampleToChooseRequestSample, validationSchema} from './validation';
import {castToOption} from 'Common/helpers/OptionHelper';

interface IExternalProps {
  orderId: number;
  horseId: number;
  sample: ISampleAdmin;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IRequestChooseAnotherSample> & OuterProps;

const ChooseAnotherSampleForm = (props: AllProps) => {
  const {status, setStatus, isValid, setErrors, isSubmitting} = props;
  const {
    horseId,
    horseSamplesLoading,
    getHorseSamples,
    horseSamples,
    chooseAnotherSampleRequesting,
    resetChooseAnotherSampleCommunication,
    onSuccess,
  } = props;

  const errorInfo = chooseAnotherSampleRequesting.error || horseSamplesLoading.error;
  const isRequesting = chooseAnotherSampleRequesting.isRequesting || horseSamplesLoading.isRequesting;

  useOnSuccessCommunication(chooseAnotherSampleRequesting, onSuccess);

  useEffect(() => {
    if (horseId) {
      getHorseSamples(horseId);
    }
  }, [horseId, getHorseSamples]);

  useEffect(() => {
    return () => resetChooseAnotherSampleCommunication();
  }, [resetChooseAnotherSampleCommunication]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    const fieldErrors = getFieldErrors(errorInfo);

    if (commonErrors) {
      setStatus(commonErrors);
    }

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  return (
    <>
      <ModalWindowHeader>Choose another sample</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        {isRequesting && <Loading />}
        <ModalWindowFormContent>
          <SelectField
            name="targetSampleId"
            label="New Sample ID"
            placeholder="New Sample ID"
            sourceField="label"
            options={castToOption(horseSamples, {key: 'sampleId'})}
            menuPosition="fixed"
          />
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" disabled={!isValid} isLoading={isSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const ChooseAnotherSampleFormFormik = withFormik<OuterProps, IRequestChooseAnotherSample>({
  mapPropsToValues: ({orderId, sample}) => convertSampleToChooseRequestSample(orderId, sample),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    await formikBag.props.chooseAnotherSample(values);
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(ChooseAnotherSampleForm);

const mapStateToProps = (state: IAppState) => ({
  horseSamples: selectors.selectHorseSamples(state),
  horseSamplesLoading: selectors.selectCommunication(state, 'horseSamplesLoading'),
  chooseAnotherSampleRequesting: selectors.selectCommunication(state, 'chooseAnotherSampleRequesting'),
});

const mapDispatchToProps = {
  getHorseSamples: actions.getHorseSamples,
  chooseAnotherSample: actions.chooseAnotherSample,
  resetChooseAnotherSampleCommunication: actions.resetChooseAnotherSampleCommunication,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(ChooseAnotherSampleFormFormik));

export default withDynamicModules(Connected, AdminOrdersModule);
