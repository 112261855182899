import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IMediaResource} from 'Common/models/IMediaResource';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.gallery;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectGallery(state: IAppState): IMediaResource[] {
  return selectState(state).data.gallery;
}
