import React, {memo} from 'react';

import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {Nullable} from 'Common/types';
import {NoData} from 'BusinessPortal/components/common/NoData/NoData';
import {IAssociationPageContactInformation} from 'BusinessPortal/models/IAssociationPageContactInformation';
import {Label, Value, Wrapper} from 'BusinessPortal/components/shared/StyledComponents';

interface IProps {
  contactInformation: Nullable<IAssociationPageContactInformation>;
}

const ContactInformationShow = (props: IEditableSection<IProps>) => {
  const {data} = props;

  if (!data?.contactInformation) {
    return <NoData />;
  }

  const {
    contactInformation: {email, phoneNumber, websiteUrl},
  } = data;

  return (
    <div>
      <Wrapper>
        <Label>Email:</Label>
        <Value>{email}</Value>
      </Wrapper>

      <Wrapper>
        <Label>Phone number:</Label>
        <Value>{phoneNumber}</Value>
      </Wrapper>

      <Wrapper>
        <Label>Website URL:</Label>
        <Value>{websiteUrl}</Value>
      </Wrapper>
    </div>
  );
};

export default memo(ContactInformationShow);
