import React, {memo} from 'react';

import Indicator from 'Common/components/Indicator/Indicator';
import ColorPalette from 'Common/constants/ColorPalette';
import {IFieldProps} from 'Common/models/IFieldProps';
import withField from 'Common/components/FormFields/withField';
import {ValidationError} from 'Common/components/FormFields/index';
import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import styled from 'styled-components';
import {ISegment} from 'Common/models/ISegment';
const RemoveButton = styled(IconButton)`
  bottom: 30px;
  margin-left: 16px;
`;

interface IProps {
  segmentColor?: string;
  segmentHeight?: number;
  segmentsCount?: number;
  isResetable?: boolean;
  resetIndicator?(): void;
  showBottomLabels?: boolean;
  segments?: ISegment[];
  labelSelectedColor?: string;
}

type AllProps = IProps & IFieldProps & IBaseControlProps;

const IndicatorField = (props: AllProps) => {
  const {
    field: {name, value},
    form,
    segmentColor,
    segmentHeight,
    segmentsCount,
    resetIndicator,
    isResetable,
    showBottomLabels,
    segments,
    disabled,
    labelSelectedColor,
    ...other
  } = props;

  const indicatorSettings = {
    segmentColor: segmentColor ?? ColorPalette.red1,
    segmentsMaxCount: segmentsCount ?? 10,
    height: segmentHeight ?? 12,
    showBottomLabels: showBottomLabels ?? false,
    disabled: !!disabled,
    labelSelectedColor: labelSelectedColor
  };

  const onClick = React.useCallback(() => {
    form.setFieldValue(name, 0);
    resetIndicator && resetIndicator();
  }, [form, name, resetIndicator]);

  const onChange = React.useCallback(
    (value: number) => {
      form.setFieldValue(name, value);
    },
    [form, name]
  );

  return (
    <FieldControlContainer {...other}>
      <div className="d-flex flex-row">
        <Indicator indicator={{...indicatorSettings, segments: segments, value: +value || 0}} onChange={onChange} />
        {isResetable && (
          <RemoveButton
            onClick={onClick}
            name={IconName.Clear}
            color={ColorPalette.white1}
            size={14}
            fill={false}
            stroke={false}
            hoverColor={ColorPalette.yellow2}
          />
        )}
      </div>
      <ValidationError name={name} />
    </FieldControlContainer>
  );
};

export default memo(withField(IndicatorField));
