import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import EditableSection from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {actions, selectors} from 'BusinessPortal/store/associationPage';
import ContactInformationShow from './ContactInformationShow';
import ContactInformationEdit from './ContactInformationEdit';

const Root = styled.div`
  max-width: 576px;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const ContactInformation = (props: AllProps) => {
  const {contactInformation, contactInformationLoading, getContactInformation} = props;

  const mainRequest = useCallback(() => getContactInformation(), [getContactInformation]);

  useEffect(() => {
    mainRequest();
  }, [mainRequest]);

  const onSuccessEdit = useCallback(() => mainRequest(), [mainRequest]);

  const isRequesting = contactInformationLoading.isRequesting;

  return (
    <Root className="position-relative">
      {isRequesting && <Loading />}
      <EditableSection
        title="Contact info"
        data={{contactInformation}}
        onSuccess={onSuccessEdit}
        editComponent={ContactInformationEdit}
        showComponent={ContactInformationShow}
      />
    </Root>
  );
};

const mapStateToProps = (state: IAppState) => ({
  contactInformation: selectors.selectContactInformation(state),
  contactInformationLoading: selectors.selectCommunication(state, 'contactInformationLoading'),
});

const mapDispatchToProps = {
  getContactInformation: actions.getContactInformation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(ContactInformation));
