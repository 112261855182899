import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
} from 'Common/store/utils/communication/index';
import {IDefaultPaymentMethod} from 'StripeSubscription/models/IDefaultPaymentMethod';
import {IScheduledSubscription, ISubscription, ISubscriptionResponse} from 'StripeSubscription/models/ISubscription';
import {Nullable} from 'Common/types';

const STATE_NAME = 'stripeSubscription';

export interface IStripeSubscriptionState {
  data: {
    defaultPaymentMethod: Nullable<IDefaultPaymentMethod>;
    createdSubscription: Nullable<ISubscriptionResponse>;
    userSubscriptions: Nullable<ISubscription[]>;
    userScheduledSubscription: Nullable<IScheduledSubscription[]>;
  };
  communications: {
    createSubscriptionRequesting: ICommunication;
    userSubscriptionsLoading: ICommunication;
    userScheduledSubscriptionsLoading: ICommunication;
    ensureCustomerCreatedRequesting: ICommunication;
    defaultPaymentMethodLoading: ICommunication;
    changeDefaultPaymentMethodRequesting: ICommunication;
    retryInvoiceRequesting: ICommunication;
    cancelSubscriptionRequesting: ICommunication;
    deleteSubscriptionRequesting: ICommunication;
    cancelScheduledSubscriptionRequesting: ICommunication;
    changeSubscriptionPlanRequesting: ICommunication;
    renewSubscriptionRequesting: ICommunication;
    setupIntentClientSecretLoading: ICommunication;
    isCustomerExistLoading: ICommunication;
    refreshSubscriptionFromStripeRequesting: ICommunication;
  };
}

export const getDefaultPaymentMethodActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_DEFAULT_PAYMENT_METHOD');
export type getDefaultPaymentMethodActions = MakeCommunicationActions<
  typeof getDefaultPaymentMethodActionTypes,
  {success: IDefaultPaymentMethod}
>;

export const changeDefaultPaymentMethodActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CHANGE_DEFAULT_PAYMENT_METHOD'
);
export type changeDefaultPaymentMethodActions = MakeCommunicationActions<
  typeof changeDefaultPaymentMethodActionTypes,
  {}
>;

export const ensureCustomerCreatedActionTypes = makeCommunicationActionType(STATE_NAME, 'ENSURE_CUSTOMER_CREATED');
export type ensureCustomerCreatedActions = MakeCommunicationActions<typeof ensureCustomerCreatedActionTypes, {}>;

export const isCustomerExistActionTypes = makeCommunicationActionType(STATE_NAME, 'IS_CUSTOMER_EXIST');
export type isCustomerExistActions = MakeCommunicationActions<typeof isCustomerExistActionTypes, {}>;

export const createSubscriptionActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_SUBSCRIPTION');
export type createSubscriptionActions = MakeCommunicationActions<
  typeof createSubscriptionActionTypes,
  {success: ISubscriptionResponse}
>;

export const getUserSubscriptionsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_SUBSCRIPTIONS');
export type getUserSubscriptionsActions = MakeCommunicationActions<
  typeof getUserSubscriptionsActionTypes,
  {success: ISubscription[]}
>;

export const getUserScheduledSubscriptionsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_USER_SCHEDULED_SUBSCRIPTIONS'
);
export type getUserScheduledSubscriptionsActions = MakeCommunicationActions<
  typeof getUserScheduledSubscriptionsActionTypes,
  {success: IScheduledSubscription[]}
>;

export const retryInvoiceActionTypes = makeCommunicationActionType(STATE_NAME, 'RETRY_INVOICE');
export type retryInvoiceActions = MakeCommunicationActions<typeof retryInvoiceActionTypes, {}>;

export const getSetupIntentClientSecretActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_SETUP_INTENT_CLIENT_SECRET'
);
export type getSetupIntentClientSecretActions = MakeCommunicationActions<
  typeof getSetupIntentClientSecretActionTypes,
  {}
>;

export const deleteSubscriptionActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_SUBSCRIPTION');
export type deleteSubscriptionActions = MakeCommunicationActions<typeof deleteSubscriptionActionTypes, {}>;

export const cancelSubscriptionActionTypes = makeCommunicationActionType(STATE_NAME, 'CANCEL_SUBSCRIPTION');
export type cancelSubscriptionActions = MakeCommunicationActions<typeof cancelSubscriptionActionTypes, {}>;

export const cancelScheduledSubscriptionActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CANCEL_SCHEDULED_SUBSCRIPTION'
);
export type cancelScheduledSubscriptionActions = MakeCommunicationActions<
  typeof cancelScheduledSubscriptionActionTypes,
  {}
>;

export const changeSubscriptionPlanActionTypes = makeCommunicationActionType(STATE_NAME, 'CHANGE_SUBSCRIPTION_PLAN');
export type changeSubscriptionPlanActions = MakeCommunicationActions<typeof changeSubscriptionPlanActionTypes, {}>;

export const renewSubscriptionActionTypes = makeCommunicationActionType(STATE_NAME, 'RENEW_SUBSCRIPTION');
export type renewSubscriptionActions = MakeCommunicationActions<typeof renewSubscriptionActionTypes, {}>;

export const refreshSubscriptionFromStripeActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'REFRESH_SUBSCRIPTION_FROM_STRIPE'
);
export type refreshSubscriptionFromStripeActions = MakeCommunicationActions<
  typeof refreshSubscriptionFromStripeActionTypes,
  {}
>;

export type stripeSubscriptionActions =
  | getDefaultPaymentMethodActions
  | changeDefaultPaymentMethodActions
  | ensureCustomerCreatedActions
  | createSubscriptionActions
  | getUserSubscriptionsActions
  | getUserScheduledSubscriptionsActions
  | cancelSubscriptionActions
  | cancelScheduledSubscriptionActions
  | deleteSubscriptionActions
  | retryInvoiceActions
  | changeSubscriptionPlanActions
  | renewSubscriptionActions
  | getSetupIntentClientSecretActions
  | isCustomerExistActions
  | refreshSubscriptionFromStripeActions;
