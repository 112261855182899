import {IHorseDetailsBP} from 'BusinessPortal/models/horse/IHorseDetailsBP';
import {IHorseOwnerBP} from 'BusinessPortal/models/horse/IHorseOwnerBP';
import {IHorseParentageBP} from 'BusinessPortal/models/horse/IHorseParentageBP';
import {IRegistry} from 'Horse/models/IRegistry';
import {IInconclusiveHealthIssue} from 'Dictionaries/models/InconclusiveHealthIssues';
import {IMediaResource} from 'Common/models/IMediaResource';
import {convertToClient as convertRegistrationAndParentageToClient} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/RegistrationAndParentageSection/converters';
import {convertToClient as convertHorseDetailsToClient} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseInfoSection/converters';
import {ProfileType} from 'Common/constants/ProfileType';
import {convertToServerDate} from 'Common/helpers/DateHelper';
import {
  IFormValues,
  initialValue,
  IUpdateHorseBPFormValues,
} from 'Admin/AdminAssociations/components/Horses/HorseForm/validation';

type convertToClientData = {
  horseDetails: IHorseDetailsBP;
  horseOwner: IHorseOwnerBP;
  horseParentage: IHorseParentageBP[];
  horseRegistries: IRegistry[];
  horseHealthIssues: IInconclusiveHealthIssue[];
  horseMedia: IMediaResource[];
};

export const convertToClient = (data: convertToClientData): IFormValues => {
  const registrationAndParentage = convertRegistrationAndParentageToClient({
    horseId: data.horseDetails.id,
    parentage: data.horseParentage,
    registries: data.horseRegistries,
  });
  const horseDetails = convertHorseDetailsToClient(data.horseDetails);
  return {
    ...horseDetails,
    organizationId: data.horseDetails.simpleOrganization?.id || initialValue.organizationId,
    ownerId: data.horseOwner.id || initialValue.ownerId,
    gender: data.horseDetails.gender || initialValue.gender,
    height: data.horseDetails.height || initialValue.height,
    microchipNumber: data.horseDetails.microchipNumber || initialValue.microchipNumber,
    passportNumber: data.horseDetails.passportNumber || initialValue.passportNumber,
    inconclusiveHealthIssues: data.horseHealthIssues.map((i) => i.id) || [],
    gallery: data.horseMedia || [],
    parents: registrationAndParentage.parents,
    registries: registrationAndParentage.registries,
    additionalInformation: '',
    participateInEtalonProjects: initialValue.participateInEtalonProjects,
    isPublicProfile: data.horseDetails.profileType === ProfileType.Public,
    owner: {
      id: data.horseOwner.id,
      avatar: data.horseOwner.avatar,
      firstName: data.horseOwner.firstName,
      lastName: data.horseOwner.lastName,
    },
    isAncestryPublicProfile: data.horseDetails.ancestryProfileType === ProfileType.Public,
  };
};

export const convertToServer = (data: IFormValues): IUpdateHorseBPFormValues => {
  const allAddressFieldsAreEmpty =
    data.address && Object.values(data.address).every((value) => value === '' || value === null);
  return {
    ...data,
    address: allAddressFieldsAreEmpty ? null : data.address,
    dateOfBirth: convertToServerDate(data.dateOfBirth),
    profileType: data.isPublicProfile ? ProfileType.Public : ProfileType.Private,
    ancestryProfileType: data.isAncestryPublicProfile ? ProfileType.Public : ProfileType.Private,
  };
};
