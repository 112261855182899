import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.adminUsers;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectUsers(state: IAppState) {
  return selectState(state).data.users;
}

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectUserDetails(state: IAppState) {
  return selectState(state).data.userDetails;
}
