import {memo, useCallback} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import EtalonExample from '../common/img/examples/etalon-example.png';
import {AncestryReportSection} from '../common/styled';
import CompositionPie from '../CompositionChart/CompositionPie';
import {breedAveragesExamples} from './data';
import {breakpoints} from 'Common/constants/Breakpoints';

const Root = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: auto;

  @media ${breakpoints.md} {
    grid-template-columns: auto auto;
  }
  grid-gap: 70px;
`;

const HeaderRoot = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 16px;
`;

const HeaderBase = styled.div`
  font-family: ${Typography.family.openSans};
  color: ${Theme.color.white};
  background: ${ColorPalette.brown2};
  text-align: center;
`;

const BreedName = styled(HeaderBase)`
  width: 320px;

  font-size: ${Typography.size.size28};
  font-weight: ${Typography.weight.semiBold600};
  line-height: 50px;
  border-radius: 25px;
`;

const PercantageWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  @media ${breakpoints.sm} {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const Percantage = styled(HeaderBase)`
  width: 280px;
  font-family: ${Typography.family.openSans};
  font-size: ${Typography.size.size12};
  font-weight: ${Typography.weight.light300};
  line-height: 36px;
  border-radius: 25px;
  color: ${hexToRgba(Theme.color.white, 0.9)};
  margin-bottom: 8px;
`;

const ExampleImg = styled.img`
  width: 70px;
  height: 27px;
  bottom: 0;
  right: 0;

  @media ${breakpoints.md} {
    width: 94px;
    height: 35px;
  }
`;

const ExamplesOfBreeds = () => {
  const renderHeader = useCallback((title: string, averageHeterozygosity: number, inbreedingCoefficient: number) => {
    return (
      <HeaderRoot className="d-flex flex-column justify-content-center align-items-center">
        <BreedName>{title}</BreedName>
        <PercantageWrapper className="d-flex w-100">
          <Percantage>{`Average Heterozygosity: ${averageHeterozygosity.toFixed(1)}%`}</Percantage>
          <Percantage>{`Inbreeding Coefficient: ${inbreedingCoefficient.toFixed(1)}%`}</Percantage>
        </PercantageWrapper>
      </HeaderRoot>
    );
  }, []);

  return (
    <AncestryReportSection>
      <Root>
        {breedAveragesExamples.map((item, i) => {
          return (
            <div className="position-relative" key={i}>
              {renderHeader(item.name, item.averageHeterozygosity, item.inbreedingCoefficient)}
              <CompositionPie
                population={item.population}
                isShowLabels
                isShowLegend
                isShowLegendPercentage
                customIamge={item.image}
              />
              <ExampleImg className="position-absolute" src={EtalonExample}></ExampleImg>
            </div>
          );
        })}
      </Root>
    </AncestryReportSection>
  );
};

export default memo(ExamplesOfBreeds);
