import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';

import {InputField, TextAreaField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {RequiredBy} from 'Common/types';
import {Combination} from 'Common/constants/Combination';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';

import {IGenotypeCombination} from 'Admin/AdminPhenotypes/models/IGenotypeCombination';
import {IUpdateGenotypeCombinationRequest} from 'Admin/AdminPhenotypes/service/models/IGenotypeCombination';
import {IUpdateGenotypeSequence} from 'Admin/AdminPhenotypes/models/IGenotypeSequence';
import {convertToServer, convertToServerUpdating, IFormValues, initialValue, validationSchema} from './validation';
import {useGenotypeSequenceEditor} from 'SequenceEditor/hooks/useGenotypeSequenceEditor';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

type IOwnProps = IPhenotypeFormExternalProps<IGenotypeCombination, IUpdateGenotypeCombinationRequest>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IOwnProps & IConnected;

type Props = FormikProps<IFormValues> & OuterProps;

function GenotypeCombinationForm(props: Props) {
  const {itemId, dictionary, ...rest} = props;
  const {setFieldValue, values} = props;
  const {getItem} = dictionary.actions;

  useEffect(() => {
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem]);

  const onSuccessSequenceEditor = useCallback(
    (type: Combination, sequences: IUpdateGenotypeSequence[]) => {
      setFieldValue('type', type);
      setFieldValue('sequences', sequences);
    },
    [setFieldValue]
  );

  const {renderRuleField, renderSequenceEditorModal} = useGenotypeSequenceEditor(
    values.type,
    values?.sequences ?? [],
    onSuccessSequenceEditor
  );

  return (
    <DictionaryForm {...rest}>
      {renderSequenceEditorModal()}

      <InputField name="alleleNames" label="Variants" placeholder="Name" />
      <InputField name="positionIndex" label="Order in Report" placeholder="Order in Report" className="w-50" />
      <TextAreaField name="description.value" label="Description" placeholder="Description" />

      {renderRuleField()}
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  return makePhenotypeFormSelectors(state, ownProps.dictionary);
};

const DangerAdvancedFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting ? item : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(GenotypeCombinationForm);

const connector = connect(mapStateToProps);
export default connector(memo(DangerAdvancedFormFormik));
