import React, {useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {IOwnerBP} from 'BusinessPortal/models/owner/IOwnerBP';
import {actions, BusinessPortalOwnerModule, selectors} from 'BusinessPortal/store/owner';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IAppState} from 'Common/store/IAppState';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import Loading from 'Loading/components/Loading';
import {Cell, Table} from 'Common/components/Table/Table';
import Typography from 'Common/constants/Typography';
import {
  BusinessPortalCellContent,
  BusinessPortalHeaderCell,
  BusinessPortalSearch,
  BusinessPortalTableWrapper,
} from 'BusinessPortal/components/common/BusinessPortalTable';
import AvatarCellHorse from 'Admin/AdminDashboard/components/Orders/shared/AvatarCellHorse';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {Pagination} from 'Common/components/Controls';
import Theme from 'Common/constants/Theme';
import {useOwnerFormSlidePanel} from 'BusinessPortal/helpers/hooks/useOwnerFormSlidePanel';
import {searchBarStyle} from 'BusinessPortal/components/common/styled';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {BusinessPortalHorseModule} from 'BusinessPortal/store/horse';
import EmptyState, {
  EmptyStateType,
} from 'BusinessPortal/components/BusinessPortalDashboard/shared/EmptyState/EmptyState';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {breakpoints} from 'Common/constants/Breakpoints';

const CONFIRM_DELETE_OWNER = 'Are you sure you want to remove the owner?';

const ActionsContent = styled(BusinessPortalCellContent)`
  display: flex;
  justify-content: end;
  @media ${breakpoints.md} {
    justify-content: space-between;
  }
`;

const ActionIcon = styled(IconButton)`
  margin-left: 20px;
`;

type IConnected = ConnectedProps<typeof connector>;

const Owners = (props: IConnected) => {
  const {
    getOwners,
    owners,
    ownersLoading: {isRequesting},
    ownersLoading,
    pagination,
    resetOwners,
    ownerDeleting,
    deleteOwner,
  } = props;

  const [isNoData, setIsNoData] = useState<boolean>(false);

  const {
    selectedId,
    setSelectedId,
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    searchBar,
    params,
    searchQuery,
    openDeleteModal,
    deleteModal,
    isDeleteModalOpen,
  } = useCommonAdminPageData<IOwnerBP>({
    getItems: getOwners,
    searchBarPlaceholder: 'Search for owners by any keyword',
    searchBarStyle: searchBarStyle,
    deleteParams: {communication: ownerDeleting, confirmDescription: CONFIRM_DELETE_OWNER, action: deleteOwner},
  });

  const {isMobile, isTablet} = useMediaQuery();

  useEffect(() => {
    return () => resetOwners();
  }, [resetOwners]);

  const updateOwners = useCallback(() => {
    getOwners(params);
  }, [getOwners, params]);

  useOnSuccessCommunication(ownersLoading, () => {
    if (owners.length < 1 && !searchQuery) {
      setIsNoData(true);
      return;
    }
    setIsNoData(false);
  });

  const hasOwners = owners.length > 0 && !isRequesting;

  const {ownerFormSlidePanel, openOwnerFormSlidePanel} = useOwnerFormSlidePanel({
    onSuccessForm: updateOwners,
    shadow: true,
    transition: true,
  });

  const openOwnerFormSlidePanelHandler = useCallback(
    (ownerId?: number) => {
      openOwnerFormSlidePanel(ownerId);
      setSelectedId(ownerId || null);
    },
    [setSelectedId, openOwnerFormSlidePanel]
  );

  useEffect(() => {
    if (selectedId && !isDeleteModalOpen) {
      openOwnerFormSlidePanel(selectedId);
    }
  }, [isDeleteModalOpen, openOwnerFormSlidePanel, selectedId]);

  return (
    <>
      {ownerFormSlidePanel}
      {deleteModal}
      <BusinessPortalSearch>
        {searchBar}
        {hasOwners && (
          <PrimaryButton onClick={() => openOwnerFormSlidePanelHandler()} size="small">
            + Add an owner
          </PrimaryButton>
        )}
      </BusinessPortalSearch>
      <BusinessPortalTableWrapper>
        {isRequesting && <Loading />}
        <>
          <Table<IOwnerBP>
            data={owners}
            rowKey="id"
            sorting={sorting}
            onChangeSorting={changeSorting}
            expandable={false}
            disableRow={() => false}
            customHeaderCell={BusinessPortalHeaderCell}
            // not needed for expanded styles but also affect models row
            expandedRowStyles={{expandedRow: {height: 'auto'}}}
            tableStyles={{fontSize: Typography.size.size16, fontFamily: Theme.font.primary}}
            isMobile={isMobile || isTablet}
          >
            <Cell<IOwnerBP>
              header="Owner name"
              dataKey="name"
              sortable={false}
              render={({avatar, name, id}) => (
                <BusinessPortalCellContent>
                  <AvatarCellHorse
                    type="owner"
                    label={name}
                    avatarUrl={avatar?.url}
                    profileUrl={`/business-portal/user/association-owner-profile/${id}`}
                    isArchived={false}
                    isDeleted={false}
                    isGeneticDataHidden={false}
                    onLabelClick={() => openOwnerFormSlidePanelHandler(id)}
                  />
                </BusinessPortalCellContent>
              )}
              width="36%"
            />
            <Cell<IOwnerBP>
              header="Email"
              dataKey="email"
              sortable={false}
              render={({email}) => <BusinessPortalCellContent>{email}</BusinessPortalCellContent>}
              width="36%"
            />
            <Cell<IOwnerBP>
              header="Phone"
              dataKey="phone"
              sortable={false}
              render={({phone}) => <BusinessPortalCellContent>{phone}</BusinessPortalCellContent>}
              width="14%"
            />
            <Cell<IOwnerBP>
              header="Horses"
              dataKey="countHorses"
              align={CellAlign.Center}
              sortable={false}
              render={({countHorses}) => <BusinessPortalCellContent>{countHorses}</BusinessPortalCellContent>}
              width="6%"
            />
            <Cell<IOwnerBP>
              header="Actions"
              sortable={false}
              align={CellAlign.Right}
              width="3%"
              render={({id}) => (
                <ActionsContent>
                  <ActionIcon
                    name={IconName.Edit}
                    fill={true}
                    stroke={false}
                    size={16}
                    color={ColorPalette.gray44}
                    onClick={() => openOwnerFormSlidePanelHandler(id)}
                  />
                  <ActionIcon
                    name={IconName.Garbage}
                    onClick={() => openDeleteModal(id)}
                    fill={true}
                    stroke={false}
                    size={16}
                    color={ColorPalette.gray44}
                  />
                </ActionsContent>
              )}
            />
          </Table>

          {hasOwners && (
            <Pagination
              pagination={pagination}
              onPageSelect={handlePageSelect}
              onChangeCountPerPage={handleCountPerPage}
            />
          )}

          {isNoData && <EmptyState type={EmptyStateType.Owner} onClick={openOwnerFormSlidePanelHandler} />}
        </>
      </BusinessPortalTableWrapper>
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  pagination: selectors.selectPagination(state),
  owners: selectors.selectOwners(state),
  ownersLoading: selectors.selectCommunication(state, 'ownersLoading'),
  ownerDeleting: selectors.selectCommunication(state, 'ownerDeleting'),
});

const mapDispatchToProps = {
  getOwners: actions.getOwners,
  deleteOwner: actions.deleteOwner,
  resetOwners: actions.resetOwners,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withDynamicModules(connector(Owners), [BusinessPortalHorseModule, BusinessPortalOwnerModule]);
