import {Reducer} from 'redux';
import {
  AdminPermissionsActions,
  getAdminPermissionsActionTypes,
  getCurrentAdminPermissionsActionTypes,
  IAdminPermissionsState,
  resetAdminPermissionsActionTypes,
} from '../types';

const initialState: IAdminPermissionsState['data'] = {
  currentAdminPermissions: [],
  adminPermissions: [],
};

export const dataAdminPermissionsReducer: Reducer<IAdminPermissionsState['data'], AdminPermissionsActions> = (
  state = initialState,
  action: AdminPermissionsActions
) => {
  switch (action.type) {
    case getCurrentAdminPermissionsActionTypes.SUCCESS: {
      return {...state, currentAdminPermissions: action.payload};
    }
    case getAdminPermissionsActionTypes.SUCCESS: {
      return {...state, adminPermissions: action.payload};
    }
    case resetAdminPermissionsActionTypes.RESET: {
      return {...state, adminPermissions: initialState.adminPermissions};
    }
    default: {
      return state;
    }
  }
};
