import {Reducer} from 'redux';

import {Actions, getFoalsActionTypes, IBuildHorseState} from '../types';
import {
  addFavoriteHorseActionTypes,
  deleteFavoriteHorseActionTypes,
  FavoriteHorsesActions,
} from 'FavoriteHorse/store/types';
import {addExcludeHorseActionTypes, deleteExcludeHorseActionTypes, ExcludeHorsesActions} from 'ExcludeHorse/store';

const initialState: IBuildHorseState['data'] = {
  foals: [],
};

export const dataBuildHorseReducer: Reducer<IBuildHorseState['data'], Actions> = (
  state = initialState,
  action: Actions | FavoriteHorsesActions | ExcludeHorsesActions
) => {
  switch (action.type) {
    case getFoalsActionTypes.SUCCESS: {
      return {...state, foals: action.payload.data};
    }
    case addFavoriteHorseActionTypes.SUCCESS: {
      return {
        ...state,
        foals: state.foals.map((foal) => ({
          ...foal,
          parents: foal.parents.map((parent) => ({
            ...parent,
            isFavorite: action.payload === parent.id ? true : parent.isFavorite,
          })),
        })),
      };
    }
    case deleteFavoriteHorseActionTypes.SUCCESS: {
      return {
        ...state,
        foals: state.foals.map((foal) => ({
          ...foal,
          parents: foal.parents.map((parent) => ({
            ...parent,
            isFavorite: action.payload === parent.id ? false : parent.isFavorite,
          })),
        })),
      };
    }
    case addExcludeHorseActionTypes.SUCCESS: {
      return {
        ...state,
        foals: state.foals.map((foal) => ({
          ...foal,
          parents: foal.parents.map((parent) => ({
            ...parent,
            isExcluded: action.payload === parent.id ? true : parent.isExcluded,
          })),
        })),
      };
    }
    case deleteExcludeHorseActionTypes.SUCCESS: {
      return {
        ...state,
        foals: state.foals.map((foal) => ({
          ...foal,
          parents: foal.parents.map((parent) => ({
            ...parent,
            isExcluded: action.payload === parent.id ? false : parent.isExcluded,
          })),
        })),
      };
    }
    default: {
      return state;
    }
  }
};
