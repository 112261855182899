import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {ButtonWrapper, PlanTitle, SubscriptionContainer} from './common/styled';
import Plan from './common/Plan';
import SubscriptionInfoRow from './common/SubscriptionInfoRow';
import withDate from 'Common/helpers/withDate';
import {getDefaultPaymentMethodString} from './common/constants';
import {IScheduledSubscription} from 'StripeSubscription/models/ISubscription';
import {IDefaultPaymentMethod} from 'StripeSubscription/models/IDefaultPaymentMethod';
import {ISubscriptionProduct} from 'StripeSubscription/models/ISubscriptionProduct';
import {Nullable} from 'Common/types';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'StripeSubscription/store/index';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';

interface IExternalProps {
  subscription: IScheduledSubscription;
  product?: ISubscriptionProduct;
  defaultPaymentMethod: Nullable<IDefaultPaymentMethod>;
  onSuccess(): void;
  changeSubscriptionPlan(): void;
  changeDefaultPaymentMethod?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalProps;

const ScheduledSubscriptionItem = (props: AllProps) => {
  const {
    subscription,
    onSuccess,
    cancelScheduledSubscriptionRequesting,
    cancelScheduledSubscription,
    changeSubscriptionPlan,
    changeDefaultPaymentMethod,
    product,
    defaultPaymentMethod,
  } = props;

  const isLoading = cancelScheduledSubscriptionRequesting.isRequesting;

  useErrorCommunicationToToast(cancelScheduledSubscriptionRequesting);
  useOnSuccessCommunication(cancelScheduledSubscriptionRequesting, onSuccess);

  const onCancelSubscription = useCallback(() => {
    subscription?.id && cancelScheduledSubscription(subscription?.id);
  }, [cancelScheduledSubscription, subscription]);

  const changeDefaultPaymentMethodHandler = useCallback(() => {
    changeDefaultPaymentMethod && changeDefaultPaymentMethod();
  }, [changeDefaultPaymentMethod]);

  return (
    <>
      <SubscriptionContainer className="d-flex flex-column">
        <PlanTitle>Next selected plan</PlanTitle>
        <Plan onChangePlan={changeSubscriptionPlan} product={product} />
        <SubscriptionInfoRow
          title="First charge"
          value={(subscription?.startDate && withDate(subscription?.startDate)) || ''}
        />
        <SubscriptionInfoRow
          title="Payment method"
          value={defaultPaymentMethod ? getDefaultPaymentMethodString(defaultPaymentMethod) : ''}
          onChangePaymentMethod={changeDefaultPaymentMethodHandler}
        />
      </SubscriptionContainer>

      <ButtonWrapper variant="outlined" size="small" isLoading={isLoading} onClick={onCancelSubscription}>
        Cancel subscription
      </ButtonWrapper>
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  cancelScheduledSubscriptionRequesting: selectors.selectCommunication(state, 'cancelScheduledSubscriptionRequesting'),
});

const mapDispatchToProps = {
  cancelScheduledSubscription: actions.cancelScheduledSubscription,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ScheduledSubscriptionItem);
