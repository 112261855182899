import React, {memo, useCallback} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminHorseTransfer/index';
import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {Field, FieldLabel, FieldValue} from 'Admin/common/styled/StyledComponents';
import UserSearchField from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearchField';
import {initialValue, validationSchema} from './validation';
import {IChangeHorseOwner} from 'Admin/AdminDashboard/models/Horse/IChangeHorseOwner';
import {CheckboxField} from 'Common/components/FormFields/index';

const Root = styled.div`
  margin-right: 24px;
`;

const CheckboxWrapper = styled.div`
  margin-bottom: 15px;
`;

interface IExternalProps {
  horseId: number;
  horseName: string;
  currentOwnerName: string;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IChangeHorseOwner> & OuterProps;

function ChangeOwner(props: AllProps) {
  const {isSubmitting, setErrors} = props;
  const {horseOwnerChanging, onSuccess, horseName, currentOwnerName} = props;

  const errorInfo = horseOwnerChanging.error;
  const isRequesting = horseOwnerChanging.isRequesting;

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(horseOwnerChanging, onSuccess);
  useOnErrorCommunication(horseOwnerChanging, onError);
  useCommunicationToToast(horseOwnerChanging, 'Horse owner has been changed');

  return (
    <>
      <ModalWindowHeader>Change owner</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <Root>
          <ModalWindowFormContent scrollable={false}>
            {isRequesting && <Loading />}
            <div className="row">
              <Field className="col-4">
                <FieldLabel>Horse</FieldLabel>
                <FieldValue>{horseName}</FieldValue>
              </Field>
              <Field className="col-4">
                <FieldLabel>Current owner</FieldLabel>
                <FieldValue>{currentOwnerName}</FieldValue>
              </Field>
            </div>

            <UserSearchField
              name="newOwnerId"
              label="New owner"
              placeholder="New owner name or e-mail"
              isRequired={true}
            />

            <CheckboxWrapper>
              <CheckboxField name="clearPreclinicalNotes" label="Clear preclinical notes" />
            </CheckboxWrapper>
          </ModalWindowFormContent>
        </Root>
        <ModalWindowFooter>
          <ModalWindowButton type="submit" isLoading={isSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const ChangeEmailFormik = withFormik<OuterProps, IChangeHorseOwner>({
  mapPropsToValues: ({horseId}) => (horseId ? {horseId, newOwnerId: 0, clearPreclinicalNotes: true} : initialValue),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    await formikBag.props.changeHorseOwner({...values, horseId: formikBag.props.horseId});
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(ChangeOwner);

const mapStateToProps = (state: IAppState) => ({
  horseOwnerChanging: selectors.selectCommunication(state, 'horseOwnerChanging'),
});

const mapDispatchToProps = {
  changeHorseOwner: actions.changeHorseOwner,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(ChangeEmailFormik));
