import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {IShortDescriptionEntity} from 'Common/models/IDescriptionEntity';
import {ITraitSequence} from 'Admin/AdminPhenotypes/models/ITraitSequence';
import {Combination} from 'Common/constants/Combination';
import {Nullable} from 'Common/types';
import {IUpdateAbilityRuleRequest} from 'Admin/AdminPhenotypes/service/models/IServerAbilityRule';
import {AbilityState} from 'Common/constants/AbilityState';
import {AbilityType} from 'Common/constants/AbilityType';
import {IIndexed} from 'Common/models/IIndexed';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IExternalUrlEntity} from 'Common/models/IExternalUrlEntity';

export interface IFormValues extends IShortDescriptionEntity, IIndexed, IExternalUrlEntity {
  id?: IEntity['id'];
  name: string;
  type: Combination;
  sequences?: ITraitSequence[];
  groupId?: Nullable<number>;
  abilityType: AbilityType;
  abilityState: AbilityState;
  aggregatorId?: Nullable<number>;
  affectionTypeId: Nullable<number>;
}

export const initialValue: IFormValues = {
  name: '',
  type: Combination.And,
  description: {value: ''},
  sequences: [],
  groupId: null,
  abilityType: AbilityType.Speed,
  abilityState: AbilityState.Low,
  aggregatorId: null,
  affectionTypeId: null,
  positionIndex: 0,
  externalDescriptionUrl: ''
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  groupId: Yup.number()
    .nullable()
    .required(REQUIRED_FIELD),
  positionIndex: Yup.number().required(REQUIRED_FIELD)
});

export const convertToServer = (values: IFormValues): Omit<IUpdateAbilityRuleRequest, 'id'> => {
  const response = {
    ...values,
    description: {value: values.description?.value || ''},
    group: values.groupId ? {id: values.groupId} : null,
    affectionType: values.affectionTypeId ? {id: values.affectionTypeId} : null,
    aggregator: values.aggregatorId ? {id: values.aggregatorId} : null
  };

  delete response['groupId'];
  delete response['aggregatorId'];

  return response;
};

export const convertToServerUpdating = (values: IFormValues): IUpdateAbilityRuleRequest => {
  return {
    ...convertToServer(values),
    id: values.id!
  };
};
