import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IDescription} from 'Common/models/IDescriptionEntity';

export interface IFormValues {
  id?: IEntity['id'];
  name: string;
  description?: IDescription;
}

export const initialValue: IFormValues = {
  name: '',
  description: {value: ''} as IDescription,
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
});
