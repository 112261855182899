import {memo} from 'react';

import {OrderInfoHeaderProps} from 'Admin/AdminDashboard/components/Orders/OrderInfoModal/OrderInfoHeader/OrderInfoHeader/common/models';
import {GrayCell} from 'Admin/AdminDashboard/components/Orders/OrderInfoModal/OrderInfoHeader/OrderInfoHeader/common/styled';
import {OrderStatusDropdown} from 'Admin/shared/components/OrderStatus/index';
import {OrderStatus} from 'Common/constants/OrderStatus';

const HorseStatus = (props: OrderInfoHeaderProps) => {
  const {
    orderDetails: {id: orderId},
    horsesDetails,
    onSuccess,
    statuses,
  } = props;

  const orderHasSeveralHorses = horsesDetails.length > 1;

  if (orderHasSeveralHorses) {
    return <GrayCell>Several</GrayCell>;
  }

  if (!statuses) {
    return null;
  }

  return (
    <OrderStatusDropdown
      horseId={horsesDetails[0].id}
      status={statuses.status}
      orderId={orderId}
      onChange={onSuccess}
      completedStatuses={statuses?.hasSample ? OrderStatus.sampleReceived : undefined}
      uncompletedStatuses={statuses?.hasNotResults ? OrderStatus.resultsReady : undefined}
    />
  );
};

export default memo(HorseStatus);
