import {combineReducers, Reducer} from 'redux';

import {IAdminOnlineReportState} from '../types';
import {communicationReducer} from './communications';
import {dataReducer} from './data';

export const onlineReportReducer: Reducer<IAdminOnlineReportState> = combineReducers<IAdminOnlineReportState>({
  communications: communicationReducer,
  data: dataReducer
});
