import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

import {
  createHorseActionTypes,
  deleteHorseActionTypes,
  getHorseAbilitiesActionTypes,
  getHorseActionTypes,
  getHorseLastOrderActionTypes,
  getHorseOwnerActionTypes,
  getHorseParantageReportActionTypes,
  getOwnerHorsesAtionTypes,
  HorseActions,
  IHorseState,
  resetHorseAncestryProfileTypeActionTypes,
  resetHorseProfileTypeActionTypes,
  searchHorseActionTypes,
  updateHorseActionTypes,
  updateHorseAncestryProfileTypeActionTypes,
  updateHorseProfileTypeActionTypes,
} from '../types';

export const communicationHorseReducer = combineReducers<IHorseState['communications'], HorseActions>({
  horseLoading: makeCommunicationReducerFromEnum(getHorseActionTypes),
  horseCreating: makeCommunicationReducerFromEnum(createHorseActionTypes),
  horseUpdating: makeCommunicationReducerFromEnum(updateHorseActionTypes),
  horseDeleting: makeCommunicationReducerFromEnum(deleteHorseActionTypes),
  diagnosticsProfileTypeUpdating: composeReducers([
    makeCommunicationReducerFromEnum(updateHorseProfileTypeActionTypes),
    makeResetCommunicationReducer(resetHorseProfileTypeActionTypes.RESET),
  ]),
  horseAbilitiesLoading: makeCommunicationReducerFromEnum(getHorseAbilitiesActionTypes),
  horseParantageReportLoading: makeCommunicationReducerFromEnum(getHorseParantageReportActionTypes),
  horseLastOrderLoading: makeCommunicationReducerFromEnum(getHorseLastOrderActionTypes),
  foundHorseSearching: makeCommunicationReducerFromEnum(searchHorseActionTypes),
  horseOwnerLoading: makeCommunicationReducerFromEnum(getHorseOwnerActionTypes),
  ownerHorsesLoading: makeCommunicationReducerFromEnum(getOwnerHorsesAtionTypes),
  ancestryProfileTypeUpdating: composeReducers([
    makeCommunicationReducerFromEnum(updateHorseAncestryProfileTypeActionTypes),
    makeResetCommunicationReducer(resetHorseAncestryProfileTypeActionTypes.RESET),
  ]),
});
