import React, {memo} from 'react';

import PropPicker, {PropPickerProps} from 'Common/components/Controls/PropPicker/PropPicker';
import {IFieldProps} from 'Common/models/IFieldProps';

import withField from './withField';
import ValidationError from './ValidationError';

interface IOwnProps {
  setUrlParameters?: (paramName: string, paramValue: string) => void;
}

type Props = IFieldProps<number> & IOwnProps & Omit<PropPickerProps, 'onChange' | 'value'>;

function PropPickerField(props: Props) {
  const {
    setUrlParameters,
    form: {setFieldValue},
    field: {name, value},
    ...rest
  } = props;

  const handleChange = React.useCallback(
    (value: number) => {
      if (setUrlParameters) {
        setUrlParameters(name, value.toString());
      }
      return setFieldValue(name, value);
    },
    [setFieldValue, name, setUrlParameters]
  );

  return (
    <>
      <PropPicker {...rest} onChange={handleChange} value={value} name={name} />
      <ValidationError name={name} />
    </>
  );
}

export default memo(withField(PropPickerField));
