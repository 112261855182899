import React, {memo, useMemo} from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import Icon, {IconName} from 'Icon/components/Icon';
import {ModalWindowHeader} from 'Common/components/Modal/shared';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {IFoalParentVariantIssue, UnreportedParentsIssues} from 'FoalProfile/models/IFoalParentVariantIssue';
import {IProbabilityEntity} from 'Common/models/IProbabilityEntity';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {ProbabilityEntityType, ProbabilityEntityTypeSorting} from 'Common/constants/ProbabilityEntityType';
import {ParentProp} from './UnreportedVariants';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const UNREPORTED_VARIANTS = `Unreported Genetic Variants`;
const UNREPORTED_VARIANTS_DESCRIPTION = `Test results are not available because either, the horse has not been tested, or test results have not been provided.`;
const COMPLETED_TEST_LEGEND = 'Testing complete; results available';
const UNCOMPLETED_TEST_LEGEND = 'Incomplete/Unreported test results';
const HINT =
  'After providing test results probability can become higher as well lower. This number shows approximate chance of occurrence.';

const MODAL_ADDITIONAL_CONTENT_HEIGHT = 180;
const MODAL_MARGIN = 64;
const MODAL_CONTENT_MAX_HEIGHT = `calc(100vh - ${MODAL_ADDITIONAL_CONTENT_HEIGHT + MODAL_MARGIN}px`;

enum ParentPosition {
  first = 'first',
  second = 'second',
}

const groupByIssueId = R.groupBy((issue: IParentFoalHealthIssue) => String(issue.id));
const groupByIssueType = R.groupBy((issue: Issue) => String(issue.probabilityEntityType));

const parseParentsIssue = (parentsIssue: IParentFoalHealthIssue[]): Issue => {
  const first = R.head(parentsIssue);
  const second = R.last(parentsIssue);

  const isUnchecked = (position: ParentPosition) =>
    first?.position === position || second?.position === position || false;
  const isFirstParentUnChecked = isUnchecked(ParentPosition.first);
  const isSecondParentUnChecked = isUnchecked(ParentPosition.second);
  return {
    id: first?.id || 0,
    name: first?.name || '',
    description: first?.description || '',
    isDanger: first?.isDanger || false,
    isFirstParentUnChecked,
    isSecondParentUnChecked,
    probabilityEntityType: first?.probabilityEntityType || second?.probabilityEntityType,
    sortingPosition: ProbabilityEntityTypeSorting[first?.probabilityEntityType || second?.probabilityEntityType || 0],
  };
};

const sortIssues = R.sortWith<Issue>([
  R.ascend(R.prop('sortingPosition')),
  R.descend(R.prop('isDanger')),
  R.ascend(R.prop('name')),
]);

const convertHealthIssues = R.pipe<
  IParentFoalHealthIssue[],
  {[index: string]: IParentFoalHealthIssue[]},
  IParentFoalHealthIssue[][],
  Issue[],
  Issue[],
  {[type: string]: Issue[]}
>(groupByIssueId, Object.values, R.map(parseParentsIssue), sortIssues, groupByIssueType);

const HealthIssues = styled.div`
  padding: 8px;

  @media ${breakpoints.sm} {
    padding: 0 24px 16px;
  }
`;

const IssueName = styled.div<{isDanged?: boolean}>`
  font-size: ${Typography.size.size13};
  color: ${ColorPalette.black1};
`;

const HeadCell = styled.th<{width: string; align?: string; opacity?: string; isFirstColumn?: boolean}>`
  width: ${(p) => p.width};
  padding: ${(p) => (p.isFirstColumn ? '16px 0 16px 16px' : '16px 0')};
  font-size: ${(p) => (p.isFirstColumn ? Typography.size.size13 : Typography.size.size16)};
  font-family: ${Typography.family.openSans};
  font-weight: ${Typography.weight.bold700};
  line-height: 24px;
  color: ${ColorPalette.black6};
  text-transform: uppercase;
  text-align: ${(p) => p.align || 'left'};
  word-break: break-word;
  opacity: ${(p) => p.opacity || 1};
`;

const ColumnTitle = styled.div`
  line-height: 24px;
  max-height: 48px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 70px;
  max-width: 160px;

  @media ${breakpoints.sm} {
    width: 100%;
  }
`;

const Cell = styled.td<{align?: string}>`
  padding: 8px;
  text-align: ${(p) => p.align || 'left'};
  word-break: break-word;

  @media ${breakpoints.sm} {
    padding: 10px 16px;
  }
`;

const Row = styled.tr`
  :nth-child(odd) {
    background-color: ${ColorPalette.gray49};
  }
`;

const ModalFooter = styled.div`
  padding: 18px 16px 24px;
  border-top: 1px solid ${ColorPalette.gray10};
  flex-direction: column;
  @media ${breakpoints.sm} {
    flex-direction: row;
    padding: 18px 40px 24px;
  }
`;

const LegendField = styled.div`
  display: flex;
  align-items: center;
  font-family: ${Typography.family.roboto};
  font-size: ${Typography.size.size13};
  font-weight: ${Typography.weight.normal400};
  line-height: 13px;
  color: ${ColorPalette.gray28};
  :not(:first-child) {
    margin-top: 14px;
  }

  padding: 0 24px;

  @media ${breakpoints.sm} {
    flex-direction: row;
    padding: 0;
  }
`;

const Probability = styled.div<{isValue: boolean}>`
  font-family: ${Typography.family.ubuntu};
  font-size: ${Typography.size.size16};
  font-weight: ${Typography.weight.medium500};
  line-height: 18px;
  color: ${(props) => (props.isValue ? ColorPalette.black1 : ColorPalette.gray44)};
`;

const TooltipIcon = styled(ColoredIcon)`
  margin-left: 8px;
`;

const GroupCell = styled.td`
  padding-bottom: 24px;
`;

const IssuesTable = styled.table`
  margin-top: 8px;
`;

const GroupName = styled.span`
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  text-transform: uppercase;
  padding-left: 16px;
`;

const RequestButton = styled(LinkButton)`
  width: 100%;
  margin-top: 16px;

  @media ${breakpoints.sm} {
    width: 168px;
    margin-top: 0;
  }
`;

interface IParentFoalHealthIssue extends IFoalParentVariantIssue {
  position: ParentPosition;
}

interface Issue {
  id: number;
  name: string;
  description: string;
  isDanger: boolean;
  probabilityEntityType?: ProbabilityEntityType;
  isFirstParentUnChecked: boolean;
  isSecondParentUnChecked: boolean;
  sortingPosition: number;
}

interface IProps {
  firstParentProp: ParentProp;
  secondParentProp: ParentProp;
  unreportedIssues: UnreportedParentsIssues;
  unreportedProbabilities: IProbabilityEntity[];
}

function UnreportedVariantsModal(props: IProps) {
  const {
    firstParentProp,
    secondParentProp,
    unreportedIssues: {firstParent, secondParent},
    unreportedProbabilities,
  } = props;

  const {isMobile} = useMediaQuery();

  const issues = useMemo(() => {
    const joinedIssues: IParentFoalHealthIssue[] = firstParent
      .map((p) => ({...p, position: ParentPosition.first}))
      .concat(secondParent.map((p) => ({...p, position: ParentPosition.second})));
    return convertHealthIssues(joinedIssues);
  }, [firstParent, secondParent]);

  const isOwnHorses = firstParentProp.isOwnHorse || secondParentProp.isOwnHorse;
  const parentsForOrder = [
    firstParentProp.isOwnHorse && firstParentProp.id,
    secondParentProp.isOwnHorse && secondParentProp.id,
  ]
    .filter(Boolean)
    .join(',');

  return (
    <>
      <ModalWindowHeader>
        <Tooltip content={UNREPORTED_VARIANTS_DESCRIPTION}>
          <span>{UNREPORTED_VARIANTS}</span>
        </Tooltip>
      </ModalWindowHeader>
      <Scrollbar maxHeight={MODAL_CONTENT_MAX_HEIGHT}>
        <HealthIssues>
          <table className="w-100">
            <thead>
              <tr>
                <HeadCell width="40%" opacity="0.15" isFirstColumn={true}>
                  <ColumnTitle>{isMobile ? 'Test' : 'Incomplete/Unreported test results'}</ColumnTitle>
                </HeadCell>
                <HeadCell align="center" width="20%" opacity="0.3">
                  <Tooltip content={firstParentProp.name}>
                    <ColumnTitle style={{marginRight: 16}}>{firstParentProp.name}</ColumnTitle>
                  </Tooltip>
                </HeadCell>
                <HeadCell align="center" width="20%" opacity="0.3">
                  <Tooltip content={secondParentProp.name}>
                    <ColumnTitle>{secondParentProp.name}</ColumnTitle>
                  </Tooltip>
                </HeadCell>
                <HeadCell align="right" width="20%" opacity="0.3">
                  <ColumnTitle>Probability</ColumnTitle>
                </HeadCell>
              </tr>
            </thead>
            <tbody>
              {Object.keys(issues).map((group, i) => {
                return (
                  <React.Fragment key={i}>
                    <tr>
                      <GroupCell colSpan={4}>
                        <GroupName>{group}</GroupName>

                        <IssuesTable className="w-100">
                          {Object.values(issues[group]).map((issue, ind) => {
                            const {
                              name,
                              description,
                              isFirstParentUnChecked,
                              isSecondParentUnChecked,
                              isDanger,
                              id,
                              probabilityEntityType,
                            } = issue;
                            const healthIssue = unreportedProbabilities?.find(
                              (x) => x.id === id && probabilityEntityType === x.probabilityEntityType
                            );
                            const probabilityValue = `${healthIssue?.probability || 0}%`;

                            return (
                              <Row key={ind}>
                                <Cell width="40%">
                                  <Tooltip content={description}>
                                    <IssueName isDanged={isDanger}>{name}</IssueName>
                                  </Tooltip>
                                </Cell>
                                <Cell width="20%" align="center">
                                  <Icon
                                    name={
                                      isFirstParentUnChecked ? IconName.CrossedCheckbox : IconName.SquareFilledCheckbox
                                    }
                                  />
                                </Cell>
                                <Cell width="20%" align="center">
                                  <Icon
                                    name={
                                      isSecondParentUnChecked ? IconName.CrossedCheckbox : IconName.SquareFilledCheckbox
                                    }
                                  />
                                </Cell>
                                <Cell width="20%" align="right">
                                  <div className="d-flex justify-content-end align-items-center">
                                    <Probability isValue={!!healthIssue?.probability}>{probabilityValue}</Probability>
                                    {healthIssue?.probability && !isMobile ? (
                                      <Tooltip content={HINT} placement="right-start">
                                        <div>
                                          <TooltipIcon
                                            name={IconName.Question}
                                            size={16}
                                            stroke={false}
                                            fill={true}
                                            color={ColorPalette.gray56}
                                          />
                                        </div>
                                      </Tooltip>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </Cell>
                              </Row>
                            );
                          })}
                        </IssuesTable>
                      </GroupCell>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </HealthIssues>
      </Scrollbar>
      <ModalFooter className="d-flex justify-content-between">
        <div>
          <LegendField className="d-flex align-items-center">
            <Icon name={IconName.SquareFilledCheckbox} />
            <span>{`${Typography.symbols.whitespace}– ${COMPLETED_TEST_LEGEND}`}</span>
          </LegendField>
          <LegendField className="d-flex align-items-center">
            <Icon name={IconName.CrossedCheckbox} />
            <span>{`${Typography.symbols.whitespace}– ${UNCOMPLETED_TEST_LEGEND}`}</span>
          </LegendField>
        </div>
        {isOwnHorses && (
          <div className="d-flex align-items-center">
            <RequestButton to={`/order-tests/${parentsForOrder}`}>Request testing</RequestButton>
          </div>
        )}
      </ModalFooter>
    </>
  );
}

export default memo(UnreportedVariantsModal);
