import {IBaseServerDictionaryFields} from 'Common/models/IBaseServerDictionaryFields';
import {IServerDescriptionEntity} from 'Common/models/IDescriptionEntity';
import capitalizeFirstLetter from 'Common/helpers/capitalizeFirstLetter';
import {INamedEntity} from 'Common/models/INamedEntity';
import {IImage} from 'Image/models/IImage';
import {Nullable} from 'Common/types';
import {withImageBaseUrl} from 'Common/helpers/withImageBaseUrl';

export function removeServerDictionaryFields<T extends IBaseServerDictionaryFields>(serverModel: T) {
  const {importId, sequence, descriptionId, description, ...rest} = serverModel;
  return {...rest, description: description ? {id: description.id, value: description.value} : undefined, sequence};
}

export function convertDictionaryToClient<T extends IServerDescriptionEntity>(serverModel: T) {
  const {description, ...rest} = serverModel;
  return {
    ...rest,
    description: description ? {value: description.value} : undefined,
  };
}

export function capitalizeNameFirstLetter<T extends INamedEntity>(serverModel: T) {
  return {
    ...serverModel,
    name: capitalizeFirstLetter(serverModel.name.toLowerCase()),
  };
}

export function convertDictionaryToClientWithImage<T extends {image: Nullable<IImage>}>(serverModel: T) {
  const {image, ...rest} = serverModel;
  return {
    ...rest,
    image: image && withImageBaseUrl(image),
  };
}
