import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {Nullable} from 'Common/types';
import {IVisitor} from 'Common/models/IVisitor';

const STATE_NAME = 'auth';

export interface IAuthState {
  data: {
    visitorType: Nullable<IVisitor>;
  };
  communications: {
    authenticating: ICommunication;
    visitorTypeLoading: ICommunication;
  };
}

export const loginActionTypes = makeCommunicationActionType(STATE_NAME, 'LOGIN');
export type loginActions = MakeCommunicationActions<typeof loginActionTypes, {}>;

export const getVisitorTypeActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_VISITOR_TYPE');
export type getVisitorTypeActions = MakeCommunicationActions<
  typeof getVisitorTypeActionTypes,
  {success: IVisitor; failure: any}
>;

export const resetLoginActionTypes = makeResetCommunicationActionType(STATE_NAME, 'LOGIN');
export type resetLoginActions = MakeResetCommunicationActions<typeof resetLoginActionTypes>;

export const resetVisitorTypeActionTypes = makeResetCommunicationActionType(STATE_NAME, 'VISITOR_TYPE');
export type resetVisitorTypeActions = MakeResetCommunicationActions<typeof resetVisitorTypeActionTypes>;

export type AuthActions = loginActions | getVisitorTypeActions | resetLoginActions | resetVisitorTypeActions;
