import React, {memo} from 'react';
import {Form, withFormik} from 'formik';
import {FormikProps} from 'formik/dist/types';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';

import GalleryField from 'Common/components/FormFields/GalleryField';
import {IconName, IProps as IconProps} from 'Icon/components/Icon';
import {
  IFormValues,
  initialValue,
  validationSchema
} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseMediaSection/validation';
import NoData from 'BusinessPortal/components/common/NoData/NoData';
import {IHorseMediaSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseMediaSection/models/IHorseMediaSectionData';

const galleryVideoIconProps: IconProps = {name: IconName.InsertVideo, size: 48};
const galleryIconProps: IconProps = {name: IconName.InsertPhoto, size: 48};

type IExternalProps = IEditableSection<IHorseMediaSectionData>;

type AllProps = FormikProps<IFormValues> & IExternalProps;

const HorseHealthInfoSectionShow = (props: AllProps) => {
  const {data} = props;

  const isNoData = !data || data.horseMedia?.length === 0;

  if (isNoData) {
    return <NoData />;
  }

  return (
    <>
      <Form>
        <GalleryField
          isEditMode={false}
          name="gallery"
          imageProps={{
            title: 'Add gallery photo',
            iconProps: galleryIconProps,
            showDownloadButton: false,
            showDeleteButton: false
          }}
          videoProps={{title: 'Add a video', iconProps: galleryVideoIconProps, showDeleteButton: false}}
        />
      </Form>
    </>
  );
};

const HorseHealthInfoSectionShowFormik = withFormik<IExternalProps, IFormValues>({
  mapPropsToValues: ({data}) => (data ? {horseId: data.horseId, gallery: data.horseMedia || []} : initialValue),
  validationSchema: validationSchema,
  handleSubmit: console.log,
  enableReinitialize: true
})(HorseHealthInfoSectionShow);

export default memo(HorseHealthInfoSectionShowFormik);
