import styled from 'styled-components';
import {Link} from 'react-router-dom';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';

const LinkAsOutlinedButton = styled(Link)`
  width: 176px;
  height: 32px;

  text-decoration: none;
  color: ${ColorPalette.black1};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size16};
  border-radius: 3px;
  background-color: ${ColorPalette.transparent0};
  border: 1px solid ${ColorPalette.gray1};

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover:not(:disabled) {
    opacity: 0.8 !important;
    text-decoration: none;
    color: ${ColorPalette.black1};
  }

  &:disabled {
    opacity: 0.3;
    text-decoration: none;
  }
`;

export default LinkAsOutlinedButton;
