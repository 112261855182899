import React, {memo, useMemo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import IndicatorField from 'Common/components/FormFields/IndicatorField';
import {ExpansionPanel, Section} from 'Common/components/ExpansionPanel/ExpansionPanel';
import {CheckboxField} from 'Common/components/FormFields';
import {ISegment} from 'Common/models/ISegment';
import {getSectionHint, SectionHint} from './shared';
import {IAbilities} from 'Dictionaries/models/IAbilities';
import {AbilityState} from 'Common/constants/AbilityState';
import {AbilityType} from 'Common/constants/AbilityType';

const SPEED_HINT = `The Myostatin variant indicates a faster short-distance sprinter, a longer-distance endurance, or a mid-distance combination muscle type.`;
const TEMPERAMENT_HINT = `In discovery phase, this variant indicates a tendency toward Curiosity (more social, investigative behavior) or Vigilance (more "wary", hyperfocused, less social).`;
const GAIT_HINT = `Neurological variant causing slight to significant locomotor changes affecting lateral "gait" type movement, smoother trot/racking/pacing abilities lessening of canter/transition ability.`;

const AbilityCheckbox = styled.div`
  display: flex;
`;

function getSegments(abilities: IAbilities[], type: AbilityType): ISegment[] {
  const filteredAbilities = abilities.filter(x => x.type === type);

  return [
    {
      id: 1,
      value: 1,
      label: filteredAbilities.filter(x => x.state === AbilityState.Low)[0]?.name
    },
    {
      id: 2,
      value: 2,
      label: filteredAbilities.filter(x => x.state === AbilityState.Medium)[0]?.name
    },
    {
      id: 3,
      value: 3,
      label: filteredAbilities.filter(x => x.state === AbilityState.High)[0]?.name
    }
  ];
}

const PrioritiesIndicatorField = styled(IndicatorField)`
  margin-top: 8px;
  margin-bottom: 0;
`;

interface IProps {
  abilities: IAbilities[];
  isSpeedSelected?: boolean;
  isTemperamentSelected?: boolean;
  isGaitSelected?: boolean;
  selectedCount: number;
}

function Priorities(props: IProps) {
  const {isSpeedSelected, isTemperamentSelected, isGaitSelected, selectedCount, abilities} = props;

  const speedSegments = useMemo(() => {
    return getSegments(abilities, AbilityType.Speed);
  }, [abilities]);

  const temperamentSegments = useMemo(() => {
    return getSegments(abilities, AbilityType.Temperament);
  }, [abilities]);

  const gaitSegments = useMemo(() => {
    return getSegments(abilities, AbilityType.Gait);
  }, [abilities]);

  return (
    <ExpansionPanel title="Abilities" headerContent={<SectionHint count={selectedCount} />}>
      <Section>
        <AbilityCheckbox>
          <CheckboxField name="isSpeedSelected" label="Speed" />
          {getSectionHint(SPEED_HINT)}
        </AbilityCheckbox>
        <PrioritiesIndicatorField
          name="abilities.speed"
          showBottomLabels={true}
          segmentsCount={3}
          segmentColor={ColorPalette.green1}
          segments={speedSegments}
          labelSelectedColor={ColorPalette.black1}
          disabled={!isSpeedSelected}
        />
      </Section>
      <Section>
        <AbilityCheckbox>
          <CheckboxField name="isTemperamentSelected" label="Temperament" />
          {getSectionHint(TEMPERAMENT_HINT)}
        </AbilityCheckbox>
        <PrioritiesIndicatorField
          name="abilities.temperament"
          showBottomLabels={true}
          segmentsCount={3}
          segmentColor={ColorPalette.green1}
          segments={temperamentSegments}
          labelSelectedColor={ColorPalette.black1}
          disabled={!isTemperamentSelected}
        />
      </Section>
      <Section>
        <AbilityCheckbox>
          <CheckboxField name="isGaitSelected" label="DMRT3" />
          {getSectionHint(GAIT_HINT)}
        </AbilityCheckbox>
        <PrioritiesIndicatorField
          name="abilities.gait"
          showBottomLabels={true}
          segmentsCount={3}
          segmentColor={ColorPalette.green1}
          segments={gaitSegments}
          labelSelectedColor={ColorPalette.black1}
          disabled={!isGaitSelected}
        />
      </Section>
    </ExpansionPanel>
  );
}

export default memo(Priorities);
