import * as Yup from 'yup';

import {IPackageCartSettings} from 'Dictionaries/models/IPackage';

export interface IFormValues extends Omit<IPackageCartSettings, 'groups'> {
  id: number;
  groups: number[];
}

export const initialValue: IFormValues = {
  id: 0,
  groups: [],
  isBundle: false,
  positionIndex: 0,
};

export const validationSchema = Yup.object().shape<IFormValues>({
  id: Yup.number(),
  groups: Yup.array().of(Yup.number()),
  isBundle: Yup.boolean(),
  positionIndex: Yup.number(),
});
