import React, {useCallback} from 'react';
import classnames from 'classnames';
import ReactSelect, {GroupBase} from 'react-select';
import {FormatOptionLabelMeta} from 'react-select/dist/declarations/src/Select';
import {Props as ReactSelectProps} from 'react-select/dist/declarations/src/Select';
import Highlighter from 'react-highlight-words';

import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import {IOption} from 'Common/models/IOption';
import FieldControlContainer from '../../Layout/FieldControlContainer';
import {getStyles} from './SelectStyles';
import './Select.css';

interface IProps<Option extends IOption, IsMulti extends boolean, Group extends GroupBase<Option>>
  extends Omit<IBaseControlProps, 'placeholder'>,
    Pick<React.InputHTMLAttributes<HTMLInputElement>, 'style'>,
    Partial<ReactSelectProps<Option, IsMulti, Group>> {
  inputRef?: React.ForwardedRef<any>;
}

const Select = <
  Option extends IOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: IProps<Option, IsMulti, Group>
) => {
  const {className, tabIndex, styles, disabled, isDisabled, menuPlacement, inputClassName, inputRef, ...other} = props;

  const optionCustomRenderer = useCallback((option: Option, labelMeta: FormatOptionLabelMeta<Option>) => {
    return (
      <Highlighter
        searchWords={[labelMeta.inputValue]}
        textToHighlight={option.label}
        highlightStyle={{fontWeight: 500, background: 'none', margin: 0, padding: 0}}
      />
    );
  }, []);

  return (
    <FieldControlContainer {...props}>
      <ReactSelect<Option, IsMulti, Group>
        styles={getStyles(styles)}
        className={classnames('form-control', inputClassName)}
        isDisabled={disabled}
        formatOptionLabel={optionCustomRenderer}
        menuPlacement="auto"
        classNamePrefix="react-select"
        isClearable={false}
        ref={inputRef}
        {...other}
      />
    </FieldControlContainer>
  );
};

export default Select;
export type SelectProps<
  Option extends IOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = IProps<Option, IsMulti, Group>;
