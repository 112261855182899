import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IDefaultPaymentMethod} from 'StripeSubscription/models/IDefaultPaymentMethod';
import {
  IScheduledSubscription,
  ISubscription,
  ISubscriptionRequest,
  ISubscriptionResponse,
} from 'StripeSubscription/models/ISubscription';
import {
  convertScheduledSubscriptionToClient,
  convertSubscriptionToClient,
} from 'StripeSubscription/service/converters/subscription';
import {IRetryInvoiceResponse} from 'StripeSubscription/models/IRetryInvoiceResponse';

const getUserSubscriptions = async (): Promise<ISubscription[]> => {
  const response = await axiosWrapper.get<IListResponse<ISubscription>>(`/StripeSubscription`);
  return response.data.data.map(convertSubscriptionToClient);
};

const getUserScheduledSubscriptions = async (): Promise<IScheduledSubscription[]> => {
  const response = await axiosWrapper.get<IListResponse<IScheduledSubscription>>(`/StripeScheduledSubscription`);
  return response.data.data.map(convertScheduledSubscriptionToClient);
};

const getDefaultPaymentMethod = async (): Promise<IDefaultPaymentMethod> => {
  const response = await axiosWrapper.get<IObjectResponse<IDefaultPaymentMethod>>(
    `/StripeSubscription/Customers/DefaultPaymentMethod`
  );
  return response.data.data;
};

const createSubscription = async (data: ISubscriptionRequest): Promise<ISubscriptionResponse> => {
  const response = await axiosWrapper.post<IObjectResponse<ISubscriptionResponse>>(`/StripeSubscriptionCreate`, data);
  return response.data.data;
};

const cancelSubscription = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/StripeSubscriptionCancel/${id}`);
};

const deleteSubscription = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/StripeSubscriptionCancelImmediately/${id}`);
};

const cancelScheduledSubscription = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/StripeSubscriptionScheduledCancel/${id}`);
};

const changeSubscriptionPlan = async (productId: number): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/StripeSubscription/Subscriptions/ChangeProduct/${productId}`);
};

const renewSubscription = async (id: number): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/StripeSubscriptionRenew/${id}`);
};

const ensureCustomerCreated = async (): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/StripeSubscriptionCustomerCreate`);
};

const isCustomerExist = async (): Promise<boolean> => {
  const result = await axiosWrapper.get<IObjectResponse<boolean>>(`/StripeSubscriptionCustomerExist`);
  return result.data.data;
};

const changeDefaultPaymentMethod = async (paymentMethodId: string): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/StripeDefaultPaymentMethodSet`, {
    paymentMethodId,
  });
};

const retryInvoice = async (paymentMethodId: string, subscriptionId: number): Promise<IRetryInvoiceResponse> => {
  const result = await axiosWrapper.post<IObjectResponse<IRetryInvoiceResponse>>(`/StripeSubscriptionInvoiceRetry`, {
    paymentMethodId,
    subscriptionId,
  });
  return result.data.data;
};

const getSetupIntentClientSecret = async (): Promise<string> => {
  const result = await axiosWrapper.get<IObjectResponse<string>>(`/StripeSubscriptionClientSecret`);
  return result.data.data;
};

const refreshSubscriptionFromStripe = async (subscriptionId: number): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<void>>(`/StripeSubscriptionRefresh`, {subscriptionId});
};

export default {
  getSetupIntentClientSecret,
  createSubscription,
  getUserSubscriptions,
  ensureCustomerCreated,
  getDefaultPaymentMethod,
  changeDefaultPaymentMethod,
  retryInvoice,
  isCustomerExist,
  getUserScheduledSubscriptions,
  changeSubscriptionPlan,
  cancelSubscription,
  deleteSubscription,
  cancelScheduledSubscription,
  renewSubscription,
  refreshSubscriptionFromStripe,
};
