import React, {memo, useCallback} from 'react';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';

import {ReactComponent as EmptyStateHorses} from './img/empty-state-horses.svg';
import {ReactComponent as EmptyStateOwners} from './img/empty-state-owners.svg';
import {ReactComponent as EmptyStateOrders} from './img/empty-state-orders.svg';
import styled from 'styled-components';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 0 auto;
`;

export const Label = styled.div`
  width: 260px;
  margin-top: 8px;
  margin-bottom: 16px;

  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size14};
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.5px;

  color: ${ColorPalette.gray44};
`;

export enum EmptyStateType {
  Horse = 'horse',
  Owner = 'owner',
  Order = 'order',
}

const mapTypeToButtonText: Record<EmptyStateType, string> = {
  [EmptyStateType.Horse]: '+ Add a horse',
  [EmptyStateType.Owner]: '+ Add an owner',
  [EmptyStateType.Order]: '+ Add a horse',
};

const mapTypeToLabel: Record<EmptyStateType, string> = {
  [EmptyStateType.Horse]: 'You’ve just started and have no horses in your base.',
  [EmptyStateType.Owner]: 'You’ve just started and have no users in your base.',
  [EmptyStateType.Order]: 'No orders. Start from creating horse.',
};

const mapTypeToImage: Record<EmptyStateType, JSX.Element> = {
  [EmptyStateType.Horse]: <EmptyStateHorses />,
  [EmptyStateType.Owner]: <EmptyStateOwners />,
  [EmptyStateType.Order]: <EmptyStateOrders />,
};

interface IProps {
  onClick?(): void;
  type: EmptyStateType;
}

const EmptyState = (props: IProps) => {
  const {onClick, type} = props;

  const onClickHandler = useCallback(() => onClick && onClick(), [onClick]);

  return (
    <Container>
      {mapTypeToImage[type]}
      <Label>{mapTypeToLabel[type]}</Label>
      <PrimaryButton onClick={onClickHandler} size="small">
        {mapTypeToButtonText[type]}
      </PrimaryButton>
    </Container>
  );
};

export default memo(EmptyState);
