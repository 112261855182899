import {IBuildHorseRequestHealthVariant} from 'Filters/models/IBuildHorseRequestHealthVariant';
import {IBuildHorseRequestHealthVariantServer} from 'BuildHorse/services/serverModel/BuildHorseRequestHealthVariant';

export function convertHealthVariantToAvoidToClient(
  model: IBuildHorseRequestHealthVariantServer
): IBuildHorseRequestHealthVariant {
  return {
    ...model,
    id: `${model.id}_${model.isAggregated}`
  };
}

export function convertHealthVariantToAvoidToServer(
  model: IBuildHorseRequestHealthVariant
): IBuildHorseRequestHealthVariantServer {
  return {
    ...model,
    id: +model.id.split('_')?.[0] || 0
  };
}
