import React, {memo} from 'react';
import styled from 'styled-components';

import {IAssociationOwnerOrderAdmin} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderDetails';
import {AvatarCell, Details} from 'Admin/AdminDashboard/components/shared';

const sectionStyle: React.CSSProperties = {marginRight: 32, marginTop: 0};

const Root = styled.div`
  margin: 24px;
`;

interface IProps {
  owner: IAssociationOwnerOrderAdmin;
}

function OwnerDetails(props: IProps) {
  const {owner} = props;

  const ownerDetails = [
    {
      title: 'Name',
      value: owner.name,
    },
    {title: 'Phone', value: owner.phone},
    {title: 'Email', value: owner.email},
  ];

  return (
    <Root className="d-flex">
      <AvatarCell
        type="owner"
        avatarUrl={owner.avatar?.url}
        label=""
        profileUrl={`/admin-associations/user/user-profile/${owner.id}`}
      />
      <Details records={ownerDetails} rootClassName="d-flex" sectionStyle={sectionStyle} />
    </Root>
  );
}

export default memo(OwnerDetails);
