import React, {useEffect} from 'react';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps} from 'formik/dist/types';
import styled from 'styled-components';

import {actions, selectors} from 'BusinessPortal/store/horse';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';

import GalleryField from 'Common/components/FormFields/GalleryField';
import {IconName, IProps as IconProps} from 'Icon/components/Icon';
import {
  IFormValues,
  initialValue,
  validationSchema,
} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseMediaSection/validation';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IMediaResource, MediaType} from 'Common/models/IMediaResource';
import {deleteImagesFromGallery} from 'Gallery/helpers/deleteImagesFromGallery';
import {IHorseMediaSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseMediaSection/models/IHorseMediaSectionData';
import {retrieveImageError} from 'Common/helpers/retrieveImageError';

const galleryVideoIconProps: IconProps = {name: IconName.InsertVideo, size: 48};
const galleryIconProps: IconProps = {name: IconName.InsertPhoto, size: 48};

const SaveButton = styled(PrimaryButton)`
  margin-top: 16px;
`;

type IConnected = ConnectedProps<typeof connector>;

type IExternalProps = IEditableSection<IHorseMediaSectionData>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const HorseHealthInfoSectionEdit = (props: AllProps) => {
  const {isValid, resetForm, isSubmitting} = props;
  const {horseImageMediaCreating, horseVideoMediaCreating, horseMediaDeleting} = props;

  useEffect(() => {
    return () => resetForm();
  }, [resetForm]);

  const isRequesting = [horseImageMediaCreating, horseVideoMediaCreating, horseMediaDeleting].some(
    (i) => i.isRequesting
  );

  if (isRequesting) {
    return <Loading />;
  }

  return (
    <>
      <Form>
        <GalleryField
          isEditMode={true}
          name="gallery"
          imageProps={{
            title: 'Add gallery photo',
            iconProps: galleryIconProps,
            showDownloadButton: true,
            showDeleteButton: true,
          }}
          videoProps={{title: 'Add a video', iconProps: galleryVideoIconProps, showDeleteButton: true}}
        />
        <SaveButton className="w-100" size="medium" type="submit" disabled={!isValid} isLoading={isSubmitting}>
          Save changes
        </SaveButton>
      </Form>
    </>
  );
};

const HorseHealthInfoSectionEditFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({data}) => (data ? {horseId: data.horseId, gallery: data.horseMedia || []} : initialValue),
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {createHorseImageMedia, createHorseVideoMedia, deleteHorseMedia, onSuccess} = formikBag.props;
    const uploadMedia = async (horseId: number, media: IMediaResource) => {
      if (media.type === MediaType.Image) {
        if (media.isLocal && media.file) {
          await createHorseImageMedia(horseId, [media]);
        }
      }
      if (media.type === MediaType.Video) {
        await createHorseVideoMedia(horseId, media);
      }
    };

    const initialGallery = formikBag.props.data?.horseMedia;

    // Upload media item
    try {
      for (const media of values.gallery || []) {
        if (!initialGallery?.some((x) => x.id === media.id)) {
          await uploadMedia(values.horseId, media);
        }
      }

      // Delete media item
      if (initialGallery && initialGallery.length > 0) {
        await deleteImagesFromGallery(values.horseId, initialGallery, values.gallery || [], deleteHorseMedia);
      }
    } catch (e) {
      formikBag.setFieldError('gallery', retrieveImageError(e));
      return;
    }

    onSuccess && onSuccess();
  },
  enableReinitialize: true,
})(HorseHealthInfoSectionEdit);

const mapStateToProps = (state: IAppState) => ({
  horseImageMediaCreating: selectors.selectCommunication(state, 'horseImageMediaCreating'),
  horseVideoMediaCreating: selectors.selectCommunication(state, 'horseVideoMediaCreating'),
  horseMediaDeleting: selectors.selectCommunication(state, 'horseMediaDeleting'),
});

const mapDispatchToProps = {
  createHorseImageMedia: actions.createHorseImageMedia,
  createHorseVideoMedia: actions.createHorseVideoMedia,
  deleteHorseMedia: actions.deleteHorseMedia,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(HorseHealthInfoSectionEditFormik);
