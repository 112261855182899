import qs from 'qs';

function decoder(urlParams: string, decoder: any, charset: string) {
  const strWithoutPlus = urlParams.replace(/\+/g, ' ');
  if (charset === 'iso-8859-1') {
    return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
  }

  if (/^(\d+|\d*\.\d+)$/.test(urlParams)) {
    return parseFloat(urlParams);
  }

  const keywords = {
    true: true,
    false: false,
    null: null,
    undefined
  };
  if (urlParams in keywords) {
    return keywords[urlParams];
  }

  // utf-8
  try {
    return decodeURIComponent(strWithoutPlus);
  } catch (e) {
    return strWithoutPlus;
  }
}

export function parseUrlParams<T extends object>(params: string, isUseDecoder: boolean = true): T {
  return qs.parse(params.slice(1), {
    decoder: isUseDecoder ? decoder : undefined
  }) as T;
}

interface IStringifyOptions {
  clearEmpty?: boolean;
}

export function stringifyUrlParams<T extends object>(params: T, options: IStringifyOptions = {}) {
  const {clearEmpty} = options;

  if (!clearEmpty) {
    return qs.stringify(params);
  }

  const paramsWithValue = Object.keys(params).reduce((acc, currKey) => {
    if (params[currKey] !== undefined && params[currKey] !== '') {
      acc[currKey] = params[currKey];
    }
    return acc;
  }, {});

  return qs.stringify(paramsWithValue);
}
