import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IErrorDescription} from '../models/IErrorDescription';
import {IRequestParams} from 'Common/models/IRequestParams';

const getErrorDescriptions = async (params?: IRequestParams): Promise<IListResponse<IErrorDescription>> => {
  const response = await axiosWrapper.get<IListResponse<IErrorDescription>>(`/ErrorDescriptionTable`, {params});
  return {
    ...response.data,
    data: response.data.data,
  };
};

const getErrorDescription = async (id: number): Promise<IErrorDescription> => {
  const response = await axiosWrapper.get<IObjectResponse<IErrorDescription>>(`/ErrorDescriptionTableDetails/${id}`);
  return response.data.data;
};

const createErrorDescription = async (data: IErrorDescription): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>('/ErrorDescriptionTableCreate', {items: [data]});
};

const updateErrorDescription = async (data: IErrorDescription): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(`/ErrorDescriptionTableUpdate`, {items: [data]});
};

const deleteErrorDescription = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/ErrorDescriptionTableDelete`, {data: [id]});
};

export default {
  getErrorDescriptions,
  getErrorDescription,
  createErrorDescription,
  updateErrorDescription,
  deleteErrorDescription,
};
