import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {IShortAbility} from 'Dictionaries/models/IAbilities';
import {IHorseReport, IUserHorseOrder} from 'Horse/models/IUserHorse';
import {Nullable} from 'Common/types';
import {IGenotypeGroup} from 'Genotype/models/IGenotypeGroup';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import {IHorseOwner} from 'Horse/models/IHorseOwner';
import {IOwnerHorse} from 'Shared/models/IOwnerHorse';

const STATE_NAME = 'adminAssociationHorseProfile';

export interface IAdminAssociationHorseProfileState {
  data: {
    horseParantageReport: Nullable<IHorseReport>;
    horseAbilities: IShortAbility[];
    horseGenotypeGroups: IGenotypeGroup[];
    horseBreedPanel: IHorseBreedPanel[];
    horseLastOrder: Nullable<IUserHorseOrder>;
    horseOwner: Nullable<IHorseOwner>;
    ownerHorses: IOwnerHorse[];
  };
  communications: {
    horseParantageReportLoading: ICommunication;
    horseAbilitiesLoading: ICommunication;
    horseGenotypeGroupsLoading: ICommunication;
    horseBreedPanelLoading: ICommunication;
    horseLastOrderLoading: ICommunication;
    horseOwnerLoading: ICommunication;
    ownerHorsesLoading: ICommunication;
  };
}

export const getHorseParantageReportActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_REPORTS');
export type getHorseParantageReportActions = MakeCommunicationActions<
  typeof getHorseParantageReportActionTypes,
  {success: IHorseReport}
>;

export const getHorseAbilitiesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_ABILITIES');
export type getHorseAbilitiesActions = MakeCommunicationActions<
  typeof getHorseAbilitiesActionTypes,
  {success: IShortAbility[]}
>;

export const getHorseGenotypeGroupsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_GENOTYPE_GROUPS');
export type getHorseGenotypeGroupsActions = MakeCommunicationActions<
  typeof getHorseGenotypeGroupsActionTypes,
  {success: IGenotypeGroup[]}
>;

export const getHorseBreedPanelActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_BREED_PANEL');
export type getHorseBreedPanelActions = MakeCommunicationActions<
  typeof getHorseBreedPanelActionTypes,
  {success: IHorseBreedPanel[]}
>;

export const getHorseLastOrderActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_LAST_ORDER');
export type getHorseLastOrderActions = MakeCommunicationActions<
  typeof getHorseLastOrderActionTypes,
  {success: IUserHorseOrder}
>;

export const resetHorseParantageReportActionTypes = makeResetCommunicationActionType(STATE_NAME, 'GET_HORSE_REPORTS');
export type resetHorseParantageReportActions = MakeResetCommunicationActions<
  typeof resetHorseParantageReportActionTypes
>;

export const resetHorseAbilitiesActionTypes = makeResetCommunicationActionType(STATE_NAME, 'GET_HORSE_ABILITIES');
export type resetHorseAbilitiesActions = MakeResetCommunicationActions<typeof resetHorseAbilitiesActionTypes>;

export const resetHorseGenotypeGroupsActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'GET_HORSE_GENOTYPE_GROUPS'
);
export type resetHorseGenotypeGroupsActions = MakeResetCommunicationActions<typeof resetHorseGenotypeGroupsActionTypes>;

export const resetHorseBreedPanelActionTypes = makeResetCommunicationActionType(STATE_NAME, 'GET_HORSE_BREED_PANEL');
export type resetHorseBreedPanelActions = MakeResetCommunicationActions<typeof resetHorseBreedPanelActionTypes>;

export const resetHorseLastOrderActionTypes = makeResetCommunicationActionType(STATE_NAME, 'GET_HORSE_LAST_ORDER');
export type resetHorseLastOrderActions = MakeResetCommunicationActions<typeof resetHorseLastOrderActionTypes>;

export const getHorseOwnerActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_OWNER');
export type getHorseOwnerActions = MakeCommunicationActions<typeof getHorseOwnerActionTypes, {success: IHorseOwner}>;

export const getOwnerHorsesAtionTypes = makeCommunicationActionType(STATE_NAME, 'GET_OWNER_HORSES');
export type getOwnerHorsesActions = MakeCommunicationActions<typeof getOwnerHorsesAtionTypes, {success: IOwnerHorse[]}>;

export type AdminAssociationHorseProfileActions =
  | getHorseParantageReportActions
  | getHorseAbilitiesActions
  | getHorseGenotypeGroupsActions
  | getHorseBreedPanelActions
  | getHorseLastOrderActions
  | resetHorseParantageReportActions
  | resetHorseAbilitiesActions
  | resetHorseGenotypeGroupsActions
  | resetHorseBreedPanelActions
  | resetHorseLastOrderActions
  | getHorseOwnerActions
  | getOwnerHorsesActions;
