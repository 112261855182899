import * as React from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import ContainerWrapper from 'Common/components/Layout/ContainerWrapper';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';

const MainText = styled.span`
  font-size: ${Typography.size.size35};
  font-weight: ${Typography.weight.medium500};
  color: ${ColorPalette.gray1};
`;

const Text = styled.span`
  font-size: ${Typography.size.size24};
  color: ${ColorPalette.gray1};
`;

const AdminWelcomePage = () => {
  return (
    <BaseLayout isBackButtonDenied={true}>
      <ContainerWrapper className="d-flex flex-row justify-content-center align-items-center">
        <div className="d-flex flex-column">
          <MainText>Welcome to Etalon Admin Page!</MainText>
          <Text>Use the menu at the top right corner.</Text>
        </div>
      </ContainerWrapper>
    </BaseLayout>
  );
};

export default AdminWelcomePage;
