import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';

import {InputField, SelectField, TextAreaField} from 'Common/components/FormFields/index';
import {IUpdateGeneRequest} from 'Admin/AdminPhenotypes/service/models/IGene';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {IAdminGene} from 'Admin/AdminPhenotypes/models/IAdminGene';
import {RequiredBy} from 'Common/types';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {
  convertToServer,
  convertToServerUpdating,
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/Genes/GeneForm/validation';
import {castToOption} from 'Common/helpers/OptionHelper';
import {IGeneGroup} from 'Dictionaries/models/IGeneGroup';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

interface IOwnProps extends IPhenotypeFormExternalProps<IAdminGene, IUpdateGeneRequest> {
  geneGroupDictionary: ISimpleDictionary<IGeneGroup>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function GeneForm(props: Props) {
  const {itemId, geneGroup, geneGroupLoading, dictionary, geneGroupDictionary, ...rest} = props;
  const {getItem} = dictionary.actions;
  const {
    actions: {getItems: getGeneGroups},
  } = geneGroupDictionary;

  useEffect(() => {
    getGeneGroups();
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem, getGeneGroups]);

  return (
    <DictionaryForm {...rest} itemPreloading={geneGroupLoading.isRequesting}>
      <InputField name="name" label="Name" placeholder="Name" />
      <SelectField isMulti={true} name="groups" label="Groups" options={castToOption(geneGroup)} />
      <InputField name="title" label="Title" placeholder="Title" />
      <TextAreaField name="description.value" label="Description" placeholder="Description" />
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  const {geneGroupDictionary} = ownProps;

  return {
    ...makePhenotypeFormSelectors(state, ownProps.dictionary),
    geneGroup: geneGroupDictionary.selectors.selectItems(state),
    geneGroupLoading: geneGroupDictionary.selectors.selectCommunication(state, 'itemsLoading'),
  };
};

const GeneFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemLoading, itemId}) =>
    itemId && item && !itemLoading.isRequesting ? {...item, groups: item.groups.map((x) => x.id)} : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(GeneForm);

const connector = connect(mapStateToProps);
export default connector(memo(GeneFormFormik));
