import {Reducer} from 'redux';

import {
  addExcludeHorseActionTypes,
  deleteExcludeHorseActionTypes,
  ExcludeHorsesActions,
  getExcludeHorsesActionTypes,
  getSimpleExcludeHorsesActionTypes,
  IExcludeHorseState,
  resetSimpleExcludeHorsesActionTypes,
} from '../types';

export const initialStateList: IExcludeHorseState['data'] = {
  excludeHorses: [],
  simpleExcludeHorses: [],
};

export const dataReducer: Reducer<IExcludeHorseState['data'], ExcludeHorsesActions> = (
  state = initialStateList,
  action: ExcludeHorsesActions
) => {
  switch (action.type) {
    case getExcludeHorsesActionTypes.SUCCESS: {
      return {...state, excludeHorses: action.payload.data};
    }
    case addExcludeHorseActionTypes.SUCCESS:
      return {
        ...state,
        excludeHorses: state.excludeHorses.map((horse) => ({
          ...horse,
          isExcluded: horse.id === action.payload ? true : horse.isExcluded,
        })),
      };
    case deleteExcludeHorseActionTypes.SUCCESS:
      return {
        ...state,
        excludeHorses: state.excludeHorses.map((horse) => ({
          ...horse,
          isExcluded: horse.id === action.payload ? false : horse.isExcluded,
        })),
      };
    case getSimpleExcludeHorsesActionTypes.SUCCESS:
      return {...state, simpleExcludeHorses: action.payload.data};
    case resetSimpleExcludeHorsesActionTypes.RESET:
      return {...state, simpleExcludeHorses: initialStateList.simpleExcludeHorses};
    default: {
      return state;
    }
  }
};
