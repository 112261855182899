import React from 'react';
import {Redirect, Switch} from 'react-router-dom';

import AppRoute from 'Common/components/AppRoute/AppRoute';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';

import {ITabPagesData} from './ITab';

interface IProps {
  isSort?: boolean;
}

type Props<T> = ITabPagesData<T> & T & IProps;

function TabsContent<T>(props: Props<T>) {
  const {tabs, basePath, isSort, ...rest} = props;

  const sortedTabs = isSort ? tabs.sort((a, b) => sortByStringKey(a, b, 'title')) : tabs;
  const redirectSection = sortedTabs.find((item) => item.defaultSection)?.section ?? sortedTabs[0].section;

  return (
    <Switch>
      {sortedTabs.map(({section, Component}, i) => {
        const wrapped = (props: any) => <Component {...props} />;
        return (
          <AppRoute key={i} path={`${basePath}/${section}`} exact={true}>
            {wrapped(rest)}
          </AppRoute>
        );
      })}
      <Redirect to={`${basePath}/${redirectSection}`} />
    </Switch>
  );
}

export default TabsContent;
