import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IUserHorse} from 'Horse/models/IUserHorse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IServerUserHorse} from 'Horse/services/types/horse';
import {convertServerUserHorse} from 'Horse/services/converters/horse';
import {VisitorType} from 'Common/constants/VisitorType';
import {ApiByType, getApiByVisitorType} from 'Common/helpers/getApiByVisitorType';

const getFavoriteHorses = async (paginationParams: IRequestParams): Promise<IListResponse<IUserHorse>> => {
  const apis: ApiByType = {
    [VisitorType.User]: `/FavoriteHorses`,
    [VisitorType.AssociationEmployee]: `/AssociationFavoriteHorses`,
  };

  const api = getApiByVisitorType(apis);

  const response = await axiosWrapper.get<IListResponse<IServerUserHorse>>(api, {
    params: {...paginationParams},
  });
  return {...response.data, data: response.data.data.map(convertServerUserHorse)};
};

const addFavoriteHorse = async (horseId: number): Promise<void> => {
  const apis: ApiByType = {
    [VisitorType.User]: `/FavoriteHorsesAdd`,
    [VisitorType.AssociationEmployee]: `/AssociationFavoriteHorsesAdd`,
  };

  const api = getApiByVisitorType(apis);

  await axiosWrapper.post<IObjectResponse<null>>(`${api}/${horseId}`);
};

const deleteFavoriteHorse = async (horseId: number): Promise<void> => {
  const apis: ApiByType = {
    [VisitorType.User]: `/FavoriteHorsesRemove`,
    [VisitorType.AssociationEmployee]: `/AssociationFavoriteHorsesDelete`,
  };

  const api = getApiByVisitorType(apis);

  await axiosWrapper.delete<IObjectResponse<null>>(`${api}/${horseId}`);
};

export default {
  getFavoriteHorses,
  addFavoriteHorse,
  deleteFavoriteHorse,
};
