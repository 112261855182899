import {IAppState} from 'Common/store/IAppState';
import {ICommunication, composeCommunication} from 'Common/store/utils/communication';
import {composeActions} from 'Common/store/utils/actions';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {
  actions as preferencesActions,
  selectors as preferencesSelectors,
} from 'OnlineReportPrintable/store/userPrintableReportPreferences';
import {actions as userActions, selectors as userSelectors} from 'OnlineReport/store/index';
import {actions as adminActions, selectors as adminSelectors} from 'Admin/AdminDashboard/store/adminOnlineReport/index';
import {Nullable} from 'Common/types';
import {OnlineReportType} from '../shared/OnlineReportType';
import {IOnlineReportCoatColor} from 'OnlineReport/models/CoatColor/IOnlineReportCoatColor';
import {IOnlineReportHealthVariants} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariants';
import {IOnlineReportAbility} from 'OnlineReport/models/PerformanceAndAbilities/IOnlineReportAbility';
import {IUserPrintableReportPreferences} from 'OnlineReportPrintable/models/IUserPrintableReportPreferences';

export interface IState {
  coatColors: Nullable<IOnlineReportCoatColor[]>;
  healthVariants: Nullable<IOnlineReportHealthVariants[]>;
  abilities: Nullable<IOnlineReportAbility[]>;
  printableReportPreferences: Nullable<IUserPrintableReportPreferences>;
  loading: ICommunication;
  coatColorLoading: ICommunication;
  healthVariantsLoading: ICommunication;
  abilitiesLoading: ICommunication;
}

export interface IDispatch {
  getAllTraits(horseId: number, orderId?: number): void;
  getPrintableReportPreferences(): void;
}

const userGetAllTraits = composeActions([
  userActions.getGeneticVariants,
  userActions.getCoatColor,
  userActions.getHealthVariants,
  userActions.getAbilities,
]);

const userDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    coatColors: userSelectors.selectCoatColors(state),
    healthVariants: userSelectors.selectHealthVariants(state),
    abilities: userSelectors.selectAbilities(state),
    printableReportPreferences: preferencesSelectors.selectUserPrintableReportPreferences(state),
    // TODO: find a way to satisfy type checker for communication keys
    loading: composeCommunication<IAppState, any>([
      [userSelectors, 'coatColorLoading'],
      [userSelectors, 'healthVariantsLoading'],
      [userSelectors, 'abilitiesLoading'],
      [preferencesSelectors, 'userPrintableReportPreferencesLoading'],
    ])(state),
    // TODO: these communications related to TODO above (this is temp workaround)
    coatColorLoading: userSelectors.selectCommunication(state, 'coatColorLoading'),
    healthVariantsLoading: userSelectors.selectCommunication(state, 'healthVariantsLoading'),
    abilitiesLoading: userSelectors.selectCommunication(state, 'abilitiesLoading'),
  }),
  dispatch: {
    getAllTraits: userGetAllTraits,
    getPrintableReportPreferences: preferencesActions.getUserPrintableReportPreferences,
  },
};

const adminGetAllTraits = composeActions([
  adminActions.getGeneticVariants,
  adminActions.getCoatColor,
  adminActions.getHealthVariants,
  adminActions.getAbilities,
]);

const adminDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    coatColors: adminSelectors.selectCoatColors(state),
    healthVariants: adminSelectors.selectHealthVariants(state),
    abilities: adminSelectors.selectAbilities(state),
    printableReportPreferences: preferencesSelectors.selectUserPrintableReportPreferences(state),
    // TODO: find a way to satisfy type checker for communication keys
    loading: composeCommunication<IAppState, any>([
      [adminSelectors, 'coatColorLoading'],
      [adminSelectors, 'healthVariantsLoading'],
      [adminSelectors, 'abilitiesLoading'],
      [preferencesSelectors, 'userPrintableReportPreferencesLoading'],
    ])(state),
    // TODO: these communications related to TODO above (this is temp workaround)
    coatColorLoading: adminSelectors.selectCommunication(state, 'coatColorLoading'),
    healthVariantsLoading: adminSelectors.selectCommunication(state, 'healthVariantsLoading'),
    abilitiesLoading: adminSelectors.selectCommunication(state, 'abilitiesLoading'),
  }),
  dispatch: {
    getAllTraits: adminGetAllTraits,
    getPrintableReportPreferences: preferencesActions.getUserPrintableReportPreferences,
  },
};

export const printSelectionFormDistributor: Record<OnlineReportType, IDistributor<IState, IDispatch>> = {
  [OnlineReportType.User]: userDistributor,
  [OnlineReportType.Association]: userDistributor,
  [OnlineReportType.Admin]: adminDistributor,
  [OnlineReportType.AdminAssociation]: adminDistributor,
  [OnlineReportType.ReviewAdmin]: adminDistributor,
  [OnlineReportType.ReviewAdminAssociation]: adminDistributor,
};
