import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {Permission} from 'Permissions/constants/Permission';

const STATE_NAME = 'permissions';

export interface IPermissionsState {
  data: {
    currentPermissions: Permission[];
  };
  communications: {
    currentPermissionsLoading: ICommunication;
  };
}

export const getCurrentPermissionsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_CURRENT_PERMISSIONS');
export type getCurrentPermissionsActions = MakeCommunicationActions<
  typeof getCurrentPermissionsActionTypes,
  {success: Permission[]}
>;

export const resetCurrentPermissionsActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'CLEAR_CURRENT_PERMISSIONS'
);
export type resetCurrentPermissionsActions = MakeResetCommunicationActions<typeof resetCurrentPermissionsActionTypes>;

export type PermissionsActions = getCurrentPermissionsActions | resetCurrentPermissionsActions;
