import React, {CSSProperties} from 'react';
import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import Avatar, {AvatarSize} from 'Common/components/Avatar/Avatar';
import {Parentage} from 'HorseProfile/constants/Parentage';
import {IHorsesParentage} from 'Horse/models/IHorsesParentage';
import {SidebarComponent, SubTitle, TextInfo, SidebarLabel} from 'HorseProfile/components/shared/StyledComponents';

const AvatarLabel = styled.div`
  font-size: ${Typography.size.size16};
  font-weight: ${Typography.weight.medium500};
  color: ${ColorPalette.black1};
`;

const RegistryName = styled(TextInfo)`
  :not(:first-child) {
    margin-top: 8px;
  }
`;

const AvatarStyle: CSSProperties = {marginRight: '30px', marginBottom: '18px', backgroundColor: ColorPalette.gray1};

interface IProps {
  parents: IHorsesParentage[];
  className?: string;
}

const Parents = (props: IProps) => {
  const sire = props.parents.find((x) => x.parent === Parentage.Sire);
  const dam = props.parents.find((x) => x.parent === Parentage.Dam);

  const renderParent = (parentage: Parentage, parent?: IHorsesParentage) => {
    return (
      <div className="d-flex">
        <Avatar avatarSize={AvatarSize.Small} style={AvatarStyle}>
          <AvatarLabel>{parentage}</AvatarLabel>
        </Avatar>

        {parent ? (
          <div className="d-flex flex-column justify-content-center" style={{marginBottom: '18px'}}>
            <SubTitle style={{marginBottom: 0}}>{parent.name}</SubTitle>
            {parent.registries
              ?.slice(0, 3)
              .map((registry) => registry.association?.name)
              .filter(Boolean)
              .map((registryName, i) => (
                <RegistryName key={i}>{registryName}</RegistryName>
              ))}
          </div>
        ) : (
          <h6 className="d-flex flex-column justify-content-center" style={{marginBottom: '18px'}}>
            {`${parentage} not found`}
          </h6>
        )}
      </div>
    );
  };

  return (
    <SidebarComponent className={props.className}>
      <SidebarLabel>Parents</SidebarLabel>
      <div className="d-flex flex-column">
        {renderParent(Parentage.Sire, sire)}
        {renderParent(Parentage.Dam, dam)}
      </div>
    </SidebarComponent>
  );
};

export default Parents;
