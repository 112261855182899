import {Reducer} from 'redux';

import {
  changeCouponStatusActionTypes,
  CouponsActions,
  getCouponByIdActionTypes,
  getCouponsActionTypes,
  IAdminCouponState,
  resetCouponDetailsActionTypes,
} from 'Admin/AdminDashboard/store/adminCoupons/types';

const initialState: IAdminCouponState['data'] = {
  coupons: [],
  couponDetail: null,
};

export const dataCouponsReducer: Reducer<IAdminCouponState['data'], CouponsActions> = (
  state = initialState,
  action: CouponsActions
) => {
  switch (action.type) {
    case getCouponsActionTypes.SUCCESS: {
      return {...state, coupons: action.payload.data};
    }
    case getCouponByIdActionTypes.REQUEST: {
      return {...state, couponDetail: null};
    }
    case getCouponByIdActionTypes.SUCCESS: {
      return {...state, couponDetail: action.payload};
    }
    case changeCouponStatusActionTypes.SUCCESS: {
      const {couponId} = action.payload;
      return {...state, coupons: state.coupons.map((c) => (c.id !== couponId ? c : {...c, isEnabled: !c.isEnabled}))};
    }
    case resetCouponDetailsActionTypes.RESET: {
      return {...state, couponDetail: initialState.couponDetail};
    }
    default: {
      return state;
    }
  }
};
