import React, {useState, useCallback, useMemo} from 'react';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import AssociationCreateUserOrder from 'Admin/AdminAssociations/components/Orders/CreateUserOrder/CreateUserOrder';
import AdminCreateUserOrder from 'Admin/AdminDashboard/components/Orders/CreateUserOrder/CreateUserOrder';

interface ICreateOrderModalParams {
  onSuccess?(): void;
  horseId?: number;
  userId?: number;
  isBusiness?: boolean;
}

export function useCreateOrderModal(params: ICreateOrderModalParams) {
  const {horseId, userId, onSuccess, isBusiness} = params;

  const [isOpen, setIsOpen] = useState(false);
  const openCreateOrderModal = useCallback(() => setIsOpen(true), []);
  const closeCreateOrderModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeCreateOrderModal();
    onSuccess && onSuccess();
  }, [closeCreateOrderModal, onSuccess]);

  const orderForm = useMemo(() => {
    if (isBusiness) {
      return <AssociationCreateUserOrder horseId={horseId} onSuccess={handleSuccess} />;
    }

    return <AdminCreateUserOrder onSuccess={handleSuccess} horseId={horseId} ownerId={userId} />;
  }, [handleSuccess, horseId, isBusiness, userId]);

  const createOrderModal = (
    <ModalWindow isOpen={isOpen} onClose={closeCreateOrderModal}>
      {orderForm}
    </ModalWindow>
  );

  return {
    createOrderModal,
    openCreateOrderModal,
  };
}
