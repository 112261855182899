import {IServerBuildHorseRequest} from '../serverModel/BuildHorseRequest';
import {IBuildHorseRequest} from 'Filters/models/IBuildHorseRequest';
import {GenePoolsType} from 'Filters/models/GenePools';
import {convertAbilityToServer} from 'Common/helpers/convertAbilities';
import {convertHealthVariantToAvoidToServer} from 'BuildHorse/services/converters/healthVariantsToAvoid';

export function convertBuildHorseRequestToServer(request: IBuildHorseRequest): IServerBuildHorseRequest {
  const {abilities, color, sourceTypes, healthVariantsToAvoid} = request;
  const colors = color ? [color] : [];

  const distance = sourceTypes.includes(GenePoolsType.LocationRadius) ? request.distance : {};

  return {
    ...request,
    colors,
    sourceTypes,
    healthVariantsToAvoid: healthVariantsToAvoid.map(convertHealthVariantToAvoidToServer),
    abilities: {
      Gait: convertAbilityToServer(abilities.gait),
      Speed: convertAbilityToServer(abilities.speed),
      Temperament: convertAbilityToServer(abilities.temperament)
    },
    distance,
    horseId: request.horseId && request.horseId !== 0 ? request.horseId : undefined,
    associations: request.associations || [],
    modifiers: request.colorModifiers
  };
}
