const TOUR_STEPS = [
  {
    target: '#horse-profile-container',
    title: 'This is the Horse Profile!',
    content: (
      <div>
        <p>Here you'll find the available details on your horse of interest.</p>
      </div>
    ),
    placement: 'right-start' as const,
    placementBeacon: 'top' as const,
    styles: {tooltip: {width: '360px'}},
  },
  {
    target: '#owner-wrapper',
    content: (
      <div>
        <p>
          Here you can also access some information about the owner, such as their other public horse profiles and even
          contact information.
        </p>
        <p>
          (In the case of a private owner profile you may place a request to the owner for their contact information).
        </p>
      </div>
    ),
    placement: 'left-start' as const,
  },
];

export default TOUR_STEPS;
