import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  getUserPrivacySettingsActions,
  getUserPrivacySettingsActionTypes,
  IUserPrivacySettingsState,
  updateUserPrivacySettingsActions,
  updateUserPrivacySettingsActionTypes,
} from './types';
import UserService, {SettingType} from 'UserProfile/services/UserService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const getUserPrivacySettings =
  (): ActionResult<IUserPrivacySettingsState, void, getUserPrivacySettingsActions> => async (dispatch) => {
    try {
      dispatch(action(getUserPrivacySettingsActionTypes.REQUEST));
      const res = await UserService.getPrivacySettings();
      dispatch(action(getUserPrivacySettingsActionTypes.SUCCESS, res.data.data));
    } catch (error) {
      dispatch(action(getUserPrivacySettingsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateUserPrivacySettings =
  (
    isPublic: boolean,
    settings: SettingType
  ): ActionResult<IUserPrivacySettingsState, void, updateUserPrivacySettingsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateUserPrivacySettingsActionTypes.REQUEST));
      await UserService.updatePrivacySettings(isPublic, settings);
      dispatch(action(updateUserPrivacySettingsActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateUserPrivacySettingsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
