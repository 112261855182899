import React, {useCallback, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {GeneticNoteState, IGeneticNote} from 'OnlineReport/models/Summary/IGeneticNote';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {Checkbox, TextArea} from 'Common/components/Controls/index';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminOnlineReport/index';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {GenotypeLabel, GenotypeValue} from 'OnlineReport/shared/styles';
import styled from 'styled-components';
import {useDeleteModal} from 'Common/helpers/hooks/useDeleteModal';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColoredIcon from 'Icon/components/ColoredIcon';

const IconsContainer = styled.div`
  top: -5px;
  left: -65px;
`;

const EditIcon = styled(IconButton)`
  margin-left: 17px;
`;

const SaveButton = styled(PrimaryButton)`
  margin-right: 16px;
`;

const PinnedCheckbox = styled(Checkbox)`
  margin-top: 16px;
`;

const PinnedIcon = styled(ColoredIcon)`
  margin-right: 8px;
`;

const PinnedLabel = styled(GenotypeLabel)`
  margin-bottom: 0;
`;

interface IExternalProps {
  horseId: number | string;
  note: IGeneticNote;
  isAdminMode?: boolean;
  geneticNoteState: GeneticNoteState;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalProps;

const GeneticNoteSection = (props: AllProps) => {
  const {
    horseId,
    isAdminMode,
    note,
    geneticNoteState,
    geneticNoteUpdating,
    geneticNoteDeleting,
    updateGeneticNote,
    deleteGeneticNote,
  } = props;

  const [isEditNoteMode, setIsEditNoteMode] = useState<boolean>();
  const [geneticNoteValue, setGeneticNoteValue] = useState<string>(note?.value || '');
  const [isNotePinned, setIsNotePinned] = useState<boolean>(note.isPinned);

  const disableEditMode = useCallback(() => {
    setIsEditNoteMode(false);
    setIsNotePinned(note.isPinned);
  }, [note.isPinned]);
  const enableEditMode = useCallback(() => setIsEditNoteMode(true), []);

  useOnSuccessCommunication(geneticNoteUpdating, disableEditMode);

  const updateGeneticNoteHandler = useCallback(() => {
    if (horseId) {
      updateGeneticNote({
        horseId: +horseId,
        state: geneticNoteState,
        ...note,
        value: geneticNoteValue,
        isPinned: isNotePinned,
      });
    }
  }, [horseId, updateGeneticNote, geneticNoteState, note, geneticNoteValue, isNotePinned]);

  const {deleteModal, openDeleteModal} = useDeleteModal({
    deleteCommunication: geneticNoteDeleting,
    deleteAction: () => horseId && deleteGeneticNote(note),
    confirmDescription: 'Are you sure you want to remove the genetic note?',
  });

  const openDeleteModalHandler = useCallback(() => openDeleteModal(), [openDeleteModal]);

  const onChangeValue = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => setGeneticNoteValue(e.currentTarget.value),
    []
  );

  const handleCheckboxClick = (event: React.FormEvent<HTMLInputElement>) => {
    setIsNotePinned(event.currentTarget.checked);
  };

  if (note?.value?.length !== 0 && !isEditNoteMode) {
    return (
      <>
        {deleteModal}
        <GenotypeLabel>Genetic notes</GenotypeLabel>
        {note.isPinned && (
          <GenotypeLabel className="d-flex align-items-center">
            <PinnedIcon name={IconName.DangerIcon} size={16} color={ColorPalette.yellow9} fill={true} stroke={false} />
            <PinnedLabel>Notes are shown to users even if there are no results</PinnedLabel>
          </GenotypeLabel>
        )}
        <div className="position-relative">
          {isAdminMode && (
            <IconsContainer className="position-absolute">
              <IconButton
                name={IconName.Delete}
                fill={true}
                stroke={false}
                size={16}
                color={ColorPalette.gray48}
                onClick={openDeleteModalHandler}
              />
              <EditIcon
                name={IconName.Edit}
                fill={true}
                size={16}
                color={ColorPalette.gray48}
                onClick={enableEditMode}
              />
            </IconsContainer>
          )}
          <GenotypeValue>{note.value}</GenotypeValue>
        </div>
      </>
    );
  }

  if (isEditNoteMode) {
    return (
      <>
        {note.value.length !== 0 && <GenotypeLabel>Genetic notes</GenotypeLabel>}
        <TextArea onChange={onChangeValue} defaultValue={note.value} />
        <div className="d-flex">
          <SaveButton size="small" onClick={updateGeneticNoteHandler}>
            Save
          </SaveButton>
          <PrimaryButton size="small" variant="outlined" onClick={disableEditMode}>
            Cancel
          </PrimaryButton>
        </div>
        <PinnedCheckbox
          id="isPinned"
          checked={isNotePinned}
          onChange={handleCheckboxClick}
          label="Show notes to users even if there are no results"
        />
      </>
    );
  }

  if (isAdminMode) {
    return (
      <PrimaryButton
        size="small"
        onClick={enableEditMode}
        icon={{
          name: IconName.Edit,
          fill: true,
          size: 16,
        }}
      >
        Add notes
      </PrimaryButton>
    );
  }

  return null;
};

const mapStateToProps = (state: IAppState) => ({
  geneticNoteUpdating: selectors.selectCommunication(state, 'geneticNoteUpdating'),
  geneticNoteDeleting: selectors.selectCommunication(state, 'geneticNoteDeleting'),
});

const mapDispatchToProps = {
  updateGeneticNote: actions.updateGeneticNote,
  deleteGeneticNote: actions.deleteGeneticNote,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(GeneticNoteSection);
