import React, {memo} from 'react';
import {Helmet} from 'react-helmet';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import {ITab} from 'Common/components/Tabs/ITab';
import AdminTabs from 'Admin/common/AdminTabs';
import AdminContent from 'Admin/common/AdminContent';
import HtmlTemplates from './HtmlTemplates/HtmlTemplates';
import StaticResources from './StaticResources/StaticResources';
import ErrorDescriptions from './ErrorDescriptions/ErrorDescriptions';
import Products from 'Admin/AdminDashboard/components/Products/Products';

const basePath = '/admin-settings';

const adminData: ITab[] = [
  {section: 'html-templates', title: 'HTML Templates', Component: HtmlTemplates},
  {section: 'static-resources', title: 'Static Resources', Component: StaticResources},
  {section: 'error-descriptions', title: 'Error Descriptions', Component: ErrorDescriptions},
  {section: 'products', title: 'Stripe Products', Component: Products}
];

const AdminSettings = () => {
  return (
    <BaseLayout withoutPaddings={true} widthByContent={true} isBackButtonDenied={true}>
      <Helmet>
        <title>Admin settings</title>
      </Helmet>

      <AdminTabs tabs={adminData} basePath={basePath} />
      <AdminContent tabs={adminData} basePath={basePath} />
    </BaseLayout>
  );
};

export default memo(AdminSettings);
