import {action} from 'typesafe-actions';

import {IRequestParams} from 'Common/models/IRequestParams';
import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {
  cancelSubscriptionActions,
  cancelSubscriptionActionTypes,
  getSubscriptionsActions,
  getSubscriptionsActionTypes,
  overwriteFromStripeActions,
  overwriteFromStripeActionTypes
} from './types';
import StripeSubscriptionDataService from 'Admin/AdminDashboard/services/StripeSubscriptionDataService';

export const getSubscriptions = (
  params?: IRequestParams
): ActionResult<IAppState, Promise<void>, getSubscriptionsActions> => async dispatch => {
  dispatch(action(getSubscriptionsActionTypes.REQUEST));
  try {
    const res = await StripeSubscriptionDataService.getStripeSubscriptions(params);
    dispatch(action(getSubscriptionsActionTypes.SUCCESS, res));
  } catch (error) {
    dispatch(action(getSubscriptionsActionTypes.FAILURE, getErrorMessage(error)));
    throw error;
  }
};

export const overwriteFromStripe = (
  userId: number
): ActionResult<IAppState, Promise<void>, overwriteFromStripeActions> => async dispatch => {
  dispatch(action(overwriteFromStripeActionTypes.REQUEST));
  try {
    await StripeSubscriptionDataService.overwriteFromStripe(userId);
    dispatch(action(overwriteFromStripeActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(overwriteFromStripeActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const cancelSubscriptions = (
  userId: number,
  subscriptionId: number
): ActionResult<IAppState, Promise<void>, cancelSubscriptionActions> => async dispatch => {
  dispatch(action(cancelSubscriptionActionTypes.REQUEST));
  try {
    await StripeSubscriptionDataService.cancelSubscriptions(userId, subscriptionId);
    dispatch(action(cancelSubscriptionActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(cancelSubscriptionActionTypes.FAILURE, getErrorMessage(error)));
  }
};
