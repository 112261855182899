import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IPackage} from 'Dictionaries/models/IPackage';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {convertPackageToClient, convertPackageToRequest} from './converters/package';
import {IServerAssociationPackage} from './types/package';
import {
  IAdminAssociationPackage,
  IAdminAssociationPackageRequest,
} from 'Admin/AdminAssociations/models/IAdminAssociationPackage';
import {convertStatus} from './converters/organization';
import {IPackageCartSettingsRequest} from 'Admin/AdminDashboard/models/Package/IPackageAdmin';

const getPackages = async (params?: IRequestParams): Promise<IListResponse<IAdminAssociationPackage>> => {
  const response = await axiosWrapper.get<IListResponse<IServerAssociationPackage>>(`/AssociationPackagesTable`, {
    params,
  });
  return {
    ...response.data,
    data: response.data.data.map(convertPackageToClient),
  };
};

const getPackage = async (id: number): Promise<IAdminAssociationPackage> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerAssociationPackage>>(
    `/AssociationPackageDetailsTable/${id}`
  );
  return convertPackageToClient(response.data.data);
};

const createPackage = async (packageInfo: IAdminAssociationPackageRequest): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(
    `/AssociationPackagesTableCreate`,
    convertPackageToRequest(packageInfo)
  );
};

const updatePackage = async (packageInfo: IAdminAssociationPackageRequest): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(
    `/AssociationPackagesTableUpdate`,
    convertPackageToRequest(packageInfo)
  );
};

const deletePackage = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/AssociationPackagesTableDelete`, {data: {id}});
};

const changePackageStatus = async (id: number, isEnabled: boolean): Promise<void> => {
  await axiosWrapper.patch<IObjectResponse<IPackage>>(`/AssociationPackageSetStatusAdmin`, {
    id,
    status: convertStatus(isEnabled),
  });
};

const updatePackageCartSettings = async (packageCartSettings: IPackageCartSettingsRequest): Promise<void> => {
  await axiosWrapper.patch<IObjectResponse<null>>(`/AssociationPackageBundleUpdateAdmin`, packageCartSettings);
};

export default {
  getPackages,
  getPackage,
  createPackage,
  updatePackage,
  deletePackage,
  changePackageStatus,
  updatePackageCartSettings,
};
