import React, {memo} from 'react';

import BusinessPortalLayout from 'BusinessPortal/components/common/BusinessPortalLayout/BusinessPortalLayout';
import HorseProfile from 'Shared/components/HorseProfile/HorseProfile';
import {VisitorType} from 'Common/constants/VisitorType';
import ColorPalette from 'Common/constants/ColorPalette';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const BusinessPortalHorseProfileLayout = () => {
  const {isMobile} = useMediaQuery();
  return (
    <BusinessPortalLayout backgroundColor={ColorPalette.white0} isBackButtonDenied={false} withoutPaddings={isMobile}>
      <div className="position-relative d-flex flex-column">
        <HorseProfile type={VisitorType.AssociationEmployee} disableBlackPillowForHeader={!isMobile} />
      </div>
    </BusinessPortalLayout>
  );
};

export default memo(BusinessPortalHorseProfileLayout);
