import {batch} from 'react-redux';
import {ActionResult} from 'Common/store/store';
import {Action} from 'typesafe-actions';

/**
 * Composes action creators which take the same parameters.
 */
const composeActions =
  <P, S, R, A extends Action<any>>(actions: ((params: P) => ActionResult<S, R, A>)[]) =>
  (params: P): ActionResult<S, void, A> =>
  async (dispatch) =>
    batch(() => actions.forEach((action) => dispatch(action(params))));

export default composeActions;
