import {AxiosPromise} from 'axios';

import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {ILocation} from 'Common/models/ICoordinatedLocation';
import {IObjectResponse} from 'Common/models/IResponse';
import {IEmployeeProfile} from 'BusinessPortal/models/employee/IEmployeeProfile';
import {IServerEmployeePasswordUpdate, IServerEmployeeProfileUpdate} from './types/employee';

const getEmployeeProfile = (): AxiosPromise<IObjectResponse<IEmployeeProfile>> => {
  return axiosWrapper.get<IObjectResponse<IEmployeeProfile>>(`/AssociationEmployeeProfile`);
};

const updateEmployeeProfile = async (data: IServerEmployeeProfileUpdate): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AssociationEmployeeProfileUpdate`, data);
};

const getEmployeeGeoLocation = (): AxiosPromise<IObjectResponse<ILocation>> => {
  return axiosWrapper.get<IObjectResponse<ILocation>>(`/AssociationEmployeeGeoPoint`);
};

const updateEmployeeGeoLocation = async (data: ILocation): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AssociationEmployeeGeoPointUpdate`, data);
};

const updateEmployeeEmail = async (email: string): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AssociationEmployeeProfileEmailUpdate/${email}`);
};

const updateEmployeePassowrd = async (data: IServerEmployeePasswordUpdate): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AssociationEmployeeProfilePasswordUpdate/`, data);
};

export default {
  getEmployeeProfile,
  updateEmployeeProfile,
  getEmployeeGeoLocation,
  updateEmployeeGeoLocation,
  updateEmployeeEmail,
  updateEmployeePassowrd,
};
