import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {convertFindHorseRequestToServer} from './converters/findHorseRequest';
import {IPaginationRequest} from 'Common/models/IPaginationRequest';
import {IListResponse} from 'Common/models/IResponse';
import {IFindHorseRequest} from 'FindHorse/models/FindHorseRequest';
import {IPotentialPartner} from 'FindHorse/models/PotentialPartners';
import {convertPotentialPartnersToClient} from './converters/potentialPartner';
import {IServerPotentialPartner} from './serverModel/PotentialPartner';
import {ApiByType, getApiByVisitorType} from 'Common/helpers/getApiByVisitorType';
import {VisitorType} from 'Common/constants/VisitorType';

const findHorse = async (
  request: IFindHorseRequest,
  pagination: IPaginationRequest
): Promise<IListResponse<IPotentialPartner>> => {
  const apis: ApiByType = {
    [VisitorType.User]: `/FindHorses`,
    [VisitorType.AssociationEmployee]: `/FindHorsesAssociation`,
  };

  const api = getApiByVisitorType(apis);

  const response = await axiosWrapper.post<IListResponse<IServerPotentialPartner>>(api, {
    ...convertFindHorseRequestToServer(request),
    pagination,
  });

  const horses = response.data.data;
  return {...response.data, data: horses.map(convertPotentialPartnersToClient)};
};

export default {
  findHorse,
};
