import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';

const Loader = styled.div<{color?: string; backgroundColor?: string}>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: ${props => (props.backgroundColor ? props.backgroundColor : ColorPalette.white0)};
  opacity: 0.7;
  span {
    opacity: 1;
    color: ${props => (props.color ? props.color : ColorPalette.black0)};
  }
`;

interface IProps {
  className?: string;
  textColor?: string;
  backgroundColor?: string;
  style?: React.CSSProperties;
}

const Loading = (props: IProps) => {
  const {textColor, backgroundColor, style} = props;
  return (
    <Loader
      className={classNames(
        'd-flex',
        'justify-content-center',
        'align-items-center',
        'position-absolute',
        props.className
      )}
      color={textColor}
      backgroundColor={backgroundColor}
      style={style}
    >
      <span>Loading...</span>
    </Loader>
  );
};

export default Loading;
