import {combineReducers, Reducer} from 'redux';

import {IAdminCouponState} from 'Admin/AdminDashboard/store/adminCoupons/types';
import {communicationCouponsReducer} from 'Admin/AdminDashboard/store/adminCoupons/reducer/communications';
import {dataCouponsReducer} from 'Admin/AdminDashboard/store/adminCoupons/reducer/data';
import {uiCouponsReducer} from 'Admin/AdminDashboard/store/adminCoupons/reducer/ui';

export const couponsReducer: Reducer<IAdminCouponState> = combineReducers<IAdminCouponState>({
  communications: communicationCouponsReducer,
  data: dataCouponsReducer,
  ui: uiCouponsReducer
});
