import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

const STATE_NAME = 'requestNewMailConfirmation';

export interface IRequestNewMailConfirmationState {
  communications: {
    confirmationRequesting: ICommunication;
  };
}

export const RequestNewMailConfirmationActionTypes = makeCommunicationActionType(STATE_NAME, 'CONFIRMATION');
export type RequestNewMailConfirmationActions = MakeCommunicationActions<
  typeof RequestNewMailConfirmationActionTypes,
  {}
>;

export type Action = RequestNewMailConfirmationActions;
