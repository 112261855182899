import {ScreenSize} from 'Common/constants/ScreenSize';
import Typography from 'Common/constants/Typography';

export enum GenotypeFontSize {
  Medium = 'medium',
  Large = 'large',
}

export const GenotypeFontSizeByScreenSize: Record<GenotypeFontSize, Record<ScreenSize, string>> = {
  large: {mobile: Typography.size.size18, tablet: Typography.size.size24, desktop: Typography.size.size35},
  medium: {mobile: Typography.size.size16, tablet: Typography.size.size20, desktop: Typography.size.size24},
};
