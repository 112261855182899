import React, {memo} from 'react';

import {Hint} from 'Common/components/StyledComponents/StyledComponents';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {CheckboxGroupField, TextAreaField} from 'Common/components/FormFields';
import Checkbox from 'Common/components/Controls/Checkbox';
import {IInconclusiveHealthIssue} from 'Dictionaries/models/InconclusiveHealthIssues';

interface IProps {
  healthIssues: IInconclusiveHealthIssue[];
  values?: number[];
}

const HealthProfile = (props: IProps) => {
  return (
    <FormControlContainer title="Health profile">
      <Hint>
        Please indicate, if your horse has ever been diagnosed with any the following? This information is used by our
        lab to provide more accurate testing results.
      </Hint>
      <CheckboxGroupField name="healthProfile">
        {props.healthIssues.map(issue => {
          return (
            <Checkbox
              key={issue.id}
              name={String(issue.id)}
              checked={!!props.values?.find(id => id === issue.id)}
              label={issue.name}
            />
          );
        })}
      </CheckboxGroupField>
      <FormControlContainer title="Preclinical notes">
        <Hint>
          Clinical/Vet/Research Notes (notes about your horse to our team - does NOT appear in public profile) for
          example: Horse has diagnosed OCD and odd white spots on the face and haunch
        </Hint>
        <TextAreaField name="preclinicalNotes" placeholder="Add any clinical/vet/research notes" memoized={true} />
      </FormControlContainer>
    </FormControlContainer>
  );
};

export default memo(HealthProfile);
