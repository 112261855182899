import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {IOrderStatusUpdateDateField} from 'Order/models/IOrderStatusUpdateDateField';

export function parseOrderStatusDate(dates: IOrderStatusUpdateDateField): IOrderStatusUpdateDateField {
  return {
    ancestryResultsStatusUpdateDate: convertUTCToClientDate(dates.ancestryResultsStatusUpdateDate) || '',
    diagnosticResultsStatusUpdateDate: convertUTCToClientDate(dates.diagnosticResultsStatusUpdateDate) || '',
    releaseStatusUpdateDate: convertUTCToClientDate(dates.releaseStatusUpdateDate) || '',
    reviewStatusUpdateDate: convertUTCToClientDate(dates.reviewStatusUpdateDate) || '',
    parentageResultsStatusUpdateDate: convertUTCToClientDate(dates.parentageResultsStatusUpdateDate) || '',
    relativesResultsStatusUpdateDate: convertUTCToClientDate(dates.relativesResultsStatusUpdateDate) || '',
  };
}
