import {Reducer} from 'redux';

import {
  AdminEmployeesActions,
  changeAdminEmployeeStatusActionTypes,
  getAdminEmployeeActionTypes,
  getAdminEmployeeGeoLocationActionTypes,
  getAdminEmployeesActionTypes,
  IAdminEmployeeState,
  resetAdminEmployeeActionTypes,
} from '../types';

const initialState: IAdminEmployeeState['data'] = {
  adminEmployees: [],
  adminEmployee: null,
  geoLocation: {latitude: 0, longitude: 0},
};

export const dataAdminEmployeesReducer: Reducer<IAdminEmployeeState['data'], AdminEmployeesActions> = (
  state = initialState,
  action: AdminEmployeesActions
) => {
  switch (action.type) {
    case getAdminEmployeesActionTypes.SUCCESS: {
      return {...state, adminEmployees: action.payload.data};
    }
    case getAdminEmployeeActionTypes.SUCCESS: {
      return {...state, adminEmployee: action.payload};
    }
    case changeAdminEmployeeStatusActionTypes.SUCCESS: {
      const {employeeId} = action.payload;
      return {
        ...state,
        adminEmployees: state.adminEmployees.map((c) => (c.id !== employeeId ? c : {...c, isActive: !c.isActive})),
      };
    }
    case getAdminEmployeeGeoLocationActionTypes.SUCCESS: {
      return {...state, geoLocation: action.payload};
    }

    case resetAdminEmployeeActionTypes.RESET: {
      return {
        ...state,
        adminEmployee: initialState.adminEmployee,
        geoLocation: initialState.geoLocation,
      };
    }

    default: {
      return state;
    }
  }
};
