import {
  ICommunication,
  MakeCommunicationActions,
  MakeResetCommunicationActions,
} from 'Common/store/utils/communication/types';
import {makeCommunicationActionType, makeResetCommunicationActionType} from 'Common/store/utils/communication/index';
import {Nullable} from 'Common/types';
import {IAdminPaymentDetails, IAdminPaymentDetailsHorse} from 'Admin/AdminAssociations/models/IAdminPaymentDetails';
import {IListResponse} from 'Common/models/IResponse';

const STATE_NAME = 'adminAssociationPaymentUpdate';

export interface IAdminAssociationPaymentUpdateState {
  data: {
    paymentDetails: Nullable<IAdminPaymentDetails>;
    paymentDetailsHorses: IAdminPaymentDetailsHorse[];
  };
  communications: {
    paymentDetailsLoading: ICommunication;
    paymentDetailsUpdating: ICommunication;
    paymentDetailsHorsesLoading: ICommunication;
  };
}

export const getPaymentDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_PAYMENT_DETAILS');
export type getPaymentDetailsActions = MakeCommunicationActions<
  typeof getPaymentDetailsActionTypes,
  {success: IAdminPaymentDetails}
>;

export const getPaymentDetailsHorsesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_PAYMENT_DETAILS_HORSES');
export type getPaymentDetailsHorsesActions = MakeCommunicationActions<
  typeof getPaymentDetailsHorsesActionTypes,
  {success: IListResponse<IAdminPaymentDetailsHorse>}
>;

export const updatePaymentDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_PAYMENT_DETAILS');
export type updatePaymentDetailsActions = MakeCommunicationActions<typeof updatePaymentDetailsActionTypes, {}>;

export const resetPaymentDetailsActionTypes = makeResetCommunicationActionType(STATE_NAME, 'PAYMENT_DETAILS');
export type resetPaymentDetailsActions = MakeResetCommunicationActions<typeof resetPaymentDetailsActionTypes>;

export const resetPaymentDetailsHorsesActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'PAYMENT_DETAILS_HORSES'
);
export type resetPaymentDetailsHorsesActions = MakeResetCommunicationActions<
  typeof resetPaymentDetailsHorsesActionTypes
>;

export type UpdatePaymentDetailsActions =
  | getPaymentDetailsActions
  | getPaymentDetailsHorsesActions
  | updatePaymentDetailsActions
  | resetPaymentDetailsActions
  | resetPaymentDetailsHorsesActions;
