import React from 'react';
import {Elements, injectStripe} from 'react-stripe-elements';

export interface InjectedStripeProps {
  stripe: stripe.Stripe;
  elements: stripe.elements.Elements | null;
}

export function withStripe<P>(Component: React.ComponentType<P & InjectedStripeProps>): React.ComponentType<P> {
  const InjectedComponent = injectStripe(Component as any);

  const wrapped = (props: P) => (
    <Elements>
      <InjectedComponent {...props} />
    </Elements>
  );

  wrapped.displayName = `withStripe(${Component.displayName || Component.name || 'Component'})`;
  return wrapped;
}
