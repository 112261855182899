import React, {useCallback, useState} from 'react';
import styled from 'styled-components';

import {IHorseOrder} from 'BusinessPortal/models/order/createOrder/IHorseOrder';
import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import {ModalWindowFooter, ModalWindowHeader} from 'Common/components/Modal/shared';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {Checkbox} from 'Common/components/Controls';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import Couponcard from 'Coupon/components/Couponcard';
import {DiscountType} from 'Coupon/constants/DiscountType';
import OrderHorse from 'BusinessPortal/components/BusinessPortalDashboard/Orders/CreateOrder/common/OrderHorse';
import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';
import ColorPalette from 'Common/constants/ColorPalette';

const CouponCardContainer = styled.div`
  margin: 32px 0;
`;

const Content = styled.div`
  padding: 0 32px 32px 32px;
`;

const Text = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 32px;
  color: ${Theme.color.black};
  margin-bottom: 12px;
`;

const AlreadyCouponedText = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  color: ${ColorPalette.gray56};
  letter-spacing: -0.2px;
  margin-top: 13px;
  margin-left: 5px;
`;

interface IProps {
  currentCoupon?: IOrderCoupon;
  coupons: IOrderCoupon[];
  orders: IHorseOrder[];
  horses: IOrderHorse[];
  onSuccess(horses: number[]): void;
}

const AssociationCreateUserDiscountApplyingForm = (props: IProps) => {
  const {currentCoupon, coupons, orders, horses, onSuccess} = props;

  const horsesWithCouponByPackages = orders.filter((order) =>
    order.packages?.some((i) => currentCoupon?.packages.some((j) => j.id === i))
  );
  const horsesWithCouponByTests = orders.filter((order) =>
    order.tests?.some((i) => currentCoupon?.tests.some((j) => j.id === i))
  );
  const uniqueHorses = horsesWithCouponByPackages
    .concat(horsesWithCouponByTests)
    .filter((value, idx, self) => self.indexOf(value) === idx);

  const allCouponedHorses = coupons.reduce(
    (acc, curr) => acc.concat({discount: curr.amount, type: curr.type, horses: curr.horses || []}),
    [] as {discount: number; type: DiscountType; horses: number[]}[]
  );

  const [checkedHorses, setCheckedHorses] = useState<number[]>(currentCoupon?.horses || []);
  const checkHorse = useCallback(
    (horseId: number) => {
      setCheckedHorses(
        checkedHorses.find((i) => i === horseId)
          ? checkedHorses.filter((i) => i !== horseId)
          : [...checkedHorses, horseId]
      );
    },
    [checkedHorses]
  );

  const onSuccessHandler = useCallback(() => onSuccess(checkedHorses), [checkedHorses, onSuccess]);

  const couponCardPackages = currentCoupon?.packages.map((i) => i.name).concat(currentCoupon?.tests.map((i) => i.name));

  return (
    <>
      <ModalWindowHeader>Coupon applying</ModalWindowHeader>
      <Content className="d-flex w-100 flex-column">
        {currentCoupon && (
          <CouponCardContainer className="d-flex justify-content-center">
            <Couponcard
              amount={currentCoupon.amount}
              discountType={currentCoupon.type}
              expiryDate={currentCoupon.validUntil}
              usesLeft={currentCoupon.usesLeft}
              packages={couponCardPackages}
            />
          </CouponCardContainer>
        )}
        <Text className="d-flex w-100">
          This coupon can be applied to multiple horses, please select up to {currentCoupon?.usesLeft} horses to charge
          it
        </Text>
        {uniqueHorses.map((item, i) => {
          const horse = horses.find((i) => i.id === item.horseId)!;
          const existingCouponFromHorse = allCouponedHorses.find((i) => i.horses.some((j) => j === horse.id));
          const isPercentDiscountType = existingCouponFromHorse?.type === DiscountType.Percentage;
          return (
            <div key={i} className="d-flex align-items-center">
              <Checkbox checked={!!checkedHorses.find((i) => i === horse.id)} onChange={() => checkHorse(horse.id)} />
              <div className="d-flex">
                <OrderHorse horse={horse} />
                {existingCouponFromHorse && (
                  <AlreadyCouponedText>
                    ({!isPercentDiscountType && '$'}
                    {existingCouponFromHorse.discount}
                    {isPercentDiscountType && '%'} off coupon applied)
                  </AlreadyCouponedText>
                )}
              </div>
            </div>
          );
        })}
      </Content>
      <ModalWindowFooter>
        <PrimaryButton onClick={onSuccessHandler}>Done</PrimaryButton>
      </ModalWindowFooter>
    </>
  );
};

export default AssociationCreateUserDiscountApplyingForm;
