import React, {memo} from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import {Value} from 'Admin/common/styled/StyledComponents';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';

const Root = styled.div`
  border: 1px solid ${Theme.color.gray};
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
`;

const HideGeneticDataMarker = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${ColorPalette.black1};
  margin-right: 8px;
`;

interface IProps {
  isGeneticDataHidden: boolean;
}

function HorseVisibleGeneticData(props: IProps) {
  return (
    <>
      {props.isGeneticDataHidden && (
        <Root className="d-flex align-items-center justify-content-center">
          <HideGeneticDataMarker className="d-flex align-items-center justify-content-center">
            <ColoredIcon name={IconName.EyeHide} color={ColorPalette.white0} size={16} fill={true} stroke={false} />
          </HideGeneticDataMarker>
          <Value>Genetic info is hidden</Value>
        </Root>
      )}
    </>
  );
}

export default memo(HorseVisibleGeneticData);
