import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminOrders;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectOrders(state: IAppState) {
  return selectState(state).data.orders;
}

export function selectHorseSamples(state: IAppState) {
  return selectState(state).data.horseSamples;
}

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectOrderDetails(state: IAppState) {
  return selectState(state).data.orderDetails;
}

export function selectOrderStatusesDetails(state: IAppState) {
  return selectState(state).data.orderStatusesDetails;
}
