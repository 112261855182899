import {Reducer} from 'redux';

import {
  activateSampleIdActionTypes,
  AdminHorsesActions,
  createSampleActionTypes,
  getHorseActionTypes,
  getHorsesActionTypes,
  getHorseSamplesActionTypes,
  getShortHorseActionTypes,
  hideAncestryReportActionTypes,
  hideGeneticDataActionTypes,
  horseArchiveActionTypes,
  IAdminHorsesState,
  resetHorseActionTypes,
  resetHorseDetailsActionTypes,
  resetHorseSamplesActionTypes,
  updateSampleIdActionTypes,
} from '../types';

const initialStateList: IAdminHorsesState['data'] = {
  horses: [],
  shortHorse: null,
  horse: null,
  horseSamples: [],
};

export const dataHorsesReducer: Reducer<IAdminHorsesState['data'], AdminHorsesActions> = (
  state = initialStateList,
  action: AdminHorsesActions
) => {
  switch (action.type) {
    case getHorsesActionTypes.SUCCESS: {
      return {...state, horses: action.payload.data};
    }

    case getShortHorseActionTypes.SUCCESS: {
      return {...state, shortHorse: action.payload};
    }

    case getHorseActionTypes.SUCCESS: {
      return {...state, horse: action.payload};
    }

    case horseArchiveActionTypes.SUCCESS: {
      const {horseId, isArchived} = action.payload;
      return {...state, horses: state.horses.map((horse) => (horse.id !== horseId ? horse : {...horse, isArchived}))};
    }

    case resetHorseActionTypes.RESET: {
      return {...state, horse: initialStateList.horse};
    }

    case resetHorseDetailsActionTypes.RESET: {
      return {...state, shortHorse: initialStateList.shortHorse};
    }

    case resetHorseSamplesActionTypes.RESET: {
      return {...state, horseSamples: initialStateList.horseSamples};
    }

    case hideGeneticDataActionTypes.SUCCESS: {
      const {horseId, isHidden} = action.payload;
      return {
        ...state,
        horses: state.horses.map((horse) => (horse.id !== horseId ? horse : {...horse, isGeneticDataHidden: isHidden})),
      };
    }

    case hideAncestryReportActionTypes.SUCCESS: {
      const {horseId, isHidden} = action.payload;
      return {
        ...state,
        horses: state.horses.map((horse) =>
          horse.id !== horseId ? horse : {...horse, isAncestryReportHidden: isHidden}
        ),
      };
    }

    case getHorseSamplesActionTypes.SUCCESS: {
      return {...state, horseSamples: action.payload.data};
    }

    case activateSampleIdActionTypes.SUCCESS:
    case updateSampleIdActionTypes.SUCCESS:
    case createSampleActionTypes.SUCCESS: {
      return {
        ...state,
        shortHorse: state.shortHorse
          ? {
              ...state.shortHorse,
              sample: {...state.shortHorse.sample, sampleId: action.payload},
            }
          : null,
      };
    }

    default: {
      return state;
    }
  }
};
