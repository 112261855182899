import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {INamedEntity} from 'Common/models/INamedEntity';
import {ICommunication} from 'Common/store/utils/communication';
import {IReportType} from 'Dictionaries/models/IReportType';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {
  actions as adminActions,
  selectors as adminSelectors,
  TestReportModule,
} from 'Admin/AdminDashboard/store/common/testReport/index';

import {
  actions as adminAssociationsActions,
  selectors as adminAssociationsSelectors,
} from 'Admin/AdminAssociations/store/adminOrders';
import {IAppState} from 'Common/store/IAppState';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';

export interface IState {
  reportTypes: INamedEntity[];
  reportTypesLoading: ICommunication;
  reportDeleting: ICommunication;
}

export interface IDispatch {
  deleteReport(id: number): void;
}

export interface IExternalProps {
  reportTypesDictionary: ISimpleDictionary<IReportType>;
}

const adminDistributor: IDistributor<IState, IDispatch, IExternalProps> = {
  state: (state: IAppState, externalProps?: IExternalProps) => ({
    reportTypes: externalProps!.reportTypesDictionary.selectors.selectItems(state),
    reportTypesLoading: externalProps!.reportTypesDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    reportDeleting: adminSelectors.selectCommunication(state, 'reportDeleting'),
  }),
  dispatch: {
    deleteReport: adminActions.deleteReport,
  },
  modules: [TestReportModule],
};

const adminAssociationDistributor: IDistributor<IState, IDispatch, IExternalProps> = {
  state: (state: IAppState, externalProps?: IExternalProps) => ({
    reportTypes: externalProps!.reportTypesDictionary.selectors.selectItems(state),
    reportTypesLoading: externalProps!.reportTypesDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    reportDeleting: adminAssociationsSelectors.selectCommunication(state, 'reportDeleting'),
  }),
  dispatch: {
    deleteReport: adminAssociationsActions.deleteReport,
  },
};

export const horseReportDistributor: Record<SelectedStrategy, IDistributor<IState, IDispatch, IExternalProps>> = {
  [SelectedStrategy.Admin]: adminDistributor,
  [SelectedStrategy.AdminAssociation]: adminAssociationDistributor,
};
