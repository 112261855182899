import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminServiceMode;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectServiceMode(state: IAppState) {
  return selectState(state).data.adminServiceMode;
}
