import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.businessPortalOwnerProfile;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectUser(state: IAppState) {
  return selectState(state).data.owner;
}

export function selectHorses(state: IAppState) {
  return selectState(state).data.horsesByOwner;
}

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}
