import {combineReducers} from 'redux';

import {
  AdministratorsActions,
  changeAdministratorStatusActionTypes,
  createAdministratorActionTypes,
  deleteAdministratorAvatarActionTypes,
  getAdministratorActionTypes,
  getAdministratorAvatarActionTypes,
  getAdministratorsActionTypes,
  IAdministratorState,
  resetAdministratorActionTypes,
  setAdministratorPasswordActionTypes,
  updateAdministratorActionTypes,
  uploadAdministratorAvatarActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

export const communicationAdministratorsReducer = combineReducers<
  IAdministratorState['communications'],
  AdministratorsActions
>({
  administratorsLoading: makeCommunicationReducerFromEnum(getAdministratorsActionTypes),
  administratorLoading: composeReducers([
    makeCommunicationReducerFromEnum(getAdministratorActionTypes),
    makeResetCommunicationReducer(resetAdministratorActionTypes.RESET),
  ]),
  administratorCreating: makeCommunicationReducerFromEnum(createAdministratorActionTypes),
  administratorUpdating: makeCommunicationReducerFromEnum(updateAdministratorActionTypes),
  administratorAvatarLoading: makeCommunicationReducerFromEnum(getAdministratorAvatarActionTypes),
  administratorAvatarUploading: makeCommunicationReducerFromEnum(uploadAdministratorAvatarActionTypes),
  administratorAvatarDeleting: makeCommunicationReducerFromEnum(deleteAdministratorAvatarActionTypes),
  administratorPasswordUpdating: makeCommunicationReducerFromEnum(setAdministratorPasswordActionTypes),
  administratorStatusUpdating: makeCommunicationReducerFromEnum(changeAdministratorStatusActionTypes),
});
