import React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminOrders/index';
import {
  actions as adminOnlineReportActions,
  selectors as adminOnlineReportSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/index';

type IConnected = ConnectedProps<typeof connector>;

export type IWithOrderDetailsActionsProps = IConnected;

function withOrderDetailsActions<T>(Component: React.ComponentType<T>) {
  const wrapped = (props: any) => <Component {...props} />;
  return connector(wrapped);
}

const mapStateToProps = (state: IAppState) => ({
  orderStatusesDetails: selectors.selectOrderStatusesDetails(state),
  orderStatusesDetailsLoading: selectors.selectCommunication(state, 'orderStatusesDetailsLoading'),
  setSampleStatusRequesting: selectors.selectCommunication(state, 'setSampleStatusRequesting'),
  changeReviewStatusRequesting: adminOnlineReportSelectors.selectCommunication(state, 'changeReviewStatusRequesting'),
  orderHorseReleaseRequesting: selectors.selectCommunication(state, 'orderHorseReleaseRequesting'),
  requiredTests: selectors.selectOrderRequiredTests(state),
  requiredTestsLoading: selectors.selectCommunication(state, 'requiredTestsLoading'),
  downloadFormRequesting: selectors.selectCommunication(state, 'downloadSubmissionFormRequesting'),
});

const mapDispatchToProps = {
  getOrderStatusesDetails: actions.getOrderStatusesDetails,
  setSampleStatus: actions.setSampleStatus,
  changeReviewStatus: adminOnlineReportActions.changeReviewStatus,
  releaseOrderHorse: actions.releaseOrderHorse,
  getOrderRequiredTests: actions.getOrderRequiredTests,
  downloadSubmissionForm: actions.downloadOrderSubmissionForm,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withOrderDetailsActions;
