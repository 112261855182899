import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';
import {IPagination, IListResponse} from 'Common/models/IResponse';
import {IPotentialPartner} from 'FindHorse/models/PotentialPartners';

const STATE_NAME = 'findHorse';

export interface IFindHorseState {
  data: {
    horses: IPotentialPartner[];
  };
  communications: {
    horsesLoading: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const findHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'FIND_HORSE_REQUEST');
export type findHorseActions = MakeCommunicationActions<
  typeof findHorseActionTypes,
  {success: IListResponse<IPotentialPartner>}
>;

export type Actions = findHorseActions;
