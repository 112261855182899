import {IHorsePackage} from 'Order/models/IHorsePackage';
import {IHorseTest} from 'Order/models/IHorseTest';

export function convertIHorsePackagesToIHorseTest(horsePackages: IHorsePackage[]): IHorseTest[] {
  const uniquePackages = horsePackages
    .flatMap((x) => x.packages.map((y) => y.id))
    .filter((value, idx, self) => self.indexOf(value) === idx);

  const result: IHorseTest[] = uniquePackages.map((uniqPackage) => {
    const horses = horsePackages
      .filter((x) => x.packages.some((h) => h.id === uniqPackage))
      .map((pack) => pack.horseId);

    return {packageId: uniqPackage, horses: horses};
  });

  return result;
}
