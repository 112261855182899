import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';

export const AdminPageLayout = styled.div`
  margin: 0px 25px;
`;

export const FieldLabel = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  color: ${ColorPalette.gray44};
  margin-bottom: 8px;
`;

export const FieldValue = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.black1};
`;

export const Field = styled.div`
  margin-bottom: 32px;
`;

export const HeaderTitle = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  margin-bottom: 8px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${ColorPalette.gray44};
`;

export const Value = styled.div`
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size16};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  color: ${ColorPalette.black1};
  line-height: 24px;
`;
