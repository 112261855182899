import {IParentRequest} from 'Horse/models/IHorseRequest';
import {Parentage} from 'HorseProfile/constants/Parentage';

const initialParent: IParentRequest = {
  registries: [],
  name: '',
  parent: Parentage.Sire
};

export const initialParentage = {sire: {...initialParent}, dam: {...initialParent, parent: Parentage.Dam}};
