import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormikContext} from 'formik';

import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {
  convertErrors,
  convertTestSummaryToServer,
  FormErrors,
  IFormValues,
} from 'BusinessPortal/components/BusinessPortalDashboard/Orders/CreateOrder/validation';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useToast} from 'Common/helpers/hooks/useToast';
import {ICreatedOrder} from 'BusinessPortal/models/order/createOrder/ICreatedOrder';
import {Nullable} from 'Common/types';
import {ICommunication} from 'Common/store/utils/communication';
import {PaymentMethod} from 'Common/constants/PaymentMethod';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import {ITestSummaryRequest} from 'BusinessPortal/services/types/summary';
import {IOrderSummary} from 'BusinessPortal/models/order/createOrder/IOrderSummary';

const CREATE_ORDER_ERROR = 'Unknown error on creating order';

interface IProps {
  createdOrderRef: React.MutableRefObject<Nullable<ICreatedOrder>>;
  createdOrder: Nullable<ICreatedOrder>;
  creatingOrder: ICommunication;
  closeStripePaymentModal(): void;
  redirectToSuccessPayment(): void;
  redirectToResultPage(): void;
  getSummary(request: ITestSummaryRequest): void;
  removeSummary(): void;
  coupon: Nullable<IOrderCoupon>;
  summary: Nullable<IOrderSummary>;
  couponLoading: ICommunication;
  summaryLoading: ICommunication;
  hasOtherError?: boolean;
}

export function useOrderHandler(props: IProps) {
  const {
    createdOrderRef,
    createdOrder,
    creatingOrder,
    closeStripePaymentModal,
    redirectToSuccessPayment,
    redirectToResultPage,
    removeSummary,
    getSummary,
    summaryLoading,
    couponLoading,
    coupon,
    summary,
    hasOtherError,
  } = props;
  const {addToast} = useToast();
  const {setStatus, setErrors, values} = useFormikContext<IFormValues>();

  const isTestEmpty = values.orders.length === 0 || values.orders.every((x) => !x.packages && !x.tests);

  useEffect(() => {
    createdOrderRef.current = createdOrder;
  }, [createdOrder, createdOrderRef]);

  const creatingOrderError = creatingOrder.error;
  const creatingOrderFieldErrors = useMemo(() => {
    return getFieldErrors<undefined, FormErrors>(creatingOrderError, convertErrors);
  }, [creatingOrderError]);

  const creatingOrderCommonErrors = useMemo(() => {
    return getCommonErrors(creatingOrderError);
  }, [creatingOrderError]);

  const unexpectedCreatingOrderError = useMemo(() => {
    if (creatingOrder.error && !creatingOrderCommonErrors && !creatingOrderFieldErrors) {
      closeStripePaymentModal();
      return CREATE_ORDER_ERROR;
    }
    return '';
  }, [closeStripePaymentModal, creatingOrder.error, creatingOrderCommonErrors, creatingOrderFieldErrors]);

  const onCreatingOrderError = useCallback(() => {
    if (creatingOrderFieldErrors) {
      setErrors(creatingOrderFieldErrors);
    }

    if (creatingOrderCommonErrors) {
      setStatus(creatingOrderCommonErrors);
      addToast(creatingOrderCommonErrors, 'error');
    }
  }, [setErrors, setStatus, addToast, creatingOrderFieldErrors, creatingOrderCommonErrors]);

  useOnErrorCommunication(creatingOrder, onCreatingOrderError);

  const isFreeOrder =
    summary?.amount === summary?.totalDiscount && summary?.amount !== 0 && summary?.totalDiscount !== 0;
  const isNeedToPay = values.paymentMethod !== PaymentMethod.Invoice && createdOrder?.totalAmount !== 0 && !isFreeOrder;
  useOnSuccessCommunication(creatingOrder, () => {
    if (isNeedToPay) {
      return;
    }

    if (createdOrder?.totalAmount === 0 || isFreeOrder) {
      redirectToSuccessPayment();
      return;
    }

    redirectToResultPage();
  });

  useEffect(() => {
    if (isTestEmpty) {
      removeSummary();
      return;
    }

    getSummary(convertTestSummaryToServer(values.orders, values.coupons));
  }, [values.orders, coupon, getSummary, isTestEmpty, removeSummary, values.coupons]);

  const summaryError = useMemo(() => {
    if (couponLoading.isRequesting && summaryLoading.error && !hasOtherError) {
      closeStripePaymentModal();
      return getCommonErrors(summaryLoading.error) || CREATE_ORDER_ERROR;
    }
    return undefined;
  }, [couponLoading.isRequesting, summaryLoading.error, hasOtherError, closeStripePaymentModal]);

  return {unexpectedCreatingOrderError, summaryError};
}
