import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {ISignupForm} from 'Signup/models/ISignupForm';
import UserService from 'UserProfile/services/UserService';
import {ISignupState, signupActions, SignupActionTypes} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const signup = (data: ISignupForm): ActionResult<ISignupState, void, signupActions> => async dispatch => {
  try {
    dispatch(action(SignupActionTypes.REQUEST));
    await UserService.signup(data);
    dispatch(action(SignupActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(SignupActionTypes.FAILURE, getErrorMessage(error)));
  }
};
