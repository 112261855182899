import React, {useCallback} from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {ReactComponent as HeaderLogo} from 'SubscriptionWelcome/img/header-horse.svg';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {
  DarkContainerBase,
  DescriptionLabel,
  HeaderLabel,
  SectionBase
} from 'SubscriptionWelcome/components/BahSubscription/parts/styled';

const Container = styled(DarkContainerBase)`
  height: 460px;
`;

const SubscriptionInformationSection = styled.div`
  max-width: 340px;

  @media ${breakpoints.sm} {
    max-width: 500px;
  }
  @media ${breakpoints.md} {
    max-width: 420px;
  }
`;

const ButtonsWrapper = styled.div`
  flex-direction: column;
  align-items: center;

  @media ${breakpoints.sm} {
    width: 100%;
    flex-direction: row;
  }
`;

const SubscribeButton = styled(PrimaryButton)`
  width: 260px;
  margin-bottom: 20px;

  @media ${breakpoints.sm} {
    width: 160px;
    margin-bottom: 0;
    margin-right: 40px;
  }
`;

const LearnMoreButton = styled(PrimaryButton)`
  width: 260px;
  background-color: ${Theme.color.black};
  color: ${Theme.color.white};

  @media ${breakpoints.sm} {
    width: 160px;
  }
`;

interface IProps {
  onSubscribeClick(): void;
}

const Body = (props: IProps) => {
  const {onSubscribeClick} = props;
  const {isDesktop} = useMediaQuery();

  const smoothAutoScroll = useCallback(() => {
    return document.getElementById('autoScrollDestination')?.scrollIntoView({behavior: 'smooth'});
  }, []);

  return (
    <Container className="d-flex w-100 justify-content-center">
      <SectionBase className="d-flex w-100 align-items-center">
        <SubscriptionInformationSection>
          <HeaderLabel isMain={true}>
            Subscribe to access <div>Build-a-Horse</div>
          </HeaderLabel>
          <DescriptionLabel isMain={true}>
            Access the most unique animal genetics tool in the world! Match horses and see the genetic potential of your
            future foal. Your dream horse is just a few clicks away!
          </DescriptionLabel>
          <ButtonsWrapper className="d-flex">
            <SubscribeButton onClick={onSubscribeClick}>Subscribe</SubscribeButton>
            <LearnMoreButton variant="outlined" onClick={smoothAutoScroll}>
              Learn more
            </LearnMoreButton>
          </ButtonsWrapper>
        </SubscriptionInformationSection>
        {isDesktop && (
          <div>
            <HeaderLogo />
          </div>
        )}
      </SectionBase>
    </Container>
  );
};

export default Body;
