import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

const selectState = (state: IAppState) => state.adminAssociationOrders;

export const selectCommunication = makeCommunicationSelector(selectState);

export const selectOrders = (state: IAppState) => selectState(state).data.orders;
export const selectPagination = (state: IAppState) => selectState(state).ui.pagination;
export const selectOrderDetails = (state: IAppState) => selectState(state).data.orderDetails;
export const selectOrderPaymentDetails = (state: IAppState) => selectState(state).data.orderPaymentDetails;
export const selectOrderRequiredTests = (state: IAppState) => selectState(state).data.requiredTests;
export const selectOrderReportDetails = (state: IAppState) => selectState(state).data.orderReportDetails;
export const selectOrderStatusesDetails = (state: IAppState) => selectState(state).data.orderStatusesDetails;
export const selectOrderSampleDetails = (state: IAppState) => selectState(state).data.orderSampleDetails;
export const selectOrderTestsDetails = (state: IAppState) => selectState(state).data.orderTestsDetails;
