import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';
import {IChangePassword} from 'UserProfile/models/IChangePassword';

const STATE_NAME = 'changePassword';

export interface IChangePasswordState {
  data: {
    message: string | null;
  };
  communications: {
    changePasswordLoading: ICommunication<IChangePassword>;
  };
}

export const updatePasswordActionTypes = makeCommunicationActionType(STATE_NAME, 'CHANGE_PASSWORD');
export type updatePasswordActions = MakeCommunicationActions<typeof updatePasswordActionTypes, {success: string}>;

export type ChangePasswordActions = updatePasswordActions;
