import React from 'react';

interface IStaticData {
  ownerName: string;
  horseName: string;
  sampleId: string;
}

interface ISectionContainerContext {
  staticData: IStaticData;
  isPrintable?: boolean;
}

export const SectionContainerContext = React.createContext<ISectionContainerContext>(null as any);

export default function useSectionContainer() {
  return React.useContext(SectionContainerContext);
}
