import React, {useEffect} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {RequiredBy} from 'Common/types';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage, FieldHint} from 'Common/components/StyledComponents/StyledComponents';
import {IAppState} from 'Common/store/IAppState';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {CheckboxField, ImageUploaderField, InputField} from 'Common/components/FormFields';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {actions, selectors} from 'Admin/AdminAdvancedDashboard/store/adminAdministrators/administrators';
import PasswordField from 'Common/components/FormFields/PasswordField';
import {FormType} from 'Common/constants/FormType';
import {
  convertToClient,
  convertToServer,
  convertToServerUpdating,
  createValidationSchema,
  IFormValues,
  initialValue,
  updateValidationSchema,
} from './validation';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {IconName} from 'Icon/components/Icon';

const formHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add administrator',
  edit: 'Update administrator',
};

const buttonHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add',
  edit: 'Save',
};

const CheckboxWrapper = styled.div`
  margin-bottom: 10px;
`;

interface IExternalProps {
  administratorId?: number;
  type: FormType;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const AdministratorForm: React.FC<AllProps> = (props: AllProps) => {
  const {status, setStatus} = props;
  const {administratorId, onSuccess, type} = props;
  const {
    getAdministrator,
    resetAdministrator,
    administratorLoading,
    administratorCreating,
    administratorUpdating,
    administratorAvatarUploading,
    administratorAvatarDeleting,
  } = props;

  const errorInfo =
    administratorLoading.error ||
    administratorUpdating.error ||
    administratorCreating.error ||
    administratorAvatarUploading.error ||
    administratorAvatarDeleting.error;

  const isRequesting = [
    administratorLoading,
    administratorUpdating,
    administratorCreating,
    administratorAvatarUploading,
    administratorAvatarDeleting,
  ].some((i) => i.isRequesting);

  useOnSuccessCommunication(administratorCreating, onSuccess);
  useOnSuccessCommunication(administratorUpdating, onSuccess);

  useEffect(() => {
    const commonError = getCommonErrors(errorInfo);
    commonError && setStatus(commonError);
  }, [errorInfo, setStatus]);

  useEffect(() => {
    if (administratorId) {
      getAdministrator(administratorId);
    }

    return resetAdministrator;
  }, [administratorId, getAdministrator, resetAdministrator]);

  const header = formHeaderByType[type];
  const saveButtonHeader = buttonHeaderByType[type];

  const isCreate = type === FormType.create;

  return (
    <>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent minHeight={isCreate ? 500 : undefined}>
          {isRequesting && <Loading />}

          <InputField isRequired={true} name="name" type="text" label="Name" placeholder="Name" className="w-50" />
          <InputField name="phone" type="text" label="Phone" placeholder="Phone" className="w-50" />

          {isCreate && (
            <>
              <InputField
                isRequired={true}
                name="email"
                type="text"
                label="Email"
                placeholder="Email"
                className="w-50"
                autoComplete="off"
              />
              <PasswordField
                name="password"
                label="Password"
                placeholder="Password"
                autoComplete="off"
                isRequired={true}
              />
              <CheckboxWrapper className="d-flex flex-column justify-content-start align-items-start">
                <CheckboxField name="isActive" label="Active administrator" />
                <FieldHint>Enabling this option will allow the administrator to be active</FieldHint>
              </CheckboxWrapper>
            </>
          )}

          <FieldControlContainer label="Image">
            <ImageUploaderField
              name="avatar"
              iconProps={{name: IconName.RearedHorse, width: 48, height: 60}}
              title="Add image"
              isShowBlurredBackground={false}
            />
          </FieldControlContainer>
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" isLoading={props.isSubmitting}>
            {saveButtonHeader}
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const AdministratorFormWithFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({administrator}) => (administrator ? convertToClient(administrator) : initialValue),
  validationSchema: ({type}: IExternalProps) =>
    type === FormType.edit ? updateValidationSchema : createValidationSchema,
  handleSubmit: async (values, formikBag) => {
    const {
      type,
      createAdministrator,
      updateAdministrator,
      uploadAdministratorAvatar,
      deleteAdministratorAvatar,
      administrator,
    } = formikBag.props;

    formikBag.setSubmitting(true);

    try {
      if (type === FormType.create) {
        const createdAdministrator = await createAdministrator(convertToServer(values));
        !!values.avatar?.file && (await uploadAdministratorAvatar(createdAdministrator.id, values.avatar.file));
      }

      if (type === FormType.edit) {
        await updateAdministrator(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
        !!values.id && !!administrator?.avatar && !values.avatar && (await deleteAdministratorAvatar(values.id));
        !!values.id && !!values.avatar?.file && (await uploadAdministratorAvatar(values.id, values.avatar.file));
      }
    } catch (err) {
      formikBag.setStatus(err);
      return;
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(AdministratorForm);

const mapStateToProps = (state: IAppState) => ({
  administrator: selectors.selectAdministrator(state),
  administratorLoading: selectors.selectCommunication(state, 'administratorLoading'),
  administratorCreating: selectors.selectCommunication(state, 'administratorCreating'),
  administratorUpdating: selectors.selectCommunication(state, 'administratorUpdating'),
  administratorAvatarUploading: selectors.selectCommunication(state, 'administratorAvatarUploading'),
  administratorAvatarDeleting: selectors.selectCommunication(state, 'administratorAvatarDeleting'),
});

const mapDispatchToProps = {
  getAdministrator: actions.getAdministrator,
  createAdministrator: actions.createAdministrator,
  updateAdministrator: actions.updateAdministrator,
  resetAdministrator: actions.resetAdministrator,
  uploadAdministratorAvatar: actions.uploadAdministratorAvatar,
  deleteAdministratorAvatar: actions.deleteAdministratorAvatar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(React.memo(AdministratorFormWithFormik));
