import React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminOrders/index';

type IConnected = ConnectedProps<typeof connector>;

export type IWithOrderDetailsActionsProps = IConnected;

function withOrderDetailsActions<T>(Component: React.ComponentType<T>) {
  const wrapped = (props: any) => <Component {...props} />;
  return connector(wrapped);
}

const mapStateToProps = (state: IAppState) => ({
  orderStatusesDetails: selectors.selectOrderStatusesDetails(state),
  orderStatusesDetailsLoading: selectors.selectCommunication(state, 'orderStatusesDetailsLoading'),
  setSampleStatusRequesting: selectors.selectCommunication(state, 'setSampleStatusRequesting'),
});

const mapDispatchToProps = {
  getOrderStatusesDetails: actions.getOrderStatusesDetails,
  resetOrderStatusDetails: actions.resetOrderStatusDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withOrderDetailsActions;
