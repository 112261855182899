import {combineReducers} from 'redux';

import {
  adminReportActions,
  IAdminReportState,
  downloadDemographicsReportActionTypes,
  downloadPaidOrdersReportActionTypes,
  downloadOrdersReportActionTypes
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationDownloadReportsReducer = combineReducers<
  IAdminReportState['communications'],
  adminReportActions
>({
  downloadDemographicsRequesting: makeCommunicationReducerFromEnum(downloadDemographicsReportActionTypes),
  downloadPaidOrdersRequesting: makeCommunicationReducerFromEnum(downloadPaidOrdersReportActionTypes),
  downloadOrdersRequesting: makeCommunicationReducerFromEnum(downloadOrdersReportActionTypes)
});
