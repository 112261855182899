import {IModule} from 'redux-dynamic-modules';

import {IAdminEmployeeState} from './types';
import {adminEmployeesReducer} from './reducer';

export interface IAdminEmployeesModuleState {
  adminEmployees: IAdminEmployeeState;
}

export const AdminEmployeesModule: IModule<IAdminEmployeesModuleState> = {
  id: 'adminEmployees',
  reducerMap: {
    adminEmployees: adminEmployeesReducer,
  },
};
