import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  createHorseActions,
  createHorseActionTypes,
  deleteHorseActions,
  deleteHorseActionTypes,
  getHorseAbilitiesActions,
  getHorseAbilitiesActionTypes,
  getHorseActions,
  getHorseActionTypes,
  getHorseLastOrderActions,
  getHorseLastOrderActionTypes,
  getHorseParantageReportActions,
  getHorseParantageReportActionTypes,
  IHorseProfileTypeRequest,
  IHorseState,
  resetHorseActions,
  resetHorseProfileTypeActions,
  resetHorseActionTypes,
  resetHorseProfileTypeActionTypes,
  updateHorseActions,
  updateHorseActionTypes,
  updateHorseProfileTypeActions,
  updateHorseProfileTypeActionTypes,
  searchHorseActionTypes,
  searchHorseActions,
  resetSearchHorseActions,
  resetSearchHorseActionTypes,
  getHorseOwnerActions,
  getHorseOwnerActionTypes,
  getOwnerHorsesActions,
  getOwnerHorsesAtionTypes,
  resetHorseParantageReportActions,
  resetHorseParantageReportActionTypes,
  updateHorseAncsetryProfileTypeActions,
  updateHorseAncestryProfileTypeActionTypes,
  resetHorseAncestryProfileTypeActions,
  resetHorseAncestryProfileTypeActionTypes,
} from './types';
import HorseService from 'Horse/services/HorseDataService';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IHorseRequestParams} from 'Horse/models/IHorseRequest';

export const getHorse =
  (id: number): ActionResult<IHorseState, void, getHorseActions> =>
  async (dispatch) => {
    dispatch(action(getHorseActionTypes.REQUEST));
    try {
      const horse = await HorseService.getHorse(id);
      dispatch(action(getHorseActionTypes.SUCCESS, horse));
    } catch (error) {
      dispatch(action(getHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createHorse =
  (params: IHorseRequestParams): ActionResult<IHorseState, Promise<number>, createHorseActions> =>
  async (dispatch) => {
    dispatch(action(createHorseActionTypes.REQUEST));

    try {
      const data = await HorseService.createHorse(params);
      dispatch(action(createHorseActionTypes.SUCCESS));
      return data;
    } catch (e) {
      const error = getErrorMessage(e);
      dispatch(action(createHorseActionTypes.FAILURE, error));
      // need for horse form submit
      throw error;
    }
  };

export const updateHorse = (
  params: IHorseRequestParams
): ActionResult<IHorseState, Promise<void>, updateHorseActions> => {
  return async (dispatch) => {
    try {
      dispatch(action(updateHorseActionTypes.REQUEST));
      await HorseService.updateHorse(params);
      dispatch(action(updateHorseActionTypes.SUCCESS));
    } catch (e) {
      const error = getErrorMessage(e);
      dispatch(action(updateHorseActionTypes.FAILURE, error));
      // need for horse form submit
      throw error;
    }
  };
};

export const deleteHorse =
  (id: number): ActionResult<IAppState, void, deleteHorseActions> =>
  async (dispatch) => {
    dispatch(action(deleteHorseActionTypes.REQUEST));
    try {
      await HorseService.deleteHorse(id);
      dispatch(action(deleteHorseActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetHorseResults = (): ActionResult<IHorseState, void, resetHorseActions> => (dispatch) => {
  dispatch(action(resetHorseActionTypes.RESET));
};

export const updateHorseProfileType =
  (params: IHorseProfileTypeRequest): ActionResult<IAppState, void, updateHorseProfileTypeActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateHorseProfileTypeActionTypes.REQUEST));
      await HorseService.updateHorseProfileType(params);
      const result = params.profileType;
      dispatch(action(updateHorseProfileTypeActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(updateHorseProfileTypeActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateHorseAncestryProfileType =
  (params: IHorseProfileTypeRequest): ActionResult<IAppState, void, updateHorseAncsetryProfileTypeActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateHorseAncestryProfileTypeActionTypes.REQUEST));
      await HorseService.updateHorseAncestryProfileType(params);
      const result = params.profileType;
      dispatch(action(updateHorseAncestryProfileTypeActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(updateHorseAncestryProfileTypeActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetHorseProfileTypeResults =
  (): ActionResult<IAppState, void, resetHorseProfileTypeActions> => (dispatch) => {
    dispatch(action(resetHorseProfileTypeActionTypes.RESET));
  };

export const resetHorseAncestryProfileTypeResults =
  (): ActionResult<IAppState, void, resetHorseAncestryProfileTypeActions> => (dispatch) => {
    dispatch(action(resetHorseAncestryProfileTypeActionTypes.RESET));
  };

export const getHorseAbilities =
  (horseId: number): ActionResult<IAppState, void, getHorseAbilitiesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseAbilitiesActionTypes.REQUEST));
      const res = await HorseService.getHorseAbilities(horseId);
      dispatch(action(getHorseAbilitiesActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getHorseAbilitiesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseParantageReport =
  (horseId: number): ActionResult<IAppState, void, getHorseParantageReportActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseParantageReportActionTypes.REQUEST));
      const res = await HorseService.getHorseParantageReport(horseId);
      dispatch(action(getHorseParantageReportActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getHorseParantageReportActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseLastOrder =
  (horseId: number): ActionResult<IAppState, void, getHorseLastOrderActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseLastOrderActionTypes.REQUEST));
      const res = await HorseService.getHorseLastOrder(horseId);
      dispatch(action(getHorseLastOrderActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHorseLastOrderActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const searchHorse =
  (id: number): ActionResult<IHorseState, void, searchHorseActions> =>
  async (dispatch) => {
    dispatch(action(searchHorseActionTypes.REQUEST));
    try {
      const horse = await HorseService.searchHorseById(id);
      dispatch(action(searchHorseActionTypes.SUCCESS, horse));
    } catch (error) {
      dispatch(action(searchHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseOwner =
  (horseId: number): ActionResult<IAppState, Promise<void>, getHorseOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseOwnerActionTypes.REQUEST));
      const data = await HorseService.getHorseOwner(horseId);
      dispatch(action(getHorseOwnerActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getHorseOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOwnerHorses =
  (horseId: number): ActionResult<IAppState, Promise<void>, getOwnerHorsesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOwnerHorsesAtionTypes.REQUEST));
      const res = await HorseService.getOwnerHorses(horseId);
      dispatch(action(getOwnerHorsesAtionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getOwnerHorsesAtionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetSearchHorseResults = (): ActionResult<IHorseState, void, resetSearchHorseActions> => (dispatch) => {
  dispatch(action(resetSearchHorseActionTypes.RESET));
};

export const resetHorseParantageReport =
  (): ActionResult<IAppState, void, resetHorseParantageReportActions> => (dispatch) => {
    dispatch(action(resetHorseParantageReportActionTypes.RESET));
  };
