import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  getHorsesByOwnerActions,
  getHorsesByOwnerActionTypes,
  getOwnerActions,
  getOwnerActionTypes,
  IAdminAssociationOwnerProfileState,
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import AdminOwnerDataService from 'Admin/AdminAssociations/services/AdminOwnerDataService';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IAppState} from 'Common/store/IAppState';

export const getUser =
  (id: number, isGeneralUser: boolean): ActionResult<IAdminAssociationOwnerProfileState, void, getOwnerActions> =>
  async (dispatch) => {
    dispatch(action(getOwnerActionTypes.REQUEST));

    await AdminOwnerDataService.getUser(id, isGeneralUser)
      .then((res) => {
        dispatch(action(getOwnerActionTypes.SUCCESS, res.data));
      })
      .catch((error) => {
        dispatch(action(getOwnerActionTypes.FAILURE, getErrorMessage(error)));
      });
  };

export const getHorsesByOwner =
  (
    params: IRequestParams,
    id: number,
    isGeneralUser: boolean
  ): ActionResult<IAppState, Promise<void>, getHorsesByOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorsesByOwnerActionTypes.REQUEST));
      const res = await AdminOwnerDataService.getHorsesByOwner(params, id, isGeneralUser);
      dispatch(action(getHorsesByOwnerActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getHorsesByOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
