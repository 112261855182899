import {IEntity} from 'Common/models/IEntity';

export enum GeneticNoteState {
  New = 'New',
  Current = 'Current',
}

export enum GeneticNoteType {
  Genotypes = 'Genotypes',
  Abilities = 'Abilities',
  Coat = 'Coat',
  Dangers = 'Dangers',
  HealthIssues = 'HealthIssues',
  DangerWithoutProbabilityList = 'DangerWithoutProbabilityList',
  HealthIssueWithoutProbabilityList = 'HealthIssueWithoutProbabilityList',
  Traits = 'Traits',
}

export interface IGeneticNote extends IEntity {
  isPinned: boolean;
  value: string;
  type?: GeneticNoteType;
}
