import {IModule} from 'redux-dynamic-modules';

import {IAdminHorseTransferState} from './types';
import {reducer} from './reducer';

export interface IHorseTransferModuleState {
  adminHorseTransfer: IAdminHorseTransferState;
}

export const AdminHorseTransferModule: IModule<IHorseTransferModuleState> = {
  id: 'adminHorseTransfer',
  reducerMap: {
    adminHorseTransfer: reducer,
  },
};
