import * as Yup from 'yup';

import {Combination} from 'Common/constants/Combination';
import {IFormSequenceItem} from '../models/IFormSequenceItem';

export interface IFormValues {
  type: Combination;
  sequences: IFormSequenceItem[];
}

export const initialValue: IFormValues = {
  type: Combination.And,
  sequences: [{type: Combination.And, sequenceItems: [0]}]
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  sequences: Yup.array()
});
