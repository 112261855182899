import LocalStorageService from 'Common/services/LocalStorageService';
import {VisitorType} from 'Common/constants/VisitorType';

export const VISITOR_TYPE_KEY = 'visitorType';

type Storage = typeof LocalStorageService;

class VisitorTypeService {
  constructor(private storage: Storage) {}

  public setVisitorType(visitorType: VisitorType) {
    this.storage.setItem(VISITOR_TYPE_KEY, visitorType);
  }

  public getVisitorType(): VisitorType {
    return this.storage.getItem(VISITOR_TYPE_KEY) as VisitorType;
  }

  public removeVisitorType() {
    this.storage.removeItem(VISITOR_TYPE_KEY);
  }
}

export default new VisitorTypeService(LocalStorageService);
