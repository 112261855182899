import React, {useCallback, useState} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import RequestSampleForm from 'Admin/shared/components/RequestSampleForm/RequestSampleForm';
import RequestSampleFormAssociation from 'Admin/AdminAssociations/components/Orders/RequestSampleForm/RequestSampleForm';
import {Nullable} from 'Common/types';
import {IAdminActionsProps} from 'Admin/AdminDashboard/helpers/hooks/common';
import useDistributorStrategy from 'Common/helpers/strategy/useDistributorStrategy';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';

type IUseRequestNewSampleAction = Omit<IAdminActionsProps, 'communication'>;

export function useRequestNewSampleAction(props: IUseRequestNewSampleAction) {
  const {onSuccess} = props;

  const [selectedOrderId, setSelectedOrderId] = useState<Nullable<number>>();
  const [selectedHorseId, setSelectedHorseId] = useState<Nullable<number>>();

  const {currentStrategy} = useDistributorStrategy();

  const [isRequestNewSampleModalOpen, setIsRequestNewSampleModalOpen] = useState(false);
  const openRequestNewSampleModal = useCallback(
    (orderId: number, horseId?: number) => {
      setIsRequestNewSampleModalOpen(true);
      setSelectedOrderId(orderId);
      setSelectedHorseId(horseId);
    },
    [setSelectedOrderId, setSelectedHorseId, setIsRequestNewSampleModalOpen]
  );
  const closeRequestNewSampleModal = useCallback(() => {
    setIsRequestNewSampleModalOpen(false);
    setSelectedOrderId(null);
    setSelectedHorseId(null);
  }, [setSelectedOrderId, setSelectedHorseId, setIsRequestNewSampleModalOpen]);
  const successRequestNewSample = useCallback(async () => {
    closeRequestNewSampleModal();
    onSuccess();
  }, [closeRequestNewSampleModal, onSuccess]);

  const requestNewSampleModal = (
    <ModalWindow isOpen={isRequestNewSampleModalOpen} onClose={closeRequestNewSampleModal}>
      {currentStrategy === SelectedStrategy.Admin && (
        <RequestSampleForm orderId={selectedOrderId!} horseId={selectedHorseId} onSuccess={successRequestNewSample} />
      )}
      {currentStrategy === SelectedStrategy.AdminAssociation && (
        <RequestSampleFormAssociation
          orderId={selectedOrderId!}
          horseId={selectedHorseId}
          onSuccess={successRequestNewSample}
        />
      )}
    </ModalWindow>
  );

  return {
    requestNewSampleModal,
    openRequestNewSampleModal,
  };
}
