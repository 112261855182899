import {combineReducers, Reducer} from 'redux';

import {IAdminGeneGroupImageState} from '../types';
import {communicationGeneGroupImageReducer} from './communications';
import {dataGeneGroupImageReducer} from './data';

export const adminGeneGroupImageReducer: Reducer<IAdminGeneGroupImageState> = combineReducers<
  IAdminGeneGroupImageState
>({
  communications: communicationGeneGroupImageReducer,
  data: dataGeneGroupImageReducer
});
