import {Reducer} from 'redux';

import {
  Actions,
  getOrderPackagesActionTypes,
  getUserCouponActionTypes,
  getUserHorsesActionTypes,
  getUserPackagesActionTypes,
  getUserSummaryActionTypes,
  IAdminCreateUserOrderState,
  resetAdminUserOrderActionTypes,
  resetUserCouponActionTypes,
  resetUserSummaryActionTypes,
} from '../types';

const initialStateList: IAdminCreateUserOrderState['data'] = {
  userHorses: [],
  userPackages: [],
  userSummary: null,
  userCoupon: null,
  orderPackages: [],
};

export const dataReducer: Reducer<IAdminCreateUserOrderState['data'], Actions> = (
  state = initialStateList,
  action: Actions
) => {
  switch (action.type) {
    case getUserHorsesActionTypes.SUCCESS: {
      return {...state, userHorses: action.payload};
    }
    case getUserPackagesActionTypes.SUCCESS: {
      return {...state, userPackages: action.payload};
    }
    case getUserSummaryActionTypes.SUCCESS: {
      return {...state, userSummary: action.payload};
    }
    case getUserCouponActionTypes.SUCCESS: {
      return {...state, userCoupon: action.payload};
    }
    case getOrderPackagesActionTypes.SUCCESS: {
      return {...state, orderPackages: action.payload};
    }
    case resetUserCouponActionTypes.RESET: {
      return {...state, userCoupon: null};
    }
    case resetUserSummaryActionTypes.RESET: {
      return {...state, userSummary: null};
    }
    case resetAdminUserOrderActionTypes.RESET: {
      return {...state, initialStateList};
    }
    default: {
      return state;
    }
  }
};
