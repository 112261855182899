import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';

import {InputField, SelectField, TextAreaField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {IEntity} from 'Common/models/IEntity';
import {IDictionary, ISimpleDictionary} from 'DictionaryFactory/types';
import {RequiredBy} from 'Common/types';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {
  convertToServer,
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/Traits/TraitForm/validation';
import {IAdminTrait} from 'Admin/AdminPhenotypes/models/IAdminTrait';
import {IUpdateTraitRequest} from 'Admin/AdminPhenotypes/service/models/IServerTrait';
import {castToOption, getStringKeysOption} from 'Common/helpers/OptionHelper';
import {GeneType} from 'Admin/AdminPhenotypes/constants/GeneType';
import {ITest} from 'Dictionaries/models/ITest';
import {useDictionaries} from 'Common/store/useDictionaries';

const typeOptions = getStringKeysOption(GeneType);

interface IExternalDictionaries {
  testDictionary: ISimpleDictionary<ITest>;
}

interface IExternalProps extends IExternalDictionaries {
  itemId: IEntity['id'] | null;
  type: FormType;
  onSuccess(): void;
  dictionary: IDictionary<IAdminTrait, IUpdateTraitRequest>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type Props = FormikProps<IFormValues> & OuterProps;

function TraitForm(props: Props) {
  const {itemId, item, dictionary, testDictionary, tests, testsLoading, ...rest} = props;
  const {getItem} = dictionary.actions;

  const {
    actions: {getItems: getTests},
  } = testDictionary;

  useEffect(() => {
    getTests();
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem, getTests]);

  return (
    <DictionaryForm {...rest}>
      <InputField name="name" label="Name" placeholder="Name" />
      <SelectField name="state" label="State" placeholder="State" options={typeOptions} className="w-50" />
      <SelectField name="test" label="Test" options={castToOption(tests)} />
      <InputField name="positionIndex" type="number" label="Position" placeholder="Position in Online report" />
      <TextAreaField name="description.value" label="Description" placeholder="Description" />
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {testDictionary} = props;
  const {selectors: testSelectors} = testDictionary;

  return {
    ...makePhenotypeFormSelectors(state, props.dictionary),
    tests: testSelectors.selectItems(state),
    testsLoading: testSelectors.selectCommunication(state, 'itemsLoading'),
  };
};

const TraitFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting ? {...item, test: item.test?.id || null} : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServer(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(TraitForm);

const connector = connect(mapStateToProps);
const Connected = connect(mapStateToProps)(memo(TraitFormFormik));
const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {tests} = useDictionaries();

  return <Connected {...externalProps} testDictionary={tests} />;
};

export default Exported;
