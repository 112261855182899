import React from 'react';

import {IAdminActionsProps} from 'Admin/AdminDashboard/helpers/hooks/common';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {useUpdateUserNotificationModal} from './parts/useUpdateUserNotificationModal';
import {useAdministratorModal} from './parts/useAdministratorModal';
import {useUpdateAdministratorPermissionsModal} from './parts/useUpdateAdministratorPermissionsModal';
import {useUpdateAdministratorPasswordModal} from 'Admin/AdminAdvancedDashboard/helpers/hooks/useAdministratorActions/parts/useUpdateAdministratorPasswordModal';

type ActionHandler = Pick<IAdminActionsProps, 'communication'>;
interface IUserActionsPartsProps {
  updateUserInfoHandler: ActionHandler;
}

interface IProps {
  data: IUserActionsPartsProps;
  onSuccess(): void;
}

export function useAdministratorActions(props: IProps) {
  const {
    onSuccess,
    data: {updateUserInfoHandler}
  } = props;

  const {
    createAdministratorModal,
    openCreateAdministratorModal,
    openUpdateAdministratorModal,
    updateAdministratorModal
  } = useAdministratorModal({onSuccess});
  const {openUpdateUserNotificationModal, updateUserNotificationModal} = useUpdateUserNotificationModal({onSuccess});
  const {
    openUpdateAdministratorPermissionsModal,
    updateAdministratorPermissionsModal
  } = useUpdateAdministratorPermissionsModal({onSuccess});

  const {updateAdministratorPasswordModal, openUpdateAdministratorPasswordModal} = useUpdateAdministratorPasswordModal({
    onSuccess
  });

  useCommunicationToToast(
    updateUserInfoHandler.communication,
    'Administrator profile successfully updated',
    undefined,
    3
  );

  const userActionsModal = (
    <>
      {createAdministratorModal}
      {updateAdministratorModal}
      {updateUserNotificationModal}
      {updateAdministratorPermissionsModal}
      {updateAdministratorPasswordModal}
    </>
  );

  return {
    userActionsModal,
    openUpdateAdministratorModal,
    openCreateAdministratorModal,
    openUpdateUserNotificationModal,
    updateAdministratorModal,
    createAdministratorModal,
    updateUserNotificationModal,
    openUpdateAdministratorPermissionsModal,
    openUpdateAdministratorPasswordModal,
    updateAdministratorPasswordModal
  };
}
