import {Reducer} from 'redux';

import {IHorseFiltersState, Actions, getAllGenePoolsActionTypes} from '../types';

const initialState: IHorseFiltersState['data'] = {
  genePools: null
};

export const dataGenePoolsReducer: Reducer<IHorseFiltersState['data'], Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case getAllGenePoolsActionTypes.SUCCESS: {
      return {...state, genePools: action.payload};
    }
    default: {
      return state;
    }
  }
};
