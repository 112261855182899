import React, {useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import OwnerShortInfoSection from './parts/OwnerShortInfoSection';
import {actions, BusinessPortalOwnerModule, selectors} from 'BusinessPortal/store/owner';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import OwnerGeneralInfoSection from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/parts/OwnerGeneralInfoSection/OwnerGeneralInfoSection';
import OwnerHorseSection from './parts/OwnerHorseSection';
import withDynamicModules from 'Common/helpers/withDynamicModules';

interface IExternalProps {
  ownerId: number;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OwnerFormEditProps = IConnected & IExternalProps;

const OwnerFormEdit = (props: OwnerFormEditProps) => {
  const {ownerDetails, getOwnerDetails, ownerDetailsLoading, ownerId, onSuccess, resetOwnerDetails} = props;

  const mainRequest = useCallback(() => {
    getOwnerDetails(ownerId);
  }, [getOwnerDetails, ownerId]);

  const onSuccessEdit = useCallback(() => {
    mainRequest();
    onSuccess && onSuccess();
  }, [mainRequest, onSuccess]);

  useEffect(() => {
    mainRequest();
    return () => resetOwnerDetails();
  }, [mainRequest, resetOwnerDetails]);

  const isRequesting = ownerDetailsLoading.isRequesting;

  return (
    <div className="position-relative">
      {isRequesting && <Loading />}
      <OwnerShortInfoSection ownerDetails={ownerDetails} onSuccess={onSuccessEdit} />
      <OwnerGeneralInfoSection ownerDetails={ownerDetails} onSuccess={onSuccessEdit} />
      <OwnerHorseSection ownerDetails={ownerDetails} onSuccessHorseForm={onSuccessEdit} />
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  ownerDetails: selectors.selectOwnerDetails(state),
  ownerDetailsLoading: selectors.selectCommunication(state, 'ownerDetailsLoading'),
});

const mapDispatchToProps = {
  getOwnerDetails: actions.getOwnerDetails,
  resetOwnerDetails: actions.resetOwnerDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(OwnerFormEdit), [BusinessPortalOwnerModule]);
