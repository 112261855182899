import {IModule} from 'redux-dynamic-modules';

import {IBuildFoalState} from './types';
import {buildFoalReducer} from './reducer';

export interface IBuildFoalModuleState {
  buildFoal: IBuildFoalState;
}

export const BuildFoalModule: IModule<IBuildFoalModuleState> = {
  id: 'buildFoal',
  reducerMap: {
    buildFoal: buildFoalReducer
  }
};
