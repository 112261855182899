import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';
import {
  AdminAssociationOwnerActions,
  createAdminAssociationOwnerActionTypes,
  deleteAdminAssociationOwnerActionTypes,
  deleteAdminAssociationOwnerAvatarActionTypes,
  getAdminAssociationOwnerDetailsActionTypes,
  getAdminAssociationOwnersActionTypes,
  IAdminAssociationOwnerState,
  resetAdminAssociationOwnerDetailsActionTypes,
  updateAdminAssociationOwnerActionTypes,
  updateAdminAssociationOwnerAvatarActionTypes,
} from '../types';

export const communicationAdminAssociationOwnerReducer = combineReducers<
  IAdminAssociationOwnerState['communications'],
  AdminAssociationOwnerActions
>({
  ownersLoading: makeCommunicationReducerFromEnum(getAdminAssociationOwnersActionTypes),
  ownerCreating: makeCommunicationReducerFromEnum(createAdminAssociationOwnerActionTypes),
  ownerUpdating: makeCommunicationReducerFromEnum(updateAdminAssociationOwnerActionTypes),
  ownerDetailsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getAdminAssociationOwnerDetailsActionTypes),
    makeResetCommunicationReducer(resetAdminAssociationOwnerDetailsActionTypes.RESET),
  ]),
  ownerAvatarUpdating: makeCommunicationReducerFromEnum(updateAdminAssociationOwnerAvatarActionTypes),
  ownerAvatarDeleting: makeCommunicationReducerFromEnum(deleteAdminAssociationOwnerAvatarActionTypes),
  ownerDeleting: makeCommunicationReducerFromEnum(deleteAdminAssociationOwnerActionTypes),
});
