import React, {memo, useMemo} from 'react';
import styled from 'styled-components';
import moment from 'moment';

import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import Typography from 'Common/constants/Typography';
import {FRONTEND_DATE3} from 'Common/constants/Date';
import {DiscountType} from 'Coupon/constants/DiscountType';
import {withCurrencyOrPercent} from 'Common/helpers/withCurrency';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {breakpoints} from 'Common/constants/Breakpoints';

const CouponLayout = styled.div`
  width: 342px;
  height: 138px;
  border-radius: 4px;
  background: linear-gradient(247.18deg, ${ColorPalette.yellow3} 5.03%, ${ColorPalette.yellow4} 92.86%),
    linear-gradient(180deg, ${ColorPalette.yellow0} 0%, ${ColorPalette.yellow5} 53.63%, #ffffff 53.64%);
  box-shadow: 0px 8px 14px ${ColorPalette.gray12}, 0px -4px 10px ${ColorPalette.transparent3};

  @media ${breakpoints.sm} {
    width: 390px;
  }
`;

const IconBackground = styled(ColoredIcon)`
  width: 337px;
  height: 155px;
  left: 100px;
  top: -24px;

  @media ${breakpoints.sm} {
    left: 116px;
  }
`;

const Separator = styled.div`
  width: 327px;
  height: 0;
  left: 9px;
  top: 50%;
  transform: translateY(-50%);
  border-top: 2px dashed ${ColorPalette.gray12};

  @media ${breakpoints.sm} {
    width: 373px;
  }
`;

const Ellipse = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${ColorPalette.white0};
`;

const EllipseLeft = styled(Ellipse)`
  top: 50%;
  transform: translateY(-50%);
  left: -8px;
`;

const EllipseRight = styled(Ellipse)`
  top: 50%;
  transform: translateY(-50%);
  right: -8px;
`;

const CloseButton = styled.div`
  top: 3px;
  right: 11px;
  z-index: 3;
`;

const ContentRow = styled.div`
  display: grid;
  grid-template-rows: 50% 50%;
  width: 100%;
  height: 100%;
`;

const ContentColumn = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  height: 100%;
`;

const BaseDiscountUses = styled.div`
  font-family: ${Typography.family.openSans};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size23};
  margin-left: 32px;
`;

const Discount = styled(BaseDiscountUses)`
  color: ${ColorPalette.black1};
`;

const Uses = styled(BaseDiscountUses)`
  color: ${ColorPalette.white0};
`;

const PackageLayout = styled.div`
  margin-left: 9px;
  font-family: ${Typography.family.openSans};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  max-height: 70px;
  overflow: hidden;
  z-index: 2;
`;

const ValidUntilLayout = styled.div`
  justify-self: end;
  margin-right: 16px;
  margin-bottom: 12px;
`;

const BaseValidUntil = styled.div`
  font-family: ${Typography.family.openSans};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size10};
  line-height: 16px;
`;

const ValidUntilCaption = styled(BaseValidUntil)`
  color: ${ColorPalette.black4};
`;

const ValidUntilDate = styled(BaseValidUntil)`
  color: ${ColorPalette.black1};
`;

interface IProps {
  amount: number;
  discountType: DiscountType;
  usesLeft: number;
  expiryDate: string;
  packages?: string[];
  onClose?: () => void;
}

function Couponcard(props: IProps) {
  const {amount, discountType, usesLeft, expiryDate, packages, onClose} = props;

  function renderPackages() {
    if (!packages || packages.length === 0) {
      return <div className="d-flex flex-column align-self-center">For all packages and tests</div>;
    }

    const tooltipText = (
      <div className="d-flex flex-column align-self-center">
        {packages.map((pack, ind) => (
          <span key={ind}>{pack}</span>
        ))}
      </div>
    );

    return (
      <Tooltip content={tooltipText}>
        <PackageLayout className="d-flex flex-column align-self-center">
          {packages.map((pack, ind) => (
            <span key={ind}>{pack}</span>
          ))}
        </PackageLayout>
      </Tooltip>
    );
  }

  const validUntil = useMemo(() => {
    return expiryDate ? moment(new Date(expiryDate)).format(FRONTEND_DATE3) : '';
  }, [expiryDate]);

  return (
    <CouponLayout className="position-relative">
      <IconBackground
        name={IconName.EtalonHorseLogo}
        className="position-absolute"
        color={ColorPalette.white4}
        fill={true}
        stroke={false}
      />
      <Separator className="position-absolute" />
      <EllipseLeft className="position-absolute" />
      <EllipseRight className="position-absolute" />

      {onClose && (
        <CloseButton className="position-absolute">
          <IconButton
            name={IconName.Close}
            onClick={onClose}
            color={ColorPalette.black1}
            fill={true}
            stroke={false}
            size={10}
          />
        </CloseButton>
      )}

      <ContentRow>
        <ContentColumn>
          <Discount className="d-flex align-items-center">{withCurrencyOrPercent(amount, discountType)} OFF</Discount>
          {renderPackages()}
        </ContentColumn>
        <ContentColumn>
          <Uses className="d-flex align-items-center">{usesLeft !== 0 ? `${usesLeft} Uses left` : ''}</Uses>
          <ValidUntilLayout className="d-flex flex-column align-self-end">
            <ValidUntilCaption>Valid until:</ValidUntilCaption>
            <ValidUntilDate>{validUntil}</ValidUntilDate>
          </ValidUntilLayout>
        </ContentColumn>
      </ContentRow>
    </CouponLayout>
  );
}

export default memo(Couponcard);
