import React, {memo, useMemo, useCallback} from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import {ActionMeta, PropsValue} from 'react-select/dist/declarations/src/types';
import {FilterOptionOption} from 'react-select/dist/declarations/src/filters';

import Select, {SelectProps} from 'Common/components/Controls/Select/Select';
import {IFieldProps} from 'Common/models/IFieldProps';
import {ValidationError} from 'Common/components/FormFields';
import {IOption} from 'Common/models/IOption';
import withField from './withField';
import {Nullable} from 'Common/types';

const InputLayout = styled.div`
  position: relative;
`;

interface IOwnProps {
  isFieldTouchedOnChange?: boolean;
  onClear?(): void;
  sourceField?: keyof IOption;
  isCaseSensitive?: boolean;
  defaultValue?: IOption;
}

interface IFilterOption extends IOption {
  abbreviation?: Nullable<string>;
}

type IProps = IOwnProps & SelectProps<IFilterOption, boolean> & IFieldProps<string[]>;

function SelectField(props: IProps) {
  const {
    field: {name, value: values},
    form,
    isFieldTouchedOnChange,
    onClear,
    sourceField = 'value',
    isCaseSensitive = true,
    ...rest
  } = props;

  const onChange = React.useCallback(
    (option?: PropsValue<IFilterOption>, action?: ActionMeta<IFilterOption>) => {
      const fieldValue = (() => {
        if (Array.isArray(option)) {
          return (option as IFilterOption[]).map((item) => item[sourceField]);
        }
        return (option as IFilterOption | null)?.[sourceField] ?? props.defaultValue;
      })();
      form.setFieldValue(name, fieldValue);
      isFieldTouchedOnChange && form.setFieldTouched(name);
      if (action?.action === 'clear') {
        onClear && onClear();
      }
    },
    [form, sourceField, props.defaultValue, name, isFieldTouchedOnChange, onClear]
  );

  const selectedValue = useMemo(() => {
    if (R.isEmpty(props.options) || R.isEmpty(values) || R.isNil(values)) {
      return null;
    }
    const options = props.options! as IFilterOption[];

    if (!Array.isArray(values)) {
      const result = options.find((option) => {
        const convertedOption = isCaseSensitive ? option[sourceField] : option[sourceField].toString().toLowerCase();
        const convertedValue = isCaseSensitive ? values : String(values).toLowerCase();
        return convertedOption === convertedValue;
      });
      return result as PropsValue<IFilterOption>;
    }
    const result = values
      .map((value) => options.find((item) => String(item[sourceField]) === String(value)))
      .filter<IFilterOption>((option): option is IFilterOption => !!option);
    return result as PropsValue<IFilterOption>;
  }, [props.options, values, sourceField, isCaseSensitive]);

  const filterOption = React.useCallback((option: FilterOptionOption<IFilterOption>, inputValue: string) => {
    return inputValue
      .split(' ')
      .every(
        (word) =>
          option.label.toLowerCase().includes(word.toLowerCase()) ||
          option.data.abbreviation?.toLowerCase().includes(word.toLowerCase())
      );
  }, []);

  const onBlur = useCallback(() => {
    form.setFieldTouched(name);
  }, [form, name]);

  const errorClass = form.touched[name] && form.errors[name] ? 'input-has-error' : '';

  return (
    <InputLayout>
      <Select
        {...rest}
        value={selectedValue}
        filterOption={filterOption}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        inputClassName={errorClass}
      />
      <ValidationError name={name} withoutLifting={true} />
    </InputLayout>
  );
}

export default memo(withField(SelectField, true));
