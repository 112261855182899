import {IModule} from 'redux-dynamic-modules';

import {ISubmissionFormState} from './types';
import {submissionFormReducer} from './reducer';

export interface ISubmissionFormModuleState {
  submissionForm: ISubmissionFormState;
}

export const SubmissionFormModule: IModule<ISubmissionFormModuleState> = {
  id: 'submissionForm',
  reducerMap: {
    submissionForm: submissionFormReducer
  }
};
