import {IHorseAdmin, IHorseDetails} from 'Admin/AdminDashboard/models/Horse';
import {IServerHorseAdmin, IServerHorseDetailsAdmin} from 'Admin/AdminDashboard/services/types/horse';
import {convertServerOrderHistoryToClient} from 'Admin/AdminDashboard/services/converters/common/orderHistory';
import {withAvatar} from 'Common/helpers/withAvatar';
import {withUserName} from 'Common/helpers/withUserName';

export function convertHorseDetailsToClient(horseDetails: IServerHorseDetailsAdmin): IHorseDetails {
  return {
    ...withAvatar(horseDetails),
    orders: horseDetails.orders.map(convertServerOrderHistoryToClient),
  };
}

export function convertHorseToClient(horse: IServerHorseAdmin): IHorseAdmin {
  return {...withAvatar(horse), owner: {...withUserName(withAvatar(horse.owner))}};
}
