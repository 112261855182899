import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';
import {
  addAvatarActionTypes,
  addImagesToGalleryActionTypes,
  addVideoToGalleryActionTypes,
  deleteAvatarActionTypes,
  deleteMediaFromGalleryActionTypes,
  GalleryActions,
  getGalleryActionTypes,
  IGalleryState,
} from '../types';

export const communicationReducer = combineReducers<IGalleryState['communications'], GalleryActions>({
  galleryLoading: makeCommunicationReducerFromEnum(getGalleryActionTypes),
  galleryUpdating: composeReducers([
    makeCommunicationReducerFromEnum(addImagesToGalleryActionTypes),
    makeCommunicationReducerFromEnum(addVideoToGalleryActionTypes),
    makeCommunicationReducerFromEnum(deleteMediaFromGalleryActionTypes),
  ]),
  avatarUpdating: makeCommunicationReducerFromEnum(addAvatarActionTypes),
  avatarDeleting: makeCommunicationReducerFromEnum(deleteAvatarActionTypes),
});
