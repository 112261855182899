import {combineReducers} from 'redux';

import {
  getUserPrintableReportPreferencesActionTypes,
  IUserPrintableReportPreferencesState,
  updateUserPrintableReportSummaryVisibilityActionTypes,
  hideUserPrintableReportPreferencesActionTypes,
  showUserPrintableReportPreferencesActionTypes,
  UserPrintableReportPreferencesActions,
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationUserPrintableReportPreferencesReducer = combineReducers<
  IUserPrintableReportPreferencesState['communications'],
  UserPrintableReportPreferencesActions
>({
  userPrintableReportPreferencesLoading: makeCommunicationReducerFromEnum(getUserPrintableReportPreferencesActionTypes),
  updateUserPrintableReportSummaryVisibilitySubmitting: makeCommunicationReducerFromEnum(
    updateUserPrintableReportSummaryVisibilityActionTypes
  ),
  hideUserPrintableReportPreferencesSubmitting: makeCommunicationReducerFromEnum(
    hideUserPrintableReportPreferencesActionTypes
  ),
  showUserPrintableReportPreferencesSubmitting: makeCommunicationReducerFromEnum(
    showUserPrintableReportPreferencesActionTypes
  ),
});
