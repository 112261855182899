import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminSubscriptions;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectSubscriptions(state: IAppState) {
  return selectState(state).data.subscriptions;
}
