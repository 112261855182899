import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {IShortDescriptionEntity} from 'Common/models/IDescriptionEntity';
import {IImage} from 'Image/models/IImage';
import {IIndexed} from 'Common/models/IIndexed';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IImaged} from 'Common/models/IImaged';

export interface IFormValues extends IShortDescriptionEntity, IIndexed, IImaged {
  id?: IEntity['id'];
  name: string;
}

export const initialValue: IFormValues = {
  name: '',
  description: {value: ''},
  positionIndex: 0,
  image: null,
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  image: Yup.mixed<IImage>().nullable(),
  positionIndex: Yup.number().required(REQUIRED_FIELD),
});
