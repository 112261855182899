import React, {memo} from 'react';
import styled from 'styled-components';

import ProbabilityItem, {IProps as ProbabilityItemProps} from './ProbabilityItem';
import ColorPalette from 'Common/constants/ColorPalette';
import Tooltip, {TooltipProps} from 'Common/components/Tooltip/Tooltip';
import Icon, {IconName} from 'Icon/components/Icon';

const SelectableItem = styled.div`
  width: 100%;
  display: inline-block;
  margin-bottom: 8px;
`;

const ClickableZone = styled.div<{isActive: boolean; isNotSelectable: boolean}>`
  width: 100%;
  min-height: 21px;
  max-height: 48px;
  height: 100%;
  margin-left: -8px;
  top: 0px;
  ${({isNotSelectable, isActive}) =>
    !isNotSelectable && `border-left: 4px solid ${isActive ? ColorPalette.yellow1 : ColorPalette.gray2};`}
  ${({isActive}) => isActive && `background: ${ColorPalette.yellow7};`}

  &:hover {
    background: ${ColorPalette.yellow7};
    cursor: ${({isNotSelectable}) => (isNotSelectable ? 'default' : 'pointer')};
  }

  :focus {
    outline: none;
  }
`;

const IconWrapper = styled.div`
  top: -2px;
  left: -28px;
`;

interface IProps {
  isActive: boolean;
  tooltipConfig?: TooltipProps;
  isNotSelectable?: boolean;
  isNotSelectableDescription?: string;

  onClick(): void;
  onMouseEnter(): void;
  onMouseLeave(): void;
}

type Props = IProps & ProbabilityItemProps;

const SelectableProbabilityItem = (props: Props) => {
  const {
    higherIsBetter,
    probability,
    isActive,
    onClick,
    onMouseEnter,
    onMouseLeave,
    alignValueRight,
    tooltipConfig,
    isNotSelectable = false,
    isNotSelectableDescription
  } = props;

  return (
    <SelectableItem className="position-relative">
      <Tooltip
        {...tooltipConfig}
        content={isNotSelectable && isNotSelectableDescription ? isNotSelectableDescription : probability.description}
      >
        <ClickableZone
          className="position-absolute"
          isActive={isActive}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          isNotSelectable={isNotSelectable}
        />
      </Tooltip>

      {!probability.isFullInfo && (
        <IconWrapper className="position-absolute">
          <Icon name={IconName.ExclamationMark} height={16} width={16} />
        </IconWrapper>
      )}

      <ProbabilityItem
        probability={probability}
        higherIsBetter={higherIsBetter}
        alignValueRight={alignValueRight}
        showTooltip={false}
      />
    </SelectableItem>
  );
};

export default memo(SelectableProbabilityItem);
