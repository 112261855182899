export function disableScrolling() {
  document.body.style.overflow = 'hidden';
}

export function enableScrolling() {
  document.body.style.overflow = 'visible';
}

export function disableScrollingWithCoordinate(coordinate: 'x' | 'y') {
  if (coordinate === 'x') {
    document.body.style.overflowX = 'hidden';
    return;
  }

  document.body.style.overflowY = 'hidden';
}

export function enableScrollingWithCoordinate(coordinate: 'x' | 'y') {
  if (coordinate === 'x') {
    document.body.style.overflowX = 'visible';
    return;
  }

  document.body.style.overflowY = 'visible';
}
