import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Form, FormikProps, withFormik} from 'formik';
import {DynamicModuleLoader} from 'redux-dynamic-modules';

import GeneralInformation from 'Admin/AdminDashboard/components/Users/UserFullInfoUpdate/parts/GeneralInformation';
import PhoneNumbers from 'Admin/AdminDashboard/components/Users/UserFullInfoUpdate/parts/PhoneNumbers';
import AccountInfo from 'Admin/AdminDashboard/components/Users/UserFullInfoUpdate/parts/AccountInfo';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import * as imageActions from 'Image/store/actions';
import {IAssociation} from 'Dictionaries/models/IAssociation';
import Loading from 'Loading/components/Loading';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {
  actions,
  AdminUserFullInfoUpdateModule,
  selectors,
} from 'Admin/AdminDashboard/store/adminUsers/userFullInfoUpdate/index';
import {
  IUserFormValues,
  UserFullInfoUpdateInitialValue,
  UserFullInfoUpdateSchema,
} from 'Admin/AdminDashboard/components/Users/UserFullInfoUpdate/UserFullInfoUpdateValidation';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {IAppState} from 'Common/store/IAppState';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import PersonalPrivacySettings from './parts/PersonalPrivacySettings';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import UserMapField from 'UserProfile/components/shared/UserMapField/UserMapField';
import {
  withUserMapFieldPropsToClient,
  withUserMapFieldPropsToRequest,
} from 'UserProfile/components/shared/UserMapField/withUserMapFieldProps';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import {useDictionaries} from 'Common/store/useDictionaries';

interface IExternalDictionaries {
  associationDictionary: ISimpleDictionary<IAssociation>;
}

interface IExternalProps extends IExternalDictionaries {
  userId: number;
  onSuccess?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IUserFormValues> & OuterProps;

const UserFullInfoUpdateFormik = (props: AllProps) => {
  const {userId, getUser, userLoading, userUpdating, resetUser, associationDictionary} = props;
  const {setErrors, setStatus, status, isSubmitting, onSuccess} = props;
  const {isPublicEmail, isPublicPhone, isPublicLocation} = props.values.privacySettings;

  const {
    actions: {getItems: getAssociations},
  } = associationDictionary;

  const errorInfo = userLoading?.error || userUpdating?.error;

  useOnSuccessCommunication(userUpdating, () => onSuccess && onSuccess());

  useEffect(() => {
    return resetUser;
  }, [resetUser]);

  useEffect(() => {
    getAssociations();
  }, [getAssociations]);

  useEffect(() => {
    if (userId) {
      getUser(userId);
    }
  }, [getUser, userId]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    const fieldErrors = getFieldErrors(errorInfo);

    if (commonErrors) {
      setStatus(commonErrors);
    }

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  const isPreloading = userLoading.isRequesting;
  const isLoading = isSubmitting || userUpdating.isRequesting;

  return (
    <>
      <ModalWindowHeader>Update profile</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {isPreloading && <Loading />}
          <GeneralInformation />
          <AccountInfo />
          <PhoneNumbers />

          <FormControlContainer title="Billing Address">
            <UserMapField />
          </FormControlContainer>

          <PersonalPrivacySettings
            title="Visibility and privacy settings"
            personalPrivacySettings={{isPublicEmail, isPublicPhone, isPublicLocation}}
            fieldNames={{
              isPublicEmail: 'privacySettings.isPublicEmail',
              isPublicPhone: 'privacySettings.isPublicPhone',
              isPublicLocation: 'privacySettings.isPublicLocation',
            }}
          />
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" isLoading={isLoading} disabled={isPreloading}>
            Save profile
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const initialValue = UserFullInfoUpdateInitialValue;
const validationSchema = UserFullInfoUpdateSchema;

const UserFullInfoUpdateWithForm = withFormik<OuterProps, IUserFormValues>({
  mapPropsToValues: ({user}) => (user ? withUserMapFieldPropsToClient(user) : initialValue),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    const {userId} = formikBag.props;

    try {
      if (values.avatar?.id === 0 && values.avatar?.file) {
        const formData = new FormData();
        formData.append('uploadedFile', values.avatar.file);
        const image = await formikBag.props.uploadImage(formData, true, userId);
        values.avatar = image;
      }
    } catch (error) {
      values.avatar = {id: 0, url: ''};
    } finally {
      await formikBag.props.updateUser(withUserMapFieldPropsToRequest(userId, values));
      formikBag.setSubmitting(false);
    }
  },
  enableReinitialize: true,
})(UserFullInfoUpdateFormik);

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {associationDictionary} = props;
  const {selectors: associationSelectors} = associationDictionary;

  return {
    user: selectors.selectUserFullInfo(state),
    userLoading: selectors.selectCommunication(state, 'userLoading'),
    userUpdating: selectors.selectCommunication(state, 'userUpdating'),
    associations: associationSelectors.selectItems(state),
  };
};

const mapDispatchToProps = {
  getUser: actions.getUserFullInfo,
  updateUser: actions.updateUser,
  uploadImage: imageActions.uploadImage,
  resetUser: actions.resetUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(React.memo(UserFullInfoUpdateWithForm));
const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {associations} = useDictionaries();

  return (
    <DynamicModuleLoader modules={[AdminUserFullInfoUpdateModule]}>
      <Connected {...externalProps} associationDictionary={associations} />
    </DynamicModuleLoader>
  );
};

export default Exported;
