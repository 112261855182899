import {IModule} from 'redux-dynamic-modules';

import {IFindHorseState} from './types';
import {reducer} from './reducer';

export interface IFindHorseModuleState {
  findHorse: IFindHorseState;
}
export const FindHorseModule: IModule<IFindHorseModuleState> = {
  id: 'findHorse',
  reducerMap: {
    findHorse: reducer
  }
};
