import React from 'react';
import styled from 'styled-components';

import {parseHorseHeight} from 'Common/helpers/parseHorseHeight';
import {HeightUnits} from 'HorseProfile/constants/HeightUnits';
import {LabelBase, ValueBase} from 'UserProfile/components/TabsData/common/styled';
import {breakpoints} from 'Common/constants/Breakpoints';
import {mediumMdSmBreakpoint} from 'UserProfile/components/TabsData/common/constants';
import {Nullable} from 'Common/types';

const Root = styled.div<{isMulti: boolean}>`
  flex-direction: column;

  ${(props) => props.isMulti && 'flex-direction: column;'}

  @media ${mediumMdSmBreakpoint} {
    flex-direction: ${(props) => (props.isMulti ? 'column' : 'row')};
  }
`;

const ItemWrapper = styled.div`
  margin-right: 0;

  @media ${breakpoints.sm} {
    margin-right: 20px;
  }
`;

const ColorsWrapper = styled.div<{isMulti: boolean}>`
  ${(props) => props.isMulti && 'margin-bottom: 16px;'}
`;

const ColorLabel = styled(LabelBase)<{isMulti: boolean}>`
  @media ${mediumMdSmBreakpoint} {
    text-align: ${(props) => (props.isMulti ? 'right' : 'left')};
    ${(props) => !props.isMulti && 'min-width: 28px;'}
    width: 35px;
  }
`;

const SimpleValue = styled(ValueBase)<{width: number}>`
  width: 100%;
  max-height: fit-content;
  text-align: right;
  overflow: hidden;
  margin: -10px 0 0 6px;
  line-height: 30px;

  @media ${breakpoints.sm} {
    width: ${(props) => props.width}px;
    text-align: left;
  }
`;

interface IProps {
  height: Nullable<number>;
  heightUnit: HeightUnits;
  colors: string[];
}

const SimpleContent = (props: IProps) => {
  const {colors, height, heightUnit} = props;

  return (
    <Root className="d-flex" isMulti={colors.length > 1}>
      <ItemWrapper className="d-flex flex-row">
        <LabelBase>Height</LabelBase>
        <SimpleValue width={100}>{height ? parseHorseHeight(height, heightUnit) : '-'}</SimpleValue>
      </ItemWrapper>
      <ItemWrapper className="d-flex flex-row">
        <ColorLabel isMulti={colors.length > 1}>Color</ColorLabel>

        {colors.length > 0 ? (
          <ColorsWrapper className="d-flex flex-column w-100" isMulti={colors.length > 1}>
            {colors.map((color, i) => (
              <SimpleValue key={i} width={140}>
                {color}
              </SimpleValue>
            ))}
          </ColorsWrapper>
        ) : (
          <SimpleValue width={140}>-</SimpleValue>
        )}
      </ItemWrapper>
    </Root>
  );
};

export default SimpleContent;
