import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import GalleryService from 'Gallery/services/GalleryService';
import {IAppState} from 'Common/store/IAppState';

import {
  addAvatarActionTypes,
  addImagesToGalleryActionTypes,
  addVideoToGalleryActionTypes,
  deleteAvatarActionTypes,
  deleteMediaFromGalleryActionTypes,
  GalleryActions,
  getGalleryActionTypes,
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IImage} from 'Image/models/IImage';
import {IVideo} from 'Video/models/IVideo';
import {IMediaResource} from 'Common/models/IMediaResource';

export const getGallery =
  (horseId: number, isAdmin?: boolean): ActionResult<IAppState, void, GalleryActions> =>
  async (dispatch) => {
    dispatch(action(getGalleryActionTypes.REQUEST));
    try {
      const media = await GalleryService.getGallery(horseId, isAdmin);
      dispatch(action(getGalleryActionTypes.SUCCESS, media));
    } catch (err) {
      dispatch(action(getGalleryActionTypes.FAILURE, getErrorMessage(err)));
    }
  };

export const addAvatar =
  (horseId: number, image: IImage, isAdmin?: boolean): ActionResult<IAppState, void, GalleryActions> =>
  async (dispatch) => {
    dispatch(action(addAvatarActionTypes.REQUEST));
    try {
      await GalleryService.addAvatar(horseId, image.file!, isAdmin);
      dispatch(action(addAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(addAvatarActionTypes.FAILURE, getErrorMessage(error)));
      // need for horse form submit
      throw getErrorMessage(error);
    }
  };

export const addImages =
  (horseId: number, images: IMediaResource[], isAdmin?: boolean): ActionResult<IAppState, void, GalleryActions> =>
  async (dispatch) => {
    dispatch(action(addImagesToGalleryActionTypes.REQUEST));
    try {
      const files = images.filter((x) => x.file && x.file !== undefined).map((img) => img.file!);
      await GalleryService.addImages(horseId, files, isAdmin);
      dispatch(action(addImagesToGalleryActionTypes.SUCCESS, images));
    } catch (error) {
      dispatch(action(addImagesToGalleryActionTypes.FAILURE, getErrorMessage(error)));
      // need for horse form submit
      throw getErrorMessage(error);
    }
  };

export const addVideo =
  (horseId: number, video: IVideo, isAdmin?: boolean): ActionResult<IAppState, void, GalleryActions> =>
  async (dispatch) => {
    dispatch(action(addVideoToGalleryActionTypes.REQUEST));
    try {
      await GalleryService.addVideo(horseId, video, isAdmin);
      dispatch(action(addVideoToGalleryActionTypes.SUCCESS, video));
    } catch (error) {
      dispatch(action(addVideoToGalleryActionTypes.FAILURE, getErrorMessage(error)));
      // need for horse form submit
      throw getErrorMessage(error);
    }
  };

export const deleteAvatar =
  (horseId: number, isAdmin?: boolean): ActionResult<IAppState, void, GalleryActions> =>
  async (dispatch) => {
    dispatch(action(deleteAvatarActionTypes.REQUEST));
    try {
      await GalleryService.deleteAvatar(horseId, isAdmin);
      dispatch(action(deleteAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteAvatarActionTypes.FAILURE, getErrorMessage(error)));
      // need for horse form submit
      throw getErrorMessage(error);
    }
  };

export const deleteMediaFromGallery =
  (horseId: number, mediaIds: number[], isAdmin?: boolean): ActionResult<IAppState, void, GalleryActions> =>
  async (dispatch) => {
    dispatch(action(deleteMediaFromGalleryActionTypes.REQUEST));
    try {
      await GalleryService.deleteMedia(horseId, mediaIds, isAdmin);
      dispatch(action(deleteMediaFromGalleryActionTypes.SUCCESS, {horseId, mediaIds}));
    } catch (error) {
      dispatch(action(deleteMediaFromGalleryActionTypes.FAILURE, getErrorMessage(error)));
      // need for horse form submit
      throw getErrorMessage(error);
    }
  };
