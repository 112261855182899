import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {IDictionary} from 'DictionaryFactory/types';
import {IAppState} from 'Common/store/IAppState';
import {Table} from 'Common/components/Table/Table';
import Loading from 'Loading/components/Loading';
import {Pagination} from 'Common/components/Controls/index';
import {IEntity} from 'Common/models/IEntity';
import {memoWithGeneric} from 'Common/helpers/react/memoWithGeneric';
import {FormType} from 'Common/constants/FormType';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import {ErrorMessage as BaseErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';

import {
  IPhenotypesSelectors,
  makePhenotypeSelectors
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeSelectors';
import {OrderingType} from 'Common/constants/OrderingType';

export const CONFIRM_DELETE_ITEM = 'Are you sure you want to remove it?';
export const LOADING_ITEMS_ERROR = 'There is error on loading items';

export const AddItemButton = styled(PrimaryButton)`
  width: 176px;
  height: 50px;
  margin-left: 55px;
`;

export const Items = styled.div`
  position: relative;
  min-height: 100px;
  margin-top: 50px;
`;

const ErrorMessage = styled(BaseErrorMessage)`
  margin-top: 24px;
  text-align: center;
`;

export interface DictionaryFormProps<D> {
  itemId: IEntity['id'] | null;
  type: FormType;
  onSuccess(): void;
  dictionary: D;
}

interface IChildrenParams {
  onDelete(id: IEntity['id']): void;
  onEdit(id: IEntity['id']): void;
}

interface IOwnProps<D> {
  dictionary: D;
  DictionaryForm: React.ComponentType<DictionaryFormProps<D>>;
  children: (params: IChildrenParams) => React.ReactFragment;
  filterField?: string;
  disableCreateItem?: boolean;
}

function DictionaryLayout<T extends IEntity, C, D extends IDictionary<T, C>>(
  props: IOwnProps<D> & IPhenotypesSelectors<T>
) {
  const {
    children,
    dictionary,
    DictionaryForm,
    items,
    itemsLoading,
    itemDeleting,
    pagination,
    disableCreateItem,
    filterField = 'name'
  } = props;

  const {getItems, deleteItem, resetItem} = dictionary.actions;

  const {
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    deleteModal,
    selectedId,
    setSelectedId,
    reloadItems,
    openDeleteModal,
    searchBar
  } = useCommonAdminPageData<IEntity>({
    sortingAfterFilteringParams: {orderBy: filterField, orderingType: OrderingType.Ascending},
    getItems,
    deleteParams: {
      communication: itemDeleting,
      action: deleteItem,
      confirmDescription: CONFIRM_DELETE_ITEM
    }
  });

  const [isCreateItemModalOpen, setIsCreateItemModalOpen] = useState(false);
  const [isEditItemModalOpen, setIsEditItemModalOpen] = useState(false);

  const handleAddItem = () => {
    setSelectedId(null);
    setIsCreateItemModalOpen(true);
  };

  const handleEditItem = (id: number) => {
    setSelectedId(id);
    setIsEditItemModalOpen(true);
  };

  const closeModals = useCallback(() => {
    setIsCreateItemModalOpen(false);
    setIsEditItemModalOpen(false);
    resetItem();
  }, [resetItem]);

  const reloadAfterEditing = useCallback(() => {
    closeModals();
    reloadItems();
  }, [reloadItems, closeModals]);

  const isRequesting = itemsLoading.isRequesting || itemDeleting.isRequesting;

  const hasLoadingError = !itemsLoading.isRequesting && !!itemsLoading.error;

  const cells = (children({onDelete: openDeleteModal, onEdit: handleEditItem}) as React.ReactElement<
    React.PropsWithChildren<{}>
  >).props.children;

  return (
    <div className="position-relative">
      {deleteModal}
      <ModalWindow isOpen={isEditItemModalOpen} onClose={closeModals}>
        <DictionaryForm
          dictionary={dictionary}
          itemId={selectedId}
          type={FormType.edit}
          onSuccess={reloadAfterEditing}
        />
      </ModalWindow>
      <ModalWindow isOpen={isCreateItemModalOpen} onClose={closeModals}>
        <DictionaryForm dictionary={dictionary} itemId={null} type={FormType.create} onSuccess={reloadAfterEditing} />
      </ModalWindow>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
        {!disableCreateItem && <AddItemButton onClick={handleAddItem}>+ Add item</AddItemButton>}
      </div>
      <Items>
        {isRequesting && <Loading />}
        {items.length > 0 && (
          <>
            <Table<T> rowKey="id" data={items} sorting={sorting} onChangeSorting={changeSorting}>
              {cells}
            </Table>
            <Pagination
              pagination={pagination}
              onPageSelect={handlePageSelect}
              onChangeCountPerPage={handleCountPerPage}
            />
          </>
        )}
        {hasLoadingError && <ErrorMessage>{LOADING_ITEMS_ERROR}</ErrorMessage>}
      </Items>
    </div>
  );
}
const mapStateToProps = <T extends IEntity, C, D extends IDictionary<T, C>>(
  state: IAppState,
  ownProps: IOwnProps<D>
): IPhenotypesSelectors<T> => {
  return makePhenotypeSelectors(state, ownProps.dictionary);
};

export default connect(mapStateToProps)(memoWithGeneric(DictionaryLayout));
