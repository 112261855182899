import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {hexToRgba} from 'Common/helpers/hexToRgba';

export const Panel = styled.div<Partial<{height: string; width: string}>>`
  height: ${props => props.height};
  width: ${props => props.width};
  &:focus {
    outline: none;
  }
  background: ${hexToRgba(ColorPalette.gray49, 0.75)};
`;
