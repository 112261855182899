import React, {memo} from 'react';
import styled from 'styled-components';
import {RouteComponentProps, withRouter} from 'react-router-dom';

import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon, {IColoredIconProps as ColoredIconProps} from 'Icon/components/ColoredIcon';
import UserHorsesListItem from './UserHorsesListItem';
import CommonLink from 'Common/components/Controls/Links/CommonLink';
import {VisitorType} from 'Common/constants/VisitorType';
import {PREFIX_USER_PROFILE_TYPE, PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';
import {UserProfileType} from 'Shared/models/UserProfileType';
import {IOwnerHorse} from 'Shared/models/IOwnerHorse';

const UserHorses = styled.div`
  background-color: ${ColorPalette.gray34};
  border-radius: 0 0 5px 5px;
  width: calc(100% + 40px);
  position: relative;
  top: 0;
  left: -20px;
  padding: 16px 20px 24px 20px;
`;

const Title = styled.div`
  font-size: ${Typography.size.size13};
  color: ${ColorPalette.gray3};
  margin: 0px 7px 16px 7px;
`;

const ShowAll = styled(CommonLink)`
  font-weight: ${Typography.weight.bold700};
  font-size: ${Typography.size.size13};
  margin: 17px 0 0 7px;
  cursor: pointer;
  height: 32px;
  width: 100px;
  color: ${ColorPalette.blue0};

  :hover {
    color: ${ColorPalette.blue0};
    opacity: 0.9;
  }
`;

interface IProps {
  userHorses: IOwnerHorse[];
  ownerId: number;
  userName: string;
  type: VisitorType;
  isGeneralUser: boolean;
}

type Props = IProps & RouteComponentProps;

const UserHorsesList = (props: Props) => {
  const {userHorses, ownerId, type: visitorType, isGeneralUser} = props;

  const showAllButtonProps: ColoredIconProps = {
    name: IconName.ShowAll,
    color: ColorPalette.blue0,
    fill: true,
    stroke: false,
    size: 20,
    hoverColor: ColorPalette.blue0,
  };

  const userProfileUrl = `${PREFIX_VISITOR_TYPE[visitorType]}/user/${
    PREFIX_USER_PROFILE_TYPE[isGeneralUser ? UserProfileType.User : UserProfileType.AssociationUser]
  }/${ownerId}/horses`;

  return (
    <UserHorses>
      <Title>{props.userName}’s public horses</Title>
      {userHorses.map((horse, i) => (
        <CommonLink key={i} to={`${PREFIX_VISITOR_TYPE[visitorType]}/horse/${horse.id}`}>
          <UserHorsesListItem key={horse.id} horse={horse} />
        </CommonLink>
      ))}
      <ShowAll to={userProfileUrl} className="d-flex align-items-center justify-content-start">
        <ColoredIcon {...showAllButtonProps} style={{marginRight: '11px'}} />
        Show all
      </ShowAll>
    </UserHorses>
  );
};

export default withRouter(memo(UserHorsesList));
