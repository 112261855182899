import {IModule} from 'redux-dynamic-modules';

import {ancestryUploadReducer} from './reducer';
import {IAdminAncestryUploadState} from './types';

export interface IAdminAncestryUploadModuleState {
  adminAncestryUpload: IAdminAncestryUploadState;
}

export const AdminAncestryUploadModule: IModule<IAdminAncestryUploadModuleState> = {
  id: 'adminAncestryUpload',
  reducerMap: {
    adminAncestryUpload: ancestryUploadReducer,
  },
};
