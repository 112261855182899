import * as Yup from 'yup';

import {IRequestChangeSample} from 'Admin/AdminAssociations/store/adminOrders';
import {ISampleAdmin} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderHorseDetails';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export const convertSampleToChangeRequestSample = (sample: ISampleAdmin): IRequestChangeSample => ({
  id: sample.id,
  sampleId: sample.sampleId,
});

export const initialValue: IRequestChangeSample = {
  id: 0,
  sampleId: '',
};

export const validationSchema = Yup.object().shape<IRequestChangeSample>({
  id: Yup.number().required(),
  sampleId: Yup.string().required(REQUIRED_FIELD),
});
