import React, {memo} from 'react';
import {useLocation} from 'react-router';
import styled from 'styled-components';

import LinkButton from 'Common/components/Controls/Buttons/LinkButton';

const AuthButton = styled(LinkButton)`
  width: 120px;
`;

function LoginLinks() {
  const location = useLocation();

  return (
    <div className="nav d-flex flex-row align-items-center mr-2">
      {location.pathname === '/login' ? (
        <AuthButton to="/signup" size="small">
          Sign Up
        </AuthButton>
      ) : (
        <AuthButton to="/login" size="small">
          Log In
        </AuthButton>
      )}
    </div>
  );
}

export default memo(LoginLinks);
