import {SelectedStrategy} from './strategy/SelectedStrategy';
import StrategyService from './strategy/StrategyService';

export type ApiByStrategyType = Record<SelectedStrategy, string>;

export const getApiBySelectedStrategy = <T extends SelectedStrategy>(apis: Record<T, string>) => {
  const currentStrategy = StrategyService.getStrategy();
  return apis[currentStrategy];
};

export const getApiParamsBySelectedStrategy = <T extends SelectedStrategy>(apis: Record<T, {}>) => {
  const currentStrategy = StrategyService.getStrategy();
  const apiParams = apis[currentStrategy];
  return apiParams ? {params: apiParams} : undefined;
};
