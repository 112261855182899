import {combineReducers, Reducer} from 'redux';

import {IAdminPhenotypeGroupImageState} from 'Admin/AdminPhenotypes/store/adminPhenotypeGroupImage/types';
import {communicationPhenotypeGroupImageReducer} from 'Admin/AdminPhenotypes/store/adminPhenotypeGroupImage/reducer/communications';
import {dataPhenotypeGroupImageReducer} from 'Admin/AdminPhenotypes/store/adminPhenotypeGroupImage/reducer/data';

export const adminPhenotypeGroupImageReducer: Reducer<IAdminPhenotypeGroupImageState> = combineReducers<
  IAdminPhenotypeGroupImageState
>({
  communications: communicationPhenotypeGroupImageReducer,
  data: dataPhenotypeGroupImageReducer
});
