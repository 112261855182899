import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {
  AncestryReportActions,
  getAncestryHorsePrivacyActionTypes,
  getAncestryKinshipHorsesActionTypes,
  getAncestryProbabilitiesActionTypes,
  getAncestryRelatedHorsesActionTypes,
  getPopulationActionTypes,
  getPopulationComparisonActionTypes,
  IAncestryReportState,
  sendHorsePublicityRequestActionTypes,
} from '../types';

export const communicationReducer = combineReducers<IAncestryReportState['communications'], AncestryReportActions>({
  populationLoading: makeCommunicationReducerFromEnum(getPopulationActionTypes),
  populationComparisonLoading: makeCommunicationReducerFromEnum(getPopulationComparisonActionTypes),
  ancestryProbabilitiesLoading: makeCommunicationReducerFromEnum(getAncestryProbabilitiesActionTypes),
  ancestryRelatedHorsesLoading: makeCommunicationReducerFromEnum(getAncestryRelatedHorsesActionTypes),
  ancestryKinshipHorsesLoading: makeCommunicationReducerFromEnum(getAncestryKinshipHorsesActionTypes),
  ancestryHorsePrivacyLoading: makeCommunicationReducerFromEnum(getAncestryHorsePrivacyActionTypes),
  sendHorsePublicityRequesting: makeCommunicationReducerFromEnum(sendHorsePublicityRequestActionTypes),
});
