import React, {memo, useEffect} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import MapField from 'Common/components/FormFields/MapField';
import {Nullable} from 'Common/types';
import {ILocation} from 'Common/models/ICoordinatedLocation';
import {IFormValues, initialValue, validationSchema} from './validation';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminEmployees';
import {IAppState} from 'Common/store/IAppState';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import Loading from 'Loading/components/Loading';

interface IProps {
  employeeId?: number;
  onSuccess?(location: Nullable<ILocation>): void;
}

type IExternalProps = IProps;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function EmployeeMapField(props: AllProps) {
  const {adminEmployeeGeoLocationLoading, employeeId, getAdminEmployeeGeoLocation, values, onSuccess} = props;

  useEffect(() => {
    const fetch = async (id: number) => {
      await getAdminEmployeeGeoLocation(id);
    };
    if (employeeId) {
      fetch(employeeId);
    }
  }, [employeeId, getAdminEmployeeGeoLocation]);

  useEffect(() => {
    // send coordinates to EmployeeForm
    onSuccess && onSuccess(values.location);
  }, [onSuccess, values.location]);

  const isRequesting = adminEmployeeGeoLocationLoading.isRequesting;

  useErrorCommunicationToToast(adminEmployeeGeoLocationLoading);

  if (isRequesting) {
    return <Loading />;
  }

  return (
    <Form>
      <MapField
        name="locationAddress"
        mapName="location"
        label="Location"
        memoized={true}
        autoComplete="off"
        placeholder="Start typing your location"
        style={{marginBottom: 24}}
      />
    </Form>
  );
}

const EmployeeMapFieldFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({geoLocation}) => (geoLocation ? {location: geoLocation, locationAddress: ''} : initialValue),
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {},
  enableReinitialize: true,
})(EmployeeMapField);

const mapStateToProps = (state: IAppState) => ({
  geoLocation: selectors.selectGeoLocation(state),
  adminEmployeeGeoLocationLoading: selectors.selectCommunication(state, 'adminEmployeeGeoLocationLoading'),
});

const mapDispatchToProps = {
  getAdminEmployeeGeoLocation: actions.getAdminEmployeeGeoLocation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(EmployeeMapFieldFormik));
