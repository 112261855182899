import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';
import styled from 'styled-components';

import {CheckboxField, InputField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {IEntity} from 'Common/models/IEntity';
import {IDictionary} from 'DictionaryFactory/types';
import {RequiredBy} from 'Common/types';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/GeneGroups/GeneGroupForm/validation';
import {IAdminGeneGroup} from 'Admin/AdminPhenotypes/models/IAdminGeneGroup';
import ImageUploaderField from 'Common/components/FormFields/ImageUploaderField';
import {IconName} from 'Icon/components/Icon';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {Hint} from 'Common/components/StyledComponents/StyledComponents';
import {actions, selectors as imageSelectors} from 'Admin/AdminPhenotypes/store/adminGeneGroupImage/index';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {AdminGeneGroupImageModule} from 'Admin/AdminPhenotypes/store/adminGeneGroupImage/adminGeneGroupImageModule';

const CheckboxWrapper = styled.div`
  padding: 8px 0;
`;

const HintWrapper = styled(Hint)`
  margin-top: 8px;
`;

interface IExternalProps {
  itemId: IEntity['id'] | null;
  type: FormType;
  onSuccess(): void;
  dictionary: IDictionary<IAdminGeneGroup, IAdminGeneGroup>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type Props = FormikProps<IFormValues> & OuterProps;

function GeneGroupForm(props: Props) {
  const {itemId, item, dictionary, ...rest} = props;
  const {getItem} = dictionary.actions;

  useEffect(() => {
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem]);

  return (
    <DictionaryForm {...rest}>
      <InputField name="name" label="Name" placeholder="Name" isRequired />
      <InputField
        name="positionIndex"
        label="Order in Report"
        placeholder="Order in Report"
        className="w-50"
        isRequired
      />
      <FieldControlContainer label="Image">
        <ImageUploaderField
          name="image"
          iconProps={{name: IconName.RearedHorse, width: 48, height: 60}}
          title="Add image"
          isShowBlurredBackground={false}
        />
      </FieldControlContainer>
      <CheckboxWrapper>
        <CheckboxField name="isShowVariantSummary" label="Show in variant summary" />
        <HintWrapper>
          Enabling this option, users allowed to see this gene group in alleles summary on reports and horse profiles
        </HintWrapper>
      </CheckboxWrapper>
    </DictionaryForm>
  );
}

const GeneGroupFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting ? item : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary, uploadGeneGroupImage, image, deleteGeneGroupImage, getGeneGroupImage} = formikBag.props;

    const uploadImage = async (file: File) => {
      const formData = new FormData();
      formData.append('image', file);
      return uploadGeneGroupImage(formData);
    };

    formikBag.setSubmitting(true);

    if (values.image?.file) {
      const imageId = await uploadImage(values.image.file);
      values.image = await getGeneGroupImage(imageId);
    }

    if (!values.image && image) {
      await deleteGeneGroupImage(image.id);
    }

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(values);
    }

    if (type === FormType.edit) {
      await updateItem(values as RequiredBy<IFormValues, 'id'>);
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(GeneGroupForm);

const mapStateToProps = (state: IAppState, ownProps: IExternalProps) => {
  return {
    ...makePhenotypeFormSelectors(state, ownProps.dictionary),
    image: imageSelectors.selectGeneGroupImage(state),
    imageLoading: imageSelectors.selectCommunication(state, 'imageLoading'),
    imageUploading: imageSelectors.selectCommunication(state, 'imageUploading'),
    imageDeleting: imageSelectors.selectCommunication(state, 'imageDeleting'),
  };
};

const mapDispatchToProps = {
  getGeneGroupImage: actions.getGeneGroupImage,
  uploadGeneGroupImage: actions.uploadGeneGroupImage,
  deleteGeneGroupImage: actions.deleteGeneGroupImage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(GeneGroupFormFormik));

export default withDynamicModules(Connected, [AdminGeneGroupImageModule]);
