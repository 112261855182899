import {IOption} from 'Common/models/IOption';
import Theme from 'Common/constants/Theme';
import ColorPalette from 'Common/constants/ColorPalette';

export const AffectionTypeColors: IOption[] = [
  {label: 'Bathing of a Red Horse', value: Theme.color.primary},
  {label: 'Dark Horse Blue', value: Theme.color.black},
  {label: 'Gold Mane', value: ColorPalette.yellow9},
  {label: 'Sunny Palomino', value: ColorPalette.yellow13},
  {label: 'Iron Grey', value: Theme.color.gray}
];
