import {IModule} from 'redux-dynamic-modules';

import {currentUserReducer} from './reducer';
import {ICurrentUserState} from './types';

export interface ICurrentUserModuleState {
  currentUser: ICurrentUserState;
}

export const CurrentUserModule: IModule<ICurrentUserModuleState> = {
  id: 'currentUser',
  reducerMap: {
    currentUser: currentUserReducer
  }
};
