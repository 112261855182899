import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';

import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {
  BusinessPortalAssociationPageActions,
  getContactInformationActions,
  getContactInformationActionTypes,
  getGeneralInformationActions,
  getGeneralInformationActionTypes,
  getOrganizationAvatarActions,
  getOrganizationAvatarActionTypes,
  resetContactInformationActionTypes,
  resetGeneralInformationActionTypes,
  updateContactInformationActions,
  updateContactInformationActionTypes,
  updateGeneralInformationActions,
  updateGeneralInformationActionTypes,
  updateOrganizationAvatarActions,
  updateOrganizationAvatarActionTypes,
} from './types';
import {IAssociationPageGeneralInformation} from 'BusinessPortal/models/IAssociationPageGeneralInformation';
import {IAssociationPageContactInformation} from 'BusinessPortal/models/IAssociationPageContactInformation';
import BusinessPortalAssociationPageService from 'BusinessPortal/services/BusinessPortalAssociationPageService';

export const getGeneralInformation =
  (): ActionResult<IAppState, Promise<void>, getGeneralInformationActions> => async (dispatch) => {
    try {
      dispatch(action(getGeneralInformationActionTypes.REQUEST));
      const data = await BusinessPortalAssociationPageService.getGeneralInformation();
      dispatch(action(getGeneralInformationActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getGeneralInformationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateGeneralInformation =
  (data: IAssociationPageGeneralInformation): ActionResult<IAppState, Promise<void>, updateGeneralInformationActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateGeneralInformationActionTypes.REQUEST));
      await BusinessPortalAssociationPageService.updateGeneralInformation(data);
      dispatch(action(updateGeneralInformationActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateGeneralInformationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetGeneralInformation =
  (): ActionResult<IAppState, void, BusinessPortalAssociationPageActions> => (dispatch) => {
    dispatch(action(resetGeneralInformationActionTypes.RESET));
  };

export const getContactInformation =
  (): ActionResult<IAppState, Promise<void>, getContactInformationActions> => async (dispatch) => {
    try {
      dispatch(action(getContactInformationActionTypes.REQUEST));
      const data = await BusinessPortalAssociationPageService.getContactInformation();
      dispatch(action(getContactInformationActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getContactInformationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateContactInformation =
  (data: IAssociationPageContactInformation): ActionResult<IAppState, Promise<void>, updateContactInformationActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateContactInformationActionTypes.REQUEST));
      await BusinessPortalAssociationPageService.updateContactInformation(data);
      dispatch(action(updateContactInformationActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateContactInformationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrganizationAvatar =
  (): ActionResult<IAppState, Promise<void>, getOrganizationAvatarActions> => async (dispatch) => {
    try {
      dispatch(action(getOrganizationAvatarActionTypes.REQUEST));
      const data = await BusinessPortalAssociationPageService.getOrganizationAvatar();
      dispatch(action(getOrganizationAvatarActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getOrganizationAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateOrganizationAvatar =
  (uploadedFile: File): ActionResult<IAppState, Promise<void>, updateOrganizationAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateOrganizationAvatarActionTypes.REQUEST));
      await BusinessPortalAssociationPageService.updateOrganizationAvatar(uploadedFile);
      dispatch(action(updateOrganizationAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateOrganizationAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetContactInformation =
  (): ActionResult<IAppState, void, BusinessPortalAssociationPageActions> => (dispatch) => {
    dispatch(action(resetContactInformationActionTypes.RESET));
  };
