import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {ISubscription} from 'StripeSubscription/models/ISubscription';
import {IDefaultPaymentMethod} from 'StripeSubscription/models/IDefaultPaymentMethod';
import {getDefaultPaymentMethodString, hasPaymentProblemStatuses} from './common/constants';
import {ButtonWrapper, PlanTitle, SubscriptionContainer} from './common/styled';
import Plan from './common/Plan';
import SubscriptionInfoRow from './common/SubscriptionInfoRow';
import withDate from 'Common/helpers/withDate';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {ISubscriptionProduct} from 'StripeSubscription/models/ISubscriptionProduct';
import {Nullable} from 'Common/types';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'StripeSubscription/store/index';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';

const PaymentProblemMessageContainer = styled.div`
  width: 100%;
  padding: 16px 30px 23px 14px;
  margin-bottom: 24px;
  background: ${ColorPalette.gray49};

  @media ${breakpoints.sm} {
    width: 95%;
  }

  @media ${breakpoints.md} {
    width: 645px;
    padding: 16px 90px 16px 14px;
  }
`;

const PaymentProblemMessage = styled.div`
  width: 497px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 32px;
`;

const ExclamationMark = styled.div`
  margin-right: 16px;
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size32};
  line-height: 56px;
  color: ${Theme.color.primary};

  @media ${breakpoints.sm} {
    margin-right: 32px;
  }
`;

interface IExternalProps {
  subscription: ISubscription;
  product?: ISubscriptionProduct;
  defaultPaymentMethod: Nullable<IDefaultPaymentMethod>;
  hasScheduledSubscription?: boolean;
  onSuccess(): void;
  changeSubscriptionPlan(): void;
  changeDefaultPaymentMethod?(subscriptionId?: number): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IExternalProps & IConnected;

const SimpleSubscriptionItem = (props: AllProps) => {
  const {
    subscription,
    onSuccess,
    deleteSubscription,
    deleteSubscriptionRequesting,
    cancelSubscriptionRequesting,
    renewSubscription,
    cancelSubscription,
    renewSubscriptionRequesting,
    product,
    changeDefaultPaymentMethod,
    changeSubscriptionPlan,
    defaultPaymentMethod,
    hasScheduledSubscription,
  } = props;

  const isLoading =
    cancelSubscriptionRequesting.isRequesting ||
    renewSubscriptionRequesting.isRequesting ||
    deleteSubscriptionRequesting.isRequesting;

  useErrorCommunicationToToast(deleteSubscriptionRequesting);
  useErrorCommunicationToToast(cancelSubscriptionRequesting);
  useErrorCommunicationToToast(renewSubscriptionRequesting);

  useOnSuccessCommunication(deleteSubscriptionRequesting, onSuccess);
  useOnSuccessCommunication(cancelSubscriptionRequesting, onSuccess);
  useOnSuccessCommunication(renewSubscriptionRequesting, onSuccess);

  const isMarkedCancellationSubscription = subscription?.cancelAtPeriodEnd;
  const hasPaymentProblem = hasPaymentProblemStatuses.some((i) => i === subscription?.status);
  const hasPaymentProblemMessage = hasPaymentProblem && !isMarkedCancellationSubscription;
  const hasRenewSubscriptionButton = subscription?.cancelAtPeriodEnd && !hasScheduledSubscription;

  const onRenewSubscription = useCallback(() => {
    subscription?.id && renewSubscription(subscription?.id);
  }, [renewSubscription, subscription]);

  const onCancelSubscription = useCallback(() => {
    if (subscription?.id) {
      if (hasPaymentProblemMessage) {
        deleteSubscription(subscription.id);
      } else {
        cancelSubscription(subscription.id);
      }
    }
  }, [cancelSubscription, deleteSubscription, hasPaymentProblemMessage, subscription]);

  const changeDefaultPaymentMethodHandler = useCallback(() => {
    if (hasPaymentProblem) {
      changeDefaultPaymentMethod && changeDefaultPaymentMethod(subscription.id);
    } else {
      changeDefaultPaymentMethod && changeDefaultPaymentMethod();
    }
  }, [changeDefaultPaymentMethod, hasPaymentProblem, subscription.id]);

  return (
    <>
      {hasPaymentProblemMessage && (
        <PaymentProblemMessageContainer className="d-flex align-items-center">
          <ExclamationMark>!</ExclamationMark>
          <PaymentProblemMessage>
            The charge for your subscription was declined by your&nbsp;
            <strong>{defaultPaymentMethod ? getDefaultPaymentMethodString(defaultPaymentMethod) : ''}</strong>. To avoid
            losing access to your service, update your payment method.
          </PaymentProblemMessage>
        </PaymentProblemMessageContainer>
      )}

      <SubscriptionContainer className="d-flex flex-column">
        <PlanTitle>Selected plan</PlanTitle>
        <Plan
          onChangePlan={changeSubscriptionPlan}
          product={product}
          isCancelled={isMarkedCancellationSubscription}
          hasScheduledSubscription={hasScheduledSubscription}
          hasPaymentProblem={hasPaymentProblem}
        />
        <SubscriptionInfoRow
          title="Date of purchase"
          value={(subscription?.startDate && withDate(subscription?.startDate)) || ''}
        />
        <SubscriptionInfoRow
          title={isMarkedCancellationSubscription ? 'Available until' : 'Next payment'}
          value={withDate(subscription?.currentPeriodEnd) || ''}
        />
        <SubscriptionInfoRow
          title="Payment method"
          value={defaultPaymentMethod ? getDefaultPaymentMethodString(defaultPaymentMethod) : ''}
          onChangePaymentMethod={!isMarkedCancellationSubscription ? changeDefaultPaymentMethodHandler : undefined}
        />
      </SubscriptionContainer>

      {hasRenewSubscriptionButton && (
        <ButtonWrapper onClick={onRenewSubscription} isLoading={isLoading}>
          Renew subscription
        </ButtonWrapper>
      )}
      {!isMarkedCancellationSubscription && (
        <ButtonWrapper isLoading={isLoading} variant="outlined" size="small" onClick={onCancelSubscription}>
          Cancel subscription
        </ButtonWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  deleteSubscriptionRequesting: selectors.selectCommunication(state, 'deleteSubscriptionRequesting'),
  cancelSubscriptionRequesting: selectors.selectCommunication(state, 'cancelSubscriptionRequesting'),
  renewSubscriptionRequesting: selectors.selectCommunication(state, 'renewSubscriptionRequesting'),
});

const mapDispatchToProps = {
  deleteSubscription: actions.deleteSubscription,
  cancelSubscription: actions.cancelSubscription,
  renewSubscription: actions.renewSubscription,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(SimpleSubscriptionItem);
