import * as R from 'ramda';
import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';

import {ModalWindowHeader} from 'Common/components/Modal/shared';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {breakpoints} from 'Common/constants/Breakpoints';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Icon, {IconName} from 'Icon/components/Icon';
import {IOrderPackage, IOrderPackageTest, ITestGroup} from 'Order/models/IOrderPackage';
import {hexToRgb, hexToRgba} from 'Common/helpers/hexToRgba';
import Theme from 'Common/constants/Theme';
import {withCurrency} from 'Common/helpers/withCurrency';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import LocalStorageService from 'Common/services/LocalStorageService';
import {EventName} from 'Common/constants/EventName';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';

export const COMPARE_PACKAGES_KEY = 'comparePackages';

const TEST_INCLUDED = 'Test included to the package';
const TEST_UNINCLUDED = 'Test is not included to the package';
const NO_SELECTED_PACKAGES = 'No packages selected. Please add packages to compare.';

const MODAL_ADDITIONAL_CONTENT_HEIGHT = 180;
const MODAL_MARGIN = 64;
const MODAL_CONTENT_MAX_HEIGHT = `calc(100vh - ${MODAL_ADDITIONAL_CONTENT_HEIGHT + MODAL_MARGIN}px`;

const ModalFooter = styled.div`
  padding: 18px 16px 24px;
  border-top: 1px solid ${ColorPalette.gray10};
  flex-direction: column;

  @media ${breakpoints.sm} {
    flex-direction: row;
    padding: 18px 40px 24px;
  }
`;

const TableContainer = styled.div`
  padding: 8px;

  @media ${breakpoints.sm} {
    padding: 0 24px 16px;
  }
`;

const HeadCell = styled.th<{width: string; align?: string; opacity?: number; isFirstColumn?: boolean}>`
  min-width: ${(p) => p.width};
  max-width: ${(p) => p.width};
  padding: ${(p) => (p.isFirstColumn ? '16px 0 16px 16px' : '16px 0')};
  font-size: ${(p) => (p.isFirstColumn ? Typography.size.size13 : Typography.size.size16)};
  font-family: ${Typography.family.openSans};
  font-weight: ${Typography.weight.bold700};
  line-height: 24px;
  color: ${(p) => (p.opacity ? hexToRgba(ColorPalette.black6, p.opacity) : hexToRgb(ColorPalette.black6))};
  text-transform: uppercase;
  text-align: ${(p) => p.align || 'left'};
  word-break: break-word;

  position: sticky;
  top: 0;
  background: ${ColorPalette.white0};
  z-index: 12;
`;

const ColumnTitle = styled.div`
  line-height: 24px;
  max-height: 48px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  max-width: 160px;
  min-width: 160px;
  padding: 0 8px;

  @media ${breakpoints.sm} {
    width: 100%;
  }
`;

const Cell = styled.td<{align?: string}>`
  padding: 8px;
  text-align: ${(p) => p.align || 'left'};
  word-break: break-word;

  @media ${breakpoints.sm} {
    padding: 10px 16px;
  }
`;

const TableCell = styled.table`
  margin-top: 8px;
  table-layout: fixed;
`;

const Row = styled.tr`
  :nth-child(odd) {
    background-color: ${ColorPalette.gray49};
  }
`;

const GroupCell = styled.td`
  padding-bottom: 24px;
`;

const GroupName = styled.span`
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  text-transform: uppercase;
  padding-left: 16px;
`;

const TestName = styled.div`
  font-size: ${Typography.size.size13};
  color: ${ColorPalette.black1};
`;

const LegendField = styled.div`
  display: flex;
  align-items: center;
  font-family: ${Typography.family.roboto};
  font-size: ${Typography.size.size13};
  font-weight: ${Typography.weight.normal400};
  line-height: 13px;
  color: ${ColorPalette.gray28};
  :not(:first-child) {
    margin-top: 14px;
  }

  padding: 0 24px;

  @media ${breakpoints.sm} {
    flex-direction: row;
    padding: 0;
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size24};
  line-height: 56px;
  color: ${ColorPalette.gray56};
`;

const TrashIcon = styled(IconButton)`
  margin-right: 16px;
`;

interface IComapareTest {
  name: string;
  description: string;
  title: string;
  includedPackages: boolean[];
}

interface IComparePackage {
  group: string;
  tests: IComapareTest[];
}

interface ICompareValue {
  group: string;
  values: string[];
}

interface IProps {
  orderPackages: IOrderPackage[];
}

function CompareForm(props: IProps) {
  const {orderPackages} = props;

  const [orderPackagesToCompare, setOrderPackagesToCompare] = useState(orderPackages);

  const {emit} = useEventBus();

  const allTests: IOrderPackageTest[] = R.uniq(
    orderPackagesToCompare
      .flatMap((x) => x.tests.map((y) => y))
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
  );

  const uniqGroups = R.uniq(R.chain<IOrderPackageTest, ITestGroup>(R.prop('groups'))(allTests));
  const byPositionIndex = R.ascend(R.prop('positionIndex'));
  const sortedUniqGroups = R.sort<ITestGroup>(byPositionIndex, uniqGroups);

  const otherTests = allTests.filter((x) => x.groups.length === 0);

  const uniqueComparingPackages: IComparePackage[] = sortedUniqGroups.map((group) => ({
    group: group.name,
    tests: allTests
      .filter((x) => x.groups.some((gr) => gr.id === group.id))
      .map((x) => ({
        name: x.name,
        title: x.title,
        description: x.description,
        includedPackages: orderPackagesToCompare.map((op) => op.tests.some((t) => t.id === x.id)),
      })),
  }));
  const otherComparingPackages: IComparePackage = {
    group: 'Other',
    tests: otherTests.map((x) => ({
      name: x.name,
      title: x.title,
      description: x.description,
      includedPackages: orderPackagesToCompare.map((op) => op.tests.some((t) => t.id === x.id)),
    })),
  };
  const comparingPackages =
    otherComparingPackages.tests.length > 0
      ? [...uniqueComparingPackages, otherComparingPackages]
      : uniqueComparingPackages;

  const packageCount = orderPackagesToCompare.length;
  const columnWidth = `calc((100% - 150px)/${packageCount})`;

  const priceComparing: ICompareValue = {
    group: 'Price',
    values: orderPackagesToCompare.map((x) => withCurrency(x.price)),
  };

  const isComparingPackages = orderPackagesToCompare.length > 0;

  const onDeleteComparePackage = useCallback(
    (id: number) => {
      const newOrderPackagesToComare = [...orderPackagesToCompare];
      const findId = newOrderPackagesToComare.findIndex((x) => x.id === id);
      // Delete from array of packages
      if (findId !== -1) {
        newOrderPackagesToComare.splice(findId, 1);
        setOrderPackagesToCompare(newOrderPackagesToComare);
      }

      //Delete from LocalStoraghe
      const packagesForCompare: number[] = LocalStorageService.getItem(COMPARE_PACKAGES_KEY) || [];
      const findPackId = packagesForCompare.findIndex((x) => x === id);
      if (findPackId !== -1) {
        packagesForCompare.splice(findPackId, 1);
      }
      LocalStorageService.setItem(COMPARE_PACKAGES_KEY, packagesForCompare);
      emit(EventName.AddPackageToCompare);
    },
    [emit, orderPackagesToCompare]
  );

  return (
    <>
      <ModalWindowHeader>
        <span>Compare Packages</span>
      </ModalWindowHeader>

      {!isComparingPackages && <ErrorMessage>{NO_SELECTED_PACKAGES}</ErrorMessage>}

      {isComparingPackages && (
        <Scrollbar maxHeight={MODAL_CONTENT_MAX_HEIGHT}>
          <TableContainer>
            <table className="w-100 position-relative">
              <thead>
                <tr>
                  <HeadCell width="150px">
                    <ColumnTitle>{''}</ColumnTitle>
                  </HeadCell>
                  {orderPackages.map((orderPackage, i) => (
                    <HeadCell key={i} align="center" width={columnWidth} opacity={0.3}>
                      <Tooltip content={orderPackage.description}>
                        <div className="d-flex justify-content-center align-items-center">
                          <ColumnTitle>{orderPackage.name}</ColumnTitle>
                          <TrashIcon
                            name={IconName.Close}
                            fill={true}
                            stroke={false}
                            size={16}
                            color={ColorPalette.gray48}
                            hoverColor={ColorPalette.red7}
                            onClick={() => onDeleteComparePackage(orderPackage.id)}
                          />
                        </div>
                      </Tooltip>
                    </HeadCell>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* Price */}
                <tr>
                  <GroupCell colSpan={packageCount + 1}>
                    <TableCell className="w-100">
                      <tbody>
                        <tr>
                          <Cell width="150px">
                            <GroupName style={{paddingLeft: 0}}>{priceComparing.group}</GroupName>
                          </Cell>
                          {priceComparing.values.map((price, i) => (
                            <Cell key={i} width={columnWidth} align="center">
                              {price}
                            </Cell>
                          ))}
                        </tr>
                      </tbody>
                    </TableCell>
                  </GroupCell>
                </tr>
                {/* Tests */}
                {comparingPackages.map((comparingPackage, i) => {
                  return (
                    <React.Fragment key={i}>
                      <tr>
                        <GroupCell colSpan={packageCount + 1}>
                          <GroupName>{comparingPackage.group}</GroupName>

                          <TableCell className="w-100">
                            <tbody>
                              {comparingPackage.tests.map((test, ind) => {
                                return (
                                  <Row key={ind}>
                                    <Cell width="150px">
                                      <Tooltip content={test.description}>
                                        <TestName>{test.title || test.name}</TestName>
                                      </Tooltip>
                                    </Cell>
                                    {test.includedPackages.map((included, i) => (
                                      <Cell key={i} width={columnWidth} align="center">
                                        <Icon
                                          name={included ? IconName.SquareFilledCheckbox : IconName.CrossedCheckbox}
                                        />
                                      </Cell>
                                    ))}
                                  </Row>
                                );
                              })}
                            </tbody>
                          </TableCell>
                        </GroupCell>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </TableContainer>
        </Scrollbar>
      )}
      <ModalFooter className="d-flex justify-content-between">
        <div>
          <LegendField className="d-flex align-items-center">
            <Icon name={IconName.SquareFilledCheckbox} />
            <span>{`${Typography.symbols.whitespace}– ${TEST_INCLUDED}`}</span>
          </LegendField>
          <LegendField className="d-flex align-items-center">
            <Icon name={IconName.CrossedCheckbox} />
            <span>{`${Typography.symbols.whitespace}– ${TEST_UNINCLUDED}`}</span>
          </LegendField>
        </div>
      </ModalFooter>
    </>
  );
}

export default memo(CompareForm);
