import classNames from 'classnames';
import {memo, useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {convertUserName} from 'Common/helpers/convertUserName';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAvatared} from 'Common/models/IAvatar';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Horse/store/horseTransfer';
import {HorseTransferModule} from 'Horse/store/horseTransfer/module';
import {SidebarComponent, SidebarLabel} from 'HorseProfile/components/shared/StyledComponents';
import Loading from 'Loading/components/Loading';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import HorseTransferForm from './HorseTransferForm/HorseTransferForm';

const InfoWrapper = styled.div`
  margin-bottom: 16px;
  font-size: ${Typography.size.size13};
  color: ${ColorPalette.black1};
`;

const NameWrapper = styled.div`
  margin-bottom: 16px;
`;

const UserName = styled.label`
  font-family: ${Typography.family.openSans};
  font-size: ${Typography.size.size23};
  font-weight: ${Typography.weight.semiBold600};
  letter-spacing: -0.4px;
  margin-bottom: 0;
  margin-left: 16px;
`;

const TransferButton = styled(PrimaryButton)`
  height: 32px;
  width: 100%;
`;

const Detailsinfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: 0 0 16px 8px;
`;

const DetailsTitle = styled.div`
  font-size: ${Typography.size.size13};
  color: ${ColorPalette.gray3};
  margin: 0px 8px 16px 8px;
`;

const TextBase = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 24px;
`;

const Label = styled(TextBase)`
  color: ${ColorPalette.gray3};
`;

const Value = styled(TextBase)`
  color: ${ColorPalette.black1};
`;

interface IOwnProps extends IAvatared {
  horseId: number;
  horseName: string;
  className?: string;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IOwnProps;

function Transfer(props: Props) {
  const {avatar, horseId, horseName, className} = props;
  const {getHorseTransferDetails, horseTransferDetails, horseTransferDetailsLoading} = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onOpenModal = useCallback(async () => {
    setIsModalOpen(true);
  }, []);
  const onCloseModal = useCallback(() => setIsModalOpen(false), []);

  useEffect(() => {
    getHorseTransferDetails(horseId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadForm = useCallback(() => {
    getHorseTransferDetails(horseId);
  }, [getHorseTransferDetails, horseId]);

  const handleSuccess = useCallback(() => {
    onCloseModal();
    reloadForm();
  }, [onCloseModal, reloadForm]);

  const isLoading = horseTransferDetailsLoading.isRequesting;

  return (
    <SidebarComponent className={classNames('position-relative', className)}>
      <ModalWindow isOpen={isModalOpen} onClose={onCloseModal}>
        <HorseTransferForm horseId={horseId} horseName={horseName} onSuccess={handleSuccess} />
      </ModalWindow>

      {isLoading && <Loading />}

      <SidebarLabel>Horse information</SidebarLabel>

      <InfoWrapper className="d-flex flex-column align-items-start justify-content-between">
        <NameWrapper className="d-flex align-items-center overflow-hidden">
          <Avatar
            avatarSize={AvatarSize.Custom}
            customSizeAvatar={47}
            defaultAvatarProfile={DefaultAvatarProfile.Horse}
            url={avatar?.url}
          />
          <UserName>{horseName}</UserName>
        </NameWrapper>

        {horseTransferDetails && (
          <>
            <DetailsTitle>Current transfer to:</DetailsTitle>

            <Detailsinfo>
              <Label>Request Date</Label>
              <Value>{horseTransferDetails.createDate}</Value>

              <Label>Name</Label>
              <Value>
                {convertUserName(horseTransferDetails.providedFirstName, horseTransferDetails.providedLastName)}
              </Value>

              <Label>Email</Label>
              <Value>{horseTransferDetails.providedEmail}</Value>

              <Label>Phone</Label>
              <Value>{horseTransferDetails.providedPhone}</Value>
            </Detailsinfo>
          </>
        )}

        {!horseTransferDetails && (
          <TransferButton size="small" onClick={onOpenModal}>
            Transfer request
          </TransferButton>
        )}
      </InfoWrapper>
    </SidebarComponent>
  );
}

const mapStateToProps = (state: IAppState) => ({
  horseTransferDetails: selectors.selectHorseTransferDetails(state),
  horseTransferDetailsLoading: selectors.selectCommunication(state, 'horseTransferDetailsLoading'),
});

const mapDispatchToProps = {
  getHorseTransferDetails: actions.getHorseTransferDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withDynamicModules(connector(memo(Transfer)), HorseTransferModule);
