import {combineReducers, Reducer} from 'redux';

import {communicationChangePasswordReducer} from './communications';
import {dataChangePasswordReducer} from './data';
import {IChangePasswordState} from '../types';

export const changePasswordReducer: Reducer<IChangePasswordState> = combineReducers<IChangePasswordState>({
  communications: communicationChangePasswordReducer,
  data: dataChangePasswordReducer,
});
