import React, {memo, useCallback, useState} from 'react';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import OwnerInfoSectionEditFindForm from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseOwnerSection/HorseOwnerSectionEdit/HorseOwnerSectionEditFindForm';
import NoData from 'BusinessPortal/components/common/NoData/NoData';
import OwnerInfoSectionEditCreateForm from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseOwnerSection/HorseOwnerSectionEdit/HorseOwnerSectionEditCreateForm';
import {IHorseOwnerSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseOwnerSection/models/IHorseOwnerSectionData';

type IExternalProps = IEditableSection<IHorseOwnerSectionData>;

const HorseOwnerSectionEdit = (props: IExternalProps) => {
  const {onSuccess, data} = props;

  const [isUserSearchFieldForm, setIsUserSearchFieldForm] = useState(true);
  const changeOwnerForm = useCallback(() => setIsUserSearchFieldForm(!isUserSearchFieldForm), [isUserSearchFieldForm]);

  if (!data?.owner) {
    return <NoData />;
  }

  const {owner, horseId} = data;

  if (isUserSearchFieldForm) {
    return (
      <OwnerInfoSectionEditFindForm
        owner={data.owner}
        horseId={horseId}
        onSuccess={onSuccess}
        onChangeForm={changeOwnerForm}
      />
    );
  }

  return (
    <OwnerInfoSectionEditCreateForm
      ownerId={owner.id}
      horseId={horseId}
      onSuccess={onSuccess}
      onChangeForm={changeOwnerForm}
    />
  );
};

export default memo(HorseOwnerSectionEdit);
