import {IModule} from 'redux-dynamic-modules';
import {IPermissionsState} from './types';
import {authReducer} from './reducer';

export interface IPermissionsModuleState {
  permissions: IPermissionsState;
}

export const PermissionsModule: IModule<IPermissionsModuleState> = {
  id: 'permissions',
  reducerMap: {
    permissions: authReducer
  }
};
