import * as Yup from 'yup';

import {IHorseTest} from 'Admin/AdminDashboard/models/Order/IHorseTest';
import {PaymentMethod} from 'Common/constants/PaymentMethod';
import {CouponError} from 'Order/services/constants/CouponError';
import {IResponseError} from 'Common/models/IError';
import {IAdminCreateUserOrderRequest} from 'Admin/AdminDashboard/models/Order/IAdminCreateUserOrderRequest';
import {convertTestsToIHorseTestRequest} from 'Admin/AdminDashboard/services/converters/orders';

export interface IFormCreateUserOrderValues {
  userId: number;
  tests: IHorseTest[];
  paymentMethod: PaymentMethod;
  couponCode?: string;
  isPaid: boolean;
  sendEmailToUser: boolean;
}

export const initialValues: IFormCreateUserOrderValues = {
  userId: 0,
  tests: [{packages: [], horseId: 0}],
  paymentMethod: PaymentMethod.Invoice,
  isPaid: false,
  sendEmailToUser: false,
};

export const validationSchema = Yup.object().shape<
  Pick<IFormCreateUserOrderValues, 'userId' | 'tests' | 'paymentMethod'>
>({
  userId: Yup.number().required(),
  paymentMethod: Yup.mixed<PaymentMethod>().required(),
  tests: Yup.array().of(
    Yup.object().shape({
      packages: Yup.array().of(Yup.number()).required('Package is required'),
      horseId: Yup.number().required('Horse is required').moreThan(0, 'Horse is required'),
    })
  ),
});

export function convertFormValuesToRequest(
  values: IFormCreateUserOrderValues,
  couponId?: number
): IAdminCreateUserOrderRequest {
  const {tests, paymentMethod, couponCode, ...rest} = values;
  return {
    horses: convertTestsToIHorseTestRequest(tests),
    coupon: couponId ? {id: couponId} : undefined,
    paymentMethod: paymentMethod,
    ...rest,
  };
}

const couponErrors: Record<CouponError, string> = {
  [CouponError.EntityNotFound]: `Coupon not found.`,
  [CouponError.CannotBeAppliedCoupon]: `Coupon doesn't contain selected packages and can’t be used. You can use another coupon or change packages.`,
  [CouponError.CouponIsDisabled]: `Coupon is inactive.`,
  [CouponError.ExpiryCoupon]: `Coupon is expired.`,
};

const DEFAULT_COUPON_ERROR = 'Error on loading coupon.';

export function getCouponError(errorResponse: IResponseError): string {
  const error = errorResponse?.errors?.[0];
  if (error?.errorCode) {
    return couponErrors[error.errorCode] || DEFAULT_COUPON_ERROR;
  }
  return error?.description || DEFAULT_COUPON_ERROR;
}
