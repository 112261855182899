import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {castToOption, getStringKeysOption} from 'Common/helpers/OptionHelper';
import {DiscountType, DiscountTypeOptions} from 'Coupon/constants/DiscountType';

import {
  ICouponForm,
  initialValue,
  validationSchema,
} from 'Admin/AdminDashboard/components/Coupons/CouponForm/validation';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminCoupons';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {IAppState} from 'Common/store/IAppState';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {CouponStatus} from 'Coupon/constants/CouponStatus';
import {FormType} from 'Common/constants/FormType';
import Nebula from 'Common/components/Layout/Nebula';
import {
  convertCouponFormToCouponRequest,
  convertCouponToCouponForm,
} from 'Admin/AdminDashboard/components/Coupons/CouponForm/converters/coupon';
import {DateTimeField, InputField, SelectField} from 'Common/components/FormFields';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import Loading from 'Loading/components/Loading';
import {FRONTEND_DATE_TIME} from 'Common/constants/Date';
import {useDictionaries} from 'Common/store/useDictionaries';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import {IPackageSimple, PackageStatus} from 'Dictionaries/models/IPackageSimple';
import {sortByName} from 'Common/helpers/sortByName';
import {INamedEntity} from 'Common/models/INamedEntity';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';

const units = getStringKeysOption(DiscountType).map((item) => ({
  value: item.value,
  label: item.label === DiscountType.Amount ? DiscountTypeOptions.Dollars : DiscountTypeOptions.Percent,
}));

const FieldControlContainerStyle = styled.div`
  margin-left: 16px;
`;

const Title = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${ColorPalette.black0};
  margin-bottom: 8px;
`;

const NewCodeButton = styled(PrimaryButton)`
  margin-top: 6px;
`;

const NoPackagesError = styled.div`
  margin-bottom: 8px;
  font-family: ${Typography.family.roboto};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  color: ${ColorPalette.red13};
`;

const formHeaderByType: Record<FormType.create | FormType.edit | FormType.createBased, string> = {
  create: 'Add coupon',
  edit: 'Edit coupon',
  createBased: 'Add coupon',
};

const buttonHeaderByType: Record<FormType.create | FormType.edit | FormType.createBased, string> = {
  create: 'Add coupon',
  edit: 'Save coupon',
  createBased: 'Add coupon',
};

interface IExternalDictionaries {
  userPackagesDictionary: ISimpleDictionary<IPackageSimple>;
  associationPackagesDictionary: ISimpleDictionary<INamedEntity>;
  associationPurchasableTestsDictionary: ISimpleDictionary<INamedEntity>;
}

interface IExternalProps extends IExternalDictionaries {
  couponId?: number;
  type: FormType;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<ICouponForm> & OuterProps;

const CouponsFormLayout = (props: AllProps) => {
  const nebulaStyle = {opacity: 0.5};

  const {status, setStatus, isSubmitting, setFieldValue, values} = props;
  const {couponId, type} = props;
  const {
    coupon,
    userPackages,
    userPackagesLoading,
    associationPackages,
    associationPackagesLoading,
    singleTests,
    singleTestsLoading,
    getCouponDetails,
    resetCouponDetails,
    userPackagesDictionary,
    associationPackagesDictionary,
    associationPurchasableTestsDictionary,
    couponLoading,
    couponCreating,
    couponUpdating,
    generateNewCodeRequesting,
    onSuccess,
    generateNewCode,
  } = props;

  const {
    actions: {getItems: getUserPackages},
  } = userPackagesDictionary;

  const {
    actions: {getItems: getAssociationPackages},
  } = associationPackagesDictionary;

  const {
    actions: {getItems: getAssociationPurchasableTests},
  } = associationPurchasableTestsDictionary;

  useOnSuccessCommunication(couponCreating, onSuccess);
  useOnSuccessCommunication(couponUpdating, onSuccess);

  const [codeWasGenerated, setCodeWasGenerated] = useState<boolean>(false);
  const [noPackages, setNoPackages] = useState<boolean>(false);

  const errorInfo =
    associationPackagesLoading.error ||
    singleTestsLoading.error ||
    userPackagesLoading.error ||
    couponLoading.error ||
    couponCreating.error ||
    couponUpdating.error ||
    generateNewCodeRequesting.error;

  const isLoading = [
    associationPackagesLoading,
    userPackagesLoading,
    singleTestsLoading,
    couponLoading,
    generateNewCodeRequesting,
  ].some((value) => value.isRequesting);

  useEffect(() => {
    const {userPackages, associationPackages, associationPurchasableTests} = values;
    const noPackages =
      userPackages?.length === 0 && associationPackages?.length === 0 && associationPurchasableTests?.length === 0;
    setNoPackages(noPackages);
  }, [values]);

  const isValid = props.submitCount === 0 || props.isValid;

  const isLightLock =
    (coupon?.status === CouponStatus.Expired || coupon?.status === CouponStatus.Active) && type === FormType.edit;

  const header = formHeaderByType[type];
  const saveButtonHeader = buttonHeaderByType[type];

  const generateNewCodeHandler = useCallback(async () => {
    try {
      const newCode = await generateNewCode();
      setFieldValue('code', newCode);
      setCodeWasGenerated(true);
    } catch (error) {
      return;
    }
  }, [generateNewCode, setFieldValue]);

  useEffect(() => {
    const commonError = getCommonErrors(errorInfo);
    commonError && setStatus(commonError);
  }, [errorInfo, setStatus]);

  useEffect(() => {
    const fetchData = async () => {
      await getUserPackages();
      await getAssociationPackages();
      await getAssociationPurchasableTests();
      if (couponId) {
        await getCouponDetails(couponId);
      } else {
        await resetCouponDetails();
      }
    };

    fetchData().then(() => {
      if (type === FormType.createBased) {
        generateNewCodeHandler();
      }
    });

    return resetCouponDetails;
  }, [
    couponId,
    generateNewCodeHandler,
    getAssociationPackages,
    getCouponDetails,
    getAssociationPurchasableTests,
    getUserPackages,
    resetCouponDetails,
    type,
  ]);

  const getPackagesOptions = useCallback(
    (packages: IPackageSimple[]) => {
      const mappedPackages = [...packages, ...(coupon?.userPackages || [])].map((x) =>
        x.status ? x : {...x, status: PackageStatus.Active}
      );

      return mappedPackages
        .sort(sortByName)
        .map((i) => (i.status !== PackageStatus.Active ? {...i, name: `${i.name} (${i.status?.toUpperCase()})`} : i));
    },
    [coupon?.userPackages]
  );

  return (
    <>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent style={{paddingBottom: 32}}>
          {(isLoading || isSubmitting) && <Loading />}
          <Nebula active={isLightLock} style={nebulaStyle}>
            <div className="d-flex w-75">
              <div className="w-50">
                <InputField
                  isRequired={true}
                  name="code"
                  type="text"
                  label="Code"
                  placeholder="Coupon code"
                  autoComplete="off"
                />
              </div>
              {type !== FormType.edit && (
                <FieldControlContainerStyle className="w-50 d-flex justify-content-start align-items-center">
                  <NewCodeButton
                    type="button"
                    variant={codeWasGenerated ? 'outlined' : 'contained'}
                    onClick={generateNewCodeHandler}
                    isLoading={generateNewCodeRequesting.isRequesting}
                    disabled={codeWasGenerated}
                    style={{color: codeWasGenerated ? ColorPalette.green2 : 'default'}}
                  >
                    {codeWasGenerated ? 'New code generated' : 'Generate new code'}
                  </NewCodeButton>
                </FieldControlContainerStyle>
              )}
            </div>
            <div className="d-flex w-75">
              <div className="w-50">
                <InputField
                  isRequired={true}
                  id="amount"
                  name="amount"
                  type="text"
                  label="Off value"
                  placeholder="Off value"
                />
              </div>
              <FieldControlContainerStyle className="w-50">
                <SelectField
                  isRequired={true}
                  name="discountType"
                  label="Units"
                  options={units}
                  defaultValue={units[0]}
                />
              </FieldControlContainerStyle>
            </div>
          </Nebula>
          <div className="d-flex w-50">
            <div className="w-50">
              <InputField
                isRequired={true}
                name="countBeforeExpiry"
                type="text"
                label="Max redemptions"
                placeholder="0 for no limit"
              />
            </div>
            <FieldControlContainerStyle className="w-50">
              <InputField
                isRequired={true}
                name="maxPerUser"
                type="text"
                label="Max per user"
                placeholder="0 for no limit"
              />
            </FieldControlContainerStyle>
          </div>
          <div className="d-flex w-75">
            <div className="w-50">
              <DateTimeField
                isRequired={true}
                futureEnabled={true}
                name="startDate"
                label="Valid from"
                dateFormat={FRONTEND_DATE_TIME}
                showTime={true}
              />
            </div>
            <FieldControlContainerStyle className="w-50">
              <DateTimeField
                isRequired={true}
                futureEnabled={true}
                name="expiryDate"
                label="Valid until"
                dateFormat={FRONTEND_DATE_TIME}
                showTime={true}
              />
            </FieldControlContainerStyle>
          </div>

          <Title>Packages and tests</Title>
          {noPackages && <NoPackagesError>Add at least one package or test</NoPackagesError>}
          <SelectField
            isMulti={true}
            name="userPackages"
            label="Individual user packages"
            options={castToOption(getPackagesOptions(userPackages))}
            memoized={true}
            isOptionDisabled={(option) => option.label.includes('(DISABLED)' || '(ARCHIVED)')}
          />
          <SelectField
            isMulti={true}
            name="associationPackages"
            label="Association packages"
            options={castToOption(associationPackages)}
            memoized={true}
            isOptionDisabled={(option) => option.label.includes('(DISABLED)' || '(ARCHIVED)')}
          />
          <SelectField
            isMulti={true}
            name="associationPurchasableTests"
            label="Single tests"
            options={castToOption(singleTests)}
            memoized={true}
            isOptionDisabled={(option) => option.label.includes('(DISABLED)' || '(ARCHIVED)')}
          />
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" disabled={isLoading || !isValid || noPackages} isLoading={isSubmitting}>
            {saveButtonHeader}
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const CouponForm = withFormik<OuterProps, ICouponForm>({
  mapPropsToValues: ({coupon}) => (coupon ? convertCouponToCouponForm(coupon) : initialValue),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, updateCoupon, createCoupon} = formikBag.props;
    const convertedValues = convertCouponFormToCouponRequest(values);
    const request = type === FormType.create || type === FormType.createBased ? createCoupon : updateCoupon;
    await request(convertedValues);
  },
  enableReinitialize: true,
})(CouponsFormLayout);

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {userPackagesDictionary, associationPackagesDictionary, associationPurchasableTestsDictionary} = props;
  const {selectors: userPackageSelectors} = userPackagesDictionary;
  const {selectors: associationPackagesSelectors} = associationPackagesDictionary;
  const {selectors: singleTestsSelectors} = associationPurchasableTestsDictionary;

  return {
    coupon: selectors.selectCouponDetails(state),
    userPackages: userPackageSelectors.selectItems(state),
    userPackagesLoading: userPackageSelectors.selectCommunication(state, 'itemsLoading'),
    associationPackages: associationPackagesSelectors.selectItems(state),
    associationPackagesLoading: associationPackagesSelectors.selectCommunication(state, 'itemsLoading'),
    singleTests: singleTestsSelectors.selectItems(state),
    singleTestsLoading: singleTestsSelectors.selectCommunication(state, 'itemsLoading'),
    couponLoading: selectors.selectCommunication(state, 'couponDetailsLoading'),
    couponCreating: selectors.selectCommunication(state, 'couponCreating'),
    couponUpdating: selectors.selectCommunication(state, 'couponUpdating'),
    generateNewCodeRequesting: selectors.selectCommunication(state, 'generateNewCodeRequesting'),
  };
};

const mapDispatchToProps = {
  getCouponDetails: actions.getCouponById,
  createCoupon: actions.createCoupon,
  updateCoupon: actions.updateCoupon,
  generateNewCode: actions.generateNewCode,
  resetCouponDetails: actions.resetCouponDetailResults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(React.memo(CouponForm));
const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {activePackages, associationActivePackagesAdmin, associationActivePurchasableTestsAdmin} = useDictionaries();

  return (
    <Connected
      {...externalProps}
      userPackagesDictionary={activePackages}
      associationPackagesDictionary={associationActivePackagesAdmin}
      associationPurchasableTestsDictionary={associationActivePurchasableTestsAdmin}
    />
  );
};

export default Exported;
