import {IHorseTransferAdmin} from 'Admin/AdminDashboard/models/Transfer/IHorseTransferAdmin';
import {IHorseTransferDetailsAdmin} from 'Admin/AdminDashboard/models/Transfer/IHorseTransferDetailsAdmin';
import {IListResponse, IPagination} from 'Common/models/IResponse';
import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {Nullable} from 'Common/types';

const STATE_NAME = 'adminHorseTransfer';

export interface IAdminHorseTransferState {
  data: {
    horseTransfers: IHorseTransferAdmin[];
    horseTransferDetails: Nullable<IHorseTransferDetailsAdmin>;
  };
  communications: {
    horseTransfersLoading: ICommunication;
    horseTransferRejecting: ICommunication;
    horseOwnerChanging: ICommunication;
    horseTransferDetailsLoading: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getHorseTransfersActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_TRANSFERS');
export type getHorseTransfersActions = MakeCommunicationActions<
  typeof getHorseTransfersActionTypes,
  {success: IListResponse<IHorseTransferAdmin>}
>;

export const getHorseTransferDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_TRANSFER_DETAILS');
export type getHorseTransferDetailsActions = MakeCommunicationActions<
  typeof getHorseTransferDetailsActionTypes,
  {success: IHorseTransferDetailsAdmin}
>;

export const rejectHorseTransferActionTypes = makeCommunicationActionType(STATE_NAME, 'REJECT_HORSE_TRANSFER');
export type regectHorseTransferActions = MakeCommunicationActions<typeof rejectHorseTransferActionTypes, {}>;

export const changeHorseOwnerActionTypes = makeCommunicationActionType(STATE_NAME, 'CHANGE_HORSE_OWNER');
export type changeHorseOwnerActions = MakeCommunicationActions<typeof changeHorseOwnerActionTypes, {}>;

export const resetHorseTransferDetailsActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'HORSE_TRANSFER_DETAILS'
);
export type resetHorseTransferDetailsActions = MakeResetCommunicationActions<
  typeof resetHorseTransferDetailsActionTypes
>;

export type Actions =
  | getHorseTransfersActions
  | regectHorseTransferActions
  | changeHorseOwnerActions
  | getHorseTransferDetailsActions
  | resetHorseTransferDetailsActions;
