import {ICreateHorseOwnerBP} from 'BusinessPortal/models/owner/IOwnerBP';
import {ICreateOwnerFormValues} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseOwnerSection/HorseOwnerSectionEdit/common/validation';

export const convertToServer = (data: ICreateOwnerFormValues): ICreateHorseOwnerBP => {
  const dataToSave: ICreateHorseOwnerBP = {
    ...data,
    address: null,
    phone: ''
  };

  delete dataToSave['id'];
  delete dataToSave['avatar'];

  return dataToSave;
};
