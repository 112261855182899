import {ICommunication} from 'Common/store/utils/communication';
import {usePrevious} from './usePrevious';

export function useOnSuccessCommunication<T>(communication: ICommunication<T>, onSuccess: () => void) {
  const prevCommunication = usePrevious<ICommunication<T>>(communication);

  if (prevCommunication?.isRequesting && !communication.isRequesting && communication.isSuccess) {
    setTimeout(() => onSuccess(), 0); // setTimeout is needed because useEffect executes after render
  }
}
