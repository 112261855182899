import React, {memo} from 'react';
import styled from 'styled-components';

import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {GeneType} from 'Admin/AdminPhenotypes/constants/GeneType';
import {ElementPosition, HorizontalElementPosition} from 'FoalProfile/models/IElementPosition';

const Gene = styled.div`
  margin-right: 10px;
`;

const Chromatid = styled.div`
  margin-right: 4px;
`;

interface IProps {
  state: GeneType;
}

function Allele(props: IProps) {
  const {state} = props;

  function renderChromatid(position: HorizontalElementPosition) {
    const enableChromatid =
      (position === ElementPosition.Left && (state === GeneType.Xn || state === GeneType.Xx)) ||
      (position === ElementPosition.Right && state === GeneType.Xx);

    const chromatidColor = enableChromatid ? ColorPalette.red7 : ColorPalette.gray48;

    return (
      <Chromatid className="d-flex flex-column">
        <ColoredIcon
          name={IconName.AlleleShort}
          height={16}
          width={7}
          fill={true}
          stroke={false}
          color={chromatidColor}
        />
        <ColoredIcon
          name={IconName.AlleleLong}
          height={26}
          width={7}
          fill={true}
          stroke={false}
          color={chromatidColor}
          style={{marginTop: -1}}
        />
      </Chromatid>
    );
  }

  return (
    <Gene className="d-flex">
      {renderChromatid(ElementPosition.Left)}
      {renderChromatid(ElementPosition.Right)}
    </Gene>
  );
}

export default memo(Allele);
