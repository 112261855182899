import React, {useState, useCallback} from 'react';

import WarningModalWindow, {ModalTypes} from 'Common/components/Modal/WarningModal';
import {ICommunication, initialCommunication} from 'Common/store/utils/communication';
import {useToast} from './useToast';
import {useOnErrorCommunication} from './useOnErrorCommunication';
import {getCommonErrors} from '../ErrorHelper';
import {useOnSuccessCommunication} from './useOnSuccessCommunication';

interface IConfirmModalParams {
  confirmDescription?: string;
  confirmCommunication?: ICommunication;
  successMessage?: string;
  errorMessage?: string;
  confirmId?: string;
  onConfirmAction(...params: any): void;
  onSuccess?(): void;
}

export function useConfirmModal(params: IConfirmModalParams) {
  const {
    confirmDescription,
    confirmCommunication,
    successMessage,
    errorMessage,
    confirmId = 'id',
    onConfirmAction,
    onSuccess
  } = params;

  const [isOpen, setIsOpen] = useState(false);
  const [actionParams, setActionParams] = useState<any | null>(null);
  const [confirmModalId, setConfirmModalId] = useState<string | null>(null);

  const openConfirmModal = useCallback(
    (...params: any) => {
      params && setActionParams(params);
      setIsOpen(true);
      setConfirmModalId(confirmId);
    },
    [confirmId]
  );

  const closeConfirmModal = useCallback(() => {
    setActionParams(null);
    setIsOpen(false);
  }, []);

  const {addToast} = useToast();

  const errorInfo = confirmCommunication?.error;
  const onError = useCallback(() => {
    if (confirmId !== confirmModalId) {
      return;
    }
    if (errorMessage) {
      addToast(errorMessage, 'error');
      return;
    }

    const commonErrors = getCommonErrors(errorInfo);
    if (commonErrors) {
      addToast(commonErrors, 'error');
    }
  }, [confirmId, confirmModalId, errorMessage, errorInfo, addToast]);

  const onSuccessCommunication = useCallback(() => {
    if (confirmId !== confirmModalId) {
      return;
    }
    addToast(successMessage || 'Success');
  }, [addToast, confirmId, confirmModalId, successMessage]);

  useOnSuccessCommunication(confirmCommunication || initialCommunication, onSuccessCommunication);
  useOnErrorCommunication(confirmCommunication || initialCommunication, onError);

  const handleSuccess = useCallback(async () => {
    closeConfirmModal();
    if (actionParams) {
      onConfirmAction && (await onConfirmAction(...actionParams));
    } else {
      onConfirmAction && (await onConfirmAction());
    }
    setConfirmModalId(null);
    onSuccess && onSuccess();
  }, [closeConfirmModal, actionParams, onSuccess, onConfirmAction]);

  const confirmModal = (
    <WarningModalWindow
      modalType={ModalTypes.Confirm}
      isOpen={isOpen}
      onClose={closeConfirmModal}
      onSuccess={handleSuccess}
    >
      {confirmDescription}
    </WarningModalWindow>
  );

  return {
    confirmModal: confirmModal,
    openConfirmModal: openConfirmModal
  };
}
