import {action} from 'typesafe-actions';

import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {
  changePackageStatusActions,
  changePackageStatusActionTypes,
  createPackageActions,
  createPackageActionTypes,
  deletePackageActions,
  deletePackageActionTypes,
  getPackageByIdActions,
  getPackageByIdActionTypes,
  getPackagesActions,
  getPackagesActionTypes,
  resetPackageDetailsActions,
  resetPackageDetailsActionTypes,
  updatePackageActions,
  updatePackageActionTypes,
  updatePackageCartSettingsActions,
  updatePackageCartSettingsActionTypes,
} from './types';
import {IRequestParams} from 'Common/models/IRequestParams';
import PackageAdminDataService from 'Admin/AdminDashboard/services/PackageDataService';
import {
  IPackageCartSettingsRequest,
  IPackageCreateRequest,
  IPackageUpdateRequest,
} from 'Admin/AdminDashboard/models/Package/IPackageAdmin';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const getPackages =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getPackagesActions> =>
  async (dispatch) => {
    dispatch(action(getPackagesActionTypes.REQUEST));
    try {
      const res = await PackageAdminDataService.getPackages(params);
      dispatch(action(getPackagesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getPackagesActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };

export const getPackagesByFilter =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getPackagesActions> =>
  async (dispatch) => {
    dispatch(action(getPackagesActionTypes.REQUEST));
    try {
      const res = await PackageAdminDataService.getPackagesByFilter(params);
      dispatch(action(getPackagesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getPackagesActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };

export const getPackageById =
  (packageId: number): ActionResult<IAppState, void, getPackageByIdActions> =>
  async (dispatch) => {
    dispatch(action(getPackageByIdActionTypes.REQUEST));
    try {
      const result = await PackageAdminDataService.getPackage(packageId);
      dispatch(action(getPackageByIdActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getPackageByIdActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createPackage =
  (params: IPackageCreateRequest): ActionResult<IAppState, void, createPackageActions> =>
  async (dispatch) => {
    dispatch(action(createPackageActionTypes.REQUEST));
    try {
      await PackageAdminDataService.createPackage(params);
      dispatch(action(createPackageActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createPackageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updatePackage =
  (params: IPackageUpdateRequest): ActionResult<IAppState, void, updatePackageActions> =>
  async (dispatch) => {
    dispatch(action(updatePackageActionTypes.REQUEST));
    try {
      const result = await PackageAdminDataService.updatePackage(params);
      dispatch(action(updatePackageActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(updatePackageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deletePackage =
  (id: number): ActionResult<IAppState, void, deletePackageActions> =>
  async (dispatch) => {
    dispatch(action(deletePackageActionTypes.REQUEST));
    try {
      await PackageAdminDataService.deletePackage(id);
      dispatch(action(deletePackageActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deletePackageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const changePackageStatus =
  (packageId: number, isEnabled: boolean): ActionResult<IAppState, void, changePackageStatusActions> =>
  async (dispatch) => {
    dispatch(action(changePackageStatusActionTypes.REQUEST));
    try {
      await PackageAdminDataService.changePackageStatus(packageId, isEnabled);
      dispatch(action(changePackageStatusActionTypes.SUCCESS, {packageId}));
    } catch (error) {
      dispatch(action(changePackageStatusActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updatePackageCartSettings =
  (packageCartSettings: IPackageCartSettingsRequest): ActionResult<IAppState, void, updatePackageCartSettingsActions> =>
  async (dispatch) => {
    dispatch(action(updatePackageCartSettingsActionTypes.REQUEST));
    try {
      await PackageAdminDataService.updatePackageCartSettings(packageCartSettings);
      dispatch(action(updatePackageCartSettingsActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updatePackageCartSettingsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetPackageDetailResults =
  (): ActionResult<IAppState, void, resetPackageDetailsActions> => async (dispatch) => {
    dispatch(action(resetPackageDetailsActionTypes.RESET));
  };
