import {combineReducers} from 'redux';

import {
  IBusinessPortalOwnerProfileState,
  OwnerProfileActions,
  getOwnerActionTypes,
  getHorsesByOwnerActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationOwnerProfileReducer = combineReducers<
  IBusinessPortalOwnerProfileState['communications'],
  OwnerProfileActions
>({
  horsesByOwnerLoading: makeCommunicationReducerFromEnum(getHorsesByOwnerActionTypes),
  ownerLoading: makeCommunicationReducerFromEnum(getOwnerActionTypes),
});
