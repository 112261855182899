import React, {memo} from 'react';
import styled from 'styled-components';

import BusinessPortalLayout from 'BusinessPortal/components/common/BusinessPortalLayout/BusinessPortalLayout';
import {ITab} from 'Common/components/Tabs/ITab';
import Horses from 'BusinessPortal/components/BusinessPortalDashboard/Horses/Horses';
import BusinessPortalTabs from 'BusinessPortal/components/common/BusinessPortalTabs/BusinessPortalTabs';
import TabsContent from 'Common/components/Tabs/TabsContent';
import Owners from './Owners/Owners';
import Orders from 'BusinessPortal/components/BusinessPortalDashboard/Orders/Orders';
import Favorites from './Favorites/Favorites';
import Excluded from './Excluded/Excluded';
import {PageContainer, PageTitle} from '../common/styled';

const ContentWrapper = styled.div`
  margin-top: 8px;
  flex: 1 0 auto;
`;

const basePath = '/business-portal/dashboard';

const data: ITab[] = [
  {section: 'horses', title: 'Horses', Component: Horses, defaultSection: true},
  {section: 'owners', title: 'Owners', Component: Owners},
  {section: 'orders', title: 'Orders', Component: Orders},
  {section: 'favorites', title: 'Favorites', Component: Favorites},
  {section: 'excluded', title: 'Excluded', Component: Excluded},
];

const BusinessPortalDashboard = () => {
  return (
    <BusinessPortalLayout>
      <PageTitle>Dashboard</PageTitle>

      <PageContainer className="d-flex h-100 flex-column">
        <BusinessPortalTabs tabs={data} />
        <ContentWrapper className="d-flex flex-column">
          <TabsContent tabs={data} basePath={basePath} />
        </ContentWrapper>
      </PageContainer>
    </BusinessPortalLayout>
  );
};

export default memo(BusinessPortalDashboard);
