import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IMailConfirmationState, mailConfirmationActions, MailConfirmationActionTypes} from './types';
import {IMailConfirmation} from 'MailConfirmation/models/IMailConfirmation';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import MailConfirmationService from 'MailConfirmation/services/MailConfirmationService';

export const mailConfirm = (
  data: IMailConfirmation
): ActionResult<IMailConfirmationState, void, mailConfirmationActions> => async dispatch => {
  try {
    dispatch(action(MailConfirmationActionTypes.REQUEST));
    const confirmMessage = await MailConfirmationService.confirmEmail(data);
    dispatch(action(MailConfirmationActionTypes.SUCCESS, confirmMessage));
  } catch (error) {
    dispatch(action(MailConfirmationActionTypes.FAILURE, getErrorMessage(error)));
  }
};
