import {IModule} from 'redux-dynamic-modules';

import {IAdminAssociationOwnerProfileState} from './types';
import {ownerProfileReducer} from './reducer';

export interface AdminAssociationOwnerProfileModuleState {
  adminAssociationOwnerProfile: IAdminAssociationOwnerProfileState;
}

export const AdminAssociationOwnerProfileModule: IModule<AdminAssociationOwnerProfileModuleState> = {
  id: 'adminAssociationOwnerProfile',
  reducerMap: {
    adminAssociationOwnerProfile: ownerProfileReducer,
  },
};
