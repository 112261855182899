import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

const STATE_NAME = 'adminRulesBackup';

export interface IAdminRulesBackupState {
  communications: {
    adminRulesBackupDownloading: ICommunication;
  };
}

export const downloadRulesBackupActionTypes = makeCommunicationActionType(STATE_NAME, 'DOWNLOAD_RULES_BACKUP');

export type downloadRulesBackupActions = MakeCommunicationActions<typeof downloadRulesBackupActionTypes, {}>;

export type RulesBackupActions = downloadRulesBackupActions;
