import {ICreatedOrder} from 'BusinessPortal/models/order/createOrder/ICreatedOrder';
import {convertPriceToClient} from 'Common/helpers/convertPrices';
import {ICreateOrderRequest} from 'BusinessPortal/models/order/createOrder/ICreateOrderRequest';

import {IServerCreateOrderRequest, IOrderHorseRequest, IServerOrderHorse} from 'BusinessPortal/services/types/order';
import {IHorseOrder} from 'BusinessPortal/models/order/createOrder/IHorseOrder';
import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {convertUserName} from 'Common/helpers/convertUserName';

export function convertCreatedOrderToClient(createdOrder: ICreatedOrder): ICreatedOrder {
  return {
    ...createdOrder,
    totalAmount: convertPriceToClient(createdOrder.totalAmount),
  };
}

export function convertCreateOrderRequestToServer(request: ICreateOrderRequest): IServerCreateOrderRequest {
  return {
    ...request,
    horses: convertHorseOrdersToServer(request.horses),
  };
}

export function convertHorseOrdersToServer(orders: IHorseOrder[]): IOrderHorseRequest[] {
  return orders.map((order) => ({
    id: order.horseId,
    couponId: order.couponId,
    packageIds: order.packages?.map((i) => i) || [],
    testIds: order.tests?.map((i) => i) || [],
  }));
}

export function convertOrderHorseToClient(orderHorse: IServerOrderHorse): IOrderHorse {
  return {
    ...withBirthDate(orderHorse),
    ownerName: convertUserName(orderHorse.ownerFirstName, orderHorse.ownerLastName),
  };
}
