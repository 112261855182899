import React, {memo, useCallback, useEffect, useState} from 'react';
import {FieldArray, Form, FormikProps, withFormik} from 'formik';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminUsers/userPermission/index';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import Loading from 'Loading/components/Loading';
import {IUserPermission} from 'Admin/AdminDashboard/models/User/IUserPermissions';
import {
  convertFormValuesToUserPermissionRequest,
  convertUserPermissionServerToFormValues,
  IFormValues,
  initialValue,
  validationSchema,
} from './validation';
import {IPermission} from 'Permissions/model/IPermission';
import ColorPalette from 'Common/constants/ColorPalette';
import {Permission} from 'Permissions/constants/Permission';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import PermissionSwitcher from './parts/PermissionSwitcher';
import {useDictionaries} from 'Common/store/useDictionaries';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';

const Root = styled.div`
  margin-left: 1px;
`;

const FieldLabel = styled.div`
  font-size: 18px;
  color: ${ColorPalette.gray18};
`;

const FieldName = styled.div`
  font-size: 18px;
  color: ${ColorPalette.black1};
  padding: 8px 0;
  margin-bottom: 16px;
`;

const PermissionList = styled.div`
  margin-bottom: 24px;
`;

const SubHead = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${Theme.color.gray};
`;

interface IExternalProps {
  userId: number;
  userName?: string;
  onSuccess(): void;
  permissionsDictionary: ISimpleDictionary<IPermission>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const UserPermissionsUpdate = (props: AllProps) => {
  const {isSubmitting, status, setStatus, setFieldValue, values} = props;
  const {userId, onSuccess} = props;
  const {
    userName,
    userPermissionsLoading,
    userPermissionsUpdating,
    permissionsLoading,
    getUserPermissions,
    permissionsDictionary,
  } = props;

  const {
    actions: {getItems: getPermissions},
  } = permissionsDictionary;

  useEffect(() => {
    getPermissions();
    getUserPermissions(userId);
  }, [userId, getPermissions, getUserPermissions]);

  useOnSuccessCommunication(userPermissionsUpdating, onSuccess);

  const errorInfo = userPermissionsLoading.error || userPermissionsUpdating.error || permissionsLoading.error;
  const isRequesting = [userPermissionsLoading, userPermissionsUpdating, permissionsLoading].some(
    (value) => value.isRequesting
  );

  useEffect(() => {
    const commonError = getCommonErrors(errorInfo);
    commonError && setStatus(commonError);
  }, [errorInfo, setStatus]);

  const [userPermissionsValues, setUserPermissionsValues] = useState<IUserPermission[]>([]);

  useEffect(() => {
    if (values) {
      setUserPermissionsValues(values.permissions.filter((item) => item.code !== Permission.Admin) || []);
    }
  }, [values]);

  const onPermissionHandler = useCallback(
    (name: string, value: boolean) => {
      setFieldValue('permissions', [
        ...values.permissions.map((item) => ({...item, isEnable: item.code === name ? value : item.isEnable})),
      ]);
    },
    [setFieldValue, values.permissions]
  );

  return (
    <>
      <ModalWindowHeader>Permission settings</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          <Root>
            {isRequesting && <Loading />}
            <FieldLabel>User name</FieldLabel>
            <FieldName>{userName}</FieldName>

            <SubHead>User permissions</SubHead>
            <FieldArray
              name="permissions"
              render={() => {
                return (
                  <PermissionList>
                    {userPermissionsValues?.map((permission, i) => (
                      <PermissionSwitcher
                        key={i}
                        permission={permission}
                        permissions={values.permissions}
                        fieldName="permissions"
                        onClick={onPermissionHandler}
                      />
                    ))}
                  </PermissionList>
                );
              }}
            />
          </Root>
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" isLoading={isSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const UserPermissionsUpdateFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({userPermissions, permissions}) =>
    userPermissions ? convertUserPermissionServerToFormValues(userPermissions, permissions) : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    await formikBag.props.updateUserPermissions(convertFormValuesToUserPermissionRequest(values));
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(UserPermissionsUpdate);

const mapStateToProps = (state: IAppState, {permissionsDictionary}: IExternalProps) => ({
  permissions: permissionsDictionary.selectors.selectItems(state),
  userPermissions: selectors.selectUserPermissions(state),
  userPermissionsLoading: selectors.selectCommunication(state, 'userPermissionsLoading'),
  userPermissionsUpdating: selectors.selectCommunication(state, 'userPermissionsUpdating'),
  permissionsLoading: permissionsDictionary.selectors.selectCommunication(state, 'itemsLoading'),
});

const mapDispatchToProps = {
  getUserPermissions: actions.getUserPermissions,
  updateUserPermissions: actions.updateUserPermissions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(UserPermissionsUpdateFormik));
const Exported = (externalProps: Omit<IExternalProps, 'permissionsDictionary'>) => {
  const {permissions} = useDictionaries();

  return <Connected {...externalProps} permissionsDictionary={permissions} />;
};

export default Exported;
