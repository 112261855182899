import React, {useCallback, useEffect} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {initialValue, IFormValues, validationSchema} from './validation';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {IAppState} from 'Common/store/IAppState';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {FormType} from 'Common/constants/FormType';
import {InputField} from 'Common/components/FormFields';
import {actions as testActions, selectors as testSelectors} from 'Admin/AdminAssociations/store/adminPurchasableTests';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {useDictionaries} from 'Common/store/useDictionaries';
import {convertToClient, convertToServerUpdating} from './converters';
import {Field, FieldLabel, FieldValue} from 'Admin/common/styled/StyledComponents';
import {IFoundOrganization} from 'Admin/AdminAssociations/models/IFoundOrganization';
import SpecialPriceField from '../../shared/SpecialPriceField';
import {useToast} from 'Common/helpers/hooks/useToast';

const CHECKBOX_VALIDATION_MESSAGE = 'At least one option must be selected';

const formHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add test',
  edit: 'Edit test',
};

const buttonHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add',
  edit: 'Save',
};

interface IExternalDictionaries {
  adminOrganizationDictionary: ISimpleDictionary<IFoundOrganization>;
}

interface IExternalProps extends IExternalDictionaries {
  testId?: number;
  type: FormType;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const PurchasableTestForm = (props: AllProps) => {
  const {status, setStatus, setErrors, isSubmitting, values, handleSubmit} = props;
  const {adminOrganizationDictionary, testId, organizations, type, onSuccess} = props;
  const {
    purchasableTestDetails,
    getPurchasableTestDetails,
    resetPurchasableTestDetailResults,
    purchasableTestUpdating,
    purchasableTestDetailsLoading,
    organizationsLoading: associationsLoading,
  } = props;

  const errorInfo = purchasableTestDetailsLoading.error || associationsLoading.error || purchasableTestUpdating.error;
  const isRequesting = [purchasableTestDetailsLoading, associationsLoading, purchasableTestUpdating].some(
    (i) => i.isRequesting
  );

  const header = formHeaderByType[type];
  const saveButtonHeader = buttonHeaderByType[type];

  useOnSuccessCommunication(purchasableTestUpdating, onSuccess);

  const {
    actions: {getItems: getOrganizations},
  } = adminOrganizationDictionary;

  useEffect(() => {
    getOrganizations();
    const fetchData = async () => {
      if (testId) {
        await getPurchasableTestDetails(testId);
      } else {
        await resetPurchasableTestDetailResults();
      }
    };

    fetchData();

    return () => resetPurchasableTestDetailResults();
  }, [testId, getPurchasableTestDetails, getOrganizations, resetPurchasableTestDetailResults]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    const fieldErrors = getFieldErrors(errorInfo);

    if (commonErrors) {
      setStatus(commonErrors);
    }

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  const {addToast} = useToast();

  const onSaveClick = useCallback(() => {
    if (!values.isAvailabilityAllOrganizations && !values.isSpecialPrices) {
      addToast(CHECKBOX_VALIDATION_MESSAGE, 'error');
      return;
    }
    handleSubmit();
  }, [addToast, handleSubmit, values.isAvailabilityAllOrganizations, values.isSpecialPrices]);

  return (
    <>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {isRequesting && <Loading />}
          <Field className="col-4">
            <FieldLabel>Test</FieldLabel>
            <FieldValue>{purchasableTestDetails?.test.name}</FieldValue>
          </Field>
          <InputField
            isRequired={true}
            name="name"
            type="text"
            label="Displayed name"
            placeholder="Name shown in portal"
            className="w-50"
            autoComplete="off"
            required
          />

          <SpecialPriceField
            specialPrices={values.specialPrices}
            organizations={organizations}
            isAvailabilityAllOrganizations={values.isAvailabilityAllOrganizations}
            isSpecialPrices={values.isSpecialPrices}
          />
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="button" onClick={onSaveClick} isLoading={isSubmitting}>
            {saveButtonHeader}
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const PurchasableTestFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({purchasableTestDetails}) =>
    purchasableTestDetails ? convertToClient(purchasableTestDetails) : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const convertedValues = convertToServerUpdating(values);

    formikBag.setSubmitting(true);
    await formikBag.props.updatePurchasableTest(convertedValues);
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(PurchasableTestForm);

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {adminOrganizationDictionary} = props;
  const {selectors: organizationSelectors} = adminOrganizationDictionary;

  return {
    purchasableTestDetails: testSelectors.selectPurchasableTestDetails(state),
    purchasableTestDetailsLoading: testSelectors.selectCommunication(state, 'purchasableTestDetailsLoading'),
    purchasableTestUpdating: testSelectors.selectCommunication(state, 'purchasableTestUpdating'),
    organizations: organizationSelectors.selectItems(state),
    organizationsLoading: organizationSelectors.selectCommunication(state, 'itemsLoading'),
  };
};

const mapDispatchToProps = {
  getPurchasableTestDetails: testActions.getPurchasableTestById,
  resetPurchasableTestDetailResults: testActions.resetPurchasableTestDetailResults,
  updatePurchasableTest: testActions.updatePurchasableTest,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(PurchasableTestFormFormik);
const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {adminOrganizations} = useDictionaries();

  return <Connected {...externalProps} adminOrganizationDictionary={adminOrganizations} />;
};

export default Exported;
