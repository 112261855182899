import React from 'react';
import styled from 'styled-components';

import {ReactComponent as FooterLogo} from 'SubscriptionWelcome/img/footer-horse.svg';
import {breakpoints} from 'Common/constants/Breakpoints';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {
  DarkContainerBase,
  DescriptionLabel,
  HeaderLabel,
  SectionBase
} from 'SubscriptionWelcome/components/BahSubscription/parts/styled';

const Container = styled(DarkContainerBase)`
  height: 350px;
`;

const Section = styled(SectionBase)`
  @media ${breakpoints.md} {
    max-width: 1100px;
    justify-content: space-between;
  }
`;

const SubscriptionInformationSection = styled.div`
  max-width: 340px;

  @media ${breakpoints.sm} {
    max-width: 440px;
  }
  @media ${breakpoints.md} {
    max-width: 500px;
  }
  @media ${breakpoints.lg} {
    max-width: 526px;
  }
`;

const SubscribeButton = styled(PrimaryButton)`
  margin-right: 40px;
`;

const FooterLogoWrapper = styled.div`
  position: absolute;

  @media ${breakpoints.md} {
    margin: 0;
    top: -120px;
    right: -50px;
  }

  @media ${breakpoints.lg} {
    right: -210px;
  }
`;

interface IProps {
  onSubscribeClick(): void;
}

const Footer = (props: IProps) => {
  const {onSubscribeClick} = props;
  const {isDesktop} = useMediaQuery();

  return (
    <Container className="d-flex w-100 justify-content-center">
      <Section className="d-flex w-100 position-relative align-items-center">
        <SubscriptionInformationSection>
          <HeaderLabel isMain={true}>Subscribe for access</HeaderLabel>
          <DescriptionLabel isMain={true}>
            Access a unique tool allowing you to calculate future foal probabilities by matching from within your herd
            or the thousands of horses in our database. Avoid mismatches, disease and undesirable traits using solid
            science. Your dream horse is a few clicks away!
          </DescriptionLabel>
          <div className="d-flex">
            <SubscribeButton onClick={onSubscribeClick}>Subscribe</SubscribeButton>
          </div>
        </SubscriptionInformationSection>
        {isDesktop && (
          <FooterLogoWrapper>
            <FooterLogo />
          </FooterLogoWrapper>
        )}
      </Section>
    </Container>
  );
};

export default Footer;
