import {Reducer} from 'redux';

import {
  AdministratorsActions,
  changeAdministratorStatusActionTypes,
  getAdministratorActionTypes,
  getAdministratorAvatarActionTypes,
  getAdministratorsActionTypes,
  IAdministratorState,
  resetAdministratorActionTypes,
} from '../types';

const initialState: IAdministratorState['data'] = {
  administrators: [],
  administrator: null,
  administratorAvatar: null,
};

export const dataAdministratorsReducer: Reducer<IAdministratorState['data'], AdministratorsActions> = (
  state = initialState,
  action: AdministratorsActions
) => {
  switch (action.type) {
    case getAdministratorsActionTypes.SUCCESS: {
      return {...state, administrators: action.payload.data};
    }

    case getAdministratorActionTypes.SUCCESS: {
      return {...state, administrator: action.payload};
    }

    case getAdministratorAvatarActionTypes.SUCCESS: {
      return {...state, administratorAvatar: action.payload};
    }

    case changeAdministratorStatusActionTypes.SUCCESS: {
      const {adminId} = action.payload;
      return {
        ...state,
        administrators: state.administrators.map((c) => (c.id !== adminId ? c : {...c, isActive: !c.isActive})),
      };
    }

    case resetAdministratorActionTypes.RESET: {
      return {
        ...state,
        administratorAvatar: initialState.administratorAvatar,
        administrator: initialState.administrator,
      };
    }

    default: {
      return state;
    }
  }
};
