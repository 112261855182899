import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication/index';

import {IListResponse, IPagination} from 'Common/models/IResponse';
import {OrderStatusField} from 'Order/models/IOrderStatusFields';
import {IAdminAssociationOrder} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrder';
import {IAdminAssociationOrderDetails} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderDetails';
import {IAdminAssociationOrderPaymentDetails} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderPaymentDetails';
import {IAdminAssociationOrderReportDetails} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderReportDetails';
import {IRequiredTest} from 'Admin/AdminDashboard/models/IRequiredTest';
import {IOrderStatused} from 'Common/models/IOrderStatused';
import {ISampleAdmin} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderHorseDetails';
import {IAdminAssociationOrderTestsDetails} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderTestsDetails';

const STATE_NAME = 'adminAssociationOrders';

export interface IAdminAssociationOrderState {
  data: {
    orders: IAdminAssociationOrder[];
    orderDetails: IAdminAssociationOrderDetails;
    orderPaymentDetails: IAdminAssociationOrderPaymentDetails;
    requiredTests: IRequiredTest[];
    orderReportDetails: IAdminAssociationOrderReportDetails[];
    orderStatusesDetails: IOrderStatused;
    orderSampleDetails: ISampleAdmin;
    orderTestsDetails: IAdminAssociationOrderTestsDetails;
  };
  communications: {
    ordersLoading: ICommunication;
    orderDetailsLoading: ICommunication;
    orderPaymentDetailsLoading: ICommunication;
    testResultsUploading: ICommunication;
    orderHorseReleaseRequesting: ICommunication;
    orderDeleting: ICommunication;
    newSampleRequesting: ICommunication;
    setSampleStatusRequesting: ICommunication;
    changeSampleRequesting: ICommunication;
    downloadReportsRequesting: ICommunication;
    downloadSubmissionFormRequesting: ICommunication;
    requiredTestsLoading: ICommunication;
    orderReportDetailsLoading: ICommunication;
    reportUploading: ICommunication;
    reportDeleting: ICommunication;
    orderStatusesDetailsLoading: ICommunication;
    orderSampleDetailsLoading: ICommunication;
    orderTestsDetailsLoading: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getOrdersActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDERS');
export type getOrdersActions = MakeCommunicationActions<
  typeof getOrdersActionTypes,
  {success: IListResponse<IAdminAssociationOrder>}
>;

export const getOrderDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_DETAILS');
export type getOrderDetailsActions = MakeCommunicationActions<
  typeof getOrderDetailsActionTypes,
  {success: IAdminAssociationOrderDetails}
>;

export const getOrderPaymentDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_PAYMENT_DETAILS');
export type getOrderPaymentDetailsActions = MakeCommunicationActions<
  typeof getOrderPaymentDetailsActionTypes,
  {success: IAdminAssociationOrderPaymentDetails}
>;

export const uploadTestResultsActionTypes = makeCommunicationActionType(STATE_NAME, 'UPLOAD_TEST_RESULTS');
export type uploadTestResultsActions = MakeCommunicationActions<typeof uploadTestResultsActionTypes, {}>;

export const releaseOrderHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'RELEASE_ORDER_HORSE');
export type releaseOrderHorseActions = MakeCommunicationActions<typeof releaseOrderHorseActionTypes, {}>;

export const deleteOrderActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_ORDER');
export type deleteOrderActions = MakeCommunicationActions<typeof deleteOrderActionTypes, {}>;

export const requestNewSampleActionTypes = makeCommunicationActionType(STATE_NAME, 'REQUEST_NEW_SAMPLE');
export type requestNewSampleActions = MakeCommunicationActions<typeof requestNewSampleActionTypes, {}>;

export const setSampleStatusActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_SAMPLE_STATUS');
export type setSampleStatusActions = MakeCommunicationActions<typeof setSampleStatusActionTypes, {}>;

export const changeSampleActionTypes = makeCommunicationActionType(STATE_NAME, 'CHANGE_SAMPLE');
export type changeSampleActions = MakeCommunicationActions<
  typeof changeSampleActionTypes,
  {success: IRequestChangeSample}
>;

export const chooseAnotherSampleActionTypes = makeCommunicationActionType(STATE_NAME, 'CHOOSE_ANOTHER_SAMPLE');
export type chooseAnotherSampleActions = MakeCommunicationActions<
  typeof chooseAnotherSampleActionTypes,
  {success: IRequestChooseAnotherSample}
>;

export const downloadReportsActionTypes = makeCommunicationActionType(STATE_NAME, 'DOWNLOAD_REPORTS');
export type downloadReportsActions = MakeCommunicationActions<typeof downloadReportsActionTypes, {}>;

export const downloadSubmissionFormActionTypes = makeCommunicationActionType(STATE_NAME, 'DOWNLOAD_SUBMISSION_FORM');
export type downloadSubmissionFormActions = MakeCommunicationActions<typeof downloadSubmissionFormActionTypes, {}>;

export const getOrderRequiredTestsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_REQUIRED_TESTS');
export type getOrderRequiredTestsActions = MakeCommunicationActions<
  typeof getOrderRequiredTestsActionTypes,
  {success: IListResponse<IRequiredTest>}
>;

export const getOrderReportDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_REPORT_DETAILS');
export type getOrderReportDetailsActions = MakeCommunicationActions<
  typeof getOrderReportDetailsActionTypes,
  {success: IListResponse<IAdminAssociationOrderReportDetails>}
>;

export const uploadReportActionTypes = makeCommunicationActionType(STATE_NAME, 'UPLOAD_REPORT');
export type uploadReportActions = MakeCommunicationActions<typeof uploadReportActionTypes, {}>;

export const deleteReportActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_REPORT');
export type deleteReportActions = MakeCommunicationActions<typeof deleteReportActionTypes, {}>;

export const getOrderStatusesDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_STATUSES_DETAILS');
export type getOrderStatusesDetailsActions = MakeCommunicationActions<
  typeof getOrderStatusesDetailsActionTypes,
  {success: IOrderStatused}
>;

export const getOrderSampleDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_SAMPLE_DETAILS');
export type getOrderSampleDetailsActions = MakeCommunicationActions<
  typeof getOrderSampleDetailsActionTypes,
  {success: ISampleAdmin}
>;

export const getOrderTestsDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_TESTS_DETAILS');
export type getOrderTestsDetailsActions = MakeCommunicationActions<
  typeof getOrderTestsDetailsActionTypes,
  {success: IAdminAssociationOrderTestsDetails}
>;

export const resetChangeSampleCommunicationActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'CHANGE_SAMPLE_COMMUNICATION'
);
export type resetChangeSampleCommunicationActions = MakeResetCommunicationActions<
  typeof resetChangeSampleCommunicationActionTypes
>;

export const resetChooseAnotherSampleCommunicationActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'CHOOSE_ANOTHER_SAMPLE_COMMUNICATION'
);
export type resetChooseAnotherSampleCommunicationActions = MakeResetCommunicationActions<
  typeof resetChooseAnotherSampleCommunicationActionTypes
>;

export interface IReleaseOrderHorse {
  orderId: number;
}

export interface IRequestNewSampleParams {
  orderId: number;
  reason?: string;
  description?: string;
}

export interface IRequestSetSampleStatus {
  orderId: number;
  status: OrderStatusField;
  sendEmail: boolean;
}

export interface IRequestChangeReviewStatus extends Omit<IRequestSetSampleStatus, 'status' | 'sendEmail'> {
  reviewStatus: OrderStatusField;
}

export interface IRequestChangeSample {
  id: number;
  sampleId: string;
}

export interface IRequestChooseAnotherSample {
  orderId: number;
  currentSampleId: string;
  targetSampleId: string;
}

export const resetOrderDetailsActionTypes = makeResetCommunicationActionType(STATE_NAME, 'ORDER_DETAILS');
export type resetOrderDetailsActions = MakeResetCommunicationActions<typeof resetOrderDetailsActionTypes>;

export type AdminAssociationOrdersActions =
  | getOrdersActions
  | getOrderDetailsActions
  | getOrderPaymentDetailsActions
  | uploadTestResultsActions
  | releaseOrderHorseActions
  | deleteOrderActions
  | requestNewSampleActions
  | setSampleStatusActions
  | changeSampleActions
  | chooseAnotherSampleActions
  | downloadReportsActions
  | downloadSubmissionFormActions
  | getOrderRequiredTestsActions
  | resetChangeSampleCommunicationActions
  | resetChooseAnotherSampleCommunicationActions
  | resetOrderDetailsActions
  | getOrderReportDetailsActions
  | uploadReportActions
  | deleteReportActions
  | getOrderStatusesDetailsActions
  | getOrderSampleDetailsActions
  | getOrderTestsDetailsActions;
