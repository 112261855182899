import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IObjectResponse} from 'Common/models/IResponse';
import {IOrderSummary} from 'Payment/models/IOrderSummary';

import {IPayPalOrder} from './models/payment';
import {IServerOrderSummary} from './models/summary';
import {convertOrderSummaryToClient} from './converters/summary';

const getPaymentIntentId = async (token: string, captchaToken: string, isBusiness?: boolean): Promise<string> => {
  const res = await axiosWrapper.post<IObjectResponse<string>>(
    isBusiness ? `/StripeAssociationOrderPaymentIntent` : `/StripePaymentIntent`,
    {token, captchaToken}
  );
  return res.data.data;
};

const getOrderSummaryByToken = async (token: string): Promise<IOrderSummary> => {
  const res = await axiosWrapper.get<IObjectResponse<IServerOrderSummary>>(`/OrderSummary/${token}`);
  return convertOrderSummaryToClient(res.data.data);
};

const getPayPalOrder = async (token: string): Promise<IPayPalOrder> => {
  const res = await axiosWrapper.get<IObjectResponse<IPayPalOrder>>(`/PayPalOrder`, {
    params: {token},
  });
  return res.data.data;
};

export default {
  getPaymentIntentId,
  getOrderSummaryByToken,
  getPayPalOrder,
};
