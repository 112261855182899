import {combineReducers, Reducer} from 'redux';

import {communicationErrorDescriptionsReducer} from './communications';
import {dataErrorDescriptionsReducer} from './data';
import {IAdminErrorDescriptionsState} from '../types';
import {uiErrorDescriptionsReducer} from './ui';

export const errorDescriptionsReducer: Reducer<IAdminErrorDescriptionsState> = combineReducers<
  IAdminErrorDescriptionsState
>({
  communications: communicationErrorDescriptionsReducer,
  data: dataErrorDescriptionsReducer,
  ui: uiErrorDescriptionsReducer
});
