import styled from 'styled-components';

import {SelectField} from 'Common/components/FormFields/index';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

export const TextUpper = styled.span`
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
  text-transform: uppercase;
`;

export const TypeSelect = styled(SelectField)`
  min-width: 80px;
  margin: 0 16px;
`;
