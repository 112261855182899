import {combineReducers} from 'redux';

import {
  changePackageStatusActionTypes,
  createPackageActionTypes,
  deletePackageActionTypes,
  AdminPackagesActions,
  getPackageByIdActionTypes,
  getPackagesActionTypes,
  IAdminPackagesState,
  resetPackageDetailsActionTypes,
  updatePackageActionTypes,
  updatePackageCartSettingsActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

export const communicationAdminPackagesReducer = combineReducers<
  IAdminPackagesState['communications'],
  AdminPackagesActions
>({
  packagesLoading: makeCommunicationReducerFromEnum(getPackagesActionTypes),
  packageCreating: makeCommunicationReducerFromEnum(createPackageActionTypes),
  packageUpdating: makeCommunicationReducerFromEnum(updatePackageActionTypes),
  packageDeleting: makeCommunicationReducerFromEnum(deletePackageActionTypes),
  packageDetailsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getPackageByIdActionTypes),
    makeResetCommunicationReducer(resetPackageDetailsActionTypes.RESET),
  ]),
  changePackageStatusRequesting: makeCommunicationReducerFromEnum(changePackageStatusActionTypes),
  packageCartSettingsUpdating: makeCommunicationReducerFromEnum(updatePackageCartSettingsActionTypes),
});
