import {Reducer} from 'redux';

import {
  Actions,
  createOrderActionTypes,
  getCouponActionTypes,
  getHorsesActionTypes,
  getSummaryActionTypes,
  IBusinessPortalCreateOrderState,
  resetCouponActionTypes,
  resetSummaryActionTypes,
} from 'BusinessPortal/store/createOrder/types';

const initialStateList: IBusinessPortalCreateOrderState['data'] = {
  horses: [],
  summary: null,
  coupon: null,
  createdOrder: null,
};

export const dataReducer: Reducer<IBusinessPortalCreateOrderState['data'], Actions> = (
  state = initialStateList,
  action: Actions
) => {
  switch (action.type) {
    case getHorsesActionTypes.SUCCESS: {
      return {...state, horses: action.payload};
    }
    case getSummaryActionTypes.SUCCESS: {
      return {...state, summary: action.payload};
    }
    case getCouponActionTypes.SUCCESS: {
      return {...state, coupon: action.payload};
    }
    case createOrderActionTypes.SUCCESS: {
      return {...state, createdOrder: action.payload};
    }
    case resetCouponActionTypes.RESET: {
      return {...state, coupon: null};
    }
    case resetSummaryActionTypes.RESET: {
      return {...state, summary: null};
    }
    default: {
      return state;
    }
  }
};
