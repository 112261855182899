import {memo, useCallback, useEffect, useState} from 'react';

import SearchBar from 'Common/components/SearchBar/SearchBar';
import {IOrderPackage} from 'Order/models/IOrderPackage';
import {IPackageGroup} from 'Dictionaries/models/IPackageGroup';

interface IProps {
  orderPackagesForFilter: IOrderPackage[];
  packageGroupsForFilter: IPackageGroup[];
  onSuccess(filteredOrderPackages: IOrderPackage[], filteredPackageGroups: IPackageGroup[]): void;
}

function SearchBarPackages(props: IProps) {
  const {orderPackagesForFilter, packageGroupsForFilter, onSuccess} = props;

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOrderPackages, setFilteredOrderPackages] = useState(orderPackagesForFilter);
  const [filteredPackageGroups, setFilteredPackageGroups] = useState(packageGroupsForFilter);

  useEffect(() => {
    onSuccess(filteredOrderPackages, filteredPackageGroups);
  }, [filteredOrderPackages, filteredPackageGroups, onSuccess]);

  useEffect(() => {
    return () => setSearchQuery('');
  }, []);

  const handleChangeFilter = useCallback(
    (value) => {
      if (value === '') {
        setSearchQuery(value);
        setFilteredOrderPackages(orderPackagesForFilter);
        setFilteredPackageGroups(packageGroupsForFilter);
        return;
      }

      const preFilteredPackageGroups = packageGroupsForFilter.filter(
        (item) =>
          item.name?.toLowerCase().includes(value.toLowerCase()) ||
          item.description?.toLowerCase().includes(value.toLowerCase())
      );

      const preFilteredPackages = orderPackagesForFilter.filter(
        (item) =>
          item.description?.toLowerCase().includes(value.toLowerCase()) ||
          item.name.toLowerCase().includes(value.toLowerCase())
      );

      const groupsOfPreFilteredPackageGroups = preFilteredPackageGroups.map((x) => x.id);
      const packagesOfPreFilteredPackages = preFilteredPackages.map((x) => x.groups).flatMap((x) => x.map((y) => y.id));

      const filteredPackageGroups = preFilteredPackageGroups.concat(
        packageGroupsForFilter.filter(
          (item) =>
            packagesOfPreFilteredPackages.filter((x) => x === item.id) &&
            !preFilteredPackageGroups.some((x) => x.id === item.id)
        )
      );

      const filteredPackages = preFilteredPackages.concat(
        orderPackagesForFilter.filter((item) =>
          item.groups.some((x) => groupsOfPreFilteredPackageGroups.includes(x.id))
        )
      );

      setSearchQuery(value);
      setFilteredOrderPackages(filteredPackages);
      setFilteredPackageGroups(filteredPackageGroups);
    },
    [orderPackagesForFilter, packageGroupsForFilter]
  );

  return <SearchBar onChange={handleChangeFilter} value={searchQuery} placeholder="Click here to search packages..." />;
}

export default memo(SearchBarPackages);
