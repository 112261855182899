import React, {memo} from 'react';
import styled from 'styled-components';

import {TextUpper, TypeSelect} from '../shared/StyledComponents';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {CombinationView} from 'Common/constants/Combination';
import {getStringKeysOption} from 'Common/helpers/OptionHelper';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import {FieldArray} from 'formik';
import {IFormSequenceItem} from '../../models/IFormSequenceItem';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';
import SequenceItems from './SequenceItems';

const typeOptions = getStringKeysOption(CombinationView, true);

const SequenceTitle = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;

const SequenceWrapper = styled.div`
  border-left: 2px solid ${Theme.color.gray};
  margin: 16px 0 24px;
  padding-left: 16px;
`;

const SequenceItemsBlock = styled.div`
  margin-top: 16px;
`;

const Title = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  margin-bottom: 16px;
`;

const TrashButton = styled.div`
  margin: 0 0 2px 16px;
`;

interface IProps {
  sequence: IFormSequenceItem;
  index: number;
  dictionaryOptions: IDictionaryOptionType[];
  includeText?: string;
  stateText?: string;
  onDelete(): void;
}

function Sequence(props: IProps) {
  const {sequence, index, dictionaryOptions, includeText, stateText, onDelete} = props;

  return (
    <>
      <SequenceTitle className="d-flex align-items-center">
        {`Sequence ${index + 1}`}
        <TrashButton className="align-self-center">
          <IconButton
            name={IconName.Close}
            color={ColorPalette.gray48}
            fill={true}
            stroke={false}
            size={12}
            onClick={onDelete}
          />
        </TrashButton>
      </SequenceTitle>
      <SequenceWrapper>
        <div className="d-flex align-items-center">
          <TextUpper>Include</TextUpper>
          <TypeSelect
            name={`sequences[${index}].type`}
            placeholder="Type"
            options={typeOptions}
            defaultValue={typeOptions[sequence.type]}
          />
          <TextUpper>of this {includeText || 'States'}</TextUpper>
        </div>

        <SequenceItemsBlock>
          <Title>{stateText || 'State'}</Title>
          <FieldArray
            name={`sequences[${index}].sequenceItems`}
            render={arrayHelper => {
              function handleAdd() {
                arrayHelper.push({});
              }

              function handleClear(i: number) {
                arrayHelper.remove(i);
              }

              return (
                <SequenceItems
                  sequenceIndex={index}
                  sequenceItems={sequence.sequenceItems}
                  sequenceType={sequence.type}
                  dictionaryOptions={dictionaryOptions}
                  onAdd={handleAdd}
                  onClear={handleClear}
                />
              );
            }}
          />
        </SequenceItemsBlock>
      </SequenceWrapper>
    </>
  );
}

export default memo(Sequence);
