import {IResetCommunicationActionTypes} from 'Common/store/utils/communication/types';

export function makeResetCommunicationActionTypes<R extends string>({
  RESET: request,
}: IResetCommunicationActionTypes<R>): IResetCommunicationActionTypes<R> {
  return {RESET: request};
}

export default function makeResetCommunicationActionType<S extends string, C extends string>(
  state: S,
  communication: C
) {
  type resetRequestType = `@@${S}/RESET_${C}`;
  return makeResetCommunicationActionTypes({
    RESET: `@@${state}/RESET_${communication}` as resetRequestType,
  });
}
