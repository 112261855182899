import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {
  AdminPermissionsActions,
  getAdminPermissionsActionTypes,
  getCurrentAdminPermissionsActionTypes,
  IAdminPermissionsState,
  setAdminPermissionsActionTypes
} from '../types';

export const communicationAdminPermissionsReducer = combineReducers<
  IAdminPermissionsState['communications'],
  AdminPermissionsActions
>({
  currentAdminPermissionsLoading: makeCommunicationReducerFromEnum(getCurrentAdminPermissionsActionTypes),
  adminPermissionsLoading: makeCommunicationReducerFromEnum(getAdminPermissionsActionTypes),
  adminPermissionsUpdating: makeCommunicationReducerFromEnum(setAdminPermissionsActionTypes)
});
