import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'BusinessPortal/store/horse';
import Loading from 'Loading/components/Loading';
import EditableSection from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {IHorseSectionBP} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/models/IHorseSectionBP';
import HorseOwnerSectionEdit from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseOwnerSection/HorseOwnerSectionEdit/HorseOwnerSectionEdit';
import HorseOwnerSectionShow from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseOwnerSection/HorseOwnerSectionShow/HorseOwnerSectionShow';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {BusinessPortalOwnerModule} from 'BusinessPortal/store/owner';

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IHorseSectionBP;

const HorseOwnerSection = (props: AllProps) => {
  const {onSuccess} = props;
  const {horseId, horseDetails, horseOwner: owner, horseOwnerLoading, getHorseOwner} = props;

  const mainRequest = useCallback(() => {
    getHorseOwner(horseId);
  }, [getHorseOwner, horseId]);

  useEffect(() => {
    mainRequest();
  }, [mainRequest]);

  const onSuccessEditHorse = useCallback(() => {
    mainRequest();
    onSuccess();
  }, [mainRequest, onSuccess]);

  const isRequesting = horseOwnerLoading.isRequesting;

  return (
    <div className="position-relative">
      {isRequesting && <Loading />}
      <EditableSection
        title="Owner"
        data={{horseId, horseName: horseDetails?.name || '', owner}}
        onSuccess={onSuccessEditHorse}
        editComponent={HorseOwnerSectionEdit}
        showComponent={HorseOwnerSectionShow}
      />
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  horseDetails: selectors.selectHorseDetails(state),
  horseOwner: selectors.selectHorseOwner(state),
  horseOwnerLoading: selectors.selectCommunication(state, 'horseOwnerLoading'),
});

const mapDispatchToProps = {
  getHorseOwner: actions.getHorseOwner,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(memo(HorseOwnerSection)), BusinessPortalOwnerModule);
