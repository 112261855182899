import React, {memo} from 'react';
import styled from 'styled-components';

import {AncestryReportSection, Description} from './common/styled';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

const RedDescription = styled.p`
  color: ${Theme.color.primary};
`;

const DescriptionWrapper = styled(Description)`
  font-size: ${Typography.size.size16};
`;

const ScienceOfAncestry = () => {
  return (
    <AncestryReportSection className="d-flex flex-column align-items-center justify-content-center">
      <DescriptionWrapper className="d-flex flex-column">
        <p>
          The Science of Ancestry OK, folks...here's where we get hardcore about our equines. Below you can find the
          scientific references, information on the specific methods and genes we use to try and understand this noble
          species:
        </p>

        <RedDescription>Genetic diversity in the modern horse illustrated from genome-wide SNP data.</RedDescription>

        <p>
          Petersen JL, Mickelson JR, Cothran EG, Andersson LS, Axelsson J, Bailey E, Bannasch D, Binns MM, Borges AS,
          Brama P, da Câmara Machado A, Distl O, Felicetti M, Fox-Clipsham L, Graves KT, Guérin G, Haase B, Hasegawa T,
          Hemmann K, Hill EW, Leeb T, Lindgren G, Lohi H, Lopes MS, McGivney BA, Mikko S, Orr N, Penedo MC, Piercy RJ,
          Raekallio M, Rieder S, Røed KH, Silvestrelli M, Swinburne J, Tozaki T, Vaudin M, M Wade C, McCue ME.
        </p>

        <RedDescription>
          Runs of homozygosity reveal signatures of positive selection for reproduction traits in breed and non-breed
          horses.
        </RedDescription>

        <p>Metzger J, Karwath M, Tonda R, Beltran S, Águeda L, Gut M, Gut IG, Distl O.</p>

        <RedDescription>
          Prehistoric genomes reveal the genetic foundation and cost of horse domestication
        </RedDescription>

        <p>
          Mikkel Schubert, Hákon Jónsson, Dan Chang, Clio Der Sarkissian, Luca Ermini, Aurélien Ginolhac, Anders
          Albrechtsen, Isabelle Dupanloupd, Adrien Foucald, Bent Petersen, Matteo Fumagalli, Maanasa Raghavan, Andaine
          Seguin-Orlando, Thor nn S. Korneliussen, Amhed M. V. Velazquez, Jesper Stenderup, Cindi A. Hoover, Carl-Johan
          Rubin, Ahmed H. Alfarhan, Saleh A. Alquraishi, Khaled A. S. Al-Rasheid, David E. MacHugh, Ted Kalb eisch,
          James N. MacLeod, Edward M. Rubin, Thomas Sicheritz- Ponten, Leif Andersson, Michael Hofreiter, Tomas
          Marques-Bonet, M. Thomas, P. Gilberta, Rasmus Nielsen, Laurent Exco er, Eske Willerslev, Beth Shapiro, and
          Ludovic Orlando.
        </p>
      </DescriptionWrapper>
    </AncestryReportSection>
  );
};

export default memo(ScienceOfAncestry);
