import {Reducer} from 'redux';

import {
  changeAdminAssociationPackageStatusActionTypes,
  AdminAssociationPackagesActions,
  getAdminAssociationPackageByIdActionTypes,
  getAdminAssociationPackagesActionTypes,
  IAdminAssociationPackagesState,
  resetAdminAssociationPackageDetailsActionTypes,
} from '../types';

const initialState: IAdminAssociationPackagesState['data'] = {
  packages: [],
  packageDetail: null,
};

export const dataAdminAssociationPackagesReducer: Reducer<
  IAdminAssociationPackagesState['data'],
  AdminAssociationPackagesActions
> = (state = initialState, action: AdminAssociationPackagesActions) => {
  switch (action.type) {
    case getAdminAssociationPackagesActionTypes.SUCCESS: {
      return {...state, packages: action.payload.data};
    }
    case getAdminAssociationPackageByIdActionTypes.REQUEST: {
      return {...state, packageDetail: null};
    }
    case getAdminAssociationPackageByIdActionTypes.SUCCESS: {
      return {...state, packageDetail: action.payload};
    }
    case changeAdminAssociationPackageStatusActionTypes.SUCCESS: {
      const {packageId} = action.payload;
      return {
        ...state,
        packages: state.packages.map((p) => (p.id !== packageId ? p : {...p, isEnabled: !p.isEnabled})),
      };
    }
    case resetAdminAssociationPackageDetailsActionTypes.RESET: {
      return {...state, packageDetail: initialState.packageDetail};
    }
    default: {
      return state;
    }
  }
};
