import {combineReducers, Reducer} from 'redux';

import {communicationAdminSubscriptionsReducer} from './communications';
import {dataAdminSubscriptionsReducer} from './data';
import {IAdminSubscriptionState} from '../types';
import {uiAdminSubscriptionsReducer} from './ui';

export const adminSubscriptionsReducer: Reducer<IAdminSubscriptionState> = combineReducers<IAdminSubscriptionState>({
  communications: communicationAdminSubscriptionsReducer,
  data: dataAdminSubscriptionsReducer,
  ui: uiAdminSubscriptionsReducer
});
