import {Reducer} from 'redux';

import {
  IFacetFilterState,
  Actions,
  getFilterPropertyOperationsActionTypes,
  getPackagesFiltersActionTypes,
} from '../types';

const initialState: IFacetFilterState['data'] = {
  filterPropertyOperations: [],
  packagesFilters: [],
};

export const dataFacetFilterReducer: Reducer<IFacetFilterState['data'], Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case getFilterPropertyOperationsActionTypes.SUCCESS: {
      const {filterPropertyOperations} = state;
      const newState = [...filterPropertyOperations];
      const findId = newState.findIndex((x) => x.type === action.payload.type);
      if (findId !== -1) {
        newState.splice(findId, 1);
      }

      newState.push(action.payload);
      return {...state, filterPropertyOperations: newState};
    }
    case getPackagesFiltersActionTypes.SUCCESS: {
      return {...state, packagesFilters: action.payload};
    }

    default: {
      return state;
    }
  }
};
