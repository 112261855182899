import {ICommunication, MakeCommunicationActions} from 'Common/store/utils/communication/types';

import {makeCommunicationActionType} from 'Common/store/utils/communication/index';

const STATE_NAME = 'adminTestResults';

export interface IAdminTestResultsState {
  communications: {
    testResultsUploading: ICommunication;
  };
}

export const uploadTestResultsActionTypes = makeCommunicationActionType(STATE_NAME, 'UPLOAD_TEST_RESULTS');

export type uploadTestResultsActions = MakeCommunicationActions<typeof uploadTestResultsActionTypes, {}>;

export type AdminTestResultsActions = uploadTestResultsActions;
