import React, {memo} from 'react';
import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';

const GridItemTitle = styled.div`
  font-size: 18px;
  font-family: ${Typography.family.openSans};
  color: ${ColorPalette.black1};
`;

const GridItemDescription = styled.div`
  margin: 16px 0 16px;
  line-height: 19px;
  font-size: ${Typography.size.size14};
  font-family: ${Typography.family.openSans};
  color: ${ColorPalette.gray30};
`;

export interface IReportGridItemProps {
  title: string;
  description: string;
  content?: React.ReactElement;
}

function ReportGridItem(props: IReportGridItemProps) {
  const {title, description, content} = props;

  return (
    <div className="d-flex flex-column align-items-center flex-grow-1">
      <GridItemTitle>{title}</GridItemTitle>
      <GridItemDescription>{description}</GridItemDescription>
      <div>{content}</div>
    </div>
  );
}

export default memo(ReportGridItem);
