import * as React from 'react';
import styled from 'styled-components';
import ColorPalette from 'Common/constants/ColorPalette';

const DetailsPriorityProbability = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

const DetailsPriorityProbabilityHigh = styled(DetailsPriorityProbability)`
  background-color: ${ColorPalette.green0};
`;

const DetailsPriorityProbabilityMedium = styled(DetailsPriorityProbability)`
  background-color: ${ColorPalette.yellow1};
`;

const DetailsPriorityProbabilityLow = styled(DetailsPriorityProbability)`
  background-color: ${ColorPalette.red1};
`;

export interface ICircleProbabilityIndicatorProps {
  probability: number;
  higherIsBetter: boolean;
}

const CircleProbabilityIndicator = (props: ICircleProbabilityIndicatorProps) => {
  if (props.probability > 75 && props.probability <= 100) {
    return props.higherIsBetter ? <DetailsPriorityProbabilityHigh /> : <DetailsPriorityProbabilityLow />;
  }
  if (props.probability > 50 && props.probability <= 75) {
    return <DetailsPriorityProbabilityMedium />;
  }
  if (props.probability > 0 && props.probability <= 50) {
    return props.higherIsBetter ? <DetailsPriorityProbabilityLow /> : <DetailsPriorityProbabilityHigh />;
  }
  return <div />;
};

export default CircleProbabilityIndicator;
