import React, {useState, memo} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import {controlLayout, errorLayout} from 'Common/components/Controls/styles';
import {Checkbox} from 'Common/components/Controls';
import {Direction} from 'Common/constants/Direction';
import {useField, useFormikContext} from 'formik';

const ErrorLayout = styled.div`
  ${errorLayout}
  bottom: -10px;
`;

const ComponentLayout = styled.div`
  position: relative;
  ${controlLayout}
`;

interface RenderCheckboxArgs {
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onBlur(): void;
  error?: string;
}

type RenderCheckboxes = (args: RenderCheckboxArgs) => React.ReactElement;

interface IOwnProps {
  children: Array<React.ReactElement<React.ComponentProps<typeof Checkbox>>> | RenderCheckboxes;
  direction?: Direction;
  className?: string;
  isNumericValues?: boolean;
  showSelectAll?: boolean;
  name: string;
  childrenStyle?: React.CSSProperties;
  showSelectLabel?: string;
}

type Props = IOwnProps;

function CheckboxGroupField(props: Props) {
  const {name, isNumericValues, showSelectAll, children, childrenStyle, showSelectLabel} = props;

  const [field] = useField<string[]>(name);
  const {setFieldValue, setFieldTouched, touched, errors} = useFormikContext();
  const {value: values} = field;
  const [selectedAll, setSelectedAll] = useState<boolean>(values?.length === props.children?.length);
  const [indeterminateAll, setIndeterminateAll] = useState<boolean>(
    props.children?.length > values?.length && values?.length > 0
  );

  const hasError = errors[name];

  const classDirection = classnames('d-flex', props.direction === Direction.Row ? 'flex-row' : 'flex-column');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parser = isNumericValues ? Number : String;
    const target = event.currentTarget;
    const valueArray = values || [];
    const newValue = target.checked
      ? [...valueArray, parser(target.name)]
      : valueArray.filter((v) => v !== parser(target.name));

    setFieldValue(name, newValue);
    if (showSelectAll) {
      setSelectedAll(newValue.length === props.children?.length);
      setIndeterminateAll(props.children?.length > newValue.length && newValue.length > 0);
    }
  };

  const onBlur = () => {
    if (!touched[name]) {
      setFieldTouched(name, true);
    }
  };

  if (typeof children === 'function') {
    return children({onChange, onBlur, error: hasError && errors[name]});
  }

  const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAll(event.currentTarget.checked);
    setIndeterminateAll(false);
    if (event.currentTarget.checked) {
      const parser = isNumericValues ? Number : String;
      setFieldValue(
        name,
        children.map((item) => parser(item.props.name))
      );
    } else {
      setFieldValue(name, []);
    }
  };

  return (
    <ComponentLayout className={props.className}>
      <div className={classDirection}>
        {showSelectAll && (
          <Checkbox
            checked={selectedAll}
            indeterminate={indeterminateAll}
            label={showSelectLabel || 'Select all'}
            onChange={onSelectAll}
          />
        )}
        <div style={childrenStyle}>
          {React.Children.map(children, (checkbox) => {
            return React.cloneElement(checkbox, {
              ...checkbox.props,
              onChange,
              onBlur,
            });
          })}
        </div>
      </div>
      {hasError && (
        <ErrorLayout>
          <div className="text-danger">{errors[name]}</div>
        </ErrorLayout>
      )}
    </ComponentLayout>
  );
}

CheckboxGroupField.defaultProps = {
  isNumericValues: true,
};

export default memo(CheckboxGroupField);
