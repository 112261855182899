import {IPaymentDetails} from 'Admin/AdminDashboard/models/IPaymentDetails';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IObjectResponse} from 'Common/models/IResponse';

import {
  convertPaymentDetailsToClient,
  convertPaymentDetailsToRequest,
} from 'Admin/AdminDashboard/services/converters/payment';

const getPaymentDetails = async (orderId: number): Promise<IPaymentDetails> => {
  const response = await axiosWrapper.get<IObjectResponse<IPaymentDetails>>(`/PaymentAdmin/${orderId}`);
  return convertPaymentDetailsToClient(response.data.data);
};

const updatePaymentDetails = async (data: IPaymentDetails): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<IPaymentDetails>>(
    `/PaymentUpdateAdmin`,
    convertPaymentDetailsToRequest(data)
  );
};

export default {
  getPaymentDetails,
  updatePaymentDetails,
};
