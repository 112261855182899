import {combineReducers} from 'redux';

import {
  AdminOrganizationsActions,
  changeAdminOrganizationStatusActionTypes,
  createAdminOrganizationActionTypes,
  getAdminOrganizationActionTypes,
  getAdminOrganizationAvatarActionTypes,
  getAdminOrganizationsActionTypes,
  IAdminOrganizationState,
  resetAdminOrganizationActionTypes,
  updateAdminOrganizationActionTypes,
  updateAdminOrganizationAvatarActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

export const communicationAdminOrganizationsReducer = combineReducers<
  IAdminOrganizationState['communications'],
  AdminOrganizationsActions
>({
  adminOrganizationsLoading: makeCommunicationReducerFromEnum(getAdminOrganizationsActionTypes),
  adminOrganizationLoading: composeReducers([
    makeCommunicationReducerFromEnum(getAdminOrganizationActionTypes),
    makeResetCommunicationReducer(resetAdminOrganizationActionTypes.RESET),
  ]),
  adminOrganizationCreating: makeCommunicationReducerFromEnum(createAdminOrganizationActionTypes),
  adminOrganizationUpdating: makeCommunicationReducerFromEnum(updateAdminOrganizationActionTypes),
  adminOrganizationStatusUpdating: makeCommunicationReducerFromEnum(changeAdminOrganizationStatusActionTypes),
  adminOrganizationAvatarLoading: makeCommunicationReducerFromEnum(getAdminOrganizationAvatarActionTypes),
  adminOrganizationAvatarUpdating: makeCommunicationReducerFromEnum(updateAdminOrganizationAvatarActionTypes),
});
