import {ICommunication} from 'Common/store/utils/communication';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {IAppState} from 'Common/store/IAppState';
import {actions as userHorsesActions, selectors as userHorseSelectors} from 'HorseProfile/store/horseList/index';
import {
  actions as adminAssociationOwnerActions,
  selectors as adminAssociationOwnerSelectors,
} from 'Admin/AdminAssociations/store/adminOwnerProfile';
import {
  actions as businessPortalOwnerActions,
  selectors as businessPortalOwnerSelectors,
} from 'BusinessPortal/store/ownerProfile';
import {HorseListModule} from 'HorseProfile/store/horseList/horseListModule';
import {VisitorType} from 'Common/constants/VisitorType';
import {IUserHorse} from 'Horse/models/IUserHorse';
import {IPagination} from 'Common/models/IResponse';
import {IRequestParams} from 'Common/models/IRequestParams';
import {AdminAssociationOwnerProfileModule} from 'Admin/AdminAssociations/store/adminOwnerProfile/adminAssociationOwnerProfileModule';
import {BusinessPortalOwnerProfileModule} from 'BusinessPortal/store/ownerProfile/businessPortalOwnerProfileModule';

export interface IState {
  horses: IUserHorse[];
  horsesLoading: ICommunication;
  pagination: IPagination;
}

export interface IDispatch {
  getHorses(params: IRequestParams, id: number, isGeneralUser: boolean): void;
}

const userDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    horses: userHorseSelectors.selectHorses(state),
    horsesLoading: userHorseSelectors.selectCommunication(state, 'horsesLoading'),
    pagination: userHorseSelectors.selectPagination(state),
  }),
  dispatch: {
    getHorses: userHorsesActions.getHorses,
  },
  modules: [HorseListModule],
};

const associationEmployeeDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    horses: businessPortalOwnerSelectors.selectHorses(state),
    horsesLoading: businessPortalOwnerSelectors.selectCommunication(state, 'horsesByOwnerLoading'),
    pagination: businessPortalOwnerSelectors.selectPagination(state),
  }),
  dispatch: {
    getHorses: businessPortalOwnerActions.getHorses,
  },
  modules: [BusinessPortalOwnerProfileModule],
};

const adminDistributor: IDistributor<any, any> = {
  state: (state: IAppState) => ({
    horses: userHorseSelectors.selectHorses(state),
    horsesLoading: userHorseSelectors.selectCommunication(state, 'horsesLoading'),
    pagination: userHorseSelectors.selectPagination(state),
  }),
  dispatch: {
    getHorses: userHorsesActions.getHorses,
  },
  modules: [HorseListModule],
};

const associationAdminEmployeeDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    horses: adminAssociationOwnerSelectors.selectHorses(state),
    horsesLoading: adminAssociationOwnerSelectors.selectCommunication(state, 'horsesByOwnerLoading'),
    pagination: adminAssociationOwnerSelectors.selectPagination(state),
  }),
  dispatch: {
    getHorses: adminAssociationOwnerActions.getHorsesByOwner,
  },
  modules: [AdminAssociationOwnerProfileModule],
};

export const userHorsesVisitorDistributor: Record<VisitorType, IDistributor<IState, IDispatch>> = {
  [VisitorType.Admin]: adminDistributor,
  [VisitorType.User]: userDistributor,
  [VisitorType.AssociationAdmin]: associationAdminEmployeeDistributor,
  [VisitorType.AssociationEmployee]: associationEmployeeDistributor,
};
