import {Reducer} from 'redux';

import {
  BusinessPortalOwnerActions,
  resetOwnerDetailsActionTypes,
  resetOwnersActionTypes,
  getOwnerDetailsActionTypes,
  getOwnersActionTypes,
  IBusinessPortalOwnerState,
} from '../types';

const initialState: IBusinessPortalOwnerState['data'] = {
  owners: [],
  ownerDetails: null,
};

export const dataBusinessPortalOwnerReducer: Reducer<IBusinessPortalOwnerState['data'], BusinessPortalOwnerActions> = (
  state = initialState,
  action: BusinessPortalOwnerActions
) => {
  switch (action.type) {
    case getOwnersActionTypes.SUCCESS: {
      return {...state, owners: action.payload.data};
    }
    case getOwnerDetailsActionTypes.SUCCESS: {
      return {...state, ownerDetails: action.payload};
    }

    case resetOwnerDetailsActionTypes.RESET: {
      return {...state, ownerDetails: initialState.ownerDetails};
    }
    case resetOwnersActionTypes.RESET: {
      return {...state, owners: initialState.owners};
    }

    default: {
      return state;
    }
  }
};
