import React, {memo} from 'react';
import styled from 'styled-components';

import {InformationSection, SectionName} from 'BusinessPortal/components/common/styled';
import {useHorseFormSlidePanel} from 'BusinessPortal/helpers/hooks/useHorseFormSlidePanel';
import {IOwnerSectionBP} from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/models/IOwnerSectionBP';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import NoData from 'BusinessPortal/components/common/NoData/NoData';
import GoToContainer from 'BusinessPortal/components/common/GoToContainer/GoToContainer';
import GoToBack from 'BusinessPortal/components/common/GoToBack/GoToBack';
import {convertUserName} from 'Common/helpers/convertUserName';

const Root = styled.div`
  padding-top: 8px;
`;

const AddHorseButton = styled(PrimaryButton)`
  margin-top: 16px;
`;

interface IExternalProps extends IOwnerSectionBP {
  onSuccessHorseForm?(): void;
}

const OwnerHorseSection = (props: IExternalProps) => {
  const {ownerDetails, onSuccessHorseForm} = props;

  const {horseFormSlidePanel, openHorseFormSlidePanel} = useHorseFormSlidePanel({
    onSuccessForm: onSuccessHorseForm,
    title: <GoToBack title={convertUserName(ownerDetails?.firstName, ownerDetails?.lastName)} />,
    closableTitle: true,
  });

  return (
    <Root>
      {horseFormSlidePanel}
      <SectionName>Horses</SectionName>
      <InformationSection>
        {!ownerDetails ? (
          <NoData />
        ) : (
          <>
            {ownerDetails.horses.map((horse, i) => (
              <GoToContainer
                key={i}
                name={horse.name}
                avatar={horse.avatar}
                hasShadow={i !== ownerDetails.horses.length - 1}
                onClick={() => openHorseFormSlidePanel(horse.id, ownerDetails.id)}
              />
            ))}

            <AddHorseButton
              className="w-100"
              variant="outlined"
              onClick={() => openHorseFormSlidePanel(undefined, ownerDetails.id)}
            >
              + Add a horse
            </AddHorseButton>
          </>
        )}
      </InformationSection>
    </Root>
  );
};

export default memo(OwnerHorseSection);
