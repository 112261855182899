import {IVideo} from 'Video/models/IVideo';

export function avatarGalleryRequestConverter(horseId: number, file: File) {
  const form = new FormData();
  form.append('horseId', horseId.toString());
  form.append('file', file);

  return form;
}

export function imagesGalleryRequestConverter(horseId: number, files: File[]) {
  const form = new FormData();
  form.append('horseId', horseId.toString());
  files.forEach(file => form.append('files', file));

  return form;
}

export function videoGalleryRequestConverter(horseId: number, video: IVideo) {
  return {horseId, url: video.url};
}
