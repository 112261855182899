import {IModule} from 'redux-dynamic-modules';

import {ITestReportState} from 'Admin/AdminDashboard/store/common/testReport/types';
import {testReportReducer} from 'Admin/AdminDashboard/store/common/testReport/reducer/index';

export interface ITestReportModuleState {
  testReport: ITestReportState;
}

export const TestReportModule: IModule<ITestReportModuleState> = {
  id: 'testReport',
  reducerMap: {
    testReport: testReportReducer
  }
};
