import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {
  deleteHtmlTemplateActions,
  deleteHtmlTemplateActionTypes,
  getHtmlTemplateActionTypes,
  getHtmlTemplateActions,
  getHtmlTemplatesActions,
  getHtmlTemplatesActionTypes,
  uploadHtmlTemplateActions,
  uploadHtmlTemplateActionTypes,
  resetTemplateActions,
  resetTemplateActionTypes,
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import HtmlTemplateService from 'Admin/AdminSettings/services/HtmlTemplateService';
import {IUploadHtmlTemplateRequest} from 'Admin/AdminSettings/models/IUploadHtmlTemplateRequest';
import {IRequestParams} from 'Common/models/IRequestParams';
import {HtmlTemplateType} from 'Admin/AdminSettings/constants/HtmlTemplateType';

export const getHtmlTemplates =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getHtmlTemplatesActions> =>
  async (dispatch) => {
    dispatch(action(getHtmlTemplatesActionTypes.REQUEST));
    try {
      const res = await HtmlTemplateService.getHtmlTemplates(params);
      dispatch(action(getHtmlTemplatesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHtmlTemplatesActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };

export const getHtmlTemplate =
  (type: HtmlTemplateType, name?: string): ActionResult<IAppState, Promise<void>, getHtmlTemplateActions> =>
  async (dispatch) => {
    dispatch(action(getHtmlTemplateActionTypes.REQUEST));
    try {
      const res = await HtmlTemplateService.getHtmlTemplate(type, name);
      dispatch(action(getHtmlTemplateActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getHtmlTemplateActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const uploadTemplate =
  (data: IUploadHtmlTemplateRequest): ActionResult<IAppState, void, uploadHtmlTemplateActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadHtmlTemplateActionTypes.REQUEST));
      await HtmlTemplateService.uploadHtmlTemplate(data);
      dispatch(action(uploadHtmlTemplateActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(uploadHtmlTemplateActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteHtmlTemplate =
  (id: number): ActionResult<IAppState, void, deleteHtmlTemplateActions> =>
  async (dispatch) => {
    dispatch(action(deleteHtmlTemplateActionTypes.REQUEST));
    try {
      await HtmlTemplateService.deleteHtmlTemplate(id);
      dispatch(action(deleteHtmlTemplateActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteHtmlTemplateActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetTemplateResults = (): ActionResult<IAppState, void, resetTemplateActions> => async (dispatch) => {
  dispatch(action(resetTemplateActionTypes.RESET));
};
