import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import ImageUploader from 'Image/components/ImageUploader';
import Loading from 'Loading/components/Loading';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'BusinessPortal/store/associationPage';
import {IImage} from 'Image/models/IImage';
import {IconName} from 'Icon/components/Icon';

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

function AssociationAvatar(props: AllProps) {
  const {
    getOrganizationAvatar,
    organizationAvatar,
    organizationAvatarLoading,
    organizationAvatarUpdating,
    updateOrganizationAvatar,
  } = props;

  const isRequesting = organizationAvatarLoading.isRequesting || organizationAvatarUpdating.isRequesting;

  useEffect(() => {
    getOrganizationAvatar();
  }, [getOrganizationAvatar]);

  const updateOrganizationAvatarHandler = useCallback(
    async (image: IImage) => {
      if (image.file) {
        try {
          await updateOrganizationAvatar(image.file);
          await getOrganizationAvatar();
        } catch (e) {
          return e;
        }
      }
    },
    [getOrganizationAvatar, updateOrganizationAvatar]
  );

  return (
    <div className="d-flex align-items-center position-relative">
      {isRequesting && <Loading />}
      <ImageUploader
        width="120px"
        height="120px"
        actionButtonsContainerHeight="100%"
        value={organizationAvatar}
        iconProps={{name: IconName.OwnerDefaultAvatar, width: 80, height: 80}}
        onChange={updateOrganizationAvatarHandler}
        title="Add profile image"
        showDeleteButton={false}
      />
    </div>
  );
}

const mapStateToProps = (state: IAppState) => ({
  organizationAvatar: selectors.selectOrganizationAvatar(state),
  organizationAvatarLoading: selectors.selectCommunication(state, 'organizationAvatarLoading'),
  organizationAvatarUpdating: selectors.selectCommunication(state, 'organizationAvatarUpdating'),
});

const mapDispatchToProps = {
  getOrganizationAvatar: actions.getOrganizationAvatar,
  updateOrganizationAvatar: actions.updateOrganizationAvatar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(AssociationAvatar));
