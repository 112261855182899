import React, {memo} from 'react';
import UserHorseOverviewLayout, {
  UserHorseOverviewProps,
} from 'UserProfile/components/shared/UserHorseOverviewLayout/UserHorseOverviewLayout';
import UserFavoriteHorseButtons from 'UserProfile/components/TabsData/UserFavoriteHorses/UserFavoriteHorse/UserFavoriteHorseButtons/UserFavoriteHorseButtons';
import {isAvailableForBah} from 'Common/helpers/isAvailableForBah';
import withFavoriteHorseActions, {
  getWithFavoriteHorseActionsModules,
  IWithFavoriteHorseActionsProps,
} from 'Common/helpers/withFavoriteHorseActions';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';

type IExternalProps = Omit<UserHorseOverviewProps, 'isEmptyOrder'>;

type AllProps = IExternalProps & IWithFavoriteHorseActionsProps;

function UserFavoriteHorse(props: AllProps) {
  const {favoriteHorseDeleting, onSuccess, isOwnHorse, horse, visitorType} = props;

  useErrorCommunicationToToast(favoriteHorseDeleting, undefined, 1);

  return (
    <UserHorseOverviewLayout horse={horse} isOwnHorse={isOwnHorse} onSuccess={onSuccess} visitorType={visitorType}>
      <UserFavoriteHorseButtons
        horse={horse}
        isAvailableToBah={horse.availabilityCriteria && isAvailableForBah(horse.availabilityCriteria)}
      />
    </UserHorseOverviewLayout>
  );
}

const Connected = withFavoriteHorseActions(memo(UserFavoriteHorse));

export default withDynamicModules(Connected, getWithFavoriteHorseActionsModules());
