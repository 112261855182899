import React, {memo} from 'react';
import styled from 'styled-components';

import {ItemContainer, LabelBase, ValueWrapper} from 'UserProfile/components/TabsData/common/styled';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {breakpoints} from 'Common/constants/Breakpoints';
import {IGenotype} from 'Genotype/models/IGenotype';
import GenotypeList from 'Genotype/component/GenotypeList';
import Typography from 'Common/constants/Typography';

const Root = styled.div`
  margin-bottom: 8px;
`;

const Items = styled.div`
  margin-left: 8px;
`;

const Values = styled(ValueWrapper)`
  font-weight: ${Typography.weight.bold700};
`;

const Label = styled(LabelBase)`
  margin-bottom: 0;
  @media ${breakpoints.sm} {
    margin-bottom: 0;
  }
`;

interface IProps {
  genotypes: IGenotype[];
}

function SummaryContent(props: IProps) {
  const {genotypes} = props;
  const {isDesktop} = useMediaQuery();

  if (!genotypes || genotypes.length === 0) {
    return null;
  }

  if (isDesktop) {
    return (
      <Root className="d-flex w-100">
        <Label className="align-self-center">Summary</Label>
        <Items>
          <ItemContainer className="d-flex flex-wrap">
            <GenotypeList genotypes={genotypes} />
          </ItemContainer>
        </Items>
      </Root>
    );
  }

  return (
    <Root className="d-flex w-100">
      <Label className="align-self-center">Summary</Label>
      <Values className="d-flex flex-wrap">
        <GenotypeList genotypes={genotypes} />
      </Values>
    </Root>
  );
}

export default memo(SummaryContent);
