import React, {memo, useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {actions} from 'UserProfile/store/currentUser/';

import AuthService from 'Auth/services/AuthService';
import Agreement from './Agreement';
import {VisitorType} from 'Common/constants/VisitorType';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';

const TOC_AGREEMENT_TEXT = `<p>Dear Etalon Client,</p>
<p>
Thank you for joining Etalon’s research and discovery community! At Etalon we are committed to our clients and the health of their horses. As you know, we are also committed to protecting our clients, their data and for all of the good folks collaborating with us. We therefore ask you to review the following legalese to make sure we are all in agreement as we move forward on this exciting endeavor. We are providing the opportunity for you to grant permission and voluntarily share your horse’s data and certain of your personal data with Etalon clients who have given permission and chosen to share their data - a whole new way to connect with your herd!
</p>
<p>
~ Team Etalon
</p>
<p>
<strong>Etalon Terms of Service</strong
</p>
<p>
<strong>By clicking “I agree”,</strong> you agree to the terms of service (“<u>Terms of Service</u>”) set forth in the following link:  <a href='https://www.etalondx.com/tos'>https://www.etalondx.com/tos</a>  The Terms of Service provide, among other things, that (1) Etalon may use the information you provide to Etalon, including your feedback and suggestions, to advance Etalon’s research and improve Etalon’s products, services, databases and offerings – your feedback as an Etalon client and horse person will be used to help us improve our products and services as well as to further the discoveries and science (your input is important and appreciated!); and (2) Etalon may take notes, photos, and other recordings of your participation in the Etalon platform or its ongoing research, and use these materials to advance Etalon’s research and improve Etalon’s products, services, databases and offerings.
</p>
<p>
In addition, the Terms of Service include the following legal provisions: (A) you agree to maintain the confidentiality of all information in the Etalon database or otherwise held by Etalon which includes horse health, physical characteristics, performance and ancestry, as well as personal information about horse owners, riders and Etalon clients; (B) you grant Etalon a non-exclusive, world-wide, royalty-free, perpetual license for all of your horse’s information, as well as any personal information that you have granted Etalon the right to use, to be used by Etalon in connection with Etalon’s current and future products, services, databases, research and offerings; (C) you release and hold Etalon harmless from any and all damages you may suffer relating to your horse’s or your personal information that you have disclosed to Etalon; and (D) other legal matters such as mandatory arbitration in San Mateo, California and application of California law.  This is only a brief, incomplete summary of the Terms of Service, and you are urged to carefully review the Terms of Service.
</P>
<p>
<strong>By clicking “I agree”,</strong> you acknowledge and agree that: (i) you have thoroughly read and understand the Terms of Service, and have had the opportunity to review the Terms of Service with an attorney of your choosing; and (ii) you agree to all of the provisions of the Terms of Service as now existing or as may be amended from time to time.
</p>
`;

interface IExternalProps {
  isShowOnly?: boolean;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalProps;

function TocAgreement(props: AllProps) {
  const {isShowOnly, confirmAgreement, getCurrentAssociationEmployee, getCurrentUser} = props;

  const {isVisitorType} = useVisitorTypeService();

  const handleOnAccept = useCallback(
    async (sign: string) => {
      await confirmAgreement(true, sign);
      if (isVisitorType(VisitorType.User)) {
        await getCurrentUser();
      } else {
        await getCurrentAssociationEmployee();
      }
    },
    [confirmAgreement, getCurrentAssociationEmployee, getCurrentUser, isVisitorType]
  );

  const handleOnCancel = useCallback(() => {
    AuthService.logout();
  }, []);

  return isShowOnly ? (
    <Agreement agreementText={TOC_AGREEMENT_TEXT} />
  ) : (
    <Agreement agreementText={TOC_AGREEMENT_TEXT} onAccept={handleOnAccept} onCancel={handleOnCancel} />
  );
}

const mapDispatchToProps = {
  confirmAgreement: actions.confirmAgreement,
  getCurrentUser: actions.getCurrentUser,
  getCurrentAssociationEmployee: actions.getCurrentAssociationEmployee,
};

const connector = connect(null, mapDispatchToProps);
export default connector(memo(TocAgreement));
