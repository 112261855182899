import React, {memo, useMemo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import withDate from 'Common/helpers/withDate';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {FRONTEND_DATE_TIME} from 'Common/constants/Date';
import {ItemContainer, Divider} from 'Admin/AdminAdvancedDashboard/components/Emergencies/common/styled';

const Title = styled.span`
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size24};
  line-height: 32px;
`;

const Status = styled.span<{isDisable: boolean}>`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${props => (props.isDisable ? ColorPalette.red13 : ColorPalette.green2)};
`;

const ValueWrapper = styled.div`
  margin-top: 16px;
`;

const ValueName = styled.span`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${Theme.color.black};
`;

const Value = styled(ValueName)<{maxWidth: number}>`
  font-weight: ${Typography.weight.medium500};
  max-width: ${props => props.maxWidth}px;
  word-break: break-word;
`;

const Button = styled(PrimaryButton)`
  margin-top: 32px;
`;

interface IProps {
  name: string;
  isDisable: boolean;
  lastStatusDate: string;
  changedBy: string;
  cause: string;
  isLoading: boolean;
  onEnableAccess(): void;
  onDisableAccess(): void;
}

function AccessPanel(props: IProps) {
  const {name, isDisable, lastStatusDate, changedBy, isLoading, cause, onEnableAccess, onDisableAccess} = props;

  const changeStatusButton = useMemo(() => {
    if (isDisable) {
      return (
        <Button size="small" onClick={onEnableAccess} isLoading={isLoading}>
          Enable access
        </Button>
      );
    }
    return (
      <Button size="small" onClick={onDisableAccess} variant="outlined" isLoading={isLoading}>
        Disable access
      </Button>
    );
  }, [isDisable, isLoading, onDisableAccess, onEnableAccess]);

  const statusName = isDisable ? 'Disable' : 'Available';
  return (
    <ItemContainer className="d-flex flex-column">
      <div className="d-flex justify-content-between">
        <Title>{name}</Title>
        <Status isDisable={isDisable}>{statusName}</Status>
      </div>

      <Divider />

      <ValueWrapper className="d-flex justify-content-between">
        <ValueName>Last status change:</ValueName>
        <Value maxWidth={140}>{withDate(lastStatusDate, FRONTEND_DATE_TIME)}</Value>
      </ValueWrapper>
      <ValueWrapper className="d-flex justify-content-between">
        <ValueName>Changed by:</ValueName>
        <Value maxWidth={190}>{changedBy}</Value>
      </ValueWrapper>
      {cause && (
        <ValueWrapper className="d-flex justify-content-between">
          <ValueName>Reason:</ValueName>
          <Value maxWidth={216}>{cause}</Value>
        </ValueWrapper>
      )}

      {changeStatusButton}
    </ItemContainer>
  );
}

export default memo(AccessPanel);
export type AccessPanelProps = IProps;
