import React, {memo, useCallback, useEffect} from 'react';
import styled from 'styled-components';

import {ModalWindowFormContent, ModalWindowHeader} from 'Common/components/Modal/shared';

import Loading from 'Loading/components/Loading';
import withHorseTransferActions, {
  IWithHorseTransferActionsProps,
} from 'Admin/AdminDashboard/helpers/withHorseTransferActions';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {CellSeveral, ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import withDate from 'Common/helpers/withDate';
import {FRONTEND_DATE} from 'Common/constants/Date';

const NO_DATA = <CellSeveral>No data</CellSeveral>;

const Root = styled.div`
  margin-bottom: 32px;
`;

const Container = styled.div`
  margin: 8px 4px 8px 0px;
`;

const FieldName = styled.div`
  color: ${ColorPalette.gray36};
  font-size: ${Typography.size.size18};
  font-family: ${Typography.family.openSans};
  margin: 0px 8px 4px 0px;
`;

const FieldValue = styled.div`
  color: ${ColorPalette.black1};
  font-size: ${Typography.size.size18};
  font-family: ${Typography.family.openSans};
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${ColorPalette.gray10};
  margin: 8px 0;
`;

interface IExternalProps {
  horseTransferId: number;
}

type OuterProps = IWithHorseTransferActionsProps & IExternalProps;

type AllProps = OuterProps;

function TransferDetails(props: AllProps) {
  const {horseTransferId, getHorseTransferDetails, horseTransferDetails, horseTransferDetailsLoading} = props;

  useEffect(() => {
    getHorseTransferDetails(horseTransferId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorInfo = horseTransferDetailsLoading.error;
  const isRequesting = horseTransferDetailsLoading.isRequesting;

  const checkByNullFunc = useCallback((data) => data ?? NO_DATA, []);

  return (
    <>
      <ModalWindowHeader>Transfer details</ModalWindowHeader>

      <ModalWindowFormContent>
        {isRequesting && <Loading />}
        <Root>
          <div className="d-flex flex-row">
            <Container className="d-flex flex-row w-50">
              <FieldName>ID:</FieldName>
              <FieldValue>{checkByNullFunc(horseTransferId)}</FieldValue>
            </Container>

            <Container className="d-flex flex-row w-50">
              <FieldName>Request date:</FieldName>
              <FieldValue>
                {horseTransferDetails ? withDate(horseTransferDetails?.createDate, FRONTEND_DATE) : NO_DATA}
              </FieldValue>
            </Container>
          </div>

          <div className="d-flex flex-row">
            <Container className="d-flex flex-row">
              <FieldName>Horse name:</FieldName>
              <FieldValue>{checkByNullFunc(horseTransferDetails?.horse.name)}</FieldValue>
            </Container>
          </div>

          <div className="d-flex flex-row">
            <Container className="d-flex flex-row">
              <FieldName>Current owner:</FieldName>
              <FieldValue>{checkByNullFunc(horseTransferDetails?.owner.name)}</FieldValue>
            </Container>
          </div>

          <Divider />

          <div className="d-flex flex-row">
            <Container className="d-flex flex-row">
              <FieldName>Client name:</FieldName>
              <FieldValue>{checkByNullFunc(horseTransferDetails?.providedName)}</FieldValue>
            </Container>
          </div>

          <div className="d-flex flex-row">
            <Container className="d-flex flex-row">
              <FieldName>Client email:</FieldName>
              <FieldValue>{checkByNullFunc(horseTransferDetails?.providedEmail)}</FieldValue>
            </Container>
          </div>

          <div className="d-flex flex-row">
            <Container className="d-flex flex-row">
              <FieldName>Client phone:</FieldName>
              <FieldValue>{checkByNullFunc(horseTransferDetails?.providedPhone)}</FieldValue>
            </Container>
          </div>

          <Divider />

          <div className="d-flex flex-row">
            <Container className="d-flex flex-row">
              <FieldName>Status:</FieldName>
              <FieldValue>{checkByNullFunc(horseTransferDetails?.status)}</FieldValue>
            </Container>
          </div>

          <div className="d-flex flex-row">
            <Container className="d-flex flex-column ">
              <FieldName>Rejection comment (for admin using only):</FieldName>
              <FieldValue>{checkByNullFunc(horseTransferDetails?.comment)}</FieldValue>
            </Container>
          </div>

          <div className="d-flex flex-row">
            <Container className="d-flex flex-column ">
              <FieldName>Reason (for email to user):</FieldName>
              <FieldValue>{checkByNullFunc(horseTransferDetails?.reason)}</FieldValue>
            </Container>
          </div>

          <ErrorMessage>{getCommonErrors(errorInfo)}</ErrorMessage>
        </Root>
      </ModalWindowFormContent>
    </>
  );
}

export default memo(withHorseTransferActions(TransferDetails));
