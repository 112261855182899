import * as Yup from 'yup';

import {ILanguage} from 'Common/models/ILanguage';
import {IErrorDescription} from '../../../models/IErrorDescription';

export const initialValue: IErrorDescription = {
  id: 0,
  code: '',
  language: null,
  languageId: 1,
  value: ''
};

export const validationSchema = Yup.object().shape<IErrorDescription>({
  id: Yup.number(),
  code: Yup.string().required('Field is required'),
  value: Yup.string().required('Field is required'),
  language: Yup.mixed<ILanguage>().nullable(),
  languageId: Yup.number().nullable()
});
