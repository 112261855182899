import React from 'react';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps} from 'formik/dist/types';

import {ImageUploaderField, InputField} from 'Common/components/FormFields';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {
  IFormValues,
  initialValue,
  validationSchema,
} from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/parts/OwnerGeneralInfoSection/validation';
import {actions, selectors} from 'BusinessPortal/store/owner';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {
  convertToClient,
  convertToServer,
  convertToServerCreate,
} from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/parts/OwnerGeneralInfoSection/converters';
import {IOwnerInfoSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/parts/OwnerGeneralInfoSection/models/IOwnerInfoSectionData';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {MutableField, MutableFieldsGroup} from 'Common/components/StyledComponents/StyledComponents';
import {IconName} from 'Icon/components/Icon';
import {FormType} from 'Common/constants/FormType';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';

type IExternalProps = IEditableSection<IOwnerInfoSectionData>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const OwnerGeneralInfoSectionEdit = (props: AllProps) => {
  const {isValid, isSubmitting, values} = props;
  const {ownerUpdating, ownerCreating} = props;

  const isRequesting = ownerUpdating.isRequesting || ownerCreating.isRequesting;
  const formType = values.id ? FormType.edit : FormType.create;
  const saveButtonHeader = formType === FormType.edit ? 'Save changes' : 'Create an Owner';

  useErrorCommunicationToToast(ownerUpdating);
  useErrorCommunicationToToast(ownerCreating);

  if (isRequesting) {
    return <Loading />;
  }

  return (
    <Form>
      {formType === FormType.create && (
        <ImageUploaderField
          name="avatar"
          memoized={true}
          width="104px"
          height="104px"
          isRound={true}
          actionButtonsContainerHeight="100%"
          iconProps={{name: IconName.OwnerDefaultAvatar, width: 100, height: 100}}
          inputStyle={{marginBottom: 24}}
        />
      )}
      <FormControlContainer title="General Information">
        <InputField
          isRequired={true}
          name="firstName"
          type="text"
          label="First Name"
          placeholder="First name"
          autoComplete="off"
          memoized={true}
        />
        <InputField
          isRequired={true}
          name="lastName"
          type="text"
          label="Last Name"
          placeholder="Last name"
          autoComplete="off"
          memoized={true}
        />
        <InputField
          name="email"
          type="email"
          label="Email"
          placeholder="Email"
          autoComplete="off"
          memoized={true}
          isRequired
        />
        <InputField name="phone" type="text" label="Phone" placeholder="Phone" autoComplete="off" memoized={true} />
      </FormControlContainer>
      <FormControlContainer title="Postal Address">
        <InputField name="address.street" label="Address" placeholder="Enter your address" />
        <MutableFieldsGroup indent="16px">
          <MutableField width={50}>
            <InputField name="address.city" label="City" placeholder="City" />
          </MutableField>
          <MutableField width={50}>
            <InputField name="address.state" label="State" placeholder="State" />
          </MutableField>
        </MutableFieldsGroup>
        <MutableFieldsGroup indent="16px">
          <MutableField width={50}>
            <InputField name="address.zip" label="ZIP / POSTAL" placeholder="ZIP / POSTAL" />
          </MutableField>
          <MutableField width={50}>
            <InputField name="address.country" label="Country" placeholder="Country" />
          </MutableField>
        </MutableFieldsGroup>
      </FormControlContainer>
      <PrimaryButton className="w-100" size="medium" type="submit" disabled={!isValid} isLoading={isSubmitting}>
        {saveButtonHeader}
      </PrimaryButton>
    </Form>
  );
};

const OwnerInfoSectionFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({data}) => (data?.owner ? convertToClient(data.owner) : initialValue),
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {createOwner, updateOwner, updateOwnerAvatar, onSuccess} = formikBag.props;
    if (values.id) {
      await updateOwner(convertToServer(values));
    } else {
      const ownerId = await createOwner(convertToServerCreate(values));
      if (ownerId) {
        if (values.avatar?.file) {
          await updateOwnerAvatar(ownerId, values.avatar.file);
        }
      }
    }
    onSuccess && onSuccess();
  },
  enableReinitialize: true,
})(OwnerGeneralInfoSectionEdit);

const mapStateToProps = (state: IAppState) => ({
  ownerUpdating: selectors.selectCommunication(state, 'ownerUpdating'),
  ownerCreating: selectors.selectCommunication(state, 'ownerCreating'),
  ownerAvatarUpdating: selectors.selectCommunication(state, 'ownerAvatarUpdating'),
});

const mapDispatchToProps = {
  updateOwner: actions.updateOwner,
  createOwner: actions.createOwner,
  updateOwnerAvatar: actions.updateOwnerAvatar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(OwnerInfoSectionFormik);
