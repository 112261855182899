import {combineReducers} from 'redux';

import {
  AdminAssociationOrdersActions,
  changeSampleActionTypes,
  deleteOrderActionTypes,
  deleteReportActionTypes,
  downloadReportsActionTypes,
  downloadSubmissionFormActionTypes,
  getOrderDetailsActionTypes,
  getOrderPaymentDetailsActionTypes,
  getOrderReportDetailsActionTypes,
  getOrderRequiredTestsActionTypes,
  getOrdersActionTypes,
  getOrderSampleDetailsActionTypes,
  getOrderStatusesDetailsActionTypes,
  getOrderTestsDetailsActionTypes,
  IAdminAssociationOrderState,
  releaseOrderHorseActionTypes,
  requestNewSampleActionTypes,
  resetChangeSampleCommunicationActionTypes,
  resetOrderDetailsActionTypes,
  setSampleStatusActionTypes,
  uploadReportActionTypes,
  uploadTestResultsActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication/index';
import composeReducers from 'Common/store/composeReducers';

export const communicationOrdersReducer = combineReducers<
  IAdminAssociationOrderState['communications'],
  AdminAssociationOrdersActions
>({
  ordersLoading: makeCommunicationReducerFromEnum(getOrdersActionTypes),
  orderDetailsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getOrderDetailsActionTypes),
    makeResetCommunicationReducer(resetOrderDetailsActionTypes.RESET),
  ]),
  orderPaymentDetailsLoading: makeCommunicationReducerFromEnum(getOrderPaymentDetailsActionTypes),
  testResultsUploading: makeCommunicationReducerFromEnum(uploadTestResultsActionTypes),
  orderHorseReleaseRequesting: makeCommunicationReducerFromEnum(releaseOrderHorseActionTypes),
  orderDeleting: makeCommunicationReducerFromEnum(deleteOrderActionTypes),
  newSampleRequesting: makeCommunicationReducerFromEnum(requestNewSampleActionTypes),
  setSampleStatusRequesting: makeCommunicationReducerFromEnum(setSampleStatusActionTypes),
  downloadReportsRequesting: makeCommunicationReducerFromEnum(downloadReportsActionTypes),
  downloadSubmissionFormRequesting: makeCommunicationReducerFromEnum(downloadSubmissionFormActionTypes),
  changeSampleRequesting: composeReducers([
    makeCommunicationReducerFromEnum(changeSampleActionTypes),
    makeResetCommunicationReducer(resetChangeSampleCommunicationActionTypes.RESET),
  ]),
  requiredTestsLoading: makeCommunicationReducerFromEnum(getOrderRequiredTestsActionTypes),
  orderReportDetailsLoading: makeCommunicationReducerFromEnum(getOrderReportDetailsActionTypes),
  reportUploading: makeCommunicationReducerFromEnum(uploadReportActionTypes),
  reportDeleting: makeCommunicationReducerFromEnum(deleteReportActionTypes),
  orderStatusesDetailsLoading: makeCommunicationReducerFromEnum(getOrderStatusesDetailsActionTypes),
  orderSampleDetailsLoading: makeCommunicationReducerFromEnum(getOrderSampleDetailsActionTypes),
  orderTestsDetailsLoading: makeCommunicationReducerFromEnum(getOrderTestsDetailsActionTypes),
});
