import {combineReducers, Reducer} from 'redux';

import {IBusinessPortalOwnerProfileState} from '../types';
import {communicationOwnerProfileReducer} from './communications';
import {dataOwnerProfileReducer} from './data';
import {uiReducer} from './ui';

export const ownerProfileReducer: Reducer<IBusinessPortalOwnerProfileState> =
  combineReducers<IBusinessPortalOwnerProfileState>({
    communications: communicationOwnerProfileReducer,
    data: dataOwnerProfileReducer,
    ui: uiReducer,
  });
