import {combineReducers} from 'redux';

import {IAdminHorseTransferState} from '../types';
import {communicationHorseTransferReducer} from './communications';
import {dataHorseTransferReducer} from './data';
import {uiHorseTransferReducer} from './ui';

export const reducer = combineReducers<IAdminHorseTransferState>({
  communications: communicationHorseTransferReducer,
  data: dataHorseTransferReducer,
  ui: uiHorseTransferReducer,
});
