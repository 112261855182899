import React, {memo} from 'react';
import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';

const Root = styled.div`
  display: flex;
  margin-left: -16px;
  flex-wrap: wrap;
`;

const Chip = styled.div<{withCross?: boolean}>`
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 18px;
  margin-bottom: 8px;
  margin-left: 16px;
  border-radius: 20px;
  background: ${Theme.color.primary};
  color: ${Theme.color.white};
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  padding-right: ${props => (props.withCross ? '10px' : '16px')};
`;

const Cross = styled(IconButton)`
  opacity: 0.2;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

export interface IChip {
  name: string;
  label: string;
  pinnedChip?: boolean;
}

interface IProps {
  chips: IChip[];
  onRemove(value: IChip['name']): void;
}

function Chips(props: IProps) {
  const {chips, onRemove} = props;
  return (
    <Root>
      {chips.map(({name, label, pinnedChip}, i) => (
        <Chip key={i} withCross={!pinnedChip}>
          {label}
          {!pinnedChip && (
            <Cross
              name={IconName.Close}
              size={10}
              color={Theme.color.white}
              hoverColor={Theme.color.white}
              onClick={() => onRemove(name)}
              stroke={false}
              fill={true}
            />
          )}
        </Chip>
      ))}
    </Root>
  );
}

export default memo(Chips);
