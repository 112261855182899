import {IModule} from 'redux-dynamic-modules';

import {adminPhenotypeGroupImageReducer} from 'Admin/AdminPhenotypes/store/adminPhenotypeGroupImage/reducer/index';
import {IAdminPhenotypeGroupImageState} from 'Admin/AdminPhenotypes/store/adminPhenotypeGroupImage/types';

export interface IAdminPhenotypeGroupImageModuleState {
  adminPhenotypeGroupImage: IAdminPhenotypeGroupImageState;
}

export const AdminPhenotypeGroupImageModule: IModule<IAdminPhenotypeGroupImageModuleState> = {
  id: 'phenotypeGroupImage',
  reducerMap: {
    adminPhenotypeGroupImage: adminPhenotypeGroupImageReducer
  }
};
