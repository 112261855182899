import React, {memo} from 'react';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout, {
  DictionaryFormProps,
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';
import {IAdminGenotype} from 'Admin/AdminPhenotypes/models/IAdminGenotype';
import GenotypeForm from './GenotypeForm/GenotypeForm';
import {IDictionary} from 'DictionaryFactory/types/dictionary';

function Genotypes() {
  const {adminGenotype: dictionary, traits} = useDictionaries();

  const wrappedGenotypeForm = (props: DictionaryFormProps<IDictionary<IAdminGenotype, IAdminGenotype>>) => {
    return <GenotypeForm {...props} traitDictionary={traits} />;
  };

  return (
    <div>
      <DictionaryLayout filterField="alleleNames" dictionary={dictionary} DictionaryForm={wrappedGenotypeForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IAdminGenotype> dataKey="id" width={100} header="ID" render={({id}) => `#${id}`} />
            <Cell<IAdminGenotype>
              dataKey="trait"
              sortable={false}
              width="25%"
              header="Trait"
              render={({trait}) => trait?.name}
            />
            <Cell<IAdminGenotype>
              dataKey="alleleNames"
              width="20%"
              header="Variants"
              render={({alleleNames}) => alleleNames}
            />
            <Cell<IAdminGenotype>
              dataKey="positionIndex"
              width="10%"
              header="Order in Report"
              sortable={false}
              render={({positionIndex}) => positionIndex}
            />
            <Cell<IAdminGenotype>
              dataKey="description"
              width="30%"
              header="Description"
              sortable={false}
              render={({description}) => description}
            />
            <Cell<IAdminGenotype>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(Genotypes);
