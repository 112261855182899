import {combineReducers} from 'redux';

import {
  AdminAncestryReportActions,
  getAncestryKinshipHorsesActionTypes,
  getAncestryProbabilitiesActionTypes,
  getAncestryRelatedHorsesActionTypes,
  getPopulationActionTypes,
  getPopulationComparisonActionTypes,
  getReviewAncestryProbabilitiesActionTypes,
  getReviewPopulationActionTypes,
  getReviewPopulationComparisonActionTypes,
  IAdminAncestryReportState,
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationReducer = combineReducers<
  IAdminAncestryReportState['communications'],
  AdminAncestryReportActions
>({
  populationLoading: makeCommunicationReducerFromEnum(getPopulationActionTypes),
  reviewPopulationLoading: makeCommunicationReducerFromEnum(getReviewPopulationActionTypes),
  populationComparisonLoading: makeCommunicationReducerFromEnum(getPopulationComparisonActionTypes),
  reviewPopulationComparisonLoading: makeCommunicationReducerFromEnum(getReviewPopulationComparisonActionTypes),
  ancestryProbabilitiesLoading: makeCommunicationReducerFromEnum(getAncestryProbabilitiesActionTypes),
  reviewAncestryProbabilitiesLoading: makeCommunicationReducerFromEnum(getReviewAncestryProbabilitiesActionTypes),
  ancestryRelatedHorsesLoading: makeCommunicationReducerFromEnum(getAncestryRelatedHorsesActionTypes),
  ancestryKinshipHorsesLoading: makeCommunicationReducerFromEnum(getAncestryKinshipHorsesActionTypes),
});
