import React, {memo} from 'react';
import styled from 'styled-components';

import Nebula from 'Common/components/Layout/Nebula';
import ImageUploader from 'Image/components/ImageUploader';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {TextBase} from '../styled';
import Typography from 'Common/constants/Typography';
import ColoredIcon, {IColoredIconProps} from 'Icon/components/ColoredIcon';
import {IImage} from 'Image/models/IImage';
import {Nullable} from 'Common/types';

const Root = styled.div<{fixedIcon: boolean; hasShadow: boolean}>`
  padding: 8px 24px 8px 16px;
  box-shadow: ${({hasShadow}) => (hasShadow ? `0 1px 0 ${ColorPalette.white9}` : 'none')};
  cursor: pointer;

  justify-content: ${({fixedIcon}) => (fixedIcon ? 'space-between' : 'start')};

  :hover {
    background-color: ${ColorPalette.gray49};
  }
`;

const InfoWrapper = styled.div`
  margin-right: 24px;
`;

const Name = styled(TextBase)`
  font-size: ${Typography.size.size16};
  letter-spacing: 0;
  margin-left: 16px;
`;

const GoToIcon = styled(ColoredIcon)`
  transform: rotate(-90deg);
`;

interface IProps {
  name: string;
  avatar: Nullable<IImage>;
  onClick(): void;
  fixedIcon?: boolean;
  hasShadow?: boolean;
  iconProps?: Partial<IColoredIconProps>;
}

const GoToContainer = (props: IProps) => {
  const {name, avatar, iconProps, hasShadow = true, fixedIcon = true, onClick} = props;

  return (
    <Root className="d-flex align-items-center" fixedIcon={fixedIcon} hasShadow={hasShadow} onClick={onClick}>
      <InfoWrapper className="d-flex align-items-center">
        <Nebula active={true} style={{opacity: 1, background: 'none'}}>
          <ImageUploader
            width="40px"
            height="40px"
            disabled={true}
            isRound={true}
            showEditButton={false}
            showDeleteButton={false}
            actionButtonsContainerHeight="0%"
            value={avatar}
            iconProps={{name: IconName.HorseDefaultAvatar}}
          />
        </Nebula>
        <Name>{name}</Name>
      </InfoWrapper>
      <GoToIcon
        color={ColorPalette.white12}
        fill={true}
        stroke={false}
        size={12}
        {...iconProps}
        name={IconName.ArrowDown}
      />
    </Root>
  );
};

export default memo(GoToContainer);
