import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';

export const MainNameRow = styled.div`
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size24};
  line-height: 32px;
  color: ${ColorPalette.black1};
  margin-bottom: 8px;
`;

export const MainInfoRow = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 12px;
  color: ${ColorPalette.gray44};
  ::first-letter {
    text-transform: uppercase;
  }
`;
