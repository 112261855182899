import React, {memo} from 'react';
import styled from 'styled-components';

import FileUploader, {FileUploaderProps} from 'Common/components/Controls/FileUploader';
import {IFieldProps} from 'Common/models/IFieldProps';

import withField from './withField';
import ValidationError from './ValidationError';

const InputLayout = styled.div`
  position: relative;
`;

type Props = IFieldProps<File[] | null> & Omit<FileUploaderProps, 'onChange' | 'isMultiple'>;

function FileUploaderField(props: Props) {
  const {
    field: {name},
    form
  } = props;

  const onChange = React.useCallback(
    (file: File) => {
      form.setFieldValue(name, file);
    },
    [form, name]
  );

  return (
    <InputLayout>
      <FileUploader {...props} onChange={onChange} name={name} />
      <ValidationError name={name} />
    </InputLayout>
  );
}

export default memo(withField(FileUploaderField));
