import React, {memo, useCallback} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import TwoActionButton, {ButtonPosition} from 'Common/components/Controls/Buttons/TwoActionButton';
import {IFoalParent} from 'Common/models/IFoal';
import {IOnSuccessedEntity} from 'Common/models/IOnSuccessedEntity';
import {IconName} from 'Icon/components/Icon';
import withExcludeHorseActions, {
  getWithExcludeHorseActionsModules,
  IWithExcludeHorseActionsProps,
} from 'Common/helpers/withExcludeHorseActions';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';

const Root = styled.div<{position: string}>`
  padding: ${({position}) => (position === 'Left' ? '0 0 0 8px' : '0 8px 0 0')};
  flex-direction: ${({position}) => (position === 'Left' ? 'row' : 'row-reverse')};
`;

interface IProps extends IOnSuccessedEntity {
  horse: IFoalParent;
  position: ButtonPosition;
  isShowLabel?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

type AllProps = IProps & IWithExcludeHorseActionsProps;

function ExcludeHorseIconButton(props: AllProps) {
  const {
    horse: {id, isExcluded},
    position,
    onSuccess,
    isShowLabel,
    style,
    className,
    addExcludeHorse,
    excludeHorseAdding,
    deleteExcludeHorse,
    excludeHorseDeleting,
  } = props;

  useErrorCommunicationToToast(excludeHorseAdding, undefined, 1);
  useErrorCommunicationToToast(excludeHorseDeleting, undefined, 2);

  const handlerActiveAction = useCallback(
    async (e) => {
      e.stopPropagation();
      await deleteExcludeHorse(id);
    },
    [deleteExcludeHorse, id]
  );

  const handlerInactiveAction = useCallback(
    async (e) => {
      e.stopPropagation();
      await addExcludeHorse(id);
    },
    [addExcludeHorse, id]
  );

  return (
    <Root
      style={style}
      className={classNames('d-flex align-items-center justify-content-start', className)}
      position={position}
    >
      <TwoActionButton
        isActive={isExcluded}
        labelActive="Excluded"
        labelInactive="Exclude from results"
        isShowLabel={isShowLabel}
        position={position}
        onSuccess={onSuccess}
        onActiveAction={handlerActiveAction}
        onInactiveAction={handlerInactiveAction}
        iconNameActive={IconName.Exclude}
        iconNameActiveHover={IconName.ExcludeHover}
        iconNameInactive={IconName.ExcludeRemove}
        iconNameInactiveHover={IconName.ExcludeRemoveHover}
      />
    </Root>
  );
}

const Connected = withExcludeHorseActions(memo(ExcludeHorseIconButton));

export default withDynamicModules(Connected, getWithExcludeHorseActionsModules());
