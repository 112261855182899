import {combineReducers, Reducer} from 'redux';

import {communicationServiceModeReducer} from './communications';
import {dataServiceModeReducer} from './data';
import {IAdminServiceModeState} from '../types';

export const serviceModeReducer: Reducer<IAdminServiceModeState> = combineReducers<IAdminServiceModeState>({
  communications: communicationServiceModeReducer,
  data: dataServiceModeReducer
});
