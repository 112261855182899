import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import UploadPanel from 'Common/components/Controls/UploadPanel';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName, IProps as IconProps} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import {ImageModule} from 'Image/store/imageModule';
import {IImage} from 'Image/models/IImage';
import {isImageExists} from 'Common/helpers/ImagesHelper';
import {downloadByUrl} from 'Common/helpers/downloadHelper';
import {getFileName} from 'Common/helpers/getFileName';
import Theme from 'Common/constants/Theme';
import ImagePreview from 'Image/components/imageUploaderPreview/ImagePreviewShort/ImagePreview';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {MediaType} from 'Common/models/IMediaResource';

enum SelectImageError {
  wrongFormat = 'wrongFormat',
  tooLarge = 'tooLarge'
}

const selectImageErrors: Record<SelectImageError, string> = {
  wrongFormat: 'Accepted image formats are png or jpg',
  tooLarge: `File size cannot exceed 25 Mb`
};

const UploadText = styled.span`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${ColorPalette.gray44};
  text-align: center;
  margin-top: 12px;
`;

const ErrorMessage = styled.div`
  margin-top: 8px;
  color: ${ColorPalette.red1};
`;

interface IOwnProps {
  isRound?: boolean;
  actionButtonsContainerHeight?: string;
  height?: string;
  width?: string;
  iconName?: IconName;
  iconProps?: IconProps;
  minSize?: number;
  maxSize?: number;
  title?: string;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  showDownloadButton?: boolean;
  value: IImage | null;
  isShowError?: boolean;
  acceptPartialSelection?: boolean;
  isShowBlurredBackground?: boolean;
  onError?(error: string): void;
  onBlur?(): void;
  onDelete?(): void;
}

type OnChangeHandler =
  | {
      isMultiple: true;
      onChange?: MultipleImageHandler;
    }
  | {
      isMultiple?: false;
      onChange?: SingleImageHandler;
    };

type SingleImageHandler = (value: IImage) => void;
type MultipleImageHandler = (value: IImage[]) => void;

type Props = OnChangeHandler & IBaseControlProps & IOwnProps;

function ImageUploader(props: Props) {
  const [isUrlExists, setIsUrlExists] = useState(true);

  const {
    onChange,
    height,
    iconProps,
    minSize,
    showDeleteButton,
    showDownloadButton,
    showEditButton,
    title,
    width,
    onBlur,
    value,
    isMultiple,
    onDelete,
    onError,
    maxSize,
    isShowError,
    isRound,
    actionButtonsContainerHeight,
    acceptPartialSelection,
    isShowBlurredBackground
  } = props;

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    if (value) {
      isImageExists(value.url)
        .then(() => {
          if (isMounted) {
            setIsUrlExists(true);
          }
        })
        .catch(() => {
          if (isMounted) {
            setIsUrlExists(false);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [value]);

  const handleBlur = React.useCallback(() => {
    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  const handleSingleFile = useCallback(
    (file: File) => {
      const url = URL.createObjectURL(file);
      const image = {id: 0, url, file, isLocal: true, type: MediaType.Image};
      (onChange as SingleImageHandler)(image);
    },
    [onChange]
  );

  const handleMultipleFile = useCallback(
    (files: File[]) => {
      const images = files.map(file => {
        const url = URL.createObjectURL(file);
        return {id: 0, url, file, isLocal: true, type: MediaType.Image};
      });
      (onChange as MultipleImageHandler)(images);
    },
    [onChange]
  );

  const handleSelectFiles = React.useCallback(
    (acceptedFiles: File[]) => {
      setError(null);
      setIsUrlExists(true);
      handleBlur();
      if (acceptedFiles.length === 0 || !onChange) {
        return;
      }

      if (isMultiple) {
        handleMultipleFile(acceptedFiles);
      } else {
        handleSingleFile(acceptedFiles[0]);
      }
    },
    [isMultiple, setIsUrlExists, handleBlur, handleMultipleFile, handleSingleFile, onChange]
  );

  const handleRejectFiles = React.useCallback(
    (rejectedFiles: File[]) => {
      const someImageIsTooLarge = maxSize && rejectedFiles.some(file => file.size > maxSize);
      const rejectImageError = someImageIsTooLarge
        ? selectImageErrors[SelectImageError.tooLarge]
        : selectImageErrors[SelectImageError.wrongFormat];
      if (isShowError) {
        setError(rejectImageError);
      }
      if (onError) {
        onError(rejectImageError);
      }
    },
    [onError, maxSize, isShowError]
  );

  const handleOnDeleteImage = React.useCallback(() => {
    setError(null);
    onDelete && onDelete();
    handleBlur();
  }, [handleBlur, onDelete]);

  const isNeedLoadImage = !value?.url;

  const handleDownloadImage = React.useCallback(() => {
    value && downloadByUrl(value.url, getFileName(value.url, '.jpg'), '.jpg');
  }, [value]);

  const iconName = props.iconName || iconProps?.name;

  const renderImage = useMemo(() => {
    if (isNeedLoadImage) {
      return (
        <UploadPanel
          isRound={isRound}
          onSelectFiles={handleSelectFiles}
          onRejectFiles={handleRejectFiles}
          height={height}
          width={width}
          minSize={minSize}
          maxSize={maxSize}
          acceptableTypes={'image/*'}
          multiple={isMultiple}
          acceptPartialSelection={acceptPartialSelection}
        >
          {iconName && <ColoredIcon name={iconName} color={ColorPalette.gray48} size={50} fill={true} {...iconProps} />}
          {title && <UploadText>{title}</UploadText>}
        </UploadPanel>
      );
    }

    return (
      <ImagePreview
        isRound={isRound}
        actionButtonsContainerHeight={actionButtonsContainerHeight}
        height={height!}
        width={width!}
        value={value}
        minSize={minSize}
        showDeleteButton={showDeleteButton}
        showEditButton={showEditButton}
        showDownloadButton={showDownloadButton}
        handleDownloadImage={handleDownloadImage}
        handleOnDeleteImage={handleOnDeleteImage}
        handleRejectFiles={handleRejectFiles}
        handleSelectFiles={handleSelectFiles}
        isUrlExists={isUrlExists}
        isShowBlurredBackground={isShowBlurredBackground}
      />
    );
  }, [
    isNeedLoadImage,
    isRound,
    actionButtonsContainerHeight,
    height,
    width,
    value,
    minSize,
    showDeleteButton,
    showEditButton,
    showDownloadButton,
    handleDownloadImage,
    handleOnDeleteImage,
    handleRejectFiles,
    handleSelectFiles,
    isUrlExists,
    isShowBlurredBackground,
    maxSize,
    isMultiple,
    acceptPartialSelection,
    iconName,
    iconProps,
    title
  ]);

  return (
    <div>
      {renderImage}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}

ImageUploader.defaultProps = {
  height: '160px',
  width: '160px',
  minSize: 0,
  maxSize: 26214400,
  showDeleteButton: true,
  showEditButton: true,
  isShowError: true
};

export default withDynamicModules(ImageUploader, ImageModule);
export type ImageUploaderProps = Props;
