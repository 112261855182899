import React, {useCallback, useState} from 'react';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import VideoForm from 'Video/components/VideoForm';

interface IProps {
  onSuccess(url: string): void;
}

export function useVideoFormModal(props: IProps) {
  const {onSuccess} = props;

  const [url, setUrl] = useState('');

  const [isModalOpen, setModalOpen] = useState(false);

  const openVideoFormModal = useCallback((url: string) => {
    setUrl(url);
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => setModalOpen(false), []);

  const handleSuccess = useCallback(
    (url: string) => {
      closeModal();
      onSuccess(url);
    },
    [closeModal, onSuccess]
  );

  const renderVideoFormModal = (
    <ModalWindow isOpen={isModalOpen} onClose={closeModal}>
      <VideoForm url={url} onAdd={handleSuccess} onClose={closeModal} />
    </ModalWindow>
  );

  return {
    openVideoFormModal,
    renderVideoFormModal
  };
}
