import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IUserAdmin} from 'Admin/AdminDashboard/models/User/IUserAdmin';
import {IUserDetails} from 'Admin/AdminDashboard/models/User/IUserDetails';
import {
  IChangeEmail,
  IChangePasswordAdmin,
  IServerUserAdmin,
  IServerUserDetailsAdmin,
  IUserUpdateRequest,
} from 'Admin/AdminDashboard/services/types/user';
import {IUserFullInfo} from 'Admin/AdminDashboard/models/User/IUserFullInfo';
import {IFoundUser} from 'Admin/AdminDashboard/models/User/IFoundUser';
import {convertUserDetailsToClient, convertUserFullInfoToClient} from 'Admin/AdminDashboard/services/converters/user';
import {withAvatar} from 'Common/helpers/withAvatar';
import {withUserName} from 'Common/helpers/withUserName';
import {IUserCreateAdmin} from '../models/User/IUserCreateAdmin';

const getUsers = async (params?: IRequestParams): Promise<IListResponse<IUserAdmin>> => {
  const response = await axiosWrapper.get<IListResponse<IServerUserAdmin>>(`/UsersAdmin`, {params});
  return {...response.data, data: response.data.data.map(withAvatar).map(withUserName)};
};

const getUserDetails = async (id: number): Promise<IUserDetails> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerUserDetailsAdmin>>(`/UsersDetailsInfoAdmin/${id}`);
  return convertUserDetailsToClient(response.data.data);
};

const getUserFullInfo = async (id: number): Promise<IUserFullInfo> => {
  const response = await axiosWrapper.get<IObjectResponse<IUserFullInfo>>(`/UsersDetailsAdmin/${id}`);
  return convertUserFullInfoToClient(response.data.data);
};

const updateUserFullInfo = async (params?: IUserUpdateRequest): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<void>>(`/UsersUpdateAdmin`, params);
};

const deleteUser = async (id: number): Promise<null> => {
  const response = await axiosWrapper.delete<null>(`/UsersDeleteAdmin`, {data: {id}});
  return response.data;
};

const findUser = async (filter: string): Promise<IFoundUser[]> => {
  const response = await axiosWrapper.get<IObjectResponse<IFoundUser[]>>(`/FindUserAdmin`, {params: {filter}});
  return response.data.data.map(withAvatar);
};

const changeUserEmail = async (params: IChangeEmail): Promise<void> => {
  await axiosWrapper.patch<IObjectResponse<string>>(`/UsersEmailUpdateAdmin`, params);
};

const changeUserPassword = async (params: IChangePasswordAdmin): Promise<void> => {
  await axiosWrapper.patch<IObjectResponse<string>>(`/UsersPasswordUpdateAdmin`, params);
};

const createUser = async (data: IUserCreateAdmin): Promise<void> => {
  const response = await axiosWrapper.post<IObjectResponse<void>>(`/UsersCreateAdmin`, data);
  return response.data.data;
};

export default {
  getUsers,
  getUserDetails,
  getUserFullInfo,
  updateUserFullInfo,
  deleteUser,
  findUser,
  changeUserEmail,
  changeUserPassword,
  createUser,
};
