import React, {memo} from 'react';

import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {CheckboxGroupField} from 'Common/components/FormFields';
import {ICommercialType} from 'Dictionaries/models/ICommercialType';
import Checkbox from 'Common/components/Controls/Checkbox';

interface IProps {
  commercialTypes: ICommercialType[];
  values?: number[];
}

function Commerciality(props: IProps) {
  return (
    <FormControlContainer title="Breeding/Sales">
      <CheckboxGroupField name="commercialTypes" showSelectAll={true}>
        {props.commercialTypes?.map(item => {
          return (
            <Checkbox
              key={item.id}
              name={String(item.id)}
              checked={!!props.values?.find(value => value === item.id)}
              label={item.name}
            />
          );
        })}
      </CheckboxGroupField>
    </FormControlContainer>
  );
}

export default memo(Commerciality);
