import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {debounce} from 'lodash';
import * as R from 'ramda';

import {IRequestParams} from 'Common/models/IRequestParams';
import {useQueryParams} from 'Common/helpers/hooks/useQueryParams';
import {defaultRequestParams, requestParamsBlank} from 'Common/constants/RequestParams';
import {SearchBar} from 'Admin/AdminDashboard/components/shared/index';
import {useDebouncedEffect} from 'Common/helpers/hooks/useDebouncedEffect';

interface IProps<T> {
  getItems?(params: IRequestParams): void;
  getItemsAnotherParams?: Omit<T, keyof IRequestParams>;
  searchBarPlaceholder?: string;
  searchBarStyle?: React.CSSProperties;
  searchBarLogoStyle?: React.CSSProperties;
  defaultSorting?: Pick<IRequestParams, 'orderBy' | 'orderingType'>;
  getItemsIgnoreParams?: string[];
}

export function useSearchBar<T extends IRequestParams>(props: IProps<T>) {
  const {
    getItems,
    getItemsAnotherParams,
    searchBarPlaceholder,
    searchBarStyle,
    searchBarLogoStyle,
    defaultSorting,
    getItemsIgnoreParams
  } = props;

  const [requestParams, setRequestParams] = useQueryParams<IRequestParams>(requestParamsBlank, ['filter']);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params: IRequestParams = useMemo(() => ({...defaultRequestParams, ...defaultSorting, ...requestParams}), [
    requestParams
  ]);

  const [itemParams, setItemParams] = useState(params);
  useEffect(() => {
    const res = {...params, ...getItemsAnotherParams};
    getItemsIgnoreParams && getItemsIgnoreParams.forEach(ignoreKey => delete res[ignoreKey]);

    if (JSON.stringify(res) !== JSON.stringify(itemParams)) {
      setItemParams(res);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getItemsAnotherParams, params]);

  const reloadItems = useCallback(() => {
    getItems && getItems(itemParams);
  }, [getItems, itemParams]);

  useDebouncedEffect(reloadItems, 200);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeFilter = useCallback(
    debounce(async (value: string) => setRequestParams({...params, filter: value, currentPage: 1}), 500),
    [getItems, params]
  );

  const handleChangeFilter = useCallback(
    value => {
      setSearchQuery(value);
      debouncedChangeFilter(value);
    },
    [debouncedChangeFilter]
  );

  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    setSearchQuery(R.isNil(params.filter) ? '' : params.filter);
  }, [params.filter]);

  const searchBar = (
    <SearchBar
      onChange={handleChangeFilter}
      value={searchQuery}
      placeholder={searchBarPlaceholder}
      style={searchBarStyle}
      logoStyle={searchBarLogoStyle}
    />
  );

  return {
    handleChangeFilter,
    params,
    setRequestParams,
    searchQuery,
    searchBar,
    reloadItems
  };
}

export type UseSearchBarProps<T> = IProps<T>;
