const TOUR_STEPS = [
  {
    target: '#filter-container',
    title: 'Welcome to the Build-a-Horse guide!',
    content: (
      <div>
        <p>The options on the left will allow you to select the characteristics of the foal you want to create.</p>
        <p>For the purpose of this guide, let's see what options would help us breed a reining horse.</p>
      </div>
    ),
    placement: 'right' as const,
    styles: {tooltip: {width: '450px'}},
  },
  {
    target: '#gene-pools',
    content: (
      <div>
        <p>In Gene Pools we can exclude some parent horses from our search.</p>
        <p>Feel free to expand this section and explore the possible filters.</p>
        <p>For now, let's leave the default settings as they are so we can query all the publicly available horses.</p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '550px'}},
  },
  {
    target: '#specific-horse',
    content: (
      <div>
        <p>If you have a stallion of mare in mind, you can type its name here.</p>
        <p>If it is publicly available in the database, it will be used for matching.</p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '535px'}},
  },
  {
    target: '#abilities',
    content: (
      <div>
        <p>
          You can select characteristics for your horse such as <strong>Speed</strong>, <strong>Temperament</strong> and{' '}
          <strong>Gait (DMRT3)</strong>.
        </p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '450px'}},
  },
  {
    target: '#abilities',
    content: (
      <div>
        <p>
          Since we are trying to create a reining horse, it might be preferable to select{' '}
          <strong>"Mid-distance"</strong> for <strong>Speed</strong> and <strong>"Non-gaited DMRT3"</strong> for{' '}
          <strong>DMRT3</strong>.
        </p>
        <p>
          (Note that the presence or absence of these attributes is neither good nor bad, it simply helps you to select
          your desired behavior).
        </p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '560px'}},
  },
  {
    target: '#colors',
    content: (
      <div>
        <p>Here you can select your desired color and modifiers.</p>
        <p>
          For example, try typing <strong>"Buckskin"</strong> for <strong>Color</strong> and{' '}
          <strong>"Blue Eyes Likely"</strong> for <strong>Modifiers</strong>.
        </p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '445px'}},
  },
  {
    target: '#features',
    content: (
      <div>
        <p>Here you can select breeds and disciplines of the parents.</p>
        <p>Remember that "breed" and "discipline" are not currently genetically verified values.</p>
        <p>
          Try typing <strong>"Reining"</strong> as a Discipline.
        </p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '475px'}},
  },
  {
    target: '#health',
    content: (
      <div>
        <p>This option can be used to exclude undesired genetic carriers or variants.</p>
        <p>
          (Note that even if you skip this step, potential health issues or mismatches will be flagged by our matching
          system for you.)
        </p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '580px'}},
  },
  {
    target: '#build',
    content: (
      <p>
        Clicking <strong>"Build"</strong> will give you a list of stallion-mare pairings that can produce the foal you
        described.
      </p>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '450px'}},
  },
  {
    target: '#breeding-matches-container',
    content: `Here is our initial list of breeding pairs that can be combined to create your dream foal.`,
    placement: 'left-start' as const,
    disableBeacon: true,
    styles: {tooltip: {width: '300px'}},
  },
  {
    target: '#breeding-matches > div:nth-child(2) > div:nth-child(1)',
    content: (
      <div>
        <p>Here's a brief summary of this matched pair.</p>
        <p>
          We can learn more about the stallion, the mare and even the potential foal by clicking{' '}
          <strong>'More details'</strong>.
        </p>
      </div>
    ),
    placement: 'left' as const,
    styles: {tooltip: {width: '315px'}},
    spotlightClicks: true,
  },
  {
    target: 'body',
    content: (
      <div>
        <p>This concludes our tour.</p>
        <p>(To restart this tour simply refresh the page).</p>
      </div>
    ),
    disableBeacon: true,
    disableOverlay: true,
    styles: {tooltip: {width: '500px'}},
  },
];

export default TOUR_STEPS;
