import * as Yup from 'yup';

import {IImage} from 'Image/models/IImage';
import {IEntity} from 'Common/models/IEntity';
import {IDescription, IShortDescriptionEntity} from 'Common/models/IDescriptionEntity';
import {IUpdateShortColorRequest} from 'Admin/AdminReference/services/models/IServerShortColor';
import {IImaged} from 'Common/models/IImaged';

export interface IFormValues extends IShortDescriptionEntity, IImaged {
  id?: IEntity['id'];
  name: string;
}

export const initialValue: IFormValues = {
  name: '',
  image: null,
  description: {value: ''} as IDescription,
};

export const validationSchema = Yup.object().shape<IFormValues>({
  name: Yup.string().required('Field is required'),
  image: Yup.mixed<IImage>().nullable(),
});

export const convertToServer = (values: IFormValues): Omit<IUpdateShortColorRequest, 'id'> => {
  return {
    ...values,
    image: values.image ? {id: values.image.id} : null,
    description: {value: values.description?.value || ''},
  };
};

export const convertToServerUpdating = (values: IFormValues): IUpdateShortColorRequest => {
  return {
    ...convertToServer(values),
    id: values.id!,
  };
};
