import {combineReducers} from 'redux';

import {communicationPaymentDetailsUploadReducer} from 'Admin/AdminDashboard/store/common/paymentDetailsUpload/reducer/communications';
import {IPaymentDetailsUploadState} from 'Admin/AdminDashboard/store/common/paymentDetailsUpload/types';
import {dataPaymentDetailsUploadReducer} from 'Admin/AdminDashboard/store/common/paymentDetailsUpload/reducer/data';

export const paymentDetailsUploadReducer = combineReducers<IPaymentDetailsUploadState>({
  data: dataPaymentDetailsUploadReducer,
  communications: communicationPaymentDetailsUploadReducer
});
