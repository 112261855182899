import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {DynamicModuleLoader} from 'redux-dynamic-modules';
import styled from 'styled-components';
import {useHistory} from 'react-router';

import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import {IPermissionDomain} from 'Dictionaries/models/IPermissionDomain';
import {useDictionaries} from 'Common/store/useDictionaries';
import {AdminPermissionsModule} from 'Admin/common/Permissions/store/AdminPermissionsModule';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {Form, FormikProps, withFormik} from 'formik';
import Loading from 'Loading/components/Loading';
import {Divider} from 'Common/components/StyledComponents/StyledComponents';
import {IFormValues, validationSchema} from './validation';
import {IAppState} from 'Common/store/IAppState';
import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {actions, selectors} from 'Admin/common/Permissions/store/index';
import {IAdministrator} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdministrator';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import {SwitchButton} from 'Common/components/Controls/Buttons/SwitchButton';
import Checkbox from 'Common/components/Controls/Checkbox';
import Nebula from 'Common/components/Layout/Nebula';
import {AdminPermissionOperation} from 'Common/constants/AdminPermissionOperation';
import {AdminPermissionDomain} from 'Common/constants/AdminPermissionDomain';
import {selectors as userSelectors} from 'UserProfile/store/currentUser';
import Tooltip from 'Common/components/Tooltip/Tooltip';

const Root = styled.div`
  margin: 0 24px 40px 0;
`;

const AdminInformationWrapper = styled.div`
  max-width: 70%;
  padding-bottom: 24px;
`;

const DomainContainer = styled.div`
  padding: 18px 18px 28px 0;
`;

const OperationContainer = styled.div`
  padding-top: 25px;
  max-width: 70%;
`;

const Label = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  color: ${Theme.color.gray};
`;

const Value = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;

const OperationValue = styled(Value)`
  margin-top: -3px;
`;

interface IExternalProps {
  admin: IAdministrator;
  permissionDomainsDictionary: ISimpleDictionary<IPermissionDomain>;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const AdminPermissionsForm = (props: AllProps) => {
  const {setErrors, isSubmitting, values, setFieldValue} = props;
  const {
    admin,
    onSuccess,
    permissionDomainsDictionary,
    getAdminPermissions,
    permissionDomains,
    currentAdminPermissionsLoading,
    currentAdmin,
    getCurrentAdminPermissions,
    resetAdminPermissions,
    adminPermissionsLoading,
    adminPermissionsUpdating,
  } = props;

  const history = useHistory();

  const {
    actions: {getItems: getPermissionDomains},
  } = permissionDomainsDictionary;

  useEffect(() => {
    getPermissionDomains();
    getAdminPermissions(admin.id);
  }, [admin.id, getAdminPermissions, getPermissionDomains, resetAdminPermissions]);

  useEffect(() => {
    return () => resetAdminPermissions();
  }, [resetAdminPermissions]);

  const errorInfo = adminPermissionsLoading.error || adminPermissionsUpdating.error;
  const isRequesting = adminPermissionsLoading.isRequesting || currentAdminPermissionsLoading.isRequesting;

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  const onSuccessHandler = useCallback(() => {
    const lostSuperiorPermission = !values.permissions.find((i) => i.domain === AdminPermissionDomain.Superior);
    if (currentAdmin?.id === values.adminId && lostSuperiorPermission) {
      getCurrentAdminPermissions();
      history.push('/admin-welcome');
      return;
    }

    onSuccess();
  }, [currentAdmin?.id, getCurrentAdminPermissions, history, onSuccess, values.adminId, values.permissions]);

  useOnSuccessCommunication(adminPermissionsUpdating, onSuccessHandler);
  useOnErrorCommunication(adminPermissionsUpdating, onError);
  useCommunicationToToast(adminPermissionsUpdating, 'Admin permissions has been changed');

  return (
    <>
      <ModalWindowHeader>Admin permissions</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <Root>
          <ModalWindowFormContent>
            {isRequesting && <Loading />}
            <AdminInformationWrapper className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <Label>User</Label>
                <Value>{admin.name}</Value>
              </div>
              <div className="d-flex flex-column">
                <Label>Email</Label>
                <Value>{admin.email}</Value>
              </div>
            </AdminInformationWrapper>
            <Divider />

            {permissionDomains.map((domain, i) => {
              const permission = values.permissions.find(
                (i) =>
                  i.domain === domain.code && i.operation.toLowerCase() === AdminPermissionOperation.Read.toLowerCase()
              );

              const onSwitchClick = () => {
                if (!permission) {
                  setFieldValue('permissions', [
                    ...values.permissions,
                    {domain: domain.code, operation: AdminPermissionOperation.Read},
                  ]);
                  return;
                }

                setFieldValue(
                  'permissions',
                  values.permissions.filter((i) => i.domain !== permission.domain)
                );
              };

              const onCheckboxClick = (operation: AdminPermissionOperation, isChecked: boolean) => {
                if (!isChecked) {
                  setFieldValue(
                    'permissions',
                    values.permissions.filter(
                      (i) => i.domain !== permission?.domain || i.operation.toLowerCase() !== operation.toLowerCase()
                    )
                  );
                  return;
                }

                setFieldValue('permissions', [...values.permissions, {domain: domain.code, operation}]);
              };

              const checkBox = (operation: AdminPermissionOperation) => (
                <div className="d-flex">
                  <Checkbox
                    labelStyle={{position: 'absolute', width: 100, height: 20}}
                    className="d-flex"
                    checked={
                      !!values.permissions.find(
                        (i) => i.domain === domain.code && i.operation.toLowerCase() === operation.toLowerCase()
                      )
                    }
                    onChange={(event) => onCheckboxClick(operation, event.target.checked)}
                  />
                  <OperationValue>Can {operation.toLowerCase()}</OperationValue>
                </div>
              );

              return (
                <React.Fragment key={i}>
                  <DomainContainer>
                    <div className="d-flex">
                      <Tooltip content={domain.description}>
                        <Value>{domain.name}</Value>
                      </Tooltip>
                      <SwitchButton onChangeHandler={onSwitchClick} checked={!!permission} />
                    </div>
                    <Nebula active={!permission} style={{opacity: 0.2}}>
                      <OperationContainer className="d-flex justify-content-between">
                        {checkBox(AdminPermissionOperation.Create)}
                        {checkBox(AdminPermissionOperation.Update)}
                        {checkBox(AdminPermissionOperation.Delete)}
                      </OperationContainer>
                    </Nebula>
                  </DomainContainer>
                  <Divider />
                </React.Fragment>
              );
            })}
          </ModalWindowFormContent>
        </Root>
        <ModalWindowFooter>
          <ModalWindowButton type="submit" isLoading={isSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const AdminPermissionsFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({admin, adminPermissions}) => {
    return {adminId: admin.id, permissions: adminPermissions};
  },
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    await formikBag.props.setAdminPermissions({adminId: values.adminId, permissions: values.permissions});
  },
  enableReinitialize: true,
})(AdminPermissionsForm);

const mapStateToProps = (state: IAppState, {permissionDomainsDictionary}: IExternalProps) => ({
  permissionDomains: permissionDomainsDictionary.selectors.selectItems(state),
  adminPermissions: selectors.selectAdminPermissions(state),
  currentAdmin: userSelectors.selectCurrentAdmin(state),
  adminPermissionsLoading: selectors.selectCommunication(state, 'adminPermissionsLoading'),
  currentAdminPermissionsLoading: selectors.selectCommunication(state, 'currentAdminPermissionsLoading'),
  adminPermissionsUpdating: selectors.selectCommunication(state, 'adminPermissionsUpdating'),
});

const mapDispatchToProps = {
  getCurrentAdminPermissions: actions.getCurrentAdminPermissions,
  getAdminPermissions: actions.getAdminPermissions,
  setAdminPermissions: actions.setAdminPermissions,
  resetAdminPermissions: actions.resetAdminPermissions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(AdminPermissionsFormFormik));
const Exported = (externalProps: Omit<IExternalProps, 'permissionDomainsDictionary'>) => {
  const {permissionDomains} = useDictionaries();

  return (
    <DynamicModuleLoader modules={[AdminPermissionsModule]}>
      <Connected permissionDomainsDictionary={permissionDomains} {...externalProps} />
    </DynamicModuleLoader>
  );
};

export default Exported;
