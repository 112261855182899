import React, {memo} from 'react';
import styled from 'styled-components';

import {IProbabilityEntity} from 'Common/models/IProbabilityEntity';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {sortProbabilities} from 'Common/helpers/ProbabilityHelper';
import Theme from 'Common/constants/Theme';

import ProbabilityListItem from './ProbabilityListItem';
import Icon, {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import capitalizeFirstLetter from 'Common/helpers/capitalizeFirstLetter';

function getProbabilitiesVariants(probabilities: IProbabilityEntity[], maxLength: number): string {
  return probabilities
    .slice(maxLength - 1)
    .map(p => capitalizeFirstLetter(p.name))
    .join(', ');
}

const Root = styled.div`
  font-family: ${Theme.font.primary};
`;

const Title = styled.div`
  font-family: ${Theme.font.secondary};
  font-size: ${Typography.size.size16};
  font-weight: ${Typography.weight.semiBold600};
  line-height: 24px;
  color: ${ColorPalette.gray48};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  margin-left: 8px;
`;

const Probabilities = styled.div`
  margin: 8px 0 0 8px;
`;

const ProbabilityItemContainer = styled.div<{withBorder?: boolean}>`
  padding: 8px 0;
  border-bottom: ${p => (p.withBorder ? `1px solid ${ColorPalette.gray47}` : 'unset')};
`;

const AnotherVariantsLayout = styled.div`
  display: grid;
  grid-template-columns: auto 32px;
  column-gap: 8px;
  padding: 8px 0;
  color: ${Theme.color.black};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  cursor: pointer;
  margin-left: 8px;
`;

const AnotherVariantsLabel = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const AnotherVariantsIcon = styled(ColoredIcon)`
  align-self: center;
  justify-self: center;
`;

const FullInfoIcon = styled(Icon)`
  left: -16px;
`;

interface IProps {
  title: string;
  probabilities: IProbabilityEntity[];
  higherIsBetter: boolean;
  anotherVariationText: string;
  maxLength?: number;
}

const ProbabilityList = (props: IProps) => {
  const {probabilities, higherIsBetter, maxLength = 3, anotherVariationText, title} = props;
  const extProbabilities = sortProbabilities(probabilities.slice(), higherIsBetter);

  const isShowAnotherVariant = extProbabilities.length > maxLength;

  const anotherVariants = isShowAnotherVariant ? getProbabilitiesVariants(probabilities, maxLength) : '';

  return (
    <Root>
      <Title>{title}</Title>
      <Probabilities>
        {extProbabilities.map((probability, index) => {
          const withBorder = isShowAnotherVariant || (!isShowAnotherVariant && index < maxLength - 1);
          return (
            index < maxLength && (
              <ProbabilityItemContainer key={index} withBorder={withBorder} className="position-relative">
                {!probability.isFullInfo && (
                  <FullInfoIcon name={IconName.ExclamationMark} height={16} width={16} className="position-absolute" />
                )}
                <ProbabilityListItem probability={probability} higherIsBetter={higherIsBetter} />
              </ProbabilityItemContainer>
            )
          );
        })}
      </Probabilities>
      {isShowAnotherVariant && (
        <Tooltip content={anotherVariants}>
          <div>
            <AnotherVariantsLayout>
              <AnotherVariantsLabel>{anotherVariationText}</AnotherVariantsLabel>
              <AnotherVariantsIcon
                name={IconName.ArrowDown}
                size={12}
                color={Theme.color.primary}
                fill={true}
                stroke={false}
              />
            </AnotherVariantsLayout>
          </div>
        </Tooltip>
      )}
    </Root>
  );
};

export default memo(ProbabilityList);
