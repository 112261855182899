import {ITestSummary} from 'Order/models/ITestSummary';

import {IServerTestSummary} from '../types/summary';
import {convertPriceToClient} from 'Common/helpers/convertPrices';

export function convertTestSummaryToClient(summary: IServerTestSummary): ITestSummary {
  const {
    total,
    couponDiscount,
    giftCardPayment,
    packagesPrice: {packagesTotal, packages}
  } = summary;
  return {
    total: convertPriceToClient(total),
    couponDiscount: convertPriceToClient(couponDiscount),
    giftCardPayment: convertPriceToClient(giftCardPayment),
    packagesPrice: {
      total: convertPriceToClient(packagesTotal),
      packages: packages.map(({id, name, packageCount, price}) => ({
        id,
        name,
        count: packageCount,
        price: convertPriceToClient(price)
      }))
    }
  };
}
