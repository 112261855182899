import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IObjectResponse} from 'Common/models/IResponse';
import {Permission} from 'Permissions/constants/Permission';
import {VisitorType} from 'Common/constants/VisitorType';
import {ApiByType, getApiByVisitorType} from 'Common/helpers/getApiByVisitorType';

const getCurrentPermissions = async (): Promise<IObjectResponse<Permission[]>> => {
  const apis: ApiByType = {
    [VisitorType.User]: `/Permission`,
    [VisitorType.AssociationEmployee]: `/AssociationEmployeePermission`,
  };

  const api = getApiByVisitorType(apis);

  const res = await axiosWrapper.get<IObjectResponse<Permission[]>>(api);
  return res.data;
};

export default {
  getCurrentPermissions,
};
