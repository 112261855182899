import React from 'react';
import styled from 'styled-components';

import MainButtonLayout, {MainPageButtonActionType} from 'Main/components/MainPageButtons/MainButtonLayout';
import {breakpoints} from 'Common/constants/Breakpoints';

const RootWrapper = styled.div`
  margin: 20px 0;
  justify-content: center;
`;

const ButtonWrapperRight = styled.div`
  width: 100%;
  max-width: 588px;
  margin-bottom: 16px;

  @media ${breakpoints.sm} {
    margin-bottom: 42px;
  }
  @media ${breakpoints.lg} {
    max-width: 527px;
  }
`;

const ButtonWrapperLeft = styled(ButtonWrapperRight)`
  @media ${breakpoints.lg} {
    margin-right: 16px;
  }
`;

const MainButtons = () => {
  return (
    <RootWrapper className="d-flex flex-wrap">
      <ButtonWrapperLeft>
        <MainButtonLayout type={MainPageButtonActionType.FAH} />
      </ButtonWrapperLeft>

      <ButtonWrapperRight>
        <MainButtonLayout type={MainPageButtonActionType.BAH} />
      </ButtonWrapperRight>
    </RootWrapper>
  );
};

export default MainButtons;
