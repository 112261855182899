import {action} from 'typesafe-actions';

import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {IUserUpdateRequest} from 'UserProfile/services/serverModels/IUserUpdateRequest';
import UserService from 'UserProfile/services/UserService';
import {
  confirmAgreementCurrentUserActions,
  confirmAgreementCurrentUserActionTypes,
  editProfileInfoCurrentUserActions,
  editProfileInfoCurrentUserActionTypes,
  getCurrentAdminActions,
  getCurrentAdminActionTypes,
  getCurrentAssociationEmployeeActions,
  getCurrentAssociationEmployeeActionTypes,
  getCurrentUserActions,
  getCurrentUserActionTypes,
  getProfileTypeCurrentUserActions,
  getProfileTypeCurrentUserActionTypes,
  resetCurrentAdminActions,
  resetCurrentAdminActionTypes,
  resetCurrentAssociationEmployeeActions,
  resetCurrentAssociationEmployeeActionTypes,
  resetCurrentUserActions,
  resetCurrentUserActionTypes,
  updateProfileTypeCurrentUserActions,
  updateProfileTypeCurrentUserActionTypes,
} from './types';

export const getCurrentAdmin =
  (): ActionResult<IAppState, Promise<void>, getCurrentAdminActions> => async (dispatch) => {
    dispatch(action(getCurrentAdminActionTypes.REQUEST));
    try {
      const data = await UserService.getCurrentAdmin();
      dispatch(action(getCurrentAdminActionTypes.SUCCESS, data));
    } catch (err) {
      dispatch(action(getCurrentAdminActionTypes.FAILURE, getErrorMessage(err)));
    }
  };

export const resetCurrentAdmin = (): ActionResult<IAppState, void, resetCurrentAdminActions> => (dispatch) => {
  dispatch(action(resetCurrentAdminActionTypes.RESET));
};

export const getCurrentUser = (): ActionResult<IAppState, Promise<void>, getCurrentUserActions> => async (dispatch) => {
  dispatch(action(getCurrentUserActionTypes.REQUEST));
  try {
    const data = await UserService.getCurrentUser();
    dispatch(action(getCurrentUserActionTypes.SUCCESS, data));
  } catch (err) {
    dispatch(action(getCurrentUserActionTypes.FAILURE, getErrorMessage(err)));
  }
};

export const resetCurrentUser = (): ActionResult<IAppState, void, resetCurrentUserActions> => (dispatch) => {
  dispatch(action(resetCurrentUserActionTypes.RESET));
};

export const getCurrentAssociationEmployee =
  (): ActionResult<IAppState, Promise<void>, getCurrentAssociationEmployeeActions> => async (dispatch) => {
    dispatch(action(getCurrentAssociationEmployeeActionTypes.REQUEST));
    try {
      const data = await UserService.getCurrentAssociationEmployee();
      dispatch(action(getCurrentAssociationEmployeeActionTypes.SUCCESS, data));
    } catch (err) {
      dispatch(action(getCurrentAssociationEmployeeActionTypes.FAILURE, getErrorMessage(err)));
    }
  };

export const resetCurrentAssociationEmployee =
  (): ActionResult<IAppState, void, resetCurrentAssociationEmployeeActions> => (dispatch) => {
    dispatch(action(resetCurrentAssociationEmployeeActionTypes.RESET));
  };

export const confirmAgreement =
  (isAgree: boolean, sign: string): ActionResult<IAppState, void, confirmAgreementCurrentUserActions> =>
  async (dispatch) => {
    try {
      dispatch(action(confirmAgreementCurrentUserActionTypes.REQUEST));
      await UserService.confirmAgreement(isAgree, sign);
      dispatch(action(confirmAgreementCurrentUserActionTypes.SUCCESS, {isAgree}));
    } catch (error) {
      dispatch(action(confirmAgreementCurrentUserActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const editUserInfo =
  (info: IUserUpdateRequest): ActionResult<IAppState, void, editProfileInfoCurrentUserActions> =>
  async (dispatch) => {
    try {
      dispatch(action(editProfileInfoCurrentUserActionTypes.REQUEST));
      const res = await UserService.updateUserInfo(info);
      dispatch(action(editProfileInfoCurrentUserActionTypes.SUCCESS, res.data.data));
    } catch (error) {
      action(editProfileInfoCurrentUserActionTypes.FAILURE, getErrorMessage(error));
    }
  };

export const getCurrentUserProfileType =
  (): ActionResult<IAppState, void, getProfileTypeCurrentUserActions> => async (dispatch) => {
    try {
      dispatch(action(getProfileTypeCurrentUserActionTypes.REQUEST));
      const res = await UserService.getUserProfileType();
      dispatch(action(getProfileTypeCurrentUserActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getProfileTypeCurrentUserActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateCurrentUserProfileType =
  (isPublic: boolean): ActionResult<IAppState, void, updateProfileTypeCurrentUserActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateProfileTypeCurrentUserActionTypes.REQUEST));
      await UserService.updateUserProfileType(isPublic);
      dispatch(action(updateProfileTypeCurrentUserActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateProfileTypeCurrentUserActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
