import React, {memo} from 'react';
import styled from 'styled-components';

import {AncestryReportSection, Description} from '../common/styled';
import {IAncestryProbabilities} from 'OnlineReport/models/shared/IAncestryProbabilities';
import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import {BreedType} from 'OnlineReport/constants/BreedType';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {IAncesryComponentProps} from 'OnlineReport/models/Ancestry/IAncesryComponentProps';

const HETEROZYGOSITY_DESCRIPTION =
  '"Heterozygosity" is a measure of genetic diversity (the number of variants or unique versions of genes within an individual). It can be correlated with overall health or resilience due to the diverse nature of the genetic "tool kit". An organism that has more flexibility is more likely to survive and prosper.';

const GENOMIC_INBREEDING_VALUE_DESCRIPTION =
  'The "Genomic Inbreeding" value is a measure of inbreeding derived from the actual genetic diversity within an individual. The value seen in this report is based on actual genetic measures as opposed to the more commonly  pedigree derived "inbreeding coefficient".';

const THOROUGHBRED_BLOOD =
  ' The genetic percentage shared with a reference population of known Thoroughbreds (Petersen, et al.) Referred to in Eventing circles as,  "% Blood", this measure may differ from pedigree calculations by as  much as 30%.';

const CHART_DISCLAMER = `In the above graph (called a Principal Component Analysis or "PCA" chart) your horse's genealogy was compared to a known reference population. These horses represent the genomes of varying "breeds" and regions throughout the world. Horses from each region of the world, group or "breed" will have genes in common and overlapping regions that can be identified, compared and graphed as above. Where horses have been closely bred (due to proximity) or purpose- bred by man (to suit a discipline or need) the genetic content of those horses will "cluster" together whereas horses that are less related (either due to breeding or global proximity) will appear in regions farther away from one another. Horses bred through longer periods of history will have more clearly defined and tighter gene clusters (they clump together in the above illustration). Often, these clusters will be entirely separated from other horse groups or samples. Where does your horse fall on the graph?Are the groups or individual horses nearby more or less like your horse? Because horses are continually bred for ever-changing disciplines and desired traits (i.e. speed, height, bone density, color, temperament, etc.) the ancestry graph and PCA will also continue to change and morph over time based on the population tested.`;

export const AncestryReportSectionWrapper = styled(AncestryReportSection)`
  padding: 8px 0;

  @media ${breakpoints.md} {
    ${(props) => (!!props.withoutPaddings ? ' padding: 0;' : 'padding: 32px 0px;')};
  }
`;

export const DescriptionWrapper = styled(Description)`
  margin-bottom: 8px;
  font-size: ${Typography.size.size16};
`;

export const Disclaimer = styled(Description)`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  color: ${ColorPalette.gray58};
`;

const Section = styled.section`
  padding: 16px 0;
`;

const Paragraph = styled.p`
  width: 100%;
  font-size: ${Typography.size.size13};
  color: ${ColorPalette.gray58};
  margin: 0;
`;

const Title = styled(Paragraph)`
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${ColorPalette.red7};
`;

interface IProps {
  data: IAncestryProbabilities;
  population: IAncestryPopulation[];
}

type AllProps = IProps & IAncesryComponentProps;

function PCAPlotProbabilities(props: AllProps) {
  const {data, population, withoutPaddings = false} = props;

  const fValue = data.f ? data.f * 100 : 0;
  const heterozygosity = data.ohom && data.nnm ? (1 - data.ohom / data.nnm) * 100 : 0;
  const thoroughbredBlood = population.filter((x) => x.type === BreedType.Thoroughbred)[0]?.percentage || 0;

  const renderParagraph = (title: string, value: string, description: string) => {
    return (
      <div>
        <Title>
          {title}: {value}%
        </Title>
        <DescriptionWrapper>{description}</DescriptionWrapper>
      </div>
    );
  };

  return (
    <AncestryReportSectionWrapper
      className="d-flex flex-column align-items-center w-100"
      withoutPaddings={withoutPaddings}
    >
      <Section>
        {renderParagraph('Heterozygosity', heterozygosity.toFixed(2), HETEROZYGOSITY_DESCRIPTION)}
        {renderParagraph('Genomic Inbreeding Value', fValue.toFixed(3), GENOMIC_INBREEDING_VALUE_DESCRIPTION)}
        {renderParagraph('Thoroughbred "Blood"', thoroughbredBlood.toFixed(2), THOROUGHBRED_BLOOD)}
      </Section>
      <Disclaimer>{CHART_DISCLAMER}</Disclaimer>
    </AncestryReportSectionWrapper>
  );
}

export default memo(PCAPlotProbabilities);
