import React, {memo} from 'react';

import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {TextAreaField} from 'Common/components/FormFields';
import {Hint} from 'Common/components/StyledComponents/StyledComponents';

const AdditionalInformation: React.FC = () => {
  return (
    <FormControlContainer title="Additional information">
      <Hint>Additional information can appear in public profile if you choose to share</Hint>
      <TextAreaField name="comments" placeholder="Add any additional relevant information" memoized={true} />
    </FormControlContainer>
  );
};

export default memo(AdditionalInformation);
