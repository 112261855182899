import React, {memo} from 'react';
import styled from 'styled-components';

import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {Nullable} from 'Common/types';
import {NoData} from 'BusinessPortal/components/common/NoData/NoData';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {Label, Value, Wrapper} from 'BusinessPortal/components/shared/StyledComponents';
import {IEmployeeProfile} from 'BusinessPortal/models/employee/IEmployeeProfile';

const NoDescription = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${Theme.color.gray};
`;

interface IProps {
  employeeProfile: Nullable<IEmployeeProfile>;
}

const GeneralInformationShow = (props: IEditableSection<IProps>) => {
  const {data} = props;

  if (!data?.employeeProfile) {
    return <NoData />;
  }

  const {employeeProfile} = data;

  return (
    <div>
      <Wrapper>
        <Label>Name:</Label>
        <Value>{employeeProfile?.name}</Value>
      </Wrapper>

      <Wrapper>
        <Label>Phone Number:</Label>
        <Value>{employeeProfile?.phoneNumber}</Value>
      </Wrapper>

      <Wrapper>
        <Label>Address:</Label>
        <Value>{employeeProfile?.address}</Value>
      </Wrapper>

      <Wrapper>
        <Label>Description:</Label>
        <Value>{employeeProfile?.description || <NoDescription>no description</NoDescription>}</Value>
      </Wrapper>
    </div>
  );
};

export default memo(GeneralInformationShow);
