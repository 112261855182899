import React, {memo} from 'react';
import styled from 'styled-components';

import Position, {ElementPosition} from 'FoalProfile/models/IElementPosition';
import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import {IFoalParentOwner} from 'FoalProfile/models/IFoalParent';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {breakpoints} from 'Common/constants/Breakpoints';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {PREFIX_USER_PROFILE_TYPE, PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';
import {UserProfileType} from 'Shared/models/UserProfileType';

const HeaderContact = styled.div<IContactProps>`
  ${(prop) =>
    prop.position === ElementPosition.Left
      ? `left: 10px; border-right: 1px solid ${ColorPalette.black3}; padding-right: 22px; justify-content: flex-end;`
      : `right: 10px; border-left: 1px solid ${ColorPalette.black3}; padding-left: 22px;`};
  padding-bottom: 43px;
  padding-top: 15px;
  grid-area: ${(prop) => `contacts-${prop.position}`};

  @media ${breakpoints.sm} {
    ${(prop) =>
      prop.position === ElementPosition.Right
        ? 'margin-left: -6px; margin-right: 15px;'
        : 'margin-right: -6px; margin-left: 15px;'};
    bottom: 10px;
    position: absolute;
    padding-bottom: 0;
    padding-top: 0;
    border: unset;
  }

  @media ${breakpoints.md} {
    bottom: 18px;
    margin: 0;
  }
`;

const ApplyButton = styled(LinkButton)<Position>`
  height: 32px;
  min-width: unset;
  ${(prop) => (prop.position === ElementPosition.Right ? 'order: 1' : 'order: 2')};
`;

const ApplyTabletButton = styled(LinkButton)<Position>`
  height: 24px;
  font-size: ${Typography.size.size13};
  min-width: unset;
  max-width: 72px;
  ${(prop) => (prop.position === ElementPosition.Left ? 'order: 1' : 'order: 2')};

  @media ${breakpoints.sm} {
    ${(prop) => (prop.position === ElementPosition.Right ? 'order: 1' : 'order: 2')};
  }
`;

const ContactAvatar = styled(Avatar)<IContactProps>`
  border: 1px ${ColorPalette.white0} solid;
  ${(prop) =>
    prop.position === ElementPosition.Right ? 'order: 1; margin-right: -6px;' : 'order: 2; margin-left: -6px;'};

  @media ${breakpoints.sm} {
    ${(prop) =>
      prop.position === ElementPosition.Right ? 'order: 2; margin-left: -6px' : 'order 1; margin-right: -6px;'};
  }

  @media ${breakpoints.md} {
    ${(prop) => (prop.position === ElementPosition.Right ? 'margin: 0 15px 0 17px;' : 'margin: 0 17px 0 15px;')};
  }
`;

interface IContactProps extends Position {
  url?: string;
}

interface IProps {
  position: ElementPosition;
  owner: IFoalParentOwner;
}

const HeaderContactBlock = (props: IProps) => {
  const {position, owner} = props;
  const {isDesktop, isTablet, isMobile} = useMediaQuery();
  const {currentVisitorType} = useVisitorTypeService();

  const userProfileUrl = `${PREFIX_VISITOR_TYPE[currentVisitorType]}/user/${
    PREFIX_USER_PROFILE_TYPE[owner.isGeneralUser ? UserProfileType.User : UserProfileType.AssociationUser]
  }/${owner.id}`;

  return (
    <HeaderContact className="d-flex flex-nowrap align-items-center" position={position}>
      <ContactAvatar
        position={position}
        url={owner.avatar?.url}
        avatarSize={AvatarSize.Custom}
        customSizeAvatar={39}
        customSizeIcon={22}
        defaultAvatarProfile={DefaultAvatarProfile.User}
      />
      {isDesktop ? (
        <ApplyButton size="small" position={props.position} to={userProfileUrl}>
          Contact Owner
        </ApplyButton>
      ) : null}
      {isTablet || isMobile ? (
        <ApplyTabletButton size="small" position={props.position} to={userProfileUrl}>
          Contact
        </ApplyTabletButton>
      ) : null}
    </HeaderContact>
  );
};

export default memo(HeaderContactBlock);
