import * as Yup from 'yup';

import {PaymentMethod, PaymentMethodAdditional} from 'Common/constants/PaymentMethod';
import {IHorseTest} from 'Order/models/IHorseTest';
import {ICreateOrderRequest} from 'Order/models/ICreateOrderRequest';

import {CouponError} from 'Order/services/constants/CouponError';
import {FieldSpecificErrors} from 'Common/models/FieldSpecificErrors';
import {IAddress} from 'Common/models/IAddress';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IResponseError} from 'Common/models/IError';
import {convertTestsToIHorseTestRequest} from 'Order/helpers/convertTestsToIHorseTestRequest';

export interface IFormValues {
  tests: IHorseTest[];
  paymentMethod: PaymentMethod | PaymentMethodAdditional;
  couponCode?: string;
  address: {
    street: string;
    city: string;
    state: string;
    country: string;
    zip: string;
  } | null;
  phoneNumber: string;
}

export const initialValues: IFormValues = {
  tests: [],
  paymentMethod: PaymentMethod.Stripe,
  phoneNumber: '',
  address: null,
};

export const validationSchema = Yup.object().shape<Pick<IFormValues, 'tests' | 'address' | 'phoneNumber'>>({
  tests: Yup.array().of(
    Yup.object().shape({
      packageId: Yup.number().required('Package is required').moreThan(0, 'Package is required'),
      horses: Yup.array().of(Yup.number()).required('Horse is required'),
    })
  ),
  address: Yup.object()
    .shape<IAddress>({
      city: Yup.string().required(REQUIRED_FIELD),
      country: Yup.string().required(REQUIRED_FIELD),
      state: Yup.string(),
      street: Yup.string().required(REQUIRED_FIELD),
      zip: Yup.string().required(REQUIRED_FIELD),
    })
    .nullable(),
  phoneNumber: Yup.string().required(REQUIRED_FIELD),
});

export function convertFormValuesToRequest(values: IFormValues, couponId?: number): ICreateOrderRequest {
  const {tests, paymentMethod} = values;
  return {
    horses: convertTestsToIHorseTestRequest(tests),
    coupon: couponId ? {id: couponId} : undefined,
    paymentMethod: paymentMethod === PaymentMethodAdditional.PayPalCard ? PaymentMethod.PayPal : paymentMethod,
  };
}

const couponErrors: Record<CouponError, string> = {
  [CouponError.EntityNotFound]: `Coupon not found.`,
  [CouponError.CannotBeAppliedCoupon]: `Coupon doesn't contain selected packages and can’t be used. You can use another coupon or change packages.`,
  [CouponError.CouponIsDisabled]: `Coupon is inactive.`,
  [CouponError.ExpiryCoupon]: `Coupon is expired.`,
};

const DEFAULT_COUPON_ERROR = 'Error on loading coupon.';

export function getCouponError(errorResponse: IResponseError): string {
  const error = errorResponse?.errors?.[0];
  if (error?.errorCode) {
    return couponErrors[error.errorCode] || DEFAULT_COUPON_ERROR;
  }
  return error?.description || DEFAULT_COUPON_ERROR;
}

export type ServerErrors = Record<string, string>;
export type FormErrors = IFormValues;

export function convertErrors(
  serverErrors: FieldSpecificErrors<ServerErrors> | undefined
): FieldSpecificErrors<IFormValues> {
  if (!serverErrors) {
    return {paymentMethod: '', couponCode: '', tests: ''};
  }

  const convertedErrors = {};
  Object.keys(serverErrors).forEach((key) => {
    const newKey = key.replace('horses', 'tests');
    convertedErrors[newKey] = serverErrors[key];
  });

  return convertedErrors;
}
