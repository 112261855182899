import {IModule} from 'redux-dynamic-modules';

import {IMainPageHorsesState} from './types';
import {mainPageHorsesReducer} from './reducer';

export interface IMainPageHorsesModuleState {
  mainPageHorses: IMainPageHorsesState;
}

export const MainPageHorsesModule: IModule<IMainPageHorsesModuleState> = {
  id: 'mainPageHorses',
  reducerMap: {
    mainPageHorses: mainPageHorsesReducer
  }
};
