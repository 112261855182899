import {IModule} from 'redux-dynamic-modules';

import {IAdminErrorDescriptionsState} from './types';
import {errorDescriptionsReducer} from './reducer';

export interface IAdminErrorDescriptionsModuleState {
  adminErrorDescriptions: IAdminErrorDescriptionsState;
}

export const AdminErrorDescriptionsModule: IModule<IAdminErrorDescriptionsModuleState> = {
  id: 'adminErrorDescriptions',
  reducerMap: {
    adminErrorDescriptions: errorDescriptionsReducer
  }
};
