import {PaymentMethod} from 'Common/constants/PaymentMethod';

export const paymentOptions = [
  {value: PaymentMethod.Stripe, label: 'Stripe'},
  {value: PaymentMethod.Invoice, label: 'Invoice'},
  {value: PaymentMethod.PreClinical, label: 'Pre-clinical'},
  {value: PaymentMethod.PayPal, label: 'PayPal'},
  {value: PaymentMethod.Square, label: 'Square'},
  {value: PaymentMethod.Check, label: 'Check'},
  {value: PaymentMethod.Cash, label: 'Cash'},
  {value: PaymentMethod.Giftcard, label: 'Gift card'},
  {value: PaymentMethod.Complimentary, label: 'Complimentary'},
  {value: PaymentMethod.Jotform, label: 'Jotform'},
];
