import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';

import {ImageUploaderField, InputField, TextAreaField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {RequiredBy} from 'Common/types';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/ModifierCombinations/ModifierCombinationForm/validation';
import {IModifierCombination} from 'Admin/AdminPhenotypes/models/IModifierCombination';
import {IUpdateModifierCombinationRequest} from 'Admin/AdminPhenotypes/service/models/IModifierCombination';
import {Combination} from 'Common/constants/Combination';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {IconName} from 'Icon/components/Icon';
import * as actions from 'Admin/AdminReference/store/uploadImage/actions';
import {selectors as imageSelectors} from 'Image/store/index';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {ImageModule} from 'Image/store/imageModule';
import {ITraitSequence} from 'Admin/AdminPhenotypes/models/ITraitSequence';
import {useTraitSequenceEditor} from 'SequenceEditor/hooks/useTraitSequenceEditor';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

type IOwnProps = IPhenotypeFormExternalProps<IModifierCombination, IUpdateModifierCombinationRequest>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function ModifierCombinationForm(props: Props) {
  const {itemId, item, dictionary, ...rest} = props;
  const {setFieldValue, values} = props;
  const {getItem} = dictionary.actions;

  useEffect(() => {
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem]);

  const onSuccessSequenceEditor = useCallback(
    (type: Combination, sequences: ITraitSequence[]) => {
      setFieldValue('type', type);
      setFieldValue('sequences', sequences);
    },
    [setFieldValue]
  );

  const {renderRuleField, renderSequenceEditorModal} = useTraitSequenceEditor(
    values.type,
    values?.sequences ?? [],
    onSuccessSequenceEditor
  );

  return (
    <DictionaryForm {...rest}>
      {renderSequenceEditorModal()}

      <InputField name="name" label="Name" placeholder="Name" />
      <InputField
        name="positionIndex"
        label="Order in Report"
        placeholder="Order in Report"
        className="w-50"
        isRequired
      />
      <FieldControlContainer label="Image">
        <ImageUploaderField
          name="image"
          iconProps={{name: IconName.RearedHorse, width: 48, height: 60}}
          title="Add image"
          isShowBlurredBackground={false}
        />
      </FieldControlContainer>
      <TextAreaField name="description.value" label="Description" placeholder="Description" />

      {renderRuleField()}
    </DictionaryForm>
  );
}

const ModifierCombinationFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting ? item : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary, uploadColorImage} = formikBag.props;

    const uploadImage = async (file: File) => {
      const formData = new FormData();
      formData.append('uploadedFile', file);
      return uploadColorImage(formData);
    };

    formikBag.setSubmitting(true);

    if (values.image?.file) {
      values.image = await uploadImage(values.image.file);
    }

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(values);
    }

    if (type === FormType.edit) {
      await updateItem(values as RequiredBy<IFormValues, 'id'>);
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(ModifierCombinationForm);

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  return {
    imageLoading: imageSelectors.selectCommunication(state, 'imageLoading'),
    imageUploading: imageSelectors.selectCommunication(state, 'imageUploading'),
    ...makePhenotypeFormSelectors(state, ownProps.dictionary),
  };
};

const mapDispatchToProps = {
  uploadColorImage: actions.uploadColorImage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(ModifierCombinationFormFormik));
export default withDynamicModules(Connected, [ImageModule]);
