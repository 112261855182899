import {combineReducers} from 'redux';
import {forgotPasswordActions, ForgotPasswordActionTypes, IForgotPasswordState} from 'ForgotPassword/store/types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationForgotPasswordReducer = combineReducers<
  IForgotPasswordState['communications'],
  forgotPasswordActions
>({
  messageLoading: makeCommunicationReducerFromEnum(ForgotPasswordActionTypes)
});
