import {HeightUnits} from 'HorseProfile/constants/HeightUnits';

const heightUnitLabels: Record<HeightUnits, string> = {
  [HeightUnits.Centimeters]: 'cm',
  [HeightUnits.Meters]: 'm',
  [HeightUnits.Hands]: 'hands',
  [HeightUnits.Inch]: 'inch'
};

export function parseHorseHeight(height: number, unit: HeightUnits) {
  return `${height} ${heightUnitLabels[unit]}`;
}
