import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'BusinessPortal/store/horse';
import Loading from 'Loading/components/Loading';
import EditableSection from 'BusinessPortal/components/common/EditableSection/EditableSection';
import HorseMediaSectionEdit from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseMediaSection/HorseMediaSectionEdit';
import HorseMediaSectionShow from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseMediaSection/HorseMediaSectionShow';
import {IHorseSectionBP} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/models/IHorseSectionBP';

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IHorseSectionBP;

const HorseMediaSection = (props: AllProps) => {
  const {horseId, onSuccess} = props;
  const {horseMedia, horseMediaLoading, getHorseMedia} = props;

  const mainRequest = useCallback(() => {
    getHorseMedia(horseId);
  }, [getHorseMedia, horseId]);

  useEffect(() => {
    mainRequest();
  }, [mainRequest]);

  const onSuccessEditHorse = useCallback(() => {
    mainRequest();
    onSuccess();
  }, [onSuccess, mainRequest]);

  const isRequesting = horseMediaLoading.isRequesting;

  return (
    <div className="position-relative">
      {isRequesting && <Loading />}
      <EditableSection
        title="Media"
        data={{horseMedia, horseId}}
        onSuccess={onSuccessEditHorse}
        editComponent={HorseMediaSectionEdit}
        showComponent={HorseMediaSectionShow}
      />
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  horseMedia: selectors.selectHorseMedia(state),
  horseMediaLoading: selectors.selectCommunication(state, 'horseMediaLoading')
});

const mapDispatchToProps = {
  getHorseMedia: actions.getHorseMedia
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(HorseMediaSection));
