import {IModule} from 'redux-dynamic-modules';

import {IAdminAssociationPackagesState} from './types';
import {adminAssociationPackagesReducer} from './reducer';

export interface IAdminAssociationPackagesModuleState {
  adminAssociationPackages: IAdminAssociationPackagesState;
}

export const AdminAssociationPackagesModule: IModule<IAdminAssociationPackagesModuleState> = {
  id: 'adminAssociationPackages',
  reducerMap: {
    adminAssociationPackages: adminAssociationPackagesReducer,
  },
};
