import React, {useCallback, useState} from 'react';
import WarningModalWindow, {ModalTypes} from 'Common/components/Modal/WarningModal';

const CONFIRM_ORDER_PLACED = 'Please confirm you wish to complete this order';

interface IProps {
  creatingOrder?: boolean;
  submitForm(): void;
}

export function usePlacedOrderModal(props: IProps) {
  const {creatingOrder, submitForm} = props;

  const [isConfirmPlacedModalOpen, setIsConfirmPlacedModalOpen] = useState(false);
  const openConfirmPlacedOrderModal = useCallback(() => {
    setIsConfirmPlacedModalOpen(true);
  }, []);

  const closeConfirmPlacedOrderModal = useCallback(() => {
    setIsConfirmPlacedModalOpen(false);
  }, []);

  const onSuccessConfirmPlacedOrderModal = useCallback(() => {
    submitForm();
    setIsConfirmPlacedModalOpen(false);
  }, [submitForm]);

  const placedOrderModal = (
    <WarningModalWindow
      modalType={ModalTypes.Confirm}
      isOpen={isConfirmPlacedModalOpen}
      onClose={closeConfirmPlacedOrderModal}
      onSuccess={onSuccessConfirmPlacedOrderModal}
      successButtonText="Confirm"
      isSuccessLoading={creatingOrder}
    >
      {CONFIRM_ORDER_PLACED}
    </WarningModalWindow>
  );

  return {
    isConfirmPlacedModalOpen,
    openConfirmPlacedOrderModal,
    closeConfirmPlacedOrderModal,
    onSuccessConfirmPlacedOrderModal,
    placedOrderModal,
  };
}
