import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IGenePools, IGenePoolsRequest} from 'Filters/models/GenePools';
import {convertClientGenePoolsRequestToServer} from './converters/GenePools';
import {IServerGenePool} from './serverModel/GenePools';
import {IObjectResponse} from 'Common/models/IResponse';

async function getAllGenePools(request: IGenePoolsRequest): Promise<IGenePools> {
  const response = await axiosWrapper.post<IObjectResponse<IServerGenePool>>(
    `/GenePools/`,
    convertClientGenePoolsRequestToServer(request)
  );
  return response.data.data;
}

export default {
  getAllGenePools
};
