import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication/index';

import {IListResponse, IPagination} from 'Common/models/IResponse';
import {ICoupon} from 'Coupon/models/ICoupon';

const STATE_NAME = 'adminCoupon';

export interface IAdminCouponState {
  data: {
    coupons: ICoupon[];
    couponDetail: ICoupon | null;
  };
  communications: {
    couponsLoading: ICommunication;
    couponCreating: ICommunication;
    couponUpdating: ICommunication;
    couponDeleting: ICommunication;
    couponDetailsLoading: ICommunication;
    changeCouponStatusRequesting: ICommunication;
    generateNewCodeRequesting: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getCouponsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_COUPONS');
export type getCouponsActions = MakeCommunicationActions<
  typeof getCouponsActionTypes,
  {success: IListResponse<ICoupon>}
>;

export const getCouponByIdActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_COUPON_BY_ID');
export type getCouponByIdActions = MakeCommunicationActions<typeof getCouponByIdActionTypes, {success: ICoupon}>;

export const createCouponActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_COUPON');
export type createCouponActions = MakeCommunicationActions<typeof createCouponActionTypes, {}>;

export const updateCouponActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_COUPON');
export type updateCouponActions = MakeCommunicationActions<typeof updateCouponActionTypes, {}>;

export const deleteCouponActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_COUPON');
export type deleteCouponActions = MakeCommunicationActions<typeof deleteCouponActionTypes, {}>;

export const changeCouponStatusActionTypes = makeCommunicationActionType(STATE_NAME, 'CHANGE_COUPON_STATUS');
export type changeCouponStatusActions = MakeCommunicationActions<
  typeof changeCouponStatusActionTypes,
  {success: {couponId: number}}
>;

export const generateNewCodeActionTypes = makeCommunicationActionType(STATE_NAME, 'GENERATE_NEW_CODE');
export type generateNewCodeActions = MakeCommunicationActions<typeof generateNewCodeActionTypes, {success: string}>;

export const resetCouponDetailsActionTypes = makeResetCommunicationActionType(STATE_NAME, 'COUPON_DETAIL');
export type resetCouponDetailsActions = MakeResetCommunicationActions<typeof resetCouponDetailsActionTypes>;

export type CouponsActions =
  | getCouponsActions
  | getCouponByIdActions
  | createCouponActions
  | updateCouponActions
  | deleteCouponActions
  | changeCouponStatusActions
  | generateNewCodeActions
  | resetCouponDetailsActions;
