import {memo, useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {IAppState} from 'Common/store/IAppState';
import Icon, {IconName} from 'Icon/components/Icon';
import {actions, selectors} from 'OnlineReport/store/ancestry';
import ContactRequestForm from '../ContactRequestForm/ContactRequestForm';

const AlreadySentIcon = styled(Icon)`
  opacity: 0.5;
  outline: none;
`;

interface IExternalProps {
  privateId: string;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IExternalProps;

function ContactButton(props: Props) {
  const {privateId, ancestryHorsePrivacy, ancestryHorsePrivacyLoading, getAncestryHorsePrivacy} = props;

  const [isContactRequestModalOpen, setIsContactRequestModalOpen] = useState(false);

  const reloadPrivacyStatus = useCallback(() => {
    getAncestryHorsePrivacy(privateId);
  }, [getAncestryHorsePrivacy, privateId]);

  useEffect(() => {
    reloadPrivacyStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useErrorCommunicationToToast(ancestryHorsePrivacyLoading);

  const horsePrivacyValidation = ancestryHorsePrivacy.find((x) => x.privateId === privateId);
  const isAlreadySent = horsePrivacyValidation?.alreadySent || false;

  const openContactRequestModal = useCallback(() => {
    setIsContactRequestModalOpen(true);
  }, []);
  const closeContactRequestModal = useCallback(() => {
    setIsContactRequestModalOpen(false);
  }, []);

  const onSuccessSave = useCallback(() => {
    closeContactRequestModal();
    reloadPrivacyStatus();
  }, [closeContactRequestModal, reloadPrivacyStatus]);

  return (
    <div>
      <ModalWindow isOpen={isContactRequestModalOpen} onClose={closeContactRequestModal}>
        <ContactRequestForm privateId={privateId} onSuccess={onSuccessSave} />
      </ModalWindow>
      {isAlreadySent ? (
        <Tooltip content="Contact request already sent. Please wait replying on your invitation.">
          <AlreadySentIcon name={IconName.Email} size={24} />
        </Tooltip>
      ) : (
        <IconButton name={IconName.Email} onClick={openContactRequestModal} size={24} fill={false} stroke={false} />
      )}
    </div>
  );
}

const mapStateToProps = (state: IAppState) => ({
  ancestryHorsePrivacy: selectors.selectAncestryHorsePrivacy(state),
  ancestryHorsePrivacyLoading: selectors.selectCommunication(state, 'ancestryHorsePrivacyLoading'),
});

const mapDispatchToProps = {
  getAncestryHorsePrivacy: actions.getAncestryHorsePrivacy,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(ContactButton));

export default memo(Connected);
