import React, {useState, useCallback} from 'react';
import {StripeProvider} from 'react-stripe-elements';
import {useScript} from 'Common/helpers/hooks/useScript';
import {Nullable} from 'Common/types';
import {useLogger} from 'Common/helpers/hooks/useLogger';
import {convertToLoggerContext} from 'Common/helpers/convertToLoggerContext';

const STRIPE_API_URL = 'https://js.stripe.com/v3/';

interface IProps {
  stripeApiKey: string;
}

function StripeProviderLoader(props: React.PropsWithChildren<IProps>) {
  const {stripeApiKey, children} = props;
  const [stripe, setStripe] = useState<Nullable<stripe.Stripe>>(null);
  const {logger} = useLogger();

  const onScriptLoadError = useCallback(
    e => {
      logger.error('Stripe script loading error', convertToLoggerContext(e));
    },
    [logger]
  );

  const onScriptLoadSuccess = useCallback(() => {
    setStripe(window.Stripe(stripeApiKey));
  }, [stripeApiKey]);

  useScript(STRIPE_API_URL, 'stripe-js', onScriptLoadSuccess, onScriptLoadError);

  return <StripeProvider stripe={stripe}>{children}</StripeProvider>;
}

export default StripeProviderLoader;
