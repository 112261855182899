import {action} from 'typesafe-actions';

import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {
  downloadDiagnosticReportActions,
  downloadDiagnosticReportActionTypes,
  getAbilitiesActions,
  getAbilitiesActionTypes,
  getAbilityDetailedActions,
  getAbilityDetailedActionTypes,
  getCoatColorActions,
  getCoatColorActionTypes,
  getCoatColorDetailedActions,
  getCoatColorDetailedActionTypes,
  getGeneticVariantsActions,
  getGeneticVariantsActionTypes,
  getHealthVariantDetailedActions,
  getHealthVariantDetailedActionTypes,
  getHealthVariantsActions,
  getHealthVariantsActionTypes,
  getHorseBreedPanelsActions,
  getHorseBreedPanelsActionTypes,
  getHorseDetailsActions,
  getHorseDetailsActionTypes,
  getHorseInfoActions,
  getHorseInfoActionTypes,
  getHorseOwnerActions,
  getHorseOwnerActionTypes,
  getSummaryAbilitiesActions,
  getSummaryAbilitiesActionTypes,
  getSummaryColorsActions,
  getSummaryColorsActionTypes,
  getSummaryHealthIssuesActions,
  getSummaryHealthIssuesActionTypes,
} from './types';
import OnlineReportsService from 'OnlineReport/services/OnlineReportsService';

export const getHorseInfo =
  (horseId: number): ActionResult<IAppState, void, getHorseInfoActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseInfoActionTypes.REQUEST));
      const res = await OnlineReportsService.getHorseInfo(horseId);
      dispatch(action(getHorseInfoActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHorseInfoActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseOwner =
  (horseId: number): ActionResult<IAppState, void, getHorseOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseOwnerActionTypes.REQUEST));
      const res = await OnlineReportsService.getHorseOwner(horseId);
      dispatch(action(getHorseOwnerActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHorseOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseDetails =
  (horseId: number): ActionResult<IAppState, void, getHorseDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseDetailsActionTypes.REQUEST));
      const res = await OnlineReportsService.getHorseDetails(horseId);
      dispatch(action(getHorseDetailsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHorseDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getSummaryAbilities =
  (horseId: number): ActionResult<IAppState, void, getSummaryAbilitiesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getSummaryAbilitiesActionTypes.REQUEST));
      const res = await OnlineReportsService.getSummaryAbilities(horseId);
      dispatch(action(getSummaryAbilitiesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getSummaryAbilitiesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getSummaryColors =
  (horseId: number): ActionResult<IAppState, void, getSummaryColorsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getSummaryColorsActionTypes.REQUEST));
      const res = await OnlineReportsService.getSummaryColors(horseId);
      dispatch(action(getSummaryColorsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getSummaryColorsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getSummaryHealthIssues =
  (horseId: number): ActionResult<IAppState, void, getSummaryHealthIssuesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getSummaryHealthIssuesActionTypes.REQUEST));
      const res = await OnlineReportsService.getSummaryHealthIssues(horseId);
      dispatch(action(getSummaryHealthIssuesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getSummaryHealthIssuesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getGeneticVariants =
  (horseId: number): ActionResult<IAppState, void, getGeneticVariantsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getGeneticVariantsActionTypes.REQUEST));
      const res = await OnlineReportsService.getGeneticVariants(horseId);
      dispatch(action(getGeneticVariantsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getGeneticVariantsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getCoatColor =
  (horseId: number): ActionResult<IAppState, void, getCoatColorActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getCoatColorActionTypes.REQUEST));
      const res = await OnlineReportsService.getCoatColor(horseId);
      dispatch(action(getCoatColorActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getCoatColorActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getCoatColorDetailed =
  (colorId: number): ActionResult<IAppState, void, getCoatColorDetailedActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getCoatColorDetailedActionTypes.REQUEST));
      const res = await OnlineReportsService.getCoatColorDetailed(colorId);
      dispatch(action(getCoatColorDetailedActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getCoatColorDetailedActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHealthVariants =
  (horseId: number): ActionResult<IAppState, void, getHealthVariantsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHealthVariantsActionTypes.REQUEST));
      const res = await OnlineReportsService.getHealthVariants(horseId);
      dispatch(action(getHealthVariantsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHealthVariantsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHealthVariantDetailed =
  (
    healthIssueId: number,
    isAggregated: boolean = false
  ): ActionResult<IAppState, void, getHealthVariantDetailedActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHealthVariantDetailedActionTypes.REQUEST));
      const res = await OnlineReportsService.getHealthVariantDetailed(healthIssueId, isAggregated);
      dispatch(action(getHealthVariantDetailedActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHealthVariantDetailedActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAbilities =
  (horseId: number): ActionResult<IAppState, void, getAbilitiesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAbilitiesActionTypes.REQUEST));
      const res = await OnlineReportsService.getAbilities(horseId);
      dispatch(action(getAbilitiesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAbilitiesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAbilityDetailed =
  (dangerId: number, isAggregated: boolean = false): ActionResult<IAppState, void, getAbilityDetailedActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAbilityDetailedActionTypes.REQUEST));
      const res = await OnlineReportsService.getAbilityDetailed(dangerId, isAggregated);
      dispatch(action(getAbilityDetailedActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAbilityDetailedActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const downloadDiagnosticReport =
  (horseId: number): ActionResult<IAppState, Promise<void>, downloadDiagnosticReportActions> =>
  async (dispatch) => {
    dispatch(action(downloadDiagnosticReportActionTypes.REQUEST));
    try {
      await OnlineReportsService.downloadDiagnosticReport(horseId);
      dispatch(action(downloadDiagnosticReportActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(downloadDiagnosticReportActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseBreedPanels =
  (horseId: number, orderId?: number): ActionResult<IAppState, void, getHorseBreedPanelsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseBreedPanelsActionTypes.REQUEST));
      const result = await OnlineReportsService.getHorseBreedPanel(horseId, orderId);
      dispatch(action(getHorseBreedPanelsActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getHorseBreedPanelsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
