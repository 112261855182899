import React, {useEffect} from 'react';
import {memo, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';

import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {IconName} from 'Icon/components/Icon';
import {IOrderPackage} from 'Order/models/IOrderPackage';
import OrderPackageCardDetails from './OrderPackageCardDetails';
import HorsesCard from './HorsesCard';
import {IOrderHorse} from 'Order/models/IOrderHorse';
import {IHorseTest} from 'Order/models/IHorseTest';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {FieldArray, FieldArrayRenderProps} from 'formik';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {TestSelectionActionProps} from '../../TestsSelection';
import LocalStorageService from 'Common/services/LocalStorageService';
import {COMPARE_PACKAGES_KEY} from '../CompareForm/CompareForm';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';
import ExpandableContainer from 'Common/components/ExpandableContainer/ExpandableContainer';

const expandableContainerStyle: React.CSSProperties = {padding: 0};
const MIN_HIDING_HORSES_COUNT = 20;

const Root = styled.div`
  height: fit-content;
  min-height: 240px;
  border-radius: 16px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.02), 0 12px 28px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${ColorPalette.gray2};
  background-color: ${ColorPalette.white0};
`;

const Container = styled.div`
  padding: 24px;
  height: 100%;
  min-height: 200px;
`;

const Title = styled.div`
  font-family: ${Theme.font.secondary};
  font-size: ${Typography.size.size20};
  font-weight: ${Typography.weight.semiBold600};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${ColorPalette.black1};
`;

const Description = styled(Title)`
  font-size: ${Typography.size.size16};
  font-weight: ${Typography.weight.normal400};
  line-height: 1.41;
  color: ${ColorPalette.gray3};
  margin: 8px 0;
`;

const Price = styled(Title)`
  color: ${ColorPalette.gray3};
`;

const AddToCartButtonWrapper = styled.div`
  width: fit-content;
`;

const AddToCartButton = styled(PrimaryButton)`
  width: 176px;
  margin-top: 32px;
`;

const HorsesWrapper = styled.div`
  margin-top: 16px;
  font-size: ${Typography.size.size16};
`;

const HorseWrapper = styled(Title)`
  font-size: ${Typography.size.size16};
  word-break: break-word;
  margin-bottom: 8px;
`;

const RemoveButtonWrapper = styled.div`
  width: 24px;
`;

const CompareButton = styled.div`
  width: 24px;
`;

interface IProps {
  orderPackage: IOrderPackage;
  isDetailShow: boolean;
  horses: IOrderHorse[];
  tests: IHorseTest[];
}

type AllProps = IProps & TestSelectionActionProps;

function OrderPackageCard(props: AllProps) {
  const {
    orderPackage: {description, name, price, tests: packageTests, reportTypes, id: packageId},
    isDetailShow,
    horses,
    tests,
  } = props;

  const {onSuccess, onOpenCard} = props;

  const [isHorsesCardOpen, setIsHorsesCardOpen] = useState(false);
  const [isDeleteHorse, setIsDeleteHorse] = useState(false);
  const [isSetForCompare, setForCompare] = useState(false);

  const {emit, listen} = useEventBus();

  const recordIndex = tests.findIndex((x) => x.packageId === packageId);

  const onChangeComparePackages = useCallback(() => {
    const packagesForCompare: number[] = LocalStorageService.getItem(COMPARE_PACKAGES_KEY) || [];
    const findId = packagesForCompare.findIndex((x) => x === packageId);
    setForCompare(findId !== -1);
  }, [packageId]);

  useEffect(() => {
    onChangeComparePackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageId]);

  useEffect(() => {
    if (isDeleteHorse) {
      onSuccess(packageId);
      setIsDeleteHorse(false);
    }
  }, [isDeleteHorse, onSuccess, packageId]);

  useEffect(() => {
    const eventComparePackagesChanged = listen(EventName.AddPackageToCompare, onChangeComparePackages);

    return () => {
      eventComparePackagesChanged.stopListening();
    };
  }, [listen, onChangeComparePackages]);

  const openHorsesCard = useCallback(() => {
    setIsHorsesCardOpen(!isHorsesCardOpen);
    onOpenCard(packageId);
  }, [isHorsesCardOpen, onOpenCard, packageId]);

  const handleOnSuccess = useCallback(() => {
    onSuccess(packageId);
    setIsHorsesCardOpen(!isHorsesCardOpen);
  }, [isHorsesCardOpen, onSuccess, packageId]);

  const horsesInPackage = useMemo(() => {
    const filteredTests = tests.find((x) => x.packageId === packageId);
    return filteredTests ? horses.filter((x) => filteredTests.horses.some((h) => h === x.id)) : null;
  }, [horses, packageId, tests]);

  const isHorseInPackage = !!horsesInPackage && horsesInPackage?.length > 0;

  const onCompareAddClick = useCallback(() => {
    const packagesForCompare: number[] = LocalStorageService.getItem(COMPARE_PACKAGES_KEY) || [];
    const findId = packagesForCompare.findIndex((x) => x === packageId);
    if (findId === -1) {
      packagesForCompare.push(packageId);
    } else {
      packagesForCompare.splice(findId, 1);
    }
    LocalStorageService.setItem(COMPARE_PACKAGES_KEY, packagesForCompare);
    setForCompare(!isSetForCompare);
    emit(EventName.AddPackageToCompare);
  }, [emit, isSetForCompare, packageId]);

  const renderRemoveButton = useCallback(
    (arrayHelper: FieldArrayRenderProps, horseId: number) => {
      const onRemove = async () => {
        setIsDeleteHorse(false);
        const index = tests?.find((x) => x.packageId === packageId)?.horses.findIndex((x) => x === horseId);

        if (index !== undefined && index !== -1) {
          arrayHelper.remove(index);
        }
        setIsDeleteHorse(true);
      };

      return (
        <IconButton
          onClick={() => onRemove()}
          name={IconName.Clear}
          color={Theme.color.primary}
          size={14}
          fill={true}
          stroke={false}
          hoverColor={Theme.color.control.active}
        />
      );
    },
    [packageId, tests]
  );

  const renderHorses = useCallback(
    (minIndex: number, maxIndex: number) => {
      return (
        <div className="flex-column">
          <FieldArray
            name={`tests[${recordIndex}].horses`}
            render={(arrayHelper) => (
              <>
                {horsesInPackage &&
                  horsesInPackage.map((horse, ind) => {
                    if (minIndex <= ind && ((maxIndex > 0 && ind < maxIndex) || maxIndex === 0)) {
                      return (
                        <div key={ind} className="d-flex justify-content-between">
                          <HorseWrapper>{horse.name}</HorseWrapper>
                          <RemoveButtonWrapper className="d-flex align-items-center justify-content-center">
                            {renderRemoveButton(arrayHelper, horse.id)}
                          </RemoveButtonWrapper>
                        </div>
                      );
                    } else {
                      return '';
                    }
                  })}
              </>
            )}
          />
        </div>
      );
    },
    [horsesInPackage, recordIndex, renderRemoveButton]
  );

  return (
    <Root className="d-flex flex-column position-relative">
      <Container className="flex-grow-1 d-flex flex-column justify-content-between">
        {isHorsesCardOpen && (
          <HorsesCard onSuccess={handleOnSuccess} horses={horses} packageId={packageId} tests={tests} />
        )}

        <div>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <Title>{name}</Title>
              <Tooltip content={isSetForCompare ? 'Remove from comparing list' : 'Add package to compare'}>
                <CompareButton>
                  <IconButton
                    name={IconName.CompareBarsAdd}
                    fill={true}
                    stroke={false}
                    size={24}
                    color={isSetForCompare ? ColorPalette.green2 : ColorPalette.gray48}
                    hoverColor={ColorPalette.green2}
                    onClick={onCompareAddClick}
                  />
                </CompareButton>
              </Tooltip>
            </div>
            {description && <Description>{description}</Description>}
            <Price>${price}</Price>
          </div>

          {isHorseInPackage && (
            <HorsesWrapper>
              {renderHorses(0, MIN_HIDING_HORSES_COUNT)}

              {horsesInPackage && horsesInPackage.length > MIN_HIDING_HORSES_COUNT && (
                <ExpandableContainer
                  containerStyle={expandableContainerStyle}
                  isClickableContainer={false}
                  buttonTitle="Show more"
                >
                  {renderHorses(MIN_HIDING_HORSES_COUNT, 0)}
                </ExpandableContainer>
              )}
            </HorsesWrapper>
          )}
        </div>

        <Tooltip content="Once you add this test to your cart, you will be able to select the horses you would like to test">
          <AddToCartButtonWrapper>
            <AddToCartButton icon={{name: IconName.ShoppingCartAdd}} onClick={openHorsesCard}>
              Choose Horse
            </AddToCartButton>
          </AddToCartButtonWrapper>
        </Tooltip>
      </Container>

      {isDetailShow && <OrderPackageCardDetails reportTypes={reportTypes} tests={packageTests} />}
    </Root>
  );
}

export default memo(OrderPackageCard);
