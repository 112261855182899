import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse} from 'Common/models/IResponse';
import {IFilterPropertyOperator} from 'FacetFilter/models/IFilterPropertyOperator';
import {IFilterProperty} from 'FacetFilter/models/IFilterProperty';

const getFilterPropertyOperations = async (request: string): Promise<IFilterPropertyOperator[]> => {
  const response = await axiosWrapper.get<IListResponse<IFilterPropertyOperator>>(`/FilterPropertyOperationsAdmin`, {
    params: {request},
  });
  return response.data.data;
};

const getPackagesFilters = async (): Promise<IFilterProperty[]> => {
  const response = await axiosWrapper.get<IListResponse<IFilterProperty>>(`/PackagesTableFiltersAdmin`);
  return response.data.data;
};

export default {
  getFilterPropertyOperations,
  getPackagesFilters,
};
