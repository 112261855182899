import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

import {IMailConfirmation} from 'MailConfirmation/models/IMailConfirmation';

const STATE_NAME = 'mailConfirmation';

export interface IMailConfirmationState {
  data: {
    message: string | null;
  };
  communications: {
    messageLoading: ICommunication<IMailConfirmation>;
  };
}

export const MailConfirmationActionTypes = makeCommunicationActionType(STATE_NAME, 'MAIL_CONFIRMATION');
export type mailConfirmationActions = MakeCommunicationActions<typeof MailConfirmationActionTypes, {success: string}>;
