import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';

import {Nullable} from 'Common/types';
import {IOrderSummary} from 'Payment/models/IOrderSummary';
import {IPayPalOrder} from 'Payment/services/models/payment';

const STATE_NAME = 'orderPayment';

export interface IOrderPaymentState {
  data: {
    paymentIntentId: Nullable<string>;
    summary: Nullable<IOrderSummary>;
    payPalOrder: Nullable<IPayPalOrder>;
  };
  communications: {
    paymentIntentIdLoading: ICommunication;
    summaryLoading: ICommunication;
    payPalOrderLoading: ICommunication;
  };
}

export const getPaymentIntentIdActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_PAYMENT_INTENT_ID');
export type getPaymentIntentIdActions = MakeCommunicationActions<
  typeof getPaymentIntentIdActionTypes,
  {success: string}
>;

export const getOrderSummaryActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_SUMMARY');
export type getOrderSummaryActions = MakeCommunicationActions<
  typeof getOrderSummaryActionTypes,
  {success: IOrderSummary}
>;

export const getPayPalOrderActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_PAYPAL_ORDER');
export type getPayPalOrderActions = MakeCommunicationActions<typeof getPayPalOrderActionTypes, {success: IPayPalOrder}>;

export const getTokenByOrderIdActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_TOKEN');
export type getTokenByOrderIdActions = MakeCommunicationActions<typeof getTokenByOrderIdActionTypes, {success: string}>;

export type Actions =
  | getPaymentIntentIdActions
  | getOrderSummaryActions
  | getPayPalOrderActions
  | getTokenByOrderIdActions;
