import React, {memo} from 'react';

import DictionariesLayout from 'Admin/AdminReference/components/NamedEntityDictionaryLayout/NamedEntityDictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';

const Breeds = () => {
  const {adminBreed} = useDictionaries();

  return <DictionariesLayout dictionary={adminBreed} />;
};

export default memo(Breeds);
