import React, {memo} from 'react';
import styled from 'styled-components';

import {Value} from 'Admin/common/styled/StyledComponents';
import SampleId from 'Admin/AdminDashboard/components/shared/SampleId/SampleId';

const Text = styled(Value)`
  margin-right: 12px;
`;

interface IProps {
  id?: number;
  sampleId: string;
  onEditClick(): void;
}

function HorseSampleId(props: IProps) {
  return (
    <Text>
      <SampleId {...props} />
    </Text>
  );
}

export default memo(HorseSampleId);
