import {combineReducers, Reducer} from 'redux';

import {IPermissionsState} from '../types';
import {communicationLoginReducer} from './communications';
import {dataAuthReducer} from './data';

export const authReducer: Reducer<IPermissionsState> = combineReducers<IPermissionsState>({
  communications: communicationLoginReducer,
  data: dataAuthReducer
});
