import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

import {
  Actions,
  IShoppingCartState,
  getShoppingCartActionTypes,
  clearShoppingCartActionTypes,
  createShoppingCartActionTypes,
  deleteShoppingCartActionTypes,
} from '../types';

export const communicationReducer = combineReducers<IShoppingCartState['communications'], Actions>({
  shoppingCartLoading: makeCommunicationReducerFromEnum(getShoppingCartActionTypes),
  shoppingCartClearing: makeCommunicationReducerFromEnum(clearShoppingCartActionTypes),
  shoppingCartCreating: makeCommunicationReducerFromEnum(createShoppingCartActionTypes),
  shoppingCartDeleting: makeCommunicationReducerFromEnum(deleteShoppingCartActionTypes),
});
