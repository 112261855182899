export const isImageExists = (url: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = reject;
    img.onload = resolve;
    img.src = url;
  });
};

export const FixImageName = (url: string) => {
  const fixedUrl = encodeURI(url)
    .split('(')
    .join('%28')
    .split(')')
    .join('%29');

  return fixedUrl;
};

export async function getIsImageExists(url: string) {
  let isExist = false;

  if (url) {
    try {
      await isImageExists(url);
      isExist = true;
    } catch {
      isExist = false;
    }
  }
  return isExist;
}
