import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  getAncestryUploadDetailsActions,
  getAncestryUploadDetailsActionTypes,
  uploadAncestryActions,
  uploadAncestryActionTypes,
  uploadAncestryHorsesLikeMeActions,
  uploadAncestryHorsesLikeMeActionTypes,
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import AdminAncestryReportService from 'Admin/AdminDashboard/services/AdminAncestryReportService';
import {IAppState} from 'Common/store/IAppState';

export const uploadAncestryFiles =
  (files: File[]): ActionResult<IAppState, void, uploadAncestryActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadAncestryActionTypes.REQUEST));
      await AdminAncestryReportService.uploadAncestryFiles(files);
      dispatch(action(uploadAncestryActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(uploadAncestryActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };

export const getAncestryUploadDetails =
  (): ActionResult<IAppState, void, getAncestryUploadDetailsActions> => async (dispatch) => {
    try {
      dispatch(action(getAncestryUploadDetailsActionTypes.REQUEST));
      const res = await AdminAncestryReportService.getAncestryUploadDetails();
      dispatch(action(getAncestryUploadDetailsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAncestryUploadDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const uploadAncestryHorseLikeMeFiles =
  (file: File): ActionResult<IAppState, void, uploadAncestryHorsesLikeMeActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadAncestryHorsesLikeMeActionTypes.REQUEST));
      await AdminAncestryReportService.uploadAncestryHorseLikeMeFiles(file);
      dispatch(action(uploadAncestryHorsesLikeMeActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(uploadAncestryHorsesLikeMeActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };
