import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import {controlLayout, inputLabelCss} from 'Common/components/Controls/styles';
import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import RequiredMark from 'Common/helpers/RequiredMark';
import ColorPalette from 'Common/constants/ColorPalette';
import HintIcon from '../HintIcont/HintIcon';

const ControlLayout = styled.div`
  padding-left: 1px;
  ${controlLayout}

  .input-has-error {
    border: 1px solid ${ColorPalette.red4} !important;
  }
  .input-has-error:focus {
    box-shadow: 0 0 0 2px ${ColorPalette.red5} !important;
  }
`;

const StyledLabel = styled.label`
  padding-left: 0;
  ${inputLabelCss}
`;

type AllProps = IBaseControlProps & Pick<React.HTMLAttributes<HTMLElement>, 'className' | 'style' | 'id' | 'children'>;

const FieldControlContainer = (props: AllProps) => {
  const {id, className, style, label, labelStyle, labelClassName, isRequired, descriptionTooltip, children} = props;

  return (
    <ControlLayout className={classnames('custom-control', className)} style={style} role="group">
      {label && (
        <StyledLabel className={`d-flex ${classnames(labelClassName)}`} htmlFor={id} style={labelStyle}>
          {label}
          <RequiredMark isRequired={isRequired} />
          {descriptionTooltip && <HintIcon tooltip={descriptionTooltip} style={{marginLeft: 5}} />}
        </StyledLabel>
      )}
      {children}
    </ControlLayout>
  );
};

export default FieldControlContainer;
