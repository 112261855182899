import React, {memo} from 'react';

import Icon, {IconName} from 'Icon/components/Icon';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import CoatColor from 'OnlineReport/components/CoatColor/CoatColor';
import PerformanceAbilities from 'OnlineReport/components/PerformanceAbilities/PerformanceAbilities';
import {
  SectionContent,
  SectionHeader,
  Header as HeaderStyle,
  Section,
  PageBreak,
} from 'OnlineReportPrintable/common/styled';
import HealthVariants from 'OnlineReport/components/HealthVariants/HealthVariants';
import {IOnlineReportHorseDetails} from 'OnlineReport/models/shared/IOnlineReportHorseDetails';
import {Nullable} from 'Common/types';
import Header from 'OnlineReportPrintable/parts/Header';

const content = [
  {title: 'Coat color', icon: IconName.DropSimple, Component: CoatColor, filterSelector: ['partialColors', null]},
  {
    title: 'Health Variants',
    icon: IconName.CrossSimple,
    Component: HealthVariants,
    filterSelector: ['advancedHealthIssues', 'aggregatedHealthIssues'],
  },
  {
    title: 'Performance and Abilities',
    icon: IconName.Thunderbolt,
    Component: PerformanceAbilities,
    filterSelector: ['advancedAbilities', 'aggregatedAbilities'],
  },
];

interface IExternalProps {
  filter?: {
    partialColors?: number[];
    aggregatedHealthIssues?: number[];
    advancedHealthIssues?: number[];
    aggregatedAbilities?: number[];
    advancedAbilities?: number[];
  };
  horseId: string;
  orderId: string;
  reportType: OnlineReportType;
  horseDetails: Nullable<IOnlineReportHorseDetails>;
}

const GenotypeGroups = (props: IExternalProps) => {
  const {filter, horseId, orderId, reportType, horseDetails} = props;

  /**
   * Returns `false` if the provided group has no visible elements.
   */
  const isSectionVisible = ([selector, aggregatedSelector]: [number | null, number | null]) => {
    if (!filter) return true;

    if (
      (!selector || filter[selector] === undefined) &&
      (!aggregatedSelector || filter[aggregatedSelector] === undefined)
    )
      return true;

    return (
      (selector && filter[selector] && filter[selector].length > 0) ||
      (aggregatedSelector && filter[aggregatedSelector] && filter[aggregatedSelector].length > 0)
    );
  };

  return (
    <>
      {content.map(({icon, title, Component, filterSelector}, i) => (
        <React.Fragment key={i}>
          {filter === undefined || isSectionVisible(filterSelector as [number | null, number | null]) ? (
            <React.Fragment key={i}>
              <table className="w-100">
                <thead>
                  <tr>
                    <th>
                      <Header horseDetails={horseDetails} isShort={true} />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <Section>
                        <SectionHeader className="d-flex w-100 align-items-center">
                          <Icon name={icon} />
                          <HeaderStyle>{title}</HeaderStyle>
                        </SectionHeader>
                        <SectionContent>
                          <Component
                            phenotypeFilter={
                              filter === undefined
                                ? undefined
                                : {
                                    advancedIds: filterSelector[0] ? filter[filterSelector[0]] : undefined,
                                    aggregatedIds: filterSelector[1] ? filter[filterSelector[1]] : undefined,
                                  }
                            }
                            isPrintable={true}
                            horseId={horseId}
                            orderId={orderId}
                            reportType={reportType}
                            onHasError={console.log}
                          />
                        </SectionContent>
                      </Section>
                    </td>
                  </tr>
                </tbody>
              </table>
              {i !== content.length - 1 && <PageBreak />}
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ))}
    </>
  );
};

export default memo(GenotypeGroups);
