import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import AcceptTransferForm from 'Admin/AdminDashboard/components/HorseTransfer/AcceptTransferForm/AcceptTransferForm';

interface IAcceprTransferModalParams {
  onSuccess?(): void;
}

export function useAceptTransferModal(params: IAcceprTransferModalParams) {
  const {onSuccess} = params;

  const [transferId, setTransferId] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const openAcceptTransferModal = useCallback((transferId: number) => {
    setTransferId(transferId);
    setIsOpen(true);
  }, []);
  const closeAcceptTransferModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeAcceptTransferModal();
    onSuccess && onSuccess();
  }, [closeAcceptTransferModal, onSuccess]);

  const acceptTransferModal = (
    <ModalWindow isOpen={isOpen} onClose={closeAcceptTransferModal}>
      <AcceptTransferForm transferId={transferId} onSuccess={handleSuccess} />
    </ModalWindow>
  );

  return {
    acceptTransferModal,
    openAcceptTransferModal,
  };
}
