import {Reducer} from 'redux';

import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';
import {BusinessPortalHorseActions, getHorsesActionTypes, IBusinessPortalHorseState} from '../types';

const initialState: IBusinessPortalHorseState['ui'] = {
  pagination: {countPerPage: MIN_COUNT_PER_PAGE, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiBusinessPortalHorseReducer: Reducer<IBusinessPortalHorseState['ui'], BusinessPortalHorseActions> = (
  state = initialState,
  action: BusinessPortalHorseActions
) => {
  switch (action.type) {
    case getHorsesActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
