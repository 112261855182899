import React, {memo, useCallback} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {
  ModalWindowHeader,
  ModalWindowFormContent,
  ModalWindowFooter,
  ModalWindowButton,
} from 'Common/components/Modal/shared';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminEmployees';
import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import Loading from 'Loading/components/Loading';
import {InputField} from 'Common/components/FormFields';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {Field, FieldLabel, FieldValue} from 'Admin/common/styled/StyledComponents';
import {IUpdateAdminEmployeeEmail} from 'Admin/AdminAssociations/services/types/employee';
import {initialValue, validationSchema} from './validation';
import {IAdminEmployee} from 'Admin/AdminAssociations/models/IAdminEmployee';

const Root = styled.div`
  margin: 0 24px 40px 0;
`;

interface IExternalProps {
  employee: IAdminEmployee;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;
type OuterProps = IExternalProps & IConnected;
type AllProps = FormikProps<IUpdateAdminEmployeeEmail> & OuterProps;

function ChangeEmail(props: AllProps) {
  const {isSubmitting, setErrors} = props;
  const {adminEmployeeEmailUpdating, onSuccess, employee} = props;

  const errorInfo = adminEmployeeEmailUpdating.error;
  const isRequesting = adminEmployeeEmailUpdating.isRequesting;

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(adminEmployeeEmailUpdating, onSuccess);
  useOnErrorCommunication(adminEmployeeEmailUpdating, onError);
  useCommunicationToToast(adminEmployeeEmailUpdating, 'Employee email has been changed');

  return (
    <>
      <ModalWindowHeader>Change employee email</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <Root>
          <ModalWindowFormContent>
            {isRequesting && <Loading />}
            <div className="row">
              <Field className="col-4">
                <FieldLabel>Employee</FieldLabel>
                <FieldValue>{employee.name}</FieldValue>
              </Field>
              <Field className="col-4">
                <FieldLabel>Current email</FieldLabel>
                <FieldValue>{employee.email}</FieldValue>
              </Field>
            </div>

            <InputField
              type="email"
              name="newEmail"
              label="New email"
              placeholder="Employee's new email"
              isRequired={true}
            />
          </ModalWindowFormContent>
        </Root>
        <ModalWindowFooter>
          <ModalWindowButton type="submit" isLoading={isSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const ChangeEmailFormik = withFormik<OuterProps, IUpdateAdminEmployeeEmail>({
  mapPropsToValues: ({employee}) => (employee.id ? {id: employee.id, newEmail: ''} : initialValue),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    await formikBag.props.updateAdminEmployeeEmail(values);
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(ChangeEmail);

const mapStateToProps = (state: IAppState) => ({
  adminEmployeeEmailUpdating: selectors.selectCommunication(state, 'adminEmployeeEmailUpdating'),
});

const mapDispatchToProps = {
  updateAdminEmployeeEmail: actions.updateAdminEmployeeEmail,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(memo(ChangeEmailFormik));
