import {Reducer} from 'redux';

import {
  IBuildFoalState,
  BuildFoalActions,
  buildFoalRequestActionTypes,
  firstParentActionTypes,
  secondParentActionTypes,
} from '../types';
import {
  addFavoriteHorseActionTypes,
  deleteFavoriteHorseActionTypes,
  FavoriteHorsesActions,
} from 'FavoriteHorse/store/types';

const initialState: IBuildFoalState['data'] = {
  foal: null,
  firstParent: null,
  secondParent: null,
};

export const dataBuildFoalReducer: Reducer<IBuildFoalState['data'], BuildFoalActions> = (
  state = initialState,
  action: BuildFoalActions | FavoriteHorsesActions
) => {
  switch (action.type) {
    case buildFoalRequestActionTypes.SUCCESS: {
      return {...state, foal: action.payload};
    }
    case firstParentActionTypes.SUCCESS: {
      return {...state, firstParent: action.payload};
    }
    case secondParentActionTypes.SUCCESS: {
      return {...state, secondParent: action.payload};
    }
    case addFavoriteHorseActionTypes.SUCCESS: {
      return {
        ...state,
        firstParent: state.firstParent && {
          ...state.firstParent,
          isFavorite: action.payload === state.firstParent?.id ? true : state.firstParent?.isFavorite,
        },
        secondParent: state.secondParent && {
          ...state.secondParent,
          isFavorite: action.payload === state.secondParent?.id ? true : state.secondParent?.isFavorite,
        },
      };
    }

    case deleteFavoriteHorseActionTypes.SUCCESS: {
      return {
        ...state,
        firstParent: state.firstParent && {
          ...state.firstParent,
          isFavorite: action.payload === state.firstParent?.id ? false : state.firstParent?.isFavorite,
        },
        secondParent: state.secondParent && {
          ...state.secondParent,
          isFavorite: action.payload === state.secondParent?.id ? false : state.secondParent?.isFavorite,
        },
      };
    }
    default: {
      return state;
    }
  }
};
