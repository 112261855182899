import ColorPalette from 'Common/constants/ColorPalette';
import {css} from 'styled-components';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';

export const inputCss = css`
  position: relative;
  border-radius: 4px;
  font-style: normal;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: ${Typography.size.size16};
  letter-spacing: 0.2px;
  z-index: 0;
  opacity: 1;

  color: ${Theme.color.black};

  :focus {
    color: ${Theme.color.black};
    box-shadow: 0px 0px 0px 2px ${Theme.color.control.shadow};
    border: 1px solid ${Theme.color.primary};
  }

  &:disabled {
    background: ${Theme.color.white};
    border: 1px solid ${ColorPalette.gray49};
    color: ${ColorPalette.gray44};
  }

  ::placeholder {
    color: ${ColorPalette.gray44};
  }
`;

export const inputLabelCss = css`
  color: ${Theme.color.black};
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: ${Typography.size.size16};
`;

export const controlLayout = css`
  margin-bottom: 16px;
`;

export const errorLayout = css`
  z-index: 1;
  position: absolute;
  bottom: -20px;
  left: 15px;
  font-size: ${Typography.size.size10};
`;
