import React, {memo} from 'react';
import styled from 'styled-components';

import {AvatarCell} from '../../shared';
import {IAvatarCellProps} from '../../shared/AvatarCell/AvatarCell';
import Nebula from 'Common/components/Layout/Nebula';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';

const ARCHIVED_LABEL = 'Archived';
const DELETED_LABEL = 'Deleted';

const DisabledSticker = styled.span`
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size12};
  font-weight: ${Typography.weight.medium500};
  z-index: 2;
  margin-right: 8px;
`;

const HideGeneticDataMarker = styled.div`
  width: 22px;
  height: 22px;
  position: absolute;
  z-index: 2;
  bottom: -6px;
  right: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${ColorPalette.black1};
  border: 2px solid ${ColorPalette.white0};
`;

interface IProps {
  isArchived: boolean;
  isDeleted: boolean;
  isGeneticDataHidden: boolean;
  isAvailableArchivedProfile?: boolean;
}

type AllProps = IAvatarCellProps & IProps;

function AvatarCellHorse(props: AllProps) {
  const {isArchived, isDeleted, isGeneticDataHidden, isAvailableArchivedProfile, ...rest} = props;
  const isNebulaActive = (isArchived && !isAvailableArchivedProfile) || isDeleted;

  return (
    <Nebula active={isNebulaActive} className="position-relative">
      <>
        <div className="position-absolute d-flex">
          {isArchived && <DisabledSticker className="badge badge-warning">{ARCHIVED_LABEL}</DisabledSticker>}
          {isDeleted && <DisabledSticker className="badge badge-danger">{DELETED_LABEL}</DisabledSticker>}
        </div>
        <AvatarCell {...rest}>
          {isGeneticDataHidden && (
            <HideGeneticDataMarker>
              <ColoredIcon name={IconName.EyeHide} color={ColorPalette.white0} size={12} fill={true} stroke={false} />
            </HideGeneticDataMarker>
          )}
        </AvatarCell>
      </>
    </Nebula>
  );
}

export default memo(AvatarCellHorse);
