import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import StartPageBackgroundPlaceholder from 'Common/components/StartPageLayout/StartPageBackgroundPlaceholder';
import {AnimatedInitiate} from 'Common/components/StyledComponents/StyledComponents';
import {ReactComponent as VerifyEmailImageDesktop} from 'Signup/components/img/verifyEmailDesktop.svg';
import {ReactComponent as VerifyEmailImageTablet} from 'Signup/components/img/verifyEmailTablet.svg';
import {ReactComponent as VerifyEmailImageMobile} from 'Signup/components/img/verifyEmailMobile.svg';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {PlaceholderDescription} from 'Common/components/StartPageLayout/shared/StyledComponents';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'MailConfirmation/store/requestNewMailConfirmation/index';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {RequestNewMailConfirmationModule} from 'MailConfirmation/store/requestNewMailConfirmation/RequestNewMailConfirmationModule';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import Loading from 'Loading/components/Loading';
import useServiceMode from 'Maintain/hooks/useServiceMode';
import {ServiceMode} from 'Maintain/models/IServiceMode';

const AnimatedInitiateWrapper = styled(AnimatedInitiate)`
  flex-direction: column;

  @media ${breakpoints.sm} {
    padding: 0 40px;
  }

  @media ${breakpoints.md} {
    flex-direction: row;
  }
`;

const Title = styled.div`
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size40};
  line-height: 56px;
  text-align: start;
  color: ${ColorPalette.white0};
`;

const Description = styled(PlaceholderDescription)`
  margin-bottom: 16px;
  text-align: start;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 50%;

  @media ${breakpoints.sm} {
    width: 60%;
    height: 70%;
  }

  @media ${breakpoints.md} {
    width: 60%;
    height: 95%;
  }
`;

const TextWrapper = styled.div`
  height: 50%;
  padding: 0 25px;
  margin-top: -50px;
  justify-content: flex-start;

  @media ${breakpoints.sm} {
    padding: 0;
    margin-top: 35px;
  }

  @media ${breakpoints.md} {
    margin-top: 0;
    height: 100%;
    justify-content: center;
  }
`;

const ResendButton = styled(PrimaryButton)`
  margin-top: 20px;
  z-index: 100;
  width: 100%;

  @media ${breakpoints.sm} {
    width: 240px;
  }
`;

interface IProps {
  email: string;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IProps;

const SuccessSignupPage = (props: AllProps) => {
  const {email, resendEmailRequesting, resendEmail} = props;
  const {isMobile, isTablet, isDesktop} = useMediaQuery();

  const {getServiceModeStatus, isServiceModeLoading} = useServiceMode();

  useEffect(() => {
    getServiceModeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRequesting = resendEmailRequesting.isRequesting;

  useCommunicationToToast(resendEmailRequesting, 'Message has been successfully sent');

  const resendEmailHandler = useCallback(async () => {
    const status = await getServiceModeStatus();
    if (status === ServiceMode.UsersOff) {
      return;
    }
    resendEmail(email);
  }, [email, getServiceModeStatus, resendEmail]);

  return (
    <StartPageBackgroundPlaceholder height="100vh">
      <AnimatedInitiateWrapper className="d-flex w-100 h-100 position-relative">
        {isServiceModeLoading && <Loading />}
        <ImageWrapper className="d-flex position-relative">
          {isMobile && <VerifyEmailImageMobile className="w-100 h-100 position-absolute" />}
          {isTablet && <VerifyEmailImageTablet className="w-100 h-100 position-absolute" />}
          {isDesktop && <VerifyEmailImageDesktop className="w-100 h-100 position-absolute" />}
        </ImageWrapper>

        <TextWrapper className="d-flex w-100 flex-column align-items-start">
          <Title>Please verify your email</Title>
          <Description maxWidth={291}>
            You’re almost done! We sent an email to <b>{email}</b>
          </Description>
          <Description maxWidth={430}>
            Just click on the link in that email to complete your signup. If you don’t see it you may need to{' '}
            <b>check your spam</b> folder.
          </Description>
          <Description>Still can’t find the email?</Description>
          <ResendButton isLoading={isRequesting} onClick={resendEmailHandler}>
            Resend email
          </ResendButton>
        </TextWrapper>
      </AnimatedInitiateWrapper>
    </StartPageBackgroundPlaceholder>
  );
};

const mapStateToProps = (state: IAppState) => ({
  resendEmailRequesting: selectors.selectCommunication(state, 'confirmationRequesting'),
});

const mapDispatchToProps = {
  resendEmail: actions.requestNewMailConfirmation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(SuccessSignupPage));

export default withDynamicModules(Connected, RequestNewMailConfirmationModule);
