import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

import {IAdminNotifications} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdminNotifications';

const STATE_NAME = 'adminNotification';

export interface IAdminNotificationState {
  data: {
    adminNotifications: IAdminNotifications;
  };
  communications: {
    adminNotificationsLoading: ICommunication;
    adminNotificationsUpdating: ICommunication;
  };
}

export const getAdminNotificationsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ADMIN_NOTIFICATIONS');

export const updateAdminNotificationsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ADMIN_NOTIFICATIONS'
);

export type getAdminNotificationsActions = MakeCommunicationActions<
  typeof getAdminNotificationsActionTypes,
  {success: IAdminNotifications}
>;

export type updateAdminNotificationsActions = MakeCommunicationActions<typeof updateAdminNotificationsActionTypes, {}>;

export type AdminNotificationsActions = getAdminNotificationsActions | updateAdminNotificationsActions;
