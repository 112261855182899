import React, {memo} from 'react';
import {Helmet} from 'react-helmet';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import Associations from 'Admin/AdminReference/components/Associations/Associations';
import Breeds from 'Admin/AdminReference/components/Breeds/Breeds';
import Disciplines from 'Admin/AdminReference/components/Disciplines/Disciplines';
import Markings from 'Admin/AdminReference/components/Markings/Markings';
import Colors from 'Admin/AdminReference/components/Colors/Colors';
import CommercialTypes from 'Admin/AdminReference/components/CommercialTypes/CommercialTypes';
import AdminTabs from 'Admin/common/AdminTabs';
import AdminContent from 'Admin/common/AdminContent';
import {ITab} from 'Common/components/Tabs/ITab';
import BreedPanels from './BreedPanels/BreedPanels';
import InconclusiveHealthIssues from './InconclusiveHealthIssues/InconclusiveHealthIssues';

const basePath = '/dictionaries-admin';

const adminData: ITab[] = [
  {section: 'associations', title: 'Associations', Component: Associations},
  {section: 'breeds', title: 'Breeds', Component: Breeds},
  {section: 'breed-panels', title: 'Breed Panels', Component: BreedPanels},
  {section: 'colors', title: 'Colors', Component: Colors},
  {section: 'commercial-types', title: 'Commercial Types', Component: CommercialTypes},
  {section: 'disciplines', title: 'Disciplines', Component: Disciplines},
  {section: 'markings', title: 'Markings', Component: Markings},
  {section: 'inconclusive-health-issues', title: 'Preclinical Studies', Component: InconclusiveHealthIssues},
];

const AdminDictionariesLayout = () => {
  return (
    <BaseLayout withoutPaddings={true} widthByContent={true} isBackButtonDenied={true}>
      <Helmet>
        <title>Reference data</title>
      </Helmet>

      <AdminTabs tabs={adminData} basePath={basePath} isSort={true} />
      <AdminContent tabs={adminData} basePath={basePath} isSort={true} />
    </BaseLayout>
  );
};

export default memo(AdminDictionariesLayout);
