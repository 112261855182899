/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect} from 'react';

export const useDebouncedEffect = (
  effect: React.EffectCallback,
  timeout: number,
  deps: React.DependencyList = [effect]
) => {
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, timeout);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, timeout]);
};
