import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.adminPermissions;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectCurrentAdminPermissions(state: IAppState) {
  return selectState(state).data.currentAdminPermissions;
}

export function selectAdminPermissions(state: IAppState) {
  return selectState(state).data.adminPermissions;
}
