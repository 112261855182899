import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import IElementPosition, {ElementPosition} from 'FoalProfile/models/IElementPosition';
import AbilityIndicator from 'FoalProfile/components/Indicators/AbilityIndicator';
import {breakpoints} from 'Common/constants/Breakpoints';
import {IShortAbility} from 'Dictionaries/models/IAbilities';
import {AbilityType} from 'Common/constants/AbilityType';
import {getAbilitiesByType} from 'Common/helpers/getAbilitiesByType';
import Theme from 'Common/constants/Theme';

const Root = styled.div<IElementPosition>`
  background-color: ${Theme.color.black};

  ${prop =>
    prop.position === ElementPosition.Left
      ? `border-right: 1px solid ${ColorPalette.black3};`
      : `border-left: 1px solid ${ColorPalette.black3};`};
`;

const InfoIndicatorsWrapper = styled.div<IElementPosition>`
  ${prop =>
    prop.position === ElementPosition.Left
      ? `padding-right: 25px; padding-left: 4px;`
      : `padding-left: 25px; padding-right: 4px;`};
  padding-bottom: 34px;

  @media ${breakpoints.sm} {
    padding-top: 15px;
    padding-bottom: 20px;
    ${prop => (prop.position === ElementPosition.Left ? `padding-right: 35px;` : `padding-left: 35px;`)};
  }

  @media ${breakpoints.lg} {
    padding-bottom: 2px;
  }
`;

interface IProps {
  position: ElementPosition;
  abilities?: IShortAbility[];
}

function FoalParentAbilities(props: IProps) {
  const {abilities = [], position} = props;
  return (
    <Root className="w-100" position={props.position}>
      <InfoIndicatorsWrapper className="w-100" position={props.position}>
        <AbilityIndicator
          elementPosition={props.position}
          title="Speed"
          ability={getAbilitiesByType(abilities, AbilityType.Speed)[0]}
        />
        <AbilityIndicator
          elementPosition={position}
          title="Temperament"
          ability={getAbilitiesByType(abilities, AbilityType.Temperament)[0]}
        />
        <AbilityIndicator
          elementPosition={position}
          title="DMRT3"
          ability={getAbilitiesByType(abilities, AbilityType.Gait)[0]}
        />
      </InfoIndicatorsWrapper>
    </Root>
  );
}

export default memo(FoalParentAbilities);
