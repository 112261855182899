import React, {useEffect} from 'react';
import {ThunkDispatch} from 'redux-thunk';
import {bindActionCreators} from 'redux';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {IAppState} from 'Common/store/IAppState';
import {ancestryDistributor} from './distributor';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import Loading from 'Loading/components/Loading';
import {IOnlineReportExternalProps} from 'OnlineReport/models/shared/IOnlineReportExternalProps';
import ScienceOfAncestry from './parts/ScienceOfAncestry';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {AncestryReportActions} from 'OnlineReport/store/ancestry';
import {AncestryReportModule} from 'OnlineReport/store/ancestry/ancestryReportModule';
import {AdminAncestryReportModule} from 'Admin/AdminDashboard/store/adminOnlineReport/ancestry/adminAncestryReportModule';
import {scrollToTop} from 'Common/helpers/scrollToTop';
import {useOnlineReportErrors} from 'OnlineReport/hooks/useOnlineReportErrors';
import GlossaryOfTermsLinks from 'OnlineReport/components/Ancestry/parts/GlossaryOfTermsLinks';
import FunFactsArticle from 'OnlineReport/components/Ancestry/parts/FunFactsArticle';
import SectionContainer from 'OnlineReport/components/Ancestry/parts/common/SectionContainer/SectionContainer';
import MapView from 'OnlineReport/components/Ancestry/parts/MapView';
import CompositionChart from 'OnlineReport/components/Ancestry/parts/CompositionChart/CompositionChart';
import GeneticCompositionKey from 'OnlineReport/components/Ancestry/parts/GeneticCompositionKey';
import BreakdownView from 'OnlineReport/components/Ancestry/parts/BreakdownView';
import {OnlineReportModule} from 'OnlineReport/store/onlineReportModule';
import {AdminOnlineReportModule} from 'Admin/AdminDashboard/store/adminOnlineReport/adminOnlineReportModule';
import PCAPlot from 'OnlineReport/components/Ancestry/parts/PCAPlot/PCAPlot';
import PCAPlotProbabilities from 'OnlineReport/components/Ancestry/parts/PCAPlot/PCAPlotProbabilities';
import {SectionContainerContext} from 'OnlineReport/components/Ancestry/parts/common/SectionContainer/useSectionContainer';
import IconedLoading from 'Common/components/IconedLoading/IconedLoading';
import HorsesLikeMe from './parts/HorsesLikeMe/HorsesLikeMe';
import GlossaryOfTerms from './parts/ExamplesOfBreeds/ExamplesOfBreeds';
import {OrderReportType} from 'Common/constants/OrderReportType';

const Root = styled.div<{isFullWidth?: boolean}>`
  ${(props) => !props.isFullWidth && 'max-width: 1280px;'}
  display: flex;
  flex-direction: column;

  @media print {
    display: block;
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IOnlineReportExternalProps;

const Ancestry = (props: AllProps) => {
  const {horseId, isPrintable} = props;
  const {
    getPopulation,
    population,
    populationLoading,
    getPopulationComparison,
    populationComparison,
    populationComparisonLoading,
    ancestryProbabilities,
    ancestryProbabilitiesLoading,
    getAncestryProbabilities,
    getHorseInfo,
    horseInfo,
    horseInfoLoading,
    ancestryRelatedHorses,
    ancestryRelatedHorsesLoading,
    getAncestryRelated,
    ancestryKinshipHorses,
    ancestryKinshipHorsesLoading,
    getAncestryKinship,
    onHasError,
    getHorseOwner,
    horseOwner,
  } = props;

  const isLoading = [
    populationLoading,
    populationComparisonLoading,
    ancestryProbabilitiesLoading,
    horseInfoLoading,
    ancestryRelatedHorsesLoading,
    ancestryKinshipHorsesLoading,
  ].some((x) => x.isRequesting);

  useEffect(() => {
    if (+horseId) {
      getPopulation(+horseId);
      getPopulationComparison(+horseId);
      getAncestryProbabilities(+horseId);
      getAncestryRelated(+horseId);
      getAncestryKinship(+horseId);
      if (isPrintable) {
        getHorseInfo(+horseId);
        getHorseOwner(+horseId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);

  const errors =
    populationLoading.error ||
    populationComparisonLoading.error ||
    ancestryProbabilitiesLoading.error ||
    horseInfoLoading.error;

  const {onlineReportErrors} = useOnlineReportErrors({
    horseId: +horseId,
    error: errors,
    reportType: OrderReportType.Ancestry,
  });

  const staticSectionsData = {
    horseName: horseInfo?.name || '',
    ownerName: horseOwner || '',
    sampleId: horseInfo?.sampleId || '',
  };

  const isNotPrintableErrors =
    isPrintable && (!ancestryRelatedHorsesLoading.error || !ancestryKinshipHorsesLoading.error);

  useEffect(() => {
    onHasError(!!errors);
  }, [errors, onHasError]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Root isFullWidth={!!errors}>
      {isLoading && <IconedLoading />}
      {onlineReportErrors}
      {!errors && (
        <SectionContainerContext.Provider value={{staticData: staticSectionsData, isPrintable}}>
          {population && population.length > 0 && (
            <SectionContainer header={horseInfo?.name || ''}>
              <MapView population={population} />
              <CompositionChart population={population} avatar={horseInfo?.avatar} />
            </SectionContainer>
          )}

          {horseInfo && population && population.length > 0 && (!isPrintable || isNotPrintableErrors) && (
            <SectionContainer header={'Horses Like Me Results'}>
              <HorsesLikeMe
                horse={horseInfo}
                population={population}
                relatedHorses={ancestryRelatedHorses}
                kinshipHorses={ancestryKinshipHorses}
                relatedErrors={ancestryRelatedHorsesLoading.error}
                kinshipErrors={ancestryKinshipHorsesLoading.error}
                isPrintable={!!isPrintable}
              />
            </SectionContainer>
          )}

          <SectionContainer header="Genetic Composition Key">
            <GeneticCompositionKey />
          </SectionContainer>
          {population && population.length > 0 && (
            <SectionContainer header="Ancestry Breakdown">
              <BreakdownView population={population} />
            </SectionContainer>
          )}
          <SectionContainer header="Ancestry Population Comparison">
            {populationComparison && (
              <PCAPlot horseName={horseInfo?.name} data={populationComparison} isPrintable={!!isPrintable} />
            )}
            {ancestryProbabilities && population && (
              <PCAPlotProbabilities data={ancestryProbabilities} population={population} />
            )}
          </SectionContainer>
          <SectionContainer header="Ancestry Fun Facts">
            <FunFactsArticle />
          </SectionContainer>
          <SectionContainer header="The Science of Ancestry">
            <ScienceOfAncestry />
          </SectionContainer>
          <SectionContainer header="Examples of Breed Averages">
            <GlossaryOfTerms />
          </SectionContainer>
          <SectionContainer header="Glossary of Terms">
            <GlossaryOfTermsLinks />
          </SectionContainer>
        </SectionContainerContext.Provider>
      )}
    </Root>
  );
};

const mapStateToProps = (state: IAppState, externalProps: {reportType: OnlineReportType}) => {
  const {reportType} = externalProps;

  const distributor = ancestryDistributor[reportType];
  return {...distributor?.state(state, externalProps)};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, undefined, AncestryReportActions>,
  externalProps: {reportType: OnlineReportType}
) => {
  const {reportType} = externalProps;

  const distributor = ancestryDistributor[reportType];
  const dispatchToProps = {...distributor.dispatch};

  return bindActionCreators(dispatchToProps, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(React.memo(Ancestry));
export default withDynamicModules(Connected, [
  AncestryReportModule,
  AdminAncestryReportModule,
  OnlineReportModule,
  AdminOnlineReportModule,
]);
