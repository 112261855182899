import React, {memo} from 'react';

import {IAdminPartialColor} from 'Admin/AdminPhenotypes/models/IAdminPartialColor';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import PartialColorsForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/PartialColors/PartialColorForm/PartialColorForm';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout, {
  DictionaryFormProps
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';
import {IDictionary} from 'DictionaryFactory/types';
import {IUpdatePartialColorRequest} from 'Admin/AdminPhenotypes/service/models/IServerPartialColor';

function PartialColors() {
  const {adminPartialColor: dictionary, partialColorGroups, traits, affectionTypes} = useDictionaries();

  const wrappedPartialColorsForm = (
    props: DictionaryFormProps<IDictionary<IAdminPartialColor, IUpdatePartialColorRequest>>
  ) => {
    return (
      <PartialColorsForm
        {...props}
        affectionTypeDictionary={affectionTypes}
        partialColorGroupDictionary={partialColorGroups}
        traitDictionary={traits}
      />
    );
  };

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={wrappedPartialColorsForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IAdminPartialColor> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
            <Cell<IAdminPartialColor> dataKey="name" width="20%" header="Name" render={({name}) => name} />
            <Cell<IAdminPartialColor>
              dataKey="positionIndex"
              width="5%"
              header="Order in Report"
              sortable={false}
              render={({positionIndex}) => positionIndex}
            />
            <Cell<IAdminPartialColor>
              dataKey="affectionType"
              width="10%"
              header="Affection type"
              render={({affectionType}) => affectionType?.name}
            />
            <Cell<IAdminPartialColor>
              dataKey="trait"
              width="10%"
              header="Trait"
              sortable={false}
              render={({trait}) => trait?.name}
            />
            <Cell<IAdminPartialColor>
              dataKey="group"
              width="10%"
              header="Group"
              sortable={false}
              render={({group}) => group?.name}
            />
            <Cell<IAdminPartialColor>
              dataKey="description"
              width="25%"
              header="Description"
              sortable={false}
              render={({description}) => description?.value}
            />
            <Cell<IAdminPartialColor>
              dataKey="externalDescriptionUrl"
              width="10%"
              header="URL"
              sortable={false}
              render={({externalDescriptionUrl}) => externalDescriptionUrl}
            />
            <Cell<IAdminPartialColor>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(PartialColors);
