import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.businessPortalAssociationPage;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectGeneralInformation(state: IAppState) {
  return selectState(state).data.generalInformation;
}

export function selectContactInformation(state: IAppState) {
  return selectState(state).data.contactInformation;
}

export function selectOrganizationAvatar(state: IAppState) {
  return selectState(state).data.organizationAvatar;
}
