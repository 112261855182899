import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  addExcludeHorseActions,
  addExcludeHorseActionTypes,
  deleteExcludeHorseActions,
  deleteExcludeHorseActionTypes,
  getExcludeHorsesActions,
  getExcludeHorsesActionTypes,
  getSimpleExcludeHorsesActions,
  getSimpleExcludeHorsesActionTypes,
  IExcludeHorseState,
  resetSimpleExcludeHorsesActions,
  resetSimpleExcludeHorsesActionTypes,
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IRequestParams} from 'Common/models/IRequestParams';
import ExcludeHorseService from 'ExcludeHorse/services/ExcludeHorseService';
import {IAppState} from 'Common/store/IAppState';

export const getExcludeHorses =
  (params: IRequestParams): ActionResult<IExcludeHorseState, void, getExcludeHorsesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getExcludeHorsesActionTypes.REQUEST));
      const horseList = await ExcludeHorseService.getExcludeHorses(params);
      dispatch(action(getExcludeHorsesActionTypes.SUCCESS, horseList));
    } catch (error) {
      dispatch(action(getExcludeHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getSimpleExcludeHorses =
  (): ActionResult<IExcludeHorseState, void, getSimpleExcludeHorsesActions> => async (dispatch) => {
    try {
      dispatch(action(getSimpleExcludeHorsesActionTypes.REQUEST));
      const horseList = await ExcludeHorseService.getSimpleExcludeHorses();
      dispatch(action(getSimpleExcludeHorsesActionTypes.SUCCESS, horseList));
    } catch (error) {
      dispatch(action(getSimpleExcludeHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const addExcludeHorse =
  (horseId: number): ActionResult<IExcludeHorseState, void, addExcludeHorseActions> =>
  async (dispatch) => {
    try {
      dispatch(action(addExcludeHorseActionTypes.REQUEST));
      await ExcludeHorseService.addExcludeHorse(horseId);
      dispatch(action(addExcludeHorseActionTypes.SUCCESS, horseId));
    } catch (error) {
      dispatch(action(addExcludeHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteExcludeHorse =
  (horseId: number): ActionResult<IExcludeHorseState, void, deleteExcludeHorseActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteExcludeHorseActionTypes.REQUEST));
      await ExcludeHorseService.deleteExcludeHorse(horseId);
      dispatch(action(deleteExcludeHorseActionTypes.SUCCESS, horseId));
    } catch (error) {
      dispatch(action(deleteExcludeHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetSimpleExcludeHorses =
  (): ActionResult<IAppState, void, resetSimpleExcludeHorsesActions> => (dispatch) => {
    dispatch(action(resetSimpleExcludeHorsesActionTypes.RESET));
  };
