import * as Yup from 'yup';

import {IChangePassword} from 'UserProfile/models/IChangePassword';

export const initialValue: IChangePassword = {
  currentPassword: '',
  password: '',
  passwordConfirm: '',
};

export const validationSchema = Yup.object().shape<IChangePassword>({
  currentPassword: Yup.string().required('Current password is required'),
  password: Yup.string()
    .required('New password is required')
    .oneOf([Yup.ref('passwordConfirm')], 'Passwords must match'),
  passwordConfirm: Yup.string()
    .required('Repeat new password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});
