import {ICreateHorseOwnerBP, IUpdateHorseOwnerBP} from 'BusinessPortal/models/owner/IOwnerBP';
import {
  IFormValues,
  initialValue,
} from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/parts/OwnerGeneralInfoSection/validation';
import {IServerHorseOwnerBP} from 'BusinessPortal/services/types/owner';

export const convertToClient = (data: IServerHorseOwnerBP): IUpdateHorseOwnerBP => {
  return {
    ...data,
    organizationId: data.organization?.id,
    address: {
      country: data.address?.country || initialValue.address!.country,
      state: data.address?.state || initialValue.address!.state,
      city: data.address?.city || initialValue.address!.city,
      street: data.address?.street || initialValue.address!.street,
      zip: data.address?.zip || initialValue.address!.zip,
    },
  };
};

export const convertToServer = (data: IFormValues): IUpdateHorseOwnerBP => {
  const allAddressFieldsAreEmpty =
    data.address && Object.values(data.address).every((value) => value === '' || value === null);

  const dataToSave = {
    ...data,
    id: data.id!,
    address: allAddressFieldsAreEmpty ? null : data.address,
  };

  delete dataToSave['avatar'];
  delete dataToSave['organization'];

  return dataToSave;
};

export const convertToServerCreate = (data: IFormValues): ICreateHorseOwnerBP => {
  const allAddressFieldsAreEmpty =
    data.address && Object.values(data.address).every((value) => value === '' || value === null);

  const dataToSave = {
    ...data,
    address: allAddressFieldsAreEmpty ? null : data.address,
    organizationId: data.organizationId!,
  };

  delete dataToSave['id'];
  delete dataToSave['avatar'];

  return dataToSave;
};
