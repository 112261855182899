import React from 'react';
import styled from 'styled-components';

import {IOrderCoupon} from 'Order/models/IOrderCoupon';
import Couponcard from 'Coupon/components/Couponcard';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {InputField} from 'Common/components/FormFields';

import Theme from 'Common/constants/Theme';
import {breakpoints} from 'Common/constants/Breakpoints';
import {BaseStepLayoutChildrenProps} from 'Order/components/CreateOrder/parts/BaseStepLayout';

const CodeFieldContainer = styled.div`
  margin-top: 7px;

  @media ${breakpoints.sm} {
    margin-top: 16px;
  }
`;

const CodeFieldHeader = styled.div`
  font-weight: ${Typography.weight.medium500};
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  color: ${ColorPalette.gray44};
`;

const CodeField = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  @media ${breakpoints.sm} {
    flex-direction: row;
  }
`;

const CouponCardContainer = styled.div`
  margin-top: 24px;
`;

const ApplyCodeButton = styled(PrimaryButton)`
  @media ${breakpoints.sm} {
    margin-left: 16px;
  }
`;

const CodeInput = styled(InputField)`
  width: 100%;

  @media ${breakpoints.sm} {
    width: 280px;
  }
`;

interface IProps {
  coupon: IOrderCoupon | null;
  isDisabled?: boolean;
  isLoading?: boolean;
  onApplyCode(): void;
  onDeleteCoupon(): void;
}

type AllProps = IProps & BaseStepLayoutChildrenProps;

function Discount(props: AllProps) {
  const {coupon, onDeleteCoupon, onApplyCode, isDisabled, isLoading} = props;

  return (
    <div>
      <div className="d-flex">
        {coupon && (
          <CouponCardContainer>
            <Couponcard
              onClose={onDeleteCoupon}
              amount={coupon.amount}
              discountType={coupon.type}
              expiryDate={coupon.validUntil}
              usesLeft={coupon.usesLeft}
              packages={coupon.packages}
            />
          </CouponCardContainer>
        )}
      </div>
      <CodeFieldContainer>
        <CodeFieldHeader>Code</CodeFieldHeader>
        <CodeField className="d-flex">
          <CodeInput name="couponCode" placeholder="Put your coupon or gift card code" autoComplete="off" />
          <ApplyCodeButton variant="outlined" disabled={isDisabled} isLoading={isLoading} onClick={onApplyCode}>
            Apply
          </ApplyCodeButton>
        </CodeField>
      </CodeFieldContainer>
    </div>
  );
}

export default Discount;
export type DiscountProps = AllProps;
