import {Reducer} from 'redux';

import {IFindHorseState, findHorseActionTypes, findHorseActions} from '../types';
import {
  addFavoriteHorseActionTypes,
  deleteFavoriteHorseActionTypes,
  FavoriteHorsesActions,
} from 'FavoriteHorse/store/types';

const initialState: IFindHorseState['data'] = {
  horses: [],
};

export const dataFindHorseReducer: Reducer<IFindHorseState['data'], findHorseActions> = (
  state = initialState,
  action: findHorseActions | FavoriteHorsesActions
) => {
  switch (action.type) {
    case findHorseActionTypes.SUCCESS: {
      return {...state, horses: action.payload.data};
    }
    case addFavoriteHorseActionTypes.SUCCESS: {
      return {
        ...state,
        horses: state.horses.map((horse) => ({
          ...horse,
          isFavorite: action.payload === horse.id ? true : horse.isFavorite,
        })),
      };
    }
    case deleteFavoriteHorseActionTypes.SUCCESS: {
      return {
        ...state,
        horses: state.horses.map((horse) => ({
          ...horse,
          isFavorite: action.payload === horse.id ? false : horse.isFavorite,
        })),
      };
    }
    default: {
      return state;
    }
  }
};
