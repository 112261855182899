import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminOrders/index';
import Loading from 'Loading/components/Loading';
import HorseReportList from 'Admin/AdminAssociations/components/Orders/HorseReportList/HorseReportList';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';

interface IOwnProps {
  orderId: number;
  horseId: number;
  openUploadReportModal(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IOwnProps;

function ReportDetails(props: Props) {
  const {
    orderId,
    horseId,
    openUploadReportModal,
    getOrderReportDetails,
    orderReportDetails,
    orderReportDetailsLoading,
  } = props;

  const {listen} = useEventBus();

  const getReports = useCallback(() => {
    if (orderId) {
      getOrderReportDetails(orderId);
    }
  }, [getOrderReportDetails, orderId]);

  useEffect(() => {
    getReports();
  }, [getReports]);

  useEffect(() => {
    const eventReportUpload = listen(EventName.ReportUpload, getReports);

    return () => {
      eventReportUpload.stopListening();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = orderReportDetailsLoading.isRequesting;

  return (
    <div className="position-relative">
      {isLoading && <Loading />}
      <HorseReportList
        horseReports={orderReportDetails}
        horseId={horseId}
        uploadReportHandler={openUploadReportModal}
        onSuccess={getReports}
      />
    </div>
  );
}

const mapStateToProps = (state: IAppState) => ({
  orderReportDetails: selectors.selectOrderReportDetails(state),
  orderReportDetailsLoading: selectors.selectCommunication(state, 'orderReportDetailsLoading'),
});

const mapDispatchToProps = {
  getOrderReportDetails: actions.getOrderReportDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default memo(connector(ReportDetails));
