import React, {memo} from 'react';

import OrderSuccessLayout from 'Shared/components/OrderSuccess/common/OrderSuccessLayout';
import OrderSuccessButtons from 'Shared/components/OrderSuccess/common/OrderSuccessButtons';
import {Description, Title} from 'Shared/components/OrderSuccess/common/styled';

function OrderSuccessPlaced() {
  return (
    <OrderSuccessLayout>
      <Title>Order successfully placed and waiting for payment</Title>
      <Description>
        <div>
          Thank you for your order. As you have chosen "invoice" please enclose a check or we will issue you an invoice.
          You can also click the pay now button to pay by card. Please be sure payment is made promptly to avoid sample
          processing delays.
        </div>
      </Description>

      <OrderSuccessButtons />
    </OrderSuccessLayout>
  );
}

export default memo(OrderSuccessPlaced);
