import React, {memo} from 'react';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout, {
  DictionaryFormProps
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';

import {IDictionary} from 'DictionaryFactory/types';
import {IAdminAbility} from 'Admin/AdminPhenotypes/models/IAdminAbility';
import {IUpdateAbilityRequest} from 'Admin/AdminPhenotypes/service/models/IServerAbility';
import AbilityForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/Abilities/AbilityForm/AbilityForm';

function Abilities() {
  const {adminAbilities: dictionary, abilityGroupsAdvanced} = useDictionaries();

  const wrappedAbilityForm = (props: DictionaryFormProps<IDictionary<IAdminAbility, IUpdateAbilityRequest>>) => {
    return <AbilityForm {...props} abilityGroupDictionary={abilityGroupsAdvanced} />;
  };

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={wrappedAbilityForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IAdminAbility> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
            <Cell<IAdminAbility> dataKey="name" width="20%" header="Name" render={({name}) => name} />
            <Cell<IAdminAbility>
              dataKey="group"
              width="15%"
              header="Group"
              sortable={false}
              render={({group}) => group?.name}
            />
            <Cell<IAdminAbility>
              dataKey="description"
              width="30%"
              header="Description"
              sortable={false}
              render={({description}) => description?.value}
            />
            <Cell<IAdminAbility>
              dataKey="externalDescriptionUrl"
              width="20%"
              header="URL"
              sortable={false}
              render={({externalDescriptionUrl}) => externalDescriptionUrl}
            />
            <Cell<IAdminAbility>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(Abilities);
