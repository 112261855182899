import * as Yup from 'yup';

export interface IFormValues {
  horseName: string;
  ownerName: string;
  isSendReport: boolean;
  uploadedFile?: File;
  reportType?: number;
}

export const initialValue: IFormValues = {
  horseName: '',
  ownerName: '',
  isSendReport: false,
  uploadedFile: undefined,
  reportType: 1,
};

export const validationSchema = Yup.object().shape<IFormValues>({
  horseName: Yup.string(),
  ownerName: Yup.string(),
  isSendReport: Yup.boolean(),
  uploadedFile: Yup.mixed<File>().required('Report is required'),
  reportType: Yup.number().required('Type of report is required'),
});
