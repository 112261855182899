import React, {memo, useCallback} from 'react';
import styled from 'styled-components';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {connect, ConnectedProps} from 'react-redux';

import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {IUserHorseOrder} from 'Horse/models/IUserHorse';
import {SidebarComponent} from 'HorseProfile/components/shared/StyledComponents';
import {userStatuses} from 'UserProfile/constants/OrderStatuses';
import {OrderStatus} from 'Common/constants/OrderStatus';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {actions, selectors} from 'Order/store/submissionForm';
import {IAppState} from 'Common/store/IAppState';
import Theme from 'Common/constants/Theme';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';
import {VisitorType} from 'Common/constants/VisitorType';
import {Nullable} from 'Common/types';

const OrderStatusLayout = styled.div`
  margin: 14px 4px 24px 4px;
`;

const Fields = styled.div`
  margin-left: 24px;
  line-height: 24px;
`;

const Status = styled.div`
  color: ${ColorPalette.black1};
  font-family: ${Typography.family.openSans};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size23};
  line-height: 32px;
  letter-spacing: -0.4px;
  margin-bottom: 8px;
`;

const OrderId = styled.div`
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  color: ${ColorPalette.gray3};
  line-height: 24px;
  margin-bottom: 8px;
`;

const Packages = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.black1};
`;

const Button = styled(PrimaryButton)`
  width: 100%;
  margin-top: 16px;
`;

const Title = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${Theme.color.gray};
`;

const AllOrdersLink = styled(Link)`
  font-family: ${Typography.family.roboto};
  font-style: normal;
  font-weight: ${Typography.weight.bold700};
  font-size: ${Typography.size.size13};
  color: ${ColorPalette.blue2};

  &:hover {
    opacity: 0.9;
  }
`;

interface IProps {
  order: IUserHorseOrder | null;
  horseId: number;
  className?: string;
  ownerId: number;
  visitorType: VisitorType;
  orderLoadingError?: Nullable<string>;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IProps & RouteComponentProps;

const OrderInfoHorse = (props: Props) => {
  const {
    order,
    history,
    horseId,
    loadingSubmissionForm,
    getSubmissionForm,
    className,
    ownerId,
    visitorType,
    orderLoadingError,
  } = props;

  const isSubmissionFormLoading = loadingSubmissionForm.isRequesting;

  const downloadSubmissionForm = useCallback(() => {
    if (order) {
      getSubmissionForm(order.id, horseId);
    }
  }, [getSubmissionForm, horseId, order]);

  const openPaymentForm = useCallback(() => {
    order && history.push({pathname: '/payment/', search: `?token=${order.token}`});
  }, [history, order]);

  const renderNoTest = () => {
    return (
      <OrderStatusLayout className="d-flex flex-column align-items-center">
        <Status>{orderLoadingError || 'Not tested yet'}</Status>
      </OrderStatusLayout>
    );
  };

  const renderStatus = () => {
    const packages = order?.packages.join(', ');
    return (
      order && (
        <div className="d-flex">
          <ColoredIcon
            color={userStatuses[order.status].color}
            name={userStatuses[order.status].icon}
            size={24}
            className="align-self-start flex-shrink-0"
            style={{marginTop: 6}}
            fill={true}
            stroke={false}
          />
          <Fields>
            <Status>{userStatuses[order.status].label}</Status>
            <OrderId>{`#${order.id}`}</OrderId>
            <Packages>{packages}</Packages>
          </Fields>
        </div>
      )
    );
  };

  const renderOrderPlaced = () => {
    return (
      <OrderStatusLayout className="d-flex flex-column">
        <div className="d-flex align-items-center">{renderStatus()}</div>
        <div className="d-flex flex-column align-items-center">
          <Button onClick={openPaymentForm} size="small">
            Pay order
          </Button>
          <Button onClick={downloadSubmissionForm} disabled={isSubmissionFormLoading} size="small" variant="outlined">
            Submission form
          </Button>
        </div>
      </OrderStatusLayout>
    );
  };

  const renderPaymentReceived = () => {
    return (
      <OrderStatusLayout className="d-flex flex-column">
        {renderStatus()}
        <div className="d-flex flex-column align-items-center">
          <Button onClick={downloadSubmissionForm} disabled={isSubmissionFormLoading} size="small" variant="outlined">
            Submission form
          </Button>
        </div>
      </OrderStatusLayout>
    );
  };

  const renderCurrentStatus = () => {
    return <OrderStatusLayout>{renderStatus()}</OrderStatusLayout>;
  };

  const renderResultsReady = () => {
    return <OrderStatusLayout className="d-flex flex-column">{renderStatus()}</OrderStatusLayout>;
  };

  return (
    <SidebarComponent className={className}>
      <div className="d-flex justify-content-between">
        <Title>Last order</Title>
        <AllOrdersLink
          to={`${PREFIX_VISITOR_TYPE[visitorType]}/user/user-profile/${ownerId}/tests`}
          className="align-self-center"
        >
          All orders
        </AllOrdersLink>
      </div>
      {!order && renderNoTest()}
      {order && (
        <>
          {order.status === OrderStatus.orderPlaced && renderOrderPlaced()}
          {order.status === OrderStatus.paymentReceived && renderPaymentReceived()}
          {order.status === OrderStatus.reviewReady && renderCurrentStatus()}
          {order.status === OrderStatus.releaseReady && renderResultsReady()}
          {order.status === OrderStatus.sampleReceived && renderCurrentStatus()}
          {order.status === OrderStatus.resultsReady && renderResultsReady()}
        </>
      )}
    </SidebarComponent>
  );
};

const mapStateToProps = (state: IAppState) => ({
  loadingSubmissionForm: selectors.selectCommunication(state, 'downloadFormRequesting'),
});

const mapDispatchToProps = {
  getSubmissionForm: actions.downloadHorseSubmissionForm,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default memo(withRouter(connector(OrderInfoHorse)));
