import {Reducer} from 'redux';

import {
  changePackageStatusActionTypes,
  AdminPackagesActions,
  getPackageByIdActionTypes,
  getPackagesActionTypes,
  IAdminPackagesState,
  resetPackageDetailsActionTypes,
} from '../types';

const initialState: IAdminPackagesState['data'] = {
  packages: [],
  packageDetail: null,
};

export const dataAdminPackagesReducer: Reducer<IAdminPackagesState['data'], AdminPackagesActions> = (
  state = initialState,
  action: AdminPackagesActions
) => {
  switch (action.type) {
    case getPackagesActionTypes.SUCCESS: {
      return {...state, packages: action.payload.data};
    }
    case getPackageByIdActionTypes.REQUEST: {
      return {...state, packageDetail: null};
    }
    case getPackageByIdActionTypes.SUCCESS: {
      return {...state, packageDetail: action.payload};
    }
    case changePackageStatusActionTypes.SUCCESS: {
      const {packageId} = action.payload;
      return {
        ...state,
        packages: state.packages.map((p) => (p.id !== packageId ? p : {...p, isEnabled: !p.isEnabled})),
      };
    }
    case resetPackageDetailsActionTypes.RESET: {
      return {...state, packageDetail: initialState.packageDetail};
    }
    default: {
      return state;
    }
  }
};
