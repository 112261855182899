import {Reducer} from 'redux';

import {IUserOrdersState, UserOrdersActions, getUserOrdersActionTypes} from '../types';

const initialStateList: IUserOrdersState['data'] = {
  userOrders: []
};

export const dataReducer: Reducer<IUserOrdersState['data'], UserOrdersActions> = (
  state = initialStateList,
  action: UserOrdersActions
) => {
  switch (action.type) {
    case getUserOrdersActionTypes.SUCCESS: {
      return {...state, userOrders: action.payload.data};
    }
    default: {
      return state;
    }
  }
};
