import * as Yup from 'yup';

import {Nullable} from 'Common/types';

export interface IFormValues {
  allPcaFile: Nullable<File>;
  kTreeFile: Nullable<File>;
  plinkFile: Nullable<File>;

  horsesLikeMe: Nullable<File>;

  isAncestryEnable: boolean;
  isHorsesLieMeEnable: boolean;
}

export const initialValue: IFormValues = {
  allPcaFile: null,
  kTreeFile: null,
  plinkFile: null,
  horsesLikeMe: null,
  isAncestryEnable: false,
  isHorsesLieMeEnable: false,
};

export const validationSchema = Yup.object().shape<IFormValues>({
  allPcaFile: Yup.mixed<File>().test('allPcaFile', `All_pca.txt is required`, function (value: File) {
    return this.parent.isAncestryEnable && !value ? false : true;
  }),
  kTreeFile: Yup.mixed<File>().test('kTreeFile', `k_tree.json is required`, function (value: File) {
    return this.parent.isAncestryEnable && !value ? false : true;
  }),
  plinkFile: Yup.mixed<File>().test('plinkFile', `Plink.txt is required`, function (value: File) {
    return this.parent.isAncestryEnable && !value ? false : true;
  }),
  horsesLikeMe: Yup.mixed<File>().test('horsesLikeMe', `Horese like Me file is required`, function (value: File) {
    return this.parent.isHorsesLieMeEnable && !value ? false : true;
  }),
  isAncestryEnable: Yup.boolean(),
  isHorsesLieMeEnable: Yup.boolean(),
});
