import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminAssociationCreateUserOrder;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectUserOrderHorses(state: IAppState) {
  return selectState(state).data.userHorses;
}

export function selectUserSummary(state: IAppState) {
  return selectState(state).data.userSummary;
}

export function selectUserCoupon(state: IAppState) {
  return selectState(state).data.userCoupon;
}

export function selectAssociationEmpoyees(state: IAppState) {
  return selectState(state).data.associationEmployees;
}
