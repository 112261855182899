import React, {useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Form, FormikProps, withFormik} from 'formik';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {IAppState} from 'Common/store/IAppState';
import {PaymentStatus} from 'Admin/AdminDashboard/models/IPaymentDetails';
import {
  initialValue,
  IPaymentDetailsForm,
  validationSchema,
} from 'Admin/AdminDashboard/components/shared/UploadPaymentDetailsForm/UploadPaymentDetailsFormValidation';
import {actions, selectors} from 'Admin/AdminDashboard/store/common/paymentDetailsUpload/index';
import {PaymentDetailsUploadModule} from 'Admin/AdminDashboard/store/common/paymentDetailsUpload/paymentDetailsUploadModule';
import {CheckboxField, DateTimeField, InputField, SelectField} from 'Common/components/FormFields/index';
import {getStringKeysOption} from 'Common/helpers/OptionHelper';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {ErrorMessage, FieldHint} from 'Common/components/StyledComponents/StyledComponents';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';

import {paymentOptions} from 'Order/constants/PaymentOptions';
import {
  convertPaymentDetailsFormToPaymentDetails,
  convertPaymentDetailsToPaymentDetailsForm,
} from './converters/paymentDetails';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import withDynamicModules from 'Common/helpers/withDynamicModules';

const SendEmailField = styled.div`
  margin: 12px 0 32px;
`;

interface IExternalProps {
  orderId: number;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IPaymentDetailsForm> & OuterProps;

const UpdatePaymentLayout = (props: AllProps) => {
  const {setStatus, setErrors, status, isSubmitting} = props;
  const {orderId, getPaymentDetails, resetPaymentDetails, paymentDetailsLoading, paymentDetailsUpdating, onSuccess} =
    props;

  const errorInfo = paymentDetailsLoading.error || paymentDetailsUpdating.error;
  const isRequesting = paymentDetailsLoading.isRequesting;

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(paymentDetailsUpdating, onSuccess);
  useOnErrorCommunication(paymentDetailsUpdating, onError);
  useCommunicationToToast(paymentDetailsUpdating, 'Payment details has been changed');

  useEffect(() => {
    const fetchData = async () => {
      if (orderId) {
        await getPaymentDetails(orderId);
      }
    };

    fetchData();

    return resetPaymentDetails;
  }, [orderId, resetPaymentDetails, getPaymentDetails]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    const fieldErrors = getFieldErrors(errorInfo);

    if (commonErrors) {
      setStatus(commonErrors);
    }

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  return (
    <>
      <ModalWindowHeader>Update payment details</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {isRequesting && <Loading />}
          <DateTimeField className="w-50" name="receivedDate" label="Payment date" />
          <SelectField
            className="w-50"
            isRequired={true}
            name="paymentStatus"
            label="Status"
            options={getStringKeysOption(PaymentStatus)}
          />
          <SelectField
            className="w-50"
            isRequired={true}
            name="paymentMethod"
            label="Payment Method"
            options={paymentOptions}
          />
          <InputField className="w-50" name="payee" type="text" label="Payee" placeholder="Payee" />
          <InputField
            isRequired={true}
            name="transactionId"
            type="text"
            label="Transaction ID"
            placeholder="Transaction ID"
          />
          <SendEmailField>
            <CheckboxField name="sendEmail" label="Send email" style={{marginBottom: 4}} />
            <FieldHint>
              Enabling this option will allow you to notify the customer about changing payment status via email.
            </FieldHint>
          </SendEmailField>
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" isLoading={isSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const UpdatePaymentWithForm = withFormik<OuterProps, IPaymentDetailsForm>({
  mapPropsToValues: ({paymentDetails}) =>
    paymentDetails ? convertPaymentDetailsToPaymentDetailsForm(paymentDetails) : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    await formikBag.props.updatePaymentDetails(convertPaymentDetailsFormToPaymentDetails(values));
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(UpdatePaymentLayout);

const mapStateToProps = (state: IAppState) => ({
  paymentDetails: selectors.selectPaymentDetails(state),
  paymentDetailsLoading: selectors.selectCommunication(state, 'paymentDetailsLoading'),
  paymentDetailsUpdating: selectors.selectCommunication(state, 'paymentDetailsUpdating'),
});

const mapDispatchToProps = {
  getPaymentDetails: actions.getPaymentDetails,
  updatePaymentDetails: actions.updatePaymentDetails,
  resetPaymentDetails: actions.resetPaymentDetailsResults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(React.memo(UpdatePaymentWithForm));
export default withDynamicModules(Connected, PaymentDetailsUploadModule);
