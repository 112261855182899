import React, {memo, useCallback, useEffect, useMemo} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {connect, ConnectedProps} from 'react-redux';
import {Form, FormikBag, FormikProps, withFormik} from 'formik';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage, FieldHint} from 'Common/components/StyledComponents/StyledComponents';
import {
  convertFormValuesToRequest,
  convertTestSummaryToServer,
  IFormValues,
  initialValues,
  validationSchema,
} from './validation';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminOrders/createUserOrder';
import {selectors as userSelectors} from 'UserProfile/store/currentUser/index';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {CheckboxField, SelectField} from 'Common/components/FormFields/index';
import Theme from 'Common/constants/Theme';
import {withCurrency} from 'Common/helpers/withCurrency';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import Nebula from 'Common/components/Layout/Nebula';
import Loading from 'Loading/components/Loading';
import {breakpoints} from 'Common/constants/Breakpoints';
import {paymentOptions} from 'Order/constants/PaymentOptions';
import {useToast} from 'Common/helpers/hooks/useToast';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {useDictionaries} from 'Common/store/useDictionaries';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import {IPackageSimple} from 'Dictionaries/models/IPackageSimple';
import {AdminAssociationCreateUserOrderModule} from 'Admin/AdminAssociations/store/adminOrders/createUserOrder/adminAssociationCreateUserOrderModule';
import {INamedEntity} from 'Common/models/INamedEntity';
import OrganizationSearchField from 'Admin/AdminAssociations/components/Employees/EmployeeForm/parts/OrganizationSearchField';
import {TextBase} from 'BusinessPortal/components/common/styled';
import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import PackageSection from 'Admin/AdminAssociations/components/Orders/CreateUserOrder/parts/PackageSection';
import {useAssociationCreateOrderDiscount} from 'Shared/components/AssociationCreateOrderDiscount/hooks/useAssociationCreateOrderDiscount';
import DiscountShow, {DiscountShowProps} from './parts/DiscountShow';
import AssociationCreateOrderDiscount, {
  AssociationCreateOrderDiscountProps,
} from 'Shared/components/AssociationCreateOrderDiscount/AssociationCreateOrderDiscount';
import {castToOption} from 'Common/helpers/OptionHelper';
import {sortByName} from 'Common/helpers/sortByName';
import {Divider} from 'Admin/shared/components/styled';

const Summary = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size24};
  line-height: 32px;
  color: ${ColorPalette.black1};
`;

const SummaryLabel = styled.div`
  margin-right: 5px;
`;

const SummaryAmount = styled.div`
  font-weight: ${Typography.weight.medium500};
`;

const MutableModalWindowFooter = styled(ModalWindowFooter)`
  flex-direction: column;

  @media ${breakpoints.sm} {
    flex-direction: row;
  }
`;

const IsPaidCheckbox = styled.div`
  padding-top: 40px;
  margin-left: 16px;
`;

const Section = styled(TextBase)`
  font-size: ${Typography.size.size20};
  line-height: 32px;
  letter-spacing: 0;
  margin-bottom: 16px;
`;

const SendEmailField = styled.div`
  margin: 12px 0 32px;
`;

interface IExternalProps {
  horseId?: number;
  onSuccess?(): void;
  packagesDictionary: ISimpleDictionary<IPackageSimple>;
  testsDictionary: ISimpleDictionary<INamedEntity>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps & RouteComponentProps<{horseIds?: string}>;

type AllProps = FormikProps<IFormValues> & OuterProps;

const CreateUserOrder = (props: AllProps) => {
  const {values, isSubmitting, isValid, submitCount, setFieldValue} = props;
  const {
    onSuccess,
    getHorses,
    packagesDictionary,
    testsDictionary,
    getSummary,
    horses,
    packages,
    tests,
    summary,
    coupon,
    getCoupon,
    creatingOrder,
    summaryLoading,
    couponLoading,
    removeCoupon,
    removeSummary,
    horsesLoading,
    packagesLoading,
    horseId,
    associationEmployees,
    getAssociationEmployees,
    associationEmployeesLoading,
  } = props;

  const {
    actions: {getItems: getPackages},
  } = packagesDictionary;

  const {
    actions: {getItems: getTests},
  } = testsDictionary;

  const {addToast} = useToast(1);

  const errorInfo =
    creatingOrder.error ||
    summaryLoading.error ||
    horsesLoading.error ||
    packagesLoading.error ||
    associationEmployeesLoading.error;

  useEffect(() => {
    const commonError = getCommonErrors(errorInfo);
    commonError && addToast(commonError, 'error');
  }, [errorInfo, addToast]);

  const {openDiscountForm, discountModal, checkCode, removeCouponFromOrder, couponError} =
    useAssociationCreateOrderDiscount({removeCoupon, horses, coupon, couponLoading, getCoupon});

  const organizationNotFound = !values.organizationId || values.organizationId === 0;

  const getNecessaryUsersInfoForOrder = useCallback(
    (organizationId: number) => {
      getHorses(organizationId);
      getPackages(organizationId);
      getTests(organizationId);
    },
    [getHorses, getPackages, getTests]
  );

  useEffect(() => {
    if (!organizationNotFound) {
      getNecessaryUsersInfoForOrder(values.organizationId!);
    }

    if (!organizationNotFound) {
      getAssociationEmployees(values.organizationId!);
    }

    if (horseId) {
      setFieldValue('tests', [{horseId}]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNecessaryUsersInfoForOrder, values.organizationId, horseId, setFieldValue]);

  const isTestEmpty = values.orders.length === 0 || values.orders.every((x) => !x.packages && !x.tests);

  useEffect(() => {
    if (isTestEmpty) {
      removeSummary();
      return;
    }

    const summaryParams = {
      organizationId: values.organizationId!,
      ...convertTestSummaryToServer(values.orders, values.coupons),
    };

    getSummary(summaryParams);
  }, [getSummary, isTestEmpty, removeSummary, values.coupons, values.orders, values.organizationId]);

  useOnSuccessCommunication(creatingOrder, () => onSuccess && onSuccess());

  const hasCouponError = !!coupon && !!couponError;
  const disableSubmitButton =
    (summary?.packagesAndTestsPrice?.length || 0) < 1 || !(submitCount === 0 || isValid) || hasCouponError || !isValid;

  const isLoading =
    horsesLoading.isRequesting ||
    packagesLoading.isRequesting ||
    associationEmployeesLoading.isRequesting ||
    isSubmitting;

  const isSummaryLoading = summaryLoading.isRequesting || couponLoading.isRequesting;

  const discountProps: AssociationCreateOrderDiscountProps = {
    coupons: values.coupons,
    onApplyCode: checkCode,
    isLoading: couponLoading.isRequesting,
  };

  const discountShowProps: DiscountShowProps = {
    horses: horses.filter((i) => values.orders.some((j) => j.horseId === i.id)),
    coupons: values.coupons,
    onDeleteCoupon: removeCouponFromOrder,
    onEditHorsesList: (coupon?: IOrderCoupon) => openDiscountForm(coupon, 'edit'),
  };

  const associationEmployeesOptions = useMemo(
    () =>
      castToOption(
        associationEmployees
          .map((employee) => ({...employee, name: `${employee.name} (${employee.email})`}))
          .sort(sortByName)
      ),
    [associationEmployees]
  );

  return (
    <>
      {discountModal}
      <ModalWindowHeader>Create order</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        {isLoading && <Loading />}
        <ModalWindowFormContent minHeight={420}>
          <Section>Organization</Section>
          <OrganizationSearchField name="organizationId" label="" isRequired />
          {organizationNotFound && <Divider />}
          <Nebula active={organizationNotFound} style={{opacity: 0.5, marginTop: -5}}>
            {!organizationNotFound && <PackageSection {...{horses, packages, tests}} orders={values.orders} />}

            <AssociationCreateOrderDiscount {...discountProps}>
              <DiscountShow {...discountShowProps} />
            </AssociationCreateOrderDiscount>

            <div className="d-flex w-100">
              <div className="w-50">
                <SelectField isRequired={true} name="paymentMethod" label="Payment Method" options={paymentOptions} />
              </div>
              <IsPaidCheckbox>
                <CheckboxField name="isPaid" label="Mark as paid" />
              </IsPaidCheckbox>
            </div>

            <SelectField
              name="employeeId"
              label="Association’s responsible employee"
              options={associationEmployeesOptions}
              isRequired
            />

            <SendEmailField>
              <CheckboxField name="sendEmailToUser" label="Send email" style={{marginBottom: 4}} />
              <FieldHint>
                Enabling this option, will allow you to notify the association’s responsible employee about creating
                order
              </FieldHint>
            </SendEmailField>
          </Nebula>
        </ModalWindowFormContent>
        <MutableModalWindowFooter>
          <ErrorMessage>{props.status}</ErrorMessage>
          <Summary className="d-flex align-items-center justify-content-between">
            <SummaryLabel>Summary: </SummaryLabel>
            <SummaryAmount>{withCurrency(summary?.totalAmount || 0)}</SummaryAmount>
          </Summary>
          <ModalWindowButton type="submit" disabled={disableSubmitButton} isLoading={isSummaryLoading}>
            Save
          </ModalWindowButton>
        </MutableModalWindowFooter>
      </Form>
    </>
  );
};

const handleSubmit = async (values: IFormValues, formikBag: FormikBag<OuterProps, IFormValues>) => {
  try {
    formikBag.setSubmitting(true);
    const {createOrder} = formikBag.props;
    const orders = convertTestSummaryToServer(values.orders, values.coupons).orders;
    const requestModel = convertFormValuesToRequest({...values, orders});
    await createOrder(requestModel);
  } finally {
    formikBag.setSubmitting(false);
  }
};

const CreateUserOrderWithFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
})(memo(CreateUserOrder));

const mapStateToProps = (state: IAppState, {packagesDictionary, testsDictionary}: IExternalProps) => ({
  horses: selectors.selectUserOrderHorses(state),
  summary: selectors.selectUserSummary(state),
  coupon: selectors.selectUserCoupon(state),
  creatingOrder: selectors.selectCommunication(state, 'creatingUserOrder'),
  summaryLoading: selectors.selectCommunication(state, 'userSummaryLoading'),
  couponLoading: selectors.selectCommunication(state, 'userCouponLoading'),
  horsesLoading: selectors.selectCommunication(state, 'userHorsesLoading'),
  packages: packagesDictionary.selectors.selectItems(state),
  packagesLoading: packagesDictionary.selectors.selectCommunication(state, 'itemsLoading'),
  tests: testsDictionary.selectors.selectItems(state),
  testsLoading: packagesDictionary.selectors.selectCommunication(state, 'itemsLoading'),
  currentUser: userSelectors.selectCurrentUser(state),
  associationEmployees: selectors.selectAssociationEmpoyees(state),
  associationEmployeesLoading: selectors.selectCommunication(state, 'associationEmployeesLoading'),
});

const mapDispatchToProps = {
  getHorses: actions.getUserHorses,
  getSummary: actions.getUserSummary,
  getCoupon: actions.getUserCoupon,
  getAssociationEmployees: actions.getAssociationEmployees,
  createOrder: actions.createUserOrder,
  removeCoupon: actions.resetUserCoupon,
  removeSummary: actions.resetUserSummary,
  resetUserOrder: actions.resetUserOrder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(CreateUserOrderWithFormik);
const Dynamic = withDynamicModules(withRouter(Connected), AdminAssociationCreateUserOrderModule);
const Exported = (externalProps: Omit<IExternalProps, 'packagesDictionary' | 'testsDictionary'>) => {
  const {associationActivePackagesAdmin, associationActivePurchasableTestsAdmin} = useDictionaries();
  return (
    <Dynamic
      {...externalProps}
      packagesDictionary={associationActivePackagesAdmin}
      testsDictionary={associationActivePurchasableTestsAdmin}
    />
  );
};

export default Exported;
export type CreateUserOrderProps = IExternalProps;
