import React, {memo, useEffect} from 'react';
import {FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {RequiredBy} from 'Common/types';

import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {InputField} from 'Common/components/FormFields/index';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {INamedEntity} from 'Common/models/INamedEntity';
import {
  convertToServerUpdating,
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminReference/components/NamedEntityDictionaryLayout/NamedEntityDictionaryFormLayout/validation';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

type IOwnProps = IPhenotypeFormExternalProps<INamedEntity, INamedEntity>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function NamedEntityDictionaryFormLayout(props: Props) {
  const {itemId, item, dictionary, ...rest} = props;
  const {getItem} = dictionary.actions;

  useEffect(() => {
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem]);

  return (
    <DictionaryForm {...rest}>
      <InputField name="name" label="Name" placeholder="Name" autoComplete="off" />
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  return makePhenotypeFormSelectors(state, ownProps.dictionary);
};

const NamedEntityDictionaryFormLayoutFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting ? item : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(values);
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(NamedEntityDictionaryFormLayout);

const connector = connect(mapStateToProps);
export default connector(memo(NamedEntityDictionaryFormLayoutFormik));
