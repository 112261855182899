import React from 'react';
import styled from 'styled-components';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import Icon, {IconName} from 'Icon/components/Icon';

export const SectionLabel = styled.div`
  margin-bottom: 8px;
  color: ${ColorPalette.gray45};
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size15};
  line-height: 24px;
`;

export const SelectWrapper = styled.div`
  margin-top: 8px;
`;

const TooltipContainer = styled.div`
  margin-left: 12px;
  outline: none;
`;

export function getSectionHint(description: React.ReactElement | string) {
  return (
    <Tooltip content={description} placement="right-start">
      <TooltipContainer>
        <Icon name={IconName.Question} size={16} />
      </TooltipContainer>
    </Tooltip>
  );
}

export const SelectedFiltersCount = styled.div`
  font-size: ${Typography.size.size20};
  line-height: 32px;
`;

const SelectedFiltersWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  padding: 0 16px;
  color: ${Theme.color.primary};
`;

interface PanelHintProps {
  count?: number;
  hint?: React.ReactElement | string;
}

export function SectionHint({count, hint}: PanelHintProps) {
  return (
    <div className="d-flex flex-grow-1 align-items-center">
      {hint && getSectionHint(hint)}
      {count !== 0 && <SelectedFilters count={count} />}
    </div>
  );
}

function SelectedFilters({count}: {count?: number}) {
  return (
    <SelectedFiltersWrapper>
      <SelectedFiltersCount>{count}</SelectedFiltersCount>
    </SelectedFiltersWrapper>
  );
}

export const FilterText = styled.span`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${Theme.color.black};
`;

export const FilterTextBold = styled(FilterText)`
  font-weight: ${Typography.weight.semiBold600};
`;
