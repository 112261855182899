import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import ChangeOwner from 'Admin/AdminDashboard/components/Horses/ChangeOwner/ChangeOwner';

interface IChangeOwnerModalParams {
  onSuccess?(): void;
}

export function useChangeOwnerModal(params: IChangeOwnerModalParams) {
  const {onSuccess} = params;

  const [horseId, setHorseId] = useState(0);
  const [horseName, setHorseName] = useState('');
  const [currentOwnerName, setCurrentOwnerName] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const openChangeOwnerModal = useCallback((horseId: number, horseName: string, currentOwnerName: string) => {
    setHorseId(horseId);
    setHorseName(horseName);
    setCurrentOwnerName(currentOwnerName);
    setIsOpen(true);
  }, []);
  const closeChangeOwnerModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeChangeOwnerModal();
    onSuccess && onSuccess();
  }, [closeChangeOwnerModal, onSuccess]);

  const changeOwnerModal = (
    <ModalWindow isOpen={isOpen} onClose={closeChangeOwnerModal}>
      <ChangeOwner
        horseId={horseId}
        horseName={horseName}
        currentOwnerName={currentOwnerName}
        onSuccess={handleSuccess}
      />
    </ModalWindow>
  );

  return {
    changeOwnerModal,
    openChangeOwnerModal
  };
}
