import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  addFavoriteHorseActions,
  addFavoriteHorseActionTypes,
  deleteFavoriteHorseActions,
  deleteFavoriteHorseActionTypes,
  getFavoriteHorsesActions,
  getFavoriteHorsesActionTypes,
  IFavoriteHorseState,
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IRequestParams} from 'Common/models/IRequestParams';
import FavoriteHorseService from 'FavoriteHorse/services/FavoriteHorseService';

export const getFavoriteHorses =
  (params: IRequestParams): ActionResult<IFavoriteHorseState, void, getFavoriteHorsesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getFavoriteHorsesActionTypes.REQUEST));
      const horseList = await FavoriteHorseService.getFavoriteHorses(params);
      dispatch(action(getFavoriteHorsesActionTypes.SUCCESS, horseList));
    } catch (error) {
      dispatch(action(getFavoriteHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const addFavoriteHorse =
  (horseId: number): ActionResult<IFavoriteHorseState, void, addFavoriteHorseActions> =>
  async (dispatch) => {
    try {
      dispatch(action(addFavoriteHorseActionTypes.REQUEST));
      await FavoriteHorseService.addFavoriteHorse(horseId);
      dispatch(action(addFavoriteHorseActionTypes.SUCCESS, horseId));
    } catch (error) {
      dispatch(action(addFavoriteHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteFavoriteHorse =
  (horseId: number): ActionResult<IFavoriteHorseState, void, deleteFavoriteHorseActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteFavoriteHorseActionTypes.REQUEST));
      await FavoriteHorseService.deleteFavoriteHorse(horseId);
      dispatch(action(deleteFavoriteHorseActionTypes.SUCCESS, horseId));
    } catch (error) {
      dispatch(action(deleteFavoriteHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
