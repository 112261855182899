import React, {useCallback} from 'react';
import styled from 'styled-components';

import {OrderStatusDropdown} from 'Admin/shared/components/OrderStatus/index';
import ColorPalette from 'Common/constants/ColorPalette';
import withDate from 'Common/helpers/withDate';
import OrderActions, {OrderActionsProps} from 'Admin/AdminDashboard/components/Orders/OrderActions';
import {IOrderHistory} from 'Admin/AdminDashboard/components/shared/OrderHistory/parts/common';
import {OrderStatus} from 'Common/constants/OrderStatus';

const OrderRoot = styled.div`
  padding: 16px 8px 16px 0;
  box-shadow: 0px 1px 0px ${ColorPalette.white9};
`;

const Gray = styled.span`
  color: ${ColorPalette.gray32};
`;

interface IProps {
  order: IOrderHistory;
  onSuccess(): void;
}

type AllProps = IProps & Omit<OrderActionsProps, 'order'>;

const OrderInfo = (props: AllProps) => {
  const {
    order: {id, horses, createDate},
    onSuccess,
    ...rest
  } = props;

  const renderOrderStatusDropdown = useCallback(() => {
    if (horses.length === 1) {
      return (
        <div className="w-50">
          <OrderStatusDropdown
            status={horses[0].status}
            orderId={id}
            horseId={horses[0].id!}
            onChange={onSuccess}
            completedStatuses={horses[0].hasSample ? OrderStatus.sampleReceived : undefined}
            uncompletedStatuses={horses[0].hasNotResults ? OrderStatus.resultsReady : undefined}
          />
        </div>
      );
    }

    return <Gray>Several</Gray>;
  }, [id, onSuccess, horses]);

  return (
    <OrderRoot className="d-flex align-items-center">
      <div className="w-25">{`${withDate(createDate)}`}</div>
      {renderOrderStatusDropdown()}
      <div className="w-25 ml-auto">
        <OrderActions order={props.order} {...rest} />
      </div>
    </OrderRoot>
  );
};

export default OrderInfo;
