import React, {memo, useState, useCallback} from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

import {IconName} from 'Icon/components/Icon';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon from 'Icon/components/ColoredIcon';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import Theme from 'Common/constants/Theme';
import UnreportedVariantsModal from './UnreportedVariantsModal';
import {UnreportedParentsIssues} from 'FoalProfile/models/IFoalParentVariantIssue';
import {ReactComponent as EmptyState} from 'FoalProfile/components/img/empty-state.svg';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import {breakpoints} from 'Common/constants/Breakpoints';
import {IProbabilityEntity} from 'Common/models/IProbabilityEntity';

const NEED_ADDITIONAL_TESTS =
  'One or more of these horses has limited genetic testing results preventing a complete review of all possible health issues.';
const NEED_ADDITIONAL_TESTS_2 = `Incomplete tests should always be assumed to be <b>Positive</b> for safety's sake until tests are complete.`;

const Root = styled.div`
  width: auto;
  height: 100%;
  background: ${ColorPalette.white0};
  margin: 0 -16px;

  @media ${breakpoints.sm} {
    width: 650px;
    box-shadow: 0px 6px 20px ${hexToRgba(ColorPalette.black0, 0.12)};
    border-radius: 8px;
  }
`;

const Content = styled.div`
  flex-direction: column;
  @media ${breakpoints.sm} {
    flex-direction: row;
  }
`;

const NeedAdditionalTestsMessage = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 32px;
  color: ${ColorPalette.black1};
  margin-bottom: 16px;
`;

const ExclamationMarkWrapper = styled.div`
  min-width: 32px;
  width: 32px;
  height: 32px;
  background: ${ColorPalette.gray49};
  border-radius: 4px;
  margin: 32px;

  @media ${breakpoints.sm} {
    margin: 8px;
  }
`;

const EmptyStateImageWrapper = styled.div`
  margin-top: 32px;
  @media ${breakpoints.sm} {
    margin-top: 0;
    margin-left: 32px;
  }
`;

const EmptyStateImage = styled(EmptyState)`
  width: 180px;
  height: 180px;
  @media ${breakpoints.sm} {
    width: 160px;
    height: 160px;
  }
`;

const TextPanel = styled.div`
  margin: 16px 32px 32px 32px;
  @media ${breakpoints.sm} {
    margin: 24px 52px;
  }
`;

const ShowIssuesButton = styled(PrimaryButton)`
  width: 100%;
`;

export type ParentProp = {id: number; name: string; isOwnHorse: boolean};

interface IProps {
  unreportedIssues: UnreportedParentsIssues;
  unreportedProbabilities: IProbabilityEntity[];
  firstParentProp: ParentProp;
  secondParentProp: ParentProp;
}

function UnreportedVariants(props: IProps) {
  const {firstParentProp, secondParentProp, unreportedIssues, unreportedProbabilities} = props;
  const [isOpenIssuesModal, setIsOpenIssuesModal] = useState(false);
  const openIssuesModal = useCallback(() => setIsOpenIssuesModal(true), []);
  const closeIssuesModal = useCallback(() => setIsOpenIssuesModal(false), []);

  const needText2 = parse(DOMPurify.sanitize(NEED_ADDITIONAL_TESTS_2));

  return (
    <Root className="position-relative">
      <ModalWindow isOpen={isOpenIssuesModal} onClose={closeIssuesModal} maxWidth="774px">
        <UnreportedVariantsModal
          firstParentProp={firstParentProp}
          secondParentProp={secondParentProp}
          unreportedIssues={unreportedIssues}
          unreportedProbabilities={unreportedProbabilities}
        />
      </ModalWindow>

      <ExclamationMarkWrapper className="position-absolute d-flex justify-content-center align-items-center">
        <ColoredIcon
          name={IconName.ExclamationMark}
          height={20}
          width={7}
          color={ColorPalette.red7}
          fill={true}
          stroke={false}
        />
      </ExclamationMarkWrapper>

      <Content className="d-flex">
        <EmptyStateImageWrapper className="d-flex align-self-center justify-content-center align-items-center">
          <EmptyStateImage />
        </EmptyStateImageWrapper>

        <TextPanel className="d-flex flex-column flex-grow-1">
          <NeedAdditionalTestsMessage>
            <div>{NEED_ADDITIONAL_TESTS}</div>
            <div>{needText2}</div>
          </NeedAdditionalTestsMessage>

          <ShowIssuesButton onClick={openIssuesModal}>Show Possible Calculations</ShowIssuesButton>
        </TextPanel>
      </Content>
    </Root>
  );
}

export default memo(UnreportedVariants);
