import {IModule} from 'redux-dynamic-modules';

import {IHorseFiltersState} from './types';
import {reducer} from './reducer';

export interface IFiltersModuleState {
  horseFilters: IHorseFiltersState;
}

export const HorseFiltersModule: IModule<IFiltersModuleState> = {
  id: 'horseFilters',
  reducerMap: {
    horseFilters: reducer
  }
};
