import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import EditableSection from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {actions, selectors} from 'BusinessPortal/store/associationPage';
import GeneralInformationShow from './GeneralInformationShow';
import GeneralInformationEdit from './GeneralInformationEdit';

const Root = styled.div`
  max-width: 576px;
  margin-bottom: 46px;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const GeneralInformation = (props: AllProps) => {
  const {generalInformation, generalInformationLoading, getGeneralInformation} = props;

  const mainRequest = useCallback(() => getGeneralInformation(), [getGeneralInformation]);

  useEffect(() => {
    mainRequest();
  }, [mainRequest]);

  const onSuccessEdit = useCallback(() => mainRequest(), [mainRequest]);

  const isRequesting = generalInformationLoading.isRequesting;

  return (
    <Root className="position-relative">
      {isRequesting && <Loading />}
      <EditableSection
        title="General info"
        data={{generalInformation}}
        onSuccess={onSuccessEdit}
        editComponent={GeneralInformationEdit}
        showComponent={GeneralInformationShow}
      />
    </Root>
  );
};

const mapStateToProps = (state: IAppState) => ({
  generalInformation: selectors.selectGeneralInformation(state),
  generalInformationLoading: selectors.selectCommunication(state, 'generalInformationLoading'),
});

const mapDispatchToProps = {
  getGeneralInformation: actions.getGeneralInformation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(GeneralInformation));
