import * as React from 'react';
import {default as Icon, IProps as IPropsExternal} from 'Icon/components/Icon';
import styled from 'styled-components';
import withoutProps from 'Common/helpers/WithoutProps';
import Theme from 'Common/constants/Theme';

export interface IColoredIconProps extends IPropsExternal {
  color?: string;
  fill?: boolean;
  stroke?: boolean;
  hoverColor?: string;
}

const ColoredIconCore = styled(withoutProps(['color', 'fill', 'hoverColor', 'stroke'], true)(Icon))<IColoredIconProps>`
  & * {
    stroke: ${({color, stroke}) => stroke && color} !important;
    fill: ${({color, fill}) => fill && color} !important;
  }

  &:hover * {
    stroke: ${({hoverColor, stroke}) => stroke && hoverColor} !important;
    fill: ${({hoverColor, fill}) => fill && hoverColor} !important;
  }
`;

const ColoredIcon = React.forwardRef((props: IColoredIconProps, ref) => {
  const {color, fill, hoverColor} = props;

  return <ColoredIconCore {...props} color={color} fill={fill} hoverColor={hoverColor} ref={ref} />;
});

ColoredIcon.defaultProps = {
  stroke: true,
  color: Theme.color.black
} as Partial<IColoredIconProps>;

export default React.memo(ColoredIcon);
