import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import UploadPaymentDetailsForm from 'Admin/AdminDashboard/components/shared/UploadPaymentDetailsForm/UploadPaymentDetailsForm';
import {Nullable} from 'Common/types';
import UpdatePaymentForm from 'Admin/AdminAssociations/components/Orders/UpdatePaymentForm/UpdatePaymentForm';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import useDistributorStrategy from 'Common/helpers/strategy/useDistributorStrategy';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';

interface IUpdatePaymentModalParams {
  onSuccess?(): void;
  orderId?: Nullable<number>;
}

export function useUpdatePaymentModal(params: IUpdatePaymentModalParams) {
  const {onSuccess} = params;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<Nullable<number>>();

  const {currentStrategy} = useDistributorStrategy();
  const {emit} = useEventBus();

  const orderId = params.orderId || selectedId;

  const openUpdatePaymentModal = useCallback((orderId?: number) => {
    setIsOpen(true);
    setSelectedId(orderId);
  }, []);
  const closeUpdatePaymentModal = useCallback(() => {
    setIsOpen(false);
    setSelectedId(null);
  }, []);

  const handleSuccess = useCallback(() => {
    closeUpdatePaymentModal();
    onSuccess && onSuccess();
    emit(EventName.UpdatePayment);
  }, [closeUpdatePaymentModal, emit, onSuccess]);

  const renderUpdatePayment = useCallback(() => {
    if (!orderId) {
      return;
    }

    if (currentStrategy === SelectedStrategy.Admin) {
      return <UploadPaymentDetailsForm orderId={orderId} onSuccess={handleSuccess} />;
    }

    if (currentStrategy === SelectedStrategy.AdminAssociation) {
      return <UpdatePaymentForm orderId={orderId} onSuccess={handleSuccess} />;
    }

    return '';
  }, [handleSuccess, orderId, currentStrategy]);

  const updatePaymentModal = (
    <ModalWindow isOpen={isOpen} onClose={closeUpdatePaymentModal}>
      {renderUpdatePayment()}
    </ModalWindow>
  );

  return {
    updatePaymentModal,
    openUpdatePaymentModal,
  };
}
