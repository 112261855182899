import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';

import {Nullable} from 'Common/types';
import {IPaymentHorse} from 'BusinessPortal/models/order/payment/IPaymentHorse';
import {IOrderSummary} from 'BusinessPortal/models/order/createOrder/IOrderSummary';

const STATE_NAME = 'businessPortalOrderPayment';

export interface IBusinessPortalOrderPaymentState {
  data: {
    orderPaymentToken: Nullable<string>;
    paymentHorses: IPaymentHorse[];
    paymentSummary: Nullable<IOrderSummary>;
  };
  communications: {
    orderPaymentTokenLoading: ICommunication;
    paymentHorsesLoading: ICommunication;
    paymentSummaryLoading: ICommunication;
  };
}

export const getPaymentHorsesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_PAYMENT_HORSES');
export type getPaymentHorsesActions = MakeCommunicationActions<
  typeof getPaymentHorsesActionTypes,
  {success: IPaymentHorse[]}
>;

export const getPaymentSummaryActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_PAYMENT_SUMMARY');
export type getPaymentSummaryActions = MakeCommunicationActions<
  typeof getPaymentSummaryActionTypes,
  {success: IOrderSummary}
>;

export const getOrderPaymentTokenActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_PAYMENT_TOKEN');
export type getOrderPaymentTokenActions = MakeCommunicationActions<
  typeof getOrderPaymentTokenActionTypes,
  {success: string}
>;

export type Actions = getPaymentHorsesActions | getPaymentSummaryActions | getOrderPaymentTokenActions;
