import React, {memo, useCallback} from 'react';
import styled from 'styled-components';
import Theme from 'Common/constants/Theme';
import {IPackageSimple, PackageStatus} from 'Dictionaries/models/IPackageSimple';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import ColorPalette from 'Common/constants/ColorPalette';

const Packages = styled.div`
  padding: 20px 0;
`;

const PackageItem = styled.div<{isDisabled: boolean}>`
  line-height: 34px;
  color: ${(props) => (props.isDisabled ? Theme.color.control.disabled : Theme.color.black)};
`;

const Logo = styled(ColoredIcon)`
  margin-right: 8px;
`;

interface IProps {
  userPackages?: IPackageSimple[];
  associationPackages?: IPackageSimple[];
  associationPurchasableTests?: IPackageSimple[];
}

const PackageSection = (props: IProps) => {
  const {associationPurchasableTests, associationPackages, userPackages} = props;

  const renderItem = useCallback((item: IPackageSimple, index: number, iconName: IconName) => {
    const isDisabled = item.status !== PackageStatus.Active;
    const logoColor = !isDisabled ? ColorPalette.gray56 : ColorPalette.gray44;
    return (
      <PackageItem key={index} isDisabled={isDisabled} className="d-flex">
        <div>
          <Logo name={iconName} color={logoColor} stroke={false} fill={true} />
        </div>
        {item.name}
      </PackageItem>
    );
  }, []);

  return (
    <Packages>
      {userPackages?.map((item, index) => renderItem(item, index, IconName.Person))}
      {associationPackages?.map((item, index) => renderItem(item, index, IconName.Business))}
      {associationPurchasableTests?.map((item, index) => renderItem(item, index, IconName.Notebook3))}
    </Packages>
  );
};

export default memo(PackageSection);
