import {IAppState} from 'Common/store/IAppState';
import {ICommunication} from 'Common/store/utils/communication';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {actions as userActions, selectors as userSelectors} from 'OnlineReport/store/index';
import {actions as adminActions, selectors as adminSelectors} from 'Admin/AdminDashboard/store/adminOnlineReport/index';
import {Nullable} from 'Common/types';
import {OnlineReportType} from '../shared/OnlineReportType';
import {IOnlineReportHealthVariants} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariants';

export interface IState {
  healthVariants: Nullable<IOnlineReportHealthVariants[]>;
  healthVariantsLoading: ICommunication;
}

export interface IDispatch {
  getHealthVariants(horseId: number, orderId?: number): void;
}

const userDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    healthVariants: userSelectors.selectHealthVariants(state),
    healthVariantsLoading: userSelectors.selectCommunication(state, 'healthVariantsLoading'),
  }),
  dispatch: {
    getHealthVariants: userActions.getHealthVariants,
  },
};

const adminDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    healthVariants: adminSelectors.selectHealthVariants(state),
    healthVariantsLoading: adminSelectors.selectCommunication(state, 'healthVariantsLoading'),
  }),
  dispatch: {
    getHealthVariants: adminActions.getHealthVariants,
  },
};

export const healthVariantsDistributor: Record<OnlineReportType, IDistributor<IState, IDispatch>> = {
  [OnlineReportType.User]: userDistributor,
  [OnlineReportType.Association]: userDistributor,
  [OnlineReportType.Admin]: adminDistributor,
  [OnlineReportType.AdminAssociation]: adminDistributor,
  [OnlineReportType.ReviewAdmin]: adminDistributor,
  [OnlineReportType.ReviewAdminAssociation]: adminDistributor,
};
