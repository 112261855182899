import {action} from 'typesafe-actions';
import {IAppState} from 'Common/store/IAppState';

import {ActionResult} from 'Common/store/store';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IImage} from 'Image/models/IImage';
import {AdminGeneGroupImageActions, deleteImageActionTypes, getImageActionTypes, uploadImageActionTypes} from './types';
import GeneGroupImageDataService from 'Admin/AdminPhenotypes/service/GeneGroupImageDataService';

export const getGeneGroupImage = (
  imageId: number
): ActionResult<IAppState, Promise<IImage>, AdminGeneGroupImageActions> => async dispatch => {
  dispatch(action(getImageActionTypes.REQUEST));
  try {
    const image = await GeneGroupImageDataService.getGeneGroupImage(imageId);
    dispatch(action(getImageActionTypes.SUCCESS, image.data));
    return image.data;
  } catch (err) {
    const error = getErrorMessage(err);
    dispatch(action(getImageActionTypes.FAILURE, error));
    throw error;
  }
};

export const uploadGeneGroupImage = (
  uploadedFile: FormData
): ActionResult<IAppState, Promise<number>, AdminGeneGroupImageActions> => async dispatch => {
  dispatch(action(uploadImageActionTypes.REQUEST));
  try {
    const res = await GeneGroupImageDataService.uploadGeneGroupImage(uploadedFile);
    dispatch(action(uploadImageActionTypes.SUCCESS, res));
    return res;
  } catch (error) {
    const err = getErrorMessage(error);
    dispatch(action(uploadImageActionTypes.FAILURE, err));
    throw err;
  }
};

export const deleteGeneGroupImage = (
  imageId: number
): ActionResult<IAppState, void, AdminGeneGroupImageActions> => async dispatch => {
  dispatch(action(deleteImageActionTypes.REQUEST));
  try {
    await GeneGroupImageDataService.deleteGeneGroupImage(imageId);
    dispatch(action(deleteImageActionTypes.SUCCESS, {imageId}));
  } catch (err) {
    const error = getErrorMessage(err);
    dispatch(action(deleteImageActionTypes.FAILURE, error));
    throw error;
  }
};
