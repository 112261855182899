import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.buildFoal;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectFoal(state: IAppState) {
  return selectState(state).data.foal;
}

export function selectFirstParent(state: IAppState) {
  return selectState(state).data.firstParent;
}

export function selectSecondParent(state: IAppState) {
  return selectState(state).data.secondParent;
}
