import React from 'react';
import styled from 'styled-components';

import Indicator from 'Common/components/Indicator/Indicator';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {IIndicator} from 'Common/models/IIndicator';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {mapAbilityValues} from 'Common/helpers/abilities';
import {breakpoints} from 'Common/constants/Breakpoints';
import {DNAIndicatorParameter} from './types';
import Tooltip from 'Common/components/Tooltip/Tooltip';

const baseIndicator: Omit<IIndicator, 'segments'> = {
  height: 8,
  segmentColor: ColorPalette.red1,
};

enum TestRequiredSize {
  Small,
  Medium,
  Large,
}

const lineHeightBySize: Record<TestRequiredSize, number> = {
  [TestRequiredSize.Small]: 20,
  [TestRequiredSize.Medium]: 40,
  [TestRequiredSize.Large]: 65,
};

const ValueIndicator = styled.div`
  height: 25px;
  align-items: center;
  margin-bottom: 8px;

  @media ${breakpoints.sm} {
    margin-bottom: 0;
  }
`;

const TextIndicator = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.green2};
  text-align: right;

  @media ${breakpoints.sm} {
    text-align: left;
  }
`;

const TestRequired = styled.div<{size: TestRequiredSize}>`
  margin-top: 3px;
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: ${({size}) => `${lineHeightBySize[size]}px`};
  text-align: right;
  text-transform: uppercase;
  color: ${ColorPalette.gray44};
  justify-content: flex-end;

  @media ${breakpoints.sm} {
    justify-content: center;
  }
`;

interface IProps {
  tests: DNAIndicatorParameter[];
}

const DnaIndicators = (props: IProps) => {
  const {tests} = props;
  const {isDesktop} = useMediaQuery();

  return (
    <>
      {tests.map((item, i) => {
        if (item.isExist) {
          if (isDesktop) {
            return (
              <ValueIndicator className="d-flex w-100" key={i}>
                <Indicator
                  indicator={{
                    ...baseIndicator,
                    value: mapAbilityValues[item.value],
                    segmentColor: item.color,
                    segmentsMaxCount: 3,
                  }}
                  tooltip={item.description}
                />
              </ValueIndicator>
            );
          }

          return (
            <ValueIndicator className="d-flex w-100" key={i}>
              <Tooltip content={item.description || ''}>
                <TextIndicator className="w-100">{item.name}</TextIndicator>
              </Tooltip>
            </ValueIndicator>
          );
        }

        const messageSize = (() => {
          if (item.size > 2) {
            return TestRequiredSize.Large;
          }
          return item.size > 1 ? TestRequiredSize.Medium : TestRequiredSize.Small;
        })();

        return (
          <TestRequired className="d-flex" key={i} size={messageSize}>
            DNA TEST REQUIRED
          </TestRequired>
        );
      })}
    </>
  );
};

export default DnaIndicators;
