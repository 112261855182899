import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import styled from 'styled-components';

import {UserProfileRouteProps} from 'Shared/components/UserProfile/UserProfile';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import {IAppState} from 'Common/store/IAppState';
import {actions as currentUserActions, selectors as currentUserSelectors} from 'UserProfile/store/currentUser';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';
import Loading from 'Loading/components/Loading';
import ProfileInfo from 'Shared/components/UserProfile/parts/ProfileInfo/ProfileInfo';
import PrivacySettings from './parts/PrivacySettings/PrivacySettings';
import Tabs from 'Common/components/Tabs/Tabs';
import TabsContent from 'Common/components/Tabs/TabsContent';
import {ITab} from 'Common/components/Tabs/ITab';
import UserSubscriptions from './parts/UserSubscriptions/UserSubscriptions';
import ChangePassword from './parts/Change password/ChangePassword';
import PersonalDetails from './parts/PersonalDetails/PersonalDetails';

const tabs: ITab[] = [
  {section: 'personal-details', title: 'Profile', Component: PersonalDetails},
  {section: 'privacy-settings', title: 'Visibility', Component: PrivacySettings},
  {section: 'subscriptions', title: 'Subscriptions', Component: UserSubscriptions},
  {section: 'change-password', title: 'Change Password', Component: ChangePassword},
];

const Root = styled.div`
  flex-direction: column;
  flex-grow: 1;
  background-color: ${ColorPalette.gray49};

  > :first-child {
    margin-bottom: 16px;
  }

  @media ${breakpoints.sm} {
    > :first-child {
      margin-bottom: 18px;
    }
  }

  @media ${breakpoints.lg} {
    flex-direction: row;
    > :first-child {
      margin-bottom: 0;
      margin-right: 32px;
    }
  }
`;

const ProfileInfoSection = styled.div`
  width: 100%;
  padding: 32px;
  background-color: ${ColorPalette.white0};

  @media ${breakpoints.lg} {
    width: 350px;
  }
`;

const ContentSection = styled.div`
  background-color: ${ColorPalette.white0};
`;

const Content = styled.div`
  padding: 40px 24px;
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: ${ColorPalette.red1};
`;

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & RouteComponentProps & UserProfileRouteProps;

function ProfileSettingsLayout(props: Props) {
  const {
    currentUser,
    getCurrentUser,
    currentUserLoading,
    match: {path},
  } = props;

  useEffect(() => {
    getCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = currentUserLoading.isRequesting;

  if (!currentUser) {
    return <ErrorMessage>Error on loading user data</ErrorMessage>;
  }

  return (
    <BaseLayout withoutPaddings={true} backButtonStyle={{marginTop: 20}}>
      <Helmet>
        <title>Profile settings</title>
      </Helmet>

      {isLoading && <Loading />}

      <Root className="d-flex flex-grow-1 ">
        <ProfileInfoSection className="align-items-stretch flex-shrink-0">
          <ProfileInfo user={currentUser} isCurrentUser={true} />
        </ProfileInfoSection>
        <ContentSection className="flex-grow-1 flex-shrink-1 d-flex flex-column">
          <Tabs tabs={tabs} />
          <Content className="h-100">
            <TabsContent basePath={path} tabs={tabs} />
          </Content>
        </ContentSection>
      </Root>
    </BaseLayout>
  );
}

const mapStateToProps = (state: IAppState) => ({
  currentUser: currentUserSelectors.selectCurrentUser(state),
  currentUserLoading: currentUserSelectors.selectCommunication(state, 'userLoading'),
});

const mapDispatchToProps = {
  getCurrentUser: currentUserActions.getCurrentUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(withRouter(memo(ProfileSettingsLayout)));
export default Connected;
