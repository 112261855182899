import React, {useCallback, memo} from 'react';
import styled from 'styled-components';

import {ModalWindowFormContent, ModalWindowHeader} from 'Common/components/Modal/shared';
import {IPackage} from 'Dictionaries/models/IPackage';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {ICommunication} from 'Common/store/utils/communication/types';
import Loading from 'Loading/components/Loading';
import {CellSeveral, ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {INamedEntity} from 'Common/models/INamedEntity';
import withDate from 'Common/helpers/withDate';
import {FRONTEND_DATE} from 'Common/constants/Date';

const NO_DATA = <CellSeveral>No data</CellSeveral>;

const Container = styled.div`
  margin: 12px 40px 12px 0px;
`;

const FieldName = styled.div`
  color: ${ColorPalette.gray36};
  font-size: ${Typography.size.size18};
  font-family: ${Typography.family.openSans};
  margin: 0px 5px 5px 0px;
`;

const FieldValue = styled.div`
  color: ${ColorPalette.black1};
  font-size: ${Typography.size.size18};
  font-family: ${Typography.family.openSans};
`;

interface IExternalProps {
  packageInfo: IPackage | null;
  externalCommunication: ICommunication;
}

const PackageInfo = (props: IExternalProps) => {
  const {packageInfo, externalCommunication} = props;

  const errorInfo = externalCommunication.error;
  const isRequesting = externalCommunication.isRequesting;

  const checkByNullFunc = useCallback((data) => data ?? NO_DATA, []);

  return (
    <>
      <ModalWindowHeader style={{marginBottom: '-15px'}}>Package info</ModalWindowHeader>
      <ModalWindowFormContent>
        {isRequesting && <Loading />}

        <div className="d-flex flex-row">
          <Container className="d-flex flex-row w-50" style={{marginBottom: '-5px'}}>
            <FieldName>ID:</FieldName>
            <FieldValue>{checkByNullFunc(packageInfo?.id)}</FieldValue>
          </Container>

          <Container className="d-flex flex-row w-50" style={{marginBottom: '-5px'}}>
            <FieldName>Status:</FieldName>
            <FieldValue>
              {packageInfo?.isArchived ? 'Archive' : packageInfo?.isEnabled ? 'Active' : 'Inactive'}
            </FieldValue>
          </Container>
        </div>

        <div className="d-flex flex-row">
          <Container className="d-flex flex-column w-50">
            <FieldName>Name:</FieldName>
            <FieldValue>{checkByNullFunc(packageInfo?.name)}</FieldValue>
          </Container>

          <Container className="d-flex flex-column w-50">
            <FieldName>Abbreviation:</FieldName>
            <FieldValue>{checkByNullFunc(packageInfo?.abbreviation)}</FieldValue>
          </Container>
        </div>

        {packageInfo?.isArchived && packageInfo?.statusChangeDate && (
          <Container className="d-flex flex-column w-50">
            <FieldName>Valid until:</FieldName>
            <FieldValue>{withDate(packageInfo?.statusChangeDate, FRONTEND_DATE)}</FieldValue>
          </Container>
        )}

        <Container className="d-flex flex-column">
          <FieldName>Tests:</FieldName>
          <FieldValue>
            {packageInfo?.tests?.length !== 0
              ? packageInfo?.tests.map((item: INamedEntity) => item.name).join(', ')
              : NO_DATA}
          </FieldValue>
        </Container>

        <Container className="d-flex flex-column">
          <FieldName>Report types:</FieldName>
          <FieldValue>
            {packageInfo?.reportTypes?.length !== 0
              ? packageInfo?.reportTypes.map((item: INamedEntity) => item.name).join(', ')
              : NO_DATA}
          </FieldValue>
        </Container>

        <Container className="d-flex flex-column">
          <FieldName>Assocation:</FieldName>
          <FieldValue>{checkByNullFunc(packageInfo?.association?.name)}</FieldValue>
        </Container>

        <Container className="d-flex flex-column">
          <FieldName>Description:</FieldName>
          <FieldValue>{checkByNullFunc(packageInfo?.description)}</FieldValue>
        </Container>

        <Container className="d-flex flex-column">
          <FieldName>Price:</FieldName>
          <FieldValue>
            <b>{packageInfo?.price ? `$${packageInfo.price}` : NO_DATA}</b>
          </FieldValue>
        </Container>

        <Container className="d-flex flex-column">
          <FieldName>Package group:</FieldName>
          <FieldValue>
            {packageInfo?.groups?.length !== 0
              ? packageInfo?.groups.map((item: INamedEntity) => item.name).join(', ')
              : NO_DATA}
          </FieldValue>
        </Container>

        <Container className="d-flex flex-column">
          <FieldName>Included to 'Bundles':</FieldName>
          <FieldValue>
            <b>{packageInfo?.isBundle ? `Yes` : 'No'}</b>
          </FieldValue>
        </Container>

        <ErrorMessage>{getCommonErrors(errorInfo)}</ErrorMessage>
      </ModalWindowFormContent>
    </>
  );
};

export default memo(PackageInfo);
