import LocalStorageService from 'Common/services/LocalStorageService';
import {SelectedStrategy} from './SelectedStrategy';

const SELECTED_STRATEGY_KEY = 'distributorStrategy';

type Storage = typeof LocalStorageService;

class StrategyService {
  constructor(private storage: Storage) {
    this.setStrategy(SelectedStrategy.Admin);
  }

  public setStrategy(strategy: SelectedStrategy) {
    this.storage.setItem(SELECTED_STRATEGY_KEY, strategy);
  }

  public getStrategy(): SelectedStrategy {
    return this.storage.getItem(SELECTED_STRATEGY_KEY) as SelectedStrategy;
  }

  public removeStrategy() {
    this.storage.removeItem(SELECTED_STRATEGY_KEY);
  }
}

export default new StrategyService(LocalStorageService);
