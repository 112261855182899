import React from 'react';
import styled from 'styled-components';

import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import Couponcard from 'Coupon/components/Couponcard';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';

import Theme from 'Common/constants/Theme';
import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';
import OrderHorse from 'BusinessPortal/components/BusinessPortalDashboard/Orders/CreateOrder/common/OrderHorse';

const CouponCardContainer = styled.div`
  margin: 24px 0;
`;

const CouponHorsesContainer = styled.div``;

const Text = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 18px;
  letter-spacing: 0.2px;
  color: ${ColorPalette.black0};
`;

const EditListButton = styled(PrimaryButton)`
  width: 168px;
`;

interface IProps {
  coupons: IOrderCoupon[];
  horses: IOrderHorse[];
  isLoading?: boolean;
  onDeleteCoupon(couponId: number): void;
  onEditHorsesList(coupon?: IOrderCoupon): void;
}

function DiscountShow(props: IProps) {
  const {coupons, horses, onEditHorsesList, onDeleteCoupon} = props;

  return (
    <div className="d-flex flex-column">
      {coupons?.map((coupon, i) => (
        <React.Fragment key={i}>
          <CouponCardContainer>
            <Couponcard
              onClose={() => onDeleteCoupon(coupon.id)}
              amount={coupon.amount}
              discountType={coupon.type}
              expiryDate={coupon.validUntil}
              usesLeft={coupon.usesLeft}
              packages={coupon.packages.map((i) => i.name).concat(coupon.tests.map((i) => i.name))}
            />
          </CouponCardContainer>

          <CouponHorsesContainer className="d-flex flex-column">
            <Text>Applied to:</Text>
            {coupon.horses?.map((horseId, j) => {
              const horse = horses.find((i) => i.id === horseId);
              return <OrderHorse key={j} horse={horse} />;
            })}
            <EditListButton variant="outlined" size="small" onClick={() => onEditHorsesList(coupon)}>
              Edit list
            </EditListButton>
          </CouponHorsesContainer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default DiscountShow;
export type DiscountShowProps = IProps;
