import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import {
  BusinessPortalOrderActions,
  deleteOrderActionTypes,
  downloadOrderGroupSubmissionFormActionTypes,
  downloadOrderSubmissionFormActionTypes,
  getOrderPackagesDetailsActionTypes,
  getOrderDetailsActionTypes,
  getOrderReportsActionTypes,
  getOrdersActionTypes,
  getOrderStatusesActionTypes,
  IBusinessPortalOrderState,
  resetOrderDetailsActionTypes,
} from '../types';
import composeReducers from 'Common/store/composeReducers';

export const communicationBusinessPortalOrderReducer = combineReducers<
  IBusinessPortalOrderState['communications'],
  BusinessPortalOrderActions
>({
  ordersLoading: makeCommunicationReducerFromEnum(getOrdersActionTypes),
  orderDetailsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getOrderDetailsActionTypes),
    makeResetCommunicationReducer(resetOrderDetailsActionTypes.RESET),
  ]),
  orderPackagesDetailsLoading: makeCommunicationReducerFromEnum(getOrderPackagesDetailsActionTypes),
  orderReportsLoading: makeCommunicationReducerFromEnum(getOrderReportsActionTypes),
  orderSubmissionFormRequesting: makeCommunicationReducerFromEnum(downloadOrderSubmissionFormActionTypes),
  orderGroupSubmissionFormRequesting: makeCommunicationReducerFromEnum(downloadOrderGroupSubmissionFormActionTypes),
  orderStatusesLoading: makeCommunicationReducerFromEnum(getOrderStatusesActionTypes),
  orderDeleting: makeCommunicationReducerFromEnum(deleteOrderActionTypes),
});
