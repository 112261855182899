import React from 'react';

import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import {SegmentPosition} from 'Common/constants/SegmentPosition';

const WIDTH = 85;
const HEIGHT = 8;

interface ISectionProps {
  color: string;
  fill: boolean;
  width?: number;
  height?: number;
}

const FilledSegmentProps: ISectionProps = {
  color: ColorPalette.green0,
  fill: true,
  width: WIDTH,
  height: HEIGHT
};

const EmptyDarkSegmentProps: ISectionProps = {
  color: ColorPalette.black3,
  fill: true,
  width: WIDTH,
  height: HEIGHT
};

const EmptyLightSegmentProps: ISectionProps = {
  color: ColorPalette.gray10,
  fill: true,
  width: WIDTH,
  height: HEIGHT
};

export enum SegmentFilling {
  Filled,
  EmptyDark,
  EmptyLight
}

export interface IAbilitySegmentProps {
  segmentPosition: SegmentPosition;
  segmentFilled: SegmentFilling;
  style?: React.CSSProperties;
  additionalSectionProps?: Partial<ISectionProps>;
  isTablet?: boolean;
}

const AbilitySegment = (props: IAbilitySegmentProps) => {
  const {isTablet} = props;

  let iconName = isTablet ? IconName.LeftTabletSection : IconName.LeftSection;
  switch (props.segmentPosition) {
    case SegmentPosition.Left:
      iconName = isTablet ? IconName.LeftTabletSection : IconName.LeftSection;
      break;
    case SegmentPosition.Center:
      iconName = isTablet ? IconName.CenterTabletSection : IconName.CenterSection;
      break;
    case SegmentPosition.Right:
      iconName = isTablet ? IconName.RightTabletSection : IconName.RightSection;
      break;
  }

  let segmentProps = FilledSegmentProps;
  switch (props.segmentFilled) {
    case SegmentFilling.Filled:
      segmentProps = FilledSegmentProps;
      break;
    case SegmentFilling.EmptyDark:
      segmentProps = EmptyDarkSegmentProps;
      break;
    case SegmentFilling.EmptyLight:
      segmentProps = EmptyLightSegmentProps;
      break;
  }

  return <ColoredIcon name={iconName} {...segmentProps} {...props.additionalSectionProps} style={props.style} />;
};

export default AbilitySegment;
