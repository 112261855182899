import React, {ReactElement, useCallback, memo, useState, ReactNode, useEffect} from 'react';
import {useFormikContext} from 'formik';
import styled from 'styled-components';

import {Checkbox} from 'Common/components/Controls';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';
import Theme from 'Common/constants/Theme';

const Root = styled.div`
  padding-bottom: 8px;
`;

const ParentWrapper = styled.div`
  padding: 8px 8px;
  /* Compensating for margin-bottom on CheckboxField's label */
  padding-top: 13px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${ColorPalette.gray2};
`;

const Label = styled.span`
  font-weight: ${Typography.weight.semiBold600};
`;

const ChildrenWrapper = styled.div`
  padding-left: 8px;
`;

const CollapseButtonWrapper = styled.div`
  padding-right: 16px;
`;

interface IIconButtonProps extends React.ComponentProps<typeof IconButton> {
  isCollapsed: boolean;
}

const ToggleIconButton = styled(IconButton)<IIconButtonProps>`
  ${({isCollapsed}) => (isCollapsed ? 'transform: rotate(180deg)' : null)}
`;

/**
 * Alternate child props for nested PrintSelectionCheckboxGroups since TypeScript doesn't allow
 * recursive prop types.
 */
export interface IChildProps extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name?: string;
  children?: ReactNode;
}

export interface IProps {
  /**
   * The `label` prop accepts any React node to allow for custom
   * styling, etc.
   */
  label: string | ReactElement<any, string | React.JSXElementConstructor<any>>;
  children: ReactNode;
}

/**
 * Function which clones the passed-in object with a single key `path` changed to
 * `newValue`. For use with Formik keys.
 */
const setFlattenedKeyInObj = (object: {}, path: string, newValue: boolean): {} => {
  const split = path.split('.');

  const key = split[0];

  return {
    ...object,
    [key]: split.length > 1 ? setFlattenedKeyInObj(object[key] || {}, split.slice(1).join('.'), newValue) : newValue,
  };
};

/**
 * Function which gets a value in an object by a single key `path`. For use
 * with Formik keys.
 */
const getFlattenedKeyInObj = (object: {}, path: string): any => {
  if (!object) return undefined;

  const split = path.split('.');

  const key = split[0];

  return split.length > 1 ? getFlattenedKeyInObj(object[key], split.slice(1).join('.')) : object[key] || undefined;
};

/**
 * Component for rendering grouped checkboxes with a parent checkbox that can
 * be used to select or de-select all children checkboxes. This checkbox does not
 * act as a Formik field itself and is only used to control children fields.
 *
 * TODO: this component is currently a type-unsafe mess optimized for convenience, refactoring needed when/if possible.
 */
const PrintSelectionCheckboxGroup = ({label, children}: IProps) => {
  const {setValues, values} = useFormikContext();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const getChildrenNames = (children: ReactNode) => {
    const names: string[] = [];

    React.Children.forEach(children as ReactElement<any>, (child: ReactElement<any>) => {
      names.push(child?.props?.name);

      child.props.children && names.push(...getChildrenNames(child.props.children));
    });

    return names.filter((name) => !!name);
  };

  const childrenNames = children && getChildrenNames(children);

  const newProps = (child: ReactElement<IChildProps>): IChildProps =>
    child.props.name
      ? {
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            child.props.onChange && child.props.onChange(event);
          },
        }
      : {
          children: React.Children.map(
            child.props.children,
            (child) =>
              child && React.cloneElement(child as ReactElement<any>, newProps(child as ReactElement<IChildProps>))
          ),
        };

  const mappedChildren = React.Children.map(children, (child) =>
    React.cloneElement(child as ReactElement<any>, newProps(child as ReactElement<IChildProps>))
  );

  const [isChecked, setIsChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  useEffect(() => {
    const someChecked =
      !!childrenNames && !!values && childrenNames.some((name) => getFlattenedKeyInObj(values as {}, name));

    const allChecked =
      !!childrenNames && !!values && childrenNames.every((name) => getFlattenedKeyInObj(values as {}, name));

    setIsChecked(allChecked);
    setIsIndeterminate(someChecked && !allChecked);
  }, [values, childrenNames]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(event.target.checked);

      childrenNames &&
        setValues((oldValues: typeof values) =>
          childrenNames.reduce<{}>(
            (acc, name) => setFlattenedKeyInObj(acc, name, event.target.checked),
            oldValues as {}
          )
        );
    },
    [setValues, childrenNames]
  );

  return (
    <Root>
      <ParentWrapper className="d-flex flex-row">
        <CollapseButtonWrapper>
          <ToggleIconButton
            isCollapsed={isCollapsed}
            size={14}
            onClick={() => setIsCollapsed(!isCollapsed)}
            name={IconName.ArrowDown}
            color={Theme.color.primary}
            stroke={false}
            fill={true}
          />
        </CollapseButtonWrapper>
        <label
          style={{
            marginBottom: 0,
          }}
        >
          <div className="d-flex flex-row justify-content-center">
            <Checkbox
              indeterminate={isIndeterminate}
              checked={isChecked}
              label={<Label>{label}</Label>}
              onChange={onChange}
            />
          </div>
        </label>
      </ParentWrapper>
      <ChildrenWrapper
        style={{
          display: isCollapsed ? 'none' : undefined,
        }}
      >
        {mappedChildren}
      </ChildrenWrapper>
    </Root>
  );
};

export default memo(PrintSelectionCheckboxGroup);
