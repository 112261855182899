import {BreedType} from 'OnlineReport/constants/BreedType';
import {IAncestryExamples} from 'OnlineReport/models/Ancestry/IAncestryExamples';
import AkhalTekeImage from '../common/img/examples/akhal_teke.jpg';
import GypsyImage from '../common/img/examples/gypsy.jpg';
import IrishSportHorseImage from '../common/img/examples/irish_sport_horse.jpg';
import PaintHorseImage from '../common/img/examples/paint_horse.jpg';
import QuarterHorseImage from '../common/img/examples/quarter_horse.jpg';
import WarmbloodImage from '../common/img/examples/warmblood.jpg';

const gypcy: IAncestryExamples = {
  name: 'Gypsy',
  averageHeterozygosity: 29.51,
  inbreedingCoefficient: 12.3,
  image: GypsyImage,
  population: [
    {
      type: BreedType.HeavyHorse,
      percentage: 79.3,
      children: [],
    },
    {
      type: BreedType.Thoroughbred,
      percentage: 4.3,
      children: [],
    },
    {
      type: BreedType.Iberian,
      percentage: 2.6,
      children: [],
    },
    {
      type: BreedType.CarriageHorse,
      percentage: 3.4,
      children: [],
    },
    {
      type: BreedType.Exmoor,
      percentage: 2.8,
      children: [],
    },
    {
      type: BreedType.NorthSea,
      percentage: 7.0,
      children: [],
    },
  ],
};

const warmblood: IAncestryExamples = {
  name: 'Warmblood',
  averageHeterozygosity: 31.35,
  inbreedingCoefficient: 6.7,
  image: WarmbloodImage,
  population: [
    {
      type: BreedType.HeavyHorse,
      percentage: 13.1,
      children: [],
    },
    {
      type: BreedType.Thoroughbred,
      percentage: 62.4,
      children: [],
    },
    {
      type: BreedType.Iberian,
      percentage: 3.9,
      children: [],
    },
    {
      type: BreedType.CarriageHorse,
      percentage: 10.4,
      children: [],
    },
    {
      type: BreedType.NearEast,
      percentage: 5.8,
      children: [],
    },
    {
      type: BreedType.NorthSea,
      percentage: 3.9,
      children: [],
    },
  ],
};

const akhalTeke: IAncestryExamples = {
  name: 'Akhal Teke',
  averageHeterozygosity: 30.37,
  inbreedingCoefficient: 9.4,
  image: AkhalTekeImage,
  population: [
    {
      type: BreedType.HeavyHorse,
      percentage: 10.1,
      children: [],
    },
    {
      type: BreedType.Thoroughbred,
      percentage: 13.0,
      children: [],
    },
    {
      type: BreedType.Iberian,
      percentage: 5.0,
      children: [],
    },
    {
      type: BreedType.CarriageHorse,
      percentage: 5.0,
      children: [],
    },
    {
      type: BreedType.Exmoor,
      percentage: 2.7,
      children: [],
    },
    {
      type: BreedType.NorthSea,
      percentage: 8.2,
      children: [],
    },
    {
      type: BreedType.NearEast,
      percentage: 56,
      children: [],
    },
  ],
};

const irishSportHorse: IAncestryExamples = {
  name: 'Irish Sport Horse',
  averageHeterozygosity: 31.64,
  inbreedingCoefficient: 5.8,
  image: IrishSportHorseImage,
  population: [
    {
      type: BreedType.HeavyHorse,
      percentage: 15.0,
      children: [],
    },
    {
      type: BreedType.Thoroughbred,
      percentage: 72.3,
      children: [],
    },
    {
      type: BreedType.Iberian,
      percentage: 1.5,
      children: [],
    },
    {
      type: BreedType.CarriageHorse,
      percentage: 6.3,
      children: [],
    },
    {
      type: BreedType.Exmoor,
      percentage: 1.2,
      children: [],
    },
    {
      type: BreedType.NorthSea,
      percentage: 2.0,
      children: [],
    },
    {
      type: BreedType.NearEast,
      percentage: 1.7,
      children: [],
    },
  ],
};

const paintHorse: IAncestryExamples = {
  name: 'Paint Horse',
  averageHeterozygosity: 32.39,
  inbreedingCoefficient: 3.6,
  image: PaintHorseImage,
  population: [
    {
      type: BreedType.HeavyHorse,
      percentage: 13.7,
      children: [],
    },
    {
      type: BreedType.Thoroughbred,
      percentage: 41.8,
      children: [],
    },
    {
      type: BreedType.Iberian,
      percentage: 9.3,
      children: [],
    },
    {
      type: BreedType.CarriageHorse,
      percentage: 20.4,
      children: [],
    },
    {
      type: BreedType.Exmoor,
      percentage: 1.4,
      children: [],
    },
    {
      type: BreedType.NorthSea,
      percentage: 4.7,
      children: [],
    },
    {
      type: BreedType.NearEast,
      percentage: 8.6,
      children: [],
    },
  ],
};

const quarterHorse: IAncestryExamples = {
  name: 'Quarter Horse',
  averageHeterozygosity: 29.51,
  inbreedingCoefficient: 12.3,
  image: QuarterHorseImage,
  population: [
    {
      type: BreedType.HeavyHorse,
      percentage: 13.2,
      children: [],
    },
    {
      type: BreedType.Thoroughbred,
      percentage: 40.5,
      children: [],
    },
    {
      type: BreedType.Iberian,
      percentage: 10.0,
      children: [],
    },
    {
      type: BreedType.CarriageHorse,
      percentage: 21.2,
      children: [],
    },
    {
      type: BreedType.Exmoor,
      percentage: 1.6,
      children: [],
    },
    {
      type: BreedType.NorthSea,
      percentage: 3.9,
      children: [],
    },
    {
      type: BreedType.NearEast,
      percentage: 9.7,
      children: [],
    },
  ],
};

export const breedAveragesExamples: IAncestryExamples[] = [
  gypcy,
  warmblood,
  akhalTeke,
  irishSportHorse,
  paintHorse,
  quarterHorse,
];
