import React, {memo, useCallback} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import * as Yup from 'yup';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';

import {IAppState} from 'Common/store/IAppState';
import {AdminChangeEmailModule} from 'Admin/AdminDashboard/store/adminUsers/changeEmail/adminChangeEmailModule';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminUsers/changeEmail/index';
import {IChangeEmail} from 'Admin/AdminDashboard/services/types/user';
import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import Loading from 'Loading/components/Loading';
import {InputField} from 'Common/components/FormFields';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {Field, FieldLabel, FieldValue} from 'Admin/common/styled/StyledComponents';
import withDynamicModules from 'Common/helpers/withDynamicModules';

const Root = styled.div`
  margin: 0 24px 40px 0;
`;

interface IExternalProps {
  userName: string;
  oldEmail: string;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IChangeEmail> & OuterProps;

function ChangeEmail(props: AllProps) {
  const {isSubmitting, setErrors} = props;
  const {changeUserEmailUpdating, onSuccess, userName, oldEmail} = props;

  const errorInfo = changeUserEmailUpdating.error;
  const isRequesting = changeUserEmailUpdating.isRequesting;

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(changeUserEmailUpdating, onSuccess);
  useOnErrorCommunication(changeUserEmailUpdating, onError);
  useCommunicationToToast(changeUserEmailUpdating, 'User email has been changed');

  return (
    <>
      <ModalWindowHeader>Change user email</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <Root>
          <ModalWindowFormContent>
            {isRequesting && <Loading />}
            <div className="row">
              <Field className="col-4">
                <FieldLabel>User</FieldLabel>
                <FieldValue>{userName}</FieldValue>
              </Field>
              <Field className="col-4">
                <FieldLabel>Current email</FieldLabel>
                <FieldValue>{oldEmail}</FieldValue>
              </Field>
            </div>

            <InputField
              type="email"
              name="newEmail"
              label="New email"
              placeholder="User's new email"
              isRequired={true}
            />
          </ModalWindowFormContent>
        </Root>
        <ModalWindowFooter>
          <ModalWindowButton type="submit" isLoading={isSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const initialValue: IChangeEmail = {
  oldEmail: '',
  newEmail: '',
};

const validationSchema = Yup.object().shape<IChangeEmail>({
  oldEmail: Yup.string(),
  newEmail: Yup.string().email('New email must be a valid email').required('New email is a required field'),
});

const ChangeEmailFormik = withFormik<OuterProps, IChangeEmail>({
  mapPropsToValues: ({oldEmail}) => (oldEmail ? {oldEmail, newEmail: ''} : initialValue),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    await formikBag.props.changeUserEmail({oldEmail: formikBag.props.oldEmail, newEmail: values.newEmail});
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(ChangeEmail);

const mapStateToProps = (state: IAppState) => ({
  changeUserEmailUpdating: selectors.selectCommunication(state, 'changeUserEmailUpdating'),
});

const mapDispatchToProps = {
  changeUserEmail: actions.changeUserEmail,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(ChangeEmailFormik));

export default withDynamicModules(Connected, AdminChangeEmailModule);
