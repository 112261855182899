import React, {memo} from 'react';

import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import NoData from 'BusinessPortal/components/common/NoData/NoData';
import {FieldContainer, FieldData, FieldName} from 'BusinessPortal/components/common/styled';
import {getStringifyAddress} from 'Common/helpers/getStringifyAddress';
import {IHorseInfoSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseInfoSection/models/IHorseInfoSectionData';

const HorseInfoSectionShow = (props: IEditableSection<IHorseInfoSectionData>) => {
  const {data} = props;

  if (!data) {
    return <NoData />;
  }

  return (
    <>
      <FieldContainer className="d-flex">
        <FieldName>Name:</FieldName>
        <FieldData>{data?.name}</FieldData>
      </FieldContainer>

      <FieldContainer className="d-flex">
        <FieldName>Sex:</FieldName>
        <FieldData>{data?.gender}</FieldData>
      </FieldContainer>

      <FieldContainer className="d-flex">
        <FieldName>Registry #:</FieldName>
        <FieldData>{data?.registryNumber}</FieldData>
      </FieldContainer>

      <FieldContainer className="d-flex">
        <FieldName width="95px">Date of birth:</FieldName>
        <FieldData>{data?.dateOfBirth}</FieldData>
      </FieldContainer>

      <FieldContainer className="d-flex">
        <FieldName width="55px">Height:</FieldName>
        <FieldData>{data?.height ? `${data.height.height} ${data.height.heightUnit}` : '-'}</FieldData>
      </FieldContainer>

      <FieldContainer className="d-flex">
        <FieldName>Disciplines:</FieldName>
        <FieldData>{data?.disciplines?.map((i) => i.name).join(', ') || '-'}</FieldData>
      </FieldContainer>

      <FieldContainer className="d-flex">
        <FieldName>Breeds:</FieldName>
        <FieldData>{data?.breeds?.map((i) => i.name).join(', ') || '-'}</FieldData>
      </FieldContainer>

      <FieldContainer className="d-flex">
        <FieldName>Colors:</FieldName>
        <FieldData>{data?.colors?.map((i) => i.name).join(', ') || '-'}</FieldData>
      </FieldContainer>

      <FieldContainer className="d-flex">
        <FieldName>Markings:</FieldName>
        <FieldData>{data?.markings?.map((i) => i.name).join(', ') || '-'}</FieldData>
      </FieldContainer>

      <FieldContainer className="d-flex">
        <FieldName>Microchip:</FieldName>
        <FieldData>{data?.microchipNumber || '-'}</FieldData>
      </FieldContainer>

      <FieldContainer className="d-flex">
        <FieldName>Passport:</FieldName>
        <FieldData>{data?.passportNumber || '-'}</FieldData>
      </FieldContainer>

      <FieldContainer className="d-flex">
        <FieldName>Location:</FieldName>
        <FieldData>{data?.address ? getStringifyAddress(data.address) : '-'}</FieldData>
      </FieldContainer>

      <FieldContainer className="d-flex">
        <FieldName width="120px">Profile Privacy:</FieldName>
        <FieldData style={{fontWeight: 600}}>{`${data?.profileType} profile` || '-'}</FieldData>
      </FieldContainer>
      <FieldContainer className="d-flex">
        <FieldName width="120px">Ancestry Profile Privacy:</FieldName>
        <FieldData style={{fontWeight: 600}}>{`${data?.ancestryProfileType} profile` || '-'}</FieldData>
      </FieldContainer>
    </>
  );
};

export default memo(HorseInfoSectionShow);
