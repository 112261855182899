import React, {memo, useCallback, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import WarningModal, {ModalTypes} from 'Common/components/Modal/WarningModal';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {isYouTubeUrl} from 'Common/helpers/isYouTubeUrl';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IMediaResource, MediaType} from 'Common/models/IMediaResource';
import {IAppState} from 'Common/store/IAppState';
import Gallery from 'Gallery/components/Gallery';
import {GalleryModule} from 'Gallery/store/galleryModule';
import {actions as galleryActions, selectors as gallerySelectors} from 'Gallery/store/index';
import {IconName, IProps as IconProps} from 'Icon/components/Icon';
import ImageUploader from 'Image/components/ImageUploader';
import {IImage} from 'Image/models/IImage';
import Loading from 'Loading/components/Loading';
import VideoPlayer from 'Video/components/VideoPlayer';

const galleryIconProps: IconProps = {size: 40, name: IconName.InsertPhoto};
const galleryVideoIconProps: IconProps = {name: IconName.InsertVideo, size: 48};
const avatarIconProps: IconProps = {name: IconName.EtalonHorseLogo, width: 73, height: 39};

const HorseGalleryRoot = styled.div`
  margin-top: 48px;
  padding: 0 8px;
`;

const AvatarBorder = styled.div`
  border: 1px dashed ${ColorPalette.gray44};
  top: -8px;
  left: -8px;
  width: 142px;
  height: 142px;
  z-index: -1;
`;

const AvatarWrapper = styled.div`
  margin: 8px;
`;

const Title = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  letter-spacing: 0.2px;
`;

const AvatarTitle = styled(Title)`
  top: -32px;
`;

const GalleryTitle = styled(Title)`
  top: -24px;
  left: 150px;
`;

interface IOwnProps {
  horseId: number;
  avatar: IImage | null;
  onSuccess?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type IProps = IOwnProps & IConnected;

function HorseGallery(props: IProps) {
  const [isDeleteWarningModalOpen, setIsDeleteWarningModalOpen] = useState(false);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [mediaToDelete, setMediaToDelete] = useState({} as IImage);
  const [isAvatarDelete, setIsAvatarDelete] = useState<boolean>(false);

  const {
    horseId,
    getGallery,
    gallery,
    addImages,
    addVideo,
    deleteMediaFromGallery,
    galleryLoading,
    galleryUpdating,
    avatar,
    addAvatar,
    deleteAvatar,
    avatarUpdating,
    avatarDeleting,
    onSuccess,
  } = props;

  React.useEffect(() => {
    getGallery(horseId, true);
  }, [horseId, getGallery]);

  const onChangeGallery = React.useCallback(
    async (medias: IMediaResource[]) => {
      for (const media of medias) {
        if (gallery?.some((x) => x.id === media.id)) {
          continue;
        }

        if (media.type === MediaType.Image) {
          if (media.isLocal && media.file) {
            await addImages(horseId, [media], true);
          }
        }
        if (media.type === MediaType.Video) {
          await addVideo(horseId, media, true);
        }
      }
    },
    [gallery, addImages, horseId, addVideo]
  );

  const onAvatarChange = useCallback(() => {
    onSuccess && onSuccess();
  }, [onSuccess]);

  useOnSuccessCommunication(galleryUpdating, () => getGallery(horseId, true));
  useOnSuccessCommunication(avatarUpdating, onAvatarChange);
  useOnSuccessCommunication(avatarDeleting, onAvatarChange);

  const deleteMedia = (image: IImage) => {
    setMediaToDelete(image);
    setIsDeleteWarningModalOpen(true);
  };

  const handlerOnSuccess = useCallback(async () => {
    setIsDeleteWarningModalOpen(false);
    if (isAvatarDelete) {
      if (avatar) {
        await deleteAvatar(horseId, true);
        setIsAvatarDelete(false);
      }
      return;
    }

    deleteMediaFromGallery(horseId, [mediaToDelete.id], true);
  }, [isAvatarDelete, deleteMediaFromGallery, horseId, mediaToDelete.id, avatar, deleteAvatar]);

  const handlerOnClose = () => {
    setIsDeleteWarningModalOpen(false);
  };

  const handleChangeAvatar = useCallback(
    async (image: IImage) => {
      if (image.isLocal && image.file) {
        await addAvatar(horseId, image, true);
      }
    },
    [addAvatar, horseId]
  );

  const handleDeleteAvatar = useCallback(async () => {
    setIsAvatarDelete(true);
    setIsDeleteWarningModalOpen(true);
  }, []);

  const openPreviewModal = useCallback((url: string) => {
    setVideoUrl(url);
    setPreviewModalOpen(true);
  }, []);

  const closePreviewModal = useCallback(() => setPreviewModalOpen(false), []);

  const loadingGalleryError = !!galleryLoading.error && galleryLoading.error?.errors?.[0].description;
  const isGalleryUpdating = galleryUpdating.isRequesting || galleryLoading.isRequesting;
  const showGallery = !galleryLoading.error && !isGalleryUpdating && gallery != null;
  const horseAvatar = (
    <AvatarWrapper className="position-relative">
      <AvatarBorder className="position-absolute" />
      <AvatarTitle className="position-absolute">Main image</AvatarTitle>
      <ImageUploader
        name="avatar"
        title="Add main image"
        iconProps={avatarIconProps}
        value={avatar}
        width="126px"
        height="126px"
        showDownloadButton={true}
        onChange={handleChangeAvatar}
        onDelete={handleDeleteAvatar}
      />
    </AvatarWrapper>
  );

  return (
    <>
      <WarningModal
        isOpen={isDeleteWarningModalOpen}
        modalTitle={'Confirm'}
        modalType={ModalTypes.Confirm}
        successButtonText={'Delete'}
        cancelButtonText={'Cancel'}
        onClose={handlerOnClose}
        onSuccess={handlerOnSuccess}
      >
        Are you sure you want to delete the media (image or video link)?
      </WarningModal>

      <ModalWindow isOpen={isPreviewModalOpen} onClose={closePreviewModal} maxWidth="900px" isLightbox={true}>
        <VideoPlayer url={videoUrl} width="100%" height={isYouTubeUrl(videoUrl) ? '553px' : '100%'} />
      </ModalWindow>

      <HorseGalleryRoot>
        {isGalleryUpdating && <Loading />}
        {showGallery && (
          <div className="position-relative">
            <GalleryTitle className="position-absolute">Gallery</GalleryTitle>
            <Gallery
              values={gallery!}
              onChange={onChangeGallery}
              onDelete={deleteMedia}
              imageProps={{
                title: 'Add gallery photo',
                width: '126px',
                height: '126px',
                showDownloadButton: true,
                iconProps: galleryIconProps,
                acceptPartialSelection: false,
              }}
              videoProps={{
                title: 'Add a video',
                width: '126px',
                height: '126px',
                iconProps: galleryVideoIconProps,
                onClick: openPreviewModal,
                showPreviewOnClick: true,
              }}
              avatar={horseAvatar}
            />
          </div>
        )}
        {loadingGalleryError}
      </HorseGalleryRoot>
    </>
  );
}

const mapStateToProps = (state: IAppState) => ({
  gallery: gallerySelectors.selectGallery(state),
  galleryLoading: gallerySelectors.selectCommunication(state, 'galleryLoading'),
  galleryUpdating: gallerySelectors.selectCommunication(state, 'galleryUpdating'),
  avatarUpdating: gallerySelectors.selectCommunication(state, 'avatarUpdating'),
  avatarDeleting: gallerySelectors.selectCommunication(state, 'avatarDeleting'),
});

const mapDispatchToProps = {
  getGallery: galleryActions.getGallery,
  deleteMediaFromGallery: galleryActions.deleteMediaFromGallery,
  addImages: galleryActions.addImages,
  addVideo: galleryActions.addVideo,
  addAvatar: galleryActions.addAvatar,
  deleteAvatar: galleryActions.deleteAvatar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(HorseGallery));
export default withDynamicModules(Connected, GalleryModule);
