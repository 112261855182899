import * as Yup from 'yup';

export interface IVisibility {
  isVisible: boolean;
}

export interface ITraitVisibilities {
  /**
   * Maps trait IDs to visibility settings.
   */
  traits: Record<number, IVisibility>;
}

export interface ISummaryVisibility {
  variantSummary: IVisibility;
  abilities: IVisibility;
  coatColors: IVisibility;
  healthVariants: IVisibility;
}

export interface IFormValues {
  /**
   * Whether or not to save choices to database before
   * redirecting to printable report.
   */
  rememberChoices: boolean;
  summary: ISummaryVisibility;
  partialColors: ITraitVisibilities;
  aggregatedHealthIssues: ITraitVisibilities;
  advancedHealthIssues: ITraitVisibilities;
  aggregatedAbilities: ITraitVisibilities;
  advancedAbilities: ITraitVisibilities;
}

const visibilitySchema = Yup.object().shape<IVisibility>({
  isVisible: Yup.boolean(),
});

const traitVisibilitiesSchema = Yup.object().shape<ITraitVisibilities>({
  traits: Yup.object(),
});

const summaryVisibilitySchema = Yup.object().shape<ISummaryVisibility>({
  variantSummary: visibilitySchema,
  healthVariants: visibilitySchema,
  coatColors: visibilitySchema,
  abilities: visibilitySchema,
});

const baseValidationSchema = Yup.object().shape<IFormValues>({
  rememberChoices: Yup.boolean(),
  summary: summaryVisibilitySchema,
  partialColors: traitVisibilitiesSchema,
  aggregatedHealthIssues: traitVisibilitiesSchema,
  advancedHealthIssues: traitVisibilitiesSchema,
  aggregatedAbilities: traitVisibilitiesSchema,
  advancedAbilities: traitVisibilitiesSchema,
});

export const getValidationScheme = () => baseValidationSchema;

export const initialValue: IFormValues = {
  rememberChoices: true,
  summary: {
    variantSummary: {
      isVisible: true,
    },
    abilities: {
      isVisible: true,
    },
    coatColors: {
      isVisible: true,
    },
    healthVariants: {
      isVisible: true,
    },
  },
  partialColors: {
    traits: {},
  },
  advancedHealthIssues: {
    traits: {},
  },
  aggregatedHealthIssues: {
    traits: {},
  },
  advancedAbilities: {
    traits: {},
  },
  aggregatedAbilities: {
    traits: {},
  },
};
