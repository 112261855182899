import {combineReducers} from 'redux';

import {
  AuthActions,
  getVisitorTypeActionTypes,
  IAuthState,
  loginActionTypes,
  resetLoginActionTypes,
  resetVisitorTypeActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

export const communicationLoginReducer = combineReducers<IAuthState['communications'], AuthActions>({
  authenticating: composeReducers([
    makeCommunicationReducerFromEnum(loginActionTypes),
    makeResetCommunicationReducer(resetLoginActionTypes.RESET),
  ]),
  visitorTypeLoading: composeReducers([
    makeCommunicationReducerFromEnum(getVisitorTypeActionTypes),
    makeResetCommunicationReducer(resetVisitorTypeActionTypes.RESET),
  ]),
});
