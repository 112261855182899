import {Reducer} from 'redux';

import {
  AdminUserFullInfoUpdateActions,
  getUserActionTypes,
  IAdminUserFullInfoUpdateState,
  resetUserActionTypes,
} from '../types';

const initialState: IAdminUserFullInfoUpdateState['data'] = {
  user: null,
};

export const dataUserFullInfoUpdateReducer: Reducer<
  IAdminUserFullInfoUpdateState['data'],
  AdminUserFullInfoUpdateActions
> = (state = initialState, action: AdminUserFullInfoUpdateActions) => {
  switch (action.type) {
    case getUserActionTypes.SUCCESS: {
      return {...state, user: action.payload};
    }
    case resetUserActionTypes.RESET: {
      return {...state, user: initialState.user};
    }
    default: {
      return state;
    }
  }
};
