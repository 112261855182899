import React from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';

import {IPagination} from 'Common/models/IResponse';
import styles from './Pagination.module.css';
import Icon, {IconName} from 'Icon/components/Icon';
import CountPerPageSectionComponent from 'Common/components/Controls/Pagination/parts/CountPerPageSection';
import SelectPageSectionComponent from 'Common/components/Controls/Pagination/parts/SelectPageSection';
import {breakpoints} from 'Common/constants/Breakpoints';

const Root = styled.div`
  margin-top: 16px;
  @media ${breakpoints.sm} {
    margin-top: 60px;
  }
`;

const RootLayout = styled.div`
  display: grid;
  grid-template-areas:
    'pages pages'
    'countPerPage jumpTo';
  grid-column-gap: 16px;
  grid-row-gap: 8px;

  @media ${breakpoints.sm} {
    grid-template-areas: 'countPerPage pages jumpTo';
    grid-template-columns: 1.1fr auto 1fr;
  }
`;

const CountPerPageSection = styled(CountPerPageSectionComponent)`
  grid-area: countPerPage;
`;

const PaginationPages = styled.div`
  grid-area: pages;
`;

const SelectPageSection = styled(SelectPageSectionComponent)`
  grid-area: jumpTo;
`;

interface IProps {
  pagination: IPagination;
  onPageSelect(selectedItem: number): void;
  onChangeCountPerPage?(countPerPage: number): void;
}

const Pagination = (props: IProps) => {
  const {pagination, onPageSelect, onChangeCountPerPage} = props;
  const {currentPage: propsCurrentPage, pageCount} = pagination;

  const currentPage = propsCurrentPage - 1; // ReactPaginate start from 0 index

  function handlePageClick({selected}: {selected: number}) {
    if (currentPage !== selected) {
      onPageSelect(selected + 1);
    }
  }

  return (
    <Root className="d-flex w-100 justify-content-center">
      <RootLayout>
        {onChangeCountPerPage && <CountPerPageSection {...props} />}
        <PaginationPages className="d-flex align-items-center justify-content-center">
          <ReactPaginate
            previousLabel={<Icon name={IconName.Previous} size={13} />}
            nextLabel={<Icon name={IconName.Next} size={13} />}
            breakLabel={'...'}
            breakClassName={classNames(styles.break, styles.paginationElement)}
            pageCount={pageCount}
            initialPage={currentPage}
            forcePage={currentPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={styles.paginationContainer}
            activeClassName={styles.activePage}
            pageClassName={classNames(styles.page, styles.paginationElement)}
            previousClassName={classNames(styles.previous, styles.paginationButton)}
            nextClassName={classNames(styles.paginationButton)}
            disabledClassName={styles.disabled}
            activeLinkClassName={styles.link}
            pageLinkClassName={styles.link}
            breakLinkClassName={styles.link}
          />
        </PaginationPages>
        {onChangeCountPerPage && <SelectPageSection {...props} />}
      </RootLayout>
    </Root>
  );
};

export default Pagination;
export type PaginationProps = IProps;
