import {combineReducers} from 'redux';

import {
  getServiceModeActionTypes,
  IAdminServiceModeState,
  ServiceModeActions,
  setServiceModeActionTypes
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationServiceModeReducer = combineReducers<
  IAdminServiceModeState['communications'],
  ServiceModeActions
>({
  adminServiceModeLoading: makeCommunicationReducerFromEnum(getServiceModeActionTypes),
  adminServiceModeUpdating: makeCommunicationReducerFromEnum(setServiceModeActionTypes)
});
