import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IRequestParams} from 'Common/models/IRequestParams';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';
import {IOrderSummary} from 'BusinessPortal/models/order/createOrder/IOrderSummary';
import {convertOrderSummaryToClient} from 'BusinessPortal/services/converters/createOrder/summary';
import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import {convertCouponToClient} from 'BusinessPortal/services/converters/createOrder/coupon';
import {convertCreateOrderRequestToServer} from 'BusinessPortal/services/converters/createOrder/order';
import {ITestSummaryRequest} from 'Admin/AdminAssociations/services/types/summary';
import {ICreateOrderRequest} from 'Admin/AdminAssociations/models/Order/createOrder/ICreateOrderRequest';
import {ICheckCouponRequest} from 'BusinessPortal/services/types/coupon';
import {IAssociationOrderEmployee} from '../models/Order/createOrder/IAssociationOrderEmployee';
import {IServerOrderHorse} from './types/order';
import {convertOrderHorseToClient} from './converters/orders';

const getHorses = async (organizationId: number, params?: IRequestParams): Promise<IOrderHorse[]> => {
  const tempParams: IRequestParams = {
    ...params,
    currentPage: 1,
    countPerPage: 2147483647,
  };

  const response = await axiosWrapper.get<IListResponse<IServerOrderHorse>>(`/AssociationOrderHorsesAdmin`, {
    params: {organizationId, ...tempParams},
  });
  return response.data.data.map(convertOrderHorseToClient);
};

const getSummary = async (data: ITestSummaryRequest): Promise<IOrderSummary> => {
  const response = await axiosWrapper.post<IObjectResponse<IOrderSummary>>(`/AssociationOrderSummaryAdmin`, data);
  return convertOrderSummaryToClient(response.data.data);
};

const getCoupon = async (organizationId: number, data: ICheckCouponRequest): Promise<IOrderCoupon> => {
  const response = await axiosWrapper.post<IObjectResponse<IOrderCoupon>>(`/AssociationCouponCheckAdmin`, {
    organizationId,
    ...data,
  });
  return convertCouponToClient(response.data.data);
};

const getAssociationEmployees = async (organizationId: number): Promise<IAssociationOrderEmployee[]> => {
  const response = await axiosWrapper.get<IListResponse<IAssociationOrderEmployee>>(
    `/AssociationEmployeeListAdmin/${organizationId}`
  );
  return response.data.data;
};

const createOrder = async (request: ICreateOrderRequest): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(
    `/AssociationOrderCreateAdmin`,
    convertCreateOrderRequestToServer(request)
  );
};

export default {
  getHorses,
  getSummary,
  getCoupon,
  getAssociationEmployees,
  createOrder,
};
