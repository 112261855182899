import {memo} from 'react';
import styled from 'styled-components';

import Switch from 'Common/components/Controls/Switch';
import {breakpoints} from 'Common/constants/Breakpoints';
import {ProfileType} from 'Common/constants/ProfileType';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {BorderedBlock} from 'HorseProfile/components/shared/StyledComponents';
import HintIcon from 'Common/components/HintIcont/HintIcon';
import {
  CHANGE_ANCESTRY_PROFILE_TYPE_TOOLTIP,
  CHANGE_DIAGNOSTICS_PROFILE_TYPE_TOOLTIP,
} from 'Shared/constants/profileTypeTooltip';

const SwitchWrapper = styled(BorderedBlock)`
  margin-top: 20px;
`;

const SwitcherWrapper = styled.div`
  margin-bottom: 8px;
`;

const ChangeProfileTypeContainer = styled.div`
  padding: 18px 20px;
  margin-bottom: 22px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.05), 0 0 4px rgba(0, 0, 0, 0.02);
  border-radius: 5px;

  @media ${breakpoints.sm} {
    margin-top: 24px;
    margin-bottom: -8px;
  }
`;

const SwitchText = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 18px;
  color: ${Theme.color.black};
`;

interface IProps {
  isDesktop: boolean;
  isOwnHorse: boolean;
  profileType: ProfileType;
  ancestryProfileType: ProfileType;
  onUpdateProfileType(): void;
  onUpdateAncestryProfileType(): void;
}

type AllProps = IProps;

function HorseProfileTypeSwitcher(props: AllProps) {
  const {isDesktop, onUpdateAncestryProfileType, onUpdateProfileType, ancestryProfileType, isOwnHorse, profileType} =
    props;

  const content = (
    <div className="d-flex flex-column">
      <SwitcherWrapper className="d-flex align-items-center">
        <Switch
          onChange={onUpdateProfileType}
          checked={profileType !== ProfileType.Private}
          textRight={<SwitchText>{profileType} diagnostic profile</SwitchText>}
          labelStyle={{minWidth: 185}}
        />
        <HintIcon tooltip={CHANGE_DIAGNOSTICS_PROFILE_TYPE_TOOLTIP} />
      </SwitcherWrapper>

      <div className="d-flex align-items-center">
        <Switch
          onChange={onUpdateAncestryProfileType}
          checked={ancestryProfileType !== ProfileType.Private}
          textRight={<SwitchText>{ancestryProfileType} ancestry profile</SwitchText>}
          labelStyle={{minWidth: 185}}
        />
        <HintIcon tooltip={CHANGE_ANCESTRY_PROFILE_TYPE_TOOLTIP} />
      </div>
    </div>
  );

  if (!isOwnHorse) {
    return null;
  }

  if (isDesktop) {
    return <SwitchWrapper className="d-flex justify-content-end align-items-center">{content}</SwitchWrapper>;
  }

  return <ChangeProfileTypeContainer className="d-flex w-100 align-items-center">{content}</ChangeProfileTypeContainer>;
}

export default memo(HorseProfileTypeSwitcher);

export type IHorseProfileTypeSwitcherProps = IProps;
