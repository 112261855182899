import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {breakpoints} from 'Common/constants/Breakpoints';

const Root = styled.div`
  margin-top: 16px;

  @media ${breakpoints.sm} {
    margin-top: 60px;
  }
`;

const Title = styled.div`
  font-family: ${Theme.font.secondary};
  font-size: ${Typography.size.size24};
  font-weight: ${Typography.weight.semiBold600};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${ColorPalette.black1};
  margin-bottom: 16px;
`;

const PackageGroups = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  grid-column-gap: 16px;

  @media ${breakpoints.md2} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${breakpoints.lg} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

interface IProps {
  title: string | React.ReactElement;
}

function OrderPackageContainer(props: React.PropsWithChildren<IProps>) {
  const {title, children} = props;
  return (
    <Root>
      <Title>{title}</Title>
      <PackageGroups>{children}</PackageGroups>
    </Root>
  );
}

export default memo(OrderPackageContainer);
