import React, {memo} from 'react';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {IAssociation} from 'Dictionaries/models/IAssociation';
import {useDictionaries} from 'Common/store/useDictionaries';
import DictionaryLayout from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import AssociationsForm from 'Admin/AdminReference/components/Associations/AssociationForm/AssociationForm';

function Associations() {
  const {adminAssociation: dictionary} = useDictionaries();

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={AssociationsForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IAssociation> header="ID" dataKey="id" render={({id}) => `#${id}`} expandOnClick={true} width={100} />
            <Cell<IAssociation>
              header="Abbreviation"
              dataKey="abbreviation"
              render={({abbreviation}) => abbreviation}
              width="20%"
            />
            <Cell<IAssociation> header="Name" dataKey="name" render={({name}) => name} width="20%" />

            <Cell<IAssociation>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(Associations);
