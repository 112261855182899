import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {IPaymentDetails} from 'Admin/AdminDashboard/models/IPaymentDetails';
import {
  getPaymentDetailsActions,
  getPaymentDetailsActionTypes,
  resetPaymentDetailsActions,
  resetPaymentDetailsActionTypes,
  updatePaymentDetailsActions,
  updatePaymentDetailsActionTypes,
} from 'Admin/AdminDashboard/store/common/paymentDetailsUpload/types';
import PaymentDetailsUploadService from 'Admin/AdminDashboard/services/PaymentDetailsUploadService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const getPaymentDetails =
  (orderId: number): ActionResult<IAppState, void, getPaymentDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getPaymentDetailsActionTypes.REQUEST));
      const result = await PaymentDetailsUploadService.getPaymentDetails(orderId);
      dispatch(action(getPaymentDetailsActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getPaymentDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updatePaymentDetails =
  (data: IPaymentDetails): ActionResult<IAppState, void, updatePaymentDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updatePaymentDetailsActionTypes.REQUEST));
      await PaymentDetailsUploadService.updatePaymentDetails(data);
      dispatch(action(updatePaymentDetailsActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updatePaymentDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetPaymentDetailsResults =
  (): ActionResult<IAppState, void, resetPaymentDetailsActions> => async (dispatch) => {
    dispatch(action(resetPaymentDetailsActionTypes.RESET));
  };
