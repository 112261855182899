export enum ProbabilityEntityType {
  Abilities = 'abilities',
  Colors = 'colors',
  Dangers = 'dangers',
  HealthIssues = 'health issues',
  Modifiers = 'modifiers'
}

export const ProbabilityEntityTypeSorting: Record<ProbabilityEntityType, number> = {
  [ProbabilityEntityType.Abilities]: 3,
  [ProbabilityEntityType.Colors]: 4,
  [ProbabilityEntityType.Dangers]: 1,
  [ProbabilityEntityType.HealthIssues]: 2,
  [ProbabilityEntityType.Modifiers]: 5
};
