import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import {IMenuItemValued} from 'Common/models/IMenuItemValued';

const iconProps = {color: ColorPalette.red7};

export enum AdminEmployeeActionValue {
  UpdateAdminEmployee = 'updateAdminEmployee',
  UpdateAdminEmpoyeePermissions = 'updateAdminEmpoyeePermissions',
  UpdateAdminEmployeePassword = 'updateAdminEmployeePassword',
  UpdateAdminEmployeeEmail = 'updateAdminEmployeeEmail',
}

export const menuItems: IMenuItemValued<AdminEmployeeActionValue>[] = [
  {
    value: AdminEmployeeActionValue.UpdateAdminEmployee,
    label: 'Edit Employee',
    icon: {name: IconName.Edit, ...iconProps},
  },
  {
    value: AdminEmployeeActionValue.UpdateAdminEmpoyeePermissions,
    label: 'Edit permissions',
    icon: {name: IconName.Eye, ...iconProps},
  },
  {
    value: AdminEmployeeActionValue.UpdateAdminEmployeeEmail,
    label: 'Change email',
    icon: {name: IconName.Email, ...iconProps},
  },
  {
    value: AdminEmployeeActionValue.UpdateAdminEmployeePassword,
    label: 'Change password',
    icon: {name: IconName.Lock, ...iconProps},
  },
];
