import * as Yup from 'yup';

import {IAdminUserPermissions, IUserPermission} from 'Admin/AdminDashboard/models/User/IUserPermissions';
import {convertToServerDate} from 'Common/helpers/DateHelper';
import {IPermission} from 'Permissions/model/IPermission';

export interface IFormValues {
  userId: string;
  permissions: Array<Omit<IUserPermission, 'permissionId'>>;
}

export const initialValue: IFormValues = {
  userId: '',
  permissions: [],
};

export const validationSchema = Yup.object().shape<IFormValues>({
  userId: Yup.string(),
  permissions: Yup.array().of(Yup.mixed()),
});

export function convertUserPermissionServerToFormValues(
  data: IAdminUserPermissions,
  permissions: IPermission[]
): IFormValues {
  return {
    ...data,
    permissions: permissions.map((permission) => {
      const item = data.permissions.find((x) => x.code === permission.code);
      if (item) {
        return {
          code: item.code,
          expiryDate: item.expiryDate,
          isEnable: true,
          description: `${permission.name} access`,
        };
      }

      return {
        code: permission.code,
        expiryDate: null,
        isEnable: false,
        description: `${permission.name} access`,
      };
    }),
  };
}

export function convertFormValuesToUserPermissionRequest(data: IFormValues): IAdminUserPermissions {
  const dataToSave = data.permissions.filter((item) => item.isEnable);
  return {
    userId: data.userId,
    permissions:
      dataToSave?.map((item) => {
        return {
          code: item.code,
          expiryDate: convertToServerDate(item.expiryDate, true),
        };
      }) || [],
  };
}
