import {
  ICommunication,
  makeCommunicationActionType,
  MakeCommunicationActions,
} from 'Common/store/utils/communication/index';
import {IUserHorse} from 'Horse/models/IUserHorse';
import {IListResponse, IPagination} from 'Common/models/IResponse';

const STATE_NAME = 'horseList';

export interface IHorseListState {
  data: {
    horses: IUserHorse[];
  };
  communications: {
    horsesLoading: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getHorsesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_HORSES');
export type getHorsesActions = MakeCommunicationActions<
  typeof getHorsesActionTypes,
  {success: IListResponse<IUserHorse>}
>;

export type HorsesActions = getHorsesActions;
