import * as React from 'react';
import {Redirect, Switch, useLocation} from 'react-router-dom';

import AppRoute from './AppRoute/AppRoute';
import Main from 'Main/components/Main';
import {COMMON_NAVIGATION_ROUTES, USER_NAVIGATION_ROUTES} from 'Common/components/Navigation';
import {ILocationState} from 'Common/models/ILocationState';

function AppAuthRouting() {
  const location = useLocation<ILocationState>();
  const {from} = location.state || {from: {pathname: '/'}};

  return (
    <Switch>
      <AppRoute path="/" component={Main} exact={true} />
      {COMMON_NAVIGATION_ROUTES.map((route, i) => (
        <AppRoute key={i} path={route.value} component={route.Component} {...route} />
      ))}
      {USER_NAVIGATION_ROUTES.map((route, i) => (
        <AppRoute key={i} path={route.value} component={route.Component} {...route} />
      ))}
      <Redirect to={from} />
    </Switch>
  );
}

export default AppAuthRouting;
