import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import ExcludeList from 'ExcludeHorse/components/ExcludeList/ExcludeList';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';

interface IExcludeListModalParams {
  onSuccess?(): void;
}

export function useExcludeListModal(params: IExcludeListModalParams) {
  const {onSuccess} = params;

  const [isOpen, setIsOpen] = useState(false);

  const {emit} = useEventBus();

  const openExcludeListModal = useCallback(() => {
    setIsOpen(true);
  }, []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeModal();
    emit(EventName.GenePoolChanged);

    onSuccess && onSuccess();
  }, [closeModal, emit, onSuccess]);

  const excludeListModal = (
    <ModalWindow isOpen={isOpen} onClose={handleSuccess}>
      <ExcludeList onApply={handleSuccess} />
    </ModalWindow>
  );

  return {
    excludeListModal,
    openExcludeListModal,
  };
}
