import React, {memo, useCallback, useState, useMemo} from 'react';
import styled from 'styled-components';

import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import UploadPanel from 'Common/components/Controls/UploadPanel';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {ImagePreviewProps} from 'Image/components/imageUploaderPreview/ImagePreviewShort/ImagePreview';
import {IAvailabilityMenuItem} from 'Common/components/DropDown/common/types';
import {DropDownMenu} from 'Common/components/DropDown/DropDownMenu';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {breakpoints} from 'Common/constants/Breakpoints';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {hexToRgba} from 'Common/helpers/hexToRgba';

const Root = styled.div`
  width: 100%;
  height: 100%;
`;

const ButtonPanel = styled.div<{height: string; isHover?: boolean}>`
  width: 100%;
  height: ${({height}) => height};
  padding: 5px;
  opacity: ${(props) => (props.isHover ? 1 : 0)};
  background: ${hexToRgba(ColorPalette.black1, 0.6)};
  bottom: 0;

  transition: opacity 0.15s ease;

  @media ${breakpoints.md} {
    padding: 0;
  }
`;

const Button = styled(IconButton)`
  margin: 0 8px;
`;

const MoreIconWrapper = styled.div`
  margin-left: auto;
`;

const MoreIcon = styled(ColoredIcon)`
  cursor: pointer;
`;

interface IProps {
  isDesktop: boolean;
}

type AllProps = IProps & Omit<ImagePreviewProps, 'width' | 'value'>;

export const Actions = memo((props: AllProps) => {
  const {
    isDesktop,
    minSize,
    maxSize,
    height,
    showDownloadButton,
    showEditButton,
    showDeleteButton,
    handleOnDeleteImage,
    handleDownloadImage,
    handleSelectFiles,
    handleRejectFiles,
  } = props;

  const commonMenuItems: IAvailabilityMenuItem[] = [
    {
      value: 'downloadHandler',
      label: 'Download photo',
      icon: {name: IconName.CloudDownload, size: isDesktop ? 32 : 20},
      id: 1,
      availabilityKey: showDownloadButton,
    },
    {
      value: 'deleteHandler',
      label: 'Delete photo',
      icon: {name: IconName.Garbage, size: isDesktop ? 24 : 18},
      id: 3,
      availabilityKey: showDeleteButton,
    },
  ];

  const touchDisplayMenuItems: IAvailabilityMenuItem[] = commonMenuItems.concat({
    value: 'editHandler',
    label: (
      <UploadPanel
        onSelectFiles={handleSelectFiles}
        onRejectFiles={handleRejectFiles}
        height="20px"
        width="100%"
        minSize={minSize}
        maxSize={maxSize}
        acceptableTypes={'image/*'}
        inputStyle={{marginLeft: -16, background: 'none'}}
      >
        Edit photo
      </UploadPanel>
    ),
    icon: {name: IconName.Edit, size: 24},
    id: 2,
    availabilityKey: showEditButton,
  });

  const desktopMenuItems: IAvailabilityMenuItem[] = commonMenuItems.concat({
    value: 'editHandler',
    label: (
      <UploadPanel
        key="editHandler_key"
        onSelectFiles={handleSelectFiles}
        onRejectFiles={handleRejectFiles}
        height="24px"
        width="24px"
        minSize={minSize}
        maxSize={maxSize}
        acceptableTypes={'image/*'}
        inputStyle={{border: 0, margin: '0 8px', background: 'none'}}
      >
        <Button
          name={IconName.Edit}
          color={ColorPalette.white0}
          fill={true}
          stroke={false}
          hoverColor={ColorPalette.gray20}
          size={24}
        />
      </UploadPanel>
    ),
    id: 2,
    availabilityKey: showEditButton,
  });

  const menuItems = isDesktop ? desktopMenuItems : touchDisplayMenuItems;

  const shownMenuItems = menuItems.filter((item) => item.availabilityKey).sort((a, b) => sortByStringKey(a, b, 'id'));

  const actionsHandler = useMemo(
    () => ({
      downloadHandler: handleDownloadImage,
      editHandler: console.log,
      deleteHandler: handleOnDeleteImage,
    }),
    [handleDownloadImage, handleOnDeleteImage]
  );

  const onSelectHandler = useCallback((type) => actionsHandler[type](), [actionsHandler]);

  const [hover, setHover] = useState<boolean>(false);

  const onMouseEnter = useCallback(() => setHover(true), [setHover]);
  const onMouseLeave = useCallback(() => setHover(false), [setHover]);

  if (!isDesktop) {
    return (
      <Root className="position-absolute" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <ButtonPanel className="d-flex align-items-start position-absolute" isHover={hover} height={height}>
          <MoreIconWrapper>
            <DropDownMenu items={shownMenuItems} onSelect={onSelectHandler} alignRight={true}>
              {({isOpen}) => (
                <MoreIcon
                  name={IconName.More}
                  color={isOpen ? ColorPalette.white0 : ''}
                  hoverColor={ColorPalette.white0}
                  fill={true}
                  stroke={false}
                />
              )}
            </DropDownMenu>
          </MoreIconWrapper>
        </ButtonPanel>
      </Root>
    );
  }

  return (
    <Root className="position-absolute" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <ButtonPanel
        className="d-flex align-items-center justify-content-center position-absolute"
        isHover={hover}
        height={height}
      >
        {shownMenuItems.map((item, i) =>
          typeof item.label === 'string' ? (
            <Button
              key={i}
              name={item.icon!.name}
              color={ColorPalette.white0}
              fill={true}
              hoverColor={ColorPalette.gray20}
              size={item.icon?.size || 28}
              stroke={false}
              onClick={actionsHandler[item.value]}
            />
          ) : (
            item.label
          )
        )}
      </ButtonPanel>
    </Root>
  );
});
