import {ICommunication} from 'Common/store/utils/communication/types';
import {useToast} from 'Common/helpers/hooks/useToast';
import {useCallback} from 'react';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';

export function useSuccessCommunicationToToast(
  communication: ICommunication,
  message?: string,
  toastId?: string | number
) {
  const {addToast} = useToast(toastId);

  const onSuccess = useCallback(() => {
    addToast(message || 'Success');
  }, [addToast, message]);

  useOnSuccessCommunication(communication, onSuccess);
}
