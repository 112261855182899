import {combineReducers, Reducer} from 'redux';

import {IAdminAssociationPackagesState} from '../types';
import {communicationAdminAssociationPackagesReducer} from './communications';
import {dataAdminAssociationPackagesReducer} from './data';
import {uiAdminAssociationPackagesReducer} from './ui';

export const adminAssociationPackagesReducer: Reducer<IAdminAssociationPackagesState> =
  combineReducers<IAdminAssociationPackagesState>({
    communications: communicationAdminAssociationPackagesReducer,
    data: dataAdminAssociationPackagesReducer,
    ui: uiAdminAssociationPackagesReducer,
  });
