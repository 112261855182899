import {combineReducers} from 'redux';

import {
  getUserPrivacySettingsActionTypes,
  IUserPrivacySettingsState,
  updateUserPrivacySettingsActionTypes,
  UserPrivacySettingsActions
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationUserPrivacySettingsReducer = combineReducers<
  IUserPrivacySettingsState['communications'],
  UserPrivacySettingsActions
>({
  userPrivacySettingsLoading: makeCommunicationReducerFromEnum(getUserPrivacySettingsActionTypes),
  userPrivacySettingsUpdating: makeCommunicationReducerFromEnum(updateUserPrivacySettingsActionTypes)
});
