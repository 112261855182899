import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

import {IHorseTest} from 'Order/models/IHorseTest';
import {IHorsePackageRequest} from 'Order/services/types/shoppingCart';

const STATE_NAME = 'shoppingCart';

export interface IShoppingCartState {
  data: {
    shoppingCart: IHorseTest[];
  };
  communications: {
    shoppingCartLoading: ICommunication;
    shoppingCartCreating: ICommunication;
    shoppingCartDeleting: ICommunication;
    shoppingCartClearing: ICommunication;
  };
}

export const getShoppingCartActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_SHOPPING_CART');
export type getShoppingCartActions = MakeCommunicationActions<
  typeof getShoppingCartActionTypes,
  {success: IHorseTest[]}
>;

export const createShoppingCartActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_SHOPPING_CART');
export type createShoppingCartActions = MakeCommunicationActions<
  typeof createShoppingCartActionTypes,
  {success: IHorsePackageRequest}
>;

export const deleteShoppingCartActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_SHOPPING_CART');
export type deleteShoppingCartActions = MakeCommunicationActions<
  typeof deleteShoppingCartActionTypes,
  {success: IHorsePackageRequest}
>;

export const clearShoppingCartActionTypes = makeCommunicationActionType(STATE_NAME, 'CLEAR_SHOPPING_CART');
export type clearShoppingCartActions = MakeCommunicationActions<typeof clearShoppingCartActionTypes, {}>;

export type Actions =
  | getShoppingCartActions
  | createShoppingCartActions
  | deleteShoppingCartActions
  | clearShoppingCartActions;
