import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {IShortDescriptionEntity} from 'Common/models/IDescriptionEntity';
import {ITraitSequence} from 'Admin/AdminPhenotypes/models/ITraitSequence';
import {Combination} from 'Common/constants/Combination';
import {Nullable} from 'Common/types';
import {IUpdateDangerAdvancedRequest} from 'Admin/AdminPhenotypes/service/models/IServerDangerAdvanced';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues extends IShortDescriptionEntity {
  id?: IEntity['id'];
  name: string;
  type: Combination;
  sequences?: ITraitSequence[];
  groupId: Nullable<number>;
}

export const initialValue: IFormValues = {
  name: '',
  type: Combination.And,
  description: {value: ''},
  sequences: [],
  groupId: null
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  groupId: Yup.number()
    .nullable()
    .required(REQUIRED_FIELD)
});

export const convertToServer = (values: IFormValues): Omit<IUpdateDangerAdvancedRequest, 'id'> => {
  delete values['group'];
  return {
    ...values,
    description: {value: values.description?.value || ''},
    group: values.groupId ? {id: values.groupId} : null
  };
};

export const convertToServerUpdating = (values: IFormValues): IUpdateDangerAdvancedRequest => {
  return {
    ...convertToServer(values),
    id: values.id!
  };
};
