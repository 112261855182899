import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';

import {InputField, SelectField, TextAreaField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {IEntity} from 'Common/models/IEntity';
import {IDictionary, ISimpleDictionary} from 'DictionaryFactory/types';
import {RequiredBy} from 'Common/types';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {convertToServer, convertToServerUpdating, IFormValues, initialValue, validationSchema} from './validation';
import {IAdminGenotype} from 'Admin/AdminPhenotypes/models/IAdminGenotype';
import {ITrait} from 'Dictionaries/models/ITrait';
import {castToOption} from 'Common/helpers/OptionHelper';
import {IUpdateGenotypeRequest} from 'Admin/AdminPhenotypes/service/models/IServerGenotype';

interface IExternalProps {
  itemId: IEntity['id'] | null;
  type: FormType;
  onSuccess(): void;
  dictionary: IDictionary<IAdminGenotype, IUpdateGenotypeRequest>;
  traitDictionary: ISimpleDictionary<ITrait>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function GenotypeForm(props: AllProps) {
  const {itemId, genotype, dictionary, trait, traitDictionary, traitLoading, ...rest} = props;
  const {getItem} = dictionary.actions;
  const {
    actions: {getItems: getTraits},
  } = traitDictionary;

  useEffect(() => {
    getTraits();
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem, getTraits]);

  return (
    <DictionaryForm
      {...rest}
      itemCreating={genotype.itemCreating}
      itemLoading={genotype.itemLoading}
      itemUpdating={genotype.itemUpdating}
      itemPreloading={traitLoading.isRequesting}
    >
      <InputField name="alleleNames" label="Variants" placeholder="Variants" />
      <SelectField name="trait" label="Trait" options={castToOption(trait)} />
      <InputField name="positionIndex" label="Order in Report" placeholder="Order in Report" className="w-50" />
      <TextAreaField name="description" label="Description" placeholder="Description" />
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {traitDictionary} = props;

  return {
    genotype: makePhenotypeFormSelectors(state, props.dictionary),
    trait: traitDictionary.selectors.selectItems(state),
    traitLoading: traitDictionary.selectors.selectCommunication(state, 'itemsLoading'),
  };
};

const GenotypeFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({genotype: {item, itemLoading}, itemId}) =>
    itemId && item && !itemLoading.isRequesting ? {...item, trait: item.trait?.id || null} : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(GenotypeForm);

const connector = connect(mapStateToProps);
export default connector(memo(GenotypeFormFormik));
