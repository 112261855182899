import {combineReducers} from 'redux';

import {IBusinessPortalCreateOrderState} from 'BusinessPortal/store/createOrder/types';
import {communicationReducer} from 'BusinessPortal/store/createOrder/reducer/communications';
import {dataReducer} from 'BusinessPortal/store/createOrder/reducer/data';

export const businessPortalCreateOrderReducer = combineReducers<IBusinessPortalCreateOrderState>({
  communications: communicationReducer,
  data: dataReducer,
});
