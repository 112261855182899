import {IModule} from 'redux-dynamic-modules';

import {IUserState} from './types';
import {userReducer} from './reducer';

export interface IUserModuleState {
  user: IUserState;
}

export const UserModule: IModule<IUserModuleState> = {
  id: 'user',
  reducerMap: {
    user: userReducer
  }
};
