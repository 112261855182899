import React, {useCallback, useEffect, useState} from 'react';

import SlidePanel from 'Common/components/SlidePanel/SlidePanel';
import {ElementPosition} from 'FoalProfile/models/IElementPosition';
import HorseFormEdit from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/HorseFormEdit';
import {FormType} from 'Common/constants/FormType';
import AssociationHorseFormCreate from 'Shared/components/Association/AssociationHorseFormCreate';

const formHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Create a Horse',
  edit: 'Horse details',
};

interface IProps {
  title?: JSX.Element;
  onSuccessForm?(): void;
  shadow?: boolean;
  transition?: boolean;
  closableTitle?: boolean;
}

export function useHorseFormSlidePanel(props: IProps) {
  const {title, closableTitle, shadow, transition, onSuccessForm} = props;

  const [isOpenSlidePanel, setIsOpenSlidePanel] = useState(false);
  const [formHasChanges, setFormHasChanges] = useState<boolean>(false);

  const [horseId, setHorseId] = useState<number>();
  const [ownerId, setOwnerId] = useState<number>();

  const [formType, setFormType] = useState<FormType>(FormType.edit);

  useEffect(() => {
    const formType = horseId ? FormType.edit : FormType.create;
    setFormType(formType);
  }, [horseId]);

  const onSuccessEditFormHandler = useCallback(() => setFormHasChanges(true), []);

  const openHorseFormSlidePanel = useCallback((horseId?: number, ownerId?: number) => {
    setIsOpenSlidePanel(true);
    setHorseId(horseId);
    setOwnerId(ownerId);
  }, []);

  const closeHorseFormSlidePanel = useCallback(async () => {
    setIsOpenSlidePanel(false);
    horseId && ownerId && formHasChanges && onSuccessForm && (await onSuccessForm());
    setFormHasChanges(false);
  }, [formHasChanges, horseId, onSuccessForm, ownerId]);

  const onSuccessCreateFormHandler = useCallback(async () => {
    await closeHorseFormSlidePanel();
    onSuccessForm && (await onSuccessForm());
  }, [closeHorseFormSlidePanel, onSuccessForm]);

  const header = formHeaderByType[formType];

  const horseFormSlidePanel = (
    <SlidePanel
      title={title || header}
      closableTitle={closableTitle}
      isOpen={isOpenSlidePanel}
      position={ElementPosition.Right}
      onClose={closeHorseFormSlidePanel}
      shadow={shadow}
      transition={transition}
    >
      {!horseId && <AssociationHorseFormCreate onSuccess={onSuccessCreateFormHandler} ownerId={ownerId} />}
      {horseId && <HorseFormEdit horseId={horseId} onSuccess={onSuccessEditFormHandler} />}
    </SlidePanel>
  );

  return {openHorseFormSlidePanel, horseFormSlidePanel, closeHorseFormSlidePanel, horseId, ownerId};
}
