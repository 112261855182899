import {Reducer} from 'redux';

import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';

import {FavoriteHorsesActions, getFavoriteHorsesActionTypes, IFavoriteHorseState} from '../types';

const initialState: IFavoriteHorseState['ui'] = {
  pagination: {countPerPage: MIN_COUNT_PER_PAGE, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiReducer: Reducer<IFavoriteHorseState['ui'], FavoriteHorsesActions> = (
  state = initialState,
  action: FavoriteHorsesActions
) => {
  switch (action.type) {
    case getFavoriteHorsesActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
