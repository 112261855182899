import React, {useCallback, useState} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import {StripeForm} from 'Payment/components';
import {IStripeFormProps} from 'Payment/components/Stripe/StripeForm';

type AllProps = IStripeFormProps;

export function useStripePaymentModal(props: AllProps) {
  const {...stripeFormProps} = props;

  const [isOpenStripePaymentModal, setIsOpenStripePaymentModal] = useState(false);
  const openStripePaymentModal = useCallback(() => {
    setIsOpenStripePaymentModal(true);
  }, []);

  const closeStripePaymentModal = useCallback(() => {
    setIsOpenStripePaymentModal(false);
  }, []);

  const stripePaymentModal = (
    <ModalWindow isOpen={isOpenStripePaymentModal} onClose={closeStripePaymentModal} maxWidth="500px">
      <StripeForm {...stripeFormProps} isBusiness />
    </ModalWindow>
  );

  return {
    isOpenStripePaymentModal,
    openStripePaymentModal,
    closeStripePaymentModal,
    stripePaymentModal,
  };
}
