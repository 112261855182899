import {VisitorType} from 'Common/constants/VisitorType';
import {selectors as userSelectors} from 'UserProfile/store/currentUser';
import {IAppState} from 'Common/store/IAppState';
import {actions, BusinessPortalOrderModule, selectors} from 'BusinessPortal/store/order';
import {actions as orderActions, selectors as orderSelectors} from 'Order/store/submissionForm';
import {SubmissionFormModule} from 'Order/store/submissionForm/submissionFormModule';
import {OrderPaymentModule} from 'Payment/store/orderPayment/orderPaymentModule';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {Nullable} from 'Common/types';
import {IAssociationEmployee} from 'UserProfile/models/IAssociationEmployee';
import {IAdmin} from 'UserProfile/models/IAdmin';
import {IUser} from 'UserProfile/models/IUser';
import {ICommunication} from 'Common/store/utils/communication';

export interface IState {
  currentVisitor: Nullable<IAssociationEmployee> | Nullable<IAdmin> | Nullable<IUser>;
  submissionFormRequesting: ICommunication;
  toOrdersPageLink: string;
  urlPrefix: string;
}

export interface IDispatch {
  getSubmissionForm(token: string): void;
}

const associationEmployeeDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    currentVisitor: userSelectors.selectCurrentAssociationEmployee(state),
    submissionFormRequesting: selectors.selectCommunication(state, 'orderGroupSubmissionFormRequesting'),
    toOrdersPageLink: '/dashboard/orders',
    urlPrefix: '/business-portal',
  }),
  dispatch: {
    getSubmissionForm: actions.downloadGroupSubmissionForm,
  },
  modules: BusinessPortalOrderModule,
};

const userDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    currentVisitor: userSelectors.selectCurrentUser(state),
    submissionFormRequesting: orderSelectors.selectCommunication(state, 'downloadFormRequesting'),
    toOrdersPageLink: `/user/user-profile/${userSelectors.selectCurrentUser(state)?.id}/tests`,
    urlPrefix: '',
  }),
  dispatch: {
    getSubmissionForm: orderActions.downloadOrderSubmissionForm,
  },
  modules: [SubmissionFormModule, OrderPaymentModule],
};

const adminDistributor: IDistributor<IState, IDispatch> = {
  ...userDistributor,
  state: (state: IAppState) => ({
    ...userDistributor.state(state),
    toOrdersPageLink: `/orders`,
    urlPrefix: '/admin',
  }),
};

export const orderSuccessVisitorDistributor: Record<VisitorType, IDistributor<IState, IDispatch>> = {
  [VisitorType.AssociationEmployee]: associationEmployeeDistributor,
  [VisitorType.User]: userDistributor,
  [VisitorType.Admin]: adminDistributor,
  [VisitorType.AssociationAdmin]: adminDistributor,
};
