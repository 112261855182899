import {IOption} from 'Common/models/IOption';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';
import {IEntity} from 'Common/models/IEntity';
import {Enumeration} from 'Common/types';

export const getNumberKeysOption = (enumeration: Enumeration): IOption[] => {
  const isNumber = (value: any) => Number.isSafeInteger(Number(value));
  return Object.keys(enumeration)
    .filter(isNumber)
    .map((key) => {
      return {value: enumeration[key], label: enumeration[key]};
    });
};

export const getStringKeysOption = (enumeration: Enumeration, useDefinedKeyName: boolean = false): IOption[] => {
  return Object.keys(enumeration).map((key) => {
    return {value: useDefinedKeyName ? key : enumeration[key], label: enumeration[key]};
  });
};

interface CastOptions {
  key?: string;
  formatter?: (value: string) => string;
}

export function castToOption<T extends IEntity>(list: T[], options?: CastOptions): IDictionaryOptionType[] {
  const key = options?.key || 'name';
  const formatter = options?.formatter;

  return list.map((item: T) => {
    return {
      ...item,
      value: item.id,
      label: formatter ? formatter(item[key]) : item[key],
    };
  });
}
