import {IAppState} from 'Common/store/IAppState';
import {ICommunication} from 'Common/store/utils/communication';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {actions as userActions, selectors as userSelectors} from 'OnlineReport/store/index';
import {actions as adminActions, selectors as adminSelectors} from 'Admin/AdminDashboard/store/adminOnlineReport/index';
import {IOnlineReportHorseInfo} from 'OnlineReport/models/shared/IOnlineReportHorseInfo';
import {Nullable} from 'Common/types';
import {IRequestChangeReviewStatus} from 'Admin/AdminDashboard/store/adminOrders';
import {OnlineReportType} from './shared/OnlineReportType';

export interface IState {
  horseInfo: Nullable<IOnlineReportHorseInfo>;
  horseInfoLoading: ICommunication;
  completeReviewRequesting: ICommunication;
}

export interface IDispatch {
  getHorseInfo(horseId: number): void;
  completeReview?(data: IRequestChangeReviewStatus): void;
  resetOnlineReportResults?(): void;
}

const userDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    horseInfo: userSelectors.selectHorseInfo(state),
    horseInfoLoading: userSelectors.selectCommunication(state, 'horseInfoLoading'),
    completeReviewRequesting: adminSelectors.selectCommunication(state, 'changeReviewStatusRequesting'),
  }),
  dispatch: {
    getHorseInfo: userActions.getHorseInfo,
  },
  // TODO: add modules here
  // modules: OnlineReportModule,
};

const adminDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    horseInfo: adminSelectors.selectHorseInfo(state),
    horseInfoLoading: adminSelectors.selectCommunication(state, 'horseInfoLoading'),
    completeReviewRequesting: adminSelectors.selectCommunication(state, 'changeReviewStatusRequesting'),
  }),
  dispatch: {
    getHorseInfo: adminActions.getHorseInfo,
    completeReview: adminActions.changeReviewStatus,
    resetOnlineReportResults: adminActions.resetOnlineReportResults,
  },
  // TODO: add modules here
  // modules: AdminOnlineReportModule,
};

export const onlineReportDistributor: Record<OnlineReportType, IDistributor<IState, IDispatch>> = {
  [OnlineReportType.User]: userDistributor,
  [OnlineReportType.Association]: userDistributor,
  [OnlineReportType.Admin]: adminDistributor,
  [OnlineReportType.AdminAssociation]: adminDistributor,
  [OnlineReportType.ReviewAdmin]: adminDistributor,
  [OnlineReportType.ReviewAdminAssociation]: adminDistributor,
};
