import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

import {IUserPrintableReportPreferences} from 'OnlineReportPrintable/models/IUserPrintableReportPreferences';
import {Nullable} from 'Common/types';

const STATE_NAME = 'userPrintableReportPreferences';

export interface IUserPrintableReportPreferencesState {
  data: {
    userPrintableReportPreferences: Nullable<IUserPrintableReportPreferences>;
  };
  communications: {
    userPrintableReportPreferencesLoading: ICommunication;
    updateUserPrintableReportSummaryVisibilitySubmitting: ICommunication;
    hideUserPrintableReportPreferencesSubmitting: ICommunication;
    showUserPrintableReportPreferencesSubmitting: ICommunication;
  };
}

export const getUserPrintableReportPreferencesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_USER_PRINTABLE_REPORT_PREFERENCES'
);
export type getUserPrintableReportPreferencesActions = MakeCommunicationActions<
  typeof getUserPrintableReportPreferencesActionTypes,
  {success: IUserPrintableReportPreferences}
>;

export const updateUserPrintableReportSummaryVisibilityActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_USER_PRINTABLE_REPORT_SUMMARY_VISIBILITY'
);
export type updateUserPrintableReportSummaryVisibilityActions = MakeCommunicationActions<
  typeof updateUserPrintableReportSummaryVisibilityActionTypes,
  {}
>;

export const hideUserPrintableReportPreferencesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'HIDE_USER_PRINTABLE_REPORT_PREFERENCES'
);
export type hideUserPrintableReportPreferencesActions = MakeCommunicationActions<
  typeof hideUserPrintableReportPreferencesActionTypes,
  {}
>;

export const showUserPrintableReportPreferencesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'SHOW_USER_PRINTABLE_REPORT_PREFERENCES'
);
export type showUserPrintableReportPreferencesActions = MakeCommunicationActions<
  typeof showUserPrintableReportPreferencesActionTypes,
  {}
>;

export type UserPrintableReportPreferencesActions =
  | getUserPrintableReportPreferencesActions
  | updateUserPrintableReportSummaryVisibilityActions
  | hideUserPrintableReportPreferencesActions
  | showUserPrintableReportPreferencesActions;
