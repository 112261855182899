import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import GenePoolsDataService from 'Filters/services/GenePoolsDataService';
import {IGenePoolsRequest} from 'Filters/models/GenePools';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {getAllGenePoolsActions, getAllGenePoolsActionTypes} from './types';

export const getAllGenePools = (
  config: IGenePoolsRequest
): ActionResult<IAppState, void, getAllGenePoolsActions> => async dispatch => {
  try {
    dispatch(action(getAllGenePoolsActionTypes.REQUEST));
    const res = await GenePoolsDataService.getAllGenePools(config);
    dispatch(action(getAllGenePoolsActionTypes.SUCCESS, res));
  } catch (error) {
    dispatch(action(getAllGenePoolsActionTypes.FAILURE, getErrorMessage(error)));
  }
};
