import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {IShortDescriptionEntity} from 'Common/models/IDescriptionEntity';
import {IUpdatePartialColorRequest} from 'Admin/AdminPhenotypes/service/models/IServerPartialColor';
import {Nullable} from 'Common/types';
import {IIndexed} from 'Common/models/IIndexed';
import {IExternalUrlEntity} from 'Common/models/IExternalUrlEntity';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues extends IShortDescriptionEntity, IIndexed, IExternalUrlEntity {
  id?: IEntity['id'];
  name: string;
  trait: Nullable<number>;
  group: Nullable<number>;
  affectionTypeId: Nullable<number>;
}

export const initialValue: IFormValues = {
  name: '',
  description: {value: ''},
  group: null,
  trait: null,
  affectionTypeId: null,
  positionIndex: 0,
  externalDescriptionUrl: ''
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  trait: Yup.number()
    .nullable()
    .required(REQUIRED_FIELD),
  group: Yup.number()
    .nullable()
    .required(REQUIRED_FIELD),
  positionIndex: Yup.number().required(REQUIRED_FIELD)
});

export const convertToServer = (values: IFormValues): Omit<IUpdatePartialColorRequest, 'id'> => {
  return {
    ...values,
    description: {value: values.description?.value || ''},
    group: values.group ? {id: values.group} : null,
    trait: values.trait ? {id: values.trait} : null,
    affectionType: values.affectionTypeId ? {id: values.affectionTypeId} : null
  };
};

export const convertToServerUpdating = (values: IFormValues): IUpdatePartialColorRequest => {
  return {
    ...convertToServer(values),
    id: values.id!
  };
};
