import React, {memo, useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {TextBase} from 'BusinessPortal/components/common/styled';
import Typography from 'Common/constants/Typography';
import {dateDiffInYearsOrMonth} from 'Common/helpers/DateHelper';
import ImageUploader from 'Image/components/ImageUploader';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'BusinessPortal/store/horse';
import Loading from 'Loading/components/Loading';
import {IconName} from 'Icon/components/Icon';
import {IImage} from 'Image/models/IImage';
import NoData from 'BusinessPortal/components/common/NoData/NoData';
import {IHorseSectionBP} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/models/IHorseSectionBP';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {Gender} from 'Common/constants/Gender';

const mapGenderIconToGender: Record<Gender, number> = {
  [Gender.Gelding]: IconName.Stallion,
  [Gender.Stallion]: IconName.Stallion,
  [Gender.Mare]: IconName.Mare
};

const HorseInformation = styled.div`
  margin-left: 24px;
`;

const Name = styled(TextBase)`
  font-size: ${Typography.size.size20};
  line-height: 32px;
  letter-spacing: 0;
`;

const SimpleInformation = styled(TextBase)`
  margin-top: 8px;
  font-size: ${Typography.size.size16};
  opacity: 0.5;
`;

const GenderSymbol = styled(ColoredIcon)`
  margin-left: 12px;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IHorseSectionBP;

const Header = (props: AllProps) => {
  const {
    horseId,
    horseDetails,
    getHorseDetails,
    deleteHorseAvatar,
    onSuccess,
    updateHorseAvatar,
    horseAvatarUpdating,
    horseAvatarDeleting,
    horseDetailsLoading
  } = props;

  const mainRequest = useCallback(() => {
    getHorseDetails(horseId);
  }, [getHorseDetails, horseId]);

  useEffect(() => {
    mainRequest();
  }, [mainRequest]);

  const onSuccessHeaderHandler = useCallback(() => {
    mainRequest();
    onSuccess && onSuccess();
  }, [mainRequest, onSuccess]);

  const updateHorseAvatarHandler = useCallback(
    async (image: IImage) => {
      if (image.file) {
        await updateHorseAvatar(horseDetails!.id, image.file);
        onSuccessHeaderHandler();
      }
    },
    [horseDetails, onSuccessHeaderHandler, updateHorseAvatar]
  );

  const deleteHorseAvatarHandler = useCallback(async () => {
    await deleteHorseAvatar(horseDetails!.id);
    onSuccessHeaderHandler();
  }, [deleteHorseAvatar, horseDetails, onSuccessHeaderHandler]);

  const isRequesting =
    horseDetailsLoading.isRequesting || horseAvatarUpdating.isRequesting || horseAvatarDeleting.isRequesting;

  if (!horseDetails) {
    return <NoData />;
  }

  return (
    <div className="d-flex position-relative">
      {isRequesting && <Loading />}
      <ImageUploader
        width="104px"
        height="104px"
        isRound={true}
        actionButtonsContainerHeight="100%"
        value={horseDetails.avatar}
        iconProps={{name: IconName.HorseDefaultAvatar, width: 104, height: 104}}
        showEditButton={true}
        showDeleteButton={true}
        onDelete={deleteHorseAvatarHandler}
        onChange={updateHorseAvatarHandler}
      />

      <HorseInformation className="d-flex flex-column justify-content-center">
        <div className="d-flex align-items-center">
          <Name>{horseDetails.name}</Name>
          {horseDetails.gender && (
            <GenderSymbol name={mapGenderIconToGender[horseDetails.gender]} size={16} fill={true} stroke={false} />
          )}
        </div>
        <SimpleInformation>
          {horseDetails.gender} {horseDetails.dateOfBirth && `, ${dateDiffInYearsOrMonth(horseDetails.dateOfBirth)}`}
        </SimpleInformation>
      </HorseInformation>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  horseDetails: selectors.selectHorseDetails(state),
  horseDetailsLoading: selectors.selectCommunication(state, 'horseDetailsLoading'),
  horseAvatarUpdating: selectors.selectCommunication(state, 'horseAvatarUpdating'),
  horseAvatarDeleting: selectors.selectCommunication(state, 'horseAvatarDeleting')
});

const mapDispatchToProps = {
  getHorseDetails: actions.getHorseDetails,
  updateHorseAvatar: actions.updateHorseAvatar,
  deleteHorseAvatar: actions.deleteHorseAvatar
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(Header));
