import * as Yup from 'yup';

import {IContactRequest} from 'UserProfile/models/IContactRequest';

export const initialValue: IContactRequest = {
  userId: 0,
  email: '',
  message: '',
};

export const validationSchema = Yup.object().shape<IContactRequest>({
  userId: Yup.number().required('User id is required'),
  email: Yup.string().required('Contact email is required'),
  message: Yup.string(),
});
