import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';

interface IProps {
  color?: string;
  hoverColor?: string;
  disabled?: boolean;
}

const ColoredExternalLink = styled.a.attrs({rel: 'noopener noreferrer', target: '_blank'})<IProps>`
  color: ${props => (props.color ? props.color : ColorPalette.white0)};
  opacity: ${props => (props.disabled ? '0.3' : '0.4')};
  :hover {
    color: ${props => (props.hoverColor ? props.hoverColor : ColorPalette.white0)};
    opacity: 1;
  }
  pointer-events: ${props => props.disabled && 'none'};
`;

export default ColoredExternalLink;
