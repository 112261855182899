import React from 'react';

import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import {SegmentPosition} from 'Common/constants/SegmentPosition';

const WIDTH = 103;
const HEIGHT = 8;

interface ISectionProps {
  color: string;
  fill: boolean;
  width: number;
  height: number;
}

const FilledSegmentProps: ISectionProps = {
  color: ColorPalette.red2,
  fill: true,
  width: WIDTH,
  height: HEIGHT
};

const EmptySegmentProps: ISectionProps = {
  color: ColorPalette.gray11,
  fill: true,
  width: WIDTH,
  height: HEIGHT
};

export enum PropPickerSegmentFilling {
  Filled,
  Empty
}

export interface IProps {
  segmentPosition: SegmentPosition;
  segmentFilled: PropPickerSegmentFilling;
  style?: React.CSSProperties;
}

const PropPickerSegment = (props: IProps) => {
  let iconName = IconName.LeftSection2;
  switch (props.segmentPosition) {
    case SegmentPosition.Left:
      iconName = IconName.LeftSection2;
      break;
    case SegmentPosition.Center:
      iconName = IconName.CenterSection2;
      break;
    case SegmentPosition.Right:
      iconName = IconName.RightSection2;
      break;
  }

  let segmentProps = FilledSegmentProps;
  switch (props.segmentFilled) {
    case PropPickerSegmentFilling.Filled:
      segmentProps = FilledSegmentProps;
      break;
    case PropPickerSegmentFilling.Empty:
      segmentProps = EmptySegmentProps;
      break;
  }

  return <ColoredIcon name={iconName} {...segmentProps} style={props.style} />;
};

export default PropPickerSegment;
