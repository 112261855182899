import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {
  BusinessPortalCellContent,
  BusinessPortalHeaderCell,
  BusinessPortalSearch,
  BusinessPortalTableWrapper,
} from 'BusinessPortal/components/common/BusinessPortalTable';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {searchBarStyle} from 'BusinessPortal/components/common/styled';
import Loading from 'Loading/components/Loading';
import {Cell, Table} from 'Common/components/Table/Table';
import {IOrderBP} from 'BusinessPortal/models/order/IOrderBP';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import AvatarCellHorse from 'Admin/AdminDashboard/components/Orders/shared/AvatarCellHorse';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {Pagination} from 'Common/components/Controls';
import {IAppState} from 'Common/store/IAppState';
import {actions, BusinessPortalOrderModule, selectors} from 'BusinessPortal/store/order';
import {
  actions as horseActions,
  BusinessPortalHorseModule,
  selectors as horseSelectors,
} from 'BusinessPortal/store/horse';
import {actions as paymentActions, selectors as paymentSelectors} from 'BusinessPortal/store/orderPayment';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {BusinessPortalOwnerModule} from 'BusinessPortal/store/owner';
import {userStatuses} from 'UserProfile/constants/OrderStatuses';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {useOrderFormSlidePanel} from 'BusinessPortal/helpers/hooks/useOrderFormSlidePanel';
import Icon, {IconName} from 'Icon/components/Icon';
import {IAvailabilityMenuItemValued, IMenuItemValued} from 'Common/models/IMenuItemValued';
import Actions from 'Common/components/Actions/Actions';
import {BusinessPortalOrderPaymentModule} from 'BusinessPortal/store/orderPayment/businessPortalOrderPaymentModule';
import {OrderStatus} from 'Common/constants/OrderStatus';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {useHorseFormSlidePanel} from 'BusinessPortal/helpers/hooks/useHorseFormSlidePanel';
import {useOwnerFormSlidePanel} from 'BusinessPortal/helpers/hooks/useOwnerFormSlidePanel';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {withCurrency} from 'Common/helpers/withCurrency';
import ColorPalette from 'Common/constants/ColorPalette';
import {useDeleteModal} from 'Common/helpers/hooks/useDeleteModal';
import EmptyState, {
  EmptyStateType,
} from 'BusinessPortal/components/BusinessPortalDashboard/shared/EmptyState/EmptyState';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

enum OrdersActionValue {
  OpenDetailedInfo = 'openDetailedInfo',
  OpenPaymentPage = 'openPaymentPage',
  DownloadSubmissionForm = 'downloadSubmissionForm',
  DeleteOrder = 'deleteOrder',
}

const commonMenuItems: IMenuItemValued<OrdersActionValue>[] = [
  {
    value: OrdersActionValue.OpenDetailedInfo,
    label: 'Detailed info',
    icon: {name: IconName.ShowInfoSmall, color: Theme.color.primary, stroke: false, fill: true},
  },
  {
    value: OrdersActionValue.OpenPaymentPage,
    label: 'Pay now',
    icon: {name: IconName.Money, color: Theme.color.primary, stroke: false, fill: true},
  },
  {
    value: OrdersActionValue.DownloadSubmissionForm,
    label: 'Submission form',
    icon: {name: IconName.Description, color: Theme.color.primary, stroke: false, fill: true},
  },
  {
    value: OrdersActionValue.DeleteOrder,
    label: 'Delete order',
    icon: {name: IconName.Garbage, color: Theme.color.gray},
    style: {color: Theme.color.gray, hoverColor: Theme.color.primary, activeColor: ColorPalette.red11},
    divided: true,
  },
];

const PackageTable = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const PackageTd = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 0;
`;

const CouponTooltip = styled.div`
  margin-right: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const CouponName = styled.div`
  margin: 2px 0;
`;

const AddOrderIcon = styled(ColoredIcon)`
  margin-right: 8px;
`;

type IConnected = ConnectedProps<typeof connector>;

const Orders = (props: IConnected) => {
  const {
    getHorses,
    resetHorses,
    horses,
    horsesLoading,
    getOrders,
    orders,
    ordersLoading,
    pagination,
    resetOrders,
    getOrderPaymentToken,
    orderPaymentTokenLoading,
    downloadSubmissionForm,
    orderSubmissionFormRequesting,
    deleteOrder,
    orderDeleting,
  } = props;

  const history = useHistory();
  const {isMobile, isTablet} = useMediaQuery();

  const [isNoData, setIsNoData] = useState<boolean>(false);

  const {
    selectedId,
    setSelectedId,
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    searchBar,
    params,
    searchQuery,
  } = useCommonAdminPageData<IOrderBP>({
    getItems: getOrders,
    searchBarPlaceholder: 'Search for orders by any keyword',
    searchBarStyle: searchBarStyle,
  });

  useEffect(() => {
    getHorses();
  }, [getHorses]);

  useEffect(() => {
    return () => {
      resetHorses();
      resetOrders();
    };
  }, [resetOrders, resetHorses]);

  const updateOrders = useCallback(() => {
    getOrders(params);
  }, [getOrders, params]);

  const redirectToCreateOrderPage = useCallback(() => history.push('/business-portal/order-tests'), [history]);
  const redirectToPaymentPage = useCallback(
    (token: string) => history.push(`/business-portal/payment?token=${token}`),
    [history]
  );

  const openPaymentPage = useCallback(
    async (id: number) => {
      const token = await getOrderPaymentToken(id);
      redirectToPaymentPage(token);
    },
    [getOrderPaymentToken, redirectToPaymentPage]
  );

  useOnSuccessCommunication(ordersLoading, () => {
    if (orders.length < 1 && !searchQuery) {
      setIsNoData(true);
      return;
    }
    setIsNoData(false);
  });

  useErrorCommunicationToToast(orderSubmissionFormRequesting);

  const isLoading = [
    ordersLoading,
    orderPaymentTokenLoading,
    orderSubmissionFormRequesting,
    orderDeleting,
    horsesLoading,
  ].some((x) => x.isRequesting);

  const hasOrders = orders.length > 0 && !ordersLoading.isRequesting;

  const getMapAvailableByMenuItem = useCallback(
    (order: IOrderBP): Record<OrdersActionValue, boolean> => ({
      [OrdersActionValue.OpenDetailedInfo]: true,
      [OrdersActionValue.OpenPaymentPage]: order.status === OrderStatus.orderPlaced,
      [OrdersActionValue.DownloadSubmissionForm]: true,
      [OrdersActionValue.DeleteOrder]: order.status === OrderStatus.orderPlaced,
    }),
    []
  );

  const getMenuItems = useCallback(
    (order: IOrderBP): IAvailabilityMenuItemValued<Partial<OrdersActionValue>>[] => {
      const mapAvailableByMenuItem = getMapAvailableByMenuItem(order);
      return commonMenuItems
        .map((item, id) => ({...item, id, availabilityKey: mapAvailableByMenuItem[item.value]}))
        .filter((item) => item.availabilityKey);
    },
    [getMapAvailableByMenuItem]
  );

  const {openOrderFormSlidePanel, orderFormSlidePanel, closeOrderFormSlidePanel} = useOrderFormSlidePanel({
    onSuccessForm: updateOrders,
    shadow: true,
    transition: true,
    openPaymentPage,
    downloadSubmissionForm,
  });

  const {horseFormSlidePanel, openHorseFormSlidePanel, closeHorseFormSlidePanel} = useHorseFormSlidePanel({
    onSuccessForm: updateOrders,
    shadow: true,
    transition: true,
  });

  const {ownerFormSlidePanel, openOwnerFormSlidePanel, closeOwnerFormSlidePanel} = useOwnerFormSlidePanel({
    onSuccessForm: updateOrders,
    shadow: true,
    transition: true,
  });

  const closeAllSlidePanels = useCallback(() => {
    closeOrderFormSlidePanel();
    closeHorseFormSlidePanel();
    closeOwnerFormSlidePanel();
  }, [closeHorseFormSlidePanel, closeOrderFormSlidePanel, closeOwnerFormSlidePanel]);

  const openOrderFormSlidePanelHandler = useCallback(
    (id: number) => {
      closeAllSlidePanels();
      openOrderFormSlidePanel(id);
      setSelectedId(id);
    },
    [closeAllSlidePanels, openOrderFormSlidePanel, setSelectedId]
  );

  const openHorseFormSlidePanelHandler = useCallback(
    (horseId, ownerId: number) => {
      closeAllSlidePanels();
      openHorseFormSlidePanel(horseId, ownerId);
    },
    [closeAllSlidePanels, openHorseFormSlidePanel]
  );

  const openOwnerFormSlidePanelHandler = useCallback(
    (id: number) => {
      closeAllSlidePanels();
      openOwnerFormSlidePanel(id);
    },
    [closeAllSlidePanels, openOwnerFormSlidePanel]
  );

  const {deleteModal, openDeleteModal} = useDeleteModal({
    onSuccess: updateOrders,
    deleteCommunication: orderDeleting,
    confirmDescription: 'Are you sure you want to remove the order?',
    deleteAction: deleteOrder,
  });

  useEffect(() => {
    if (selectedId) {
      openOrderFormSlidePanelHandler(selectedId);
    }
  }, [openOrderFormSlidePanelHandler, selectedId]);

  const renderPrice = useCallback(({price, coupon}: IOrderBP) => {
    return (
      <BusinessPortalCellContent className="d-flex justify-content-end">
        {coupon && (
          <CouponTooltip>
            <Tooltip content={<CouponName>{coupon}</CouponName>}>
              <div>
                <Icon name={IconName.Coupon} />
              </div>
            </Tooltip>
          </CouponTooltip>
        )}
        {withCurrency(price)}
      </BusinessPortalCellContent>
    );
  }, []);

  const hasHorses = horses.length > 0 && !horsesLoading.isRequesting;

  return (
    <>
      {horseFormSlidePanel}
      {ownerFormSlidePanel}
      {orderFormSlidePanel}
      {deleteModal}
      <BusinessPortalSearch>
        {searchBar}
        {(hasOrders || hasHorses) && (
          <PrimaryButton onClick={redirectToCreateOrderPage} size="small">
            <AddOrderIcon
              name={IconName.OrderTesting}
              fill={true}
              stroke={false}
              size={22}
              color={ColorPalette.white0}
            />
            Add an order
          </PrimaryButton>
        )}
      </BusinessPortalSearch>
      <BusinessPortalTableWrapper>
        {isLoading && <Loading />}
        <>
          <Table<IOrderBP>
            data={orders}
            rowKey="id"
            sorting={sorting}
            onChangeSorting={changeSorting}
            expandable={false}
            disableRow={() => false}
            customHeaderCell={BusinessPortalHeaderCell}
            // not needed for expanded styles but also affect models row
            expandedRowStyles={{expandedRow: {height: 'auto'}}}
            tableStyles={{fontSize: Typography.size.size16, fontFamily: Theme.font.primary}}
            isMobile={isMobile || isTablet}
          >
            <Cell<IOrderBP>
              header="#"
              dataKey="id"
              align={CellAlign.Left}
              sortable={false}
              render={({id}) => <BusinessPortalCellContent>{id}</BusinessPortalCellContent>}
              width="5%"
            />
            <Cell<IOrderBP>
              header="Owner"
              dataKey="owner"
              sortable={false}
              render={({owner}) => (
                <BusinessPortalCellContent>
                  <AvatarCellHorse
                    type="owner"
                    label={owner.name}
                    avatarUrl={owner.avatar?.url}
                    isArchived={false}
                    isDeleted={false}
                    isGeneticDataHidden={false}
                    onLabelClick={() => openOwnerFormSlidePanelHandler(owner.id)}
                    profileUrl={`/business-portal/user/association-owner-profile/${owner.id}`}
                  />
                </BusinessPortalCellContent>
              )}
              width="20%"
            />
            <Cell<IOrderBP>
              header="Horse"
              dataKey="horse"
              sortable={false}
              render={({horse, owner}) => (
                <BusinessPortalCellContent>
                  <AvatarCellHorse
                    type="horse"
                    label={horse.name}
                    avatarUrl={horse.avatar?.url}
                    isArchived={horse.isArchived}
                    isDeleted={horse.isDeleted}
                    isGeneticDataHidden={false}
                    onLabelClick={() => openHorseFormSlidePanelHandler(horse.id, owner.id)}
                    profileUrl={`/business-portal/horse/${horse.id}`}
                  />
                </BusinessPortalCellContent>
              )}
              width="25%"
            />
            <Cell<IOrderBP>
              header="Status"
              dataKey="status"
              sortable={false}
              render={({status}) => (
                <BusinessPortalCellContent>
                  <ColoredIcon
                    color={userStatuses[status].color}
                    name={userStatuses[status].icon}
                    size={20}
                    className="align-self-start flex-shrink-0"
                    style={{marginRight: 6}}
                    fill={true}
                    stroke={false}
                  />
                  {userStatuses[status].label}
                </BusinessPortalCellContent>
              )}
              width="20%"
            />
            <Cell<IOrderBP>
              header="Package"
              dataKey="packages"
              align={CellAlign.Left}
              sortable={false}
              render={({packages, tests}) => {
                const isPackage = packages && packages.length > 0;
                const isTest = tests && tests.length > 0;

                const packageValues = packages.map((x) => x.name).join(', ');
                const testValues = tests.map((x) => x.name).join(', ');

                return (
                  <BusinessPortalCellContent>
                    <PackageTable>
                      {isPackage && (
                        <tr>
                          <Tooltip content={packageValues}>
                            <PackageTd>{packageValues}</PackageTd>
                          </Tooltip>
                        </tr>
                      )}
                      {isTest && (
                        <tr>
                          <Tooltip content={testValues}>
                            <PackageTd>{testValues}</PackageTd>
                          </Tooltip>
                        </tr>
                      )}
                    </PackageTable>
                  </BusinessPortalCellContent>
                );
              }}
              width="15%"
            />
            <Cell<IOrderBP>
              header="Price"
              dataKey="price"
              align={CellAlign.Right}
              sortable={false}
              render={renderPrice}
              width="10%"
            />
            <Cell<IOrderBP>
              header="Actions"
              align={CellAlign.Right}
              width="5%"
              render={(order) => (
                <Actions<OrdersActionValue>
                  menuItems={getMenuItems(order)}
                  openDetailedInfo={() => openOrderFormSlidePanelHandler(order.id)}
                  openPaymentPage={() => openPaymentPage(order.id)}
                  downloadSubmissionForm={() => downloadSubmissionForm(order.id)}
                  deleteOrder={() => openDeleteModal(order.id)}
                />
              )}
            />
          </Table>

          {hasOrders && (
            <Pagination
              pagination={pagination}
              onPageSelect={handlePageSelect}
              onChangeCountPerPage={handleCountPerPage}
            />
          )}

          {isNoData && !hasHorses && <EmptyState type={EmptyStateType.Order} onClick={openHorseFormSlidePanel} />}
        </>
      </BusinessPortalTableWrapper>
    </>
  );
};
const mapStateToProps = (state: IAppState) => ({
  pagination: selectors.selectPagination(state),
  horses: horseSelectors.selectHorses(state),
  horsesLoading: horseSelectors.selectCommunication(state, 'horsesLoading'),
  orders: selectors.selectOrders(state),
  ordersLoading: selectors.selectCommunication(state, 'ordersLoading'),
  orderSubmissionFormRequesting: selectors.selectCommunication(state, 'orderSubmissionFormRequesting'),
  orderPaymentTokenLoading: paymentSelectors.selectCommunication(state, 'orderPaymentTokenLoading'),
  orderDeleting: selectors.selectCommunication(state, 'orderDeleting'),
});

const mapDispatchToProps = {
  getHorses: horseActions.getHorses,
  resetHorses: horseActions.resetHorses,
  getOrders: actions.getOrders,
  resetOrders: actions.resetOrders,
  getOrderPaymentToken: paymentActions.getOrderPaymentToken,
  downloadSubmissionForm: actions.downloadSubmissionForm,
  deleteOrder: actions.deleteOrder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withDynamicModules(connector(Orders), [
  BusinessPortalHorseModule,
  BusinessPortalOwnerModule,
  BusinessPortalOrderModule,
  BusinessPortalOrderPaymentModule,
]);
