import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';
import {ILocation} from 'Common/models/ICoordinatedLocation';
import {IEmployeeProfile} from 'BusinessPortal/models/employee/IEmployeeProfile';
import {Nullable} from 'Common/types';

const STATE_NAME = 'businessPortalEmployee';

export interface IBusinessPortalEmployeeProfileState {
  data: {
    employeeProfile: Nullable<IEmployeeProfile>;
    geoLocation: ILocation;
  };
  communications: {
    employeeProfileLoading: ICommunication;
    employeeProfileUpdating: ICommunication;
    geoLocationLoading: ICommunication;
    geoLocationUpdating: ICommunication;
    emailUpdating: ICommunication;
    passwordUpdating: ICommunication;
  };
}

export const getEmployeeProfileActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_EMPLOYEE_PROFILE');
export type getEmployeeProfileActions = MakeCommunicationActions<
  typeof getEmployeeProfileActionTypes,
  {success: IEmployeeProfile}
>;

export const updateEmployeeProfileActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_EMPLOYEE_PROFILE');
export type updateEmployeeProfileActions = MakeCommunicationActions<typeof updateEmployeeProfileActionTypes, {}>;

export const getEmployeeGeoLocationActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_EMPLOYEE_GEOLOCATION');
export type getEmployeeGeoLocationActions = MakeCommunicationActions<
  typeof getEmployeeGeoLocationActionTypes,
  {success: ILocation}
>;

export const updateEmployeeGeoLocationActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_EMPLOYEE_GEOLOCATION'
);
export type updateEmployeeGeoLocationActions = MakeCommunicationActions<
  typeof updateEmployeeGeoLocationActionTypes,
  {}
>;

export const updateEmployeeEmailActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_EMPLOYEE_EMAIL');
export type updateEmployeeEmailActions = MakeCommunicationActions<typeof updateEmployeeEmailActionTypes, {}>;

export const updateEmployeePasswordActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_EMPLOYEE_PASSWORD');
export type updateEmployeePasswordActions = MakeCommunicationActions<typeof updateEmployeePasswordActionTypes, {}>;

export type EmployeeActions =
  | getEmployeeProfileActions
  | updateEmployeeProfileActions
  | getEmployeeGeoLocationActions
  | updateEmployeeGeoLocationActions
  | updateEmployeeEmailActions
  | updateEmployeePasswordActions;
