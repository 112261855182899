import * as React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {zIndex} from 'Common/constants/ZIndex';
import {disableScrolling, enableScrolling} from 'Common/helpers/operateWithScroll';

const ModalWindowBody = styled.div`
  height: 100%;
  position: relative;
`;

const CloseButton = styled.div<{isLightbox: boolean}>`
  position: absolute;
  z-index: ${zIndex.modal + 1};
  top: ${props => (props.isLightbox ? '-36px' : '25px')};
  right: ${props => (props.isLightbox ? '0px' : '25px')};
`;

const style = (maxHeight: string, minWidth: string, maxWidth: string, isLightbox: boolean) => {
  return {
    overlay: {
      backgroundColor: ColorPalette.black2,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: zIndex.modal
    },
    content: {
      width: '100%',
      minWidth,
      maxWidth: maxWidth && maxWidth !== '' ? maxWidth : '80%',
      maxHeight,
      borderRadius: '4px',
      margin: '1rem',
      outline: 'none',
      backgroundColor: isLightbox ? ColorPalette.transparent0 : ColorPalette.white0,
      boxShadow: `0px 0px 20px ${ColorPalette.gray4}`
    }
  };
};

interface IProps {
  isOpen: boolean;
  hideCloseButton?: boolean;
  maxHeight?: string;
  minWidth?: string;
  maxWidth?: string;
  isLightbox?: boolean;
  onClose?: () => void;
}

type AllProps = IProps & React.HTMLAttributes<HTMLElement>;

const ModalWindow = (props: AllProps) => {
  const {
    maxHeight = 'fit-content',
    minWidth = '30%',
    maxWidth = '698px',
    isOpen,
    hideCloseButton,
    onClose,
    children,
    isLightbox = false
  } = props;

  return (
    <ReactModal
      isOpen={isOpen}
      appElement={document.getElementById('root') as HTMLElement}
      ariaHideApp={false}
      style={style(maxHeight, minWidth, maxWidth, isLightbox)}
      onRequestClose={onClose}
      onAfterOpen={disableScrolling}
      onAfterClose={enableScrolling}
      shouldCloseOnOverlayClick={false}
      className="modal-overlay-content"
    >
      {children && (
        <ModalWindowBody>
          {!hideCloseButton && onClose && (
            <CloseButton className="align-self-end" isLightbox={isLightbox}>
              <IconButton
                name={IconName.Close}
                onClick={onClose}
                color={isLightbox ? ColorPalette.white0 : ColorPalette.gray31}
                hoverColor={isLightbox ? ColorPalette.gray1 : ColorPalette.black2}
                fill={true}
                stroke={false}
                size={isLightbox ? 16 : 20}
              />
            </CloseButton>
          )}
          {children}
        </ModalWindowBody>
      )}
    </ReactModal>
  );
};

ModalWindow.defaultProps = {
  hideCloseButton: false
} as Partial<IProps>;

export default ModalWindow;
