import React, {useCallback, useEffect, useMemo} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {GenePoolsType} from 'Filters/models/GenePools';
import {IAppState} from 'Common/store/IAppState';
import {ExpansionPanel, Section} from 'Common/components/ExpansionPanel/ExpansionPanel';
import {CheckboxGroupField, SelectField} from 'Common/components/FormFields';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import ColorPalette from 'Common/constants/ColorPalette';

import {Checkbox} from 'Common/components/Controls';
import {ICommercialType} from 'Dictionaries/models/ICommercialType';
import TabSelectField from 'Common/components/FormFields/TabSelectField';
import {actions, selectors} from 'Filters/store';

import {FilterText, FilterTextBold, SectionHint, SectionLabel, SelectWrapper} from './shared';
import {castToOption} from 'Common/helpers/OptionHelper';
import SelectedValues from 'Filters/components/shared/SelectedValues/SelectedValues';
import {IAssociation} from 'Dictionaries/models/IAssociation';
import {useDeepCompareEffect} from 'Common/helpers/hooks/useDeepCompareEffect';
import SwitchField from 'Common/components/FormFields/SwitchField';
import ButtonAsLink from 'Common/components/Controls/Buttons/ButtonAsLink';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {VisitorType} from 'Common/constants/VisitorType';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {HorseFiltersGender} from '../formConfig';

export const MAX_LOCATION_RADIUS = 1000;

const locationTabs = [
  {value: 50, label: '50 km'},
  {value: 500, label: '500 km'},
  {value: MAX_LOCATION_RADIUS, label: '1000 km'},
];

const pools: Array<{name: GenePoolsType; label: string; disabled?: boolean}> = [
  {name: GenePoolsType.AllPublic, label: 'All available profiles'},
  {name: GenePoolsType.MyHorses, label: 'My horses'},
  {name: GenePoolsType.Favorite, label: 'My favorites'},
  {name: GenePoolsType.LocationRadius, label: 'Location'},
  {name: GenePoolsType.Registries, label: 'Registries'},
];

const GenPool = styled.div`
  display: flex;
  align-items: center;
  line-height: 16px;
  font-size: ${Typography.size.size16};
  font-family: ${Theme.font.primary};
`;

const GenPoolCount = styled.div`
  margin-left: auto;
  color: ${ColorPalette.gray44};
`;

interface IOwnProps {
  selectedCount: number;
  distance?: number;
  selectedGenePools: string[];
  commercialTypes: ICommercialType[];
  selectedCommercialTypes: number[];
  associations: IAssociation[];
  selectedAssociations: number[];
  selectedGender?: HorseFiltersGender;
  onOpenExcludeModal?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IOwnProps;

function GenePools(props: Props) {
  const {
    selectedCount,
    getGenePools,
    genePools,
    selectedGenePools,
    commercialTypes,
    selectedCommercialTypes,
    associations,
    selectedAssociations,
    distance,
    selectedGender,
    onOpenExcludeModal,
  } = props;

  const {isVisitorType} = useVisitorTypeService();

  useDeepCompareEffect(() => {
    if (distance === undefined) {
      return;
    }

    if (isVisitorType(VisitorType.AssociationEmployee)) {
      return;
    }

    getGenePools({distance: distance === 0 ? {} : {value: distance}, associations: selectedAssociations});
  }, [getGenePools, distance, selectedAssociations]);

  const onUpdateGenePool = useCallback(() => {
    if (isVisitorType(VisitorType.AssociationEmployee)) {
      return;
    }

    getGenePools({distance: distance === 0 ? {} : {value: distance}, associations: selectedAssociations});
  }, [distance, getGenePools, isVisitorType, selectedAssociations]);
  const {listen, removeListener} = useEventBus();

  useEffect(() => {
    listen(EventName.GenePoolChanged, onUpdateGenePool);

    return () => {
      removeListener(EventName.GenePoolChanged, () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isShowAssociations = selectedGenePools.includes(GenePoolsType.Registries);
  const isShowLocation = selectedGenePools.includes(GenePoolsType.LocationRadius);

  const associationsOptions = useMemo(() => castToOption(associations), [associations]);

  return (
    <ExpansionPanel title="Gene pools" headerContent={<SectionHint count={selectedCount} />}>
      <Section>
        <CheckboxGroupField name="sourceTypes" isNumericValues={false}>
          {({onChange}) => (
            <>
              {pools.map((pool) => (
                <GenPool key={pool.name}>
                  <Checkbox
                    onChange={onChange}
                    name={pool.name}
                    checked={!!selectedGenePools.find((value) => value === pool.name)}
                    label={pool.label}
                    disabled={pool.disabled}
                  />
                  <GenPoolCount>{!pool.disabled && genePools ? genePools[pool.name] : 0}</GenPoolCount>
                </GenPool>
              ))}
            </>
          )}
        </CheckboxGroupField>
      </Section>
      {isShowAssociations && (
        <Section>
          <SectionLabel>Associations</SectionLabel>
          <SelectWrapper>
            <SelectField
              isMulti={true}
              name="associations"
              options={associationsOptions}
              controlShouldRenderValue={false}
              blurInputOnSelect={true}
              isClearable={false}
            />
          </SelectWrapper>
          <SelectedValues name="associations" options={associationsOptions} />
        </Section>
      )}
      {isShowLocation && (
        <Section>
          <SectionLabel>Location Radius</SectionLabel>
          <TabSelectField name="distance.value" items={locationTabs} />
        </Section>
      )}
      <Section>
        <SectionLabel>Available for</SectionLabel>
        <CheckboxGroupField name="commercialTypes">
          {commercialTypes?.map((item) => {
            const isDisabled =
              selectedGender !== 'all' && !item.genders.some((x) => x === selectedGender) && item.genders.length > 0;

            return (
              <Checkbox
                key={item.id}
                name={String(item.id)}
                checked={!!selectedCommercialTypes.find((value) => value === item.id)}
                disabled={isDisabled}
                label={
                  <Tooltip content={item.genders.length > 0 ? `Applies to ${item.genders.join(', ')}` : ''}>
                    <div>{item.name}</div>
                  </Tooltip>
                }
              />
            );
          })}
        </CheckboxGroupField>
      </Section>
      <Section>
        <div className="d-flex justify-content-between align-items-center">
          <SectionLabel>Excluded horses</SectionLabel>
          <ButtonAsLink onClick={onOpenExcludeModal}>Edit</ButtonAsLink>
        </div>
        <SelectWrapper className="d-flex flex-column align-items-center">
          <div>
            <FilterTextBold>{genePools ? genePools['ExcludedHorses'] : 0}</FilterTextBold>&nbsp;
            <FilterText>horses excluded</FilterText>
          </div>
          <SelectWrapper>
            <SwitchField
              name="isHideExcludedHorses"
              textRight={<FilterText>Show excluded horse in results</FilterText>}
              style={{paddingLeft: 0}}
            />
          </SelectWrapper>
        </SelectWrapper>
      </Section>
    </ExpansionPanel>
  );
}

const mapStateToProps = (state: IAppState) => ({
  genePools: selectors.selectGenePools(state),
  isGenePoolsLoading: selectors.selectCommunication(state, 'genePoolsLoading'),
});

const mapDispatchToProps = {
  getGenePools: actions.getAllGenePools,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(GenePools);
