import {IconName} from 'Icon/components/Icon';
import {OrderStatus} from 'Common/constants/OrderStatus';
import ColorPalette from 'Common/constants/ColorPalette';
import {IOrderStatusMain} from 'Admin/shared/components/OrderStatus/constants';

export const statuses: Record<OrderStatus, IOrderStatusMain> = {
  [OrderStatus.orderPlaced]: {icon: IconName.Clock, label: 'Order placed', color: ColorPalette.gray44},
  [OrderStatus.paymentReceived]: {icon: IconName.Money, label: 'Payment received', color: ColorPalette.yellow9},
  [OrderStatus.sampleReceived]: {icon: IconName.Truck, label: 'Sample received', color: ColorPalette.yellow9},
  [OrderStatus.resultsReady]: {icon: IconName.CheckedQuestionnaire, label: 'Results ready', color: ColorPalette.green2},
  [OrderStatus.reviewReady]: {icon: IconName.InsertComment, label: 'Reviewed', color: ColorPalette.green2},
  [OrderStatus.releaseReady]: {icon: IconName.FilledCheckbox, label: 'Order complete', color: ColorPalette.green2},
};

export const userStatuses: Record<OrderStatus, IOrderStatusMain> = {
  [OrderStatus.orderPlaced]: statuses[OrderStatus.orderPlaced],
  [OrderStatus.paymentReceived]: statuses[OrderStatus.paymentReceived],
  [OrderStatus.sampleReceived]: statuses[OrderStatus.sampleReceived],
  [OrderStatus.resultsReady]: statuses[OrderStatus.sampleReceived],
  [OrderStatus.reviewReady]: statuses[OrderStatus.sampleReceived],
  [OrderStatus.releaseReady]: statuses[OrderStatus.resultsReady],
};
