import {IDictionary} from 'DictionaryFactory/types';
import {IAppState} from 'Common/store/IAppState';
import {ICommunication} from 'Common/store/utils/communication/index';
import {IPagination} from 'Common/models/IResponse';

export interface IPhenotypesSelectors<T> {
  items: T[];
  itemsLoading: ICommunication;
  itemDeleting: ICommunication;
  pagination: IPagination;
}

export function makePhenotypeSelectors<T>(
  state: IAppState,
  dictionary: IDictionary<T, unknown>
): IPhenotypesSelectors<T> {
  const {selectors} = dictionary;
  return {
    items: selectors.selectItems(state),
    itemsLoading: selectors.selectCommunication(state, 'itemsLoading'),
    itemDeleting: selectors.selectCommunication(state, 'itemDeleting'),
    pagination: selectors.selectPagination(state)
  };
}
