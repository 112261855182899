import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IRequestParams} from 'Common/models/IRequestParams';

import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {ICreateHorseOwnerBP, IUpdateHorseOwnerBP} from 'BusinessPortal/models/owner/IOwnerBP';
import {
  BusinessPortalOwnerActions,
  createOwnerActions,
  createOwnerActionTypes,
  deleteOwnerActions,
  deleteOwnerActionTypes,
  deleteOwnerAvatarActions,
  deleteOwnerAvatarActionTypes,
  getOwnerDetailsActions,
  getOwnerDetailsActionTypes,
  getOwnersActions,
  getOwnersActionTypes,
  resetOwnerDetailsActionTypes,
  resetOwnersActionTypes,
  updateOwnerActions,
  updateOwnerActionTypes,
  updateOwnerAvatarActions,
  updateOwnerAvatarActionTypes,
} from './types';
import BusinessPortalOwnerService from 'BusinessPortal/services/BusinessPortalOwnerService';

export const getOwners =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getOwnersActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOwnersActionTypes.REQUEST));
      const owners = await BusinessPortalOwnerService.getOwners(params);
      dispatch(action(getOwnersActionTypes.SUCCESS, owners));
    } catch (error) {
      dispatch(action(getOwnersActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOwnerDetails =
  (ownerId: number): ActionResult<IAppState, Promise<void>, getOwnerDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOwnerDetailsActionTypes.REQUEST));
      const data = await BusinessPortalOwnerService.getOwnerDetails(ownerId);
      dispatch(action(getOwnerDetailsActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getOwnerDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createOwner =
  (data: ICreateHorseOwnerBP): ActionResult<IAppState, Promise<number>, createOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createOwnerActionTypes.REQUEST));
      const ownerId = await BusinessPortalOwnerService.createOwner(data);
      dispatch(action(createOwnerActionTypes.SUCCESS));
      return ownerId;
    } catch (error) {
      const err = getErrorMessage(error);
      dispatch(action(createOwnerActionTypes.FAILURE, err));
      throw err;
    }
  };

export const updateOwner =
  (data: IUpdateHorseOwnerBP): ActionResult<IAppState, Promise<void>, updateOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateOwnerActionTypes.REQUEST));
      await BusinessPortalOwnerService.updateOwner(data);
      dispatch(action(updateOwnerActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateOwnerAvatar =
  (ownerId: number, uploadedFile: File): ActionResult<IAppState, Promise<void>, updateOwnerAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateOwnerAvatarActionTypes.REQUEST));
      await BusinessPortalOwnerService.updateOwnerAvatar(ownerId, uploadedFile);
      dispatch(action(updateOwnerAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateOwnerAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteOwnerAvatar =
  (ownerId: number): ActionResult<IAppState, Promise<void>, deleteOwnerAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteOwnerAvatarActionTypes.REQUEST));
      await BusinessPortalOwnerService.deleteOwnerAvatar(ownerId);
      dispatch(action(deleteOwnerAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteOwnerAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteOwner =
  (ownerId: number): ActionResult<IAppState, Promise<void>, deleteOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteOwnerActionTypes.REQUEST));
      await BusinessPortalOwnerService.deleteOwner(ownerId);
      dispatch(action(deleteOwnerActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetOwners = (): ActionResult<IAppState, void, BusinessPortalOwnerActions> => (dispatch) => {
  dispatch(action(resetOwnersActionTypes.RESET));
};

export const resetOwnerDetails = (): ActionResult<IAppState, void, BusinessPortalOwnerActions> => (dispatch) => {
  dispatch(action(resetOwnerDetailsActionTypes.RESET));
};
