import React, {memo} from 'react';
import styled from 'styled-components';
import {IProbabilityEntity} from 'Common/models/IProbabilityEntity';
import Typography from 'Common/constants/Typography';
import Tooltip, {TooltipProps} from 'Common/components/Tooltip/Tooltip';
import getProbabilityColor from 'Common/helpers/getProbabilityColor';
import Theme from 'Common/constants/Theme';

const Root = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 32px;
  column-gap: 8px;
  line-height: 16px;
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size14};
`;

const ProbabilityName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${Theme.color.black};

  :first-letter {
    text-transform: capitalize;
  }
`;

const Probability = styled.div<{color: string}>`
  font-weight: ${Typography.weight.bold700};
  color: ${p => p.color};
  text-align: right;
`;

function renderProbability(prob: number, higherIsBetter?: boolean) {
  const color = getProbabilityColor(prob, higherIsBetter);

  return <Probability color={color}>{`${prob}%`}</Probability>;
}

export interface IProps {
  probability: IProbabilityEntity;
  higherIsBetter: boolean;
  showTooltip?: boolean;
  tooltipConfig?: TooltipProps;
}

function ProbabilityListItem(props: IProps) {
  const {probability, higherIsBetter, showTooltip = true, tooltipConfig} = props;

  return (
    <Tooltip {...tooltipConfig} content={showTooltip ? probability.description : ''}>
      <Root>
        <ProbabilityName>{probability.name}</ProbabilityName>
        {renderProbability(probability.probability || 100, higherIsBetter)}
      </Root>
    </Tooltip>
  );
}

export default memo(ProbabilityListItem);
