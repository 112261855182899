import React, {memo} from 'react';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout, {
  DictionaryFormProps
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';

import {IDictionary} from 'DictionaryFactory/types';
import HealthVariantForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/HealthVariants/HealthVariantForm/HealthVariantForm';
import {IAdminHealthVariant} from 'Admin/AdminPhenotypes/models/IAdminHealthVariant';
import {IUpdateHealthVariantRequest} from 'Admin/AdminPhenotypes/service/models/IServerHealthVariant';

function HealthVariants() {
  const {adminHealthVariants: dictionary, healthIssueGroupsAdvanced} = useDictionaries();

  const wrappedHealthVariantForm = (
    props: DictionaryFormProps<IDictionary<IAdminHealthVariant, IUpdateHealthVariantRequest>>
  ) => {
    return <HealthVariantForm {...props} healthVariantGroupDictionary={healthIssueGroupsAdvanced} />;
  };

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={wrappedHealthVariantForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IAdminHealthVariant> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
            <Cell<IAdminHealthVariant> dataKey="name" width="20%" header="Name" render={({name}) => name} />
            <Cell<IAdminHealthVariant>
              dataKey="group"
              width="15%"
              header="Group"
              sortable={false}
              render={({group}) => group?.name}
            />
            <Cell<IAdminHealthVariant>
              dataKey="description"
              width="35%"
              header="Description"
              sortable={false}
              render={({description}) => description?.value}
            />
            <Cell<IAdminHealthVariant>
              dataKey="externalDescriptionUrl"
              width="20%"
              header="URL"
              sortable={false}
              render={({externalDescriptionUrl}) => externalDescriptionUrl}
            />
            <Cell<IAdminHealthVariant>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(HealthVariants);
