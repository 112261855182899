import React, {memo, useCallback} from 'react';
import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import {IconName} from 'Icon/components/Icon';
import Theme from 'Common/constants/Theme';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';
import Tooltip from 'Common/components/Tooltip/Tooltip';

const Root = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: baseline;
  :not(:last-child) {
    border-bottom: 1px solid ${ColorPalette.gray47};
  }
`;

const SelectedValueLabel = styled.div`
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size15};
  line-height: 24px;
  flex-grow: 1;
  outline: none;
`;

const RemoveValueIcon = styled(IconButton)`
  flex-shrink: 0;
  margin-left: auto;
`;

const Point = styled.div`
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 50%;
  background: ${Theme.color.primary};
`;

interface IProps {
  label: string;
  value: number | string;
  withDot?: boolean;
  description?: string;
  onRemove(value: number | string): void;
}

function SelectedValue(props: IProps) {
  const {label, value, onRemove, withDot, description} = props;

  const remove = useCallback(() => onRemove(value), [value, onRemove]);
  return (
    <Root>
      {withDot && <Point />}
      <Tooltip content={description}>
        <SelectedValueLabel>{label}</SelectedValueLabel>
      </Tooltip>
      <RemoveValueIcon color="#D4D4D4" name={IconName.Close} size={10} onClick={remove} />
    </Root>
  );
}

export default memo(SelectedValue);
