import {combineReducers} from 'redux';

import {
  getHtmlTemplatesActionTypes,
  IAdminHtmlTemplateState,
  HtmlTemplatesActions,
  deleteHtmlTemplateActionTypes,
  uploadHtmlTemplateActionTypes,
  getHtmlTemplateActionTypes
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';

export const communicationHtmlTemplatesReducer = combineReducers<
  IAdminHtmlTemplateState['communications'],
  HtmlTemplatesActions
>({
  templatesLoading: makeCommunicationReducerFromEnum(getHtmlTemplatesActionTypes),
  templateUploading: makeCommunicationReducerFromEnum(uploadHtmlTemplateActionTypes),
  templateDeleting: makeCommunicationReducerFromEnum(deleteHtmlTemplateActionTypes),
  templateLoading: makeCommunicationReducerFromEnum(getHtmlTemplateActionTypes)
});
