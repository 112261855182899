import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication/index';

import {
  activateSampleIdActionTypes,
  AdminHorsesActions,
  createHorseActionTypes,
  createSampleActionTypes,
  deleteHorseActionTypes,
  getHorseActionTypes,
  getHorsesActionTypes,
  getHorseSamplesActionTypes,
  getShortHorseActionTypes,
  hideAncestryReportActionTypes,
  hideGeneticDataActionTypes,
  horseArchiveActionTypes,
  IAdminHorsesState,
  resetHorseActionTypes,
  resetHorseDetailsActionTypes,
  updateHorseActionTypes,
  updateSampleIdActionTypes,
} from '../types';
import composeReducers from 'Common/store/composeReducers';

export const communicationHorsesReducer = combineReducers<IAdminHorsesState['communications'], AdminHorsesActions>({
  horsesLoading: composeReducers([
    makeCommunicationReducerFromEnum(getHorsesActionTypes),
    makeResetCommunicationReducer(resetHorseActionTypes.RESET),
  ]),
  shortHorseLoading: composeReducers([
    makeCommunicationReducerFromEnum(getShortHorseActionTypes),
    makeResetCommunicationReducer(resetHorseDetailsActionTypes.RESET),
  ]),
  horseLoading: makeCommunicationReducerFromEnum(getHorseActionTypes),
  horseCreating: makeCommunicationReducerFromEnum(createHorseActionTypes),
  horseUpdating: makeCommunicationReducerFromEnum(updateHorseActionTypes),
  horseDeleting: makeCommunicationReducerFromEnum(deleteHorseActionTypes),
  horseArchiving: makeCommunicationReducerFromEnum(horseArchiveActionTypes),
  geneticDataHiding: makeCommunicationReducerFromEnum(hideGeneticDataActionTypes),
  sampleIdUpdating: makeCommunicationReducerFromEnum(updateSampleIdActionTypes),
  sampleIdActivating: makeCommunicationReducerFromEnum(activateSampleIdActionTypes),
  horseSamplesLoading: makeCommunicationReducerFromEnum(getHorseSamplesActionTypes),
  sampleCreating: makeCommunicationReducerFromEnum(createSampleActionTypes),
  ancestryReportHiding: makeCommunicationReducerFromEnum(hideAncestryReportActionTypes),
});
