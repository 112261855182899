import {IListResponse} from 'Common/models/IResponse';
import {ICommunication, makeCommunicationActionType} from 'Common/store/utils/communication';
import {
  MakeCommunicationActions,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {IHorseDetailsBP} from 'BusinessPortal/models/horse/IHorseDetailsBP';
import {IHorseBP} from 'BusinessPortal/models/horse/IHorseBP';
import {IHorseOwnerBP} from 'BusinessPortal/models/horse/IHorseOwnerBP';
import {IHorseParentageBP} from 'BusinessPortal/models/horse/IHorseParentageBP';
import {IRegistry} from 'Horse/models/IRegistry';
import {IInconclusiveHealthIssue} from 'Dictionaries/models/InconclusiveHealthIssues';
import {IMediaResource} from 'Common/models/IMediaResource';
import {IAssociationHorseState} from 'Shared/components/Association/models/IAssociationHorseState';
import {IAdminAssociationHorse} from 'Admin/AdminAssociations/models/IAdminAssociationHorse';
import {IHorseSample} from 'Admin/AdminDashboard/models/Order/IHorseSample';
import {Nullable} from 'Common/types';

const STATE_NAME = 'adminAssociationHorse';

export interface IAdminAssociationHorseStateWrapper {
  data: {
    horseSample: Nullable<IHorseSample>;
  };
  communications: {
    horseSampleLoading: ICommunication;
  };
}

export type IAdminAssociationHorseState = IAdminAssociationHorseStateWrapper &
  IAssociationHorseState<IAdminAssociationHorse>;

export const getAdminAssociationHorsesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ADMIN_ASSOCIATION_HORSES'
);
export type getAdminAssociationHorsesActions = MakeCommunicationActions<
  typeof getAdminAssociationHorsesActionTypes,
  {success: IListResponse<IHorseBP>}
>;

export const createAdminAssociationHorseActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CREATE_ADMIN_ASSOCIATION_HORSE'
);
export type createAdminAssociationHorseActions = MakeCommunicationActions<
  typeof createAdminAssociationHorseActionTypes,
  {}
>;

export const getAdminAssociationHorseDetailsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ADMIN_ASSOCIATION_HORSE_DETAILS'
);
export type getAdminAssociationHorseDetailsActions = MakeCommunicationActions<
  typeof getAdminAssociationHorseDetailsActionTypes,
  {success: IHorseDetailsBP}
>;

export const getAdminAssociationHorseOwnerActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ADMIN_ASSOCIATION_HORSE_OWNER'
);
export type getAdminAssociationHorseOwnerActions = MakeCommunicationActions<
  typeof getAdminAssociationHorseOwnerActionTypes,
  {success: IHorseOwnerBP}
>;

export const getAdminAssociationHorseParentageActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ADMIN_ASSOCIATION_HORSE_PARENTAGE'
);
export type getAdminAssociationHorseParentageActions = MakeCommunicationActions<
  typeof getAdminAssociationHorseParentageActionTypes,
  {success: IHorseParentageBP[]}
>;

export const getAdminAssociationHorseRegistriesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ADMIN_ASSOCIATION_HORSE_REGISTRIES'
);
export type getAdminAssociationHorseRegistriesActions = MakeCommunicationActions<
  typeof getAdminAssociationHorseRegistriesActionTypes,
  {success: IRegistry[]}
>;

export const getAdminAssociationHorseHealthIssuesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ADMIN_ASSOCIATION_HORSE_HEALTH_ISSUES'
);
export type getAdminAssociationHorseHealthIssuesActions = MakeCommunicationActions<
  typeof getAdminAssociationHorseHealthIssuesActionTypes,
  {success: IInconclusiveHealthIssue[]}
>;

export const updateAdminAssociationHorseDetailsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ADMIN_ASSOCIATION_HORSE_DETAILS'
);
export type updateAdminAssociationHorseDetailsActions = MakeCommunicationActions<
  typeof updateAdminAssociationHorseDetailsActionTypes,
  {}
>;

export const setAdminAssociationHorseOwnerActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'SET_ADMIN_ASSOCIATION_HORSE_OWNER'
);
export type setAdminAssociationHorseOwnerActions = MakeCommunicationActions<
  typeof setAdminAssociationHorseOwnerActionTypes,
  {}
>;

export const updateAdminAssociationHorseParentageActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ADMIN_ASSOCIATION_HORSE_PARENTAGE'
);
export type updateAdminAssociationHorseParentageActions = MakeCommunicationActions<
  typeof updateAdminAssociationHorseParentageActionTypes,
  {}
>;

export const updateAdminAssociationHorseRegistriesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ADMIN_ASSOCIATION_HORSE_REGISTRIES'
);
export type updateAdminAssociationHorseRegistriesActions = MakeCommunicationActions<
  typeof updateAdminAssociationHorseRegistriesActionTypes,
  {}
>;

export const updateAdminAssociationHorseHealthIssuesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ADMIN_ASSOCIATION_HORSE_HEALTH_ISSUES'
);
export type updateAdminAssociationHorseHealthIssuesActions = MakeCommunicationActions<
  typeof updateAdminAssociationHorseHealthIssuesActionTypes,
  {}
>;

export const updateAdminAssociationHorseAvatarActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ADMIN_ASSOCIATION_HORSE_AVATAR'
);
export type updateAdminAssociationHorseAvatarActions = MakeCommunicationActions<
  typeof updateAdminAssociationHorseAvatarActionTypes,
  {}
>;

export const deleteAdminAssociationHorseActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DELETE_ADMIN_ASSOCIATION_HORSE'
);
export type deleteAdminAssociationHorseActions = MakeCommunicationActions<
  typeof deleteAdminAssociationHorseActionTypes,
  {}
>;

export const deleteAdminAssociationHorseAvatarActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DELETE_ADMIN_ASSOCIATION_HORSE_AVATAR'
);
export type deleteAdminAssociationHorseAvatarActions = MakeCommunicationActions<
  typeof deleteAdminAssociationHorseAvatarActionTypes,
  {}
>;

export const getAdminAssociationHorseMediaActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ADMIN_ASSOCIATION_HORSE_MEDIA'
);
export type getAdminAssociationHorseMediaActions = MakeCommunicationActions<
  typeof getAdminAssociationHorseMediaActionTypes,
  {success: IMediaResource[]}
>;

export const deleteAdminAssociationHorseMediaActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DELETE_ADMIN_ASSOCIATION_HORSE_MEDIA'
);
export type deleteAdminAssociationHorseMediaActions = MakeCommunicationActions<
  typeof deleteAdminAssociationHorseMediaActionTypes,
  {}
>;

export const createAdminAssociationHorseImageMediaActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CREATE_ADMIN_ASSOCIATION_HORSE_IMAGE_MEDIA'
);
export type createAdminAssociationHorseImageMediaActions = MakeCommunicationActions<
  typeof createAdminAssociationHorseImageMediaActionTypes,
  {}
>;

export const createAdminAssociationHorseVideoMediaActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CREATE_ADMIN_ASSOCIATION_HORSE_VIDEO_MEDIA'
);
export type createAdminAssociationHorseVideoMediaActions = MakeCommunicationActions<
  typeof createAdminAssociationHorseVideoMediaActionTypes,
  {}
>;

export const getAdminAssociationHorseSampleActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_SAMPLES');
export type getAdminAssociationHorseSampleActions = MakeCommunicationActions<
  typeof getAdminAssociationHorseSampleActionTypes,
  {success: IHorseSample}
>;

export const resetAdminAssociationHorsesActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'ADMIN_ASSOCIATION_HORSES'
);
export type resetAdminAssociationHorsesActions = MakeResetCommunicationActions<
  typeof resetAdminAssociationHorsesActionTypes
>;

export const resetAdminAssociationHorseMediaActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'ADMIN_ASSOCIATION_HORSE_MEDIA'
);
export type resetAdminAssociationHorseMediaActions = MakeResetCommunicationActions<
  typeof resetAdminAssociationHorseMediaActionTypes
>;

export const resetAdminAssociationHorseDetailsActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'ADMIN_ASSOCIATION_HORSE_DETAILS'
);
export type resetAdminAssociationHorseDetailsActions = MakeResetCommunicationActions<
  typeof resetAdminAssociationHorseDetailsActionTypes
>;

export const resetAdminAssociationHorseOwnerActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'ADMIN_ASSOCIATION_HORSE_OWNER'
);
export type resetAdminAssociationHorseOwnerActions = MakeResetCommunicationActions<
  typeof resetAdminAssociationHorseOwnerActionTypes
>;

export const resetAdminAssociationHorseParentageActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'ADMIN_ASSOCIATION_HORSE_PARENTAGE'
);
export type resetAdminAssociationHorseParentageActions = MakeResetCommunicationActions<
  typeof resetAdminAssociationHorseParentageActionTypes
>;

export const resetAdminAssociationHorseRegistriesActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'ADMIN_ASSOCIATION_HORSE_REGISTRIES'
);
export type resetAdminAssociationHorseRegistriesActions = MakeResetCommunicationActions<
  typeof resetAdminAssociationHorseRegistriesActionTypes
>;

export const resetAdminAssociationHorseHealthIssuesActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'ADMIN_ASSOCIATION_HORSE_HEALTH_ISSUES'
);
export type resetAdminAssociationHorseHealthIssuesActions = MakeResetCommunicationActions<
  typeof resetAdminAssociationHorseHealthIssuesActionTypes
>;

export const resetAdminAssociationHorseSampleActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'ADMIN_ASSOCIATION_HORSE_SAMPLE'
);
export type resetAdminAssociationHorseSampleActions = MakeResetCommunicationActions<
  typeof resetAdminAssociationHorseSampleActionTypes
>;

export type AdminAssociationHorseActions =
  | createAdminAssociationHorseImageMediaActions
  | createAdminAssociationHorseVideoMediaActions
  | getAdminAssociationHorseMediaActions
  | getAdminAssociationHorsesActions
  | createAdminAssociationHorseActions
  | setAdminAssociationHorseOwnerActions
  | getAdminAssociationHorseDetailsActions
  | getAdminAssociationHorseOwnerActions
  | getAdminAssociationHorseParentageActions
  | getAdminAssociationHorseRegistriesActions
  | getAdminAssociationHorseHealthIssuesActions
  | updateAdminAssociationHorseDetailsActions
  | updateAdminAssociationHorseParentageActions
  | updateAdminAssociationHorseRegistriesActions
  | updateAdminAssociationHorseHealthIssuesActions
  | updateAdminAssociationHorseAvatarActions
  | deleteAdminAssociationHorseMediaActions
  | deleteAdminAssociationHorseAvatarActions
  | getAdminAssociationHorseSampleActions
  | resetAdminAssociationHorsesActions
  | resetAdminAssociationHorseDetailsActions
  | resetAdminAssociationHorseOwnerActions
  | resetAdminAssociationHorseParentageActions
  | resetAdminAssociationHorseRegistriesActions
  | resetAdminAssociationHorseHealthIssuesActions
  | resetAdminAssociationHorseMediaActions
  | resetAdminAssociationHorseSampleActions;
