import React, {useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import BahSubscriptionForm from 'SubscriptionWelcome/components/BahSubscription/BahSubscriptionForm';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'StripeSubscription/store/index';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {StripeSubscriptionModule} from 'StripeSubscription/store/stripeSubscriptionModule';
import {hasPaymentProblemStatuses} from 'UserProfile/components/ProfileSettings/parts/UserSubscriptions/parts/common/constants';
import ProblemPaymentMessage from 'SubscriptionWelcome/components/BahSubscription/ProblemPaymentMessage';
import Loading from 'Loading/components/Loading';
import {actions as permissionsAction, selectors as permissionsSelectors} from 'Permissions/store';
import {actions as userActions, selectors as userSelectors} from 'UserProfile/store/currentUser';
import Footer from 'SubscriptionWelcome/components/BahSubscription/parts/Footer';
import Body from 'SubscriptionWelcome/components/BahSubscription/parts/Body';
import Header from 'SubscriptionWelcome/components/BahSubscription/parts/Header';

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const BahSubscription = (props: AllProps) => {
  const {
    isCustomerExist,
    userSubscriptionsLoading,
    isCustomerExistLoading,
    userSubscriptions,
    getUserSubscriptions,
    getCurrentPermissions,
    currentPermissionsLoading,
    currentUserLoading,
    getCurrentUser,
  } = props;

  const [isPartialSuccess, setIsPartialSuccess] = useState(false);
  const onPartialSuccess = useCallback(() => setIsPartialSuccess(true), [setIsPartialSuccess]);

  const isLoading =
    isCustomerExistLoading.isRequesting ||
    userSubscriptionsLoading.isRequesting ||
    currentPermissionsLoading.isRequesting ||
    currentUserLoading.isRequesting;

  const getSubscriptions = useCallback(async () => {
    await getCurrentUser();
    await getCurrentPermissions();
    const isStripeCustomerExist = await isCustomerExist();
    if (isStripeCustomerExist) {
      await getUserSubscriptions();
    }
  }, [getCurrentUser, getCurrentPermissions, isCustomerExist, getUserSubscriptions]);

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  const paymentProblemSubscriptions = userSubscriptions?.filter((i) =>
    hasPaymentProblemStatuses.some((j) => j === i?.status)
  );
  const hasPaymentProblemSubscriptions = paymentProblemSubscriptions && paymentProblemSubscriptions?.length > 0;

  const [isBahSubscriptionModalOpen, setIsBahSubscriptionModalOpen] = useState(false);
  const openBahSubscriptionModal = useCallback(() => setIsBahSubscriptionModalOpen(true), []);
  const closeBahSubscriptionModal = useCallback(
    async (afterSuccess?: boolean) => {
      setIsBahSubscriptionModalOpen(false);
      (isPartialSuccess || afterSuccess) && (await getSubscriptions());
    },
    [getSubscriptions, isPartialSuccess]
  );
  const successBahSubscriptionModal = useCallback(async () => {
    await closeBahSubscriptionModal(true);
  }, [closeBahSubscriptionModal]);

  if (isLoading) {
    return (
      <BaseLayout withoutPaddings={true} isBackButtonDenied={true}>
        <Loading />
      </BaseLayout>
    );
  }

  if (hasPaymentProblemSubscriptions) {
    return (
      <BaseLayout withoutPaddings={true} isBackButtonDenied={true}>
        <ProblemPaymentMessage />
      </BaseLayout>
    );
  }

  return (
    <BaseLayout withoutPaddings={true} isBackButtonDenied={true}>
      <ModalWindow isOpen={isBahSubscriptionModalOpen} onClose={() => closeBahSubscriptionModal()}>
        <BahSubscriptionForm onSuccess={successBahSubscriptionModal} onPartialSuccess={onPartialSuccess} />
      </ModalWindow>

      <div className="d-flex flex-column w-100 justify-content-center align-items-center">
        <Header onSubscribeClick={openBahSubscriptionModal} />
        <Body />
        <Footer onSubscribeClick={openBahSubscriptionModal} />
      </div>
    </BaseLayout>
  );
};
const mapStateToProps = (state: IAppState) => ({
  userSubscriptions: selectors.selectUserSubscriptions(state),
  currentPermissions: permissionsSelectors.selectCurrentPermissions(state),
  currentPermissionsLoading: permissionsSelectors.selectCommunication(state, 'currentPermissionsLoading'),
  userSubscriptionsLoading: selectors.selectCommunication(state, 'userSubscriptionsLoading'),
  currentUserLoading: userSelectors.selectCommunication(state, 'currentUserLoading'),
  isCustomerExistLoading: selectors.selectCommunication(state, 'isCustomerExistLoading'),
});

const mapDispatchToProps = {
  isCustomerExist: actions.isCustomerExist,
  getUserSubscriptions: actions.getUserSubscriptions,
  getCurrentPermissions: permissionsAction.getCurrentPermissions,
  getCurrentUser: userActions.getCurrentUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(BahSubscription);
export default withDynamicModules(Connected, StripeSubscriptionModule);
