import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.adminAssociationOwner;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export const selectOwners = (state: IAppState) => selectState(state).data.owners;
export const selectPagination = (state: IAppState) => selectState(state).ui.pagination;
export const selectOwnerDetails = (state: IAppState) => selectState(state).data.ownerDetails;
