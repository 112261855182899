import {Reducer} from 'redux';
import {IResetPasswordState, resetPasswordActions, resetPasswordActionTypes} from 'ResetPassword/store/types';

const initialState: IResetPasswordState['data'] = {
  message: null,
};

export const dataResetPasswordReducer: Reducer<IResetPasswordState['data'], resetPasswordActions> = (
  state = initialState,
  action: resetPasswordActions
) => {
  switch (action.type) {
    case resetPasswordActionTypes.SUCCESS: {
      return {...state, message: action.payload};
    }
    default: {
      return state;
    }
  }
};
