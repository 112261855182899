import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import ImageUploader, {ImageUploaderProps} from 'Image/components/ImageUploader';
import {IImage} from 'Image/models/IImage';
import ColorPalette from 'Common/constants/ColorPalette';
import VideoUploader, {VideoUploaderProps} from 'Video/components/VideoUploader';
import {useVideoFormModal} from 'Video/hooks/useVideoFormModal';
import {IMediaResource, MediaType} from 'Common/models/IMediaResource';

const GalleryImages = styled.div`
  margin: -8px;
`;

const GalleryImage = styled.div`
  margin: 8px;
`;

const ErrorMessage = styled.div`
  margin-top: 8px;
  color: ${ColorPalette.red1};
`;

interface IOwnProps {
  isEditMode?: boolean;
  values: IMediaResource[];
  imagesCount?: number;
  disableAdding?: boolean;
  acceptPartialSelection?: boolean;
  avatar?: React.ReactElement;
  onChange?(media: IMediaResource[]): void;
  onDelete?(image: IMediaResource): void;
}

type ImageProps = {imageProps: Omit<ImageUploaderProps, 'onChange' | 'value' | 'isMultiple' | 'onDelete'>};
type VideoProps = {videoProps: Omit<VideoUploaderProps, 'value' | 'onChange'>};

type IProps = IOwnProps & ImageProps & VideoProps;

function Gallery(props: IProps) {
  const {values, isEditMode, onChange, disableAdding, onDelete, imagesCount, avatar, imageProps, videoProps} = props;

  const [error, setError] = useState<string | null>(null);

  const generateNewId = useCallback((): number => {
    let newId = 0;
    let alreadyExistId = true;
    while (alreadyExistId) {
      newId = _.random(Number.MAX_SAFE_INTEGER);
      // eslint-disable-next-line no-loop-func
      alreadyExistId = !!values.find((i) => i.id === newId);
    }

    return newId;
  }, [values]);

  const addImages = useCallback(
    (images: IImage[]) => {
      setError(null);
      const newImages = images.map((image) => ({...image, id: generateNewId(), type: MediaType.Image}));
      onChange && onChange([...values, ...newImages]);
    },
    [onChange, values, generateNewId]
  );

  const deleteMedia = useCallback(
    (media: IMediaResource) => {
      onChange && onChange(values.filter((value) => value.id !== media.id));
      onDelete && onDelete(media);
    },
    [values, onChange, onDelete]
  );

  const addVideo = useCallback(
    (url: string) => {
      const newMedia: IMediaResource = {id: generateNewId(), type: MediaType.Video, url};
      onChange && onChange([...values, newMedia]);
    },
    [generateNewId, onChange, values]
  );

  const {openVideoFormModal, renderVideoFormModal} = useVideoFormModal({onSuccess: addVideo});

  const isShowUploaders = isEditMode && !disableAdding && (!imagesCount || values.length < imagesCount);

  return (
    <div>
      {renderVideoFormModal}

      <GalleryImages className="d-flex flex-wrap">
        {avatar}
        {values.map((media, index) => {
          const onDelete = () => {
            deleteMedia(media);
          };

          return (
            <GalleryImage key={index}>
              {media.type === MediaType.Image && (
                <ImageUploader
                  {...imageProps}
                  onDelete={onDelete}
                  actionButtonsContainerHeight={!isEditMode ? '0%' : undefined}
                  showDeleteButton={imageProps.showDeleteButton && isEditMode}
                  showDownloadButton={imageProps.showDownloadButton && isEditMode}
                  value={media}
                  showEditButton={false}
                  isShowError={false}
                />
              )}

              {media.type === MediaType.Video && (
                <VideoUploader
                  {...videoProps}
                  value={media}
                  actionButtonsContainerHeight={!isEditMode ? '0%' : undefined}
                  showEditButton={false}
                  onDelete={onDelete}
                />
              )}
            </GalleryImage>
          );
        })}
        {isShowUploaders && (
          <>
            <GalleryImage>
              <ImageUploader
                {...imageProps}
                value={null}
                onChange={addImages}
                showEditButton={false}
                isMultiple={true}
                onError={setError}
                isShowError={false}
              />
            </GalleryImage>
            <GalleryImage>
              <VideoUploader {...videoProps} value={null} onChange={openVideoFormModal} showEditButton={false} />
            </GalleryImage>
          </>
        )}
      </GalleryImages>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}

Gallery.defaultProps = {
  acceptPartialSelection: true,
  isEditMode: true,
};

export default React.memo(Gallery);
export type GalleryProps = IProps;
