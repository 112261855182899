import {Reducer} from 'redux';

import {AdminUsersActions, getUsersActionTypes, IAdminUsersState} from '../types';
import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';

const initialState: IAdminUsersState['ui'] = {
  pagination: {countPerPage: MIN_COUNT_PER_PAGE, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiUsersReducer: Reducer<IAdminUsersState['ui'], AdminUsersActions> = (
  state = initialState,
  action: AdminUsersActions
) => {
  switch (action.type) {
    case getUsersActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
