import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication/index';
import {IListResponse, IPagination} from 'Common/models/IResponse';

import {Nullable} from 'Common/types';
import {IErrorDescription} from 'Admin/AdminSettings/models/IErrorDescription';

const STATE_NAME = 'adminErrorDescriptions';

export interface IAdminErrorDescriptionsState {
  data: {
    errorDescriptions: IErrorDescription[];
    errorDescription: Nullable<IErrorDescription>;
  };
  communications: {
    errorDescriptionsLoading: ICommunication;
    errorDescriptionLoading: ICommunication;
    errorDescriptionCreating: ICommunication;
    errorDescriptionUpdating: ICommunication;
    errorDescriptionDeleting: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getErrorDescriptionsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ERROR_DESCRIPTIONS');

export type getErrorDescriptionsActions = MakeCommunicationActions<
  typeof getErrorDescriptionsActionTypes,
  {success: IListResponse<IErrorDescription>}
>;

export const getErrorDescriptionActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ERROR_DESCRIPTION');

export type getErrorDescriptionActions = MakeCommunicationActions<
  typeof getErrorDescriptionActionTypes,
  {success: IErrorDescription}
>;

export const updateErrorDescriptionActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_ERROR_DESCRIPTION');

export type updateErrorDescriptionActions = MakeCommunicationActions<typeof updateErrorDescriptionActionTypes, {}>;

export const createErrorDescriptionActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_ERROR_DESCRIPTION');

export type createErrorDescriptionActions = MakeCommunicationActions<typeof createErrorDescriptionActionTypes, {}>;

export const deleteErrorDescriptionActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_ERROR_DESCRIPTION');

export type deleteErrorDescriptionActions = MakeCommunicationActions<typeof deleteErrorDescriptionActionTypes, {}>;

export const resetErrorDescriptionActionTypes = makeResetCommunicationActionType(STATE_NAME, 'ERROR_DESCRIPTION');
export type resetErrorDescriptionActions = MakeResetCommunicationActions<typeof resetErrorDescriptionActionTypes>;

export type ErrorDescriptionsActions =
  | getErrorDescriptionsActions
  | createErrorDescriptionActions
  | updateErrorDescriptionActions
  | deleteErrorDescriptionActions
  | getErrorDescriptionActions
  | resetErrorDescriptionActions;
