import {IModule} from 'redux-dynamic-modules';

import {IAdminPurchasableTestState} from './types';
import {adminPurchasableTestsReducer} from './reducer';

export interface IAdminPurchasableTestsModuleState {
  adminPurchasableTests: IAdminPurchasableTestState;
}

export const AdminPurchasableTestsModule: IModule<IAdminPurchasableTestsModuleState> = {
  id: 'adminPurchasableTests',
  reducerMap: {
    adminPurchasableTests: adminPurchasableTestsReducer,
  },
};
