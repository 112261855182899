import * as Yup from 'yup';
import {IAssociationPageGeneralInformation} from 'BusinessPortal/models/IAssociationPageGeneralInformation';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues extends IAssociationPageGeneralInformation {}

export const initialValue: IFormValues = {
  id: 0,
  name: '',
  abbreviation: null,
  country: '',
  address: '',
  description: '',
};

export const validationSchema = Yup.object().shape<IFormValues>({
  id: Yup.number().required(),
  name: Yup.string().required(REQUIRED_FIELD),
  address: Yup.string(),
  country: Yup.string(),
  abbreviation: Yup.string(),
  description: Yup.string(),
});
