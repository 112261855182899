import React from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import {ModalWindowButton, ModalWindowContent, ModalWindowFooter, ModalWindowHeader} from './shared/StyledComponents';
import {breakpoints} from 'Common/constants/Breakpoints';
import styled from 'styled-components';

interface IProps {
  isOpen: boolean;
  hideCloseButton?: boolean;
  maxHeight?: string;
  maxWidth?: string;
  modalTitle?: string;
  modalType: ModalTypes;
  successButtonText?: string;
  cancelButtonText?: string;
  onSuccess?: () => void;
  isSuccessLoading?: boolean;
  onClose: () => void;
}

export enum ModalTypes {
  Alert = 'Alert',
  Confirm = 'Confirm'
}

const WarningModalWindowButton = styled(ModalWindowButton)`
  width: 80%;

  :not(:first-child) {
    margin-left: 0;
    margin-bottom: 20px;
  }

  @media ${breakpoints.xs} {
    width: 150px;

    :not(:first-child) {
      margin-left: 20px;
      margin-bottom: 0;
    }
  }
`;

const WarningModalWindowFooter = styled(ModalWindowFooter)`
  flex-direction: column-reverse;

  @media ${breakpoints.xs} {
    flex-direction: row;
  }
`;

type AllProps = IProps & React.HTMLAttributes<HTMLElement>;

const WarningModalWindow = (props: AllProps) => {
  const {
    onSuccess,
    onClose,
    modalType,
    modalTitle,
    cancelButtonText,
    successButtonText,
    isSuccessLoading,
    maxHeight,
    maxWidth = '512px',
    isOpen,
    hideCloseButton
  } = props;

  return (
    <ModalWindow
      isOpen={isOpen}
      hideCloseButton={hideCloseButton}
      maxWidth={maxWidth}
      onClose={onClose}
      maxHeight={maxHeight}
    >
      <ModalWindowHeader>
        {!modalTitle ? (modalType === ModalTypes.Alert ? 'Alert' : 'Confirm') : modalTitle}
      </ModalWindowHeader>
      <ModalWindowContent>{props.children}</ModalWindowContent>
      <WarningModalWindowFooter className="d-flex justify-content-end">
        {modalType === ModalTypes.Alert ? (
          <WarningModalWindowButton onClick={onSuccess} disabled={false} isLoading={isSuccessLoading}>
            {successButtonText ? successButtonText : 'Ok'}
          </WarningModalWindowButton>
        ) : (
          <>
            <WarningModalWindowButton onClick={onClose} disabled={false} variant="outlined">
              {cancelButtonText ? cancelButtonText : 'Cancel'}
            </WarningModalWindowButton>
            <WarningModalWindowButton onClick={onSuccess} disabled={false} isLoading={isSuccessLoading}>
              {successButtonText ? successButtonText : 'Ok'}
            </WarningModalWindowButton>
          </>
        )}
      </WarningModalWindowFooter>
    </ModalWindow>
  );
};

export default WarningModalWindow;
