import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'BusinessPortal/store/horse';
import Loading from 'Loading/components/Loading';
import EditableSection from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {IInconclusiveHealthIssue} from 'Dictionaries/models/InconclusiveHealthIssues';
import {useDictionaries} from 'Common/store/useDictionaries';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import HorseHealthInfoSectionEdit from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseHealthInfoSection/HorseHealthInfoSectionEdit';
import HorseHealthInfoSectionShow from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseHealthInfoSection/HorseHealthInfoSectionShow';
import {IHorseSectionBP} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/models/IHorseSectionBP';

interface IExternalDictionaries {
  inconclusiveHealthIssuesDictionary: ISimpleDictionary<IInconclusiveHealthIssue>;
}

type IExternalProps = IHorseSectionBP & IExternalDictionaries;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalProps;

const HorseHealthInfoSection = (props: AllProps) => {
  const {horseId, onSuccess} = props;
  const {
    getHorseHealthIssues,
    inconclusiveHealthIssuesDictionary,
    inconclusiveHealthIssues,
    horseHealthIssues,
    horseHealthIssuesLoading
  } = props;

  const mainRequest = useCallback(() => {
    getHorseHealthIssues(horseId);
  }, [getHorseHealthIssues, horseId]);

  useEffect(() => {
    mainRequest();
  }, [mainRequest]);

  const {
    actions: {getItems: getInconclusiveHealthIssues}
  } = inconclusiveHealthIssuesDictionary;

  useEffect(() => {
    getInconclusiveHealthIssues();
  }, [getInconclusiveHealthIssues]);

  const onSuccessEditHorse = useCallback(() => {
    mainRequest();
    onSuccess();
  }, [onSuccess, mainRequest]);

  const isRequesting = horseHealthIssuesLoading.isRequesting;

  return (
    <div className="position-relative">
      {isRequesting && <Loading />}
      <EditableSection
        title="Health Info"
        data={{horseHealthIssues, inconclusiveHealthIssues, horseId}}
        onSuccess={onSuccessEditHorse}
        editComponent={HorseHealthInfoSectionEdit}
        showComponent={HorseHealthInfoSectionShow}
      />
    </div>
  );
};

const mapStateToProps = (state: IAppState, props: IExternalDictionaries) => {
  const {inconclusiveHealthIssuesDictionary} = props;
  const {selectors: inconclusiveHealthIssuesSelectors} = inconclusiveHealthIssuesDictionary;

  return {
    horseHealthIssues: selectors.selectHorseHealthIssues(state),
    horseHealthIssuesLoading: selectors.selectCommunication(state, 'horseHealthIssuesLoading'),
    inconclusiveHealthIssues: inconclusiveHealthIssuesSelectors.selectItems(state),
    inconclusiveHealthIssuesLoading: inconclusiveHealthIssuesSelectors.selectCommunication(state, 'itemsLoading')
  };
};
const mapDispatchToProps = {
  getHorseHealthIssues: actions.getHorseHealthIssues
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(HorseHealthInfoSection);
const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {inconclusiveHealthIssue} = useDictionaries();

  return <Connected inconclusiveHealthIssuesDictionary={inconclusiveHealthIssue} {...externalProps} />;
};

export default memo(Exported);
