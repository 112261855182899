import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';

import horse from './img/horse.svg';
import owner from './img/owner.svg';
import several from './img/several.svg';
import Theme from 'Common/constants/Theme';
import useIsImageExist from 'Common/helpers/hooks/useIsImageExist';
import CommonLink from 'Common/components/Controls/Links/CommonLink';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import ColoredIcon, {IColoredIconProps} from 'Icon/components/ColoredIcon';
import Typography from 'Common/constants/Typography';
import BlurredImage from 'Common/components/BlurredImage/BlurredImage';
import {IconName} from 'Icon/components/Icon';

type AvatarType = 'owner' | 'horse' | 'several';

const defaultsAvatars: Record<AvatarType, string> = {
  horse,
  owner,
  several,
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const EditHover = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: ${Theme.color.black};
  opacity: 0.2;
  visibility: hidden;
  border-radius: 50%;
`;

const AvatarWrapper = styled.div<{type: AvatarType; size?: string; clickable?: boolean}>`
  position: relative;
  height: ${(props) => props.size || '40px'};
  width: ${(props) => props.size || '40px'};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${ColorPalette.gray52};

  ${(p) =>
    p.type !== 'several' &&
    `
    cursor: ${p.clickable ? 'pointer' : 'default'};

  :hover {
    ${EditHover} {
      visibility: visible;
    }
  }`}
`;

const BaseAvatar = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
`;

const AvatarBlurred = styled(BlurredImage)`
  overflow: hidden;
  border-radius: 50%;
`;

const AvatarImage = styled(BaseAvatar)<{url?: string}>`
  background-size: contain;
  ${(p) =>
    p.url &&
    `
    background-image: url('${p.url}');
  `}
`;

const Label = styled.div<{clickable?: boolean}>`
  margin-left: 16px;
  color: ${Theme.color.black};
  cursor: ${(p) => (p.clickable ? 'pointer' : 'default')};

  :hover {
    color: ${ColorPalette.blue2};
  }
`;

const BahAccessMarkWrapper = styled.div`
  width: 22px;
  height: 22px;
  position: absolute;
  z-index: 2;
  bottom: -6px;
  right: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${ColorPalette.white0};
`;

const SampleId = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 12px;
  letter-spacing: 0.5px;
`;

const Sid = styled.span`
  color: ${Theme.color.gray};
`;

const SampleName = styled.span`
  color: ${Theme.color.black};
  :hover {
    color: ${ColorPalette.blue2} !important;
  }
`;

export interface IAvatarCellProps {
  type: AvatarType;
  label: string;
  avatarUrl?: string;
  profileUrl?: string;
  tooltip?: string;
  isMarkedIcon?: boolean;
  markedIcon?: IColoredIconProps;
  sampleId?: string;
  size?: string;
  hasOnlineReportAccess?: boolean;

  onAvatarClick?(): void;
  onLabelClick?(): void;
}

function AvatarCell(props: React.PropsWithChildren<IAvatarCellProps>) {
  const {
    onLabelClick,
    type,
    label,
    isMarkedIcon,
    markedIcon,
    avatarUrl,
    profileUrl,
    tooltip,
    sampleId,
    size,
    children,
    onAvatarClick,
    hasOnlineReportAccess,
  } = props;

  const isAvatarExists = useIsImageExist(avatarUrl);

  const renderAvatar = React.useCallback(() => {
    return (
      <>
        <AvatarBlurred
          className="position-absolute"
          src={isAvatarExists && avatarUrl ? avatarUrl : defaultsAvatars[type]}
        />
        {isAvatarExists && <AvatarImage url={avatarUrl} />}
        {isMarkedIcon && markedIcon && (
          <BahAccessMarkWrapper>
            <ColoredIcon {...markedIcon} />
          </BahAccessMarkWrapper>
        )}
        {children}
      </>
    );
  }, [isAvatarExists, avatarUrl, type, isMarkedIcon, markedIcon, children]);

  const avatar = (
    <AvatarWrapper type={type} size={size} onClick={onAvatarClick} clickable={!!onAvatarClick || !!profileUrl}>
      <EditHover />
      {renderAvatar()}
    </AvatarWrapper>
  );

  return (
    <Root>
      {type !== 'several' && profileUrl && !onAvatarClick ? <CommonLink to={profileUrl}>{avatar}</CommonLink> : avatar}
      <Tooltip content={tooltip}>
        <Label onClick={onLabelClick} clickable={!!onLabelClick} className="d-flex flex-column">
          <span>{label}</span>
          {sampleId && (
            <SampleId>
              <Sid>SID: </Sid>
              <SampleName>{sampleId}</SampleName>
            </SampleId>
          )}
          {hasOnlineReportAccess && (
            <Tooltip content="Has Online Report access">
              <ColoredIcon name={IconName.Notebook} color={ColorPalette.green2} />
            </Tooltip>
          )}
        </Label>
      </Tooltip>
    </Root>
  );
}

export default memo(AvatarCell);
