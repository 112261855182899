import * as Yup from 'yup';

import {IAdminPermissions} from 'Common/models/IAdminPermissions';

export interface IFormValues {
  adminId: number;
  permissions: Omit<IAdminPermissions, 'id'>[];
}

export const initialValue: IFormValues = {
  adminId: 0,
  permissions: []
};

export const validationSchema = Yup.object().shape<IFormValues>({
  adminId: Yup.number(),
  permissions: Yup.array().of(Yup.mixed())
});
