import {IModule} from 'redux-dynamic-modules';

import {IAdminRulesBackupState} from './types';
import {rulesBackupReducer} from './reducer';

export interface IAdminRulesBackupModuleState {
  adminRulesBackup: IAdminRulesBackupState;
}

export const AdminRulesBackupModule: IModule<IAdminRulesBackupModuleState> = {
  id: 'adminRulesBackup',
  reducerMap: {
    adminRulesBackup: rulesBackupReducer
  }
};
