import React, {memo} from 'react';
import styled from 'styled-components';

import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';
import {IAdminAffectionType} from 'Admin/AdminPhenotypes/models/IAdminAffectionType';
import AffectionTypeForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/AffectionTypes/AffectionTypeForm/AffectionTypeForm';
import {HAS_RULE_ICON, NO_RULE_ICON} from 'Admin/AdminPhenotypes/components/share/StyledComponents';

const ColorContainer = styled.div<{color?: string}>`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  ${({color}) => `background-color: ${color}`};
`;

function AffectionTypes() {
  const {adminAffectionType: dictionary} = useDictionaries();

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={AffectionTypeForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IAdminAffectionType> dataKey="id" width="5%" header="ID" render={({id}) => `#${id}`} />
            <Cell<IAdminAffectionType> dataKey="name" width="25%" header="Name" render={({name}) => name} />
            <Cell<IAdminAffectionType>
              dataKey="color"
              width="15%"
              header="Color"
              render={({color}) => <ColorContainer color={color || undefined} />}
            />
            <Cell<IAdminAffectionType>
              header="Affection"
              render={({hasAffection}) => (hasAffection ? HAS_RULE_ICON : NO_RULE_ICON)}
              width="10%"
            />
            <Cell<IAdminAffectionType>
              dataKey="description"
              width="35%"
              header="Description"
              sortable={false}
              render={({description}) => description}
            />
            <Cell<IAdminAffectionType>
              header="Actions"
              align={CellAlign.Right}
              width="10%"
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(AffectionTypes);
