import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';
import {IGenePools} from 'Filters/models/GenePools';

const STATE_NAME = 'horseFilters';

export interface IHorseFiltersState {
  data: {
    genePools: IGenePools | null;
  };
  communications: {
    genePoolsLoading: ICommunication;
  };
}

export const getAllGenePoolsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ALL_GENE_POOLS');
export type getAllGenePoolsActions = MakeCommunicationActions<typeof getAllGenePoolsActionTypes, {success: IGenePools}>;

export type Actions = getAllGenePoolsActions;
