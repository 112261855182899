import {IOrderSummary} from 'BusinessPortal/models/order/createOrder/IOrderSummary';

import {convertPriceToClient} from 'Common/helpers/convertPrices';

export function convertOrderSummaryToClient(summary: IOrderSummary): IOrderSummary {
  const {amount, totalAmount, totalDiscount, packageAndTestDiscounts, packagesAndTestsPrice} = summary;
  return {
    totalAmount: convertPriceToClient(totalAmount),
    amount: convertPriceToClient(amount),
    totalDiscount: convertPriceToClient(totalDiscount),
    packagesAndTestsPrice: packagesAndTestsPrice.map((i) => ({...i, value: convertPriceToClient(i.value)})),
    packageAndTestDiscounts: packageAndTestDiscounts.map((i) => ({...i, value: convertPriceToClient(i.value)})),
  };
}
