import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IHorse} from 'Horse/models/IHorse';
import {IAppState} from 'Common/store/IAppState';
import {IShortAbility} from 'Dictionaries/models/IAbilities';
import {IUserHorseOrder, IHorseReport} from 'Horse/models/IUserHorse';
import {Nullable} from 'Common/types';
import IFoundHorse from 'Horse/models/IFoundHorse';

function selectHorseState(state: IAppState) {
  return state.horse;
}

export const selectCommunication = makeCommunicationSelector(selectHorseState);

export function selectHorse(state: IAppState): Nullable<IHorse> {
  return selectHorseState(state).data.horse;
}

export function selectHorseAbilities(state: IAppState): IShortAbility[] {
  return selectHorseState(state).data.horseAbilities;
}

export function selectHorseParantageReport(state: IAppState): Nullable<IHorseReport> {
  return selectHorseState(state).data.horseParantageReport;
}

export function selectHorseLastOrder(state: IAppState): Nullable<IUserHorseOrder> {
  return selectHorseState(state).data.horseLastOrder;
}

export function selectFoundHorse(state: IAppState): Nullable<IFoundHorse> {
  return selectHorseState(state).data.foundHorse;
}

export function selectHorseOwner(state: IAppState) {
  return selectHorseState(state).data.horseOwner;
}

export function selectOwnerHorses(state: IAppState) {
  return selectHorseState(state).data.ownerHorses;
}
