import React, {useCallback, useState} from 'react';
import styled from 'styled-components';

import {OrderStatusField} from 'Order/models/IOrderStatusFields';
import {Nullable} from 'Common/types';
import WarningModalWindow, {ModalTypes} from 'Common/components/Modal/WarningModal';
import {IRequestSetSampleStatus as IRequestSetSampleStatusAdmin} from 'Admin/AdminDashboard/store/adminOrders/types';
import {IAdminActionsProps} from 'Admin/AdminDashboard/helpers/hooks/common';
import {actionLabelByStatus} from 'Admin/shared/components/OrderStatus/constants';
import {FieldHint} from 'Common/components/StyledComponents/StyledComponents';
import {Checkbox} from 'Common/components/Controls';
import {IRequestSetSampleStatus} from 'Admin/AdminAssociations/store/adminOrders';

const CONFIRM_SET_SAMPLE_STATUS = 'Are you sure you want to change the sample status?';
const CONFIRM_MARK_SAMPLE_AS_RECEIVED = 'Have you received a sample and want to change order status to';

const SendEmailField = styled.div`
  margin-top: 24px;
`;

type RequestSetSampleStatus = IRequestSetSampleStatus | IRequestSetSampleStatusAdmin;

export interface ISetSampleStatusActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(data: RequestSetSampleStatus): void;
}

type IUseSetSampleStatusAction = Omit<ISetSampleStatusActionProps, 'communication'>;

export function useSetSampleStatusAction(props: IUseSetSampleStatusAction) {
  const {action, onSuccess} = props;

  const [selectedOrderId, setSelectedOrderId] = useState<Nullable<number>>();
  const [selectedHorseId, setSelectedHorseId] = useState<Nullable<number>>();
  const [sampleStatusType, setSampleStatusType] = useState<OrderStatusField>();
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxClick = useCallback(() => {
    setIsChecked(!isChecked);
  }, [isChecked]);

  const [isSetSampleStatusModalOpen, setIsSetSampleStatusModal] = useState(false);
  const openSampleReceivedModal = useCallback((orderId: number, horseId?: number) => {
    setIsSetSampleStatusModal(true);
    setSelectedOrderId(orderId);
    setSelectedHorseId(horseId);
    setSampleStatusType(OrderStatusField.Ready);
  }, []);

  const openSampleNotReceivedModal = useCallback((orderId: number, horseId?: number) => {
    setIsSetSampleStatusModal(true);
    setSelectedOrderId(orderId);
    setSelectedHorseId(horseId);
    setSampleStatusType(OrderStatusField.Waiting);
  }, []);

  const closeSetSampleStatusModal = useCallback(() => setIsSetSampleStatusModal(false), []);
  const successSetSampleStatusModal = useCallback(async () => {
    closeSetSampleStatusModal();

    const horseIdParam = selectedHorseId && {horseId: selectedHorseId!};
    const params = {
      orderId: selectedOrderId!,
      status: sampleStatusType!,
      sendEmail: isChecked,
      ...horseIdParam,
    };

    action && (await action(params));
    onSuccess();
  }, [closeSetSampleStatusModal, action, selectedOrderId, selectedHorseId, sampleStatusType, isChecked, onSuccess]);

  const isStatusWaiting = sampleStatusType === OrderStatusField.Waiting;

  const setSampleStatusModal = (
    <WarningModalWindow
      modalType={ModalTypes.Confirm}
      isOpen={isSetSampleStatusModalOpen}
      onClose={closeSetSampleStatusModal}
      onSuccess={successSetSampleStatusModal}
      maxWidth="693px"
      successButtonText="Change status"
    >
      {isStatusWaiting ? (
        CONFIRM_SET_SAMPLE_STATUS
      ) : (
        <>
          <span>
            {CONFIRM_MARK_SAMPLE_AS_RECEIVED}
            <b> {actionLabelByStatus.paymentReceived}</b>?
          </span>
          <SendEmailField>
            <Checkbox
              name="sendEmail"
              label="Send email"
              style={{marginBottom: 4}}
              checked={isChecked}
              onChange={handleCheckboxClick}
            />
            <FieldHint>
              Enabling this option will allow you to notify the customer about the sample receiving.
            </FieldHint>
          </SendEmailField>
        </>
      )}
    </WarningModalWindow>
  );

  return {
    openSampleReceivedModal,
    openSampleNotReceivedModal,
    setSampleStatusModal,
  };
}
