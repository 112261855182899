import React, {memo} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import MapField from 'Common/components/FormFields/MapField';
import {Nullable} from 'Common/types';
import {ILocation} from 'Common/models/ICoordinatedLocation';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {IFormValues, initialValue, validationSchema} from './validation';
import {actions, selectors} from 'BusinessPortal/store/employeeProfile';
import {IAppState} from 'Common/store/IAppState';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import Loading from 'Loading/components/Loading';

interface IProps {
  location: Nullable<ILocation>;
}

type IExternalProps = IEditableSection<IProps>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function GeoLocationEdit(props: AllProps) {
  const {isValid, isSubmitting} = props;
  const {onSuccess, geoLocationUpdating} = props;

  const isRequesting = geoLocationUpdating.isRequesting;

  useOnSuccessCommunication(geoLocationUpdating, () => onSuccess && onSuccess());
  useErrorCommunicationToToast(geoLocationUpdating);

  if (isRequesting) {
    return <Loading />;
  }

  return (
    <Form>
      <MapField
        name="address"
        mapName="location"
        label="Location"
        memoized={true}
        autoComplete="off"
        placeholder="Start typing your location"
        style={{marginBottom: 24}}
      />
      <PrimaryButton className="w-100" size="medium" type="submit" disabled={!isValid} isLoading={isSubmitting}>
        Save changes
      </PrimaryButton>
    </Form>
  );
}

const GeneralInformationEditFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({data}) => (data ? {...data, address: ''} : initialValue),
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {updateEmployeeGeoLocation} = formikBag.props;
    if (values.location) {
      await updateEmployeeGeoLocation(values.location);
    }
  },
  enableReinitialize: true,
})(GeoLocationEdit);

const mapStateToProps = (state: IAppState) => ({
  geoLocationUpdating: selectors.selectCommunication(state, 'geoLocationUpdating'),
});

const mapDispatchToProps = {
  updateEmployeeGeoLocation: actions.updateEmployeeGeoLocation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(GeneralInformationEditFormik));
