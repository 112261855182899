import {combineReducers, Reducer} from 'redux';

import {communicationUserPrintableReportPreferencesReducer} from './communications';
import {dataUserPrintableReportPreferencesReducer} from './data';
import {IUserPrintableReportPreferencesState} from 'OnlineReportPrintable/store/userPrintableReportPreferences/types';

export const userPrintableReportPreferencesReducer: Reducer<IUserPrintableReportPreferencesState> =
  combineReducers<IUserPrintableReportPreferencesState>({
    communications: communicationUserPrintableReportPreferencesReducer,
    data: dataUserPrintableReportPreferencesReducer,
  });
