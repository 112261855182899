import {combineReducers} from 'redux';

import {IHorseTransferState} from '../types';
import {communicationHorseTransferReducer} from './communications';
import {dataHorseTransferReducer} from './data';

export const reducer = combineReducers<IHorseTransferState>({
  communications: communicationHorseTransferReducer,
  data: dataHorseTransferReducer,
});
