import {combineReducers, Reducer} from 'redux';

import {communicationCurrentUserReducer} from './communications';
import {dataCurrentUserReducer} from './data';
import {ICurrentUserState} from '../types';

export const currentUserReducer: Reducer<ICurrentUserState> = combineReducers<ICurrentUserState>({
  communications: communicationCurrentUserReducer,
  data: dataCurrentUserReducer
});
