import {IHorseDetailsBP, IUpdateHorseDetailsBP} from 'BusinessPortal/models/horse/IHorseDetailsBP';
import {initialValue} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseInfoSection/validation';
import {convertToServerDate} from 'Common/helpers/DateHelper';
import {IFormValues} from './validation';
import {ProfileType} from 'Common/constants/ProfileType';

export const convertToClient = (horse: IHorseDetailsBP): IFormValues => {
  return {
    ...horse,
    height: horse.height || initialValue.height,
    breeds: horse.breeds.map((i) => i.id),
    disciplines: horse.disciplines.map((i) => i.id),
    colors: horse.colors.map((i) => i.id),
    markings: horse.markings.map((i) => i.id),
    address: {
      country: horse.address?.country || initialValue.address!.country,
      state: horse.address?.state || initialValue.address!.state,
      city: horse.address?.city || initialValue.address!.city,
      street: horse.address?.street || initialValue.address!.street,
      zip: horse.address?.zip || initialValue.address!.zip,
    },
    isPublicProfile: horse.profileType === ProfileType.Public,
    isAncestryPublicProfile: horse.ancestryProfileType === ProfileType.Public,
  };
};

export const convertToServer = (data: IFormValues): IUpdateHorseDetailsBP => {
  const allAddressFieldsAreEmpty =
    data.address && Object.values(data.address).every((value) => value === '' || value === null);

  const dataToSave = {
    ...data,
    address: allAddressFieldsAreEmpty ? null : data.address,
    dateOfBirth: convertToServerDate(data.dateOfBirth),
    profileType: data.isPublicProfile ? ProfileType.Public : ProfileType.Private,
    ancestryProfileType: data.isAncestryPublicProfile ? ProfileType.Public : ProfileType.Private,
  };

  delete dataToSave['simpleOrganization'];
  delete dataToSave['organizationId'];

  return dataToSave;
};
