import {Reducer} from 'redux';

import {IAdminStaticResourcesState} from '../types';
import {
  getStaticResourcesActionTypes,
  StaticResourcesActions
} from 'Admin/AdminSettings/stores/adminStaticResources/types';

const initialState: IAdminStaticResourcesState['ui'] = {
  pagination: {countPerPage: 0, currentPage: 1, pageCount: 1, totalItemsCount: 1}
};

export const uiStaticResourcesReducer: Reducer<IAdminStaticResourcesState['ui'], StaticResourcesActions> = (
  state = initialState,
  action: StaticResourcesActions
) => {
  switch (action.type) {
    case getStaticResourcesActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination || initialState.pagination};
    }
    default: {
      return state;
    }
  }
};
