import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {getServiceModeActions, getServiceModeActionTypes, IServiceModeState} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import ServiceModeDataService from '../services/ServiceModeDataService';
import {IServiceMode} from 'Maintain/models/IServiceMode';

export const getServiceMode = (): ActionResult<
  IServiceModeState,
  Promise<IServiceMode>,
  getServiceModeActions
> => async dispatch => {
  dispatch(action(getServiceModeActionTypes.REQUEST));
  try {
    const res = await ServiceModeDataService.getOperatingModeControl();
    dispatch(action(getServiceModeActionTypes.SUCCESS, res));
    return res;
  } catch (error) {
    dispatch(action(getServiceModeActionTypes.FAILURE, getErrorMessage(error)));
    throw error;
  }
};
