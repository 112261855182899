import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';

export const searchBarStyle: React.CSSProperties = {height: 32, background: ColorPalette.white0};

export const TextBase = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${Theme.color.black};
`;

export const InformationSection = styled.div`
  padding: 16px 16px 0 16px;
  margin-bottom: 16px;
`;

export const FieldContainer = styled.div`
  margin-bottom: 16px;
`;

export const FieldName = styled(TextBase)<{width?: string}>`
  width: ${({width}) => width || '80px'};
  font-weight: ${Typography.weight.medium500};
  letter-spacing: 0.5px;
  margin-right: 16px;
`;

export const FieldData = styled(TextBase)`
  width: 145px;
  margin-right: 24px;
`;

export const Divider = styled.div<{width?: string}>`
  width: ${({width}) => width || '100%'};
  height: 1px;
  margin: 16px 0;
  background-color: ${ColorPalette.white9};
`;

export const SectionName = styled(TextBase)`
  line-height: 18px;
  background: ${ColorPalette.gray49};
  border-radius: 8px;
  padding: 12px;
  margin: 16px 0;
`;

export const FieldHint = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.gray44};
`;

export const PageTitle = styled.div`
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size24};
  line-height: 32px;
  color: ${ColorPalette.black1};
  margin-bottom: 24px;
  text-align: center;

  @media ${breakpoints.sm} {
    margin-bottom: 32px;
    text-align: left;
  }
`;

export const PageContainer = styled.div`
  background-color: ${Theme.color.white};
  border-radius: 8px;
  padding: 16px;

  @media ${breakpoints.md} {
    padding: 24px;
  }
`;
