import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {IShortDescriptionEntity} from 'Common/models/IDescriptionEntity';
import {ITraitSequence} from 'Admin/AdminPhenotypes/models/ITraitSequence';
import {Combination} from 'Common/constants/Combination';
import {IImage} from 'Image/models/IImage';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IImaged} from 'Common/models/IImaged';

export interface IFormValues extends IShortDescriptionEntity, IImaged {
  id?: IEntity['id'];
  name: string;
  type: Combination;
  sequences?: ITraitSequence[];
}

export const initialValue: IFormValues = {
  name: '',
  type: Combination.And,
  description: {value: ''},
  image: null,
  sequences: [],
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  image: Yup.mixed<IImage>().nullable(),
});
