import React, {memo} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import PropPickerSegment, {PropPickerSegmentFilling, IProps as IPropPrickerSegmentProps} from './PropPickerSegment';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {SegmentPosition} from 'Common/constants/SegmentPosition';
import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import {controlLayout, inputLabelCss} from 'Common/components/Controls/styles';
import RequiredMark from 'Common/helpers/RequiredMark';

const ControlLayout = styled.div`
  padding-left: 0;
  ${controlLayout}
`;

const StyledLabel = styled.label`
  padding-left: 0;
  ${inputLabelCss}
`;

const IndicatorLayout = styled.div`
  padding-left: 0;
  margin-top: 25px;
  margin-bottom: 50px;
`;

const Segments = styled.div`
  height: 100%;
`;

const SegmentTitle = styled.div<ITitleProps>`
  font-size: ${Typography.size.size13};
  line-height: 24px;
  color: ${prop => (prop.isActive ? ColorPalette.black1 : ColorPalette.gray3)};
  bottom: -25px;
  width: 100%;
`;

const SegmentTitleLeft = styled(SegmentTitle)`
  text-align: left;
`;

const SegmentTitleCenter = styled(SegmentTitle)`
  text-align: center;
`;

const SegmentTitleRight = styled(SegmentTitle)`
  text-align: right;
`;

const ClickableZone = styled.div`
  top: 0;
  height: 45px;
  width: 96px;
  cursor: pointer;
`;

const ClickableZoneLeft = styled(ClickableZone)`
  left: 0;
`;

const ClickableZoneCenter = styled(ClickableZone)`
  width: 89px;
  left: 7px;
`;

const ClickableZoneRight = styled(ClickableZone)`
  left: 3px;
`;

interface ITitleProps {
  isActive: boolean;
}

interface IProps {
  minValueText: string;
  middleValueText: string;
  maxValueText: string;
  onChange(value: number): void;
  value: number;
}

export type PropPickerProps = IProps & IBaseControlProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

function getFilled(filledIndex: number, currentIndex: number): PropPickerSegmentFilling {
  return currentIndex <= filledIndex ? PropPickerSegmentFilling.Filled : PropPickerSegmentFilling.Empty;
}

function PropPicker(props: PropPickerProps) {
  const {onChange, value, name} = props;

  function onClickZone(event: React.MouseEvent<HTMLDivElement>) {
    onChange(+event.currentTarget.id.substr(event.currentTarget.id.length - 1));
  }

  const segment1: IPropPrickerSegmentProps = {
    segmentPosition: SegmentPosition.Left,
    segmentFilled: getFilled(value, 0),
    style: {marginRight: -4}
  };

  const segment2: IPropPrickerSegmentProps = {
    segmentPosition: SegmentPosition.Center,
    segmentFilled: getFilled(value, 1)
  };

  const segment3: IPropPrickerSegmentProps = {
    segmentPosition: SegmentPosition.Right,
    segmentFilled: getFilled(value, 2),
    style: {marginLeft: -4}
  };

  return (
    <IndicatorLayout className="d-flex align-items-center">
      <ControlLayout role="group" className={classnames(props.className)} style={props.style}>
        {props.label && (
          <StyledLabel className={classnames(props.labelClassName)} style={props.labelStyle}>
            {props.label} <RequiredMark isRequired={props.isRequired} />
          </StyledLabel>
        )}
        <Segments className="d-flex">
          <div className="position-relative">
            <PropPickerSegment {...segment1} />
            <SegmentTitleLeft className="position-absolute" isActive={value === 0}>
              {props.minValueText}
            </SegmentTitleLeft>
            <ClickableZoneLeft id={name + '0'} className="position-absolute" onClick={onClickZone} />
          </div>

          <div className="position-relative">
            <PropPickerSegment {...segment2} />
            <SegmentTitleCenter className="position-absolute" isActive={value === 1}>
              {props.middleValueText}
            </SegmentTitleCenter>
            <ClickableZoneCenter id={name + '1'} className="position-absolute" onClick={onClickZone} />
          </div>

          <div className="position-relative">
            <PropPickerSegment {...segment3} />
            <SegmentTitleRight className="position-absolute" isActive={value === 2}>
              {props.maxValueText}
            </SegmentTitleRight>
            <ClickableZoneRight id={name + '2'} className="position-absolute" onClick={onClickZone} />
          </div>
        </Segments>
      </ControlLayout>
    </IndicatorLayout>
  );
}

export default memo(PropPicker);
