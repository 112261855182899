import React, {memo, useCallback} from 'react';
import styled from 'styled-components';

import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';

const actionButtonProps = {color: ColorPalette.gray28, fill: true, stroke: false, size: 24};

const ActionButton = styled(IconButton)`
  margin-left: 12px;
`;

interface IActionProps {
  id: number;
  deleteAction?: (id: number) => void;
  editAction?: (id: number) => void;
}

function Actions(props: IActionProps) {
  const {editAction, deleteAction, id} = props;

  const handleEditAction = useCallback(() => {
    editAction && editAction(id);
  }, [editAction, id]);

  const handleDeleteAction = useCallback(() => {
    deleteAction && deleteAction(id);
  }, [deleteAction, id]);

  return (
    <div className="d-flex align-items-center justify-content-end">
      {editAction && <ActionButton name={IconName.Edit} {...actionButtonProps} onClick={handleEditAction} />}
      {deleteAction && <ActionButton name={IconName.Garbage} {...actionButtonProps} onClick={handleDeleteAction} />}
    </div>
  );
}

export default memo(Actions);
