import * as Yup from 'yup';

import {HeightLimits, HeightUnits} from 'HorseProfile/constants/HeightUnits';
import {IImage} from 'Image/models/IImage';

import {Gender} from 'Common/constants/Gender';
import {isValidHeight} from 'Common/helpers/HeightHelper';
import {FRONTEND_DATE} from 'Common/constants/Date';
import {isValidDate} from 'Common/helpers/DateHelper';
import {IUpdateHorseDetailsBP} from 'BusinessPortal/models/horse/IHorseDetailsBP';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IHeight} from 'Common/models/IHeight';
import {IAddressWithNullableField} from 'Common/models/IAddress';
import {IWithHorseMapFieldProps} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseInfoSection/helpers/HorseMapField/HorseMapField';
import {ICoordinatedLocation} from 'Common/models/ICoordinatedLocation';

export type IUpdateHorseDetailsBPFormValues = IWithHorseMapFieldProps & IUpdateHorseDetailsBP;

export interface IFormValues extends Omit<IUpdateHorseDetailsBPFormValues, 'profileType' | 'ancestryProfileType'> {
  isPublicProfile: boolean;
  isAncestryPublicProfile: boolean;
}

export const initialValue: IFormValues = {
  id: 0,
  gender: Gender.Mare,
  name: '',
  address: {
    country: '',
    state: '',
    city: '',
    street: '',
    zip: '',
  },
  dateOfBirth: '',
  passportNumber: '',
  microchipNumber: '',
  registryNumber: '',
  height: {
    height: 0,
    heightUnit: HeightUnits.Hands,
  },
  avatar: null,
  breeds: [],
  disciplines: [],
  colors: [],
  markings: [],
  location: null,
  horseAddress: '',
  isManualAddressMode: false,
  isPublicProfile: false,
  isAncestryPublicProfile: false,
};

export const validationSchema = Yup.object().shape<IFormValues>({
  id: Yup.number().required(REQUIRED_FIELD),
  name: Yup.string().required(REQUIRED_FIELD),
  dateOfBirth: Yup.string()
    .nullable()
    .test('dateOfBirth', `Please enter a valid date: ${FRONTEND_DATE}`, function (value: string) {
      return value ? isValidDate(value, FRONTEND_DATE) : true;
    }),
  breeds: Yup.array().of(Yup.number()).required(REQUIRED_FIELD),
  colors: Yup.array().of(Yup.number()),
  disciplines: Yup.array().of(Yup.number()),
  markings: Yup.array().of(Yup.number()),
  gender: Yup.mixed<Gender>().required(REQUIRED_FIELD),
  height: Yup.object()
    .shape<IHeight>({
      height: Yup.number().test('height checking', '', function (height: number) {
        if (height) {
          const currentUnitLimits = HeightLimits[this.parent.heightUnit];
          const baseErrorMessage = `'Height' must be between ${currentUnitLimits.min} and ${currentUnitLimits.max}. `;
          const additionalErrorMessage = `You entered ${height}. `;
          const additionalHandsErrorMessage = `Also the fractional part must be 1, 2 or 3. ${additionalErrorMessage}. `;

          const isValidHeightUnit = isValidHeight(height, this.parent.heightUnit);
          if (!isValidHeightUnit) {
            if (this.parent.heightUnit === HeightUnits.Hands) {
              return this.createError({message: baseErrorMessage + additionalHandsErrorMessage});
            }

            return this.createError({message: baseErrorMessage + additionalErrorMessage});
          }
        }

        return true;
      }),
      heightUnit: Yup.mixed<HeightUnits>(),
    })
    .nullable(),
  avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
  microchipNumber: Yup.string().nullable(),
  passportNumber: Yup.string().nullable(),
  registryNumber: Yup.string().nullable(),
  address: Yup.object()
    .shape<IAddressWithNullableField>({
      country: Yup.string(),
      state: Yup.string(),
      city: Yup.string(),
      street: Yup.string(),
      zip: Yup.string(),
    })
    .nullable(),
  location: Yup.object()
    .shape<ICoordinatedLocation>({
      id: Yup.number(),
      latitude: Yup.number(),
      longitude: Yup.number(),
    })
    .nullable(),
  horseAddress: Yup.string().test('horseAddress', 'Unknown address', function () {
    return this.parent.address;
  }),
  isManualAddressMode: Yup.boolean(),
  isPublicProfile: Yup.boolean(),
  isAncestryPublicProfile: Yup.boolean(),
});
