import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

import {
  getPermissionsByUserIdActionTypes,
  IUserPermissionsState,
  updatePermissionsByUserIdActionTypes,
  UserPermissionsActions
} from '../types';

export const communicationUserPermissionsReducer = combineReducers<
  IUserPermissionsState['communications'],
  UserPermissionsActions
>({
  userPermissionsLoading: makeCommunicationReducerFromEnum(getPermissionsByUserIdActionTypes),
  userPermissionsUpdating: makeCommunicationReducerFromEnum(updatePermissionsByUserIdActionTypes)
});
