import {Reducer} from 'redux';

import {
  ErrorDescriptionsActions,
  getErrorDescriptionActionTypes,
  getErrorDescriptionsActionTypes,
  IAdminErrorDescriptionsState,
  resetErrorDescriptionActionTypes,
} from '../types';

const initialState: IAdminErrorDescriptionsState['data'] = {
  errorDescriptions: [],
  errorDescription: null,
};

export const dataErrorDescriptionsReducer: Reducer<IAdminErrorDescriptionsState['data'], ErrorDescriptionsActions> = (
  state = initialState,
  action: ErrorDescriptionsActions
) => {
  switch (action.type) {
    case getErrorDescriptionsActionTypes.SUCCESS: {
      return {...state, errorDescriptions: action.payload.data};
    }
    case getErrorDescriptionActionTypes.SUCCESS: {
      return {...state, errorDescription: action.payload};
    }
    case resetErrorDescriptionActionTypes.RESET: {
      return {...state, errorDescription: initialState.errorDescription};
    }
    default: {
      return state;
    }
  }
};
