import {IModule} from 'redux-dynamic-modules';
import {IBusinessPortalAssociationPageState} from './types';
import {businessPortalAssociationPageReducer} from './reducer';

export interface IBusinessPortalAssociationPageModuleState {
  businessPortalAssociationPage: IBusinessPortalAssociationPageState;
}

export const BusinessPortalAssociationPageModule: IModule<IBusinessPortalAssociationPageModuleState> = {
  id: 'businessPortalAssociationPage',
  reducerMap: {
    businessPortalAssociationPage: businessPortalAssociationPageReducer,
  },
};
