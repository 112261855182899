import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';

const Root = styled.div`
  height: 500px;
`;

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;

  @media ${breakpoints.sm} {
    padding: 0;
    width: 50%;
  }
`;

const Title = styled.div`
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size32};
  line-height: 33px;
  color: ${Theme.color.gray};

  @media ${breakpoints.sm} {
    line-height: 56px;
  }
`;

const Message = styled.div`
  width: 100%;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.black0};

  @media ${breakpoints.sm} {
    width: 438px;
  }
`;

const ProfileSettingsButton = styled(PrimaryButton)`
  width: 100%;
  margin-top: 24px;

  @media ${breakpoints.sm} {
    width: 168px;
  }
`;

const ProblemPaymentMessage = () => {
  const history = useHistory();

  const redirectToProfileSettings = useCallback(() => {
    history.push('/account-details/subscriptions');
  }, [history]);

  return (
    <Root className="d-flex w-100 justify-content-center align-items-start align-self-center">
      <Wrapper className="d-flex flex-column align-self-center">
        <Title>Problems with a card occurred.</Title>
        <Message>
          There are problems with your subscription payment. You can change the payment method in your profile to access
          our service.
        </Message>
        <ProfileSettingsButton size="small" onClick={redirectToProfileSettings}>
          Profile settings
        </ProfileSettingsButton>
      </Wrapper>
    </Root>
  );
};

export default ProblemPaymentMessage;
