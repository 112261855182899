import {combineReducers} from 'redux';

import {communicationBusinessPortalHorseReducer} from './communications';
import {dataBusinessPortalHorseReducer} from './data';
import {uiBusinessPortalHorseReducer} from './ui';
import {IBusinessPortalHorseState} from '../types';

export const businessPortalHorseReducer = combineReducers<IBusinessPortalHorseState>({
  communications: communicationBusinessPortalHorseReducer,
  data: dataBusinessPortalHorseReducer,
  ui: uiBusinessPortalHorseReducer
});
