import {IModule} from 'redux-dynamic-modules';
import {IContactRequestState} from 'UserProfile/store/contactRequest/types';
import {contactRequestReducer} from 'UserProfile/store/contactRequest/reducer/index';

export interface IContactRequestModuleState {
  contactRequest: IContactRequestState;
}

export const ContactRequestModule: IModule<IContactRequestModuleState> = {
  id: 'contactRequest',
  reducerMap: {
    contactRequest: contactRequestReducer
  }
};
