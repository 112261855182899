export enum PhenotypeState {
  NotTested = 'NotTested',
  Inconclusive = 'Inconclusive',
  Negative = 'Negative',
  Positive = 'Positive'
}

export const PhenotypeStateName: Record<PhenotypeState, string> = {
  [PhenotypeState.NotTested]: 'Not ordered',
  [PhenotypeState.Inconclusive]: 'Inconclusive',
  [PhenotypeState.Negative]: 'Not detected',
  [PhenotypeState.Positive]: 'Detected'
};
