import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IRequestParams} from 'Common/models/IRequestParams';
import {ICoupon} from 'Coupon/models/ICoupon';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {convertCouponToClient, convertCouponToRequest} from 'Admin/AdminDashboard/services/converters/coupon';
import {ICouponRequest} from 'Admin/AdminDashboard/components/Coupons/CouponForm/validation';

const getCoupons = async (params?: IRequestParams): Promise<IListResponse<ICoupon>> => {
  const response = await axiosWrapper.get<IListResponse<ICoupon>>(`/CouponAdmin`, {params});
  return {
    ...response.data,
    data: response.data.data.map(convertCouponToClient),
  };
};

const getCoupon = async (id: number): Promise<ICoupon> => {
  const response = await axiosWrapper.get<IObjectResponse<ICoupon>>(`/CouponDetailsAdmin/${id}`);
  return convertCouponToClient(response.data.data);
};

const createCoupon = async (coupon: ICouponRequest): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<ICoupon>>(`/CouponCreateAdmin`, convertCouponToRequest(coupon));
};

const updateCoupon = async (coupon: ICouponRequest): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<ICoupon>>(`/CouponUpdateAdmin`, convertCouponToRequest(coupon));
};

const deleteCoupon = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/CouponDeleteAdmin`, {data: {id}});
};

const changeCouponStatus = async (id: number, isEnabled: boolean): Promise<void> => {
  await axiosWrapper.patch<IObjectResponse<ICoupon>>(`/CouponStatusUpdateAdmin`, {id, isEnabled});
};

const generateNewCode = async (): Promise<string> => {
  const response = await axiosWrapper.get<IObjectResponse<string>>('/GenerateCouponCodeAdmin');
  return response.data.data;
};

export default {
  getCoupons,
  getCoupon,
  createCoupon,
  updateCoupon,
  deleteCoupon,
  changeCouponStatus,
  generateNewCode,
};
