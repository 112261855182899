import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';
import {IListResponse} from 'Common/models/IResponse';
import {IMainPageHorse} from 'Main/models/IMainPageHorses';

const STATE_NAME = 'mainPageHorses';

export interface IMainPageHorsesState {
  data: {
    horses: IMainPageHorse[];
  };
  communications: {
    horsesLoading: ICommunication;
  };
}

export const getMainPageHorsesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSES');
export type getMainPageHorsesActions = MakeCommunicationActions<
  typeof getMainPageHorsesActionTypes,
  {success: IListResponse<IMainPageHorse>}
>;

export type MainPageHorsesActions = getMainPageHorsesActions;
