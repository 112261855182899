export const convertToFormData = (file: File, fieldName?: string) => {
  const formData = new FormData();
  formData.append(fieldName || 'uploadedFile', file);
  return formData;
};

export const convertFilesToFormData = (files: File[]) => {
  const formData = new FormData();
  files.forEach((file) => formData.append('files', file));
  return formData;
};
