import {IModule} from 'redux-dynamic-modules';
import {IAdminPermissionsState} from './types';
import {adminPermissionsReducer} from './reducer';

export interface IAdminPermissionsModuleState {
  adminPermissions: IAdminPermissionsState;
}

export const AdminPermissionsModule: IModule<IAdminPermissionsModuleState> = {
  id: 'adminPermissions',
  reducerMap: {
    adminPermissions: adminPermissionsReducer
  }
};
