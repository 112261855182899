import {combineReducers} from 'redux';

import {IFacetFilterState} from '../types';
import {communicationFacetFilterReducer} from './communications';
import {dataFacetFilterReducer} from './data';

export const reducer = combineReducers<IFacetFilterState>({
  communications: communicationFacetFilterReducer,
  data: dataFacetFilterReducer,
});
