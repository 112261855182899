import {Reducer} from 'redux';

import {
  Actions,
  createOrderActionTypes,
  getContactInformationActionTypes,
  getCouponActionTypes,
  getHorsesActionTypes,
  getOrderPackagesActionTypes,
  getSummaryActionTypes,
  ICreateOrderState,
  resetCouponActionTypes,
  resetSummaryActionTypes,
} from '../types';

const initialStateList: ICreateOrderState['data'] = {
  horses: [],
  summary: null,
  coupon: null,
  createdOrder: null,
  contactInformation: null,
  orderPackages: [],
};

export const dataReducer: Reducer<ICreateOrderState['data'], Actions> = (state = initialStateList, action: Actions) => {
  switch (action.type) {
    case getHorsesActionTypes.SUCCESS: {
      return {...state, horses: action.payload};
    }
    case getSummaryActionTypes.SUCCESS: {
      return {...state, summary: action.payload};
    }
    case getCouponActionTypes.SUCCESS: {
      return {...state, coupon: action.payload};
    }
    case createOrderActionTypes.SUCCESS: {
      return {...state, createdOrder: action.payload};
    }
    case getContactInformationActionTypes.SUCCESS: {
      return {...state, contactInformation: action.payload};
    }
    case getOrderPackagesActionTypes.SUCCESS: {
      return {...state, orderPackages: action.payload};
    }

    case resetCouponActionTypes.RESET: {
      return {...state, coupon: null};
    }
    case resetSummaryActionTypes.RESET: {
      return {...state, summary: null};
    }
    default: {
      return state;
    }
  }
};
