import {Reducer} from 'redux';

import {AdminGeneGroupImageActions, getImageActionTypes, IAdminGeneGroupImageState} from '../types';

const initialImageState: IAdminGeneGroupImageState['data'] = {image: null};

export const dataGeneGroupImageReducer: Reducer<IAdminGeneGroupImageState['data'], AdminGeneGroupImageActions> = (
  state = initialImageState,
  action: AdminGeneGroupImageActions
) => {
  switch (action.type) {
    case getImageActionTypes.SUCCESS: {
      return {...state, image: action.payload};
    }
    default: {
      return state;
    }
  }
};
