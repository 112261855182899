import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {connect, ConnectedProps} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import Topic from './Topic';
import {IconName} from 'Icon/components/Icon';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {IAppState} from 'Common/store/IAppState';
import {OnlineReportActions} from 'OnlineReport/store';
import {AdminOnlineReportActions} from 'Admin/AdminDashboard/store/adminOnlineReport';
import {useSummaryGenotypes} from 'OnlineReport/hooks/useSummaryGenotypes';
import Loading from 'Loading/components/Loading';
import {IOnlineReportExternalProps} from 'OnlineReport/models/shared/IOnlineReportExternalProps';
import {OnlineReportType} from '../shared/OnlineReportType';
import {GeneticNoteState, GeneticNoteType, IGeneticNote} from 'OnlineReport/models/Summary/IGeneticNote';
import GeneticNoteSection from 'OnlineReport/components/shared/GeneticNoteSection';
import usePermissions from 'Permissions/hooks/usePermissions';
import ColorPalette from 'Common/constants/ColorPalette';
import {VisitorType} from 'Common/constants/VisitorType';
import {Permission} from 'Permissions/constants/Permission';
import {Divider, GenotypeLabel} from 'OnlineReport/shared/styles';
import {summaryDistributor} from './distributor';
import {useOnlineReportErrors} from 'OnlineReport/hooks/useOnlineReportErrors';
import {Nullable} from 'Common/types';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const REQUIRED_TESTS_DESCRIPTION = `For various reasons, the horse may have incomplete tests. Remember this when the report is reviewed.`;
const VARIANT_SUMMARY_DESCRIPTION = `A genetic “variant” can also be called an “allele”. It refers to genetic differences within a specific genetic region. For instance: The coat colors Cream, Pearl, Sunshine and Snowdrop are all different coat color dilutions, and may have slightly different effects on how the horse appears. However, each of these is all found within the same genetic region of the horse genome “SLC45A2” and so they are “variants” of that region. Typically, a horse can have two variants within each region; one inherited from the father and one from the mother. On very rare occasions, a “variant” or “mutation” actually mutates yet again making it appear as if a horse has three or more variants within a region!`;
const ABILITIES_DESCRIPTION = `Abilities can also be largely genetic and are, thus, also inherited much like coat or eye color. An example of a genetic ability can be seen with the Myostatin region (MSTN). Horses inheriting two variants of “sprint-type” myostatin tend to be faster in short bursts, while horses with two “endurance-type” may not be as quick, but may have longer distance abilities. Similarly, horses with two DMRT3 variants may have a smoother gait and or be seen as “gaited” however, they may not be able to perform the clear 3 beat canter or tempi change seen in a non-DMRT3 horse.`;
const COAT_COLOR_DESCRIPTION = `Coat color can be one of the most interesting subjects when it comes to genetics as a great many of the variants can be easily seen on the horse tested. At the same time, there are many variants resulting in a broad range of visible effects on the horse despite a “dominant” or “partial dominant” reputation. For example: A horse may test positive for “Lethal White Overo” (EDNRB) and the classic appearance of that horse may include white markings, often across the face, and possibly a blue eye or two. “Overo” is defined as a “dominant” gene (LWO). However, there are many cases of horses who do have the Lethal White Overo variant but still may not have white markings or blue eyes.`;
const HEALTH_DESCRIPTION = `Health variants are genetics we see are highly correlated with a particular disease, trait, or change but may not necessarily mean the horse will actually have or contract that issue. Knowing that a horse is at a higher risk for a particular disease is helpful in the day-to-day management of that animal. For example, we know that Recurrent Laryngeal Neuropathy (RLN or “Roaring”) has an associated set of health variants.  Therefore, if a horse tests positive for two health variants (RLN/RLN) one might avoid extended stressful disciplines or events that may encourage the development of roaring such as endurance or eventing.`;

const iconStyle = {size: 40, stroke: false, fill: false};

const SummaryRow = styled.div<{color?: string}>`
  display: grid;
  grid-template-rows: auto auto;
  padding-top: 16px;
  ${({color}) => color && `background-color: ${color}`};

  @media ${breakpoints.sm} {
    padding: 20px 0;
    grid-template-columns: 50% 50%;
  }

  @media ${breakpoints.md} {
    padding: 40px 25px;
  }
`;

const Button = styled(LinkButton)`
  width: 168px;
`;

const Content = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 32px;
  margin-top: 16px;

  @media ${breakpoints.sm} {
    margin-top: 0;
  }
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IOnlineReportExternalProps;

const Summary = (props: AllProps) => {
  const {
    summaryAbilities,
    summaryColors,
    summaryHealthIssues,
    getRequiredTests,
    requiredTests,
    requiredTestsLoading,
    getSummaryAbilities,
    getSummaryColors,
    getSummaryHealthIssues,
    summaryAbilitiesLoading,
    summaryColorsLoading,
    summaryHealthIssuesLoading,
    horseId,
    orderId: orderIdentifier,
    reportType,
    onHasError,
    breedPanels,
    breedPanelsLoading,
    getBreedPanels,
    resetRequiredTestsResults,
    geneticVariants,
    geneticVariantsLoading,
    getGeneticVariants,
  } = props;

  const [isPageLoading, setIsPageLoading] = useState(true);

  const {isMobile} = useMediaQuery();

  const isUserMode = reportType === OnlineReportType.User;

  const isReview =
    reportType === OnlineReportType.ReviewAdmin || reportType === OnlineReportType.ReviewAdminAssociation;

  const {hasPermission, isVisitorType} = usePermissions();

  const isAdmin = isVisitorType(VisitorType.Admin) || hasPermission(Permission.Admin);

  const isAdminMode = !isUserMode && isAdmin;

  const orderId = isReview && parseInt(orderIdentifier) ? +orderIdentifier : undefined;

  const {location} = useHistory();

  const summaryError = summaryAbilitiesLoading.error || summaryColorsLoading.error || summaryHealthIssuesLoading.error;
  const isSummaryLoading = [summaryAbilitiesLoading, summaryColorsLoading, summaryHealthIssuesLoading].some(
    (i) => i.isRequesting
  );

  useEffect(() => {
    if (parseInt(horseId) && !isSummaryLoading) {
      getSummaryAbilities(+horseId, orderId);
      getSummaryColors(+horseId, orderId);
      getSummaryHealthIssues(+horseId, orderId);
      setIsPageLoading(false);
    }

    if (isAdminMode && parseInt(horseId) && orderId) {
      getRequiredTests(+orderId, +horseId);
    }

    if (parseInt(horseId) && !breedPanelsLoading.isRequesting) {
      getBreedPanels(+horseId, orderId);
    }

    if (parseInt(horseId) && !geneticVariantsLoading.isRequesting) {
      getGeneticVariants(+horseId, orderId);
    }

    return () => {
      resetRequiredTestsResults();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resetRequiredTestsResults,
    getBreedPanels,
    getRequiredTests,
    getSummaryAbilities,
    getSummaryColors,
    getSummaryHealthIssues,
    getGeneticVariants,
    horseId,
    isAdminMode,
    orderId,
    reportType,
  ]);

  const geneticNoteState = !!orderId ? GeneticNoteState.New : GeneticNoteState.Current;
  const {
    abilityProperties,
    summaryGenotypes,
    coatColorProperties,
    healthIssueProperties,
    horseBreedPanelGenotypes,
    requiredTestProperties,
  } = useSummaryGenotypes(
    summaryAbilities,
    summaryColors,
    summaryHealthIssues,
    breedPanels ?? [],
    geneticVariants,
    requiredTests
  );

  const errorInfo =
    summaryError || requiredTestsLoading.error || breedPanelsLoading.error || geneticVariantsLoading.error;
  const isLoading =
    isSummaryLoading || [requiredTestsLoading, breedPanelsLoading, geneticVariantsLoading].some((i) => i.isRequesting);

  const {onlineReportErrors} = useOnlineReportErrors({horseId: +horseId, error: summaryError});

  useEffect(() => {
    if (errorInfo) {
      onHasError(true);
    } else {
      onHasError(false);
    }
  }, [onHasError, errorInfo]);

  const getGeneticNoteWithType = useCallback((note: Nullable<IGeneticNote>, type: GeneticNoteType) => {
    if (!note) {
      return {isPinned: false, value: '', type, id: 0};
    }

    return {...note, type};
  }, []);

  const isExistRequiredTests = requiredTests && requiredTests.length > 0;

  const hasHealthIssue = healthIssueProperties && healthIssueProperties.length !== 0;

  if (isLoading || isPageLoading) {
    return <Loading />;
  }

  return (
    <>
      {onlineReportErrors}

      {isExistRequiredTests && (
        <SummaryRow color={ColorPalette.gray49}>
          <Topic
            name="Incomplete tests"
            description={REQUIRED_TESTS_DESCRIPTION}
            icon={{name: IconName.MarkedNotebook, ...iconStyle}}
          />
          <Content>{requiredTestProperties}</Content>
        </SummaryRow>
      )}
      {!errorInfo && (
        <SummaryRow>
          <Topic
            name="Variant summary"
            description={VARIANT_SUMMARY_DESCRIPTION}
            icon={{name: IconName.Notebook, ...iconStyle}}
          />
          <Content>
            {summaryGenotypes}
            {horseBreedPanelGenotypes}
            <GeneticNoteSection
              isAdminMode={isAdminMode}
              horseId={horseId}
              geneticNoteState={geneticNoteState}
              note={getGeneticNoteWithType(geneticVariants?.note || null, GeneticNoteType.Genotypes)}
            />
          </Content>
        </SummaryRow>
      )}

      {summaryAbilities && (
        <>
          <Divider />
          <SummaryRow>
            <Topic
              name="Performance and Abilities"
              description={ABILITIES_DESCRIPTION}
              icon={{name: IconName.Notebook, ...iconStyle}}
            >
              {isUserMode && abilityProperties && (
                <Button
                  to={location.pathname.replace('summary', 'performance-abilities')}
                  size={isMobile ? 'small' : 'medium'}
                >
                  Full report
                </Button>
              )}
            </Topic>
            <Content>
              {abilityProperties}
              <GeneticNoteSection
                isAdminMode={isAdminMode}
                horseId={horseId}
                geneticNoteState={geneticNoteState}
                note={getGeneticNoteWithType(summaryAbilities?.note || null, GeneticNoteType.Abilities)}
              />
            </Content>
          </SummaryRow>
        </>
      )}

      {summaryColors && (
        <>
          <Divider />
          <SummaryRow>
            <Topic name="Coat color" description={COAT_COLOR_DESCRIPTION} icon={{name: IconName.Drop, ...iconStyle}}>
              {isUserMode && coatColorProperties && (
                <Button to={location.pathname.replace('summary', 'coat-color')} size={isMobile ? 'small' : 'medium'}>
                  Full report
                </Button>
              )}
            </Topic>
            <Content>
              {coatColorProperties}
              <GeneticNoteSection
                isAdminMode={isAdminMode}
                horseId={horseId}
                geneticNoteState={geneticNoteState}
                note={getGeneticNoteWithType(summaryColors?.note || null, GeneticNoteType.Coat)}
              />
            </Content>
          </SummaryRow>
        </>
      )}

      {summaryHealthIssues && (
        <>
          <Divider />
          <SummaryRow>
            <Topic name="Health Variants" description={HEALTH_DESCRIPTION} icon={{name: IconName.Cross, ...iconStyle}}>
              {isUserMode && hasHealthIssue && (
                <Button
                  to={location.pathname.replace('summary', 'health-variants')}
                  size={isMobile ? 'small' : 'medium'}
                >
                  Full report
                </Button>
              )}
            </Topic>
            <Content>
              {hasHealthIssue ? (
                healthIssueProperties
              ) : (
                <GenotypeLabel>
                  Horse has not tested positive for any known disease variants on this panel.
                </GenotypeLabel>
              )}
              <GeneticNoteSection
                isAdminMode={isAdminMode}
                horseId={horseId}
                geneticNoteState={geneticNoteState}
                note={getGeneticNoteWithType(summaryHealthIssues?.note || null, GeneticNoteType.HealthIssues)}
              />
            </Content>
          </SummaryRow>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: IAppState, externalProps: IOnlineReportExternalProps) => {
  const {reportType} = externalProps;

  const distributor = summaryDistributor[reportType];
  return {...distributor?.state(state, externalProps)};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, undefined, OnlineReportActions | AdminOnlineReportActions>,
  externalProps: IOnlineReportExternalProps
) => {
  const {reportType} = externalProps;

  const distributor = summaryDistributor[reportType];
  const dispatchToProps = {...distributor.dispatch};

  return bindActionCreators(dispatchToProps, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(React.memo(Summary));
export default Connected;
