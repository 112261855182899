import React, {memo} from 'react';
import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import {TextBase} from '../styled';

export const Root = styled(TextBase)`
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${Theme.color.gray};
`;

export const NoData = () => {
  return <Root className="d-flex w-100 justify-content-center">No data</Root>;
};

export default memo(NoData);
