import {Reducer} from 'redux';

import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';
import {BusinessPortalOrderActions, getOrdersActionTypes, IBusinessPortalOrderState} from '../types';

const initialState: IBusinessPortalOrderState['ui'] = {
  pagination: {countPerPage: MIN_COUNT_PER_PAGE, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiBusinessPortalOrderReducer: Reducer<IBusinessPortalOrderState['ui'], BusinessPortalOrderActions> = (
  state = initialState,
  action: BusinessPortalOrderActions
) => {
  switch (action.type) {
    case getOrdersActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
