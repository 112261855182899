import {Reducer} from 'redux';

import {
  getPaymentDetailsActionTypes,
  IPaymentDetailsUploadState,
  PaymentDetailsUploadActions,
  resetPaymentDetailsActionTypes,
} from 'Admin/AdminDashboard/store/common/paymentDetailsUpload/types';

const initialState: IPaymentDetailsUploadState['data'] = {
  paymentDetails: null,
};

export const dataPaymentDetailsUploadReducer: Reducer<IPaymentDetailsUploadState['data'], PaymentDetailsUploadActions> =
  (state = initialState, action: PaymentDetailsUploadActions) => {
    switch (action.type) {
      case getPaymentDetailsActionTypes.SUCCESS:
        return {...state, paymentDetails: action.payload};

      case resetPaymentDetailsActionTypes.RESET:
        return {...state, paymentDetails: initialState.paymentDetails};

      default: {
        return state;
      }
    }
  };
