import {IModule} from 'redux-dynamic-modules';
import {RouterState, connectRouter, routerMiddleware} from 'connected-react-router';
import {History} from 'history';

export const RouterModule = (history: History): IModule<RouterState> => {
  return {
    id: 'router',
    reducerMap: {
      router: connectRouter(history)
    } as any,
    middlewares: [routerMiddleware(history)]
  };
};
