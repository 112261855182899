import {Reducer} from 'redux';

import {AdministratorsActions, getAdministratorsActionTypes, IAdministratorState} from '../types';
import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';

const initialState: IAdministratorState['ui'] = {
  pagination: {countPerPage: MIN_COUNT_PER_PAGE, currentPage: 1, pageCount: 1, totalItemsCount: 1}
};

export const uiAdministratorsReducer: Reducer<IAdministratorState['ui'], AdministratorsActions> = (
  state = initialState,
  action: AdministratorsActions
) => {
  switch (action.type) {
    case getAdministratorsActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
