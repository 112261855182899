import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.orderPayment;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectPaymentIntentId(state: IAppState) {
  return selectState(state).data.paymentIntentId;
}

export function selectOrderSummary(state: IAppState) {
  return selectState(state).data.summary;
}

export function selectPayPalOrder(state: IAppState) {
  return selectState(state).data.payPalOrder;
}
