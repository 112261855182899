import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {IAdminChangeEmailState, changeEmailActionTypes, changeEmailActions} from '../types';

export const communicationChangeEmailReducer = combineReducers<
  IAdminChangeEmailState['communications'],
  changeEmailActions
>({
  changeUserEmailUpdating: makeCommunicationReducerFromEnum(changeEmailActionTypes)
});
