import makeCommunicationReducer from './makeCommunicationReducer';
import {ICommunicationActionTypes, IMakeCommunicationActionTypes} from './types';

const makeCommunicationReducerFromEnum = <T extends IMakeCommunicationActionTypes<any, any, any>>({
  REQUEST: request,
  SUCCESS: success,
  FAILURE: failure
}: ICommunicationActionTypes<any, any, any>) => {
  return makeCommunicationReducer<T>({requestType: request, successType: success, failureType: failure});
};

export default makeCommunicationReducerFromEnum;
