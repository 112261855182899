import {IOrderHorse} from 'Order/models/IOrderHorse';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {ITestSummary} from 'Order/models/ITestSummary';
import {IOrderCoupon} from 'Order/models/IOrderCoupon';
import {ICreatedOrder} from 'Order/models/ICreatedOrder';
import {ICreateOrderRequest} from 'Order/models/ICreateOrderRequest';

import {convertTestSummaryToClient} from './converters/summary';
import {IServerTestSummary, ITestSummaryRequest} from './types/summary';
import {convertCouponToClient} from './converters/coupon';
import {convertCreatedOrderToClient, convertCreateOrderRequestToServer} from './converters/order';
import {ICheckCouponRequest} from './types/coupon';
import {IContactInformation} from 'Order/models/IContactInformation';
import {isAdminVisitor} from 'Common/helpers/isAdminVisitor';
import {IOrderPackage} from 'Order/models/IOrderPackage';
import {withPrice} from 'Common/helpers/withPrice';

const getHorses = async (): Promise<IOrderHorse[]> => {
  const response = await axiosWrapper.get<IListResponse<IOrderHorse>>(`/OrdersHorseOfUser/`);
  return response.data.data || []; // possible can be null when server DB is empty
};

const getSummary = async (request: ITestSummaryRequest, userId?: number): Promise<ITestSummary> => {
  const response = isAdminVisitor()
    ? await axiosWrapper.post<IObjectResponse<IServerTestSummary>>(`/OrderPaymentSummaryAdmin`, {
        ...request,
        userId,
      })
    : await axiosWrapper.post<IObjectResponse<IServerTestSummary>>(`/OrdersSummary`, request);
  return convertTestSummaryToClient(response.data.data);
};

const getCoupon = async (request: ICheckCouponRequest, userId?: number): Promise<IOrderCoupon> => {
  const response = isAdminVisitor()
    ? await axiosWrapper.post<IObjectResponse<{data: IOrderCoupon}>>(`/DiscountValidateAdmin`, {
        ...request,
        userId,
      })
    : await axiosWrapper.post<IObjectResponse<{data: IOrderCoupon}>>(`/OrdersCheckCoupon`, request);
  return convertCouponToClient(response.data.data.data);
};

const createOrder = async (request: ICreateOrderRequest): Promise<ICreatedOrder> => {
  const response = await axiosWrapper.post<IObjectResponse<ICreatedOrder>>(
    `/OrdersCreate`,
    convertCreateOrderRequestToServer(request)
  );
  return convertCreatedOrderToClient(response.data.data);
};

const getContactInformation = async (): Promise<IContactInformation> => {
  const response = await axiosWrapper.get<IObjectResponse<IContactInformation>>('/UsersContactInformation');
  return response.data.data;
};

const updateContactInformation = async (data: IContactInformation): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<void>>('/UsersContactInformationUpdate', data);
};

const getOrderPackages = async (): Promise<IOrderPackage[]> => {
  const response = await axiosWrapper.get<IListResponse<IOrderPackage>>(`/OrderPackages`);
  return response.data.data.map(withPrice);
};

export default {
  getHorses,
  getSummary,
  getCoupon,
  createOrder,
  getContactInformation,
  updateContactInformation,
  getOrderPackages,
};
