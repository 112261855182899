import React, {memo, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import {IconName, IProps as IconProps} from 'Icon/components/Icon';
import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import ColoredIcon from 'Icon/components/ColoredIcon';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {Nullable} from 'Common/types';
import {IVideo} from 'Video/models/IVideo';
import {Panel} from './shared/StyledComponents';
import VideoPreview from './VideoPreview/VideoPreview';

const UploadText = styled.span`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${ColorPalette.gray44};
  text-align: center;
  margin-top: 12px;
`;

const EmptyPanel = styled(Panel)`
  cursor: pointer;
`;

interface IProps {
  actionButtonsContainerHeight?: string;
  value: Nullable<IVideo>;
  height?: string;
  width?: string;
  iconName?: IconName;
  iconProps?: IconProps;
  title?: string;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  showPreviewOnClick?: boolean;
  onClick?(url?: string): void;
  onChange?(url?: string): void;
  onDelete?(): void;
}

type AllProps = IBaseControlProps & IProps;

function VideoUploader(props: AllProps) {
  const {
    height,
    width,
    iconProps,
    inputStyle,
    value,
    title,
    showEditButton,
    showDeleteButton,
    showPreviewOnClick,
    onClick,
    onChange,
    actionButtonsContainerHeight,
    onDelete
  } = props;

  const iconName = props.iconName || iconProps?.name;
  const isNeedLoadVideo = !value?.url;

  const onAddVideo = useCallback(() => {
    onChange && onChange();
  }, [onChange]);

  const handleEditVideo = useCallback(
    (url?: string) => {
      onChange && onChange(url);
    },
    [onChange]
  );

  const handleDeleteVideo = useCallback(() => {
    onDelete && onDelete();
  }, [onDelete]);

  const onPreviewClick = useCallback(() => {
    onClick && onClick(value?.url);
  }, [onClick, value]);

  const renderVideo = useMemo(() => {
    if (isNeedLoadVideo) {
      return (
        <EmptyPanel
          className={classnames(
            'd-flex',
            'flex-column',
            'justify-content-center',
            'align-items-center',
            props.inputClassName
          )}
          style={inputStyle}
          height={height}
          width={width}
          onClick={onAddVideo}
        >
          {iconName && <ColoredIcon name={iconName} color={ColorPalette.gray48} size={50} fill={true} {...iconProps} />}
          {title && <UploadText>{title}</UploadText>}
        </EmptyPanel>
      );
    }

    return (
      <VideoPreview
        height={height}
        actionButtonsContainerHeight={actionButtonsContainerHeight}
        width={width}
        value={value}
        showEditButton={showEditButton}
        showDeleteButton={showDeleteButton}
        showPreviewOnClick={showPreviewOnClick}
        onClick={onPreviewClick}
        onEditVideo={handleEditVideo}
        onDeleteVideo={handleDeleteVideo}
      />
    );
  }, [
    actionButtonsContainerHeight,
    handleDeleteVideo,
    handleEditVideo,
    height,
    iconName,
    iconProps,
    inputStyle,
    isNeedLoadVideo,
    onAddVideo,
    onPreviewClick,
    props.inputClassName,
    showDeleteButton,
    showEditButton,
    showPreviewOnClick,
    title,
    value,
    width
  ]);

  return <div>{renderVideo}</div>;
}

VideoUploader.defaultProps = {
  height: '160px',
  width: '160px',
  showDeleteButton: true,
  showEditButton: true
};

export default memo(VideoUploader);
export type VideoUploaderProps = IProps;
