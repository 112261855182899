import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.resetPassword;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectResultMessage(state: IAppState) {
  return selectState(state).data.message;
}
