import React, {memo} from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const Root = styled.div`
  padding: 16px;
  background: ${Theme.color.white};
  box-shadow: 0 6px 14px ${hexToRgba(ColorPalette.black0, 0.05)}, 0 0 4px ${hexToRgba(ColorPalette.black0, 0.02)};
  border-radius: 8px;

  @media ${breakpoints.sm} {
    padding: 24px;
  }
`;

const ClosedRoot = styled.div`
  padding-left: 16px;

  @media ${breakpoints.sm} {
    padding-left: 24px;
  }
`;

const ChildrenWrapper = styled.div`
  padding-left: 0;

  @media ${breakpoints.sm} {
    padding-left: 48px;
  }
`;

const StepInfo = styled.div`
  margin-bottom: 12px;
`;

const StepNumber = styled.div<{isClosedColor?: boolean}>`
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({isClosedColor}) => (isClosedColor ? Theme.color.gray : Theme.color.primary)};
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.white};
  text-align: center;

  @media ${breakpoints.sm} {
    min-width: 32px;
    height: 32px;
  }
`;

const StepName = styled.div<{isClosedColor?: boolean; isNumberExist?: boolean; isMobile?: boolean}>`
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size24};
  line-height: 32px;
  align-items: center;
  margin-left: ${({isNumberExist, isMobile}) => (isNumberExist ? 16 : !isNumberExist && isMobile ? 12 : 48)}px;
  color: ${({isClosedColor}) => (isClosedColor ? Theme.color.gray : Theme.color.black)};
`;

const EditButton = styled(PrimaryButton)<{isMobile?: boolean}>`
  width: 168px;
  margin-top: ${({isMobile}) => (isMobile ? 24 : 0)}px;
`;

const ContinueButton = styled(PrimaryButton)`
  width: 168px;
  margin-top: 16px;
`;

const CheckMarkIcon = styled(ColoredIcon)`
  margin-left: 7px;
  margin-right: 3px;
`;

interface IProps {
  stepNumber?: number;
  label: string;
  onContinueClick?(): void;
  isLast?: boolean;
  hasError?: boolean;
}

interface IChildrenProps {
  isEdit?: boolean;
  isOpen?: boolean;
  isDisabledEditButton?: boolean;
  onContinueClick?(): void;
  onEditClick?(): void;
  onClose?(): void;
  isValid?: boolean;
}

type AllProps = IProps & IChildrenProps;

const BaseStepLayout = (props: React.PropsWithChildren<AllProps>) => {
  const {stepNumber, label, isLast, onContinueClick, onClose, onEditClick, children, ...rest} = props;
  const {isEdit, isOpen, isDisabledEditButton, isValid = true} = rest;

  const {isMobile} = useMediaQuery();

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {...rest});
    }
    return child;
  });

  if (!isOpen && !isEdit) {
    return (
      <ClosedRoot>
        <StepInfo className="d-flex align-items-center">
          {stepNumber && (
            <StepNumber isClosedColor={true} className="d-flex align-items-center justify-content-center">
              {stepNumber}
            </StepNumber>
          )}
          <StepName isNumberExist={true} isClosedColor={true}>
            {label}
          </StepName>
        </StepInfo>
      </ClosedRoot>
    );
  }

  if (!isEdit) {
    return (
      <Root className="d-flex w-100 flex-column">
        <div className="d-flex justify-content-between">
          <StepInfo className="d-flex align-items-center">
            <CheckMarkIcon size={20} fill={true} stroke={false} name={IconName.Check} color={ColorPalette.green2} />
            <StepName isNumberExist={true} isClosedColor={true}>
              {label}
            </StepName>
          </StepInfo>
          {!isMobile && (
            <EditButton disabled={isDisabledEditButton} variant="outlined" size="small" onClick={onEditClick}>
              Edit
            </EditButton>
          )}
        </div>
        <ChildrenWrapper>{childrenWithProps}</ChildrenWrapper>
        {isMobile && (
          <EditButton
            disabled={isDisabledEditButton}
            isMobile={true}
            className="w-100"
            variant="outlined"
            size="small"
            onClick={onEditClick}
          >
            Edit
          </EditButton>
        )}
      </Root>
    );
  }

  return (
    <Root className="d-flex w-100 flex-column">
      <StepInfo className="d-flex align-items-center">
        {stepNumber && (
          <StepNumber className="d-flex align-items-center justify-content-center">{stepNumber}</StepNumber>
        )}
        <StepName isNumberExist={!!stepNumber} isMobile={isMobile}>
          {label}
        </StepName>
      </StepInfo>
      <ChildrenWrapper>{childrenWithProps}</ChildrenWrapper>
      {stepNumber && (
        <ContinueButton className="align-self-end" disabled={!isValid} onClick={onContinueClick}>
          {isLast ? 'Review order' : 'Continue'}
        </ContinueButton>
      )}
    </Root>
  );
};

export default memo(BaseStepLayout);
export type BaseStepLayoutChildrenProps = IChildrenProps;
