import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';

import {InputField, SelectField, TextAreaField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {castToOption} from 'Common/helpers/OptionHelper';
import {RequiredBy} from 'Common/types';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {
  convertToFormValues,
  convertToServer,
  convertToServerUpdating,
  IFormValues,
  initialValue,
  validationSchema,
} from './validation';
import {ITest} from 'Dictionaries/models/ITest';
import {IBreed} from 'Dictionaries/models/IBreed';
import {IBreedPanel} from 'Dictionaries/models/IBreedPanel';
import {IUpdateBreedPanelRequest} from 'Admin/AdminReference/services/models/IServerBreedPanel';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

interface IOwnProps extends IPhenotypeFormExternalProps<IBreedPanel, IUpdateBreedPanelRequest> {
  testDictionary: ISimpleDictionary<ITest>;
  breedDictionary: ISimpleDictionary<IBreed>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function BreedPanelForm(props: Props) {
  const {
    itemId,
    item,
    dictionary,
    testDictionary,
    breedDictionary,
    tests,
    testsLoading,
    breeds,
    breedsLoading,
    ...rest
  } = props;

  const {getItem} = dictionary.actions;
  const {
    actions: {getItems: getTests},
  } = testDictionary;
  const {
    actions: {getItems: getBreeds},
  } = breedDictionary;

  useEffect(() => {
    getTests();
    getBreeds();
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem, getTests, getBreeds]);

  const isLoading = testsLoading.isRequesting || breedsLoading.isRequesting;

  return (
    <DictionaryForm {...rest} itemPreloading={isLoading}>
      <InputField name="name" label="Name" placeholder="Name" isRequired />
      <SelectField name="breeds" label="Breeds" options={castToOption(breeds)} isMulti isRequired />
      <SelectField name="tests" label="Tests" options={castToOption(tests)} isMulti isRequired />
      <InputField name="positionIndex" label="Order in Report" placeholder="Order in Report" className="w-50" />
      <TextAreaField name="description" label="Description" placeholder="Description" />
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  const {breedDictionary, testDictionary} = ownProps;

  return {
    breeds: breedDictionary.selectors.selectItems(state),
    breedsLoading: breedDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    tests: testDictionary.selectors.selectItems(state),
    testsLoading: testDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    ...makePhenotypeFormSelectors(state, ownProps.dictionary),
  };
};

const BreedPanelFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting ? convertToFormValues(item) : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(BreedPanelForm);

const connector = connect(mapStateToProps);
export default connector(memo(BreedPanelFormFormik));
