import * as React from 'react';
import styled from 'styled-components';

import {OrderStatus} from 'Common/constants/OrderStatus';
import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon from 'Icon/components/ColoredIcon';
import Typography from 'Common/constants/Typography';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {actionLabelByStatus, statuses, statusOrdering} from './constants';
import {convertCompletedStatuses} from './helpers';
import {IAdditionalButton} from './parts/OrderStatusDropdownView';
import OrderUpdateDate from './parts/OrderUpdateDate';
import {IOrderStatusUpdateDateField} from 'Order/models/IOrderStatusUpdateDateField';

const CurrentOrderStatusRoot = styled.div`
  padding: 24px 0 0 24px;
  font-size: ${Typography.size.size18};
`;

const Status = styled.div<{clickable?: boolean}>`
  cursor: ${({clickable}) => (clickable ? 'pointer' : 'unset')};
`;

const StatusLabel = styled.div<{color: string}>`
  margin: 0 12px 0 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${Typography.size.size18};
  font-family: ${Typography.family.openSans};
  color: ${({color}) => color};
`;

const Divider = styled.div<{small?: boolean; height?: number}>`
  width: 1px;
  height: ${({small, height}) => height || (small ? 15 : 23)}px;
  margin: 6px 0 6px 10px;
  background: ${ColorPalette.gray24};
`;

const ActionButton = styled(PrimaryButton)<{isAdditional?: boolean}>`
  width: 200px;
  padding: 0 4px;
  margin-top: ${({isAdditional}) => (isAdditional ? '8px' : 0)};
`;

interface IProps {
  status: OrderStatus;
  onChangeOrderStatus?(): void;
  isLoading?: boolean;
  completedStatuses?: OrderStatus | OrderStatus[];
  uncompletedStatuses?: OrderStatus | OrderStatus[];
  additionalButtons?: Partial<Record<OrderStatus, IAdditionalButton>>;
  textStyle?: React.CSSProperties;
  dividerHeight?: number;
  filteredStatuses?: OrderStatus[];
  statusUpdateDates?: IOrderStatusUpdateDateField;
}

function OrderStatusList(props: IProps) {
  const {
    dividerHeight,
    textStyle,
    status,
    onChangeOrderStatus,
    uncompletedStatuses,
    additionalButtons,
    isLoading,
    completedStatuses,
    filteredStatuses,
    statusUpdateDates,
  } = props;

  const completedStatusesNumber = completedStatuses ? convertCompletedStatuses(completedStatuses) : [];
  const uncompletedStatusesNumber = uncompletedStatuses ? convertCompletedStatuses(uncompletedStatuses) : [];

  const filteredOrderStatuses = filteredStatuses
    ? statuses.filter((x) => filteredStatuses?.includes(x.status))
    : statuses;

  return (
    <CurrentOrderStatusRoot>
      {filteredOrderStatuses.map(({icon, label, color, statusUpdateDate}, i, arr) => {
        const isLast = i === arr.length - 1;
        const isCompleted = i <= statusOrdering[status] || completedStatusesNumber.includes(i);
        const isUncompleted = uncompletedStatusesNumber.includes(i);
        const isCurrent = i === statusOrdering[status];
        const hasAdditionalButtons = !!additionalButtons?.[status];

        const coloredIconColor = isUncompleted ? ColorPalette.gray48 : isCompleted ? color : ColorPalette.gray48;
        const statusLabelColor = isUncompleted
          ? ColorPalette.gray48
          : isCompleted
          ? ColorPalette.black1
          : ColorPalette.gray48;

        let counts = 0;
        statusUpdateDate &&
          statusUpdateDates &&
          statusUpdateDate.length > 0 &&
          statusUpdateDate.forEach((date) => {
            if (statusUpdateDates[date]) {
              counts++;
            }
          });
        const dividerHeightExt = dividerHeight || (counts > 1 ? counts * 16 - 4 : 23);
        const dividerHeightExtFirst = dividerHeight || (counts > 1 ? counts * 16 - 4 : 15);

        return (
          <React.Fragment key={i}>
            <div className="position-relative">
              <Status className="d-flex align-items-center">
                <ColoredIcon name={icon} color={coloredIconColor} fill={true} stroke={false} />
                <StatusLabel color={statusLabelColor} style={textStyle}>
                  {label}
                </StatusLabel>
              </Status>
              <OrderUpdateDate statusUpdateDate={statusUpdateDate} statusUpdateDates={statusUpdateDates} />
            </div>
            {!isLast && (!isCurrent || !onChangeOrderStatus) && <Divider height={dividerHeightExtFirst} />}
            {!isLast && isCurrent && onChangeOrderStatus && (
              <>
                <Divider height={dividerHeightExt} />
                <ActionButton size="medium" onClick={onChangeOrderStatus} isLoading={isLoading}>
                  {actionLabelByStatus[status]}
                </ActionButton>
                {hasAdditionalButtons && (
                  <ActionButton isAdditional={true} variant="outlined" onClick={additionalButtons?.[status]?.onClick}>
                    {additionalButtons?.[status]?.name}
                  </ActionButton>
                )}
                <Divider small={true} />
              </>
            )}
          </React.Fragment>
        );
      })}
    </CurrentOrderStatusRoot>
  );
}

export default React.memo(OrderStatusList);
