import {IModule} from 'redux-dynamic-modules';

import {IExcludeHorseState} from 'ExcludeHorse/store/types';
import {excludeHorsesReducer} from 'ExcludeHorse/store/reducer/index';

export interface IExcludeHorseModuleState {
  excludeHorses: IExcludeHorseState;
}

export const ExcludeHorseModule: IModule<IExcludeHorseModuleState> = {
  id: 'excludeHorse',
  reducerMap: {
    excludeHorses: excludeHorsesReducer
  }
};
