import React, {useCallback, useState} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import RequiredTestsList from 'Admin/shared/components/RequiredTestsList/RequiredTestsList';
import {IAdminActionsProps} from 'Admin/AdminDashboard/helpers/hooks/common';
import {IRequiredTest} from 'Admin/AdminDashboard/models/IRequiredTest';

interface IRequiredTestsParams extends Omit<IAdminActionsProps, 'action' | 'onSuccess'> {
  action(orderId: number): void;
  orderId: number;
  horseName: string;
  ownerName: string;
  requiredTests: IRequiredTest[];
}

export function useRequiredTestsModal(props: IRequiredTestsParams) {
  const {action, orderId, horseName, requiredTests, communication, ownerName} = props;

  const [isRequiredTestsModalOpen, setIsRequiredTestsModalOpen] = useState(false);
  const openRequiredTestsModal = useCallback(() => {
    action(orderId);
    setIsRequiredTestsModalOpen(true);
  }, [action, orderId]);
  const closeRequiredTestsModal = useCallback(() => setIsRequiredTestsModalOpen(false), []);

  const requiredTestsModal = (
    <ModalWindow isOpen={isRequiredTestsModalOpen} onClose={closeRequiredTestsModal}>
      <RequiredTestsList
        horseName={horseName}
        ownerName={ownerName}
        requiredTests={requiredTests}
        isLoading={communication.isRequesting}
      />
    </ModalWindow>
  );

  return {requiredTestsModal, openRequiredTestsModal};
}
