import React from 'react';
import {Permission} from 'Permissions/constants/Permission';
import {VisitorType} from 'Common/constants/VisitorType';

interface IPermissionsContext {
  hasPermission(permission: Permission | Permission[] | string | string[]): boolean;
  isVisitorType(type: VisitorType): boolean;
  isDevelopmentAdmin: boolean;
}

export const PermissionsContext = React.createContext<IPermissionsContext>(null as any);

export default function usePermissions() {
  return React.useContext(PermissionsContext);
}
