import React, {memo} from 'react';
import styled from 'styled-components';

import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {getHorseInfo} from 'HorseProfile/helpers/getHorseInfo';
import {IOwnerHorse} from 'Shared/models/IOwnerHorse';

const HorseWrapper = styled.div`
  width: 100%;
  background: ${ColorPalette.white0};
  box-shadow: 0px 6px 14px ${ColorPalette.transparent5};
  border-radius: 5px;
  padding: 9px 12px;
  margin-bottom: 8px;
  cursor: pointer;
`;

const HorseInfoWrapper = styled.div`
  margin-left: 10px;
`;

const HorseName = styled.div`
  font-family: ${Typography.family.openSans};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  letter-spacing: -0.4px;
  color: ${ColorPalette.black0};
`;

const HorseInformation = styled.div`
  font-family: Roboto;
  font-size: ${Typography.size.size13};
  color: ${ColorPalette.gray3};
  margin-top: -2px;
`;

const Breed = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface IProps {
  horse: IOwnerHorse;
}

type Props = IProps;

const UserHorsesListItem = (props: Props) => {
  const {horse} = props;

  const {breed, age} = getHorseInfo(horse);

  return (
    <HorseWrapper className="d-flex align-items-center justify-content-start">
      <Avatar
        avatarSize={AvatarSize.Custom}
        customSizeAvatar={47}
        defaultAvatarProfile={DefaultAvatarProfile.Horse}
        url={horse.avatar?.url}
      />
      <HorseInfoWrapper className="d-flex flex-column overflow-hidden">
        <HorseName>{horse.name}</HorseName>
        <HorseInformation className="d-flex flex-wrap">
          {breed && <Breed>{`${breed}, `}</Breed>}
          <div>{horse.gender}</div>
          {age && <div>{`, ${age}`}</div>}
        </HorseInformation>
      </HorseInfoWrapper>
    </HorseWrapper>
  );
};

export default memo(UserHorsesListItem);
