import {Reducer} from 'redux';

import {
  GenotypeListActions,
  getGenotypeListActionTypes,
  resetGenotypeListActionTypes,
  IGenotypeListState,
} from '../types';

const initialState: IGenotypeListState['data'] = {
  genotypes: [],
};

export const dataGenotypesReducer: Reducer<IGenotypeListState['data'], GenotypeListActions> = (
  state = initialState,
  action: GenotypeListActions
) => {
  switch (action.type) {
    case getGenotypeListActionTypes.SUCCESS: {
      return {...state, genotypes: action.payload};
    }
    case resetGenotypeListActionTypes.RESET: {
      return {...state, genotypes: initialState.genotypes};
    }
    default: {
      return state;
    }
  }
};
