import {IAppState} from 'Common/store/IAppState';
import {ICommunication} from 'Common/store/utils/communication';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {
  actions as adminActions,
  IRequestNewSampleParams as IRequestNewSampleParamsAdmin,
  selectors as adminSelectors,
} from 'Admin/AdminDashboard/store/adminOrders/index';
import {
  actions as adminAssociationsActions,
  IRequestNewSampleParams as IRequestNewSampleParamsAdminAssociationAdmin,
  selectors as adminAssociationsSelectors,
} from 'Admin/AdminAssociations/store/adminOrders';

export interface IState {
  sampleRequesting: ICommunication;
}

export interface IDispatch<T> {
  requestSample(params: T): void;
}

const adminDistributor: IDistributor<IState, IDispatch<IRequestNewSampleParamsAdmin>> = {
  state: (state: IAppState) => ({
    sampleRequesting: adminSelectors.selectCommunication(state, 'newSampleRequesting'),
  }),
  dispatch: {
    requestSample: adminActions.requestNewSample,
  },
};

const adminAssociationDistributor: IDistributor<IState, IDispatch<IRequestNewSampleParamsAdminAssociationAdmin>> = {
  state: (state: IAppState) => ({
    sampleRequesting: adminAssociationsSelectors.selectCommunication(state, 'newSampleRequesting'),
  }),
  dispatch: {
    requestSample: adminAssociationsActions.requestNewSample,
  },
};

export const requestSampleFormDistributor: Record<
  SelectedStrategy,
  IDistributor<IState, IDispatch<IRequestNewSampleParamsAdmin | IRequestNewSampleParamsAdminAssociationAdmin>>
> = {
  [SelectedStrategy.Admin]: adminDistributor,
  [SelectedStrategy.AdminAssociation]: adminAssociationDistributor,
};
