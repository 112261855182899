import {IModule} from 'redux-dynamic-modules';
import {IBusinessPortalHorseState} from 'BusinessPortal/store/horse/types';
import {businessPortalHorseReducer} from 'BusinessPortal/store/horse/reducer';

export interface IBusinessPortalHorseModuleState {
  businessPortalHorse: IBusinessPortalHorseState;
}

export const BusinessPortalHorseModule: IModule<IBusinessPortalHorseModuleState> = {
  id: 'businessPortalHorse',
  reducerMap: {
    businessPortalHorse: businessPortalHorseReducer
  }
};
