import {ICreatedOrder} from 'Order/models/ICreatedOrder';
import {convertPriceToClient} from 'Common/helpers/convertPrices';
import {ICreateOrderRequest} from 'Order/models/ICreateOrderRequest';

import {IServerCreateOrderRequest, ITestHorseRequest} from '../types/order';
import {IHorseTest} from 'Order/models/IHorseTest';
import {convertTestsToIHorseTestRequest} from 'Order/helpers/convertTestsToIHorseTestRequest';
import {IHorseTestRequest} from 'Order/models/IHorseTestRequest';

export function convertCreatedOrderToClient(createdOrder: ICreatedOrder): ICreatedOrder {
  return {
    ...createdOrder,
    amount: convertPriceToClient(createdOrder.amount),
  };
}

export function convertCreateOrderRequestToServer(request: ICreateOrderRequest): IServerCreateOrderRequest {
  return {
    ...request,
    horses: convertHorseTestRequestToServer(request.horses),
  };
}

export function convertHorseTestToServer(horseTests: IHorseTest[]): ITestHorseRequest[] {
  const tests = convertTestsToIHorseTestRequest(horseTests);

  return convertHorseTestRequestToServer(tests);
}

export function convertHorseTestRequestToServer(tests: IHorseTestRequest[]): ITestHorseRequest[] {
  return tests
    .filter((test): test is Omit<IHorseTestRequest, 'horseId'> & {horseId: number} => !!test.horseId)
    .map((test) => ({id: test.horseId, packages: test.packages?.map((id) => ({id})) || []}));
}
