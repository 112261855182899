import React from 'react';
import styled from 'styled-components';

import {IOrderCoupon} from 'Order/models/IOrderCoupon';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {InputField} from 'Common/components/FormFields/index';

import Theme from 'Common/constants/Theme';
import {breakpoints} from 'Common/constants/Breakpoints';
import {withCurrencyOrPercent} from 'Common/helpers/withCurrency';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';
import {inputLabelCss} from 'Common/components/Controls/styles';

const Root = styled.div`
  margin-bottom: 10px;
`;

const Title = styled.div`
  ${inputLabelCss};
`;

const CodeFieldContainer = styled.div`
  margin-top: 7px;

  @media ${breakpoints.sm} {
    margin-top: 16px;
  }
`;

const CodeFieldHeader = styled.div`
  font-weight: ${Typography.weight.medium500};
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  color: ${ColorPalette.gray44};
`;

const CodeField = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  @media ${breakpoints.sm} {
    flex-direction: row;
  }
`;

const CouponCardHeader = styled.div`
  margin-top: 16px;
  padding: 0 22px 0 15px;
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-size: ${Typography.size.size12};
  font-weight: ${Typography.weight.normal400};
  color: ${ColorPalette.gray44};
  line-height: 24px;
  letter-spacing: 0.5px;
`;

const CouponCardItem = styled.div<{maxWidth?: string}>`
  :not(:last-child) {
    width: 100%;
    margin-right: 20px;
    max-width: ${props => props.maxWidth ?? '100%'};
  }
`;

const CouponCardContainer = styled.div`
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06), 0px 0px 8px rgba(0, 0, 0, 0.04);
  padding: 20px 22px 20px 15px;
  cursor: pointer;

  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.black1};

  :hover {
    svg {
      path {
        fill: ${ColorPalette.red7};
      }
    }
  }
`;

const Icon = styled(IconButton)`
  path {
    fill: ${ColorPalette.gray48};
  }

  :hover {
    path {
      fill: ${ColorPalette.red7};
    }
  }
`;

const ApplyCodeButton = styled(PrimaryButton)`
  @media ${breakpoints.sm} {
    margin-left: 16px;
  }
`;

const CodeInput = styled(InputField)`
  width: 100%;
`;

interface IProps {
  coupon: IOrderCoupon | null;
  isDisabled?: boolean;
  isLoading?: boolean;
  onApplyCode(): void;
  onDeleteCoupon(): void;
}

function DiscountSection(props: IProps) {
  const {coupon, onDeleteCoupon, onApplyCode, isDisabled, isLoading} = props;

  return (
    <Root>
      <Title>Coupons and gift cards</Title>
      <div className="d-flex flex-column">
        {coupon && (
          <div className="d-flex w-100 flex-column">
            <CouponCardHeader className="d-flex w-100">
              <CouponCardItem maxWidth="165px">Code</CouponCardItem>
              <CouponCardItem maxWidth="360px">Type</CouponCardItem>
              <CouponCardItem>Discount</CouponCardItem>
            </CouponCardHeader>
            <CouponCardContainer className="d-flex w-100">
              <CouponCardItem maxWidth="165px">{coupon?.code}</CouponCardItem>
              <CouponCardItem maxWidth="360px">{coupon?.packages}</CouponCardItem>
              <div className="d-flex flex-row align-self-start">
                <div style={{marginRight: 20, wordBreak: 'normal'}}>
                  {withCurrencyOrPercent(coupon?.amount, coupon?.type)}
                </div>
                <Icon
                  className="align-self-center"
                  color={ColorPalette.gray48}
                  name={IconName.Close}
                  size={12}
                  stroke={false}
                  onClick={onDeleteCoupon}
                />
              </div>
            </CouponCardContainer>
          </div>
        )}
      </div>
      <CodeFieldContainer>
        <CodeFieldHeader hidden={!!coupon}>Code</CodeFieldHeader>
        <CodeField>
          <div className="w-50">
            <CodeInput name="couponCode" placeholder="Put your coupon or gift card code" />
          </div>
          <ApplyCodeButton type="button" disabled={isDisabled} isLoading={isLoading} onClick={onApplyCode}>
            Apply
          </ApplyCodeButton>
        </CodeField>
      </CodeFieldContainer>
    </Root>
  );
}

export default DiscountSection;
