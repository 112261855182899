import React, {memo} from 'react';
import styled from 'styled-components';

import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';
import CopyZone from 'Common/components/CopyZone/CopyZone';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {breakpoints} from 'Common/constants/Breakpoints';

const ActionButtonEdit = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 48px;
  @media ${breakpoints.sm} {
    top: 16px;
    right: 56px;
  }
`;
const ActionButtonShare = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

interface IActionProps {
  editHandler(): void;
  urlToCopy: string;
  successfulMessage: string;
}

export const Actions = memo(({editHandler, urlToCopy, successfulMessage}: IActionProps) => {
  const {isMobile} = useMediaQuery();

  const actionButtonProps = {
    color: ColorPalette.white0,
    fill: true,
    stroke: false,
    size: isMobile ? 16 : 24,
    hoverColor: ColorPalette.gray1,
  };

  return (
    <>
      <ActionButtonEdit name={IconName.Edit} {...actionButtonProps} onClick={editHandler} />
      <ActionButtonShare>
        <CopyZone textToCopy={urlToCopy} successfulMessage={successfulMessage}>
          <IconButton name={IconName.Share} {...actionButtonProps} />
        </CopyZone>
      </ActionButtonShare>
    </>
  );
});
