import React from 'react';
import styled from 'styled-components';
import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColoredIcon from 'Icon/components/ColoredIcon';

const Root = styled.div`
  width: 100%;
  height: 56px;
  cursor: pointer;
  margin-top: 16px;
  background: ${Theme.color.white};
  border: 1px dashed ${ColorPalette.gray44};
  box-sizing: border-box;

  :hover {
    background: ${ColorPalette.gray49};
  }
`;

const Text = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size16};
  line-height: 18px;
  letter-spacing: -0.2px;
  color: ${Theme.color.black};
  margin-left: 16px;
`;

interface IProps {
  label: string;
  onClick(): void;
}

const CreateUserBusinessElementSection = (props: IProps) => {
  const {label, onClick} = props;

  return (
    <Root className="d-flex justify-content-center align-items-center" onClick={onClick}>
      <ColoredIcon name={IconName.AddCircleFilled} color={ColorPalette.red7} fill={true} stroke={false} size={20} />
      <Text>{label}</Text>
    </Root>
  );
};

export default CreateUserBusinessElementSection;
