import {Reducer} from 'redux';

import {
  AdminAssociationHorseActions,
  getAdminAssociationHorsesActionTypes,
  IAdminAssociationHorseState,
} from '../types';
import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';

const initialState: IAdminAssociationHorseState['ui'] = {
  pagination: {countPerPage: MIN_COUNT_PER_PAGE, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiAdminAssociationHorseReducer: Reducer<IAdminAssociationHorseState['ui'], AdminAssociationHorseActions> =
  (state = initialState, action: AdminAssociationHorseActions) => {
    switch (action.type) {
      case getAdminAssociationHorsesActionTypes.SUCCESS: {
        return {...state, pagination: action.payload.pagination};
      }
      default: {
        return state;
      }
    }
  };
