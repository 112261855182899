import {IModule} from 'redux-dynamic-modules';

import {genotypesReducer} from './reducer';
import {IGenotypeListState} from './types';

export interface IGenotypeListModuleState {
  genotypes: IGenotypeListState;
}

export const GenotypesModule: IModule<IGenotypeListModuleState> = {
  id: 'genotypeList',
  reducerMap: {
    genotypes: genotypesReducer
  } as any
};
