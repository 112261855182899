import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import CreateOrderDataService from 'Order/services/CreateOrderDataService';
import {ITestSummaryRequest} from 'Order/services/types/summary';
import {ICreateOrderRequest} from 'Order/models/ICreateOrderRequest';
import {ICheckCouponRequest} from 'Order/services/types/coupon';

import {
  createOrderActions,
  createOrderActionTypes,
  getContactInformationActions,
  getContactInformationActionTypes,
  getCouponActions,
  getCouponActionTypes,
  getHorsesActions,
  getHorsesActionTypes,
  getOrderPackagesActionTypes,
  getOrderPackagesActions,
  getSummaryActions,
  getSummaryActionTypes,
  resetCouponActionTypes,
  resetSummaryActionTypes,
  updateContactInformationActions,
  updateContactInformationActionTypes,
} from './types';
import {IContactInformation} from 'Order/models/IContactInformation';

export const getHorses = (): ActionResult<IAppState, Promise<void>, getHorsesActions> => async (dispatch) => {
  dispatch(action(getHorsesActionTypes.REQUEST));
  try {
    const res = await CreateOrderDataService.getHorses();
    dispatch(action(getHorsesActionTypes.SUCCESS, res));
  } catch (error) {
    dispatch(action(getHorsesActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const getSummary =
  (request: ITestSummaryRequest): ActionResult<IAppState, Promise<void>, getSummaryActions> =>
  async (dispatch) => {
    dispatch(action(getSummaryActionTypes.REQUEST));
    try {
      const res = await CreateOrderDataService.getSummary(request);
      dispatch(action(getSummaryActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getSummaryActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getCoupon =
  (request: ICheckCouponRequest): ActionResult<IAppState, Promise<void>, getCouponActions> =>
  async (dispatch) => {
    dispatch(action(getCouponActionTypes.REQUEST));
    try {
      const res = await CreateOrderDataService.getCoupon(request);
      dispatch(action(getCouponActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getCouponActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createOrder =
  (request: ICreateOrderRequest): ActionResult<IAppState, Promise<void>, createOrderActions> =>
  async (dispatch) => {
    dispatch(action(createOrderActionTypes.REQUEST));
    try {
      const res = await CreateOrderDataService.createOrder(request);
      dispatch(action(createOrderActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(createOrderActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getContactInformation =
  (): ActionResult<IAppState, Promise<void>, getContactInformationActions> => async (dispatch) => {
    dispatch(action(getContactInformationActionTypes.REQUEST));
    try {
      const res = await CreateOrderDataService.getContactInformation();
      dispatch(action(getContactInformationActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getContactInformationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrderPackages =
  (): ActionResult<IAppState, Promise<void>, getOrderPackagesActions> => async (dispatch) => {
    dispatch(action(getOrderPackagesActionTypes.REQUEST));
    try {
      const res = await CreateOrderDataService.getOrderPackages();
      dispatch(action(getOrderPackagesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getOrderPackagesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateContactInformation =
  (request: IContactInformation): ActionResult<IAppState, Promise<void>, updateContactInformationActions> =>
  async (dispatch) => {
    dispatch(action(updateContactInformationActionTypes.REQUEST));
    try {
      await CreateOrderDataService.updateContactInformation(request);
      dispatch(action(updateContactInformationActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateContactInformationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetCoupon = () => action(resetCouponActionTypes.RESET);
export const resetSummary = () => action(resetSummaryActionTypes.RESET);
