import {Reducer} from 'redux';

import {AdminAncestryUploadActions, getAncestryUploadDetailsActionTypes, IAdminAncestryUploadState} from '../types';

const initialState: IAdminAncestryUploadState['data'] = {
  ancestryUploadDetails: {uploadDate: ''},
};

export const dataAncestryUploadReducer: Reducer<IAdminAncestryUploadState['data'], AdminAncestryUploadActions> = (
  state = initialState,
  action: AdminAncestryUploadActions
) => {
  switch (action.type) {
    case getAncestryUploadDetailsActionTypes.SUCCESS: {
      return {...state, ancestryUploadDetails: action.payload};
    }

    default: {
      return state;
    }
  }
};
