export enum BreedType {
  CarriageHorse = 'carriageHorse',
  Exmoor = 'exmoor',
  HeavyHorse = 'heavyHorse',
  Iberian = 'iberian',
  NearEast = 'nearEast',
  NorthSea = 'northSea',
  Thoroughbred = 'thoroughbred',
}

export const MAP_BREED_COLOR: Record<BreedType, string> = {
  [BreedType.CarriageHorse]: '#74241a',
  [BreedType.Exmoor]: '#dc8343',
  [BreedType.HeavyHorse]: '#804aba',
  [BreedType.Iberian]: '#d7a440',
  [BreedType.NearEast]: '#d43b2a',
  [BreedType.NorthSea]: '#5aad1f',
  [BreedType.Thoroughbred]: '#0a2974',
};

export const MAP_BREED_NAME: Record<BreedType, string> = {
  [BreedType.CarriageHorse]: 'Carriage Horse',
  [BreedType.Exmoor]: 'Exmoor',
  [BreedType.HeavyHorse]: 'European Heavy Horse',
  [BreedType.Iberian]: 'Iberian',
  [BreedType.NearEast]: 'Near East',
  [BreedType.NorthSea]: 'North Sea',
  [BreedType.Thoroughbred]: 'Thoroughbred',
};

export const MAP_BREED_COUNTRIES: Record<BreedType, string[]> = {
  [BreedType.CarriageHorse]: ['FR', 'US'],
  [BreedType.Exmoor]: ['GB'],
  [BreedType.HeavyHorse]: [
    'FR',
    'GB',
    'DE',
    'BE',
    'NL',
    'LU',
    'AT',
    'CZ',
    'SK',
    'HU',
    'RO',
    'CH',
    'IT',
    'SI',
    'HR',
    'BA',
    'PL',
    'DK',
  ],
  [BreedType.Iberian]: ['ES', 'PT'],
  [BreedType.NearEast]: ['OM', 'AE', 'QA', 'SA', 'IQ', 'IR', 'KW', 'SY', 'JO', 'IL', 'PS', 'LB', 'EG', 'TR', 'YE'],
  [BreedType.NorthSea]: ['LU', 'NO', 'SE', 'FI', 'IS', 'FO', 'GB'],
  [BreedType.Thoroughbred]: ['GB', 'IE'],
};

export const SORT_BREED_NAME: Record<BreedType, number> = {
  [BreedType.HeavyHorse]: 0,
  [BreedType.Thoroughbred]: 1,
  [BreedType.Iberian]: 2,
  [BreedType.CarriageHorse]: 3,
  [BreedType.Exmoor]: 4,
  [BreedType.NorthSea]: 5,
  [BreedType.NearEast]: 6,
};
