import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.userPermissions;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectUserPermissions(state: IAppState) {
  return selectState(state).data.userPermissions;
}
