import {IServerOnlineReportHealthVariants} from 'OnlineReport/services/models/IOnlineReportHealthVariantsServer';
import {IOnlineReportHealthVariants} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariants';
import {withImage} from 'Common/helpers/withImage';

export function convertServerHealthVariantsToClient(
  model: IServerOnlineReportHealthVariants
): IOnlineReportHealthVariants {
  return {
    ...withImage(model),
    healthVariants: model.healthIssues,
  };
}
