import {IServerEmployeeProfileUpdate} from 'BusinessPortal/services/types/employee';
import {IFormValues} from './validation';

export function convertFormValuesToServer(values: IFormValues): IServerEmployeeProfileUpdate {
  return {
    id: values.id,
    address: values.address,
    description: values.description,
    name: values.name,
    phoneNumber: values.phoneNumber,
  };
}
