import {IModule} from 'redux-dynamic-modules';

import {IAdminAssociationHorseState} from './types';
import {adminAssociationHorseReducer} from './reducer';

export interface IAdminAssociationHorseModuleState {
  adminAssociationHorse: IAdminAssociationHorseState;
}

export const AdminAssociationHorseModule: IModule<IAdminAssociationHorseModuleState> = {
  id: 'adminAssociationHorse',
  reducerMap: {
    adminAssociationHorse: adminAssociationHorseReducer,
  },
};
