import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';
import {IListResponse, IPagination} from 'Common/models/IResponse';
import {IUserOrder} from 'UserProfile/models/IUserOrder';

const STATE_NAME = 'userOrders';

export interface IUserOrdersState {
  data: {
    userOrders: IUserOrder[];
  };
  communications: {
    userOrdersLoading: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getUserOrdersActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_USER_ORDERS');

export type getUserOrdersActions = MakeCommunicationActions<
  typeof getUserOrdersActionTypes,
  {success: IListResponse<IUserOrder>}
>;

export type UserOrdersActions = getUserOrdersActions;
