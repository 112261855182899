import React, {memo, useEffect} from 'react';

import {AdminAssociationOrdersActions, IRequestChangeSample} from 'Admin/AdminAssociations/store/adminOrders';
import {
  AdminOrdersActions,
  IRequestChangeSample as IRequestChangeSampleAdmin,
} from 'Admin/AdminDashboard/store/adminOrders/index';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import Loading from 'Loading/components/Loading';
import {InputField} from 'Common/components/FormFields/index';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {Form, FormikProps, withFormik} from 'formik';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {convertSampleToChangeRequestSample, validationSchema} from './validation';
import {changeSampleFormDistributor, IDispatch, IState} from './distributor';
import {IEntity} from 'Common/models/IEntity';
import {IAppState} from 'Common/store/IAppState';
import {ThunkDispatch} from 'redux-thunk';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';

export interface ISampleAdmin extends IEntity {
  sampleId: string;
  receivedDate: string;
}

type RequestChangeSample = IRequestChangeSample | IRequestChangeSampleAdmin;

interface IExternalProps {
  sample: ISampleAdmin;
  onSuccess(): void;
  adminVisitorType: SelectedStrategy;
}

type IConnected = IState & IDispatch<RequestChangeSample>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<RequestChangeSample> & OuterProps;

const ChangeSampleForm = (props: AllProps) => {
  const {status, setStatus, isValid, setErrors, isSubmitting} = props;
  const {changeSampleRequesting, resetChangeSampleCommunication, onSuccess} = props;

  const errorInfo = changeSampleRequesting.error;
  const isRequesting = changeSampleRequesting.isRequesting;

  useOnSuccessCommunication(changeSampleRequesting, onSuccess);

  useEffect(() => {
    return () => resetChangeSampleCommunication();
  }, [resetChangeSampleCommunication]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    const fieldErrors = getFieldErrors(errorInfo);

    if (commonErrors) {
      setStatus(commonErrors);
    }

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  return (
    <>
      <ModalWindowHeader>Edit sample</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        {isRequesting && <Loading />}
        <ModalWindowFormContent>
          <InputField name="sampleId" label="Sample ID" placeholder="Sample ID" autoComplete="off" />
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" disabled={!isValid} isLoading={isSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const ChangeSampleFormWithFormik = withFormik<OuterProps, RequestChangeSample>({
  mapPropsToValues: ({sample}) => convertSampleToChangeRequestSample(sample),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    await formikBag.props.changeSample(values);
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(ChangeSampleForm);

const mapStateToProps = (state: IAppState, externalProps: IExternalProps) => {
  const {adminVisitorType} = externalProps;

  const distributor = changeSampleFormDistributor[adminVisitorType];
  return {...distributor?.state(state, externalProps)};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, undefined, AdminOrdersActions | AdminAssociationOrdersActions>,
  externalProps: IExternalProps
) => {
  const {adminVisitorType} = externalProps;

  const distributor = changeSampleFormDistributor[adminVisitorType];
  const dispatchToProps = {...distributor.dispatch};

  return bindActionCreators(dispatchToProps, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(ChangeSampleFormWithFormik));
export default Connected;
