import React from 'react';
import {DynamicModuleLoader} from 'redux-dynamic-modules-react';
import {IModuleTuple, IModule} from 'redux-dynamic-modules';

function withDynamicModules<T>(Component: React.ComponentType<T>, modules: IModule<any> | IModuleTuple) {
  const modulesArray = Array.isArray(modules) ? modules : [modules];

  return function(externalProps: T) {
    return (
      <DynamicModuleLoader modules={modulesArray}>
        <Component {...externalProps} />
      </DynamicModuleLoader>
    );
  };
}

export default withDynamicModules;
