import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import OrderSuccessLayout from 'Shared/components/OrderSuccess/common/OrderSuccessLayout';
import OrderSuccessButtons from 'Shared/components/OrderSuccess/common/OrderSuccessButtons';
import {Description, Title} from 'Shared/components/OrderSuccess/common/styled';

const Link = styled.a.attrs({target: '_blank', rel: 'noopener noreferrer'})`
  color: ${ColorPalette.blue0};
`;

function OrderSuccessPaid() {
  return (
    <OrderSuccessLayout>
      <Title>Order successfully paid</Title>
      <Description>
        <div>
          Ok, time to send in your horse hair sample! Using the sample submission form, please send your pulled hair
          sample in as soon as possible and we’ll get this testing started!
        </div>
        <div>
          Need to see how to do it? Here’s a <Link href="https://www.youtube.com/watch?v=EAqhb8zfk6k">video</Link> to
          help
        </div>
      </Description>

      <OrderSuccessButtons isPayment />
    </OrderSuccessLayout>
  );
}

export default memo(OrderSuccessPaid);
