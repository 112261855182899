import React, {memo, useCallback} from 'react';
import styled from 'styled-components';

import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';
import {IFilterProperty} from 'FacetFilter/models/IFilterProperty';
import {IFilterPropertyOperations} from 'FacetFilter/models/IFilterPropertyOperator';
import {IFilterRequest} from 'FacetFilter/models/IFilterRequest';
import {IconName} from 'Icon/components/Icon';
import {Title} from './parts/styled';
import {FilterType} from 'FacetFilter/constants/FilterType';
import {BooleanFacet, EnumFacet, StringFacet} from './facetsByType/index';
import {DictionaryToProperty, EnumToProperty} from 'FacetFilter/models/types';
import ItemListFacet from './facetsByType/ItemListFacet';

const Root = styled.div`
  min-width: 60px;
  height: 36px;
  border: 1px solid ${ColorPalette.gray26};
  border-radius: 2px;
  background: ${ColorPalette.gray40};
  padding: 0 8px;
  gap: 8px;
  cursor: pointer;

  :hover {
    background: ${ColorPalette.transparent3};
  }
`;

export interface IFacetProps {
  filterProperty: IFilterProperty;
  filterPropertyOperations?: IFilterPropertyOperations;
  filterRequest?: IFilterRequest;
  mapEnumToProperty?: EnumToProperty;
  mapDictionaryToProperty?: DictionaryToProperty;
  onSuccess(filter: IFilterRequest, isExecute?: boolean): void;
}

interface IProps extends IFacetProps {
  onRemove(property: string): void;
}

function Facet(props: IProps) {
  const {
    filterProperty,
    filterRequest,
    onRemove,
    onSuccess,
    filterPropertyOperations,
    mapEnumToProperty,
    mapDictionaryToProperty,
  } = props;

  const facetProps = {filterProperty, filterPropertyOperations, filterRequest, onSuccess};

  const onRemoveItem = useCallback(() => {
    onRemove(filterProperty.property);
  }, [filterProperty.property, onRemove]);

  const isUnknownFilterType = !Object.values(FilterType).includes(filterProperty.type);

  return (
    <Root className="d-flex align-items-center justify-content-between">
      {filterProperty.type === FilterType.Boolean && <BooleanFacet {...facetProps} />}
      {filterProperty.type === FilterType.Enumeration && mapEnumToProperty && (
        <EnumFacet {...facetProps} enumeration={mapEnumToProperty[filterProperty.property]} />
      )}
      {filterProperty.type === FilterType.Integer && <StringFacet {...facetProps} inputType="number" />}
      {filterProperty.type === FilterType.ItemList && mapDictionaryToProperty && (
        <ItemListFacet {...facetProps} dictionary={mapDictionaryToProperty[filterProperty.property]} />
      )}
      {filterProperty.type === FilterType.String && <StringFacet {...facetProps} />}

      {isUnknownFilterType && (
        <>
          <Title>{filterProperty.title}</Title>
        </>
      )}

      <IconButton
        name={IconName.Close}
        onClick={onRemoveItem}
        color={ColorPalette.gray36}
        fill={true}
        stroke={false}
        size={12}
      />
    </Root>
  );
}

export default memo(Facet);
