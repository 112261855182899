import {combineReducers, Reducer} from 'redux';

import {IAdminAncestryReportState} from '../types';
import {communicationReducer} from './communications';
import {dataReducer} from './data';

export const adminAncestryReportReducer: Reducer<IAdminAncestryReportState> =
  combineReducers<IAdminAncestryReportState>({
    communications: communicationReducer,
    data: dataReducer,
  });
