import React, {memo, useCallback} from 'react';

import withField from './withField';
import ValidationError from './ValidationError';
import Switch, {SwitchProps} from 'Common/components/Controls/Switch';
import {IFieldProps} from 'Common/models/IFieldProps';

interface IProps {
  onClick?(fieldName: string, value: boolean): void;
}

type Props = IProps & Omit<SwitchProps, 'onClick' | 'onChange'> & IFieldProps<boolean>;

function SwitchField(props: Props) {
  const {
    field: {name, value},
    form,
    onClick,
    ...other
  } = props;

  const onChange = useCallback(
    (value: boolean) => {
      form.setFieldValue(name, value);
      onClick && onClick(name, value);
    },
    [form, name, onClick]
  );

  return (
    <div className="d-flex flex-row position-relative">
      <Switch checked={value} onChange={onChange} {...other} />
      <ValidationError name={name} />
    </div>
  );
}

export default memo(withField(SwitchField));
