import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {
  createErrorDescriptionActions,
  createErrorDescriptionActionTypes,
  deleteErrorDescriptionActions,
  deleteErrorDescriptionActionTypes,
  getErrorDescriptionActions,
  getErrorDescriptionActionTypes,
  getErrorDescriptionsActions,
  getErrorDescriptionsActionTypes,
  resetErrorDescriptionActions,
  resetErrorDescriptionActionTypes,
  updateErrorDescriptionActions,
  updateErrorDescriptionActionTypes,
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IErrorDescription} from 'Admin/AdminSettings/models/IErrorDescription';
import ErrorDescriptionService from '../../services/ErrorDescriptionService';

export const getErrorDescriptions =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getErrorDescriptionsActions> =>
  async (dispatch) => {
    dispatch(action(getErrorDescriptionsActionTypes.REQUEST));
    try {
      const res = await ErrorDescriptionService.getErrorDescriptions(params);
      dispatch(action(getErrorDescriptionsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getErrorDescriptionsActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };

export const getErrorDescription =
  (id: number): ActionResult<IAppState, Promise<void>, getErrorDescriptionActions> =>
  async (dispatch) => {
    dispatch(action(getErrorDescriptionActionTypes.REQUEST));
    try {
      const res = await ErrorDescriptionService.getErrorDescription(id);
      dispatch(action(getErrorDescriptionActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getErrorDescriptionActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createErrorDescription =
  (data: IErrorDescription): ActionResult<IAppState, void, createErrorDescriptionActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createErrorDescriptionActionTypes.REQUEST));
      await ErrorDescriptionService.createErrorDescription(data);
      dispatch(action(createErrorDescriptionActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createErrorDescriptionActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateErrorDescription =
  (data: IErrorDescription): ActionResult<IAppState, void, updateErrorDescriptionActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateErrorDescriptionActionTypes.REQUEST));
      await ErrorDescriptionService.updateErrorDescription(data);
      dispatch(action(updateErrorDescriptionActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateErrorDescriptionActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteErrorDescription =
  (id: number): ActionResult<IAppState, void, deleteErrorDescriptionActions> =>
  async (dispatch) => {
    dispatch(action(deleteErrorDescriptionActionTypes.REQUEST));
    try {
      await ErrorDescriptionService.deleteErrorDescription(id);
      dispatch(action(deleteErrorDescriptionActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteErrorDescriptionActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetErrorDescriptionResults =
  (): ActionResult<IAppState, void, resetErrorDescriptionActions> => async (dispatch) => {
    dispatch(action(resetErrorDescriptionActionTypes.RESET));
  };
