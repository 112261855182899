import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import {convertPriceToClient} from 'Common/helpers/convertPrices';
import {DiscountType} from 'Coupon/constants/DiscountType';

export function convertCouponToClient(coupon: IOrderCoupon): IOrderCoupon {
  return {
    ...coupon,
    amount: coupon.type === DiscountType.Percentage ? coupon.amount : convertPriceToClient(coupon.amount),
  };
}
