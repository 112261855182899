import * as Yup from 'yup';

import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IEmployeeProfile} from 'BusinessPortal/models/employee/IEmployeeProfile';

export interface IFormValues
  extends Pick<IEmployeeProfile, 'id' | 'name' | 'address' | 'phoneNumber' | 'description'> {}

export const initialValue: IFormValues = {
  id: 0,
  name: '',
  address: '',
  phoneNumber: '',
  description: '',
};

export const validationSchema = Yup.object().shape<IFormValues>({
  id: Yup.number().required(),
  name: Yup.string().required(REQUIRED_FIELD),
  address: Yup.string(),
  phoneNumber: Yup.string(),
  description: Yup.string(),
});
