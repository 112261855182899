import React, {memo} from 'react';
import styled from 'styled-components';

import Loading from 'Loading/components/Loading';
import EditableSection from 'BusinessPortal/components/common/EditableSection/EditableSection';
import ChangeEmailShow from './ChangeEmailShow';
import ChangeEmailEdit from './ChangeEmailEdit';
import {ICommunication} from 'Common/store/utils/communication';

const Root = styled.div`
  max-width: 576px;
  margin-bottom: 46px;
`;

interface IProps {
  email?: string;
  employeeProfileLoading: ICommunication;
  onSuccess(): void;
}

const ChangeEmail = (props: IProps) => {
  const {email, onSuccess, employeeProfileLoading} = props;

  const isRequesting = employeeProfileLoading.isRequesting;

  return (
    <Root className="position-relative">
      {isRequesting && <Loading />}
      <EditableSection
        title="Email"
        data={{email}}
        onSuccess={onSuccess}
        editComponent={ChangeEmailEdit}
        showComponent={ChangeEmailShow}
        isReadOnlyMode={true}
      />
    </Root>
  );
};

export default memo(ChangeEmail);
