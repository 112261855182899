import {IModule} from 'redux-dynamic-modules';
import {IAdminTestResultsState} from 'Admin/AdminDashboard/store/adminTestResults/types';
import {testResultsReducer} from 'Admin/AdminDashboard/store/adminTestResults/reducer/index';

export interface IAdminTestResultsModuleState {
  adminTestResults: IAdminTestResultsState;
}

export const AdminTestResultsModule: IModule<IAdminTestResultsModuleState> = {
  id: 'adminTestResults',
  reducerMap: {
    adminTestResults: testResultsReducer
  }
};
