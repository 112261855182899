import React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminHorseTransfer';
import {AdminHorseTransferModule} from 'Admin/AdminDashboard/store/adminHorseTransfer/module';

type IConnected = ConnectedProps<typeof connector>;

export type IWithHorseTransferActionsProps = IConnected;

function withHorseTransferActions<T>(Component: React.ComponentType<T>) {
  const wrapped = (props: any) => <Component {...props} />;
  return connector(wrapped);
}

const mapStateToProps = (state: IAppState) => ({
  horseOwnerChanging: selectors.selectCommunication(state, 'horseOwnerChanging'),
  horseTransferRejecting: selectors.selectCommunication(state, 'horseTransferRejecting'),
  horseTransferDetails: selectors.selectHorseTransferDetails(state),
  horseTransferDetailsLoading: selectors.selectCommunication(state, 'horseTransferDetailsLoading'),
});

const mapDispatchToProps = {
  rejectHorseTransfer: actions.rejectHorseTransfer,
  getHorseTransferDetails: actions.getHorseTransferDetails,
  changeHorseOwner: actions.changeHorseOwner,
  resetHorseTransferDetails: actions.resetHorseTransferDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

function getWithHorseTransferActionsModules() {
  return [AdminHorseTransferModule];
}

export {getWithHorseTransferActionsModules, withHorseTransferActions as default};
