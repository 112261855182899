import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {breakpoints} from 'Common/constants/Breakpoints';

export const SettingsTitle = styled.div`
  width: calc(100% - 48px);
  margin-bottom: 32px;
  color: ${ColorPalette.black0};
  font-family: ${Typography.family.openSans};
  font-size: ${Typography.size.size24};
  line-height: 33px;
`;

export const FormLayout = styled.div`
  max-width: 500px;
  width: 100%;
`;
export const Button = styled(PrimaryButton)`
  width: 100%;
  height: 48px;
  margin-bottom: 20px;

  @media ${breakpoints.sm} {
    width: 170px;
  }
`;
