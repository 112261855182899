import * as R from 'ramda';
import {useRef} from 'react';

export function useMemoizePrevious<T>(value: T, initial: T): T {
  const ref = useRef<T>();

  if (!R.equals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current || initial;
}
