import {ActionResult} from 'Common/store/store';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {ICommunicationAction, ICommunicationResetAction} from './types/shared';

export function createActionThunk<P, R>(apiMethod: (args: P) => Promise<R>, actions: ICommunicationAction<R>) {
  return (args: P): ActionResult<{}, void> => async dispatch => {
    try {
      dispatch(actions.request());
      const response = await apiMethod(args);
      dispatch(actions.success(response));
    } catch (error) {
      dispatch(actions.failure(getErrorMessage(error)));
    }
  };
}

export function createResetAction(action: ICommunicationResetAction) {
  return (): ActionResult<{}, void> => dispatch => {
    dispatch(action.reset());
  };
}
