import React, {memo, useMemo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';
import styled from 'styled-components';
import {CSSObjectWithLabel} from 'react-select/dist/declarations/src';

import {CheckboxField, InputField, SelectField, TextAreaField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {IFormValues, initialValue, validationSchema} from './validation';
import {Nullable, RequiredBy} from 'Common/types';
import {IAdminAffectionType} from 'Admin/AdminPhenotypes/models/IAdminAffectionType';
import {AffectionTypeColors} from 'Common/constants/AffectionTypeColors';
import {SelectStyles} from 'Common/components/Controls/Select/SelectStyles';
import ColorPalette from 'Common/constants/ColorPalette';
import {Hint} from 'Common/components/StyledComponents/StyledComponents';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

const coloredDot = (color: Nullable<string> = '') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color || undefined,
    borderRadius: 10,
    content: '" "',
    marginRight: 12,
    height: 16,
    width: 16,
  },
});

const CheckboxWrapper = styled.div`
  padding: 8px 0;
`;

const HintWrapper = styled(Hint)`
  margin-top: 8px;
`;

type IOwnProps = IPhenotypeFormExternalProps<IAdminAffectionType, IAdminAffectionType>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function AffectionTypeForm(props: Props) {
  const {itemId, item, dictionary, ...rest} = props;
  const {getItem} = dictionary.actions;

  useEffect(() => {
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem]);

  const affectionTypeColorStyles = useMemo(
    (): SelectStyles => ({
      input: () => ({
        ...coloredDot(props.values.color),
      }),
      placeholder: () => ({
        ...coloredDot(ColorPalette.gray46),
      }),
      singleValue: () => ({
        marginLeft: 30,
      }),
      option: (base: CSSObjectWithLabel, state: any) => ({
        ...coloredDot(state.data.value),
      }),
    }),
    [props.values.color]
  );

  return (
    <DictionaryForm {...rest}>
      <InputField name="name" label="Name" placeholder="Name" />
      <SelectField
        name="color"
        label="Color"
        options={AffectionTypeColors}
        isClearable={true}
        styles={affectionTypeColorStyles}
      />
      <CheckboxWrapper>
        <CheckboxField name="hasAffection" label="Has affect" />
        <HintWrapper>
          Enabling this option will define this affect type as one which has affect on horse and make connected health
          variant rules usable in build.
        </HintWrapper>
      </CheckboxWrapper>
      <TextAreaField name="description" label="Description" placeholder="Description" />
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  return makePhenotypeFormSelectors(state, ownProps.dictionary);
};

const AffectionTypeFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting ? item : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(values);
    }

    if (type === FormType.edit) {
      await updateItem(values as RequiredBy<IFormValues, 'id'>);
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(AffectionTypeForm);

const connector = connect(mapStateToProps);
export default connector(memo(AffectionTypeFormFormik));
