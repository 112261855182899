import {memo, useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import {IOrderHorseDetailsAdmin} from 'Admin/AdminDashboard/models/Order/IOrderHorseDetailsAdmin';
import {IOrderHorseStatuses} from 'Admin/AdminDashboard/models/Order/IOrderHorseStatuses';
import {DropDownMenu, IMenuItem, IOrderedMenuItem} from 'Common/components/DropDown';
import ColorPalette from 'Common/constants/ColorPalette';
import {EventName} from 'Common/constants/EventName';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';

enum OrderMenuItemValue {
  OpenLiveReport = 'openLiveReport',
  ReviewLiveReport = 'reviewLiveReport',
}

const menuItems: Record<OrderMenuItemValue, Omit<IOrderedMenuItem, 'value'>> = {
  [OrderMenuItemValue.OpenLiveReport]: {
    label: 'Open live report',
    icon: {name: IconName.Notebook, color: ColorPalette.red7, fill: false, stroke: true},
    id: 2,
  },
  [OrderMenuItemValue.ReviewLiveReport]: {
    label: 'Review live report',
    icon: {name: IconName.InsertComment, color: ColorPalette.red7},
    id: 2,
  },
};

const MoreIcon = styled(ColoredIcon)`
  cursor: pointer;
`;

interface IProps {
  horse: IOrderHorseDetailsAdmin;
  orderId: number;
}

function OrderMenuItem(props: IProps) {
  const {horse, orderId} = props;

  const [statuses, setStatuses] = useState<IOrderHorseStatuses>();

  const history = useHistory();
  const {listen} = useEventBus();

  const onSetStatuses = useCallback(
    (data: IOrderHorseStatuses) => {
      if (data.orderId === orderId && data.horseId === horse.id) {
        setStatuses(data);
      }
    },
    [horse.id, orderId]
  );

  useEffect(() => {
    const eventOrderStatusChanged = listen(EventName.OrderStatusChanged, onSetStatuses);

    return () => {
      eventOrderStatusChanged.stopListening();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openLiveReport = useCallback(
    (horseId: number, orderId?: number) => {
      if (orderId) {
        history.push(`/review-admin/online-report/${horseId}/${orderId}`);
      } else {
        history.push(`/admin/online-report/${horseId}/0`);
      }
    },
    [history]
  );

  const actionsHandler: Record<OrderMenuItemValue, (horseId: number, orderId?: number) => void> = {
    [OrderMenuItemValue.OpenLiveReport]: (horseId: number) => openLiveReport(horseId),
    [OrderMenuItemValue.ReviewLiveReport]: (horseId: number, orderId?: number) => openLiveReport(horseId, orderId),
  };

  const renderMenuItems: (horse: IOrderHorseDetailsAdmin) => IMenuItem[] = useCallback(
    (horse: IOrderHorseDetailsAdmin) => {
      const hasSample = !!statuses?.statuses.hasSample;
      const hasRelease = !!statuses?.statuses.hasRelease;

      const mapAvailableByMenuItem: Record<OrderMenuItemValue, boolean> = {
        [OrderMenuItemValue.OpenLiveReport]: hasRelease,
        [OrderMenuItemValue.ReviewLiveReport]: hasSample && !hasRelease,
      };

      return Object.entries(menuItems)
        .map(([key, value]) => ({...value, value: key, availabilityKey: mapAvailableByMenuItem[key]}))
        .filter((item) => item.availabilityKey);
    },
    [statuses]
  );

  const filteredMenuItems = renderMenuItems(horse);

  if (filteredMenuItems.length > 0) {
    return (
      <DropDownMenu
        items={filteredMenuItems}
        onSelect={(type) => actionsHandler[type!](horse.id, orderId)}
        alignRight={true}
      >
        {({isOpen}) => (
          <MoreIcon
            name={IconName.More}
            color={isOpen ? ColorPalette.black1 : ''}
            hoverColor={ColorPalette.black1}
            fill={true}
            stroke={false}
          />
        )}
      </DropDownMenu>
    );
  }

  return null;
}

export default memo(OrderMenuItem);
