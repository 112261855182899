import React, {memo, ReactNode, CSSProperties, useCallback} from 'react';
import {Helmet} from 'react-helmet';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Icon, {IconName} from 'Icon/components/Icon';
import Theme from 'Common/constants/Theme';
import ColoredIcon from 'Icon/components/ColoredIcon';
import ColoredNavLink from 'Common/components/Controls/Links/ColoredNavLink';
import {ReactComponent as EtalonTitle} from 'BusinessPortal/img/etalon-title.svg';
import AuthService from 'Auth/services/AuthService';
import {breakpoints} from 'Common/constants/Breakpoints';
import {selectors as currentPermissionsSelectors} from 'Permissions/store/index';
import {IAppState} from 'Common/store/IAppState';
import {INavigation} from 'Common/models/INavigation';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {getBusinessPortalHeadersNavMenu} from 'Common/components/Header/common';

const menuLinkActiveStyle: CSSProperties = {color: Theme.color.white, borderRight: `2px solid ${Theme.color.white}`};
const backButtonStyle: CSSProperties = {paddingLeft: 247, paddingTop: 48};

const MenuLayout = styled.aside`
  width: 223px;
  height: 100%;
  background-color: ${Theme.color.black};

  position: fixed;
  top: 0;
  left: 0;
  padding: 32px 0;
  height: 100%;
`;

const ContentLayout = styled.div<{backgroundColor?: string; withoutPaddings?: boolean}>`
  background-color: ${({backgroundColor}) => backgroundColor || ColorPalette.gray49};

  ${(props) => !props.withoutPaddings && ' padding: 8px;'}
  ${(props) => !props.withoutPaddings && 'padding-top: 24px;'}

  @media ${breakpoints.sm} {
    padding-left: 223px;
    ${(props) => !props.withoutPaddings && 'padding: 16px 16px 0 239px;'}
  }

  @media ${breakpoints.md} {
    ${(props) => !props.withoutPaddings && 'padding: 24px 24px 0 247px;'}
  }
`;

const LogoWrapper = styled.a`
  padding: 0 24px;
  margin-bottom: 104px;
  align-items: center;
`;

const LogoIcon = styled(ColoredIcon)`
  width: 58px;
  height: 30px;
`;

const LogoName = styled(EtalonTitle)`
  margin-left: 14px;
  color: ${Theme.color.white};
`;

const MenuLink = styled(ColoredNavLink)`
  display: flex;
  align-items: center;
  margin: 12px 0 12px 24px;
  height: 32px;

  font-family: ${Typography.family.ubuntu};
  font-size: ${Typography.size.size14};
  line-height: 18px;
  letter-spacing: 0.2px;
  color: ${ColorPalette.gray56};
  opacity: 1;
  text-decoration: none;

  &:hover {
    color: ${Theme.color.white};
    text-decoration: none;
  }
`;

const MenuLinkText = styled.span`
  margin-left: 2px;
`;

const IconCell = styled.div`
  width: 24px;
  height: 24px;
`;

interface IProps {
  backgroundColor?: string;
  withoutPaddings?: boolean;
  header?: React.ReactElement;
  isBackButtonDenied?: boolean;
}

type IConnected = ConnectedProps<typeof connector>;

type BusinessPortalLayoutProps = {children: ReactNode};

type AllProps = IConnected & IProps & BusinessPortalLayoutProps;

const BusinessPortalLayout = (props: AllProps) => {
  const {children, backgroundColor, withoutPaddings, currentUserPermissions, header, isBackButtonDenied = true} = props;

  const businessPortalRoutes = getBusinessPortalHeadersNavMenu(currentUserPermissions);

  const routes = businessPortalRoutes.filter((x) => x.order);
  const bottomRoutes = businessPortalRoutes.filter((x) => x.orderBottom);

  const renderRoutes = useCallback((routes: INavigation[]) => {
    return routes.map((route) => (
      <MenuLink key={route.value} to={route.value} activeStyle={menuLinkActiveStyle}>
        {route.icon && (
          <IconCell className="d-flex justify-content-start align-items-center">
            <Icon {...route.icon} />
          </IconCell>
        )}
        <MenuLinkText>{route.label}</MenuLinkText>
      </MenuLink>
    ));
  }, []);

  const {isMobile} = useMediaQuery();

  return (
    <>
      <Helmet>
        <title>Business Portal</title>
      </Helmet>
      <BaseLayout
        withoutPaddings={true}
        hideHeader={!isMobile}
        hideFooter={true}
        isBackButtonDenied={isBackButtonDenied}
        header={header}
        backButtonStyle={backButtonStyle}
      >
        <div className="d-flex flex-grow-1">
          {!isMobile && (
            <MenuLayout className="d-flex flex-column">
              <LogoWrapper href="http://www.etalondx.com/" className="d-flex">
                <LogoIcon name={IconName.EtalonHorseLogo} color={Theme.color.white} fill={true} />
                <LogoName />
              </LogoWrapper>
              <div className="d-flex flex-column flex-grow-1">
                {renderRoutes(routes)}

                <div className="mt-auto">
                  {renderRoutes(bottomRoutes)}
                  <MenuLink to={'/logout'} onClick={() => AuthService.logout(true)}>
                    <IconCell className="d-flex justify-content-start align-items-center">
                      <Icon name={IconName.Logout} size={16} />
                    </IconCell>
                    <MenuLinkText>Sign out</MenuLinkText>
                  </MenuLink>
                </div>
              </div>
            </MenuLayout>
          )}

          <ContentLayout
            className="d-flex flex-column w-100 h-100"
            backgroundColor={backgroundColor}
            withoutPaddings={withoutPaddings}
          >
            {children}
          </ContentLayout>
        </div>
      </BaseLayout>
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  currentUserPermissions: currentPermissionsSelectors.selectCurrentPermissions(state),
});

const connector = connect(mapStateToProps);

export default connector(memo(BusinessPortalLayout));
