import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {LabelBase} from 'UserProfile/components/TabsData/common/styled';
import {AbilityType} from 'Common/constants/AbilityType';
import DnaIndicators from './common/DnaIndicators';
import {parseToIndicatorParameters} from 'Common/helpers/parseToIndicatorParameters';
import {IShortAbility} from 'Dictionaries/models/IAbilities';

const DnaValues = styled.div`
  margin-left: 8px;
  margin-top: -5px;
`;

interface IProps {
  temperament?: IShortAbility;
  gait?: IShortAbility;
  speed?: IShortAbility;
}

const DnaContent = (props: IProps) => {
  const {temperament, gait, speed} = props;

  const dnaTests = parseToIndicatorParameters(
    [
      {
        type: AbilityType.Temperament,
        state: temperament?.state || null,
        name: temperament?.name,
        description: temperament?.description,
      },
      {
        type: AbilityType.Gait,
        state: gait?.state || null,
        name: gait?.name,
        description: gait?.description,
      },
      {
        type: AbilityType.Speed,
        state: speed?.state || null,
        name: speed?.name,
        description: speed?.description,
      },
    ],
    [ColorPalette.red1, ColorPalette.yellow1, ColorPalette.green1]
  );

  return (
    <div className="d-flex flex-row w-100">
      <div>
        <LabelBase disabled={!temperament}>Temperament</LabelBase>
        <LabelBase disabled={!gait}>DMRT3</LabelBase>
        <LabelBase disabled={!speed}>Speed</LabelBase>
      </div>
      <DnaValues className="w-100">
        <DnaIndicators tests={dnaTests} />
      </DnaValues>
    </div>
  );
};

export default memo(DnaContent);
