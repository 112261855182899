const TOUR_STEPS = [
  {
    target: '#parent-header',
    content: `These are the parent horses.`,
    placement: 'bottom' as const,
    placementBeacon: 'bottom' as const,
  },
  {
    target: '#parent-header > div:first-child',
    content: `On the top left you'll find the stallion's information..`,
    placement: 'bottom' as const,
    disableBeacon: true,
  },
  {
    target: '#parent-header > div:nth-child(2)',
    content: `..and on the top right the mare's information.`,
    placement: 'bottom' as const,
    disableBeacon: true,
  },
  {
    target: '#parent-header > div:first-child > div:nth-child(2)',
    content: (
      <div>
        <p>
          These are the genetic attributes related to ability (<strong>Speed</strong>, <strong>Temperament</strong> and{' '}
          <strong>Gait</strong>).
        </p>
        <p style={{color: 'darkgrey'}}>(Hover your mouse over the text for more info).</p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '400px'}},
  },
  {
    target: '#genotypes > div:first-child > div:first-child',
    content: (
      <div>
        <p>These are the genetic attributes related to color.</p>
        <p style={{color: 'darkgrey'}}>(Hover your mouse over the text for more info).</p>
      </div>
    ),
    placement: 'bottom' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '400px'}},
  },
  {
    target: '#genotypes > div:first-child > div:nth-child(2)',
    content: (
      <div>
        <p>
          Here, any potential health issues will be highlighted for you. We'll go into more detail when analyzing the
          foal.
        </p>
        <p style={{color: 'darkgrey'}}>(Hover your mouse over the text for more info).</p>
      </div>
    ),
    placement: 'bottom' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '480px'}},
  },
  {
    target: '#genotypes > div:first-child > div:nth-child(3)',
    content: (
      <div>
        <p>The Etalon verified badge indicates that this sample was tested in the Etalon lab.</p>
        <p style={{color: 'darkgrey'}}>(Hover your mouse over the text for more info).</p>
      </div>
    ),
    placement: 'bottom' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '440px'}},
  },
  {
    target: '#foal-profile',
    content: `This section is all about the foal.`,
    placement: 'left-start' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '310px'}},
  },
  {
    target: '#foal-profile > div:first-child',
    content: (
      <div>
        <p>This is your foal simulation.</p>
        <p>
          Interacting with <strong>Color</strong> and <strong>Modifiers</strong> will allow you to see the potential
          variations of your dream foal!
        </p>
      </div>
    ),
    placement: 'bottom' as const,
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '#foal-profile > div:nth-child(2)',
    content: (
      <div>
        <p>Your foal's potential coat colors appear here along with their probabilities.</p>
        <p>(You can toggle different colors to interact with the foal simulation).</p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '#foal-profile > div:nth-child(3)',
    content: (
      <div>
        <p>Your foal's potential modifiers appear here along with their probabilities.</p>
        <p>(You can toggle different modifiers to interact with the foal simulation).</p>
      </div>
    ),
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '#foal-profile > div:nth-child(4)',
    content: `These are your foals genetic attributes related to abilities and their respective probabilities of inheritance.`,
    placement: 'left' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '360px'}},
  },
  {
    target: '#foal-profile > div:nth-child(5)',
    content: (
      <p>
        Any potential health issues or conflicts (such as <strong>lethal recessives</strong> or{' '}
        <strong>carrier status</strong>) inheritable by your foal will be flagged here.
      </p>
    ),
    placement: 'left' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '360px'}},
  },
  {
    //Feature removed?? -> You will have an option to request that the horse of interest be tested once you view the "Show Possible Calculation" window.
    target: '#foal-profile > div:nth-child(5)',
    title: 'Topic of Interest',
    content: (
      <div>
        <p>
          If you followed the Build-a-Horse tour, the mare is a GBED (Glycogen Branching Enzyme Deficiency) carrier.
        </p>
        <p>
          Which is important to know if you intend to breed your foal, so as to not breed with another carrier of GBED.
        </p>
        <p>
          However, if you do not intend to breed your foal, being a carrier of GBED has no known effects on the horse.
        </p>
      </div>
    ),
    placement: 'left' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '480px'}},
  },
  {
    target: '#foal-profile > div:nth-child(6)',
    content: `Lastly, down here we indicate if either parent horse has incomplete or unknown genetic test results.`,
    placement: 'top' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {tooltip: {width: '420px'}},
  },
  {
    target: 'body',
    content: (
      <div>
        <p>This concludes our tour.</p>
        <p>(To restart this tour simply refresh the page).</p>
      </div>
    ),
    disableBeacon: true,
    disableOverlay: true,
    styles: {tooltip: {width: '500px'}},
  },
];

export default TOUR_STEPS;
