import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IAdminOrganization} from '../models/IAdminOrganization';
import {ICreateAdminOrganization, IServerOrganization, IUpdateAdminOrganization} from './types/organizations';
import {convertOrganizationStatusToServer, convertOrganizationToClient} from './converters/organization';
import {IFoundOrganization} from '../models/IFoundOrganization';
import {IImage} from 'Image/models/IImage';
import {withImageBaseUrl} from 'Common/helpers/withImageBaseUrl';

const getAdminOrganizations = async (params?: IRequestParams): Promise<IListResponse<IAdminOrganization>> => {
  const response = await axiosWrapper.get<IListResponse<IServerOrganization>>(`/OrganizationAdmin`, {params});
  return {...response.data, data: response.data.data.map(convertOrganizationToClient)};
};

const getAdminOrganization = async (id: number): Promise<IAdminOrganization> => {
  const response = await axiosWrapper.get<IObjectResponse<IAdminOrganization>>(`/OrganizationDetailsAdmin/${id}`);
  return response.data.data;
};

const createAdminOrganization = async (data: ICreateAdminOrganization): Promise<number> => {
  const response = await axiosWrapper.post<IObjectResponse<number>>(`/OrganizationCreateAdmin`, data);
  return response.data.data;
};

const updateAdminOrganization = async (data: IUpdateAdminOrganization): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(`/OrganizationUpdateAdmin`, data);
};

const changeAdminOrganizationStatus = async (id: number, isActive: boolean): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/OrganizationStatusUpdateAdmin`, {
    id,
    status: convertOrganizationStatusToServer(isActive),
  });
};

const searchOrganization = async (filter: string): Promise<IFoundOrganization[]> => {
  const response = await axiosWrapper.get<IObjectResponse<IFoundOrganization[]>>(
    `/OrganizationByNameOrAbbreviationAdmin`,
    {
      params: {filter},
    }
  );
  return response.data.data;
};

const getAdminOrganizationAvatar = async (organizationId: number): Promise<IImage> => {
  const response = await axiosWrapper.get<IObjectResponse<IImage>>(
    `/AssociationOrganizationAvatarAdmin/${organizationId}`
  );
  return withImageBaseUrl(response.data.data);
};

const updateAdminOrganizationAvatar = async (organizationId: number, uploadedFile: File): Promise<void> => {
  const formData = new FormData();
  formData.append('file', uploadedFile);
  formData.append('organizationId', `${organizationId}`);

  await axiosWrapper.post<IObjectResponse<null>>('/AssociationOrganizationAvatarUpdateAdmin/', formData);
};

export default {
  getAdminOrganizations,
  getAdminOrganization,
  createAdminOrganization,
  updateAdminOrganization,
  changeAdminOrganizationStatus,
  searchOrganization,
  getAdminOrganizationAvatar,
  updateAdminOrganizationAvatar,
};
