import {IEntity} from 'Common/models/IEntity';

export enum HorseSampleStatus {
  Undefined = 'Undefined',
  Waiting = 'Waiting',
  Received = 'Received',
  Broken = 'Broken',
}

export interface IHorseSample extends IEntity {
  sampleId: string;
  status: HorseSampleStatus;
}
