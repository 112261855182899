import {IEntity} from 'Common/models/IEntity';
import {Nullable} from 'Common/types';
import {IOnlineReportCoatColor} from 'OnlineReport/models/CoatColor/IOnlineReportCoatColor';
import {IOnlineReportHealthVariants} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariants';
import {IOnlineReportAbility} from 'OnlineReport/models/PerformanceAndAbilities/IOnlineReportAbility';
import {IUserPrintableReportPreferencesPreferences} from 'OnlineReportPrintable/models/IUserPrintableReportPreferencesPreferences';
import {IUserPrintableReportPreferences} from 'OnlineReportPrintable/models/IUserPrintableReportPreferences';
import {IUserReportSummaryVisbility} from 'OnlineReportPrintable/models/IUserReportSummaryVisibility';
import {initialValue, ITraitVisibilities, IFormValues} from './validation';

/**
 * Props used to generate the initialValue.
 */
interface OuterProps {
  coatColors: Nullable<IOnlineReportCoatColor[]>;
  healthVariants: Nullable<IOnlineReportHealthVariants[]>;
  abilities: Nullable<IOnlineReportAbility[]>;
  printableReportPreferences: Nullable<IUserPrintableReportPreferences>;
}

/**
 * Maps redux store props to formik initialValues for the PrintSelectionForm.
 */
export const formikPropsToValues = ({
  coatColors,
  healthVariants,
  abilities,
  printableReportPreferences,
}: OuterProps) => {
  const formValueSelector = <T, E extends IEntity>(
    groups: T[],
    selector: (object: T) => E[],
    idSelector: (object: E) => number
  ) => ({
    traits: groups.reduce(
      (acc, group) => ({
        ...acc,
        ...selector(group).reduce((acc, partial) => ({...acc, [idSelector(partial)]: {isVisible: true}}), {}),
      }),
      {}
    ),
  });

  const partialColorsToFormValue = (groups: IOnlineReportCoatColor[]) =>
    formValueSelector(
      groups,
      (group) => group.partialColors,
      (partial) => partial.id
    );

  const healthVariantsToFormValue = (groups: IOnlineReportHealthVariants[]) =>
    formValueSelector(
      groups,
      (group) => group.healthVariants,
      (partial) => (partial.isAggregated && partial.aggregatorId ? partial.aggregatorId : partial.id)
    );

  const abilitiesToFormValue = (groups: IOnlineReportAbility[]) =>
    formValueSelector(
      groups,
      (group) => group.abilities,
      (partial) => (partial.isAggregated && partial.aggregatorId ? partial.aggregatorId : partial.id)
    );

  const mergeServerClientPreferences = (
    originalPrefs: ITraitVisibilities,
    serverPrefs: IEntity[]
  ): ITraitVisibilities =>
    serverPrefs
      .filter(({id}) => Object.keys(originalPrefs.traits).includes(id.toString()))
      .reduce(
        (acc, pref) => ({
          traits: {
            ...acc.traits,
            [pref.id]: {
              isVisible: false,
            },
          },
        }),
        originalPrefs
      );

  const values = initialValue;

  if (coatColors) values.partialColors = partialColorsToFormValue(coatColors);
  if (healthVariants) {
    values.aggregatedHealthIssues = healthVariantsToFormValue(
      healthVariants.map((group) => ({
        ...group,
        healthVariants: group.healthVariants.filter((x) => x.isAggregated),
      }))
    );

    values.advancedHealthIssues = healthVariantsToFormValue(
      healthVariants.map((group) => ({
        ...group,
        healthVariants: group.healthVariants.filter((x) => !x.isAggregated),
      }))
    );
  }
  if (abilities) {
    values.aggregatedAbilities = abilitiesToFormValue(
      abilities.map((group) => ({
        ...group,
        abilities: group.abilities.filter((x) => x.isAggregated),
      }))
    );

    values.advancedAbilities = abilitiesToFormValue(
      abilities.map((group) => ({
        ...group,
        abilities: group.abilities.filter((x) => !x.isAggregated),
      }))
    );
  }

  if (printableReportPreferences) {
    values.summary = {
      variantSummary: {
        isVisible: printableReportPreferences.reportSummaryVisibility.isVariantSummaryVisible,
      },
      abilities: {
        isVisible: printableReportPreferences.reportSummaryVisibility.isAbilitiesVisible,
      },
      coatColors: {
        isVisible: printableReportPreferences.reportSummaryVisibility.isCoatColorsVisible,
      },
      healthVariants: {
        isVisible: printableReportPreferences.reportSummaryVisibility.isHealthVariantsVisible,
      },
    };

    values.partialColors = mergeServerClientPreferences(
      values.partialColors,
      printableReportPreferences.hiddenPartialColors
    );

    values.aggregatedHealthIssues = mergeServerClientPreferences(
      values.aggregatedHealthIssues,
      printableReportPreferences.hiddenAggregatedHealthIssues
    );

    values.advancedHealthIssues = mergeServerClientPreferences(
      values.advancedHealthIssues,
      printableReportPreferences.hiddenAdvancedHealthIssues
    );

    values.aggregatedAbilities = mergeServerClientPreferences(
      values.aggregatedAbilities,
      printableReportPreferences.hiddenAggregatedAbilities
    );

    values.advancedAbilities = mergeServerClientPreferences(
      values.advancedAbilities,
      printableReportPreferences.hiddenAdvancedAbilities
    );
  }

  return values;
};

/**
 * Represents the delta between an IFormValue and the existing PrintableReportPreferences.
 */
export interface IServerFormDelta {
  summary: IUserReportSummaryVisbility;
  newHidden: IUserPrintableReportPreferencesPreferences;
  newShow: IUserPrintableReportPreferencesPreferences;
}

/**
 * Takes values from the PrintSelectionForm along with pre-existing server preferences andf returns
 * the new ReportSummaryVisibility, new phenotypes to send to the /Hide API, and phenotypes to send to
 * the /Show API.
 *
 * When `printableReportPreferences` is null, it will simply return all new values.
 */
export const getServerFormDelta = (
  values: IFormValues,
  printableReportPreferences: Nullable<IUserPrintableReportPreferences>
): IServerFormDelta => {
  if (printableReportPreferences == null) {
    return {
      summary: {
        isVariantSummaryVisible: values.summary.variantSummary.isVisible,
        isCoatColorsVisible: values.summary.coatColors.isVisible,
        isAbilitiesVisible: values.summary.abilities.isVisible,
        isHealthVariantsVisible: values.summary.healthVariants.isVisible,
      },
      newHidden: {
        hiddenPartialColors: getHiddenPhenotypes(values.partialColors),
        hiddenAggregatedHealthIssues: getHiddenPhenotypes(values.aggregatedHealthIssues),
        hiddenAdvancedHealthIssues: getHiddenPhenotypes(values.advancedHealthIssues),
        hiddenAggregatedAbilities: getHiddenPhenotypes(values.aggregatedAbilities),
        hiddenAdvancedAbilities: getHiddenPhenotypes(values.advancedAbilities),
      },
      newShow: {
        hiddenPartialColors: getShowPhenotypes(values.partialColors),
        hiddenAggregatedHealthIssues: getShowPhenotypes(values.aggregatedHealthIssues),
        hiddenAdvancedHealthIssues: getShowPhenotypes(values.advancedHealthIssues),
        hiddenAggregatedAbilities: getShowPhenotypes(values.aggregatedAbilities),
        hiddenAdvancedAbilities: getShowPhenotypes(values.advancedAbilities),
      },
    };
  }

  return {
    summary: {
      isVariantSummaryVisible: values.summary.variantSummary.isVisible,
      isCoatColorsVisible: values.summary.coatColors.isVisible,
      isAbilitiesVisible: values.summary.abilities.isVisible,
      isHealthVariantsVisible: values.summary.healthVariants.isVisible,
    },
    newHidden: {
      hiddenPartialColors: getNewHiddenPhenotypes(printableReportPreferences.hiddenPartialColors, values.partialColors),
      hiddenAggregatedHealthIssues: getNewHiddenPhenotypes(
        printableReportPreferences.hiddenAggregatedHealthIssues,
        values.aggregatedHealthIssues
      ),
      hiddenAdvancedHealthIssues: getNewHiddenPhenotypes(
        printableReportPreferences.hiddenAdvancedHealthIssues,
        values.advancedHealthIssues
      ),
      hiddenAggregatedAbilities: getNewHiddenPhenotypes(
        printableReportPreferences.hiddenAggregatedAbilities,
        values.aggregatedAbilities
      ),
      hiddenAdvancedAbilities: getNewHiddenPhenotypes(
        printableReportPreferences.hiddenAdvancedAbilities,
        values.advancedAbilities
      ),
    },
    newShow: {
      hiddenPartialColors: getNewShowPhenotypes(printableReportPreferences.hiddenPartialColors, values.partialColors),
      hiddenAggregatedHealthIssues: getNewShowPhenotypes(
        printableReportPreferences.hiddenAggregatedHealthIssues,
        values.aggregatedHealthIssues
      ),
      hiddenAdvancedHealthIssues: getNewShowPhenotypes(
        printableReportPreferences.hiddenAdvancedHealthIssues,
        values.advancedHealthIssues
      ),
      hiddenAggregatedAbilities: getNewShowPhenotypes(
        printableReportPreferences.hiddenAggregatedAbilities,
        values.aggregatedAbilities
      ),
      hiddenAdvancedAbilities: getNewShowPhenotypes(
        printableReportPreferences.hiddenAdvancedAbilities,
        values.advancedAbilities
      ),
    },
  };
};

const getHiddenPhenotypes = (visibilities: ITraitVisibilities) =>
  Object.entries(visibilities.traits)
    .filter(([_, {isVisible}]) => !isVisible)
    .map(([id]) => ({id: +id}));

const getShowPhenotypes = (visibilities: ITraitVisibilities) =>
  Object.entries(visibilities.traits)
    .filter(([_, {isVisible}]) => isVisible)
    .map(([id]) => ({id: +id}));

const getNewHiddenPhenotypes = (serverHidden: IEntity[], visibilities: ITraitVisibilities): IEntity[] =>
  Object.entries(visibilities.traits)
    .filter(([_, {isVisible}]) => !isVisible)
    .filter(([clientId]) => !serverHidden.some(({id}) => id === +clientId))
    .map(([id]) => ({id: +id}));

const getNewShowPhenotypes = (serverHidden: IEntity[], visibilities: ITraitVisibilities): IEntity[] =>
  Object.entries(visibilities.traits)
    .filter(([_, {isVisible}]) => isVisible)
    .filter(([clientId]) => serverHidden.some(({id}) => id === +clientId))
    .map(([id]) => ({id: +id}));
