import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';

import ColorPalette from 'Common/constants/ColorPalette';
import Icon, {IconName} from 'Icon/components/Icon';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import usePermissions from 'Permissions/hooks/usePermissions';
import {buildAdminPermission} from 'Common/components/Navigation';
import {AdminPermissionDomain} from 'Common/constants/AdminPermissionDomain';
import {AdminPermissionOperation} from 'Common/constants/AdminPermissionOperation';

const Root = styled.div`
  width: 391px;
  min-height: 89px;
  background: ${ColorPalette.red7};
  box-shadow: 0px 6px 20px ${hexToRgba(ColorPalette.black0, 0.12)};
  border-radius: 20px;
  position: fixed;
  bottom: 63px;
  right: 70px;
  z-index: 101;
`;

const WarningIcon = styled(Icon)`
  margin: 16px 0 0 16px;
`;

const Description = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.white0};
  margin: 16px;
`;

const RightSide = styled.div`
  margin: 16px 16px 16px 0;
`;

const ChangeText = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size16};
  line-height: 18px;
  letter-spacing: -0.2px;
  color: ${ColorPalette.white0};
  text-align: right;

  &:hover {
    cursor: pointer;
    color: ${ColorPalette.gray20};
  }
`;

const ChangeUnderline = styled.div`
  width: 54px;
  height: 2px;
  border: 1px ${ColorPalette.white0} solid;
  margin-top: 4px;
`;

interface IProps {
  onClose(): void;
}

function MaintainNotification(props: IProps) {
  const {onClose} = props;
  const history = useHistory();
  const {hasPermission} = usePermissions();

  const onChange = useCallback(() => {
    history.push('/admin-advanced/emergencies');
  }, [history]);

  const isChangeAvailable = hasPermission(
    buildAdminPermission(AdminPermissionDomain.Superior, AdminPermissionOperation.Read)
  );

  return (
    <Root className="d-flex justify-content-between">
      <WarningIcon name={IconName.Warning} size={24} />
      <Description className="d-flex flex-column justify-content-between">
        <div>User access to the portal disabled</div>
        {isChangeAvailable ? (
          <div>You can change it in access panel</div>
        ) : (
          <div>Admin with Superior permission can change it in access panel</div>
        )}
      </Description>
      <RightSide className="d-flex flex-column justify-content-between align-items-end">
        <IconButton
          name={IconName.Close}
          size={16}
          color={ColorPalette.white0}
          hoverColor={ColorPalette.gray20}
          fill={true}
          stroke={false}
          onClick={onClose}
        />
        {isChangeAvailable && (
          <div>
            <ChangeText onClick={onChange}>Change</ChangeText>
            <ChangeUnderline />
          </div>
        )}
      </RightSide>
    </Root>
  );
}

export default MaintainNotification;
