import React from 'react';

import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {
  ISetSampleStatusActionProps,
  useSetSampleStatusAction,
} from 'Admin/shared/helpers/hooks/useOrderActions/useSetSampleStatusAction';
import {IAdminActionsProps} from 'Admin/AdminDashboard/helpers/hooks/common';
// import {useCreateNewSampleAction} from './parts/useCreateNewSampleAction';
import {useUpdatePaymentModal} from 'Admin/shared/helpers/hooks/useOrderActions/useUpdatePaymentModal';
import {useDeleteModal} from 'Common/helpers/hooks/useDeleteModal';
import {useRequestNewSampleAction} from 'Admin/shared/helpers/hooks/useOrderActions/useRequestNewSampleAction';
import {useConfirmModal} from 'Common/helpers/hooks/useConfirmModal';
import {IRequestChangeReviewStatus} from 'Admin/AdminDashboard/store/adminOrders/types';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';

export interface IDeleteOrderActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(orderId: number): void;
}

export interface IDismissReviewActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(data: IRequestChangeReviewStatus): void;
}

interface IOrderActionsPartsProps {
  deleteOrderAction: Omit<IDeleteOrderActionProps, 'onSuccess'>;
  downloadSubmissionFormAction: Omit<IAdminActionsProps, 'onSuccess'>;
  setSampleStatusAction: Omit<ISetSampleStatusActionProps, 'onSuccess'>;
  requestNewSampleAction: Omit<IAdminActionsProps, 'onSuccess'>;
  // downloadReportsAction: Omit<IAdminActionsProps, 'onSuccess'>;
  dismissReviewAction: Omit<IDismissReviewActionProps, 'onSuccess'>;
}

interface IProps {
  data: IOrderActionsPartsProps;
  onSuccess(): void;
}

export function useOrderActions(props: IProps) {
  const {
    onSuccess,
    data: {
      // downloadReportsAction,
      requestNewSampleAction,
      deleteOrderAction,
      downloadSubmissionFormAction,
      setSampleStatusAction,
      dismissReviewAction,
    },
  } = props;

  const {emit} = useEventBus();

  const {deleteModal, openDeleteModal} = useDeleteModal({
    onSuccess,
    deleteCommunication: deleteOrderAction.communication,
    confirmDescription: 'Are you sure you want to remove the order?',
    deleteAction: (selectedId: number) => deleteOrderAction.action(selectedId),
  });

  const {openSampleNotReceivedModal, openSampleReceivedModal, setSampleStatusModal} = useSetSampleStatusAction({
    action: setSampleStatusAction.action,
    onSuccess: () => {
      emit(EventName.SampleChanged);
      onSuccess();
    },
  });

  const {openUpdatePaymentModal, updatePaymentModal} = useUpdatePaymentModal({onSuccess: onSuccess});

  const {requestNewSampleModal, openRequestNewSampleModal} = useRequestNewSampleAction({
    onSuccess: () => {
      emit(EventName.SampleChanged);
      onSuccess();
    },
  });

  const {confirmModal: dismissReviewConfirmModal, openConfirmModal: openDismissReviewConfirmModal} = useConfirmModal({
    confirmDescription: 'Are you sure you want to dismiss review?',
    confirmCommunication: dismissReviewAction.communication,
    successMessage: 'Review successfully dismissed',
    onConfirmAction: dismissReviewAction.action,
    confirmId: 'dismissReviewConfirm',
    onSuccess: () => {
      emit(EventName.ReleaseOrder);
      onSuccess();
    },
  });

  useCommunicationToToast(deleteOrderAction.communication, 'Order successfully deleted', undefined, 1);
  useCommunicationToToast(setSampleStatusAction.communication, 'Sample status successfully set', undefined, 2);
  useCommunicationToToast(requestNewSampleAction.communication, 'Sample ID successfully updated', undefined, 3);
  useCommunicationToToast(
    downloadSubmissionFormAction.communication,
    'Submission form successfully received',
    undefined,
    4
  );
  // useCommunicationToToast(downloadReportsAction.communication, 'All reports successfully received', undefined, 5);

  const orderActionsModal = (
    <>
      {deleteModal}
      {setSampleStatusModal}
      {requestNewSampleModal}
      {updatePaymentModal}
    </>
  );

  return {
    openDeleteModal,
    openSampleReceivedModal,
    openSampleNotReceivedModal,
    // createNewSampleModal,
    openRequestNewSampleModal,
    orderActionsModal,
    openUpdatePaymentModal,
    updatePaymentModal,
    dismissReviewConfirmModal,
    openDismissReviewConfirmModal,
  };
}
