import {Reducer} from 'redux';

import {
  confirmAgreementCurrentUserActionTypes,
  CurrentUserActions,
  getCurrentAdminActionTypes,
  getCurrentAssociationEmployeeActionTypes,
  getCurrentUserActionTypes,
  getProfileTypeCurrentUserActionTypes,
  ICurrentUserState,
  resetCurrentAdminActionTypes,
  resetCurrentAssociationEmployeeActionTypes,
  resetCurrentUserActionTypes,
} from '../types';

const initialState: ICurrentUserState['data'] = {
  user: null,
  admin: null,
  associationEmployee: null,
  userProfileType: null,
};

export const dataCurrentUserReducer: Reducer<ICurrentUserState['data'], CurrentUserActions> = (
  state = initialState,
  action: CurrentUserActions
) => {
  switch (action.type) {
    case getCurrentUserActionTypes.SUCCESS: {
      return {...state, user: action.payload};
    }
    case getCurrentAdminActionTypes.SUCCESS: {
      return {...state, admin: action.payload};
    }
    case getCurrentAssociationEmployeeActionTypes.SUCCESS: {
      return {...state, associationEmployee: action.payload};
    }
    case confirmAgreementCurrentUserActionTypes.SUCCESS: {
      if (!state.user) {
        return state;
      }
      return {...state, user: {...state.user, isAgreement: action.payload.isAgree}};
    }
    case getProfileTypeCurrentUserActionTypes.SUCCESS: {
      return {...state, userProfileType: action.payload};
    }
    case resetCurrentUserActionTypes.RESET: {
      return {...state, user: initialState.user};
    }
    case resetCurrentAdminActionTypes.RESET: {
      return {...state, admin: initialState.admin};
    }
    case resetCurrentAssociationEmployeeActionTypes.RESET: {
      return {...state, associationEmployee: initialState.associationEmployee};
    }
    default: {
      return state;
    }
  }
};
