import * as Yup from 'yup';

import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues {
  privateId: string;
  message: string;
}

export const initialValue: IFormValues = {
  privateId: '',
  message: '',
};

export const validationSchema = Yup.object().shape<IFormValues>({
  privateId: Yup.string().required(REQUIRED_FIELD),
  message: Yup.string()
    .required(REQUIRED_FIELD)
    .test('Message test', 'Message cannot be empty', function (value: string) {
      const trimmed = value ? value.trim() : '';
      return !!trimmed;
    }),
});
