import {Reducer} from 'redux';

import {
  Actions,
  clearShoppingCartActionTypes,
  createShoppingCartActionTypes,
  deleteShoppingCartActionTypes,
  getShoppingCartActionTypes,
  IShoppingCartState,
} from '../types';

const initialStateList: IShoppingCartState['data'] = {
  shoppingCart: [],
};

export const dataReducer: Reducer<IShoppingCartState['data'], Actions> = (
  state = initialStateList,
  action: Actions
) => {
  switch (action.type) {
    case getShoppingCartActionTypes.SUCCESS: {
      return {...state, shoppingCart: action.payload};
    }

    case createShoppingCartActionTypes.SUCCESS: {
      const newValue = action.payload;
      const newShoppingCart = [...state.shoppingCart];

      const findId = newShoppingCart.findIndex((x) => x.packageId === newValue.packageId);
      if (findId === -1) {
        newShoppingCart.push({packageId: newValue.packageId, horses: [newValue.horseId]});
      } else {
        newShoppingCart[findId].horses.push(newValue.horseId);
      }

      return {...state, shoppingCart: newShoppingCart};
    }

    case deleteShoppingCartActionTypes.SUCCESS: {
      const deletedValue = action.payload;
      const newShoppingCart = [...state.shoppingCart];

      const findId = newShoppingCart.findIndex((x) => x.packageId === deletedValue.packageId);
      if (findId !== -1) {
        const findHorseId = newShoppingCart[findId].horses.findIndex((x) => x === deletedValue.horseId);
        if (findHorseId !== -1) newShoppingCart[findId].horses.splice(findHorseId, 1);
      }

      return {...state, shoppingCart: newShoppingCart};
    }

    case clearShoppingCartActionTypes.SUCCESS: {
      return {...state, shoppingCart: initialStateList.shoppingCart};
    }

    default: {
      return state;
    }
  }
};
