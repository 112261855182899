import React, {memo, useCallback, useEffect} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';

import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {IFormValues, initialValue, validationSchema} from './validation';
import withHorseTransferActions, {
  IWithHorseTransferActionsProps,
} from 'Admin/AdminDashboard/helpers/withHorseTransferActions';
import {TextAreaField} from 'Common/components/FormFields';

const Root = styled.div`
  margin-right: 24px;
`;

const Text = styled.div`
  margin-bottom: 16px;
`;

interface IExternalProps {
  transferId: number;
  onSuccess(): void;
  onClose(): void;
}

type OuterProps = IWithHorseTransferActionsProps & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function RejectHorseTransferForm(props: AllProps) {
  const {isSubmitting, setErrors, setFieldValue} = props;
  const {transferId, onSuccess, onClose, horseTransferRejecting} = props;

  const errorInfo = horseTransferRejecting.error;
  const isRequesting = horseTransferRejecting.isRequesting;

  useEffect(() => {
    setFieldValue('transferId', transferId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(horseTransferRejecting, onSuccess);
  useOnErrorCommunication(horseTransferRejecting, onError);
  useCommunicationToToast(horseTransferRejecting, 'Horse transfer request has been rejected');

  return (
    <>
      <ModalWindowHeader>Reject Horse transfer request</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <Root>
          <ModalWindowFormContent scrollable={false}>
            {isRequesting && <Loading />}
            <Text>Are you sure you want to reject horse transfer request?</Text>
            <TextAreaField name="comment" label="Comment (for admin using only)" memoized={true} />
            <TextAreaField name="reason" label="Reason (for email to user)" memoized={true} />
          </ModalWindowFormContent>
        </Root>
        <ModalWindowFooter>
          <ModalWindowButton type="button" variant="outlined" onClick={onClose} isLoading={isSubmitting}>
            Cancel
          </ModalWindowButton>
          <ModalWindowButton type="submit" isLoading={isSubmitting}>
            Ok
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const RejectHorseTransferFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {rejectHorseTransfer} = formikBag.props;

    rejectHorseTransfer({comment: values.comment, reason: values.reason, transferId: values.transferId});
  },
  enableReinitialize: true,
})(RejectHorseTransferForm);

export default memo(withHorseTransferActions(RejectHorseTransferFormFormik));
