import React, {memo} from 'react';

import {Label, Wrapper} from 'BusinessPortal/components/shared/StyledComponents';

const ChangePasswordShow = () => {
  return (
    <div>
      <Wrapper>
        <Label>Change your password</Label>
      </Wrapper>
    </div>
  );
};

export default memo(ChangePasswordShow);
