import {combineReducers} from 'redux';

import {
  createErrorDescriptionActionTypes,
  deleteErrorDescriptionActionTypes,
  getErrorDescriptionActionTypes,
  getErrorDescriptionsActionTypes,
  IAdminErrorDescriptionsState,
  resetErrorDescriptionActionTypes,
  ErrorDescriptionsActions,
  updateErrorDescriptionActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication/index';
import composeReducers from 'Common/store/composeReducers';

export const communicationErrorDescriptionsReducer = combineReducers<
  IAdminErrorDescriptionsState['communications'],
  ErrorDescriptionsActions
>({
  errorDescriptionsLoading: makeCommunicationReducerFromEnum(getErrorDescriptionsActionTypes),
  errorDescriptionUpdating: makeCommunicationReducerFromEnum(updateErrorDescriptionActionTypes),
  errorDescriptionCreating: makeCommunicationReducerFromEnum(createErrorDescriptionActionTypes),
  errorDescriptionDeleting: makeCommunicationReducerFromEnum(deleteErrorDescriptionActionTypes),
  errorDescriptionLoading: composeReducers([
    makeCommunicationReducerFromEnum(getErrorDescriptionActionTypes),
    makeResetCommunicationReducer(resetErrorDescriptionActionTypes.RESET),
  ]),
});
