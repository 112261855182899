import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {
  IAdminAncestryUploadState,
  uploadAncestryActionTypes,
  getAncestryUploadDetailsActionTypes,
  AdminAncestryUploadActions,
  uploadAncestryHorsesLikeMeActionTypes,
} from '../types';

export const communicationAncestryUploadReducer = combineReducers<
  IAdminAncestryUploadState['communications'],
  AdminAncestryUploadActions
>({
  ancestryUploading: makeCommunicationReducerFromEnum(uploadAncestryActionTypes),
  ancestryUploadDetailsLoading: makeCommunicationReducerFromEnum(getAncestryUploadDetailsActionTypes),
  ancestryHorsesLikeMeUploading: makeCommunicationReducerFromEnum(uploadAncestryHorsesLikeMeActionTypes),
});
