import {IModule} from 'redux-dynamic-modules';

import {IAdminOrganizationState} from './types';
import {administratorsReducer} from './reducer';

export interface IAdminOrganizationsModuleState {
  adminOrganizations: IAdminOrganizationState;
}

export const AdminOrganizationsModule: IModule<IAdminOrganizationsModuleState> = {
  id: 'adminOrganizations',
  reducerMap: {
    adminOrganizations: administratorsReducer,
  },
};
