import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';

const Root = styled.div`
  height: 40px;
  display: flex;
  background: ${ColorPalette.gray46};
  border-radius: 4px;
`;

const Tab = styled.div<{selected?: boolean}>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  border-radius: 4px;
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size14};
  line-height: 14px;
  cursor: pointer;

  ${p =>
    p.selected
      ? `
   background: ${Theme.color.primary};
   color: ${Theme.color.white};
   `
      : `
    background: unset;
    color: ${ColorPalette.gray45};
  `};
`;

type Value = string | number;

export interface ITabItem {
  value: Value;
  label: string;
}

export interface IProps {
  items: ITabItem[];
  selectedValue: Value;
  onSelect(value: Value): void;
}

function TabSelect(props: IProps) {
  const {items, onSelect, selectedValue} = props;

  return (
    <Root>
      {items.map(({value, label}, i) => (
        <Tab key={i} onClick={() => onSelect(value)} selected={selectedValue === value}>
          {label}
        </Tab>
      ))}
    </Root>
  );
}

export default memo(TabSelect);
