import {useDictionaries} from 'Common/store/useDictionaries';
import {Combination} from 'Common/constants/Combination';
import {useSequenceEditor} from './useSequenceEditor';
import {IFormSequenceItem} from 'SequenceEditor/models/IFormSequenceItem';
import {IGenotypeSequence, IUpdateGenotypeSequence} from 'Admin/AdminPhenotypes/models/IGenotypeSequence';

export function useGenotypeSequenceEditor(
  type: Combination,
  sequences: IGenotypeSequence[],
  onSuccess: (type: Combination, sequences: IUpdateGenotypeSequence[]) => void
) {
  const {genotypes} = useDictionaries();

  const getStringifySequences = () => {
    return sequences.map(s => `${s.genotypes.map(x => x.name).join(` ${s.type} `)}`);
  };

  function convertSequenceToFormValues(): IFormSequenceItem[] {
    return sequences.map(value => ({type: value.type, sequenceItems: value.genotypes.map(x => x.id)}));
  }

  function convertFormValuesToGenotypeSequence(values: IFormSequenceItem[]): IUpdateGenotypeSequence[] {
    const sequences = values.map(value => ({
      type: value.type,
      genotypes: value.sequenceItems
        .filter(x => x > 0)
        .map(x => ({
          id: x
        }))
    }));

    return sequences.filter(x => x.genotypes.length > 0);
  }

  function handleSuccess(type: Combination, sequences: IFormSequenceItem[]) {
    const convertedSequences = convertFormValuesToGenotypeSequence(sequences);
    onSuccess(type, convertedSequences);
  }

  const {renderRuleField, renderSequenceEditorModal} = useSequenceEditor(
    type,
    convertSequenceToFormValues(),
    genotypes,
    handleSuccess,
    getStringifySequences,
    'Genotypes',
    'Genotype'
  );

  return {renderRuleField, renderSequenceEditorModal};
}
