import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

import {
  IPermissionsState,
  getCurrentPermissionsActions,
  getCurrentPermissionsActionTypes,
  resetCurrentPermissionsActionTypes,
} from '../types';

export const communicationLoginReducer = combineReducers<
  IPermissionsState['communications'],
  getCurrentPermissionsActions
>({
  currentPermissionsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getCurrentPermissionsActionTypes),
    makeResetCommunicationReducer(resetCurrentPermissionsActionTypes.RESET),
  ]),
});
