import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import TransferDetails from 'Admin/AdminDashboard/components/HorseTransfer/TransferDetails/TransferDetails';

interface IHorseTransferDetailsModalParams {}

export function useHorseTransferDetailsModal(params: IHorseTransferDetailsModalParams) {
  const [horseTransferId, setHorseTransferId] = useState<number>(0);

  const [isOpen, setIsOpen] = useState(false);
  const openHorseTransferDetailsModal = useCallback((transferId: number) => {
    setHorseTransferId(transferId);
    setIsOpen(true);
  }, []);
  const closeHorseTransferDetailsModal = useCallback(() => setIsOpen(false), []);

  const horseTransferDetailsModal = (
    <ModalWindow isOpen={isOpen} onClose={closeHorseTransferDetailsModal}>
      <TransferDetails horseTransferId={horseTransferId} />
    </ModalWindow>
  );

  return {
    horseTransferDetailsModal,
    openHorseTransferDetailsModal,
  };
}
