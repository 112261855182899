import React, {memo} from 'react';
// import styled from 'styled-components';

// import {breakpoints} from 'Common/constants/Breakpoints';
// import Typography from 'Common/constants/Typography';
// import Theme from 'Common/constants/Theme';

// const Root = styled.div`
//   margin-top: 8px;
//   justify-content: start;

//   > div:not(:first-child) {
//     margin-left: 16px;
//   }

//   @media ${breakpoints.sm} {
//     margin: 0;
//   }

//   @media ${breakpoints.lg} {
//     justify-content: space-around;
//   }
// `;

// const StatisticsValue = styled.span`
//   font-family: ${Theme.font.secondary};
//   font-style: normal;
//   font-weight: ${Typography.weight.semiBold600};
//   font-size: ${Typography.size.size16};
//   line-height: 24px;
//   color: ${Theme.color.black};

//   @media ${breakpoints.sm} {
//     font-size: ${Typography.size.size24};
//     line-height: 32px;
//   }
// `;

// const StatisticsLabel = styled.span`
//   font-family: ${Theme.font.primary};
//   font-style: normal;
//   font-weight: ${Typography.weight.normal400};
//   font-size: ${Typography.size.size12};
//   line-height: 16px;
//   color: ${Theme.color.gray};
//   margin-right: 8px;

//   @media ${breakpoints.sm} {
//     font-size: ${Typography.size.size16};
//     line-height: 24px;
//     margin-right: 11px;
//   }
// `;

interface IProps {
  countHorses: number;
  countVerifiedTests: number;
}

// TODO: Uncomment after backend is ready, and countHorses, countVerifiedTests are counted properly

const StatisticsSection = (props: IProps) => {
  // const {countHorses, countVerifiedTests} = props;

  return <></>;
  // (
  //   <Root className="d-flex ">
  //     <div className="d-flex align-items-baseline">
  //       <StatisticsLabel>Horses</StatisticsLabel>
  //       <StatisticsValue>{countHorses !== undefined ? countHorses : 'N/A'}</StatisticsValue>{' '}
  //     </div>
  //     <div className="d-flex align-items-baseline">
  //       <StatisticsLabel>Verified tests</StatisticsLabel>
  //       <StatisticsValue>{countVerifiedTests !== undefined ? countVerifiedTests : 'N/A'}</StatisticsValue>{' '}
  //     </div>
  //   </Root>
  // );
};

export default memo(StatisticsSection);
