import React, {memo, useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {IAppState} from 'Common/store/IAppState';
import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {Pagination} from 'Common/components/Controls';
import Loading from 'Loading/components/Loading';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminEmployees';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {AvatarCell} from 'Admin/AdminDashboard/components/shared';
import {IAdminEmployee} from 'Admin/AdminAssociations/models/IAdminEmployee';
import Actions from 'Common/components/Actions/Actions';
import {AdminEmployeesModule} from 'Admin/AdminAssociations/store/adminEmployees/adminEployeesModule';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import usePermissions from 'Permissions/hooks/usePermissions';
import {VisitorType} from 'Common/constants/VisitorType';
import {SwitchButton} from 'Common/components/Controls/Buttons/SwitchButton';
import {useAdminEmployeeActions} from 'Admin/AdminAssociations/helpers/hooks/useAdminEmployeeActions/useAdminEmployeeActions';
import {AdminEmployeeActionValue, menuItems} from './EmploeesActions';
import {UserPermissionsModule} from 'Admin/AdminDashboard/store/adminUsers/userPermission/UserPermissionsModule';

const AddButton = styled(PrimaryButton)`
  height: 50px;
  margin-left: 55px;
`;

const AdminEmployeesTable = styled.div`
  margin-top: 50px;
`;

type IConnected = ConnectedProps<typeof connector>;

const AdminEmployees = (props: IConnected) => {
  const {
    adminEmployees,
    adminEmployeesLoading,
    changeAdminEmployeeStatus,
    adminEmployeeStatusUpdating,
    getAdminEmployees,
    pagination,
  } = props;

  const {isVisitorType} = usePermissions();

  const {params, changeSorting, sorting, handlePageSelect, handleCountPerPage, searchBar} =
    useCommonAdminPageData<IAdminEmployee>({
      getItems: getAdminEmployees,
      searchBarPlaceholder: 'Search for employees by any keyword',
    });

  const reloadAdminEmployees = useCallback(() => {
    getAdminEmployees(params);
  }, [getAdminEmployees, params]);

  const {
    createAdminEmployeeModal,
    updateAdminEmployeeModal,
    updateAdminEmployeePasswordModal,
    updateAdminEmployeeEmailModal,
    updateUserPermissionsModal,
    openCreateAdminEmployeeModal,
    openUpdateAdminEmployeeModal,
    openUpdateAdminEmployeePasswordModal,
    openUpdateAdminEmployeeEmailModal,
    openModalUserPermissionsModal,
  } = useAdminEmployeeActions({
    onSuccess: reloadAdminEmployees,
  });

  const changeStatus = useCallback(
    (value: boolean, adminId: number) => {
      changeAdminEmployeeStatus(adminId, value);
    },
    [changeAdminEmployeeStatus]
  );

  const isLoading = adminEmployeesLoading.isRequesting || adminEmployeeStatusUpdating.isRequesting;
  const isAdminVisitor = isVisitorType(VisitorType.Admin);
  const isShowPagination = adminEmployees.length > 0;

  return (
    <AdminPageLayout>
      {createAdminEmployeeModal}
      {updateAdminEmployeeModal}
      {updateAdminEmployeePasswordModal}
      {updateAdminEmployeeEmailModal}
      {updateUserPermissionsModal}
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
        {isAdminVisitor && <AddButton onClick={openCreateAdminEmployeeModal}>+ Create Employee</AddButton>}
      </div>
      <AdminEmployeesTable className="position-relative">
        {isLoading && <Loading />}
        <Table<IAdminEmployee> data={adminEmployees} rowKey="id" sorting={sorting} onChangeSorting={changeSorting}>
          <Cell<IAdminEmployee>
            header="Active"
            dataKey="isActive"
            render={({id, isActive}) => <SwitchButton itemId={id} onChangeHandler={changeStatus} checked={isActive} />}
            width="10%"
          />
          <Cell<IAdminEmployee> header="ID" dataKey="id" render={({id}) => `#${id}`} expandOnClick={true} width="100" />
          <Cell<IAdminEmployee>
            header="Name"
            dataKey="name"
            render={({id, name, avatar}) => (
              <AvatarCell
                type="owner"
                avatarUrl={avatar?.url}
                label={name}
                onLabelClick={() => openUpdateAdminEmployeeModal(id)}
              />
            )}
            width="30%"
          />
          <Cell<IAdminEmployee> header="Email" dataKey="email" render={({email}) => email} width="20%" />
          <Cell<IAdminEmployee>
            header="Phone"
            dataKey="phoneNumber"
            render={({phoneNumber}) => phoneNumber}
            width="10%"
          />
          <Cell<IAdminEmployee>
            header="Organization"
            dataKey="organization"
            render={({organization}) => {
              const abbreviation = organization.abbreviation ? ` (${organization.abbreviation})` : '';
              return (
                <>
                  {organization.name}
                  {abbreviation}
                </>
              );
            }}
            width="20%"
          />

          <Cell<IAdminEmployee>
            header="Actions"
            align={CellAlign.Right}
            width="100"
            render={(adminEmployee) => (
              <Actions<AdminEmployeeActionValue>
                menuItems={menuItems}
                updateAdminEmployee={() => openUpdateAdminEmployeeModal(adminEmployee.id)}
                updateAdminEmployeePassword={() => openUpdateAdminEmployeePasswordModal(adminEmployee)}
                updateAdminEmployeeEmail={() => openUpdateAdminEmployeeEmailModal(adminEmployee)}
                updateAdminEmpoyeePermissions={() =>
                  openModalUserPermissionsModal(adminEmployee.id, adminEmployee.name)
                }
              />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </AdminEmployeesTable>
    </AdminPageLayout>
  );
};

const mapStateToProps = (state: IAppState) => ({
  adminEmployees: selectors.selectAdminEmployees(state),
  adminEmployeesLoading: selectors.selectCommunication(state, 'adminEmployeesLoading'),
  adminEmployeeStatusUpdating: selectors.selectCommunication(state, 'adminEmployeeStatusUpdating'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getAdminEmployees: actions.getAdminEmployees,
  changeAdminEmployeeStatus: actions.changeAdminEmployeeStatus,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(AdminEmployees));

export default withDynamicModules(Connected, [AdminEmployeesModule, UserPermissionsModule]);
