import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {
  clearShoppingCartActions,
  clearShoppingCartActionTypes,
  createShoppingCartActions,
  createShoppingCartActionTypes,
  deleteShoppingCartActions,
  deleteShoppingCartActionTypes,
  getShoppingCartActions,
  getShoppingCartActionTypes,
} from './types';
import ShoppingCartDataService from 'Order/services/ShoppingCartDataService';
import {IHorsePackageRequest} from 'Order/services/types/shoppingCart';

export const getShoppingCart =
  (): ActionResult<IAppState, Promise<void>, getShoppingCartActions> => async (dispatch) => {
    dispatch(action(getShoppingCartActionTypes.REQUEST));
    try {
      const res = await ShoppingCartDataService.getShoppingCart();
      dispatch(action(getShoppingCartActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getShoppingCartActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createShoppingCart =
  (params: IHorsePackageRequest): ActionResult<IAppState, Promise<void>, createShoppingCartActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createShoppingCartActionTypes.REQUEST));
      await ShoppingCartDataService.createShoppingCart(params);
      dispatch(action(createShoppingCartActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(createShoppingCartActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteShoppingCart =
  (params: IHorsePackageRequest): ActionResult<IAppState, Promise<void>, deleteShoppingCartActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteShoppingCartActionTypes.REQUEST));
      await ShoppingCartDataService.deleteShoppingCart(params);
      dispatch(action(deleteShoppingCartActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(deleteShoppingCartActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const clearShoppingCart =
  (): ActionResult<IAppState, Promise<void>, clearShoppingCartActions> => async (dispatch) => {
    try {
      dispatch(action(clearShoppingCartActionTypes.REQUEST));
      await ShoppingCartDataService.clearShoppingCart();
      dispatch(action(clearShoppingCartActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(clearShoppingCartActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
