import {IPaymentDetails, PaymentStatus} from 'Admin/AdminDashboard/models/IPaymentDetails';
import {IPaymentDetailsForm} from 'Admin/AdminDashboard/components/shared/UploadPaymentDetailsForm/UploadPaymentDetailsFormValidation';
import {OrderStatusField} from 'Order/models/IOrderStatusFields';

export function convertPaymentDetailsToPaymentDetailsForm(paymentDetails: IPaymentDetails): IPaymentDetailsForm {
  return {
    ...paymentDetails,
    paymentStatus: paymentDetails.paymentStatus && convertOrderStatusFieldToPaymentStatus(paymentDetails.paymentStatus),
    sendEmail: true
  };
}

export function convertPaymentDetailsFormToPaymentDetails(paymentDetails: IPaymentDetailsForm): IPaymentDetails {
  return {
    ...paymentDetails,
    paymentStatus: paymentDetails.paymentStatus && convertPaymentStatusToOrderStatusField(paymentDetails.paymentStatus)
  };
}

function convertOrderStatusFieldToPaymentStatus(orderStatusField: OrderStatusField): PaymentStatus {
  if (orderStatusField === OrderStatusField.Waiting) {
    return PaymentStatus.Waiting;
  }

  return PaymentStatus.Ready;
}

function convertPaymentStatusToOrderStatusField(paymentStatus: PaymentStatus): OrderStatusField {
  if (paymentStatus === PaymentStatus.Waiting) {
    return OrderStatusField.Waiting;
  }

  return OrderStatusField.Ready;
}
