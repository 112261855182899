import {IModule} from 'redux-dynamic-modules';
import {IUserPermissionsState} from './types';
import {userPermissionsReducer} from 'Admin/AdminDashboard/store/adminUsers/userPermission/reducer/index';

export interface IUserPermissionsModuleState {
  userPermissions: IUserPermissionsState;
}

export const UserPermissionsModule: IModule<IUserPermissionsModuleState> = {
  id: 'userPermissions',
  reducerMap: {
    userPermissions: userPermissionsReducer
  }
};
