import {combineReducers, Reducer} from 'redux';

import {communicationServiceModeReducer} from './communications';
import {dataServiceModeReducer} from './data';
import {IServiceModeState} from '../types';

export const serviceModeReducer: Reducer<IServiceModeState> = combineReducers<IServiceModeState>({
  communications: communicationServiceModeReducer,
  data: dataServiceModeReducer
});
