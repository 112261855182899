import React, {memo} from 'react';
import styled from 'styled-components';

import OrderOverview from 'UserProfile/components/TabsData/common/OrderOverview';
import {Nullable} from 'Common/types';
import {IUserHorseOrder} from 'Horse/models/IUserHorse';
import {breakpoints} from 'Common/constants/Breakpoints';
import {mediumMdSmBreakpoint} from 'UserProfile/components/TabsData/common/constants';
import {
  ContainerBase,
  MissingOrderContainer,
} from 'Shared/components/UserProfile/parts/UserHorsesTab/UserHorse/UserHorseButtons/styled';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';
import {VisitorType} from 'Common/constants/VisitorType';

const ExistingOrderContainer = styled(ContainerBase)`
  margin-bottom: 16px;
  @media ${breakpoints.sm} {
    width: 60%;
  }

  @media ${mediumMdSmBreakpoint} {
    width: 168px;
    align-items: center;
    margin-top: 0;
  }
`;

const OrderButton = styled(LinkButton)`
  width: 100%;
  min-width: unset;

  @media ${breakpoints.sm} {
    width: 168px;
  }
`;

const ExistingOrderButton = styled(OrderButton)`
  margin-top: 8px;

  @media ${breakpoints.sm} {
    margin-top: 15px;
  }

  @media ${mediumMdSmBreakpoint} {
    align-self: center;
  }
`;

const OrderWrapper = styled.div`
  justify-content: center;

  @media ${breakpoints.sm} {
    justify-content: flex-start;
  }

  @media ${mediumMdSmBreakpoint} {
    justify-content: center;
  }
`;

const MissingOrder = styled(MissingOrderContainer)`
  margin-bottom: 16px;
`;

interface IProps {
  horseId: number;
  order: Nullable<IUserHorseOrder>;
  visitorType: VisitorType;
}

type AllProps = IProps;

const HorseOrderButtons = (props: AllProps) => {
  const {order, horseId, visitorType} = props;

  if (order) {
    return (
      <ExistingOrderContainer className="d-flex flex-column h-100 justify-content-start">
        <OrderWrapper className="d-flex w-100">
          <OrderOverview order={order} />
        </OrderWrapper>
        <ExistingOrderButton
          size="small"
          variant="outlined"
          to={`${PREFIX_VISITOR_TYPE[visitorType]}/order-tests/${horseId}`}
        >
          Order Testing
        </ExistingOrderButton>
      </ExistingOrderContainer>
    );
  }

  return (
    <MissingOrder className="d-flex w-100">
      <OrderButton size="small" to={`/order-tests/${horseId}`}>
        Order Testing
      </OrderButton>
    </MissingOrder>
  );
};

export default memo(HorseOrderButtons);
