import {Reducer} from 'redux';

import {IHorseListState, HorsesActions, getHorsesActionTypes} from 'HorseProfile/store/horseList/types';
import {
  addFavoriteHorseActionTypes,
  deleteFavoriteHorseActionTypes,
  FavoriteHorsesActions,
} from 'FavoriteHorse/store/types';

const initialStateList: IHorseListState['data'] = {
  horses: [],
};

export const dataReducer: Reducer<IHorseListState['data'], HorsesActions> = (
  state = initialStateList,
  action: HorsesActions | FavoriteHorsesActions
) => {
  switch (action.type) {
    case getHorsesActionTypes.SUCCESS: {
      return {...state, horses: action.payload.data};
    }
    case addFavoriteHorseActionTypes.SUCCESS:
      return {
        ...state,
        horses: state.horses.map((horse) => ({
          ...horse,
          isFavorite: horse.id === action.payload ? true : horse.isFavorite,
        })),
      };
    case deleteFavoriteHorseActionTypes.SUCCESS:
      return {
        ...state,
        horses: state.horses.map((horse) => ({
          ...horse,
          isFavorite: horse.id === action.payload ? false : horse.isFavorite,
        })),
      };
    default: {
      return state;
    }
  }
};
