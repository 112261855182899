import React, {memo} from 'react';

import {StatusLabel} from './styled';
import {statuses, statusOrdering} from '../constants';
import {OrderStatus} from 'Common/constants/OrderStatus';
import ColoredIcon from 'Icon/components/ColoredIcon';

interface IProps {
  status: OrderStatus;
}

const IconedOrderStatus = ({status}: IProps) => {
  const currentStatus = statuses[statusOrdering[status]];

  return (
    <div className="d-flex flex-row align-items-center">
      <ColoredIcon name={currentStatus.icon} color={currentStatus.color} fill={true} stroke={false} />
      <StatusLabel>{currentStatus.label}</StatusLabel>
    </div>
  );
};

export default memo(IconedOrderStatus);
