import * as R from 'ramda';

import {statusOrdering} from 'Admin/shared/components/OrderStatus/constants';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {IEntity} from 'Common/models/IEntity';
import {IOrderStatused} from 'Common/models/IOrderStatused';

export const getWorstHorseStatus = <T extends IOrderStatused & IEntity>(horses: T[]) => {
  const horseWithWorstStatus = R.head(
    horses
      .map((item) => ({id: item.id, horse: item, order: statusOrdering[item.status]}))
      .sort((a, b) => sortByStringKey(a, b, 'order'))
  );

  return horseWithWorstStatus?.horse.status;
};
