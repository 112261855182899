import React, {memo} from 'react';
import DictionariesLayout from 'Admin/AdminReference/components/NamedEntityDictionaryLayout/NamedEntityDictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';

const Disciplines = () => {
  const {adminDiscipline} = useDictionaries();

  return <DictionariesLayout dictionary={adminDiscipline} />;
};

export default memo(Disciplines);
