import React, {memo} from 'react';
import styled from 'styled-components';

import {ModalWindowFooter, ModalWindowButton} from 'Common/components/Modal/shared';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';

const ErrorModalMessage = styled(ErrorMessage)`
  padding: 24px;
  text-align: center;
`;

interface IProps {
  error: string;
  onAccept(): void;
}

function ErrorModal(props: IProps) {
  const {error, onAccept} = props;
  return (
    <>
      <ErrorModalMessage>{error}</ErrorModalMessage>
      <ModalWindowFooter className="justify-content-center">
        <ModalWindowButton onClick={onAccept}>ok</ModalWindowButton>
      </ModalWindowFooter>
    </>
  );
}

export default memo(ErrorModal);
