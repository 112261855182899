import React, {memo, useEffect, useState} from 'react';
import styled from 'styled-components';

import ContainerWrapper from 'Common/components/Layout/ContainerWrapper';
import ColorPalette from 'Common/constants/ColorPalette';
import Placeholder1 from './PlaceHolder1';
import Placeholder2 from './PlaceHolder2';

const placeholders = [Placeholder1, Placeholder2];

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 80% 20%;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${ColorPalette.white0};
`;

const ClickableArea = styled.div<{isActive: boolean}>`
  width: 24px;
  height: 24px;
  opacity: ${props => (props.isActive ? 1 : 0.5)};

  &:hover {
    ${props => !props.isActive && 'opacity: 0.7;'}
    cursor: pointer;
  }
`;

const Container = styled(ContainerWrapper)`
  padding: 0;
`;

interface IProps {
  slideLifetime?: number;
}

function PlaceholderCarousel({slideLifetime}: IProps) {
  const [activeDotIndex, setActiveDotIndex] = useState(0);

  useEffect(() => {
    let intervalId = 0;
    if (slideLifetime) {
      intervalId = setInterval(
        () => setActiveDotIndex(activeDotIndex === placeholders.length - 1 ? 0 : activeDotIndex + 1),
        slideLifetime * 1000
      );
    }

    return () => clearInterval(intervalId);
  }, [activeDotIndex, slideLifetime]);

  const renderPlaceHolder = () => {
    const Component = placeholders[activeDotIndex];
    return Component ? <Component /> : null;
  };

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Root className="flex-grow-1">
        {renderPlaceHolder()}
        <div className="d-flex justify-content-center">
          {placeholders.map((item, idx) => {
            const isActive = idx === activeDotIndex;
            const onDotClick = () => {
              setActiveDotIndex(idx);
            };

            return (
              <div key={idx}>
                <ClickableArea
                  className="d-flex justify-content-center align-items-center"
                  isActive={isActive}
                  onClick={onDotClick}
                >
                  <Dot />
                </ClickableArea>
              </div>
            );
          })}
        </div>
      </Root>
    </Container>
  );
}

export default memo(PlaceholderCarousel);
