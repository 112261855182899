import {combineReducers, Reducer} from 'redux';

import {communicationUserPrivacySettingsReducer} from './communications';
import {dataUserPrivacySettingsReducer} from './data';
import {IUserPrivacySettingsState} from '../types';

export const userPrivacySettingsReducer: Reducer<IUserPrivacySettingsState> =
  combineReducers<IUserPrivacySettingsState>({
    communications: communicationUserPrivacySettingsReducer,
    data: dataUserPrivacySettingsReducer,
  });
