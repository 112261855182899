import * as React from 'react';
import {Redirect, Switch, useLocation} from 'react-router-dom';
import {Location} from 'history';

import AppRoute from './AppRoute/AppRoute';
import {ILocationState} from 'Common/models/ILocationState';
import {ADMIN_NAVIGATION_ROUTES, COMMON_NAVIGATION_ROUTES} from 'Common/components/Navigation';
import AdminWelcomePage from 'Admin/common/AdminWelcomePage';

function AppAuthRoutingAdmin() {
  const location: Location<ILocationState> = useLocation();
  const {from} = location.state || {from: {pathname: '/admin-welcome'}};

  return (
    <Switch>
      <AppRoute path="/admin-welcome" component={AdminWelcomePage} />
      {COMMON_NAVIGATION_ROUTES.map((route, i) => (
        <AppRoute key={i} path={route.value} component={route.Component} {...route} />
      ))}
      {ADMIN_NAVIGATION_ROUTES.map((route, i) => (
        <AppRoute key={i} path={route.value} component={route.Component} {...route} />
      ))}
      <Redirect to={from} />
    </Switch>
  );
}

export default AppAuthRoutingAdmin;
