import React, {memo, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import {CSSObjectWithLabel} from 'react-select/dist/declarations/src/types';

import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ColorPalette from 'Common/constants/ColorPalette';
import {Divider} from 'Order/components/CreateOrder/common/styled';
import {IOrderHorse} from 'Order/models/IOrderHorse';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import {FieldArray, FieldArrayRenderProps} from 'formik';
import {castToOption} from 'Common/helpers/OptionHelper';
import {sortByName} from 'Common/helpers/sortByName';
import {SelectField} from 'Common/components/FormFields';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IHorseTest} from 'Order/models/IHorseTest';

const selectStyles = {
  placeholder: () => ({
    color: ColorPalette.black1,
  }),
  control: (base: CSSObjectWithLabel, state: any) => ({
    border: state.isFocused || state.hasValue ? `1px solid ${Theme.color.primary}` : `1px solid ${ColorPalette.black1}`,
    ':hover': {
      borderColor: state.isFocused || state.hasValue ? Theme.color.primary : ColorPalette.black1,
    },
  }),
};

const Root = styled.div`
  width: 90%;
  min-height: 400px;
  max-height: 600px;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  box-shadow: 0 4px 11px 0 ${ColorPalette.gray1};
  background-color: ${ColorPalette.white0};
  z-index: 1;
`;

const DoneButton = styled(PrimaryButton)`
  min-width: 80px;
`;

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.div`
  font-family: ${Theme.font.secondary};
  font-size: ${Typography.size.size18};
  font-weight: ${Typography.weight.semiBold600};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${ColorPalette.black1};
`;

const SelectWrapper = styled.div`
  margin-top: 16px;
`;

const CartIcon = styled(ColoredIcon)`
  margin-right: 8px;
`;

const RemoveButtonHorseWrapper = styled.div`
  padding-top: 10px;
  display: block;
`;

const RemoveButton = styled(IconButton)`
  bottom: 30px;
  margin-left: 16px;
`;

const AddHorseButton = styled(PrimaryButton)`
  margin-top: 24px;
`;

interface IProps {
  packageId: number;
  horses: IOrderHorse[];
  tests: IHorseTest[];
  onSuccess(): void;
}

function HorsesCard(props: IProps) {
  const {onSuccess, horses, tests, packageId} = props;

  const uniqueHorses = useMemo(
    () =>
      castToOption(
        horses
          .map((horse) =>
            tests.find(
              (t) =>
                t.packageId === packageId && t.horses.length > 0 && t.horses.findIndex((x) => x === horse.id) !== -1
            )
              ? {...horse, isInvisible: true}
              : horse
          )
          .sort(sortByName)
      ),
    [horses, packageId, tests]
  );

  const filteredTests = useMemo(() => {
    return tests.find((x) => x.packageId === packageId);
  }, [packageId, tests]);

  const recordIndex = tests.findIndex((x) => x.packageId === packageId);
  const isHorsesSelect = tests[recordIndex].horses.filter((x) => x !== 0).length > 0;

  const renderRemoveButton = useCallback((arrayHelper: FieldArrayRenderProps, index: number) => {
    return (
      <RemoveButton
        onClick={() => arrayHelper.remove(index)}
        name={IconName.Clear}
        color={Theme.color.primary}
        size={14}
        fill={true}
        stroke={false}
        hoverColor={Theme.color.control.active}
      />
    );
  }, []);

  const onDoneClick = useCallback(() => {
    onSuccess();
  }, [onSuccess]);

  return (
    <Root className="d-flex flex-column justify-content-between position-absolute">
      <div>
        <div>
          <Container className="d-flex align-items-center">
            <CartIcon name={IconName.ShoppingCartAdd} color={Theme.color.primary} stroke={false} fill={true} />
            <Title>Add to cart for:</Title>
            <IconButton
              className="ml-auto"
              name={IconName.Close}
              onClick={onDoneClick}
              color={ColorPalette.gray31}
              hoverColor={ColorPalette.black2}
              fill={true}
              stroke={false}
              size={16}
            />
          </Container>

          <Divider />
        </div>

        <Container className="d-flex flex-column" style={{paddingTop: 0}}>
          <Scrollbar minHeight={250} maxHeight={370}>
            <FieldArray
              name={`tests[${recordIndex}].horses`}
              render={(arrayHelper) => (
                <div className="flex-column">
                  {filteredTests?.horses &&
                    filteredTests?.horses.length > 0 &&
                    filteredTests?.horses.map((_, index, arr) => (
                      <React.Fragment key={index}>
                        <SelectWrapper className="d-flex">
                          <div className="w-100">
                            <SelectField
                              style={{marginBottom: 0}}
                              name={`tests[${recordIndex}].horses[${index}]`}
                              options={uniqueHorses}
                              isRequired={true}
                              menuPortalTarget={document.body}
                              placeholder="Select Horse"
                              styles={selectStyles}
                            />
                          </div>
                          <RemoveButtonHorseWrapper className="align-self-start">
                            {renderRemoveButton(arrayHelper, index)}
                          </RemoveButtonHorseWrapper>
                        </SelectWrapper>
                      </React.Fragment>
                    ))}
                  <AddHorseButton size="small" variant="outlined" onClick={() => arrayHelper.push(0)}>
                    + Additional Horses
                  </AddHorseButton>
                </div>
              )}
            />
          </Scrollbar>
        </Container>
      </div>

      <div>
        <Divider />
        <Container>
          <DoneButton onClick={onDoneClick} size="small" disabled={!isHorsesSelect}>
            {/* variant={isHorsesSelect ? 'contained' : 'outlined'}> */}
            Done
          </DoneButton>
        </Container>
      </div>
    </Root>
  );
}

export default memo(HorsesCard);
