import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IUserOrdersState, getUserOrdersActions, getUserOrdersActionTypes} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import UserOrderDataService from 'UserProfile/services/UserOrderDataService';
import {IUserOrderRequest} from 'UserProfile/services/serverModels/IUserOrderRequest';

export const getUserOrders = (
  params: IUserOrderRequest
): ActionResult<IUserOrdersState, void, getUserOrdersActions> => async dispatch => {
  try {
    dispatch(action(getUserOrdersActionTypes.REQUEST));
    const userOrders = await UserOrderDataService.getUserOrders(params);
    dispatch(action(getUserOrdersActionTypes.SUCCESS, userOrders));
  } catch (error) {
    dispatch(action(getUserOrdersActionTypes.FAILURE, getErrorMessage(error)));
  }
};
