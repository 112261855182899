import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IRequestParams} from 'Common/models/IRequestParams';

import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {
  BusinessPortalHorseActions,
  createHorseActions,
  createHorseActionTypes,
  createHorseImageMediaActions,
  createHorseImageMediaActionTypes,
  createHorseVideoMediaActions,
  createHorseVideoMediaActionTypes,
  deleteHorseActions,
  deleteHorseActionTypes,
  deleteHorseAvatarActions,
  deleteHorseAvatarActionTypes,
  deleteHorseMediaActions,
  deleteHorseMediaActionTypes,
  getHorseDetailsActions,
  getHorseDetailsActionTypes,
  getHorseHealthIssuesActions,
  getHorseHealthIssuesActionTypes,
  getHorseMediaActions,
  getHorseMediaActionTypes,
  getHorseOwnerActions,
  getHorseOwnerActionTypes,
  getHorseParentageActions,
  getHorseParentageActionTypes,
  getHorseRegistriesActions,
  getHorseRegistriesActionTypes,
  getHorsesActions,
  getHorsesActionTypes,
  IHorseProfileTypeRequest,
  resetHorseAncestryProfileTypeActions,
  resetHorseAncestryProfileTypeActionTypes,
  resetHorseDetailsActionTypes,
  resetHorseHealthIssuesActionTypes,
  resetHorseMediaActionTypes,
  resetHorseOwnerActionTypes,
  resetHorseParentageActionTypes,
  resetHorseProfileTypeActions,
  resetHorseProfileTypeActionTypes,
  resetHorseRegistriesActionTypes,
  resetHorsesActionTypes,
  setHorseOwnerActions,
  setHorseOwnerActionTypes,
  updateHorseAncestryProfileTypeActionTypes,
  updateHorseAncsetryProfileTypeActions,
  updateHorseAvatarActions,
  updateHorseAvatarActionTypes,
  updateHorseDetailsActions,
  updateHorseDetailsActionTypes,
  updateHorseHealthIssuesActions,
  updateHorseHealthIssuesActionTypes,
  updateHorseParentageActions,
  updateHorseParentageActionTypes,
  updateHorseProfileTypeActions,
  updateHorseProfileTypeActionTypes,
  updateHorseRegistriesActions,
  updateHorseRegistriesActionTypes,
} from './types';
import BusinessPortalService from 'BusinessPortal/services/BusinessPortalHorseService';
import {IUpdateHorseDetailsBP} from 'BusinessPortal/models/horse/IHorseDetailsBP';
import {IUpdateHorseParentageBP} from 'BusinessPortal/models/horse/IHorseParentageBP';
import {IUpdateHorseRegistriesBP} from 'BusinessPortal/models/horse/IHorseRegistriesBP';
import {IUpdateHorseInconclusiveHealthIssuesBP} from 'BusinessPortal/models/horse/IInconclusiveHealthIssuesBP';
import {IVideo} from 'Video/models/IVideo';
import {IMediaResource} from 'Common/models/IMediaResource';
import {ICreateHorseBP} from 'BusinessPortal/models/horse/IHorseBP';

export const getHorses =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getHorsesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorsesActionTypes.REQUEST));
      const horses = await BusinessPortalService.getHorses(params);
      dispatch(action(getHorsesActionTypes.SUCCESS, horses));
    } catch (error) {
      dispatch(action(getHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createHorse =
  (data: ICreateHorseBP): ActionResult<IAppState, Promise<number>, createHorseActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createHorseActionTypes.REQUEST));
      const horseId = await BusinessPortalService.createHorse(data);
      dispatch(action(createHorseActionTypes.SUCCESS));
      return horseId;
    } catch (error) {
      const err = getErrorMessage(error);
      dispatch(action(createHorseActionTypes.FAILURE, err));
      throw err;
    }
  };

export const getHorseDetails =
  (horseId: number): ActionResult<IAppState, Promise<void>, getHorseDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseDetailsActionTypes.REQUEST));
      const data = await BusinessPortalService.getHorseDetails(horseId);
      dispatch(action(getHorseDetailsActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getHorseDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseOwner =
  (horseId: number): ActionResult<IAppState, Promise<void>, getHorseOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseOwnerActionTypes.REQUEST));
      const data = await BusinessPortalService.getHorseOwner(horseId);
      dispatch(action(getHorseOwnerActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getHorseOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseParentage =
  (horseId: number): ActionResult<IAppState, Promise<void>, getHorseParentageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseParentageActionTypes.REQUEST));
      const data = await BusinessPortalService.getHorseParentage(horseId);
      dispatch(action(getHorseParentageActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getHorseParentageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseRegistries =
  (horseId: number): ActionResult<IAppState, Promise<void>, getHorseRegistriesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseRegistriesActionTypes.REQUEST));
      const data = await BusinessPortalService.getHorseRegistries(horseId);
      dispatch(action(getHorseRegistriesActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getHorseRegistriesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseHealthIssues =
  (horseId: number): ActionResult<IAppState, Promise<void>, getHorseHealthIssuesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseHealthIssuesActionTypes.REQUEST));
      const data = await BusinessPortalService.getHorseHealthIssues(horseId);
      dispatch(action(getHorseHealthIssuesActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getHorseHealthIssuesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateHorseDetails =
  (data: IUpdateHorseDetailsBP): ActionResult<IAppState, Promise<void>, updateHorseDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateHorseDetailsActionTypes.REQUEST));
      await BusinessPortalService.updateHorseDetails(data);
      dispatch(action(updateHorseDetailsActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateHorseDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setHorseOwner =
  (horseId: number, horseOwnerId: number): ActionResult<IAppState, Promise<void>, setHorseOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(setHorseOwnerActionTypes.REQUEST));
      await BusinessPortalService.setHorseOwner(horseId, horseOwnerId);
      dispatch(action(setHorseOwnerActionTypes.SUCCESS));
    } catch (error) {
      const err = getErrorMessage(error);
      dispatch(action(setHorseOwnerActionTypes.FAILURE, err));
    }
  };

export const updateHorseParentage =
  (data: IUpdateHorseParentageBP): ActionResult<IAppState, Promise<void>, updateHorseParentageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateHorseParentageActionTypes.REQUEST));
      await BusinessPortalService.updateHorseParentage(data);
      dispatch(action(updateHorseParentageActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateHorseParentageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateHorseRegistries =
  (data: IUpdateHorseRegistriesBP): ActionResult<IAppState, Promise<void>, updateHorseRegistriesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateHorseRegistriesActionTypes.REQUEST));
      await BusinessPortalService.updateHorseRegistries(data);
      dispatch(action(updateHorseRegistriesActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateHorseRegistriesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateHorseHealthIssues =
  (
    data: IUpdateHorseInconclusiveHealthIssuesBP
  ): ActionResult<IAppState, Promise<void>, updateHorseHealthIssuesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateHorseHealthIssuesActionTypes.REQUEST));
      await BusinessPortalService.updateHorseHealthIssues(data);
      dispatch(action(updateHorseHealthIssuesActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateHorseHealthIssuesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateHorseAvatar =
  (horseId: number, uploadedFile: File): ActionResult<IAppState, Promise<void>, updateHorseAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateHorseAvatarActionTypes.REQUEST));
      await BusinessPortalService.updateHorseAvatar(horseId, uploadedFile);
      dispatch(action(updateHorseAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateHorseAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteHorse =
  (horseId: number): ActionResult<IAppState, void, deleteHorseActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteHorseActionTypes.REQUEST));
      await BusinessPortalService.deleteHorse(horseId);
      dispatch(action(deleteHorseActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteHorseAvatar =
  (horseId: number): ActionResult<IAppState, Promise<void>, deleteHorseAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteHorseAvatarActionTypes.REQUEST));
      await BusinessPortalService.deleteHorseAvatar(horseId);
      dispatch(action(deleteHorseAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteHorseAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseMedia =
  (horseId: number): ActionResult<IAppState, Promise<void>, getHorseMediaActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseMediaActionTypes.REQUEST));
      const data = await BusinessPortalService.getHorseMedia(horseId);
      dispatch(action(getHorseMediaActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getHorseMediaActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createHorseImageMedia =
  (horseId: number, images: IMediaResource[]): ActionResult<IAppState, Promise<void>, createHorseImageMediaActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createHorseImageMediaActionTypes.REQUEST));
      const files = images.filter((x) => x.file && x.file !== undefined).map((img) => img.file!);
      await BusinessPortalService.createHorseImageMedia(horseId, files);
      dispatch(action(createHorseImageMediaActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createHorseImageMediaActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createHorseVideoMedia =
  (horseId: number, video: IVideo): ActionResult<IAppState, Promise<void>, createHorseVideoMediaActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createHorseVideoMediaActionTypes.REQUEST));
      await BusinessPortalService.createHorseVideoMedia(horseId, video);
      dispatch(action(createHorseVideoMediaActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createHorseVideoMediaActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteHorseMedia =
  (horseId: number, mediaIds: number[]): ActionResult<IAppState, Promise<void>, deleteHorseMediaActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteHorseMediaActionTypes.REQUEST));
      await BusinessPortalService.deleteHorseMedia(horseId, mediaIds);
      dispatch(action(deleteHorseMediaActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteHorseMediaActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateHorseProfileType =
  (params: IHorseProfileTypeRequest): ActionResult<IAppState, void, updateHorseProfileTypeActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateHorseProfileTypeActionTypes.REQUEST));
      await BusinessPortalService.updateHorseProfileType(params);
      const result = params.profileType;
      dispatch(action(updateHorseProfileTypeActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(updateHorseProfileTypeActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateHorseAncestryProfileType =
  (params: IHorseProfileTypeRequest): ActionResult<IAppState, void, updateHorseAncsetryProfileTypeActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateHorseAncestryProfileTypeActionTypes.REQUEST));
      await BusinessPortalService.updateHorseAncestryProfileType(params);
      const result = params.profileType;
      dispatch(action(updateHorseAncestryProfileTypeActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(updateHorseAncestryProfileTypeActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetHorseMedia = (): ActionResult<IAppState, void, BusinessPortalHorseActions> => (dispatch) => {
  dispatch(action(resetHorseMediaActionTypes.RESET));
};

export const resetHorseDetails = (): ActionResult<IAppState, void, BusinessPortalHorseActions> => (dispatch) => {
  dispatch(action(resetHorseDetailsActionTypes.RESET));
};

export const resetHorseOwner = (): ActionResult<IAppState, void, BusinessPortalHorseActions> => (dispatch) => {
  dispatch(action(resetHorseOwnerActionTypes.RESET));
};

export const resetHorseParentage = (): ActionResult<IAppState, void, BusinessPortalHorseActions> => (dispatch) => {
  dispatch(action(resetHorseParentageActionTypes.RESET));
};

export const resetHorseRegistries = (): ActionResult<IAppState, void, BusinessPortalHorseActions> => (dispatch) => {
  dispatch(action(resetHorseRegistriesActionTypes.RESET));
};

export const resetHorseHealthIssues = (): ActionResult<IAppState, void, BusinessPortalHorseActions> => (dispatch) => {
  dispatch(action(resetHorseHealthIssuesActionTypes.RESET));
};

export const resetHorses = (): ActionResult<IAppState, void, BusinessPortalHorseActions> => (dispatch) => {
  dispatch(action(resetHorsesActionTypes.RESET));
};

export const resetHorseProfileTypeResults =
  (): ActionResult<IAppState, void, resetHorseProfileTypeActions> => (dispatch) => {
    dispatch(action(resetHorseProfileTypeActionTypes.RESET));
  };

export const resetHorseAncestryProfileTypeResults =
  (): ActionResult<IAppState, void, resetHorseAncestryProfileTypeActions> => (dispatch) => {
    dispatch(action(resetHorseAncestryProfileTypeActionTypes.RESET));
  };
