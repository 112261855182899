import styled from 'styled-components';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';

export const Label = styled.label`
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.gray3};
  margin-bottom: 8px;
`;

export const PanelLabel = styled.div`
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.gray3};
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const SidebarLabel = styled.label`
  font-size: ${Typography.size.size15};
  color: ${ColorPalette.gray3};
  line-height: 24px;
  margin-bottom: 10px;
`;

export const SubTitle = styled.div`
  font-size: ${Typography.size.size19};
  font-weight: ${Typography.weight.medium500};
  color: ${ColorPalette.black1};
  margin-bottom: 10px;
`;

export const TextInfo = styled.div`
  font-size: ${Typography.size.size13};
  color: ${ColorPalette.black1};
  max-width: 293px;
`;

export const SidebarComponent = styled.div`
  margin-bottom: 24px;
  background-color: ${ColorPalette.white0};
  box-shadow: 0px 6px 14px ${ColorPalette.transparent5};
  border-radius: 5px;
  padding: 16px 20px 0 20px;
`;

export const BorderedBlock = styled.div`
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid ${ColorPalette.gray37};
  border-radius: 10px;
`;
