import React, {memo, useMemo} from 'react';
import styled from 'styled-components';

import {IAssociation} from 'Dictionaries/models/IAssociation';
import {InputField, SelectField} from 'Common/components/FormFields/index';
import {castToOption} from 'Common/helpers/OptionHelper';
import {Icon, IconedField} from 'Common/components/StyledComponents/StyledComponents';
import {breakpoints} from 'Common/constants/Breakpoints';

const regNumberInputStyles = {textIndent: 15};

const FieldsContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.sm} {
    flex-direction: row;
    > :first-child {
      margin-right: 18px;
    }
  }
`;

const FieldWrapper = styled.div`
  width: 100%;
`;

interface IRegistryProps {
  index: number;
  associations: IAssociation[];
  fieldName: string;
  selectedAssociationIds: number[];
  selectedId: number | null;
}

const Registry = (props: IRegistryProps) => {
  const {index, associations, fieldName, selectedAssociationIds, selectedId} = props;

  const associationsOptions = useMemo(
    () => castToOption(associations.filter(a => a.id === selectedId || !selectedAssociationIds.includes(a.id))),
    [associations, selectedAssociationIds, selectedId]
  );

  return (
    <FieldsContainer className="d-flex justify-content-between">
      <FieldWrapper>
        <SelectField
          name={`${fieldName}[${index}].associationId`}
          label="Registry name"
          options={associationsOptions}
        />
      </FieldWrapper>
      <FieldWrapper>
        <IconedField>
          <InputField
            inputStyle={regNumberInputStyles}
            name={`${fieldName}[${index}].registrationNumber`}
            type="text"
            label="Reg number"
            placeholder="Reg number"
            memoized={true}
          />
          <Icon>#</Icon>
        </IconedField>
      </FieldWrapper>
    </FieldsContainer>
  );
};

export default memo(Registry);
