import {combineReducers, Reducer} from 'redux';

import {dataForgotPasswordReducer} from './data';
import {communicationForgotPasswordReducer} from 'ForgotPassword/store/reducer/communications';
import {IForgotPasswordState} from 'ForgotPassword/store/types';

export const forgotPasswordReducer: Reducer<IForgotPasswordState> = combineReducers<IForgotPasswordState>({
  communications: communicationForgotPasswordReducer,
  data: dataForgotPasswordReducer
});
