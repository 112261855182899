import React, {useEffect, useMemo} from 'react';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps} from 'formik/dist/types';

import {CheckboxGroupField} from 'Common/components/FormFields';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';

import {actions, selectors} from 'BusinessPortal/store/horse';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {Checkbox} from 'Common/components/Controls';
import {
  IFormValues,
  initialValue,
  validationSchema
} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseHealthInfoSection/validation';
import {convertToClient} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseHealthInfoSection/converters';
import {IHorseHealthInfoSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseHealthInfoSection/models/IHorseHealthInfoSectionData';

type IConnected = ConnectedProps<typeof connector>;

type IExternalProps = IEditableSection<IHorseHealthInfoSectionData>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const HorseHealthInfoSectionEdit = (props: AllProps) => {
  const {isValid, resetForm, isSubmitting, values} = props;
  const {onSuccess, horseHealthIssuesUpdating, data} = props;

  useEffect(() => {
    return () => resetForm();
  }, [resetForm]);

  const isRequesting = horseHealthIssuesUpdating.isRequesting;

  useOnSuccessCommunication(horseHealthIssuesUpdating, () => onSuccess && onSuccess());

  const checkboxes = useMemo(() => {
    return data!.inconclusiveHealthIssues.map(issue1 => {
      const isChecked = !!values.inconclusiveHealthIssues?.find(issue2 => issue1.id === issue2);
      return <Checkbox key={issue1.id} name={String(issue1.id)} checked={isChecked} label={issue1.name} />;
    });
  }, [data, values.inconclusiveHealthIssues]);

  if (isRequesting) {
    return <Loading />;
  }

  return (
    <Form>
      <CheckboxGroupField name="inconclusiveHealthIssues">{checkboxes}</CheckboxGroupField>
      <PrimaryButton className="w-100" size="medium" type="submit" disabled={!isValid} isLoading={isSubmitting}>
        Save changes
      </PrimaryButton>
    </Form>
  );
};

const HorseHealthInfoSectionEditFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({data}) => (data ? convertToClient(data) : initialValue),
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {updateHorseHealthIssues} = formikBag.props;
    await updateHorseHealthIssues(values);
  },
  enableReinitialize: true
})(HorseHealthInfoSectionEdit);

const mapStateToProps = (state: IAppState) => ({
  horseHealthIssuesUpdating: selectors.selectCommunication(state, 'horseHealthIssuesUpdating')
});

const mapDispatchToProps = {
  updateHorseHealthIssues: actions.updateHorseHealthIssues
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(HorseHealthInfoSectionEditFormik);
