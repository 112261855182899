import React, {memo} from 'react';

import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import DictionaryLayout from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import {INamedEntity} from 'Common/models/INamedEntity';
import {IDictionary} from 'DictionaryFactory/types/dictionary';
import DictionaryFormLayout from 'Admin/AdminReference/components/NamedEntityDictionaryLayout/NamedEntityDictionaryFormLayout/NamedEntityDictionaryFormLayout';

interface IProps {
  dictionary: IDictionary<INamedEntity, INamedEntity>;
}

const NamedEntityDictionaryLayout = ({dictionary}: IProps) => {
  return (
    <DictionaryLayout dictionary={dictionary} DictionaryForm={DictionaryFormLayout}>
      {({onDelete, onEdit}) => (
        <>
          <Cell<INamedEntity> header="ID" dataKey="id" render={({id}) => `#${id}`} width={100} />
          <Cell<INamedEntity> header="Name" dataKey="name" render={({name}) => name} width="80%" />
          <Cell<INamedEntity>
            header="Actions"
            align={CellAlign.Right}
            render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            width={100}
          />
        </>
      )}
    </DictionaryLayout>
  );
};

export default memo(NamedEntityDictionaryLayout);
