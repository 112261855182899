import {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import BackButton from 'Common/components/Controls/Buttons/BackButton';
import {breakpoints} from 'Common/constants/Breakpoints';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {IHorseTest} from 'Order/models/IHorseTest';
import {IOrderHorse} from 'Order/models/IOrderHorse';
import {IOrderPackage} from 'Order/models/IOrderPackage';
import {TestSelectionActionProps} from '../TestsSelection';
import OrderPackageCard from './OrderPackageCard/OrderPackageCard';
import OrderPackageContainer from './OrderPackageContainer';
import SearchBarCategory from './SearchBarCategory';
import {sortBySorter} from 'Common/helpers/Sorter/sortBySorter';
import {packageSorter} from 'Order/constants/PackageSorter';

const ContainerWrapper = styled.div`
  padding-top: 32px;
  @media ${breakpoints.sm} {
    padding-top: 0;
  }
`;

const Title = styled.div`
  margin-top: 16px;
`;

const TitlePartOne = styled.span`
  font-family: ${Theme.font.secondary};
  font-size: ${Typography.size.size24};
  font-weight: ${Typography.weight.semiBold600};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${ColorPalette.gray3};
`;

const TitlePartTwo = styled(TitlePartOne)`
  color: ${ColorPalette.black1};
`;

const BackButtonWrapper = styled(BackButton)`
  left: -20px;
  top: 70px;
`;

interface IProps {
  orderPackages: IOrderPackage[];
  categoryName: string;
  horses: IOrderHorse[];
  tests: IHorseTest[];
  onBackButtonClick(): void;
}

type AllProps = IProps & TestSelectionActionProps;

function CategoryView(props: AllProps) {
  const {onBackButtonClick, categoryName, orderPackages, horses, tests} = props;
  const {onSuccess, onOpenCard} = props;

  const [sortedOrderPackages, setSortedOrderPackages] = useState<IOrderPackage[]>([]);
  const [filteredOrderPackages, setFilteredOrderPackages] = useState<IOrderPackage[]>([]);

  useEffect(() => {
    const sorted = sortBySorter<IOrderPackage>(packageSorter, orderPackages);
    setSortedOrderPackages(sorted);
    setFilteredOrderPackages(sorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchSuccess = useCallback((filterOrderPackages: IOrderPackage[]) => {
    setFilteredOrderPackages(filterOrderPackages);
  }, []);

  const renderTitle = (
    <Title>
      <TitlePartOne>All Tests / </TitlePartOne>
      <TitlePartTwo>{categoryName}</TitlePartTwo>
    </Title>
  );

  return (
    <div className="position-relative">
      <SearchBarCategory orderPackagesForFilter={sortedOrderPackages} onSuccess={onSearchSuccess} />

      <BackButtonWrapper className="position-absolute" onClick={onBackButtonClick} />

      <ContainerWrapper>
        <OrderPackageContainer title={renderTitle}>
          {filteredOrderPackages.map((orderPackage, i) => {
            return (
              <OrderPackageCard
                key={i}
                orderPackage={orderPackage}
                isDetailShow={true}
                horses={horses}
                tests={tests}
                onOpenCard={onOpenCard}
                onSuccess={onSuccess}
              />
            );
          })}
        </OrderPackageContainer>
      </ContainerWrapper>
    </div>
  );
}

export default memo(CategoryView);
