import React, {useEffect} from 'react';
import {Form, FormikProps} from 'formik';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader
} from 'Common/components/Modal/shared/index';
import Loading from 'Loading/components/Loading';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {ICommunication} from 'Common/store/utils/communication/types';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {FormType} from 'Common/constants/FormType';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {memoWithGeneric} from 'Common/helpers/react/memoWithGeneric';

const FORM_FIELDS_ERROR = `There are some errors at form's fields`;

const formHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add item',
  edit: 'Edit item'
};

interface IOwnProps {
  type: FormType;
  onSuccess(): void;
  children: React.ReactNode;
  itemLoading: ICommunication;
  itemCreating: ICommunication;
  itemUpdating: ICommunication;
  itemPreloading?: boolean;
}

function DictionaryForm<T>(props: FormikProps<T> & IOwnProps) {
  const {status, setStatus, setErrors, isSubmitting, children, itemPreloading} = props;
  const {type, onSuccess} = props;
  const {itemLoading, itemCreating, itemUpdating} = props;
  const errorInfo = itemLoading.error || itemCreating.error || itemUpdating.error;
  const isPreloading = itemLoading.isRequesting || itemPreloading;

  const header = formHeaderByType[type];

  useOnSuccessCommunication(itemCreating, onSuccess);
  useOnSuccessCommunication(itemUpdating, onSuccess);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    if (commonErrors) {
      setStatus(commonErrors);
    }

    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  const isValid = props.submitCount === 0 || props.isValid;

  const isFormSubmitting = itemCreating.isRequesting || itemUpdating.isRequesting || isSubmitting;

  const isDisabledSubmit = !isValid || isPreloading;

  const validationFieldError = !isValid ? FORM_FIELDS_ERROR : '';

  return (
    <>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          <>
            {(isPreloading || isFormSubmitting) && <Loading />}
            {children}
          </>
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{validationFieldError || status}</ErrorMessage>
          <ModalWindowButton type="submit" disabled={isDisabledSubmit} isLoading={isFormSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

export default memoWithGeneric(DictionaryForm);
