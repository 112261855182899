import React, {useCallback, useEffect, useState} from 'react';
import * as R from 'ramda';
import {useFormikContext} from 'formik';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import AssociationCreateUserDiscountApplyingForm from 'Shared/components/AssociationCreateOrderDiscount/AssociationCreateUserDiscountApplyingForm';
import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';
import {getCouponError} from 'Admin/AdminAssociations/components/Orders/CreateUserOrder/validation';
import {getErrorCode} from 'Common/helpers/ErrorHelper';
import {CouponError} from 'Order/services/constants/CouponError';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {IHorseOrder} from 'BusinessPortal/models/order/createOrder/IHorseOrder';
import {PaymentMethod} from 'Common/constants/PaymentMethod';
import {useToast} from 'Common/helpers/hooks/useToast';
import {ICheckCouponRequest} from 'BusinessPortal/services/types/coupon';
import {ICommunication} from 'Common/store/utils/communication';
import {Nullable} from 'Common/types';

export interface IUseAssociationCreateOrderDiscountFormValues {
  organizationId?: number;
  isPaid?: boolean;
  orders: IHorseOrder[];
  coupons: IOrderCoupon[];
  paymentMethod: PaymentMethod;
}

interface IProps {
  removeCoupon(): void;
  horses: IOrderHorse[];
  coupon: Nullable<IOrderCoupon>;
  couponLoading: ICommunication;
  getCoupon(request: ICheckCouponRequest, organizationId?: number): Promise<IOrderCoupon>;
}

export function useAssociationCreateOrderDiscount(props: IProps) {
  const {removeCoupon, horses, getCoupon, coupon, couponLoading} = props;
  const {setFieldValue, values} = useFormikContext<IUseAssociationCreateOrderDiscountFormValues>();

  const {addToast} = useToast();

  const [couponError, setCouponError] = useState<string | null>(null);

  const [isOpenDiscountFormType, setOpenDiscountFormType] = useState<string>('create');

  const [currentCoupon, setCurrentCoupon] = useState<IOrderCoupon>();
  const [isDiscountFormOpen, setDiscountFormOpen] = useState<boolean>(false);
  const openDiscountForm = useCallback((coupon?: IOrderCoupon, type?: 'create' | 'edit') => {
    setCurrentCoupon(coupon);
    type && setOpenDiscountFormType(type);
    setDiscountFormOpen(true);
  }, []);
  const closeDiscountForm = useCallback(() => {
    setDiscountFormOpen(false);
    removeCoupon();
    setCurrentCoupon(undefined);
  }, [removeCoupon]);

  const onSuccessDiscountForm = useCallback(
    (horses: number[]) => {
      closeDiscountForm();
      if (!currentCoupon) {
        return;
      }

      if (horses.length === 0) {
        setFieldValue(
          'coupons',
          values.coupons.filter((i) => i.id !== currentCoupon.id)
        );
        return;
      }

      const operatingCoupon = {...currentCoupon, horses};
      let oldCoupons =
        isOpenDiscountFormType === 'create'
          ? values.coupons
          : values.coupons.filter((coupon) => coupon.id !== operatingCoupon.id);
      const horsesAlreadyWithCoupons: number[] = [].concat(
        ...(oldCoupons.map((i) => R.intersection(i.horses?.map((i) => i) || [], horses)) as any)
      );

      if (horsesAlreadyWithCoupons.length !== 0) {
        const oldCouponsWithDeletedHorses = oldCoupons.map((coupon) => ({
          ...coupon,
          horses: coupon.horses?.filter((i) => horses.every((j) => j !== i)),
        }));
        const excludedCouponsWithoutHorses = oldCouponsWithDeletedHorses.filter(
          (coupon) => coupon.horses?.length !== 0
        );
        oldCoupons = excludedCouponsWithoutHorses;
      }

      setFieldValue('coupons', oldCoupons.concat(operatingCoupon));
      // setFieldValue('orders', values.orders.map(i => horses.includes(i.horseId) ? {...i, couponId: currentCoupon.id} : i));
    },
    [closeDiscountForm, currentCoupon, isOpenDiscountFormType, setFieldValue, values.coupons]
  );

  const couponLoadingError = useCallback(() => {
    if (couponLoading.error) {
      const couponErrorText = getCouponError(couponLoading.error);
      addToast(couponErrorText, 'error');
      setCouponError(couponErrorText);
    }
    if (coupon && couponLoading.error && getErrorCode(couponLoading.error) === CouponError.CannotBeAppliedCoupon) {
      return;
    }
    removeCoupon();
  }, [coupon, couponLoading.error, removeCoupon, addToast]);

  useOnErrorCommunication(couponLoading, couponLoadingError);
  useOnSuccessCommunication(couponLoading, () => setCouponError(null));

  const checkCode = useCallback(
    async (code: string) => {
      const uniqSelectedPackages = values.orders
        .reduce((acc, curr) => acc.concat(curr.packages || []), [] as number[])
        .filter((value, idx, self) => self.indexOf(value) === idx);
      const uniqSelectedTests = values.orders
        .reduce((acc, curr) => acc.concat(curr.tests || []), [] as number[])
        .filter((value, idx, self) => self.indexOf(value) === idx);

      try {
        const coupon = await getCoupon(
          {
            code,
            tests: uniqSelectedTests,
            packages: uniqSelectedPackages,
          },
          values.organizationId
        );

        const couponWithoutTestsAndPackages = coupon.packages.length === 0 && coupon.tests.length === 0;
        if (couponWithoutTestsAndPackages) {
          addToast(`The coupon doesn't contain packages and tests`, 'error');
          return;
        }

        openDiscountForm(coupon, 'create');
      } catch (err) {
        return;
      }
    },
    [addToast, getCoupon, openDiscountForm, values.orders, values.organizationId]
  );

  const removeCouponFromOrder = useCallback(
    (couponId: number) => {
      setFieldValue(
        'coupons',
        values.coupons.filter((i) => i.id !== couponId)
      );

      setCouponError(null);
    },
    [setFieldValue, values.coupons]
  );

  // Remove a horse from the coupon
  // if the coupon has already been applied and horse has been removed from common horses list
  useEffect(() => {
    values.coupons.forEach((coupon) => {
      const deletedHorses = R.difference(
        coupon.horses || [],
        values.orders.map((i) => i.horseId)
      );
      if (deletedHorses.length > 0) {
        setFieldValue(
          'coupons',
          values.coupons
            .map((i) =>
              i.id === coupon.id ? {...i, horses: i.horses?.filter((j) => deletedHorses.some((k) => k !== j))} : i
            )
            .filter((i) => i.horses?.length && i.horses.length > 0)
        );
      }
    });
  }, [setFieldValue, values.coupons, values.orders]);

  const discountModal = (
    <ModalWindow isOpen={isDiscountFormOpen} onClose={closeDiscountForm}>
      <AssociationCreateUserDiscountApplyingForm
        currentCoupon={currentCoupon}
        coupons={values.coupons}
        orders={values.orders}
        onSuccess={onSuccessDiscountForm}
        horses={horses}
      />
    </ModalWindow>
  );

  return {
    isDiscountFormOpen,
    openDiscountForm,
    closeDiscountForm,
    onSuccessDiscountForm,
    discountModal,
    checkCode,
    removeCouponFromOrder,
    couponError,
  };
}
