import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {
  createStaticResourceActions,
  createStaticResourceActionTypes,
  deleteStaticResourceActions,
  deleteStaticResourceActionTypes,
  getStaticResourceActions,
  getStaticResourceActionTypes,
  getStaticResourcesActions,
  getStaticResourcesActionTypes,
  resetStaticResourceActions,
  resetStaticResourceActionTypes,
  updateStaticResourceActions,
  updateStaticResourceActionTypes,
  uploadStaticResourceFileActions,
  uploadStaticResourceFileActionTypes,
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IRequestParams} from 'Common/models/IRequestParams';
import StaticResourceService from 'Admin/AdminSettings/services/StaticResourceService';
import {ICreateStaticResourceRequest} from 'Admin/AdminSettings/models/ICreateStaticResourceRequest';
import {IUpdateStaticResourceRequest} from 'Admin/AdminSettings/models/IUpdateStaticResourceRequest';
import {IStaticResource} from 'Admin/AdminSettings/models/IStaticResource';

export const getStaticResources =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getStaticResourcesActions> =>
  async (dispatch) => {
    dispatch(action(getStaticResourcesActionTypes.REQUEST));
    try {
      const res = await StaticResourceService.getStaticResources(params);
      dispatch(action(getStaticResourcesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getStaticResourcesActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };

export const getStaticResource =
  (id: number): ActionResult<IAppState, Promise<void>, getStaticResourceActions> =>
  async (dispatch) => {
    dispatch(action(getStaticResourceActionTypes.REQUEST));
    try {
      const res = await StaticResourceService.getStaticResource(id);
      dispatch(action(getStaticResourceActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getStaticResourceActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createStaticResource =
  (data: ICreateStaticResourceRequest): ActionResult<IAppState, void, createStaticResourceActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createStaticResourceActionTypes.REQUEST));
      const res = await StaticResourceService.createStaticResource(data);
      dispatch(action(createStaticResourceActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(createStaticResourceActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateStaticResource =
  (data: IUpdateStaticResourceRequest): ActionResult<IAppState, void, updateStaticResourceActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateStaticResourceActionTypes.REQUEST));
      const res = await StaticResourceService.updateStaticResource(data);
      dispatch(action(updateStaticResourceActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(updateStaticResourceActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteStaticResource =
  (id: number): ActionResult<IAppState, void, deleteStaticResourceActions> =>
  async (dispatch) => {
    dispatch(action(deleteStaticResourceActionTypes.REQUEST));
    try {
      await StaticResourceService.deleteStaticResource(id);
      dispatch(action(deleteStaticResourceActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteStaticResourceActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const uploadStaticResourceFile =
  (
    id: number,
    uploadedFile: File
  ): ActionResult<IAppState, Promise<IStaticResource>, uploadStaticResourceFileActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadStaticResourceFileActionTypes.REQUEST));
      const res = await StaticResourceService.uploadStaticResourceFile(id, uploadedFile);
      dispatch(action(uploadStaticResourceFileActionTypes.SUCCESS, res));
      return res;
    } catch (error) {
      const err = getErrorMessage(error);
      dispatch(action(uploadStaticResourceFileActionTypes.FAILURE, err));
      throw err;
    }
  };

export const resetStaticResourceResults =
  (): ActionResult<IAppState, void, resetStaticResourceActions> => async (dispatch) => {
    dispatch(action(resetStaticResourceActionTypes.RESET));
  };
