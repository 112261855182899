import {Reducer} from 'redux';

import {
  IAdminOrdersState,
  AdminOrdersActions,
  getOrdersActionTypes,
} from 'Admin/AdminDashboard/store/adminOrders/types';

const initialState: IAdminOrdersState['ui'] = {
  pagination: {countPerPage: 0, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiOrdersReducer: Reducer<IAdminOrdersState['ui'], AdminOrdersActions> = (
  state = initialState,
  action: AdminOrdersActions
) => {
  switch (action.type) {
    case getOrdersActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
