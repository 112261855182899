import {Reducer} from 'redux';

import {IAdminPackagesState, AdminPackagesActions, getPackagesActionTypes} from '../types';

const initialState: IAdminPackagesState['ui'] = {
  pagination: {countPerPage: 0, currentPage: 1, pageCount: 1, totalItemsCount: 1}
};

export const uiAdminPackagesReducer: Reducer<IAdminPackagesState['ui'], AdminPackagesActions> = (
  state = initialState,
  action: AdminPackagesActions
) => {
  switch (action.type) {
    case getPackagesActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
