import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminErrorDescriptions;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectErrorDescriptions(state: IAppState) {
  return selectState(state).data.errorDescriptions;
}

export function selectErrorDescription(state: IAppState) {
  return selectState(state).data.errorDescription;
}
