import React, {memo} from 'react';
import styled from 'styled-components';

import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {IAssociationPageGeneralInformation} from 'BusinessPortal/models/IAssociationPageGeneralInformation';
import {Nullable} from 'Common/types';
import {NoData} from 'BusinessPortal/components/common/NoData/NoData';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {Label, Value, Wrapper} from 'BusinessPortal/components/shared/StyledComponents';

const NoDescription = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${Theme.color.gray};
`;

interface IProps {
  generalInformation: Nullable<IAssociationPageGeneralInformation>;
}

const GeneralInformationShow = (props: IEditableSection<IProps>) => {
  const {data} = props;

  if (!data?.generalInformation) {
    return <NoData />;
  }

  const {generalInformation} = data;

  return (
    <div>
      <Wrapper>
        <Label>Association name:</Label>
        <Value>{generalInformation?.name}</Value>
      </Wrapper>

      <Wrapper>
        <Label>Abbreviation:</Label>
        <Value>{generalInformation?.abbreviation}</Value>
      </Wrapper>

      <Wrapper>
        <Label>Country:</Label>
        <Value>{generalInformation?.country}</Value>
      </Wrapper>

      <Wrapper>
        <Label>Address:</Label>
        <Value>{generalInformation?.address}</Value>
      </Wrapper>

      <Wrapper>
        <Label>Description:</Label>
        <Value>{generalInformation?.description || <NoDescription>no description</NoDescription>}</Value>
      </Wrapper>
    </div>
  );
};

export default memo(GeneralInformationShow);
