import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';
import {FormikProps, withFormik} from 'formik';

import {ModalWindowFooter, ModalWindowFormContent, ModalWindowHeader} from 'Common/components/Modal/shared/index';
import {InjectedStripeProps, withStripe} from 'Common/helpers/withStripe';
import {IAppState} from 'Common/store/IAppState';
import {Nullable} from 'Common/types';
import {ISubscriptionProduct} from 'StripeSubscription/models/ISubscriptionProduct';
import {actions, selectors} from 'StripeSubscription/store/index';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {StripeSubscriptionModule} from 'StripeSubscription/store/stripeSubscriptionModule';
import Theme from 'Common/constants/Theme';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {mapPriorityByInterval} from 'Common/constants/Interval';
import StripeFields from 'Payment/components/Stripe/PaymentFields/StripeFields';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import Loading from 'Loading/components/Loading';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {ISubscriptionResponse} from 'StripeSubscription/models/ISubscription';
import {RadiobuttonField} from 'Common/components/FormFields/index';
import {IFormValues, initialValue} from 'SubscriptionWelcome/components/BahSubscription/validation';
import {PaymentIntentStatus} from 'StripeSubscription/models/PaymentIntentStatus';
import {IRetryInvoiceResponse} from 'StripeSubscription/models/IRetryInvoiceResponse';
import {getCommonErrors, getStringErrorDetails} from 'Common/helpers/ErrorHelper';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useDictionaries} from 'Common/store/useDictionaries';
import {ISimpleDictionary} from 'DictionaryFactory/types';

const TextBase = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  line-height: 18px;
  color: ${Theme.color.black};
`;

const ProductsWrapper = styled.div<{wasAlreadyTryingToSubmit?: boolean}>`
  pointer-events: ${({wasAlreadyTryingToSubmit}) => (wasAlreadyTryingToSubmit ? 'none' : 'auto')};
  opacity: ${({wasAlreadyTryingToSubmit}) => (wasAlreadyTryingToSubmit ? 0.4 : 1)};
`;

const Product = styled.div<{isActive?: boolean}>`
  height: 64px;
  margin: 8px 0;
  padding: 0 28px;
  border: 2px solid ${({isActive}) => (isActive ? Theme.color.primary : ColorPalette.white9)};
  border-radius: 10px;

  :hover {
    cursor: pointer;
    border: 2px solid ${Theme.color.primary};
  }
`;

const ProductDescription = styled(TextBase)`
  font-size: ${Typography.size.size16};
`;

const ProductCost = styled.div`
  min-width: 130px;
`;

const ProductPrice = styled(TextBase)`
  font-size: ${Typography.size.size20};
`;

const ProductInterval = styled(TextBase)`
  color: ${ColorPalette.gray44};
`;

const PayButton = styled(PrimaryButton)`
  width: 100%;

  @media ${breakpoints.sm} {
    width: 432px;
  }
`;

const ModalWindowHeaderWrapper = styled(ModalWindowHeader)`
  font-size: ${Typography.size.size18};
  line-height: 24px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size24};
    line-height: 33px;
  }
`;

interface IExternalDictionaries {
  productDictionary: ISimpleDictionary<ISubscriptionProduct>;
}

interface IExternalProps {
  onSuccess(): void;
  onPartialSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = InjectedStripeProps & IExternalProps & IConnected & IExternalDictionaries;

type AllProps = FormikProps<IFormValues> & OuterProps;

const BahSubscriptionForm = (props: AllProps) => {
  const {elements, stripe, values, setFieldValue, setStatus, status} = props;
  const {
    onSuccess,
    onPartialSuccess,
    products,
    activeProductsLoading,
    createSubscriptionRequesting,
    ensureCustomerCreatedRequesting,
    refreshSubscriptionFromStripe,
    refreshSubscriptionFromStripeRequesting,
    createSubscription,
    ensureCustomerCreated,
    retryInvoiceRequesting,
    retryInvoice,
    productDictionary,
  } = props;

  const {
    actions: {getItems: getProducts},
  } = productDictionary;

  const [currentInvoiceStatus, setCurrentInvoice] = useState<Nullable<PaymentIntentStatus>>(null);
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState<Nullable<string>>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
  const [isCardExpiryComplete, setIsCardExpiryComplete] = useState(false);
  const [isCardCVCComplete, setIsCardCVCComplete] = useState(false);

  const isCardComplete = isCardNumberComplete && isCardExpiryComplete && isCardCVCComplete;

  const [cardNumberError, setCardNumberError] = useState('');
  const [cardExpiryError, setCardExpiryError] = useState('');
  const [cardCvcError, setCardCvcError] = useState('');

  const cardError = `${cardNumberError} ${cardExpiryError} ${cardCvcError}`;

  const handleOnCardNumberChange = useCallback((event: stripe.elements.ElementChangeResponse) => {
    setCardNumberError(event.error?.message || '');
    setIsCardNumberComplete(event.complete);
  }, []);

  const handleOnCardExpiryChange = useCallback((event: stripe.elements.ElementChangeResponse) => {
    setCardExpiryError(event.error?.message || '');
    setIsCardExpiryComplete(event.complete);
  }, []);

  const handleOnCardCVCChange = useCallback((event: stripe.elements.ElementChangeResponse) => {
    setCardCvcError(event.error?.message || '');
    setIsCardCVCComplete(event.complete);
  }, []);

  const isDisableSubmit = !isCardComplete || !values.subscriptionId;

  const isLoading = activeProductsLoading.isRequesting || !stripe || !elements;
  const isButtonLoading =
    isSubmitting ||
    ensureCustomerCreatedRequesting.isRequesting ||
    createSubscriptionRequesting.isRequesting ||
    retryInvoiceRequesting.isRequesting ||
    refreshSubscriptionFromStripeRequesting.isRequesting;

  const errorInfo = createSubscriptionRequesting.error;

  const onError = useCallback(() => {
    const error = getStringErrorDetails(errorInfo);
    if (!error) {
      setStatus(getCommonErrors(errorInfo));
      return;
    }

    setStatus(error);
  }, [setStatus, errorInfo]);

  useOnErrorCommunication(createSubscriptionRequesting, onError);

  const onProductClick = useCallback(
    (event: React.MouseEvent, productId: number) => {
      event.preventDefault();
      if (values.subscriptionId !== productId) {
        setFieldValue('subscriptionId', productId);
        return;
      }
      setFieldValue('subscriptionId', null);
    },
    [values.subscriptionId, setFieldValue]
  );

  const onSuccessSubscribe = useCallback(
    async (subscriptionId?: number) => {
      if (subscriptionId) {
        await refreshSubscriptionFromStripe(subscriptionId);
      }
      setStatus('');
      onSuccess();
    },
    [refreshSubscriptionFromStripe, setStatus, onSuccess]
  );

  useEffect(() => {
    currentSubscriptionId && currentSubscriptionId.length > 0 && onPartialSuccess();
  }, [currentSubscriptionId, onPartialSuccess]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const bahProducts = useMemo(
    () =>
      products
        ?.filter((i) => i.isActive)
        .sort((a, b) => mapPriorityByInterval[a.billingInterval] - mapPriorityByInterval[b.billingInterval]),
    [products]
  );

  const handlePaymentThatRequiresCustomerAction = useCallback(
    async (
      subscription: Nullable<ISubscriptionResponse>,
      paymentMethodId: string,
      invoice?: IRetryInvoiceResponse,
      isRetry?: boolean
    ): Promise<void> => {
      const paymentIntent = invoice
        ? {status: invoice.paymentIntentStatus, client_secret: invoice.clientSecret}
        : {status: subscription?.latestInvoicePaymentIntentStatus, client_secret: subscription?.clientSecret};

      if (
        paymentIntent.status === PaymentIntentStatus.Action ||
        (isRetry === true && paymentIntent.status === PaymentIntentStatus.PaymentMethod)
      ) {
        const {error} = await stripe.confirmCardPayment(paymentIntent.client_secret!, {
          payment_method: paymentMethodId,
        });

        if (error) {
          setCurrentInvoice(error?.payment_intent?.status as PaymentIntentStatus);
          throw Error(error.message);
        }
      }
    },
    [stripe]
  );

  const handleRequiresPaymentMethod = useCallback((subscription: Nullable<ISubscriptionResponse>) => {
    if (subscription?.latestInvoicePaymentIntentStatus === PaymentIntentStatus.PaymentMethod) {
      setCurrentInvoice(subscription.latestInvoicePaymentIntentStatus);
      throw new Error('Your card was declined.');
    }
  }, []);

  const onSubscribe = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }

    try {
      setIsSubmitting(true);
      await ensureCustomerCreated();

      const cardNumber = elements.getElement('cardNumber');

      if (cardNumber) {
        const {error, paymentMethod} = await stripe.createPaymentMethod('card', cardNumber);
        if (error) {
          throw Error(error.message);
        }

        const paymentMethodId = paymentMethod?.id;
        if (currentInvoiceStatus === PaymentIntentStatus.PaymentMethod) {
          if (paymentMethodId && currentSubscriptionId) {
            const result = await retryInvoice(paymentMethodId, +currentSubscriptionId);
            await handlePaymentThatRequiresCustomerAction(null, paymentMethodId, result, true);
            await onSuccessSubscribe(+currentSubscriptionId);
          }
          return;
        }

        if (paymentMethodId) {
          const createdSubscription = await createSubscription({
            paymentMethodId,
            subscriptionProductId: values.subscriptionId!,
          });

          if (createdSubscription?.latestInvoicePaymentIntentStatus !== 'succeeded') {
            try {
              await handlePaymentThatRequiresCustomerAction(createdSubscription, paymentMethodId);
              await handleRequiresPaymentMethod(createdSubscription);
              await onSuccessSubscribe(createdSubscription?.subscriptionId);
              return;
            } catch (e) {
              setStatus(e.message);
            }
            setCurrentSubscriptionId(`${createdSubscription?.subscriptionId}`);
          } else {
            await onSuccessSubscribe(createdSubscription.subscriptionId);
          }
        }
      }
    } catch (e) {
      setStatus((e as unknown as any).message);
    } finally {
      setIsSubmitting(false);
    }
  }, [
    stripe,
    elements,
    ensureCustomerCreated,
    currentInvoiceStatus,
    currentSubscriptionId,
    retryInvoice,
    handlePaymentThatRequiresCustomerAction,
    onSuccessSubscribe,
    createSubscription,
    values.subscriptionId,
    handleRequiresPaymentMethod,
    setStatus,
  ]);

  return (
    <>
      <ModalWindowHeaderWrapper>Get access to Build-a-Horse</ModalWindowHeaderWrapper>
      {isLoading && <Loading />}
      <div className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          <ProductsWrapper wasAlreadyTryingToSubmit={!!currentSubscriptionId}>
            {bahProducts?.map((product) => {
              return (
                <Product
                  key={product.id}
                  className="d-flex justify-content-between"
                  isActive={product.id === values.subscriptionId}
                  onClick={(e) => onProductClick(e, product.id)}
                >
                  <div className="d-flex align-items-center">
                    <RadiobuttonField name="subscriptionId" value={product.id} />
                    <ProductDescription>{product.name}</ProductDescription>
                  </div>
                  <ProductCost className="d-flex align-items-center">
                    <ProductPrice>${product.price}</ProductPrice>
                    <ProductInterval>&nbsp;/&nbsp;{product.billingInterval}</ProductInterval>
                  </ProductCost>
                </Product>
              );
            })}
          </ProductsWrapper>
          <StripeFields
            onCardNumberChange={handleOnCardNumberChange}
            onCardExpiryChange={handleOnCardExpiryChange}
            onCardCVCChange={handleOnCardCVCChange}
          />
          {cardError && <ErrorMessage>{cardError}</ErrorMessage>}
          {status && <ErrorMessage>{status}</ErrorMessage>}
        </ModalWindowFormContent>
        <ModalWindowFooter className="d-flex justify-content-center flex-column">
          <PayButton isLoading={isButtonLoading} disabled={isDisableSubmit} onClick={onSubscribe}>
            {values.subscriptionId
              ? `Subscribe for $${bahProducts?.find((i) => i.id === values.subscriptionId)?.price}`
              : 'Subscribe'}
          </PayButton>
        </ModalWindowFooter>
      </div>
    </>
  );
};

const BahSubscriptionFormWithFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValue,
  handleSubmit: console.log,
  enableReinitialize: true,
})(BahSubscriptionForm);

const mapStateToProps = (state: IAppState, props: IExternalDictionaries) => {
  const {
    productDictionary: {selectors: productSelectors},
  } = props;

  return {
    products: productSelectors.selectItems(state),
    activeProductsLoading: productSelectors.selectCommunication(state, 'itemsLoading'),
    ensureCustomerCreatedRequesting: selectors.selectCommunication(state, 'ensureCustomerCreatedRequesting'),
    createSubscriptionRequesting: selectors.selectCommunication(state, 'createSubscriptionRequesting'),
    retryInvoiceRequesting: selectors.selectCommunication(state, 'retryInvoiceRequesting'),
    refreshSubscriptionFromStripeRequesting: selectors.selectCommunication(
      state,
      'refreshSubscriptionFromStripeRequesting'
    ),
  };
};

const mapDispatchToProps = {
  ensureCustomerCreated: actions.ensureCustomerCreated,
  createSubscription: actions.createSubscription,
  retryInvoice: actions.retryInvoice,
  refreshSubscriptionFromStripe: actions.refreshSubscriptionFromStripe,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(withStripe(BahSubscriptionFormWithFormik));
const Dictionaried = (props: IExternalProps) => {
  const {stripeSubscriptionProducts} = useDictionaries();

  return <Connected {...props} productDictionary={stripeSubscriptionProducts} />;
};

export default withDynamicModules(Dictionaried, StripeSubscriptionModule);
