import {IModule} from 'redux-dynamic-modules';

import {IBusinessPortalOrderPaymentState} from './types';
import {businessPortalOrderPaymentReducer} from './reducer';

export interface IBusinessPortalOrderPaymentModuleState {
  businessPortalOrderPayment: IBusinessPortalOrderPaymentState;
}

export const BusinessPortalOrderPaymentModule: IModule<IBusinessPortalOrderPaymentModuleState> = {
  id: 'businessPortalOrderPayment',
  reducerMap: {
    businessPortalOrderPayment: businessPortalOrderPaymentReducer,
  },
};
