import React, {CSSProperties, memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import Tooltip from '../Tooltip/Tooltip';

const Icon = styled(ColoredIcon)`
  margin-left: 12px;

  :hover {
    cursor: pointer;
  }
`;

interface IProps {
  tooltip: React.ReactChild | React.ReactChild[] | undefined;
  style?: CSSProperties;
}

function HintIcon(props: IProps) {
  const {style, tooltip} = props;

  const fakeEvent = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Tooltip content={tooltip}>
      <div className="d-flex" onClick={fakeEvent}>
        <Icon
          name={IconName.Question}
          size={16}
          color={ColorPalette.red7}
          fill={true}
          stroke={false}
          style={style}
          onClick={fakeEvent}
        />
      </div>
    </Tooltip>
  );
}

export default memo(HintIcon);
