import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import ColorPalette from 'Common/constants/ColorPalette';
import {IGenotype} from 'Genotype/models/IGenotype';
import Tooltip from 'Common/components/Tooltip/Tooltip';

const AlleleName = styled.div<{isHighlight: boolean}>`
  ${({isHighlight}) => isHighlight && `color: ${ColorPalette.red1}`};
`;

interface IProps {
  genotypes: IGenotype[];
}

const GenotypeList = ({genotypes}: IProps) => {
  const propPositionIndex = R.prop('positionIndex');
  const propAlleleNames = R.prop('alleleNames');

  const sorting = R.sortWith<IGenotype>([R.ascend(propPositionIndex), R.ascend(propAlleleNames)]);

  return (
    <>
      {sorting(genotypes).map((genotype, index) => {
        const isLast = genotypes.length - 1 === index;
        return (
          <Tooltip content={genotype.description} key={index}>
            <div className="d-flex">
              <AlleleName isHighlight={genotype.highlightColor}>{genotype.alleleNames}</AlleleName>
              {!isLast ? <div>,&nbsp;</div> : ''}
            </div>
          </Tooltip>
        );
      })}
    </>
  );
};

export default GenotypeList;
