import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';

import {
  ModalWindowHeader,
  ModalWindowFormContent,
  ModalWindowFooter,
  ModalWindowButton
} from 'Common/components/Modal/shared';
import {Input} from 'Common/components/Controls';

const Root = styled.div`
  margin: 0 24px 40px 0;
`;

const Text = styled.div`
  margin-bottom: 24px;
`;

interface IProps {
  onClick(cause: string): void;
  onClose(): void;
}

function DisablingAccessModal(props: IProps) {
  const {onClick, onClose} = props;
  const [value, setValue] = useState('');

  const onDisableClick = useCallback(() => {
    onClick(value);
    onClose();
  }, [onClick, onClose, value]);

  const onInputChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  }, []);

  return (
    <>
      <ModalWindowHeader>Disabling access</ModalWindowHeader>
      <Root>
        <ModalWindowFormContent>
          <Text>
            When you click <b>Disable</b> users won’t be able to use any part of website. Are you sure?
          </Text>
          <Input label="Reason" value={value} onChange={onInputChange} />
        </ModalWindowFormContent>
      </Root>
      <ModalWindowFooter>
        <ModalWindowButton type="button" variant="outlined" onClick={onDisableClick}>
          Disable
        </ModalWindowButton>
        <ModalWindowButton type="button" onClick={onClose}>
          Cancel
        </ModalWindowButton>
      </ModalWindowFooter>
    </>
  );
}

export default memo(DisablingAccessModal);
