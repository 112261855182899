import React, {memo, useCallback} from 'react';
import styled from 'styled-components';

import Icon, {IconName} from 'Icon/components/Icon';
import Theme from 'Common/constants/Theme';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {breakpoints} from 'Common/constants/Breakpoints';
import {ToastContainer} from 'Common/components/Toast/ToastContainer';
import {useHistory} from 'react-router-dom';

const Root = styled.div<{height?: string}>`
  ${({height}) => (height ? 'height: 100vh;' : '')}
  background: linear-gradient(176.5deg, #272b39 2.89%, #151925 96.3%);
`;

const Logo = styled.div`
  top: 32px;
  right: 32px;
  z-index: 1;
  cursor: pointer;
`;

const LogoIcon = styled(ColoredIcon)`
  margin-right: 16px;

  width: 50px;
  height: 24px;

  @media ${breakpoints.sm} {
    width: 65px;
    height: 32px;
  }
`;

const LogoName = styled(Icon)`
  width: 87px;
  height: 12px;

  @media ${breakpoints.md} {
    width: 115px;
    height: 15px;
  }
`;

interface IProps {
  height?: string;
}

const StartPageBackgroundPlaceholder = (props: IProps & React.PropsWithChildren<{}>) => {
  const {height, children} = props;

  const history = useHistory();

  const redirectToLoginPage = useCallback(() => history.push('/login'), [history]);

  return (
    <Root className="d-flex w-100 flex-column position-relative" height={height}>
      <Logo className="position-absolute" onClick={redirectToLoginPage}>
        <LogoIcon name={IconName.EtalonHorseLogo} color={Theme.color.white} fill={true} />
        <LogoName name={IconName.EtalonLogo} />
      </Logo>
      <ToastContainer className="position-absolute" />
      {children}
    </Root>
  );
};

export default memo(StartPageBackgroundPlaceholder);
