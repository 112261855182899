import {IModule} from 'redux-dynamic-modules';

import {IAdminNotificationState} from './types';
import {adminNotificationsReducer} from './reducer';

export interface IAdminNotificationsModuleState {
  adminNotifications: IAdminNotificationState;
}

export const AdminNotificationsModule: IModule<IAdminNotificationsModuleState> = {
  id: 'adminNotifications',
  reducerMap: {
    adminNotifications: adminNotificationsReducer
  }
};
