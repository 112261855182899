import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';

import {InputField, SelectField, TextAreaField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {RequiredBy} from 'Common/types';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {
  convertToServer,
  convertToServerUpdating,
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/PartialColors/PartialColorForm/validation';
import {ITrait} from 'Dictionaries/models/ITrait';
import {IPartialColorGroup} from 'Dictionaries/models/IPartialColorGroup';
import {IUpdatePartialColorRequest} from 'Admin/AdminPhenotypes/service/models/IServerPartialColor';
import {IAdminPartialColor} from 'Admin/AdminPhenotypes/models/IAdminPartialColor';
import {castToOption} from 'Common/helpers/OptionHelper';
import {IAffectionType} from 'Dictionaries/models/IAffectionType';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

interface IOwnProps extends IPhenotypeFormExternalProps<IAdminPartialColor, IUpdatePartialColorRequest> {
  traitDictionary: ISimpleDictionary<ITrait>;
  partialColorGroupDictionary: ISimpleDictionary<IPartialColorGroup>;
  affectionTypeDictionary: ISimpleDictionary<IAffectionType>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function PartialColorForm(props: Props) {
  const {
    itemId,
    partialColor,
    dictionary,
    trait,
    traitDictionary,
    traitLoading,
    partialColorGroup,
    partialColorGroupDictionary,
    partialColorGroupLoading,
    affectionTypeDictionary,
    affectionTypes,
    affectionTypesLoading,
    ...rest
  } = props;
  const {getItem} = dictionary.actions;
  const {
    actions: {getItems: getTraits},
  } = traitDictionary;
  const {
    actions: {getItems: getpartialColorGroups},
  } = partialColorGroupDictionary;
  const {
    actions: {getItems: getAffectionTypes},
  } = affectionTypeDictionary;

  useEffect(() => {
    getTraits();
    getpartialColorGroups();
    getAffectionTypes();
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem, getTraits, getpartialColorGroups, getAffectionTypes]);

  return (
    <DictionaryForm
      {...rest}
      itemCreating={partialColor.itemCreating}
      itemLoading={partialColor.itemLoading}
      itemUpdating={partialColor.itemUpdating}
      itemPreloading={traitLoading.isRequesting || partialColorGroupLoading.isRequesting}
    >
      <InputField name="name" label="Name" placeholder="Name" />
      <InputField
        name="positionIndex"
        label="Order in Report"
        placeholder="Order in Report"
        className="w-50"
        isRequired
      />
      <SelectField name="trait" label="Trait" options={castToOption(trait)} />
      <SelectField name="group" label="Group" options={castToOption(partialColorGroup)} />
      <SelectField
        name="affectionTypeId"
        label="Affection type"
        placeholder="Affection type"
        options={castToOption(affectionTypes)}
      />

      <TextAreaField name="description.value" label="Description" placeholder="Description" />
      <InputField name="externalDescriptionUrl" label="URL" placeholder="External URL" />
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  const {traitDictionary, partialColorGroupDictionary, affectionTypeDictionary} = ownProps;

  return {
    partialColor: makePhenotypeFormSelectors(state, ownProps.dictionary),
    trait: traitDictionary.selectors.selectItems(state),
    traitLoading: traitDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    partialColorGroup: partialColorGroupDictionary.selectors.selectItems(state),
    partialColorGroupLoading: partialColorGroupDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    affectionTypes: affectionTypeDictionary.selectors.selectItems(state),
    affectionTypesLoading: affectionTypeDictionary.selectors.selectCommunication(state, 'itemsLoading'),
  };
};

const PartialColorFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({partialColor: {item, itemLoading}}) =>
    item && !itemLoading.isRequesting
      ? {
          ...item,
          affectionTypeId: item.affectionType?.id || null,
          group: item.group?.id || null,
          trait: item.trait?.id || null,
        }
      : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(PartialColorForm);

const connector = connect(mapStateToProps);
export default connector(memo(PartialColorFormFormik));
