import React, {memo, useState} from 'react';
import styled from 'styled-components';
import {Collapse} from 'react-bootstrap';

import ColoredIcon, {IColoredIconProps as IconProps} from 'Icon/components/ColoredIcon';
import withoutProps from 'Common/helpers/WithoutProps';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';

const toggleProps: IconProps = {
  size: 12,
  name: IconName.ArrowDown,
  color: ColorPalette.red7,
  fill: true,
  stroke: false,
};

const Container = styled.div<{isClickableContainer?: boolean}>`
  padding: 0 24px 8px;
  ${(props) => props.isClickableContainer && 'cursor: pointer;'};
`;

const ToggleButton = styled.div`
  width: 100%;
  min-height: 32px;
  height: 32px;
  border-radius: 0 0 16px 16px;
  padding-bottom: 8px;
  cursor: pointer;
`;

const ToggleIcon = styled(withoutProps(['isOpen'])(ColoredIcon))<{isOpen: boolean}>`
  margin-left: 10px;
  transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'unset')};
`;

const Title = styled.span`
  cursor: pointer;
  font-family: ${Typography.family.roboto};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size14};
  line-height: 15px;
  color: ${ColorPalette.red7} !important;
`;

interface IProps {
  isOpenDefault?: boolean;
  containerStyle?: React.CSSProperties;
  isClickableContainer?: boolean;
  buttonTitle?: string;
}

function ExpandableContainer(props: React.PropsWithChildren<IProps>) {
  const {children, isOpenDefault, containerStyle, isClickableContainer = true, buttonTitle} = props;
  const [isOpen, setIsOpen] = useState(isOpenDefault);

  return (
    <>
      <Container
        onClick={() => isClickableContainer && setIsOpen(!isOpen)}
        isClickableContainer={isClickableContainer}
        style={containerStyle}
      >
        <Collapse in={isOpen}>
          <div>{children}</div>
        </Collapse>
      </Container>
      <ToggleButton
        className="d-flex justify-content-center align-items-center"
        onClick={() => setIsOpen(!isOpen)}
        aria-controls="tests-collapse-list"
        aria-expanded={isOpen}
      >
        <Title>{buttonTitle}</Title>
        <ToggleIcon {...toggleProps} isOpen={isOpen} />
      </ToggleButton>
    </>
  );
}

export default memo(ExpandableContainer);
