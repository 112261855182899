import {useCallback} from 'react';
import {History} from 'history';

interface IProps {
  token?: string;
  history: History;
}

export function useRedirects(props: IProps) {
  const {history, token} = props;

  const redirectToResultPage = useCallback(() => {
    history.push(`/business-portal/order-placed/${token}`);
  }, [history, token]);

  const redirectToSuccessPayment = useCallback(() => {
    history.push(`/business-portal/order-placed/${token}/payment`);
  }, [history, token]);

  return {
    redirectToResultPage,
    redirectToSuccessPayment,
  };
}
