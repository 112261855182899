import React, {useCallback, useState} from 'react';

import OwnerForm from 'Admin/AdminAssociations/components/Owners/OwnerForm/OwnerForm';
import ModalWindow from 'Common/components/Modal/ModalWindow';

interface IUseModalParams {
  onSuccess?(): void;
}

export function useAdminOwnerModal(params: IUseModalParams) {
  const {onSuccess} = params;

  const [updatedId, setUpdatedId] = useState<number>();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const openUpdateAdminOwnerModal = useCallback(
    (id?: number) => {
      setIsUpdateModalOpen(true);
      setUpdatedId(id);
    },
    [setIsUpdateModalOpen]
  );

  const openCreateAdminOwnerModal = useCallback(() => {
    setIsCreateModalOpen(true);
  }, []);

  const closeModals = useCallback(() => {
    setIsUpdateModalOpen(false);
    setIsCreateModalOpen(false);
  }, []);

  const onSuccessHandler = useCallback(() => {
    closeModals();
    onSuccess && onSuccess();
  }, [onSuccess, closeModals]);

  const updateAdminOwnerModal = (
    <ModalWindow isOpen={isUpdateModalOpen} onClose={closeModals}>
      <OwnerForm onSuccess={onSuccessHandler} ownerId={updatedId!} />
    </ModalWindow>
  );

  const createAdminOwnerModal = (
    <ModalWindow isOpen={isCreateModalOpen} onClose={closeModals}>
      <OwnerForm onSuccess={onSuccessHandler} />
    </ModalWindow>
  );

  return {
    openUpdateAdminOwnerModal,
    openCreateAdminOwnerModal,
    updateAdminOwnerModal,
    createAdminOwnerModal,
    updatedId,
  };
}
