import {memo} from 'react';
import styled from 'styled-components';

import EditableSection from 'BusinessPortal/components/common/EditableSection/EditableSection';
import OwnerGeneralInfoSectionEdit from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/parts/OwnerGeneralInfoSection/OwnerGeneralInfoSectionEdit';
import OwnerInfoSectionShow from 'BusinessPortal/components/BusinessPortalDashboard/shared/OwnerInfoSectionShow/OwnerInfoSectionShow';
import {IOwnerSectionBP} from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/models/IOwnerSectionBP';

const GeneralEditableSectionWrapper = styled.div`
  padding-top: 8px;
`;

const OwnerGeneralInfoSection = (props: IOwnerSectionBP) => {
  const {ownerDetails: owner, onSuccess} = props;
  return (
    <GeneralEditableSectionWrapper>
      <EditableSection
        title="General info"
        data={{owner}}
        onSuccess={onSuccess}
        editComponent={OwnerGeneralInfoSectionEdit}
        showComponent={OwnerInfoSectionShow}
      />
    </GeneralEditableSectionWrapper>
  );
};

export default memo(OwnerGeneralInfoSection);
