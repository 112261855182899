import {combineReducers, Reducer} from 'redux';

import {dataResetPasswordReducer} from './data';
import {communicationResetPasswordReducer} from './communications';
import {IResetPasswordState} from 'ResetPassword/store/types';

export const resetPasswordReducer: Reducer<IResetPasswordState> = combineReducers<IResetPasswordState>({
  communications: communicationResetPasswordReducer,
  data: dataResetPasswordReducer
});
