import React, {memo} from 'react';

import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import UserSearchField from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearchField';

function SelectOwner() {
  return (
    <FormControlContainer title="Horse owner">
      <UserSearchField name="userId" memoized={true} />
    </FormControlContainer>
  );
}

export default memo(SelectOwner);
