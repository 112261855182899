import {PaymentMethod} from 'Common/constants/PaymentMethod';
import {IEntity} from 'Common/models/IEntity';
import {Nullable} from 'Common/types';
import {INamedEntity} from 'Common/models/INamedEntity';
import {OrderStatusField} from 'Order/models/IOrderStatusFields';

export interface IAdminPaymentDetails {
  orderIds: number[];
  receivedDate: Nullable<string>;
  method: PaymentMethod;
  transactionId: string;
  status: Nullable<OrderStatusField>;
  timestamp: string;
  sendEmail: boolean;
}

export interface IAdminPaymentDetailsHorse extends IEntity {
  horse: INamedEntity;
  isPaid: boolean;
}

export enum PaymentStatus {
  Waiting = 'Waiting',
  Ready = 'Paid',
}
