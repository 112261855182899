import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IObjectResponse} from 'Common/models/IResponse';
import {IAdminUserPermissions} from 'Admin/AdminDashboard/models/User/IUserPermissions';
import {convertUserPermissions} from './converters/user';
import {ApiByStrategyType, getApiBySelectedStrategy} from 'Common/helpers/getApiBySelectedStrategy';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';

const getUserPermissions = async (userId: number): Promise<IAdminUserPermissions> => {
  const apis: ApiByStrategyType = {
    [SelectedStrategy.Admin]: `/UserPermissionAdmin`,
    [SelectedStrategy.AdminAssociation]: `/AssociationEmployeePermissionsAdmin`,
  };

  const api = getApiBySelectedStrategy(apis);

  const response = await axiosWrapper.get<IObjectResponse<IAdminUserPermissions>>(`${api}/${userId}`);
  return convertUserPermissions(response.data.data);
};

const updateUserPermissions = async (data: IAdminUserPermissions): Promise<void> => {
  const apis: ApiByStrategyType = {
    [SelectedStrategy.Admin]: `/UserPermissionUpdateAdmin`,
    [SelectedStrategy.AdminAssociation]: `/AssociationEmployeePermissionsUpdateAdmin`,
  };

  const api = getApiBySelectedStrategy(apis);

  await axiosWrapper.post<IObjectResponse<null>>(api, data);
};

export default {
  getUserPermissions,
  updateUserPermissions,
};
