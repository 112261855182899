import {IModule} from 'redux-dynamic-modules';

import {IShoppingCartState} from './types';
import {shoppingCartReducer} from './reducer';

export interface IShoppingCartModuleState {
  shoppingCart: IShoppingCartState;
}

export const ShoppingCartModule: IModule<IShoppingCartModuleState> = {
  id: 'shoppingCart',
  reducerMap: {
    shoppingCart: shoppingCartReducer,
  },
};
