import {useCallback, useEffect, useMemo, useState} from 'react';
import * as R from 'ramda';

import {OrderingType} from 'Common/constants/OrderingType';
import {ITableSorting} from 'Common/components/Table/Table';
import {IRequestParams} from 'Common/models/IRequestParams';
import {ICommunication} from 'Common/store/utils/communication/index';
import {IEntity} from 'Common/models/IEntity';
import {useSearchBar, UseSearchBarProps} from 'Common/helpers/hooks/useSearchBar';
import {defaultSortingParams} from 'Common/constants/RequestParams';
import {useDeleteModal} from 'Common/helpers/hooks/useDeleteModal';

interface IDeleteItemParams {
  communication: ICommunication;
  confirmDescription: string;
  action(id: IEntity['id']): void;
}

export interface ICommonAdminPageParams {
  getItems?(params: IRequestParams): void;
  deleteParams?: IDeleteItemParams;
  defaultSorting?: Pick<IRequestParams, 'orderBy' | 'orderingType'>;
  sortingAfterFilteringParams?: Pick<IRequestParams, 'orderBy' | 'orderingType'>;
  getItemsIgnoreParams?: string[];
}

export function useCommonAdminPageData<Sorting, T = IRequestParams>(
  args: ICommonAdminPageParams & UseSearchBarProps<T> = {}
) {
  const {
    getItems,
    deleteParams,
    searchBarPlaceholder,
    defaultSorting = defaultSortingParams,
    sortingAfterFilteringParams,
    getItemsIgnoreParams = ['selectedId'],
    searchBarLogoStyle,
    searchBarStyle
  } = args;

  const {communication: deleteCommunication, confirmDescription, action: deleteAction} = deleteParams || {};
  const {searchBar, reloadItems, handleChangeFilter, setRequestParams, params} = useSearchBar({
    getItems,
    searchBarPlaceholder,
    defaultSorting,
    getItemsIgnoreParams,
    searchBarLogoStyle,
    searchBarStyle
  });

  const {
    openDeleteModal,
    closeDeleteModal,
    selectedId,
    setSelectedId,
    isDeleteModalOpen,
    closeDeleteErrorModal,
    deleteModal
  } = useDeleteModal({
    confirmDescription,
    deleteCommunication,
    onSuccess: reloadItems,
    deleteAction
  });

  const {orderBy, filter, orderingType} = params;

  const isFilledSearchBar = filter && filter.length > 0;

  const sorting = useMemo(
    () =>
      ({
        sortBy: orderBy || null,
        isAscending: orderingType === OrderingType.Ascending || false
      } as ITableSorting<Sorting>),
    [orderBy, orderingType]
  );

  const handlePageSelect = useCallback(
    (currentPage: number) => {
      setSelectedId(null);
      setRequestParams({...params, currentPage});
    },
    [setSelectedId, setRequestParams, params]
  );

  const handleCountPerPage = useCallback(
    (countPerPage: number) => {
      setSelectedId(null);
      setRequestParams({...params, currentPage: 1, countPerPage});
    },
    [setSelectedId, setRequestParams, params]
  );

  const [useSortingAfterFilteringParams, setUseSortingAfterFilteringParams] = useState(!!sortingAfterFilteringParams);
  const [isManualMode, setIsManualMode] = useState(false);

  const changeSorting = useCallback(
    (tableSorting: ITableSorting<Sorting>) => {
      if (useSortingAfterFilteringParams) {
        setIsManualMode(true);
      }
      setSelectedId(null);
      setRequestParams({
        ...params,
        orderBy: tableSorting.sortBy || null,
        orderingType: tableSorting.isAscending ? OrderingType.Ascending : OrderingType.Descending
      });
    },
    [useSortingAfterFilteringParams, setSelectedId, setRequestParams, params]
  );

  useEffect(() => {
    if (useSortingAfterFilteringParams && isManualMode) {
      setUseSortingAfterFilteringParams(false);
    }
  }, [useSortingAfterFilteringParams, isManualMode]);

  useEffect(() => {
    if (useSortingAfterFilteringParams) {
      if (isFilledSearchBar) {
        setRequestParams({...params, ...sortingAfterFilteringParams});
      } else {
        setRequestParams({...params, ...defaultSortingParams});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilledSearchBar]);

  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    setSelectedId(null);
    setSearchQuery(R.isNil(filter) ? '' : filter);
  }, [filter, setSelectedId]);

  useEffect(() => {
    if (params.selectedId) {
      setSelectedId(params.selectedId);
    }
  }, [params.selectedId, setSelectedId]);

  useEffect(() => {
    if (selectedId) {
      setRequestParams({
        ...params,
        selectedId
      });
    } else {
      delete params['selectedId'];
      setRequestParams({
        ...params
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, setRequestParams]);

  return {
    sorting,
    searchQuery,
    setSearchQuery,
    openDeleteModal,
    closeDeleteModal,
    selectedId,
    setSelectedId,
    isDeleteModalOpen,
    handlePageSelect,
    handleCountPerPage,
    handleChangeFilter,
    setRequestParams,
    changeSorting,
    params,
    closeDeleteErrorModal,
    reloadItems,
    deleteModal,
    searchBar
  };
}
