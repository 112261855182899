import React, {memo} from 'react';
import styled from 'styled-components';

import {Nullable} from 'Common/types';
import Icon, {IconName} from 'Icon/components/Icon';
import {IVideo} from 'Video/models/IVideo';
import {Panel} from '../shared/StyledComponents';
import {getYouTubeThumbnailUrl} from 'Common/helpers/getYouTubeThumbnailUrl';
import VideoPreviewActions from './VideoPreviewActions';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const Thumbnail = styled.img<Partial<IProps>>`
  height: ${props => props.height};
  width: ${props => props.width};
`;

const ThumbnailPLayIcon = styled(Icon)`
  z-index: 1;
`;

interface IProps {
  actionButtonsContainerHeight?: string;
  height?: string;
  width?: string;
  value: Nullable<IVideo>;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  showPreviewOnClick?: boolean;
  onClick?(): void;
  onDeleteVideo(): void;
  onEditVideo(): void;
}

function VideoPreview(props: IProps) {
  const {height, actionButtonsContainerHeight, width, value, onClick, ...rest} = props;
  const {isDesktop} = useMediaQuery();

  const videoThumbnail = getYouTubeThumbnailUrl(value?.url);

  return (
    <Panel
      className="d-flex flex-column justify-content-center align-items-center position-relative"
      height={height}
      width={width}
      onClick={onClick}
    >
      <ThumbnailPLayIcon name={IconName.PlayIcon} size={32} />
      {videoThumbnail && (
        <Thumbnail className="position-absolute" src={videoThumbnail} alt="Video" height={height} width={width} />
      )}
      <VideoPreviewActions isDesktop={isDesktop} height={actionButtonsContainerHeight} {...rest} />
    </Panel>
  );
}

export default memo(VideoPreview);
export type VideoPreviewProps = IProps;
