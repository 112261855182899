import React, {memo} from 'react';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout, {
  DictionaryFormProps
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';

import {IDictionary} from 'DictionaryFactory/types';
import BreedPanelForm from './BreedPanelForm/BreedPanelForm';
import {IBreedPanel} from 'Dictionaries/models/IBreedPanel';
import {IUpdateBreedPanelRequest} from 'Admin/AdminReference/services/models/IServerBreedPanel';

function BreedPanels() {
  const {adminBreedPanels: dictionary, tests, breeds} = useDictionaries();

  const wrappedBreedPanelForm = (props: DictionaryFormProps<IDictionary<IBreedPanel, IUpdateBreedPanelRequest>>) => {
    return <BreedPanelForm {...props} testDictionary={tests} breedDictionary={breeds} />;
  };

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={wrappedBreedPanelForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IBreedPanel> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
            <Cell<IBreedPanel> dataKey="name" width="10%" header="Name" render={({name}) => name} />
            <Cell<IBreedPanel>
              dataKey="breeds"
              width="25%"
              header="Affected breeds"
              sortable={false}
              render={({breeds}) => breeds.map(x => x.name).join(', ')}
            />
            <Cell<IBreedPanel>
              dataKey="tests"
              width="25%"
              header="Tests"
              sortable={false}
              render={({tests}) => <b>{tests.map(x => x.name).join(', ')}</b>}
            />
            <Cell<IBreedPanel>
              dataKey="positionIndex"
              width="10%"
              header="Order in Report"
              sortable={false}
              render={({positionIndex}) => positionIndex}
            />
            <Cell<IBreedPanel>
              dataKey="description"
              width="20%"
              header="Description"
              sortable={false}
              render={({description}) => description}
            />
            <Cell<IBreedPanel>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(BreedPanels);
