import React, {useMemo} from 'react';
import styled, {css} from 'styled-components';

import Allele from 'OnlineReport/components/shared/Alleles';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import {IOnlineReportGroupElement} from 'OnlineReport/models/shared/IOnlineReportGroupElement';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {OnlineReportGroupType} from 'OnlineReport/components/shared/OnlineReportGroupType';
import {GeneType} from 'Admin/AdminPhenotypes/constants/GeneType';
import {PhenotypeState, PhenotypeStateName} from 'OnlineReport/constants/PhenotypeState ';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const mapHeightByVariants: Record<GeneType, number> = {
  Inconclusive: 0,
  NotTested: 0,
  XnOrXx: 0,
  Nn: 0,
  Xn: 1,
  Xx: 2,
};

const ElementHeader = styled.div<{isDisable?: boolean; isPrintable?: boolean}>`
  margin-left: ${(props) => (props.isPrintable ? 0 : props.isDisable ? 23 : 0)}px;
  margin-bottom: ${({isPrintable}) => (isPrintable ? 24 : 0)}px;
  page-break-inside: avoid;
  flex-direction: column;

  @media ${breakpoints.sm} {
    margin-left: ${(props) => (props.isPrintable ? 0 : props.isDisable ? 12 : 0)}px;
  }

  @media ${breakpoints.md} {
    flex-direction: row;
    margin-left: ${(props) => (props.isPrintable ? 0 : props.isDisable ? 35 : 23)}px;
  }

  @media print {
    margin-left: ${(props) => (props.isPrintable ? 0 : props.isDisable ? 35 : 23)}px;
    flex-direction: row;
  }
`;

const ElementHeaderInfoContainer = styled.div`
  width: 100%;
  flex-direction: column;

  @media ${breakpoints.sm} {
    width: 90%;
    flex-direction: row;
  }

  @media ${breakpoints.md} {
    width: 50%;
  }

  @media print {
    width: 50%;
  }
`;

const ElementHeaderNameStyles = css`
  font-family: ${Typography.family.ubuntu};
  font-weight: ${Typography.weight.normal400};
  font-size: 20px;
  line-height: 32px;
  width: 100%;
  outline: none;
`;

const ElementHeaderName = styled.div<{isDisable?: boolean}>`
  ${ElementHeaderNameStyles};
  color: ${(props) => (props.isDisable ? Theme.color.gray : Theme.color.black)};
`;

const ElementHeaderNameWrapper = styled(ElementHeaderName)`
  width: 100%;
  margin-bottom: 12px;

  @media ${breakpoints.sm} {
    width: 90%;
    margin-bottom: 0;
  }
  @media ${breakpoints.md} {
    width: 50%;
  }
  @media print {
    width: 50%;
  }
`;

const ElementHeaderNameLinkWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;

  @media ${breakpoints.sm} {
    width: 90%;
  }
  @media ${breakpoints.md} {
    width: 50%;
    margin-bottom: 0;
  }
  @media print {
    width: 50%;
  }
`;

const ElementHeaderNameLink = styled.a.attrs({rel: 'noopener noreferrer', target: '_blank'})<{isDisable?: boolean}>`
  ${ElementHeaderNameStyles};
  color: ${(props) => (props.isDisable ? Theme.color.gray : Theme.color.black)};
  width: fit-content;
  padding: 0;
`;

const AllelesContainer = styled.div<{maxAllelesPairCount: number}>`
  outline: none;
  width: ${({maxAllelesPairCount}) => maxAllelesPairCount * 32}px;
  justify-content: start;

  @media ${breakpoints.sm} {
    justify-content: end;
  }
  @media print {
    justify-content: end;
  }
`;

const AlleleWrapper = styled.div`
  width: 100%;
  min-width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media print {
    min-width: 0;
  }
`;

const AlleleInfo = styled.div<{isDetected: boolean; color?: string; isPrintable?: boolean}>`
  width: 176px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  text-align: end;
  justify-content: ${({isPrintable}) => (isPrintable ? 'start' : 'end')};
  color: ${({isDetected, color}) => (color ? color : isDetected ? Theme.color.black : Theme.color.gray)};

  margin-top: 12px;

  @media ${breakpoints.sm} {
    margin-top: 0;
  }

  @media print {
    justify-content: end;
  }
`;

const AlleleDetected = styled(ElementHeaderName)<{isDetected: boolean}>`
  ${(props) => !props.isDetected && `color: ${Theme.color.gray}`};
  justify-content: end;
  text-align: right;
  min-width: 120px;

  @media ${breakpoints.sm} {
    justify-content: start;
    text-align: left;
  }
`;

interface IExternalProps {
  isPrintable?: boolean;
}

interface IProps {
  groupElement: IOnlineReportGroupElement;
  groupElementType: OnlineReportGroupType;
  maxAllelesPairCount: number;
}

type AllProps = IExternalProps & IProps;

const GroupElementHeader = (props: AllProps) => {
  const {isPrintable, groupElement, maxAllelesPairCount} = props;
  const {state, traits, name, externalDescriptionUrl} = groupElement;

  const {isTablet, isMobile} = useMediaQuery();
  const isMobileView = isTablet || isMobile;

  const variantsCount = traits.reduce((acc, current) => acc + mapHeightByVariants[current.state], 0);
  const variantsText = variantsCount === 1 ? `${variantsCount} variant detected` : `${variantsCount} variants detected`;
  const isNotTested = state === PhenotypeState.NotTested;

  // const onSwitchClick = (e: React.MouseEvent) => {
  //   e.stopPropagation();
  // };

  const notNullableGeneNameList = traits.filter((i) => i.geneName && i.geneName?.length > 0).map((i) => i.geneName);
  const hasNotNullableGeneNames = notNullableGeneNameList.length > 0;

  const nullableGeneNamesCount = traits.filter((i) => !i.geneName || i.geneName?.length === 0).length;
  const hasNullableGeneNames = nullableGeneNamesCount > 0;

  const geneListText = () => {
    if (hasNotNullableGeneNames) {
      if (notNullableGeneNameList.length > 1 || hasNullableGeneNames) {
        return `${notNullableGeneNameList[0]}...`;
      }

      if (notNullableGeneNameList.length === 1) {
        return notNullableGeneNameList[0];
      }

      return hasNullableGeneNames ? `${nullableGeneNamesCount} Not Defined` : undefined;
    }

    return hasNullableGeneNames ? `${nullableGeneNamesCount} Not Defined` : undefined;
  };

  const geneListTooltipInfo =
    notNullableGeneNameList.length > 1
      ? notNullableGeneNameList.join(', ') + (hasNullableGeneNames ? ` and ${nullableGeneNamesCount} Not Defined` : '')
      : undefined;

  const renderAlleles = useMemo(() => {
    return (
      <>
        <AllelesContainer maxAllelesPairCount={maxAllelesPairCount} className="d-flex w-100">
          <Tooltip content={!isPrintable ? variantsText : undefined}>
            <div className="d-flex">
              {traits.map((trait, idx) => {
                return <Allele key={idx} state={trait.state} />;
              })}
            </div>
          </Tooltip>
        </AllelesContainer>
        {!isPrintable && <AlleleDetected isDetected={variantsCount !== 0}>{variantsText}</AlleleDetected>}
      </>
    );
  }, [isPrintable, maxAllelesPairCount, traits, variantsCount, variantsText]);

  const renderAlleleInfo = useMemo(() => {
    if (
      state !== PhenotypeState.Positive ||
      (state === PhenotypeState.Positive && !groupElement.affectionTypeResponse)
    ) {
      return (
        <AlleleInfo
          className="d-flex w-100 align-items-center"
          isDetected={state === PhenotypeState.Positive}
          isPrintable={isPrintable}
        >
          {PhenotypeStateName[state]}
        </AlleleInfo>
      );
    }

    if (!!groupElement.affectionTypeResponse) {
      const {name, description, color} = groupElement.affectionTypeResponse;
      return (
        <AlleleInfo
          className="d-flex w-100 align-items-center"
          color={color && color.length > 0 ? color : undefined}
          isDetected={true}
          isPrintable={isPrintable}
        >
          <Tooltip content={description || ''}>
            <div>{name}</div>
          </Tooltip>
        </AlleleInfo>
      );
    }

    return null;
  }, [groupElement.affectionTypeResponse, isPrintable, state]);

  const elementHeaderName = useMemo(() => {
    if (externalDescriptionUrl) {
      return (
        <ElementHeaderNameLinkWrapper>
          <ElementHeaderNameLink className="nav-link" isDisable={isNotTested} href={externalDescriptionUrl}>
            {name}
          </ElementHeaderNameLink>
        </ElementHeaderNameLinkWrapper>
      );
    }

    return <ElementHeaderNameWrapper isDisable={isNotTested}>{name}</ElementHeaderNameWrapper>;
  }, [externalDescriptionUrl, isNotTested, name]);

  return (
    <ElementHeader
      className="d-flex w-100 justify-content-end align-items-center"
      isDisable={isNotTested}
      isPrintable={isPrintable}
    >
      {elementHeaderName}
      <ElementHeaderInfoContainer className="d-flex justify-content-between align-items-center">
        <Tooltip content={geneListTooltipInfo}>
          <ElementHeaderName className="w-100" isDisable={isNotTested}>
            {geneListText()}
          </ElementHeaderName>
        </Tooltip>
        {state !== PhenotypeState.Inconclusive && state !== PhenotypeState.NotTested && (
          <>
            {isMobileView && <AlleleWrapper>{renderAlleles}</AlleleWrapper>}
            {!isMobileView && <>{renderAlleles}</>}
          </>
        )}

        {renderAlleleInfo}

        {/*TODO not supported by the backend yet*/}
        {/*<SwitchButton checked={!isPrivate} onClick={onSwitchClick} onChangeHandler={console.log} />*/}
      </ElementHeaderInfoContainer>
    </ElementHeader>
  );
};

export default GroupElementHeader;
