import React, {memo} from 'react';
import styled from 'styled-components';
import {Range as ReactRange, getTrackBackground} from 'react-range';
import {IProps as ReactRangeProps, ITrackProps, IThumbProps} from 'react-range/lib/types';

import ColorPalette from 'Common/constants/ColorPalette';
import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import FieldControlContainer from '../Layout/FieldControlContainer';

const HelperText = styled.span`
  margin-left: auto;
  color: ${ColorPalette.gray3};
`;

const TrackWrapper = styled.div`
  height: 8px;
  width: 100%;
  margin-bottom: 6px;
`;

const TrackBody = styled.div<{min: number; max: number; values: number[]}>`
  height: 8px;
  width: 100%;
  border-radius: 6px;
  background: ${p =>
    getTrackBackground({
      values: p.values,
      colors: [ColorPalette.red2, ColorPalette.gray2],
      min: p.min,
      max: p.max
    })};
  align-self: center;
`;

const ThumbBodyOuter = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${ColorPalette.red3};
`;

const ThumbBody = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${ColorPalette.red2};
`;

const ThumbBodyInner = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${ColorPalette.white0};
`;

interface IOwnProps {
  minValueText: string;
  maxValueText: string;
}

type Props = IOwnProps &
  IBaseControlProps &
  Omit<ReactRangeProps, 'renderTrack' | 'renderThumb' | 'direction' | 'allowOverlap' | 'disabled'> &
  Omit<React.HTMLAttributes<HTMLElement>, 'onChange'>;

function Range(props: Props) {
  const {onChange, values, max, min} = props;

  const renderTrack = React.useCallback(
    (params: {props: ITrackProps; children: React.ReactNode; isDragged: boolean; disabled: boolean}) => (
      <TrackWrapper
        onMouseDown={params.props.onMouseDown}
        onTouchStart={params.props.onTouchStart}
        className="d-flex"
        style={params.props.style}
      >
        <TrackBody ref={params.props.ref} min={min} max={max} values={values}>
          {params.children}
        </TrackBody>
      </TrackWrapper>
    ),
    [values, max, min]
  );

  return (
    <FieldControlContainer {...props} style={{marginTop: 25, marginBottom: 45}}>
      <ReactRange renderTrack={renderTrack} renderThumb={renderThumb} onChange={onChange} values={values} />
      <div className="d-flex">
        <HelperText>{props.minValueText}</HelperText>
        <HelperText>{props.maxValueText}</HelperText>
      </div>
    </FieldControlContainer>
  );
}

function renderThumb(params: {props: IThumbProps; value: number; index: number; isDragged: boolean}) {
  return params.isDragged ? (
    <ThumbBodyOuter {...params.props} className="d-flex align-items-center justify-content-center">
      <ThumbBody className="d-flex align-items-center justify-content-center">
        <ThumbBodyInner />
      </ThumbBody>
    </ThumbBodyOuter>
  ) : (
    <ThumbBody {...params.props} className="d-flex align-items-center justify-content-center">
      <ThumbBodyInner />
    </ThumbBody>
  );
}

export default memo(Range);
export type RangeProps = Props;
