import {combineReducers, Reducer} from 'redux';

import {IImageState} from '../types';
import {communicationImageReducer} from './communications';
import {dataImageReducer} from './data';

export const imageReducer: Reducer<IImageState> = combineReducers<IImageState>({
  communications: communicationImageReducer,
  data: dataImageReducer
});
