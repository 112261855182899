import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';

import {ImageUploaderField, InputField, TextAreaField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {IDictionary} from 'DictionaryFactory/types';
import {RequiredBy} from 'Common/types';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {
  convertToFormValues,
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/AbilityGroupsAdvanced/AbilityGroupAdvancedForm/validation';
import {IAdminAbilityGroupAdvanced} from 'Admin/AdminPhenotypes/models/IAdminAbilityGroupAdvanced';
import {IUpdateAbilityGroupAdvancedRequest} from 'Admin/AdminPhenotypes/service/models/IServerAbilityGroupAdvanced';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {IconName} from 'Icon/components/Icon';
import {actions, selectors as imageSelectors} from 'Admin/AdminPhenotypes/store/adminPhenotypeGroupImage/index';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {AdminPhenotypeGroupImageModule} from 'Admin/AdminPhenotypes/store/adminPhenotypeGroupImage/adminPhenotypeGroupImageModule';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

interface IOwnProps
  extends IPhenotypeFormExternalProps<IAdminAbilityGroupAdvanced, IUpdateAbilityGroupAdvancedRequest> {
  dictionary: IDictionary<IAdminAbilityGroupAdvanced, IUpdateAbilityGroupAdvancedRequest>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function AbilityGroupAdvancedForm(props: Props) {
  const {itemId, item, dictionary, image, getImage, imageDeleting, imageLoading, imageUploading, ...rest} = props;
  const {getItem} = dictionary.actions;

  useEffect(() => {
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem]);

  useEffect(() => {
    item?.image && getImage(item.image.id);
  }, [getImage, item]);

  return (
    <DictionaryForm {...rest}>
      <InputField name="name" label="Name" placeholder="Name" />
      <InputField name="positionIndex" label="Order in Report" placeholder="Order in Report" className="w-50" />
      <FieldControlContainer label="Image">
        <ImageUploaderField
          name="image"
          iconProps={{name: IconName.RearedHorse, width: 48, height: 60}}
          title="Add image"
        />
      </FieldControlContainer>
      <TextAreaField name="description.value" label="Description" placeholder="Description" />
    </DictionaryForm>
  );
}

const AbilityGroupAdvancedFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting ? convertToFormValues(item) : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary, image, uploadImage, getImage, deleteImage} = formikBag.props;

    const uploadPhenotypeImage = async (file: File) => {
      const formData = new FormData();
      formData.append('image', file);
      return uploadImage(formData);
    };

    formikBag.setSubmitting(true);

    if (values.image?.file) {
      const imageId = await uploadPhenotypeImage(values.image.file);
      values.image = await getImage(imageId);
    }

    if (!values.image && image) {
      await deleteImage(image.id);
    }

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(values);
    }

    if (type === FormType.edit) {
      await updateItem(values as RequiredBy<IFormValues, 'id'>);
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(AbilityGroupAdvancedForm);

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  return {
    image: imageSelectors.selectPhenotypeGroupImage(state),
    imageLoading: imageSelectors.selectCommunication(state, 'imageLoading'),
    imageUploading: imageSelectors.selectCommunication(state, 'imageUploading'),
    imageDeleting: imageSelectors.selectCommunication(state, 'imageDeleting'),
    ...makePhenotypeFormSelectors(state, ownProps.dictionary),
  };
};

const mapDispatchToProps = {
  getImage: actions.getPhenotypeGroupImage,
  uploadImage: actions.uploadPhenotypeGroupImage,
  deleteImage: actions.deletePhenotypeGroupImage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(AbilityGroupAdvancedFormFormik));
export default withDynamicModules(Connected, [AdminPhenotypeGroupImageModule]);
