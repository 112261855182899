import React, {memo} from 'react';
import {IAdminAbilityRule} from 'Admin/AdminPhenotypes/models/IAdminAbilityRule';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout, {
  DictionaryFormProps
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';

import {IDictionary} from 'DictionaryFactory/types';
import {IUpdateAbilityRuleRequest} from 'Admin/AdminPhenotypes/service/models/IServerAbilityRule';
import AbilityRulesForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/AbilityRules/AbilityRuleForm/AbilityRuleForm';
import {getRuleIcon} from 'Admin/AdminPhenotypes/helpers/getRuleIcon';

function AbilityRules() {
  const {adminAbilityRules: dictionary, abilityGroupsAdvanced, aggregatedAbilities, affectionTypes} = useDictionaries();

  const wrappedAbilityRuleForm = (
    props: DictionaryFormProps<IDictionary<IAdminAbilityRule, IUpdateAbilityRuleRequest>>
  ) => {
    return (
      <AbilityRulesForm
        {...props}
        affectionTypeDictionary={affectionTypes}
        aggregatedAbilityDictionary={aggregatedAbilities}
        abilityGroupDictionary={abilityGroupsAdvanced}
      />
    );
  };

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={wrappedAbilityRuleForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IAdminAbilityRule> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
            <Cell<IAdminAbilityRule> dataKey="name" width="15%" header="Name" render={({name}) => name} />
            <Cell<IAdminAbilityRule>
              dataKey="positionIndex"
              width="5%"
              header="Order in Report"
              sortable={false}
              render={({positionIndex}) => positionIndex}
            />
            <Cell<IAdminAbilityRule>
              dataKey="affectionType"
              width="10%"
              header="Affection type"
              render={({affectionType}) => affectionType?.name}
            />
            <Cell<IAdminAbilityRule>
              dataKey="aggregator"
              width="7%"
              header="Ability"
              sortable={false}
              render={({aggregator}) => aggregator?.name}
            />
            <Cell<IAdminAbilityRule> dataKey="group" width="7%" header="Group" render={({group}) => group?.name} />
            <Cell<IAdminAbilityRule>
              dataKey="abilityType"
              width="10%"
              header="Type"
              render={({abilityType}) => abilityType}
            />
            <Cell<IAdminAbilityRule>
              dataKey="abilityState"
              width="7%"
              header="State"
              render={({abilityState}) => abilityState}
            />
            <Cell<IAdminAbilityRule>
              header="Rule"
              sortable={false}
              render={({sequences}) => getRuleIcon(sequences)}
              width="5%"
            />
            <Cell<IAdminAbilityRule>
              dataKey="description"
              width="15%"
              header="Description"
              sortable={false}
              render={({description}) => description?.value}
            />
            <Cell<IAdminAbilityRule>
              dataKey="externalDescriptionUrl"
              width="10%"
              header="URL"
              sortable={false}
              render={({externalDescriptionUrl}) => externalDescriptionUrl}
            />
            <Cell<IAdminAbilityRule>
              header="Actions"
              align={CellAlign.Right}
              width="100"
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(AbilityRules);
