import {IGenePoolsRequest} from 'Filters/models/GenePools';
import {IGenePoolServerRequest} from '../serverModel/IGenePoolServerRequest';
import {LocationUnit} from 'Filters/constants/LocationUnit';
import {IDistance} from 'Filters/models/Distance';

const DEFAULT_DISTANCE: IDistance = {value: 0, unit: LocationUnit.Km};

export function convertClientGenePoolsRequestToServer(clientRequest: IGenePoolsRequest): IGenePoolServerRequest {
  const genePools: IGenePoolServerRequest = {
    ...clientRequest,
    distance: clientRequest.distance || DEFAULT_DISTANCE,
    associations: clientRequest.associations || []
  };

  return genePools;
}
