import {AxiosPromise} from 'axios';

import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IObjectResponse} from 'Common/models/IResponse';
import {IUserPrintableReportPreferences} from 'OnlineReportPrintable/models/IUserPrintableReportPreferences';
import {IUserReportSummaryVisbility} from 'OnlineReportPrintable/models/IUserReportSummaryVisibility';
import {IUserPrintableReportPreferencesPreferences} from 'OnlineReportPrintable/models/IUserPrintableReportPreferencesPreferences';

const getAllPreferences = (): AxiosPromise<IObjectResponse<IUserPrintableReportPreferences>> => {
  return axiosWrapper.get<IObjectResponse<IUserPrintableReportPreferences>>(`/PrintableReportPreferences`);
};

const updateSummaryVisibility = (data: IUserReportSummaryVisbility): AxiosPromise<IObjectResponse<void>> => {
  return axiosWrapper.put<IObjectResponse<void>>(`/PrintableReportPreferencesSummaryUpdate`, data);
};

const hidePreferences = (data: IUserPrintableReportPreferencesPreferences): AxiosPromise<IObjectResponse<void>> => {
  return axiosWrapper.post<IObjectResponse<void>>(`/PrintableReportPreferencesHide`, data);
};

const showPreferences = (data: IUserPrintableReportPreferencesPreferences): AxiosPromise<IObjectResponse<void>> => {
  return axiosWrapper.post<IObjectResponse<void>>(`/PrintableReportPreferencesShow`, data);
};

export default {
  getAllPreferences,
  updateSummaryVisibility,
  hidePreferences,
  showPreferences,
};
