import {datadogLogs} from '@datadog/browser-logs';

import {InjectedStripeProps} from 'Common/helpers/withStripe';
import {convertToLoggerContext} from 'Common/helpers/convertToLoggerContext';

interface IHandlerArgs extends InjectedStripeProps {
  paymentId: string;
  setIsLoading(isLoading: boolean): void;
  setError(error: string): void;
  onSuccess(): void;
}

function getStripeErrorMessage(error: stripe.Error) {
  return error.message || 'unknown error';
}

export function getStripeSubmitHandler(args: IHandlerArgs) {
  const {paymentId, setIsLoading, setError, stripe, elements, onSuccess} = args;
  return async () => {
    try {
      if (!stripe || !elements) {
        datadogLogs.logger.error(
          "Invalid state at order's payment",
          convertToLoggerContext({stripe: !!stripe, elements: !!elements})
        );
        throw Error(`Invalid state at order's payment`);
      }
      if (!paymentId) {
        datadogLogs.logger.error('Empty paymentIntentId', convertToLoggerContext({paymentId: !!paymentId}));
        throw Error(`Empty paymentIntentId`);
      }
      setError('');
      setIsLoading(true);

      const {error: stripeError} = await stripe.handleCardPayment(paymentId);

      if (stripeError) {
        setError(getStripeErrorMessage(stripeError));
        datadogLogs.logger.error('Stripe error', convertToLoggerContext(stripeError));
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      onSuccess();
    } catch (e) {
      setError(getStripeErrorMessage(e));
      datadogLogs.logger.error('Stripe error exception', convertToLoggerContext(e));
      setIsLoading(false);
    }
  };
}
