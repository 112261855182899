import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';
import {IFilterProperty} from 'FacetFilter/models/IFilterProperty';
import {IFilterPropertyOperations} from 'FacetFilter/models/IFilterPropertyOperator';

const STATE_NAME = 'facetFilter';

export interface IFacetFilterState {
  data: {
    filterPropertyOperations: IFilterPropertyOperations[];
    packagesFilters: IFilterProperty[];
  };
  communications: {
    filterPropertyOperationsLoading: ICommunication;
    packageFiltersLoading: ICommunication;
  };
}

export const getFilterPropertyOperationsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_FILTER_PROPERTY_OPERATIONS'
);
export type getFilterPropertyOperationsActions = MakeCommunicationActions<
  typeof getFilterPropertyOperationsActionTypes,
  {success: IFilterPropertyOperations}
>;

export const getPackagesFiltersActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_PACKAGES_FILTERS');
export type getPackagesFiltersActions = MakeCommunicationActions<
  typeof getPackagesFiltersActionTypes,
  {success: IFilterProperty[]}
>;

export type Actions = getFilterPropertyOperationsActions | getPackagesFiltersActions;
