import React from 'react';
import styled from 'styled-components';
import Typography from 'Common/constants/Typography';
import classNames from 'classnames';
import RequiredMark from 'Common/helpers/RequiredMark';

const ControlContainer = styled.div`
  padding-bottom: 8px;
`;

const SectionTitle = styled.div`
  font-family: ${Typography.family.openSans};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size18};
  line-height: 25px;

  margin-bottom: 16px;
`;

interface IProps {
  title?: string;
  titleStyle?: React.CSSProperties | undefined;
  isRequired?: boolean;
}

type AllProps = IProps & React.HTMLAttributes<HTMLDivElement>;

const FormControlContainer = (props: AllProps) => {
  const {className, style, title, titleStyle, children, isRequired} = props;
  return (
    <ControlContainer className={classNames(className)} style={style}>
      {title && (
        <SectionTitle style={titleStyle}>
          <span>{title}</span> <RequiredMark isRequired={isRequired} />
        </SectionTitle>
      )}
      {children}
    </ControlContainer>
  );
};

export default FormControlContainer;
