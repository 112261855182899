import React from 'react';
import Dropdown, {DropdownProps} from 'react-bootstrap/Dropdown';
import styled from 'styled-components';

import {RenderToggle, Toggle} from 'Common/components/DropDown/common/components';
import {Menu} from 'Common/components/DropDown/common/styles';

const InfoWrapper = styled(Menu)`
  padding: 20px 24px;
`;

interface IDropdownInfo {
  info: string;
  children: RenderToggle;
  alignRight?: DropdownProps['alignRight'];
  style?: React.CSSProperties;
}

export function DropDownInfo(props: IDropdownInfo) {
  const {children, info, style, alignRight} = props;
  return (
    <Dropdown alignRight={alignRight}>
      <Dropdown.Toggle as={Toggle as any} id="dropdown-custom-components">
        {children}
      </Dropdown.Toggle>
      <InfoWrapper style={style}>{info}</InfoWrapper>
    </Dropdown>
  );
}
