import React from 'react';
import {connect} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IDistributor} from './IDistributor';
import {SelectedStrategy} from './SelectedStrategy';
import StrategyService from './StrategyService';

function withDistributorStrategy<S, D, E = {}>(
  Component: React.ComponentType<any>,
  contextDistributor: Record<SelectedStrategy, IDistributor<S, D, E>>,
  strategy?: SelectedStrategy
) {
  const currentStrategy = StrategyService.getStrategy();
  const selectedStrategy = strategy || currentStrategy;

  const distributor = selectedStrategy ? contextDistributor[selectedStrategy] : null;

  const mapStateToProps = (state: IAppState, externalProps?: E) => ({...distributor?.state(state, externalProps)});
  const mapDispatchToProps = {...distributor?.dispatch};
  const connector = connect(mapStateToProps, mapDispatchToProps);

  const wrapped = (props: any) => <Component {...props} />;
  wrapped.displayName = `withDistributorStrategy(${Component.displayName || Component.name || 'Component'})`;
  const Connected = connector(wrapped);
  return !distributor?.modules ? Connected : withDynamicModules(Connected, distributor?.modules);
}

export default withDistributorStrategy;
