import {IHorseTransferAdmin} from 'Admin/AdminDashboard/models/Transfer/IHorseTransferAdmin';
import {IHorseTransferDetailsAdmin} from 'Admin/AdminDashboard/models/Transfer/IHorseTransferDetailsAdmin';
import {convertUserName} from 'Common/helpers/convertUserName';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {withAvatar} from 'Common/helpers/withAvatar';
import {withUserName} from 'Common/helpers/withUserName';
import {IServerHorseTransferAdmin, IServerHorseTransferDetails} from '../types/transfer';

export function convertHorseTransersAdminToClient(horseTranser: IServerHorseTransferAdmin): IHorseTransferAdmin {
  return {
    ...horseTranser,
    createDate: convertUTCToClientDate(horseTranser.createDate) || '',
    horse: withAvatar(horseTranser.horse),
    owner: withUserName(withAvatar(horseTranser.owner)),
    providedName: convertUserName(horseTranser.providedFirstName, horseTranser.providedLastName),
  };
}

export function convertHorseTranserDetailsAdminToClient(
  horseTranser: IServerHorseTransferDetails
): IHorseTransferDetailsAdmin {
  return {
    ...horseTranser,
    createDate: convertUTCToClientDate(horseTranser.createDate) || '',
    horse: withAvatar(horseTranser.horse),
    owner: withUserName(withAvatar(horseTranser.owner)),
    providedName: convertUserName(horseTranser.providedFirstName, horseTranser.providedLastName),
  };
}
