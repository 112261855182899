import {action} from 'typesafe-actions';

import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {
  changeAdminAssociationPackageStatusActions,
  changeAdminAssociationPackageStatusActionTypes,
  createAdminAssociationPackageActions,
  createAdminAssociationPackageActionTypes,
  deleteAdminAssociationPackageActions,
  deleteAdminAssociationPackageActionTypes,
  getAdminAssociationPackageByIdActions,
  getAdminAssociationPackageByIdActionTypes,
  getAdminAssociationPackagesActions,
  getAdminAssociationPackagesActionTypes,
  resetAdminAssociationPackageDetailsActions,
  resetAdminAssociationPackageDetailsActionTypes,
  updateAdminAssociationPackageActions,
  updateAdminAssociationPackageActionTypes,
  updateAdminAssociationPackageCartSettingsActions,
  updateAdminAssociationPackageCartSettingsActionTypes,
} from './types';
import {IRequestParams} from 'Common/models/IRequestParams';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import AdminPackageDataService from 'Admin/AdminAssociations/services/AdminPackageDataService';
import {IAdminAssociationPackageRequest} from 'Admin/AdminAssociations/models/IAdminAssociationPackage';
import {IPackageCartSettingsRequest} from 'Admin/AdminDashboard/models/Package/IPackageAdmin';

export const getPackages =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getAdminAssociationPackagesActions> =>
  async (dispatch) => {
    dispatch(action(getAdminAssociationPackagesActionTypes.REQUEST));
    try {
      const res = await AdminPackageDataService.getPackages(params);
      dispatch(action(getAdminAssociationPackagesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAdminAssociationPackagesActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };

export const getPackageById =
  (packageId: number): ActionResult<IAppState, void, getAdminAssociationPackageByIdActions> =>
  async (dispatch) => {
    dispatch(action(getAdminAssociationPackageByIdActionTypes.REQUEST));
    try {
      const result = await AdminPackageDataService.getPackage(packageId);
      dispatch(action(getAdminAssociationPackageByIdActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getAdminAssociationPackageByIdActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createPackage =
  (params: IAdminAssociationPackageRequest): ActionResult<IAppState, void, createAdminAssociationPackageActions> =>
  async (dispatch) => {
    dispatch(action(createAdminAssociationPackageActionTypes.REQUEST));
    try {
      await AdminPackageDataService.createPackage(params);
      dispatch(action(createAdminAssociationPackageActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createAdminAssociationPackageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updatePackage =
  (params: IAdminAssociationPackageRequest): ActionResult<IAppState, void, updateAdminAssociationPackageActions> =>
  async (dispatch) => {
    dispatch(action(updateAdminAssociationPackageActionTypes.REQUEST));
    try {
      const result = await AdminPackageDataService.updatePackage(params);
      dispatch(action(updateAdminAssociationPackageActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(updateAdminAssociationPackageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deletePackage =
  (id: number): ActionResult<IAppState, void, deleteAdminAssociationPackageActions> =>
  async (dispatch) => {
    dispatch(action(deleteAdminAssociationPackageActionTypes.REQUEST));
    try {
      await AdminPackageDataService.deletePackage(id);
      dispatch(action(deleteAdminAssociationPackageActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteAdminAssociationPackageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const changePackageStatus =
  (packageId: number, isEnabled: boolean): ActionResult<IAppState, void, changeAdminAssociationPackageStatusActions> =>
  async (dispatch) => {
    dispatch(action(changeAdminAssociationPackageStatusActionTypes.REQUEST));
    try {
      await AdminPackageDataService.changePackageStatus(packageId, isEnabled);
      dispatch(action(changeAdminAssociationPackageStatusActionTypes.SUCCESS, {packageId}));
    } catch (error) {
      dispatch(action(changeAdminAssociationPackageStatusActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updatePackageCartSettings =
  (
    packageCartSettings: IPackageCartSettingsRequest
  ): ActionResult<IAppState, void, updateAdminAssociationPackageCartSettingsActions> =>
  async (dispatch) => {
    dispatch(action(updateAdminAssociationPackageCartSettingsActionTypes.REQUEST));
    try {
      await AdminPackageDataService.updatePackageCartSettings(packageCartSettings);
      dispatch(action(updateAdminAssociationPackageCartSettingsActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateAdminAssociationPackageCartSettingsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetPackageDetailResults =
  (): ActionResult<IAppState, void, resetAdminAssociationPackageDetailsActions> => async (dispatch) => {
    dispatch(action(resetAdminAssociationPackageDetailsActionTypes.RESET));
  };
