import {IModule, IModuleStore} from 'redux-dynamic-modules';
import {createAsyncAction, getType, PayloadAction} from 'typesafe-actions';
import {bindActionCreators, combineReducers, Reducer} from 'redux';

import {makeCommunicationReducer} from 'Common/store/utils/communication';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IObjectResponse} from 'Common/models/IResponse';

import {createActionThunk} from './createActionThunk';
import {CommunicationReducer, ICommunicationAction, IFactoryParameters} from './types/shared';

import {
  ISimpleDictionary,
  ISimpleDictionaryActions,
  ISimpleDictionaryCommunicationState,
  ISimpleDictionaryDataState,
  ISimpleDictionaryReduxActions,
  ISimpleDictionarySelectors,
  ISimpleDictionaryState,
  SimpleDictionaryModuleState,
} from './types/simpleDictionary';

class SimpleDictionaryFactory {
  private isInitialized: Promise<void>;
  private completeInitialize = () => {};
  private reduxStore?: IModuleStore<unknown>;

  constructor() {
    this.isInitialized = new Promise<void>((resolve) => (this.completeInitialize = resolve));
  }

  public init(store: IModuleStore<unknown>) {
    this.reduxStore = store;
    this.completeInitialize();
  }

  public async create<Item, ServerModel>(
    params: IFactoryParameters<Item, ServerModel>
  ): Promise<ISimpleDictionary<Item>> {
    await this.isInitialized;
    return this.createStore<Item, ServerModel>(params);
  }

  private createStore<Item, ServerModel>({
    rootType,
    apiPath,
    convertListToClient,
    convertToClient,
    isRememberValues = false,
  }: IFactoryParameters<Item, ServerModel>): ISimpleDictionary<Item> {
    const {reducer, actions, selectors} = this.createModule<Item, ServerModel>(
      rootType,
      apiPath,
      isRememberValues,
      convertToClient,
      convertListToClient
    );
    const dictionary: IModule<{[key in typeof rootType]: ISimpleDictionaryState<Item>}> = {
      id: rootType,
      reducerMap: {
        [rootType]: reducer,
      } as any,
    };
    this.reduxStore!.addModule(dictionary);

    return {actions, selectors};
  }

  private createModule<T, S>(
    rootType: string,
    apiPath: string,
    isRememberValues: boolean,
    convertToClient: (serverModel: S) => T,
    convertListToClient?: (serverList: T[]) => T[]
  ) {
    const reduxActions = this.createActions<T>(rootType);
    const reducer = this.createReducer<T>(reduxActions);
    const selectors = this.createSelectors<T>(rootType);
    const actions = this.createThunks<T, S>(
      apiPath,
      reduxActions,
      selectors,
      isRememberValues,
      convertToClient,
      convertListToClient
    );
    return {reducer, actions, selectors};
  }

  private createActions<T>(rootType: string): ISimpleDictionaryReduxActions<T> {
    const getItems = createAsyncAction(
      [`${rootType}_REQUEST`, () => undefined],
      [`${rootType}_SUCCESS`, (res: T[]) => res],
      [`${rootType}_FAILURE`, (err: string) => err]
    )();

    return {getItems};
  }

  private createReducer<T>(actions: ISimpleDictionaryReduxActions<T>): Reducer<ISimpleDictionaryState<T>> {
    const initialDataState: ISimpleDictionaryDataState<T> = {
      items: [],
    };
    const dataReducer: Reducer<ISimpleDictionaryDataState<T>, PayloadAction<string, T[]>> = (
      state = initialDataState,
      action
    ) => {
      switch (action.type) {
        case getType(actions.getItems.success): {
          return {...state, items: action.payload};
        }
        default: {
          return state;
        }
      }
    };

    const getReducer = (action: ICommunicationAction<T[]>) =>
      makeCommunicationReducer<CommunicationReducer<T>>({
        requestType: getType(action.request),
        successType: getType(action.success),
        failureType: getType(action.failure),
      });
    const communicationReducer = combineReducers<ISimpleDictionaryCommunicationState>({
      itemsLoading: getReducer(actions.getItems),
    });

    return combineReducers({
      data: dataReducer,
      communication: communicationReducer,
    });
  }

  private createSelectors<T>(rootType: string): ISimpleDictionarySelectors<T> {
    const selectItems = (state: SimpleDictionaryModuleState<T>) =>
      (state[rootType] as {data: ISimpleDictionaryDataState<T>}).data.items;

    const selectCommunication = (
      state: SimpleDictionaryModuleState<T>,
      key: keyof ISimpleDictionaryCommunicationState
    ) => (state[rootType] as {communication: ISimpleDictionaryCommunicationState}).communication[key];

    return {selectItems, selectCommunication};
  }

  private createThunks<T, S>(
    apiPath: string,
    actions: ISimpleDictionaryReduxActions<T>,
    selectors: ISimpleDictionarySelectors<T>,
    isRememberValues: boolean,
    converter: (serverModel: S) => T,
    listConverter?: (serverList: T[]) => T[]
  ): ISimpleDictionaryActions {
    const getItemsMethod = async (id?: number) => {
      const api = !!id ? `${apiPath}/${id}` : apiPath;
      const response = await axiosWrapper.get<IObjectResponse<S[]>>(api);
      const convertedListObjects = response.data.data.map(converter);
      return listConverter ? listConverter(convertedListObjects) : convertedListObjects;
    };
    const {dispatch, getState} = this.reduxStore!;

    const thunk = createActionThunk<number | undefined, T[]>(getItemsMethod, actions.getItems);

    const getItems = ((id?: number) => {
      const isAlreadyExist = selectors.selectItems(getState() as SimpleDictionaryModuleState<T>)?.length > 0;
      if (isRememberValues && isAlreadyExist) {
        return () => undefined;
      }
      return thunk(id);
    }) as () => void;

    return bindActionCreators(
      {
        getItems,
      },
      dispatch
    );
  }
}

export default new SimpleDictionaryFactory();
