import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IUserHorseOrder, IHorseReport} from 'Horse/models/IUserHorse';
import {IServerUserHorseOrderShort} from './types/horse';
import {convertHorseLastOrderToClient} from './converters/horse';
import {IShortAbility} from 'Dictionaries/models/IAbilities';
import {IGenotypeGroup} from 'Genotype/models/IGenotypeGroup';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {IHorseOwner} from 'Horse/models/IHorseOwner';
import {withImage} from 'Common/helpers/withImage';
import {IServerHorseOwner} from 'Horse/services/types/owner';
import {withUserName} from 'Common/helpers/withUserName';

const getHorseParantageReport = async (horseId: number): Promise<IObjectResponse<IHorseReport>> => {
  const response = await axiosWrapper.get<IObjectResponse<IHorseReport>>(`/HorseParentageReportAdmin/${horseId}`);
  return response.data;
};

const getHorseAbilities = async (horseId: number): Promise<IListResponse<IShortAbility>> => {
  const response = await axiosWrapper.get<IListResponse<IShortAbility>>(`/AssociationHorseAbilitiesAdmin/${horseId}`);
  return response.data;
};

const getHorseGenotypeGroups = async (horseId: number): Promise<IListResponse<IGenotypeGroup>> => {
  const response = await axiosWrapper.get<IListResponse<IGenotypeGroup>>(
    `/AssociationHorseGenotypeGroupsAdmin/${horseId}`
  );
  return {...response.data, data: response.data.data.map(withImage)};
};

const getHorseBreedPanel = async (horseId: number): Promise<IListResponse<IHorseBreedPanel>> => {
  const response = await axiosWrapper.get<IListResponse<IHorseBreedPanel>>(`/AssociationHorsePanelsAdmin/${horseId}`);
  return response.data;
};

const getHorseLastOrder = async (horseId: number): Promise<IUserHorseOrder> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerUserHorseOrderShort>>(
    `/AssociationHorseLastOrderAdmin/${horseId}`
  );
  return convertHorseLastOrderToClient(response.data.data);
};

const getHorseOwner = async (horseId: number): Promise<IHorseOwner> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerHorseOwner>>(`/HorseOwnerAdmin/${horseId}`);
  return withUserName(response.data.data);
};

const getOwnerHorses = async (horseId: number): Promise<IListResponse<IOrderHorse>> => {
  const response = await axiosWrapper.get<IListResponse<IOrderHorse>>(`/HorseProfileHorsesAdmin/${horseId}`);
  return {...response.data, data: response.data.data.map(withBirthDate)};
};

export default {
  getHorseAbilities,
  getHorseParantageReport,
  getHorseGenotypeGroups,
  getHorseBreedPanel,
  getHorseLastOrder,
  getHorseOwner,
  getOwnerHorses,
};
