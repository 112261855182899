import React, {memo} from 'react';
import styled from 'styled-components';

import {OrderStatus} from 'Common/constants/OrderStatus';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon, {IColoredIconProps as IconProps} from 'Icon/components/ColoredIcon';
import ColorPalette from 'Common/constants/ColorPalette';
import ClickAwayListener from 'Common/helpers/ClickAwayListener';
import withoutProps from 'Common/helpers/WithoutProps';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {zIndex} from 'Common/constants/ZIndex';
import {actionLabelByStatus, statuses, statusOrdering} from '../constants';
import IconedOrderStatus from './IconedOrderStatus';
import {convertCompletedStatuses} from '../helpers';
import {StatusLabel} from './styled';
import {IOrderStatusUpdateDateField} from 'Order/models/IOrderStatusUpdateDateField';
import OrderUpdateDate from './OrderUpdateDate';

export interface IAdditionalButton {
  name: string;
  onClick(): void;
}

const toggleProps: IconProps = {
  size: 12,
  name: IconName.ArrowDown,
  color: ColorPalette.gray44,
  fill: true,
  stroke: false,
};

const OrderStatusMenuRoot = styled.div`
  position: relative;
`;

const Status = styled.div<{clickable?: boolean}>`
  cursor: ${({clickable}) => (clickable ? 'pointer' : 'unset')};
`;

const StatusList = styled.div`
  min-width: 256px;
  z-index: ${zIndex.modal - 1};
  padding: 20px 22px 36px;
  position: absolute;
  top: -20px;
  left: -22px;
  background: ${ColorPalette.white0};
  box-shadow: 0px 0px 20px ${ColorPalette.transparent2};
  border-radius: 4px;
`;

const ToggleIcon = styled(withoutProps(['isOpen'])(ColoredIcon))<{isOpen: boolean}>`
  transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'unset')};
`;

const Divider = styled.div<{small?: boolean; height?: number}>`
  width: 1px;
  height: ${({small, height}) => height || (small ? 15 : 23)}px;
  margin: 6px 0 6px 10px;
  background: ${ColorPalette.gray24};
`;

const ActionButton = styled(PrimaryButton)<{isAdditional?: boolean}>`
  width: 100%;
  padding: 0 4px;
  margin-top: ${({isAdditional}) => (isAdditional ? '8px' : 0)};
`;

interface IProps {
  status: OrderStatus;
  completedStatuses?: OrderStatus | OrderStatus[];
  uncompletedStatuses?: OrderStatus | OrderStatus[];
  onChangeOrderStatus(): void;
  additionalButtons?: Partial<Record<OrderStatus, IAdditionalButton>>;
  statusUpdateDates: IOrderStatusUpdateDateField;
}

function OrderStatusDropdownView(props: IProps) {
  const {status, onChangeOrderStatus, uncompletedStatuses, completedStatuses, additionalButtons, statusUpdateDates} =
    props;

  const [isOpen, setIsOpen] = React.useState(false);

  const completedStatusesNumber = completedStatuses ? convertCompletedStatuses(completedStatuses) : [];
  const uncompletedStatusesNumber = uncompletedStatuses ? convertCompletedStatuses(uncompletedStatuses) : [];

  const isResultReady = false;
  const openMenu = React.useCallback(() => !isResultReady && setIsOpen(true), [isResultReady]);
  const closeMenu = React.useCallback(() => setIsOpen(false), []);

  const onActionClick = React.useCallback(() => {
    closeMenu();
    onChangeOrderStatus && onChangeOrderStatus();
  }, [closeMenu, onChangeOrderStatus]);

  return (
    <OrderStatusMenuRoot>
      <Status className="d-flex align-items-center" onClick={openMenu} clickable={!isResultReady}>
        <IconedOrderStatus status={status} />
        {!isResultReady && <ToggleIcon {...toggleProps} isOpen={false} />}
      </Status>
      {isOpen && (
        <ClickAwayListener onClickAway={closeMenu}>
          <StatusList>
            {statuses.slice(statusOrdering[status]).map(({status, icon, label, color, statusUpdateDate}, i, arr) => {
              const isFirst = i === 0;
              const isLast = i === arr.length - 1;
              const originIndex = i + statusOrdering[status];
              const isCompleted = completedStatusesNumber.includes(originIndex) || isFirst;
              const isUncompleted = uncompletedStatusesNumber.includes(i);
              const hasAdditionalButtons = !!additionalButtons?.[status];

              const coloredIconColor = isUncompleted ? ColorPalette.gray48 : isCompleted ? color : ColorPalette.gray48;
              const statusLabelColor = isUncompleted
                ? ColorPalette.gray48
                : isCompleted
                ? ColorPalette.black1
                : ColorPalette.gray48;

              let counts = 0;
              statusUpdateDate &&
                statusUpdateDates &&
                statusUpdateDate.length > 0 &&
                statusUpdateDate.forEach((date) => {
                  if (statusUpdateDates[date]) {
                    counts++;
                  }
                });
              const dividerHeightExt = counts > 1 ? counts * 16 - 4 : 23;
              const dividerHeightExtFirst = counts > 1 ? counts * 16 - 4 : 15;

              return (
                <React.Fragment key={label}>
                  <div className="position-relative">
                    <Status
                      className="d-flex align-items-center"
                      onClick={isFirst ? closeMenu : undefined}
                      clickable={isFirst}
                    >
                      <ColoredIcon name={icon} color={coloredIconColor} fill={true} stroke={false} />
                      <StatusLabel color={statusLabelColor}>{label}</StatusLabel>
                      {isFirst && <ToggleIcon {...toggleProps} isOpen={true} />}
                    </Status>
                    <OrderUpdateDate statusUpdateDate={statusUpdateDate} statusUpdateDates={statusUpdateDates} />
                  </div>

                  {!isFirst && !isLast && <Divider height={dividerHeightExt} />}
                  {isFirst && !isLast && onChangeOrderStatus && (
                    <>
                      <Divider height={dividerHeightExtFirst} />
                      <ActionButton size="medium" onClick={onActionClick}>
                        {actionLabelByStatus[status]}
                      </ActionButton>
                      {hasAdditionalButtons && (
                        <ActionButton
                          isAdditional={true}
                          variant="outlined"
                          onClick={additionalButtons?.[status]?.onClick}
                        >
                          {additionalButtons?.[status]?.name}
                        </ActionButton>
                      )}
                      <Divider small={true} />
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </StatusList>
        </ClickAwayListener>
      )}
    </OrderStatusMenuRoot>
  );
}

export default memo(OrderStatusDropdownView);
