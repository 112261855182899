import React, {memo} from 'react';
import {IAdminDangerAdvanced} from 'Admin/AdminPhenotypes/models/IAdminDangerAdvanced';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout, {
  DictionaryFormProps
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';

import {IDictionary} from 'DictionaryFactory/types';
import {IUpdateDangerAdvancedRequest} from 'Admin/AdminPhenotypes/service/models/IServerDangerAdvanced';
import DangerAdvancedForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/DangersAdvanced/DangerAdvancedForm/DangerAdvancedForm';
import {getRuleIcon} from 'Admin/AdminPhenotypes/helpers/getRuleIcon';

function DangersAdvanced() {
  const {adminDangerAdvanced: dictionary, dangerGroupsAdvanced} = useDictionaries();

  const wrappedGeneForm = (
    props: DictionaryFormProps<IDictionary<IAdminDangerAdvanced, IUpdateDangerAdvancedRequest>>
  ) => {
    return <DangerAdvancedForm {...props} dangerGroupDictionary={dangerGroupsAdvanced} />;
  };

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={wrappedGeneForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IAdminDangerAdvanced> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
            <Cell<IAdminDangerAdvanced> dataKey="name" width="20%" header="Name" render={({name}) => name} />
            <Cell<IAdminDangerAdvanced>
              dataKey="group"
              width="20%"
              header="Group"
              sortable={false}
              render={({group}) => group?.name}
            />
            <Cell<IAdminDangerAdvanced> header="Rule" render={({sequences}) => getRuleIcon(sequences)} width="5%" />
            <Cell<IAdminDangerAdvanced>
              dataKey="description"
              width="40%"
              header="Description"
              sortable={false}
              render={({description}) => description?.value}
            />
            <Cell<IAdminDangerAdvanced>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(DangersAdvanced);
