import React, {memo} from 'react';
import styled from 'styled-components';

import Loading from 'Loading/components/Loading';
import EditableSection from 'BusinessPortal/components/common/EditableSection/EditableSection';
import GeneralInformationShow from './GeneralInformationShow';
import GeneralInformationEdit from './GeneralInformationEdit';
import {Nullable} from 'Common/types';
import {IEmployeeProfile} from 'BusinessPortal/models/employee/IEmployeeProfile';
import {ICommunication} from 'Common/store/utils/communication';

const Root = styled.div`
  max-width: 576px;
  margin-bottom: 46px;
`;

interface IProps {
  employeeProfile: Nullable<IEmployeeProfile>;
  employeeProfileLoading: ICommunication;
  onSuccess(): void;
}

const GeneralInformation = (props: IProps) => {
  const {employeeProfile, onSuccess, employeeProfileLoading} = props;

  const isRequesting = employeeProfileLoading.isRequesting;

  return (
    <Root className="position-relative">
      {isRequesting && <Loading />}
      <EditableSection
        title="General info"
        data={{employeeProfile}}
        onSuccess={onSuccess}
        editComponent={GeneralInformationEdit}
        showComponent={GeneralInformationShow}
      />
    </Root>
  );
};

export default memo(GeneralInformation);
