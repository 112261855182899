import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared/index';
import {IAppState} from 'Common/store/IAppState';

import {ISubscriptionProduct} from 'StripeSubscription/models/ISubscriptionProduct';
import {actions, selectors} from 'StripeSubscription/store/index';
import Theme from 'Common/constants/Theme';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {FormikProps, withFormik} from 'formik';
import {RadiobuttonField} from 'Common/components/FormFields/index';
import {IFormValues, initialValue} from './validation';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {getCommonErrors, getStringErrorDetails} from 'Common/helpers/ErrorHelper';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';

const TextBase = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  line-height: 18px;
  color: ${Theme.color.black};
`;

const Product = styled.div<{isActive?: boolean; isDisabled?: boolean}>`
  opacity: ${({isDisabled}) => (isDisabled ? 0.7 : 1)};
  height: 64px;
  margin: 8px 0;
  padding: 0 28px;
  border: 2px solid ${({isActive, isDisabled}) => (isActive && !isDisabled ? Theme.color.primary : ColorPalette.white9)};
  border-radius: 10px;

  :hover {
    cursor: pointer;
    border: 2px solid ${({isDisabled}) => (isDisabled ? ColorPalette.white9 : Theme.color.primary)};
  }
`;

const ProductDescription = styled(TextBase)`
  font-size: ${Typography.size.size16};
`;

const ProductCost = styled.div`
  min-width: 130px;
`;

const ProductPrice = styled(TextBase)`
  font-size: ${Typography.size.size20};
`;

const ProductInterval = styled(TextBase)`
  color: ${ColorPalette.gray44};
`;

const PayButton = styled(ModalWindowButton)`
  width: 432px;
`;

interface IExternalProps {
  currentSubscriptionIds?: number[];
  activeProducts?: ISubscriptionProduct[];
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const ChangeSubscriptionPlanForm = (props: AllProps) => {
  const {status, setStatus, values, setFieldValue, isSubmitting} = props;
  const {currentSubscriptionIds, changeSubscriptionPlanRequesting} = props;
  const {onSuccess, activeProducts} = props;

  const isDisableSubmit = !values.subscriptionId || currentSubscriptionIds?.some((i) => i === values.subscriptionId);

  const errorInfo = changeSubscriptionPlanRequesting.error;
  const onError = useCallback(() => {
    const error = getStringErrorDetails(errorInfo);
    if (!error) {
      const commonErrors = getCommonErrors(errorInfo);
      commonErrors && setStatus(commonErrors);
      return;
    }

    setStatus(error);
  }, [setStatus, errorInfo]);

  useOnSuccessCommunication(changeSubscriptionPlanRequesting, onSuccess);
  useOnErrorCommunication(changeSubscriptionPlanRequesting, onError);

  const onProductClick = useCallback(
    (event: React.MouseEvent, productId: number) => {
      event.preventDefault();
      if (values.subscriptionId !== productId) {
        setFieldValue('subscriptionId', productId);
        return;
      }
      setFieldValue('subscriptionId', null);
    },
    [values.subscriptionId, setFieldValue]
  );

  return (
    <>
      <ModalWindowHeader>Choose the plan</ModalWindowHeader>
      <div className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent style={{marginBottom: 40}}>
          {activeProducts?.map((product) => {
            const isActive = product.id === values.subscriptionId;
            const isDisabled = currentSubscriptionIds?.some((i) => i === product.id);

            return (
              <Product
                key={product.id}
                className="d-flex justify-content-between"
                isActive={isActive}
                isDisabled={isDisabled}
                onClick={(e) => onProductClick(e, product.id)}
              >
                <div className="d-flex align-items-center">
                  <RadiobuttonField name="subscriptionId" value={product.id} disabled={isDisabled} />
                  <ProductDescription>{product.name}</ProductDescription>
                </div>
                <ProductCost className="d-flex align-items-center">
                  <ProductPrice>${product.price}</ProductPrice>
                  <ProductInterval>&nbsp;/&nbsp;{product.billingInterval}</ProductInterval>
                </ProductCost>
              </Product>
            );
          })}
          {status && <ErrorMessage>{status}</ErrorMessage>}
        </ModalWindowFormContent>
        <ModalWindowFooter className="d-flex justify-content-center">
          <ErrorMessage>{status}</ErrorMessage>
          <PayButton type="submit" disabled={isDisableSubmit} isLoading={isSubmitting} onClick={props.submitForm}>
            Switch plan
          </PayButton>
        </ModalWindowFooter>
      </div>
    </>
  );
};

const ChangeSubscriptionPlanFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({currentSubscriptionIds}) => (currentSubscriptionIds ? {subscriptionId: null} : initialValue),
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    await formikBag.props.changeSubscriptionPlan(values.subscriptionId!);
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(ChangeSubscriptionPlanForm);

const mapStateToProps = (state: IAppState) => ({
  changeSubscriptionPlanRequesting: selectors.selectCommunication(state, 'changeSubscriptionPlanRequesting'),
});

const mapDispatchToProps = {
  changeSubscriptionPlan: actions.changeSubscriptionPlan,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ChangeSubscriptionPlanFormik);
