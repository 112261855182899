import React, {memo, useMemo} from 'react';
import styled from 'styled-components';

import {InputField, SelectField} from 'Common/components/FormFields';
import {Icon, IconedField} from 'Common/components/StyledComponents/StyledComponents';
import {castToOption} from 'Common/helpers/OptionHelper';
import {IFoundOrganization} from 'Admin/AdminAssociations/models/IFoundOrganization';
import capitalizeFirstLetter from 'Common/helpers/capitalizeFirstLetter';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';

const FieldsContainer = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  > :first-child {
    margin-right: 18px;
  }
`;

const RemoveButtonWrapper = styled.div`
  width: 5%;
  align-self: flex-end;
  margin-bottom: 28px;
  margin-left: 16px;
  text-align: left;
`;

interface IProps {
  organizations: IFoundOrganization[];
  selectedOrganizations: number[];
  index: number;
  onDelete(): void;
}

function SpecialPrice(props: IProps) {
  const {organizations, index, selectedOrganizations, onDelete} = props;

  const organizationsOptions = useMemo(
    () =>
      castToOption(organizations, {formatter: (value) => capitalizeFirstLetter(value.toLowerCase())}).sort((a, b) =>
        sortByStringKey(a, b, 'label')
      ),
    [organizations]
  );

  const filteredOptions = useMemo(
    () =>
      organizationsOptions.map((option) =>
        selectedOrganizations.find((seqItem) => seqItem === option.value) ? {...option, isInvisible: true} : option
      ),
    [selectedOrganizations, organizationsOptions]
  );

  return (
    <div className="d-flex flex-row justify-content-start">
      <FieldsContainer className="d-flex justify-content-between">
        <div className="w-75">
          <SelectField
            isClearable={true}
            name={`specialPrices[${index}].organizations`}
            label="User"
            options={filteredOptions}
            isMulti
            menuPosition="fixed"
          />
        </div>
        <div className="w-25">
          <IconedField>
            <InputField
              inputStyle={{textIndent: 15}}
              name={`specialPrices[${index}].price`}
              type="text"
              label="Price"
              placeholder="Price"
              autoComplete="off"
            />
            <Icon>$</Icon>
          </IconedField>
        </div>
      </FieldsContainer>
      <RemoveButtonWrapper>
        <IconButton
          name={IconName.Close}
          color={ColorPalette.gray48}
          fill={true}
          stroke={false}
          size={12}
          onClick={onDelete}
        />
      </RemoveButtonWrapper>
    </div>
  );
}

export default memo(SpecialPrice);
