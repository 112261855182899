import React, {memo} from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';

import {Permission} from 'Permissions/constants/Permission';
import usePermissions from 'Permissions/hooks/usePermissions';
import {isAdminVisitor} from 'Common/helpers/isAdminVisitor';

interface IOwnProps {
  permissions?: Permission | Permission[] | string | string[];
  redirectComponent?: React.ComponentType;
  onlyForDevelopmentAdmin?: boolean;
}

type IExternalProps = RouteProps & IOwnProps;

function AppRoute(props: IExternalProps) {
  const {permissions, redirectComponent: RedirectComponent, onlyForDevelopmentAdmin, ...routeProps} = props;
  const {hasPermission, isDevelopmentAdmin} = usePermissions();

  const redirectFrom = Array.isArray(routeProps.path) ? routeProps.path[0] : routeProps.path;

  return (!permissions || hasPermission(permissions)) &&
    ((onlyForDevelopmentAdmin && isDevelopmentAdmin) || !onlyForDevelopmentAdmin) ? (
    <Route {...routeProps} />
  ) : RedirectComponent ? (
    <RedirectComponent />
  ) : !isAdminVisitor() ? (
    <Redirect from={redirectFrom} to={'/'} />
  ) : null;
}

export default memo(AppRoute);
export type IAppRouteProps = IExternalProps;
