import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {IStringDescription} from 'Common/models/IDescriptionEntity';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {Interval} from 'Common/constants/Interval';
import {IUpdateProductRequest} from 'Admin/AdminDashboard/services/models/IServerProduct';
import {IAdminProduct} from 'Admin/AdminDashboard/models/IAdminProduct';

export interface IFormValues extends IStringDescription {
  id?: IEntity['id'];
  name: string;
  price?: number;
  billingInterval: Interval;
  permissions: string[];
  isActive: boolean;
}

export const initialValue: IFormValues = {
  name: '',
  description: '',
  billingInterval: Interval.Day,
  price: 0,
  permissions: [],
  isActive: true
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  price: Yup.number().required(REQUIRED_FIELD),
  billingInterval: Yup.mixed<Interval>().required(REQUIRED_FIELD)
});

export const convertToClient = (values: IAdminProduct): IFormValues => {
  return {
    ...values,
    permissions: values.permissions.map(x => x.code)
  };
};

export const convertToServer = (values: IFormValues): Omit<IUpdateProductRequest, 'id'> => {
  const response: Omit<IUpdateProductRequest, 'id'> = {
    ...values,
    priceInSmallestUnits: values.price! * 100,
    permissionCodes: values.permissions
  };

  delete response['price'];
  delete response['permissions'];

  return response;
};

export const convertToServerUpdating = (values: IFormValues): IUpdateProductRequest => {
  return {
    ...convertToServer(values),
    id: values.id!
  };
};
