import {IModule} from 'redux-dynamic-modules';

import {stripeSubscriptionReducer} from 'StripeSubscription/store/reducer/index';
import {IStripeSubscriptionState} from 'StripeSubscription/store/types';

export interface IStripeSubscriptionModuleState {
  stripeSubscription: IStripeSubscriptionState;
}

export const StripeSubscriptionModule: IModule<IStripeSubscriptionModuleState> = {
  id: 'stripeSubscription',
  reducerMap: {
    stripeSubscription: stripeSubscriptionReducer
  }
};
