import styled from 'styled-components';

import ColoredNavLink from 'Common/components/Controls/Links/ColoredNavLink';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {breakpoints} from 'Common/constants/Breakpoints';

export const StartPageLink = styled(ColoredNavLink)`
  opacity: 1;
  margin-bottom: 0;
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  &:hover {
    color: ${ColorPalette.red12};
  }
`;

export const SignUpLink = styled(StartPageLink)`
  padding-left: 4px;
  text-decoration-line: underline;
`;

export const Title = styled.div`
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size32};
  line-height: 56px;
  margin-bottom: 8px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size40};
    font-weight: ${Typography.weight.bold700};
  }
`;

export const Text = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  margin-bottom: 40px;
`;

export const Button = styled(PrimaryButton)`
  width: 240px;
`;

export const PlaceholderTitle = styled.div`
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size32};
  line-height: 56px;
  text-align: center;
  color: ${ColorPalette.white0};
`;

export const PlaceholderDescription = styled.div<{maxWidth?: number}>`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  text-align: center;
  color: ${ColorPalette.white0};
  margin-bottom: 32px;
  ${({maxWidth}) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
`;
