import {PageBreak} from 'OnlineReportPrintable/common/styled';
import React, {memo} from 'react';

const BreakableSection = (props: React.PropsWithChildren<any>) => {
  const {children} = props;

  return (
    <>
      <table className="w-100">
        <tbody>
          <tr>
            <td>{children}</td>
          </tr>
        </tbody>
      </table>
      <PageBreak />
    </>
  );
};

export default memo(BreakableSection);
