import {IFormValues} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/RegistrationAndParentageSection/validation';
import {IRegistrationAndParentageSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/RegistrationAndParentageSection/models/IRegistrationAndParentageSectionData';

export const convertToClient = (data: IRegistrationAndParentageSectionData): IFormValues => {
  const convertedParentage = data.parentage?.map(i => ({
    ...i,
    registries: i.registries.map(j => ({
      associationId: j.association?.id || 0,
      registrationNumber: j.registrationNumber
    }))
  }));
  const convertedRegistries = data.registries?.map(i => ({
    associationId: i.association?.id || 0,
    registrationNumber: i.registrationNumber
  }));

  return {
    horseId: data.horseId,
    parents: convertedParentage || [],
    registries: convertedRegistries || []
  };
};
