import React, {memo} from 'react';
import styled from 'styled-components';

import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {FieldContainer, FieldData, FieldName} from 'BusinessPortal/components/common/styled';
import NoData from 'BusinessPortal/components/common/NoData/NoData';
import {getStringifyAddress} from 'Common/helpers/getStringifyAddress';
import {IOwnerInfoSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/parts/OwnerGeneralInfoSection/models/IOwnerInfoSectionData';
import {convertUserName} from 'Common/helpers/convertUserName';

const FieldNameWrapper = styled(FieldName)`
  margin-bottom: 16px;
`;

const OwnerInfoSectionShow = (props: IEditableSection<IOwnerInfoSectionData>) => {
  const {data} = props;

  if (!data?.owner) {
    return <NoData />;
  }

  const {email, firstName, lastName, phone, address} = data.owner;

  return (
    <>
      <FieldNameWrapper width="100%">{convertUserName(firstName, lastName)}</FieldNameWrapper>
      <FieldContainer className="d-flex">
        <FieldName>Email:</FieldName>
        <FieldData>{email || '-'}</FieldData>
      </FieldContainer>
      <FieldContainer className="d-flex">
        <FieldName>Phone:</FieldName>
        <FieldData>{phone || '-'}</FieldData>
      </FieldContainer>
      <FieldContainer className="d-flex">
        <FieldName>Address:</FieldName>
        <FieldData>{address ? getStringifyAddress(address) : '-'}</FieldData>
      </FieldContainer>
    </>
  );
};

export default memo(OwnerInfoSectionShow);
