import React, {memo, useMemo} from 'react';
import {FieldArray} from 'formik';
import styled from 'styled-components';

import {IAssociation} from 'Dictionaries/models/IAssociation';
import {IRegistryRequest} from 'Common/models/IRegistryRequest';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {breakpoints} from 'Common/constants/Breakpoints';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {useMemoizePrevious} from 'Common/helpers/hooks/useMemoizePrevious';

import Registry from './Registry';

const RemoveButtonWrapper = styled.div`
  width: 10%;
  align-self: flex-end;
  margin-bottom: 28px;
  margin-left: 16px;

  text-align: right;

  @media ${breakpoints.sm} {
    text-align: left;
  }
`;

const AddButton = styled(PrimaryButton)`
  width: 150px;
`;

interface IProps {
  associations: IAssociation[];
  registries: IRegistryRequest[];
  fieldName: string;
}

function RegistriesList(props: IProps) {
  const {associations, registries, fieldName} = props;
  const selectedAssociationIds = useMemo(() => registries.map(r => r.associationId) as number[], [registries]);
  const memoizedSelectedAssociationIds = useMemoizePrevious(selectedAssociationIds, []);
  return (
    <FieldArray
      name={fieldName}
      render={arrayHelper => {
        return (
          <div className="flex-column">
            {registries.map((r, index) => {
              return (
                <div key={index} className="d-flex flex-row justify-content-start">
                  <Registry
                    key={index}
                    index={index}
                    associations={associations}
                    selectedAssociationIds={memoizedSelectedAssociationIds}
                    fieldName={fieldName}
                    selectedId={r.associationId}
                  />
                  <RemoveButtonWrapper>
                    <IconButton
                      onClick={() => arrayHelper.remove(index)}
                      name={IconName.Close}
                      color={ColorPalette.red7}
                      size={14}
                      fill={true}
                      stroke={false}
                      hoverColor={ColorPalette.red7}
                    />
                  </RemoveButtonWrapper>
                </div>
              );
            })}
            <AddButton onClick={() => arrayHelper.push({associationId: null, registrationNumber: ''})} type="button">
              Add registry
            </AddButton>
          </div>
        );
      }}
    />
  );
}

export default memo(RegistriesList);
