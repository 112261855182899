import {Reducer} from 'redux';

import {IChangePasswordState, updatePasswordActionTypes, updatePasswordActions, ChangePasswordActions} from '../types';

const initialState: IChangePasswordState['data'] = {
  message: null,
};

export const dataChangePasswordReducer: Reducer<IChangePasswordState['data'], updatePasswordActions> = (
  state = initialState,
  action: ChangePasswordActions
) => {
  switch (action.type) {
    case updatePasswordActionTypes.SUCCESS: {
      return {...state, message: action.payload};
    }
    default: {
      return state;
    }
  }
};
