import {INamedEntity} from 'Common/models/INamedEntity';

export enum StringDictionaryErrorType {
  Full,
  Short
}

function getError(dictionaryName: string, errorType: StringDictionaryErrorType) {
  if (errorType === StringDictionaryErrorType.Full) {
    return `${dictionaryName} isn't specified`;
  }

  return '-';
}

export function dictionaryToString(
  dictionary: INamedEntity[],
  dictionaryName: string,
  errorType: StringDictionaryErrorType = StringDictionaryErrorType.Short
) {
  return dictionary.length > 0
    ? dictionary.map(b => b.name.replace(/\//g, '/ ')).join(', ')
    : getError(dictionaryName, errorType);
}
