import {combineReducers, Reducer} from 'redux';

import {IHorseListState} from 'HorseProfile/store/horseList/types';
import {communicationReducer} from 'HorseProfile/store/horseList/reducer/communications';
import {dataReducer} from 'HorseProfile/store/horseList/reducer/data';
import {uiReducer} from 'HorseProfile/store/horseList/reducer/ui';

export const horsesReducer: Reducer<IHorseListState> = combineReducers<IHorseListState>({
  communications: communicationReducer,
  data: dataReducer,
  ui: uiReducer
});
