import React, {CSSProperties, memo, useCallback} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import {useDropzone} from 'react-dropzone';

import ColorPalette from 'Common/constants/ColorPalette';
import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import {hexToRgba} from 'Common/helpers/hexToRgba';

const Panel = styled.div<Partial<IProps> & {isRound?: boolean}>`
  height: ${props => props.height};
  width: ${props => props.width};
  cursor: pointer;
  border-radius: ${({isRound}) => (isRound ? 50 : 0)}%;
  &:focus {
    outline: none;
  }
  background: ${hexToRgba(ColorPalette.gray49, 0.75)};
  overflow: hidden;
`;

interface IProps {
  isRound?: boolean;
  height?: string;
  width?: string;
  minSize?: number;
  maxSize?: number;
  acceptableTypes?: string | string[];
  multiple?: boolean;
  acceptPartialSelection?: boolean;
  onSelectFiles(files: File[]): void;
  onRejectFiles(files: File[]): void;
}

const activeStyle: CSSProperties = {backgroundColor: ColorPalette.gray16};

type AllProps = IBaseControlProps & IProps;

function UploadPanel(props: React.PropsWithChildren<AllProps>) {
  const {onSelectFiles, onRejectFiles, multiple, isRound, acceptPartialSelection} = props;

  const handleSelectFiles = useCallback(
    (acceptedFiles: File[], rejectedFiles: File[]) => {
      const hasErrors = rejectedFiles.length > 0;

      if (acceptedFiles.length > 0 && (!hasErrors || acceptPartialSelection)) {
        onSelectFiles(acceptedFiles);
      }
      if (hasErrors) {
        onRejectFiles(rejectedFiles);
      }
    },
    [onRejectFiles, onSelectFiles, acceptPartialSelection]
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: handleSelectFiles,
    accept: props.acceptableTypes,
    minSize: props.minSize,
    maxSize: props.maxSize,
    multiple
  });

  return (
    <Panel
      className={classnames(
        'd-flex',
        'flex-column',
        'justify-content-center',
        'align-items-center',
        props.inputClassName
      )}
      style={isDragActive ? {...activeStyle, ...props.inputStyle} : props.inputStyle}
      height={props.height}
      width={props.width}
      isRound={isRound}
      {...getRootProps({isDragActive})}
    >
      {props.children}
      <input {...getInputProps()} />
    </Panel>
  );
}

UploadPanel.defaultProps = {
  height: '100px',
  width: '100%',
  multiple: false,
  acceptPartialSelection: true
};

export default memo(UploadPanel);
