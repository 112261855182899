import {combineReducers} from 'redux';

import {IAdminAssociationOwnerState} from '../types';
import {communicationAdminAssociationOwnerReducer} from './communications';
import {dataAdminAssociationOwnerReducer} from './data';
import {uiAdminAssociationOwnerReducer} from './ui';

export const adminAssociationOwnerReducer = combineReducers<IAdminAssociationOwnerState>({
  communications: communicationAdminAssociationOwnerReducer,
  data: dataAdminAssociationOwnerReducer,
  ui: uiAdminAssociationOwnerReducer,
});
