import React, {memo, useCallback} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import {breakpoints} from 'Common/constants/Breakpoints';
import PlaceholderCarousel from './Placeholders/PlaceholderCarousel';
import StartPageBackgroundPlaceholder from 'Common/components/StartPageLayout/StartPageBackgroundPlaceholder';
import ContainerWrapper from 'Common/components/Layout/ContainerWrapper';
import {ToastContainer} from 'Common/components/Toast/ToastContainer';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import Theme from 'Common/constants/Theme';

const Root = styled.div`
  display: flex;
  height: 100vh;

  @media ${breakpoints.sm} {
    display: grid;
    grid-template-columns: 50% 50%;
  }
`;

const ChildrenContainer = styled(ContainerWrapper)`
  margin: 30px 0;

  @media ${breakpoints.md} {
    height: 100vh;
  }
`;

const LogoPanel = styled.div`
  height: 56px;
  width: 100%;

  @media ${breakpoints.sm} {
    background: linear-gradient(176.5deg, #272b39 2.89%, #151925 96.3%);
  }
`;

const Logo = styled.div`
  top: 24px;
  left: 24px;
  z-index: 1;
  cursor: pointer;

  @media ${breakpoints.sm} {
    top: 12px;
    left: 40px;
  }
`;

const LogoIcon = styled(ColoredIcon)`
  margin-right: 16px;

  width: 65px;
  height: 32px;
`;

const LogoName = styled(ColoredIcon)`
  width: 114px;
  height: 15px;
`;

interface IProps {
  isDesktop?: boolean;
  isMobile?: boolean;
}

function StartPageLayout(props: IProps & React.PropsWithChildren<{}>) {
  const {isDesktop, isMobile, children} = props;

  const history = useHistory();
  const redirectToLoginPage = useCallback(() => history.push('/login'), [history]);

  return (
    <Root className="d-flex">
      <div className="d-flex w-100 flex-column">
        {!isDesktop && (
          <LogoPanel className="position-relative">
            <Logo className="position-absolute" onClick={redirectToLoginPage}>
              <LogoIcon
                name={IconName.EtalonHorseLogo}
                color={isMobile ? Theme.color.black : Theme.color.white}
                fill={true}
              />
              <LogoName
                name={IconName.EtalonLogo}
                color={isMobile ? Theme.color.black : Theme.color.white}
                fill={true}
              />
            </Logo>
          </LogoPanel>
        )}
        <ChildrenContainer className="d-flex w-100 position-relative justify-content-center align-items-center">
          <ToastContainer className="position-absolute" />
          {children}
        </ChildrenContainer>
      </div>
      {isDesktop && (
        <StartPageBackgroundPlaceholder>
          <PlaceholderCarousel slideLifetime={5} />
        </StartPageBackgroundPlaceholder>
      )}
    </Root>
  );
}

export default memo(StartPageLayout);
