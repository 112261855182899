import {IPotentialPartner} from 'FindHorse/models/PotentialPartners';
import {IServerPotentialPartner} from '../serverModel/PotentialPartner';
import {AbilityType} from 'Common/constants/AbilityType';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {withAvatar} from 'Common/helpers/withAvatar';

export function convertPotentialPartnersToClient(serverPartner: IServerPotentialPartner): IPotentialPartner {
  const serverAbilities = serverPartner.abilities;
  const abilities: IPotentialPartner['abilities'] = {
    temperament: serverAbilities.find((a) => a.type === AbilityType.Temperament),
    gait: serverAbilities.find((a) => a.type === AbilityType.Gait),
    speed: serverAbilities.find((a) => a.type === AbilityType.Speed),
  };

  return {
    ...withAvatar(withBirthDate(serverPartner)),
    abilities,
    colors: serverPartner.colors ?? [],
  };
}
