import React, {memo} from 'react';

import {SwitchFieldWrapper, SwitchWrapper} from 'Common/components/StyledComponents/StyledComponents';
import {IAdminNotificationType} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdminNotificationType';
import SwitchField from 'Common/components/FormFields/SwitchField';
import Tooltip from 'Common/components/Tooltip/Tooltip';

interface IProps {
  notification: IAdminNotificationType;
  onClick(code: string, value: boolean): void;
}

function NotificationSwitcher(props: IProps) {
  const {notification, onClick} = props;

  return (
    <SwitchWrapper className="d-flex justify-content-between">
      <Tooltip content={notification.description}>
        <div className="align-self-center">{notification.name}</div>
      </Tooltip>
      <SwitchFieldWrapper>
        <SwitchField name={notification.code} onClick={onClick} checked={notification.isEnable} />
      </SwitchFieldWrapper>
    </SwitchWrapper>
  );
}

export default memo(NotificationSwitcher);
