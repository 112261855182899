import {Reducer} from 'redux';

import {AuthActions, getVisitorTypeActionTypes, IAuthState, resetVisitorTypeActionTypes} from '../types';

const initialState: IAuthState['data'] = {
  visitorType: null,
};

export const dataLoginReducer: Reducer<IAuthState['data'], AuthActions> = (
  state = initialState,
  action: AuthActions
) => {
  switch (action.type) {
    case getVisitorTypeActionTypes.SUCCESS: {
      return {...state, visitorType: action.payload};
    }
    case resetVisitorTypeActionTypes.RESET: {
      return {...state, visitorType: initialState.visitorType};
    }
    default: {
      return state;
    }
  }
};
