import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IAdminOrganization} from 'Admin/AdminAssociations/models/IAdminOrganization';
import {Nullable} from 'Common/types';
import {IImage} from 'Image/models/IImage';

export interface IFormValues extends Omit<IAdminOrganization, 'id'> {
  id?: IEntity['id'];
  avatar?: Nullable<IImage>;
}

export const initialValue: IFormValues = {
  name: '',
  description: '',
  abbreviation: null,
  address: null,
  country: null,
  email: '',
  phoneNumber: null,
  websiteUrl: null,
  isActive: true,
  avatar: null,
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  email: Yup.string().required(REQUIRED_FIELD),
  isActive: Yup.boolean(),
  avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
});
