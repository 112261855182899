import React, {memo, CSSProperties, useMemo, useState, useCallback} from 'react';
import styled from 'styled-components';
import * as history from 'history';
import classNames from 'classnames';
import {useLocation} from 'react-router-dom';

import ColorPalette from 'Common/constants/ColorPalette';
import HorizontalMenuLink, {ILinkHoverStyle} from 'Common/components/Controls/Links/HorizontalMenuLink';
import Theme from 'Common/constants/Theme';
import {breakpoints} from 'Common/constants/Breakpoints';
import Icon from 'Icon/components/Icon';

import {ITab} from './ITab';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {SubHeaderToggle} from '../Controls';

const Root = styled.div`
  height: 40px;
  background-color: ${ColorPalette.gray49};
  @media ${breakpoints.sm} {
    height: 64px;
  }
`;

const Tab = styled.div`
  width: 280px;
  text-align: center;
  background: ${Theme.color.white};
`;

const TabIcon = styled(Icon)`
  margin-left: 16px;
`;

export const Menu = styled.div`
  box-shadow: 0px 0px 20px ${ColorPalette.transparent2};
  border-radius: 4px;
  border: none;
  position: absolute;
  z-index: 101;
  top: 40px;
  padding: 8px;
  background: ${Theme.color.white};
`;

interface IProps {
  tabs: ITab[];
  tabStyle?: React.CSSProperties;
  linkStyle?: React.CSSProperties;
  activeLinkStyle?: React.CSSProperties;
  hoverLinkStyle?: ILinkHoverStyle;
  className?: string;
  style?: CSSProperties;
  isSort?: boolean;
  isTabsCollapsedToMenu?: boolean;
  tabNameMobileStyle?: React.CSSProperties;
}

function Tabs(props: IProps) {
  const {
    tabs,
    linkStyle,
    activeLinkStyle,
    hoverLinkStyle,
    tabStyle,
    className,
    style,
    isSort,
    isTabsCollapsedToMenu,
    tabNameMobileStyle,
  } = props;

  const location = useLocation();

  const sortedTabs = isSort ? tabs.sort((a, b) => sortByStringKey(a, b, 'title')) : tabs;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const activeTabName = useMemo(() => {
    const splittedLocation = location.pathname.split('/');
    const activeTab = sortedTabs.find((x) => x.section === splittedLocation[splittedLocation.length - 1]);

    return (
      <div>
        {activeTab?.title}
        {activeTab?.icon && <TabIcon {...activeTab.icon} />}
      </div>
    );
  }, [location.pathname, sortedTabs]);

  const renderTabLinks = useMemo(
    () => (
      <Root className={classNames('d-flex', className)} style={style}>
        {sortedTabs.map(({title, section, icon}, i) => {
          const onActiveHandle = (location: history.Location) => {
            const splittedLocation = location.pathname.split('/');
            const active = splittedLocation[splittedLocation.length - 1] === section;
            return active;
          };

          return (
            <Tab key={i} style={tabStyle}>
              <HorizontalMenuLink
                to={`./${section}`}
                borderWidth="85%"
                style={{
                  fontSize: isTabsCollapsedToMenu ? 16 : 20,
                  height: isTabsCollapsedToMenu ? 40 : 64,
                  ...linkStyle,
                }}
                activeStyle={activeLinkStyle}
                hoverStyle={hoverLinkStyle}
                checkIsActive={onActiveHandle}
                replace={true}
                onClick={() => setIsMenuOpen(false)}
              >
                {title}
                {icon && <TabIcon {...icon} />}
              </HorizontalMenuLink>
            </Tab>
          );
        })}
      </Root>
    ),
    [activeLinkStyle, className, hoverLinkStyle, isTabsCollapsedToMenu, linkStyle, sortedTabs, style, tabStyle]
  );

  return (
    <>
      {isTabsCollapsedToMenu && (
        <div className="position-relative">
          <SubHeaderToggle onClick={toggleMenu} isHidden={!isMenuOpen} titleStyle={tabNameMobileStyle}>
            {activeTabName}
          </SubHeaderToggle>
          {isMenuOpen && <Menu>{renderTabLinks}</Menu>}
        </div>
      )}

      {!isTabsCollapsedToMenu && renderTabLinks}
    </>
  );
}

Tabs.defaultProps = {
  linkStyle: {
    color: ColorPalette.gray45,
    backgroundColor: ColorPalette.gray49,
    lineHeight: '32px',
  },
  activeLinkStyle: {backgroundColor: Theme.color.white, color: Theme.color.black},
  hoverLinkStyle: {color: Theme.color.black},
};

export default memo(Tabs);
