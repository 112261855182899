import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'BusinessPortal/store/employeeProfile';
import EditableSection from 'BusinessPortal/components/common/EditableSection/EditableSection';
import Loading from 'Loading/components/Loading';
import GeoLocationEdit from './GeoLocationEdit';
import GeoLocationShow from './GeoLocationShow';

const Root = styled.div`
  max-width: 576px;
  margin-bottom: 46px;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

function GeoLocation(props: AllProps) {
  const {geoLocation, geoLocationLoading, getEmployeeGeoLocation} = props;

  const mainRequest = useCallback(() => getEmployeeGeoLocation(), [getEmployeeGeoLocation]);

  useEffect(() => {
    mainRequest();
  }, [mainRequest]);

  const onSuccessEdit = useCallback(() => mainRequest(), [mainRequest]);

  const isRequesting = geoLocationLoading.isRequesting;

  return (
    <Root className="position-relative">
      {isRequesting && <Loading />}
      <EditableSection
        title="Location"
        data={{location: geoLocation}}
        onSuccess={onSuccessEdit}
        editComponent={GeoLocationEdit}
        showComponent={GeoLocationShow}
      />
    </Root>
  );
}

const mapStateToProps = (state: IAppState) => ({
  geoLocation: selectors.selectGeoLocation(state),
  geoLocationLoading: selectors.selectCommunication(state, 'geoLocationLoading'),
});

const mapDispatchToProps = {
  getEmployeeGeoLocation: actions.getEmployeeGeoLocation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(GeoLocation));
