import React, {memo} from 'react';

import UserHorsesSection from 'Admin/AdminDashboard/components/Users/PersonalHorses/parts/UserHorsesSection/UserHorsesSection';
import CreateUserBusinessElementSection from 'Admin/AdminDashboard/components/shared/CreateUserBusinessElementSection/CreateUserBusinessElementSection';
import {IHorseDetails} from 'Admin/AdminDashboard/models/Horse/IHorseDetails';
import {useHorseActions} from 'Admin/AdminDashboard/helpers/hooks/useHorseActions/useHorseActions';
import withHorseActions, {IWithHorseActionsProps} from 'Admin/AdminDashboard/helpers/withHorseActions';
import {IOwnerShort} from 'Admin/AdminDashboard/models/IOwnerShort';
import {sortByName} from 'Common/helpers/sortByName';

interface IOwnProps {
  horses?: IHorseDetails[];
  owner: IOwnerShort;
  onSuccess(): void;
}

type IProps = IOwnProps & IWithHorseActionsProps;

const PersonalHorses = (props: IProps) => {
  const {horses, owner, onSuccess} = props;
  const {setArchiveHorse, horseArchiving, horseCreating, horseUpdating} = props;

  const {
    openUpdateHorseModal,
    openCreateHorseModal,
    openChangeOwnerModal,
    archiveHorse,
    horseActionsModal,
    openLiveReport,
    openChangeSampleIdModal,
    geneticDataVisibilityModal,
    openGeneticDataVisibilityModal,
  } = useHorseActions({
    onSuccess: onSuccess,
    data: {
      archiveHorseAction: {action: setArchiveHorse, communication: horseArchiving},
      createHorseAction: {communication: horseCreating},
      editHorseAction: {communication: horseUpdating},
    },
  });

  const userHorses = horses?.map((item) => ({...item, owner: owner})).sort(sortByName);

  return (
    <>
      {horseActionsModal}
      {geneticDataVisibilityModal}
      <CreateUserBusinessElementSection label="Create horse" onClick={openCreateHorseModal} />
      <UserHorsesSection
        horses={userHorses}
        ownerName={owner.name}
        editHandler={openUpdateHorseModal}
        archiveHandler={archiveHorse}
        changeOwnerHandler={openChangeOwnerModal}
        openLiveReportHandler={openLiveReport}
        editSampleIdHandler={openChangeSampleIdModal}
        editHorseDataVisibilityHandler={openGeneticDataVisibilityModal}
      />
    </>
  );
};

export default withHorseActions(memo(PersonalHorses));
