import React, {memo} from 'react';
import {IGenotypeCombination} from 'Admin/AdminPhenotypes/models/IGenotypeCombination';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';
import {HAS_RULE_ICON, NO_RULE_ICON} from '../../share/StyledComponents';
import GenotypeCombinationForm from './GenotypeCombinationForm/GenotypeCombinationForm';

function GenotypeCombinations() {
  const {adminGenotypeCombination: dictionary} = useDictionaries();

  return (
    <div>
      <DictionaryLayout filterField="alleleNames" dictionary={dictionary} DictionaryForm={GenotypeCombinationForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IGenotypeCombination> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
            <Cell<IGenotypeCombination>
              dataKey="alleleNames"
              width="20%"
              header="Variants"
              render={({alleleNames}) => alleleNames}
            />
            <Cell<IGenotypeCombination> dataKey="type" width="20%" header="Type" render={({type}) => type} />
            <Cell<IGenotypeCombination>
              dataKey="positionIndex"
              width="10%"
              header="Order in Report"
              sortable={false}
              render={({positionIndex}) => positionIndex}
            />
            <Cell<IGenotypeCombination>
              header="Rule"
              render={({sequences}) => (sequences && sequences.length > 0 ? HAS_RULE_ICON : NO_RULE_ICON)}
              width="5%"
            />
            <Cell<IGenotypeCombination>
              dataKey="description"
              sortable={false}
              width="35%"
              header="Description"
              render={({description}) => description?.value}
            />
            <Cell<IGenotypeCombination>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(GenotypeCombinations);
