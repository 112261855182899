import {IModule} from 'redux-dynamic-modules';

import {changePasswordReducer} from './reducer';
import {IAdminChangePasswordState} from './types';

export interface IAdminChangePasswordModuleState {
  adminChangePassword: IAdminChangePasswordState;
}

export const AdminChangePasswordModule: IModule<IAdminChangePasswordModuleState> = {
  id: 'adminChangePassword',
  reducerMap: {
    adminChangePassword: changePasswordReducer
  }
};
