import React, {memo} from 'react';
import styled from 'styled-components';

import {ReactComponent as BlurSvg} from './blur.svg';

const Root = styled.div`
  width: 100%;
  height: 100%;
  user-select: none;
`;

const HidedSvg = styled(BlurSvg)`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  filter: blur(5px);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);

  @supports (filter: url()) {
    filter: url(#svg-blur);
  }
`;

interface IProps {
  src: string;
  className?: string;
}

function BlurredImage(props: IProps) {
  const {className, src} = props;

  return (
    <Root className={className}>
      <HidedSvg />
      <Image src={src} />
    </Root>
  );
}

export default memo(BlurredImage);
