import {IModule} from 'redux-dynamic-modules';

import {IAdminAssociationHorseProfileState} from './types';
import {adminAssociationHorseProfileReducer} from './reducer';

export interface IAdminAssociationHorseProfileModuleState {
  adminAssociationHorseProfile: IAdminAssociationHorseProfileState;
}

export const AdminAssociationHorseProfileModule: IModule<IAdminAssociationHorseProfileModuleState> = {
  id: 'adminAssociationHorseProfile',
  reducerMap: {
    adminAssociationHorseProfile: adminAssociationHorseProfileReducer,
  },
};
