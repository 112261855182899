import React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {FavoriteHorseModule} from 'FavoriteHorse/store/favoriteHorseModule';
import {actions as favoriteActions, selectors as favoriteSelectors} from 'FavoriteHorse/store/index';

type IConnected = ConnectedProps<typeof connector>;

export type IWithFavoriteHorseActionsProps = IConnected;

function withFavoriteHorseActions<T>(Component: React.ComponentType<T>) {
  const wrapped = (props: any) => <Component {...props} />;

  wrapped.displayName = `withFavoriteHorseActions(${Component.displayName || Component.name || 'Component'})`;
  return connector(wrapped);
}

const mapStateToProps = (state: IAppState) => ({
  favoriteHorseAdding: favoriteSelectors.selectCommunication(state, 'favoriteHorseAdding'),
  favoriteHorseDeleting: favoriteSelectors.selectCommunication(state, 'favoriteHorseDeleting'),
});

const mapDispatchToProps = {
  addFavoriteHorse: favoriteActions.addFavoriteHorse,
  deleteFavoriteHorse: favoriteActions.deleteFavoriteHorse,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

function getWithFavoriteHorseActionsModules() {
  return [FavoriteHorseModule];
}

export {getWithFavoriteHorseActionsModules, withFavoriteHorseActions as default};
