import {
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {IListResponse} from 'Common/models/IResponse';

import {IOrderBP} from 'BusinessPortal/models/order/IOrderBP';
import {IAssociationOrderState} from 'Shared/components/Association/models/IAssociationOrderState';
import {IOrderDetailsBP} from 'BusinessPortal/models/order/IOrderDetailsBP';
import {IOrderReport} from 'BusinessPortal/models/order/IOrderReport';
import {IOrderStatus} from 'BusinessPortal/models/order/IOrderStatus';
import {IOrderPackagesDetails} from 'BusinessPortal/models/order/IOrderPackagesDetails';

const STATE_NAME = 'businessPortalOrder';

export type IBusinessPortalOrderState = IAssociationOrderState;

export const getOrdersActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDERS');
export type getOrdersActions = MakeCommunicationActions<
  typeof getOrdersActionTypes,
  {success: IListResponse<IOrderBP>}
>;

export const getOrderDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_DETAILS');
export type getOrderDetailsActions = MakeCommunicationActions<
  typeof getOrderDetailsActionTypes,
  {success: IOrderDetailsBP}
>;

export const getOrderPackagesDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_PACKAGES_DETAILS');
export type getOrderPackagesDetailsActions = MakeCommunicationActions<
  typeof getOrderPackagesDetailsActionTypes,
  {success: IOrderPackagesDetails}
>;

export const getOrderReportsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_REPORTS');
export type getOrderReportsActions = MakeCommunicationActions<
  typeof getOrderReportsActionTypes,
  {success: IOrderReport[]}
>;

export const getOrderStatusesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_STATUSES');
export type getOrderStatusesActions = MakeCommunicationActions<
  typeof getOrderStatusesActionTypes,
  {success: IOrderStatus}
>;

export const downloadOrderSubmissionFormActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DOWNLOAD_ORDER_SUBMISSION_FORM'
);
export type downloadOrderSubmissionFormActions = MakeCommunicationActions<
  typeof downloadOrderSubmissionFormActionTypes,
  {}
>;

export const downloadOrderGroupSubmissionFormActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DOWNLOAD_ORDER_GROUP_SUBMISSION_FORM'
);
export type downloadOrderGroupSubmissionFormActions = MakeCommunicationActions<
  typeof downloadOrderGroupSubmissionFormActionTypes,
  {}
>;

export const deleteOrderActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_ORDER');
export type deleteOrderActions = MakeCommunicationActions<typeof deleteOrderActionTypes, {}>;

export const resetOrdersActionTypes = makeResetCommunicationActionType(STATE_NAME, 'CLEAR_ORDERS');
export type resetOrdersActions = MakeResetCommunicationActions<typeof resetOrdersActionTypes>;

export const resetOrderDetailsActionTypes = makeResetCommunicationActionType(STATE_NAME, 'CLEAR_ORDER_DETAILS');
export type resetOrderDetailsActions = MakeResetCommunicationActions<typeof resetOrderDetailsActionTypes>;

export type BusinessPortalOrderActions =
  | getOrdersActions
  | getOrderDetailsActions
  | getOrderPackagesDetailsActions
  | getOrderReportsActions
  | getOrderStatusesActions
  | downloadOrderSubmissionFormActions
  | downloadOrderGroupSubmissionFormActions
  | resetOrdersActions
  | resetOrderDetailsActions
  | deleteOrderActions;
