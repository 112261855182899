import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IUserHorse} from 'Horse/models/IUserHorse';
import {IServerUserHorse} from './types/horse';
import {convertServerUserHorse} from './converters/horse';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IUser} from 'UserProfile/models/IUser';
import {withAvatar} from 'Common/helpers/withAvatar';

const getUser = async (id: number, isGeneralUser: boolean): Promise<IObjectResponse<IUser>> => {
  const api = isGeneralUser ? '/AssociationOwnerProfileAdmin' : '/AssociationPublicProfileAdmin';
  const response = await axiosWrapper.get<IObjectResponse<IUser>>(`${api}/${id}`);
  return {...response.data, data: withAvatar(response.data.data)};
};

const getHorsesByOwner = async (
  params: IRequestParams,
  id: number,
  isGeneralUser: boolean
): Promise<IListResponse<IUserHorse>> => {
  const api = isGeneralUser ? '/AssociationOwnerProfileHorsesAdmin' : '/AssociationPublicProfileHorsesAdmin';
  const idParam = isGeneralUser ? {ownerId: id} : {organizationId: id};

  const response = await axiosWrapper.get<IListResponse<IServerUserHorse>>(api, {
    params: {...params, ...idParam},
  });
  return {...response.data, data: response.data.data.map(convertServerUserHorse)};
};

export default {getUser, getHorsesByOwner};
