import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';

export const MenuLinkTitle = styled.div`
  color: ${ColorPalette.black1};
  font-family: ${Typography.family.openSans};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size32};
  line-height: 44px;
  padding: 2% 0 2%;
  text-align: center;
`;

export const MainLayout = styled.div`
  margin: 0px 45px;
`;
