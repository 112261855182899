import React, {memo, useCallback} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import ColorPalette from 'Common/constants/ColorPalette';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'ExcludeHorse/store/index';
import Loading from 'Loading/components/Loading';

import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {Pagination} from 'Common/components/Controls/index';
import Typography from 'Common/constants/Typography';
import {IUserProfileSectionProps} from 'Shared/models/IUserProfileSectionProps';
import UserExcludeHorse from 'UserProfile/components/TabsData/UserExcludeHorses/UserExcludeHorse/UserExcludeHorse';
import {useSearchBar} from 'Common/helpers/hooks/useSearchBar';

const UNKNOWN_ERROR = 'Error on loading horse';

const Horses = styled.div`
  margin-top: 16px;
`;

const ErrorMessage = styled.div`
  margin-top: 16px;
  color: ${ColorPalette.red4};
`;

const EmptyText = styled.div`
  font-family: ${Typography.family.openSans};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size23};
  line-height: 32px;
`;

const SearchBarWrapper = styled.div`
  padding: 0 24px 0 16px;
`;

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IUserProfileSectionProps;

function UserExcludeHorses(props: Props) {
  const {horses, horsesLoading, getHorses, pagination, visitorType} = props;

  const {searchBar, reloadItems, setRequestParams, params} = useSearchBar({
    getItems: getHorses,
    searchBarPlaceholder: 'Search for horses by name',
  });

  const handlePageSelect = useCallback(
    (currentPage: number) => setRequestParams({...params, currentPage}),
    [setRequestParams, params]
  );

  const isLoading = horsesLoading.isRequesting;

  const error = !!horsesLoading.error && (getCommonErrors(horsesLoading.error) || UNKNOWN_ERROR);

  function renderEmptyPage() {
    return (
      <div className="h-100 d-flex flex-column justify-content-center align-items-center">
        <EmptyText>You have no excluded horses yet.</EmptyText>
      </div>
    );
  }

  return (
    <div className="flex-grow-1 d-flex flex-column h-100 position-relative">
      <SearchBarWrapper>{searchBar}</SearchBarWrapper>

      {(() => {
        if (isLoading) {
          return <Loading />;
        }

        if (error) {
          return <ErrorMessage>{`Error on loading horses: ${error}`}</ErrorMessage>;
        }

        if (horses.length < 1) {
          return renderEmptyPage();
        }

        return (
          <>
            <Horses>
              {horses.map((horse, i) => (
                <UserExcludeHorse key={i} horse={horse} onSuccess={reloadItems} visitorType={visitorType} />
              ))}
            </Horses>
            <Pagination pagination={pagination} onPageSelect={handlePageSelect} />
          </>
        );
      })()}
    </div>
  );
}

const mapStateToProps = (state: IAppState) => ({
  horses: selectors.selectExcludeHorses(state),
  horsesLoading: selectors.selectCommunication(state, 'excludeHorsesLoading'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getHorses: actions.getExcludeHorses,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(UserExcludeHorses));
