import React, {memo} from 'react';
import * as R from 'ramda';
import Scrollbars, {ScrollbarProps} from 'react-custom-scrollbars';
import styled from 'styled-components';

const AutoFocusTrigger = styled.button`
  position: absolute;
  border: none;
  cursor: default !important;
  opacity: 0;
`;

interface IOwnProps {
  minHeight?: string | number;
  maxHeight?: string | number;
}

interface Props extends IOwnProps, ScrollbarProps {
  renderViewStyle?: React.CSSProperties;
}

function Scrollbar(props: Props) {
  const {height, style, renderViewStyle, autoHeight = true, minHeight, maxHeight, children, ...rest} = props;

  const isHasHeight = !R.isNil(height);

  return (
    <Scrollbars
      autoHeight={autoHeight}
      autoHeightMin={minHeight}
      autoHeightMax={maxHeight}
      style={isHasHeight ? {height, ...style} : style}
      renderView={({style, ...scrollbarProps}) => (
        <div style={{...style, ...renderViewStyle, paddingRight: 30}} {...scrollbarProps} tabIndex={-1} />
      )}
      {...rest}
    >
      <AutoFocusTrigger autoFocus={true} onClick={e => e.preventDefault()} />
      {children}
    </Scrollbars>
  );
}

export default memo(Scrollbar);
