import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';

import {InputField, SelectField, TextAreaField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {castToOption} from 'Common/helpers/OptionHelper';
import {RequiredBy} from 'Common/types';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {IAbilityGroupAdvanced} from 'Dictionaries/models/IAbilityGroupAdvanced';
import {IUpdateAbilityRequest} from 'Admin/AdminPhenotypes/service/models/IServerAbility';
import {IAdminAbility} from 'Admin/AdminPhenotypes/models/IAdminAbility';
import {
  convertToServer,
  convertToServerUpdating,
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/Abilities/AbilityForm/validation';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

interface IOwnProps extends IPhenotypeFormExternalProps<IAdminAbility, IUpdateAbilityRequest> {
  abilityGroupDictionary: ISimpleDictionary<IAbilityGroupAdvanced>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function AbilityForm(props: Props) {
  const {itemId, item, dictionary, abilityGroupDictionary, abilityGroup, abilityGroupLoading, ...rest} = props;
  const {getItem} = dictionary.actions;
  const {
    actions: {getItems: getAbilityGroups},
  } = abilityGroupDictionary;

  useEffect(() => {
    getAbilityGroups();
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem, getAbilityGroups]);

  return (
    <DictionaryForm {...rest} itemPreloading={abilityGroupLoading.isRequesting}>
      <InputField name="name" label="Name" placeholder="Name" />
      <SelectField name="groupId" label="Group" options={castToOption(abilityGroup)} />
      <TextAreaField name="description.value" label="Description" placeholder="Description" />
      <InputField name="externalDescriptionUrl" label="URL" placeholder="External URL" />
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  const {abilityGroupDictionary} = ownProps;

  return {
    abilityGroup: abilityGroupDictionary.selectors.selectItems(state),
    abilityGroupLoading: abilityGroupDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    ...makePhenotypeFormSelectors(state, ownProps.dictionary),
  };
};

const AbilityFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting ? {...item, groupId: item.group?.id || null} : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(AbilityForm);

const connector = connect(mapStateToProps);
export default connector(memo(AbilityFormFormik));
