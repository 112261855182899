export enum HeightUnits {
  Meters = 'Meters',
  Centimeters = 'Centimeters',
  Inch = 'Inch',
  Hands = 'Hands'
}

export const HeightLimits: Record<HeightUnits, {min: number; max: number}> = {
  Meters: {min: 0.4, max: 3},
  Centimeters: {min: 40, max: 300},
  Inch: {min: 16, max: 120},
  Hands: {min: 4, max: 30}
};
