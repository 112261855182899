import {combineReducers} from 'redux';

import {
  IHorseTransferState,
  Actions,
  getHorseTransferDetailsActionTypes,
  createHorseTransferActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationHorseTransferReducer = combineReducers<IHorseTransferState['communications'], Actions>({
  horseTransferDetailsLoading: makeCommunicationReducerFromEnum(getHorseTransferDetailsActionTypes),
  horseTransferCreating: makeCommunicationReducerFromEnum(createHorseTransferActionTypes),
});
