import {IOrderSummary} from 'Payment/models/IOrderSummary';
import {IServerOrderSummary} from '../models/summary';
import {convertTestSummaryToClient} from 'Order/services/converters/summary';

export function convertOrderSummaryToClient(summary: IServerOrderSummary): IOrderSummary {
  return {
    horses: summary.horses,
    testsSummary: convertTestSummaryToClient(summary.testsSummary)
  };
}
