import {IModule} from 'redux-dynamic-modules';

import {serviceModeReducer} from './reducer';
import {IAdminServiceModeState} from './types';

export interface IAdminServiceModeModuleState {
  adminServiceMode: IAdminServiceModeState;
}

export const AdminServiceModeModule: IModule<IAdminServiceModeModuleState> = {
  id: 'adminServiceMode',
  reducerMap: {
    adminServiceMode: serviceModeReducer
  }
};
