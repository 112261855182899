import React from 'react';
import styled from 'styled-components';

import {HeightUnits} from 'HorseProfile/constants/HeightUnits';
import {Nullable} from 'Common/types';
import {ICommercialType} from 'Dictionaries/models/ICommercialType';
import {Separator} from 'UserProfile/components/TabsData/common/styled';
import {breakpoints} from 'Common/constants/Breakpoints';
import {mediumMdSmBreakpoint} from 'UserProfile/components/TabsData/common/constants';
import SimpleContent from './parts/SimpleContent';
import DnaContent from 'UserProfile/components/shared/UserHorseOverviewLayout/HorseCharacteristics/parts/DnaContent';
import CommercialityContent from 'UserProfile/components/shared/UserHorseOverviewLayout/HorseCharacteristics/parts/CommercialityContent';
import {IShortAbility} from 'Dictionaries/models/IAbilities';
import SummaryContent from './parts/SummaryContent';
import {IGenotype} from 'Genotype/models/IGenotype';

const Root = styled.div`
  width: 100%;

  @media ${breakpoints.sm} {
    width: 90%;
  }

  @media ${mediumMdSmBreakpoint} {
    width: 380px;
    margin: 10px -10px 0 0;
  }
`;

interface IProps {
  height: Nullable<number>;
  heightUnit: HeightUnits;
  colors: string[];
  temperament?: IShortAbility;
  gait?: IShortAbility;
  speed?: IShortAbility;
  commercialTypes: ICommercialType[];
  genotypes: IGenotype[];
}

const HorseCharacteristics = (props: IProps) => {
  const {height, colors, heightUnit, temperament, gait, speed, commercialTypes, genotypes} = props;

  return (
    <Root className="d-flex flex-column h-100 justify-content-start">
      <SimpleContent colors={colors} height={height} heightUnit={heightUnit} />
      <DnaContent temperament={temperament} gait={gait} speed={speed} />
      <SummaryContent genotypes={genotypes} />
      <CommercialityContent horseCommercials={commercialTypes} />

      <Separator />
    </Root>
  );
};

export default HorseCharacteristics;
