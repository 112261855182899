import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useHistory} from 'react-router';
import {Form, FormikProps, withFormik} from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import {IResetPassword} from 'ResetPassword/models/IResetPassword';
import ContainerWrapper from 'Common/components/Layout/ContainerWrapper';
import Typography from 'Common/constants/Typography';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {actions, selectors} from 'ResetPassword/store';

import {ResetPasswordModule} from 'ResetPassword/store/resetPasswordModule';
import {IAppState} from 'Common/store/IAppState';
import PasswordField from 'Common/components/FormFields/PasswordField';
import {parseUrlParams} from 'Common/helpers/url';
import Loading from 'Loading/components/Loading';
import useServiceMode from 'Maintain/hooks/useServiceMode';
import withDynamicModules from 'Common/helpers/withDynamicModules';

const UNKNOWN_ERROR = 'Error on resetting password';

const FormLayout = styled.div`
  width: 95%;
  max-width: 500px;
`;

const SuccessMessage = styled.div`
  margin-top: 30px;
  font-weight: ${Typography.weight.medium500};
`;

const Header = styled.h1`
  margin-bottom: 40px;
`;

const ErrorMessageWrapper = styled(ErrorMessage)`
  margin-left: 8px;
`;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected;

type AllProps = OuterProps & FormikProps<IResetPassword>;

const ResetPasswordFormik = (props: AllProps) => {
  const {resettingPassword, setErrors, isSubmitting} = props;
  const history = useHistory();
  const resettingPasswordError = resettingPassword?.error;

  const {getServiceModeStatus, isServiceModeLoading} = useServiceMode();

  useEffect(() => {
    getServiceModeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const error = resettingPasswordError && (getCommonErrors(resettingPasswordError) || UNKNOWN_ERROR);

  useEffect(() => {
    const fieldErrors = getFieldErrors(resettingPasswordError);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, resettingPasswordError]);

  useOnSuccessCommunication(resettingPassword, () => {
    history.replace('/login');
  });

  return (
    <BaseLayout isBackButtonDenied={true}>
      <ContainerWrapper className="d-flex flex-column justify-content-center align-items-center">
        {isServiceModeLoading && <Loading />}
        <FormLayout className="d-flex flex-column">
          <Header>Reset password</Header>
          <Form>
            <PasswordField name="password" label="New password" placeholder="New password" />
            <PasswordField name="passwordConfirm" label="Repeat new password" placeholder="Repeat new password" />
            <div className="d-flex justify-content-between align-items-center" style={{marginTop: 40}}>
              <PrimaryButton type="submit" isLoading={isSubmitting}>
                Reset
              </PrimaryButton>
              {!isSubmitting && error && <ErrorMessageWrapper>{error}</ErrorMessageWrapper>}
            </div>
            <SuccessMessage className="position-absolute">{props.message}</SuccessMessage>
          </Form>
        </FormLayout>
      </ContainerWrapper>
    </BaseLayout>
  );
};

const initialValue: IResetPassword = {
  password: '',
  passwordConfirm: '',
  email: '',
  code: '',
};

const validationSchema = Yup.object().shape<IResetPassword>({
  password: Yup.string().required('Password is required'),
  passwordConfirm: Yup.string()
    .required('Repeat password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
  email: Yup.string(),
  code: Yup.string(),
});

const ResetPassword = withFormik<OuterProps, IResetPassword>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {email, code} = parseUrlParams<IResetPassword>(window.location.search);
    await formikBag.props.resetPassword({...values, email, code});
  },
})(ResetPasswordFormik);

const mapStateToProps = (state: IAppState) => ({
  message: selectors.selectResultMessage(state),
  resettingPassword: selectors.selectCommunication(state, 'messageLoading'),
});

const mapDispatchToProps = {
  resetPassword: actions.resetPassword,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(ResetPassword);

export default withDynamicModules(Connected, ResetPasswordModule);
