import {Nullable} from 'Common/types';
import {IUser} from 'UserProfile/models/IUser';
import {ICommunication} from 'Common/store/utils/communication';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {IAppState} from 'Common/store/IAppState';
import {selectors as currentUserSelectors} from 'UserProfile/store/currentUser';
import {actions as userActions, selectors as userSelectors} from 'UserProfile/store/user';
import {
  actions as adminAssociationUserActions,
  selectors as adminAssociationUserSelectors,
} from 'Admin/AdminAssociations/store/adminOwnerProfile';
import {
  actions as businessPortalOwnerActions,
  selectors as businessPortalOwnerSelectors,
} from 'BusinessPortal/store/ownerProfile';
import {FavoriteHorseModule} from 'FavoriteHorse/store/favoriteHorseModule';
import {ExcludeHorseModule} from 'ExcludeHorse/store/excludeHorseModule';
import {UserOrdersModule} from 'UserProfile/store/userOrder/horseListModule';
import {SubmissionFormModule} from 'Order/store/submissionForm/submissionFormModule';
import {UserModule} from 'UserProfile/store/user/userModule';
import {VisitorType} from 'Common/constants/VisitorType';
import {AdminAssociationOwnerProfileModule} from 'Admin/AdminAssociations/store/adminOwnerProfile/adminAssociationOwnerProfileModule';
import {BusinessPortalOwnerProfileModule} from 'BusinessPortal/store/ownerProfile/businessPortalOwnerProfileModule';

export interface IState {
  user: Nullable<IUser>;
  currentUser: Nullable<IUser>;
  userLoading: ICommunication;
}

export interface IDispatch {
  getUser(id?: number, isGeneralUser?: boolean, isAssociationOwner?: boolean): void;
}

const userDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    user: userSelectors.selectUser(state),
    currentUser: currentUserSelectors.selectCurrentUser(state),
    userLoading: userSelectors.selectCommunication(state, 'userLoading'),
  }),
  dispatch: {
    getUser: userActions.getUser,
  },
  modules: [
    FavoriteHorseModule,
    ExcludeHorseModule,
    UserOrdersModule,
    SubmissionFormModule,
    UserModule,
    BusinessPortalOwnerProfileModule,
  ],
};

const associationEmployeeDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    user: businessPortalOwnerSelectors.selectUser(state),
    currentUser: currentUserSelectors.selectCurrentUser(state),
    userLoading: businessPortalOwnerSelectors.selectCommunication(state, 'ownerLoading'),
  }),
  dispatch: {
    getUser: businessPortalOwnerActions.getUser,
  },
  modules: [BusinessPortalOwnerProfileModule],
};

const adminDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    user: userSelectors.selectUser(state),
    currentUser: currentUserSelectors.selectCurrentUser(state),
    userLoading: userSelectors.selectCommunication(state, 'userLoading'),
  }),
  dispatch: {
    getUser: userActions.getUser,
  },
  modules: [UserModule],
};

const associationAdminEmployeeDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    user: adminAssociationUserSelectors.selectUser(state),
    currentUser: currentUserSelectors.selectCurrentUser(state),
    userLoading: adminAssociationUserSelectors.selectCommunication(state, 'ownerLoading'),
  }),
  dispatch: {
    getUser: adminAssociationUserActions.getUser,
  },
  modules: [AdminAssociationOwnerProfileModule],
};

export const userProfileVisitorDistributor: Record<VisitorType, IDistributor<IState, IDispatch>> = {
  [VisitorType.Admin]: adminDistributor,
  [VisitorType.User]: userDistributor,
  [VisitorType.AssociationAdmin]: associationAdminEmployeeDistributor,
  [VisitorType.AssociationEmployee]: associationEmployeeDistributor,
};
