import React, {useEffect} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {initialValue, IFormValues, validationSchema} from './validation';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {IAppState} from 'Common/store/IAppState';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {CheckboxField, InputField, SelectField} from 'Common/components/FormFields';
import {actions as packageActions, selectors as packageSelectors} from 'Admin/AdminDashboard/store/adminPackages';
import Loading from 'Loading/components/Loading';
import {castToOption} from 'Common/helpers/OptionHelper';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {useDictionaries} from 'Common/store/useDictionaries';
import {IPackageGroup} from 'Dictionaries/models/IPackageGroup';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import HintIcon from 'Common/components/HintIcont/HintIcon';
import {convertFormValuesToRequest, convertPackageCartSettingsToFormValues} from './converters';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';

interface IExternalDictionaries {
  packageGroupsDictionary: ISimpleDictionary<IPackageGroup>;
}

interface IExternalProps extends IExternalDictionaries {
  packageId: number;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const PackageCartSettingsForm = (props: AllProps) => {
  const {status, setStatus, setErrors, isSubmitting, packageGroupsDictionary} = props;
  const {
    getPackageDetails,
    packageGroups,
    packageId,
    packageGroupsLoading,
    packageLoading,
    resetPackageDetails,
    packageCartSettingsUpdating,
    onSuccess,
  } = props;

  const errorInfo = packageLoading.error || packageGroupsLoading.error;
  const isLoading = [packageLoading, packageGroupsLoading].some((i) => i.isRequesting);

  useOnSuccessCommunication(packageCartSettingsUpdating, onSuccess);

  const {
    actions: {getItems: getPackageGroups},
  } = packageGroupsDictionary;

  useEffect(() => {
    getPackageDetails(packageId);

    return () => resetPackageDetails();
  }, [getPackageDetails, packageId, resetPackageDetails]);

  useEffect(() => {
    getPackageGroups();
  }, [getPackageGroups]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    const fieldErrors = getFieldErrors(errorInfo);

    if (commonErrors) {
      setStatus(commonErrors);
    }

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  return (
    <>
      <ModalWindowHeader>Package settings for Shopping cart</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {isLoading && <Loading />}

          <FormControlContainer title="Package settings for Shopping cart" style={{marginTop: 24}}>
            <div className="d-flex w-100 align-items-center">
              <div className="w-50">
                <SelectField
                  isMulti={true}
                  name="groups"
                  label="Package groups"
                  options={castToOption(packageGroups)}
                  menuPosition="fixed"
                />
              </div>
              <HintIcon tooltip="Which 'Categories' it belongs to in Shopping cart" />
            </div>

            <div className="d-flex w-100 align-items-center">
              <div className="w-50">
                <InputField name="positionIndex" label="Order in Category" placeholder="Order in Category" />
              </div>
            </div>

            <CheckboxField name="isBundle" label="Included to 'Bundles'" style={{marginBottom: 8}} />
          </FormControlContainer>
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" isLoading={isSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const PackageCartSettingsFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({packageInfo}) =>
    packageInfo ? convertPackageCartSettingsToFormValues(packageInfo) : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    formikBag.props.updatePackageCartSettings(convertFormValuesToRequest(values));
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(PackageCartSettingsForm);

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {packageGroupsDictionary} = props;
  const {selectors: packageGroupSelectors} = packageGroupsDictionary;

  return {
    packageInfo: packageSelectors.selectPackageDetails(state),
    packageLoading: packageSelectors.selectCommunication(state, 'packageDetailsLoading'),
    packageCartSettingsUpdating: packageSelectors.selectCommunication(state, 'packageCartSettingsUpdating'),
    packageGroups: packageGroupSelectors.selectItems(state),
    packageGroupsLoading: packageGroupSelectors.selectCommunication(state, 'itemsLoading'),
  };
};

const mapDispatchToProps = {
  getPackageDetails: packageActions.getPackageById,
  resetPackageDetails: packageActions.resetPackageDetailResults,
  updatePackageCartSettings: packageActions.updatePackageCartSettings,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(PackageCartSettingsFormFormik);

const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {packageGroups} = useDictionaries();

  return <Connected {...externalProps} packageGroupsDictionary={packageGroups} />;
};

export default Exported;
