import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';
import {IResetPassword} from 'ResetPassword/models/IResetPassword';

const STATE_NAME = 'resetPassword';

export interface IResetPasswordState {
  data: {
    message: string | null;
  };
  communications: {
    messageLoading: ICommunication<IResetPassword>;
  };
}

export const resetPasswordActionTypes = makeCommunicationActionType(STATE_NAME, 'RESET_PASSWORD');
export type resetPasswordActions = MakeCommunicationActions<typeof resetPasswordActionTypes, {success: string}>;
