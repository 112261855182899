import React, {memo} from 'react';
import styled from 'styled-components';

import {IFoal} from 'Common/models/IFoal';
import PotentialFoal from './PotentialFoal/PotentialFoal';
import {breakpoints} from 'Common/constants/Breakpoints';

const Root = styled.div<{showOneColumn?: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: ${(p) => (p.showOneColumn ? '100%' : '50% 50%')};
  grid-gap: 20px;
`;

const PotentialFoalContainer = styled.div`
  height: auto;

  @media ${breakpoints.md} {
    height: 310px;
  }
`;

interface IProps {
  foals: IFoal[];
  showOneColumn?: boolean;
  searchCallback?(): void;
}

function PotentialFoals(props: IProps) {
  const {foals, showOneColumn, searchCallback} = props;

  React.useEffect(() => searchCallback?.());

  return (
    <Root showOneColumn={showOneColumn}>
      {foals.map((foal, index) => (
        <PotentialFoalContainer key={index}>
          <PotentialFoal foal={foal} />
        </PotentialFoalContainer>
      ))}
    </Root>
  );
}

export default memo(PotentialFoals);
