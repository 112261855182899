import {IModule} from 'redux-dynamic-modules';

import {mailConfirmationReducer} from './reducer';
import {IRequestNewMailConfirmationState} from './types';

export interface IRequestNewMailConfirmationModuleState {
  requestNewMailConfirmation: IRequestNewMailConfirmationState;
}

export const RequestNewMailConfirmationModule: IModule<IRequestNewMailConfirmationModuleState> = {
  id: 'requestNewMailConfirmation',
  reducerMap: {
    requestNewMailConfirmation: mailConfirmationReducer
  }
};
