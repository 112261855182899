import React, {memo} from 'react';
import styled from 'styled-components';

import Icon, {IconName} from 'Icon/components/Icon';
import {Nullable} from 'Common/types';
import withDate from 'Common/helpers/withDate';
import {FRONTEND_DATE} from 'Common/constants/Date';

const IconWrapper = styled(Icon)`
  margin: 0 8px 5px 0;
`;

interface IPackageName {
  name: string;
  isArchived: boolean;
  statusChangeDate: Nullable<string>;
}

export const PackageName = memo(({name, isArchived, statusChangeDate}: IPackageName) => {
  return (
    <>
      {isArchived && <IconWrapper name={IconName.Archive} />}
      {name} {isArchived && statusChangeDate && `(until ${withDate(statusChangeDate, FRONTEND_DATE)})`}
    </>
  );
});
