import {action} from 'typesafe-actions';

import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {
  getOrderPaymentTokenActions,
  getOrderPaymentTokenActionTypes,
  getPaymentHorsesActions,
  getPaymentHorsesActionTypes,
  getPaymentSummaryActions,
  getPaymentSummaryActionTypes,
} from 'BusinessPortal/store/orderPayment/types';
import OrderPaymentService from 'BusinessPortal/services/OrderPaymentService';

export const getOrderPaymentToken =
  (orderId: number): ActionResult<IAppState, Promise<string>, getOrderPaymentTokenActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOrderPaymentTokenActionTypes.REQUEST));
      const res = await OrderPaymentService.getOrderPaymentToken(orderId);
      dispatch(action(getOrderPaymentTokenActionTypes.SUCCESS, res));
      return res;
    } catch (error) {
      const err = getErrorMessage(error);
      dispatch(action(getOrderPaymentTokenActionTypes.FAILURE, err));
      throw err;
    }
  };

export const getPaymentHorses =
  (token: string): ActionResult<IAppState, Promise<void>, getPaymentHorsesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getPaymentHorsesActionTypes.REQUEST));
      const res = await OrderPaymentService.getPaymentHorses(token);
      dispatch(action(getPaymentHorsesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getPaymentHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getPaymentSummary =
  (token: string): ActionResult<IAppState, Promise<void>, getPaymentSummaryActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getPaymentSummaryActionTypes.REQUEST));
      const res = await OrderPaymentService.getPaymentSummary(token);
      dispatch(action(getPaymentSummaryActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getPaymentSummaryActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
