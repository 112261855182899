export function isYouTubeUrl(url?: string) {
  if (!url) {
    return false;
  }

  const result = url.match(/youtube\.com/);
  const resultShort = url.match(/youtu.be/);

  if (result || resultShort) {
    return true;
  }

  return false;
}
