import * as Yup from 'yup';

import {PaymentMethod} from 'Common/constants/PaymentMethod';
import {FRONTEND_DATE} from 'Common/constants/Date';
import {isValidDate} from 'Common/helpers/DateHelper';
import {Nullable} from 'Common/types';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IAdminPaymentDetails, PaymentStatus} from 'Admin/AdminAssociations/models/IAdminPaymentDetails';

export interface IPaymentDetailsForm extends Omit<IAdminPaymentDetails, 'status'> {
  status: Nullable<PaymentStatus>;
}

export const initialValue: IPaymentDetailsForm = {
  orderIds: [],
  receivedDate: null,
  method: PaymentMethod.None,
  transactionId: '',
  status: PaymentStatus.Waiting,
  timestamp: '',
  sendEmail: true,
};

export const validationSchema = Yup.object().shape<Partial<IPaymentDetailsForm>>({
  receivedDate: Yup.string()
    .nullable()
    .test('receivedDate', `Please enter a valid date: ${FRONTEND_DATE}`, function (value: string) {
      return value ? isValidDate(value, FRONTEND_DATE) : true;
    }),
  method: Yup.mixed<PaymentMethod>().required(REQUIRED_FIELD),
  transactionId: Yup.string()
    .transform((value, original) => (original === null ? '' : value))
    .required(REQUIRED_FIELD),
  status: Yup.mixed<PaymentStatus>().required(REQUIRED_FIELD),
  sendEmail: Yup.boolean(),
});
