import {INamedEntity} from 'Common/models/INamedEntity';

export enum PackageStatus {
  Disabled = 'Disabled',
  Active = 'Active',
  Archived = 'Archived'
}

export interface IPackageSimple extends INamedEntity {
  status?: PackageStatus;
}
