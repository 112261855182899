import React from 'react';
import styled from 'styled-components';

import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';

const actionButtonProps = {color: ColorPalette.gray28, fill: true, stroke: false, size: 16};

const ActionsWrapper = styled.div`
  cursor: default;
  margin-top: 16px;
  width: 80px;
  height: 32px;
  background: ${ColorPalette.gray38};
  border-radius: 3px;
`;

const ActionButton = styled(IconButton)`
  margin: 8px;
`;

interface IActionProps {
  id: number;
  editHandler(id: number): void;
  deleteHandler(id: number): void;
}

export const Actions = React.memo((props: IActionProps) => {
  const {id, editHandler, deleteHandler} = props;

  const handleEditAction = React.useCallback(
    (e) => {
      nothing(e);
      editHandler(id);
    },
    [id, editHandler]
  );

  const handleDeleteAction = React.useCallback(
    (e) => {
      nothing(e);
      deleteHandler(id);
    },
    [id, deleteHandler]
  );

  return (
    <ActionsWrapper className="d-flex align-items-center justify-content-around" onClick={nothing}>
      <ActionButton name={IconName.Edit} {...actionButtonProps} onClick={handleEditAction} />
      <ActionButton name={IconName.Garbage} onClick={handleDeleteAction} {...actionButtonProps} />
    </ActionsWrapper>
  );
});

function nothing(e: React.MouseEvent) {
  e.stopPropagation();
}
