import classNames from 'classnames';
import React, {memo, PropsWithChildren} from 'react';
import styled from 'styled-components';

import {ButtonPosition} from 'Common/components/Controls/Buttons/TwoActionButton';

const Root = styled.div<{isShowShadow?: boolean; position: string}>`
  flex-direction: ${({position}) => (position === 'Left' ? 'row' : 'row-reverse')};
  cursor: default;
  height: 60px;
  z-index: 3;
  margin-top: -2px;
  ${({isShowShadow}) =>
    isShowShadow && 'background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);'}
`;

interface IProps {
  position: ButtonPosition;
  isShowShadow?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

function ShadowContainer(props: PropsWithChildren<IProps>) {
  const {isShowShadow = false, position, children, style, className} = props;

  const fakeEvent = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Root
      style={style}
      className={classNames('d-flex justify-content-start', className)}
      isShowShadow={isShowShadow}
      onClick={fakeEvent}
      position={position}
    >
      {children}
    </Root>
  );
}

export default memo(ShadowContainer);
