import {IPackage} from 'Dictionaries/models/IPackage';
import {IPackageCreateRequest, IPackageUpdateRequest} from 'Admin/AdminDashboard/models/Package/IPackageAdmin';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {IServerPackage} from '../types/package';
import {PackageStatus} from 'Dictionaries/models/IPackageSimple';

type IPackageRequest = IPackageCreateRequest | IPackageUpdateRequest;

export function convertPackageToClient(packageInfo: IServerPackage): IPackage {
  return {
    ...packageInfo,
    price: packageInfo.price / 100,
    statusChangeDate: packageInfo.statusChangeDate ? convertUTCToClientDate(packageInfo.statusChangeDate) : null,
    isArchived: packageInfo.status === PackageStatus.Archived,
    isEnabled: packageInfo.status === PackageStatus.Active,
  };
}

export function convertPackageToRequest(value: IPackageRequest): IPackageRequest {
  const dataToSave: Omit<IPackageRequest, 'isArchived' | 'isEnabled' | 'statusChangeDate' | 'status'> = value;
  delete dataToSave['isArchived'];
  delete dataToSave['isEnabled'];
  delete dataToSave['statusChangeDate'];
  delete dataToSave['status'];

  return {
    ...dataToSave,
    price: value.price * 100,
  };
}
