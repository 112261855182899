import * as R from 'ramda';
import {ISorter} from './ISorter';

const makeSorter = R.compose(
  R.sortWith,
  R.map<ISorter, any>(({order, columnName, isNumber}) =>
    (order === 'desc' ? R.descend : R.ascend)(
      R.compose<any, any>(isNumber ? Number.parseFloat : R.toLower, R.prop(columnName) as unknown as any)
    )
  )
);

export const sortBySorter = <T>(sorter: ISorter[], value: T[]) => {
  return makeSorter(sorter)(value) as T[];
};
