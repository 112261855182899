import {IListResponse, IPagination} from 'Common/models/IResponse';
import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {IAdministrator} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdministrator';
import {Nullable} from 'Common/types';
import {IImage} from 'Image/models/IImage';

const STATE_NAME = 'administrator';

export interface IAdministratorState {
  data: {
    administrators: IAdministrator[];
    administrator: Nullable<IAdministrator>;
    administratorAvatar: Nullable<IImage>;
  };
  communications: {
    administratorsLoading: ICommunication;
    administratorLoading: ICommunication;
    administratorCreating: ICommunication;
    administratorUpdating: ICommunication;
    administratorAvatarLoading: ICommunication;
    administratorAvatarUploading: ICommunication;
    administratorAvatarDeleting: ICommunication;
    administratorPasswordUpdating: ICommunication;
    administratorStatusUpdating: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getAdministratorsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ADMINISTRATORS');

export type getAdministratorsActions = MakeCommunicationActions<
  typeof getAdministratorsActionTypes,
  {success: IListResponse<IAdministrator>}
>;

export const getAdministratorActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ADMINISTRATOR');

export type getAdministratorActions = MakeCommunicationActions<
  typeof getAdministratorActionTypes,
  {success: IAdministrator}
>;

export const createAdministratorActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_ADMINISTRATOR');

export type createAdministratorActions = MakeCommunicationActions<typeof createAdministratorActionTypes, {}>;

export const updateAdministratorActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_ADMINISTRATOR');

export type updateAdministratorActions = MakeCommunicationActions<typeof updateAdministratorActionTypes, {}>;

export const resetAdministratorActionTypes = makeResetCommunicationActionType(STATE_NAME, 'ADMINISTRATOR');
export type resetAdministratorActions = MakeResetCommunicationActions<typeof resetAdministratorActionTypes>;

export const getAdministratorAvatarActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ADMINISTRATOR_AVATAR');

export type getAdministratorAvatarActions = MakeCommunicationActions<
  typeof getAdministratorAvatarActionTypes,
  {success: IImage}
>;

export const uploadAdministratorAvatarActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPLOAD_ADMINISTRATOR_AVATAR'
);

export type uploadAdministratorAvatarActions = MakeCommunicationActions<
  typeof uploadAdministratorAvatarActionTypes,
  {}
>;

export const deleteAdministratorAvatarActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DELETE_ADMINISTRATOR_AVATAR'
);

export type deleteAdministratorAvatarActions = MakeCommunicationActions<
  typeof deleteAdministratorAvatarActionTypes,
  {}
>;

export const setAdministratorPasswordActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'SET_ADMINISTRATOR_PASSWORD'
);

export type setAdministratorPasswordActions = MakeCommunicationActions<typeof setAdministratorPasswordActionTypes, {}>;

export const changeAdministratorStatusActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CHANGE_ADMINISTRATOR_STATUS'
);

export type changeAdministratorStatusActions = MakeCommunicationActions<
  typeof changeAdministratorStatusActionTypes,
  {success: {adminId: number}}
>;

export type AdministratorsActions =
  | getAdministratorsActions
  | getAdministratorActions
  | createAdministratorActions
  | updateAdministratorActions
  | resetAdministratorActions
  | getAdministratorAvatarActions
  | uploadAdministratorAvatarActions
  | deleteAdministratorAvatarActions
  | setAdministratorPasswordActions
  | changeAdministratorStatusActions;
