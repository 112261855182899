import {combineReducers} from 'redux';

import {
  changeAdminAssociationPackageStatusActionTypes,
  createAdminAssociationPackageActionTypes,
  deleteAdminAssociationPackageActionTypes,
  AdminAssociationPackagesActions,
  getAdminAssociationPackageByIdActionTypes,
  getAdminAssociationPackagesActionTypes,
  IAdminAssociationPackagesState,
  resetAdminAssociationPackageDetailsActionTypes,
  updateAdminAssociationPackageActionTypes,
  updateAdminAssociationPackageCartSettingsActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

export const communicationAdminAssociationPackagesReducer = combineReducers<
  IAdminAssociationPackagesState['communications'],
  AdminAssociationPackagesActions
>({
  packagesLoading: makeCommunicationReducerFromEnum(getAdminAssociationPackagesActionTypes),
  packageCreating: makeCommunicationReducerFromEnum(createAdminAssociationPackageActionTypes),
  packageUpdating: makeCommunicationReducerFromEnum(updateAdminAssociationPackageActionTypes),
  packageDeleting: makeCommunicationReducerFromEnum(deleteAdminAssociationPackageActionTypes),
  packageDetailsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getAdminAssociationPackageByIdActionTypes),
    makeResetCommunicationReducer(resetAdminAssociationPackageDetailsActionTypes.RESET),
  ]),
  changePackageStatusRequesting: makeCommunicationReducerFromEnum(changeAdminAssociationPackageStatusActionTypes),
  packageCartSettingsUpdating: makeCommunicationReducerFromEnum(updateAdminAssociationPackageCartSettingsActionTypes),
});
