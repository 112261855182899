import React, {useCallback, useState} from 'react';

import SlidePanel from 'Common/components/SlidePanel/SlidePanel';
import {ElementPosition} from 'FoalProfile/models/IElementPosition';
import OrderForm, {OrderFormProps} from 'BusinessPortal/components/BusinessPortalDashboard/Orders/OrderForm';

interface IProps {
  onSuccessForm?(): void;
  shadow?: boolean;
  transition?: boolean;
  closableTitle?: boolean;
}

export function useOrderFormSlidePanel(props: IProps & Omit<OrderFormProps, 'orderId'>) {
  const {closableTitle, shadow, transition, onSuccessForm, ...orderFormProps} = props;

  const [isOpenSlidePanel, setIsOpenSlidePanel] = useState(false);

  const [orderId, setOrderId] = useState<number>();

  const openOrderFormSlidePanel = useCallback((orderId?: number) => {
    setIsOpenSlidePanel(true);
    setOrderId(orderId);
  }, []);

  const closeOrderFormSlidePanel = useCallback(async () => {
    setIsOpenSlidePanel(false);
    orderId && onSuccessForm && (await onSuccessForm());
  }, [onSuccessForm, orderId]);

  const orderFormSlidePanel = (
    <SlidePanel
      title="Order info"
      isOpen={isOpenSlidePanel}
      closableTitle={closableTitle}
      position={ElementPosition.Right}
      onClose={closeOrderFormSlidePanel}
      shadow={shadow}
      transition={transition}
    >
      {orderId && <OrderForm orderId={orderId} {...orderFormProps} />}
    </SlidePanel>
  );

  return {openOrderFormSlidePanel, orderFormSlidePanel, closeOrderFormSlidePanel, orderId};
}
