import React, {memo} from 'react';
import styled from 'styled-components';

import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon from 'Icon/components/ColoredIcon';

const Root = styled.div`
  width: fit-content;
  cursor: pointer;
`;

const Title = styled.div`
  margin-left: 4px;
  color: ${ColorPalette.blue2};
`;

const GoToBackIcon = styled(ColoredIcon)`
  transform: rotate(90deg);
  margin-right: 28px;
`;

interface IProps {
  title?: string | JSX.Element;
}

const GoToBack = (props: IProps) => {
  const {title} = props;

  if (!title) {
    return null;
  }

  return (
    <Root className="d-flex align-items-center">
      <GoToBackIcon name={IconName.ArrowDown} color={ColorPalette.white12} fill={true} stroke={false} size={16} />
      Back to <Title style={{color: ColorPalette.blue2}}>{title}</Title>
    </Root>
  );
};

export default memo(GoToBack);
