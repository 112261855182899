import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {IFilterProperty} from 'FacetFilter/models/IFilterProperty';
import {IFilterPropertyOperations} from 'FacetFilter/models/IFilterPropertyOperator';
import {IFilterRequest} from 'FacetFilter/models/IFilterRequest';
import {IconName} from 'Icon/components/Icon';
import Facet from './Facet';
import FacetDropDown from './parts/FacetDropDown';
import {FilterType} from 'FacetFilter/constants/FilterType';
import {DictionaryToProperty, EnumToProperty} from 'FacetFilter/models/types';
import ColoredIcon from 'Icon/components/ColoredIcon';

const Root = styled.div`
  width: 100%;
  min-height: 48px;
  padding: 4px 16px;
  border: none;
  border-radius: 4px;
  color: ${Theme.color.black};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  font-family: ${Theme.font.primary};
  background: ${ColorPalette.gray49};
`;

const FacetContainer = styled.div`
  gap: 12px;
  margin: 0 24px;
`;

const ClearButton = styled(IconButton)`
  margin-right: 16px;
`;

const FunnelIcon = styled(ColoredIcon)`
  left: 8px;
`;

interface IProps {
  filterProperties: IFilterProperty[];
  filterPropertyOperations: IFilterPropertyOperations[];
  defaultFilterRequest?: IFilterRequest[];
  mapEnumToProperty?: EnumToProperty;
  mapDictionaryToProperty?: DictionaryToProperty;
  onChange(value: any): void;
  onReload(): void;
}

function FacetSearchBar(props: IProps) {
  const {
    filterProperties,
    filterPropertyOperations,
    defaultFilterRequest,
    mapEnumToProperty,
    mapDictionaryToProperty,
    onChange,
  } = props;
  const [isOpenList, setIsOpenList] = useState(false);
  const [facets, setFacets] = useState<IFilterProperty[]>([]);
  const [filterRequest, setFilterRequest] = useState<IFilterRequest[]>([]);

  useEffect(() => {
    setFilterRequest(defaultFilterRequest || []);
    const findFacets = filterProperties.filter((x) => defaultFilterRequest?.some((y) => y.property === x.property));
    setFacets(findFacets);
  }, [defaultFilterRequest, filterProperties]);

  useEffect(() => {
    onChange(filterRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRequest]);

  const onItemClick = useCallback(
    (property: string) => {
      setIsOpenList(!isOpenList);

      const newFacet = filterProperties.find((x) => x.property === property);
      if (newFacet === undefined) {
        return;
      }
      const newFacets = [...facets];
      newFacets.push(newFacet);
      setFacets(newFacets);
    },
    [facets, isOpenList, filterProperties]
  );

  const onFacetRemove = useCallback(
    (property: string) => {
      const closeFacetId = facets.findIndex((x) => x.property === property);
      if (closeFacetId === -1) {
        return;
      }
      const newFacets = [...facets];
      newFacets.splice(closeFacetId, 1);
      setFacets(newFacets);

      const closeFilterRequestId = filterRequest.findIndex((x) => x.property === property);
      if (closeFilterRequestId === -1) {
        return;
      }
      const newFilterRequest = [...filterRequest];
      newFilterRequest.splice(closeFilterRequestId, 1);
      setFilterRequest(newFilterRequest);
    },
    [facets, filterRequest]
  );

  const getFilterPropertyOperations = useCallback(
    (type: FilterType) => {
      return filterPropertyOperations.find((x) => x.type === type);
    },
    [filterPropertyOperations]
  );

  const filterMenuItems = useMemo(() => {
    return filterProperties.filter((x) => !facets.includes(x)).sort((a, b) => sortByStringKey(a, b, 'title'));
  }, [facets, filterProperties]);

  const onSuccess = useCallback(
    (request: IFilterRequest) => {
      const newFilterRequest = [...filterRequest].filter((x) => x.property !== request.property);
      setFilterRequest([...newFilterRequest, request]);
    },
    [filterRequest]
  );

  const onRemoveFilters = useCallback(() => {
    setFacets([]);
    setFilterRequest([]);
  }, []);

  return (
    <Root className="position-relative d-flex align-items-center justify-content-between">
      <FunnelIcon
        className="position-absolute"
        name={IconName.FilterList}
        size={24}
        color={ColorPalette.gray44}
        stroke={false}
        fill={true}
      />

      <FacetContainer className="d-flex align-items-center flex-wrap">
        {facets.map((facet, i) => {
          const filterPropertyOperations = getFilterPropertyOperations(facet.type);
          const filterRequestToFacet = filterRequest.find((x) => x.property === facet.property);
          return (
            <Facet
              key={i}
              filterProperty={facet}
              filterPropertyOperations={filterPropertyOperations}
              filterRequest={filterRequestToFacet}
              mapEnumToProperty={mapEnumToProperty}
              mapDictionaryToProperty={mapDictionaryToProperty}
              onRemove={onFacetRemove}
              onSuccess={onSuccess}
            />
          );
        })}
        <FacetDropDown values={filterMenuItems} onClick={onItemClick} />
      </FacetContainer>

      <div className="d-flex align-items-center">
        {facets.length > 0 && (
          <ClearButton
            name={IconName.CloseCircle}
            stroke={false}
            fill={true}
            color={ColorPalette.gray44}
            onClick={onRemoveFilters}
          />
        )}
      </div>
    </Root>
  );
}

FacetSearchBar.defaultProps = {
  logoStyle: {size: 16, color: ColorPalette.gray44},
  placeholder: 'Search',
};

export default memo(FacetSearchBar);
