import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

export const Title = styled.div`
  font-family: ${Theme.font.primary};
  font-style: 'normal';
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: '16px';
  letter-spacing: 0.2;
  color: ${Theme.color.black};
`;
