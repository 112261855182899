import React, {memo} from 'react';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout, {
  DictionaryFormProps,
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';

import {IDictionary} from 'DictionaryFactory/types';
import PackageGroupForm from './PackageGroupForm/PackageGroupForm';
import {IPackageGroup} from 'Dictionaries/models/IPackageGroup';
import {IUpdatePackageGroupRequest} from 'Admin/AdminReference/services/models/IServerPackageGroup';

function PackageGroups() {
  const {adminAssociationPackageGroups: dictionary} = useDictionaries();

  const wrappedPackageGroupForm = (
    props: DictionaryFormProps<IDictionary<IPackageGroup, IUpdatePackageGroupRequest>>
  ) => {
    return <PackageGroupForm {...props} />;
  };

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={wrappedPackageGroupForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IPackageGroup> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
            <Cell<IPackageGroup> dataKey="name" width="30%" header="Name" render={({name}) => name} />
            <Cell<IPackageGroup>
              dataKey="positionIndex"
              width="10%"
              header="Order in View"
              render={({positionIndex}) => positionIndex}
            />
            <Cell<IPackageGroup>
              dataKey="description"
              width="50%"
              header="Description"
              sortable={false}
              render={({description}) => description}
            />
            <Cell<IPackageGroup>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(PackageGroups);
