import React from 'react';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {FormikProps} from 'formik/dist/types';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {IFormValues, initialValue, validationSchema} from './validation';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {IAssociationPageContactInformation} from 'BusinessPortal/models/IAssociationPageContactInformation';
import {actions, selectors} from 'BusinessPortal/store/associationPage';
import {Nullable} from 'Common/types';
import {InputField} from 'Common/components/FormFields';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';

interface IProps {
  contactInformation: Nullable<IAssociationPageContactInformation>;
}

type IExternalProps = IEditableSection<IProps>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const ContactInformationEdit = (props: AllProps) => {
  const {isValid, isSubmitting} = props;
  const {onSuccess, contactInformationUpdating} = props;

  const isRequesting = contactInformationUpdating.isRequesting;

  useOnSuccessCommunication(contactInformationUpdating, () => onSuccess && onSuccess());
  useErrorCommunicationToToast(contactInformationUpdating);

  if (isRequesting) {
    return <Loading />;
  }

  return (
    <Form>
      <InputField name="email" type="text" label="Email" placeholder="Email" autoComplete="off" memoized={true} />
      <InputField
        name="phoneNumber"
        type="text"
        label="Phone number"
        placeholder="Phone number"
        autoComplete="off"
        memoized={true}
      />
      <InputField
        name="websiteUrl"
        type="text"
        label="Website URL"
        placeholder="Put your association website link here"
        autoComplete="off"
        memoized={true}
      />
      <PrimaryButton className="w-100" size="medium" type="submit" disabled={!isValid} isLoading={isSubmitting}>
        Save changes
      </PrimaryButton>
    </Form>
  );
};

const ContactInformationEditFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({data}) => data?.contactInformation || initialValue,
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {updateContactInformation} = formikBag.props;
    await updateContactInformation(values);
  },
  enableReinitialize: true,
})(ContactInformationEdit);

const mapStateToProps = (state: IAppState) => ({
  contactInformationUpdating: selectors.selectCommunication(state, 'contactInformationUpdating'),
});

const mapDispatchToProps = {
  updateContactInformation: actions.updateContactInformation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ContactInformationEditFormik);
