import React, {memo, useCallback, useMemo, useState} from 'react';

import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';
import {Nullable} from 'Common/types';
import {InformationSection, SectionName} from '../styled';

export interface IEditableSection<T> {
  data: Nullable<T>;
  onSuccess?(): void;
}

interface IProps<T> extends IEditableSection<T> {
  title: string;
  editComponent: React.ComponentType<IEditableSection<T>>;
  showComponent: React.ComponentType<IEditableSection<T>>;
  isReadOnlyMode?: boolean;
}

const EditableSection = <T extends any>(props: IProps<T>) => {
  const {title, editComponent, showComponent, data, onSuccess, isReadOnlyMode} = props;

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const enableEditMode = useCallback(() => setIsEditMode(true), []);
  const disableEditMode = useCallback(() => setIsEditMode(false), []);

  const onSuccessFormHandler = useCallback(() => {
    disableEditMode();
    onSuccess && onSuccess();
  }, [disableEditMode, onSuccess]);

  const componentWrapper = useMemo(() => {
    const Component = isEditMode ? editComponent : showComponent;
    return <Component data={data} onSuccess={onSuccessFormHandler} />;
  }, [data, editComponent, isEditMode, onSuccessFormHandler, showComponent]);

  return (
    <>
      <SectionName className="d-flex justify-content-between">
        {title}
        {!isReadOnlyMode && (
          <>
            {!isEditMode && (
              <IconButton name={IconName.Edit} fill={true} stroke={false} size={16} onClick={enableEditMode} />
            )}
            {isEditMode && (
              <IconButton name={IconName.Close} fill={true} stroke={false} size={16} onClick={disableEditMode} />
            )}
          </>
        )}
      </SectionName>

      <InformationSection>{componentWrapper}</InformationSection>
    </>
  );
};

export default memo(EditableSection) as <T>(props: IProps<T>) => JSX.Element;
