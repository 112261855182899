import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Icon, {IconName} from 'Icon/components/Icon';
import {breakpoints} from 'Common/constants/Breakpoints';

const NamePanel = styled.div`
  bottom: 0;
  height: 80px;
  width: 100%;

  @media ${breakpoints.md} {
    border-radius: 0 0 8px 8px;
  }
`;

const GradientPanel = styled.div`
  background: linear-gradient(180deg, ${ColorPalette.transparent1} 0%, ${ColorPalette.gray14} 100%);
  opacity: 0.7;
  bottom: 0;
  height: 96px;
  width: 100%;

  @media ${breakpoints.md} {
    border-radius: 0 0 8px 8px;
  }
`;

const ParentName = styled.div`
  font-size: ${Typography.size.size18};
  font-weight: ${Typography.weight.medium500};
  color: ${ColorPalette.white0};
  line-height: 24px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0px;
  margin-bottom: 4px;
  z-index: 1;
`;

const TwoLinesDiv = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FirstParent = styled(TwoLinesDiv)`
  margin-right: 25px;
  margin-left: 5px;
  text-align: right;
`;

const SecondParent = styled(TwoLinesDiv)`
  margin-left: 25px;
  margin-right: 5px;
  text-align: left;
`;

const ParentGenderAge = styled.div`
  font-size: ${Typography.size.size13};
  color: ${ColorPalette.white0};
  line-height: 14px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0px;
  margin-bottom: 18px;
  z-index: 1;
`;

const DnaLogoImg = styled(Icon)`
  left: 50%;
  transform: translate(-50%, -25%);
`;

interface IProp {
  stallionName: string;
  mareName: string;
  stallionDetails: string;
  mareDetails: string;
}

const ParentNamePanel = (props: IProp) => {
  return (
    <NamePanel className="position-absolute">
      <div className="d-flex flex-column justify-content-end h-100">
        <ParentName className="position-relative">
          <FirstParent>{props.stallionName}</FirstParent>
          <SecondParent>{props.mareName}</SecondParent>
          <DnaLogoImg name={IconName.DnaIcon} size={45} className="position-absolute" />
        </ParentName>
        <ParentGenderAge>
          <FirstParent className="d-flex justify-content-end">{props.stallionDetails}</FirstParent>
          <SecondParent className="d-flex justify-content-start">{props.mareDetails}</SecondParent>
        </ParentGenderAge>
      </div>
      <GradientPanel className="position-absolute" />
    </NamePanel>
  );
};

export default memo(ParentNamePanel);
