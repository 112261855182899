import {IModule} from 'redux-dynamic-modules';

import {adminGeneGroupImageReducer} from './index';
import {IAdminGeneGroupImageState} from './types';

export interface IAdminGeneGroupImageModuleState {
  adminGeneGroupImage: IAdminGeneGroupImageState;
}

export const AdminGeneGroupImageModule: IModule<IAdminGeneGroupImageModuleState> = {
  id: 'geneGroupImage',
  reducerMap: {
    adminGeneGroupImage: adminGeneGroupImageReducer
  }
};
