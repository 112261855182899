import React, {memo, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';

import {OrderStatus} from 'Common/constants/OrderStatus';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IUserOrder} from 'UserProfile/models/IUserOrder';
import {IAppState} from 'Common/store/IAppState';

import {actions, selectors} from 'Order/store/submissionForm/index';
import {downloadByUrl} from 'Common/helpers/downloadHelper';
import {breakpoints} from 'Common/constants/Breakpoints';
import {MainInfoRow} from 'UserProfile/components/common/styled';
import HorseOverview from 'UserProfile/components/TabsData/common/HorseOverview';
import OrderOverview from 'UserProfile/components/TabsData/common/OrderOverview';
import {Separator} from 'UserProfile/components/TabsData/common/styled';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {mediumMdSmBreakpoint} from '../../common/constants';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import usePermissions from 'Permissions/hooks/usePermissions';
import {Permission} from 'Permissions/constants/Permission';
import {OrderReportType} from 'Common/constants/OrderReportType';
import {OrderReportStatus} from 'Common/constants/OrderReportStatus';

const sampleReceived = 'Once DNA tests are complete, you will see results here.';
const statusDescriptionText: Record<OrderStatus, string> = {
  [OrderStatus.orderPlaced]:
    'Test results provided in 5-10 business days after sample is received and payment is complete. *Ancestry results may take 4-6 weeks. You can pay by check, payable to Etalon Diagnostics, Inc., or contact the Etalon office to use a debit or credit card.',
  [OrderStatus.paymentReceived]: 'Payment received.',
  [OrderStatus.sampleReceived]: sampleReceived,
  [OrderStatus.resultsReady]: sampleReceived,
  [OrderStatus.reviewReady]: sampleReceived,
  [OrderStatus.releaseReady]: 'Results are ready!',
};

const Root = styled.div`
  height: auto;
  width: 100%;
  box-shadow: 0px 6px 16px ${ColorPalette.gray51}, 0px 0px 8px ${ColorPalette.gray50};
  border-radius: 4px;
  background: ${ColorPalette.white0};

  flex-direction: column;
  padding: 16px 24px 24px 24px;
  margin-bottom: 24px;

  @media ${breakpoints.sm} {
    box-shadow: none;
    border-radius: 0;
    flex-direction: row;
    padding: 24px 20px 24px 24px;
    border-bottom: 1px solid ${ColorPalette.white9};
  }
`;

const Container = styled.div`
  @media ${breakpoints.sm} {
    min-width: 40%;
    margin-right: 20px;
  }

  @media ${mediumMdSmBreakpoint} {
    min-width: 24%;
  }
`;

const DescriptionOverview = styled(MainInfoRow)`
  font-size: ${Typography.size.size13};
  line-height: 24px;

  display: flex;
  margin-bottom: 20px;

  @media ${breakpoints.sm} {
    display: none;
  }

  @media ${breakpoints.md} {
    display: flex;
  }
`;

const ButtonsWrapper = styled.div`
  margin-bottom: -24px;

  @media ${breakpoints.sm} {
    margin-top: 0;
  }
`;

const Button = styled(PrimaryButton)`
  width: 100%;
  margin-bottom: 16px;

  @media ${breakpoints.sm} {
    width: 166px;
  }
`;

const ReportLinkButton = styled(LinkButton)`
  width: 100%;
  margin-bottom: 16px;

  @media ${breakpoints.sm} {
    width: 166px;
  }
`;

interface IOwnProps {
  userOrder: IUserOrder;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IOwnProps & RouteComponentProps;

function UserOrder(props: Props) {
  const {
    userOrder: {dateOfBirth, gender, availabilityCriteria, name, avatar, breeds, order, isArchived, id},
    getHorseSubmissionForm,
    loadingSubmissionForm,
    history,
  } = props;

  const {hasPermission} = usePermissions();

  const [isSubmissionFormClicked, setIsSubmissionFormClicked] = useState(false);

  const redirectToHorseProfile = useCallback(() => history.push(`/horse/${id}`), [id, history]);

  const downloadSubmissionForm = useCallback(async () => {
    setIsSubmissionFormClicked(true);
    await getHorseSubmissionForm(order.id, id);
    setIsSubmissionFormClicked(false);
  }, [getHorseSubmissionForm, order, id]);

  const openPaymentForm = useCallback(() => {
    history.push({pathname: '/payment/', search: `?token=${order.token}`});
  }, [history, order]);

  const breed = useMemo(
    () => (breeds.length > 0 ? breeds.map((b) => b.replace(/\//g, '/ ')).join(', ') : `Breeds isn't specified`),
    [breeds]
  );

  const renderSubmissionFormButton = useCallback(() => {
    const isButtonLoading = isSubmissionFormClicked;
    const isButtonDisabled = loadingSubmissionForm.isRequesting;

    return (
      <Button
        variant="outlined"
        size="small"
        onClick={downloadSubmissionForm}
        isLoading={isButtonLoading}
        disabled={!isButtonLoading && isButtonDisabled}
      >
        Submission form
      </Button>
    );
  }, [downloadSubmissionForm, isSubmissionFormClicked, loadingSubmissionForm]);

  return (
    <Root className="d-flex justify-content-between">
      <Container className="w-100">
        <HorseOverview
          horse={{name, availabilityCriteria, avatar, breed, dateOfBirth, gender, horseId: id, isArchived}}
          onClickHandler={redirectToHorseProfile}
        />
      </Container>
      <Separator />

      <div className="d-flex w-100">
        <OrderOverview order={order} />
      </div>
      <Separator />

      <DescriptionOverview className="w-100">{statusDescriptionText[order.status]}</DescriptionOverview>
      <ButtonsWrapper className="d-flex flex-column align-items-center w-100">
        {order.hasPayment &&
          order.reports.map((report, i) => {
            const isDiagnostic =
              report.name === OrderReportType.Diagnostic &&
              hasPermission(Permission.OnlineReport) &&
              report.status !== OrderReportStatus.NotOrdered;
            const isAncestry =
              report.name === OrderReportType.Ancestry &&
              hasPermission(Permission.OnlineReport) &&
              report.status !== OrderReportStatus.NotOrdered;

            if (isDiagnostic) {
              return (
                <ReportLinkButton to={`/user/online-report/${id}/0`} size="small" key={i}>
                  {`${report.name} report`}
                </ReportLinkButton>
              );
            }

            if (isAncestry) {
              return (
                <ReportLinkButton to={`/user/online-report/${id}/0/ancestry`} size="small" key={i}>
                  {`${report.name} report`}
                </ReportLinkButton>
              );
            }

            const handleDownloadResultClick = () => {
              downloadByUrl(report.url, `${report.name} (${name})`, '.pdf');
            };

            return (
              report.url && (
                <Button key={i} onClick={handleDownloadResultClick} size="small">
                  {`${report.type} report`}
                </Button>
              )
            );
          })}
        {!order.hasPayment && (
          <Button onClick={openPaymentForm} size="small">
            Pay order
          </Button>
        )}
        {(order.status === OrderStatus.orderPlaced || order.status === OrderStatus.paymentReceived) &&
          renderSubmissionFormButton()}
      </ButtonsWrapper>
    </Root>
  );
}

const mapStateToProps = (state: IAppState) => ({
  loadingSubmissionForm: selectors.selectCommunication(state, 'downloadFormRequesting'),
});

const mapDispatchToProps = {
  getHorseSubmissionForm: actions.downloadHorseSubmissionForm,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default memo(withRouter(connector(UserOrder)));
