import {combineReducers} from 'redux';

import {HorseBreedPanelsActions, getHorseBreedPanelsActionTypes, IHorseBreedPanelState} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

export const communicationHorseBreedPanelsReducer = combineReducers<
  IHorseBreedPanelState['communications'],
  HorseBreedPanelsActions
>({
  horseBreedPanelsLoading: composeReducers([makeCommunicationReducerFromEnum(getHorseBreedPanelsActionTypes)])
});
