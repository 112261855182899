import {combineReducers, Reducer} from 'redux';

import {IHorseState} from '../types';
import {communicationHorseReducer} from './communications';
import {dataHorseReducer} from './data';

export const horseReducer: Reducer<IHorseState> = combineReducers<IHorseState>({
  communications: communicationHorseReducer,
  data: dataHorseReducer
});
