import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.horseFilters;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectGenePools(state: IAppState) {
  return selectState(state).data.genePools;
}
