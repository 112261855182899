import React, {memo} from 'react';
import {Helmet} from 'react-helmet';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import AdminTabs from 'Admin/common/AdminTabs';
import AdminContent from 'Admin/common/AdminContent';
import {ITab} from 'Common/components/Tabs/ITab';
import ColorCombinations from './ColorCombinations/ColorCombinations';
import ModifierCombinations from './ModifierCombinations/ModifierCombinations';
import AbilityGroupsAdvanced from './AbilityGroupsAdvanced/AbilityGroupsAdvanced';
import DangerGroupsAdvanced from './DangerGroupsAdvanced/DangerGroupsAdvanced';
import HealthVariantGroups from './HealthVariantGroups/HealthVariantGroups';
import PartialColorGroups from './PartialColorGroups/PartialColorGroups';
import PartialColors from './PartialColors/PartialColors';
import AbilityRules from './AbilityRules/AbilityRules';
import DangersAdvanced from './DangersAdvanced/DangersAdvanced';
import HealthVariantRules from './HealthVariantRules/HealthVariantRules';
import AffectionTypes from './AffectionTypes/AffectionTypes';
import HealthVariants from './HealthVariants/HealthVariants';
import Abilities from './Abilities/Abilities';
import Genotypes from './Genotypes/Genotypes';
import GenotypeCombinations from './GenotypeCombinations/GenotypeCombinations';

const basePath = '/admin-phenotypes-advanced';

const adminData: ITab[] = [
  {section: 'affection-types', title: 'Affection types', Component: AffectionTypes},
  {section: 'color-combinations', title: 'Colors', Component: ColorCombinations},
  {section: 'modifier-combinations', title: 'Modifiers', Component: ModifierCombinations},
  {section: 'abilities', title: 'Abilities', Component: Abilities},
  {section: 'ability-rules', title: 'Ability rules', Component: AbilityRules},
  {section: 'abilities-groups', title: 'Ability groups', Component: AbilityGroupsAdvanced},
  {section: 'dangers', title: 'Dangers', Component: DangersAdvanced},
  {section: 'dangers-groups', title: 'Danger groups', Component: DangerGroupsAdvanced},
  {section: 'health-variant-rules', title: 'Health variant rules', Component: HealthVariantRules},
  {section: 'health-variants', title: 'Health variants', Component: HealthVariants},
  {
    section: 'health-variant-groups',
    title: 'Health variant groups',
    Component: HealthVariantGroups,
  },
  {section: 'partial-color-groups', title: 'Partial color groups', Component: PartialColorGroups},
  {section: 'partial-colors', title: 'Partial colors', Component: PartialColors},
  {section: 'genotypes', title: 'Genotypes', Component: Genotypes},
  {section: 'genotype-combinations', title: 'Genotypes Combinations', Component: GenotypeCombinations},
];

function AdminPhenotypesAdvanced() {
  return (
    <BaseLayout withoutPaddings={true} widthByContent={true} isBackButtonDenied={true}>
      <Helmet>
        <title>Advanced Phenotypes</title>
      </Helmet>

      <AdminTabs tabs={adminData} basePath={basePath} isSort={true} />
      <AdminContent tabs={adminData} basePath={basePath} isSort={true} />
    </BaseLayout>
  );
}

export default memo(AdminPhenotypesAdvanced);
