import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

import {
  Actions,
  createOrderActionTypes,
  getContactInformationActionTypes,
  getCouponActionTypes,
  getHorsesActionTypes,
  getOrderPackagesActionTypes,
  getSummaryActionTypes,
  ICreateOrderState,
  updateContactInformationActionTypes,
} from '../types';

export const communicationReducer = combineReducers<ICreateOrderState['communications'], Actions>({
  horsesLoading: makeCommunicationReducerFromEnum(getHorsesActionTypes),
  summaryLoading: makeCommunicationReducerFromEnum(getSummaryActionTypes),
  couponLoading: makeCommunicationReducerFromEnum(getCouponActionTypes),
  creatingOrder: makeCommunicationReducerFromEnum(createOrderActionTypes),
  contactInformationLoading: makeCommunicationReducerFromEnum(getContactInformationActionTypes),
  contactInformationUpdating: makeCommunicationReducerFromEnum(updateContactInformationActionTypes),
  orderPackagesLoading: makeCommunicationReducerFromEnum(getOrderPackagesActionTypes),
});
