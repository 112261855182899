import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication/index';

import {IOrderHorse} from 'Order/models/IOrderHorse';
import {ITestSummary} from 'Order/models/ITestSummary';
import {IOrderCoupon} from 'Order/models/IOrderCoupon';
import {IPackageSimple} from 'Dictionaries/models/IPackageSimple';
import {IOrderPackage} from 'Admin/AdminDashboard/models/Order/IOrderPackage';

const STATE_NAME = 'adminCreateUserOrder';

export interface IAdminCreateUserOrderState {
  data: {
    userHorses: IOrderHorse[];
    userPackages: IPackageSimple[];
    userSummary: ITestSummary | null;
    userCoupon: IOrderCoupon | null;
    orderPackages: IOrderPackage[];
  };
  communications: {
    userHorsesLoading: ICommunication;
    userPackagesLoading: ICommunication;
    userSummaryLoading: ICommunication;
    userCouponLoading: ICommunication;
    creatingUserOrder: ICommunication;
    orderPackagesLoading: ICommunication;
  };
}

export const getUserHorsesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_HORSES');
export type getUserHorsesActions = MakeCommunicationActions<typeof getUserHorsesActionTypes, {success: IOrderHorse[]}>;

export const getUserPackagesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_PACKAGES');
export type getUserPackagesActions = MakeCommunicationActions<
  typeof getUserPackagesActionTypes,
  {success: IPackageSimple[]}
>;

export const getUserSummaryActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_SUMMARY');
export type getUserSummaryActions = MakeCommunicationActions<typeof getUserSummaryActionTypes, {success: ITestSummary}>;

export const getUserCouponActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_COUPON');
export type getUserCouponActions = MakeCommunicationActions<typeof getUserCouponActionTypes, {success: IOrderCoupon}>;

export const createUserOrderActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_USER_ORDER');
export type createUserOrderActions = MakeCommunicationActions<typeof createUserOrderActionTypes, {}>;

export const getOrderPackagesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_PACKAGES');
export type getOrderPackagesActions = MakeCommunicationActions<
  typeof getOrderPackagesActionTypes,
  {success: IOrderPackage[]}
>;

export const resetUserCouponActionTypes = makeResetCommunicationActionType(STATE_NAME, 'USER_COUPON');
export type resetUserCouponActions = MakeResetCommunicationActions<typeof resetUserCouponActionTypes>;

export const resetUserSummaryActionTypes = makeResetCommunicationActionType(STATE_NAME, 'USER_SUMMARY');
export type resetUserSummaryActions = MakeResetCommunicationActions<typeof resetUserSummaryActionTypes>;

export const resetAdminUserOrderActionTypes = makeResetCommunicationActionType(STATE_NAME, 'USER_ORDER');
export type resetAdminUserOrderActions = MakeResetCommunicationActions<typeof resetAdminUserOrderActionTypes>;

export type Actions =
  | getUserHorsesActions
  | getUserPackagesActions
  | getUserSummaryActions
  | getUserCouponActions
  | createUserOrderActions
  | resetUserCouponActions
  | resetUserSummaryActions
  | resetAdminUserOrderActions
  | getOrderPackagesActions;
