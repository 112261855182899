import {Form, FormikProps, withFormik} from 'formik';
import {memo, useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {CheckboxField, InputField} from 'Common/components/FormFields';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import WarningModalWindow, {ModalTypes} from 'Common/components/Modal/WarningModal';
import {
  ErrorMessage,
  Hint,
  MutableField,
  MutableFieldsGroup,
} from 'Common/components/StyledComponents/StyledComponents';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Horse/store/horseTransfer';
import {convertFormValuesToRequest} from './converters';
import {IFormValues, initialValue, validationSchema} from './validation';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useSuccessCommunicationToToast} from 'Common/helpers/hooks/useSuccessCommunicationToToast';

const checkboxStyle = {marginBottom: 8};

interface IExternalProps {
  horseId: number;
  horseName: string;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function HorseTransferForm(props: AllProps) {
  const {horseId, horseName, horseTransferCreating, onSuccess} = props;
  const {handleSubmit, setFieldValue, errors, setFieldTouched, setErrors, setStatus, status} = props;

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const onErrorModalOpen = useCallback(async () => {
    setIsErrorModalOpen(true);
  }, []);
  const onErrorModalClose = useCallback(() => setIsErrorModalOpen(false), []);

  const errorInfo = horseTransferCreating.error;

  useEffect(() => {
    setFieldValue('horseId', horseId);
    setFieldValue('defaultHorseName', horseName);
    setStatus('');
  }, [horseId, horseName, setFieldValue, setStatus]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    const fieldErrors = getFieldErrors(errorInfo);

    if (commonErrors) {
      setStatus(commonErrors);
    }

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  useOnSuccessCommunication(horseTransferCreating, onSuccess);
  useSuccessCommunicationToToast(horseTransferCreating, 'Horse transfer request has been created');

  const onSaveClick = useCallback(() => {
    if (Object.keys(errors).length !== 0) {
      onErrorModalOpen();
      Object.keys(errors).forEach((error) => {
        setFieldTouched(error, true);
      });
      return;
    }
    handleSubmit();
  }, [errors, handleSubmit, onErrorModalOpen, setFieldTouched]);

  return (
    <>
      <WarningModalWindow
        modalType={ModalTypes.Alert}
        modalTitle="Warning"
        isOpen={isErrorModalOpen}
        onClose={onErrorModalClose}
        onSuccess={onErrorModalClose}
      >
        <p>To complete transaction you need to:</p>
        <ul>
          <li>All fields must be completed</li>
          <li>All checkboxes must be selected</li>
        </ul>
      </WarningModalWindow>

      <ModalWindowHeader>Horse Transfer Request</ModalWindowHeader>
      <Form>
        <ModalWindowFormContent>
          <FormControlContainer title="Agreement Release">
            <Hint>
              I agree to the above release statement and release all rights to all data and items checked above for the
              horse (<strong>{horseName}</strong>) named in the Etalon Profile.
            </Hint>

            <CheckboxField name="releaseHorseProfile" label="Release Horse Profile" style={checkboxStyle} />
            <CheckboxField name="releaseHorseResults" label="Release Horse Orders/Results" style={checkboxStyle} />
            <CheckboxField name="releaseHorseSample" label="Release Horse Hair Sample" style={checkboxStyle} />

            <CheckboxField
              name="isAgreeStatements"
              label="I agree to the above statements and release all rights to all items checkedabove for the following Horse as named in the Etalon Profile:"
              style={{marginTop: 4, ...checkboxStyle}}
              labelStyle={{lineHeight: '20px'}}
            />
            <InputField
              name="horseName"
              label="Horse Name"
              placeholder="Please type the name of the horse you wish to transfer here so we can confirm your choice"
            />
          </FormControlContainer>

          <FormControlContainer title="Please release all of the above information to the following Etalon client">
            <Hint>
              Client named must already have an existing Etalon account, and the email listed must match the client name
              on existing account.
            </Hint>

            <MutableFieldsGroup indent="16px">
              <MutableField width={50}>
                <InputField name="providedFirstName" label="Client First Name" />
              </MutableField>
              <MutableField width={50}>
                <InputField name="providedLastName" label="Client Last Name" />
              </MutableField>
            </MutableFieldsGroup>
            <InputField name="providedEmail" label="Client Email" />
            <InputField name="providedPhone" label="Phone Number" />

            <CheckboxField
              name="isAgreeRights"
              label="I agree to release all rights to all items checked above to the person listedabove."
              style={checkboxStyle}
            />
            <CheckboxField
              name="isSubmitRequest"
              label="Submit my request for review and approval. I understand that this processmay take up to 5 business days."
              style={{marginTop: 4, ...checkboxStyle}}
            />
          </FormControlContainer>

          <InputField name="signature" label="Signature" />
        </ModalWindowFormContent>
      </Form>
      <ModalWindowFooter>
        <ErrorMessage>{status}</ErrorMessage>
        <ModalWindowButton type="button" onClick={onSaveClick}>
          Submit
        </ModalWindowButton>
      </ModalWindowFooter>
    </>
  );
}

const HorseTransferFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {createHorseTransfer} = formikBag.props;
    formikBag.setSubmitting(true);
    await createHorseTransfer(convertFormValuesToRequest(values));
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(HorseTransferForm);

const mapStateToProps = (state: IAppState) => ({
  horseTransferCreating: selectors.selectCommunication(state, 'horseTransferCreating'),
});

const mapDispatchToProps = {
  createHorseTransfer: actions.createHorseTransfer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(HorseTransferFormFormik));
