import React from 'react';
import {withGoogleMap, withGoogleScript} from 'Common/helpers/Map/index';
import {IWithGoogleScriptProps} from './withGScript';
import {IWithGoogleMapProps} from './withGMap';

import {compose} from 'recompose';

type IWithMapProps = IWithGoogleScriptProps & IWithGoogleMapProps;

export function withMap<T>(Component: React.ComponentType<T & IWithMapProps>) {
  const wrapped = (props: T & IWithMapProps) => <Component {...props} />;

  wrapped.displayName = `withMap(${Component.displayName || Component.name || 'Component'})`;

  return compose<any, T & Partial<IWithMapProps>>(withGoogleScript, withGoogleMap)(wrapped);
}
