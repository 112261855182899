import React, {memo, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {connect, ConnectedProps} from 'react-redux';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Icon, {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {breakpoints, size} from 'Common/constants/Breakpoints';
import HeaderButtons from 'Common/components/Header/HeaderButtons/HeaderButtons';
import UserMenu from 'Common/components/Header/UserMenu/UserMenu';
import {zIndex} from 'Common/constants/ZIndex';
import {scrollToTop} from 'Common/helpers/scrollToTop';
import {selectors as permissionsSelectors} from 'Permissions/store';
import {selectors as adminPermissionsSelectors} from 'Admin/common/Permissions/store/index';
import {selectors as userSelectors} from 'UserProfile/store/currentUser';
import {IAppState} from 'Common/store/IAppState';
import Theme from 'Common/constants/Theme';
import IconButton from 'Common/components/Controls/Buttons/IconButton';

const LinkedLogo = styled.a`
  margin-left: 0;

  @media ${breakpoints.sm} {
    margin-left: 32px;
  }
`;

const Navbar = styled.div`
  background-color: ${ColorPalette.black1};
  color: ${ColorPalette.white0};
  padding: 0;
  min-width: ${size.xss}px;
  width: 100%;
  z-index: ${zIndex.header};
`;

const NavbarItemsWrapper = styled.div`
  width: 100%;
  height: 56px;
  font-size: ${Typography.size.size13};
  font-weight: ${Typography.weight.normal400};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-top: 9px;
`;

const MobileBackIcon = styled(IconButton)`
  margin: 22px 0 22px 22px;
`;

const LogoIcon = styled(ColoredIcon)`
  margin-right: 0;

  width: 50px;
  height: 24px;

  @media ${breakpoints.sm} {
    width: 65px;
    height: 32px;
  }
`;

const LogoName = styled(Icon)`
  width: 87px;
  height: 12px;

  @media ${breakpoints.md} {
    width: 115px;
    height: 15px;
  }
`;

interface IOwnProps {
  leftSection?: React.ReactElement;
  isBackButtonDenied?: boolean;
  hideUserSection?: boolean;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IOwnProps & IConnected;

function Header(props: AllProps) {
  const {
    currentUser,
    currentAdmin,
    userPermissions,
    adminPermissions,
    leftSection,
    isBackButtonDenied,
    hideUserSection,
    currentAssociationEmployee,
  } = props;
  const {isMobile, isTablet} = useMediaQuery();
  const history = useHistory();

  const [isActiveUserMenu, setIsActiveUserMenu] = useState(false);
  const openUserMenu = useCallback(() => {
    scrollToTop();
    setIsActiveUserMenu(true);
  }, [setIsActiveUserMenu]);
  const closeUserMenu = useCallback(() => setIsActiveUserMenu(false), [setIsActiveUserMenu]);

  const backClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const userMenu = useMemo(() => {
    if (currentUser) {
      const visitor = {
        id: currentUser.id,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        avatarUrl: currentUser.avatar?.url,
      };

      return <UserMenu visitor={visitor} visitorPermissions={userPermissions} onLinkHandler={closeUserMenu} />;
    }

    if (currentAdmin) {
      const visitor = {
        id: currentAdmin.id,
        name: currentAdmin?.name,
        avatarUrl: currentAdmin.avatar?.url,
      };

      return <UserMenu visitor={visitor} visitorPermissions={adminPermissions} onLinkHandler={closeUserMenu} />;
    }

    if (currentAssociationEmployee) {
      const visitor = {
        id: currentAssociationEmployee.id,
        name: currentAssociationEmployee?.name,
        avatarUrl: currentAssociationEmployee.avatar?.url,
      };

      return <UserMenu visitor={visitor} visitorPermissions={userPermissions} onLinkHandler={closeUserMenu} />;
    }

    return null;
  }, [adminPermissions, closeUserMenu, currentAdmin, currentAssociationEmployee, currentUser, userPermissions]);

  const showUserMenu = !hideUserSection && isMobile && isActiveUserMenu;
  return (
    <>
      <Navbar className="navbar navbar-expand-lg justify-content-center">
        <NavbarItemsWrapper className="d-flex justify-content-between align-items-center">
          {leftSection}
          {!leftSection && !isBackButtonDenied && isMobile && (
            <MobileBackIcon
              onClick={backClick}
              name={IconName.Back}
              size={12}
              color={Theme.color.white}
              hoverColor={ColorPalette.white3}
              fill={true}
              stroke={false}
            />
          )}
          <LinkedLogo href="http://www.etalondx.com/" className="d-flex flex-row" onClick={closeUserMenu}>
            <LogoIcon name={IconName.EtalonHorseLogo} color={Theme.color.white} fill={true} />
            <TextWrapper>{!isTablet && <LogoName name={IconName.EtalonLogo} />}</TextWrapper>
          </LinkedLogo>
          {!hideUserSection && <HeaderButtons openUserMenu={openUserMenu} closeUserMenu={closeUserMenu} />}
        </NavbarItemsWrapper>
      </Navbar>

      {showUserMenu && userMenu}
    </>
  );
}

const mapStateToProps = (state: IAppState) => ({
  userPermissions: permissionsSelectors.selectCurrentPermissions(state),
  adminPermissions: adminPermissionsSelectors.selectCurrentAdminPermissions(state),
  currentUser: userSelectors.selectCurrentUser(state),
  currentAdmin: userSelectors.selectCurrentAdmin(state),
  currentAssociationEmployee: userSelectors.selectCurrentAssociationEmployee(state),
});

const connector = connect(mapStateToProps);
export default connector(memo(Header));
