import * as Yup from 'yup';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IUpdateHorseInconclusiveHealthIssuesBP} from 'BusinessPortal/models/horse/IInconclusiveHealthIssuesBP';

export type IFormValues = IUpdateHorseInconclusiveHealthIssuesBP;

export const initialValue: IFormValues = {
  horseId: 0,
  inconclusiveHealthIssues: []
};

export const validationSchema = Yup.object().shape<IFormValues>({
  horseId: Yup.number().required(REQUIRED_FIELD),
  inconclusiveHealthIssues: Yup.array().of(Yup.number())
});
