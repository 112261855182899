import {combineReducers, Reducer} from 'redux';

import {IExcludeHorseState} from 'ExcludeHorse/store/types';
import {communicationReducer} from 'ExcludeHorse/store/reducer/communications';
import {dataReducer} from 'ExcludeHorse/store/reducer/data';
import {uiReducer} from 'ExcludeHorse/store/reducer/ui';

export const excludeHorsesReducer: Reducer<IExcludeHorseState> = combineReducers<IExcludeHorseState>({
  communications: communicationReducer,
  data: dataReducer,
  ui: uiReducer
});
