import {Reducer} from 'redux';

import {
  Actions,
  getOrderPaymentTokenActionTypes,
  getPaymentHorsesActionTypes,
  getPaymentSummaryActionTypes,
  IBusinessPortalOrderPaymentState,
} from '../types';

const initialState: IBusinessPortalOrderPaymentState['data'] = {
  paymentSummary: null,
  paymentHorses: [],
  orderPaymentToken: null,
};

export const dataReducer: Reducer<IBusinessPortalOrderPaymentState['data'], Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case getOrderPaymentTokenActionTypes.REQUEST: {
      return {...state, orderPaymentToken: null};
    }
    case getOrderPaymentTokenActionTypes.SUCCESS: {
      return {...state, orderPaymentToken: action.payload};
    }
    case getPaymentHorsesActionTypes.SUCCESS: {
      return {...state, paymentHorses: action.payload};
    }
    case getPaymentSummaryActionTypes.SUCCESS: {
      return {...state, paymentSummary: action.payload};
    }
    default: {
      return state;
    }
  }
};
