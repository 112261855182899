import {IUser} from 'UserProfile/models/IUser';
import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';

import {IEntity} from 'Common/models/IEntity';
import {Nullable} from 'Common/types';
import {IAdmin} from 'UserProfile/models/IAdmin';
import {IAssociationEmployee} from 'UserProfile/models/IAssociationEmployee';

export interface ICurrentUserProfileType extends IEntity {
  isPublic: boolean;
}

const STATE_NAME = 'currentUser';

export interface ICurrentUserState {
  data: {
    user: Nullable<IUser>;
    admin: Nullable<IAdmin>;
    userProfileType: Nullable<ICurrentUserProfileType>;
    associationEmployee: Nullable<IAssociationEmployee>;
  };
  communications: {
    currentUserLoading: ICommunication; // TODO: REFACTOR COMMUNICATION STATE
    currentAdminLoading: ICommunication;
    currentAssociationEmployeeLoading: ICommunication;
    userLoading: ICommunication;
    userUpdating: ICommunication;
    userProfileTypeLoading: ICommunication;
    userProfileTypeUpdating: ICommunication;
  };
}

export const getCurrentUserActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_CURRENT_USER');

export type getCurrentUserActions = MakeCommunicationActions<typeof getCurrentUserActionTypes, {success: IUser}>;

export const resetCurrentUserActionTypes = makeResetCommunicationActionType(STATE_NAME, 'CURRENT_USER');
export type resetCurrentUserActions = MakeResetCommunicationActions<typeof resetCurrentUserActionTypes>;

export const getCurrentAdminActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_CURRENT_ADMIN');

export type getCurrentAdminActions = MakeCommunicationActions<typeof getCurrentAdminActionTypes, {success: IAdmin}>;

export const resetCurrentAdminActionTypes = makeResetCommunicationActionType(STATE_NAME, 'CURRENT_ADMIN');
export type resetCurrentAdminActions = MakeResetCommunicationActions<typeof resetCurrentAdminActionTypes>;

export const getCurrentAssociationEmployeeActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_CURRENT_ASSOCIATION_EMPLOYEE'
);

export type getCurrentAssociationEmployeeActions = MakeCommunicationActions<
  typeof getCurrentAssociationEmployeeActionTypes,
  {success: IAssociationEmployee}
>;

export const resetCurrentAssociationEmployeeActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'CURRENT_ASSOCIATION_EMPLOYEE'
);
export type resetCurrentAssociationEmployeeActions = MakeResetCommunicationActions<
  typeof resetCurrentAssociationEmployeeActionTypes
>;

export const confirmAgreementCurrentUserActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CONFIRM_AGREEMENT_CURRENT_USER'
);

export type confirmAgreementCurrentUserActions = MakeCommunicationActions<
  typeof confirmAgreementCurrentUserActionTypes,
  {success: {isAgree: boolean}}
>;

export const editProfileInfoCurrentUserActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'EDIT_PROFILE_INFO_CURRENT_USER'
);

export type editProfileInfoCurrentUserActions = MakeCommunicationActions<
  typeof editProfileInfoCurrentUserActionTypes,
  {}
>;

export const getProfileTypeCurrentUserActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_PROFILE_TYPE_CURRENT_USER'
);

export type getProfileTypeCurrentUserActions = MakeCommunicationActions<
  typeof getProfileTypeCurrentUserActionTypes,
  {success: ICurrentUserProfileType}
>;

export const updateProfileTypeCurrentUserActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_PROFILE_TYPE_CURRENT_USER'
);

export type updateProfileTypeCurrentUserActions = MakeCommunicationActions<
  typeof updateProfileTypeCurrentUserActionTypes,
  {}
>;

export type CurrentUserActions =
  | getCurrentUserActions
  | getCurrentAdminActions
  | getCurrentAssociationEmployeeActions
  | resetCurrentAssociationEmployeeActions
  | resetCurrentAdminActions
  | resetCurrentUserActions
  | confirmAgreementCurrentUserActions
  | editProfileInfoCurrentUserActions
  | updateProfileTypeCurrentUserActions
  | getProfileTypeCurrentUserActions;
