export const size = {
  xss: 320,
  xs: 425,
  sm: 576,
  md: 992,
  md2: 1280,
  lg: 1440,
  xl: 1920,
  xxl: 2560,
};

/** Note: 
0 < mobile < 576
576 <= tablet < 992
992 <= desktop 
*/

export const breakpoints = {
  xss: `(min-width: ${size.xss}px)`,
  xs: `(min-width: ${size.xs}px)`,
  sm: `(min-width: ${size.sm}px)`,
  md: `(min-width: ${size.md}px)`,
  md2: `(min-width: ${size.md2}px)`,
  lg: `(min-width: ${size.lg}px)`,
  xl: `(min-width: ${size.xl}px)`,
  xxl: `(min-width: ${size.xxl}px)`,
};
