import React, {memo, useCallback} from 'react';

import {IFieldProps} from 'Common/models/IFieldProps';
import {ValidationError} from 'Common/components/FormFields/index';
import withField from 'Common/components/FormFields/withField';
import {SelectProps} from 'Common/components/Controls/Select/Select';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import {IFoundOrganization} from 'Admin/AdminAssociations/models/IFoundOrganization';
import OrganizationSearch from './OrganizationSearch';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';

type IProps = {
  action?(filter: string): Promise<IFoundOrganization[]>;
  defaultValue?: IDictionaryOptionType;
} & SelectProps<IDictionaryOptionType> &
  IFieldProps<number> &
  IBaseControlProps;

function OrganizationSearchField(props: IProps) {
  const {
    field: {name},
    form: {setFieldValue},
    action,
    defaultValue,
    ...rest
  } = props;

  const onSelect = useCallback(
    (id: number) => {
      setFieldValue(name, id);
    },
    [name, setFieldValue]
  );

  const onRemove = useCallback(() => {
    setFieldValue(name, undefined);
  }, [name, setFieldValue]);

  return (
    <div>
      <FieldControlContainer {...rest}>
        <OrganizationSearch
          onSelect={onSelect}
          action={action}
          onClear={onRemove}
          name={name}
          defaultValue={defaultValue}
        />
      </FieldControlContainer>
      <ValidationError name={name} />
    </div>
  );
}

export default memo(withField(OrganizationSearchField));
