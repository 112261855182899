import {IServerFoal, IServerFoalAbility} from 'BuildHorse/models/Foal';
import {IFoal, IFoalAbility} from 'Common/models/IFoal';
import {IProbabilityEntity} from 'Common/models/IProbabilityEntity';
import {convertAbilityToClient} from 'Common/helpers/convertAbilities';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {IServerProbabilityEntity} from 'FoalProfile/services/models/IServerProbabilityEntity';
import {InformationAvailabilityType} from 'Common/constants/InformationAvailabilityType';
import {withAvatar} from 'Common/helpers/withAvatar';
import {withImage} from 'Common/helpers/withImage';

export function convertFoalToClient(serverFoal: IServerFoal): IFoal {
  const coat: IServerProbabilityEntity[] = serverFoal.colors
    ? serverFoal.colors.concat(serverFoal.modifiers)
    : serverFoal.modifiers;
  return {
    ...serverFoal,
    coat: convertFoalProbabilityToClient(coat),
    parents: serverFoal.parents.map(withBirthDate).map(withAvatar),
    abilities: convertFoalAbilityToClient(serverFoal.abilities),
    dangers: convertFoalProbabilityToClient(serverFoal.dangers.map((danger) => ({...danger, isDanger: true}))),
    healthIssues: convertFoalProbabilityToClient(serverFoal.healthIssues),
  };
}

export function convertFoalAbilityToClient(serverAbilities: IServerFoalAbility[]): IFoalAbility[] {
  return serverAbilities.map((ability) => ({
    ...ability,
    state: convertAbilityToClient(ability.state),
    isFullInfo: ability.informationAvailability === InformationAvailabilityType.FromBoth,
  }));
}

export function convertFoalProbabilityToClient(serverProbability: IServerProbabilityEntity[]): IProbabilityEntity[] {
  return serverProbability.map((probability) => ({
    ...withImage(probability),
    isFullInfo: probability.informationAvailability === InformationAvailabilityType.FromBoth,
  }));
}
