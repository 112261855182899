import React, {memo} from 'react';
import {FormikProps} from 'formik/dist/types';
import styled from 'styled-components';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {ReportType} from 'Common/constants/ReportType';

import {IFormValues, initialValues, validationSchema} from 'Admin/AdminDashboard/components/Reports/shared/validation';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {DateTimeField} from 'Common/components/FormFields/index';
import {ActionButton} from 'Admin/AdminDashboard/components/Reports/shared/styledComponents';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminReports/index';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {AdminReportsModule} from 'Admin/AdminDashboard/store/adminReports/adminReportsModule';

export const ReportFormType = {
  ...ReportType,
  All: 'All',
};

const SubmitButton = styled(ActionButton)`
  align-self: center;
  margin-top: 16px;
`;

interface IProps {
  reportType: keyof typeof ReportFormType;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const Report = (props: AllProps) => {
  const {
    isSubmitting,
    isValid,
    downloadPaidOrdersRequesting,
    downloadDemographicsRequesting,
    downloadOrdersRequesting,
  } = props;

  useCommunicationToToast(downloadPaidOrdersRequesting, 'Paid Orders report successfully created', undefined, 1);
  useCommunicationToToast(downloadDemographicsRequesting, 'Demographics report successfully created', undefined, 2);
  useCommunicationToToast(downloadOrdersRequesting, 'Orders report successfully created', undefined, 3);

  const isDisabled = !isValid;

  return (
    <Form className="d-flex flex-column justify-content-center">
      <DateTimeField name="from" label="From" isRequired={true} />
      <DateTimeField name="to" label="To" isRequired={true} />
      <SubmitButton type="submit" isLoading={isSubmitting} disabled={isDisabled}>
        Download
      </SubmitButton>
    </Form>
  );
};

const ReportFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValues,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {downloadPaidOrdersReport, downloadDemographicsReport, downloadOrdersReport, reportType} = formikBag.props;
    const {from, to} = values;

    const mapEnumToFunc = {
      [ReportFormType.PaidOrders]: [downloadPaidOrdersReport],
      [ReportFormType.Demographics]: [downloadDemographicsReport],
      [ReportFormType.Orders]: [downloadOrdersReport],
      [ReportFormType.All]: [downloadPaidOrdersReport, downloadDemographicsReport, downloadOrdersReport],
    };

    if (!from || !to) {
      return;
    }

    await Promise.all(mapEnumToFunc[reportType].map((item) => item(from, to)));
  },
})(Report);

const mapStateToProps = (state: IAppState) => ({
  downloadPaidOrdersRequesting: selectors.selectCommunication(state, 'downloadPaidOrdersRequesting'),
  downloadDemographicsRequesting: selectors.selectCommunication(state, 'downloadDemographicsRequesting'),
  downloadOrdersRequesting: selectors.selectCommunication(state, 'downloadOrdersRequesting'),
});

const mapDispatchToProps = {
  downloadPaidOrdersReport: actions.downloadPaidOrdersReport,
  downloadDemographicsReport: actions.downloadDemographicsReport,
  downloadOrdersReport: actions.downloadOrdersReport,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(memo(ReportFormik)), AdminReportsModule);
