import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminHtmlTemplates;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectHtmlTemplates(state: IAppState) {
  return selectState(state).data.templates;
}

export function selectHtmlTemplate(state: IAppState) {
  return selectState(state).data.template;
}
