import React, {memo} from 'react';
import {OrderInfoHeaderProps} from 'Admin/AdminDashboard/components/Orders/OrderInfoModal/OrderInfoHeader/OrderInfoHeader/common/models';
import {GrayCell} from 'Admin/AdminDashboard/components/Orders/OrderInfoModal/OrderInfoHeader/OrderInfoHeader/common/styled';

const OrderPackages = (props: OrderInfoHeaderProps) => {
  const {horsesDetails} = props;

  const orderHasSeveralHorses = horsesDetails.length > 1;

  if (orderHasSeveralHorses) {
    return <GrayCell>Several</GrayCell>;
  }

  return <>{horsesDetails[0].packagesDetails.packages.map(item => item.name).join(', ')}</>;
};

export default memo(OrderPackages);
