import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

import {IAdminServiceMode} from 'Admin/AdminAdvancedDashboard/models/IAdminServiceMode';

const STATE_NAME = 'adminServiceMode';

export interface IAdminServiceModeState {
  data: {
    adminServiceMode: IAdminServiceMode;
  };
  communications: {
    adminServiceModeLoading: ICommunication;
    adminServiceModeUpdating: ICommunication;
  };
}

export const getServiceModeActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_SERVICE_MODE');

export type getServiceModeActions = MakeCommunicationActions<
  typeof getServiceModeActionTypes,
  {success: IAdminServiceMode}
>;

export const setServiceModeActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_SERVICE_MODE');

export type setServiceModeActions = MakeCommunicationActions<typeof setServiceModeActionTypes, {}>;

export type ServiceModeActions = getServiceModeActions | setServiceModeActions;
