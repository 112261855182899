import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import AncestryUploadForm from 'Admin/shared/components/AncestryUploadForm/AncestryUploadForm';

interface IUpdatePaymentModalParams {
  onSuccess?(): void;
}

export function useAncestryUploadModal(params: IUpdatePaymentModalParams) {
  const {onSuccess} = params;

  const [isOpen, setIsOpen] = useState(false);
  const openAncestryUploadModal = useCallback(() => setIsOpen(true), []);
  const closeAncestryUploadModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeAncestryUploadModal();
    onSuccess && onSuccess();
  }, [closeAncestryUploadModal, onSuccess]);

  const ancestryUploadModal = (
    <ModalWindow isOpen={isOpen} onClose={closeAncestryUploadModal}>
      <AncestryUploadForm onSuccess={handleSuccess} />
    </ModalWindow>
  );

  return {
    ancestryUploadModal,
    openAncestryUploadModal,
  };
}
