import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.horseTransfer;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectHorseTransferDetails(state: IAppState) {
  return selectState(state).data.horseTransferDetails;
}
