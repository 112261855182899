import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminPurchasableTests;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectPurchasableTests(state: IAppState) {
  return selectState(state).data.purchasableTests;
}

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectPurchasableTestDetails(state: IAppState) {
  return selectState(state).data.purchasableTestDetail;
}
