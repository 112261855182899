import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.adminEmployees;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectAdminEmployees(state: IAppState) {
  return selectState(state).data.adminEmployees;
}

export function selectAdminEmployee(state: IAppState) {
  return selectState(state).data.adminEmployee;
}

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectGeoLocation(state: IAppState) {
  return selectState(state).data.geoLocation;
}
