import * as Yup from 'yup';

import {IAdminNotificationType} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdminNotificationType';
import {IAdminNotifications} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdminNotifications';
import {INotificationType} from 'Dictionaries/models/INotificationType';
import {IAdminNotificationRequest} from 'Admin/AdminAdvancedDashboard/services/types/notification';

export interface IFormValues {
  adminId: number;
  notificationTypes: IAdminNotificationType[];
}

export const initialValue: IFormValues = {
  adminId: 0,
  notificationTypes: []
};

export const validationSchema = Yup.object().shape<IFormValues>({
  adminId: Yup.number(),
  notificationTypes: Yup.array().of(Yup.mixed())
});

export function convertAdminNotificationToFormValues(
  data: IAdminNotifications,
  notificationTypes: INotificationType[]
): IFormValues {
  return {
    adminId: data.id,
    notificationTypes: notificationTypes.map(notification => ({
      ...notification,
      description: notification.description?.value,
      isEnable: data.notificationTypes ? data.notificationTypes.some(x => x.toString() === notification.code) : false
    }))
  };
}

export function convertFormValuesToRequest(values: IFormValues): IAdminNotificationRequest {
  return {
    adminId: values.adminId,
    notificationTypes: values.notificationTypes.filter(item => item.isEnable).map(item => item.code)
  };
}
