import {IListResponse} from 'Common/models/IResponse';
import {IMainPageHorse} from 'Main/models/IMainPageHorses';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {withAvatar} from 'Common/helpers/withAvatar';

const getHorses = async (filter?: string): Promise<IListResponse<IMainPageHorse>> => {
  const response = await axiosWrapper.get<IListResponse<IMainPageHorse>>(`/MainPage/Horses`, {params: {filter}});
  return {
    ...response.data,
    data: response.data.data.map(withBirthDate).map(withAvatar),
  };
};

export default {
  getHorses,
};
