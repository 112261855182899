import {
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {IListResponse} from 'Common/models/IResponse';

import {IOwnerBP} from 'BusinessPortal/models/owner/IOwnerBP';
import {IOwnerDetailsBP} from 'BusinessPortal/models/owner/IOwnerDetailsBP';
import {IAssociationOwnerState} from 'Shared/components/Association/models/IAssociationOwnerState';
import {IAdminAssociationOwner} from 'Admin/AdminAssociations/models/IAdminAssociationOwner';

const STATE_NAME = 'adminAssociationOwner';

export type IAdminAssociationOwnerState = IAssociationOwnerState<IAdminAssociationOwner>;

export const getAdminAssociationOwnersActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ADMIN_ASSOCIATION_OWNERS'
);
export type getAdminAssociationOwnersActions = MakeCommunicationActions<
  typeof getAdminAssociationOwnersActionTypes,
  {success: IListResponse<IOwnerBP>}
>;

export const getAdminAssociationOwnerDetailsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ADMIN_ASSOCIATION_OWNER_DETAILS'
);
export type getAdminAssociationOwnerDetailsActions = MakeCommunicationActions<
  typeof getAdminAssociationOwnerDetailsActionTypes,
  {success: IOwnerDetailsBP}
>;

export const createAdminAssociationOwnerActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CREATE_ADMIN_ASSOCIATION_OWNER'
);
export type createAdminAssociationOwnerActions = MakeCommunicationActions<
  typeof createAdminAssociationOwnerActionTypes,
  {}
>;

export const updateAdminAssociationOwnerActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ADMIN_ASSOCIATION_OWNER'
);
export type updateAdminAssociationOwnerActions = MakeCommunicationActions<
  typeof updateAdminAssociationOwnerActionTypes,
  {}
>;

export const updateAdminAssociationOwnerAvatarActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ADMIN_ASSOCIATION_OWNER_AVATAR'
);
export type updateAdminAssociationOwnerAvatarActions = MakeCommunicationActions<
  typeof updateAdminAssociationOwnerAvatarActionTypes,
  {}
>;

export const deleteAdminAssociationOwnerAvatarActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DELETE_ADMIN_ASSOCIATION_OWNER_AVATAR'
);
export type deleteAdminAssociationOwnerAvatarActions = MakeCommunicationActions<
  typeof deleteAdminAssociationOwnerAvatarActionTypes,
  {}
>;

export const deleteAdminAssociationOwnerActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DELETE_ADMIN_ASSOCIATION_OWNER'
);
export type deleteAdminAssociationOwnerActions = MakeCommunicationActions<
  typeof deleteAdminAssociationOwnerActionTypes,
  {}
>;

export const resetAdminAssociationOwnersActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'ADMIN_ASSOCIATION_OWNERS'
);
export type resetAdminAssociationOwnersActions = MakeResetCommunicationActions<
  typeof resetAdminAssociationOwnersActionTypes
>;

export const resetAdminAssociationOwnerDetailsActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'ADMIN_ASSOCIATION_OWNER_DETAILS'
);
export type resetAdminAssociationOwnerDetailsActions = MakeResetCommunicationActions<
  typeof resetAdminAssociationOwnerDetailsActionTypes
>;

export type AdminAssociationOwnerActions =
  | getAdminAssociationOwnersActions
  | getAdminAssociationOwnerDetailsActions
  | createAdminAssociationOwnerActions
  | updateAdminAssociationOwnerActions
  | updateAdminAssociationOwnerAvatarActions
  | deleteAdminAssociationOwnerAvatarActions
  | resetAdminAssociationOwnersActions
  | resetAdminAssociationOwnerDetailsActions;
