import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  Actions,
  createUserOrderActionTypes,
  getAssociationEmployeesActionTypes,
  getUserCouponActionTypes,
  getUserHorsesActionTypes,
  getUserSummaryActionTypes,
  IAdminAssociationCreateUserOrderState,
} from '../types';

export const communicationReducer = combineReducers<IAdminAssociationCreateUserOrderState['communications'], Actions>({
  userHorsesLoading: makeCommunicationReducerFromEnum(getUserHorsesActionTypes),
  userSummaryLoading: makeCommunicationReducerFromEnum(getUserSummaryActionTypes),
  userCouponLoading: makeCommunicationReducerFromEnum(getUserCouponActionTypes),
  associationEmployeesLoading: makeCommunicationReducerFromEnum(getAssociationEmployeesActionTypes),
  creatingUserOrder: makeCommunicationReducerFromEnum(createUserOrderActionTypes),
});
