import {Reducer} from 'redux';

import {
  BusinessPortalAssociationPageActions,
  getContactInformationActionTypes,
  getGeneralInformationActionTypes,
  getOrganizationAvatarActionTypes,
  IBusinessPortalAssociationPageState,
  resetContactInformationActionTypes,
  resetGeneralInformationActionTypes,
} from '../types';

const initialState: IBusinessPortalAssociationPageState['data'] = {
  generalInformation: null,
  contactInformation: null,
  organizationAvatar: null,
};

export const dataBusinessPortalAssociationPageReducer: Reducer<
  IBusinessPortalAssociationPageState['data'],
  BusinessPortalAssociationPageActions
> = (state = initialState, action: BusinessPortalAssociationPageActions) => {
  switch (action.type) {
    case getGeneralInformationActionTypes.SUCCESS: {
      return {...state, generalInformation: action.payload};
    }
    case getContactInformationActionTypes.SUCCESS: {
      return {...state, contactInformation: action.payload};
    }
    case getOrganizationAvatarActionTypes.SUCCESS: {
      return {...state, organizationAvatar: action.payload};
    }

    case resetGeneralInformationActionTypes.RESET: {
      return {...state, generalInformation: initialState.generalInformation};
    }
    case resetContactInformationActionTypes.RESET: {
      return {...state, contactInformation: initialState.contactInformation};
    }

    default: {
      return state;
    }
  }
};
