import React, {memo, useEffect, useState} from 'react';
import _ from 'lodash';

import {MutableField, MutableFieldsGroup} from 'Common/components/StyledComponents/StyledComponents';
import {InputField, SelectField} from 'Common/components/FormFields/index';
import {handleHeightFocus} from 'HorseProfile/helpers/handleHeightFocus';
import {getStringKeysOption} from 'Common/helpers/OptionHelper';
import {HeightUnits} from 'HorseProfile/constants/HeightUnits';
import {convertHeight, roundHeight} from 'Common/helpers/convertHeight';
import withField from 'Common/components/FormFields/withField';
import {IFieldProps} from 'Common/models/IFieldProps';

export interface IProps {
  heightName: string;
  heightUnitName: string;
  heightPercentWidth?: number;
  heightUnitPercentWidth?: number;
}

type AllProps = IProps & Omit<IFieldProps, 'name'>;

const HorseHeightField = (props: AllProps) => {
  const {values, touched, setFieldValue} = props.form;
  const {heightName, heightUnitName, heightPercentWidth = 50, heightUnitPercentWidth = 25} = props;

  const [prevHeightUnit, setPrevHeightUnit] = useState<HeightUnits>(_.get(values, heightUnitName));
  const [heightValue, setHeightValue] = useState(_.get(values, heightName));

  useEffect(() => {
    const newHeightValue = _.get(values, heightName);
    const newHeightUnitValue = _.get(values, heightUnitName);

    const newHeightUnit = HeightUnits[newHeightUnitValue];
    setPrevHeightUnit(newHeightUnit);

    if (![_.get(touched, heightUnitName)][0]) {
      return;
    }
    const valueToConvert =
      !heightValue || newHeightValue !== Math.round(heightValue * 100) / 100 ? newHeightValue : heightValue;

    const convertedValue = convertHeight(valueToConvert || 0, prevHeightUnit, newHeightUnit);

    setFieldValue(heightName, roundHeight(convertedValue, newHeightUnit));
    setHeightValue(convertedValue);
  }, [values, prevHeightUnit, heightValue, setFieldValue, heightUnitName, touched, heightName]);

  return (
    <MutableFieldsGroup indent="16px">
      <MutableField width={heightPercentWidth}>
        <InputField
          name={heightName}
          type="number"
          step="any"
          min={0}
          label="Height"
          placeholder="Height"
          autoComplete="off"
          memoized={true}
          onFocus={handleHeightFocus}
        />
      </MutableField>
      <MutableField width={heightUnitPercentWidth}>
        <SelectField
          name={heightUnitName}
          label="Units"
          options={getStringKeysOption(HeightUnits)}
          defaultValue={{value: HeightUnits.Hands, label: HeightUnits.Hands}}
          isFieldTouchedOnChange={true}
          memoized={true}
        />
      </MutableField>
    </MutableFieldsGroup>
  );
};

export default memo(withField(HorseHeightField));
