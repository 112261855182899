import {IModule} from 'redux-dynamic-modules';

import {IBusinessPortalCreateOrderState} from 'BusinessPortal/store/createOrder/types';
import {businessPortalCreateOrderReducer} from 'BusinessPortal/store/createOrder/reducer';

export interface IBusinessPortalCreateOrderModuleState {
  businessPortalCreateOrder: IBusinessPortalCreateOrderState;
}

export const BusinessPortalCreateOrderModule: IModule<IBusinessPortalCreateOrderModuleState> = {
  id: 'businessPortalCreateOrder',
  reducerMap: {
    businessPortalCreateOrder: businessPortalCreateOrderReducer,
  },
};
