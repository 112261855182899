import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {mapAbilityValues} from 'Common/helpers/abilities';
import {AbilityType} from 'Common/constants/AbilityType';

import Characteristic from './Characteristic';
import {IShortAbility} from 'Dictionaries/models/IAbilities';
import {getAbilitiesByType} from 'Common/helpers/getAbilitiesByType';

const CharacteristicWrapper = styled.div`
  margin-top: 24px;
`;

interface IProps {
  temperament: number;
  abilities?: IShortAbility[];
  isShowTemperament: boolean;
}

function HorseCharacteristics(props: IProps) {
  const {temperament, abilities, isShowTemperament} = props;

  const speedAbilities = abilities && getAbilitiesByType(abilities, AbilityType.Speed)[0];
  const temperamentAbilities = abilities && getAbilitiesByType(abilities, AbilityType.Temperament)[0];
  const gaitAbilities = abilities && getAbilitiesByType(abilities, AbilityType.Gait)[0];

  return (
    <div>
      {[
        {
          label: 'Temperament (DNA):',
          value: temperamentAbilities?.state,
          color: ColorPalette.red1,
          description: temperamentAbilities?.description,
          name: temperamentAbilities?.name,
        },
        {
          label: 'DMRT3:',
          value: gaitAbilities?.state,
          color: ColorPalette.yellow1,
          description: gaitAbilities?.description,
          name: gaitAbilities?.name,
        },
        {
          label: 'Speed:',
          value: speedAbilities?.state,
          color: ColorPalette.green1,
          description: speedAbilities?.description,
          name: speedAbilities?.name,
        },
      ].map(({label, value, color, description, name}, i) => (
        <CharacteristicWrapper key={i}>
          <Characteristic
            label={label}
            title={name || 'No results'}
            color={color}
            value={value ? mapAbilityValues[value] : 0}
            max={3}
            isEmpty={!value}
            description={description}
          />
        </CharacteristicWrapper>
      ))}
      {isShowTemperament && (
        <CharacteristicWrapper>
          <Characteristic
            label="Temperament:"
            title={`${temperament} / 10`}
            color={ColorPalette.red1}
            max={10}
            value={temperament}
          />
        </CharacteristicWrapper>
      )}
    </div>
  );
}

export default memo(HorseCharacteristics);
