import React, {memo} from 'react';
import styled from 'styled-components';

import FoalPortrait from 'FoalProfile/components/img/foal.svg';
import {IImage} from 'Image/models/IImage';
import {Nullable} from 'Common/types';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {breakpoints} from 'Common/constants/Breakpoints';

const Root = styled.div`
  position: relative;
  min-height: 287px;
  max-height: 416px;
  max-width: 331px;
  min-width: 229px;
`;

const FoalImageDiv = styled.div<{url?: Nullable<string>}>`
  position: absolute;
  width: 100%;
  height: 100%;
  ${prop => prop.url && `background-image:url("${prop.url}");`}
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
`;

const ImageWrapper = styled.div<{isBlured?: boolean}>`
  filter: blur(${({isBlured}) => (isBlured ? 3 : 0)}px);
  opacity: ${({isBlured}) => (isBlured ? 0.5 : 1)};
`;

const Message = styled.div`
  z-index: 1;
  padding: 0 30px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size10};
  line-height: 24px;
  text-align: center;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size12};
  }
`;

interface IProps {
  colorImage: Nullable<IImage>;
  hoverColorImage: Nullable<IImage>;
  setHoverColorImage: boolean;
  modifierImages?: Nullable<IImage>[];
  hoverModifierImage?: Nullable<IImage>;
  setHoverModifierImage?: boolean;
  isBlured?: boolean;
  messageOnHorse?: string | React.ReactElement;
}

const FoalImage = (props: IProps) => {
  const {
    colorImage,
    modifierImages = [],
    hoverColorImage,
    hoverModifierImage,
    setHoverColorImage,
    setHoverModifierImage,
    isBlured,
    messageOnHorse
  } = props;

  const imageColor = setHoverColorImage ? hoverColorImage : colorImage;
  const colorImageToShow = imageColor?.url && imageColor.isExist ? imageColor.url : FoalPortrait;

  const isModifierExist =
    modifierImages.some(x => x?.isExist) || (hoverModifierImage?.isExist && setHoverModifierImage);
  const isShowColor = imageColor?.isExist || (!imageColor?.isExist && !isModifierExist);

  return (
    <Root>
      {messageOnHorse && (
        <Message className="d-flex h-100 position-absolute align-items-center">{messageOnHorse}</Message>
      )}
      <ImageWrapper className="w-100 h-100 position-absolute" isBlured={isBlured}>
        {isShowColor && <FoalImageDiv url={colorImageToShow} />}
        {modifierImages && modifierImages.map((item, i) => item?.isExist && <FoalImageDiv key={i} url={item?.url} />)}
        {hoverModifierImage &&
          hoverModifierImage.isExist &&
          setHoverModifierImage &&
          !modifierImages.find(i => i?.id === hoverModifierImage.id) && <FoalImageDiv url={hoverModifierImage?.url} />}
      </ImageWrapper>
    </Root>
  );
};

export default memo(FoalImage);
