import {action} from 'typesafe-actions';
import {IAppState} from 'Common/store/IAppState';

import {ActionResult} from 'Common/store/store';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IImage} from 'Image/models/IImage';
import {
  AdminPhenotypeGroupImageActions,
  getImageActionTypes,
  uploadImageActionTypes,
  deleteImageActionTypes
} from 'Admin/AdminPhenotypes/store/adminPhenotypeGroupImage/types';
import PhenotypeGroupImageDataService from 'Admin/AdminPhenotypes/service/PhenotypeGroupImageDataService';

export const getPhenotypeGroupImage = (
  imageId: number
): ActionResult<IAppState, Promise<IImage>, AdminPhenotypeGroupImageActions> => async dispatch => {
  dispatch(action(getImageActionTypes.REQUEST));
  try {
    const image = await PhenotypeGroupImageDataService.getPhenotypeGroupImage(imageId);
    dispatch(action(getImageActionTypes.SUCCESS, image.data));
    return image.data;
  } catch (err) {
    const error = getErrorMessage(err);
    dispatch(action(getImageActionTypes.FAILURE, error));
    throw error;
  }
};

export const uploadPhenotypeGroupImage = (
  uploadedFile: FormData
): ActionResult<IAppState, Promise<number>, AdminPhenotypeGroupImageActions> => async dispatch => {
  dispatch(action(uploadImageActionTypes.REQUEST));
  try {
    const res = await PhenotypeGroupImageDataService.uploadPhenotypeGroupImage(uploadedFile);
    dispatch(action(uploadImageActionTypes.SUCCESS, res));
    return res;
  } catch (error) {
    const err = getErrorMessage(error);
    dispatch(action(uploadImageActionTypes.FAILURE, err));
    throw err;
  }
};

export const deletePhenotypeGroupImage = (
  imageId: number
): ActionResult<IAppState, void, AdminPhenotypeGroupImageActions> => async dispatch => {
  dispatch(action(deleteImageActionTypes.REQUEST));
  try {
    await PhenotypeGroupImageDataService.deletePhenotypeGroupImage(imageId);
    dispatch(action(deleteImageActionTypes.SUCCESS, {imageId}));
  } catch (err) {
    const error = getErrorMessage(err);
    dispatch(action(deleteImageActionTypes.FAILURE, error));
    throw error;
  }
};
