import React from 'react';
import styled from 'styled-components';

import OrderInfo from 'Admin/AdminDashboard/components/shared/OrderHistory/parts/OrdersInfoSection/OrderInfo';
import {OrderActionsProps} from 'Admin/AdminDashboard/components/Orders/OrderActions';
import {IOrderHistory} from 'Admin/AdminDashboard/components/shared/OrderHistory/parts/common';
import {HeaderTitle} from 'Admin/common/styled/StyledComponents';

const EmptyListMessage = styled.div`
  padding-top: 16px;
  padding-left: 8px;
`;

const Header = styled.div`
  margin-top: 16px;
`;

const ActionHeader = styled(HeaderTitle)`
  text-align: right;
`;

interface IProps {
  orders: IOrderHistory[];
  onSuccess(): void;
}

type AllProps = IProps & Omit<OrderActionsProps, 'order'>;

const OrdersInfoSecton = (props: AllProps) => {
  const {orders, onSuccess, ...rest} = props;

  if (orders.length === 0) {
    return <EmptyListMessage>No Orders</EmptyListMessage>;
  }

  return (
    <>
      <Header className="d-flex align-items-center">
        <HeaderTitle className="w-25">Date</HeaderTitle>
        <HeaderTitle className="w-50">Status</HeaderTitle>
        <ActionHeader className="w-25 ml-auto">Actions</ActionHeader>
      </Header>
      {orders.map((order, i) => (
        <OrderInfo key={i} order={order} onSuccess={onSuccess} {...rest} />
      ))}
    </>
  );
};

export default OrdersInfoSecton;
