import React, {memo, useEffect, useState} from 'react';
import styled from 'styled-components';

import {ElementPosition} from 'FoalProfile/models/IElementPosition';
import FoalPlaceHolder from 'Icon/foal-placeholder.svg';
import {FixImageName, isImageExists} from 'Common/helpers/ImagesHelper';
import {IImage} from 'Image/models/IImage';
import BlurredImage from 'Common/components/BlurredImage/BlurredImage';
import {breakpoints} from 'Common/constants/Breakpoints';

const BorderRadiusStyle: Record<ElementPosition, string> = {
  [ElementPosition.Left]: 'border-radius: 6px 0px 0px 6px',
  [ElementPosition.Right]: 'border-radius: 0px 6px 6px 0px',
  [ElementPosition.Top]: 'border-radius: 6px 6px 0px 0px',
  [ElementPosition.Bottom]: 'border-radius: 6px'
};

const BorderRadiusStyleMobile: Record<ElementPosition, string> = {
  [ElementPosition.Left]: 'border-radius: 6px 0px 0px 0px',
  [ElementPosition.Right]: 'border-radius: 0px 6px 0px 0px',
  [ElementPosition.Top]: 'border-radius: 6px 6px 0px 0px',
  [ElementPosition.Bottom]: 'border-radius: 6px'
};

const ImageWrapper = styled.div<IHeaderProps>`
  width: ${prop => prop.width || '100%'};
  height: ${prop => prop.height};
  ${prop => `${BorderRadiusStyleMobile[prop.position]};`}
  overflow: hidden;
  cursor: pointer;

  @media ${breakpoints.md} {
    ${prop => `${BorderRadiusStyle[prop.position]};`}
  }
`;

const BasePortrait = styled.div<IHeaderProps>`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${prop => prop.url});
`;

const HeaderPortrait = styled(BasePortrait)<IHeaderProps>`
  background-size: ${prop => (prop.url === FoalPlaceHolder ? 'cover' : 'contain')};
  ${prop => `${BorderRadiusStyleMobile[prop.position]};`}

  @media ${breakpoints.md} {
    ${prop => `${BorderRadiusStyle[prop.position]};`}
  }
`;

const HoverPanel = styled.div<{isShowHoverShadow: boolean}>`
  width: 100%;
  height: 100%;
  z-index: 2;
  ${props =>
    props.isShowHoverShadow &&
    '&:hover {background: linear-gradient(0deg, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.35) 100%);'};
`;

interface IHeaderProps {
  url?: string;
  position: ElementPosition;
  height?: string;
  width?: string;
}

interface IProps {
  avatar: IImage | null;
  elementPosition: ElementPosition;
  height?: string;
  width?: string;
  style?: React.CSSProperties;
  isShowHoverShadow?: boolean;
  onClick?(): void;
  onMouseEnterHandler?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onMouseLeaveHandler?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

const SquareAvatar = (props: React.PropsWithChildren<IProps>) => {
  const {
    avatar,
    elementPosition,
    width,
    height,
    onClick,
    style,
    children,
    isShowHoverShadow = true,
    onMouseEnterHandler,
    onMouseLeaveHandler
  } = props;
  const [isAvatarExists, setIsAvatarExists] = useState(false);
  useEffect(() => {
    let isMounted = true;

    if (avatar && avatar.url) {
      isImageExists(avatar.url)
        .then(() => {
          if (isMounted) {
            setIsAvatarExists(true);
          }
        })
        .catch(() => {
          if (isMounted) {
            setIsAvatarExists(false);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [avatar]);

  return (
    <ImageWrapper
      className="position-relative"
      position={elementPosition}
      onClick={onClick}
      height={height}
      width={width}
      style={style}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <HoverPanel className="position-absolute" isShowHoverShadow={isShowHoverShadow} />
      {isAvatarExists && avatar && <BlurredImage className="position-absolute" src={FixImageName(avatar.url)} />}
      <HeaderPortrait
        className="position-absolute"
        url={isAvatarExists && avatar ? FixImageName(avatar.url) : FoalPlaceHolder}
        position={elementPosition}
      />
      {children}
    </ImageWrapper>
  );
};

SquareAvatar.defaultProps = {
  height: '270px'
};

export default memo(SquareAvatar);
