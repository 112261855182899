import React from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {ICellProps} from '../Table';
import {memoWithGeneric} from 'Common/helpers/react/memoWithGeneric';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';

const CellMobile = styled.div`
  height: auto;
  width: 100%;
  box-shadow: 0px 6px 16px ${ColorPalette.gray51}, 0px 0px 8px ${ColorPalette.gray50};
  border-radius: 4px;
  background: ${ColorPalette.white0};

  flex-direction: column;
  padding: 16px;
  margin-bottom: 24px;
`;

const Row = styled.div`
  min-height: 48px;
  display: grid;
  grid-template-columns: 35% auto;
`;

const Header = styled.div`
  color: ${Theme.color.gray};
  width: 100%;

  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  padding: 8px 0;
`;

const Value = styled.div`
  width: 100%;
`;

interface IProps<T> {
  cells: ICellProps<T>[];
  row: T;
}

function RowMobile<T>(props: IProps<T>) {
  const {cells, row} = props;

  return (
    <CellMobile>
      {cells.map(({header, render}, i) => {
        return (
          <Row key={i}>
            <Header className="align-self-center">{header}</Header>
            <Value className="align-self-center">{render(row)}</Value>
          </Row>
        );
      })}
    </CellMobile>
  );
}

export default memoWithGeneric(RowMobile);
