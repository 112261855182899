import React, {memo, useCallback, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {IAdminOrganization} from 'Admin/AdminAssociations/models/IAdminOrganization';
import {SwitchButton} from 'Common/components/Controls/Buttons/SwitchButton';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminOrganization';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import usePermissions from 'Permissions/hooks/usePermissions';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {VisitorType} from 'Common/constants/VisitorType';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import Loading from 'Loading/components/Loading';
import {Pagination} from 'Common/components/Controls';
import {AdminOrganizationsModule} from 'Admin/AdminAssociations/store/adminOrganization/adminOrganizationsModule';
import {IMenuItemValued} from 'Common/models/IMenuItemValued';
import {IconName} from 'Icon/components/Icon';
import Actions from 'Common/components/Actions/Actions';
import ColorPalette from 'Common/constants/ColorPalette';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import {FormType} from 'Common/constants/FormType';
import OrganizationForm from './OrganizationForm/OrganizationForm';

const iconProps = {color: ColorPalette.red7};

enum OrganizationActionValue {
  UpdateOrganization = 'updateOrganization',
}

const menuItems: IMenuItemValued<OrganizationActionValue>[] = [
  {
    value: OrganizationActionValue.UpdateOrganization,
    label: 'Edit organization',
    icon: {name: IconName.Edit, ...iconProps},
  },
];

const AddButton = styled(PrimaryButton)`
  height: 50px;
  margin-left: 55px;
`;

const OrganizationsTable = styled.div`
  margin-top: 50px;
`;

type IConnected = ConnectedProps<typeof connector>;

function Organizations(props: IConnected) {
  const {
    adminOrganizationStatusUpdating,
    adminOrganizations,
    adminOrganizationsLoading,
    changeAdminOrganizationStatus,
    getAdminOrganizations,
    pagination,
  } = props;

  const [formType, setFormType] = useState<FormType>(FormType.create);
  const [isCreateOrEditFormOpen, setIsCreateOrEditFormOpen] = useState(false);

  const {isVisitorType} = usePermissions();

  const {
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    searchBar,
    selectedId,
    setSelectedId,
    reloadItems,
  } = useCommonAdminPageData<IAdminOrganization>({
    getItems: getAdminOrganizations,
    searchBarPlaceholder: 'Search for organizations by any keyword',
  });

  const handleAddOrganization = () => {
    setFormType(FormType.create);
    setSelectedId(null);
    setIsCreateOrEditFormOpen(true);
  };

  const handleEditOrganization = (id: number) => {
    setFormType(FormType.edit);
    setSelectedId(id);
    setIsCreateOrEditFormOpen(true);
  };

  const closeCreateOrEditModal = useCallback(() => {
    setIsCreateOrEditFormOpen(false);
  }, []);

  const handleSuccess = useCallback(() => {
    closeCreateOrEditModal();
    reloadItems();
  }, [closeCreateOrEditModal, reloadItems]);

  const onChangeStatus = useCallback(
    (value: boolean, id: number) => {
      changeAdminOrganizationStatus(id, value);
    },
    [changeAdminOrganizationStatus]
  );

  const isLoading = adminOrganizationsLoading.isRequesting || adminOrganizationStatusUpdating.isRequesting;
  const isAdminVisitor = isVisitorType(VisitorType.Admin);
  const isShowPagination = adminOrganizations.length > 0;

  return (
    <AdminPageLayout>
      <ModalWindow isOpen={isCreateOrEditFormOpen} onClose={closeCreateOrEditModal}>
        <OrganizationForm id={selectedId || undefined} type={formType} onSuccess={handleSuccess} />
      </ModalWindow>

      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
        {isAdminVisitor && <AddButton onClick={handleAddOrganization}>+ Create organization</AddButton>}
      </div>
      <OrganizationsTable className="position-relative">
        {isLoading && <Loading />}
        <Table<IAdminOrganization>
          data={adminOrganizations}
          rowKey="id"
          sorting={sorting}
          onChangeSorting={changeSorting}
        >
          <Cell<IAdminOrganization>
            header="Active"
            dataKey="isActive"
            render={({id, isActive}) => (
              <SwitchButton itemId={id} onChangeHandler={onChangeStatus} checked={isActive} />
            )}
            width="10%"
          />
          <Cell<IAdminOrganization> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
          <Cell<IAdminOrganization> dataKey="name" width="15%" header="Name" render={({name}) => name} />
          <Cell<IAdminOrganization>
            dataKey="abbreviation"
            width="7%"
            header="Abbreviation"
            render={({abbreviation}) => abbreviation}
          />
          <Cell<IAdminOrganization> dataKey="email" width="10%" header="Email" render={({email}) => email} />
          <Cell<IAdminOrganization>
            dataKey="phoneNumber"
            width="10%"
            header="Phone Number"
            render={({phoneNumber}) => phoneNumber}
          />
          <Cell<IAdminOrganization> dataKey="address" width="12%" header="Address" render={({address}) => address} />
          <Cell<IAdminOrganization> dataKey="country" width="7%" header="Country" render={({country}) => country} />
          <Cell<IAdminOrganization>
            dataKey="websiteUrl"
            width="10%"
            header="Website Url"
            render={({websiteUrl}) => websiteUrl}
          />
          <Cell<IAdminOrganization>
            dataKey="description"
            width="10%"
            header="Description"
            render={({description}) => description}
          />
          <Cell<IAdminOrganization>
            header="Actions"
            align={CellAlign.Right}
            width="100"
            render={(organization) => (
              <Actions<OrganizationActionValue>
                menuItems={menuItems}
                updateOrganization={() => handleEditOrganization(organization.id)}
              />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </OrganizationsTable>
    </AdminPageLayout>
  );
}

const mapStateToProps = (state: IAppState) => ({
  adminOrganizations: selectors.selectAdminOrganizations(state),
  adminOrganizationsLoading: selectors.selectCommunication(state, 'adminOrganizationsLoading'),
  adminOrganizationStatusUpdating: selectors.selectCommunication(state, 'adminOrganizationStatusUpdating'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getAdminOrganizations: actions.getAdminOrganizations,
  changeAdminOrganizationStatus: actions.changeAdminOrganizationStatus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const Connected = connector(memo(Organizations));

export default withDynamicModules(Connected, AdminOrganizationsModule);
