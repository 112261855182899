import {Reducer} from 'redux';

import {
  changePurchasableTestStatusActionTypes,
  AdminPurchasableTestsActions,
  getPurchasableTestByIdActionTypes,
  getPurchasableTestsActionTypes,
  IAdminPurchasableTestState,
  resetPurchasableTestDetailsActionTypes,
} from '../types';

const initialState: IAdminPurchasableTestState['data'] = {
  purchasableTests: [],
  purchasableTestDetail: null,
};

export const dataAdminPurchasableTestsReducer: Reducer<
  IAdminPurchasableTestState['data'],
  AdminPurchasableTestsActions
> = (state = initialState, action: AdminPurchasableTestsActions) => {
  switch (action.type) {
    case getPurchasableTestsActionTypes.SUCCESS: {
      return {...state, purchasableTests: action.payload.data};
    }
    case getPurchasableTestByIdActionTypes.REQUEST: {
      return {...state, purchasableTestDetail: null};
    }
    case getPurchasableTestByIdActionTypes.SUCCESS: {
      return {...state, purchasableTestDetail: action.payload};
    }
    case changePurchasableTestStatusActionTypes.SUCCESS: {
      const {purchasableTestId} = action.payload;
      return {
        ...state,
        purchasableTests: state.purchasableTests.map((p) =>
          p.id !== purchasableTestId ? p : {...p, isEnabled: !p.isEnabled}
        ),
      };
    }
    case resetPurchasableTestDetailsActionTypes.RESET: {
      return {...state, purchasableTestDetail: initialState.purchasableTestDetail};
    }
    default: {
      return state;
    }
  }
};
