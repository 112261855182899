import React from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import {ISubscriptionProduct} from 'StripeSubscription/models/ISubscriptionProduct';
import {Link, TitleBase} from './styled';
import {breakpoints} from 'Common/constants/Breakpoints';

const Root = styled.div`
  margin-bottom: 24px;
  flex-direction: column;

  @media ${breakpoints.md} {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

const ProductWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  margin: 0 32px 16px 0;
  background: ${Theme.color.white};
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.02);

  @media ${breakpoints.sm} {
    width: 437px;
    height: 56px;
  }
`;

const Product = styled.div`
  margin-right: 10px;

  @media ${breakpoints.md} {
    margin-right: 0;
  }
`;

const ProductName = styled.div`
  flex-direction: column;

  @media ${breakpoints.md} {
    flex-direction: row;
  }
`;

const Point = styled.div<{isActive?: boolean}>`
  width: 8px;
  height: 8px;
  margin-right: 16px;
  border-radius: 50%;
  background: ${({isActive}) => (isActive ? Theme.color.primary : Theme.color.gray)};
`;

const CanceledFlag = styled(TitleBase)`
  color: ${Theme.color.primary};

  @media ${breakpoints.md} {
    margin-left: 5px;
  }
`;

interface IProps {
  product?: ISubscriptionProduct;
  isCancelled?: boolean;
  hasPaymentProblem?: boolean;
  hasScheduledSubscription?: boolean;
  onChangePlan(): void;
}

const Plan = (props: IProps) => {
  const {product, onChangePlan, isCancelled, hasPaymentProblem, hasScheduledSubscription} = props;

  return (
    <Root className="d-flex align-items-baseline">
      <ProductWrapper className="d-flex justify-content-between">
        <Product className="d-flex justify-content-center align-items-center">
          <Point isActive={!isCancelled} />
          <ProductName className="d-flex">
            {product?.name}
            {isCancelled && <CanceledFlag>(Canceled)</CanceledFlag>}
          </ProductName>
        </Product>
        <div>
          ${product?.price} / {product?.billingInterval.toLowerCase()}
        </div>
      </ProductWrapper>
      {!hasScheduledSubscription && !hasPaymentProblem && <Link onClick={onChangePlan}>Choose another plan</Link>}
    </Root>
  );
};

export default Plan;
