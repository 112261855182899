import React, {useCallback, useState} from 'react';
import {Nullable} from 'Common/types';
import WarningModalWindow, {ModalTypes} from 'Common/components/Modal/WarningModal';
import {IReleaseOrderHorse as IReleaseOrderHorseAdmin} from 'Admin/AdminDashboard/store/adminOrders/types';
import {IAdminActionsProps} from 'Admin/AdminDashboard/helpers/hooks/common';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {IReleaseOrderHorse} from 'Admin/AdminAssociations/store/adminOrders';
import useDistributorStrategy from 'Common/helpers/strategy/useDistributorStrategy';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';

const CONFIRM_RELEASE_ORDER_HORSE = 'Are you sure you want to release order horse?';

type ReleaseOrderHorse = IReleaseOrderHorseAdmin | IReleaseOrderHorse;

export interface IReleaseActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(data: ReleaseOrderHorse): void;
  orderId?: Nullable<number>;
  horseId?: Nullable<number>;
}

export function useReleaseOrderHorseAction(props: IReleaseActionProps) {
  const {action, onSuccess, communication} = props;

  const [selectedOrderId, setSelectedOrderId] = useState<Nullable<number>>();
  const [selectedHorseId, setSelectedHorseId] = useState<Nullable<number>>();

  const orderId = props.orderId || selectedOrderId;
  const horseId = props.horseId || selectedHorseId;

  const {currentStrategy} = useDistributorStrategy();

  useOnSuccessCommunication(communication, () => {
    if (orderId === selectedOrderId && horseId === selectedHorseId) {
      onSuccess();
    }
  });
  useErrorCommunicationToToast(communication, undefined, 1);

  const [isReleaseOrderHorseModalOpen, setIsReleaseOrderHorseModalOpen] = useState(false);
  const openReleaseOrderHorseModal = useCallback(() => {
    setIsReleaseOrderHorseModalOpen(true);
    setSelectedOrderId(orderId);

    if (currentStrategy === SelectedStrategy.Admin) {
      setSelectedHorseId(horseId);
    }
    if (currentStrategy === SelectedStrategy.AdminAssociation) {
      setSelectedHorseId(null);
    }
  }, [horseId, orderId, currentStrategy]);

  const closeReleaseOrderHorseModal = useCallback(() => setIsReleaseOrderHorseModalOpen(false), []);
  const successReleaseOrderHorseModal = useCallback(async () => {
    closeReleaseOrderHorseModal();

    const horseIdParam = selectedHorseId && {horseId: selectedHorseId!};
    const params = {
      orderId: selectedOrderId!,
      ...horseIdParam,
    };

    action && (await action(params));
  }, [action, closeReleaseOrderHorseModal, selectedHorseId, selectedOrderId]);

  const releaseOrderHorseModal = (
    <WarningModalWindow
      modalType={ModalTypes.Confirm}
      isOpen={isReleaseOrderHorseModalOpen}
      onClose={closeReleaseOrderHorseModal}
      onSuccess={successReleaseOrderHorseModal}
    >
      {CONFIRM_RELEASE_ORDER_HORSE}
    </WarningModalWindow>
  );

  return {
    openReleaseOrderHorseModal,
    releaseOrderHorseModal,
  };
}
