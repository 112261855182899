import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  buildFoalRequestActions,
  buildFoalRequestActionTypes,
  firstParentActionTypes,
  secondParentActionTypes
} from './types';
import BuildFoalDataService from 'FoalProfile/services/BuildFoalDataService';
import {IBuildFoalRequest} from 'FoalProfile/models/IBuildFoalRequest';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IAppState} from 'Common/store/IAppState';
import {ElementPosition} from 'FoalProfile/models/IElementPosition';

const mapPositionToActionType = {
  [ElementPosition.Left]: firstParentActionTypes,
  [ElementPosition.Right]: secondParentActionTypes
};

export const buildFoalRequest = (
  config: IBuildFoalRequest
): ActionResult<IAppState, void, buildFoalRequestActions> => async dispatch => {
  try {
    dispatch(action(buildFoalRequestActionTypes.REQUEST));
    const foal = await BuildFoalDataService.buildFoalRequest(config);
    dispatch(action(buildFoalRequestActionTypes.SUCCESS, foal));
  } catch (error) {
    dispatch(action(buildFoalRequestActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const getParent = (
  horseId: number,
  position: ElementPosition
): ActionResult<IAppState, void, buildFoalRequestActions> => async dispatch => {
  try {
    dispatch(action(mapPositionToActionType[position].REQUEST));
    const foal = await BuildFoalDataService.getParent(horseId);
    dispatch(action(mapPositionToActionType[position].SUCCESS, foal));
  } catch (error) {
    dispatch(action(mapPositionToActionType[position].FAILURE, getErrorMessage(error)));
  }
};
