import React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';

import {
  actions as userActions,
  AdminUserFullInfoUpdateModule,
  selectors as userSelectors,
} from 'Admin/AdminDashboard/store/adminUsers/userFullInfoUpdate/index';
import {
  actions as permissionActions,
  selectors as permissionSelectors,
} from 'Admin/AdminDashboard/store/adminUsers/userPermission/index';
import {
  actions as passwordActions,
  selectors as passwordSelectors,
} from 'Admin/AdminDashboard/store/adminUsers/changePassword/index';
import {
  actions as emailActions,
  selectors as emailSelectors,
} from 'Admin/AdminDashboard/store/adminUsers/changeEmail/index';
import {
  actions as adminUsersActions,
  selectors as adminUsersSelectors,
} from 'Admin/AdminDashboard/store/adminUsers/users/index';
import {
  actions as confirmationActions,
  selectors as confirmationSelectors,
} from 'MailConfirmation/store/requestNewMailConfirmation';
import {AdminChangeEmailModule} from 'Admin/AdminDashboard/store/adminUsers/changeEmail/adminChangeEmailModule';
import {UserPermissionsModule} from 'Admin/AdminDashboard/store/adminUsers/userPermission/UserPermissionsModule';
import {AdminChangePasswordModule} from 'Admin/AdminDashboard/store/adminUsers/changePassword/adminChangePasswordModule';
import {RequestNewMailConfirmationModule} from 'MailConfirmation/store/requestNewMailConfirmation/RequestNewMailConfirmationModule';

type IConnected = ConnectedProps<typeof connector>;

export type IWithUserActionsProps = IConnected;

function withUserActions<T>(Component: React.ComponentType<T>) {
  const wrapped = (props: any) => <Component {...props} />;
  return connector(wrapped);
}

const mapStateToProps = (state: IAppState) => ({
  userUpdating: userSelectors.selectCommunication(state, 'userUpdating'),
  userDeleting: adminUsersSelectors.selectCommunication(state, 'deleteUserLoading'),
  userPermissionsUpdating: permissionSelectors.selectCommunication(state, 'userPermissionsUpdating'),
  changeUserPasswordUpdating: passwordSelectors.selectCommunication(state, 'changeUserPasswordUpdating'),
  changeUserEmailUpdating: emailSelectors.selectCommunication(state, 'changeUserEmailUpdating'),
  sendingNewVerification: confirmationSelectors.selectCommunication(state, 'confirmationRequesting'),
  userCreating: adminUsersSelectors.selectCommunication(state, 'userCreating'),
});

const mapDispatchToProps = {
  deleteUser: adminUsersActions.deleteUserById,
  updateUser: userActions.updateUser,
  updateUserPermissions: permissionActions.updateUserPermissions,
  changeUserPassword: passwordActions.changeUserPassword,
  changeUserEmail: emailActions.changeUserEmail,
  sendNewVerification: confirmationActions.requestNewMailConfirmation,
  createUser: adminUsersActions.createUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

function getWithUserActionsModules() {
  return [
    AdminUserFullInfoUpdateModule,
    AdminChangeEmailModule,
    UserPermissionsModule,
    AdminChangePasswordModule,
    RequestNewMailConfirmationModule,
  ];
}

export {getWithUserActionsModules, withUserActions as default};
