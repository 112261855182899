import {FormikProps, withFormik} from 'formik';
import {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';
import {
  convertToServer,
  convertToServerUpdating,
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminReference/components/Colors/ColorForm/validation';
import {IUpdateShortColorRequest} from 'Admin/AdminReference/services/models/IServerShortColor';
import {InputField, SelectField} from 'Common/components/FormFields/index';
import {FormType} from 'Common/constants/FormType';
import {Gender} from 'Common/constants/Gender';
import {getStringKeysOption} from 'Common/helpers/OptionHelper';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import {RequiredBy} from 'Common/types';
import {IShortColor} from 'Dictionaries/models/IColor';
import {ImageModule} from 'Image/store/imageModule';

type IOwnProps = IPhenotypeFormExternalProps<IShortColor, IUpdateShortColorRequest>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function CommercialTypeForm(props: Props) {
  const {itemId, item, dictionary, ...rest} = props;
  const {getItem} = dictionary.actions;

  useEffect(() => {
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem]);

  return (
    <DictionaryForm {...rest}>
      <InputField
        isRequired={true}
        name="name"
        type="text"
        label="Name"
        placeholder="Name"
        className="w-50"
        autoComplete="off"
      />
      <SelectField
        name="genders"
        label="Gender"
        options={getStringKeysOption(Gender)}
        memoized={true}
        isMulti
        menuPosition="fixed"
      />
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  return {
    ...makePhenotypeFormSelectors(state, ownProps.dictionary),
  };
};

const CommercialTypeFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting ? item : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;

    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(CommercialTypeForm);

const connector = connect(mapStateToProps);
const Connected = connector(memo(CommercialTypeFormFormik));
export default withDynamicModules(Connected, ImageModule);
