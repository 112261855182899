import {convertUTCToClientDate, convertToServerDate} from 'Common/helpers/DateHelper';
import {FRONTEND_DATE} from 'Common/constants/Date';
import {IAdminPaymentDetails} from 'Admin/AdminAssociations/models/IAdminPaymentDetails';
import {IServerPaymentUpdateRequest} from '../types/payment';

export function convertPaymentDetailsToClient(paymentDetails: IAdminPaymentDetails): IAdminPaymentDetails {
  return {
    ...paymentDetails,
    receivedDate: paymentDetails.receivedDate
      ? convertUTCToClientDate(paymentDetails.receivedDate, FRONTEND_DATE)
      : null,
  };
}

export function convertPaymentDetailsToRequest(paymentDetails: IAdminPaymentDetails): IServerPaymentUpdateRequest {
  return {
    ...paymentDetails,
    receivedDate: convertToServerDate(paymentDetails.receivedDate, true),
  };
}
