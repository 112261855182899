import React, {memo} from 'react';
import styled from 'styled-components';

import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import ImageUploaderField from 'Common/components/FormFields/ImageUploaderField';
import GalleryField from 'Common/components/FormFields/GalleryField';
import {IconName, IProps as IconProps} from 'Icon/components/Icon';
import {FORM_CONTROL_CONTAINER_STYLE} from 'Common/constants/Styled';
import {breakpoints} from 'Common/constants/Breakpoints';

const galleryVideoIconProps: IconProps = {name: IconName.InsertVideo, size: 48};
const galleryIconProps: IconProps = {name: IconName.InsertPhoto, size: 48};

const AvatarWrapper = styled(FieldControlContainer)`
  max-width: 126px;
  margin-bottom: 20px;
`;

const GalleryWrapper = styled(FieldControlContainer)`
  margin-bottom: 20px;
`;

const Pictures = styled.div`
  flex-direction: column;

  @media ${breakpoints.sm} {
    flex-direction: row;

    > :first-child {
      margin-right: 68px;
    }
  }
`;

function HorseImages() {
  return (
    <FormControlContainer title="Horse images" titleStyle={FORM_CONTROL_CONTAINER_STYLE} style={{marginTop: 24}}>
      <Pictures className="d-flex">
        <AvatarWrapper label="Main image">
          <ImageUploaderField
            name="avatar"
            title={'Add main image'}
            iconProps={galleryIconProps}
            memoized={true}
            showDownloadButton={true}
          />
        </AvatarWrapper>
        <GalleryWrapper label="Gallery">
          <GalleryField
            name="gallery"
            imageProps={{title: 'Add gallery photo', iconProps: galleryIconProps, showDownloadButton: true}}
            videoProps={{title: 'Add a video', iconProps: galleryVideoIconProps}}
            memoized={true}
          />
        </GalleryWrapper>
      </Pictures>
    </FormControlContainer>
  );
}

export default memo(HorseImages);
