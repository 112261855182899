import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';

import {
  ModalWindowHeader,
  ModalWindowFormContent,
  ModalWindowFooter,
  ModalWindowButton
} from 'Common/components/Modal/shared';
import {Input} from 'Common/components/Controls';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import VideoPlayer from './VideoPlayer';

const Root = styled.div`
  margin: 0 24px 40px 0;
`;

const Text = styled.div`
  margin-bottom: 24px;
`;

const VideoWrapper = styled.div<{isShow: boolean}>`
  width: 100%;
  display: ${props => (props.isShow ? 'block' : 'none')};
`;

const PreviewTitle = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${Theme.color.black};
  margin-bottom: 16px;
`;

interface IProps {
  url?: string;
  onAdd(url: string): void;
  onClose(): void;
}

function VideoForm(props: IProps) {
  const {url, onAdd, onClose} = props;
  const [value, setValue] = useState(url || '');

  const onAddClick = useCallback(() => {
    onAdd(value);
    onClose();
  }, [onAdd, onClose, value]);

  const onInputChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  }, []);

  const isPreviewShow = !!value && value.length > 0;

  return (
    <>
      <ModalWindowHeader>Video adding</ModalWindowHeader>
      <Root>
        <ModalWindowFormContent>
          <Text>Put the link on the YouTube or Facebook video below to add it</Text>
          <Input label="Video link" placeholder="Put the link here" value={value} onChange={onInputChange} />

          <VideoWrapper isShow={isPreviewShow}>
            <PreviewTitle>Preview</PreviewTitle>
            <VideoPlayer url={value} />
          </VideoWrapper>
        </ModalWindowFormContent>
      </Root>
      <ModalWindowFooter>
        <ModalWindowButton type="button" onClick={onAddClick}>
          Add video
        </ModalWindowButton>
      </ModalWindowFooter>
    </>
  );
}

export default memo(VideoForm);
