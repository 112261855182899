import React, {memo} from 'react';
import {IAdminAbilityGroupAdvanced} from 'Admin/AdminPhenotypes/models/IAdminAbilityGroupAdvanced';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import AbilityGroupsAdvancedForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/AbilityGroupsAdvanced/AbilityGroupAdvancedForm/AbilityGroupAdvancedForm';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';
import {HAS_COLOR_IMAGE_ICON, NO_COLOR_IMAGE_ICON} from '../../share/StyledComponents';

function AbilityGroupsAdvanced() {
  const {adminAbilityGroupAdvanced: dictionary} = useDictionaries();

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={AbilityGroupsAdvancedForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IAdminAbilityGroupAdvanced> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
            <Cell<IAdminAbilityGroupAdvanced> dataKey="name" width="20%" header="Name" render={({name}) => name} />
            <Cell<IAdminAbilityGroupAdvanced>
              dataKey="positionIndex"
              width="10%"
              header="Order in Report"
              sortable={false}
              render={({positionIndex}) => positionIndex}
            />
            <Cell<IAdminAbilityGroupAdvanced>
              header="Image"
              render={({image}) => (image ? HAS_COLOR_IMAGE_ICON : NO_COLOR_IMAGE_ICON)}
              width="10%"
            />
            <Cell<IAdminAbilityGroupAdvanced>
              dataKey="description"
              width="40%"
              header="Description"
              sortable={false}
              render={({description}) => description?.value}
            />
            <Cell<IAdminAbilityGroupAdvanced>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(AbilityGroupsAdvanced);
