import React, {useCallback} from 'react';
import styled from 'styled-components';

import OrderHorse from 'Admin/AdminDashboard/components/Orders/OrderHorses/OrderHorse';
import useOnUpdateOrder from 'Admin/AdminDashboard/components/Orders/OrderHorses/useOnUpdateOrder';
import PriceDetails from 'Admin/AdminDashboard/components/Orders/OrdersDetails/PriceDetails';
import {useHorseModal} from 'Admin/AdminDashboard/helpers/hooks/useHorseActions/parts/useHorseModal';
import {IOrderHorseDetailsAdmin} from 'Admin/AdminDashboard/models/Order/IOrderHorseDetailsAdmin';
import {IOrderPriceSummaryAdmin} from 'Admin/AdminDashboard/models/Order/IOrderPriceSummaryAdmin';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {Cell, ITableProps, Table} from 'Common/components/Table/Table';
import {ExpandedRowStyles} from 'Common/components/Table/view/Row';
import {HORSE_GRANTED_MARKED_ICON} from 'Common/constants/avatarMarkedIcon';
import ColorPalette from 'Common/constants/ColorPalette';
import {isAvailableForBah} from 'Common/helpers/isAvailableForBah';
import {withCurrency} from 'Common/helpers/withCurrency';
import withDate from 'Common/helpers/withDate';
import AvatarCellHorse from '../shared/AvatarCellHorse';
import OrderMenuItem from './parts/OrderMenuItem';
import OrderStatusDetails from './parts/OrderStatusDetails';

const expandedRowStyles: ExpandedRowStyles = {
  toggle: {backgroundColor: 'transparent', marginLeft: '60px'},
  expandedRow: {boxShadow: 'none'},
  content: {paddingLeft: '85px'},
};

const OwnerCell = styled.span`
  color: ${ColorPalette.gray32};
`;

const PriceDetailsSingleHorse = styled.div`
  margin-top: 28px;
`;

interface IProps {
  orderId: number;
  priceDetails?: IOrderPriceSummaryAdmin;
  horsesDetails: IOrderHorseDetailsAdmin[];
  createDate: string;
  owner: string;
  withRightPadding?: boolean;
  tableProps?: Partial<ITableProps<IOrderHorseDetailsAdmin>>;
  isClearStatuses?: boolean;
}

function OrderHorses(props: IProps) {
  const {orderId, createDate, owner, priceDetails, horsesDetails, withRightPadding, tableProps, isClearStatuses} =
    props;

  const {onUpdateOrder} = useOnUpdateOrder();

  const renderExpandContent = useCallback(
    (orderHorse: IOrderHorseDetailsAdmin) => {
      return <OrderHorse horseDetails={orderHorse} orderId={orderId} ownerName={owner} />;
    },
    [orderId, owner]
  );

  const {updateHorseModal, openUpdateHorseModal} = useHorseModal({onSuccess: onUpdateOrder});

  if (horsesDetails.length === 1) {
    return (
      <>
        <div style={{...tableProps?.expandedRowStyles?.content}}>
          <OrderHorse horseDetails={horsesDetails[0]} orderId={orderId} ownerName={owner} isClearStatuses={true} />
        </div>

        {priceDetails && (
          <PriceDetailsSingleHorse>
            <PriceDetails orderId={orderId} {...priceDetails} />
          </PriceDetailsSingleHorse>
        )}
      </>
    );
  }

  return (
    <>
      {updateHorseModal}
      <Table<IOrderHorseDetailsAdmin>
        expandable={true}
        renderExpandContent={renderExpandContent}
        isHideHeader={true}
        expandedRowStyles={expandedRowStyles}
        multipleExpand={true}
        {...tableProps}
        data={horsesDetails}
      >
        <Cell<IOrderHorseDetailsAdmin> width="10%" render={() => withDate(createDate)} />
        <Cell<IOrderHorseDetailsAdmin> width="15%" render={() => <OwnerCell>{owner}</OwnerCell>} />
        <Cell<IOrderHorseDetailsAdmin>
          width="15%"
          render={({id, name, avatar, isArchived, isDeleted, availabilityCriteria, sample, isGeneticDataHidden}) => (
            <AvatarCellHorse
              label={name}
              type="horse"
              profileUrl={`/admin/horse/${id}`}
              avatarUrl={avatar?.url}
              onLabelClick={() => openUpdateHorseModal(id)}
              isArchived={isArchived}
              isDeleted={isDeleted}
              isMarkedIcon={isAvailableForBah(availabilityCriteria)}
              markedIcon={HORSE_GRANTED_MARKED_ICON}
              sampleId={sample?.sampleId}
              isGeneticDataHidden={isGeneticDataHidden}
            />
          )}
        />
        <Cell<IOrderHorseDetailsAdmin>
          width="15%"
          render={({id}) => (
            <OrderStatusDetails
              orderId={orderId}
              horseId={id}
              onChange={onUpdateOrder}
              isClearStatuses={isClearStatuses}
            />
          )}
        />
        <Cell<IOrderHorseDetailsAdmin>
          width="20%"
          render={({packagesDetails}) =>
            packagesDetails.packages.length > 1
              ? `${packagesDetails.packages.length} packages`
              : packagesDetails.packages[0]?.name
          }
        />
        <Cell<IOrderHorseDetailsAdmin>
          width="10%"
          render={({packagesDetails}) => withCurrency(packagesDetails.totalPrice)}
          align={withRightPadding ? CellAlign.Left : CellAlign.Right}
        />
        <Cell<IOrderHorseDetailsAdmin>
          header="Actions"
          width="100px"
          align={CellAlign.Right}
          render={(horse) => <OrderMenuItem horse={horse} orderId={orderId} />}
        />
        {withRightPadding && (
          <Cell<IOrderHorseDetailsAdmin> align={CellAlign.Right} width="100px" render={() => <div />} />
        )}
      </Table>
      {priceDetails && <PriceDetails orderId={orderId} {...priceDetails} />}
    </>
  );
}

OrderHorses.defaultProps = {
  withRightPadding: false,
};

export default React.memo(OrderHorses);
