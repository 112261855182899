import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {Title} from 'Admin/AdminAdvancedDashboard/components/Emergencies/common/styled';
import AccessPanel, {AccessPanelProps} from './AccessPanel/AccessPanel';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import DisablingAccessModal from './AccessPanel/DisablingAccessModal';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import useServiceMode from 'Maintain/hooks/useServiceMode';
import {ServiceMode} from 'Maintain/models/IServiceMode';
import {IAppState} from 'Common/store/IAppState';
import withDynamicModules from 'Common/helpers/withDynamicModules';

import Loading from 'Loading/components/Loading';
import {actions, selectors} from 'Admin/AdminAdvancedDashboard/store/adminEmergencies/accessPanel';
import {AdminServiceModeModule} from 'Admin/AdminAdvancedDashboard/store/adminEmergencies/accessPanel/adminServiceModeModule';

const Panels = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 310px);
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const AccessPanels = (props: AllProps) => {
  const {adminServiceMode, adminServiceModeLoading, adminServiceModeUpdating, getServiceMode, setServiceMode} = props;

  const isLoading = adminServiceModeLoading.isRequesting;
  const [isModalOpen, setIsModalOpen] = useState(false);

  useErrorCommunicationToToast(adminServiceModeLoading);
  useErrorCommunicationToToast(adminServiceModeUpdating);

  const {getServiceModeStatus} = useServiceMode();

  useEffect(() => {
    getServiceMode();
  }, [getServiceMode]);

  const onEnableAccess = useCallback(async () => {
    await setServiceMode({mode: ServiceMode.On, cause: ''});
    getServiceMode();
    getServiceModeStatus();
  }, [getServiceMode, getServiceModeStatus, setServiceMode]);

  const onDisableAccess = useCallback(async () => {
    setIsModalOpen(true);
  }, []);

  const onDisableSuccess = useCallback(
    async (cause: string) => {
      await setServiceMode({mode: ServiceMode.UsersOff, cause});
      getServiceMode();
      getServiceModeStatus();
    },
    [getServiceMode, getServiceModeStatus, setServiceMode]
  );

  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const panels: AccessPanelProps[] = useMemo(
    () => [
      {
        name: 'General access',
        isDisable: adminServiceMode.mode === 'UsersOff',
        changedBy: adminServiceMode.adminEmail,
        lastStatusDate: adminServiceMode.modeSettingDate,
        onEnableAccess: onEnableAccess,
        onDisableAccess: onDisableAccess,
        isLoading: adminServiceModeUpdating.isRequesting,
        cause: adminServiceMode.cause,
      },
    ],
    [
      adminServiceMode.adminEmail,
      adminServiceMode.cause,
      adminServiceMode.mode,
      adminServiceMode.modeSettingDate,
      adminServiceModeUpdating.isRequesting,
      onDisableAccess,
      onEnableAccess,
    ]
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <ModalWindow isOpen={isModalOpen} onClose={closeModal}>
        <DisablingAccessModal onClick={onDisableSuccess} onClose={closeModal} />
      </ModalWindow>

      <div className="flex-grow-1">
        <Title>Access panel</Title>
        <Panels>
          {panels.map((panel, index) => (
            <AccessPanel key={index} {...panel} />
          ))}
        </Panels>
      </div>
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  adminServiceMode: selectors.selectServiceMode(state),
  adminServiceModeLoading: selectors.selectCommunication(state, 'adminServiceModeLoading'),
  adminServiceModeUpdating: selectors.selectCommunication(state, 'adminServiceModeUpdating'),
});

const mapDispatchToProps = {
  getServiceMode: actions.getServiceMode,
  setServiceMode: actions.setServiceMode,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(AccessPanels));

export default withDynamicModules(Connected, AdminServiceModeModule);
