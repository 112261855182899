import * as React from 'react';
import {HTMLAttributes} from 'react';

import {ReactComponent as EtalonLogo} from 'Icon/etalon-logo.svg';
import {ReactComponent as Diagnostics} from 'Icon/diagnostics.svg';
import {ReactComponent as EtalonHorseLogo} from 'Icon/etalon-horse-logo.svg';
import {ReactComponent as EtalonDiagnosticsHorseLogo} from 'Icon/etalon-diagnostics-horse-logo.svg';
import {ReactComponent as Search} from 'Icon/search.svg';
import {ReactComponent as Instagram} from 'Icon/instagram.svg';
import {ReactComponent as Twitter} from 'Icon/twitter.svg';
import {ReactComponent as Facebook} from 'Icon/facebook.svg';
import {ReactComponent as Horsehead} from 'Icon/horsehead.svg';
import {ReactComponent as Horseheads} from 'Icon/horseheads.svg';
import {ReactComponent as Dna} from 'Icon/dna.svg';
import {ReactComponent as Person} from 'Icon/person.svg';
import {ReactComponent as Home} from 'Icon/home.svg';
import {ReactComponent as Check} from 'Icon/outline-check.svg';
import {ReactComponent as Calendar} from 'Icon/calendar.svg';
import {ReactComponent as Close} from 'Icon/close.svg';
import {ReactComponent as FirstOne} from 'Icon/first-one.svg';
import {ReactComponent as Next} from 'Icon/next.svg';
import {ReactComponent as Previous} from 'Icon/previous.svg';
import {ReactComponent as LastOne} from 'Icon/last-one.svg';
import {ReactComponent as Back} from 'Icon/icon-back.svg';
import {ReactComponent as CheckOrder} from 'Icon/check-order.svg';
import {ReactComponent as DnaIcon} from 'Icon/dna-img.svg';
import {ReactComponent as PossibilityTriangle} from 'Icon/posibility-triangle.svg';
import {ReactComponent as DangerIcon} from 'Icon/danger-icon.svg';
import {ReactComponent as LeftSection} from 'Icon/left-section.svg';
import {ReactComponent as CenterSection} from 'Icon/center-section.svg';
import {ReactComponent as RightSection} from 'Icon/right-section.svg';
import {ReactComponent as LeftSection2} from 'Icon/left-section2.svg';
import {ReactComponent as CenterSection2} from 'Icon/center-section2.svg';
import {ReactComponent as RightSection2} from 'Icon/right-section2.svg';
import {ReactComponent as Delete} from 'Icon/delete-icon.svg';
import {ReactComponent as ViewOn} from 'Icon/view-on.svg';
import {ReactComponent as ViewOff} from 'Icon/view-off.svg';
import {ReactComponent as Archive} from 'Icon/archive.svg';
import {ReactComponent as UnArchive} from 'Icon/unarchive.svg';
import {ReactComponent as Edit} from 'Icon/edit.svg';
import {ReactComponent as Garbage} from 'Icon/garbage.svg';
import {ReactComponent as Clear} from 'Icon/clear.svg';
import {ReactComponent as Settings} from 'Icon/settings.svg';
import {ReactComponent as SettingsContained} from 'Icon/settings-contained.svg';
import {ReactComponent as AddCircleStroked} from 'Icon/add-circle-stroked.svg';
import {ReactComponent as More} from 'Icon/more.svg';
import {ReactComponent as Info} from 'Icon/info.svg';
import {ReactComponent as CreditCard} from 'Icon/credit-card.svg';
import {ReactComponent as Money} from 'Icon/money.svg';
import {ReactComponent as Truck} from 'Icon/truck.svg';
import {ReactComponent as FilledCheckbox} from 'Icon/filled-checkbox.svg';
import {ReactComponent as ArrowDown} from 'Icon/arrow-down.svg';
import {ReactComponent as CloudUpload} from 'Icon/cloud-upload.svg';
import {ReactComponent as CloudDownload} from 'Icon/cloud-download.svg';
import {ReactComponent as InsertPhoto} from 'Icon/insert-photo.svg';
import {ReactComponent as Phone} from 'Icon/phone.svg';
import {ReactComponent as MapMarker} from 'Icon/map-marker.svg';
import {ReactComponent as Share} from 'Icon/share.svg';
import {ReactComponent as ShowAll} from 'Icon/show-all.svg';
import {ReactComponent as HorseHeight} from 'Icon/height-icon.svg';
import {ReactComponent as Refresh} from 'Icon/refresh.svg';
import {ReactComponent as ShowInfo} from 'Icon/show-info.svg';
import {ReactComponent as ArrowDownload} from 'Icon/arrow-download.svg';
import {ReactComponent as RearedHorse} from 'Icon/reared-horse.svg';
import {ReactComponent as ImageFrame} from 'Icon/image-frame.svg';
import {ReactComponent as FileCopy} from 'Icon/file-copy.svg';
import {ReactComponent as HorseheadNoCrest} from 'Icon/horsehead-nocrest.svg';
import {ReactComponent as Colors} from 'Icon/colors.svg';
import {ReactComponent as Health} from 'Icon/health.svg';
import {ReactComponent as ExclamationMark} from 'Icon/exclamation-mark.svg';
import {ReactComponent as CrossedCheckbox} from 'Icon/crossed-checkbox.svg';
import {ReactComponent as SquareFilledCheckbox} from 'Icon/square-filed-checkbox.svg';
import {ReactComponent as Eye} from 'Icon/eye.svg';
import {ReactComponent as Mail} from 'Icon/mail.svg';
import {ReactComponent as CallPhone} from 'Icon/call-phone.svg';
import {ReactComponent as Pin} from 'Icon/pin.svg';
import {ReactComponent as CheckShield} from 'Icon/check-shield.svg';
import {ReactComponent as ArrowNext} from 'Icon/arrow-next.svg';
import {ReactComponent as Sandwich} from 'Icon/sandwich.svg';
import {ReactComponent as Question} from 'Icon/question.svg';
import {ReactComponent as Heart} from 'Icon/heart.svg';
import {ReactComponent as ColorDrop} from 'Icon/color-drop.svg';
import {ReactComponent as LeftTabletSection} from 'Icon/left-tablet-section.svg';
import {ReactComponent as CenterTabletSection} from 'Icon/center-tablet-section.svg';
import {ReactComponent as RightTabletSection} from 'Icon/right-tablet-section.svg';
import {ReactComponent as Lock} from 'Icon/lock.svg';
import {ReactComponent as FiltersToggle} from 'Icon/filters-toggle.svg';
import {ReactComponent as Star} from 'Icon/star.svg';
import {ReactComponent as Upload} from 'Icon/upload.svg';
import {ReactComponent as AddCircleFilled} from 'Icon/add-circle-filled.svg';
import {ReactComponent as HorseGrantedBlack} from 'Icon/yellowGranted-black.svg';
import {ReactComponent as HorseGrantedWhite} from 'Icon/yellowGranted-white.svg';
import {ReactComponent as UserHasSubscription} from 'Icon/greenGranted-black.svg';
import {ReactComponent as HorseNotGranted} from 'Icon/notgranted.svg';
import {ReactComponent as CheckMark} from 'Icon/checkbox-mark.svg';
import {ReactComponent as Description} from 'Icon/description.svg';
import {ReactComponent as Exchange} from 'Icon/exchange.svg';
import {ReactComponent as Reload} from 'Icon/reload.svg';
import {ReactComponent as BorderedCross} from 'Icon/bordered-cross.svg';
import {ReactComponent as CardMastercard} from 'Icon/card-mastercard.svg';
import {ReactComponent as CardMaestro} from 'Icon/card-maestro.svg';
import {ReactComponent as CardVisa} from 'Icon/card-visa.svg';
import {ReactComponent as CardVisaElectron} from 'Icon/card-visa-electron.svg';
import {ReactComponent as CardAmericanExpress} from 'Icon/card-american-express.svg';
import {ReactComponent as CardUnionpay} from 'Icon/card-unionpay.svg';
import {ReactComponent as AddToFavorites} from 'Icon/favorites-add.svg';
import {ReactComponent as AddToFavoritesHover} from 'Icon/favorites-add-hover.svg';
import {ReactComponent as DeleteFromFavorites} from 'Icon/favorites-delete.svg';
import {ReactComponent as DeleteFromFavoritesHover} from 'Icon/favorites-delete-hover.svg';
import {ReactComponent as Waiting} from 'Icon/waiting.svg';
import {ReactComponent as Clock} from 'Icon/clock.svg';
import {ReactComponent as AlleleShort} from 'Icon/allele-short.svg';
import {ReactComponent as AlleleLong} from 'Icon/allele-long.svg';
import {ReactComponent as MarkedNotebook} from 'Icon/marked-notebook.svg';
import {ReactComponent as Notebook} from 'Icon/notebook.svg';
import {ReactComponent as Notebook2} from 'Icon/notebook2.svg';
import {ReactComponent as Notebook3} from 'Icon/notebook3.svg';
import {ReactComponent as Drop} from 'Icon/drop.svg';
import {ReactComponent as DropSimple} from 'Icon/drop-simple.svg';
import {ReactComponent as Cross} from 'Icon/cross.svg';
import {ReactComponent as CrossSimple} from 'Icon/cross-simple.svg';
import {ReactComponent as Pyramid} from 'Icon/pyramid.svg';
import {ReactComponent as Gear} from 'Icon/gear.svg';
import {ReactComponent as GenderSymbol} from 'Icon/gender-symbol.svg';
import {ReactComponent as OpenEnvelope} from 'Icon/open-envelope.svg';
import {ReactComponent as Coupon} from 'Icon/coupon.svg';
import {ReactComponent as DoneMark} from 'Icon/done-mark.svg';
import {ReactComponent as Stallion} from 'Icon/stallion-gender.svg';
import {ReactComponent as Mare} from 'Icon/mare-gender.svg';
import {ReactComponent as InsertComment} from 'Icon/insert-comment.svg';
import {ReactComponent as CheckedQuestionnaire} from 'Icon/checked-questionnaire.svg';
import {ReactComponent as Scientist} from 'Icon/scientist.svg';
import {ReactComponent as EyeHide} from 'Icon/eye-hide.svg';
import {ReactComponent as EyeShow} from 'Icon/eye-show.svg';
import {ReactComponent as ExcludedFilter} from 'Icon/excluded.svg';
import {ReactComponent as Exclude} from 'Icon/exclude.svg';
import {ReactComponent as ExcludeHover} from 'Icon/exclude-hover.svg';
import {ReactComponent as ExcludeRemove} from 'Icon/exclude-remove.svg';
import {ReactComponent as ExcludeRemoveHover} from 'Icon/exclude-remove-hover.svg';
import {ReactComponent as BrokenImage} from 'Icon/broken-image.svg';
import {ReactComponent as Notification} from 'Icon/notification.svg';
import {ReactComponent as Email} from 'Icon/email.svg';
import {ReactComponent as PenOutline} from 'Icon/pen-outline.svg';
import {ReactComponent as Warning} from 'Icon/warning.svg';
import {ReactComponent as InsertVideo} from 'Icon/insert-video.svg';
import {ReactComponent as PlayArrow} from 'Icon/play-arrow.svg';
import {ReactComponent as PlayIcon} from 'Icon/play-icon.svg';
import {ReactComponent as PlayIconPreview} from 'Icon/play-icon-preview.svg';
import {ReactComponent as Thunderbolt} from 'Icon/thunderbolt.svg';
import {ReactComponent as Permission} from 'Icon/permission.svg';
import {ReactComponent as Dashboard} from 'Icon/dashboard.svg';
import {ReactComponent as AssociationPage} from 'Icon/association-page.svg';
import {ReactComponent as Logout} from 'Icon/logout.svg';
import {ReactComponent as HorseDefaultAvatar} from 'Icon/horse-default-avatar.svg';
import {ReactComponent as OwnerDefaultAvatar} from 'Icon/owner-default-avatar.svg';
import {ReactComponent as ControlPointDuplicate} from 'Icon/control-point-duplicate.svg';
import {ReactComponent as EtalonVerified} from 'Icon/etalon-verified.svg';
import {ReactComponent as ShowInfoSmall} from 'Icon/show-info-small.svg';
import {ReactComponent as Business} from 'Icon/business.svg';
import {ReactComponent as OrderTesting} from 'Icon/order-testing.svg';
import {ReactComponent as World} from 'Icon/world.svg';
import {ReactComponent as Pie} from 'Icon/pie.svg';
import {ReactComponent as ShoppingCart} from 'Icon/shopping-cart.svg';
import {ReactComponent as ShoppingCartAdd} from 'Icon/shopping-cart-add.svg';
import {ReactComponent as CompareBars} from 'Icon/compare-bars.svg';
import {ReactComponent as CompareBarsAdd} from 'Icon/compare-bars-add.svg';
import {ReactComponent as FilterList} from 'Icon/filter-list.svg';
import {ReactComponent as CloseCircle} from 'Icon/close-circle.svg';

export enum IconName {
  EtalonLogo,
  EtalonHorseLogo,
  EtalonDiagnosticsHorseLogo,
  Search,
  Instagram,
  Twitter,
  Facebook,
  Horsehead,
  Dna,
  Person,
  Home,
  Check,
  Calendar,
  Close,
  FirstOne,
  Next,
  Previous,
  LastOne,
  Back,
  CheckOrder,
  DnaIcon,
  PossibilityTriangle,
  DangerIcon,
  LeftSection,
  CenterSection,
  RightSection,
  LeftSection2,
  CenterSection2,
  RightSection2,
  Delete,
  ViewOn,
  ViewOff,
  Horseheads,
  Archive,
  UnArchive,
  Edit,
  Garbage,
  Clear,
  Settings,
  SettingsContained,
  AddCircleStroked,
  Info,
  More,
  CreditCard,
  Money,
  Truck,
  FilledCheckbox,
  ArrowDown,
  CloudUpload,
  CloudDownload,
  InsertPhoto,
  Phone,
  MapMarker,
  Share,
  ShowAll,
  HorseHeight,
  Refresh,
  ShowInfo,
  ArrowDownload,
  RearedHorse,
  ImageFrame,
  FileCopy,
  HorseheadNoCrest,
  Colors,
  Health,
  ExclamationMark,
  CrossedCheckbox,
  SquareFilledCheckbox,
  Eye,
  Mail,
  CallPhone,
  Pin,
  CheckShield,
  ArrowNext,
  Sandwich,
  Diagnostics,
  Question,
  Heart,
  ColorDrop,
  LeftTabletSection,
  CenterTabletSection,
  RightTabletSection,
  Lock,
  FiltersToggle,
  Star,
  Upload,
  AddCircleFilled,
  HorseGrantedBlack,
  HorseGrantedWhite,
  HorseNotGranted,
  CheckMark,
  Description,
  Exchange,
  Reload,
  BorderedCross,
  CardMastercard,
  CardMaestro,
  CardVisa,
  CardVisaElectron,
  CardAmericanExpress,
  CardUnionpay,
  AddToFavorites,
  AddToFavoritesHover,
  DeleteFromFavorites,
  Waiting,
  Clock,
  DeleteFromFavoritesHover,
  AlleleShort,
  AlleleLong,
  MarkedNotebook,
  Notebook,
  Notebook2,
  Notebook3,
  Drop,
  DropSimple,
  Cross,
  CrossSimple,
  Pyramid,
  Gear,
  GenderSymbol,
  OpenEnvelope,
  Coupon,
  DoneMark,
  Stallion,
  Mare,
  InsertComment,
  CheckedQuestionnaire,
  Scientist,
  EyeHide,
  EyeShow,
  ExcludedFilter,
  UserHasSubscription,
  Exclude,
  ExcludeHover,
  ExcludeRemove,
  ExcludeRemoveHover,
  BrokenImage,
  Notification,
  Email,
  PenOutline,
  Warning,
  InsertVideo,
  PlayArrow,
  PlayIcon,
  PlayIconPreview,
  Thunderbolt,
  Permission,
  Dashboard,
  AssociationPage,
  Logout,
  Dummy,
  HorseDefaultAvatar,
  OwnerDefaultAvatar,
  ControlPointDuplicate,
  EtalonVerified,
  ShowInfoSmall,
  Business,
  OrderTesting,
  World,
  Pie,
  ShoppingCart,
  ShoppingCartAdd,
  CompareBars,
  CompareBarsAdd,
  FilterList,
  CloseCircle,
}

const ICONS_MAP: {[key: number]: any} = {
  [IconName.EtalonLogo]: EtalonLogo,
  [IconName.Diagnostics]: Diagnostics,
  [IconName.EtalonHorseLogo]: EtalonHorseLogo,
  [IconName.EtalonDiagnosticsHorseLogo]: EtalonDiagnosticsHorseLogo,
  [IconName.Search]: Search,
  [IconName.Instagram]: Instagram,
  [IconName.Twitter]: Twitter,
  [IconName.Facebook]: Facebook,
  [IconName.Horsehead]: Horsehead,
  [IconName.Dna]: Dna,
  [IconName.Person]: Person,
  [IconName.Home]: Home,
  [IconName.Check]: Check,
  [IconName.Calendar]: Calendar,
  [IconName.Close]: Close,
  [IconName.FirstOne]: FirstOne,
  [IconName.Next]: Next,
  [IconName.Previous]: Previous,
  [IconName.LastOne]: LastOne,
  [IconName.Back]: Back,
  [IconName.CheckOrder]: CheckOrder,
  [IconName.DnaIcon]: DnaIcon,
  [IconName.PossibilityTriangle]: PossibilityTriangle,
  [IconName.DangerIcon]: DangerIcon,
  [IconName.LeftSection]: LeftSection,
  [IconName.CenterSection]: CenterSection,
  [IconName.RightSection]: RightSection,
  [IconName.LeftSection2]: LeftSection2,
  [IconName.CenterSection2]: CenterSection2,
  [IconName.RightSection2]: RightSection2,
  [IconName.Delete]: Delete,
  [IconName.ViewOn]: ViewOn,
  [IconName.ViewOff]: ViewOff,
  [IconName.Horseheads]: Horseheads,
  [IconName.Archive]: Archive,
  [IconName.UnArchive]: UnArchive,
  [IconName.Edit]: Edit,
  [IconName.Garbage]: Garbage,
  [IconName.Clear]: Clear,
  [IconName.Settings]: Settings,
  [IconName.SettingsContained]: SettingsContained,
  [IconName.AddCircleStroked]: AddCircleStroked,
  [IconName.More]: More,
  [IconName.Info]: Info,
  [IconName.CreditCard]: CreditCard,
  [IconName.Money]: Money,
  [IconName.Truck]: Truck,
  [IconName.FilledCheckbox]: FilledCheckbox,
  [IconName.ArrowDown]: ArrowDown,
  [IconName.CloudUpload]: CloudUpload,
  [IconName.CloudDownload]: CloudDownload,
  [IconName.InsertPhoto]: InsertPhoto,
  [IconName.Phone]: Phone,
  [IconName.MapMarker]: MapMarker,
  [IconName.Share]: Share,
  [IconName.ShowAll]: ShowAll,
  [IconName.HorseHeight]: HorseHeight,
  [IconName.Refresh]: Refresh,
  [IconName.ShowInfo]: ShowInfo,
  [IconName.ArrowDownload]: ArrowDownload,
  [IconName.RearedHorse]: RearedHorse,
  [IconName.ImageFrame]: ImageFrame,
  [IconName.FileCopy]: FileCopy,
  [IconName.HorseheadNoCrest]: HorseheadNoCrest,
  [IconName.Colors]: Colors,
  [IconName.Health]: Health,
  [IconName.ExclamationMark]: ExclamationMark,
  [IconName.CrossedCheckbox]: CrossedCheckbox,
  [IconName.SquareFilledCheckbox]: SquareFilledCheckbox,
  [IconName.Eye]: Eye,
  [IconName.Mail]: Mail,
  [IconName.CallPhone]: CallPhone,
  [IconName.Pin]: Pin,
  [IconName.CheckShield]: CheckShield,
  [IconName.ArrowNext]: ArrowNext,
  [IconName.Question]: Question,
  [IconName.Sandwich]: Sandwich,
  [IconName.Heart]: Heart,
  [IconName.ColorDrop]: ColorDrop,
  [IconName.LeftTabletSection]: LeftTabletSection,
  [IconName.CenterTabletSection]: CenterTabletSection,
  [IconName.RightTabletSection]: RightTabletSection,
  [IconName.Lock]: Lock,
  [IconName.FiltersToggle]: FiltersToggle,
  [IconName.Star]: Star,
  [IconName.AddCircleFilled]: AddCircleFilled,
  [IconName.Upload]: Upload,
  [IconName.HorseGrantedBlack]: HorseGrantedBlack,
  [IconName.HorseGrantedWhite]: HorseGrantedWhite,
  [IconName.HorseNotGranted]: HorseNotGranted,
  [IconName.CheckMark]: CheckMark,
  [IconName.Description]: Description,
  [IconName.Exchange]: Exchange,
  [IconName.Reload]: Reload,
  [IconName.BorderedCross]: BorderedCross,
  [IconName.CardAmericanExpress]: CardAmericanExpress,
  [IconName.CardMaestro]: CardMaestro,
  [IconName.CardMastercard]: CardMastercard,
  [IconName.CardUnionpay]: CardUnionpay,
  [IconName.CardVisa]: CardVisa,
  [IconName.CardVisaElectron]: CardVisaElectron,
  [IconName.AddToFavorites]: AddToFavorites,
  [IconName.AddToFavoritesHover]: AddToFavoritesHover,
  [IconName.DeleteFromFavorites]: DeleteFromFavorites,
  [IconName.Waiting]: Waiting,
  [IconName.Clock]: Clock,
  [IconName.DeleteFromFavoritesHover]: DeleteFromFavoritesHover,
  [IconName.AlleleShort]: AlleleShort,
  [IconName.AlleleLong]: AlleleLong,
  [IconName.MarkedNotebook]: MarkedNotebook,
  [IconName.Notebook]: Notebook,
  [IconName.Notebook2]: Notebook2,
  [IconName.Notebook3]: Notebook3,
  [IconName.Drop]: Drop,
  [IconName.DropSimple]: DropSimple,
  [IconName.Cross]: Cross,
  [IconName.CrossSimple]: CrossSimple,
  [IconName.Pyramid]: Pyramid,
  [IconName.Gear]: Gear,
  [IconName.GenderSymbol]: GenderSymbol,
  [IconName.OpenEnvelope]: OpenEnvelope,
  [IconName.Coupon]: Coupon,
  [IconName.DoneMark]: DoneMark,
  [IconName.Stallion]: Stallion,
  [IconName.Mare]: Mare,
  [IconName.InsertComment]: InsertComment,
  [IconName.CheckedQuestionnaire]: CheckedQuestionnaire,
  [IconName.Scientist]: Scientist,
  [IconName.EyeHide]: EyeHide,
  [IconName.EyeShow]: EyeShow,
  [IconName.ExcludedFilter]: ExcludedFilter,
  [IconName.UserHasSubscription]: UserHasSubscription,
  [IconName.Exclude]: Exclude,
  [IconName.ExcludeHover]: ExcludeHover,
  [IconName.ExcludeRemove]: ExcludeRemove,
  [IconName.ExcludeRemoveHover]: ExcludeRemoveHover,
  [IconName.BrokenImage]: BrokenImage,
  [IconName.Notification]: Notification,
  [IconName.Email]: Email,
  [IconName.PenOutline]: PenOutline,
  [IconName.Warning]: Warning,
  [IconName.InsertVideo]: InsertVideo,
  [IconName.PlayArrow]: PlayArrow,
  [IconName.PlayIcon]: PlayIcon,
  [IconName.PlayIconPreview]: PlayIconPreview,
  [IconName.Thunderbolt]: Thunderbolt,
  [IconName.Permission]: Permission,
  [IconName.Dashboard]: Dashboard,
  [IconName.AssociationPage]: AssociationPage,
  [IconName.Logout]: Logout,
  [IconName.HorseDefaultAvatar]: HorseDefaultAvatar,
  [IconName.OwnerDefaultAvatar]: OwnerDefaultAvatar,
  [IconName.ControlPointDuplicate]: ControlPointDuplicate,
  [IconName.EtalonVerified]: EtalonVerified,
  [IconName.ShowInfoSmall]: ShowInfoSmall,
  [IconName.Business]: Business,
  [IconName.OrderTesting]: OrderTesting,
  [IconName.World]: World,
  [IconName.Pie]: Pie,
  [IconName.ShoppingCart]: ShoppingCart,
  [IconName.ShoppingCartAdd]: ShoppingCartAdd,
  [IconName.CompareBars]: CompareBars,
  [IconName.CompareBarsAdd]: CompareBarsAdd,
  [IconName.FilterList]: FilterList,
  [IconName.CloseCircle]: CloseCircle,
};

export interface IProps extends HTMLAttributes<unknown> {
  name: IconName;
  size?: number;
  width?: number;
  height?: number;
}

const Icon = React.forwardRef((props: IProps, ref) => {
  const {name, size = 20, width = size, height = size} = props;

  const IconComponent = ICONS_MAP[name];

  return <IconComponent {...props} width={width} height={height} ref={ref} />;
});

export default Icon;
