import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';

import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';
import {StepInfoWrapper, StepLabel, StepValue} from 'Order/components/CreateOrder/common/styled';
import {INamedEntity} from 'Common/models/INamedEntity';
import {IHorseOrder} from 'BusinessPortal/models/order/createOrder/IHorseOrder';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import OrderHorse from 'BusinessPortal/components/BusinessPortalDashboard/Orders/CreateOrder/common/OrderHorse';

const UNIQUE_SPLITTER = '_&_';

const Divider = styled.div`
  border: 1px solid ${ColorPalette.white9};
  margin: 8px 0;
`;

const ShowListButton = styled.div`
  cursor: pointer;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.primary};
  margin-top: 24px;

  span {
    margin-left: 4px;
    font-size: 14px;
    font-weight: ${Typography.weight.medium500};
    line-height: 16px;
    color: ${Theme.color.black};
  }
`;

const ValueContainer = styled.div`
  margin: 8px 0;
`;

interface IAlias extends INamedEntity {
  alias: string;
}

interface ITestsSelectionHorse {
  horse: IOrderHorse;
  packages: IAlias[];
  tests: IAlias[];
}

interface ITestsSelectionOrder {
  groupBy: string;
  horses: ITestsSelectionHorse[];
}

interface IProps {
  horses: IOrderHorse[];
  orders: IHorseOrder[];
  packages: INamedEntity[];
  tests: INamedEntity[];
}

const TestsSelectionShow = (props: IProps) => {
  const {packages, horses, orders, tests} = props;

  const [isShowFullList, setIsShowFullList] = useState(false);
  const showFullList = useCallback(() => setIsShowFullList(true), []);

  // An alias id is added because Package and Test can match by name or ID
  const horseWithUniquePackagesAndTests: ITestsSelectionHorse[] = orders.map((horse) => ({
    horse: horses.find((i) => i.id === horse.horseId)!,
    packages: packages
      .filter((i) => horse.packages?.some((j) => j === i.id))
      .map((i) => ({...i, alias: `${i.id}${UNIQUE_SPLITTER}${i.name}`})),
    tests: tests
      .filter((i) => horse.tests?.some((j) => j === i.id))
      .map((i) => ({...i, alias: `${i.id}${UNIQUE_SPLITTER}${i.name}`})),
  }));

  const groupHorses = horseWithUniquePackagesAndTests.reduce((previous, currentItem) => {
    const group = currentItem.tests
      .concat(currentItem.packages)
      .map((i) => i.alias)
      .toString();
    if (!previous.find((i) => i.groupBy === group)) {
      previous = [...previous, {groupBy: group, horses: []}];
    }

    const currentGroup = previous.find((i) => i.groupBy === group);
    return previous.map((item) =>
      item.groupBy !== currentGroup?.groupBy ? item : {...item, horses: [...item.horses, currentItem]}
    );
  }, [] as ITestsSelectionOrder[]);

  const sortedGroupHorses = groupHorses.sort(
    (a, b) =>
      b.groupBy.split(',').map((i) => i.split(UNIQUE_SPLITTER)[1]).length -
      a.groupBy.split(',').map((i) => i.split(UNIQUE_SPLITTER)[1]).length
  );

  const horsesCount = sortedGroupHorses.reduce((acc, curr) => acc + curr.horses.length, 0);
  const isShowDivider = isShowFullList && sortedGroupHorses.length > 1;
  const isShowFullListButton = !isShowFullList && (sortedGroupHorses.length > 1 || horsesCount > 4);
  return (
    <>
      <StepInfoWrapper className="d-flex">
        <StepLabel className="d-flex w-100">Horses</StepLabel>
        <StepLabel className="d-flex w-100">Packages and tests</StepLabel>
      </StepInfoWrapper>
      {sortedGroupHorses.map((group, i) => {
        if (!isShowFullList && i > 0) return null;
        return (
          <div key={i} className="d-flex w-100 flex-column">
            <div className="d-flex w-100 justify-content-between">
              <div className="d-flex w-100 flex-column">
                {group.horses.map((item, j) => {
                  if (!isShowFullList && j > 3) return null;
                  return <OrderHorse key={j} horse={item.horse} />;
                })}
              </div>

              <ValueContainer className="d-flex w-100">
                <StepValue>
                  {group.groupBy
                    .split(',')
                    .map((i) => i.split(UNIQUE_SPLITTER)[1])
                    .sort()
                    .join(', ')}
                </StepValue>
              </ValueContainer>
            </div>
            {isShowDivider && i !== sortedGroupHorses.length - 1 && <Divider />}
            {isShowFullListButton && (
              <ShowListButton className="d-flex align-items-baseline" onClick={showFullList}>
                Show full list <span>({horsesCount} horses)</span>
              </ShowListButton>
            )}
          </div>
        );
      })}
    </>
  );
};

export default memo(TestsSelectionShow);
