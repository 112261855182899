import React from 'react';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps} from 'formik/dist/types';
import styled from 'styled-components';

import {InputField} from 'Common/components/FormFields';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {actions as horseActions, selectors as horseSelectors} from 'BusinessPortal/store/horse';
import {actions as ownerActions, selectors as ownerSelectors} from 'BusinessPortal/store/owner';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {
  createOwnerInitialValue,
  createOwnerValidationSchema,
  ICreateOwnerFormValues
} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseOwnerSection/HorseOwnerSectionEdit/common/validation';
import {convertToServer} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseOwnerSection/HorseOwnerSectionEdit/common/converters';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {ChangeOwnerFormButton} from './common/styled';

const SubmitButton = styled(PrimaryButton)`
  margin-top: 16px;
`;

interface IExternalProps {
  horseId: number;
  ownerId?: number;
  onSuccess?(): void;
  onChangeForm?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<ICreateOwnerFormValues> & OuterProps;

const HorseOwnerInfoSectionCreateForm = (props: AllProps) => {
  const {isValid, isSubmitting} = props;
  const {onSuccess, onChangeForm, ownerCreating, setHorseOwnerRequesting} = props;

  const isRequesting = ownerCreating.isRequesting;

  useErrorCommunicationToToast(ownerCreating);
  useErrorCommunicationToToast(setHorseOwnerRequesting);
  useOnSuccessCommunication(setHorseOwnerRequesting, () => onSuccess && onSuccess());

  if (isRequesting) {
    return <Loading />;
  }

  return (
    <Form>
      <InputField
        isRequired={true}
        name="name"
        type="text"
        label="Full Name"
        placeholder="Full name"
        autoComplete="off"
        memoized={true}
      />
      <InputField
        name="email"
        type="email"
        label="Email"
        placeholder="Email"
        autoComplete="off"
        memoized={true}
        isRequired
      />
      <ChangeOwnerFormButton onClick={onChangeForm}>Find an existing user?</ChangeOwnerFormButton>
      <SubmitButton className="w-100" size="medium" type="submit" disabled={!isValid} isLoading={isSubmitting}>
        Save changes
      </SubmitButton>
    </Form>
  );
};

const HorseOwnerInfoSectionCreateFormFormik = withFormik<OuterProps, ICreateOwnerFormValues>({
  mapPropsToValues: () => createOwnerInitialValue,
  validationSchema: createOwnerValidationSchema,
  handleSubmit: async (values, formikBag) => {
    const {createOwner, setHorseOwner, horseId} = formikBag.props;
    try {
      const ownerId = await createOwner(convertToServer(values));
      await setHorseOwner(horseId, ownerId);
    } catch (error) {
      return;
    }
  },
  enableReinitialize: true
})(HorseOwnerInfoSectionCreateForm);

const mapStateToProps = (state: IAppState) => ({
  ownerCreating: ownerSelectors.selectCommunication(state, 'ownerCreating'),
  setHorseOwnerRequesting: horseSelectors.selectCommunication(state, 'setHorseOwnerRequesting')
});

const mapDispatchToProps = {
  createOwner: ownerActions.createOwner,
  setHorseOwner: horseActions.setHorseOwner
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(HorseOwnerInfoSectionCreateFormFormik);
