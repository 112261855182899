import React from 'react';
import styled from 'styled-components';

import Icon, {IconName} from 'Icon/components/Icon';
import SwitchField from 'Common/components/FormFields/SwitchField';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import Typography from 'Common/constants/Typography';
import {IPersonalPrivacySettings} from 'UserProfile/models/IUserPrivacySettings';
import {Nullable} from 'Common/types';
import {SwitchFieldWrapper, SwitchWrapper} from 'Common/components/StyledComponents/StyledComponents';
import ColorPalette from 'Common/constants/ColorPalette';

const iconProps = {size: 22, style: {marginRight: 10}};

const PrivacyText = styled.div`
  font-size: ${Typography.size.size14};
`;

export const ItemVisibilityText = styled.div`
  color: ${ColorPalette.gray28};
`;

interface IProps {
  title: string;
  fieldNames?: Record<keyof IPersonalPrivacySettings, string>;
  personalPrivacySettings: Nullable<IPersonalPrivacySettings>;
}

const PersonalPrivacySettings = ({title, fieldNames, personalPrivacySettings}: IProps) => {
  const fields = {
    email: fieldNames?.isPublicEmail ?? 'isPublicEmail',
    phone: fieldNames?.isPublicPhone ?? 'isPublicPhone',
    location: fieldNames?.isPublicLocation ?? 'isPublicLocation',
  };

  const data = [
    {label: 'My E-mail', name: fields.email, icon: IconName.Mail, object: personalPrivacySettings?.isPublicEmail},
    {
      label: 'My Phone Number',
      name: fields.phone,
      icon: IconName.CallPhone,
      object: personalPrivacySettings?.isPublicPhone,
    },
    {
      label: 'Address',
      name: fields.location,
      icon: IconName.Pin,
      object: personalPrivacySettings?.isPublicLocation,
    },
  ];

  return (
    <FormControlContainer title={title} style={{marginBottom: 10}}>
      {data.map((item, i) => (
        <SwitchWrapper className="d-flex flex-row" key={i}>
          <div className="d-flex flex-row align-items-center w-100">
            <Icon name={item.icon} {...iconProps} />
            <PrivacyText>{item.label}</PrivacyText>
          </div>
          <SwitchFieldWrapper>
            <SwitchField
              name={item.name}
              textLeft={<ItemVisibilityText>{item.object ? 'Public' : 'Private'}</ItemVisibilityText>}
            />
          </SwitchFieldWrapper>
        </SwitchWrapper>
      ))}
    </FormControlContainer>
  );
};

export default PersonalPrivacySettings;
