import {
  ICommunication,
  makeCommunicationActionType,
  MakeCommunicationActions,
  makeResetCommunicationActionType,
  MakeResetCommunicationActions,
} from 'Common/store/utils/communication';
import {IPagination, IListResponse} from 'Common/models/IResponse';

import {IHorseAdmin, IHorseDetails, IHorseFullInfo} from 'Admin/AdminDashboard/models/Horse';
import {IHorseSample} from 'Admin/AdminDashboard/models/Order/IHorseSample';

const STATE_NAME = 'adminHorses';

export interface IAdminHorsesState {
  data: {
    horses: IHorseAdmin[];
    shortHorse: IHorseDetails | null;
    horse: IHorseFullInfo | null;
    horseSamples: IHorseSample[];
  };
  communications: {
    horsesLoading: ICommunication;
    shortHorseLoading: ICommunication;
    horseLoading: ICommunication;
    horseCreating: ICommunication;
    horseUpdating: ICommunication;
    horseDeleting: ICommunication;
    horseArchiving: ICommunication;
    geneticDataHiding: ICommunication;
    sampleIdUpdating: ICommunication;
    sampleIdActivating: ICommunication;
    horseSamplesLoading: ICommunication;
    sampleCreating: ICommunication;
    ancestryReportHiding: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export interface IArchiveHorseParams {
  horseId: number;
  isArchived: boolean;
}

export interface IHideDataParams {
  horseId: number;
  isHidden: boolean;
}

export const getHorsesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSES');
export const getShortHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_SHORT_HORSE');
export const getHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE');
export const createHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_HORSE');
export const updateHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_HORSE');
export const deleteHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_HORSE');
export const horseArchiveActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_HORSE_ARCHIVE');
export const hideGeneticDataActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_HIDING_GENETIC_DATA');
export const updateSampleIdActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_SAMPLE_ID');
export const activateSampleIdActionTypes = makeCommunicationActionType(STATE_NAME, 'ACTIVATE_SAMPLE_ID');
export const getHorseSamplesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_SAMPLES');
export const createSampleActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_SAMPLE');
export const hideAncestryReportActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_HIDING_ANCESTRY_REPORT');

export type getHorsesActions = MakeCommunicationActions<
  typeof getHorsesActionTypes,
  {success: IListResponse<IHorseAdmin>}
>;
export type getShortHorseActions = MakeCommunicationActions<typeof getShortHorseActionTypes, {success: IHorseDetails}>;
export type getHorseActions = MakeCommunicationActions<typeof getHorseActionTypes, {success: IHorseFullInfo}>;
export type createHorseActions = MakeCommunicationActions<typeof createHorseActionTypes, {}>;
export type updateHorseActions = MakeCommunicationActions<typeof updateHorseActionTypes, {}>;
export type deleteHorseActions = MakeCommunicationActions<typeof deleteHorseActionTypes, {}>;
export type horseArchiveAction = MakeCommunicationActions<
  typeof horseArchiveActionTypes,
  {success: IArchiveHorseParams}
>;
export type hideGeneticDataActions = MakeCommunicationActions<
  typeof hideGeneticDataActionTypes,
  {success: IHideDataParams}
>;
export type updateSampleIdActions = MakeCommunicationActions<typeof updateSampleIdActionTypes, {success: string}>;
export type activateSampleIdActions = MakeCommunicationActions<typeof activateSampleIdActionTypes, {success: string}>;
export type getHorsesSamplesActions = MakeCommunicationActions<
  typeof getHorseSamplesActionTypes,
  {success: IListResponse<IHorseSample>}
>;
export type createSampleActions = MakeCommunicationActions<typeof createSampleActionTypes, {success: string}>;
export type hideAncestryReportActions = MakeCommunicationActions<
  typeof hideAncestryReportActionTypes,
  {success: IHideDataParams}
>;

export const resetHorseActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSE');
export type resetHorseActions = MakeResetCommunicationActions<typeof resetHorseActionTypes>;

export const resetHorseDetailsActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSE_DETAILS');
export type resetHorseDetailsActions = MakeResetCommunicationActions<typeof resetHorseDetailsActionTypes>;

export const resetHorseSamplesActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSE_SAMPLES');
export type resetHorseSamplesActions = MakeResetCommunicationActions<typeof resetHorseSamplesActionTypes>;

export type AdminHorsesActions =
  | getHorsesActions
  | getShortHorseActions
  | getHorseActions
  | createHorseActions
  | updateHorseActions
  | deleteHorseActions
  | horseArchiveAction
  | resetHorseActions
  | resetHorseDetailsActions
  | resetHorseSamplesActions
  | hideGeneticDataActions
  | updateSampleIdActions
  | activateSampleIdActions
  | getHorsesSamplesActions
  | createSampleActions
  | hideAncestryReportActions;
