import {combineReducers} from 'redux';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {IResetPasswordState, resetPasswordActions, resetPasswordActionTypes} from 'ResetPassword/store/types';

export const communicationResetPasswordReducer = combineReducers<
  IResetPasswordState['communications'],
  resetPasswordActions
>({
  messageLoading: makeCommunicationReducerFromEnum(resetPasswordActionTypes),
});
