import React from 'react';
import styled from 'styled-components';

import {SubHeader} from 'Filters/components/shared/StyledComponents';
import IconYellow from 'Icon/accordion-icon-yellow.svg';
import IconBlack from 'Icon/arrow-down.svg';

const SubHeaderToggleElement = styled(SubHeader)<ISelectedPoints>`
  cursor: pointer;
  position: relative;
  margin-left: 28px;

  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  ::before {
    display: block;
    height: 12px;
    width: 12px;
    content: '';
    position: absolute;
    left: -26px;
    top: 30%;
    transform: translateY(-50%);
    background-image: url(${(prop) => (prop.isSelectedPoints ? IconYellow : IconBlack)});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    ${(prop) => (prop.isHidden ? 'transform: rotate(0deg);' : 'transform: rotate(180deg);')}
  }
`;

interface IProps {
  isHidden: boolean;
  isSelectedPoints?: boolean;
  titleStyle?: React.CSSProperties;
  onClick(): void;
}

interface ISelectedPoints {
  isSelectedPoints?: boolean;
  isHidden: boolean;
}

type AllProps = IProps & React.HTMLAttributes<HTMLDivElement>;

const SubHeaderToggle = (props: AllProps) => {
  return (
    <>
      <SubHeaderToggleElement
        onClick={props.onClick}
        isSelectedPoints={props.isSelectedPoints}
        isHidden={props.isHidden}
        style={props.titleStyle}
      >
        {props.children}
      </SubHeaderToggleElement>
    </>
  );
};

export default SubHeaderToggle;
