import {DNAIndicatorParameter} from 'UserProfile/components/shared/UserHorseOverviewLayout/HorseCharacteristics/parts/common/types';
import {AbilityType} from 'Common/constants/AbilityType';
import {Nullable} from 'Common/types';
import {AbilityState} from 'Common/constants/AbilityState';

export function parseToIndicatorParameters(
  tests: Array<{
    type: AbilityType;
    state: Nullable<AbilityState>;
    name?: string;
    description?: string;
  }>,
  colors: string[]
): DNAIndicatorParameter[] {
  return (
    tests.map((testValue, index) =>
      !!testValue.state
        ? {
            abilityType: testValue.type,
            isExist: true,
            color: colors[index],
            value: testValue.state,
            name: testValue.name,
            description: testValue.description,
          }
        : {abilityType: testValue.type, isExist: false, size: 1}
    ) as DNAIndicatorParameter[]
  ).reduce((arr, curr) => {
    const prevItem = arr[arr.length - 1];
    if (!curr.isExist && prevItem && !prevItem.isExist) {
      prevItem.size += 1;
      return arr;
    }

    arr.push({...curr});
    return arr;
  }, [] as DNAIndicatorParameter[]);
}
