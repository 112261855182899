import {combineReducers, Reducer} from 'redux';

import {IGenotypeListState} from '../types';
import {communicationGenotypesReducer} from './communications';
import {dataGenotypesReducer} from './data';

export const genotypesReducer: Reducer<IGenotypeListState> = combineReducers<IGenotypeListState>({
  communications: communicationGenotypesReducer,
  data: dataGenotypesReducer
});
