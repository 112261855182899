import {IOrderAdmin} from 'Admin/AdminDashboard/models/Order/IOrderAdmin';
import {IOrderDetails} from 'Admin/AdminDashboard/models/Order/IOrderDetails';
import {
  IReleaseOrderHorse,
  IRequestChangeSample,
  IRequestChooseAnotherSample,
  IRequestNewSampleParams,
  IRequestSetSampleStatus,
} from 'Admin/AdminDashboard/store/adminOrders';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';

import {IHorseSample} from 'Admin/AdminDashboard/models/Order/IHorseSample';
import {
  convertOrderAdminToClient,
  convertOrderDetailsToClient,
  convertStatusDetails,
} from 'Admin/AdminDashboard/services/converters/orders';
import {
  IServerOrderAdmin,
  IServerOrderDetailsAdmin,
  IServerOrderStatusAdmin,
} from 'Admin/AdminDashboard/services/types/order';
import {downloadResponse} from 'Common/helpers/downloadHelper';
import {IOrderStatused} from 'Common/models/IOrderStatused';

const getOrders = async (params?: IRequestParams): Promise<IListResponse<IOrderAdmin>> => {
  const response = await axiosWrapper.get<IListResponse<IServerOrderAdmin>>(`/OrdersAdmin`, {params});
  return {...response.data, data: response.data.data.map(convertOrderAdminToClient)};
};

const getOrderDetails = async (orderId: number): Promise<IOrderDetails> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerOrderDetailsAdmin>>(`/OrdersDetailsAdmin/${orderId}`);
  return convertOrderDetailsToClient(response.data.data);
};

const releaseOrderHorse = async (data: IReleaseOrderHorse): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<void>>(`/OrderReleaseAdmin`, data);
};

const getHorseSamples = async (horseId: number): Promise<IHorseSample[]> => {
  const response = await axiosWrapper.get<IListResponse<IHorseSample>>(`/SampleAdmin/${horseId}`);
  return response.data.data;
};

const deleteOrder = async (id: number): Promise<void> => {
  await axiosWrapper.delete<void>(`/OrdersDeleteAdmin`, {data: {id}});
};

const requestNewSample = async (params: IRequestNewSampleParams): Promise<void> => {
  await axiosWrapper.post<void>(`/SampleRequestAdmin`, params);
};

const setSampleStatus = async (params: IRequestSetSampleStatus): Promise<void> => {
  await axiosWrapper.post<void>(`/SampleStatusUpdateAdmin`, params);
};

const downloadReports = async (orderId: number): Promise<void> => {
  const response = await axiosWrapper.get<void>(`/OrderReportsAdmin/${orderId}`, {responseType: 'blob'});
  downloadResponse(response, 'report');
};

const downloadOrderSubmissionForm = async (orderId: number): Promise<void> => {
  const response = await axiosWrapper.get<void>(`/OrderSubmissionFormAdmin/${orderId}`, {
    responseType: 'blob',
  });
  downloadResponse(response, 'submission-form');
};

const downloadHorseSubmissionForm = async (orderId: number, horseId: number): Promise<void> => {
  const response = await axiosWrapper.get<void>(`/HorseSubmissionFormAdmin`, {
    params: {orderId, horseId},
    responseType: 'blob',
  });
  downloadResponse(response, 'submission-form');
};

const changeSample = async (params: IRequestChangeSample): Promise<void> => {
  await axiosWrapper.post<void>(`/SampleUpdateAdmin`, params);
};

const chooseAnotherSample = async (params: IRequestChooseAnotherSample): Promise<void> => {
  await axiosWrapper.post<void>(`/SampleOrderAdmin/ChangeSampleInOrder`, params);
};

const getOrderStatusesDetails = async (orderId: number, horseId: number): Promise<IOrderStatused> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerOrderStatusAdmin>>('/OrderHorseStatusesDetailsAdmin', {
    params: {orderId, horseId},
  });
  return convertStatusDetails(response.data.data);
};

export default {
  getOrders,
  getOrderDetails,
  getHorseSamples,
  deleteOrder,
  requestNewSample,
  releaseOrderHorse,
  setSampleStatus,
  changeSample,
  chooseAnotherSample,
  downloadReports,
  downloadOrderSubmissionForm,
  downloadHorseSubmissionForm,
  getOrderStatusesDetails,
};
