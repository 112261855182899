import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';

import {actions, selectors} from 'Admin/AdminSettings/stores/adminErrorDescriptions/index';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import Loading from 'Loading/components/Loading';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {IErrorDescription} from 'Admin/AdminSettings/models/IErrorDescription';
import {AdminErrorDescriptionsModule} from 'Admin/AdminSettings/stores/adminErrorDescriptions/adminErrorDescriptionsModule';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import Pagination from 'Common/components/Controls/Pagination/Pagination';
import {FormType} from 'Common/constants/FormType';
import {OrderingType} from 'Common/constants/OrderingType';
import ErrorDescriptionForm from './ErrorDescriptionForm/ErrorDescriptionForm';

const CONFIRM_DELETE_ERROR_DESCRIPTION = 'Are you sure you want to remove the error description?';

const actionButtonProps = {color: ColorPalette.gray28, fill: true, stroke: false, size: 24};

const AddErrorDescriptionButton = styled(PrimaryButton)`
  width: 176px;
  height: 50px;
  margin-left: 55px;
`;

const ErrorDescriptionTable = styled.div`
  margin-top: 50px;
`;

const ActionButton = styled(IconButton)`
  margin-left: 12px;
`;

interface IActionProps {
  id: number;
  editAction: (id: number) => void;
  deleteAction: (id: number) => void;
}

const Actions = React.memo((props: IActionProps) => {
  const {id, editAction, deleteAction} = props;

  const handleEditAction = useCallback(() => editAction(id), [id, editAction]);
  const handleDeleteAction = useCallback(() => deleteAction(id), [id, deleteAction]);

  return (
    <div className="d-flex align-items-center justify-content-end">
      <ActionButton name={IconName.Edit} {...actionButtonProps} onClick={handleEditAction} />
      <ActionButton name={IconName.Garbage} {...actionButtonProps} onClick={handleDeleteAction} />
    </div>
  );
});

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const ErrorDescriptions = (props: AllProps) => {
  const {
    getErrorDescriptions,
    errorDescriptionsLoading,
    errorDescriptions,
    deleteErrorDescription,
    errorDescriptionDeleting,
    pagination,
  } = props;

  const {
    selectedId,
    setSelectedId,
    openDeleteModal,
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    deleteModal,
    reloadItems,
    searchBar,
  } = useCommonAdminPageData<IErrorDescription>({
    sortingAfterFilteringParams: {orderBy: 'name', orderingType: OrderingType.Ascending},
    getItems: getErrorDescriptions,
    deleteParams: {
      communication: errorDescriptionDeleting,
      confirmDescription: CONFIRM_DELETE_ERROR_DESCRIPTION,
      action: deleteErrorDescription,
    },
  });

  const [isCreateOrEditErrorDescriptionOpen, setIsCreateOrEditErrorDescriptionOpen] = useState(false);
  const [formType, setFormType] = useState<FormType>(FormType.create);

  const isRequesting =
    errorDescriptionsLoading.isRequesting ||
    errorDescriptionsLoading.isRequesting ||
    errorDescriptionDeleting.isRequesting;

  const handleAddErrorDescription = () => {
    setFormType(FormType.create);
    setSelectedId(null);
    setIsCreateOrEditErrorDescriptionOpen(true);
  };

  const handleEditErrorDescription = (id: number) => {
    setFormType(FormType.edit);
    setSelectedId(id);
    setIsCreateOrEditErrorDescriptionOpen(true);
  };

  const closeCreateOrEditErrorDescriptionModal = useCallback(() => {
    setIsCreateOrEditErrorDescriptionOpen(false);
  }, []);

  const onSuccessCreateOrEditErrorDescription = useCallback(() => {
    closeCreateOrEditErrorDescriptionModal();
    reloadItems();
  }, [closeCreateOrEditErrorDescriptionModal, reloadItems]);

  const isShowPagination = errorDescriptions.length > 0;

  return (
    <AdminPageLayout>
      {deleteModal}
      <ModalWindow isOpen={isCreateOrEditErrorDescriptionOpen} onClose={closeCreateOrEditErrorDescriptionModal}>
        <ErrorDescriptionForm
          errorDescriptionId={selectedId || undefined}
          type={formType}
          onSuccess={onSuccessCreateOrEditErrorDescription}
        />
      </ModalWindow>

      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
        <AddErrorDescriptionButton onClick={handleAddErrorDescription}>+ Add resource</AddErrorDescriptionButton>
      </div>
      <ErrorDescriptionTable className="position-relative">
        {isRequesting && <Loading />}
        <Table<IErrorDescription>
          data={errorDescriptions}
          rowKey="id"
          sorting={sorting}
          onChangeSorting={changeSorting}
        >
          <Cell<IErrorDescription> header="ID" dataKey="id" render={({id}) => `#${id}`} width="10%" />
          <Cell<IErrorDescription> header="Code" dataKey="code" width="40%" render={({code}) => code} />
          <Cell<IErrorDescription> header="Value" dataKey="value" width="40%" render={({value}) => value} />
          <Cell<IErrorDescription>
            header="Actions"
            align={CellAlign.Right}
            width="10%"
            render={({id}) => (
              <Actions id={id} editAction={handleEditErrorDescription} deleteAction={openDeleteModal} />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </ErrorDescriptionTable>
    </AdminPageLayout>
  );
};

const mapStateToProps = (state: IAppState) => ({
  errorDescriptions: selectors.selectErrorDescriptions(state),
  errorDescriptionsLoading: selectors.selectCommunication(state, 'errorDescriptionsLoading'),
  errorDescriptionDeleting: selectors.selectCommunication(state, 'errorDescriptionDeleting'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getErrorDescriptions: actions.getErrorDescriptions,
  deleteErrorDescription: actions.deleteErrorDescription,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(ErrorDescriptions);

export default withDynamicModules(Connected, AdminErrorDescriptionsModule);
