import {combineReducers, Reducer} from 'redux';

import {IOrderPaymentState} from '../types';
import {communicationReducer} from './communications';
import {dataReducer} from './data';

export const orderPaymentReducer: Reducer<IOrderPaymentState> = combineReducers<IOrderPaymentState>({
  communications: communicationReducer,
  data: dataReducer
});
