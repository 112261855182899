import {IEntity} from './IEntity';

export enum MediaType {
  Image = 'Image',
  Video = 'Video'
}

export interface IMediaResource extends IEntity {
  type: MediaType;
  url: string;
  file?: File;
  isLocal?: boolean;
  isExist?: boolean;
}
