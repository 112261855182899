import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.createOrder;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectIsLoadingFormData(state: IAppState) {
  return selectCommunication(state, 'horsesLoading').isRequesting;
}

export function selectOrderHorses(state: IAppState) {
  return selectState(state).data.horses;
}

export function selectSummary(state: IAppState) {
  return selectState(state).data.summary;
}

export function selectCoupon(state: IAppState) {
  return selectState(state).data.coupon;
}

export function selectCreatedOrder(state: IAppState) {
  return selectState(state).data.createdOrder;
}

export function selectContactInformation(state: IAppState) {
  return selectState(state).data.contactInformation;
}

export function selectOrderPackages(state: IAppState) {
  return selectState(state).data.orderPackages;
}
