import {ICommunication} from 'Common/store/utils/communication';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {useSuccessCommunicationToToast} from 'Common/helpers/hooks/useSuccessCommunicationToToast';

export function useCommunicationToToast(
  communication: ICommunication,
  successMessage?: string,
  errorMessage?: string,
  toastId?: number | string
) {
  useSuccessCommunicationToToast(communication, successMessage, toastId);
  useErrorCommunicationToToast(communication, errorMessage, toastId);
}
