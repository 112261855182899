import * as Yup from 'yup';

export interface IFormValues {
  newPassword: string;
  confirmPassword: string;
}

export const initialValue: IFormValues = {
  newPassword: '',
  confirmPassword: '',
};

export const validationSchema = Yup.object().shape<IFormValues>({
  newPassword: Yup.string()
    .required('New password is required')
    .oneOf([Yup.ref('confirmPassword')], 'Passwords must match'),
  confirmPassword: Yup.string()
    .required('Repeat new password is required')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});
