import React, {useCallback, useState} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import AdminPermissionsForm from 'Admin/AdminAdvancedDashboard/components/Administrators/PermissionsForm/AdminPermissionsForm';
import {IAdministrator} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdministrator';

interface IProps {
  onSuccess(): void;
}

export function useUpdateAdministratorPermissionsModal(props: IProps) {
  const {onSuccess} = props;

  const [selectedId, setSelectedId] = useState(0);
  const [selectedAdmin, setSelectedAdmin] = useState<IAdministrator>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openUpdateAdministratorPermissionsModal = useCallback(
    (admin: IAdministrator) => {
      setSelectedId(admin.id);
      setSelectedAdmin(admin);
      setIsModalOpen(true);
    },
    [setSelectedId]
  );
  const closeUpdateAdministratorPermissionsModal = useCallback(() => setIsModalOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeUpdateAdministratorPermissionsModal();
    onSuccess();
  }, [closeUpdateAdministratorPermissionsModal, onSuccess]);

  const updateAdministratorPermissionsModal = (
    <ModalWindow isOpen={isModalOpen && !!selectedId} onClose={closeUpdateAdministratorPermissionsModal}>
      <AdminPermissionsForm admin={selectedAdmin!} onSuccess={handleSuccess} />
    </ModalWindow>
  );

  return {
    openUpdateAdministratorPermissionsModal,
    updateAdministratorPermissionsModal
  };
}
