import {IAddress, IAddressWithNullableField} from 'Common/models/IAddress';

const mapPriorityToAddressField: Record<keyof IAddress, number> = {
  country: 0,
  state: 1,
  city: 2,
  street: 3,
  zip: 4
};

export const getStringifyAddress = <T extends IAddressWithNullableField | IAddress>(address: T) => {
  const parsedAddress: T = {
    ...address,
    state: address.state && address.zip ? `${address.state} ${address.zip}`.trim() : address.state,
    zip: address.state?.length === 0 ? address.zip : ''
  };

  return Object.entries(parsedAddress)
    .filter(([, value]) => value?.length > 0)
    .sort(([key1], [key2]) => mapPriorityToAddressField[key2] - mapPriorityToAddressField[key1])
    .map(([, value]) => value)
    .join(', ');
};
