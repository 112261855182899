import * as React from 'react';
import styled from 'styled-components';

import {DropDownMenu, IMenuItem} from 'Common/components/DropDown/index';
import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon, {IColoredIconProps} from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import Typography from 'Common/constants/Typography';
import IconButton from 'Common/components/Controls/Buttons/IconButton';

const Header = styled.div`
  height: 44px;
  padding-left: 22px;
  padding-right: 10px;
  background: ${ColorPalette.gray22};
`;

const HeaderTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${Typography.size.size18};
  font-weight: 600;
`;

const SColumn = styled.div<{flexBasis?: string}>`
  flex-basis: ${({flexBasis}) => flexBasis || '100%'};
  margin: 0 22px;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }
`;

const SettingsIcon = styled(ColoredIcon)`
  cursor: pointer;
`;

const Divider = styled.div`
  flex-basis: 2px;
  margin: 8px 0;
  border-right: solid 1px ${ColorPalette.gray10};
`;

const ActionIconButton = styled(IconButton)`
  margin: 4px 8px 0 0;
`;

interface IColumnProps {
  label: string;
  width?: string;
  menu?: IColumnMenu;
  actions?: IAction[];
}

interface IColumnMenu {
  items: IMenuItem[];
  onSelect(value: string): void;
}

interface IAction {
  icon: IColoredIconProps;
  onClick(): void;
}

export default function RecordDetails({children}: React.PropsWithChildren<{}>) {
  const columnsProps = (
    React.Children.toArray(children) as Array<React.ReactElement<React.PropsWithChildren<IColumnProps>>>
  ).map(({props}) => props);

  return (
    <div className="d-flex align-content-stretch flex-wrap">
      {columnsProps.map(({width, label, menu, children: colChildren, actions}, i, arr) => (
        <React.Fragment key={i}>
          <SColumn flexBasis={width}>
            <Header className="d-flex align-items-center justify-content-between">
              <HeaderTitle>{label}</HeaderTitle>
              <div className="d-flex">
                {actions &&
                  actions.map((action, i) => <ActionIconButton {...action.icon} onClick={action.onClick} key={i} />)}
                {menu && (
                  <DropDownMenu items={menu.items} onSelect={menu.onSelect} alignRight={true}>
                    {({isOpen}) => (
                      <SettingsIcon
                        name={IconName.Settings}
                        color={isOpen ? ColorPalette.black1 : ''}
                        hoverColor={ColorPalette.black1}
                        fill={true}
                        stroke={false}
                      />
                    )}
                  </DropDownMenu>
                )}
              </div>
            </Header>
            <div>{colChildren}</div>
          </SColumn>
          {i !== arr.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </div>
  );
}

function Column(_: React.PropsWithChildren<IColumnProps>) {
  return null;
}

RecordDetails.Column = Column;
