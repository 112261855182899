import React, {useState, useCallback, useEffect, useMemo, useRef, CSSProperties, memo} from 'react';
import styled from 'styled-components';
import {debounce} from 'lodash';
import {PropsValue, InputActionMeta, ActionMeta} from 'react-select/dist/declarations/src/types';
import {FormatOptionLabelMeta} from 'react-select/dist/declarations/src/Select';
import Highlighter from 'react-highlight-words';

import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {Select} from 'Common/components/Controls/index';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';
import {SelectProps} from 'Common/components/Controls/Select/Select';
import Theme from 'Common/constants/Theme';
import {IFoundOrganization} from 'Admin/AdminAssociations/models/IFoundOrganization';
import AdminOrganizationDataService from 'Admin/AdminAssociations/services/AdminOrganizationDataService';
import {Nullable} from 'Common/types';

const highlightStyle: CSSProperties = {fontWeight: 500, background: 'none', margin: 0, padding: 0};

const styles = {
  menuList: () => ({
    boxShadow: 'unset',
  }),
  valueContainer: () => ({
    paddingLeft: '56px',
  }),
};

const style = {
  marginBottom: 0,
};

const SearchIcon = styled(ColoredIcon)`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

const optionCustomRenderer = (
  option: IDictionaryOptionType,
  labelMeta: FormatOptionLabelMeta<IDictionaryOptionType>
) => {
  const {label} = option;

  return (
    <Highlighter searchWords={[labelMeta.inputValue]} textToHighlight={`${label}`} highlightStyle={highlightStyle} />
  );
};

const emptyListMessage = () => 'No organizations';

interface IOwnProps {
  defaultValue?: IDictionaryOptionType;
  action?(filter: string): Promise<IFoundOrganization[]>;
  onSelect(id: number): void;
  onClear?: () => void;
}

type Props = IOwnProps & Partial<SelectProps<IDictionaryOptionType>>;

function OrganizationSearch(props: Props) {
  const {onSelect, onClear, action = AdminOrganizationDataService.searchOrganization, defaultValue, ...rest} = props;

  const [searchValue, setSearchValue] = useState('');

  const onInputChange = useCallback(
    (value: string, {action}: InputActionMeta) => {
      if (action === 'input-change') {
        setSearchValue(value);
      }
    },
    [setSearchValue]
  );

  const [foundValues, setFoundValues] = useState<IFoundOrganization[]>([]);
  const [isOrganizationLoading, setIsOrganizationLoading] = useState(false);
  const isEmptyValue = useRef(true);

  const getOrganizations = useCallback(
    async (inputValue: string) => {
      if (!action) {
        return;
      }

      try {
        setIsOrganizationLoading(true);
        const organizations = await action(inputValue);
        if (isEmptyValue.current) {
          return;
        }
        setFoundValues(organizations);
      } catch (e) {
        console.error(e);
      } finally {
        setIsOrganizationLoading(false);
      }
    },
    [action]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetOrganizations = useCallback(
    debounce((value?: string) => value && getOrganizations(value), 500),
    [getOrganizations]
  );

  useEffect(() => {
    isEmptyValue.current = !searchValue;
    if (!searchValue) {
      setFoundValues([]);
    }
    debouncedGetOrganizations(searchValue);
  }, [searchValue, getOrganizations, debouncedGetOrganizations]);

  const [selectedOrganization, setSelectedOrganization] = useState<Nullable<PropsValue<IDictionaryOptionType>>>(
    defaultValue || null
  );

  const onSelectOption = useCallback(
    (organization: PropsValue<IDictionaryOptionType>, action: ActionMeta<IDictionaryOptionType>) => {
      if (action.action === 'select-option' && organization) {
        const {value} = organization as IDictionaryOptionType;
        setSelectedOrganization(organization as IDictionaryOptionType);
        onSelect(Number(value));
      }

      if (action.action === 'clear') {
        setSelectedOrganization(null);
        setSearchValue('');
        onClear && onClear();
      }
    },
    [onSelect, onClear]
  );

  const isLoading = !!searchValue && isOrganizationLoading;
  const organizations = useMemo(() => (!!searchValue ? foundValues : []), [foundValues, searchValue]);

  const options = useMemo(() => {
    return organizations.map(({id, name, abbreviation}) => {
      const abbr = abbreviation ? `(${abbreviation})` : '';
      return {
        value: id,
        label: `${name} ${abbr}`,
        id,
      };
    });
  }, [organizations]);

  return (
    <div className="position-relative">
      <SearchIcon name={IconName.Search} size={24} stroke={false} fill={true} color={Theme.color.primary} />
      <Select<IDictionaryOptionType>
        isSearchable={true}
        inputValue={selectedOrganization ? undefined : searchValue}
        onInputChange={onInputChange}
        onChange={onSelectOption}
        options={options}
        styles={styles}
        formatOptionLabel={optionCustomRenderer}
        noOptionsMessage={emptyListMessage}
        isLoading={isLoading}
        value={selectedOrganization}
        {...rest}
        placeholder="Start typing to find the organization"
        isClearable={true}
        style={style}
      />
    </div>
  );
}

export default memo(OrganizationSearch);
