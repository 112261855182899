import React, {memo} from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {IAdminPaymentDetailsHorse} from 'Admin/AdminAssociations/models/IAdminPaymentDetails';

const Root = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
`;

const Text = styled.div`
  margin-bottom: 4px;
`;

const HorseName = styled(Text)`
  font-size: ${Typography.size.size14};
  line-height: 18px;
  letter-spacing: 0.2px;
  color: ${Theme.color.black};
`;

const OrderId = styled(Text)`
  font-size: ${Typography.size.size12};
  line-height: 12px;
  letter-spacing: 0.5px;
  color: ${Theme.color.gray};
`;

const PaidStatus = styled(OrderId)<{isPaid: boolean}>`
  text-transform: uppercase;
  ${(props) => props.isPaid && 'color: ' + ColorPalette.green3}
`;

interface IProps {
  horse: IAdminPaymentDetailsHorse;
}

function ConnectedHorseCheckbox(props: IProps) {
  const {horse} = props;

  const status = horse.isPaid ? 'paid' : 'waiting';

  return (
    <Root className="d-flex">
      <div>
        <HorseName>{horse.horse.name}</HorseName>
        <OrderId>OID: {horse.id}</OrderId>
        <PaidStatus isPaid={horse.isPaid}>{status}</PaidStatus>
      </div>
    </Root>
  );
}

export default memo(ConnectedHorseCheckbox);
