import {Reducer} from 'redux';

import {IUserState, UserActions, getUserActionTypes} from '../types';

const initialState: IUserState['data'] = {
  user: null
};

export const dataUserReducer: Reducer<IUserState['data'], UserActions> = (
  state = initialState,
  action: UserActions
) => {
  switch (action.type) {
    case getUserActionTypes.SUCCESS: {
      return {...state, user: action.payload};
    }
    default: {
      return state;
    }
  }
};
