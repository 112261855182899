import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.userPrivacySettings;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectUserPrivacySettings(state: IAppState) {
  return selectState(state).data.userPrivacySettings;
}
