import {
  ICommunication,
  MakeCommunicationActions,
  MakeResetCommunicationActions,
} from 'Common/store/utils/communication/types';

import {makeCommunicationActionType, makeResetCommunicationActionType} from 'Common/store/utils/communication/index';
import {IPaymentDetails} from 'Admin/AdminDashboard/models/IPaymentDetails';

const STATE_NAME = 'paymentDetailsUpload';

export interface IPaymentDetailsUploadState {
  data: {
    paymentDetails: IPaymentDetails | null;
  };
  communications: {
    paymentDetailsLoading: ICommunication;
    paymentDetailsUpdating: ICommunication;
  };
}

export const getPaymentDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_PAYMENT_DETAILS');
export type getPaymentDetailsActions = MakeCommunicationActions<
  typeof getPaymentDetailsActionTypes,
  {success: IPaymentDetails}
>;

export const updatePaymentDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_PAYMENT_DETAILS');
export type updatePaymentDetailsActions = MakeCommunicationActions<typeof updatePaymentDetailsActionTypes, {}>;

export const resetPaymentDetailsActionTypes = makeResetCommunicationActionType(STATE_NAME, 'PAYMENT_DETAILS');
export type resetPaymentDetailsActions = MakeResetCommunicationActions<typeof resetPaymentDetailsActionTypes>;

export type PaymentDetailsUploadActions =
  | getPaymentDetailsActions
  | updatePaymentDetailsActions
  | resetPaymentDetailsActions;
