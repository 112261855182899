import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IPaymentHorse} from 'BusinessPortal/models/order/payment/IPaymentHorse';
import {ICreatedOrder} from 'BusinessPortal/models/order/createOrder/ICreatedOrder';
import {IOrderSummary} from 'BusinessPortal/models/order/createOrder/IOrderSummary';
import {convertOrderSummaryToClient} from 'BusinessPortal/services/converters/createOrder/summary';
import {withPrice} from 'Common/helpers/withPrice';

const getOrderPaymentToken = async (orderId: number): Promise<string> => {
  const res = await axiosWrapper.post<IObjectResponse<ICreatedOrder>>(`/AssociationOrderPayment`, {
    orderIds: [orderId],
  });
  return res.data.data.token;
};

const getPaymentHorses = async (token: string): Promise<IPaymentHorse[]> => {
  const res = await axiosWrapper.get<IListResponse<IPaymentHorse>>(`/AssociationPaymentHorses/${token}`);
  return res.data.data.map(withPrice);
};

const getPaymentSummary = async (token: string): Promise<IOrderSummary> => {
  const res = await axiosWrapper.get<IObjectResponse<IOrderSummary>>(`/AssociationOrdersPaymentSummary/${token}`);
  return convertOrderSummaryToClient(res.data.data);
};

export default {
  getOrderPaymentToken,
  getPaymentHorses,
  getPaymentSummary,
};
