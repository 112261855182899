import * as React from 'react';
import styled from 'styled-components';
import ColorPalette from 'Common/constants/ColorPalette';

const ProgressBarContainer = styled.div<{borderRadius: number}>`
  height: 8px;
  border-radius: ${props => props.borderRadius}px;
`;

const ProgressBarProgress = styled.div<{borderRadius: number}>`
  background-color: ${ColorPalette.red2};
  border-radius: ${props => props.borderRadius}px;
`;

export interface IProgressBarProps {
  currentValue: number;
  minValue: number;
  maxValue: number;

  borderRadius: number;
  styleContainer?: React.CSSProperties;
  styleProgress?: React.CSSProperties;
}

const ProgressBar = (props: IProgressBarProps) => {
  return (
    <ProgressBarContainer borderRadius={props.borderRadius} className="progress" style={props.styleContainer}>
      <ProgressBarProgress
        borderRadius={props.borderRadius}
        style={props.styleProgress}
        className={`progress-bar w-${(props.currentValue / props.maxValue) * 100}`}
        role="progressbar"
        aria-valuenow={props.currentValue}
        aria-valuemin={props.minValue}
        aria-valuemax={props.maxValue}
      />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
