import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {IconName} from 'Icon/components/Icon';
import {Details, OrderHistory} from 'Admin/AdminDashboard/components/shared/index';
import RecordDetails from 'Admin/AdminDashboard/components/shared/RecordDetails/RecordDetails';
import PersonalHorses from 'Admin/AdminDashboard/components/Users/PersonalHorses/PersonalHorses';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminUsers/users/index';
import {
  actions as userPermissionActions,
  selectors as userPermissionSelectors,
} from 'Admin/AdminDashboard/store/adminUsers/userPermission/index';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import ColorPalette from 'Common/constants/ColorPalette';

import {UpdateOrderContext} from 'Admin/AdminDashboard/components/Orders/OrderHorses/useOnUpdateOrder';
import {CellSeveral} from 'Common/components/StyledComponents/StyledComponents';
import {IOwnerShort} from 'Admin/AdminDashboard/models/IOwnerShort';
import {convertToClientDate} from 'Common/helpers/DateHelper';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

const LastPayment = styled.span`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.gray};
`;

const Column = RecordDetails.Column;

interface IProps {
  userId: number;
  onEditUser(id: number): void;
  onChange(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IProps & IConnected;

function UserDetails(props: Props) {
  const {
    userId,
    onEditUser,
    getUserById,
    userDetails,
    userDetailsLoading,
    getUserPermissions,
    userPermissions,
    userPermissionsLoading,
    onChange,
    resetUserDetails,
  } = props;

  const detailsMenu = {
    items: [{label: 'Edit user', value: 'update', icon: {name: IconName.Edit, color: ColorPalette.red7}}],
    onSelect: () => onEditUser(id),
  };

  const reloadUser = useCallback(() => {
    getUserById(userId);
    getUserPermissions(userId);
  }, [userId, getUserById, getUserPermissions]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetUserDetails, []);

  useEffect(() => {
    reloadUser();
  }, [reloadUser]);

  if (userDetailsLoading.isRequesting || userPermissionsLoading.isRequesting) {
    return <Loading />;
  }

  if (userDetailsLoading.error) {
    return <span>Error on user details loading</span>;
  }

  if (!userDetails || userDetails.id !== userId) {
    return null;
  }

  const {email, horses, phone, mobile, address, id, orders, subscription} = userDetails;
  const {permissions} = userPermissions;

  const details = [
    {title: 'Email', value: email?.length > 0 ? email : <CellSeveral>No email</CellSeveral>},
    {title: 'Phone', value: phone?.length > 0 ? phone : <CellSeveral>No phone</CellSeveral>},
    {title: 'Mobile Phone', value: mobile?.length > 0 ? mobile : <CellSeveral>No mobile phone</CellSeveral>},
    {title: 'Address', value: address?.length > 0 ? address : <CellSeveral>No address</CellSeveral>},
    {
      title: 'Permissions',
      value:
        permissions?.length > 0 ? (
          permissions?.map((i) => i.code).join(', ')
        ) : (
          <CellSeveral>No permissions allowed</CellSeveral>
        ),
    },
    {
      title: 'Subscription',
      value: subscription ? (
        <>
          {subscription?.name}
          <LastPayment> (date of purchase: {convertToClientDate(subscription?.dateLastPayment || '')})</LastPayment>
        </>
      ) : (
        <CellSeveral>No subscriptions</CellSeveral>
      ),
    },
  ];

  const horseOwner: IOwnerShort = {
    ...userDetails,
    avatar: null,
  };

  return (
    <UpdateOrderContext.Provider value={{onUpdateOrder: onChange}}>
      <RecordDetails>
        <Column label="Details" width="22%" menu={detailsMenu}>
          <Details records={details} />
        </Column>
        <Column label="Horses" width="35%">
          <PersonalHorses owner={horseOwner} horses={horses} onSuccess={reloadUser} />
        </Column>
        <Column label="Order History" width="35%">
          <OrderHistory userId={userId} orders={orders} onSuccess={reloadUser} />
        </Column>
      </RecordDetails>
    </UpdateOrderContext.Provider>
  );
}

const mapStateToProps = (state: IAppState) => ({
  userDetailsLoading: selectors.selectCommunication(state, 'userDetailsLoading'),
  userDetails: selectors.selectUserDetails(state),
  userPermissions: userPermissionSelectors.selectUserPermissions(state),
  userPermissionsLoading: userPermissionSelectors.selectCommunication(state, 'userPermissionsLoading'),
});

const mapDispatchToProps = {
  getUserById: actions.getUserById,
  getUserPermissions: userPermissionActions.getUserPermissions,
  resetUserDetails: actions.resetUserDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(UserDetails));
