import {combineReducers} from 'redux';

import {downloadRulesBackupActionTypes, IAdminRulesBackupState, RulesBackupActions} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationRulesBackupReducer = combineReducers<
  IAdminRulesBackupState['communications'],
  RulesBackupActions
>({
  adminRulesBackupDownloading: makeCommunicationReducerFromEnum(downloadRulesBackupActionTypes)
});
