import React, {memo, useMemo} from 'react';
import styled from 'styled-components';

import {IPropsImage} from 'Image/components/imageUploaderPreview/common/types';
import {ImagePreviewBlurred, ImagePreviewLayout} from 'Image/components/imageUploaderPreview/common/styles';
import {Actions} from 'Image/components/imageUploaderPreview/ImagePreviewShort/ImagePreviewActions';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {breakpoints} from 'Common/constants/Breakpoints';
import {IImage} from 'Image/models/IImage';
import {Nullable} from 'Common/types';
import Icon, {IconName} from 'Icon/components/Icon';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';

const PreviewLayout = styled.div<IPropsImage & {isRound?: boolean}>`
  width: ${prop => prop.width};
  height: ${prop => prop.height};
  border-radius: ${({isRound}) => (isRound ? 50 : 0)}%;

  @media ${breakpoints.md} {
    overflow: hidden;
  }
`;

const BrokenImageText = styled.div<{width: string}>`
  width: ${prop => prop.width};
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size10};
  line-height: 1px;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${ColorPalette.red9};
  margin-top: 8px;
`;

interface IProps {
  isRound?: boolean;
  actionButtonsContainerHeight?: string;
  height: string;
  width: string;
  minSize?: number;
  maxSize?: number;
  value: Nullable<IImage>;
  showDownloadButton?: boolean;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  handleDownloadImage(): void;
  handleOnDeleteImage(): void;
  handleSelectFiles(acceptedFiles: File[]): void;
  handleRejectFiles(acceptedFiles: File[]): void;
  isUrlExists: boolean;
  isShowBlurredBackground?: boolean;
}

const ImagePreview = (props: IProps) => {
  const {width, height, value, isRound, actionButtonsContainerHeight, isShowBlurredBackground = true, ...other} = props;
  const {isDesktop} = useMediaQuery();

  const renderImage = useMemo(() => {
    if (!props.isUrlExists) {
      return (
        <div className="d-flex w-100 h-100 position-absolute justify-content-center align-items-center">
          <Icon name={IconName.BrokenImage} size={67} />
        </div>
      );
    }

    return (
      <>
        {value?.url && isShowBlurredBackground && (
          <ImagePreviewBlurred className="position-absolute" height={height} width={width} src={value.url} />
        )}
        <ImagePreviewLayout className="position-absolute" height={height} width={width} url={value?.url} />
      </>
    );
  }, [height, isShowBlurredBackground, props.isUrlExists, value, width]);

  return (
    <>
      <PreviewLayout isRound={isRound} className="position-relative" height={height} width={width}>
        {renderImage}
        <Actions
          isDesktop={isDesktop}
          {...other}
          height={actionButtonsContainerHeight || '40px'}
          showDownloadButton={props.showDownloadButton && props.isUrlExists}
        />
      </PreviewLayout>
      {!props.isUrlExists && <BrokenImageText width={width}>Image URL is broken</BrokenImageText>}
    </>
  );
};

export default memo(ImagePreview);
export type ImagePreviewProps = IProps;
