import React from 'react';

import {IAdminActionsProps} from 'Admin/AdminDashboard/helpers/hooks/common';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';

import {useUpdateUserModal} from './parts/useUpdateUserModal';
import {useUpdateUserPermissionsModal} from 'Admin/shared/helpers/hooks/useUserActions/useUpdateUserPermissionsModal';
import {useChangeUserPasswordModal} from './parts/useChangeUserPasswordModal';
import {useChangeUserEmailModal} from './parts/useChangeUserEmailModal';
import {useSendVerificationModal} from './parts/useSendVerificationModal';
import {useDeleteModal} from 'Common/helpers/hooks/useDeleteModal';
import {useBindActions} from 'Common/helpers/hooks/useBindActions';
import {actions as adminUsersActions} from 'Admin/AdminDashboard/store/adminUsers/users/index';
import {useCreateUserModal} from './parts/useCreateUserModal';

type ActionHandler = Pick<IAdminActionsProps, 'communication'>;
interface IUserActionsPartsProps {
  createHorseHandler: ActionHandler;
  updateUserInfoHandler: ActionHandler;
  updateUserPermissionsHandler: ActionHandler;
  deleteUserHandler: ActionHandler;
  sendingVerificationHandler: ActionHandler;
}

interface IProps {
  data: IUserActionsPartsProps;
  onSuccess(): void;
}

export function useUserActions(props: IProps) {
  const {
    onSuccess,
    data: {
      createHorseHandler,
      deleteUserHandler,
      updateUserInfoHandler,
      updateUserPermissionsHandler,
      sendingVerificationHandler,
    },
  } = props;

  const {deleteUser} = useBindActions({deleteUser: adminUsersActions.deleteUserById});

  const {openDeleteModal, deleteModal} = useDeleteModal({
    confirmDescription: 'Are you sure you want to remove the user?',
    onSuccess,
    deleteAction: deleteUser,
  });

  const {openCreateUserModal, createUserModal} = useCreateUserModal({onSuccess});
  const {openUpdateUserModal, updateUserModal} = useUpdateUserModal({onSuccess});
  const {openModalUserPermissionsModal, updateUserPermissionsModal} = useUpdateUserPermissionsModal({
    onSuccess,
  });
  const {openChangeUserPasswordModal, changeUserPasswordModal} = useChangeUserPasswordModal({onSuccess});
  const {openChangeUserEmailModal, changeUserEmailModal} = useChangeUserEmailModal({onSuccess});

  const {openSendVerificationModal, sendVerificationModals} = useSendVerificationModal({
    communication: sendingVerificationHandler.communication,
  });

  useCommunicationToToast(deleteUserHandler.communication, 'User successfully deleted', undefined, 1);
  useCommunicationToToast(
    updateUserPermissionsHandler.communication,
    'User permissions successfully updated',
    undefined,
    2
  );
  useCommunicationToToast(updateUserInfoHandler.communication, 'User profile successfully updated', undefined, 3);
  useCommunicationToToast(createHorseHandler.communication, 'User successfully created');

  const userActionsModal = (
    <>
      {createUserModal}
      {updateUserModal}
      {updateUserPermissionsModal}
      {changeUserEmailModal}
      {changeUserPasswordModal}
      {deleteModal}
      {sendVerificationModals}
    </>
  );

  return {
    userActionsModal,
    openCreateUserModal,
    openUpdateUserModal,
    openModalUserPermissionsModal,
    openChangeUserPasswordModal,
    openChangeUserEmailModal,
    openDeleteModal,
    openSendVerificationModal,
    deleteModal,
    createUserModal,
    updateUserModal,
    updateUserPermissionsModal,
    changeUserPasswordModal,
    changeUserEmailModal,
    sendVerificationModals,
  };
}
