import React, {memo} from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

import ColoredIcon, {IColoredIconProps} from 'Icon/components/ColoredIcon';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const Root = styled.div`
  flex-direction: column;

  @media ${breakpoints.md} {
    flex-direction: row;
  }
`;

const Content = styled.div`
  margin-top: 16px;

  @media ${breakpoints.sm} {
    margin-top: 0;
  }

  @media ${breakpoints.md} {
    margin-left: 32px;
  }
`;

const Name = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${Theme.color.black};
  margin-left: 16px;

  @media ${breakpoints.sm} {
    margin-bottom: 16px;
  }

  @media ${breakpoints.md} {
    margin-left: 0;
  }
`;

const Description = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.gray};
  text-align: justify;
  width: 100%;

  @media ${breakpoints.sm} {
    width: 90%;
  }

  @media ${breakpoints.md} {
    width: 75%;
  }
`;

const Child = styled.div`
  margin-top: 24px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
`;

interface ISummaryTopicProps {
  name: string;
  description: string;
  icon: IColoredIconProps;
}

function Topic(props: React.PropsWithChildren<ISummaryTopicProps>) {
  const {description, icon, name, children} = props;

  const {isMobile, isTablet} = useMediaQuery();

  const isMobileView = isMobile || isTablet;
  const htmlContent = parse(DOMPurify.sanitize(description));

  return (
    <Root className="d-flex">
      <div className="d-flex">
        <IconWrapper>
          <ColoredIcon {...icon} />
        </IconWrapper>
        {isMobileView && <Name className="align-self-center">{name}</Name>}
      </div>
      <Content className="d-flex flex-column">
        {!isMobileView && <Name>{name}</Name>}
        <Description>{htmlContent}</Description>
        {children && <Child>{children}</Child>}
      </Content>
    </Root>
  );
}

export default memo(Topic);
