import {IModule} from 'redux-dynamic-modules';

import {IHorseTransferState} from './types';
import {reducer} from './reducer';

export interface IHorseTransferModuleState {
  horseTransfer: IHorseTransferState;
}

export const HorseTransferModule: IModule<IHorseTransferModuleState> = {
  id: 'horseTransfer',
  reducerMap: {
    horseTransfer: reducer,
  },
};
