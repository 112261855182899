import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {RadiobuttonField} from 'Common/components/FormFields';
import {PaymentMethod, PaymentMethodAdditional} from 'Common/constants/PaymentMethod';
import Icon, {IconName} from 'Icon/components/Icon';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import {BaseStepLayoutChildrenProps} from 'Order/components/CreateOrder/parts/BaseStepLayout';
import {StepLabel, StepValue} from 'Order/components/CreateOrder/common/styled';

const Description = styled.div`
  margin-top: 10px;
  color: ${Theme.color.gray};
  font-weight: ${Typography.weight.normal400};
  font-style: normal;
  line-height: 24px;
`;

const ContactMethodField = styled.div`
  margin-top: 24px;
  padding-right: 10px;

  &:first-child {
    margin-top: 4px;
  }

  &:last-child {
    margin-top: 16px;
  }
`;

const PriorityContactMethodField = styled(ContactMethodField)`
  font-weight: ${Typography.weight.medium500};
`;

const PriorityContainer = styled.div`
  border-left: 4px solid ${ColorPalette.red7};
  padding: 8px 16px;
  margin-top: 8px;
`;

const CardIcon = styled(Icon)`
  margin-right: 10px;
  width: 30px;
  height: 19px;
`;

interface IProps {
  excludeInvoice?: boolean;
  type?: PaymentMethod | PaymentMethodAdditional;
}

type AllProps = IProps & BaseStepLayoutChildrenProps;

function Payment(props: AllProps) {
  const {type, isEdit = true, excludeInvoice} = props;

  if (!isEdit) {
    return (
      <div className="d-flex">
        <div className="d-flex flex-column" style={{marginRight: 100}}>
          <StepLabel>Credit or debit card</StepLabel>
          <StepValue>{type === PaymentMethod.Stripe ? 'Debit or credit card (using Stripe)' : type}</StepValue>
        </div>
      </div>
    );
  }

  return (
    <>
      <PriorityContainer>
        <PriorityContactMethodField>
          <RadiobuttonField
            name="paymentMethod"
            value={PaymentMethod.Stripe}
            label={
              <span>
                Debit or credit card (using <strong>Stripe</strong>)
              </span>
            }
          />
          <div className="d-flex">
            <CardIcon name={IconName.CardMastercard} />
            <CardIcon name={IconName.CardMaestro} />
            <CardIcon name={IconName.CardVisa} />
            <CardIcon name={IconName.CardVisaElectron} />
            <CardIcon name={IconName.CardAmericanExpress} />
            <CardIcon name={IconName.CardUnionpay} />
          </div>
          <Description>Transfer money using credit card. Visa, Master Card, Maestro, American Express</Description>
        </PriorityContactMethodField>

        {!excludeInvoice && (
          <PriorityContactMethodField>
            <RadiobuttonField name="paymentMethod" value={PaymentMethod.Invoice} label="Invoice" />
            <Description>Samples will not be processed until payment is complete.</Description>
          </PriorityContactMethodField>
        )}
      </PriorityContainer>
    </>
  );
}

export default memo(Payment);
export type PaymentProps = AllProps;
