import React, {memo} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {ISubscriptionAdmin} from 'Admin/AdminDashboard/models/ISubscriptionAdmin';
import {AdminSubscriptionsModule} from 'Admin/AdminDashboard/store/adminSubscriptions/adminSubscriptionsModule';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminSubscriptions';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import Loading from 'Loading/components/Loading';
import {Cell, Table} from 'Common/components/Table/Table';
import {AvatarCell} from 'Admin/AdminDashboard/components/shared';
import {USER_SUBSCRIPTION_MARKED_ICON} from 'Common/constants/avatarMarkedIcon';
import withDate from 'Common/helpers/withDate';
import {useUpdateUserModal} from 'Admin/AdminDashboard/helpers/hooks/useUserActions/parts/useUpdateUserModal';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import Pagination from 'Common/components/Controls/Pagination/Pagination';
import {HAS_RULE_ICON, NO_RULE_ICON} from 'Admin/AdminPhenotypes/components/share/StyledComponents';
import SubscriptionActions from './SubscriptionActions';
import {useSubscriptionActions} from 'Admin/AdminDashboard/helpers/hooks/useSubscriptionActions';

const TemplateTable = styled.div`
  margin-top: 50px;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

function Subscriptions(props: AllProps) {
  const {
    getSubscriptions,
    overwriteFromStripe,
    pagination,
    subscriptions,
    subscriptionsLoading,
    subscriptionsOverwriting,
    cancelSubscriptions,
    subscriptionCanceling,
  } = props;

  const {changeSorting, searchBar, sorting, reloadItems, handlePageSelect, handleCountPerPage, deleteModal} =
    useCommonAdminPageData<ISubscriptionAdmin>({
      getItems: getSubscriptions,
      searchBarPlaceholder: 'Search for subscriptions by any keyword',
    });

  const {openUpdateUserModal, updateUserModal} = useUpdateUserModal({onSuccess: reloadItems});
  const {openSyncSubscriptionsModal, subscriptionActionsModal, openCancelSubscriptionModal} = useSubscriptionActions({
    onSuccess: reloadItems,
    data: {
      syncSubscriptionHandler: {action: overwriteFromStripe, communication: subscriptionsOverwriting},
      cancelSubscriptionHandler: {action: cancelSubscriptions, communication: subscriptionCanceling},
    },
  });

  const isRequesting = subscriptionsLoading.isRequesting;
  const isShowPagination = subscriptions.length > 0;

  return (
    <AdminPageLayout>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
      </div>

      {updateUserModal}
      {deleteModal}
      {subscriptionActionsModal}

      <TemplateTable className="position-relative">
        {isRequesting && <Loading />}
        <Table<ISubscriptionAdmin> data={subscriptions} rowKey="id" sorting={sorting} onChangeSorting={changeSorting}>
          <Cell<ISubscriptionAdmin> header="ID" dataKey="id" render={({id}) => `#${id}`} width={100} />
          <Cell<ISubscriptionAdmin>
            header="Product"
            dataKey="product"
            render={({product}) => product.name}
            width="15%"
          />
          <Cell<ISubscriptionAdmin>
            header="User"
            dataKey="user"
            render={({user}) => (
              <AvatarCell
                type="owner"
                avatarUrl={user.avatar?.url}
                profileUrl={`/user/user-profile/${user.id}`}
                label={user.name}
                onLabelClick={() => openUpdateUserModal(user.id)}
                markedIcon={USER_SUBSCRIPTION_MARKED_ICON}
              />
            )}
            width="20%"
          />
          <Cell<ISubscriptionAdmin>
            header="Started at"
            dataKey="startDate"
            width="10%"
            render={({startDate}) => `${withDate(startDate)}`}
          />
          <Cell<ISubscriptionAdmin>
            header="Ended at"
            dataKey="currentPeriodEnd"
            width="10%"
            render={({currentPeriodEnd}) => `${withDate(currentPeriodEnd)}`}
          />
          <Cell<ISubscriptionAdmin>
            header="Cancel at end"
            dataKey="cancelAtPeriodEnd"
            render={({cancelAtPeriodEnd}) => (cancelAtPeriodEnd ? HAS_RULE_ICON : NO_RULE_ICON)}
            width="10%"
          />
          <Cell<ISubscriptionAdmin>
            header="Status"
            dataKey="stripeSubscriptionStatus"
            render={({stripeSubscriptionStatus}) => stripeSubscriptionStatus}
            width="10%"
          />
          <Cell<ISubscriptionAdmin>
            header="Stripe subscription id"
            dataKey="stripeSubscriptionId"
            width="15%"
            render={({stripeSubscriptionId}) => stripeSubscriptionId}
          />

          <Cell<ISubscriptionAdmin>
            header="Actions"
            align={CellAlign.Right}
            width={100}
            render={(subscription) => (
              <SubscriptionActions
                id={subscription.id}
                userId={subscription.user.id}
                isActive={!subscription.cancelAtPeriodEnd}
                cancelSubscriptionHandler={openCancelSubscriptionModal}
                syncStripeSubscriptionHandler={openSyncSubscriptionsModal}
              />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </TemplateTable>
    </AdminPageLayout>
  );
}

const mapStateToProps = (state: IAppState) => ({
  subscriptions: selectors.selectSubscriptions(state),
  subscriptionsLoading: selectors.selectCommunication(state, 'subscriptionsLoading'),
  subscriptionsOverwriting: selectors.selectCommunication(state, 'subscriptionsOverwriting'),
  subscriptionCanceling: selectors.selectCommunication(state, 'subscriptionCanceling'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getSubscriptions: actions.getSubscriptions,
  overwriteFromStripe: actions.overwriteFromStripe,
  cancelSubscriptions: actions.cancelSubscriptions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(Subscriptions));

export default withDynamicModules(Connected, AdminSubscriptionsModule);
