import styled from 'styled-components';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';

export const SummaryTitle = styled.h3`
  font-family: ${Theme.font.secondary};
  font-size: ${Typography.size.size25};
  font-weight: ${Typography.weight.bold700};
  text-align: center;
  margin-bottom: 40px;
`;

export const Description = styled.div`
  max-width: 1024px;
  display: flex;
  align-self: center;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  color: ${ColorPalette.blue3};
`;

export const AncestryReportSection = styled.div<{withoutPaddings?: boolean}>`
  ${(props) => (!!props.withoutPaddings ? ' padding: 0;' : 'padding: 20px 0;')};
`;
