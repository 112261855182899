import {VisitorType} from 'Common/constants/VisitorType';
import VisitorTypeService from './visitorType/VisitorTypeService';

type UserVisitorType = VisitorType.User | VisitorType.AssociationEmployee;
export type ApiByType = Record<UserVisitorType, string>;
export type ParamsByType = Record<UserVisitorType, {}>;

export const getApiByVisitorType = <T extends VisitorType>(apis: Record<T, string>) => {
  const typeFromStorage = VisitorTypeService.getVisitorType();
  return apis[typeFromStorage];
};

export const getApiParamsByVisitorType = <T extends VisitorType>(apis: Record<T, {}>) => {
  const typeFromStorage = VisitorTypeService.getVisitorType();
  const apiParams = apis[typeFromStorage];
  return apiParams ? {params: apiParams} : undefined;
};
