import {IModule} from 'redux-dynamic-modules';
import {createLogger} from 'redux-logger';

export const DevModule: IModule<{}> = {
  id: 'development',
  middlewares: [
    createLogger({
      level: 'info',
      collapsed: true
    })
  ]
};
