import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.adminOrganizations;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectAdminOrganizations(state: IAppState) {
  return selectState(state).data.adminOrganizations;
}

export function selectAdminOrganization(state: IAppState) {
  return selectState(state).data.adminOrganization;
}

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectAdminOrganizationAvatar(state: IAppState) {
  return selectState(state).data.adminOrganizationAvatar;
}
