import {IModule} from 'redux-dynamic-modules';

import {resetPasswordReducer} from './reducer';
import {IResetPasswordState} from 'ResetPassword/store/types';

export interface IResetPasswordModuleState {
  resetPassword: IResetPasswordState;
}

export const ResetPasswordModule: IModule<IResetPasswordModuleState> = {
  id: 'resetPassword',
  reducerMap: {
    resetPassword: resetPasswordReducer
  }
};
