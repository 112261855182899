import React, {memo} from 'react';
import styled from 'styled-components';

import {DropDownMenu} from 'Common/components/DropDown/DropDownMenu';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {IMenuItem} from 'Common/components/DropDown/common/types';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IToggleChildrenProps, RenderToggle} from 'Common/components/DropDown';

const Root = styled.div`
  margin-left: auto;
`;

const MoreIcon = styled(ColoredIcon)`
  cursor: pointer;
`;

interface IProps {
  menuItems: IMenuItem[];
  onSelectHandler(value: string): void;
  children?: RenderToggle;
}

const AdminActions = (props: IProps) => {
  const {menuItems, onSelectHandler, children} = props;

  const defaultChildren = ({isOpen}: IToggleChildrenProps) => (
    <MoreIcon
      name={IconName.More}
      color={isOpen ? ColorPalette.black1 : ''}
      hoverColor={ColorPalette.black1}
      fill={true}
      stroke={false}
    />
  );

  return (
    <Root className="d-flex align-items-center justify-content-end">
      {menuItems.length !== 0 && (
        <DropDownMenu items={menuItems} onSelect={onSelectHandler} alignRight={true}>
          {children || defaultChildren}
        </DropDownMenu>
      )}
    </Root>
  );
};

export default memo(AdminActions);
