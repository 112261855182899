import React, {memo, useMemo} from 'react';

import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {IHorseAdmin} from 'Admin/AdminDashboard/models/Horse/IHorseAdmin';
import {IMenuItem} from 'Common/components/DropDown/common/types';
import {copyToClipboard} from 'Common/helpers/copyToClipboard';
import {useToast} from 'Common/helpers/hooks/useToast';
import AdminActions from 'Admin/AdminDashboard/components/shared/AdminActions/AdminActions';
import {getBaseUrl} from 'Common/helpers/getBaseUrl';

const iconProps = {color: ColorPalette.red7};

const commonMenuItems: IMenuItem[] = [
  {value: 'copyLink', label: 'Copy profile link', icon: {name: IconName.FileCopy, ...iconProps}},
  {value: 'editHorse', label: 'Edit horse', icon: {name: IconName.Edit, ...iconProps}},
  {value: 'editSampleId', label: 'Edit sample ID', icon: {name: IconName.PenOutline, ...iconProps}},
  {
    value: 'editHorseDataVisibility',
    label: 'Edit horse data visibility',
    icon: {name: IconName.EyeShow, ...iconProps},
  },
  {value: 'changeOwner', label: 'Change owner', icon: {name: IconName.Exchange, ...iconProps}},
  {
    value: 'openLiveReport',
    label: 'Open live report',
    icon: {name: IconName.Notebook, ...iconProps, fill: false, stroke: true},
  },
  {value: 'archiveHorse', label: 'Archive horse', icon: {name: IconName.Archive, ...iconProps}, divided: true},
  {value: 'unarchiveHorse', label: 'Unarchive horse', icon: {name: IconName.UnArchive, ...iconProps}},
];

interface IExternalProps {
  horse: IHorseAdmin;
  ownerName: string;
  editHandler: (horseId: number) => void;
  archiveHandler: (horseId: number, isArchived: boolean) => void;
  changeOwnerHandler: (horseId: number, name: string, currentOwnerName: string) => void;
  openLiveReportHandler: (horseId: number) => void;
  editSampleIdHandler: (horseId: number, id?: number) => void;
  editHorseDataVisibilityHandler: (
    horseId: number,
    name: string,
    isGeneticDataHidden: boolean,
    isAncestryReportHidden: boolean
  ) => void;
}

type AllProps = IExternalProps;

const HorseActions = (props: AllProps) => {
  const {id, name, isArchived, isGeneticDataHidden, sample, isAncestryReportHidden} = props.horse;
  const {
    editHandler,
    archiveHandler,
    changeOwnerHandler,
    openLiveReportHandler,
    editSampleIdHandler,
    editHorseDataVisibilityHandler,
  } = props;

  const {addToast} = useToast();

  const mapAvailableByMenuItem = useMemo(
    () => ({
      copyLink: !isArchived,
      editHorse: !isArchived,
      archiveHorse: !isArchived,
      unarchiveHorse: isArchived,
      changeOwner: !isArchived,
      openLiveReport: !isArchived,
      editSampleId: !isArchived,
      editHorseDataVisibility: !isArchived,
    }),
    [isArchived]
  );

  const copyProfileLinkHandler = (horseId: number) => {
    copyToClipboard(`${getBaseUrl()}/horse/${horseId}`);
    addToast('Horse profile link has been copied to your clipboard');
  };

  const actionsHandler = {
    copyLink: () => copyProfileLinkHandler(id),
    editHorse: () => editHandler(id),
    changeOwner: () => changeOwnerHandler(id, name, props.ownerName),
    archiveHorse: () => archiveHandler(id, !isArchived),
    unarchiveHorse: () => archiveHandler(id, !isArchived),
    openLiveReport: () => openLiveReportHandler(id),
    editSampleId: () => editSampleIdHandler(id, sample?.id),
    editHorseDataVisibility: () =>
      editHorseDataVisibilityHandler(id, name, isGeneticDataHidden, isAncestryReportHidden),
  };

  const onSelectHandler = (type: keyof typeof actionsHandler) => actionsHandler[type]();

  const shownMenuItems = useMemo(
    () =>
      commonMenuItems
        .map((item) => ({...item, availabilityKey: mapAvailableByMenuItem[item.value]}))
        .filter((item) => item.availabilityKey),
    [mapAvailableByMenuItem]
  );

  return <AdminActions menuItems={shownMenuItems} onSelectHandler={onSelectHandler} />;
};

export default memo(HorseActions);
export type HorseActionsProps = IExternalProps;
