import React from 'react';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {FormikProps} from 'formik/dist/types';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {IFormValues, initialValue, validationSchema} from './validation';
import {actions, selectors} from 'BusinessPortal/store/employeeProfile';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import PasswordField from 'Common/components/FormFields/PasswordField';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';

type IExternalProps = IEditableSection<null>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const ChangePasswordEdit = (props: AllProps) => {
  const {isValid, isSubmitting} = props;
  const {onSuccess, passwordUpdating} = props;

  const isRequesting = passwordUpdating.isRequesting;

  useOnSuccessCommunication(passwordUpdating, () => onSuccess && onSuccess());
  useErrorCommunicationToToast(passwordUpdating);

  if (isRequesting) {
    return <Loading />;
  }

  return (
    <Form>
      <PasswordField name="newPassword" label="New password" placeholder="New password" isRequired={true} />
      <PasswordField
        name="confirmPassword"
        label="Repeat new password"
        placeholder="Repeat new password"
        isRequired={true}
      />
      <PrimaryButton className="w-100" size="medium" type="submit" disabled={!isValid} isLoading={isSubmitting}>
        Save changes
      </PrimaryButton>
    </Form>
  );
};

const GeneralInformationEditFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValue,
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {updateEmployeePassword} = formikBag.props;
    await updateEmployeePassword({...values});
  },
  enableReinitialize: true,
})(ChangePasswordEdit);

const mapStateToProps = (state: IAppState) => ({
  passwordUpdating: selectors.selectCommunication(state, 'passwordUpdating'),
});

const mapDispatchToProps = {
  updateEmployeePassword: actions.updateEmployeePassword,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(GeneralInformationEditFormik);
