import React, {useCallback, useState} from 'react';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import NotificationForm from 'Admin/AdminAdvancedDashboard/components/Administrators/NotificationForm/NotificationForm';

interface IProps {
  onSuccess(): void;
}

export function useUpdateUserNotificationModal(props: IProps) {
  const {onSuccess} = props;

  const [selectedId, setSelectedId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openUpdateUserNotificationModal = useCallback(
    (userId: number) => {
      setSelectedId(userId);
      setIsModalOpen(true);
    },
    [setSelectedId]
  );
  const closeUpdateUserNotificationModal = useCallback(() => setIsModalOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeUpdateUserNotificationModal();
    onSuccess();
  }, [closeUpdateUserNotificationModal, onSuccess]);

  const updateUserNotificationModal = (
    <ModalWindow isOpen={isModalOpen && !!selectedId} onClose={closeUpdateUserNotificationModal}>
      <NotificationForm adminId={selectedId} onSuccess={handleSuccess} />
    </ModalWindow>
  );

  return {
    openUpdateUserNotificationModal,
    updateUserNotificationModal
  };
}
