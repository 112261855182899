import {combineReducers} from 'redux';

import {communicationBusinessPortalAssociationPageReducer} from './communications';
import {dataBusinessPortalAssociationPageReducer} from './data';
import {IBusinessPortalAssociationPageState} from '../types';

export const businessPortalAssociationPageReducer = combineReducers<IBusinessPortalAssociationPageState>({
  communications: communicationBusinessPortalAssociationPageReducer,
  data: dataBusinessPortalAssociationPageReducer,
});
