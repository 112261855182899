import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {IAdminChangePasswordState, changePasswordActionTypes, changePasswordActions} from '../types';

export const communicationChangePasswordReducer = combineReducers<
  IAdminChangePasswordState['communications'],
  changePasswordActions
>({
  changeUserPasswordUpdating: makeCommunicationReducerFromEnum(changePasswordActionTypes)
});
