import classNames from 'classnames';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {DynamicModuleLoader} from 'redux-dynamic-modules';
import {ThunkDispatch} from 'redux-thunk';
import styled from 'styled-components';

import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import {PREFIX_USER_PROFILE_TYPE, PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {VisitorType} from 'Common/constants/VisitorType';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {IAppState} from 'Common/store/IAppState';
import {SidebarComponent, SidebarLabel} from 'HorseProfile/components/shared/StyledComponents';
import Loading from 'Loading/components/Loading';
import {UserProfileType} from 'Shared/models/UserProfileType';
import {horseProfileOwnerVisitorDistributor, IDispatch, IState} from './distributor';
import UserHorsesList from './UserHorsesList';

const InfoWrapper = styled.div`
  margin-bottom: 16px;
  font-size: ${Typography.size.size13};
  color: ${ColorPalette.black1};
`;

const NameWrapper = styled.div`
  margin-bottom: 16px;
`;

const UserName = styled.label`
  font-family: ${Typography.family.openSans};
  font-size: ${Typography.size.size23};
  font-weight: ${Typography.weight.semiBold600};
  letter-spacing: -0.4px;
  margin-bottom: 0;
  margin-left: 16px;
`;

const ContactButton = styled(LinkButton)`
  height: 32px;
  width: 100%;
`;

interface IExternalProps {
  horseId: number;
  className?: string;
  type: VisitorType;
  isOwnHorse: boolean;
  getOwnerId(id: number): void;
}

type Props = IExternalProps & IState & IDispatch & RouteComponentProps;

const Owner = (props: Props) => {
  const {
    horseId,
    horses,
    horsesLoading,
    getOwnerHorses,
    className,
    type: visitorType,
    getHorseOwner,
    horseOwner,
    horseOwnerLoading,
    getOwnerId,
    isOwnHorse,
  } = props;
  const {isDesktop} = useMediaQuery();

  useEffect(() => {
    getHorseOwner(horseId);
    getOwnerHorses(horseId);
  }, [getHorseOwner, getOwnerHorses, horseId]);

  useEffect(() => {
    if (horseOwner?.id && horseOwner?.id !== 0) {
      getOwnerId(horseOwner.id);
    }
  }, [getOwnerId, horseOwner]);

  const isLoading = [horsesLoading, horseOwnerLoading].some((x) => x.isRequesting);

  if (!horseOwner) {
    return <div>No owner info</div>;
  }

  return (
    <SidebarComponent className={classNames('position-relative', className)} id="owner-wrapper">
      <SidebarLabel>Owner information</SidebarLabel>

      {isLoading && <Loading />}

      <InfoWrapper className="d-flex flex-column align-items-start justify-content-between">
        <NameWrapper className="d-flex overflow-hidden">
          <Avatar
            avatarSize={AvatarSize.Custom}
            customSizeAvatar={36}
            customSizeIcon={24}
            defaultAvatarProfile={DefaultAvatarProfile.User}
            url={horseOwner.avatar?.url}
          />
          <UserName>{horseOwner.name}</UserName>
        </NameWrapper>

        {!isOwnHorse && (
          <ContactButton
            size="small"
            to={`${PREFIX_VISITOR_TYPE[visitorType]}/user/${
              PREFIX_USER_PROFILE_TYPE[
                horseOwner.isGeneralUser ? UserProfileType.User : UserProfileType.AssociationUser
              ]
            }/${horseOwner.id}`}
          >
            Contact Owner
          </ContactButton>
        )}
      </InfoWrapper>
      {isDesktop &&
        (() => {
          if (!horses || horses.length === 0) {
            return <div>User has no public horses</div>;
          }
          return (
            <UserHorsesList
              userHorses={horses}
              userName={horseOwner.name}
              ownerId={horseOwner.id}
              type={visitorType}
              isGeneralUser={horseOwner.isGeneralUser}
            />
          );
        })()}
    </SidebarComponent>
  );
};

const mapStateToProps = (state: IAppState, externalProps: IExternalProps) =>
  horseProfileOwnerVisitorDistributor[externalProps.type!].state;

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppState, undefined, any>, externalProps: IExternalProps) =>
  bindActionCreators(horseProfileOwnerVisitorDistributor[externalProps.type!].dispatch as any, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(withRouter(Owner)) as unknown as (props: IExternalProps) => JSX.Element;
const Exported = (externalProps: IExternalProps) => {
  const visitorType = externalProps.type;
  const modules = horseProfileOwnerVisitorDistributor[visitorType].modules || [];

  return (
    <DynamicModuleLoader modules={[modules]}>
      <Connected {...externalProps} type={visitorType!} />
    </DynamicModuleLoader>
  );
};

export default Exported;
