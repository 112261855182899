import React, {memo} from 'react';
import styled from 'styled-components';

import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import {IUser} from 'UserProfile/models/IUser';
import {useMatchMediaQuery, useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {userProfileMinDesktopQuery} from 'UserProfile/components/TabsData/common/constants';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';
import Typography from 'Common/constants/Typography';
import Map from 'Common/components/Map/Map';
import {Nullable} from 'Common/types';
import {ILocation} from 'Common/models/ICoordinatedLocation';
import {getStringifyAddress} from 'Common/helpers/getStringifyAddress';

const coloredIconProps = {fill: true, stroke: false, color: ColorPalette.gray44};

const ContactItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 28px;

  @media ${breakpoints.sm} {
    height: 55px;
  }

  @media ${breakpoints.lg} {
    flex-direction: column;
    height: auto;
  }
`;

const LabelWrapper = styled.div`
  margin: 24px 0 16px 0;
`;

const DividerLine = styled.div`
  display: none;
  height: 1px;
  width: 100%;
  margin-left: 8px;
  background-color: ${ColorPalette.gray20};

  @media ${breakpoints.sm} {
    display: block;
  }
`;

const Label = styled.div<{minWidth: number}>`
  min-width: ${({minWidth}) => minWidth}px;
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size12};
  line-height: 24px;
  text-transform: uppercase;
  color: ${ColorPalette.gray44};
  margin-left: 10px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size16};
    margin-left: 16px;
  }
`;

const Value = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 24px;
  color: ${ColorPalette.black1};
  align-self: center;
  margin: 20px 0 0 17px;

  @media ${breakpoints.sm} {
    align-self: start;
    font-size: ${Typography.size.size16};
  }

  @media ${breakpoints.lg} {
    margin: 0;
  }
`;

interface IProps {
  user: IUser;
}

const ContactInfo = ({user}: IProps) => {
  const {email, userAddress, phone, location} = user;
  const {isMobile} = useMediaQuery();

  const isUserProfileMinDesktop = useMatchMediaQuery(userProfileMinDesktopQuery);
  const position: Nullable<ILocation> =
    location && location.latitude !== 0 && location.longitude !== 0
      ? {
          latitude: location?.latitude,
          longitude: location?.longitude,
        }
      : null;

  return (
    <>
      {email && (
        <ContactItem>
          <LabelWrapper className="d-flex align-items-center">
            <ColoredIcon
              {...coloredIconProps}
              name={IconName.Mail}
              size={isMobile ? 14 : 20}
              className="flex-shrink-0"
            />
            {isUserProfileMinDesktop && <Label minWidth={45}>EMAIL</Label>}
            {isUserProfileMinDesktop && <DividerLine />}
          </LabelWrapper>
          <Value>{email}</Value>
        </ContactItem>
      )}

      {phone && (
        <ContactItem>
          <LabelWrapper className="d-flex align-items-center">
            <ColoredIcon
              {...coloredIconProps}
              name={IconName.Phone}
              size={isMobile ? 14 : 20}
              className="flex-shrink-0"
            />
            {isUserProfileMinDesktop && <Label minWidth={115}>PHONE NUMBER</Label>}
            {isUserProfileMinDesktop && <DividerLine />}
          </LabelWrapper>
          <Value>{phone}</Value>
        </ContactItem>
      )}

      {userAddress && (
        <ContactItem>
          <LabelWrapper className="d-flex align-items-center">
            <ColoredIcon
              {...coloredIconProps}
              name={IconName.MapMarker}
              size={isMobile ? 14 : 20}
              className="flex-shrink-0"
            />
            {isUserProfileMinDesktop && <Label minWidth={65}>ADDRESS</Label>}
            {isUserProfileMinDesktop && <DividerLine />}
          </LabelWrapper>
          <Value>{getStringifyAddress(userAddress)}</Value>
        </ContactItem>
      )}

      {position && <Map position={position} style={{maxWidth: 400}} />}
    </>
  );
};

export default memo(ContactInfo);
