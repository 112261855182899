import {Reducer} from 'redux';

import {getUserPrivacySettingsActionTypes, IUserPrivacySettingsState, UserPrivacySettingsActions} from '../types';

const initialState: IUserPrivacySettingsState['data'] = {
  userPrivacySettings: null
};

export const dataUserPrivacySettingsReducer: Reducer<IUserPrivacySettingsState['data'], UserPrivacySettingsActions> = (
  state = initialState,
  action: UserPrivacySettingsActions
) => {
  switch (action.type) {
    case getUserPrivacySettingsActionTypes.REQUEST: {
      return {...state, userPrivacySettings: initialState.userPrivacySettings};
    }
    case getUserPrivacySettingsActionTypes.SUCCESS: {
      return {...state, userPrivacySettings: action.payload};
    }
    default: {
      return state;
    }
  }
};
