import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.facetFilter;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectFilterPropertyOperations(state: IAppState) {
  return selectState(state).data.filterPropertyOperations;
}

export function selectPackagesFilters(state: IAppState) {
  return selectState(state).data.packagesFilters;
}
