import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import ReportService from 'Admin/AdminDashboard/services/ReportService';
import {ReportType} from 'Common/constants/ReportType';

import {
  downloadDemographicsReportActionTypes,
  downloadDemographicsReportActions,
  downloadPaidOrdersReportActionTypes,
  downloadPaidOrdersReportActions,
  downloadOrdersReportActionTypes,
  downloadOrdersReportActions
} from './types';

export const downloadDemographicsReport = (
  from: string,
  to: string
): ActionResult<IAppState, void, downloadDemographicsReportActions> => async dispatch => {
  dispatch(action(downloadDemographicsReportActionTypes.REQUEST));
  try {
    await ReportService.downloadReport(from, to, ReportType.Demographics);
    dispatch(action(downloadDemographicsReportActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(downloadDemographicsReportActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const downloadPaidOrdersReport = (
  from: string,
  to: string
): ActionResult<IAppState, void, downloadPaidOrdersReportActions> => async dispatch => {
  dispatch(action(downloadPaidOrdersReportActionTypes.REQUEST));
  try {
    await ReportService.downloadReport(from, to, ReportType.PaidOrders);
    dispatch(action(downloadPaidOrdersReportActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(downloadPaidOrdersReportActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const downloadOrdersReport = (
  from: string,
  to: string
): ActionResult<IAppState, void, downloadOrdersReportActions> => async dispatch => {
  dispatch(action(downloadOrdersReportActionTypes.REQUEST));
  try {
    await ReportService.downloadReport(from, to, ReportType.Orders);
    dispatch(action(downloadOrdersReportActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(downloadOrdersReportActionTypes.FAILURE, getErrorMessage(error)));
  }
};
