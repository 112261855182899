import {IPackage} from 'Dictionaries/models/IPackage';
import {IFormValues} from 'Admin/AdminDashboard/components/Packages/PackageForm/validation';
import {IPackageCreateRequest, IPackageUpdateRequest} from 'Admin/AdminDashboard/models/Package/IPackageAdmin';
import {FormType} from 'Common/constants/FormType';
import {convertNumberArrayToIEntityArray} from 'Common/helpers/convertNumberArrayToIEntityArray';

export function convertPackageToPackageForm(packageInfo: IPackage, type: FormType): IFormValues {
  return {
    ...packageInfo,
    association: packageInfo.association?.id || null,
    tests: packageInfo.tests?.map((item) => item.id) || [],
    id: type === FormType.createBased ? 0 : packageInfo.id,
    reportTypes: packageInfo.reportTypes?.map((item) => item.id) || [],
    groups: packageInfo.groups?.map((item) => item.id) || [],
  };
}

export function convertPackageFormToPackageCreateRequest(packageInfo: IFormValues): IPackageCreateRequest {
  return {
    ...convertPackageFormToPackageUpdateRequest(packageInfo),
    groups: convertNumberArrayToIEntityArray(packageInfo.groups),
    isBundle: packageInfo.isBundle,
    positionIndex: packageInfo.positionIndex,
  };
}

export function convertPackageFormToPackageUpdateRequest(packageInfo: IFormValues): IPackageUpdateRequest {
  const dataToSave: IPackageUpdateRequest = {
    ...packageInfo,
    association: (packageInfo.association && {id: packageInfo.association}) || null,
    tests: convertNumberArrayToIEntityArray(packageInfo.tests),
    reportTypes: convertNumberArrayToIEntityArray(packageInfo.reportTypes),
  };

  delete dataToSave['isBundle'];
  delete dataToSave['positionIndex'];
  delete dataToSave['groups'];

  return {
    ...dataToSave,
  };
}
