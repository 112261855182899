import moment from 'moment';
import * as Yup from 'yup';
import {Nullable} from 'Common/types';
import {convertToClientDate, isValidDate} from 'Common/helpers/DateHelper';
import {FRONTEND_DATE} from 'Common/constants/Date';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues {
  from: Nullable<string>;
  to: Nullable<string>;
}

export const initialValues: IFormValues = {
  from: convertToClientDate(moment()),
  to: convertToClientDate(moment())
};

export const validationSchema = Yup.object().shape<IFormValues>({
  from: Yup.string()
    .required(REQUIRED_FIELD)
    .test('dateFrom', `Please enter a valid date: ${FRONTEND_DATE}`, function(value: string) {
      return value ? isValidDate(value, FRONTEND_DATE) : true;
    })
    .test('isBeforeTo', "Must be earlier than 'Date To' or equal", function(value) {
      return moment(value, FRONTEND_DATE).isSameOrBefore(moment(this.parent.to, FRONTEND_DATE) || 0);
    }),
  to: Yup.string()
    .required(REQUIRED_FIELD)
    .test('dateTo', `Please enter a valid date: ${FRONTEND_DATE}`, function(value: string) {
      return value ? isValidDate(value, FRONTEND_DATE) : true;
    })
    .test('isAfterFrom', "Must be later than 'Date From' or equal", function(value) {
      return moment(value, FRONTEND_DATE).isSameOrAfter(moment(this.parent.from, FRONTEND_DATE) || 0);
    })
});
