import React, {memo, useCallback} from 'react';

import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {Divider, FieldContainer, FieldData, FieldName} from 'BusinessPortal/components/common/styled';
import capitalizeFirstLetter from 'Common/helpers/capitalizeFirstLetter';
import {IRegistry} from 'Horse/models/IRegistry';
import NoData from 'BusinessPortal/components/common/NoData/NoData';
import {IRegistrationAndParentageSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/RegistrationAndParentageSection/models/IRegistrationAndParentageSectionData';

const RegistrationAndParentageSectionShow = (props: IEditableSection<IRegistrationAndParentageSectionData>) => {
  const {data} = props;

  const isNoData = data?.registries?.length === 0 && data?.parentage?.length === 0;

  const getRegistries = useCallback((registry: IRegistry, role?: string) => {
    return (
      <>
        <FieldContainer className="d-flex">
          <FieldName width="106px">{role ? `${role} registry` : 'Registry name'}:</FieldName>
          <FieldData>{registry.association?.name}</FieldData>
        </FieldContainer>
        <FieldContainer className="d-flex">
          <FieldName width="14px">#:</FieldName>
          <FieldData>{registry.registrationNumber}</FieldData>
        </FieldContainer>
      </>
    );
  }, []);

  if (isNoData) {
    return <NoData />;
  }

  return (
    <>
      {data?.registries?.map((registry, i) => (
        <div className="d-flex" key={i}>
          {getRegistries(registry)}
        </div>
      ))}
      {data?.registries?.length !== 0 && <Divider />}

      {data?.parentage?.map((parent, i) => {
        const role = capitalizeFirstLetter(parent.parent);
        return (
          <React.Fragment key={i}>
            <div className="d-flex flex-column">
              <FieldContainer className="d-flex">
                <FieldName width="106px">{role} name:</FieldName>
                <FieldData>{parent?.name || '-'}</FieldData>
              </FieldContainer>
              {parent?.registries.map((registry, i) => (
                <div className="d-flex" key={i}>
                  {getRegistries(registry, role)}
                </div>
              ))}
            </div>
            <Divider />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default memo(RegistrationAndParentageSectionShow);
