import React, {useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';
import {useFormikContext} from 'formik';
import {IAppState} from 'Common/store/IAppState';
import {ExpansionPanel, Section} from 'Common/components/ExpansionPanel/ExpansionPanel';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import ColorPalette from 'Common/constants/ColorPalette';

import {SearchHorseInput} from 'Horse/components';
import {actions as horseActions, selectors as horseSelectors} from 'Horse/store/horse';
import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import {dateDiffInYearsOrMonth} from 'Common/helpers/DateHelper';
import IconedLoading from 'Common/components/IconedLoading/IconedLoading';
import {HorseModule} from 'Horse/store/horse/horseModule';
import {getSectionHint} from './shared';
import IFoundHorse from 'Horse/models/IFoundHorse';
import withDynamicModules from 'Common/helpers/withDynamicModules';

const HINT =
  "Optional - Choose a specific horse by name to include as one of the parents for your build. Horses must be tested and have a 'public' profile to appear here. That horse's tested variants will be included in the calculation for your foal.";

const HorseProfile = styled.div`
  display: flex;
  align-items: center;
  font-size: ${Typography.size.size14};
  line-height: 16px;
`;

const HorseDescription = styled.div`
  margin-left: 16px;
  overflow: hidden;
`;

const HorseName = styled.div`
  color: ${Theme.color.black};
`;

const HorseDetails = styled.div`
  color: ${ColorPalette.gray44};
`;

const PreloaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
`;

interface IOwnProps {
  horseId?: number;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IOwnProps;

function SpecificHorse(props: Props) {
  const {searchHorse, removeHorse, horse, horseId, horseLoading, resetHorse} = props;

  const {setFieldValue} = useFormikContext();

  useEffect(
    () => {
      if (horse && horse.id !== horseId) {
        resetHorse();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    horseId && searchHorse(horseId);
  }, [horseId, searchHorse]);

  const onSelectHorse = useCallback(
    (horse: IFoundHorse) => {
      setFieldValue('horseId', horse.id);
    },
    [setFieldValue]
  );

  const onRemoveHorse = useCallback(() => {
    removeHorse();
    setFieldValue('horseId', undefined);
  }, [removeHorse, setFieldValue]);

  const horseDetails = horse
    ? [horse.gender, horse.dateOfBirth ? dateDiffInYearsOrMonth(horse.dateOfBirth) : ''].filter(Boolean).join(', ')
    : [];

  const isLoading = horseLoading.isRequesting;

  return (
    <ExpansionPanel
      title="Specific horse"
      headerContent={getSectionHint(HINT)}
      isFixed={isLoading || !!horse}
      onClear={!isLoading ? onRemoveHorse : undefined}
    >
      <Section>
        {(() => {
          if (isLoading) {
            return (
              <PreloaderContainer>
                <IconedLoading />
              </PreloaderContainer>
            );
          }

          if (horse) {
            return (
              <HorseProfile>
                <Avatar
                  avatarSize={AvatarSize.Custom}
                  customSizeAvatar={48}
                  customSizeIcon={24}
                  defaultAvatarProfile={DefaultAvatarProfile.Horse}
                  url={horse.avatar && horse.avatar.url}
                />
                <HorseDescription>
                  <HorseName>{horse.name}</HorseName>
                  <HorseDetails>{horseDetails}</HorseDetails>
                </HorseDescription>
              </HorseProfile>
            );
          }

          return <SearchHorseInput onSelectHorse={onSelectHorse} />;
        })()}
      </Section>
    </ExpansionPanel>
  );
}

const mapStateToProps = (state: IAppState) => ({
  horse: horseSelectors.selectFoundHorse(state),
  horseLoading: horseSelectors.selectCommunication(state, 'horseLoading'),
});

const mapDispatchToProps = {
  searchHorse: horseActions.searchHorse,
  removeHorse: horseActions.resetSearchHorseResults,
  resetHorse: horseActions.resetHorseResults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(SpecificHorse);

export default withDynamicModules(Connected, HorseModule);
