import * as Yup from 'yup';

import {IChangePasswordAdmin} from 'Admin/AdminDashboard/services/types/user';

export const initialValue: IChangePasswordAdmin = {
  email: '',
  password: '',
  passwordConfirm: ''
};

export const validationSchema = Yup.object().shape<IChangePasswordAdmin>({
  email: Yup.string(),
  password: Yup.string()
    .required('New password is required')
    .oneOf([Yup.ref('passwordConfirm')], 'Passwords must match'),
  passwordConfirm: Yup.string()
    .required('Repeat new password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match')
});
