import {IPackageCartSettings} from 'Dictionaries/models/IPackage';
import {IFormValues} from './validation';
import {convertNumberArrayToIEntityArray} from 'Common/helpers/convertNumberArrayToIEntityArray';
import {IPackageCartSettingsRequest} from 'Admin/AdminDashboard/models/Package/IPackageAdmin';

export function convertPackageCartSettingsToFormValues(packageInfo: IPackageCartSettings): IFormValues {
  return {
    id: packageInfo.id,
    isBundle: packageInfo.isBundle,
    positionIndex: packageInfo.positionIndex,
    groups: packageInfo.groups.map((item) => item.id) || [],
  };
}

export function convertFormValuesToRequest(values: IFormValues): IPackageCartSettingsRequest {
  const dataToSave: IPackageCartSettingsRequest = {
    ...values,
    groups: convertNumberArrayToIEntityArray(values.groups),
    isBundle: values.isBundle,
    positionIndex: values.positionIndex,
  };

  return {
    ...dataToSave,
  };
}
