import React, {memo} from 'react';
import DictionariesLayout from 'Admin/AdminReference/components/NamedEntityDictionaryLayout/NamedEntityDictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';

const Markings = () => {
  const {adminMarking} = useDictionaries();

  return <DictionariesLayout dictionary={adminMarking} />;
};

export default memo(Markings);
