import {IModule} from 'redux-dynamic-modules';

import {forgotPasswordReducer} from './reducer';
import {IForgotPasswordState} from './types';

export interface IForgotPasswordModuleState {
  forgotPassword: IForgotPasswordState;
}

export const ForgotPasswordModule: IModule<IForgotPasswordModuleState> = {
  id: 'forgotPassword',
  reducerMap: {
    forgotPassword: forgotPasswordReducer
  }
};
