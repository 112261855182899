import {ICreateHorseBP} from 'BusinessPortal/models/horse/IHorseBP';
import {IFormValues} from 'Shared/components/Association/validation';
import {convertToServerDate} from 'Common/helpers/DateHelper';
import {ProfileType} from 'Common/constants/ProfileType';

export const convertToServer = (data: Omit<IFormValues, 'ownerId'>, ownerId: number): ICreateHorseBP => {
  const allAddressFieldsAreEmpty =
    data.address && Object.values(data.address).every((value) => value === '' || value === null);

  return {
    ...data,
    ownerId,
    address: allAddressFieldsAreEmpty ? null : data.address,
    dateOfBirth: convertToServerDate(data.dateOfBirth),
    profileType: data.isPublicProfile ? ProfileType.Public : ProfileType.Private,
    ancestryProfileType: data.isAncestryPublicProfile ? ProfileType.Public : ProfileType.Private,
  };
};
