import {combineReducers, Reducer} from 'redux';

import {IFindHorseState} from '../types';
import {communicationFindHorseReducer} from './communications';
import {dataFindHorseReducer} from './data';
import {uiFindHorseReducer} from './ui';

export const reducer: Reducer<IFindHorseState> = combineReducers<IFindHorseState>({
  communications: communicationFindHorseReducer,
  data: dataFindHorseReducer,
  ui: uiFindHorseReducer
});
