import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  getPaymentDetailsActionTypes,
  getPaymentDetailsHorsesActionTypes,
  IAdminAssociationPaymentUpdateState,
  UpdatePaymentDetailsActions,
  updatePaymentDetailsActionTypes,
} from '../types';

export const communicationPaymentDetailsUpdateReducer = combineReducers<
  IAdminAssociationPaymentUpdateState['communications'],
  UpdatePaymentDetailsActions
>({
  paymentDetailsLoading: makeCommunicationReducerFromEnum(getPaymentDetailsActionTypes),
  paymentDetailsHorsesLoading: makeCommunicationReducerFromEnum(getPaymentDetailsHorsesActionTypes),
  paymentDetailsUpdating: makeCommunicationReducerFromEnum(updatePaymentDetailsActionTypes),
});
