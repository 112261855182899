import React, {memo, useCallback} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';

import {IAppState} from 'Common/store/IAppState';
import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {initialValue, validationSchema} from './validation';
import {Field, FieldLabel, FieldValue} from 'Admin/common/styled/StyledComponents';
import PasswordField from 'Common/components/FormFields/PasswordField';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAdministrator, IUpdateAdministratorPassword} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdministrator';
import {actions, selectors} from 'Admin/AdminAdvancedDashboard/store/adminAdministrators/administrators';
import {AdministratorsModule} from 'Admin/AdminAdvancedDashboard/store/adminAdministrators/administrators/administratorsModule';

const Root = styled.div`
  margin: 0 24px 40px 0;
`;

interface IExternalProps {
  admin: IAdministrator;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IUpdateAdministratorPassword> & OuterProps;

function AdminPasswordForm(props: AllProps) {
  const {isSubmitting, setErrors, isValid} = props;
  const {admin, administratorPasswordUpdating, onSuccess} = props;

  const errorInfo = administratorPasswordUpdating.error;
  const isRequesting = administratorPasswordUpdating.isRequesting;

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(administratorPasswordUpdating, onSuccess);
  useOnErrorCommunication(administratorPasswordUpdating, onError);
  useCommunicationToToast(administratorPasswordUpdating, 'Admin password has been changed');

  return (
    <>
      <ModalWindowHeader>Change user password</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <Root>
          <ModalWindowFormContent>
            {isRequesting && <Loading />}
            <div className="row">
              <Field className="col-4">
                <FieldLabel>Admin</FieldLabel>
                <FieldValue>{admin.name}</FieldValue>
              </Field>
              <Field className="col-4">
                <FieldLabel>Admin Email</FieldLabel>
                <FieldValue>{admin.email}</FieldValue>
              </Field>
            </div>

            <PasswordField name="password" label="New password" placeholder="New password" isRequired={true} />
            <PasswordField
              name="passwordConfirm"
              label="Repeat new password"
              placeholder="Repeat new password"
              isRequired={true}
            />
          </ModalWindowFormContent>
        </Root>
        <ModalWindowFooter>
          <ModalWindowButton type="submit" isLoading={isSubmitting} disabled={!isValid}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const AdminPasswordFormFormik = withFormik<OuterProps, IUpdateAdministratorPassword>({
  mapPropsToValues: ({admin}) => (admin ? {id: admin.id, password: '', passwordConfirm: ''} : initialValue),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    await formikBag.props.setAdministratorPassword({
      id: formikBag.props.admin.id,
      password: values.password,
      passwordConfirm: values.passwordConfirm,
    });
  },
  enableReinitialize: true,
})(AdminPasswordForm);

const mapStateToProps = (state: IAppState) => ({
  administratorPasswordUpdating: selectors.selectCommunication(state, 'administratorPasswordUpdating'),
});

const mapDispatchToProps = {
  setAdministratorPassword: actions.setAdministratorPassword,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(AdminPasswordFormFormik));

export default withDynamicModules(Connected, AdministratorsModule);
