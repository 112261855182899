import {combineReducers, Reducer} from 'redux';

import {IGalleryState} from '../types';
import {communicationReducer} from './communications';
import {dataReducer} from './data';

export const galleryReducer: Reducer<IGalleryState> = combineReducers<IGalleryState>({
  communications: communicationReducer,
  data: dataReducer
});
