import React, {useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import Loading from 'Loading/components/Loading';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'StripeSubscription/store/index';
import {InjectedStripeProps, withStripe} from 'Common/helpers/withStripe';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {StripeSubscriptionModule} from 'StripeSubscription/store/stripeSubscriptionModule';
import {Nullable} from 'Common/types';
import {finishedSubscriptionStatuses} from './parts/common/constants';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import ChangeSubscriptionPlanForm from './ChangeSubscriptionPlanForm/ChangeSubscriptionPlanForm';
import ChangeDefaultPaymentMethodForm from './ChangeDefaultPaymentMethodForm/ChangeDefaultPaymentMethodForm';
import SimpleSubscriptionItem from './parts/SimpleSubscriptionItem';
import ScheduledSubscriptionItem from './parts/ScheduledSubscriptionItem';
import {ISubscriptionProduct} from 'StripeSubscription/models/ISubscriptionProduct';
import ColorPalette from 'Common/constants/ColorPalette';
import EmptySubscriptions from './parts/EmptySubscriptions';
import usePermissions from 'Permissions/hooks/usePermissions';
import {Permission} from 'Permissions/constants/Permission';
import {breakpoints} from 'Common/constants/Breakpoints';
import ContainerWrapper from 'Common/components/Layout/ContainerWrapper';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {useDictionaries} from 'Common/store/useDictionaries';
import {SettingsTitle} from '../styled';

const Root = styled(ContainerWrapper)`
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
`;
const Separator = styled.div`
  width: 100%;
  margin: 24px 0;
  border: 1px solid ${ColorPalette.gray48};

  @media ${breakpoints.md} {
    width: 70%;
  }
`;

interface IExternalDictionaries {
  productDictionary: ISimpleDictionary<ISubscriptionProduct>;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = InjectedStripeProps & IConnected & IExternalDictionaries;

function UserSubscriptions(props: AllProps) {
  const {
    defaultPaymentMethod,
    defaultPaymentMethodLoading,
    userScheduledSubscriptions,
    userScheduledSubscriptionsLoading,
    userSubscriptions,
    userSubscriptionsLoading,
    getDefaultPaymentMethod,
    getSubscriptions,
    getScheduledSubscriptions,
    products,
    productsLoading,
    isCustomerExist,
    isCustomerExistLoading,
    productDictionary,
  } = props;

  const {
    actions: {getItems: getProducts},
  } = productDictionary;

  const {hasPermission} = usePermissions();
  const hasBahPermissions =
    hasPermission(Permission.BuildHorse) || hasPermission(Permission.FindHorse) || hasPermission(Permission.BuildFoal);

  const onLoadPage = useCallback(async () => {
    const isExistStripeCustomer = await isCustomerExist();
    if (isExistStripeCustomer) {
      getProducts();
      getSubscriptions();
      getScheduledSubscriptions();
      getDefaultPaymentMethod();
    }
  }, [isCustomerExist, getProducts, getSubscriptions, getScheduledSubscriptions, getDefaultPaymentMethod]);

  const [problemSubscriptionId, setIsProblemSubscriptionId] = useState<Nullable<number>>(null);

  const [isChangeSubscriptionPlanModalOpen, setIsChangeSubscriptionPlanModalOpen] = useState(false);
  const openChangeSubscriptionPlanModal = useCallback(() => setIsChangeSubscriptionPlanModalOpen(true), []);
  const closeChangeSubscriptionPlanModal = useCallback(() => setIsChangeSubscriptionPlanModalOpen(false), []);
  const successChangeSubscriptionPlanModal = useCallback(async () => {
    closeChangeSubscriptionPlanModal();
    await onLoadPage();
  }, [closeChangeSubscriptionPlanModal, onLoadPage]);

  const [isChangeDefaultPaymentMethodModalOpen, setIsChangeDefaultPaymentMethodModalOpen] = useState(false);
  const openChangeDefaultPaymentMethodModal = useCallback((subscriptionId?: number) => {
    if (subscriptionId) {
      setIsProblemSubscriptionId(subscriptionId);
    } else {
      setIsProblemSubscriptionId(null);
    }
    setIsChangeDefaultPaymentMethodModalOpen(true);
  }, []);
  const closeChangeDefaultPaymentMethodModal = useCallback(() => setIsChangeDefaultPaymentMethodModalOpen(false), []);
  const successChangeDefaultPaymentMethodModal = useCallback(async () => {
    closeChangeDefaultPaymentMethodModal();
    await onLoadPage();
  }, [closeChangeDefaultPaymentMethodModal, onLoadPage]);

  const isLoading = [
    userSubscriptionsLoading,
    userScheduledSubscriptionsLoading,
    defaultPaymentMethodLoading,
    productsLoading,
    isCustomerExistLoading,
  ].some((i) => i.isRequesting);

  useErrorCommunicationToToast(userSubscriptionsLoading);
  useErrorCommunicationToToast(userScheduledSubscriptionsLoading);
  useErrorCommunicationToToast(defaultPaymentMethodLoading);
  useErrorCommunicationToToast(productsLoading);
  useErrorCommunicationToToast(isCustomerExistLoading);

  useEffect(() => {
    onLoadPage();
  }, [onLoadPage]);

  const subscription = userSubscriptions?.filter((i) => finishedSubscriptionStatuses.every((j) => j !== i.status))?.[0];
  const scheduledSubscription = userScheduledSubscriptions?.[0];

  const subscriptionProduct = products?.find((i) => i.id === subscription?.productId);
  const scheduledSubscriptionProduct = products?.find((i) => i.id === scheduledSubscription?.productId);

  const currentSubscriptionIds: number[] = [subscriptionProduct?.id || 0, scheduledSubscriptionProduct?.id || 0].filter(
    (i) => i !== 0
  );

  const hasSubscriptions = subscription || scheduledSubscription;

  return (
    <Root className="d-flex flex-column position-relative">
      <ModalWindow isOpen={isChangeSubscriptionPlanModalOpen} onClose={closeChangeSubscriptionPlanModal}>
        <ChangeSubscriptionPlanForm
          currentSubscriptionIds={currentSubscriptionIds}
          activeProducts={products?.filter((i) => i.isActive)}
          onSuccess={successChangeSubscriptionPlanModal}
        />
      </ModalWindow>

      <ModalWindow isOpen={isChangeDefaultPaymentMethodModalOpen} onClose={closeChangeDefaultPaymentMethodModal}>
        <ChangeDefaultPaymentMethodForm
          onSuccess={successChangeDefaultPaymentMethodModal}
          subscriptionId={problemSubscriptionId}
        />
      </ModalWindow>

      {isLoading && <Loading />}

      <SettingsTitle>Subscription settings</SettingsTitle>

      {!hasSubscriptions && !isLoading && <EmptySubscriptions hasBahPermissions={hasBahPermissions} />}

      {subscription && (
        <SimpleSubscriptionItem
          onSuccess={onLoadPage}
          subscription={subscription}
          product={subscriptionProduct}
          defaultPaymentMethod={defaultPaymentMethod}
          changeSubscriptionPlan={openChangeSubscriptionPlanModal}
          changeDefaultPaymentMethod={openChangeDefaultPaymentMethodModal}
          hasScheduledSubscription={!!scheduledSubscription}
        />
      )}

      {scheduledSubscription && <Separator />}

      {scheduledSubscription && (
        <ScheduledSubscriptionItem
          onSuccess={onLoadPage}
          subscription={scheduledSubscription}
          product={scheduledSubscriptionProduct}
          defaultPaymentMethod={defaultPaymentMethod}
          changeSubscriptionPlan={openChangeSubscriptionPlanModal}
          changeDefaultPaymentMethod={openChangeDefaultPaymentMethodModal}
        />
      )}
    </Root>
  );
}

const mapStateToProps = (state: IAppState, props: IExternalDictionaries) => {
  const {
    productDictionary: {selectors: productSelectors},
  } = props;

  return {
    products: productSelectors.selectItems(state),
    userSubscriptions: selectors.selectUserSubscriptions(state),
    userScheduledSubscriptions: selectors.selectUserScheduledSubscriptions(state),
    defaultPaymentMethod: selectors.selectDefaultPaymentMethod(state),
    productsLoading: productSelectors.selectCommunication(state, 'itemsLoading'),
    userSubscriptionsLoading: selectors.selectCommunication(state, 'userSubscriptionsLoading'),
    userScheduledSubscriptionsLoading: selectors.selectCommunication(state, 'userScheduledSubscriptionsLoading'),
    defaultPaymentMethodLoading: selectors.selectCommunication(state, 'defaultPaymentMethodLoading'),
    isCustomerExistLoading: selectors.selectCommunication(state, 'isCustomerExistLoading'),
  };
};

const mapDispatchToProps = {
  isCustomerExist: actions.isCustomerExist,
  getSubscriptions: actions.getUserSubscriptions,
  getScheduledSubscriptions: actions.getUserScheduledSubscriptions,
  getDefaultPaymentMethod: actions.getDefaultPaymentMethod,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(withStripe(UserSubscriptions));
const Dictionaried = () => {
  const {stripeSubscriptionProducts} = useDictionaries();

  return <Connected productDictionary={stripeSubscriptionProducts} />;
};

export default withDynamicModules(Dictionaried, StripeSubscriptionModule);
