import {IModule} from 'redux-dynamic-modules';

import {IBusinessPortalEmployeeProfileState} from './types';
import {employeeReducer} from './reducer';

export interface BusinessPortalEmployeeModuleState {
  businessPortalEmployeeProfile: IBusinessPortalEmployeeProfileState;
}

export const BusinessPortalEmployeeModule: IModule<BusinessPortalEmployeeModuleState> = {
  id: 'businessPortalEmployeeProfile',
  reducerMap: {
    businessPortalEmployeeProfile: employeeReducer,
  },
};
