import React, {memo, useMemo} from 'react';
import {ExpansionPanel, Section} from 'Common/components/ExpansionPanel/ExpansionPanel';
import {SectionHint, SectionLabel, SelectWrapper} from './shared';
import {SelectField} from 'Common/components/FormFields';
import SelectedValues from 'Filters/components/shared/SelectedValues/SelectedValues';
import {IHealthVariantToAvoid} from 'Dictionaries/models/IHealthVariantToAvoid';

const HINT =
  'Here you can select health variants you wish to avoid (genetic carriers). Potential serious health issues will be flagged during your breed matching, regardless, for your protection.';

export interface HealthVariantStringed extends Omit<IHealthVariantToAvoid, 'id'> {
  id: string;
}

interface IProps {
  healthVariant: HealthVariantStringed[];
  selectedCount: number;
}

function Health(props: IProps) {
  const {healthVariant, selectedCount} = props;

  const healthVariantsOptions = useMemo(() => {
    return healthVariant.map(i => ({
      label: i.name,
      value: i.id,
      description: i.description
    }));
  }, [healthVariant]);

  return (
    <ExpansionPanel title="Health" headerContent={<SectionHint hint={HINT} count={selectedCount} />}>
      <Section>
        <SectionLabel>Issues to avoid</SectionLabel>
        <SelectWrapper>
          <SelectField
            isMulti={true}
            name="healthVariantsToAvoidExtended"
            options={healthVariantsOptions}
            controlShouldRenderValue={false}
            blurInputOnSelect={true}
            isClearable={false}
            menuPlacement="top"
          />
        </SelectWrapper>
        <SelectedValues name="healthVariantsToAvoidExtended" options={healthVariantsOptions} withDots={true} />
      </Section>
    </ExpansionPanel>
  );
}

export default memo(Health);
