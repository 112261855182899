import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';
import styled from 'styled-components';

import {InputField, SelectField, TextAreaField} from 'Common/components/FormFields';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {getStringKeysOption} from 'Common/helpers/OptionHelper';
import {RequiredBy} from 'Common/types';
import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {
  convertToClient,
  convertToServer,
  convertToServerUpdating,
  IFormValues,
  initialValue,
  validationSchema,
} from './validation';
import {IAdminProduct} from 'Admin/AdminDashboard/models/IAdminProduct';
import {IUpdateProductRequest} from 'Admin/AdminDashboard/services/models/IServerProduct';
import {Interval} from 'Common/constants/Interval';
import {IPermission} from 'Permissions/model/IPermission';
import {Permission} from 'Permissions/constants/Permission';
import {Icon, IconedField} from 'Common/components/StyledComponents/StyledComponents';
import SwitchField from 'Common/components/FormFields/SwitchField';
import ColorPalette from 'Common/constants/ColorPalette';
import {useDictionaries} from 'Common/store/useDictionaries';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

export const SwitchWrapper = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${ColorPalette.gray10};
`;

interface IOwnProps extends IPhenotypeFormExternalProps<IAdminProduct, IUpdateProductRequest> {
  permissionsDictionary: ISimpleDictionary<IPermission>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IOwnProps & IConnected;

type Props = FormikProps<IFormValues> & OuterProps;

function ProductForm(props: Props) {
  const {itemId, item, dictionary, permissions, permissionsLoading, permissionsDictionary, ...rest} = props;
  const {type} = props;
  const {getItem} = dictionary.actions;
  const {
    actions: {getItems: getPermissions},
  } = permissionsDictionary;

  useEffect(() => {
    getPermissions();
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem, getPermissions]);

  const filteredPermissions = permissions
    .filter((x) => x.code !== Permission.Admin)
    .map((permission) => ({
      value: permission.code,
      label: `${permission.name} access`,
    }));

  const isDisabled = type === FormType.edit;

  return (
    <DictionaryForm {...rest}>
      <InputField name="name" label="Name" placeholder="Name" isRequired />
      <IconedField>
        <InputField
          name="price"
          label="Price"
          placeholder="Price"
          inputStyle={{textIndent: 15}}
          disabled={isDisabled}
          isRequired
        />
        <Icon>$</Icon>
      </IconedField>
      <SelectField
        name="billingInterval"
        label="Billing interval"
        options={getStringKeysOption(Interval)}
        disabled={isDisabled}
        isRequired
      />
      <SelectField name="permissions" label="Permissions" options={filteredPermissions} isMulti />
      <TextAreaField name="description" label="Description" placeholder="Description" />
      <SwitchWrapper className="d-flex justify-content-between">
        <div>Active</div>
        <SwitchField name="isActive" />
      </SwitchWrapper>
    </DictionaryForm>
  );
}

const ProductFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting ? convertToClient(item) : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(ProductForm);

const mapStateToProps = (state: IAppState, externalProps: IOwnProps) => {
  const {permissionsDictionary} = externalProps;
  return {
    permissions: permissionsDictionary.selectors.selectItems(state),
    permissionsLoading: permissionsDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    ...makePhenotypeFormSelectors(state, externalProps.dictionary),
  };
};

const connector = connect(mapStateToProps);
const Connected = connector(memo(ProductFormFormik));
const Exported = (externalProps: Omit<IOwnProps, 'permissionsDictionary'>) => {
  const {permissions} = useDictionaries();

  return <Connected {...externalProps} permissionsDictionary={permissions} />;
};

export default Exported;
