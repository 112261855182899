import React, {useCallback, useState} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import AdminPasswordForm from 'Admin/AdminAdvancedDashboard/components/Administrators/PasswordForm/AdminPasswordForm';
import {IAdministrator} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdministrator';

interface IProps {
  onSuccess(): void;
}

export function useUpdateAdministratorPasswordModal(props: IProps) {
  const {onSuccess} = props;

  const [selectedId, setSelectedId] = useState(0);
  const [selectedAdmin, setSelectedAdmin] = useState<IAdministrator>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openUpdateAdministratorPasswordModal = useCallback(
    (admin: IAdministrator) => {
      setSelectedId(admin.id);
      setSelectedAdmin(admin);
      setIsModalOpen(true);
    },
    [setSelectedId]
  );
  const closeUpdateAdministratorPasswordModal = useCallback(() => setIsModalOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeUpdateAdministratorPasswordModal();
    onSuccess();
  }, [closeUpdateAdministratorPasswordModal, onSuccess]);

  const updateAdministratorPasswordModal = (
    <ModalWindow isOpen={isModalOpen && !!selectedId} onClose={closeUpdateAdministratorPasswordModal}>
      <AdminPasswordForm admin={selectedAdmin!} onSuccess={handleSuccess} />
    </ModalWindow>
  );

  return {
    openUpdateAdministratorPasswordModal,
    updateAdministratorPasswordModal
  };
}
