import React, {memo} from 'react';

import {deleteToFavoriteIconProps} from 'UserProfile/components/TabsData/common/constants';
import {DeleteFavoriteButton, FavoriteButtonsCustomTooltip} from 'UserProfile/components/TabsData/common/styled';

interface IProps {
  onClick(): void;
}

const DeleteFavoriteHorseButton = (props: IProps) => {
  const {onClick} = props;

  return (
    <DeleteFavoriteButton
      size="small"
      type="button"
      variant="contained"
      icon={deleteToFavoriteIconProps}
      iconStyle={{marginBottom: 3, marginRight: -4}}
      tooltip="You added this horse to favorite. Click to remove"
      tooltipStyled={FavoriteButtonsCustomTooltip}
      onClick={onClick}
    >
      Favorite
    </DeleteFavoriteButton>
  );
};

export default memo(DeleteFavoriteHorseButton);
