import {combineReducers} from 'redux';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  cancelScheduledSubscriptionActionTypes,
  cancelSubscriptionActionTypes,
  changeDefaultPaymentMethodActionTypes,
  changeSubscriptionPlanActionTypes,
  createSubscriptionActionTypes,
  deleteSubscriptionActionTypes,
  ensureCustomerCreatedActionTypes,
  getDefaultPaymentMethodActionTypes,
  getSetupIntentClientSecretActionTypes,
  getUserScheduledSubscriptionsActionTypes,
  getUserSubscriptionsActionTypes,
  isCustomerExistActionTypes,
  IStripeSubscriptionState,
  refreshSubscriptionFromStripeActionTypes,
  renewSubscriptionActionTypes,
  retryInvoiceActionTypes,
  stripeSubscriptionActions
} from 'StripeSubscription/store/types';

export const communicationStripeSubscriptionReducer = combineReducers<
  IStripeSubscriptionState['communications'],
  stripeSubscriptionActions
>({
  createSubscriptionRequesting: makeCommunicationReducerFromEnum(createSubscriptionActionTypes),
  userSubscriptionsLoading: makeCommunicationReducerFromEnum(getUserSubscriptionsActionTypes),
  userScheduledSubscriptionsLoading: makeCommunicationReducerFromEnum(getUserScheduledSubscriptionsActionTypes),
  defaultPaymentMethodLoading: makeCommunicationReducerFromEnum(getDefaultPaymentMethodActionTypes),
  changeDefaultPaymentMethodRequesting: makeCommunicationReducerFromEnum(changeDefaultPaymentMethodActionTypes),
  ensureCustomerCreatedRequesting: makeCommunicationReducerFromEnum(ensureCustomerCreatedActionTypes),
  retryInvoiceRequesting: makeCommunicationReducerFromEnum(retryInvoiceActionTypes),
  deleteSubscriptionRequesting: makeCommunicationReducerFromEnum(deleteSubscriptionActionTypes),
  cancelSubscriptionRequesting: makeCommunicationReducerFromEnum(cancelSubscriptionActionTypes),
  cancelScheduledSubscriptionRequesting: makeCommunicationReducerFromEnum(cancelScheduledSubscriptionActionTypes),
  changeSubscriptionPlanRequesting: makeCommunicationReducerFromEnum(changeSubscriptionPlanActionTypes),
  renewSubscriptionRequesting: makeCommunicationReducerFromEnum(renewSubscriptionActionTypes),
  setupIntentClientSecretLoading: makeCommunicationReducerFromEnum(getSetupIntentClientSecretActionTypes),
  isCustomerExistLoading: makeCommunicationReducerFromEnum(isCustomerExistActionTypes),
  refreshSubscriptionFromStripeRequesting: makeCommunicationReducerFromEnum(refreshSubscriptionFromStripeActionTypes)
});
