import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IImage} from 'Image/models/IImage';
import {IAdminEmployee} from 'Admin/AdminAssociations/models/IAdminEmployee';
import {Nullable} from 'Common/types';
import {ILocation} from 'Common/models/ICoordinatedLocation';

export interface IFormValues extends Omit<IAdminEmployee, 'id' | 'organization'> {
  id?: IEntity['id'];
  password?: string;
  organizationId?: number;
  location?: Nullable<ILocation>;
  locationAddress?: string;
}

export const initialValue: IFormValues = {
  name: '',
  email: '',
  password: '',
  address: '',
  phoneNumber: '',
  isActive: true,
  avatar: null,
  description: '',
  location: {latitude: 0, longitude: 0},
  locationAddress: '',
};

export const createValidationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  email: Yup.string().required(REQUIRED_FIELD),
  password: Yup.string().required(REQUIRED_FIELD),
  phoneNumber: Yup.string(),
  isActive: Yup.boolean().required(REQUIRED_FIELD),
  avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
  description: Yup.string(),
  location: Yup.object()
    .shape<ILocation>({
      latitude: Yup.number(),
      longitude: Yup.number(),
    })
    .nullable(),
  locationAddress: Yup.string(),
});

export const updateValidationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  email: Yup.string().required(REQUIRED_FIELD),
  phoneNumber: Yup.string(),
  address: Yup.string(),
  avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
  description: Yup.string(),
  location: Yup.object()
    .shape<ILocation>({
      latitude: Yup.number(),
      longitude: Yup.number(),
    })
    .nullable(),
  locationAddress: Yup.string(),
});
