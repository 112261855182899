import React, {memo} from 'react';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import HorseProfile from 'Shared/components/HorseProfile/HorseProfile';
import {VisitorType} from 'Common/constants/VisitorType';

const HorseProfileLayout = () => {
  const {isDesktop} = useMediaQuery();

  return (
    <BaseLayout withoutPaddings={true} backButtonStyle={{marginTop: isDesktop ? 20 : 0}} isDarkBackground={true}>
      <HorseProfile type={VisitorType.User} />
    </BaseLayout>
  );
};

export default memo(HorseProfileLayout);
