import React, {memo} from 'react';
import styled, {StyledComponent} from 'styled-components';
import Tippy, {TippyProps} from '@tippy.js/react';

import ColorPalette from 'Common/constants/ColorPalette';
import {PartialBy} from 'Common/types';
import Typography from 'Common/constants/Typography';

export const TooltipTippyBase = styled(Tippy)`
  border-radius: 4px;
  padding: 10px 15px !important;
  max-width: 300px !important;
  font-size: ${Typography.size.size14};
  overflow: hidden;
  word-break: break-word;
`;

const ArrowStyle = (arrowColor: string) => `
  &[x-placement^='top'] {
    .tippy-arrow {
      border-top-color: ${arrowColor};
    }
  }
  &[x-placement^='bottom'] {
    .tippy-arrow {
      border-bottom-color: ${arrowColor};
    }
  }
  &[x-placement^='left'] {
    .tippy-arrow {
      border-left-color: ${arrowColor};
    }
  }
  &[x-placement^='right'] {
    .tippy-arrow {
      border-right-color: ${arrowColor};
    }
  }
`;

const TooltipTippyDefault = styled(TooltipTippyBase)`
  background: ${ColorPalette.white0};
  box-shadow: 0px 0px 15px 8px ${ColorPalette.gray4};
  text-align: left;
  line-height: 16px;
  color: ${ColorPalette.black1};

  ${ArrowStyle(ColorPalette.white0)}
`;

export const TooltipTippyAlert = styled(TooltipTippyBase)`
  background: ${ColorPalette.yellow8};
  border: 2px solid ${ColorPalette.yellow7};
  text-align: center;
  line-height: 24px;
  color: ${ColorPalette.black1};
  line-height: 24px;
  font-size: ${Typography.size.size13};

  ${ArrowStyle(ColorPalette.yellow8)}
`;

export const TooltipTippyError = styled(TooltipTippyBase)`
  background: ${ColorPalette.red6};
  border: 2px solid ${ColorPalette.red4};
  border: 2px solid ${ColorPalette.yellow7};
  text-align: center;
  line-height: 24px;
  color: ${ColorPalette.white0};
  line-height: 24px;

  ${ArrowStyle(ColorPalette.red6)}
`;

export enum TooltipType {
  Default,
  Alert,
  Error
}

const VariantByType: Record<TooltipType, StyledComponent<any, any>> = {
  [TooltipType.Default]: TooltipTippyDefault,
  [TooltipType.Error]: TooltipTippyError,
  [TooltipType.Alert]: TooltipTippyAlert
};

interface IOwnProps {
  tooltipType?: TooltipType;
  boundaryParent?: string;
}

type IProps = PartialBy<TippyProps, 'content'> & IOwnProps;

function Tooltip(props: IProps) {
  const {content, tooltipType = TooltipType.Default, children, boundaryParent, ...rest} = props;

  const TooltipTippy = VariantByType[tooltipType];

  return content ? (
    <TooltipTippy {...rest} content={content} boundary={boundaryParent}>
      {children}
    </TooltipTippy>
  ) : (
    children
  );
}

Tippy.defaultProps = {
  arrow: true,
  placement: 'bottom',
  popperOptions: {modifiers: {computeStyle: {gpuAcceleration: false}}}
};

export default memo(Tooltip);

export type TooltipProps = Omit<IProps, 'children'>;
