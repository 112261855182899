import {combineReducers} from 'redux';

import {
  IBusinessPortalEmployeeProfileState,
  EmployeeActions,
  getEmployeeGeoLocationActionTypes,
  updateEmployeeGeoLocationActionTypes,
  getEmployeeProfileActionTypes,
  updateEmployeeProfileActionTypes,
  updateEmployeeEmailActionTypes,
  updateEmployeePasswordActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationReducer = combineReducers<
  IBusinessPortalEmployeeProfileState['communications'],
  EmployeeActions
>({
  employeeProfileLoading: makeCommunicationReducerFromEnum(getEmployeeProfileActionTypes),
  employeeProfileUpdating: makeCommunicationReducerFromEnum(updateEmployeeProfileActionTypes),
  geoLocationLoading: makeCommunicationReducerFromEnum(getEmployeeGeoLocationActionTypes),
  geoLocationUpdating: makeCommunicationReducerFromEnum(updateEmployeeGeoLocationActionTypes),
  emailUpdating: makeCommunicationReducerFromEnum(updateEmployeeEmailActionTypes),
  passwordUpdating: makeCommunicationReducerFromEnum(updateEmployeePasswordActionTypes),
});
