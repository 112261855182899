import {IModule} from 'redux-dynamic-modules';

import {IAdminHorsesState} from './types';
import {horsesReducer} from './reducer/index';

export interface IAdminHorsesModuleState {
  adminHorses: IAdminHorsesState;
}

export const AdminHorsesModule: IModule<IAdminHorsesModuleState> = {
  id: 'adminHorses',
  reducerMap: {
    adminHorses: horsesReducer,
  },
};
