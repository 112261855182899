import {ICoupon} from 'Coupon/models/ICoupon';
import {ICouponForm, ICouponRequest} from 'Admin/AdminDashboard/components/Coupons/CouponForm/validation';

export function convertCouponToCouponForm(coupon: ICoupon): ICouponForm {
  return {
    ...coupon,
    userPackages: coupon.userPackages?.map((item) => item.id),
    associationPackages: coupon.associationPackages?.map((item) => item.id),
    associationPurchasableTests: coupon.associationPurchasableTests?.map((item) => item.id),
  };
}

export function convertCouponFormToCouponRequest(coupon: ICouponForm): ICouponRequest {
  return {
    ...coupon,
    userPackages: coupon.userPackages?.map((item) => {
      return {id: item};
    }),
    associationPackages: coupon.associationPackages?.map((item) => {
      return {id: item};
    }),
    associationPurchasableTests: coupon.associationPurchasableTests?.map((item) => {
      return {id: item};
    }),
  };
}
