import React from 'react';
import styled from 'styled-components';

import {InfoContainer, Link, Value, ValueTitle} from './styled';
import {breakpoints} from 'Common/constants/Breakpoints';

const Container = styled(InfoContainer)<{isMobileColumnDirection?: boolean}>`
  flex-direction: ${({isMobileColumnDirection}) => (isMobileColumnDirection ? 'column' : 'row')};

  @media ${breakpoints.md} {
    flex-direction: row;
  }
`;

const ChangePaymentMethodLink = styled(Link)`
  margin-top: 16px;
  @media ${breakpoints.md} {
    margin-top: 0;
    margin-left: 32px;
  }
`;

interface IProps {
  title: string;
  value: string;
  onChangePaymentMethod?(): void;
}

const SubscriptionInfoRow = (props: IProps) => {
  const {onChangePaymentMethod, title, value} = props;

  return (
    <Container className="d-flex" isMobileColumnDirection={!!onChangePaymentMethod}>
      <div className="d-flex">
        <ValueTitle>{title}</ValueTitle>
        <Value>{value}</Value>
      </div>
      {onChangePaymentMethod && (
        <ChangePaymentMethodLink onClick={onChangePaymentMethod}>Change payment method</ChangePaymentMethodLink>
      )}
    </Container>
  );
};

export default SubscriptionInfoRow;
