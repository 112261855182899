import React, {memo, useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import HorseInfoSectionEdit from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseInfoSection/HorseInfoSectionEdit';
import HorseInfoSectionShow from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseInfoSection/HorseInfoSectionShow';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'BusinessPortal/store/horse';
import Loading from 'Loading/components/Loading';
import EditableSection from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {IHorseSectionBP} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/models/IHorseSectionBP';

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IHorseSectionBP;

const HorseInfoSection = (props: AllProps) => {
  const {onSuccess} = props;
  const {horseId, horseDetails, getHorseDetails, horseDetailsLoading} = props;

  const onSuccessEditHorse = useCallback(() => {
    getHorseDetails(horseId);
    onSuccess();
  }, [getHorseDetails, horseId, onSuccess]);

  const isRequesting = horseDetailsLoading.isRequesting;

  return (
    <div className="position-relative">
      {isRequesting && <Loading />}
      <EditableSection
        title="Horse Info"
        data={horseDetails}
        onSuccess={onSuccessEditHorse}
        editComponent={HorseInfoSectionEdit}
        showComponent={HorseInfoSectionShow}
      />
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  horseDetails: selectors.selectHorseDetails(state),
  horseDetailsLoading: selectors.selectCommunication(state, 'horseDetailsLoading')
});

const mapDispatchToProps = {
  getHorseDetails: actions.getHorseDetails
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(HorseInfoSection));
