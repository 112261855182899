import {Reducer} from 'redux';

import {getSubscriptionsActionTypes, IAdminSubscriptionState, SubscriptionsActions} from '../types';

const initialState: IAdminSubscriptionState['data'] = {
  subscriptions: []
};

export const dataAdminSubscriptionsReducer: Reducer<IAdminSubscriptionState['data'], SubscriptionsActions> = (
  state = initialState,
  action: SubscriptionsActions
) => {
  switch (action.type) {
    case getSubscriptionsActionTypes.SUCCESS: {
      return {...state, subscriptions: action.payload.data};
    }
    default: {
      return state;
    }
  }
};
