import React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {actions as ordersAction, selectors as ordersSelectors} from 'Admin/AdminDashboard/store/adminOrders/index';

import {AdminOrdersModule} from 'Admin/AdminDashboard/store/adminOrders/adminOrdersModule';
import {
  actions as paymentDetailsActions,
  selectors as paymentDetailsSelectors,
} from 'Admin/AdminDashboard/store/common/paymentDetailsUpload/index';
import {PaymentDetailsUploadModule} from 'Admin/AdminDashboard/store/common/paymentDetailsUpload/paymentDetailsUploadModule';
import {
  actions as adminOnlineReportActions,
  selectors as adminOnlineReportSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/index';
import {AdminOnlineReportModule} from 'Admin/AdminDashboard/store/adminOnlineReport/adminOnlineReportModule';

type IConnected = ConnectedProps<typeof connector>;

export type IWithOrderActionsProps = IConnected;

function withOrderActions<T>(Component: React.ComponentType<T>) {
  const wrapped = (props: any) => <Component {...props} />;
  return connector(wrapped);
}

const mapStateToProps = (state: IAppState) => ({
  orderDeleting: ordersSelectors.selectCommunication(state, 'orderDeleting'),
  setSampleStatusRequesting: ordersSelectors.selectCommunication(state, 'setSampleStatusRequesting'),
  newSampleRequesting: ordersSelectors.selectCommunication(state, 'newSampleRequesting'),
  downloadReportsRequesting: ordersSelectors.selectCommunication(state, 'downloadReportsRequesting'),
  downloadFormRequesting: ordersSelectors.selectCommunication(state, 'downloadSubmissionFormRequesting'),
  paymentDetailsUpdating: paymentDetailsSelectors.selectCommunication(state, 'paymentDetailsUpdating'),
  changeReviewStatusRequesting: adminOnlineReportSelectors.selectCommunication(state, 'changeReviewStatusRequesting'),
});

const mapDispatchToProps = {
  deleteOrder: ordersAction.deleteOrder,
  setSampleStatus: ordersAction.setSampleStatus,
  requestNewSample: ordersAction.requestNewSample,
  downloadReports: ordersAction.downloadReports,
  downloadSubmissionForm: ordersAction.downloadOrderSubmissionForm,
  updatePaymentDetails: paymentDetailsActions.updatePaymentDetails,
  changeReviewStatus: adminOnlineReportActions.changeReviewStatus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

function getWithOrderActionsModules() {
  return [AdminOrdersModule, PaymentDetailsUploadModule, AdminOnlineReportModule];
}

export {getWithOrderActionsModules, withOrderActions as default};
