import React from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';

const Mark = styled.span`
  margin-left: 3px;
  color: ${ColorPalette.red7};
`;

interface IProps {
  isRequired?: boolean;
}

const RequiredMark = (props: IProps) => {
  return props.isRequired ? <Mark>*</Mark> : null;
};

export default RequiredMark;
