import * as React from 'react';
import {Field, FieldProps, FastField} from 'formik';

interface IWithFieldProps {
  memoized?: boolean;
}

function withField<P extends FieldProps>(Component: React.ComponentType<P>, isNeverMemoized?: boolean) {
  type OwnProps = Omit<P, keyof FieldProps>;
  const result: React.FC<OwnProps & IWithFieldProps> = (props: OwnProps & IWithFieldProps) => {
    const {memoized, ...rest} = props;

    const FieldWrapper = isNeverMemoized ? Field : memoized ? FastField : Field;

    return <FieldWrapper {...rest} component={Component} />;
  };
  result.displayName = `withField(${Component.displayName || Component.name || 'Component'})`;
  return result;
}

export default withField;
