import {ICommunication} from 'Common/store/utils/communication';
import {usePrevious} from './usePrevious';

export function useOnRequestCommunication(communication: ICommunication, onRequest: () => void) {
  const prevCommunication = usePrevious<ICommunication>(communication);

  if (!prevCommunication?.isRequesting && communication.isRequesting) {
    setTimeout(() => onRequest(), 0); // setTimeout is needed because useEffect executes after render
  }
}
