import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication/index';
import {IListResponse, IPagination} from 'Common/models/IResponse';

import {IStaticResource} from 'Admin/AdminSettings/models/IStaticResource';
import {Nullable} from 'Common/types';

const STATE_NAME = 'adminStaticResources';

export interface IAdminStaticResourcesState {
  data: {
    staticResources: IStaticResource[];
    staticResource: Nullable<IStaticResource>;
  };
  communications: {
    staticResourcesLoading: ICommunication;
    staticResourceLoading: ICommunication;
    staticResourceCreating: ICommunication;
    staticResourceUpdating: ICommunication;
    staticResourceDeleting: ICommunication;
    staticResourceFileUploading: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getStaticResourcesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_STATIC_RESOURCES');

export type getStaticResourcesActions = MakeCommunicationActions<
  typeof getStaticResourcesActionTypes,
  {success: IListResponse<IStaticResource>}
>;

export const getStaticResourceActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_STATIC_RESOURCE');

export type getStaticResourceActions = MakeCommunicationActions<
  typeof getStaticResourceActionTypes,
  {success: IStaticResource}
>;

export const updateStaticResourceActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_STATIC_RESOURCE');

export type updateStaticResourceActions = MakeCommunicationActions<
  typeof updateStaticResourceActionTypes,
  {success: IStaticResource}
>;

export const createStaticResourceActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_STATIC_RESOURCE');

export type createStaticResourceActions = MakeCommunicationActions<
  typeof createStaticResourceActionTypes,
  {success: IStaticResource}
>;

export const deleteStaticResourceActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_STATIC_RESOURCE');

export type deleteStaticResourceActions = MakeCommunicationActions<typeof deleteStaticResourceActionTypes, {}>;

export const uploadStaticResourceFileActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPLOAD_STATIC_RESOURCE_FILE'
);

export type uploadStaticResourceFileActions = MakeCommunicationActions<
  typeof uploadStaticResourceFileActionTypes,
  {success: IStaticResource}
>;

export const resetStaticResourceActionTypes = makeResetCommunicationActionType(STATE_NAME, 'STATIC_RESOURCE');
export type resetStaticResourceActions = MakeResetCommunicationActions<typeof resetStaticResourceActionTypes>;

export type StaticResourcesActions =
  | getStaticResourcesActions
  | createStaticResourceActions
  | uploadStaticResourceFileActions
  | updateStaticResourceActions
  | deleteStaticResourceActions
  | getStaticResourceActions
  | resetStaticResourceActions;
