import * as Yup from 'yup';

export interface IFormValues {
  userName: string;
  password: string;
  rememberMe: false;
}

export const initialValues: IFormValues = {userName: '', password: '', rememberMe: false};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  userName: Yup.string()
    .email('Email not valid')
    .required('Email is required'),
  password: Yup.string().required('Password is required')
});
