import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {IListResponse} from 'Common/models/IResponse';
import {IHorseBP} from 'BusinessPortal/models/horse/IHorseBP';
import {IHorseDetailsBP} from 'BusinessPortal/models/horse/IHorseDetailsBP';
import {IHorseOwnerBP} from 'BusinessPortal/models/horse/IHorseOwnerBP';
import {IHorseParentageBP} from 'BusinessPortal/models/horse/IHorseParentageBP';
import {IInconclusiveHealthIssue} from 'Dictionaries/models/InconclusiveHealthIssues';
import {IRegistry} from 'Horse/models/IRegistry';
import {IMediaResource} from 'Common/models/IMediaResource';
import {IAssociationHorseState} from 'Shared/components/Association/models/IAssociationHorseState';
import {ProfileType} from 'Common/constants/ProfileType';

export interface IHorseProfileTypeRequest {
  horseId: number;
  profileType: ProfileType;
}

const STATE_NAME = 'businessPortalHorse';

interface IOwnBusinessPortalHorsesState {
  communications: {
    profileTypeChanging: ICommunication;
    ancestryProfileTypeUpdating: ICommunication;
  };
}

export type IBusinessPortalHorseState = IAssociationHorseState<IHorseBP> & IOwnBusinessPortalHorsesState;

export const getHorsesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSES');
export type getHorsesActions = MakeCommunicationActions<
  typeof getHorsesActionTypes,
  {success: IListResponse<IHorseBP>}
>;

export const createHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_HORSE');
export type createHorseActions = MakeCommunicationActions<typeof createHorseActionTypes, {}>;

export const getHorseDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_DETAILS');
export type getHorseDetailsActions = MakeCommunicationActions<
  typeof getHorseDetailsActionTypes,
  {success: IHorseDetailsBP}
>;

export const getHorseOwnerActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_OWNER');
export type getHorseOwnerActions = MakeCommunicationActions<typeof getHorseOwnerActionTypes, {success: IHorseOwnerBP}>;

export const getHorseParentageActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_PARENTAGE');
export type getHorseParentageActions = MakeCommunicationActions<
  typeof getHorseParentageActionTypes,
  {success: IHorseParentageBP[]}
>;

export const getHorseRegistriesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_REGISTRIES');
export type getHorseRegistriesActions = MakeCommunicationActions<
  typeof getHorseRegistriesActionTypes,
  {success: IRegistry[]}
>;

export const getHorseHealthIssuesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_HEALTH_ISSUES');
export type getHorseHealthIssuesActions = MakeCommunicationActions<
  typeof getHorseHealthIssuesActionTypes,
  {success: IInconclusiveHealthIssue[]}
>;

export const updateHorseDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_HORSE_DETAILS');
export type updateHorseDetailsActions = MakeCommunicationActions<typeof updateHorseDetailsActionTypes, {}>;

export const setHorseOwnerActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_HORSE_OWNER');
export type setHorseOwnerActions = MakeCommunicationActions<typeof setHorseOwnerActionTypes, {}>;

export const updateHorseParentageActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_HORSE_PARENTAGE');
export type updateHorseParentageActions = MakeCommunicationActions<typeof updateHorseParentageActionTypes, {}>;

export const updateHorseRegistriesActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_HORSE_REGISTRIES');
export type updateHorseRegistriesActions = MakeCommunicationActions<typeof updateHorseRegistriesActionTypes, {}>;

export const updateHorseHealthIssuesActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_HORSE_HEALTH_ISSUES');
export type updateHorseHealthIssuesActions = MakeCommunicationActions<typeof updateHorseHealthIssuesActionTypes, {}>;

export const updateHorseAvatarActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_HORSE_AVATAR');
export type updateHorseAvatarActions = MakeCommunicationActions<typeof updateHorseAvatarActionTypes, {}>;

export const deleteHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_HORSE');
export type deleteHorseActions = MakeCommunicationActions<typeof deleteHorseActionTypes, {}>;

export const deleteHorseAvatarActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_HORSE_AVATAR');
export type deleteHorseAvatarActions = MakeCommunicationActions<typeof deleteHorseAvatarActionTypes, {}>;

export const getHorseMediaActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_MEDIA');
export type getHorseMediaActions = MakeCommunicationActions<
  typeof getHorseMediaActionTypes,
  {success: IMediaResource[]}
>;

export const deleteHorseMediaActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_HORSE_MEDIA');
export type deleteHorseMediaActions = MakeCommunicationActions<typeof deleteHorseMediaActionTypes, {}>;

export const createHorseImageMediaActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_HORSE_IMAGE_MEDIA');
export type createHorseImageMediaActions = MakeCommunicationActions<typeof createHorseImageMediaActionTypes, {}>;

export const createHorseVideoMediaActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_HORSE_VIDEO_MEDIA');
export type createHorseVideoMediaActions = MakeCommunicationActions<typeof createHorseVideoMediaActionTypes, {}>;

export const updateHorseProfileTypeActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_HORSE_PROFILE_TYPE');
export type updateHorseProfileTypeActions = MakeCommunicationActions<
  typeof updateHorseProfileTypeActionTypes,
  {success: ProfileType}
>;

export const updateHorseAncestryProfileTypeActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_HORSE_ANCESTRY_PROFILE_TYPE'
);
export type updateHorseAncsetryProfileTypeActions = MakeCommunicationActions<
  typeof updateHorseAncestryProfileTypeActionTypes,
  {success: ProfileType}
>;

export const resetHorseAncestryProfileTypeActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'HORSE_ANCESTRY_PROFILE_TYPE'
);
export type resetHorseAncestryProfileTypeActions = MakeResetCommunicationActions<
  typeof resetHorseAncestryProfileTypeActionTypes
>;

export const resetHorseProfileTypeActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSE_PROFILE_TYPE');
export type resetHorseProfileTypeActions = MakeResetCommunicationActions<typeof resetHorseProfileTypeActionTypes>;

export const resetHorsesActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSES');
export type resetHorsesActions = MakeResetCommunicationActions<typeof resetHorsesActionTypes>;

export const resetHorseMediaActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSE_MEDIA');
export type resetHorseMediaActions = MakeResetCommunicationActions<typeof resetHorseMediaActionTypes>;

export const resetHorseDetailsActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSE_DETAILS');
export type resetHorseDetailsActions = MakeResetCommunicationActions<typeof resetHorseDetailsActionTypes>;

export const resetHorseOwnerActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSE_OWNER');
export type resetHorseOwnerActions = MakeResetCommunicationActions<typeof resetHorseOwnerActionTypes>;

export const resetHorseParentageActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSE_PARENTAGE');
export type resetHorseParentageActions = MakeResetCommunicationActions<typeof resetHorseParentageActionTypes>;

export const resetHorseRegistriesActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSE_REGISTRIES');
export type resetHorseRegistriesActions = MakeResetCommunicationActions<typeof resetHorseRegistriesActionTypes>;

export const resetHorseHealthIssuesActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSE_HEALTH_ISSUES');
export type resetHorseHealthIssuesActions = MakeResetCommunicationActions<typeof resetHorseHealthIssuesActionTypes>;

export type BusinessPortalHorseActions =
  | createHorseImageMediaActions
  | createHorseVideoMediaActions
  | getHorseMediaActions
  | getHorsesActions
  | createHorseActions
  | setHorseOwnerActions
  | getHorseDetailsActions
  | getHorseOwnerActions
  | getHorseParentageActions
  | getHorseRegistriesActions
  | getHorseHealthIssuesActions
  | updateHorseDetailsActions
  | updateHorseParentageActions
  | updateHorseRegistriesActions
  | updateHorseHealthIssuesActions
  | updateHorseAvatarActions
  | deleteHorseActions
  | deleteHorseMediaActions
  | deleteHorseAvatarActions
  | resetHorsesActions
  | resetHorseDetailsActions
  | resetHorseOwnerActions
  | resetHorseParentageActions
  | resetHorseRegistriesActions
  | resetHorseHealthIssuesActions
  | resetHorseMediaActions
  | updateHorseProfileTypeActions
  | resetHorseProfileTypeActions
  | updateHorseAncsetryProfileTypeActions
  | resetHorseAncestryProfileTypeActions;
