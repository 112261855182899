import React, {memo} from 'react';

import {SwitchButton} from 'Common/components/Controls/Buttons/SwitchButton';
import {SwitchFieldWrapper, SwitchWrapper} from 'Common/components/StyledComponents/StyledComponents';

interface IProps {
  id: number;
  isActive: boolean;
  description: string;
  onChange(value: boolean, id: number): void;
}

function GeneticDataVisibilitySwitcher(props: IProps) {
  const {description, id, isActive, onChange} = props;

  return (
    <SwitchWrapper className="d-flex flex-column">
      <div className="d-flex flex-row">
        <div className="align-self-center w-100">{description}</div>
        <SwitchFieldWrapper>
          <SwitchButton itemId={id} onChangeHandler={onChange} checked={isActive} />
        </SwitchFieldWrapper>
      </div>
    </SwitchWrapper>
  );
}

export default memo(GeneticDataVisibilitySwitcher);
