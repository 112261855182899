import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

import {IAdminUserPermissions} from 'Admin/AdminDashboard/models/User/IUserPermissions';

const STATE_NAME = 'userPermissions';

export interface IUserPermissionsState {
  data: {
    userPermissions: IAdminUserPermissions;
  };
  communications: {
    userPermissionsLoading: ICommunication;
    userPermissionsUpdating: ICommunication;
  };
}

export const getPermissionsByUserIdActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_PERMISSIONS_BY_USER_ID');

export const updatePermissionsByUserIdActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_PERMISSIONS_BY_USER_ID'
);

export type getPermissionsByUserIdActions = MakeCommunicationActions<
  typeof getPermissionsByUserIdActionTypes,
  {success: IAdminUserPermissions}
>;

export type updatePermissionsByUserIdActions = MakeCommunicationActions<
  typeof updatePermissionsByUserIdActionTypes,
  {}
>;

export type UserPermissionsActions = getPermissionsByUserIdActions | updatePermissionsByUserIdActions;
