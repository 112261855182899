import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminHorseTransfer;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectHorseTransfers(state: IAppState) {
  return selectState(state).data.horseTransfers;
}

export function selectHorseTransferDetails(state: IAppState) {
  return selectState(state).data.horseTransferDetails;
}
