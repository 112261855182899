import React, {memo} from 'react';

import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import NoData from 'BusinessPortal/components/common/NoData/NoData';
import {ILocation} from 'Common/models/ICoordinatedLocation';
import {Nullable} from 'Common/types';
import Map from 'Common/components/Map/Map';

interface IProps {
  location: Nullable<ILocation>;
}

function GeoLocationShow(props: IEditableSection<IProps>) {
  const {data} = props;

  if (!data?.location || (data?.location?.latitude === 0 && data?.location?.longitude === 0)) {
    return <NoData />;
  }

  const {location} = data;
  const isNoLocation = location.latitude === 0 && location.longitude === 0;

  return <>{!isNoLocation && <Map position={location} style={{maxWidth: 540}} />}</>;
}

export default memo(GeoLocationShow);
