import {combineReducers} from 'redux';

import {
  AdminEmployeesActions,
  changeAdminEmployeeStatusActionTypes,
  createAdminEmployeeActionTypes,
  deleteAdminEmployeeAvatarActionTypes,
  getAdminEmployeeActionTypes,
  getAdminEmployeesActionTypes,
  IAdminEmployeeState,
  resetAdminEmployeeActionTypes,
  updateAdminEmployeePasswordActionTypes,
  updateAdminEmployeeActionTypes,
  uploadAdminEmployeeAvatarActionTypes,
  updateAdminEmployeeEmailActionTypes,
  getAdminEmployeeGeoLocationActionTypes,
  updateAdminEmployeeGeoLocationActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

export const communicationAdminEmployeesReducer = combineReducers<
  IAdminEmployeeState['communications'],
  AdminEmployeesActions
>({
  adminEmployeesLoading: makeCommunicationReducerFromEnum(getAdminEmployeesActionTypes),
  adminEmployeeLoading: composeReducers([
    makeCommunicationReducerFromEnum(getAdminEmployeeActionTypes),
    makeResetCommunicationReducer(resetAdminEmployeeActionTypes.RESET),
  ]),
  adminEmployeeCreating: makeCommunicationReducerFromEnum(createAdminEmployeeActionTypes),
  adminEmployeeUpdating: makeCommunicationReducerFromEnum(updateAdminEmployeeActionTypes),
  adminEmployeeAvatarUploading: makeCommunicationReducerFromEnum(uploadAdminEmployeeAvatarActionTypes),
  adminEmployeeAvatarDeleting: makeCommunicationReducerFromEnum(deleteAdminEmployeeAvatarActionTypes),
  adminEmployeePasswordUpdating: makeCommunicationReducerFromEnum(updateAdminEmployeePasswordActionTypes),
  adminEmployeeStatusUpdating: makeCommunicationReducerFromEnum(changeAdminEmployeeStatusActionTypes),
  adminEmployeeEmailUpdating: makeCommunicationReducerFromEnum(updateAdminEmployeeEmailActionTypes),
  adminEmployeeGeoLocationLoading: makeCommunicationReducerFromEnum(getAdminEmployeeGeoLocationActionTypes),
  adminEmployeeGeoLocationUpdating: makeCommunicationReducerFromEnum(updateAdminEmployeeGeoLocationActionTypes),
});
