import * as R from 'ramda';
import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {IOrderPackageTest, ITestGroup} from 'Order/models/IOrderPackage';
import {IReportType} from 'Dictionaries/models/IReportType';
import {Divider} from 'Order/components/CreateOrder/common/styled';
import ExpandableContainer from 'Common/components/ExpandableContainer/ExpandableContainer';
import {packageSorter} from 'Order/constants/PackageSorter';
import {sortBySorter} from 'Common/helpers/Sorter/sortBySorter';

const Title = styled.div`
  font-family: ${Theme.font.secondary};
  font-size: ${Typography.size.size20};
  font-weight: ${Typography.weight.semiBold600};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${ColorPalette.black1};
`;

const Price = styled(Title)`
  color: ${ColorPalette.gray3};
`;

const TestGroupName = styled(Price)`
  font-size: ${Typography.size.size14};
  margin-top: 16px;
  margin-bottom: 4px;
`;

const TestName = styled(Title)`
  font-size: ${Typography.size.size16};
`;

const Section = styled.div`
  width: 100%;
  margin: 16px 0 8px 0;

  font-family: ${Theme.font.secondary};
  font-size: ${Typography.size.size18};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${ColorPalette.gray44};
`;

interface IProps {
  reportTypes: IReportType[];
  tests: IOrderPackageTest[];
}

function OrderPackageCardDetails(props: IProps) {
  const {tests, reportTypes} = props;

  const uniqGroups = R.uniq(R.chain<IOrderPackageTest, ITestGroup>(R.prop('groups'))(tests));
  const sortedUniqGroups = sortBySorter<ITestGroup>(packageSorter, uniqGroups);

  const otherTests = tests.filter((x) => x.groups.length === 0);

  return (
    <>
      <Divider />
      <ExpandableContainer buttonTitle="Click to See What's Included">
        <div id="tests-collapse-list">
          {reportTypes.length > 0 && (
            <>
              <Section>Reports Included:</Section>
              <TestName>{reportTypes.map((x) => x.name).join(', ')}</TestName>
            </>
          )}

          {(otherTests.length > 0 || sortedUniqGroups.length > 0) && (
            <Section style={{marginBottom: -4}}>Tests Included:</Section>
          )}

          {otherTests.length > 0 && (
            <>
              <TestGroupName>Other</TestGroupName>
              <div className="d-flex flex-column">
                {otherTests.map((otherTest, ind) => (
                  <TestName key={ind}>{otherTest.title || otherTest.name}</TestName>
                ))}
              </div>
            </>
          )}

          {sortedUniqGroups.map((group, i) => {
            const filteredTests = tests.filter((x) => x.groups.some((y) => y.id === group.id));

            return (
              <React.Fragment key={i}>
                <TestGroupName>{group.name}</TestGroupName>
                <div className="d-flex flex-column">
                  {filteredTests.map((filteredTest, ind) => (
                    <TestName key={ind}>{filteredTest.title || filteredTest.name}</TestName>
                  ))}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </ExpandableContainer>
    </>
  );
}

export default memo(OrderPackageCardDetails);
