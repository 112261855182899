import React, {useState, memo, useCallback, useEffect} from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

import Typography from 'Common/constants/Typography';
import Checkbox from 'Common/components/Controls/Checkbox';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ColorPalette from 'Common/constants/ColorPalette';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {breakpoints} from 'Common/constants/Breakpoints';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import Loading from 'Loading/components/Loading';
import {ModalWindowFooter, ModalWindowFormContent, ModalWindowHeader} from 'Common/components/Modal/shared';
import {Input} from 'Common/components/Controls';
import withDate from 'Common/helpers/withDate';
import {FRONTEND_DATE_TIME} from 'Common/constants/Date';
import Theme from 'Common/constants/Theme';

const AgreementLayout = styled.div`
  margin: 0;

  @media ${breakpoints.sm} {
    margin: 30px;
  }
`;

const TextLayout = styled.div`
  width: 100%;
  height: 46vh;

  max-height: 320px;

  @media ${breakpoints.sm} {
    max-height: 400px;
    height: 48vh;
  }

  @media ${breakpoints.md} {
    max-height: 548px;
    height: 50vh;
  }
`;

const BackgroundBottom = styled.div`
  width: calc(100% - 10px);
  @media ${breakpoints.sm} {
    width: calc(100% - 20px);
  }
  height: 58px;
  padding-right: 10px;
  bottom: 0;
  background: linear-gradient(
    180deg,
    ${ColorPalette.white1} 0%,
    ${ColorPalette.white3} 54.17%,
    ${ColorPalette.white0} 100%
  );
`;

const AgreementTitle = styled(ModalWindowHeader)`
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size24};
  }
`;

const Content = styled.div`
  p {
    font-size: ${Typography.size.size16};
    text-align: justify;
    line-height: 24px;
  }
`;

const ButtonsLayout = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 8px;

  @media ${breakpoints.sm} {
    grid-template-rows: auto auto;
    grid-column-gap: 16px;
  }
`;

const Button = styled(PrimaryButton)`
  width: 50%;
  min-width: 150px;

  @media ${breakpoints.sm} {
    width: unset;
    min-width: 168px;
  }
`;

const AcceptButton = styled(Button)`
  @media ${breakpoints.sm} {
    margin-right: 30px;
  }
`;

const Footer = styled(ModalWindowFooter)`
  padding-top: 16px;
`;

const FieldsLayout = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`;

const Label = styled.div`
  color: ${Theme.color.black};
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: ${Typography.size.size16};
  margin-bottom: 8px;
`;

interface IProps {
  agreementText: string;

  onAccept?(sign: string): void;
  onCancel?(): void;
}

function Agreement(props: IProps) {
  const {agreementText, onAccept, onCancel} = props;
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [isEmptyValue, setIsEmptyValue] = useState(true);

  const handleCheckboxClick = (event: React.FormEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked);
  };

  const {isMobile, isTablet} = useMediaQuery();
  const maxHeight = isTablet ? 400 : isMobile ? 300 : 548;
  const scrollsHeight = isTablet ? '48vh' : isMobile ? '46vh' : '50vh';

  const htmlContent = parse(DOMPurify.sanitize(agreementText));

  useEffect(() => {
    const trimmedName = nameValue ? nameValue.trim() : '';

    setIsEmptyValue(!!!trimmedName);
  }, [nameValue]);

  const handleAccept = useCallback(() => {
    let isMounted = true;

    async function action() {
      setIsLoading(true);
      onAccept && (await onAccept(nameValue));
      if (isMounted) {
        setIsLoading(false);
      }
    }

    action();
    isMounted = false;
  }, [nameValue, onAccept]);

  const handleCancel = useCallback(() => {
    let isMounted = true;

    async function action() {
      setIsLoading(true);
      onCancel && (await onCancel());
      if (isMounted) {
        setIsLoading(false);
      }
    }

    action();
    isMounted = false;
  }, [onCancel]);

  const onNameInputChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setNameValue(event.currentTarget.value);
  }, []);

  const currentDate = withDate(new Date().toString(), FRONTEND_DATE_TIME);

  return (
    <AgreementLayout>
      {isLoading && <Loading />}

      <AgreementTitle>Etalon Confidentiality Agreement and Release</AgreementTitle>
      <ModalWindowFormContent>
        <TextLayout className="position-relative">
          <Scrollbar style={{height: scrollsHeight, maxHeight}} autoHeight={false}>
            <Content>{htmlContent}</Content>
            <br />
          </Scrollbar>

          <BackgroundBottom className="position-absolute" />
        </TextLayout>
      </ModalWindowFormContent>

      {onAccept && onCancel && (
        <Footer className="d-flex flex-column">
          <div className="align-self-start w-100">
            <Checkbox
              id="agree"
              label="I have read and agree to the terms and conditions"
              checked={isChecked}
              onChange={handleCheckboxClick}
              style={{marginBottom: 8}}
            />
            <FieldsLayout className="w-100">
              <Input
                value={nameValue}
                placeholder="Type your name"
                autoComplete="off"
                onChange={onNameInputChange}
                className="w-100"
                maxLength={25}
              />

              <div className="d-flex justify-content-between">
                <Label>Current date</Label>
                <Label>{currentDate || ''}</Label>
              </div>
            </FieldsLayout>
          </div>

          <ButtonsLayout className="align-self-end">
            <AcceptButton onClick={handleAccept} size="medium" disabled={!isChecked || isEmptyValue}>
              Accept
            </AcceptButton>
            <Button onClick={handleCancel} size="medium" variant="outlined">
              Cancel
            </Button>
          </ButtonsLayout>
        </Footer>
      )}
    </AgreementLayout>
  );
}

export default memo(Agreement);
