import React, {memo} from 'react';
import styled from 'styled-components';

import {ModalWindowFormContent, ModalWindowHeader} from 'Common/components/Modal/shared';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import Loading from 'Loading/components/Loading';
import {GeneType} from 'Admin/AdminPhenotypes/constants/GeneType';
import {IRequiredTest} from 'Admin/AdminDashboard/models/IRequiredTest';

const CommonInfoWrapper = styled.div`
  margin-right: 80px;
`;

const CommonSection = styled.div`
  margin-bottom: 24px;
`;

const RequiredTests = styled.div`
  margin-bottom: 48px;
`;

const RequiredTestsSection = styled.div`
  margin: 24px 0;
`;

const Label = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  color: ${Theme.color.gray};
`;

const Value = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;

const Header = styled.div`
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.black0};
  margin-bottom: 16px;
  text-transform: uppercase;
`;

const Test = styled(Value)<{position: number}>`
  background-color: ${({position}) => (position % 2 === 0 ? ColorPalette.gray49 : ColorPalette.white0)};
  padding: 6px 12px 6px 10px;
  word-break: break-all;
  margin-right: 30px;
  border-radius: 4px;

  font-size: ${Typography.size.size14};
  line-height: 20px;
`;

interface IProps {
  horseName?: string;
  ownerName?: string;
  requiredTests: IRequiredTest[];
  isLoading?: boolean;
}

const RequiredTestsList = (props: IProps) => {
  const {horseName, ownerName, requiredTests, isLoading} = props;

  const inconclusiveTestsGroup = requiredTests.filter((x) => x.state === GeneType.Inconclusive);
  const notPerformedTestsGroup = requiredTests.filter((x) => x.state === GeneType.NotTested);

  const isInconclusiveTestsGroup = inconclusiveTestsGroup && inconclusiveTestsGroup.length > 0;
  const isNotPerformedTestsGroup = notPerformedTestsGroup && notPerformedTestsGroup.length > 0;

  return (
    <>
      <ModalWindowHeader>Incomplete tests</ModalWindowHeader>
      <ModalWindowFormContent>
        {(horseName || ownerName) && (
          <CommonSection className="d-flex flex-row">
            {horseName && (
              <CommonInfoWrapper>
                <Label>Horse</Label>
                <Value>{horseName}</Value>
              </CommonInfoWrapper>
            )}
            {ownerName && (
              <CommonInfoWrapper>
                <Label>Owner</Label>
                <Value>{ownerName}</Value>
              </CommonInfoWrapper>
            )}
          </CommonSection>
        )}

        <RequiredTests>
          {isLoading && <Loading />}

          {isInconclusiveTestsGroup && (
            <RequiredTestsSection>
              <Header>Inconclusive tests:</Header>
              {inconclusiveTestsGroup.map((test, i) => (
                <Test key={i} position={i}>
                  {test.name}
                </Test>
              ))}
            </RequiredTestsSection>
          )}
          {isNotPerformedTestsGroup && (
            <RequiredTestsSection>
              <Header>Not performed tests:</Header>
              {notPerformedTestsGroup.map((test, i) => (
                <Test key={i} position={i}>
                  {test.name}
                </Test>
              ))}
            </RequiredTestsSection>
          )}
        </RequiredTests>
      </ModalWindowFormContent>
    </>
  );
};

export default memo(RequiredTestsList);
