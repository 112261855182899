import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';

import {IChangeEmail} from 'Admin/AdminDashboard/services/types/user';

const STATE_NAME = 'adminChangePassword';

export interface IAdminChangePasswordState {
  communications: {
    changeUserPasswordUpdating: ICommunication<IChangeEmail>;
  };
}

export const changePasswordActionTypes = makeCommunicationActionType(STATE_NAME, 'CHANGE_PASSWORD');

export type changePasswordActions = MakeCommunicationActions<typeof changePasswordActionTypes, {}>;
