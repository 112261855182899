import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.auth;
}

export function selectVisitorType(state: IAppState) {
  return selectState(state).data.visitorType;
}

export const selectCommunication = makeCommunicationSelector(selectState);
