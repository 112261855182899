import React, {memo, useCallback} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon, {IColoredIconProps} from 'Icon/components/ColoredIcon';
import Theme from 'Common/constants/Theme';

const SearchBarInput = styled.input`
  width: 100%;
  height: 48px;
  padding: 0 14px 0 50px;
  border: none;
  border-radius: 4px;
  color: ${Theme.color.black};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  font-family: ${Theme.font.primary};
  background: ${ColorPalette.gray49};

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${ColorPalette.gray44};
  }
`;

const SearchIcon = styled(ColoredIcon)`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

interface IProps {
  value: string;
  onChange(value: string): void;
  placeholder?: string;
  logoStyle?: Pick<IColoredIconProps, 'size' | 'color'>;
  style?: React.CSSProperties;
}

function SearchBar(props: IProps) {
  const {onChange: setValue, value, placeholder, style, logoStyle} = props;

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setValue(e.currentTarget.value), [setValue]);

  return (
    <div className="position-relative">
      <SearchIcon name={IconName.Search} size={logoStyle?.size} color={logoStyle?.color} stroke={false} fill={true} />
      <SearchBarInput value={value} onChange={onChange} placeholder={placeholder} style={style} />
    </div>
  );
}

SearchBar.defaultProps = {
  logoStyle: {size: 16, color: ColorPalette.gray44},
  placeholder: 'Search'
};

export default memo(SearchBar);
