import {combineReducers} from 'redux';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  addExcludeHorseActionTypes,
  deleteExcludeHorseActionTypes,
  ExcludeHorsesActions,
  getExcludeHorsesActionTypes,
  getSimpleExcludeHorsesActionTypes,
  IExcludeHorseState,
} from '../types';

export const communicationReducer = combineReducers<IExcludeHorseState['communications'], ExcludeHorsesActions>({
  excludeHorsesLoading: makeCommunicationReducerFromEnum(getExcludeHorsesActionTypes),
  excludeHorseAdding: makeCommunicationReducerFromEnum(addExcludeHorseActionTypes),
  excludeHorseDeleting: makeCommunicationReducerFromEnum(deleteExcludeHorseActionTypes),
  simpleExcludeHorsesLoading: makeCommunicationReducerFromEnum(getSimpleExcludeHorsesActionTypes),
});
