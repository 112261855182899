import * as Yup from 'yup';
import {IRequestChooseAnotherSample} from 'Admin/AdminDashboard/store/adminOrders/types';
import {ISampleAdmin} from 'Admin/AdminDashboard/models/Order/IOrderHorseDetailsAdmin';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export const convertSampleToChooseRequestSample = (
  orderId: number,
  sample: ISampleAdmin
): IRequestChooseAnotherSample => ({
  orderId,
  currentSampleId: sample.sampleId,
  targetSampleId: sample.sampleId
});

export const initialValue: IRequestChooseAnotherSample = {
  orderId: 0,
  currentSampleId: '',
  targetSampleId: ''
};

export const validationSchema = Yup.object().shape<IRequestChooseAnotherSample>({
  orderId: Yup.number().required(),
  currentSampleId: Yup.string().required(),
  targetSampleId: Yup.string().required(REQUIRED_FIELD)
});
