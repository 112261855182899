import {combineReducers, Reducer} from 'redux';

import {dataStripeSubscriptionReducer} from 'StripeSubscription/store/reducer/data';
import {communicationStripeSubscriptionReducer} from 'StripeSubscription/store/reducer/communications';
import {IStripeSubscriptionState} from 'StripeSubscription/store/types';

export const stripeSubscriptionReducer: Reducer<IStripeSubscriptionState> = combineReducers<IStripeSubscriptionState>({
  communications: communicationStripeSubscriptionReducer,
  data: dataStripeSubscriptionReducer
});
