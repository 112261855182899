import {useEffect, useState} from 'react';
import _ from 'lodash';

import {IFoalModifier} from 'Common/models/IFoal';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';

export function useSortedModifiers(modifiers: IFoalModifier[]) {
  const [requiredModifiers, setRequiredModifiers] = useState<IFoalModifier[]>([]);
  const [singleModifiers, setSingleModifiers] = useState<IFoalModifier[]>([]);
  const [severalModifiers, setSeveralModifiers] = useState<IFoalModifier[]>([]);

  const [extModifiers, setExtModifiers] = useState<IFoalModifier[]>([]);

  useEffect(() => {
    const groupedModifiersByTestGroup = _(modifiers)
      .groupBy(modifier => modifier.dependencies?.testGroup)
      .entries()
      .value();
    const required: IFoalModifier[] = [];
    const single: IFoalModifier[] = [];
    const several: IFoalModifier[] = [];

    groupedModifiersByTestGroup.forEach(([key, value]) => {
      if (value.length === 1 && value[0].probability === 100) {
        required.push(value[0]);
      } else if (key === 'null' || value.length < 2) {
        single.push(...value);
      } else {
        several.push(...value.sort((a, b) => sortByStringKey(b, a, 'probability')));
      }
    });

    setRequiredModifiers(required);
    setSingleModifiers(single.sort((a, b) => sortByStringKey(b, a, 'probability')));
    setSeveralModifiers(several);
  }, [modifiers]);

  useEffect(() => {
    if (requiredModifiers && singleModifiers && severalModifiers)
      setExtModifiers([...requiredModifiers, ...severalModifiers, ...singleModifiers]);
  }, [requiredModifiers, singleModifiers, severalModifiers]);

  return {
    extModifiers,
    requiredModifiers,
    singleModifiers,
    severalModifiers
  };
}
