import {Reducer} from 'redux';

import {
  AdminUsersActions,
  getUserByIdActionTypes,
  getUsersActionTypes,
  IAdminUsersState,
  resetUserDetailsActionTypes,
} from '../types';

const initialStateList: IAdminUsersState['data'] = {
  users: [],
  userDetails: null,
};

export const dataUsersReducer: Reducer<IAdminUsersState['data'], AdminUsersActions> = (
  state = initialStateList,
  action: AdminUsersActions
) => {
  switch (action.type) {
    case getUsersActionTypes.SUCCESS: {
      return {...state, users: action.payload.data};
    }
    case resetUserDetailsActionTypes.RESET:
    case getUserByIdActionTypes.REQUEST: {
      return {...state, userDetails: initialStateList.userDetails};
    }
    case getUserByIdActionTypes.SUCCESS: {
      return {...state, userDetails: action.payload};
    }
    default: {
      return state;
    }
  }
};
