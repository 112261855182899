import {Reducer} from 'redux';

import {
  IBusinessPortalEmployeeProfileState,
  EmployeeActions,
  getEmployeeGeoLocationActionTypes,
  getEmployeeProfileActionTypes,
} from '../types';

const initialState: IBusinessPortalEmployeeProfileState['data'] = {
  employeeProfile: null,
  geoLocation: {latitude: 0, longitude: 0},
};

export const dataReducer: Reducer<IBusinessPortalEmployeeProfileState['data'], EmployeeActions> = (
  state = initialState,
  action: EmployeeActions
) => {
  switch (action.type) {
    case getEmployeeProfileActionTypes.SUCCESS: {
      return {...state, employeeProfile: action.payload};
    }
    case getEmployeeGeoLocationActionTypes.SUCCESS: {
      return {...state, geoLocation: action.payload};
    }

    default: {
      return state;
    }
  }
};
