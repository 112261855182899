import React, {useCallback, useState} from 'react';
import styled from 'styled-components';

import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';

import Theme from 'Common/constants/Theme';
import {breakpoints} from 'Common/constants/Breakpoints';
import {BaseStepLayoutChildrenProps} from 'BusinessPortal/components/BusinessPortalDashboard/Orders/CreateOrder/parts/BaseStepLayout';
import Input from 'Common/components/Controls/Input';

const CodeFieldContainer = styled.div`
  margin-top: 7px;

  @media ${breakpoints.sm} {
    margin-top: 16px;
  }
`;

const CodeFieldHeader = styled.div`
  font-weight: ${Typography.weight.medium500};
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  color: ${ColorPalette.gray44};
`;

const CodeField = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  @media ${breakpoints.sm} {
    flex-direction: row;
  }
`;

const ApplyCodeButton = styled(PrimaryButton)`
  @media ${breakpoints.sm} {
    margin-left: 16px;
  }
`;

const CodeInput = styled(Input)`
  width: 100%;

  @media ${breakpoints.sm} {
    width: 280px;
  }
`;

interface IProps {
  coupons: IOrderCoupon[];
  isLoading?: boolean;
  onApplyCode(code: string): void;
}

type AllProps = IProps & BaseStepLayoutChildrenProps;

function AssociationCreateOrderDiscount(props: React.PropsWithChildren<AllProps>) {
  const {children} = props;
  const {coupons, onApplyCode, isLoading} = props;

  const [code, setCode] = useState('');

  const onInputChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    if (!event.currentTarget.value) {
      setCode('');
      return;
    }

    setCode(event.currentTarget.value);
  }, []);

  const onApplyCodeHandler = useCallback(() => onApplyCode(code), [code, onApplyCode]);

  const codeAlreadyExist = coupons.some((i) => i.code.toLowerCase() === code.toLowerCase());

  return (
    <div>
      {children}

      <CodeFieldContainer>
        <CodeFieldHeader>Code</CodeFieldHeader>
        <CodeField className="d-flex">
          <CodeInput placeholder="Put your coupon or gift card code" autoComplete="off" onInput={onInputChange} />
          <ApplyCodeButton
            type="button"
            variant="outlined"
            disabled={!code || codeAlreadyExist}
            isLoading={isLoading}
            onClick={onApplyCodeHandler}
          >
            Apply
          </ApplyCodeButton>
        </CodeField>
      </CodeFieldContainer>
    </div>
  );
}

export default AssociationCreateOrderDiscount;
export type AssociationCreateOrderDiscountProps = IProps;
