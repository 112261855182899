import React, {useCallback, useState} from 'react';

import {Nullable} from 'Common/types';
import WarningModalWindow, {ModalTypes} from 'Common/components/Modal/WarningModal';

import {actions as confirmationActions} from 'MailConfirmation/store/requestNewMailConfirmation';
import {useBindActions} from 'Common/helpers/hooks/useBindActions';
import styled from 'styled-components';
import Typography from 'Common/constants/Typography';
import {ICommunication} from 'Common/store/utils/communication';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';

const CONFIRM_SEND_NEW_VERIFICATION = 'Are you sure you want to send new verification email to user?';

const ResultMessage = styled.div`
  text-align: center;
  font-size: ${Typography.size.size16};
`;

interface Props {
  onSuccess?(): void;
  communication: ICommunication;
}

export function useSendVerificationModal(props: Props) {
  const {onSuccess, communication} = props;
  const {sendVerification} = useBindActions({sendVerification: confirmationActions.requestNewMailConfirmation});

  const [email, setEmail] = useState<Nullable<string>>(null);

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isResultOpen, setIsResultOpen] = useState(false);

  const openConfirmModal = useCallback((email: string) => {
    setEmail(email);
    setIsConfirmOpen(true);
  }, []);
  const cancelResendModal = useCallback(() => {
    setIsConfirmOpen(false);
    setEmail(null);
  }, []);
  const confirmAction = useCallback(async () => {
    setIsConfirmOpen(false);
    email && sendVerification(email);
  }, [email, sendVerification]);

  useOnSuccessCommunication(communication, () => {
    setIsResultOpen(true);
    onSuccess && onSuccess();
  });

  useOnErrorCommunication(communication, () => {
    setIsResultOpen(true);
  });

  const hasError = !!communication.error;
  const error = hasError ? getCommonErrors(communication.error) || `Unknown error` : null;

  const closeSuccessModal = useCallback(() => {
    setIsConfirmOpen(false);
    setIsResultOpen(false);
    setEmail(null);
  }, []);

  const confirmSendVerificationModal = (
    <WarningModalWindow
      modalType={ModalTypes.Confirm}
      isOpen={isConfirmOpen}
      onClose={cancelResendModal}
      onSuccess={confirmAction}
    >
      {CONFIRM_SEND_NEW_VERIFICATION}
    </WarningModalWindow>
  );

  const resultResendVerificationModal = (
    <WarningModalWindow
      modalType={ModalTypes.Alert}
      isOpen={isResultOpen}
      onClose={closeSuccessModal}
      onSuccess={closeSuccessModal}
      modalTitle="Email sent"
      successButtonText="Got it!"
    >
      <ResultMessage>
        {hasError ? (
          <>
            <div>
              Error on resending verification to <b>{email}</b>
            </div>
            <div>{error}</div>
          </>
        ) : (
          <div>
            Verification email has been successfully sent to <b>{email}</b>
          </div>
        )}
      </ResultMessage>
    </WarningModalWindow>
  );

  const sendVerificationModals = (
    <>
      {confirmSendVerificationModal}
      {resultResendVerificationModal}
    </>
  );

  return {
    openSendVerificationModal: openConfirmModal,
    sendVerificationModals
  };
}
