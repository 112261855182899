export default {
  size: {
    size7: '0.4375rem',
    size8: '0.5rem',
    size9: '0.5625rem',
    size10: '0.625rem',
    size11: '0.6875rem',
    size12: '0.75rem',
    size13: '0.8125rem',
    size14: '0.875rem',
    size15: '0.9375rem',
    size16: '1rem',
    size17: '1.0625rem',
    size18: '1.125rem',
    size19: '1.1875rem',
    size20: '1.25rem',
    size22: '1.375rem',
    size23: '1.4375rem',
    size24: '1.5rem',
    size25: '1.5625rem',
    size28: '1.75rem',
    size30: '1.875rem',
    size32: '2rem',
    size33: '2.0625rem',
    size35: '2.1875rem',
    size40: '2.5rem',
    size50: '3.125rem',
    size74: '4.625rem',
  },
  weight: {
    light300: '300',
    normal400: '400',
    medium500: '500',
    semiBold600: '600',
    bold700: '700',
  },
  family: {
    openSans: 'Open Sans',
    roboto: 'Roboto',
    sourceSansPro: 'Source Sans Pro',
    ubuntu: 'Ubuntu',
  },
  symbols: {
    infinity: '∞',
    whitespace: '\u00A0',
  },
};
