import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

import {IForgotPassword} from 'ForgotPassword/models/IForgotPassword';

const STATE_NAME = 'forgotPassword';

export interface IForgotPasswordState {
  data: {
    message: string | null;
  };
  communications: {
    messageLoading: ICommunication<IForgotPassword>;
  };
}

export const ForgotPasswordActionTypes = makeCommunicationActionType(STATE_NAME, 'FORGOT_PASSWORD');
export type forgotPasswordActions = MakeCommunicationActions<typeof ForgotPasswordActionTypes, {success: string}>;
