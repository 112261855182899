import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminPackages;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectPackages(state: IAppState) {
  return selectState(state).data.packages;
}

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectPackageDetails(state: IAppState) {
  return selectState(state).data.packageDetail;
}
