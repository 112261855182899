import styled from 'styled-components';
import Icon from 'Icon/components/Icon';
import {breakpoints} from 'Common/constants/Breakpoints';
import ColorPalette from 'Common/constants/ColorPalette';
import {ScreenSize} from 'Common/constants/ScreenSize';
import {zIndex} from 'Common/constants/ZIndex';

export const HeaderFiltersToggleIcon = styled(Icon)`
  width: 24px;
  margin-left: 18px;
  cursor: pointer;

  @media ${breakpoints.sm} {
    display: none;
  }
`;
export const Filters = styled.div<{isOpen?: boolean}>`
  padding: 32px;
  padding-bottom: 92px;

  @media ${breakpoints.sm} {
    padding-bottom: 32px;
  }
`;

export const FiltersContainer = styled.div<{isHidden?: boolean; currentSize: ScreenSize}>`
  position: relative;
  flex-grow: 1;
  background: ${ColorPalette.gray38};
  max-width: 374px;
  width: 100%;
  height: auto;

  ${(p) =>
    p.currentSize === 'mobile'
      ? `
    max-width: unset;
    
    ${
      p.isHidden
        ? `
      width: 0;
      height: 0;

      ${Filters} {
        display: none;
      }
    `
        : `
      position: fixed;
      top: 56px;
      height: calc(100% - 56px);
      width: 100%;
      z-index: ${zIndex.mobileHorseFilters};
      overflow: auto;
    `
    }`
      : ``}

  ${(p) =>
    p.currentSize === 'tablet'
      ? p.isHidden
        ? `
      width: 0;
      height: 0;
      margin-right: 48px;

      ${Filters} {
        display: none;
      }
    `
        : `
    margin-right: 0px;
    `
      : ``}
`;

export const PlaceholderContainer = styled.div`
  margin: 64px auto 0;
  max-width: 660px;
`;
