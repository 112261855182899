import React, {memo} from 'react';
import {Helmet} from 'react-helmet';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import {ITab} from 'Common/components/Tabs/ITab';
import AdminTabs from 'Admin/common/AdminTabs';
import AdminContent from 'Admin/common/AdminContent';
import Administrators from '../components/Administrators/Administrators';
import Emergencies from '../components/Emergencies/Emergencies';

const basePath = '/admin-advanced';

const data: ITab[] = [
  {section: 'admins', title: 'Admins', Component: Administrators},
  {section: 'emergencies', title: 'Emergencies', Component: Emergencies}
];

const AdminAdvancedDashboardLayout = () => {
  return (
    <BaseLayout withoutPaddings={true} widthByContent={true} isBackButtonDenied={true}>
      <Helmet>
        <title>Admin advanced dashboard</title>
      </Helmet>

      <AdminTabs tabs={data} basePath={basePath} />
      <AdminContent tabs={data} basePath={basePath} />
    </BaseLayout>
  );
};

export default memo(AdminAdvancedDashboardLayout);
