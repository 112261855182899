import * as Yup from 'yup';

import {SampleRequestReason} from './SampleRequestReason';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IRequestSampleForm {
  reasons?: SampleRequestReason[];
  otherReason?: string;
  description?: string;
}

export const validationSchema = Yup.object().shape<IRequestSampleForm>({
  reasons: Yup.array().of(Yup.mixed<SampleRequestReason>()).required(REQUIRED_FIELD),
  otherReason: Yup.string().notRequired(),
  description: Yup.string().notRequired(),
});

export const initialValue: IRequestSampleForm = {
  otherReason: '',
  description: '',
};
