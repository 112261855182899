import React, {memo} from 'react';
import {IAdminGene} from 'Admin/AdminPhenotypes/models/IAdminGene';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import GenesForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/Genes/GeneForm/GeneForm';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout, {
  DictionaryFormProps
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';
import {IDictionary} from 'DictionaryFactory/types';
import {IUpdateGeneRequest} from 'Admin/AdminPhenotypes/service/models/IGene';

function Genes() {
  const {adminGene: dictionary, geneGroups} = useDictionaries();

  const wrappedGeneForm = (props: DictionaryFormProps<IDictionary<IAdminGene, IUpdateGeneRequest>>) => {
    return <GenesForm {...props} geneGroupDictionary={geneGroups} />;
  };

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={wrappedGeneForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IAdminGene> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
            <Cell<IAdminGene> dataKey="name" width="20%" header="Name" render={({name}) => name} />
            <Cell<IAdminGene>
              dataKey="groups"
              sortable={false}
              width="20%"
              header="Groups"
              render={({groups}) => groups.map(x => x.name).join(', ')}
            />
            <Cell<IAdminGene> dataKey="title" width="20%" header="Title" sortable={false} render={({title}) => title} />
            <Cell<IAdminGene>
              dataKey="description"
              width="30%"
              header="Description"
              sortable={false}
              render={({description}) => description?.value}
            />
            <Cell<IAdminGene>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(Genes);
