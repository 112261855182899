import * as Yup from 'yup';
import {IEntity} from 'Common/models/IEntity';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {Nullable} from 'Common/types';

export interface IFormValues {
  id?: IEntity['id'];
  name: string;
  description: string;
  color: Nullable<string>;
  hasAffection: boolean;
}

export const initialValue: IFormValues = {
  name: '',
  description: '',
  color: null,
  hasAffection: false
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  hasAffection: Yup.boolean().required(REQUIRED_FIELD)
});
