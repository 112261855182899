import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {Nullable} from 'Common/types';
import {IUpdateGenotypeRequest} from 'Admin/AdminPhenotypes/service/models/IServerGenotype';
import {IIndexed} from 'Common/models/IIndexed';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues extends IIndexed {
  id?: IEntity['id'];
  alleleNames: string;
  description: string;
  trait: Nullable<number>;
}

export const initialValue: IFormValues = {
  alleleNames: '',
  description: '',
  trait: null,
  positionIndex: 0,
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  alleleNames: Yup.string().required(REQUIRED_FIELD),
  description: Yup.string(),
  trait: Yup.number().nullable().required(REQUIRED_FIELD),
  positionIndex: Yup.number().required(REQUIRED_FIELD),
});

export const convertToServer = (values: IFormValues): Omit<IUpdateGenotypeRequest, 'id'> => {
  return {
    ...values,
    trait: values.trait ? {id: values.trait} : null,
  };
};

export const convertToServerUpdating = (values: IFormValues): IUpdateGenotypeRequest => ({
  ...convertToServer(values),
  id: values.id!,
});
