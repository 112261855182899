import {Reducer} from 'redux';

import {IMailConfirmationState, mailConfirmationActions, MailConfirmationActionTypes} from '../types';

const initialState: IMailConfirmationState['data'] = {
  message: null
};

export const dataMailConfirmationReducer: Reducer<IMailConfirmationState['data'], mailConfirmationActions> = (
  state = initialState,
  action: mailConfirmationActions
) => {
  switch (action.type) {
    case MailConfirmationActionTypes.SUCCESS: {
      return {...state, message: action.payload};
    }
    default: {
      return state;
    }
  }
};
