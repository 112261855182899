import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import GeneticDataVisibility from 'Admin/AdminDashboard/components/Horses/GeneticDataVisibility/GeneticDataVisibility';

interface IGeneticDataVisibilityModalParams {
  onSuccess?(): void;
}

export function useGeneticDataVisibilityModal(params: IGeneticDataVisibilityModalParams) {
  const {onSuccess} = params;

  const [horseId, setHorseId] = useState(0);
  const [horseName, setHorseName] = useState('');
  const [isGeneticDataHidden, setisGeneticDataHidden] = useState(false);
  const [isAncestryReportHidden, setisAncestryReportHidden] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const openGeneticDataVisibilityModal = useCallback(
    (horseId: number, horseName: string, isGeneticDataHidden: boolean, isAncestryReportHidden: boolean) => {
      setHorseId(horseId);
      setHorseName(horseName);
      setisGeneticDataHidden(isGeneticDataHidden);
      setisAncestryReportHidden(isAncestryReportHidden);
      setIsOpen(true);
    },
    []
  );
  const closeGeneticDataVisibilityModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeGeneticDataVisibilityModal();
    onSuccess && onSuccess();
  }, [closeGeneticDataVisibilityModal, onSuccess]);

  const geneticDataVisibilityModal = (
    <ModalWindow isOpen={isOpen} onClose={handleSuccess}>
      <GeneticDataVisibility
        horseId={horseId}
        horseName={horseName}
        isGeneticDataHidden={isGeneticDataHidden}
        isAncestryReportHidden={isAncestryReportHidden}
        onSuccess={handleSuccess}
      />
    </ModalWindow>
  );

  return {
    geneticDataVisibilityModal,
    openGeneticDataVisibilityModal,
  };
}
