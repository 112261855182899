import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {updatePasswordActionTypes, IChangePasswordState, ChangePasswordActions} from '../types';

export const communicationChangePasswordReducer = combineReducers<
  IChangePasswordState['communications'],
  ChangePasswordActions
>({
  changePasswordLoading: makeCommunicationReducerFromEnum(updatePasswordActionTypes),
});
