import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
} from 'Common/store/utils/communication/index';

import {IServiceMode} from '../models/IServiceMode';

const STATE_NAME = 'serviceMode';

export interface IServiceModeState {
  data: {
    serviceMode: IServiceMode;
  };
  communications: {
    serviceModeLoading: ICommunication;
  };
}

export const getServiceModeActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_SERVICE_MODE');
export type getServiceModeActions = MakeCommunicationActions<typeof getServiceModeActionTypes, {success: IServiceMode}>;

export type ServiceModeActions = getServiceModeActions;
