import React, {memo} from 'react';
import {IModifierCombination} from 'Admin/AdminPhenotypes/models/IModifierCombination';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import ModifierCombinationsForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/ModifierCombinations/ModifierCombinationForm/ModifierCombinationForm';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';
import {HAS_COLOR_IMAGE_ICON, NO_COLOR_IMAGE_ICON} from '../../share/StyledComponents';
import {getRuleIcon} from 'Admin/AdminPhenotypes/helpers/getRuleIcon';

function ModifierCombinations() {
  const {adminModifierCombination: dictionary} = useDictionaries();

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={ModifierCombinationsForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IModifierCombination> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
            <Cell<IModifierCombination> dataKey="name" width="20%" header="Name" render={({name}) => name} />
            <Cell<IModifierCombination>
              header="Image"
              render={({image}) => (image ? HAS_COLOR_IMAGE_ICON : NO_COLOR_IMAGE_ICON)}
              width="5%"
            />
            <Cell<IModifierCombination> header="Rule" render={({sequences}) => getRuleIcon(sequences)} width="5%" />
            <Cell<IModifierCombination>
              dataKey="positionIndex"
              width="10%"
              header="Order in Report"
              sortable={false}
              render={({positionIndex}) => positionIndex}
            />
            <Cell<IModifierCombination>
              dataKey="description"
              width="50%"
              header="Description"
              sortable={false}
              render={({description}) => description?.value}
            />
            <Cell<IModifierCombination>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(ModifierCombinations);
