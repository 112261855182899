import React, {memo, useMemo} from 'react';

import {OrderStatus} from 'Common/constants/OrderStatus';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {IMenuItem, IOrderedMenuItem} from 'Common/components/DropDown/common/types';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import AdminActions from 'Admin/AdminDashboard/components/shared/AdminActions/AdminActions';
import {IOrderStatused} from 'Common/models/IOrderStatused';
import {OrderStatusField} from 'Order/models/IOrderStatusFields';
import {IRequestChangeReviewStatus} from 'Admin/AdminDashboard/store/adminOrders/types';

enum OrderMenuItemValue {
  OpenLiveReport = 'openLiveReport',
  ReviewLiveReport = 'reviewLiveReport',
  SubmissionForm = 'submissionForm',
  UpdatePayment = 'updatePayment',
  SampleNotReceived = 'sampleNotReceived',
  SampleReceived = 'sampleReceived',
  RequestNewSample = 'requestNewSample',
  DismissReview = 'dismissReview',
  DeleteOrder = 'deleteOrder',
}

const iconProps = {color: ColorPalette.red7};
const deleteIconProps = {name: IconName.Garbage, color: ColorPalette.gray44};
const deleteTextProps = {color: ColorPalette.gray44, hoverColor: ColorPalette.red9, activeColor: ColorPalette.red11};

const commonMenuItems: Record<OrderMenuItemValue, Omit<IOrderedMenuItem, 'value'>> = {
  [OrderMenuItemValue.OpenLiveReport]: {
    label: 'Open live report',
    icon: {name: IconName.Notebook, ...iconProps, fill: false, stroke: true},
    id: 2,
  },
  [OrderMenuItemValue.ReviewLiveReport]: {
    label: 'Review live report',
    icon: {name: IconName.InsertComment, ...iconProps},
    id: 2,
  },
  [OrderMenuItemValue.SubmissionForm]: {
    label: 'Submission form',
    icon: {name: IconName.Description, ...iconProps},
    id: 3,
  },
  [OrderMenuItemValue.UpdatePayment]: {label: 'Update payment', icon: {name: IconName.Money, ...iconProps}, id: 4},
  [OrderMenuItemValue.SampleNotReceived]: {
    label: 'Sample not received',
    icon: {name: IconName.Truck, ...iconProps},
    id: 5,
  },
  [OrderMenuItemValue.SampleReceived]: {
    label: 'Mark sample as received',
    icon: {name: IconName.Truck, ...iconProps},
    id: 5,
  },
  [OrderMenuItemValue.RequestNewSample]: {
    label: 'Resubmit sample',
    icon: {name: IconName.Truck, ...iconProps},
    id: 5,
  },
  [OrderMenuItemValue.DismissReview]: {
    label: 'Dismiss review',
    icon: {name: IconName.InsertComment, ...iconProps},
    id: 99,
  },
  [OrderMenuItemValue.DeleteOrder]: {
    label: 'Delete order',
    icon: {...deleteIconProps},
    style: {...deleteTextProps},
    divided: true,
    id: 100,
  },
};

interface IExternalProps {
  orderId: number;
  horseId: number;
  statuses: IOrderStatused;
  deleteOrderHandler(orderId: number): void;
  openLiveReportHandler(horseId: number, orderId?: number): void;
  submissionFormHandler(orderId: number): void;
  sampleReceivedHandler(orderId: number): void;
  sampleNotReceivedHandler(orderId: number): void;
  requestNewSampleHandler(orderId: number): void;
  updatePaymentHandler(orderId: number): void;
  dismissReviewHandler(data: IRequestChangeReviewStatus): void;
}

type AllProps = IExternalProps;

const OrderActions = (props: AllProps) => {
  const {horseId, orderId, statuses} = props;
  const {
    deleteOrderHandler,
    openLiveReportHandler,
    submissionFormHandler,
    sampleNotReceivedHandler,
    sampleReceivedHandler,
    requestNewSampleHandler,
    updatePaymentHandler,
    dismissReviewHandler,
  } = props;

  const mapAvailableByMenuItem: Record<OrderMenuItemValue, boolean> = useMemo(() => {
    const orderStatus = statuses.status || OrderStatus.orderPlaced;
    const hasSample = statuses.hasSample;

    return {
      [OrderMenuItemValue.OpenLiveReport]: orderStatus === OrderStatus.releaseReady,
      [OrderMenuItemValue.ReviewLiveReport]: hasSample && orderStatus !== OrderStatus.releaseReady,
      [OrderMenuItemValue.SubmissionForm]: !!submissionFormHandler,
      [OrderMenuItemValue.UpdatePayment]: true,
      [OrderMenuItemValue.SampleNotReceived]: hasSample,
      [OrderMenuItemValue.SampleReceived]: !hasSample,
      [OrderMenuItemValue.RequestNewSample]: true,
      [OrderMenuItemValue.DismissReview]: orderStatus === OrderStatus.reviewReady,
      [OrderMenuItemValue.DeleteOrder]: orderStatus === OrderStatus.orderPlaced,
    };
  }, [statuses.hasSample, statuses.status, submissionFormHandler]);

  const actionsHandler: Record<OrderMenuItemValue, () => void> = {
    [OrderMenuItemValue.OpenLiveReport]: () => openLiveReportHandler(horseId),
    [OrderMenuItemValue.ReviewLiveReport]: () => openLiveReportHandler(horseId, orderId),
    [OrderMenuItemValue.SubmissionForm]: () => submissionFormHandler(orderId),
    [OrderMenuItemValue.UpdatePayment]: () => updatePaymentHandler(orderId),
    [OrderMenuItemValue.SampleNotReceived]: () => sampleNotReceivedHandler(orderId),
    [OrderMenuItemValue.SampleReceived]: () => sampleReceivedHandler(orderId),
    [OrderMenuItemValue.RequestNewSample]: () => requestNewSampleHandler(orderId),
    [OrderMenuItemValue.DismissReview]: () => dismissReviewHandler({orderId, reviewStatus: OrderStatusField.Waiting}),
    [OrderMenuItemValue.DeleteOrder]: () => deleteOrderHandler(orderId),
  };

  const onSelectHandler = (type: keyof typeof actionsHandler) => actionsHandler[type]();

  const shownMenuItems: IMenuItem[] = useMemo(
    () =>
      Object.entries(commonMenuItems)
        .map(([key, value]) => ({...value, value: key, availabilityKey: mapAvailableByMenuItem[key]}))
        .filter((item) => item.availabilityKey)
        .sort((a, b) => sortByStringKey(a, b, 'id')),
    [mapAvailableByMenuItem]
  );

  return <AdminActions menuItems={shownMenuItems} onSelectHandler={onSelectHandler} />;
};

export default memo(OrderActions);
export type OrderActionsProps = IExternalProps;
