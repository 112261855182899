import {IModule} from 'redux-dynamic-modules';

import {changePasswordReducer} from './reducer';
import {IChangePasswordState} from './types';

export interface IChangePasswordModuleState {
  changePassword: IChangePasswordState;
}

export const ChangePasswordModule: IModule<IChangePasswordModuleState> = {
  id: 'changePassword',
  reducerMap: {
    changePassword: changePasswordReducer,
  },
};
