import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {Cell, Table} from 'Common/components/Table/Table';
import {ICoupon} from 'Coupon/models/ICoupon';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {IconName} from 'Icon/components/Icon';
import {withCurrencyOrPercent} from 'Common/helpers/withCurrency';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';
import withDate from 'Common/helpers/withDate';

import {AdminCouponsModule} from 'Admin/AdminDashboard/store/adminCoupons/adminCouponsModule';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminCoupons/index';
import {Pagination} from 'Common/components/Controls/index';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import Loading from 'Loading/components/Loading';
import {FormType} from 'HorseProfile/components/HorseProfileForm/constants';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import CouponsForm from 'Admin/AdminDashboard/components/Coupons/CouponForm/CouponForm';
import Typography from 'Common/constants/Typography';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {SwitchButton} from 'Common/components/Controls/Buttons/SwitchButton';
import Nebula from 'Common/components/Layout/Nebula';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import PackageSection from './PackageSection';

const CONFIRM_DELETE_COUPON = 'Are you sure you want to remove the coupon?';

const actionButtonProps = {color: ColorPalette.gray56, fill: true, stroke: false, size: 24};

const AddCouponButton = styled(PrimaryButton)`
  width: 176px;
  height: 50px;
  margin-left: 55px;
`;

const CouponsTable = styled.div`
  margin-top: 50px;
`;

const ActionButton = styled(IconButton)`
  margin-left: 12px;
`;

interface IActionProps {
  id: number;
  isDeletable: boolean;
  deleteAction: (id: number) => void;
  editAction: (id: number) => void;
  copyAction: (id: number) => void;
}

const Actions = React.memo((props: IActionProps) => {
  const {id, editAction, deleteAction, copyAction, isDeletable} = props;

  const nebulaStyle = {opacity: 0.3, marginBottom: 3};

  const handleEditAction = useCallback(() => editAction(id), [id, editAction]);
  const handleDeleteAction = useCallback(() => deleteAction(id), [id, deleteAction]);
  const handleCopyAction = useCallback(() => copyAction(id), [id, copyAction]);

  return (
    <div className="d-flex align-items-center">
      <ActionButton name={IconName.Edit} {...actionButtonProps} onClick={handleEditAction} />
      <ActionButton name={IconName.FileCopy} {...actionButtonProps} onClick={handleCopyAction} />
      <Nebula active={!isDeletable} style={nebulaStyle}>
        <ActionButton name={IconName.Garbage} {...actionButtonProps} onClick={handleDeleteAction} />
      </Nebula>
    </div>
  );
});

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const Coupons = (props: AllProps) => {
  const {
    getCoupons,
    deleteCoupon,
    couponsLoading,
    couponDeleting,
    changeCouponStatus,
    changeCouponStatusRequesting,
    coupons,
  } = props;

  const {
    selectedId,
    setSelectedId,
    openDeleteModal,
    reloadItems,
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    deleteModal,
    searchBar,
  } = useCommonAdminPageData<ICoupon>({
    getItems: getCoupons,
    deleteParams: {communication: couponDeleting, confirmDescription: CONFIRM_DELETE_COUPON, action: deleteCoupon},
    searchBarPlaceholder: 'Search for coupons by any keyword',
  });

  const [isCreateOrEditCouponOpen, setIsCreateOrEditCouponOpen] = useState(false);
  const [formType, setFormType] = useState<FormType>(FormType.create);

  const isRequesting = [couponsLoading, couponDeleting, changeCouponStatusRequesting].some((i) => i.isRequesting);

  const handleAddCoupon = () => {
    setFormType(FormType.create);
    setSelectedId(null);
    setIsCreateOrEditCouponOpen(true);
  };

  const handleEditCoupon = (id: number) => {
    setFormType(FormType.edit);
    setSelectedId(id);
    setIsCreateOrEditCouponOpen(true);
  };

  const handleCopyCoupon = (id: number) => {
    setFormType(FormType.createBased);
    setSelectedId(id);
    setIsCreateOrEditCouponOpen(true);
  };

  const closeCreateOrEditCouponModal = useCallback(() => {
    setIsCreateOrEditCouponOpen(false);
  }, []);

  const onSuccessSubmitCouponsForm = useCallback(() => {
    closeCreateOrEditCouponModal();
    reloadItems();
  }, [closeCreateOrEditCouponModal, reloadItems]);

  const changeStatus = useCallback(
    (value: boolean, couponId: number) => {
      changeCouponStatus(couponId, value);
    },
    [changeCouponStatus]
  );

  const isShowPagination = coupons.length > 0;

  return (
    <AdminPageLayout>
      {deleteModal}
      <ModalWindow isOpen={isCreateOrEditCouponOpen} onClose={closeCreateOrEditCouponModal}>
        <CouponsForm couponId={selectedId || undefined} type={formType} onSuccess={onSuccessSubmitCouponsForm} />
      </ModalWindow>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
        <AddCouponButton onClick={handleAddCoupon}>+ Add coupon</AddCouponButton>
      </div>
      <CouponsTable className="position-relative">
        {isRequesting && <Loading />}
        <Table<ICoupon>
          data={coupons}
          disableRow={(coupons) => !coupons.isEnabled}
          rowKey="id"
          sorting={sorting}
          onChangeSorting={changeSorting}
        >
          <Cell<ICoupon>
            header="Enable"
            dataKey="isEnabled"
            render={({id, isEnabled}) => (
              <SwitchButton itemId={id} onChangeHandler={changeStatus} checked={isEnabled} />
            )}
            width="10%"
          />
          <Cell<ICoupon> header="ID" dataKey="id" render={({id}) => `#${id}`} width="10%" />
          <Cell<ICoupon> header="Code" dataKey="code" render={({code}) => code} width="20%" />
          <Cell<ICoupon>
            header="Off"
            dataKey="amount"
            width="5%"
            render={({amount, discountType}) => withCurrencyOrPercent(amount, discountType)}
          />
          <Cell<ICoupon>
            header="Valid"
            dataKey="expiryDate"
            width="15%"
            render={({startDate, expiryDate}) =>
              `${startDate && withDate(startDate)} - ${expiryDate && withDate(expiryDate)}`
            }
          />
          <Cell<ICoupon>
            header="Uses"
            dataKey="countUsed"
            width="10%"
            render={({countUsed, countBeforeExpiry}) =>
              `${countUsed} / ${countBeforeExpiry === 0 ? Typography.symbols.infinity : countBeforeExpiry}`
            }
          />
          <Cell<ICoupon>
            header="Packages"
            dataKey="userPackages"
            width="30%"
            sortable={false}
            render={({userPackages, associationPackages, associationPurchasableTests}) => (
              <PackageSection {...{userPackages, associationPackages, associationPurchasableTests}} />
            )}
          />
          <Cell<ICoupon>
            header="Actions"
            align={CellAlign.Right}
            width={100}
            render={({id, countUsed, isEnabled}) => (
              <Actions
                id={id}
                isDeletable={countUsed === 0 && !isEnabled}
                editAction={handleEditCoupon}
                copyAction={handleCopyCoupon}
                deleteAction={openDeleteModal}
              />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={props.pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </CouponsTable>
    </AdminPageLayout>
  );
};

const mapStateToProps = (state: IAppState) => ({
  coupons: selectors.selectCoupons(state),
  couponsLoading: selectors.selectCommunication(state, 'couponsLoading'),
  couponDeleting: selectors.selectCommunication(state, 'couponDeleting'),
  changeCouponStatusRequesting: selectors.selectCommunication(state, 'changeCouponStatusRequesting'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getCoupons: actions.getCoupons,
  deleteCoupon: actions.deleteCoupon,
  changeCouponStatus: actions.changeCouponStatus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(Coupons);

export default withDynamicModules(Connected, [AdminCouponsModule]);
