import styled from 'styled-components';

import {IPropsImage} from './types';
import BlurredImage from 'Common/components/BlurredImage/BlurredImage';

export const ImagePreviewBase = styled.div<IPropsImage>`
  width: ${prop => prop.width};
  height: ${prop => prop.height};
  background: url(${prop => `'${prop.url}'`});
  background-position: center;
  background-repeat: no-repeat;
`;

export const ImagePreviewLayout = styled(ImagePreviewBase)<IPropsImage>`
  background-size: contain;
`;

export const ImagePreviewBlurred = styled(BlurredImage)<IPropsImage>`
  width: ${prop => prop.width};
  height: ${prop => prop.height};
`;
