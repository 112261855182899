import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication/index';

import {IUserFullInfo} from 'Admin/AdminDashboard/models/User/IUserFullInfo';
import {Nullable} from 'Common/types';

const STATE_NAME = 'adminUserFullInfoUpdate';

export interface IAdminUserFullInfoUpdateState {
  data: {
    user: Nullable<IUserFullInfo>;
  };
  communications: {
    userLoading: ICommunication;
    userUpdating: ICommunication;
  };
}

export const getUserActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER');

export const updateUserActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_USER');

export type getUserActions = MakeCommunicationActions<typeof getUserActionTypes, {success: IUserFullInfo}>;

export type updateUserActions = MakeCommunicationActions<typeof updateUserActionTypes, {}>;

export const resetUserActionTypes = makeResetCommunicationActionType(STATE_NAME, 'USER');
export type resetUserActions = MakeResetCommunicationActions<typeof resetUserActionTypes>;

export type AdminUserFullInfoUpdateActions = getUserActions | updateUserActions | resetUserActions;
