import {combineReducers, Reducer} from 'redux';

import {IHorseBreedPanelState} from '../types';
import {communicationHorseBreedPanelsReducer} from './communications';
import {dataHorseBreedPanelsReducer} from './data';

export const horseBreedPanelsReducer: Reducer<IHorseBreedPanelState> = combineReducers<IHorseBreedPanelState>({
  communications: communicationHorseBreedPanelsReducer,
  data: dataHorseBreedPanelsReducer
});
