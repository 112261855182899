import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';

import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';
import {IOrderSummary} from 'BusinessPortal/models/order/createOrder/IOrderSummary';
import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import {ICreatedOrder} from 'BusinessPortal/models/order/createOrder/ICreatedOrder';
import {Nullable} from 'Common/types';

const STATE_NAME = 'businessPortalCreateOrder';

export interface IBusinessPortalCreateOrderState {
  data: {
    horses: IOrderHorse[];
    summary: Nullable<IOrderSummary>;
    coupon: Nullable<IOrderCoupon>;
    createdOrder: Nullable<ICreatedOrder>;
  };
  communications: {
    horsesLoading: ICommunication;
    summaryLoading: ICommunication;
    couponLoading: ICommunication;
    creatingOrder: ICommunication;
  };
}

export const getHorsesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSES');
export type getHorsesActions = MakeCommunicationActions<typeof getHorsesActionTypes, {success: IOrderHorse[]}>;

export const getSummaryActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_SUMMARY');
export type getSummaryActions = MakeCommunicationActions<typeof getSummaryActionTypes, {success: IOrderSummary}>;

export const getCouponActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_COUPON');
export type getCouponActions = MakeCommunicationActions<typeof getCouponActionTypes, {success: IOrderCoupon}>;

export const createOrderActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_ORDER');
export type createOrderActions = MakeCommunicationActions<typeof createOrderActionTypes, {success: ICreatedOrder}>;

export const resetCouponActionTypes = makeResetCommunicationActionType(STATE_NAME, 'REMOVE_COUPON');
export type resetCouponActions = MakeResetCommunicationActions<typeof resetCouponActionTypes>;

export const resetSummaryActionTypes = makeResetCommunicationActionType(STATE_NAME, 'REMOVE_SUMMARY');
export type resetSummaryActions = MakeResetCommunicationActions<typeof resetSummaryActionTypes>;

export type Actions =
  | getHorsesActions
  | getSummaryActions
  | getCouponActions
  | createOrderActions
  | resetCouponActions
  | resetSummaryActions;
