import {useState, useEffect} from 'react';

import {Nullable} from 'Common/types';

import {isImageExists} from '../ImagesHelper';

export default function useIsImageExist(url?: Nullable<string>) {
  const [isAvatarExists, setIsAvatarExists] = useState(false);
  useEffect(() => {
    let isMounted = true;

    if (url) {
      isImageExists(url)
        .then(() => {
          if (isMounted) {
            setIsAvatarExists(true);
          }
        })
        .catch(() => {
          if (isMounted) {
            setIsAvatarExists(false);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [url]);

  return isAvatarExists;
}
