import {Reducer} from 'redux';

import {getSubscriptionsActionTypes, IAdminSubscriptionState, SubscriptionsActions} from '../types';

const initialState: IAdminSubscriptionState['ui'] = {
  pagination: {countPerPage: 0, currentPage: 1, pageCount: 1, totalItemsCount: 1}
};

export const uiAdminSubscriptionsReducer: Reducer<IAdminSubscriptionState['ui'], SubscriptionsActions> = (
  state = initialState,
  action: SubscriptionsActions
) => {
  switch (action.type) {
    case getSubscriptionsActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination || initialState.pagination};
    }
    default: {
      return state;
    }
  }
};
