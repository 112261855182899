import {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';

type Function = (...args: any) => any;

export function useBindActions<T extends Record<string, Function>>(actions: T) {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      bindActionCreators(
        {
          ...actions
        },
        dispatch
      ),
    [actions, dispatch]
  );
}
