import {IModule} from 'redux-dynamic-modules';

import {IBusinessPortalOwnerState} from 'BusinessPortal/store/owner/types';
import {businessPortalOwnerReducer} from 'BusinessPortal/store/owner/reducer';

export interface IBusinessPortalOwnerModuleState {
  businessPortalOwner: IBusinessPortalOwnerState;
}

export const BusinessPortalOwnerModule: IModule<IBusinessPortalOwnerModuleState> = {
  id: 'businessPortalOwner',
  reducerMap: {
    businessPortalOwner: businessPortalOwnerReducer
  }
};
