import React, {useState, memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {IIndicator} from 'Common/models/IIndicator';
import Typography from 'Common/constants/Typography';
import Segment from './Segment';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {ISegment} from 'Common/models/ISegment';
import {ISegmentProps} from './Segment';
import Theme from 'Common/constants/Theme';

function getSegments(
  maxValue: number = 10,
  segmentColor: string,
  defaultSegmentColor: string,
  value?: number,
  hoveredValue?: number | null
): ISegment[] {
  const arr: ISegment[] = Array.from({length: maxValue}).map<ISegment>((_, i) => ({
    id: i,
    value: i + 1,
    label: String(i + 1)
  }));
  return getSegmentsFromSegments(arr, segmentColor, defaultSegmentColor, value, hoveredValue);
}

function getSegmentsFromSegments(
  segments: ISegment[],
  segmentColor: string,
  defaultSegmentColor: string,
  value?: number,
  hoveredValue?: number | null
): ISegmentProps[] {
  const hoveredIndex = segments.findIndex(item => item.value === hoveredValue);
  if (!value) {
    return segments.map((item: ISegment, index) => {
      const isPlacedBeforeHovered = hoveredIndex > -1 && index <= hoveredIndex;
      return {
        ...item,
        color: isPlacedBeforeHovered ? segmentColor : defaultSegmentColor,
        label: item.label || String(index + 1),
        selected: false,
        hovered: isPlacedBeforeHovered ? true : false,
        current: hoveredIndex === index ? true : false
      };
    });
  }

  const valueIndex = segments.findIndex(item => item.value === value);
  return segments.map((item: ISegment, index) => {
    const isPlacedBeforeHovered = hoveredIndex > -1 && index <= hoveredIndex;
    const isPlacedAfterSelected = valueIndex > -1 && index > valueIndex;
    let isHovered: boolean = false;
    let color: string = defaultSegmentColor;

    if (!hoveredValue && !isPlacedAfterSelected) {
      color = segmentColor;
      isHovered = false;
    }
    if (hoveredValue && isPlacedBeforeHovered && !isPlacedAfterSelected) {
      color = segmentColor;
      isHovered = false;
    }
    if (hoveredValue && !isPlacedBeforeHovered && !isPlacedAfterSelected) {
      color = segmentColor;
      isHovered = true;
    }
    if (hoveredValue && isPlacedBeforeHovered && isPlacedAfterSelected) {
      color = segmentColor;
      isHovered = true;
    }

    return {
      ...item,
      color,
      label: item.label || String(index + 1),
      selected: !isPlacedAfterSelected,
      hovered: isHovered,
      current: hoveredIndex === index || valueIndex === index
    };
  });
}

const IndicatorLayout = styled.div<{isDisabled?: boolean}>`
  width: 100%;
  ${prop => (prop.isDisabled ? 'opacity: .4' : '')};
`;

const IndicatorLabel = styled.label`
  padding-left: 0;
  color: ${ColorPalette.black1};
  font-size: ${Typography.size.size16};
`;

const SegmentShortLabel = styled.span`
  color: ${ColorPalette.gray3};
  font-size: ${Typography.size.size13};
  margin-bottom: 16px;
`;

const SegmentsLayout = styled.div<{heightSegments: number}>`
  height: ${props => `${props.heightSegments}px`};
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

const SegmentsWrapper = styled.div`
  display: flex;
  height: 100%;
  border-radius: 10px;
  margin: 0 -2px;
`;

const BottomLine = styled.div`
  width: 100%;
  display: flex;
  margin: 0;
`;

const BottomItem = styled.div`
  text-align: center;

  :not(:first-child) {
    margin-left: 1px;
  }
`;

const BottomLabel = styled.div<ISegmentLabelProp>`
  width: 100%;
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  color: ${prop => (prop.color ? prop.color : ColorPalette.gray1)};
  font-size: ${Typography.size.size13};
  ${prop => {
    if (prop.isFirstSegment) {
      return `left: 0; text-align: left;`;
    }
    if (prop.isLastSegment) {
      return `right: 0; text-align: right;`;
    }
    return `text-align: center;`;
  }}
`;

const ClickableZone = styled.div<ClickableZoneProps>`
  display: block;
  width: 100%;
  height: ${prop => prop.clickableHeight}px;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
`;

interface ClickableZoneProps {
  clickableHeight: number;
  withLabels?: boolean;
}

interface ISegmentLabelProp {
  color?: string;
  isFirstSegment?: boolean;
  isLastSegment?: boolean;
}

interface IProps {
  indicator: IIndicator;
  tooltip?: React.ReactChild | React.ReactChild[];
  style?: React.CSSProperties | undefined;
  onChange?(value: number): void;
}

const Indicator = (props: IProps) => {
  const [hoveredValue, setHoveredValue] = useState<number | null>(null);
  const {
    showBottomLabels,
    label,
    shortLabel,
    height,
    segmentColor,
    segmentsMaxCount,
    disabled,
    labelSelectedColor
  } = props.indicator;
  const indicatorValue = props.indicator.value;
  const defaultSegmentColor = props.indicator.bgColor ? props.indicator.bgColor : ColorPalette.gray2;
  const clickableHeight = (showBottomLabels ? 38 : 10) + height;

  function onClickHandler(value: number) {
    !disabled && props.onChange && props.onChange(value);
  }

  const indicatorSegments: ISegmentProps[] = props.indicator.segments
    ? getSegmentsFromSegments(props.indicator.segments, segmentColor, defaultSegmentColor, indicatorValue, hoveredValue)
    : getSegments(segmentsMaxCount, segmentColor, defaultSegmentColor, indicatorValue, hoveredValue);

  const handleOnEnter = (value: number) => {
    !disabled && setHoveredValue(value);
  };

  const handleOnLeave = () => {
    !disabled && setHoveredValue(null);
  };

  return (
    <Tooltip content={props.tooltip ? props.tooltip : ''}>
      <IndicatorLayout className="d-flex flex-column" isDisabled={disabled} style={props.style}>
        {label ? <IndicatorLabel>{label}</IndicatorLabel> : ''}
        {indicatorSegments.length > 0 && (
          <div className="d-flex flex-column">
            {shortLabel && <SegmentShortLabel>{shortLabel}</SegmentShortLabel>}

            <SegmentsLayout heightSegments={height}>
              <SegmentsWrapper className="d-flex">
                {indicatorSegments.map((segmentItem, index) => (
                  <Segment key={index} {...segmentItem} />
                ))}
              </SegmentsWrapper>
            </SegmentsLayout>

            {(showBottomLabels || props.onChange) && (
              <BottomLine>
                {indicatorSegments.map((segmentItem, index) => (
                  <BottomItem key={index} className="w-100 h-100 position-relative">
                    {showBottomLabels && segmentItem.label && (
                      <BottomLabel
                        isFirstSegment={index === 0}
                        isLastSegment={index === indicatorSegments.length - 1}
                        color={
                          segmentItem.current && !disabled && labelSelectedColor
                            ? labelSelectedColor
                            : segmentItem.current && !disabled && !labelSelectedColor
                            ? segmentColor
                            : segmentItem.labelColor
                        }
                      >
                        {segmentItem.label}
                      </BottomLabel>
                    )}
                    {!disabled && props.onChange && (
                      <ClickableZone
                        clickableHeight={clickableHeight}
                        withLabels={showBottomLabels}
                        onClick={onClickHandler.bind(null, segmentItem.value)}
                        onMouseEnter={handleOnEnter.bind(null, segmentItem.value)}
                        onMouseLeave={handleOnLeave}
                      />
                    )}
                  </BottomItem>
                ))}
              </BottomLine>
            )}
          </div>
        )}
      </IndicatorLayout>
    </Tooltip>
  );
};

export default memo(Indicator);
