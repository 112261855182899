import {Reducer} from 'redux';

import {
  getAbilitiesActionTypes,
  getAbilityDetailedActionTypes,
  getCoatColorActionTypes,
  getCoatColorDetailedActionTypes,
  getGeneticVariantsActionTypes,
  getHealthVariantDetailedActionTypes,
  getHealthVariantsActionTypes,
  getHorseBreedPanelsActionTypes,
  getHorseDetailsActionTypes,
  getHorseInfoActionTypes,
  getHorseOwnerActionTypes,
  getSummaryAbilitiesActionTypes,
  getSummaryColorsActionTypes,
  getSummaryHealthIssuesActionTypes,
  IOnlineReportState,
  OnlineReportActions,
} from '../types';

const initialState: IOnlineReportState['data'] = {
  horseInfo: null,
  horseOwner: null,
  horseDetails: null,
  summaryAbilities: null,
  summaryColors: null,
  summaryHealthIssues: null,
  coatColors: null,
  coatColorsDetailed: null,
  healthVariants: null,
  healthVariantsDetailed: null,
  abilities: null,
  abilityDetailed: null,
  geneticVariants: null,
  horseBreedPanels: [],
};

export const dataReducer: Reducer<IOnlineReportState['data'], OnlineReportActions> = (
  state = initialState,
  action: OnlineReportActions
) => {
  switch (action.type) {
    case getHorseInfoActionTypes.REQUEST: {
      return {...state, horseInfo: null};
    }
    case getHorseInfoActionTypes.SUCCESS: {
      return {...state, horseInfo: action.payload};
    }

    case getHorseOwnerActionTypes.REQUEST: {
      return {...state, horseOwner: null};
    }
    case getHorseOwnerActionTypes.SUCCESS: {
      return {...state, horseOwner: action.payload};
    }

    case getHorseDetailsActionTypes.REQUEST: {
      return {...state, horseDetails: null};
    }
    case getHorseDetailsActionTypes.SUCCESS: {
      return {...state, horseDetails: action.payload};
    }

    case getSummaryAbilitiesActionTypes.REQUEST: {
      return {...state, summaryAbilities: null};
    }
    case getSummaryAbilitiesActionTypes.SUCCESS: {
      return {...state, summaryAbilities: action.payload};
    }

    case getSummaryColorsActionTypes.REQUEST: {
      return {...state, summaryColors: null};
    }
    case getSummaryColorsActionTypes.SUCCESS: {
      return {...state, summaryColors: action.payload};
    }

    case getSummaryHealthIssuesActionTypes.REQUEST: {
      return {...state, summaryHealthIssues: null};
    }
    case getSummaryHealthIssuesActionTypes.SUCCESS: {
      return {...state, summaryHealthIssues: action.payload};
    }

    case getGeneticVariantsActionTypes.REQUEST: {
      return {...state, geneticVariants: initialState.geneticVariants};
    }
    case getGeneticVariantsActionTypes.SUCCESS: {
      return {...state, geneticVariants: action.payload};
    }

    case getCoatColorActionTypes.REQUEST: {
      return {...state, coatColors: null};
    }
    case getCoatColorActionTypes.SUCCESS: {
      return {...state, coatColors: action.payload};
    }
    case getCoatColorDetailedActionTypes.REQUEST: {
      return {...state, coatColorsDetailed: state.coatColorsDetailed};
    }
    case getCoatColorDetailedActionTypes.SUCCESS: {
      return {
        ...state,
        coatColorsDetailed:
          !state.coatColorsDetailed || state.coatColorsDetailed.length < 1
            ? [action.payload]
            : [...state.coatColorsDetailed, action.payload],
      };
    }

    case getHealthVariantsActionTypes.REQUEST: {
      return {...state, healthVariants: null};
    }
    case getHealthVariantsActionTypes.SUCCESS: {
      return {...state, healthVariants: action.payload};
    }
    case getHealthVariantDetailedActionTypes.SUCCESS: {
      return {
        ...state,
        healthVariantsDetailed:
          !state.healthVariantsDetailed || state.healthVariantsDetailed.length < 1
            ? [action.payload]
            : [...state.healthVariantsDetailed, action.payload],
      };
    }

    case getAbilitiesActionTypes.REQUEST: {
      return {...state, abilities: null};
    }
    case getAbilitiesActionTypes.SUCCESS: {
      return {...state, abilities: action.payload};
    }
    case getAbilityDetailedActionTypes.SUCCESS: {
      return {
        ...state,
        abilityDetailed:
          !state.abilityDetailed || state.abilityDetailed.length < 1
            ? [action.payload]
            : [...state.abilityDetailed, action.payload],
      };
    }

    case getHorseBreedPanelsActionTypes.REQUEST: {
      return {...state, horseBreedPanels: initialState.horseBreedPanels};
    }
    case getHorseBreedPanelsActionTypes.SUCCESS: {
      return {...state, horseBreedPanels: action.payload};
    }

    default: {
      return state;
    }
  }
};
