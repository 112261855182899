import React, {memo, useMemo} from 'react';
import styled from 'styled-components';

import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';
import {TextUpper} from '../shared/StyledComponents';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import Theme from 'Common/constants/Theme';
import {SelectField} from 'Common/components/FormFields';
import Tooltip from 'Common/components/Tooltip/Tooltip';

const Cells = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 50% 50%;
`;

const LinkedText = styled(TextUpper)`
  width: 55px;
  color: ${Theme.color.gray};
  text-align: center;
  align-self: flex-start;
  margin-top: 12px;
`;

const AddButton = styled(PrimaryButton)`
  align-self: flex-start;
  width: 100%;
`;

interface IProps {
  sequenceIndex: number;
  sequenceItems: number[];
  sequenceType: string;
  dictionaryOptions: IDictionaryOptionType[];

  onAdd(): void;
  onClear(i: number): void;
}

function SequenceItems(props: IProps) {
  const {sequenceIndex, sequenceItems, sequenceType, dictionaryOptions, onAdd, onClear} = props;

  const filteredOptions = useMemo(
    () =>
      dictionaryOptions.map((option) =>
        sequenceItems.find((seqItem) => seqItem === option.value) ? {...option, isInvisible: true} : option
      ),
    [sequenceItems, dictionaryOptions]
  );

  return (
    <>
      <Cells>
        {sequenceItems.map((item, i) => {
          const handleOnClear = () => onClear(i);
          const tooltip = dictionaryOptions.find((x) => x.value === sequenceItems[i])?.label || '';
          return (
            <div key={i} className="d-flex align-items-center">
              {i > 0 && <LinkedText>{sequenceType}</LinkedText>}
              <>
                <Tooltip content={tooltip} placement="right" boundaryParent="scrollingContainer">
                  <div className="w-100">
                    <SelectField
                      name={`sequences[${sequenceIndex}].sequenceItems[${i}]`}
                      options={filteredOptions}
                      isClearable={true}
                      onClear={handleOnClear}
                      menuPosition="fixed"
                    />
                  </div>
                </Tooltip>
              </>
            </div>
          );
        })}

        <div className="d-flex align-items-center">
          {sequenceItems.length > 0 && <LinkedText>{sequenceType}</LinkedText>}
          <AddButton type="button" onClick={onAdd}>
            + Add state
          </AddButton>
        </div>
      </Cells>
    </>
  );
}

export default memo(SequenceItems);
