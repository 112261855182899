import React, {memo} from 'react';

import {addToFavoriteIconProps} from 'UserProfile/components/TabsData/common/constants';
import {AddFavoriteButton} from 'UserProfile/components/TabsData/common/styled';

interface IProps {
  onClick(): void;
}

const AddFavoriteHorseButton = (props: IProps) => {
  const {onClick} = props;

  return (
    <AddFavoriteButton
      size="small"
      type="button"
      variant="hollowed"
      icon={addToFavoriteIconProps}
      iconStyle={{marginBottom: 3, marginRight: -4}}
      onClick={onClick}
    >
      Add to favorites
    </AddFavoriteButton>
  );
};

export default memo(AddFavoriteHorseButton);
