import React, {memo} from 'react';
import styled from 'styled-components';

import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {InputField} from 'Common/components/FormFields/index';
import {IAssociation} from 'Dictionaries/models/IAssociation';
import {IRegistryRequest} from 'Common/models/IRegistryRequest';

import RegistriesList from './RegistriesList/RegistriesList';

const PassportInfo = styled.div`
  margin-top: 24px;
`;

interface IProps {
  associations: IAssociation[];
  registries: IRegistryRequest[];
}

function Registries(props: IProps) {
  const {associations, registries} = props;
  return (
    <FormControlContainer title="Registries">
      <RegistriesList fieldName="registries" associations={associations} registries={registries} />
      <PassportInfo>
        <InputField
          name="microchipNumber"
          type="text"
          label="Chip"
          placeholder="Chip"
          autoComplete="off"
          memoized={true}
        />
        <InputField
          name="passportNumber"
          type="text"
          label="Passport"
          placeholder="Passport"
          autoComplete="off"
          memoized={true}
        />
      </PassportInfo>
    </FormControlContainer>
  );
}

export default memo(Registries);
