import {combineReducers} from 'redux';

import {
  confirmAgreementCurrentUserActionTypes,
  CurrentUserActions,
  editProfileInfoCurrentUserActionTypes,
  getCurrentAdminActionTypes,
  getCurrentAssociationEmployeeActionTypes,
  getCurrentUserActionTypes,
  getProfileTypeCurrentUserActionTypes,
  ICurrentUserState,
  resetCurrentAdminActionTypes,
  resetCurrentAssociationEmployeeActionTypes,
  resetCurrentUserActionTypes,
  updateProfileTypeCurrentUserActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

export const communicationCurrentUserReducer = combineReducers<ICurrentUserState['communications'], CurrentUserActions>(
  {
    currentUserLoading: makeCommunicationReducerFromEnum(getCurrentUserActionTypes),
    currentAdminLoading: makeCommunicationReducerFromEnum(getCurrentAdminActionTypes),
    currentAssociationEmployeeLoading: makeCommunicationReducerFromEnum(getCurrentAssociationEmployeeActionTypes),
    userUpdating: makeCommunicationReducerFromEnum(editProfileInfoCurrentUserActionTypes),
    userLoading: composeReducers([
      makeCommunicationReducerFromEnum(getCurrentUserActionTypes),
      makeCommunicationReducerFromEnum(confirmAgreementCurrentUserActionTypes),
      makeCommunicationReducerFromEnum(editProfileInfoCurrentUserActionTypes),
      makeCommunicationReducerFromEnum(getCurrentAssociationEmployeeActionTypes),
      makeResetCommunicationReducer(resetCurrentUserActionTypes.RESET),
      makeResetCommunicationReducer(resetCurrentAdminActionTypes.RESET),
      makeResetCommunicationReducer(resetCurrentAssociationEmployeeActionTypes.RESET),
    ]),
    userProfileTypeLoading: makeCommunicationReducerFromEnum(getProfileTypeCurrentUserActionTypes),
    userProfileTypeUpdating: makeCommunicationReducerFromEnum(updateProfileTypeCurrentUserActionTypes),
  }
);
