import {ISubscriptionAdmin} from 'Admin/AdminDashboard/models/ISubscriptionAdmin';

import {IListResponse, IPagination} from 'Common/models/IResponse';
import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

const STATE_NAME = 'adminSubscription';

export interface IAdminSubscriptionState {
  data: {
    subscriptions: ISubscriptionAdmin[];
  };
  communications: {
    subscriptionsLoading: ICommunication;
    subscriptionsOverwriting: ICommunication;
    subscriptionCanceling: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getSubscriptionsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_SUBSCRIPTIONS');

export type getSubscriptionsActions = MakeCommunicationActions<
  typeof getSubscriptionsActionTypes,
  {success: IListResponse<ISubscriptionAdmin>}
>;

export const overwriteFromStripeActionTypes = makeCommunicationActionType(STATE_NAME, 'OVERWRITE_FROM_STRIPE');

export type overwriteFromStripeActions = MakeCommunicationActions<typeof overwriteFromStripeActionTypes, {}>;

export const cancelSubscriptionActionTypes = makeCommunicationActionType(STATE_NAME, 'CANCEL_STRIPE_SUBSCRIPTION');

export type cancelSubscriptionActions = MakeCommunicationActions<typeof cancelSubscriptionActionTypes, {}>;

export type SubscriptionsActions = getSubscriptionsActions | overwriteFromStripeActions | cancelSubscriptionActions;
