import React, {memo, useCallback, useMemo} from 'react';

import {IFieldProps} from 'Common/models/IFieldProps';
import {ValidationError} from 'Common/components/FormFields/index';
import withField from 'Common/components/FormFields/withField';
import {SelectProps} from 'Common/components/Controls/Select/Select';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {IBaseControlProps} from 'Common/models/IBaseControlProps';

import UserSearch, {IUserOption} from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearch';
import {IFoundUser} from 'Admin/AdminDashboard/models/User/IFoundUser';

type IProps = {
  action?(filter: string): Promise<IFoundUser[]>;
  defaultValue?: IUserOption;
} & SelectProps<IUserOption> &
  IFieldProps<number> &
  IBaseControlProps;

function UserSearchField(props: IProps) {
  const {
    field: {name, value},
    form: {setFieldValue},
    action,
    defaultValue,
    ...rest
  } = props;

  const onSelect = useCallback(
    (userId: number) => {
      setFieldValue(name, userId);
    },
    [name, setFieldValue]
  );

  const onRemove = useCallback(() => {
    setFieldValue(name, undefined);
  }, [name, setFieldValue]);

  const hasValue = useMemo(() => !!value && value !== 0, [value]);

  return (
    <div>
      <FieldControlContainer {...rest}>
        <UserSearch
          hasValue={hasValue}
          onSelect={onSelect}
          action={action}
          onClear={onRemove}
          name={name}
          defaultValue={defaultValue}
        />
      </FieldControlContainer>
      <ValidationError name={name} />
    </div>
  );
}

export default memo(withField(UserSearchField));
