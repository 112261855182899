import React, {memo, useCallback, useEffect, useState} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import Loading from 'Loading/components/Loading';
import {FileUploaderField} from 'Common/components/FormFields';
import {IFormValues, initialValue, validationSchema} from './validation';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminAncestryUpload';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {getErrorCode, getErrorDetails} from 'Common/helpers/ErrorHelper';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import {AdminAncestryUploadModule} from 'Admin/AdminDashboard/store/adminAncestryUpload/adminAncestryUploadModule';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import Nebula from 'Common/components/Layout/Nebula';
import SwitchField from 'Common/components/FormFields/SwitchField';

const TitleContainer = styled.div`
  padding: 12px 18px 18px 0;
`;

const Value = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;

interface ExternalProps {
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & ExternalProps;
type AllProps = FormikProps<IFormValues> & OuterProps;

function AncestryUploadForm(props: AllProps) {
  const {isSubmitting, values} = props;
  const {ancestryUploading, ancestryHorsesLikeMeUploading} = props;

  const [duplicateElements, setDuplicateElements] = useState<string[]>();
  const [isOpen, setIsOpen] = useState(false);

  const isRequesting = ancestryUploading.isRequesting;
  const errorDetails = getErrorDetails(ancestryUploading.error);
  const errorCode = ancestryUploading.error && getErrorCode(ancestryUploading.error);

  useEffect(() => {
    if (errorCode === 'DuplicateElement' && errorDetails && errorDetails?.length > 0) {
      const duplicates: string[] = [];
      errorDetails?.forEach((x) => Object.keys(x).forEach((key) => duplicates?.push(key)));
      setDuplicateElements(duplicates);
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode]);

  useCommunicationToToast(ancestryUploading, 'Files was succesfully uploaded and processed');
  useCommunicationToToast(ancestryHorsesLikeMeUploading, 'Files was succesfully uploaded and processed');

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <ModalWindow isOpen={isOpen} onClose={closeModal}>
        <ModalWindowHeader>Duplicate Sample IDs</ModalWindowHeader>
        <ModalWindowFormContent>
          {duplicateElements?.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </ModalWindowFormContent>
      </ModalWindow>
      <ModalWindowHeader>Upload files</ModalWindowHeader>
      <div className="d-flex flex-column justify-content-center">
        {isRequesting && <Loading />}
        <Form>
          <ModalWindowFormContent>
            <TitleContainer>
              <div className="d-flex">
                <Value>Ancestry files</Value>
                <SwitchField name="isAncestryEnable" />
              </div>
            </TitleContainer>
            <Nebula active={!values.isAncestryEnable} style={{opacity: 0.2}}>
              <FileUploaderField
                isRequired={true}
                name="allPcaFile"
                label="All_pca.txt"
                placeholder="Select a file to upload"
              />

              <FileUploaderField
                isRequired={true}
                name="kTreeFile"
                label="k_tree.json"
                placeholder="Select a file to upload"
              />
              <FileUploaderField
                isRequired={true}
                name="plinkFile"
                label="Plink.txt"
                placeholder="Select a file to upload"
              />
            </Nebula>

            <TitleContainer>
              <div className="d-flex">
                <Value>'Horses like me' files</Value>
                <SwitchField name="isHorsesLieMeEnable" />
              </div>
            </TitleContainer>
            <Nebula active={!values.isHorsesLieMeEnable} style={{opacity: 0.2}}>
              <FileUploaderField
                isRequired={true}
                name="horsesLikeMe"
                label="File"
                placeholder="Select a file to upload"
              />
            </Nebula>
          </ModalWindowFormContent>
          <ModalWindowFooter>
            <ModalWindowButton type="submit" isLoading={isSubmitting}>
              Upload
            </ModalWindowButton>
          </ModalWindowFooter>
        </Form>
      </div>
    </>
  );
}

const AncestryUploadFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => ({...initialValue}),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {uploadAncestryFiles, uploadAncestryHorseLikeMeFiles, onSuccess} = formikBag.props;
    if (values.isAncestryEnable && values.allPcaFile && values.kTreeFile && values.plinkFile) {
      try {
        await uploadAncestryFiles([values.allPcaFile, values.kTreeFile, values.plinkFile]);
        onSuccess && onSuccess();
      } catch (err) {}
    }

    if (values.isHorsesLieMeEnable && values.horsesLikeMe) {
      try {
        await uploadAncestryHorseLikeMeFiles(values.horsesLikeMe);
        onSuccess && onSuccess();
      } catch (err) {}
    }
  },
  enableReinitialize: true,
})(AncestryUploadForm);

const mapStateToProps = (state: IAppState) => ({
  ancestryUploading: selectors.selectCommunication(state, 'ancestryUploading'),
  ancestryHorsesLikeMeUploading: selectors.selectCommunication(state, 'ancestryHorsesLikeMeUploading'),
});

const mapDispatchToProps = {
  uploadAncestryFiles: actions.uploadAncestryFiles,
  uploadAncestryHorseLikeMeFiles: actions.uploadAncestryHorseLikeMeFiles,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(AncestryUploadFormik));

export default withDynamicModules(memo(Connected), AdminAncestryUploadModule);
