import {IModule} from 'redux-dynamic-modules';

import {IUserOrdersState} from './types';
import {userOrdersReducer} from './reducer';

export interface IUserOrdersModuleState {
  userOrders: IUserOrdersState;
}

export const UserOrdersModule: IModule<IUserOrdersModuleState> = {
  id: 'userOrders',
  reducerMap: {
    userOrders: userOrdersReducer
  } as any
};
