import {ProbabilityEntityType} from 'Common/constants/ProbabilityEntityType';
import {IProbabilityEntity} from 'Common/models/IProbabilityEntity';
import {IFoalParentVariantIssue} from 'FoalProfile/models/IFoalParentVariantIssue';

export function convertProbabilityToDangerProbability<T = IProbabilityEntity | IFoalParentVariantIssue>(
  probabilities: T[],
  dangers?: T[]
): T[] {
  const extProbabilities: T[] = [];
  probabilities.forEach(x => {
    extProbabilities.push({...x, isDanger: false});
  });
  if (dangers) {
    dangers.forEach(x => {
      extProbabilities.push({...x, isDanger: true});
    });
  }

  return extProbabilities;
}

export function sortProbabilities(probabilities: IProbabilityEntity[], higherIsBetter: boolean) {
  probabilities.sort((a, b) => {
    if (a.probability < b.probability) {
      return higherIsBetter ? 1 : -1;
    }
    if (a.probability > b.probability) {
      return higherIsBetter ? -1 : 1;
    }
    return 0;
  });

  return probabilities;
}

export function convertProbabilityToTypedProbability<T = IProbabilityEntity | IFoalParentVariantIssue>(
  probabilities: T[],
  type: ProbabilityEntityType
): T[] {
  return probabilities.map(x => ({...x, probabilityEntityType: type}));
}
