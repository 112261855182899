import * as React from 'react';
import styled from 'styled-components';
import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon, {IColoredIconProps as ColoredIconProps} from 'Icon/components/ColoredIcon';

const IconButtonStyle = styled(ColoredIcon)`
  &:hover:not(:disabled) {
    cursor: pointer;
  }
`;

type AllProps = ColoredIconProps & React.HTMLAttributes<HTMLDivElement>;

const IconButton = (props: AllProps) => {
  return <IconButtonStyle hoverColor={props.hoverColor ? props.hoverColor : ColorPalette.black2} {...props} />;
};

export default IconButton;
