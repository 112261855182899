import React, {memo, useCallback} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import UserOrder from 'UserProfile/components/TabsData/UserOrders/UserOrder/UserOrder';
import {Pagination} from 'Common/components/Controls/index';

import {actions, selectors as userOrderSelectors} from 'UserProfile/store/userOrder/index';
import {IAppState} from 'Common/store/IAppState';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import Loading from 'Loading/components/Loading';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {IUserProfileSectionProps} from 'Shared/models/IUserProfileSectionProps';
import {breakpoints} from 'Common/constants/Breakpoints';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import {useSearchBar} from 'Common/helpers/hooks/useSearchBar';

const UNKNOWN_ERROR = 'Error on loading list of orders';

const OrderTestLink = styled(LinkButton)`
  width: 160px;
  margin-bottom: 24px;
`;

const ButtonWrapper = styled.div`
  padding: 0 32px 0 24px;
  justify-content: center;

  @media ${breakpoints.sm} {
    justify-content: start;
  }
`;

const Orders = styled.div`
  margin-top: 16px;
`;

const ErrorMessage = styled.div`
  margin-top: 16px;
  color: ${ColorPalette.red4};
`;

const EmptyText = styled.div`
  font-family: ${Typography.family.openSans};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size23};
  line-height: 32px;
`;

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IUserProfileSectionProps;

function UserOrders(props: Props) {
  const {userOrders, userOrdersLoading, getUserOrders, pagination, userId} = props;

  const {setRequestParams, params, searchBar} = useSearchBar({
    getItems: getUserOrders,
    getItemsAnotherParams: {userId},
    searchBarPlaceholder: 'Search for horses by name',
  });

  const handlePageSelect = useCallback(
    (currentPage: number) => setRequestParams({...params, currentPage}),
    [setRequestParams, params]
  );

  const isLoading = userOrdersLoading.isRequesting;

  const error = !!userOrdersLoading.error && (getCommonErrors(userOrdersLoading.error) || UNKNOWN_ERROR);

  function renderEmptyPage() {
    return (
      <div className="h-100 d-flex flex-column justify-content-center align-items-center">
        <EmptyText>You have no tests in your account.</EmptyText>
        <EmptyText>Please press "Order Testing" above to begin.</EmptyText>
      </div>
    );
  }

  return (
    <div className="flex-grow-1 d-flex flex-column h-100 position-relative">
      <ButtonWrapper className="d-flex w-100 flex-column">
        <OrderTestLink className="d-flex align-self-start" size="small" variant="outlined" to="/order-tests">
          Order Testing
        </OrderTestLink>
        {searchBar}
      </ButtonWrapper>
      {isLoading && <Loading />}
      {!isLoading && !error && (
        <Orders>
          {userOrders.map((order, i) => (
            <UserOrder key={i} userOrder={order} />
          ))}
        </Orders>
      )}
      {!isLoading && !error && userOrders.length < 1 && renderEmptyPage()}
      {error && <ErrorMessage>{`Error on loading horses: ${error}`}</ErrorMessage>}
      <Pagination pagination={pagination} onPageSelect={handlePageSelect} />
    </div>
  );
}

const mapStateToProps = (state: IAppState) => ({
  userOrders: userOrderSelectors.selectUserOrders(state),
  userOrdersLoading: userOrderSelectors.selectCommunication(state, 'userOrdersLoading'),
  pagination: userOrderSelectors.selectPagination(state),
});

const mapDispatchToProps = {
  getUserOrders: actions.getUserOrders,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(UserOrders));
