import {Reducer} from 'redux';

import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';

import {IHorseListState, HorsesActions, getHorsesActionTypes} from 'HorseProfile/store/horseList/types';

const initialState: IHorseListState['ui'] = {
  pagination: {countPerPage: MIN_COUNT_PER_PAGE, currentPage: 1, pageCount: 1, totalItemsCount: 1}
};

export const uiReducer: Reducer<IHorseListState['ui'], HorsesActions> = (
  state = initialState,
  action: HorsesActions
) => {
  switch (action.type) {
    case getHorsesActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
