import React from 'react';
import styled from 'styled-components';

import {userStatuses} from 'UserProfile/constants/OrderStatuses';
import {MainInfoRow, MainNameRow} from 'UserProfile/components/common/styled';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';
import {IUserHorseOrder} from 'Horse/models/IUserHorse';
import {OrderStatus} from 'Common/constants/OrderStatus';
import ColoredIcon from 'Icon/components/ColoredIcon';
import Theme from 'Common/constants/Theme';
import withDate from 'Common/helpers/withDate';

const Container = styled.div`
  width: 100%;
  min-width: 130px;

  @media ${breakpoints.sm} {
    width: 130px;
    margin-left: 10px;
  }

  @media ${breakpoints.md} {
    margin-left: 5px;
  }
`;

const OrderInfoWrapper = styled.div`
  text-align: right;

  @media ${breakpoints.sm} {
    margin-left: 30px;
    align-items: start;
  }
`;

const Status = styled(MainNameRow)<{color: string}>`
  width: auto;
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  color: ${(props) => props.color};
`;

const OrderId = styled(MainInfoRow)`
  font-weight: ${Typography.weight.medium500};
  color: ${ColorPalette.gray36};
  margin-bottom: 12px;

  @media ${breakpoints.md} {
    margin-right: 0;
  }
`;

const StatusLabel = styled.div`
  display: flex;
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 12px;
  letter-spacing: 0.5px;
  color: ${ColorPalette.gray44};

  @media ${breakpoints.sm} {
    display: none;
  }
`;

const StatusWrapper = styled.div`
  margin-left: 35px;

  @media ${breakpoints.sm} {
    margin-left: 0;
  }
`;

const Icon = styled(ColoredIcon)`
  margin-right: 13.5px;
`;

const IconedWrapper = styled.div`
  justify-content: flex-end;
  text-align: end;

  @media ${breakpoints.sm} {
    justify-content: start;
    text-align: start;
  }
`;

const PackagesWrapper = styled.div`
  margin-top: 14px;
`;

const Packages = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 20px;
  color: ${ColorPalette.black1};
  text-align: end;

  @media ${breakpoints.sm} {
    margin-left: 30px;
    text-align: start;
  }
`;

interface IProps {
  order: IUserHorseOrder;
}

export const mapStatusToStyles: Record<OrderStatus, {size: number}> = {
  [OrderStatus.orderPlaced]: {size: 16},
  [OrderStatus.paymentReceived]: {size: 20},
  [OrderStatus.sampleReceived]: {size: 20},
  [OrderStatus.resultsReady]: {size: 20},
  [OrderStatus.reviewReady]: {size: 20},
  [OrderStatus.releaseReady]: {size: 16},
};

const OrderOverview = ({order}: IProps) => {
  const packages = order.packages?.join(', ');
  return (
    <Container className="d-flex flex-column">
      <div className="d-flex justify-content-between">
        <StatusLabel className="w-100">Order status</StatusLabel>
        <StatusWrapper className="w-100">
          <IconedWrapper className="d-flex">
            <Icon
              name={userStatuses[order.status].icon}
              color={userStatuses[order.status].color}
              size={mapStatusToStyles[order.status].size}
              fill={true}
              stroke={false}
            />
            <Status color={userStatuses[order.status].color}>{userStatuses[order.status].label}</Status>
          </IconedWrapper>
          <OrderInfoWrapper className="d-flex flex-column">
            <OrderId>{`#${order.id}`}</OrderId>
            <MainInfoRow>{withDate(order.createDate)}</MainInfoRow>
          </OrderInfoWrapper>
        </StatusWrapper>
      </div>

      <PackagesWrapper className="d-flex justify-content-between">
        <StatusLabel className="w-100">Tests ordered</StatusLabel>
        <Packages>{packages}</Packages>
      </PackagesWrapper>
    </Container>
  );
};

export default OrderOverview;
