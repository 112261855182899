import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {PropsValue, InputActionMeta, ActionMeta} from 'react-select/dist/declarations/src/types';
import {FormatOptionLabelMeta} from 'react-select/dist/declarations/src/Select';
import Highlighter from 'react-highlight-words';
import {components} from 'react-select';
import {InputProps} from 'react-select/dist/declarations/src/components/Input';

import {Select} from 'Common/components/Controls';
import ColorPalette from 'Common/constants/ColorPalette';
import {castToOption} from 'Common/helpers/OptionHelper';
import {IOption} from 'Common/models/IOption';
import {IHorseSample} from 'Admin/AdminDashboard/models/Order/IHorseSample';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';

const NewSampleItem = styled.div<{isValue: boolean}>`
  ${(props) => !props.isValue && 'margin-top: 6px;'}
`;

const Input = (props: InputProps<IDictionaryOptionType>) => <components.Input {...props} isHidden={false} />;

const NewSampleLabel = styled.span`
  color: ${ColorPalette.gray44};
`;

const noSamplesMessage = () => 'No samples found';

const styles = {
  dropdownIndicator: () => ({
    display: 'none',
  }),
};

interface ISampleOptionGroup {
  label: string;
  options: IDictionaryOptionType[];
}

interface IProps {
  selectedId?: number;
  horseSamples: IHorseSample[];
  onSelectSample(sample: IOption): void;
}

function SearchSampleInput(props: IProps) {
  const {horseSamples, selectedId, onSelectSample} = props;

  const [newSample, setNewSample] = useState('');
  const [selectedValue, setSelectedValue] = useState<IOption>();
  const [inputValue, setInputValue] = useState('');
  const [firstInput, setFirstInput] = useState(0);

  const selectRef = useRef<any>(null);

  const newSampleId = useMemo(() => ({value: 0, label: newSample, isInvisible: !newSample}), [newSample]);

  const sampleOptions = useMemo(() => {
    const options: ISampleOptionGroup[] = [
      {label: 'Current samples', options: castToOption(horseSamples, {key: 'sampleId'})},
      {label: 'Create new Sample ID', options: [newSampleId]},
    ];
    return options;
  }, [horseSamples, newSampleId]);

  useEffect(() => {
    if (firstInput < 2 && horseSamples.length > 0) {
      const foundSample = sampleOptions
        .find((x) => x.label === 'Current samples')
        ?.options.find((y) => y.value === selectedId);
      setSelectedValue(foundSample);
      setInputValue(foundSample?.label || '');
      setFirstInput(firstInput + 1);
    }
  }, [firstInput, horseSamples, sampleOptions, selectedId]);

  useEffect(() => {
    let foundSample = sampleOptions
      .find((x) => x.label === 'Current samples')
      ?.options.find((y) => y.label.toLowerCase() === inputValue.toLowerCase());
    if (!foundSample) {
      foundSample = newSampleId;
    }
    onSelectSample(foundSample);
  }, [inputValue, newSampleId, onSelectSample, sampleOptions]);

  const onInputChange = useCallback(
    (value: string, {action}: InputActionMeta) => {
      if (action === 'input-change') {
        setInputValue(value);
        if (!horseSamples.some((x) => x.sampleId === value)) {
          setNewSample(value);
          return;
        }
        setNewSample('');
      }
    },
    [horseSamples]
  );

  const onChange = useCallback(
    (sample: PropsValue<IOption>, action: ActionMeta<IOption>) => {
      if (action.action === 'select-option' && sample) {
        const sampleOption = sample as IOption;
        setSelectedValue(sampleOption);
        setInputValue(sampleOption.label || '');
        onSelectSample(sampleOption);
      }
      if (action.action === 'clear') {
        setSelectedValue(undefined);
        setInputValue('');
      }
    },
    [onSelectSample]
  );

  const onFocus = () => selectedValue && selectRef?.current?.select?.inputRef.select();

  const renderItem = (option: IOption, labelMeta: FormatOptionLabelMeta<IOption>) => {
    const {label, value} = option;

    if (value !== 0) {
      return (
        <Highlighter
          searchWords={[labelMeta.inputValue]}
          textToHighlight={label}
          highlightStyle={{fontWeight: 500, background: 'none', margin: 0, padding: 0}}
        />
      );
    }

    return (
      <NewSampleItem className="d-flex align-items-center" isValue={labelMeta.context === 'value'}>
        {option.label}
        <NewSampleLabel>&nbsp;(new SID)</NewSampleLabel>
      </NewSampleItem>
    );
  };

  return (
    <Select
      inputRef={selectRef}
      name="sampleId"
      label="New Sample ID"
      placeholder="New Sample ID"
      options={sampleOptions}
      onInputChange={onInputChange}
      formatOptionLabel={renderItem}
      onChange={onChange}
      value={selectedValue}
      onFocus={onFocus}
      inputValue={inputValue}
      controlShouldRenderValue={false}
      noOptionsMessage={noSamplesMessage}
      styles={styles}
      components={{
        Input,
      }}
    />
  );
}

export default memo(SearchSampleInput);
