import {Reducer} from 'redux';

import {
  Actions,
  getAssociationEmployeesActionTypes,
  getUserCouponActionTypes,
  getUserHorsesActionTypes,
  getUserSummaryActionTypes,
  IAdminAssociationCreateUserOrderState,
  resetAdminUserOrderActionTypes,
  resetUserCouponActionTypes,
  resetUserSummaryActionTypes,
} from '../types';

const initialStateList: IAdminAssociationCreateUserOrderState['data'] = {
  userHorses: [],
  userSummary: null,
  userCoupon: null,
  associationEmployees: [],
};

export const dataReducer: Reducer<IAdminAssociationCreateUserOrderState['data'], Actions> = (
  state = initialStateList,
  action: Actions
) => {
  switch (action.type) {
    case getUserHorsesActionTypes.SUCCESS: {
      return {...state, userHorses: action.payload};
    }
    case getUserSummaryActionTypes.SUCCESS: {
      return {...state, userSummary: action.payload};
    }
    case getUserCouponActionTypes.SUCCESS: {
      return {...state, userCoupon: action.payload};
    }
    case getAssociationEmployeesActionTypes.SUCCESS: {
      return {...state, associationEmployees: action.payload};
    }
    case resetUserCouponActionTypes.RESET: {
      return {...state, userCoupon: null};
    }
    case resetUserSummaryActionTypes.RESET: {
      return {...state, userSummary: null};
    }
    case resetAdminUserOrderActionTypes.RESET: {
      return {...state, initialStateList};
    }
    default: {
      return state;
    }
  }
};
