import styled from 'styled-components';

import {breakpoints} from 'Common/constants/Breakpoints';
import {mediumMdSmBreakpoint} from 'UserProfile/components/TabsData/common/constants';

export const ContainerBase = styled.div`
  width: 100%;

  @media ${breakpoints.sm} {
    justify-content: flex-end;
  }
`;

export const MissingOrderContainer = styled(ContainerBase)`
  width: 100%;
  flex-direction: column;

  @media ${breakpoints.sm} {
    width: 60%;
    flex-direction: row;
    justify-content: flex-start;
  }

  @media ${mediumMdSmBreakpoint} {
    width: 168px;
    flex-direction: column;
    align-items: center;
  }
`;
