import {Reducer} from 'redux';

import {
  AdminAssociationHorseProfileActions,
  getHorseAbilitiesActionTypes,
  getHorseBreedPanelActionTypes,
  getHorseGenotypeGroupsActionTypes,
  getHorseLastOrderActionTypes,
  getHorseOwnerActionTypes,
  getHorseParantageReportActionTypes,
  getOwnerHorsesAtionTypes,
  IAdminAssociationHorseProfileState,
  resetHorseAbilitiesActionTypes,
  resetHorseBreedPanelActionTypes,
  resetHorseGenotypeGroupsActionTypes,
  resetHorseLastOrderActionTypes,
  resetHorseParantageReportActionTypes,
} from '../types';

const initialState: IAdminAssociationHorseProfileState['data'] = {
  horseAbilities: [],
  horseParantageReport: null,
  horseGenotypeGroups: [],
  horseBreedPanel: [],
  horseLastOrder: null,
  horseOwner: null,
  ownerHorses: [],
};

export const dataHorseReducer: Reducer<
  IAdminAssociationHorseProfileState['data'],
  AdminAssociationHorseProfileActions
> = (state = initialState, action: AdminAssociationHorseProfileActions) => {
  switch (action.type) {
    case getHorseAbilitiesActionTypes.SUCCESS: {
      return {...state, horseAbilities: action.payload};
    }
    case getHorseParantageReportActionTypes.SUCCESS: {
      return {...state, horseParantageReport: action.payload};
    }
    case getHorseGenotypeGroupsActionTypes.SUCCESS: {
      return {...state, horseGenotypeGroups: action.payload};
    }
    case getHorseBreedPanelActionTypes.SUCCESS: {
      return {...state, horseBreedPanel: action.payload};
    }
    case getHorseLastOrderActionTypes.SUCCESS: {
      return {...state, horseLastOrder: action.payload};
    }
    case getHorseOwnerActionTypes.SUCCESS: {
      return {...state, horseOwner: action.payload};
    }
    case getOwnerHorsesAtionTypes.SUCCESS: {
      return {...state, ownerHorses: action.payload};
    }

    case resetHorseAbilitiesActionTypes.RESET: {
      return {...state, horseAbilities: initialState.horseAbilities};
    }
    case resetHorseParantageReportActionTypes.RESET: {
      return {...state, horseParantageReport: initialState.horseParantageReport};
    }
    case resetHorseGenotypeGroupsActionTypes.RESET: {
      return {...state, horseGenotypeGroups: initialState.horseGenotypeGroups};
    }
    case resetHorseBreedPanelActionTypes.RESET: {
      return {...state, horseBreedPanel: initialState.horseBreedPanel};
    }
    case resetHorseLastOrderActionTypes.RESET: {
      return {...state, horseLastOrder: initialState.horseLastOrder};
    }

    default: {
      return state;
    }
  }
};
