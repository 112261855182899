import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {IStringDescription} from 'Common/models/IDescriptionEntity';
import {IIndexed} from 'Common/models/IIndexed';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IBreedPanel} from 'Dictionaries/models/IBreedPanel';
import {IUpdateBreedPanelRequest} from 'Admin/AdminReference/services/models/IServerBreedPanel';

export interface IFormValues extends IStringDescription, IIndexed {
  id?: IEntity['id'];
  name: string;
  tests: number[];
  breeds: number[];
}

export const initialValue: IFormValues = {
  name: '',
  description: '',
  breeds: [],
  tests: [],
  positionIndex: 0
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  breeds: Yup.array()
    .of(Yup.number())
    .required('Breeds are required'),
  tests: Yup.array()
    .of(Yup.number())
    .required('Tests are required'),
  positionIndex: Yup.number().required(REQUIRED_FIELD)
});

export const convertToFormValues = (values: IBreedPanel): IFormValues => {
  return {
    ...values,
    breeds: values.breeds.map(x => x.id),
    tests: values.tests.map(x => x.id)
  };
};

export const convertToServer = (values: IFormValues): Omit<IUpdateBreedPanelRequest, 'id'> => {
  return {
    ...values,
    description: {value: values.description || ''},
    breeds: values.breeds.map(x => ({id: x})),
    tests: values.tests.map(x => ({id: x}))
  };
};

export const convertToServerUpdating = (values: IFormValues): IUpdateBreedPanelRequest => {
  return {
    ...convertToServer(values),
    id: values.id!
  };
};
