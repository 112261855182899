import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {Field, FieldLabel, FieldValue} from 'Admin/common/styled/StyledComponents';
import Loading from 'Loading/components/Loading';
import {actions, AdminHorsesModule, selectors} from 'Admin/AdminDashboard/store/adminHorses';
import {IAppState} from 'Common/store/IAppState';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {useSuccessCommunicationToToast} from 'Common/helpers/hooks/useSuccessCommunicationToToast';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import GeneticDataVisibilitySwitcher from './GeneticDataVisibilitySwitcher';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';

const Root = styled.div`
  margin-right: 24px;
`;

interface IProps {
  horseId: number;
  horseName: string;
  isGeneticDataHidden: boolean;
  isAncestryReportHidden: boolean;

  onSuccess?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IProps;

function GeneticDataVisibility(props: AllProps) {
  const {
    horseId,
    horseName,
    isAncestryReportHidden,
    isGeneticDataHidden,
    onSuccess,
    ancestryReportHiding,
    geneticDataHiding,
    setHideAncestryReport,
    setHideGeneticData,
  } = props;

  const [isGeneticDataHiddenState, setIsGeneticDataHiddenState] = useState(!isGeneticDataHidden);
  const [isAncestryReportHiddenState, setIsAncestryReportHiddenState] = useState(!isAncestryReportHidden);

  useSuccessCommunicationToToast(geneticDataHiding, 'Visible of Genetic data successfully changed');
  useSuccessCommunicationToToast(ancestryReportHiding, 'Visible of Ancestry report successfully changed');

  useOnSuccessCommunication(geneticDataHiding, () => setIsGeneticDataHiddenState(!isGeneticDataHiddenState));
  useOnSuccessCommunication(ancestryReportHiding, () => setIsAncestryReportHiddenState(!isAncestryReportHiddenState));

  useErrorCommunicationToToast(geneticDataHiding);
  useErrorCommunicationToToast(ancestryReportHiding);

  const setGeneticDataVisibility = useCallback(
    (value: boolean, horseId: number) => {
      setHideGeneticData({horseId, isHidden: !value});
    },
    [setHideGeneticData]
  );

  const setAncestryReportVisibility = useCallback(
    (value: boolean, horseId: number) => {
      setHideAncestryReport({horseId, isHidden: !value});
    },
    [setHideAncestryReport]
  );

  const isRequesting = [ancestryReportHiding, geneticDataHiding].some((x) => x.isRequesting);

  return (
    <>
      <ModalWindowHeader>Horse data visibility</ModalWindowHeader>
      <div className="d-flex flex-column justify-content-center">
        <Root>
          <ModalWindowFormContent scrollable={false}>
            {isRequesting && <Loading />}
            <div className="row">
              <Field className="col-4">
                <FieldLabel>Horse</FieldLabel>
                <FieldValue>{horseName}</FieldValue>
              </Field>
            </div>
            <GeneticDataVisibilitySwitcher
              id={horseId}
              onChange={setGeneticDataVisibility}
              isActive={isGeneticDataHiddenState}
              description="Genetic data visibility"
            />
            <GeneticDataVisibilitySwitcher
              id={horseId}
              onChange={setAncestryReportVisibility}
              isActive={isAncestryReportHiddenState}
              description="Ancestry report visibility"
            />
          </ModalWindowFormContent>
        </Root>
        <ModalWindowFooter>
          <ModalWindowButton type="button" onClick={onSuccess}>
            Close
          </ModalWindowButton>
        </ModalWindowFooter>
      </div>
    </>
  );
}

const mapStateToProps = (state: IAppState) => ({
  geneticDataHiding: selectors.selectCommunication(state, 'geneticDataHiding'),
  ancestryReportHiding: selectors.selectCommunication(state, 'ancestryReportHiding'),
});

const mapDispatchToProps = {
  setHideGeneticData: actions.setHideGeneticData,
  setHideAncestryReport: actions.setHideAncestryReport,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withDynamicModules(connector(memo(GeneticDataVisibility)), AdminHorsesModule);
