import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {TooltipTippyBase} from 'Common/components/Tooltip/Tooltip';
import Theme from 'Common/constants/Theme';

export const LabelBase = styled.div<{disabled?: boolean}>`
  margin-bottom: 20px;
  min-width: 82px;
  text-align: right;
  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 12px;
  color: ${props => (props.disabled ? ColorPalette.white9 : ColorPalette.gray44)};
  text-align: left;

  @media ${breakpoints.sm} {
    margin-bottom: 14px;
    text-align: right;
  }
`;

export const ValueBase = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.black1};
  max-height: 24px;
`;

export const Separator = styled.hr`
  display: flex;
  border: 1px solid ${ColorPalette.white9};
  margin: 15px 0;

  @media ${breakpoints.sm} {
    display: none;
  }
`;

export const ButtonBase = styled(PrimaryButton)`
  width: 100%;
  letter-spacing: -0.2px;
`;

export const AddFavoriteButton = styled(ButtonBase)`
  &:hover:not(:disabled):not(.primary-button__loading) {
    background: ${ColorPalette.yellow12}!important;
  }
`;

export const DeleteFavoriteButton = styled(ButtonBase)`
  color: ${ColorPalette.black0};
  background-color: ${ColorPalette.yellow9};

  &:hover:not(:disabled):not(.primary-button__loading) {
    background: ${ColorPalette.yellow10};
  }
`;

export const FavoriteButtonsCustomTooltip = styled(TooltipTippyBase)`
  background: rgba(124, 124, 124, 0.9);
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: 10px;
  line-height: 12px;
`;

export const ItemContainer = styled.div`
  background: ${ColorPalette.gray49};
  border-radius: 3px;

  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  text-align: center;
  color: ${ColorPalette.black1};
  padding: 8px 24px;
`;

export const ItemWrapper = styled.div`
  margin: 0 1px 8px 0;
`;

export const ValueWrapper = styled(ValueBase)`
  justify-content: flex-end;
  text-align: right;
  max-height: fit-content;
  margin: -6px 0 0 8px;

  @media ${breakpoints.sm} {
    justify-content: flex-start;
    text-align: left;
  }
`;
