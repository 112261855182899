enum QualityType {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Max = 'max'
}

const mapTypeToKey: Record<QualityType, string> = {
  [QualityType.Low]: 'sddefault',
  [QualityType.Medium]: 'mqdefault',
  [QualityType.High]: 'hqdefault',
  [QualityType.Max]: 'maxresdefault'
};

export function getYouTubeThumbnailUrl(url: string | undefined, quality: QualityType = QualityType.High) {
  if (!url) {
    return false;
  }

  let videoId;
  const result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/);
  const resultShort = url.match(/youtu.be\/(.{11})/);

  if (result) {
    videoId = result.pop();
  } else if (resultShort) {
    videoId = resultShort.pop();
  }

  if (!videoId) {
    return false;
  }

  return 'http://img.youtube.com/vi/' + videoId + '/' + mapTypeToKey[quality] + '.jpg';
}
