import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IHorsePackage} from 'Order/models/IHorsePackage';
import {IHorsePackageRequest} from './types/shoppingCart';
import {IHorseTest} from 'Order/models/IHorseTest';
import {convertIHorsePackagesToIHorseTest} from './converters/shoppingCart';

const getShoppingCart = async (): Promise<IHorseTest[]> => {
  const response = await axiosWrapper.get<IListResponse<IHorsePackage>>(`/UserHorsePackageBucket`);
  return convertIHorsePackagesToIHorseTest(response.data.data);
};

const createShoppingCart = async (request: IHorsePackageRequest): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<void>>(`/UserHorsePackageBucketCreate`, request);
};

const deleteShoppingCart = async (request: IHorsePackageRequest): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<void>>(`/UserHorsePackageBucketDelete`, request);
};

const clearShoppingCart = async (): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<void>>(`/UserHorsePackageBucketClear`);
};

export default {
  getShoppingCart,
  createShoppingCart,
  deleteShoppingCart,
  clearShoppingCart,
};
