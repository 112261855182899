import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {getMainPageHorsesActions, getMainPageHorsesActionTypes, IMainPageHorsesState} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import MainPageService from 'Main/services/MainPageService';

export const getHorses = (
  filter?: string
): ActionResult<IMainPageHorsesState, void, getMainPageHorsesActions> => async dispatch => {
  try {
    dispatch(action(getMainPageHorsesActionTypes.REQUEST));
    const horseList = await MainPageService.getHorses(filter);
    dispatch(action(getMainPageHorsesActionTypes.SUCCESS, horseList));
  } catch (error) {
    dispatch(action(getMainPageHorsesActionTypes.FAILURE, getErrorMessage(error)));
  }
};
