import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.businessPortalHorse;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectHorses(state: IAppState) {
  return selectState(state).data.horses;
}

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectHorseDetails(state: IAppState) {
  return selectState(state).data.horseDetails;
}

export function selectHorseMedia(state: IAppState) {
  return selectState(state).data.horseMedia;
}

export function selectHorseOwner(state: IAppState) {
  return selectState(state).data.horseOwner;
}

export function selectHorseParentage(state: IAppState) {
  return selectState(state).data.horseParentage;
}

export function selectHorseRegistries(state: IAppState) {
  return selectState(state).data.horseRegistries;
}

export function selectHorseHealthIssues(state: IAppState) {
  return selectState(state).data.horseHealthIssues;
}
