import {Reducer} from 'redux';

import {
  IBusinessPortalOwnerProfileState,
  OwnerProfileActions,
  getOwnerActionTypes,
  getHorsesByOwnerActionTypes,
} from '../types';

const initialState: IBusinessPortalOwnerProfileState['data'] = {
  owner: null,
  horsesByOwner: [],
};

export const dataOwnerProfileReducer: Reducer<IBusinessPortalOwnerProfileState['data'], OwnerProfileActions> = (
  state = initialState,
  action: OwnerProfileActions
) => {
  switch (action.type) {
    case getOwnerActionTypes.SUCCESS: {
      return {...state, owner: action.payload};
    }
    case getHorsesByOwnerActionTypes.SUCCESS: {
      return {...state, horsesByOwner: action.payload};
    }

    default: {
      return state;
    }
  }
};
