export const selectStyles = {
  container: () => ({
    minHeight: 32,
    minWidth: 120,
    maxWidth: 300,
  }),
  control: () => ({
    minHeight: 32,
    maxHeight: 32,
  }),
  indicatorsContainer: () => ({
    maxHeight: 32,
  }),
  valueContainer: () => ({
    padding: '0 0 0 12px',
  }),
  menu: () => ({width: 'max-content', minWidth: '100%'}),
};
