import React, {memo, useCallback} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import ColorPalette from 'Common/constants/ColorPalette';
import {IMainPageHorse} from 'Main/models/IMainPageHorses';
import SquareAvatar from 'Common/components/Avatar/SquareAvatar';
import {ElementPosition} from 'FoalProfile/models/IElementPosition';
import Typography from 'Common/constants/Typography';
import {dateDiffInYearsOrMonth} from 'Common/helpers/DateHelper';

const Card = styled.div`
  width: 250px;
  height: 281px;
  background: ${ColorPalette.white0};
  box-shadow: 0px 6px 14px ${ColorPalette.transparent5};
  border-radius: 10px;
`;

const Name = styled.div`
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${ColorPalette.black6};
  margin-top: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
`;

const HorseInfo = styled.div`
  font-family: ${Typography.family.roboto};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size13};
  line-height: 24px;
  color: ${ColorPalette.gray42};
`;

const NameBlock = styled.div`
  cursor: default;
  width: 100%;
  margin-left: 16px;
`;

interface IProps {
  horse: IMainPageHorse;
}

type Props = IProps;

function HorseCard(props: Props) {
  const {horse} = props;

  const history = useHistory();

  const redirectToHorseProfile = useCallback(() => {
    history.push(`/horse/${horse.id}`);
  }, [history, horse]);

  return (
    <Card className="d-flex flex-column">
      <SquareAvatar
        onClick={redirectToHorseProfile}
        avatar={horse?.avatar}
        elementPosition={ElementPosition.Top}
        height="193px"
      />

      <div className="d-flex align-items-center">
        <NameBlock>
          <Name>{horse.name}</Name>
          <div>
            {horse.dateOfBirth ? (
              <HorseInfo>
                {horse.gender}, <strong>{dateDiffInYearsOrMonth(horse.dateOfBirth)}</strong>
              </HorseInfo>
            ) : (
              <HorseInfo>{horse.gender}</HorseInfo>
            )}
          </div>
        </NameBlock>
      </div>
    </Card>
  );
}

export default memo(HorseCard);
