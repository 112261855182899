import * as Yup from 'yup';

import {IUpdateHorseParentageBP, IUpdateHorseParentBP} from 'BusinessPortal/models/horse/IHorseParentageBP';
import {IRegistryRequest} from 'Common/models/IRegistryRequest';
import {Parentage} from 'HorseProfile/constants/Parentage';

export interface IFormValues extends IUpdateHorseParentageBP {
  registries: IRegistryRequest[];
}

export const initialValue: IFormValues = {
  horseId: 0,
  registries: [{associationId: 0, registrationNumber: null}],
  parents: [
    {name: '', parent: Parentage.Sire, registries: []},
    {name: '', parent: Parentage.Dam, registries: []}
  ]
};

const registryShape = Yup.array().of(
  Yup.object().shape<IRegistryRequest>({
    registrationNumber: Yup.string().nullable(),
    associationId: Yup.number().nullable()
  })
);

const parentageShape = Yup.array().of(
  Yup.object().shape<IUpdateHorseParentBP>({
    registries: registryShape,
    name: Yup.string(),
    parent: Yup.mixed<Parentage>()
  })
);

export const validationSchema = Yup.object().shape<IFormValues>({
  horseId: Yup.number().required(),
  registries: registryShape,
  parents: parentageShape
});
