import * as Yup from 'yup';

export interface IFormValues {
  id: number;
  sampleId: string;
}

export const initialValue: IFormValues = {
  id: 0,
  sampleId: ''
};

export const validationSchema = Yup.object().shape<IFormValues>({
  id: Yup.number(),
  sampleId: Yup.string()
});
