import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.adminGeneGroupImage;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectGeneGroupImage(state: IAppState) {
  return selectState(state).data.image;
}
