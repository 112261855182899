import React, {memo} from 'react';

import RulesBackup from 'Admin/AdminAdvancedDashboard/components/Emergencies/RulesBackup/RulesBackup';
import AccessPanels from 'Admin/AdminAdvancedDashboard/components/Emergencies/AccessPanels/AccessPanels';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';

const Emergencies = () => {
  return (
    <AdminPageLayout className="d-flex w-100 h-100">
      <RulesBackup />
      <AccessPanels />
    </AdminPageLayout>
  );
};

export default memo(Emergencies);
