import {SelectedStrategy} from './SelectedStrategy';
import StrategyService from './StrategyService';

export default function useDistributorStrategy() {
  const currentStrategy = StrategyService.getStrategy();

  const setStrategy = (strategy: SelectedStrategy) => {
    StrategyService.setStrategy(strategy);
  };

  return {currentStrategy, setStrategy};
}
