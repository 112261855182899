import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

export const Title = styled.div`
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size32};
  line-height: 56px;
  color: ${ColorPalette.black0};
  margin-bottom: 32px;
`;

export const ItemContainer = styled.div`
  width: 310px;
  min-height: 210px;
  background: ${ColorPalette.white0};
  border-radius: 8px;
  padding: 16px;
`;

export const Divider = styled.div`
  width: 100%;
  background-color: ${ColorPalette.white9};
  margin-top: 11px;
  margin-bottom: 8px;
  height: 1px;
`;
