import {IModule} from 'redux-dynamic-modules';
import {paymentDetailsUploadReducer} from 'Admin/AdminDashboard/store/common/paymentDetailsUpload/reducer/index';
import {IPaymentDetailsUploadState} from 'Admin/AdminDashboard/store/common/paymentDetailsUpload/types';

export interface IPaymentDetailsUploadModuleState {
  paymentDetailsUpload: IPaymentDetailsUploadState;
}

export const PaymentDetailsUploadModule: IModule<IPaymentDetailsUploadModuleState> = {
  id: 'paymentDetailsUpload',
  reducerMap: {
    paymentDetailsUpload: paymentDetailsUploadReducer
  }
};
