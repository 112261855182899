import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {IDescription, IShortDescriptionEntity} from 'Common/models/IDescriptionEntity';
import {GeneType} from 'Admin/AdminPhenotypes/constants/GeneType';
import {IAdminTrait} from 'Admin/AdminPhenotypes/models/IAdminTrait';
import {Nullable} from 'Common/types';
import {IIndexed} from 'Common/models/IIndexed';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues extends IShortDescriptionEntity, IIndexed {
  id?: IEntity['id'];
  name: string;
  test: Nullable<number>;
  state: GeneType;
}

export const initialValue: IFormValues = {
  name: '',
  description: {value: ''} as IDescription,
  test: null,
  state: GeneType.NotTested,
  positionIndex: 0
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  test: Yup.number()
    .nullable()
    .required(REQUIRED_FIELD),
  positionIndex: Yup.number().required(REQUIRED_FIELD)
});

export const convertToServer = (values: IFormValues): IAdminTrait => {
  return {
    ...values,
    test: values.test ? {id: values.test} : null,
    description: {value: values.description?.value || ''},
    id: values.id!
  };
};
