import {Reducer} from 'redux';

import {
  IAdminAssociationOwnerProfileState,
  OwnerActions,
  getOwnerActionTypes,
  getHorsesByOwnerActionTypes,
} from '../types';

const initialState: IAdminAssociationOwnerProfileState['data'] = {
  owner: null,
  horsesByOwner: [],
};

export const dataOwnerProfileReducer: Reducer<IAdminAssociationOwnerProfileState['data'], OwnerActions> = (
  state = initialState,
  action: OwnerActions
) => {
  switch (action.type) {
    case getOwnerActionTypes.SUCCESS: {
      return {...state, owner: action.payload};
    }
    case getHorsesByOwnerActionTypes.SUCCESS: {
      return {...state, horsesByOwner: action.payload};
    }

    default: {
      return state;
    }
  }
};
