import {IModule} from 'redux-dynamic-modules';

import {serviceModeReducer} from './reducer/index';
import {IServiceModeState} from './types';

export interface IServiceModeModuleState {
  serviceMode: IServiceModeState;
}

export const ServiceModeModule: IModule<IServiceModeModuleState> = {
  id: 'serviceMode',
  reducerMap: {
    serviceMode: serviceModeReducer
  }
};
