import React from 'react';
import ReactSwitch from 'react-switch';
import classnames from 'classnames';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {IBaseControlProps} from 'Common/models/IBaseControlProps';

const SwitchLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextAround = styled.div<{marginLeft: string; marginRight: string; isEnable: boolean}>`
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  font-family: ${Typography.family.roboto};
  font-size: ${Typography.size.size13};
  color: ${(props) => (props.isEnable ? ColorPalette.black1 : ColorPalette.white7)};
`;

interface IProps {
  onChange(value: boolean): void;
  textLeft?: React.ReactNode;
  textRight?: React.ReactNode;
  width?: number;
  height?: number;
}

type AllProps = IProps &
  IBaseControlProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'width' | 'height'>;

const Switch = (props: AllProps) => {
  const {onChange, checked = false, height, width, textRight, textLeft, labelStyle, ...other} = props;

  const activeBoxShadow = `0px 0px 4px ${ColorPalette.gray4}`;

  return (
    <SwitchLayout className={classnames('custom-control', props.className)} {...other}>
      {textLeft && (
        <TextAround
          className="align-self-center"
          style={labelStyle}
          marginLeft="0px"
          marginRight="10px"
          isEnable={!checked}
        >
          {textLeft}
        </TextAround>
      )}
      <ReactSwitch
        onChange={onChange}
        checked={checked}
        onColor={ColorPalette.green2}
        offColor={ColorPalette.white6}
        onHandleColor={ColorPalette.white0}
        offHandleColor={ColorPalette.white0}
        handleDiameter={height! - 3}
        width={width}
        height={height}
        checkedIcon={false}
        uncheckedIcon={false}
        activeBoxShadow={activeBoxShadow}
      />
      {textRight && (
        <TextAround
          className="align-self-center"
          style={labelStyle}
          marginLeft="10px"
          marginRight="0px"
          isEnable={checked}
        >
          {textRight}
        </TextAround>
      )}
    </SwitchLayout>
  );
};

Switch.defaultProps = {
  textLeft: '',
  textRight: '',
  width: 37,
  height: 20,
};

export default Switch;
export type SwitchProps = AllProps;
