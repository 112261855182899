import React, {memo} from 'react';

import TextArea, {TextAreaProps} from 'Common/components/Controls/TextArea';
import {IFieldProps} from 'Common/models/IFieldProps';

import withField from './withField';
import ValidationError from './ValidationError';

type Props = IFieldProps & TextAreaProps;

function TextAreaField(props: Props) {
  const {field, ...rest} = props;
  return (
    <>
      <TextArea {...rest} {...field} />
      <ValidationError name={field.name} />
    </>
  );
}

export default memo(withField(TextAreaField));
