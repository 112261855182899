import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {
  uploadTestResultsActions,
  uploadTestResultsActionTypes
} from 'Admin/AdminDashboard/store/adminTestResults/types';
import TestResultsService from 'Admin/AdminDashboard/services/TestResultsService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const uploadTestResults = (
  uploadedFile: FormData
): ActionResult<IAppState, void, uploadTestResultsActions> => async dispatch => {
  try {
    dispatch(action(uploadTestResultsActionTypes.REQUEST));
    await TestResultsService.uploadTestResults(uploadedFile);
    dispatch(action(uploadTestResultsActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(uploadTestResultsActionTypes.FAILURE, getErrorMessage(error)));
  }
};
