import React, {memo} from 'react';

import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';
import GeneGroupsForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/GeneGroups/GeneGroupForm/GeneGroupForm';
import {
  HAS_COLOR_IMAGE_ICON,
  HAS_RULE_ICON,
  NO_COLOR_IMAGE_ICON,
  NO_RULE_ICON
} from 'Admin/AdminPhenotypes/components/share/StyledComponents';
import {IAdminGeneGroup} from 'Admin/AdminPhenotypes/models/IAdminGeneGroup';

function GeneGroups() {
  const {adminGeneGroup: dictionary} = useDictionaries();

  return (
    <DictionaryLayout dictionary={dictionary} DictionaryForm={GeneGroupsForm}>
      {({onDelete, onEdit}) => (
        <>
          <Cell<IAdminGeneGroup> dataKey="id" width="10%" header="ID" render={({id}) => `#${id}`} />
          <Cell<IAdminGeneGroup> dataKey="name" width="25%" header="Name" render={({name}) => name} />
          <Cell<IAdminGeneGroup>
            dataKey="positionIndex"
            width="20%"
            header="Order in Report"
            sortable={false}
            render={({positionIndex}) => positionIndex}
          />
          <Cell<IAdminGeneGroup>
            header="Image"
            render={({image}) => (image ? HAS_COLOR_IMAGE_ICON : NO_COLOR_IMAGE_ICON)}
            width="20%"
          />
          <Cell<IAdminGeneGroup>
            dataKey="isShowVariantSummary"
            width="15%"
            header="Show variant summary"
            render={({isShowVariantSummary}) => (isShowVariantSummary ? HAS_RULE_ICON : NO_RULE_ICON)}
          />
          <Cell<IAdminGeneGroup>
            header="Actions"
            align={CellAlign.Right}
            width="10%"
            render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
          />
        </>
      )}
    </DictionaryLayout>
  );
}

export default memo(GeneGroups);
