import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  getPaymentDetailsActionTypes,
  IPaymentDetailsUploadState,
  PaymentDetailsUploadActions,
  updatePaymentDetailsActionTypes
} from 'Admin/AdminDashboard/store/common/paymentDetailsUpload/types';

export const communicationPaymentDetailsUploadReducer = combineReducers<
  IPaymentDetailsUploadState['communications'],
  PaymentDetailsUploadActions
>({
  paymentDetailsLoading: makeCommunicationReducerFromEnum(getPaymentDetailsActionTypes),
  paymentDetailsUpdating: makeCommunicationReducerFromEnum(updatePaymentDetailsActionTypes)
});
