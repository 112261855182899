import {Context} from '@datadog/browser-core/cjs/utils';

export function convertToLoggerContext(value: any, isWrapForLogger: boolean = true): Context {
  const context = (value as unknown) as Context;
  if (isWrapForLogger) {
    return {log_details: context};
  }

  return context;
}
