import {IModule} from 'redux-dynamic-modules';

import {IAdminUserFullInfoUpdateState} from './types';
import {userFullInfoUpdateReducer} from './reducer/index';

export interface IAdminUserFullInfoUpdateModuleState {
  adminUserFullInfoUpdate: IAdminUserFullInfoUpdateState;
}

export const AdminUserFullInfoUpdateModule: IModule<IAdminUserFullInfoUpdateModuleState> = {
  id: 'userFullInfo',
  reducerMap: {
    adminUserFullInfoUpdate: userFullInfoUpdateReducer,
  },
};
