import React, {memo, useCallback, useEffect} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminHorses/index';
import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {IFormValues, initialValue, validationSchema} from './validation';
import {IOption} from 'Common/models/IOption';
import SearchSampleInput from './parts/SearchSampleInput';

const Root = styled.div`
  margin-right: 24px;
`;

interface IExternalProps {
  horseId: number;
  id?: number;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function ChangeSampleIdForm(props: AllProps) {
  const {isSubmitting, values, setErrors, setFieldValue} = props;
  const {
    horseSamplesLoading,
    sampleIdActivating,
    sampleIdUpdating,
    getHorseSamples,
    resetHorseSamples,
    horseSamples,
    horseId,
    id,
    onSuccess,
    sampleCreating,
  } = props;

  const errorInfo =
    horseSamplesLoading.error || sampleIdActivating.error || sampleIdUpdating.error || sampleCreating.error;
  const isRequesting = [horseSamplesLoading, sampleIdActivating, sampleIdUpdating, sampleCreating].some(
    (x) => x.isRequesting
  );
  const isDisabled = !values.sampleId;

  useEffect(() => {
    if (horseId) {
      getHorseSamples(horseId);
    }

    return () => resetHorseSamples();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(sampleIdUpdating, onSuccess);
  useOnErrorCommunication(sampleIdUpdating, onError);
  useCommunicationToToast(sampleIdUpdating, 'Horse sample has been changed');

  useOnSuccessCommunication(sampleIdActivating, onSuccess);
  useOnErrorCommunication(sampleIdActivating, onError);
  useCommunicationToToast(sampleIdActivating, 'Horse sample has been changed');

  useOnSuccessCommunication(sampleCreating, onSuccess);
  useOnErrorCommunication(sampleCreating, onError);
  useCommunicationToToast(sampleCreating, 'Horse sample has been created');

  const onSelectSample = useCallback(
    (sample: IOption) => {
      setFieldValue('id', sample.value);
      setFieldValue('sampleId', sample.label);
    },
    [setFieldValue]
  );

  return (
    <>
      <ModalWindowHeader>Edit sample ID</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <Root>
          <ModalWindowFormContent scrollable={false}>
            {isRequesting && <Loading />}
            <SearchSampleInput horseSamples={horseSamples} onSelectSample={onSelectSample} selectedId={id} />
          </ModalWindowFormContent>
        </Root>
        <ModalWindowFooter>
          <ModalWindowButton type="submit" isLoading={isSubmitting} disabled={isDisabled}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const ChangeEmailFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {activateSampleId, updateSampleId, createSample, horseSamples, id, horseId} = formikBag.props;

    if (!horseSamples || horseSamples.length === 0) {
      await createSample(values.sampleId, horseId);
      return;
    }

    if (values.id === 0 && id) {
      await updateSampleId(id, values.sampleId);
    } else {
      await activateSampleId(values.sampleId);
    }
  },
  enableReinitialize: true,
})(ChangeSampleIdForm);

const mapStateToProps = (state: IAppState) => ({
  horseSamples: selectors.selectHorseSamples(state),
  horseSamplesLoading: selectors.selectCommunication(state, 'horseSamplesLoading'),
  sampleIdActivating: selectors.selectCommunication(state, 'sampleIdActivating'),
  sampleIdUpdating: selectors.selectCommunication(state, 'sampleIdUpdating'),
  sampleCreating: selectors.selectCommunication(state, 'sampleCreating'),
});

const mapDispatchToProps = {
  getHorseSamples: actions.getHorseSamples,
  updateSampleId: actions.updateSampleId,
  activateSampleId: actions.activateSampleId,
  createSample: actions.createSample,
  resetHorseSamples: actions.resetHorseSamples,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(ChangeEmailFormik));
