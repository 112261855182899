import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.businessPortalOrder;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export const selectOrders = (state: IAppState) => selectState(state).data.orders;
export const selectOrderDetails = (state: IAppState) => selectState(state).data.orderDetails;
export const selectOrderPackagesAndTests = (state: IAppState) => selectState(state).data.orderPackagesDetails;
export const selectOrderReports = (state: IAppState) => selectState(state).data.orderReports;
export const selectOrderStatuses = (state: IAppState) => selectState(state).data.orderStatuses;
export const selectPagination = (state: IAppState) => selectState(state).ui.pagination;
