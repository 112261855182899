import * as Yup from 'yup';
import {IChangeHorseOwner} from 'Admin/AdminDashboard/models/Horse/IChangeHorseOwner';

const newOwnerIdValidationMessage = 'New horse owner is a required field. Check provided email.';

export const initialValue: IChangeHorseOwner = {
  horseId: 0,
  newOwnerId: 0,
  clearPreclinicalNotes: true,
};

export const validationSchema = Yup.object().shape<IChangeHorseOwner>({
  horseId: Yup.number(),
  newOwnerId: Yup.number().required(newOwnerIdValidationMessage),
  clearPreclinicalNotes: Yup.boolean(),
});
