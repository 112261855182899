import {IResponseError} from 'Common/models/IError';

const EMAIL_CONFIRMATION_EXPIRED_ERROR = 'IdentityInvalidToken';
const EMAIL_NOT_CONFIRMED_ERROR = 'EmailNotConfirmed';

export function isEmailConfirmationExpired(response: IResponseError): boolean {
  return !!response?.errors?.find(error => error.errorCode === EMAIL_CONFIRMATION_EXPIRED_ERROR);
}

export function isEmailNotConfirmed(response: IResponseError): boolean {
  if (!response.errors || !Array.isArray(response.errors)) {
    return false;
  }
  return !!response?.errors?.find(error => error.errorCode === EMAIL_NOT_CONFIRMED_ERROR);
}
