import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import BusinessPortalService from 'BusinessPortal/services/BusinessPortalHorseService';
import {IUpdateHorseDetailsBP} from 'BusinessPortal/models/horse/IHorseDetailsBP';
import {IRequestParams} from 'Common/models/IRequestParams';
import {
  AdminAssociationHorseActions,
  createAdminAssociationHorseActions,
  createAdminAssociationHorseActionTypes,
  createAdminAssociationHorseImageMediaActions,
  createAdminAssociationHorseImageMediaActionTypes,
  createAdminAssociationHorseVideoMediaActions,
  createAdminAssociationHorseVideoMediaActionTypes,
  deleteAdminAssociationHorseActions,
  deleteAdminAssociationHorseActionTypes,
  deleteAdminAssociationHorseAvatarActions,
  deleteAdminAssociationHorseAvatarActionTypes,
  deleteAdminAssociationHorseMediaActions,
  deleteAdminAssociationHorseMediaActionTypes,
  getAdminAssociationHorseDetailsActions,
  getAdminAssociationHorseDetailsActionTypes,
  getAdminAssociationHorseHealthIssuesActions,
  getAdminAssociationHorseHealthIssuesActionTypes,
  getAdminAssociationHorseMediaActions,
  getAdminAssociationHorseMediaActionTypes,
  getAdminAssociationHorseOwnerActions,
  getAdminAssociationHorseOwnerActionTypes,
  getAdminAssociationHorseParentageActions,
  getAdminAssociationHorseParentageActionTypes,
  getAdminAssociationHorseRegistriesActions,
  getAdminAssociationHorseRegistriesActionTypes,
  getAdminAssociationHorsesActions,
  getAdminAssociationHorsesActionTypes,
  getAdminAssociationHorseSampleActions,
  getAdminAssociationHorseSampleActionTypes,
  resetAdminAssociationHorseDetailsActionTypes,
  resetAdminAssociationHorseHealthIssuesActionTypes,
  resetAdminAssociationHorseMediaActionTypes,
  resetAdminAssociationHorseOwnerActionTypes,
  resetAdminAssociationHorseParentageActionTypes,
  resetAdminAssociationHorseRegistriesActionTypes,
  resetAdminAssociationHorsesActionTypes,
  resetAdminAssociationHorseSampleActionTypes,
  setAdminAssociationHorseOwnerActions,
  setAdminAssociationHorseOwnerActionTypes,
  updateAdminAssociationHorseAvatarActions,
  updateAdminAssociationHorseAvatarActionTypes,
  updateAdminAssociationHorseDetailsActions,
  updateAdminAssociationHorseDetailsActionTypes,
  updateAdminAssociationHorseHealthIssuesActions,
  updateAdminAssociationHorseHealthIssuesActionTypes,
  updateAdminAssociationHorseParentageActions,
  updateAdminAssociationHorseParentageActionTypes,
  updateAdminAssociationHorseRegistriesActions,
  updateAdminAssociationHorseRegistriesActionTypes,
} from './types';
import {ICreateHorseBP} from 'BusinessPortal/models/horse/IHorseBP';
import {IUpdateHorseParentageBP} from 'BusinessPortal/models/horse/IHorseParentageBP';
import {IUpdateHorseRegistriesBP} from 'BusinessPortal/models/horse/IHorseRegistriesBP';
import {IUpdateHorseInconclusiveHealthIssuesBP} from 'BusinessPortal/models/horse/IInconclusiveHealthIssuesBP';
import {IMediaResource} from 'Common/models/IMediaResource';
import {IVideo} from 'Video/models/IVideo';
import AdminOrderDataService from 'Admin/AdminAssociations/services/AdminOrderDataService';

export const getHorses =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getAdminAssociationHorsesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminAssociationHorsesActionTypes.REQUEST));
      const data = await BusinessPortalService.getHorses(params, true);
      dispatch(action(getAdminAssociationHorsesActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getAdminAssociationHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createHorse =
  (data: ICreateHorseBP): ActionResult<IAppState, Promise<number>, createAdminAssociationHorseActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createAdminAssociationHorseActionTypes.REQUEST));
      const horseId = await BusinessPortalService.createHorse(data, true);
      dispatch(action(createAdminAssociationHorseActionTypes.SUCCESS));
      return horseId;
    } catch (error) {
      const err = getErrorMessage(error);
      dispatch(action(createAdminAssociationHorseActionTypes.FAILURE, err));
      throw err;
    }
  };

export const getHorseDetails =
  (horseId: number): ActionResult<IAppState, Promise<void>, getAdminAssociationHorseDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminAssociationHorseDetailsActionTypes.REQUEST));
      const data = await BusinessPortalService.getHorseDetails(horseId, true);
      dispatch(action(getAdminAssociationHorseDetailsActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getAdminAssociationHorseDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseOwner =
  (horseId: number): ActionResult<IAppState, Promise<void>, getAdminAssociationHorseOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminAssociationHorseOwnerActionTypes.REQUEST));
      const data = await BusinessPortalService.getHorseOwner(horseId, true);
      dispatch(action(getAdminAssociationHorseOwnerActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getAdminAssociationHorseOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseParentage =
  (horseId: number): ActionResult<IAppState, Promise<void>, getAdminAssociationHorseParentageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminAssociationHorseParentageActionTypes.REQUEST));
      const data = await BusinessPortalService.getHorseParentage(horseId, true);
      dispatch(action(getAdminAssociationHorseParentageActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getAdminAssociationHorseParentageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseRegistries =
  (horseId: number): ActionResult<IAppState, Promise<void>, getAdminAssociationHorseRegistriesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminAssociationHorseRegistriesActionTypes.REQUEST));
      const data = await BusinessPortalService.getHorseRegistries(horseId, true);
      dispatch(action(getAdminAssociationHorseRegistriesActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getAdminAssociationHorseRegistriesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseHealthIssues =
  (horseId: number): ActionResult<IAppState, Promise<void>, getAdminAssociationHorseHealthIssuesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminAssociationHorseHealthIssuesActionTypes.REQUEST));
      const data = await BusinessPortalService.getHorseHealthIssues(horseId, true);
      dispatch(action(getAdminAssociationHorseHealthIssuesActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getAdminAssociationHorseHealthIssuesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateHorseDetails =
  (data: IUpdateHorseDetailsBP): ActionResult<IAppState, Promise<void>, updateAdminAssociationHorseDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdminAssociationHorseDetailsActionTypes.REQUEST));
      await BusinessPortalService.updateHorseDetails(data, true);
      dispatch(action(updateAdminAssociationHorseDetailsActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateAdminAssociationHorseDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setHorseOwner =
  (
    horseId: number,
    horseOwnerId: number
  ): ActionResult<IAppState, Promise<void>, setAdminAssociationHorseOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(setAdminAssociationHorseOwnerActionTypes.REQUEST));
      await BusinessPortalService.setHorseOwner(horseId, horseOwnerId, true);
      dispatch(action(setAdminAssociationHorseOwnerActionTypes.SUCCESS));
    } catch (error) {
      const err = getErrorMessage(error);
      dispatch(action(setAdminAssociationHorseOwnerActionTypes.FAILURE, err));
    }
  };

export const updateHorseParentage =
  (
    data: IUpdateHorseParentageBP
  ): ActionResult<IAppState, Promise<void>, updateAdminAssociationHorseParentageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdminAssociationHorseParentageActionTypes.REQUEST));
      await BusinessPortalService.updateHorseParentage(data, true);
      dispatch(action(updateAdminAssociationHorseParentageActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateAdminAssociationHorseParentageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateHorseRegistries =
  (
    data: IUpdateHorseRegistriesBP
  ): ActionResult<IAppState, Promise<void>, updateAdminAssociationHorseRegistriesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdminAssociationHorseRegistriesActionTypes.REQUEST));
      await BusinessPortalService.updateHorseRegistries(data, true);
      dispatch(action(updateAdminAssociationHorseRegistriesActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateAdminAssociationHorseRegistriesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateHorseHealthIssues =
  (
    data: IUpdateHorseInconclusiveHealthIssuesBP
  ): ActionResult<IAppState, Promise<void>, updateAdminAssociationHorseHealthIssuesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdminAssociationHorseHealthIssuesActionTypes.REQUEST));
      await BusinessPortalService.updateHorseHealthIssues(data, true);
      dispatch(action(updateAdminAssociationHorseHealthIssuesActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateAdminAssociationHorseHealthIssuesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateHorseAvatar =
  (
    horseId: number,
    uploadedFile: File
  ): ActionResult<IAppState, Promise<void>, updateAdminAssociationHorseAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdminAssociationHorseAvatarActionTypes.REQUEST));
      await BusinessPortalService.updateHorseAvatar(horseId, uploadedFile, true);
      dispatch(action(updateAdminAssociationHorseAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateAdminAssociationHorseAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteHorseAvatar =
  (horseId: number): ActionResult<IAppState, Promise<void>, deleteAdminAssociationHorseAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteAdminAssociationHorseAvatarActionTypes.REQUEST));
      await BusinessPortalService.deleteHorseAvatar(horseId, true);
      dispatch(action(deleteAdminAssociationHorseAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteAdminAssociationHorseAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseMedia =
  (adminAssociationHorseId: number): ActionResult<IAppState, Promise<void>, getAdminAssociationHorseMediaActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminAssociationHorseMediaActionTypes.REQUEST));
      const data = await BusinessPortalService.getHorseMedia(adminAssociationHorseId, true);
      dispatch(action(getAdminAssociationHorseMediaActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getAdminAssociationHorseMediaActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createHorseImageMedia =
  (
    horseId: number,
    images: IMediaResource[]
  ): ActionResult<IAppState, Promise<void>, createAdminAssociationHorseImageMediaActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createAdminAssociationHorseImageMediaActionTypes.REQUEST));
      const files = images.filter((x) => x.file && x.file !== undefined).map((img) => img.file!);
      await BusinessPortalService.createHorseImageMedia(horseId, files, true);
      dispatch(action(createAdminAssociationHorseImageMediaActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createAdminAssociationHorseImageMediaActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createHorseVideoMedia =
  (
    adminAssociationHorseId: number,
    video: IVideo
  ): ActionResult<IAppState, Promise<void>, createAdminAssociationHorseVideoMediaActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createAdminAssociationHorseVideoMediaActionTypes.REQUEST));
      await BusinessPortalService.createHorseVideoMedia(adminAssociationHorseId, video, true);
      dispatch(action(createAdminAssociationHorseVideoMediaActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createAdminAssociationHorseVideoMediaActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteHorseMedia =
  (
    adminAssociationHorseId: number,
    mediaIds: number[]
  ): ActionResult<IAppState, Promise<void>, deleteAdminAssociationHorseMediaActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteAdminAssociationHorseMediaActionTypes.REQUEST));
      await BusinessPortalService.deleteHorseMedia(adminAssociationHorseId, mediaIds, true);
      dispatch(action(deleteAdminAssociationHorseMediaActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteAdminAssociationHorseMediaActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteHorse =
  (horseId: number): ActionResult<IAppState, Promise<void>, deleteAdminAssociationHorseActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteAdminAssociationHorseActionTypes.REQUEST));
      await BusinessPortalService.deleteHorse(horseId, true);
      dispatch(action(deleteAdminAssociationHorseActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteAdminAssociationHorseActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseSample =
  (horseId: number): ActionResult<IAppState, Promise<void>, getAdminAssociationHorseSampleActions> =>
  async (dispatch) => {
    dispatch(action(getAdminAssociationHorseSampleActionTypes.REQUEST));
    try {
      const res = await AdminOrderDataService.getHorseSamples(horseId);
      dispatch(action(getAdminAssociationHorseSampleActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAdminAssociationHorseSampleActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetHorseMedia = (): ActionResult<IAppState, void, AdminAssociationHorseActions> => (dispatch) => {
  dispatch(action(resetAdminAssociationHorseMediaActionTypes.RESET));
};

export const resetHorseDetails = (): ActionResult<IAppState, void, AdminAssociationHorseActions> => (dispatch) => {
  dispatch(action(resetAdminAssociationHorseDetailsActionTypes.RESET));
};

export const resetHorseOwner = (): ActionResult<IAppState, void, AdminAssociationHorseActions> => (dispatch) => {
  dispatch(action(resetAdminAssociationHorseOwnerActionTypes.RESET));
};

export const resetHorseParentage = (): ActionResult<IAppState, void, AdminAssociationHorseActions> => (dispatch) => {
  dispatch(action(resetAdminAssociationHorseParentageActionTypes.RESET));
};

export const resetHorseRegistries = (): ActionResult<IAppState, void, AdminAssociationHorseActions> => (dispatch) => {
  dispatch(action(resetAdminAssociationHorseRegistriesActionTypes.RESET));
};

export const resetHorseHealthIssues = (): ActionResult<IAppState, void, AdminAssociationHorseActions> => (dispatch) => {
  dispatch(action(resetAdminAssociationHorseHealthIssuesActionTypes.RESET));
};

export const resetHorses = (): ActionResult<IAppState, void, AdminAssociationHorseActions> => (dispatch) => {
  dispatch(action(resetAdminAssociationHorsesActionTypes.RESET));
};

export const resetHorseSample = (): ActionResult<IAppState, void, AdminAssociationHorseActions> => (dispatch) => {
  dispatch(action(resetAdminAssociationHorseSampleActionTypes.RESET));
};
