import React, {memo} from 'react';
import {IAdminHealthVariantRule} from 'Admin/AdminPhenotypes/models/IAdminHealthVariantRule';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout, {
  DictionaryFormProps
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';

import {IDictionary} from 'DictionaryFactory/types';
import {IUpdateHealthVariantRuleRequest} from 'Admin/AdminPhenotypes/service/models/IServerHealthVariantRule';
import HealthVariantRulesForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/HealthVariantRules/HealthVariantRuleForm/HealthVariantRuleForm';
import {getRuleIcon} from 'Admin/AdminPhenotypes/helpers/getRuleIcon';

function HealthVariantRules() {
  const {
    adminHealthVariantRules: dictionary,
    healthIssueGroupsAdvanced,
    aggregatedHealthVariants,
    affectionTypes
  } = useDictionaries();

  const wrappedHealthVariantRuleForm = (
    props: DictionaryFormProps<IDictionary<IAdminHealthVariantRule, IUpdateHealthVariantRuleRequest>>
  ) => {
    return (
      <HealthVariantRulesForm
        {...props}
        affectionTypeDictionary={affectionTypes}
        aggregatedHealthVariantDictionary={aggregatedHealthVariants}
        healthVariantGroupDictionary={healthIssueGroupsAdvanced}
      />
    );
  };

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={wrappedHealthVariantRuleForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IAdminHealthVariantRule> dataKey="id" width={100} header="ID" render={({id}) => `#${id}`} />
            <Cell<IAdminHealthVariantRule> dataKey="name" width="15%" header="Name" render={({name}) => name} />
            <Cell<IAdminHealthVariantRule>
              dataKey="positionIndex"
              width="10%"
              header="Order in Report"
              sortable={false}
              render={({positionIndex}) => positionIndex}
            />
            <Cell<IAdminHealthVariantRule>
              dataKey="affectionType"
              width="10%"
              header="Affection type"
              sortable={false}
              render={({affectionType}) => affectionType?.name}
            />
            <Cell<IAdminHealthVariantRule>
              dataKey="aggregator"
              width="10%"
              header="Health variant"
              sortable={false}
              render={({aggregator}) => aggregator?.name}
            />
            <Cell<IAdminHealthVariantRule>
              dataKey="group"
              width="10%"
              header="Group"
              sortable={false}
              render={({group}) => group?.name}
            />
            <Cell<IAdminHealthVariantRule> header="Rule" render={({sequences}) => getRuleIcon(sequences)} width="5%" />
            <Cell<IAdminHealthVariantRule>
              dataKey="description"
              width="20%"
              header="Description"
              sortable={false}
              render={({description}) => description?.value}
            />
            <Cell<IAdminHealthVariantRule>
              dataKey="externalDescriptionUrl"
              width="10%"
              header="URL"
              sortable={false}
              render={({externalDescriptionUrl}) => externalDescriptionUrl}
            />
            <Cell<IAdminHealthVariantRule>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(HealthVariantRules);
