import {IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {Nullable} from 'Common/types';
import {IHorseTransferCreate, IHorseTransferDetails} from 'Horse/models/IHorseTransfer';
import {convertHorseTranserDetailsToClient} from './converters/transfer';

const getHorseTransferDetails = async (id: number): Promise<Nullable<IHorseTransferDetails>> => {
  const response = await axiosWrapper.get<IObjectResponse<Nullable<IHorseTransferDetails>>>(
    `/HorseTransferRequestDetails/${id}`
  );
  return convertHorseTranserDetailsToClient(response.data.data);
};

const createHorseTransfer = async (params: IHorseTransferCreate): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<number>>(`/HorseTransferRequestsCreateUser/`, params);
};

export default {getHorseTransferDetails, createHorseTransfer};
