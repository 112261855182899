import {toast, ToastContent, ToastOptions, TypeOptions} from 'react-toastify';
import {useMediaQuery} from './useMediaQuery';
import {useMemo} from 'react';

export function useToast(toastId?: number | string) {
  const {isDesktop} = useMediaQuery();
  const toastPosition = useMemo(() => (isDesktop ? 'bottom-left' : 'top-center'), [isDesktop]);
  const defaultOptions: ToastOptions = {type: 'success', position: toastPosition, toastId: toastId};

  const addToast = (content: ToastContent, type?: TypeOptions, options?: ToastOptions) => {
    const toastOptions = {...defaultOptions, type: type ? type : defaultOptions.type, ...options};
    return toast(content, toastOptions);
  };

  const removeToast = (toastId?: string | number | undefined) => {
    toast.dismiss(toastId);
  };
  return {addToast, removeToast};
}
