import {memo} from 'react';
import styled from 'styled-components';

import {MutableFieldsGroupBase} from 'Common/components/StyledComponents/StyledComponents';
import {breakpoints} from 'Common/constants/Breakpoints';
import {Nullable} from 'Common/types';
import {IImage} from 'Image/models/IImage';
import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import {AncestryReportSection, Description, SummaryTitle} from '../common/styled';
import CompositionPie from './CompositionPie';
import {IAncesryComponentProps} from 'OnlineReport/models/Ancestry/IAncesryComponentProps';

const SplitWrapper = styled(MutableFieldsGroupBase)`
  height: 100%;
  margin-bottom: 30px;

  @media ${breakpoints.sm} {
    height: 300px;
    margin: 0;
  }
`;

interface IProps {
  population: IAncestryPopulation[];
  avatar?: Nullable<IImage>;
  isShowDescription?: boolean;
}

type AllProps = IProps & IAncesryComponentProps;

const CompositionChart = (props: AllProps) => {
  const {avatar, population, isShowTitle = true, isShowDescription = true, withoutPaddings = false} = props;

  return (
    <AncestryReportSection className="d-flex w-100 flex-column" withoutPaddings={withoutPaddings}>
      {isShowTitle && <SummaryTitle>Ancestry Identified</SummaryTitle>}
      <SplitWrapper pointOfChange="sm" className="d-flex w-100 justify-content-center">
        <CompositionPie population={population} avatar={avatar} isShowLabels isShowLegend isShowLegendPercentage />
      </SplitWrapper>
      {isShowDescription && (
        <Description>
          **Your analysis is ongoing and may include discovery or beta-stage data/calculations that may change over time
          as equine science evolves. The current defined/known population for the equine ancestry project may include
          unintended bias toward specific "breeds" or regions based on sample sizes/availability. lt is assumed to have
          an estimated error range of 5-7°/o which may change with growth and education in the sample population. The
          Etalon Ancestry and Composition testing is intended to assist in better understanding equine science, health &
          performance. As populations, disciplines and breeding trends change over time, sotoo will the results of each
          horse when compared within the population at that time. This service is not intended as a tool to provide
          specific breed or definitive registry information.
        </Description>
      )}
    </AncestryReportSection>
  );
};

export default memo(CompositionChart);
