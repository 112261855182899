import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.onlineReport;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectHorseInfo(state: IAppState) {
  return selectState(state).data.horseInfo;
}

export function selectHorseOwner(state: IAppState) {
  return selectState(state).data.horseOwner;
}

export function selectHorseDetails(state: IAppState) {
  return selectState(state).data.horseDetails;
}

export function selectSummaryAbilities(state: IAppState) {
  return selectState(state).data.summaryAbilities;
}

export function selectSummaryColors(state: IAppState) {
  return selectState(state).data.summaryColors;
}

export function selectSummaryHealthIssues(state: IAppState) {
  return selectState(state).data.summaryHealthIssues;
}

export function selectCoatColors(state: IAppState) {
  return selectState(state).data.coatColors;
}

export function selectCoatColorDetailed(state: IAppState) {
  return selectState(state).data.coatColorsDetailed;
}

export function selectHealthVariants(state: IAppState) {
  return selectState(state).data.healthVariants;
}

export function selectHealthVariantDetailed(state: IAppState) {
  return selectState(state).data.healthVariantsDetailed;
}

export function selectAbilities(state: IAppState) {
  return selectState(state).data.abilities;
}

export function selectAbilityDetailed(state: IAppState) {
  return selectState(state).data.abilityDetailed;
}

export function selectGeneticVariants(state: IAppState) {
  return selectState(state).data.geneticVariants;
}

export function selectHorseBreedPanels(state: IAppState) {
  return selectState(state).data.horseBreedPanels;
}
