import {IDictionary} from 'DictionaryFactory/types';
import {IAppState} from 'Common/store/IAppState';
import {ICommunication} from 'Common/store/utils/communication';

export interface IPhenotypesFormSelectors<T> {
  item: T | null;
  itemLoading: ICommunication;
  itemCreating: ICommunication;
  itemUpdating: ICommunication;
}

export function makePhenotypeFormSelectors<T>(
  state: IAppState,
  dictionary: IDictionary<T, unknown>
): IPhenotypesFormSelectors<T> {
  const {selectors} = dictionary;
  return {
    item: selectors.selectItemDetails(state),
    itemLoading: selectors.selectCommunication(state, 'itemLoading'),
    itemCreating: selectors.selectCommunication(state, 'itemCreating'),
    itemUpdating: selectors.selectCommunication(state, 'itemUpdating'),
  };
}
