import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IUserHorse} from 'Horse/models/IUserHorse';
import {IServerUserHorse} from './types/horse';
import {convertServerUserHorse} from './converters/horse';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IUser} from 'UserProfile/models/IUser';
import {AxiosPromise} from 'axios';
import {getApiByVisitorType} from 'Common/helpers/getApiByVisitorType';
import {VisitorType} from 'Common/constants/VisitorType';

const getUser = (
  id: number,
  isGeneralUser: boolean,
  isAssociationOwner?: boolean
): AxiosPromise<IObjectResponse<IUser>> => {
  const api = isGeneralUser
    ? isAssociationOwner
      ? '/AssociationOwnerProfile'
      : '/UserProfileAssociation'
    : '/AssociationOwnerProfile';
  return axiosWrapper.get<IObjectResponse<IUser>>(`${api}/${id}`);
};

const getHorses = async (
  params: IRequestParams,
  id: number,
  isGeneralUser: boolean
): Promise<IListResponse<IUserHorse>> => {
  const api = isGeneralUser ? 'UserProfileHorsesAssociation' : '/AssociationOwnerProfileHorses';
  const idParam = isGeneralUser ? {UserId: id} : {ownerId: id};

  const response = await axiosWrapper.get<IListResponse<IServerUserHorse>>(api, {
    params: {...params, ...idParam},
  });
  return {...response.data, data: response.data.data.map(convertServerUserHorse)};
};

const getAssociationUser = (id: number): AxiosPromise<IObjectResponse<IUser>> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: '/AssociationPublicProfileUser',
    [VisitorType.AssociationEmployee]: '/AssociationPublicProfileAssociation',
  });

  return axiosWrapper.get<IObjectResponse<IUser>>(`${api}/${id}`);
};

const getAssociationHorses = async (
  params: IRequestParams,
  organizationId: number
): Promise<IListResponse<IUserHorse>> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: '/AssociationPublicProfileHorsesUser',
    [VisitorType.AssociationEmployee]: '/AssociationPublicProfileHorsesAssociation',
  });

  const response = await axiosWrapper.get<IListResponse<IServerUserHorse>>(api, {
    params: {...params, organizationId},
  });
  return {...response.data, data: response.data.data.map(convertServerUserHorse)};
};

export default {getUser, getHorses, getAssociationUser, getAssociationHorses};
