import React, {memo} from 'react';

import {InputField} from 'Common/components/FormFields/index';
import withField from 'Common/components/FormFields/withField';
import {InputFieldProps} from 'Common/components/FormFields/InputField';

interface IProps {
  isShowEye?: boolean;
}

type AllProps = IProps & Omit<InputFieldProps, 'type'>;

const PasswordField = (props: AllProps) => {
  const {isShowEye = true, ...rest} = props;

  return (
    <div className="position-relative">
      <InputField {...rest} type="password" isShowEye={isShowEye} />
    </div>
  );
};

export default memo(withField(PasswordField));
