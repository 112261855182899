import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {
  createHorseTransferActions,
  createHorseTransferActionTypes,
  getHorseTransferDetailsActions,
  getHorseTransferDetailsActionTypes,
} from './types';
import HorseTransferDataService from 'Horse/services/HorseTransferDataService';
import {IHorseTransferCreate} from 'Horse/models/IHorseTransfer';

export const getHorseTransferDetails =
  (id: number): ActionResult<IAppState, void, getHorseTransferDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseTransferDetailsActionTypes.REQUEST));
      const res = await HorseTransferDataService.getHorseTransferDetails(id);
      dispatch(action(getHorseTransferDetailsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHorseTransferDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createHorseTransfer =
  (params: IHorseTransferCreate): ActionResult<IAppState, void, createHorseTransferActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createHorseTransferActionTypes.REQUEST));
      await HorseTransferDataService.createHorseTransfer(params);
      dispatch(action(createHorseTransferActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createHorseTransferActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
