import React, {memo, useCallback} from 'react';

import {IFieldProps} from 'Common/models/IFieldProps';
import withField from 'Common/components/FormFields/withField';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';
import SelectedValue from './SelectedValue';

interface IOwnProps {
  options: IDictionaryOptionType[];
  withDots?: boolean;
}

type Props = IOwnProps & IFieldProps<Array<string | number>>;

function SelectedValues(props: Props) {
  const {
    field: {name, value: fieldValues},
    form: {setFieldValue},
    options,
    withDots
  } = props;

  const removeFromValues = useCallback(
    (valueToRemove: number | string) => {
      setFieldValue(
        name,
        fieldValues?.filter(value => value !== valueToRemove)
      );
    },
    [setFieldValue, fieldValues, name]
  );

  return (
    <div>
      {fieldValues?.map((value, i) => (
        <SelectedValue
          key={i}
          label={options.find(option => option.value === value)?.label || ''}
          value={value}
          description={options.find(option => option.value === value)?.description}
          onRemove={removeFromValues}
          withDot={withDots}
        />
      ))}
    </div>
  );
}

export default memo(withField(SelectedValues));
