import {combineReducers, Reducer} from 'redux';

import {communicationHtmlTemplatesReducer} from './communications';
import {dataHtmlTemplatesReducer} from './data';
import {IAdminHtmlTemplateState} from '../types';
import {uiHtmlTemplatesReducer} from './ui';

export const htmlTemplatesReducer: Reducer<IAdminHtmlTemplateState> = combineReducers<IAdminHtmlTemplateState>({
  communications: communicationHtmlTemplatesReducer,
  data: dataHtmlTemplatesReducer,
  ui: uiHtmlTemplatesReducer
});
