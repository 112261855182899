import React, {memo, useEffect} from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';

import DefaultHeaderCell, {IHeaderCellProps} from './view/HeaderCell';
import Row, {IRenderRowArgs, ExpandedRowStyles} from './view/Row';
import {CellAlign} from './constants/CellAlign';
import {SortOrder} from './constants/SortOrder';
import {useForceUpdate} from 'Common/helpers/hooks/useForceUpdate';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import RowMobile from './view/RowMobile';

const EXPAND_CELL_WIDTH = 90;

const TableRoot = styled.table<{expandable?: boolean}>`
  width: 100%;
  color: ${ColorPalette.black1};
  font-size: ${Typography.size.size18};
  font-family: ${Typography.family.openSans};

  ${({expandable}) =>
    expandable
      ? `
  & td:first-child {
    padding-left: 25px;
  }
  & td:last-child {
    padding-right: 25px;
  }
  `
      : ''}
`;

const Td = styled.td<{clickable?: boolean; width?: number | string; cellAlign?: CellAlign}>`
  cursor: ${({clickable}) => (clickable ? 'pointer' : 'unset')};
  width: ${({width}) => (!isNaN(Number(width)) ? `${width}px` : width || 'unset')};
  text-align: ${({cellAlign}) => cellAlign};
  word-break: break-all;
  &:not(:last-child) {
    padding-right: 5px;
  }
`;

const ExpandIconWrapper = styled.div<{expanded?: boolean}>`
  width: 34px;
  height: 34px;
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'unset')};
  border-radius: 50%;
  background-size: 37%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${Theme.color.white};
`;

const ExpandIcon = styled(ColoredIcon)`
  width: 12px;
  height: 12px;
`;

export interface ICellProps<T> {
  header?: string;
  dataKey?: keyof T;
  align?: CellAlign;
  sortable?: boolean;
  expandOnClick?: boolean;
  width?: number | string;
  memoized?: boolean;
  render(row: T): React.ReactNode;
}

export interface ITableSorting<T> {
  sortBy: keyof T | null;
  isAscending: boolean;
}

type RowKey = number | string;

export interface ITableProps<T> {
  data: T[];
  rowKey?: keyof T;
  disableSorting?: boolean;
  sorting?: ITableSorting<T>;
  expandable?: boolean;
  isHideHeader?: boolean;
  expandedRowStyles?: ExpandedRowStyles;
  tableStyles?: React.CSSProperties;
  multipleExpand?: boolean;
  defaultExpandedRow?: RowKey;
  customHeaderCell?: React.FunctionComponent<IHeaderCellProps<T>>;
  isMobile?: boolean;
  renderExpandContent?(row: T): React.ReactNode;
  onChangeSorting?(sorting: ITableSorting<T>): void;
  disableRow?(row: T): boolean;
  onExpand?(rowKey: RowKey): void;
}

function isPropsShallowEqual<T>(prevProps: ICellProps<T>[], nextProps: ICellProps<T>[]): boolean {
  return prevProps.every((props, i) => R.equals(props, nextProps[i]));
}

function useMemoCellProps<T>(cellsProps: ICellProps<T>[]): ICellProps<T>[] {
  const ref = React.useRef<ICellProps<T>[]>();

  if (!ref.current) {
    ref.current = cellsProps;
    return cellsProps;
  }

  const prevCellsProps = ref.current;
  const prevUnMemoizedCells = prevCellsProps.filter((p) => !p.memoized);
  const nextUnMemoizedCells = cellsProps.filter((p) => !p.memoized);

  if (isPropsShallowEqual(prevUnMemoizedCells, nextUnMemoizedCells)) {
    return prevCellsProps;
  }

  ref.current = cellsProps;
  return cellsProps;
}

function isRowExpanded(
  expandedRow: RowKey | null,
  expandedRows: Array<RowKey>,
  key: RowKey,
  isMultiple: boolean
): boolean {
  if (!isMultiple) {
    return expandedRow === key;
  }

  return expandedRows.includes(key);
}

function Table<T>(props: React.PropsWithChildren<ITableProps<T>>) {
  const {
    data,
    rowKey,
    renderExpandContent,
    sorting,
    children,
    onChangeSorting,
    disableRow,
    disableSorting,
    isHideHeader,
    expandable: isRowExpandable,
    onExpand: propsOnExpand,
    expandedRowStyles,
    tableStyles,
    multipleExpand = false,
    defaultExpandedRow,
    customHeaderCell,
    isMobile,
  } = props;

  const cellsProps = (React.Children.toArray(children) as Array<React.ReactElement<ICellProps<T>>>).map(
    ({props: childrenProps}) => childrenProps
  );
  const cells = useMemoCellProps<T>(cellsProps);
  const forceUpdate = useForceUpdate();

  const [expandedRow, setExpandedRow] = React.useState<RowKey | null>(null);
  const expandedRows = React.useRef<Array<RowKey>>([]);

  const expandRow = React.useCallback(
    (key: RowKey, isExpanded: boolean) => {
      const handleExpand = () => propsOnExpand && isExpanded && propsOnExpand(key);

      if (!multipleExpand) {
        setExpandedRow(isExpanded ? key : null);
        handleExpand();
        return;
      }

      if (isExpanded) {
        expandedRows.current = expandedRows.current.concat([key]);
      } else {
        expandedRows.current = expandedRows.current.filter((k) => k !== key);
      }

      forceUpdate();
      handleExpand();
    },
    [multipleExpand, propsOnExpand, forceUpdate]
  );

  useEffect(() => {
    defaultExpandedRow && expandRow(defaultExpandedRow, true);
  }, [defaultExpandedRow, expandRow]);

  const changeSort = React.useCallback(
    (dataKey: keyof T) => {
      if (!dataKey || !onChangeSorting || !sorting) {
        return;
      }

      setExpandedRow(null);
      expandedRows.current = [];
      forceUpdate();

      onChangeSorting({sortBy: dataKey, isAscending: dataKey !== sorting.sortBy ? true : !sorting.isAscending});
    },
    [sorting, onChangeSorting, forceUpdate]
  );

  const getSortOrder = React.useCallback(
    (dataKey: keyof T) => {
      if (!sorting || sorting.sortBy !== dataKey) {
        return null;
      }
      return sorting.isAscending ? SortOrder.Asc : SortOrder.Desc;
    },
    [sorting]
  );

  const renderRow = React.useCallback(
    ({row, disabled, expandable, expanded, order}: IRenderRowArgs<T>) => {
      const onExpand = () => !disabled && expandRow(rowKey ? String(row[rowKey]) : order, !expanded);
      return (
        <>
          {expandable && (
            <Td onClick={onExpand} clickable={!disabled}>
              <ExpandIconWrapper
                expanded={expanded}
                style={expandedRowStyles?.toggle}
                className="d-flex align-items-center justify-content-center"
              >
                <ExpandIcon
                  name={IconName.ArrowDown}
                  fill={true}
                  stroke={false}
                  color={expandedRowStyles?.toggleIcon?.color || Theme.color.primary}
                  style={expandedRowStyles?.toggleIcon}
                />
              </ExpandIconWrapper>
            </Td>
          )}
          {cells.map(({align, render, expandOnClick, width}, i) => (
            <Td
              key={i}
              cellAlign={align || CellAlign.Left}
              onClick={expandable && expandOnClick ? onExpand : undefined}
              clickable={expandable && expandOnClick && !disabled}
              width={width}
            >
              {render(row)}
            </Td>
          ))}
        </>
      );
    },
    [rowKey, cells, expandedRowStyles, expandRow]
  );

  return (
    <>
      {isMobile && data.map((row, i) => <RowMobile<T> key={i} cells={cells} row={row} />)}

      {!isMobile && (
        <TableRoot expandable={isRowExpandable} style={tableStyles}>
          {!isHideHeader && (
            <thead>
              <tr>
                {isRowExpandable && <Td width={EXPAND_CELL_WIDTH} />}
                {cells.map(({header, align, dataKey, sortable = true}, i) => {
                  const HeaderCell = customHeaderCell || DefaultHeaderCell;

                  return (
                    <HeaderCell<T>
                      header={header || ''}
                      key={header || i}
                      dataKey={dataKey}
                      align={align}
                      onClick={sortable && !disableSorting ? changeSort : undefined}
                      sortable={sortable && !disableSorting && !!dataKey}
                      sortOrder={dataKey && getSortOrder(dataKey)}
                    />
                  );
                })}
              </tr>
            </thead>
          )}
          <tbody>
            {data.map((row, i) => {
              const key = rowKey ? String(row[rowKey]) : i;
              const expanded = isRowExpanded(expandedRow, expandedRows.current, key, multipleExpand);
              const disabled = disableRow && disableRow(row);

              return (
                <Row<T>
                  key={key}
                  order={i}
                  row={row}
                  renderRow={renderRow}
                  expanded={expanded}
                  expandable={!!isRowExpandable}
                  renderExpandContent={renderExpandContent}
                  disabled={disabled}
                  styles={expandedRowStyles}
                />
              );
            })}
          </tbody>
        </TableRoot>
      )}
    </>
  );
}

function Cell<T>(_: React.PropsWithChildren<ICellProps<T>>) {
  return null;
}

(Cell as React.FC<ICellProps<any>>).defaultProps = {
  memoized: true,
};

const memoizedTable = memo(Table) as <T>(props: React.PropsWithChildren<ITableProps<T>>) => JSX.Element;

export {memoizedTable as Table, memoizedTable, Cell};
