import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {IAvailabilityCriteria} from 'Common/models/IAvailabilityCriteria';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import Theme from 'Common/constants/Theme';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import BahAccessCriteria from 'Common/components/BahAccessCriteria/BahAccessCriteria';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Theme.color.white};
  font-family: ${Theme.font.primary};
`;

const Title = styled.div`
  margin-left: 12px;
`;

const ShowClarifications = styled.div`
  margin-left: 8px;
  text-decoration: underline;
  cursor: pointer;
  outline: none;
`;

interface IProps {
  criteria: IAvailabilityCriteria;
}

function UnavailableForBah(props: IProps) {
  const {criteria} = props;
  return (
    <Root>
      <ColoredIcon size={32} color={ColorPalette.black1} name={IconName.HorseNotGranted} fill={false} stroke={false} />
      <Title>Bah access not granted.</Title>
      <Tooltip content={<BahAccessCriteria criteria={criteria} />}>
        <ShowClarifications>Why?</ShowClarifications>
      </Tooltip>
    </Root>
  );
}

export default memo(UnavailableForBah);
