import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {IAdminActionsProps} from 'Admin/AdminDashboard/helpers/hooks/common';
import {useHorseModal} from 'Admin/AdminDashboard/helpers/hooks/useHorseActions/parts/useHorseModal';
import {useChangeOwnerModal} from 'Admin/AdminDashboard/helpers/hooks/useHorseActions/parts/useChangeOwnerModal';
import {IArchiveHorseParams} from 'Admin/AdminDashboard/store/adminHorses/types';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {useChangeSampleIdModal} from './parts/useChangeSampleIdModal';
import {useGeneticDataVisibilityModal} from './parts/useGeneticDataVisibilityModal';

export interface IArchiveHorseActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(params: IArchiveHorseParams): void;
}

export interface IUpdateDiagnosticReportProps extends Omit<IAdminActionsProps, 'action'> {
  action(horseId: number): void;
}

interface IHorseActionsPartsProps {
  createHorseAction: Omit<IAdminActionsProps, 'onSuccess'>;
  editHorseAction: Omit<IAdminActionsProps, 'onSuccess'>;
  archiveHorseAction: Omit<IArchiveHorseActionProps, 'onSuccess'>;
}

interface IProps {
  data: IHorseActionsPartsProps;
  onSuccess(): void;
}

export function useHorseActions(props: IProps) {
  const {
    onSuccess,
    data: {archiveHorseAction, createHorseAction, editHorseAction},
  } = props;

  const {updateHorseModal, createHorseModal, openUpdateHorseModal, openCreateHorseModal} = useHorseModal({onSuccess});
  const {changeOwnerModal, openChangeOwnerModal} = useChangeOwnerModal({onSuccess});
  const {changeSampleIdModal, openChangeSampleIdModal} = useChangeSampleIdModal({onSuccess});
  const {geneticDataVisibilityModal, openGeneticDataVisibilityModal} = useGeneticDataVisibilityModal({onSuccess});
  const archiveHorse = useCallback(
    (horseId: number, isArchived: boolean) => {
      archiveHorseAction.action({horseId, isArchived});
    },
    [archiveHorseAction]
  );

  useCommunicationToToast(archiveHorseAction.communication, 'Horse status successfully changed', undefined, 1);
  useCommunicationToToast(createHorseAction.communication, 'Horse successfully created', undefined, 3);
  useCommunicationToToast(editHorseAction.communication, 'Horse profile successfully changed', undefined, 4);

  const horseActionsModal = (
    <>
      {createHorseModal}
      {updateHorseModal}
      {changeOwnerModal}
      {changeSampleIdModal}
    </>
  );

  const history = useHistory();

  const openLiveReport = useCallback(
    (horseId: number) => {
      history.push(`/admin/online-report/${horseId}/0`);
    },
    [history]
  );

  return {
    openCreateHorseModal,
    openUpdateHorseModal,
    openChangeOwnerModal,
    createHorseModal,
    updateHorseModal,
    changeOwnerModal,
    archiveHorse,
    horseActionsModal,
    openLiveReport,
    openChangeSampleIdModal,
    geneticDataVisibilityModal,
    openGeneticDataVisibilityModal,
  };
}
