import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {ICreateHorseOwnerBP, IOwnerBP, IUpdateHorseOwnerBP} from 'BusinessPortal/models/owner/IOwnerBP';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IOwnerDetailsBP} from 'BusinessPortal/models/owner/IOwnerDetailsBP';
import {IFoundUser} from 'Admin/AdminDashboard/models/User/IFoundUser';
import {withAvatar} from 'Common/helpers/withAvatar';
import {convertOwnerDetails} from './converters/owner';
import {IServerOwnerBP, IServerOwnerDetailsBP} from './types/owner';
import {withUserName} from 'Common/helpers/withUserName';

const getOwners = async (params?: IRequestParams, isAdmin?: boolean): Promise<IListResponse<IOwnerBP>> => {
  const response = await axiosWrapper.get<IListResponse<IServerOwnerBP>>(
    isAdmin ? '/AssociationHorseOwnerAdmin' : '/AssociationHorseOwner',
    {params}
  );
  return {...response.data, data: response.data.data.map(withAvatar).map(withUserName)};
};

const getOwnerDetails = async (ownerId: number, isAdmin?: boolean): Promise<IOwnerDetailsBP> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerOwnerDetailsBP>>(
    (isAdmin ? `/AssociationHorseOwnerDetailsAdmin` : `/AssociationHorseOwnerDetails`) + `/${ownerId}`
  );
  return convertOwnerDetails(response.data.data);
};

const createOwner = async (data: ICreateHorseOwnerBP, isAdmin?: boolean): Promise<number> => {
  const response = await axiosWrapper.post<IObjectResponse<number>>(
    isAdmin ? '/AssociationHorseOwnerCreateAdmin' : '/AssociationHorseOwnerCreate',
    data
  );
  return response.data.data;
};

const updateOwner = async (data: IUpdateHorseOwnerBP, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(
    isAdmin ? '/AssociationHorseOwnerUpdateAdmin' : `/AssociationHorseOwnerUpdate`,
    data
  );
};

const updateOwnerAvatar = async (ownerId: number, uploadedFile: File, isAdmin?: boolean): Promise<void> => {
  const formData = new FormData();
  formData.append('file', uploadedFile);
  formData.append('horseOwnerId', `${ownerId}`);

  await axiosWrapper.post<IObjectResponse<null>>(
    isAdmin ? '/AssociationHorseOwnerAvatarUpdateAdmin' : `/AssociationHorseOwnerAvatarUpdate`,
    formData
  );
};

const deleteOwnerAvatar = async (ownerId: number, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(
    (isAdmin ? '/AssociationHorseOwnerAvatarDeleteAdmin' : `/AssociationHorseOwnerAvatarDelete`) + `/${ownerId}`
  );
};

const deleteOwner = async (ownerId: number, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(
    (isAdmin ? '/AssociationHorseOwnerAdminDelete' : `/AssociationHorseOwnerDelete`) + `/${ownerId}`
  );
};

const findOwner = async (filter: string, organizationId?: number): Promise<IFoundUser[]> => {
  const response = await axiosWrapper.get<IObjectResponse<IFoundUser[]>>(
    organizationId ? `/AssociationHorseOwnerByNameAndOrganizationAdmin` : `/AssociationHorseOwnerByName`,
    {params: {filter, organizationId}}
  );
  return response.data.data.map(withAvatar);
};

export default {
  getOwners,
  getOwnerDetails,
  createOwner,
  updateOwner,
  updateOwnerAvatar,
  deleteOwner,
  deleteOwnerAvatar,
  findOwner,
};
