import React from 'react';

interface IUpdateOrderContext {
  onUpdateOrder(): void;
}

export const UpdateOrderContext = React.createContext<IUpdateOrderContext>({onUpdateOrder: console.log});

export default function useOnUpdateOrder() {
  return React.useContext(UpdateOrderContext);
}
