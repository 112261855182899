import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {updatePasswordActions, updatePasswordActionTypes, IChangePasswordState} from './types';
import UserService from 'UserProfile/services/UserService';
import {IChangePassword} from 'UserProfile/models/IChangePassword';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const changePassword =
  (data: IChangePassword): ActionResult<IChangePasswordState, void, updatePasswordActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updatePasswordActionTypes.REQUEST));
      const res = await UserService.changePassword(data);
      dispatch(action(updatePasswordActionTypes.SUCCESS, res.data.data));
    } catch (error) {
      dispatch(action(updatePasswordActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
