import {memo, useCallback, useEffect, useState} from 'react';

import {Title} from '../parts/styled';
import {IFacetProps} from '../Facet';
import {Checkbox} from 'Common/components/Controls';
import {IFilterRequest} from 'FacetFilter/models/IFilterRequest';

interface IProps extends IFacetProps {}

function BooleanFacet(props: IProps) {
  const {
    filterProperty,
    filterRequest = {
      property: filterProperty.property,
      operation: 'Equal', // NOTE: Operation is 'Equal' by default for boolean filter.
      value: `true`,
    },
    onSuccess,
  } = props;

  const [isChecked, setIsChecked] = useState(true);
  const [request, setRequest] = useState<IFilterRequest>(filterRequest);

  useEffect(() => {
    setRequest(filterRequest);
    setIsChecked(filterRequest.value ? filterRequest.value === 'true' : isChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onSuccess(request);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const handleCheckboxClick = useCallback(() => {
    setIsChecked(!isChecked);
    setRequest({...request, value: `${!isChecked}`});
  }, [isChecked, request]);

  return (
    <>
      <Title>{filterProperty.title}</Title>
      <Checkbox checked={isChecked} onChange={handleCheckboxClick} />
    </>
  );
}

export default memo(BooleanFacet);
