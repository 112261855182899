import React, {memo, useCallback, useState, useMemo} from 'react';
import styled from 'styled-components';

import {INamedEntity} from 'Common/models/INamedEntity';
// import TestsSelection, {TestSelectionProps} from 'Order/components/CreateOrder/parts/TestsSelection';
import ContactInformation, {ContactInformationProps} from 'Order/components/CreateOrder/parts/ContactInformation';
import Payment, {PaymentProps} from 'Order/components/CreateOrder/parts/Payment';
import BaseStepLayout from 'Order/components/CreateOrder/parts/BaseStepLayout';
import {Discount} from 'Order/components/CreateOrder/parts/index';
import {breakpoints} from 'Common/constants/Breakpoints';
import {DiscountProps} from 'Order/components/CreateOrder/parts/Discount';
import TestSelection, {TestSelectionProps} from './parts/TestsSelection';

interface IStepContent extends INamedEntity {
  isEdit: boolean;
  isOpen: boolean;
  isDisabledEditButton?: boolean;
}

const defaultStepContent: IStepContent[] = [
  {id: 1, name: 'Tests selection', isEdit: true, isOpen: true},
  {id: 2, name: 'Contact information', isEdit: false, isOpen: false},
  {id: 3, name: 'Payment', isEdit: false, isOpen: false},
];

const Section = styled.div<{isTight?: boolean}>`
  max-width: ${({isTight}) => (isTight ? '360px' : 'unset')};
  margin-top: 8px;

  &:last-child {
    margin-bottom: 78px;
  }

  @media ${breakpoints.sm} {
    margin-top: 19px;
  }
`;

interface IProps {
  testSelectionProps: TestSelectionProps;
  discountProps: DiscountProps;
  contactInformationProps: ContactInformationProps;
  paymentProps: PaymentProps;
}

const CreateOrderMainContent = (props: IProps) => {
  const {discountProps, testSelectionProps, paymentProps, contactInformationProps} = props;

  const defaultStepComponent = useMemo(
    () => [
      {id: 1, Component: <TestSelection {...testSelectionProps} />, ...testSelectionProps},
      {
        id: 2,
        Component: <ContactInformation {...contactInformationProps} />,
        ...contactInformationProps,
      },
      {id: 3, Component: <Payment {...paymentProps} />, ...paymentProps},
    ],
    [contactInformationProps, paymentProps, testSelectionProps]
  );

  const [stepContent, setStepContent] = useState<IStepContent[]>(defaultStepContent);

  const onContinueClick = useCallback(
    (id) => {
      const newContent = stepContent.map((i) => {
        if (i.id === id) {
          return {...i, isEdit: false, isOpen: true};
        }

        if (i.id === id + 1) {
          return {...i, isEdit: true, isOpen: true, isDisabledEditButton: false};
        }

        return {...i, isDisabledEditButton: false};
      });

      const stepComp = defaultStepComponent.filter((j) => j.id === id)?.[0];
      stepComp?.onContinueClick && stepComp.onContinueClick();

      setStepContent(newContent);
    },
    [defaultStepComponent, stepContent]
  );

  const onEditClick = useCallback(
    (id) => {
      const newContent = stepContent.map((i) => {
        if (i.id === id) {
          return {...i, isEdit: true, isOpen: true};
        }

        const stepComp = defaultStepComponent.filter((j) => j.id === i.id)?.[0];
        i.isOpen && stepComp?.onClose && stepComp.onClose();
        return {...i, isEdit: false, isOpen: !stepComp?.isValid ? false : i.isOpen, isDisabledEditButton: true};
      });

      const stepComp = defaultStepComponent.filter((j) => j.id === id)?.[0];
      stepComp?.onEditClick && stepComp.onEditClick();

      setStepContent(newContent);
    },
    [defaultStepComponent, stepContent]
  );

  return (
    <>
      {stepContent.map((element) => {
        const {name, id, ...rest} = element;

        const currentStep = defaultStepComponent.filter((i) => i.id === id)?.[0];

        return (
          <Section key={id}>
            <BaseStepLayout
              label={element.name}
              stepNumber={id}
              isLast={id === stepContent.length}
              onEditClick={() => onEditClick(id)}
              onContinueClick={() => onContinueClick(id)}
              onClose={currentStep?.onClose}
              isValid={currentStep.isValid}
              {...rest}
            >
              {currentStep.Component}
            </BaseStepLayout>
          </Section>
        );
      })}

      {(stepContent?.slice(-1)[0].isEdit || stepContent?.slice(-1)[0].isOpen) && (
        <Section>
          <BaseStepLayout label="Have a coupon or gift card?" isOpen={true} isEdit={true}>
            <Discount {...discountProps} />
          </BaseStepLayout>
        </Section>
      )}
    </>
  );
};

export default memo(CreateOrderMainContent);
export type CreateOrderMainContentProps = IProps;
