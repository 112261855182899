import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {IMailConfirmationState, mailConfirmationActions, MailConfirmationActionTypes} from '../types';

export const communicationMailConfirmationReducer = combineReducers<
  IMailConfirmationState['communications'],
  mailConfirmationActions
>({
  messageLoading: makeCommunicationReducerFromEnum(MailConfirmationActionTypes)
});
