import {IFormValues} from './validation';
import {
  ICreateAdminOrganization,
  IUpdateAdminOrganization,
  OrganizationStatus,
} from 'Admin/AdminAssociations/services/types/organizations';

export const convertToServerCreating = (values: IFormValues): ICreateAdminOrganization => {
  const dataToSave: ICreateAdminOrganization = {
    ...values,
    status: values.isActive ? OrganizationStatus.Activated : OrganizationStatus.Disabled,
  };
  delete dataToSave['isActive'];

  return dataToSave;
};

export const convertToServerUpdating = (values: IFormValues): IUpdateAdminOrganization => {
  const dataToSave: IUpdateAdminOrganization = {
    ...values,
    id: values.id!,
  };

  delete dataToSave['isActive'];
  delete dataToSave['status'];

  return dataToSave;
};
