import {combineReducers, Reducer} from 'redux';

import {IUserOrdersState} from '../types';
import {communicationReducer} from './communications';
import {dataReducer} from './data';
import {uiReducer} from './ui';

export const userOrdersReducer: Reducer<IUserOrdersState> = combineReducers<IUserOrdersState>({
  communications: communicationReducer,
  data: dataReducer,
  ui: uiReducer
});
