import React, {useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Form, FormikProps, withFormik} from 'formik';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {IAppState} from 'Common/store/IAppState';
import {IAdminPaymentDetailsHorse, PaymentStatus} from 'Admin/AdminAssociations/models/IAdminPaymentDetails';
import {initialValue, IPaymentDetailsForm, validationSchema} from './validation';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminPaymentUpdate';
import {AdminAssociationPaymentUpdateModule} from 'Admin/AdminAssociations/store/adminPaymentUpdate/adminAssociationPaymentUpdateModule';
import {
  CheckboxField,
  CheckboxGroupField,
  DateTimeField,
  InputField,
  SelectField,
} from 'Common/components/FormFields/index';
import {getStringKeysOption} from 'Common/helpers/OptionHelper';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {ErrorMessage, FieldHint} from 'Common/components/StyledComponents/StyledComponents';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {paymentOptions} from 'Order/constants/PaymentOptions';
import {convertPaymentDetailsFormToPaymentDetails, convertPaymentDetailsToPaymentDetailsForm} from './converters';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ConnectedHorseCheckbox from './parts/ConnectedHorseCheckbox';
import {Checkbox} from 'Common/components/Controls';

const cheboxesStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '33% 33% 33%',
  marginLeft: 20,
  marginTop: 8,
};

const SendEmailField = styled.div`
  margin: 12px 0 32px;
`;

interface IExternalProps {
  orderId: number;
  onSuccess(): void;
}

const CommonSection = styled.div`
  margin-bottom: 24px;
`;

const CommonInfoWrapper = styled.div`
  margin-right: 80px;
`;

const Label = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  margin-bottom: 8px;
  color: ${Theme.color.gray};
`;

const Value = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IPaymentDetailsForm> & OuterProps;

const UpdatePaymentForm = (props: AllProps) => {
  const {setStatus, setErrors, status, isSubmitting, values} = props;
  const {
    orderId,
    getPaymentDetails,
    getPaymentDetailsHorses,
    resetPaymentDetails,
    resetPaymentDetailsHorsesResults,
    paymentDetailsHorses,
    paymentDetailsLoading,
    paymentDetailsHorsesLoading,
    paymentDetailsUpdating,
    onSuccess,
  } = props;

  const errorInfo = paymentDetailsLoading.error || paymentDetailsUpdating.error;
  const isRequesting = paymentDetailsLoading.isRequesting || paymentDetailsHorsesLoading.isRequesting;

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(paymentDetailsUpdating, onSuccess);
  useOnErrorCommunication(paymentDetailsUpdating, onError);
  useCommunicationToToast(paymentDetailsUpdating, 'Payment details has been changed');

  useEffect(() => {
    const fetchData = async () => {
      if (orderId) {
        await getPaymentDetails(orderId);
        await getPaymentDetailsHorses(orderId);
      }
    };

    fetchData();

    return () => {
      resetPaymentDetails();
      resetPaymentDetailsHorsesResults();
    };
  }, [orderId, resetPaymentDetails, getPaymentDetails, getPaymentDetailsHorses, resetPaymentDetailsHorsesResults]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    const fieldErrors = getFieldErrors(errorInfo);

    if (commonErrors) {
      setStatus(commonErrors);
    }

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  const horseOrder = paymentDetailsHorses.find((x) => x.id === orderId);
  const connectedHorses: IAdminPaymentDetailsHorse[] = paymentDetailsHorses.filter((x) => x.id !== orderId);

  return (
    <>
      <ModalWindowHeader>Update payment</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {isRequesting && <Loading />}
          <CommonSection className="d-flex flex-row">
            <CommonInfoWrapper>
              <Label>Horse</Label>
              <Value>{horseOrder?.horse.name}</Value>
            </CommonInfoWrapper>
          </CommonSection>

          {connectedHorses.length > 0 && (
            <>
              <Label>Connected horses</Label>
              <CheckboxGroupField
                name="orderIds"
                showSelectAll={true}
                childrenStyle={cheboxesStyle}
                showSelectLabel="All"
              >
                {connectedHorses?.map((horse, i) => {
                  const isChecked = !!values.orderIds?.find((x) => x === horse.id);
                  const labelRender = <ConnectedHorseCheckbox horse={horse} />;

                  return <Checkbox key={i} name={String(horse.id)} checked={isChecked} label={labelRender} />;
                })}
              </CheckboxGroupField>
            </>
          )}

          <DateTimeField className="w-50" name="receivedDate" label="Payment date" isRequired />
          <SelectField
            className="w-50"
            isRequired={true}
            name="status"
            label="Status"
            options={getStringKeysOption(PaymentStatus)}
          />
          <SelectField
            className="w-50"
            isRequired={true}
            name="method"
            label="Payment Method"
            options={paymentOptions}
          />
          <InputField
            isRequired={true}
            name="transactionId"
            type="text"
            label="Transaction ID"
            placeholder="Transaction ID"
          />
          <SendEmailField>
            <CheckboxField name="sendEmail" label="Send email" style={{marginBottom: 4}} />
            <FieldHint>
              Enabling this option will allow you to notify the customer about changing payment status via email.
            </FieldHint>
          </SendEmailField>
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" isLoading={isSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const UpdatePaymentFormFormik = withFormik<OuterProps, IPaymentDetailsForm>({
  mapPropsToValues: ({orderId, paymentDetails, paymentDetailsHorses}) =>
    paymentDetails
      ? convertPaymentDetailsToPaymentDetailsForm(orderId, paymentDetails, paymentDetailsHorses)
      : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {props} = formikBag;
    formikBag.setSubmitting(true);
    await formikBag.props.updatePaymentDetails(convertPaymentDetailsFormToPaymentDetails(props.orderId, values));
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(UpdatePaymentForm);

const mapStateToProps = (state: IAppState) => ({
  paymentDetails: selectors.selectPaymentDetails(state),
  paymentDetailsHorses: selectors.selectPaymentHorses(state),
  paymentDetailsLoading: selectors.selectCommunication(state, 'paymentDetailsLoading'),
  paymentDetailsHorsesLoading: selectors.selectCommunication(state, 'paymentDetailsHorsesLoading'),
  paymentDetailsUpdating: selectors.selectCommunication(state, 'paymentDetailsUpdating'),
});

const mapDispatchToProps = {
  getPaymentDetails: actions.getPaymentDetails,
  getPaymentDetailsHorses: actions.getPaymentDetailsHorses,
  updatePaymentDetails: actions.updatePaymentDetails,
  resetPaymentDetails: actions.resetPaymentDetailsResults,
  resetPaymentDetailsHorsesResults: actions.resetPaymentDetailsHorsesResults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(React.memo(UpdatePaymentFormFormik));
export default withDynamicModules(Connected, AdminAssociationPaymentUpdateModule);
