import * as Yup from 'yup';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IUpdateHorseOwnerBP} from 'BusinessPortal/models/owner/IOwnerBP';
import {IAddressWithNullableField} from 'Common/models/IAddress';
import {Nullable} from 'Common/types';
import {IImage} from 'Image/models/IImage';

export interface IFormValues extends Omit<IUpdateHorseOwnerBP, 'id'> {
  id?: number;
  avatar?: Nullable<IImage>;
}

export const initialValue: IFormValues = {
  id: 0,
  firstName: '',
  lastName: '',
  address: {
    country: '',
    state: '',
    city: '',
    street: '',
    zip: '',
  },
  phone: '',
  email: '',
  avatar: null,
};

export const validationSchema = Yup.object().shape<IFormValues>({
  id: Yup.number().required(REQUIRED_FIELD),
  firstName: Yup.string().required(REQUIRED_FIELD),
  lastName: Yup.string().required(REQUIRED_FIELD),
  address: Yup.object()
    .shape<IAddressWithNullableField>({
      country: Yup.string(),
      state: Yup.string(),
      city: Yup.string(),
      street: Yup.string(),
      zip: Yup.string(),
    })
    .nullable(),
  phone: Yup.string(),
  email: Yup.string().email('Email not valid').required('Email is required'),
  avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
});
