import * as Yup from 'yup';
import {ILocation} from 'Common/models/ICoordinatedLocation';
import {Nullable} from 'Common/types';

export interface IFormValues {
  location: Nullable<ILocation>;
  address: string;
}

export const initialValue: IFormValues = {
  location: null,
  address: '',
};

export const validationSchema = Yup.object().shape<IFormValues>({
  location: Yup.object()
    .shape<ILocation>({
      latitude: Yup.number(),
      longitude: Yup.number(),
    })
    .nullable(),
  address: Yup.string(),
});
