import {IUserAdmin} from 'Admin/AdminDashboard/models/User/IUserAdmin';

import {IListResponse, IPagination} from 'Common/models/IResponse';
import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication/index';
import {IUserDetails} from 'Admin/AdminDashboard/models/User/IUserDetails';

const STATE_NAME = 'adminUsers';

export interface IAdminUsersState {
  data: {
    users: IUserAdmin[];
    userDetails: IUserDetails | null;
  };
  communications: {
    usersLoading: ICommunication;
    deleteUserLoading: ICommunication;
    userDetailsLoading: ICommunication;
    userCreating: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getUsersActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USERS');

export const getUserByIdActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_BY_ID');
export type getUserByIdActions = MakeCommunicationActions<typeof getUserByIdActionTypes, {success: IUserDetails}>;

export const deleteUserByIdActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_USER_BY_ID');
export type deleteUserByIdActions = MakeCommunicationActions<typeof deleteUserByIdActionTypes, {}>;

export type getUsersActions = MakeCommunicationActions<
  typeof getUsersActionTypes,
  {success: IListResponse<IUserAdmin>}
>;

export const createUserActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_USER');
export type createUserActions = MakeCommunicationActions<typeof createUserActionTypes, {}>;

export const resetUserDetailsActionTypes = makeResetCommunicationActionType(STATE_NAME, 'USER_DETAILS');
export type resetUserDetailsActions = MakeResetCommunicationActions<typeof resetUserDetailsActionTypes>;

export type AdminUsersActions =
  | getUsersActions
  | getUserByIdActions
  | deleteUserByIdActions
  | createUserActions
  | resetUserDetailsActions;
