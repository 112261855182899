import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';

import {
  getPermissionsByUserIdActionTypes,
  IUserPermissionsState,
  updatePermissionsByUserIdActionTypes,
  UserPermissionsActions
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import UserPermissionsService from 'Admin/AdminDashboard/services/UserPermissionsService';
import {IAdminUserPermissions} from 'Admin/AdminDashboard/models/User/IUserPermissions';

export const getUserPermissions = (
  userId: number
): ActionResult<IUserPermissionsState, void, UserPermissionsActions> => async dispatch => {
  dispatch(action(getPermissionsByUserIdActionTypes.REQUEST));
  try {
    const permissions = await UserPermissionsService.getUserPermissions(userId);
    dispatch(action(getPermissionsByUserIdActionTypes.SUCCESS, permissions));
  } catch (error) {
    dispatch(action(getPermissionsByUserIdActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const updateUserPermissions = (
  data: IAdminUserPermissions
): ActionResult<IUserPermissionsState, void, UserPermissionsActions> => async dispatch => {
  dispatch(action(updatePermissionsByUserIdActionTypes.REQUEST));
  try {
    await UserPermissionsService.updateUserPermissions(data);
    dispatch(action(updatePermissionsByUserIdActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(updatePermissionsByUserIdActionTypes.FAILURE, getErrorMessage(error)));
  }
};
