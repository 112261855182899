import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import UploadReportForm from 'Admin/shared/components/UploadReportForm/UploadReportForm';
import useDistributorStrategy from 'Common/helpers/strategy/useDistributorStrategy';

interface IUpdatePaymentModalParams {
  onSuccess?(): void;
  horseId: number;
  orderId: number;
  horseName: string;
  ownerName: string;
}

export function useUploadReportModal(params: IUpdatePaymentModalParams) {
  const {onSuccess, horseId, orderId, horseName, ownerName} = params;

  const {currentStrategy} = useDistributorStrategy();

  const [isOpen, setIsOpen] = useState(false);
  const openUploadReportModal = useCallback(() => setIsOpen(true), []);
  const closeSendReportModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeSendReportModal();
    onSuccess && onSuccess();
  }, [closeSendReportModal, onSuccess]);

  const uploadReportModal = (
    <ModalWindow isOpen={isOpen} onClose={closeSendReportModal}>
      <UploadReportForm
        horseId={horseId}
        orderId={orderId}
        horseName={horseName}
        ownerName={ownerName}
        onSuccess={handleSuccess}
        selectedStrategy={currentStrategy}
      />
    </ModalWindow>
  );

  return {
    uploadReportModal,
    openUploadReportModal,
  };
}
