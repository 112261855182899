import styled from 'styled-components';

import {breakpoints} from 'Common/constants/Breakpoints';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

export const BaseContainer = styled.div`
  padding: 0 16px;
`;

export const TextBase = styled.div<{isMain?: boolean}>`
  font-size: ${({isMain}) => (isMain ? Typography.size.size16 : Typography.size.size12)};
  line-height: 24px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size16};
    line-height: 32px;
  }
`;

export const SectionBase = styled.div`
  width: 100%;

  justify-content: center;
  align-self: start;

  @media ${breakpoints.md} {
    justify-content: space-evenly;
  }

  @media ${breakpoints.lg} {
    max-width: 1100px;
    justify-content: space-between;
  }
`;

export const DarkContainerBase = styled(BaseContainer)`
  background-color: ${Theme.color.black};
  color: ${Theme.color.white};
  padding-top: 32px;

  @media ${breakpoints.sm} {
    height: 400px;
  }

  @media ${breakpoints.md} {
    height: 520px;
  }
`;

export const HeaderLabel = styled(TextBase)<{isMain?: boolean}>`
  font-family: ${Theme.font.secondary};
  font-weight: ${({isMain}) => (isMain ? Typography.weight.bold700 : Typography.weight.semiBold600)};
  font-size: ${({isMain}) => (isMain ? Typography.size.size24 : Typography.size.size13)};
  line-height: ${({isMain}) => (isMain ? Typography.size.size32 : Typography.size.size24)};
  margin-bottom: 16px;
  text-align: left;

  @media ${breakpoints.sm} {
    font-size: ${({isMain}) => (isMain ? Typography.size.size40 : Typography.size.size32)};
    line-height: 56px;
    font-weight: ${({isMain}) => (isMain ? Typography.weight.bold700 : Typography.weight.medium500)};
  }
`;

export const DescriptionLabel = styled(TextBase)<{isMain?: boolean}>`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  margin-bottom: 32px;
`;
