import React, {memo} from 'react';

import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {InputField} from 'Common/components/FormFields/index';

const AccountInfo: React.FC = () => {
  return (
    <>
      <FormControlContainer title="Account">
        <FieldControlContainer className="w-50">
          <InputField type="email" name="email" label="Email address" placeholder="Email" disabled={true} />
        </FieldControlContainer>
      </FormControlContainer>
    </>
  );
};

export default memo(AccountInfo);
