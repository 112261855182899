import * as Yup from 'yup';

import {IUpdateAdminEmployeeEmail} from 'Admin/AdminAssociations/services/types/employee';

export const initialValue: IUpdateAdminEmployeeEmail = {
  id: 0,
  newEmail: '',
};

export const validationSchema = Yup.object().shape<IUpdateAdminEmployeeEmail>({
  id: Yup.number(),
  newEmail: Yup.string().email('New email must be a valid email').required('New email is a required field'),
});
