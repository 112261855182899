import React, {memo, useEffect, useState} from 'react';
import {FieldArray, Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {Combination, CombinationView} from 'Common/constants/Combination';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import Loading from 'Loading/components/Loading';
import {IFormValues, initialValue, validationSchema} from './validation';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {castToOption, getStringKeysOption} from 'Common/helpers/OptionHelper';
import Sequence from './parts/Sequence';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {TextUpper, TypeSelect} from './shared/StyledComponents';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {IAppState} from 'Common/store/IAppState';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {Nullable} from 'Common/types';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {INamedEntity} from 'Common/models/INamedEntity';
import {IFormSequenceItem} from 'SequenceEditor/models/IFormSequenceItem';

const typeOptions = getStringKeysOption(CombinationView, true);

const Block = styled.div`
  margin-bottom: 32px;
`;

const Button = styled(PrimaryButton)`
  margin-bottom: 48px;
`;

interface IOwnProps {
  type?: Combination;
  sequences: IFormSequenceItem[];
  dictionary: ISimpleDictionary<INamedEntity>;
  includeText?: string;
  stateText?: string;
  onSubmit(type: Combination, sequences: IFormSequenceItem[], dictionaryItems: INamedEntity[]): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function SequenceEditor(props: Props) {
  const {dictionary, values, dictionaryItems, dictionaryItemsLoading, includeText, stateText} = props;
  const [error, setError] = useState<Nullable<string>>(null);

  const isPreloading = dictionaryItemsLoading.isRequesting;
  const header = 'Rule';
  const errors = dictionaryItemsLoading.error;
  const dictionaryOptions = castToOption(dictionaryItems).sort((a, b) => sortByStringKey(a, b, 'label'));

  const {
    actions: {getItems},
  } = dictionary;

  useEffect(() => {
    getItems();
  }, [getItems]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errors);
    commonErrors && setError(commonErrors);
  }, [errors]);

  return (
    <>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {isPreloading && <Loading />}

          <Block className="d-flex align-items-center">
            <TextUpper>Must match</TextUpper>
            <TypeSelect name="type" placeholder="Type" options={typeOptions} />
            <TextUpper>of the following</TextUpper>
          </Block>

          <FieldControlContainer>
            <FieldArray
              name="sequences"
              render={(arrayHelper) => {
                return (
                  <>
                    {values.sequences.map((sequence, i) => {
                      const onDeleteSequence = () => arrayHelper.remove(i);
                      return (
                        <Sequence
                          key={i}
                          sequence={sequence}
                          index={i}
                          dictionaryOptions={dictionaryOptions}
                          includeText={includeText}
                          stateText={stateText}
                          onDelete={onDeleteSequence}
                        />
                      );
                    })}
                    <Button type="button" size="small" onClick={() => arrayHelper.push({...initialValue.sequences[0]})}>
                      + Add sequence
                    </Button>
                  </>
                );
              }}
            />
          </FieldControlContainer>
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{error}</ErrorMessage>
          <ModalWindowButton type="submit">Create rule</ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const SequenceEditorFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({type, sequences}) => ({
    type: type ?? initialValue.type,
    sequences: sequences && sequences.length > 0 ? sequences : initialValue.sequences,
  }),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {
      props: {onSubmit, dictionaryItems},
    } = formikBag;

    onSubmit(values.type, values.sequences, dictionaryItems);
  },
  enableReinitialize: true,
})(SequenceEditor);

const mapStateToProps = (state: IAppState, props: IOwnProps) => {
  const {dictionary} = props;

  return {
    dictionaryItems: dictionary.selectors.selectItems(state),
    dictionaryItemsLoading: dictionary.selectors.selectCommunication(state, 'itemsLoading'),
  };
};

const connector = connect(mapStateToProps);
export default connector(memo(SequenceEditorFormik));
