import {IModule} from 'redux-dynamic-modules';

import {adminSubscriptionsReducer} from './reducer';
import {IAdminSubscriptionState} from './types';

export interface IAdminSubscriptionsModuleState {
  adminSubscriptions: IAdminSubscriptionState;
}

export const AdminSubscriptionsModule: IModule<IAdminSubscriptionsModuleState> = {
  id: 'adminSubscriptions',
  reducerMap: {
    adminSubscriptions: adminSubscriptionsReducer
  }
};
