import {combineReducers} from 'redux';

import {
  IAdminAssociationOwnerProfileState,
  OwnerActions,
  getOwnerActionTypes,
  getHorsesByOwnerActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationOwnerProfileReducer = combineReducers<
  IAdminAssociationOwnerProfileState['communications'],
  OwnerActions
>({
  horsesByOwnerLoading: makeCommunicationReducerFromEnum(getHorsesByOwnerActionTypes),
  ownerLoading: makeCommunicationReducerFromEnum(getOwnerActionTypes),
});
