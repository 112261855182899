import {action} from 'typesafe-actions';

import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {
  changePurchasableTestStatusActions,
  changePurchasableTestStatusActionTypes,
  createPurchasableTestActions,
  createPurchasableTestActionTypes,
  getPurchasableTestByIdActions,
  getPurchasableTestByIdActionTypes,
  getPurchasableTestsActions,
  getPurchasableTestsActionTypes,
  resetPurchasableTestDetailsActions,
  resetPurchasableTestDetailsActionTypes,
  updatePurchasableTestActions,
  updatePurchasableTestActionTypes,
} from './types';
import {IRequestParams} from 'Common/models/IRequestParams';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import AdminPurchasableTestDataService from 'Admin/AdminAssociations/services/AdminPurchasableTestDataService';
import {IAdminPurchasableTestRequest} from 'Admin/AdminAssociations/models/IAdminPurchasableTest';

export const getPurchasableTests =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getPurchasableTestsActions> =>
  async (dispatch) => {
    dispatch(action(getPurchasableTestsActionTypes.REQUEST));
    try {
      const res = await AdminPurchasableTestDataService.getPurchasableTests(params);
      dispatch(action(getPurchasableTestsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getPurchasableTestsActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };

export const getPurchasableTestById =
  (id: number): ActionResult<IAppState, void, getPurchasableTestByIdActions> =>
  async (dispatch) => {
    dispatch(action(getPurchasableTestByIdActionTypes.REQUEST));
    try {
      const result = await AdminPurchasableTestDataService.getPurchasableTest(id);
      dispatch(action(getPurchasableTestByIdActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getPurchasableTestByIdActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createPurchasableTest =
  (params: IAdminPurchasableTestRequest): ActionResult<IAppState, void, createPurchasableTestActions> =>
  async (dispatch) => {
    dispatch(action(createPurchasableTestActionTypes.REQUEST));
    try {
      await AdminPurchasableTestDataService.createPurchasableTest(params);
      dispatch(action(createPurchasableTestActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createPurchasableTestActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updatePurchasableTest =
  (params: IAdminPurchasableTestRequest): ActionResult<IAppState, void, updatePurchasableTestActions> =>
  async (dispatch) => {
    dispatch(action(updatePurchasableTestActionTypes.REQUEST));
    try {
      const result = await AdminPurchasableTestDataService.updatePurchasableTest(params);
      dispatch(action(updatePurchasableTestActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(updatePurchasableTestActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const changePurchasableTestStatus =
  (id: number, isEnabled: boolean): ActionResult<IAppState, void, changePurchasableTestStatusActions> =>
  async (dispatch) => {
    dispatch(action(changePurchasableTestStatusActionTypes.REQUEST));
    try {
      await AdminPurchasableTestDataService.changePurchasableTestStatus(id, isEnabled);
      dispatch(action(changePurchasableTestStatusActionTypes.SUCCESS, {purchasableTestId: id}));
    } catch (error) {
      dispatch(action(changePurchasableTestStatusActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetPurchasableTestDetailResults =
  (): ActionResult<IAppState, void, resetPurchasableTestDetailsActions> => async (dispatch) => {
    dispatch(action(resetPurchasableTestDetailsActionTypes.RESET));
  };
