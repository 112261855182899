import * as React from 'react';
import styled from 'styled-components';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import history from 'Common/helpers/History';
import ContainerWrapper from 'Common/components/Layout/ContainerWrapper';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';

const Sign404 = styled.span`
  font-size: ${Typography.size.size28};
  font-weight: ${Typography.weight.medium500};
  color: ${ColorPalette.gray1};
`;

const Text404 = styled.span`
  font-size: ${Typography.size.size16};
  color: ${ColorPalette.gray1};
`;

const BackButton = styled(PrimaryButton)`
  width: 100px;
  margin-top: 20px;
`;

function goBack() {
  history.goBack();
}

const NotFoundPage: React.FC = () => {
  return (
    <BaseLayout>
      <ContainerWrapper className="d-flex flex-row justify-content-center align-items-center">
        <div className="d-flex flex-column">
          <Sign404>404</Sign404>
          <Text404>Sorry, the page you visited does not exist</Text404>
          <BackButton onClick={goBack}>Back</BackButton>
        </div>
      </ContainerWrapper>
    </BaseLayout>
  );
};

export default NotFoundPage;
