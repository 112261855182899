import {IAdminCreateUserOrderRequest} from 'Admin/AdminDashboard/models/Order/IAdminCreateUserOrderRequest';
import {IServerAdminCreateUserOrderRequest} from 'Order/services/types/createUserOrder';
import {convertHorseTestRequestToServer} from 'Order/services/converters/order';

export function convertCreateUserOrderRequestToServer(
  request: IAdminCreateUserOrderRequest
): IServerAdminCreateUserOrderRequest {
  return {
    ...request,
    horses: convertHorseTestRequestToServer(request.horses),
  };
}
