import React, {memo} from 'react';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';

interface IProps {
  isExcluded: boolean;
  onAddExcludedHorse(): void;
  onDeleteExcludedHorse(): void;
}

function ExcludeHorseButton(props: IProps) {
  const {isExcluded, onAddExcludedHorse, onDeleteExcludedHorse} = props;
  return (
    <>
      {isExcluded ? (
        <PrimaryButton className="w-100" size="small" onClick={onDeleteExcludedHorse}>
          Remove from list
        </PrimaryButton>
      ) : (
        <PrimaryButton className="w-100" variant="outlined" size="small" onClick={onAddExcludedHorse}>
          Add to exclude list
        </PrimaryButton>
      )}
    </>
  );
}

export default memo(ExcludeHorseButton);
