import {IModule} from 'redux-dynamic-modules';

import {htmlTemplatesReducer} from './reducer/index';
import {IAdminHtmlTemplateState} from './types';

export interface IAdminHtmlTemplatesModuleState {
  adminHtmlTemplates: IAdminHtmlTemplateState;
}

export const AdminHtmlTemplatesModule: IModule<IAdminHtmlTemplatesModuleState> = {
  id: 'adminHtmlTemplates',
  reducerMap: {
    adminHtmlTemplates: htmlTemplatesReducer
  }
};
