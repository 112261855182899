import {IModule} from 'redux-dynamic-modules';

import {IAdminCreateUserOrderState} from './types';
import {adminCreateUserOrderReducer} from './reducer/index';

export interface IAdminCreateUserOrderModuleState {
  adminCreateUserOrder: IAdminCreateUserOrderState;
}

export const AdminCreateUserOrderModule: IModule<IAdminCreateUserOrderModuleState> = {
  id: 'adminCreateUserOrder',
  reducerMap: {
    adminCreateUserOrder: adminCreateUserOrderReducer,
  },
};
