import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IObjectResponse} from 'Common/models/IResponse';
import {IUploadTestReportRequest} from 'Admin/shared/models/IUploadTestReportRequest';
import {convertToFormData} from 'Common/helpers/convertToFormData';

const uploadReport = async (params: IUploadTestReportRequest): Promise<void> => {
  const {orderId, horseId, isSendReport, uploadedFile} = params;

  await axiosWrapper.post<IObjectResponse<null>>('/HorseReportsUploadAdmin', convertToFormData(uploadedFile), {
    params: {orderId, horseId, isSendReport},
  });
};

const deleteReport = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>('/HorseReportsAdminDelete', {
    data: {reportId: id},
  });
};

export default {
  uploadReport,
  deleteReport,
};
