import {combineReducers} from 'redux';

import {communicationPaymentDetailsUpdateReducer} from './communications';
import {IAdminAssociationPaymentUpdateState} from '../types';
import {dataPaymentDetailsUpdateReducer} from './data';

export const paymentDetailsUpdateReducer = combineReducers<IAdminAssociationPaymentUpdateState>({
  data: dataPaymentDetailsUpdateReducer,
  communications: communicationPaymentDetailsUpdateReducer,
});
