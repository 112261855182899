import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';
import Theme from 'Common/constants/Theme';

export const Icon = styled.div`
  position: absolute;
  font-size: ${Typography.size.size18};
  line-height: 24px;
  top: 50%;
  left: 11px;
  color: ${ColorPalette.white7};
  ::selection {
    background: transparent;
  }
  ::-moz-selection {
    background: transparent;
  }
`;

export const IconedField = styled.div`
  position: relative;
`;

export const Hint = styled.p`
  margin-top: -8px;
  color: ${ColorPalette.gray3};
  font-size: ${Typography.size.size14};
  line-height: 24px;
`;

export const FieldHint = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.gray44};
`;

export const ErrorMessage = styled.div`
  color: ${ColorPalette.red0};
  font-weight: ${Typography.weight.medium500};
`;

export const CellSeveral = styled.span`
  color: ${ColorPalette.gray32};
`;

export const CopyLinkMessage = styled.div<{visible: boolean}>`
  position: absolute;
  height: 40px;
  top: 60px;
  right: -120px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: ${(props) => (props.visible ? 'opacity 0.5s linear' : 'visibility 0s 0.5s, opacity 0.5s linear')};
  padding: 7px 16px;
  background: ${ColorPalette.yellow6};
  border: 2px solid #ffd41b;
  border-radius: 4px;
  font-family: ${Typography.family.roboto};
  font-size: ${Typography.size.size15};
  align-items: center;
  text-align: center;
`;

export const PageError = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: ${ColorPalette.red1};
`;

export const MutableFieldsGroupBase = styled.div<{pointOfChange?: keyof typeof breakpoints}>`
  display: flex;
  flex-direction: column;

  ${({pointOfChange = 'sm'}) => ` 
    @media ${breakpoints[pointOfChange]} {
      flex-direction: row;
    }`}
`;

export const MutableField = styled.div<{width: number; keepSize?: boolean}>`
  width: ${({width, keepSize}) => (keepSize ? width : 100)}%;

  @media ${breakpoints.sm} {
    width: ${({width}) => width}%;
  }
`;

export const MutableFieldsGroup = styled(MutableFieldsGroupBase)<{indent?: string}>`
  @media ${breakpoints.sm} {
    > ${MutableField}:not(:first-child) {
      margin-left: ${(props) => props.indent ?? 0};
    }
  }
`;

export const AnimatedInitiate = styled.div<{duration?: number; delay?: number}>`
  opacity: 0;
  animation: show ${({duration}) => (duration ? duration : 0.5)}s 1;
  animation-fill-mode: forwards;

  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const SwitchWrapper = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${ColorPalette.gray10};
`;

export const SwitchFieldWrapper = styled.div`
  align-self: center;
  display: flex;
  justify-content: right;
  min-width: 120px;

  @media ${breakpoints.md} {
    min-width: 140px;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${ColorPalette.gray10};
`;
