import React, {memo, useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';
import {debounce} from 'lodash';
import * as R from 'ramda';

import {IAppState} from 'Common/store/IAppState';
import {MainPageHorsesModule} from 'Main/store/mainPageHorses/mainPageHorsesModule';
import {Nullable} from 'Common/types';
import {actions, selectors} from 'Main/store/mainPageHorses';
import {selectors as userSelector} from 'UserProfile/store/currentUser';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Loading from 'Loading/components/Loading';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import LinkAsOutlinedButton from 'Common/components/Controls/Links/LinkAsOutlinedButton';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import HorseProfileForm from 'HorseProfile/components/HorseProfileForm/HorseProfileForm';
import {FormType} from 'Common/constants/FormType';
import {breakpoints} from 'Common/constants/Breakpoints';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import Theme from 'Common/constants/Theme';

import HorseCarousel from './HorseCarousel';
import EmptyHorse from './EmptyHorse';
import {SearchBar} from 'Admin/AdminDashboard/components/shared/index';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import withDynamicModules from 'Common/helpers/withDynamicModules';

const searchBarStyle: React.CSSProperties = {
  backgroundColor: `${hexToRgba(Theme.color.white, 0.05)}`,
  color: ColorPalette.gray44,
  boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.1)',
  borderRadius: 10,
  fontWeight: Typography.weight.normal400 as any,
  letterSpacing: 0.2,
};

const WelcomeSection = styled.div`
  flex-direction: column;
  align-items: center;

  @media ${breakpoints.sm} {
    flex-direction: row;
    align-items: baseline;
  }

  @media ${breakpoints.md} {
    align-items: center;
  }
`;

const SearchableTitle = styled.div`
  flex-direction: column;

  @media ${breakpoints.md} {
    flex-direction: row;
    align-items: center;
  }
`;
const TitleSection = styled.div`
  @media ${breakpoints.md} {
    width: 70%;
  }
`;

const SearchBarSection = styled.div`
  margin: 24px 0;
  order: 1;

  @media ${breakpoints.sm} {
    width: 120%;
  }

  @media ${breakpoints.md} {
    margin: 0 16px 0 24px;
    width: 30%;
    order: 0;
  }
`;

const Title = styled.div`
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size20};
  line-height: 25px;
  color: ${Theme.color.white};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size32};
    line-height: 40px;
  }
`;

const SubTitle = styled.div`
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size13};
  line-height: 16px;
  color: ${Theme.color.white};
  opacity: 0.8;
  margin-top: 8px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size24};
    line-height: 30px;
  }
`;

const MyHorsesButton = styled(LinkAsOutlinedButton)`
  width: 118px;
  height: 44px;
  font-family: ${Typography.family.roboto};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size13};
  line-height: 15px;
  color: ${Theme.color.white};
  border: 2px solid ${ColorPalette.red7};
  border-radius: 4px;

  &:hover:not(:disabled) {
    color: ${Theme.color.white};
  }
`;

const ContentWrapper = styled.div`
  margin: 26px 20px 0;
  padding: 0;
  min-height: 281px;

  @media ${breakpoints.sm} {
    margin: 26px -24px 0;
    padding: 0 24px;
  }
  @media ${breakpoints.md} {
    padding: 0;
  }
`;

const NoHorsesWrap = styled.div`
  flex-direction: column-reverse;
  justify-content: center;
  @media ${breakpoints.sm} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const NoHorsesText = styled.div`
  max-width: 513px;
  margin-bottom: 20px;
  color: ${ColorPalette.white0};

  font-family: ${Typography.family.ubuntu};
  font-size: 20px;
  line-height: 32px;

  @media ${breakpoints.sm} {
    margin: 0 0 0 56px;
  }
`;

const ButtonWrapper = styled.div`
  margin: 38px 0 10px;
`;

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected;

function Horses(props: Props) {
  const {getHorses, horses, currentUserId, horsesLoading} = props;

  const [error, setError] = useState<Nullable<string>>(null);
  const [isAddHorseOpen, setIsAddHorseOpen] = useState(false);

  const isLoading = horsesLoading.isRequesting;
  const errorInfo = horsesLoading.error;

  useEffect(() => {
    getHorses();
  }, [getHorses]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeFilter = useCallback(
    debounce(async (value: string) => getHorses(value), 500),
    [getHorses]
  );

  const handleChangeFilter = useCallback(
    (value) => {
      setSearchQuery(value);
      debouncedChangeFilter(value);
    },
    [debouncedChangeFilter]
  );
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    setSearchQuery(R.isNil(searchQuery) ? '' : searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    commonErrors && setError(commonErrors);
  }, [errorInfo]);

  const reloadItems = useCallback(() => {
    getHorses();
  }, [getHorses]);

  const openModalNewHorse = React.useCallback(() => setIsAddHorseOpen(true), []);
  const closeModalNewHorse = React.useCallback(() => setIsAddHorseOpen(false), []);

  const onSuccessCreateHorse = React.useCallback(() => {
    closeModalNewHorse();
    reloadItems();
  }, [closeModalNewHorse, reloadItems]);

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  function renderHorsesOutput() {
    if (horses.length < 1) {
      return (
        <NoHorsesWrap className="d-flex align-items-center">
          <EmptyHorse onClick={openModalNewHorse} />
          {(() => {
            if (searchQuery) {
              return <NoHorsesText>No matches found</NoHorsesText>;
            }

            return (
              <NoHorsesText>
                To start testing and building horses you will need to add your horse(s) to your profile. Start by
                clicking the "+ Add new horse" on the left
              </NoHorsesText>
            );
          })()}
        </NoHorsesWrap>
      );
    }

    return <HorseCarousel horses={horses} onEmptyCardClick={openModalNewHorse} />;
  }

  return (
    <div className="d-flex flex-column">
      <WelcomeSection className="d-flex justify-content-between">
        <SearchableTitle className="d-flex w-100">
          <TitleSection>
            <Title>Welcome to an evolution in equine genetics</Title>
            <SubTitle className="d-flex">Please select horses you wish to test, breed, buy or sell</SubTitle>
          </TitleSection>
          <SearchBarSection>
            <SearchBar
              onChange={handleChangeFilter}
              value={searchQuery}
              placeholder="Search your horses"
              style={searchBarStyle}
            />
          </SearchBarSection>
        </SearchableTitle>
        <MyHorsesButton to={`/user/user-profile/${currentUserId}`}>All my horses</MyHorsesButton>
      </WelcomeSection>

      <ContentWrapper className="position-relative">
        {isLoading ? (
          <Loading textColor={ColorPalette.white0} backgroundColor={ColorPalette.transparent6} />
        ) : (
          renderHorsesOutput()
        )}
      </ContentWrapper>

      <ButtonWrapper className="align-self-center">
        <LinkButton to={`/order-tests/`}>Order Testing</LinkButton>
      </ButtonWrapper>

      <ModalWindow isOpen={isAddHorseOpen} onClose={closeModalNewHorse}>
        <HorseProfileForm type={FormType.create} onSuccess={onSuccessCreateHorse} isAdmin={false} />
      </ModalWindow>
    </div>
  );
}

const mapStateToProps = (state: IAppState) => ({
  horses: selectors.selectHorses(state),
  horsesLoading: selectors.selectCommunication(state, 'horsesLoading'),
  currentUserId: userSelector.selectCurrentUserId(state),
});

const mapDispatchToProps = {
  getHorses: actions.getHorses,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(Horses));

export default withDynamicModules(Connected, MainPageHorsesModule);
