import {CSSProperties} from 'react';
import styled from 'styled-components';
import {NavLink, NavLinkProps} from 'react-router-dom';

import ColorPalette from 'Common/constants/ColorPalette';

interface IProps {
  color?: string;
  hoverColor?: string;
  activeStyle?: React.CSSProperties;
  disabled?: boolean;
}

const defaultActiveStyle: CSSProperties = {opacity: 1};

const ColoredNavLink = styled(NavLink).attrs(props => {
  return {activeStyle: props.activeStyle || defaultActiveStyle};
})<IProps & NavLinkProps>`
  color: ${props => (props.color ? props.color : ColorPalette.white0)};
  opacity: ${props => (props.disabled ? '0.3' : '0.4')};
  :hover {
    color: ${props => (props.hoverColor ? props.hoverColor : ColorPalette.white0)};
    opacity: 1;
  }
  pointer-events: ${props => props.disabled && 'none'};
`;

export default ColoredNavLink;
