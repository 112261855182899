import React, {useState, useCallback, useEffect, useMemo, useRef, CSSProperties} from 'react';
import styled from 'styled-components';
import {debounce} from 'lodash';
import {PropsValue, InputActionMeta, ActionMeta} from 'react-select/dist/declarations/src/types';
import {FormatOptionLabelMeta} from 'react-select/dist/declarations/src/Select';
import Highlighter from 'react-highlight-words';

import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {Select} from 'Common/components/Controls/index';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';
import UserDataService from 'Admin/AdminDashboard/services/UserDataService';
import {IFoundUser} from 'Admin/AdminDashboard/models/User/IFoundUser';
import {SelectProps} from 'Common/components/Controls/Select/Select';
import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {Nullable} from 'Common/types';

const highlightStyle: CSSProperties = {fontWeight: 500, background: 'none', margin: 0, padding: 0};

const selectStyles = {
  menuList: () => ({
    boxShadow: 'unset',
  }),
  valueContainer: () => ({
    paddingLeft: '56px',
  }),
};

const style = {
  marginBottom: 0,
};

const SearchIcon = styled(ColoredIcon)`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

const AvatarWrapper = styled.div`
  margin-right: 8px;
`;

const NameHighlighter = styled(Highlighter)`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${Theme.color.black};
  margin-bottom: 4px;
`;

const EmailHighlighter = styled(Highlighter)`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 12px;
  letter-spacing: 0.5px;
  color: ${Theme.color.gray};
`;

const optionCustomRenderer = (option: IUserOption, labelMeta: FormatOptionLabelMeta<IUserOption>) => {
  const {avatar, firstName, lastName, email} = option;
  return (
    <div className="d-flex align-items-center">
      <AvatarWrapper>
        <Avatar
          avatarSize={AvatarSize.Custom}
          customSizeAvatar={32}
          customSizeIcon={18}
          defaultAvatarProfile={DefaultAvatarProfile.User}
          url={avatar?.url}
        />
      </AvatarWrapper>
      <div className="d-flex flex-column">
        <NameHighlighter
          searchWords={[labelMeta.inputValue]}
          textToHighlight={`${firstName} ${lastName}`}
          highlightStyle={highlightStyle}
        />
        <EmailHighlighter
          searchWords={[labelMeta.inputValue]}
          textToHighlight={email}
          highlightStyle={highlightStyle}
        />
      </div>
    </div>
  );
};

const emptyListMessage = () => 'No users';

export interface IUserOption extends IDictionaryOptionType, IFoundUser {}

interface IOwnProps {
  defaultValue?: IUserOption;
  action?(filter: string): Promise<IFoundUser[]>;
  onSelect(userId: number): void;
  onClear?: () => void;
  hasValue: boolean;
}

type Props = IOwnProps & SelectProps<IUserOption>;

function UserSearch(props: Props) {
  const {onSelect, onClear, action = UserDataService.findUser, defaultValue, hasValue, ...rest} = props;

  const [searchValue, setSearchValue] = useState('');

  const onInputChange = useCallback(
    (value: string, {action}: InputActionMeta) => {
      if (action === 'input-change') {
        setSearchValue(value);
      }
    },
    [setSearchValue]
  );

  const [foundUsers, setFoundUsers] = useState<IFoundUser[]>([]);
  const isEmptyValue = useRef(true);

  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const getUsers = useCallback(
    async (inputValue: string) => {
      try {
        setIsUsersLoading(true);
        const users = await action(inputValue);
        if (isEmptyValue.current) {
          return;
        }
        setFoundUsers(users);
      } catch (e) {
        console.error(e);
      } finally {
        setIsUsersLoading(false);
      }
    },
    [action]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetUsers = useCallback(
    debounce((value?: string) => value && getUsers(value), 500),
    [getUsers]
  );

  useEffect(() => {
    isEmptyValue.current = !searchValue;
    if (!searchValue) {
      setFoundUsers([]);
    }
    debouncedGetUsers(searchValue);
  }, [searchValue, getUsers, debouncedGetUsers]);

  const [selectedUser, setSelectedUser] = useState<Nullable<PropsValue<IUserOption>>>(defaultValue || null);

  const onClearValue = useCallback(() => {
    setSelectedUser(null);
    setSearchValue('');
    onClear && onClear();
  }, [onClear]);

  useEffect(() => {
    if (!hasValue) {
      onClearValue();
    }
  }, [hasValue, onClearValue]);

  const onSelectOption = useCallback(
    (user: PropsValue<IUserOption>, action: ActionMeta<IUserOption>) => {
      if (action.action === 'select-option' && user) {
        const {value} = user as IUserOption;
        setSelectedUser(user as IUserOption);
        onSelect(Number(value));
      }

      if (action.action === 'clear') {
        onClearValue();
      }
    },
    [onSelect, onClearValue]
  );

  const isLoading = !!searchValue && isUsersLoading;
  const users = useMemo(() => (!!searchValue ? foundUsers : []), [foundUsers, searchValue]);

  const options = useMemo(
    () =>
      users.map(({id, email, firstName, lastName, avatar}) => ({
        value: id,
        label: `${firstName} ${lastName} (${email})`,
        avatar,
        id,
        firstName,
        lastName,
        email,
      })),
    [users]
  );

  return (
    <div className="position-relative">
      <SearchIcon name={IconName.Search} size={24} stroke={false} fill={true} color={Theme.color.primary} />
      <Select<IUserOption>
        isSearchable={true}
        inputValue={selectedUser ? undefined : searchValue}
        onInputChange={onInputChange}
        onChange={onSelectOption}
        options={options}
        styles={selectStyles}
        formatOptionLabel={optionCustomRenderer}
        noOptionsMessage={emptyListMessage}
        isLoading={isLoading}
        value={selectedUser}
        {...rest}
        placeholder="Find the owner"
        isClearable={true}
        style={style}
      />
    </div>
  );
}

export default UserSearch;
