import React, {memo} from 'react';
import AdminActions from 'Admin/AdminDashboard/components/shared/AdminActions/AdminActions';
import {IMenuItemValued} from 'Common/models/IMenuItemValued';
import {RenderToggle} from 'Common/components/DropDown';

type ActionsHandler<T extends string> = {
  [K in T]: () => void;
};

interface IProps<T> {
  menuItems: IMenuItemValued<T>[];
  children?: RenderToggle;
}

type AllProps<T extends string> = ActionsHandler<T> & IProps<T>;

const Actions = <T extends string>(props: AllProps<T>) => {
  const {menuItems, children} = props;

  const onSelectHandler = (type: T) => props[type]();

  return (
    <AdminActions menuItems={menuItems} onSelectHandler={onSelectHandler}>
      {children}
    </AdminActions>
  );
};

export default memo(Actions) as <T extends string>(props: AllProps<T>) => JSX.Element;
