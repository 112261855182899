import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {convertDemographicsToRequest} from './converters/reports';
import {downloadResponse} from 'Common/helpers/downloadHelper';
import {ReportType} from 'Common/constants/ReportType';

const urlByReportType: Record<ReportType, string> = {
  [ReportType.Demographics]: '/DemographicsReportAdmin',
  [ReportType.PaidOrders]: '/PaidOrdersReportAdmin',
  [ReportType.Orders]: '/OrdersReportAdmin',
};

const filenameByReportType: Record<ReportType, string> = {
  [ReportType.Demographics]: 'Demographics',
  [ReportType.PaidOrders]: 'PaidOrders',
  [ReportType.Orders]: 'Orders',
};

const downloadReport = async (from: string, to: string, type: ReportType): Promise<void> => {
  const response = await axiosWrapper.get(urlByReportType[type], {
    params: convertDemographicsToRequest({from, to}),
    responseType: 'blob',
  });
  const fileName = `${filenameByReportType[type]}-${from}${to !== from ? '-' + to : ''}`;
  downloadResponse(response, fileName);
};

export default {downloadReport};
