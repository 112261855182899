import {OrderStatus} from 'Common/constants/OrderStatus';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {OrderStatusUpdateDate} from 'Order/models/IOrderStatusUpdateDateField';

export interface IOrderStatusMain {
  icon: IconName;
  label: string;
  color: string;
}

interface IOrderStatus extends IOrderStatusMain {
  status: OrderStatus;
  statusUpdateDate?: OrderStatusUpdateDate[];
}

export const actionLabelByStatus: Record<OrderStatus, string> = {
  [OrderStatus.orderPlaced]: 'Update payment',
  [OrderStatus.paymentReceived]: 'Mark sample as received',
  [OrderStatus.sampleReceived]: 'Review',
  [OrderStatus.resultsReady]: 'Review',
  [OrderStatus.reviewReady]: 'Release',
  [OrderStatus.releaseReady]: '',
};

export const statusOrdering: Record<OrderStatus, number> = {
  [OrderStatus.orderPlaced]: 0,
  [OrderStatus.paymentReceived]: 1,
  [OrderStatus.sampleReceived]: 2,
  [OrderStatus.resultsReady]: 3,
  [OrderStatus.reviewReady]: 4,
  [OrderStatus.releaseReady]: 5,
};

export const statuses: Array<IOrderStatus> = [
  {status: OrderStatus.orderPlaced, icon: IconName.Clock, label: 'Order placed', color: ColorPalette.gray44},
  {status: OrderStatus.paymentReceived, icon: IconName.Money, label: 'Payment received', color: ColorPalette.yellow9},
  {status: OrderStatus.sampleReceived, icon: IconName.Truck, label: 'Sample received', color: ColorPalette.green2},
  {
    status: OrderStatus.resultsReady,
    icon: IconName.CheckedQuestionnaire,
    label: 'Results ready',
    color: ColorPalette.green2,
    statusUpdateDate: [
      OrderStatusUpdateDate.AncestryResultsStatusUpdateDate,
      OrderStatusUpdateDate.DiagnosticResultsStatusUpdateDate,
      OrderStatusUpdateDate.ParentageResultsStatusUpdateDate,
      OrderStatusUpdateDate.RelativesResultsStatusUpdateDate,
    ],
  },
  {
    status: OrderStatus.reviewReady,
    icon: IconName.InsertComment,
    label: 'Reviewed',
    color: ColorPalette.green2,
    statusUpdateDate: [OrderStatusUpdateDate.ReviewStatusUpdateDate],
  },
  {
    status: OrderStatus.releaseReady,
    icon: IconName.FilledCheckbox,
    label: 'Order complete',
    color: ColorPalette.green2,
    statusUpdateDate: [OrderStatusUpdateDate.ReleaseStatusUpdateDate],
  },
];
