import React, {memo} from 'react';
import styled from 'styled-components';

import {ImageUploaderProps} from 'Image/components/ImageUploader';
import {IMediaResource} from 'Common/models/IMediaResource';
import Gallery, {GalleryProps} from 'Gallery/components/Gallery';
import {IFieldProps} from 'Common/models/IFieldProps';
import {VideoUploaderProps} from 'Video/components/VideoUploader';

import withField from './withField';
import ValidationError from './ValidationError';

const InputLayout = styled.div`
  position: relative;
`;

type ImageProps = {imageProps: Omit<ImageUploaderProps, 'value' | 'onChange' | 'onDelete'>};
type VideoProps = {videoProps: Omit<VideoUploaderProps, 'value' | 'onChange'>};

type Props = IFieldProps<IMediaResource[]> & ImageProps & VideoProps & Pick<GalleryProps, 'isEditMode'>;

function GalleryField(props: Props) {
  const {
    field: {name, value},
    form: {setFieldValue}
  } = props;

  const onChange = React.useCallback((values: IMediaResource[]) => setFieldValue(name, values), [name, setFieldValue]);

  return (
    <InputLayout>
      <Gallery {...props} onChange={onChange} values={value || []} />
      <ValidationError name={name} />
    </InputLayout>
  );
}

export default memo(withField(GalleryField));
