import React, {useCallback, useMemo, useState} from 'react';
import {useStore} from 'react-redux';

import {useBindActions} from 'Common/helpers/hooks/useBindActions';
import {ServiceMode} from 'Maintain/models/IServiceMode';
import {actions} from 'Maintain/store/index';
import MaintainNotification from 'Maintain/components/MaintainNotification';
import {IAppState} from 'Common/store/IAppState';

// TODO: make websocket instead of timer
const DELAY_TIMEOUT = 300000;

export default function useServiceMode() {
  const {getServiceMode} = useBindActions({getServiceMode: actions.getServiceMode});
  const [timerId, setTimerId] = useState<number>();
  const [isShowNotification, setIsShowNotification] = useState(false);

  const store = useStore<IAppState>();
  const serviceMode = store.getState().serviceMode;
  const serviceModeState = serviceMode.data.serviceMode.mode;
  const isServiceModeLoading = serviceMode.communications.serviceModeLoading.isRequesting;

  const getServiceModeStatus = useCallback(async () => {
    const res = await getServiceMode();
    return res.mode;
  }, [getServiceMode]);

  const startServiceModeTimer = useCallback(async () => {
    setIsShowNotification(true);
    getServiceModeStatus();

    const id = setInterval(function tick() {
      getServiceModeStatus();
    }, DELAY_TIMEOUT);

    setTimerId(id);
  }, [getServiceModeStatus]);

  const stopServiceModeTimer = useCallback(() => {
    clearTimeout(timerId);
    setIsShowNotification(false);
  }, [timerId]);

  const renderNotification = useMemo(() => {
    const isShow = isShowNotification && serviceModeState === ServiceMode.UsersOff;

    return isShow && <MaintainNotification onClose={stopServiceModeTimer} />;
  }, [isShowNotification, serviceModeState, stopServiceModeTimer]);

  return {
    getServiceModeStatus,
    isServiceModeLoading,
    startServiceModeTimer,
    stopServiceModeTimer,
    renderNotification
  };
}
