import React, {memo, useCallback, useEffect} from 'react';

import {OrderStatusList} from 'Admin/shared/components/OrderStatus';
import {OrderStatus} from 'Common/constants/OrderStatus';
import {IAdditionalButton} from 'Admin/shared/components/OrderStatus/parts/OrderStatusDropdownView';
import Loading from 'Loading/components/Loading';
import withOrderDetailsActions, {
  IWithOrderDetailsActionsProps,
} from 'Admin/AdminAssociations/helpers/withOrderDetailsActions';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';

interface IOwnProps {
  orderId: number;
  statusItemsFunc: {};
  openRequiredTestsModal(): void;
}

type Props = IWithOrderDetailsActionsProps & IOwnProps;

function StatusDetails(props: Props) {
  const {
    getOrderStatusesDetails,
    orderId,
    orderStatusesDetails,
    orderStatusesDetailsLoading,
    setSampleStatusRequesting,
    statusItemsFunc,
    openRequiredTestsModal,
  } = props;

  const {emit, listen} = useEventBus();

  const getDetails = useCallback(() => {
    if (orderId) {
      getOrderStatusesDetails(orderId);
    }
  }, [getOrderStatusesDetails, orderId]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    if (orderStatusesDetails) {
      emit(EventName.OrderStatusChanged, orderStatusesDetails);
    }
  }, [emit, orderStatusesDetails]);

  useEffect(() => {
    const eventUpdatePayment = listen(EventName.UpdatePayment, getDetails);
    const eventSampleChanged = listen(EventName.SampleChanged, getDetails);
    const eventReleaseOrder = listen(EventName.ReleaseOrder, getDetails);

    return () => {
      eventUpdatePayment.stopListening();
      eventSampleChanged.stopListening();
      eventReleaseOrder.stopListening();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const additionalButtons: Partial<Record<OrderStatus, IAdditionalButton>> = {
    [OrderStatus.sampleReceived]: {name: 'Check results', onClick: openRequiredTestsModal},
  };

  const isLoading = orderStatusesDetailsLoading.isRequesting;

  return (
    <div className="position-relative">
      {isLoading && <Loading />}

      <OrderStatusList
        status={orderStatusesDetails.status}
        onChangeOrderStatus={statusItemsFunc[orderStatusesDetails.status]}
        isLoading={setSampleStatusRequesting.isRequesting}
        completedStatuses={orderStatusesDetails.hasSample ? OrderStatus.sampleReceived : undefined}
        uncompletedStatuses={orderStatusesDetails.hasNotResults ? OrderStatus.resultsReady : undefined}
        additionalButtons={additionalButtons}
        statusUpdateDates={orderStatusesDetails.statusUpdateDates}
      />
    </div>
  );
}

export default memo(withOrderDetailsActions(StatusDetails));
