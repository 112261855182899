import {Reducer} from 'redux';

import {
  getPaymentDetailsActionTypes,
  getPaymentDetailsHorsesActionTypes,
  IAdminAssociationPaymentUpdateState,
  UpdatePaymentDetailsActions,
  resetPaymentDetailsActionTypes,
  resetPaymentDetailsHorsesActionTypes,
} from '../types';

const initialState: IAdminAssociationPaymentUpdateState['data'] = {
  paymentDetails: null,
  paymentDetailsHorses: [],
};

export const dataPaymentDetailsUpdateReducer: Reducer<
  IAdminAssociationPaymentUpdateState['data'],
  UpdatePaymentDetailsActions
> = (state = initialState, action: UpdatePaymentDetailsActions) => {
  switch (action.type) {
    case getPaymentDetailsActionTypes.SUCCESS:
      return {...state, paymentDetails: action.payload};

    case getPaymentDetailsHorsesActionTypes.SUCCESS:
      return {...state, paymentDetailsHorses: action.payload.data};

    case resetPaymentDetailsActionTypes.RESET:
      return {...state, paymentDetails: initialState.paymentDetails};

    case resetPaymentDetailsHorsesActionTypes.RESET:
      return {...state, paymentDetailsHorses: initialState.paymentDetailsHorses};

    default: {
      return state;
    }
  }
};
