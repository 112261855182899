import {useCallback, useEffect, useMemo, useState} from 'react';

import {Title} from '../parts/styled';
import {IFacetProps} from '../Facet';
import {Select} from 'Common/components/Controls';
import {IFilterRequest} from 'FacetFilter/models/IFilterRequest';
import {castToOption} from 'Common/helpers/OptionHelper';
import {Nullable} from 'Common/types';
import {ActionMeta, PropsValue} from 'react-select/dist/declarations/src/types';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';
import {OperationDropDown} from '../parts/OperationDropDown';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {IEntity} from 'Common/models/IEntity';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {IAppState} from 'Common/store/IAppState';
import {IFacetSelectors, makeFacetSelectors} from 'FacetFilter/helpers/makeFacetSelectors';
import {connect} from 'react-redux';
import {memoWithGeneric} from 'Common/helpers/react/memoWithGeneric';
import Loading from 'Loading/components/Loading';
import {selectStyles} from './styled';
import {FilterOperatorIgnor} from 'FacetFilter/constants/FilterOperator';

const style = {
  marginBottom: 0,
};

interface IOwnProps<D> {
  dictionary: D;
}

type AllProps<T, D> = IOwnProps<D> & IFacetSelectors<T> & IFacetProps;

function ItemListFacet<T extends IEntity, D extends ISimpleDictionary<T>>(props: AllProps<T, D>) {
  const {
    filterProperty,
    filterPropertyOperations,
    filterRequest = {
      property: filterProperty.property,
      operation: filterPropertyOperations?.operations[0].operation || '',
      value: '',
    },
    onSuccess,
  } = props;
  const {dictionary, items, itemsLoading} = props;

  const [request, setRequest] = useState<IFilterRequest>(filterRequest);
  const [selectedEnumValue, setSelectedEnumValue] = useState<Nullable<PropsValue<IDictionaryOptionType>>>(null);
  const [isDisableSelect, setIsDisabledSelect] = useState(false);

  const {
    actions: {getItems},
  } = dictionary;

  useEffect(() => {
    getItems();
  }, [getItems]);

  const options = useMemo(() => castToOption(items).sort((a, b) => sortByStringKey(a, b, 'label')), [items]);

  useEffect(() => {
    setRequest(filterRequest);
    const findOption = options.find((x) => x.value.toString() === filterRequest.value);
    setSelectedEnumValue(findOption || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (selectedEnumValue || (!selectedEnumValue && FilterOperatorIgnor.includes(request.operation))) {
      onSuccess(request);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const onSelectOption = useCallback(
    (enumValue: PropsValue<IDictionaryOptionType>, action: ActionMeta<IDictionaryOptionType>) => {
      if (action.action === 'select-option' && enumValue) {
        const {value} = enumValue as IDictionaryOptionType;
        setSelectedEnumValue(enumValue as IDictionaryOptionType);
        setRequest({...request, value: value.toString()});
      }
    },
    [request]
  );

  const onOperationSelect = useCallback(
    (operation: string) => {
      setRequest({...request, operation});
      if (FilterOperatorIgnor.includes(operation)) {
        setIsDisabledSelect(true);
      } else {
        setIsDisabledSelect(false);
      }
    },
    [request]
  );

  const isLoading = itemsLoading.isRequesting;

  return (
    <>
      {isLoading && <Loading />}
      <Title>{filterProperty.title}</Title>
      {filterPropertyOperations && (
        <OperationDropDown
          filterOperations={filterPropertyOperations}
          onSelect={onOperationSelect}
          defaultOperation={filterRequest.operation}
        />
      )}
      <Select
        options={options}
        value={selectedEnumValue}
        onChange={onSelectOption}
        style={style}
        styles={selectStyles}
        disabled={isDisableSelect}
      />
    </>
  );
}

const mapStateToProps = <T extends IEntity, D extends ISimpleDictionary<T>>(
  state: IAppState,
  ownProps: IOwnProps<D>
): IFacetSelectors<T> => {
  return makeFacetSelectors(state, ownProps.dictionary);
};

export default connect(mapStateToProps)(memoWithGeneric(ItemListFacet));
