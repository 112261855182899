import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  AuthActions,
  getVisitorTypeActions,
  getVisitorTypeActionTypes,
  loginActions,
  loginActionTypes,
  resetVisitorTypeActionTypes,
} from './types';
import AuthService from 'Auth/services/AuthService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IAppState} from 'Common/store/IAppState';
import {IAuthCredentials} from 'Auth/models/IAuthCredentials';

export const login =
  (payload: IAuthCredentials): ActionResult<IAppState, Promise<void>, loginActions> =>
  async (dispatch) => {
    try {
      dispatch(action(loginActionTypes.REQUEST));
      await AuthService.login(payload);
      dispatch(action(loginActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(loginActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getVisitorType =
  (email: string): ActionResult<IAppState, Promise<void>, getVisitorTypeActions> =>
  async (dispatch) => {
    dispatch(action(getVisitorTypeActionTypes.REQUEST));
    try {
      const user = await AuthService.getVisitorType(email);
      dispatch(action(getVisitorTypeActionTypes.SUCCESS, user));
    } catch (error) {
      dispatch(action(getVisitorTypeActionTypes.FAILURE, error));
    }
  };

export const resetVisitorType = (): ActionResult<IAppState, void, AuthActions> => (dispatch) => {
  dispatch(action(resetVisitorTypeActionTypes.RESET));
};
