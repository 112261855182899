import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';

import {IListResponse, IPagination} from 'Common/models/IResponse';
import {IAdminAssociationPackage} from 'Admin/AdminAssociations/models/IAdminAssociationPackage';
import {Nullable} from 'Common/types';

const STATE_NAME = 'adminAssociationPackages';

export interface IAdminAssociationPackagesState {
  data: {
    packages: IAdminAssociationPackage[];
    packageDetail: Nullable<IAdminAssociationPackage>;
  };
  communications: {
    packagesLoading: ICommunication;
    packageCreating: ICommunication;
    packageUpdating: ICommunication;
    packageDeleting: ICommunication;
    packageDetailsLoading: ICommunication;
    changePackageStatusRequesting: ICommunication;
    packageCartSettingsUpdating: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getAdminAssociationPackagesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ASSOCIATION_PACKAGES'
);

export const getAdminAssociationPackageByIdActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ASSOCIATION_PACKAGE_BY_ID'
);

export const createAdminAssociationPackageActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CREATE_ASSOCIATION_PACKAGE'
);

export const updateAdminAssociationPackageActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ASSOCIATION_PACKAGE'
);

export const deleteAdminAssociationPackageActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DELETE_ASSOCIATION_PACKAGE'
);

export const changeAdminAssociationPackageStatusActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CHANGE_ASSOCIATION_PACKAGE_STATUS'
);

export const updateAdminAssociationPackageCartSettingsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ASSOCIATION_PACKAGE_CART_SETTINGS'
);

export const resetAdminAssociationPackageDetailsActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'ASSOCIATION_PACKAGE_DETAIL'
);

export type resetAdminAssociationPackageDetailsActions = MakeResetCommunicationActions<
  typeof resetAdminAssociationPackageDetailsActionTypes
>;

export type getAdminAssociationPackagesActions = MakeCommunicationActions<
  typeof getAdminAssociationPackagesActionTypes,
  {success: IListResponse<IAdminAssociationPackage>}
>;

export type getAdminAssociationPackageByIdActions = MakeCommunicationActions<
  typeof getAdminAssociationPackageByIdActionTypes,
  {success: IAdminAssociationPackage}
>;

export type changeAdminAssociationPackageStatusActions = MakeCommunicationActions<
  typeof changeAdminAssociationPackageStatusActionTypes,
  {success: {packageId: number}}
>;

export type updateAdminAssociationPackageCartSettingsActions = MakeCommunicationActions<
  typeof updateAdminAssociationPackageCartSettingsActionTypes,
  {}
>;

export type createAdminAssociationPackageActions = MakeCommunicationActions<
  typeof createAdminAssociationPackageActionTypes,
  {}
>;

export type updateAdminAssociationPackageActions = MakeCommunicationActions<
  typeof updateAdminAssociationPackageActionTypes,
  {}
>;

export type deleteAdminAssociationPackageActions = MakeCommunicationActions<
  typeof deleteAdminAssociationPackageActionTypes,
  {}
>;

export type AdminAssociationPackagesActions =
  | getAdminAssociationPackagesActions
  | getAdminAssociationPackageByIdActions
  | createAdminAssociationPackageActions
  | updateAdminAssociationPackageActions
  | deleteAdminAssociationPackageActions
  | changeAdminAssociationPackageStatusActions
  | updateAdminAssociationPackageCartSettingsActions
  | resetAdminAssociationPackageDetailsActions;
