import {Reducer} from 'redux';

import {ServiceModeActions, getServiceModeActionTypes, IServiceModeState} from '../types';
import {ServiceMode} from 'Maintain/models/IServiceMode';

const initialState: IServiceModeState['data'] = {
  serviceMode: {mode: ServiceMode.On}
};

export const dataServiceModeReducer: Reducer<IServiceModeState['data'], ServiceModeActions> = (
  state = initialState,
  action: ServiceModeActions
) => {
  switch (action.type) {
    case getServiceModeActionTypes.SUCCESS: {
      return {...state, serviceMode: action.payload};
    }
    default: {
      return state;
    }
  }
};
