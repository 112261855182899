import {Form, FormikProps, withFormik} from 'formik';
import {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {AdminUsersModule} from 'Admin/AdminDashboard/store/adminUsers/users/adminUsersModule';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminUsers/users/index';
import {InputField} from 'Common/components/FormFields';
import PasswordField from 'Common/components/FormFields/PasswordField';
import SelectField from 'Common/components/FormFields/SelectField';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {Salutation} from 'Common/constants/Salutation';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {getNumberKeysOption} from 'Common/helpers/OptionHelper';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import {IFormValues, initialValue, validationSchema} from './validation';

const salutations = getNumberKeysOption(Salutation).filter((item) => item.value !== Salutation[Salutation.Unknown]);

const FieldWrapper = styled.div`
  min-width: 100px;
  margin-right: 16px;
`;

interface IExternalProps {
  onSuccess?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const CreateUserForm = (props: AllProps) => {
  const {onSuccess, userCreating, setErrors, status, setStatus, isSubmitting} = props;

  const errorInfo = userCreating.error;

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    const fieldErrors = getFieldErrors(errorInfo);

    if (commonErrors) {
      setStatus(commonErrors);
    }

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  useOnSuccessCommunication(userCreating, () => onSuccess && onSuccess());

  const isLoading = isSubmitting || userCreating.isRequesting;

  return (
    <>
      <ModalWindowHeader>Add a new user</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          <div className="d-flex justify-content-between">
            <FieldWrapper className="w-25">
              <SelectField
                name="salutation"
                label="Salutation"
                placeholder="Salutation"
                options={salutations}
                isClearable={true}
              />
            </FieldWrapper>
            <div className="w-75">
              <InputField id="firstName" name="firstName" label="First name" placeholder="First name" isRequired />
            </div>
          </div>
          <InputField name="lastName" label="Last name" placeholder="Last name" isRequired />
          <InputField type="email" name="email" label="User email" placeholder="Email" isRequired autoComplete="off" />
          <PasswordField
            name="password"
            label="Password"
            placeholder="Password"
            isRequired
            autoComplete="new-password"
          />
          <InputField name="mobilePhone" label="Mobile number" placeholder="Mobile number" isRequired />
        </ModalWindowFormContent>

        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" isLoading={isLoading}>
            Save user
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const CreateUserFormWithFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    await formikBag.props.createUser(values);
    formikBag.setSubmitting(false);
  },
})(CreateUserForm);

const mapStateToProps = (state: IAppState) => ({
  userCreating: selectors.selectCommunication(state, 'userCreating'),
});

const mapDispatchToProps = {
  createUser: actions.createUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(CreateUserFormWithFormik), AdminUsersModule);
