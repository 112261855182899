import styled from 'styled-components';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

export const Title = styled.div`
  margin-top: 16px;
  font-family: ${Theme.font.secondary};
  font-size: ${Typography.size.size24};
  font-weight: ${Typography.weight.semiBold600};
  line-height: 32px;
  color: ${Theme.color.black};
`;

export const Description = styled.div`
  margin: 16px 0 32px;
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;
