import React, {memo} from 'react';
import styled from 'styled-components';

import {IProbabilityEntity} from 'Common/models/IProbabilityEntity';
import Icon, {IconName} from 'Icon/components/Icon';
import ProbabilityItem from 'Common/components/Probability/ProbabilityItem';

const Root = styled.div`
  margin-bottom: 8px;
`;

const DangerIconWrapper = styled.div`
  display: grid;
  grid-template-areas: 'danger full';
  grid-template-columns: 16px 16px;
  align-items: center;
  justify-items: center;

  top: -2px;
  left: -28px;
`;

const DangerIcon = styled.div`
  grid-area: danger;
`;

const FullInfoIcon = styled.div`
  grid-area: full;
`;

interface IProps {
  healthIssuesProbability: IProbabilityEntity[];
}

function HealthIssuesProbability(props: IProps) {
  const {healthIssuesProbability} = props;

  return (
    <>
      {healthIssuesProbability.map((value, index) => (
        <Root className="position-relative" key={index}>
          <DangerIconWrapper className="position-absolute">
            <DangerIcon>{value.isDanger && <Icon name={IconName.DangerIcon} height={16} width={13} />}</DangerIcon>
            <FullInfoIcon>
              {!value.isFullInfo && <Icon name={IconName.ExclamationMark} height={16} width={16} />}
            </FullInfoIcon>
          </DangerIconWrapper>
          <ProbabilityItem probability={value} higherIsBetter={false} alignValueRight={true} />
        </Root>
      ))}
    </>
  );
}

export default memo(HealthIssuesProbability);
