import {datadogLogs} from '@datadog/browser-logs';

export function useLogger(loggerName?: string) {
  if (loggerName) {
    const logger = datadogLogs.getLogger(loggerName);
    if (logger) {
      return {logger};
    }
  }

  return {logger: datadogLogs.logger};
}
