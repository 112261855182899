import {combineReducers} from 'redux';

import {
  AdminGeneGroupImageActions,
  deleteImageActionTypes,
  getImageActionTypes,
  IAdminGeneGroupImageState,
  uploadImageActionTypes
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';

export const communicationGeneGroupImageReducer = combineReducers<
  IAdminGeneGroupImageState['communications'],
  AdminGeneGroupImageActions
>({
  imageLoading: makeCommunicationReducerFromEnum(getImageActionTypes),
  imageUploading: makeCommunicationReducerFromEnum(uploadImageActionTypes),
  imageDeleting: makeCommunicationReducerFromEnum(deleteImageActionTypes)
});
