import {IModule} from 'redux-dynamic-modules';

import {IBusinessPortalOwnerState} from 'BusinessPortal/store/owner/types';
import {adminAssociationOwnerReducer} from 'Admin/AdminAssociations/store/adminOwners/reducer';

export interface IAdminAssociationOwnerModuleState {
  adminAssociationOwner: IBusinessPortalOwnerState;
}

export const AdminAssociationOwnerModule: IModule<IAdminAssociationOwnerModuleState> = {
  id: 'adminAssociationOwner',
  reducerMap: {
    adminAssociationOwner: adminAssociationOwnerReducer,
  },
};
