import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'BusinessPortal/store/horse';
import Loading from 'Loading/components/Loading';
import EditableSection from 'BusinessPortal/components/common/EditableSection/EditableSection';
import RegistrationAndParentageSectionEdit from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/RegistrationAndParentageSection/RegistrationAndParentageSectionEdit';
import RegistrationAndParentageSectionShow from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/RegistrationAndParentageSection/RegistrationAndParentageSectionShow';
import {IHorseSectionBP} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/models/IHorseSectionBP';

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IHorseSectionBP;

const RegistriesAndParentageSection = (props: AllProps) => {
  const {onSuccess} = props;
  const {
    horseId,
    horseRegistries,
    horseParentage,
    horseRegistriesLoading,
    horseParentageLoading,
    getHorseRegistries,
    getHorseParentage
  } = props;

  const mainRequest = useCallback(() => {
    getHorseRegistries(horseId);
    getHorseParentage(horseId);
  }, [getHorseParentage, getHorseRegistries, horseId]);

  useEffect(() => {
    mainRequest();
  }, [mainRequest]);

  const onSuccessEditHorse = useCallback(() => {
    mainRequest();
    onSuccess();
  }, [mainRequest, onSuccess]);

  const isRequesting = horseRegistriesLoading.isRequesting || horseParentageLoading.isRequesting;

  return (
    <div className="position-relative">
      {isRequesting && <Loading />}
      <EditableSection
        title="Registration and Parentage"
        data={{parentage: horseParentage, registries: horseRegistries, horseId}}
        onSuccess={onSuccessEditHorse}
        editComponent={RegistrationAndParentageSectionEdit}
        showComponent={RegistrationAndParentageSectionShow}
      />
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  horseRegistries: selectors.selectHorseRegistries(state),
  horseParentage: selectors.selectHorseParentage(state),
  horseRegistriesLoading: selectors.selectCommunication(state, 'horseRegistriesLoading'),
  horseParentageLoading: selectors.selectCommunication(state, 'horseParentageLoading')
});

const mapDispatchToProps = {
  getHorseRegistries: actions.getHorseRegistries,
  getHorseParentage: actions.getHorseParentage
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(RegistriesAndParentageSection));
