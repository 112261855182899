import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  getHorseBreedPanelsActions,
  getHorseBreedPanelsActionTypes,
  IHorseBreedPanelState,
  resetHorseBreedPanelsActions,
  resetHorseBreedPanelsActionTypes,
} from './types';
import BreedPanelDataService from 'BreedPanel/services/BreedPanelDataService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IAppState} from 'Common/store/IAppState';

export const getHorseBreedPanels =
  (horseId: number, orderId?: number): ActionResult<IAppState, void, getHorseBreedPanelsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseBreedPanelsActionTypes.REQUEST));
      const result = await BreedPanelDataService.getHorseBreedPanel(horseId, orderId);
      dispatch(action(getHorseBreedPanelsActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getHorseBreedPanelsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetHorseBreedPanelsResults =
  (): ActionResult<IHorseBreedPanelState, void, resetHorseBreedPanelsActions> => (dispatch) => {
    dispatch(action(resetHorseBreedPanelsActionTypes.RESET));
  };
