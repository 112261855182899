import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {getHorsesActions, getHorsesActionTypes, IHorseListState} from 'HorseProfile/store/horseList/types';
import HorseService from 'Horse/services/HorseDataService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IRequestParams} from 'Common/models/IRequestParams';

export const getHorses =
  (params: IRequestParams, id: number): ActionResult<IHorseListState, void, getHorsesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorsesActionTypes.REQUEST));
      const horseList = await HorseService.getHorses(params, id);
      dispatch(action(getHorsesActionTypes.SUCCESS, horseList));
    } catch (error) {
      dispatch(action(getHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
