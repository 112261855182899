import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IPaginationRequest} from 'Common/models/IPaginationRequest';
import {IListResponse} from 'Common/models/IResponse';
import {IBuildHorseRequest} from 'Filters/models/IBuildHorseRequest';
import {IFoal} from 'Common/models/IFoal';
import {IServerFoal} from 'BuildHorse/models/Foal';
import {convertFoalToClient} from './converters/foal';
import {convertBuildHorseRequestToServer} from './converters/buildHorseRequest';
import {VisitorType} from 'Common/constants/VisitorType';
import {ApiByType, getApiByVisitorType} from 'Common/helpers/getApiByVisitorType';

const getFoals = async (
  buildHorseRequest: IBuildHorseRequest,
  pagination: IPaginationRequest
): Promise<IListResponse<IFoal>> => {
  const apis: ApiByType = {
    [VisitorType.User]: `/BuildHorses`,
    [VisitorType.AssociationEmployee]: `/BuildHorsesAssociation`,
  };

  const api = getApiByVisitorType(apis);

  const response = await axiosWrapper.post<IListResponse<IServerFoal>>(api, {
    ...convertBuildHorseRequestToServer(buildHorseRequest),
    pagination,
  });

  const foals = response.data.data;
  return {...response.data, data: foals.map(convertFoalToClient)};
};

export default {
  getFoals,
};
