import {IModule} from 'redux-dynamic-modules';

import {imageReducer} from './reducer';
import {IImageState} from '.';

export interface IImageModuleState {
  image: IImageState;
}

export const ImageModule: IModule<IImageModuleState> = {
  id: 'image',
  reducerMap: {
    image: imageReducer
  }
};
