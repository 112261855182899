import React from 'react';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {FormikProps} from 'formik/dist/types';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {IFormValues, initialValue, validationSchema} from './validation';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {actions, selectors} from 'BusinessPortal/store/employeeProfile';
import {InputField} from 'Common/components/FormFields';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';

interface IProps {
  email?: string;
}

type IExternalProps = IEditableSection<IProps>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const ChangeEmailEdit = (props: AllProps) => {
  const {isValid, isSubmitting} = props;
  const {onSuccess, emailUpdating} = props;

  const isRequesting = emailUpdating.isRequesting;

  useOnSuccessCommunication(emailUpdating, () => onSuccess && onSuccess());
  useErrorCommunicationToToast(emailUpdating);

  if (isRequesting) {
    return <Loading />;
  }

  return (
    <Form>
      <InputField
        isRequired={true}
        name="email"
        type="email"
        label="Email"
        placeholder="Email"
        autoComplete="off"
        memoized={true}
      />

      <PrimaryButton className="w-100" size="medium" type="submit" disabled={!isValid} isLoading={isSubmitting}>
        Save changes
      </PrimaryButton>
    </Form>
  );
};

const GeneralInformationEditFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({data}) => (data?.email ? {email: data?.email} : initialValue),
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {updateEmployeeEmail} = formikBag.props;
    await updateEmployeeEmail(values.email || '');
  },
  enableReinitialize: true,
})(ChangeEmailEdit);

const mapStateToProps = (state: IAppState) => ({
  emailUpdating: selectors.selectCommunication(state, 'emailUpdating'),
});

const mapDispatchToProps = {
  updateEmployeeEmail: actions.updateEmployeeEmail,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(GeneralInformationEditFormik);
