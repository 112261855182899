import React, {memo} from 'react';
import styled from 'styled-components';

import Input, {InputProps} from 'Common/components/Controls/Input';
import {IFieldProps} from 'Common/models/IFieldProps';

import withField from './withField';
import ValidationError from './ValidationError';

const InputLayout = styled.div`
  position: relative;
`;

type Props = InputProps & IFieldProps<string | number>;

function InputField(props: Props) {
  const {form, field, ...rest} = props;
  const errorClass = form.touched[field.name] && form.errors[field.name] ? 'input-has-error' : '';

  return (
    <InputLayout>
      <Input inputClassName={errorClass} {...rest} {...field} />
      <ValidationError name={field.name} />
    </InputLayout>
  );
}

export default memo(withField(InputField));
export type InputFieldProps = Props;
