import {IListResponse, IPagination} from 'Common/models/IResponse';
import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {Nullable} from 'Common/types';
import {IAdminEmployee} from 'Admin/AdminAssociations/models/IAdminEmployee';
import {ILocation} from 'Common/models/ICoordinatedLocation';

const STATE_NAME = 'adminEmployee';

export interface IAdminEmployeeState {
  data: {
    adminEmployees: IAdminEmployee[];
    adminEmployee: Nullable<IAdminEmployee>;
    geoLocation: ILocation;
  };
  communications: {
    adminEmployeesLoading: ICommunication;
    adminEmployeeLoading: ICommunication;
    adminEmployeeCreating: ICommunication;
    adminEmployeeUpdating: ICommunication;
    adminEmployeeAvatarUploading: ICommunication;
    adminEmployeeAvatarDeleting: ICommunication;
    adminEmployeePasswordUpdating: ICommunication;
    adminEmployeeStatusUpdating: ICommunication;
    adminEmployeeEmailUpdating: ICommunication;
    adminEmployeeGeoLocationLoading: ICommunication;
    adminEmployeeGeoLocationUpdating: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getAdminEmployeesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ADMIN_EMPLOYEES');
export type getAdminEmployeesActions = MakeCommunicationActions<
  typeof getAdminEmployeesActionTypes,
  {success: IListResponse<IAdminEmployee>}
>;

export const getAdminEmployeeActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ADMIN_EMPLOYEE');
export type getAdminEmployeeActions = MakeCommunicationActions<
  typeof getAdminEmployeeActionTypes,
  {success: IAdminEmployee}
>;

export const createAdminEmployeeActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_ADMIN_EMPLOYEE');
export type createAdminEmployeeActions = MakeCommunicationActions<typeof createAdminEmployeeActionTypes, {}>;

export const updateAdminEmployeeActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_ADMIN_EMPLOYEE');
export type updateAdminEmployeeActions = MakeCommunicationActions<typeof updateAdminEmployeeActionTypes, {}>;

export const uploadAdminEmployeeAvatarActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPLOAD_ADMIN_EMPLOYEE_AVATAR'
);
export type uploadAdminEmployeeAvatarActions = MakeCommunicationActions<
  typeof uploadAdminEmployeeAvatarActionTypes,
  {}
>;

export const deleteAdminEmployeeAvatarActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DELETE_ADMIN_EMPLOYEE_AVATAR'
);
export type deleteAdminEmployeeAvatarActions = MakeCommunicationActions<
  typeof deleteAdminEmployeeAvatarActionTypes,
  {}
>;

export const updateAdminEmployeePasswordActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ADMIN_EMPLOYEE_PASSWORD'
);
export type updateAdminEmployeePasswordActions = MakeCommunicationActions<
  typeof updateAdminEmployeePasswordActionTypes,
  {}
>;

export const changeAdminEmployeeStatusActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CHANGE_ADMIN_EMPLOYEE_STATUS'
);
export type changeAdminEmployeeStatusActions = MakeCommunicationActions<
  typeof changeAdminEmployeeStatusActionTypes,
  {success: {employeeId: number}}
>;

export const updateAdminEmployeeEmailActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ADMIN_EMPLOYEE_EMAIL'
);
export type updateAdminEmployeeEmailActions = MakeCommunicationActions<typeof updateAdminEmployeeEmailActionTypes, {}>;

export const getAdminEmployeeGeoLocationActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ADMIN_EMPLOYEE_GEOLOCATION'
);
export type getAdminEmployeeGeoLocationActions = MakeCommunicationActions<
  typeof getAdminEmployeeGeoLocationActionTypes,
  {success: ILocation}
>;

export const updateAdminEmployeeGeoLocationActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ADMIN_EMPLOYEE_GEOLOCATION'
);
export type updateAdminEmployeeGeoLocationActions = MakeCommunicationActions<
  typeof updateAdminEmployeeGeoLocationActionTypes,
  {}
>;

export const resetAdminEmployeeActionTypes = makeResetCommunicationActionType(STATE_NAME, 'ADMIN_EMPLOYEE');
export type resetAdminEmployeeActions = MakeResetCommunicationActions<typeof resetAdminEmployeeActionTypes>;

export type AdminEmployeesActions =
  | getAdminEmployeesActions
  | getAdminEmployeeActions
  | createAdminEmployeeActions
  | updateAdminEmployeeActions
  | uploadAdminEmployeeAvatarActions
  | deleteAdminEmployeeAvatarActions
  | updateAdminEmployeePasswordActions
  | changeAdminEmployeeStatusActions
  | updateAdminEmployeeEmailActions
  | resetAdminEmployeeActions
  | getAdminEmployeeGeoLocationActions
  | updateAdminEmployeeGeoLocationActions;
