import {combineReducers, Reducer} from 'redux';

import {communicationHorseReducer} from './communications';
import {dataHorseReducer} from './data';
import {IAdminAssociationHorseProfileState} from '../types';

export const adminAssociationHorseProfileReducer: Reducer<IAdminAssociationHorseProfileState> =
  combineReducers<IAdminAssociationHorseProfileState>({
    communications: communicationHorseReducer,
    data: dataHorseReducer,
  });
