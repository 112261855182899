import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'BusinessPortal/store/employeeProfile';
import BusinessPortalLayout from 'BusinessPortal/components/common/BusinessPortalLayout/BusinessPortalLayout';
import {PageContainer, PageTitle} from '../common/styled';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {BusinessPortalEmployeeModule} from 'BusinessPortal/store/employeeProfile/businessPortalEmployeeProfileModule';
import GeoLocation from './parts/GeoLocation/GeoLocation';
import GeneralInformation from './parts/GeneralInformation/GeneralInformation';
import ChangeEmail from './parts/ChangeEmail/ChangeEmail';
import ChangePassword from './parts/ChangePassword/ChangePassword';

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

function BusinessPortalPersonalSettings(props: AllProps) {
  const {employeeProfile, employeeProfileLoading, getEmployeeProfile} = props;

  const mainRequest = useCallback(() => getEmployeeProfile(), [getEmployeeProfile]);

  useEffect(() => {
    mainRequest();
  }, [mainRequest]);

  const onSuccess = useCallback(() => mainRequest(), [mainRequest]);

  return (
    <BusinessPortalLayout>
      <PageTitle>Personal settings</PageTitle>

      <PageContainer className="h-100">
        <GeneralInformation
          employeeProfile={employeeProfile}
          employeeProfileLoading={employeeProfileLoading}
          onSuccess={onSuccess}
        />
        <ChangeEmail
          email={employeeProfile?.email}
          employeeProfileLoading={employeeProfileLoading}
          onSuccess={onSuccess}
        />
        <ChangePassword />

        <GeoLocation />
      </PageContainer>
    </BusinessPortalLayout>
  );
}
const mapStateToProps = (state: IAppState) => ({
  employeeProfile: selectors.selectEmployeeProfile(state),
  employeeProfileLoading: selectors.selectCommunication(state, 'employeeProfileLoading'),
});

const mapDispatchToProps = {
  getEmployeeProfile: actions.getEmployeeProfile,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(BusinessPortalPersonalSettings));

export default withDynamicModules(Connected, BusinessPortalEmployeeModule);
