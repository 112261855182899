import React, {useCallback} from 'react';
import styled from 'styled-components';

import ColoredIcon from 'Icon/components/ColoredIcon';
import {IOrderedMenuItem} from 'Common/components/DropDown/common/types';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {DropDownMenu} from 'Common/components/DropDown/DropDownMenu';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';

const MoreIcon = styled(ColoredIcon)`
  cursor: pointer;
`;

const desktopMenuItems: IOrderedMenuItem[] = [
  {
    value: '/account-details/personal-details',
    label: 'Edit profile',
    icon: {name: IconName.Edit, color: ColorPalette.red7},
    isMenuItemAsLink: true,
    id: 1,
  },
  {
    value: '/account-details/privacy-settings',
    label: 'Change visibility',
    icon: {name: IconName.Eye, color: ColorPalette.red7},
    isMenuItemAsLink: true,
    id: 2,
  },
  {
    value: '/account-details/subscriptions',
    label: 'Subscriptions',
    icon: {name: IconName.Edit, color: ColorPalette.red7},
    isMenuItemAsLink: true,
    id: 3,
  },
  {
    value: '/account-details/change-password',
    label: 'Change password',
    icon: {name: IconName.Lock, color: ColorPalette.red7},
    isMenuItemAsLink: true,
    id: 4,
  },
];
interface IActionsProps {}

export const ProfileInfoActions = React.memo((props: IActionsProps) => {
  const {isDesktop} = useMediaQuery();

  const availableMenuItems = isDesktop
    ? desktopMenuItems
    : desktopMenuItems.sort((a, b) => sortByStringKey(a, b, 'id'));

  const onSelectHandler = useCallback(() => {
    return;
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-end">
      <DropDownMenu items={availableMenuItems} onSelect={onSelectHandler} alignRight={true}>
        <MoreIcon
          name={IconName.SettingsContained}
          color={ColorPalette.red7}
          hoverColor={ColorPalette.red7}
          fill={true}
          stroke={false}
        />
      </DropDownMenu>
    </div>
  );
});
