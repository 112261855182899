import {combineReducers} from 'redux';

import {
  createStaticResourceActionTypes,
  deleteStaticResourceActionTypes,
  getStaticResourceActionTypes,
  getStaticResourcesActionTypes,
  IAdminStaticResourcesState,
  resetStaticResourceActionTypes,
  StaticResourcesActions,
  updateStaticResourceActionTypes,
  uploadStaticResourceFileActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication/index';
import composeReducers from 'Common/store/composeReducers';

export const communicationStaticResourcesReducer = combineReducers<
  IAdminStaticResourcesState['communications'],
  StaticResourcesActions
>({
  staticResourcesLoading: makeCommunicationReducerFromEnum(getStaticResourcesActionTypes),
  staticResourceUpdating: makeCommunicationReducerFromEnum(updateStaticResourceActionTypes),
  staticResourceCreating: makeCommunicationReducerFromEnum(createStaticResourceActionTypes),
  staticResourceFileUploading: makeCommunicationReducerFromEnum(uploadStaticResourceFileActionTypes),
  staticResourceDeleting: makeCommunicationReducerFromEnum(deleteStaticResourceActionTypes),
  staticResourceLoading: composeReducers([
    makeCommunicationReducerFromEnum(getStaticResourceActionTypes),
    makeResetCommunicationReducer(resetStaticResourceActionTypes.RESET),
  ]),
});
