import {IModule} from 'redux-dynamic-modules';

import {staticResourcesReducer} from './reducer/index';
import {IAdminStaticResourcesState} from './types';

export interface IAdminStaticResourcesModuleState {
  adminStaticResources: IAdminStaticResourcesState;
}

export const AdminStaticResourcesModule: IModule<IAdminStaticResourcesModuleState> = {
  id: 'adminStaticResources',
  reducerMap: {
    adminStaticResources: staticResourcesReducer
  }
};
