import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {NotificationCode} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdminNotificationType';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {
  IAdministrator,
  ICreateAdministrator,
  IUpdateAdministrator,
} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdministrator';
import {IImage} from 'Image/models/IImage';
import {Nullable} from 'Common/types';
import {IAvatared} from 'Common/models/IAvatar';

export interface IFormValues extends IAvatared {
  id?: IEntity['id'];
  name: string;
  phone: Nullable<string>;
  email?: string;
  notifications?: NotificationCode[];
  isActive?: boolean;
  password?: string;
}

export const initialValue: IFormValues = {
  name: '',
  email: '',
  password: '',
  phone: null,
  isActive: false,
  notifications: [],
  avatar: null,
};

export const createValidationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  email: Yup.string().required(REQUIRED_FIELD),
  password: Yup.string().required(REQUIRED_FIELD),
  phone: Yup.string().nullable(),
  isActive: Yup.boolean().required(REQUIRED_FIELD),
  avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
});

export const updateValidationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  phone: Yup.string().nullable(),
  avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
});

export const convertToClient = (values: IAdministrator): IFormValues => {
  return {
    ...values,
    phone: values.phone,
    notifications: values?.notifications?.map((i) => i.name),
  };
};

export const convertToServer = (values: IFormValues): ICreateAdministrator => {
  return {
    ...values,
    isActive: values.isActive!,
    email: values.email!,
    notifications: values.notifications!,
    password: values.password!,
  };
};

export const convertToServerUpdating = (values: IFormValues): IUpdateAdministrator => {
  return {
    ...values,
    id: values.id!,
  };
};
