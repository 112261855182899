import * as Yup from 'yup';

import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues {
  email: string;
}

export const initialValue: IFormValues = {
  email: '',
};

export const validationSchema = Yup.object().shape<IFormValues>({
  email: Yup.string().email('Email not valid').required(REQUIRED_FIELD),
});
