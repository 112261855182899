import {Reducer} from 'redux';

import {
  getUserPrintableReportPreferencesActionTypes,
  IUserPrintableReportPreferencesState,
  UserPrintableReportPreferencesActions,
} from '../types';

const initialState: IUserPrintableReportPreferencesState['data'] = {
  userPrintableReportPreferences: null,
};

export const dataUserPrintableReportPreferencesReducer: Reducer<
  IUserPrintableReportPreferencesState['data'],
  UserPrintableReportPreferencesActions
> = (state = initialState, action: UserPrintableReportPreferencesActions) => {
  switch (action.type) {
    case getUserPrintableReportPreferencesActionTypes.REQUEST: {
      return {...state, userPrintableReportPreferences: initialState.userPrintableReportPreferences};
    }
    case getUserPrintableReportPreferencesActionTypes.SUCCESS: {
      return {...state, userPrintableReportPreferences: action.payload};
    }
    default: {
      return state;
    }
  }
};
