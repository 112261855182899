import {combineReducers} from 'redux';

import {IAdministratorState} from '../types';
import {dataAdministratorsReducer} from './data';
import {communicationAdministratorsReducer} from './communications';
import {uiAdministratorsReducer} from './ui';

export const administratorsReducer = combineReducers<IAdministratorState>({
  communications: communicationAdministratorsReducer,
  data: dataAdministratorsReducer,
  ui: uiAdministratorsReducer
});
