import React, {memo} from 'react';
import {useHistory} from 'react-router';
import styled from 'styled-components';

import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {DropDownMenu} from 'Common/components/DropDown';
import {Permission} from 'Permissions/constants/Permission';
import {getHeadersNavMenu, getUserMenuLinks} from 'Common/components/Header/common';
import AuthService from 'Auth/services/AuthService';
import {breakpoints} from 'Common/constants/Breakpoints';
import {Nullable} from 'Common/types';
import usePermissions from 'Permissions/hooks/usePermissions';
import {VisitorType} from 'Common/constants/VisitorType';

const MainMenu = styled.div`
  cursor: pointer;
  color: ${ColorPalette.white0};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size13};
  margin-right: 20px;
`;

const UserAvatar = styled(Avatar)`
  margin-left: 13px;
`;

const UserName = styled.div`
  display: none;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media ${breakpoints.md} {
    display: block;
  }
`;

interface INavMenuVisitor {
  name: string;
  avatar?: Nullable<string>;
}

interface IExternalProps {
  visitor: INavMenuVisitor;
  userPermissions: Permission[] | string[];
  currentUserId?: number;
}

function NavMenu(props: IExternalProps) {
  const {visitor, userPermissions, currentUserId} = props;
  const history = useHistory();

  const {isDevelopmentAdmin, isVisitorType} = usePermissions();

  const onSelectOption = React.useCallback(
    (value: string) => {
      if (value === 'logout') {
        AuthService.logout(true);
        return;
      }

      history.push(`${value}`);
    },
    [history]
  );

  const isUser = isVisitorType(VisitorType.User);

  const links = getHeadersNavMenu(userPermissions, isDevelopmentAdmin)
    .concat(isUser ? getUserMenuLinks(currentUserId) : [])
    .concat([
      {
        label: 'Log out',
        value: 'logout',
        divided: true,
        style: {color: ColorPalette.gray48, hoverColor: ColorPalette.red9, activeColor: ColorPalette.red11},
      },
    ]);

  return (
    <div className="nav d-flex flex-row align-items-center">
      <DropDownMenu items={links} onSelect={onSelectOption} alignRight={true}>
        <MainMenu className="d-flex align-items-center">
          <UserName>{visitor.name}</UserName>
          <UserAvatar
            avatarSize={AvatarSize.Custom}
            customSizeAvatar={28}
            customSizeIcon={16}
            defaultAvatarProfile={DefaultAvatarProfile.User}
            url={visitor.avatar}
          />
        </MainMenu>
      </DropDownMenu>
    </div>
  );
}

export default memo(NavMenu);
