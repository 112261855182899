import styled from 'styled-components';

import {breakpoints} from 'Common/constants/Breakpoints';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

export const GenotypesContainerBase = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  > :focus {
    outline: none;
  }
`;

export const GenotypeLabel = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.gray};
  margin-bottom: 8px;
`;

export const GenotypeValue = styled(GenotypesContainerBase)`
  margin-bottom: 12px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
  white-space: pre-wrap;
`;

export const Divider = styled.div`
  width: 100%;
  height: 3px;
  margin: 16px 0;
  background: radial-gradient(50% 550% at 50% 50%, #f4f5f7 80%, rgba(244, 245, 247, 0) 100%);

  @media ${breakpoints.sm} {
    margin: 16px 0;
  }

  @media ${breakpoints.md} {
    margin: 32px 0;
  }
`;
