import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import IElementPosition, {ElementPosition} from 'FoalProfile/models/IElementPosition';
import AbilitySegment, {SegmentFilling, IAbilitySegmentProps} from 'Common/components/AbilityIndicator/AbilitySegment';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {IFeatureIndicatorValue} from 'FoalProfile/models/IFeatureIndicatorValue';
import {SegmentPosition} from 'Common/constants/SegmentPosition';
import Tooltip from 'Common/components/Tooltip/Tooltip';

const IndicatorLayout = styled.div`
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const IndicatorTitle = styled.div`
  font-size: ${Typography.size.size13};
  color: ${ColorPalette.gray3};
`;

const Segments = styled.div<IElementPosition>`
  ${prop => (prop.position === ElementPosition.Left ? 'margin-left: 16px' : 'margin-right: 16px')};
  height: 100%;
`;

const SegmentValue = styled.div`
  font-size: ${Typography.size.size10};
  line-height: 10px;
  color: ${ColorPalette.gray9};
  left: 50%;
  transform: translateX(-50%);
  top: -6px;
`;

const SegmentTitle = styled.div`
  font-size: ${Typography.size.size8};
  line-height: 10px;
  color: ${ColorPalette.gray9};
  left: 50%;
  transform: translateX(-50%);
  bottom: -4px;
  width: 100%;
  text-align: center;
  top: 18px;
`;

interface IProps {
  values: IFeatureIndicatorValue;
  elementPosition: ElementPosition;
  title: string;
  minValueText: string;
  middleValueText: string;
  maxValueText: string;
}

function getStyle(value: number): React.CSSProperties | undefined {
  if (value === 25) {
    return {opacity: 0.25};
  }
  if (value === 50) {
    return {opacity: 0.5};
  }
  return undefined;
}

function getFilled(value: number): SegmentFilling {
  return value === 0 ? SegmentFilling.EmptyLight : SegmentFilling.Filled;
}

const FeatureIndicator = (props: IProps) => {
  const titleOrder = props.elementPosition === ElementPosition.Left ? 'order-1' : 'order-2';
  const segmentsOrder = props.elementPosition === ElementPosition.Left ? 'order-2' : 'order-1';
  const indicatorPosition =
    props.elementPosition === ElementPosition.Left ? 'justify-content-end' : 'justify-content-start';

  const segment1: IAbilitySegmentProps = {
    segmentPosition: SegmentPosition.Left,
    segmentFilled: getFilled(props.values.Low),
    style: {...getStyle(props.values.Low), marginRight: -2}
  };

  const segment2: IAbilitySegmentProps = {
    segmentPosition: SegmentPosition.Center,
    segmentFilled: getFilled(props.values.Medium),
    style: {...getStyle(props.values.Medium), marginLeft: -2, marginRight: -2}
  };

  const segment3: IAbilitySegmentProps = {
    segmentPosition: SegmentPosition.Right,
    segmentFilled: getFilled(props.values.High),
    style: {...getStyle(props.values.High), marginLeft: -2}
  };

  return (
    <IndicatorLayout className={classNames('d-flex', 'align-items-center', indicatorPosition)}>
      <IndicatorTitle className={titleOrder}>{props.title}</IndicatorTitle>
      <Segments className={classNames(segmentsOrder, 'd-flex')} position={props.elementPosition}>
        <Tooltip content={props.values.LowDescription}>
          <div className="position-relative">
            <SegmentValue className="position-absolute">{`${props.values.Low}%`}</SegmentValue>
            <AbilitySegment {...segment1} />
            <SegmentTitle className="position-absolute">{props.minValueText}</SegmentTitle>
          </div>
        </Tooltip>
        <Tooltip content={props.values.MediumDescription}>
          <div className="position-relative">
            <SegmentValue className="position-absolute">{`${props.values.Medium}%`}</SegmentValue>
            <AbilitySegment {...segment2} />
            <SegmentTitle className="position-absolute">{props.middleValueText}</SegmentTitle>
          </div>
        </Tooltip>
        <Tooltip content={props.values.HighDescription}>
          <div className="position-relative">
            <SegmentValue className="position-absolute">{`${props.values.High}%`}</SegmentValue>
            <AbilitySegment {...segment3} />
            <SegmentTitle className="position-absolute">{props.maxValueText}</SegmentTitle>
          </div>
        </Tooltip>
      </Segments>
    </IndicatorLayout>
  );
};

export default FeatureIndicator;
