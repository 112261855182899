import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IUserHorse} from 'Horse/models/IUserHorse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IServerUserHorse} from 'Horse/services/types/horse';
import {convertServerUserHorse} from 'Horse/services/converters/horse';
import IFoundExcludeHorse from 'ExcludeHorse/models/IFoundExcludeHorse';
import {convertServerSimpleHorse} from './converters/excludeHorse';
import {VisitorType} from 'Common/constants/VisitorType';
import {getApiByVisitorType} from 'Common/helpers/getApiByVisitorType';

const getExcludeHorses = async (paginationParams: IRequestParams): Promise<IListResponse<IUserHorse>> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/ExcludedHorses`,
    [VisitorType.AssociationEmployee]: `/AssociationExcludedHorses`,
  });

  const response = await axiosWrapper.get<IListResponse<IServerUserHorse>>(api, {
    params: {...paginationParams},
  });

  return {...response.data, data: response.data.data.map(convertServerUserHorse)};
};

const getSimpleExcludeHorses = async (): Promise<IListResponse<IFoundExcludeHorse>> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/ExcludedHorsesSimple`,
    [VisitorType.AssociationEmployee]: `/ExcludedHorsesListAssociation`,
  });

  const response = await axiosWrapper.get<IListResponse<IFoundExcludeHorse>>(api);
  return {...response.data, data: response.data.data.map(convertServerSimpleHorse)};
};

const addExcludeHorse = async (horseId: number): Promise<void> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/ExcludedHorsesAdd`,
    [VisitorType.AssociationEmployee]: `/AssociationExcludedHorsesAdd`,
  });

  await axiosWrapper.post<IObjectResponse<null>>(`${api}/${horseId}`);
};

const deleteExcludeHorse = async (horseId: number): Promise<void> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/ExcludedHorsesRemove`,
    [VisitorType.AssociationEmployee]: `/AssociationExcludedHorsesDelete`,
  });

  await axiosWrapper.delete<IObjectResponse<null>>(`${api}/${horseId}`);
};

export default {
  getExcludeHorses,
  addExcludeHorse,
  deleteExcludeHorse,
  getSimpleExcludeHorses,
};
