import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';

import {Grid, SearchBar} from 'Admin/AdminDashboard/components/shared/index';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import ReportGridItem from './ReportGridItem';
import Report from 'Admin/AdminDashboard/components/Reports/Report';

const GridWrapper = styled.div`
  margin-top: 42px;
`;

const Reports = () => {
  const gridReports = useCallback(() => {
    return [
      {
        title: 'Demographics Report',
        description: 'Download demographics report file in .xlsx format.',
        content: <Report reportType="Demographics" />
      },
      {
        title: 'Paid Orders',
        description: 'Download Paid Orders report file in .xlsx format.',
        content: <Report reportType="PaidOrders" />
      },
      {
        title: 'Orders Report',
        description: 'Download Orders report file in .xlsx format.',
        content: <Report reportType="Orders" />
      },
      {
        title: 'All Reports',
        description: 'Download all reports as separate files in .xlsx format for a certain period of time.',
        content: <Report reportType="All" />
      }
    ];
  }, []);

  const [searchQuery, setSearchQuery] = useState('');
  const [reports, setReports] = useState(gridReports());

  const handleChangeFilter = useCallback(
    value => {
      if (value === '') {
        setSearchQuery(value);
        setReports(gridReports());
        return;
      }

      const filteredReports = gridReports().filter(
        item =>
          item.description.toLowerCase().includes(value.toLowerCase()) ||
          item.title.toLowerCase().includes(value.toLowerCase())
      );

      setSearchQuery(value);
      setReports(filteredReports);
    },
    [gridReports]
  );

  return (
    <AdminPageLayout>
      <SearchBar onChange={handleChangeFilter} value={searchQuery} placeholder="Search for reports by any keyword" />

      <GridWrapper>
        <Grid
          items={reports.map(item => (
            <ReportGridItem {...item} />
          ))}
        />
      </GridWrapper>
    </AdminPageLayout>
  );
};

export default memo(Reports);
