import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
} from 'Common/store/utils/communication/index';

import {IImage} from 'Image/models/IImage';
import {Nullable} from 'Common/types';

const STATE_NAME = 'adminGeneGroupImage';

export interface IAdminGeneGroupImageState {
  data: {
    image: Nullable<IImage>;
  };
  communications: {
    imageLoading: ICommunication;
    imageUploading: ICommunication;
    imageDeleting: ICommunication;
  };
}

export const getImageActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_IMAGE');

export const uploadImageActionTypes = makeCommunicationActionType(STATE_NAME, 'UPLOAD_IMAGE');

export const deleteImageActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_IMAGE');

export type getImageActions = MakeCommunicationActions<typeof getImageActionTypes, {success: IImage}>;

export type uploadImageActions = MakeCommunicationActions<typeof uploadImageActionTypes, {success: number}>;

export type deleteImageActions = MakeCommunicationActions<typeof deleteImageActionTypes, {}>;

export type AdminGeneGroupImageActions = getImageActions | uploadImageActions | deleteImageActions;
