import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Location} from 'history';
import styled from 'styled-components';
import {Form, FormikBag, FormikProps, withFormik} from 'formik';
import {useHistory, useLocation} from 'react-router-dom';

import {actions as authActions, selectors} from 'Auth/store';
import {InputField} from 'Common/components/FormFields';
import ColorPalette from 'Common/constants/ColorPalette';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {IAppState} from 'Common/store/IAppState';
import {actions as userActions, selectors as userSelectors} from 'UserProfile/store/currentUser';
import {actions as permissionsAction, selectors as permissionsSelectors} from 'Permissions/store';
import {actions as adminPermissionsAction, selectors as adminPermissionsSelector} from 'Admin/common/Permissions/store';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {IFormValues, initialValues, validationSchema} from './validation';
import PasswordField from 'Common/components/FormFields/PasswordField';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {isEmailNotConfirmed} from 'MailConfirmation/helpers/mailConfirmationErrors';
import Typography from 'Common/constants/Typography';
import StartPageLayout from 'Common/components/StartPageLayout/StartPageLayout';
import {
  Button,
  SignUpLink,
  StartPageLink,
  Text,
  Title,
} from 'Common/components/StartPageLayout/shared/StyledComponents';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useToast} from 'Common/helpers/hooks/useToast';
import {VisitorType} from 'Common/constants/VisitorType';
import {ILocationState} from 'Common/models/ILocationState';

const FormLayout = styled.div`
  height: 100%;
  width: 95%;
  max-width: 500px;
  padding: 0 9px;

  align-self: start;

  @media ${breakpoints.sm} {
    height: auto;
    padding: 0;
  }

  @media ${breakpoints.md} {
    align-self: center;
  }
`;

const ForgotPasswordWrapper = styled.div`
  justify-content: center;

  @media ${breakpoints.sm} {
    justify-content: flex-end;
  }
`;

const ForgotPasswordLink = styled(StartPageLink)`
  font-size: ${Typography.size.size16};
  line-height: 24px;
`;

const MissingAccountMobileText = styled(Text)`
  font-size: ${Typography.size.size16};
  line-height: 24px;
  margin-bottom: 4px;
  justify-content: center;
`;

const SubmitButton = styled(Button)`
  width: 100%;

  @media ${breakpoints.sm} {
    width: 240px;
  }
`;

const ButtonsFooter = styled.div`
  flex-direction: column-reverse;
  align-items: center;

  @media ${breakpoints.sm} {
    flex-direction: column;
    align-items: start;
  }
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = FormikProps<IFormValues> & IConnected;

function LoginForm(props: AllProps) {
  const {
    setStatus,
    setErrors,
    getCurrentPermissions,
    getCurrentUser,
    getCurrentAdmin,
    getCurrentAdminPermissions,
    getCurrentAssociationEmployee,
    authenticating,
    currentUserLoading,
    visitorTypeLoading,
    currentAdminLoading,
    currentAssociationEmployeeLoading,
    currentPermissionsLoading,
    currentAdminPermissionsLoading,
    visitorType,
    values,
    isSubmitting,
  } = props;
  const history = useHistory();
  const location: Location<ILocationState> = useLocation();

  const [isAuthFinished, setIsAuthFinished] = useState(false);
  const {isDesktop, isMobile} = useMediaQuery();

  const authError = authenticating.error;
  const initializeUserError = isAuthFinished ? currentUserLoading.error || currentPermissionsLoading.error : undefined;
  const initializeAdminError = isAuthFinished
    ? currentAdminLoading.error || currentAdminPermissionsLoading.error
    : undefined;
  const initializeAssociationEmployeeError = isAuthFinished ? currentAssociationEmployeeLoading.error : undefined;
  const errors = authError || initializeUserError || initializeAdminError || initializeAssociationEmployeeError;
  const error = getCommonErrors(errors);

  useEffect(() => {
    const fieldErrors = getFieldErrors(errors);

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errors]);

  const {addToast} = useToast();

  const postAuthRequests = useCallback(async () => {
    if (visitorType === null) {
      return;
    }

    switch (visitorType.type) {
      case VisitorType.Admin:
        await Promise.all([getCurrentAdmin(), getCurrentAdminPermissions()]);
        break;
      case VisitorType.User:
        await Promise.all([getCurrentPermissions(), getCurrentUser()]);
        break;
      case VisitorType.AssociationEmployee:
        await getCurrentAssociationEmployee();
        break;
    }

    setIsAuthFinished(true);
  }, [
    getCurrentAdmin,
    getCurrentAdminPermissions,
    getCurrentPermissions,
    getCurrentUser,
    getCurrentAssociationEmployee,
    visitorType,
  ]);

  useOnSuccessCommunication(visitorTypeLoading, postAuthRequests);

  useOnErrorCommunication(authenticating, () => {
    if (authenticating.error && isEmailNotConfirmed(authenticating.error)) {
      history.push(`/email-not-confirmed/${values.userName}`);
      return;
    }
    if (error) {
      addToast(error, 'error');
    }
  });

  useEffect(() => {
    if (
      isAuthFinished &&
      ((currentUserLoading.isSuccess && currentPermissionsLoading.isSuccess) ||
        (currentAdminLoading.isSuccess && currentAdminPermissionsLoading.isSuccess) ||
        currentAssociationEmployeeLoading.isSuccess)
    ) {
      setIsAuthFinished(false);
      let pathAfterAuth = '/';

      switch (visitorType?.type) {
        case VisitorType.Admin:
          pathAfterAuth = '/admin';
          break;
        case VisitorType.AssociationEmployee:
          pathAfterAuth = '/business-portal/dashboard';
          break;
        case VisitorType.User:
          pathAfterAuth = '/';
          break;
        default:
          pathAfterAuth = '/';
          break;
      }

      const {from} = location.state || {from: {pathname: pathAfterAuth}};
      history.replace(from);
    }
  }, [
    isAuthFinished,
    currentUserLoading.isSuccess,
    currentPermissionsLoading.isSuccess,
    history,
    location,
    currentAdminLoading.isSuccess,
    visitorType,
    currentAdminPermissionsLoading.isSuccess,
    currentAssociationEmployeeLoading.isSuccess,
  ]);

  const isLoading =
    authenticating.isRequesting ||
    currentUserLoading.isRequesting ||
    currentPermissionsLoading.isRequesting ||
    currentAdminPermissionsLoading.isRequesting ||
    currentAdminLoading.isRequesting ||
    currentAssociationEmployeeLoading.isRequesting ||
    isSubmitting;

  const missingAccountLink = useMemo(() => {
    const Root = isMobile ? MissingAccountMobileText : Text;

    return (
      <Root className="d-flex align-items-center">
        <>Don’t have an account?</>
        <SignUpLink color={ColorPalette.red7} to="/signup">
          Sign up
        </SignUpLink>
      </Root>
    );
  }, [isMobile]);

  return (
    <StartPageLayout isDesktop={isDesktop} isMobile={isMobile}>
      <FormLayout className="d-flex flex-column">
        <Title>
          Log in to <strong>Etalon!</strong>
        </Title>
        {!isMobile && missingAccountLink}
        <div className="d-flex flex-column justify-content-between flex-grow-1">
          <Form>
            <InputField type="email" name="userName" label="Email" placeholder="Email" disabled={isLoading} />
            <PasswordField name="password" label="Password" placeholder="Password" disabled={isLoading} />

            <ButtonsFooter className="d-flex w-100">
              {/* <CheckboxField name="rememberMe" label="Remember me" /> */}
              <ForgotPasswordWrapper className="d-flex w-100">
                <ForgotPasswordLink className="nav-link" color={ColorPalette.red7} to="/forgot-password">
                  Forgot password?
                </ForgotPasswordLink>
              </ForgotPasswordWrapper>
              <SubmitButton type="submit" isLoading={isLoading}>
                Log In
              </SubmitButton>
            </ButtonsFooter>
          </Form>

          {isMobile && missingAccountLink}
        </div>
      </FormLayout>
    </StartPageLayout>
  );
}

const handleSubmit = async (values: IFormValues, bag: FormikBag<AllProps, IFormValues>) => {
  await bag.props.login(values);
  await bag.props.getVisitorType(values.userName);
};

const LoginFormFormik = withFormik<AllProps, IFormValues>({
  mapPropsToValues: () => initialValues,
  validationSchema,
  handleSubmit,
})(LoginForm);
const mapDispatchToProps = {
  login: authActions.login,
  getVisitorType: authActions.getVisitorType,
  getCurrentPermissions: permissionsAction.getCurrentPermissions,
  getCurrentUser: userActions.getCurrentUser,
  getCurrentAdmin: userActions.getCurrentAdmin,
  getCurrentAdminPermissions: adminPermissionsAction.getCurrentAdminPermissions,
  getCurrentAssociationEmployee: userActions.getCurrentAssociationEmployee,
};

const mapStateToProps = (state: IAppState) => ({
  visitorType: selectors.selectVisitorType(state),
  authenticating: selectors.selectCommunication(state, 'authenticating'),
  visitorTypeLoading: selectors.selectCommunication(state, 'visitorTypeLoading'),
  currentUserLoading: userSelectors.selectCommunication(state, 'currentUserLoading'),
  currentAdminLoading: userSelectors.selectCommunication(state, 'currentAdminLoading'),
  currentAssociationEmployeeLoading: userSelectors.selectCommunication(state, 'currentAssociationEmployeeLoading'),
  currentPermissionsLoading: permissionsSelectors.selectCommunication(state, 'currentPermissionsLoading'),
  currentAdminPermissionsLoading: adminPermissionsSelector.selectCommunication(state, 'currentAdminPermissionsLoading'),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(LoginFormFormik);
