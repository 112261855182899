import {action} from 'typesafe-actions';
import {IResetPasswordState, resetPasswordActions, resetPasswordActionTypes} from 'ResetPassword/store/types';
import {ActionResult} from 'Common/store/store';
import {IResetPassword} from 'ResetPassword/models/IResetPassword';
import AuthService from 'Auth/services/AuthService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const resetPassword =
  (data: IResetPassword): ActionResult<IResetPasswordState, void, resetPasswordActions> =>
  async (dispatch) => {
    dispatch(action(resetPasswordActionTypes.REQUEST));

    await AuthService.resetPassword(data)
      .then((res) => {
        dispatch(action(resetPasswordActionTypes.SUCCESS, res.data.data));
      })
      .catch((error) => {
        dispatch(action(resetPasswordActionTypes.FAILURE, getErrorMessage(error)));
      });
  };
