import {AxiosPromise} from 'axios';

import {IUser} from 'UserProfile/models/IUser';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IObjectResponse} from 'Common/models/IResponse';
import {ISignupForm} from 'Signup/models/ISignupForm';
import {IChangePassword} from 'UserProfile/models/IChangePassword';
import {convertClientUserRequestToServer} from './converter/UserConverter';
import {IUserUpdateRequest} from './serverModels/IUserUpdateRequest';
import {IUserPrivacySettings} from 'UserProfile/models/IUserPrivacySettings';
import {IContactRequest} from 'UserProfile/models/IContactRequest';
import {ProfileType} from 'Common/constants/ProfileType';
import {ICurrentUserProfileType} from 'UserProfile/store/currentUser/types';
import {IAdmin} from 'UserProfile/models/IAdmin';
import {isAdminVisitor} from 'Common/helpers/isAdminVisitor';
import {IAssociationEmployee} from 'UserProfile/models/IAssociationEmployee';
import {ApiByType, getApiByVisitorType} from 'Common/helpers/getApiByVisitorType';
import {VisitorType} from 'Common/constants/VisitorType';
import {withAvatar} from 'Common/helpers/withAvatar';

export type SettingType = 'Email' | 'Location' | 'Phone';

const getCurrentUser = async (): Promise<IUser> => {
  const response = await axiosWrapper.get<IObjectResponse<IUser>>(`/UsersCurrent`);
  return withAvatar(response.data.data);
};

const getCurrentAdmin = async (): Promise<IAdmin> => {
  const response = await axiosWrapper.get<IObjectResponse<IAdmin>>(`/CurrentAdmin`);
  return withAvatar(response.data.data);
};

const getCurrentAssociationEmployee = async (): Promise<IAssociationEmployee> => {
  const response = await axiosWrapper.get<IObjectResponse<IAssociationEmployee>>(`/AssociationEmployee`);
  return withAvatar(response.data.data);
};

const getUser = async (id: number): Promise<IObjectResponse<IUser>> => {
  const response = await axiosWrapper.get<IObjectResponse<IUser>>(
    (isAdminVisitor() ? `/UserProfileAdmin` : `/UsersProfile`) + `/${id}`
  );
  return {...response.data, data: withAvatar(response.data.data)};
};

const confirmAgreement = async (isAgree: boolean, sign: string): Promise<void> => {
  const apis: ApiByType = {
    [VisitorType.User]: `/UsersGeneralAgreementUpdate`,
    [VisitorType.AssociationEmployee]: `/AssociationEmployeeAgreement`,
  };

  const api = getApiByVisitorType(apis);

  await axiosWrapper.post<IObjectResponse<void>>(api, {isAgree, sign});
};

const signup = async (data: ISignupForm): Promise<string> => {
  const response = await axiosWrapper.post<IObjectResponse<string>>(`/Registration`, data);
  return response.data.data;
};

const changePassword = (data: IChangePassword): AxiosPromise<IObjectResponse<string>> => {
  return axiosWrapper.post<IObjectResponse<string>>(`/UsersPasswordUpdate`, data);
};

const updateUserInfo = (data: IUserUpdateRequest): AxiosPromise<IObjectResponse<IUser>> => {
  return axiosWrapper.put(`/UsersProfileUpdate`, data, {
    transformRequest: convertClientUserRequestToServer,
  });
};

const getPrivacySettings = (): AxiosPromise<IObjectResponse<IUserPrivacySettings>> => {
  return axiosWrapper.get<IObjectResponse<IUserPrivacySettings>>(`/UsersPrivacySettings`);
};

const updatePrivacySettings = (isPublic: boolean, settings: SettingType): AxiosPromise<IObjectResponse<void>> => {
  const urls: Record<SettingType, string> = {
    Email: '/UsersEmailPrivacyUpdate',
    Location: '/UsersLocationPrivacyUpdate',
    Phone: '/UsersPhonePrivacyUpdate',
  };
  return axiosWrapper.put<IObjectResponse<void>>(urls[settings], isPublic);
};

const getUserProfileType = async (): Promise<ICurrentUserProfileType> => {
  const response = await axiosWrapper.get<IObjectResponse<ICurrentUserProfileType>>(`/UsersGlobalPrivacySettings/`);
  return response.data.data;
};

const updateUserProfileType = async (isPublic: boolean): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<ProfileType>>(`/UsersGlobalPrivacySettingsUpdate/`, {isPublic});
};

const sendContactRequest = (data: IContactRequest): AxiosPromise<IObjectResponse<void>> => {
  const apis: ApiByType = {
    [VisitorType.User]: `/UserContactRequest`,
    [VisitorType.AssociationEmployee]: `/UserContactRequestAssociation`,
  };

  const api = getApiByVisitorType(apis);

  return axiosWrapper.post<IObjectResponse<void>>(api, data);
};

export default {
  getCurrentUser,
  getCurrentAdmin,
  getCurrentAssociationEmployee,
  getUser,
  confirmAgreement,
  updateUserInfo,
  signup,
  changePassword,
  getPrivacySettings,
  updatePrivacySettings,
  sendContactRequest,
  getUserProfileType,
  updateUserProfileType,
};
