import {IModule} from 'redux-dynamic-modules';

import {IAdminUsersState} from './types';
import {usersReducer} from './reducer/index';

export interface IAdminUsersModuleState {
  adminUsers: IAdminUsersState;
}

export const AdminUsersModule: IModule<IAdminUsersModuleState> = {
  id: 'adminUsers',
  reducerMap: {
    adminUsers: usersReducer,
  },
};
