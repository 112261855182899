import {IRequestParams} from 'Common/models/IRequestParams';
import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';
import {OrderingType} from './OrderingType';

export const requestParamsBlank: Array<keyof IRequestParams> = [
  'hasGenSequence',
  'orderBy',
  'orderingType',
  'countPerPage',
  'currentPage',
  'filter',
  'filters',
  'selectedId',
];

export const defaultRequestParams: IRequestParams = {
  countPerPage: MIN_COUNT_PER_PAGE,
  currentPage: 1,
};

export const defaultSortingParams: Pick<IRequestParams, 'orderBy' | 'orderingType'> = {
  orderBy: 'id',
  orderingType: OrderingType.Descending,
};
