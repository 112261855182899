import {combineReducers} from 'redux';

import {
  Actions,
  getPaymentIntentIdActionTypes,
  IOrderPaymentState,
  getOrderSummaryActionTypes,
  getPayPalOrderActionTypes
} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

export const communicationReducer = combineReducers<IOrderPaymentState['communications'], Actions>({
  paymentIntentIdLoading: makeCommunicationReducerFromEnum(getPaymentIntentIdActionTypes),
  summaryLoading: makeCommunicationReducerFromEnum(getOrderSummaryActionTypes),
  payPalOrderLoading: makeCommunicationReducerFromEnum(getPayPalOrderActionTypes)
});
