import React, {useCallback, useState} from 'react';
import styled from 'styled-components';

import ClickAwayListener from 'Common/helpers/ClickAwayListener';
import {INCREASE_COUNT_PER_PAGE, MAX_COUNT_PER_PAGE, MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';
import {PaginationProps} from 'Common/components/Controls/Pagination/Pagination';
import {zIndex} from 'Common/constants/ZIndex';
import ColorPalette from 'Common/constants/ColorPalette';
import withoutProps from 'Common/helpers/WithoutProps';
import ColoredIcon, {IColoredIconProps as IconProps} from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import {PaginationTextBase} from './common';
import {breakpoints} from 'Common/constants/Breakpoints';

const toggleProps: IconProps = {
  size: 8,
  name: IconName.ArrowDown,
  color: ColorPalette.gray44,
  fill: true,
  stroke: false,
};

const Root = styled.div`
  margin-right: 8px;
  cursor: pointer;

  @media ${breakpoints.sm} {
    min-width: 145px;
    margin-right: 40px;
  }
`;

const CountPerPageList = styled.div`
  z-index: ${zIndex.modal - 1};
  padding: 8px 0;
  position: absolute;
  top: -100px;
  right: -60px;
  background: ${ColorPalette.white0};
  box-shadow: 0px 0px 20px ${ColorPalette.transparent2};
  border-radius: 4px;

  @media ${breakpoints.sm} {
    top: 30px;
    right: -20px;
  }
`;

const CountPerPageItem = styled.div`
  padding: 8px 16px 8px 35px;
  :hover {
    background-color: ${ColorPalette.gray49};
  }
`;

const Label = styled(PaginationTextBase)`
  color: ${ColorPalette.gray54};
`;

const CountPerPage = styled(PaginationTextBase)`
  color: ${ColorPalette.black1};
  margin-right: 6px;
`;

const ToggleIcon = styled(withoutProps(['isOpen'])(ColoredIcon))<{isOpen: boolean}>`
  transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'unset')};
`;

const CountPerPageSection = (props: PaginationProps) => {
  const {pagination, onChangeCountPerPage} = props;

  const renderCountPerPageItems = useCallback(() => {
    const items = [];
    for (let i = MIN_COUNT_PER_PAGE; i <= MAX_COUNT_PER_PAGE; i = i + INCREASE_COUNT_PER_PAGE) {
      const setCountPerPage = () => {
        onChangeCountPerPage && onChangeCountPerPage(i);
      };

      items.push(
        <CountPerPageItem key={i} onClick={setCountPerPage}>
          {i}
        </CountPerPageItem>
      );
    }

    return items;
  }, [onChangeCountPerPage]);

  const [isOpenCountItemsPerPageMenu, setIsOpenCountItemsPerPageMenu] = useState(false);
  const openCountItemsPerPageMenu = useCallback(
    () => setIsOpenCountItemsPerPageMenu(true),
    [setIsOpenCountItemsPerPageMenu]
  );
  const closeCountItemsPerPageMenu = useCallback(
    () => setIsOpenCountItemsPerPageMenu(false),
    [setIsOpenCountItemsPerPageMenu]
  );
  const toggleCountItemsPerPageMenu = useCallback(() => {
    if (isOpenCountItemsPerPageMenu) {
      closeCountItemsPerPageMenu();
      return;
    }

    openCountItemsPerPageMenu();
  }, [isOpenCountItemsPerPageMenu, openCountItemsPerPageMenu, closeCountItemsPerPageMenu]);

  return (
    <ClickAwayListener onClickAway={closeCountItemsPerPageMenu}>
      <Root
        className="d-flex justify-content-start align-self-center position-relative"
        onClick={toggleCountItemsPerPageMenu}
      >
        <Label>Show by page:</Label>
        <CountPerPage>{pagination.countPerPage}</CountPerPage>
        <ToggleIcon className="align-self-center" {...toggleProps} isOpen={isOpenCountItemsPerPageMenu} />
        {isOpenCountItemsPerPageMenu && <CountPerPageList>{renderCountPerPageItems()}</CountPerPageList>}
      </Root>
    </ClickAwayListener>
  );
};

export default CountPerPageSection;
