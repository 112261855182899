import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  getGenotypeListActions,
  getGenotypeListActionTypes,
  IGenotypeListState,
  resetGenotypeListActions,
  resetGenotypeListActionTypes,
} from './types';

import GenotypeService from 'Genotype/services/GenotypeService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const getGenotypes =
  (id: number): ActionResult<IGenotypeListState, void, getGenotypeListActions> =>
  async (dispatch) => {
    dispatch(action(getGenotypeListActionTypes.REQUEST));

    await GenotypeService.getGenotypes(id)
      .then((res) => {
        dispatch(action(getGenotypeListActionTypes.SUCCESS, res.data));
      })
      .catch((err) => {
        dispatch(action(getGenotypeListActionTypes.FAILURE, getErrorMessage(err)));
      });
  };

export const resetGenotypesResults =
  (): ActionResult<IGenotypeListState, void, resetGenotypeListActions> => (dispatch) => {
    dispatch(action(resetGenotypeListActionTypes.RESET));
  };
