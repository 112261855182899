import {IAdminOrganization} from 'Admin/AdminAssociations/models/IAdminOrganization';
import {IListResponse, IPagination} from 'Common/models/IResponse';
import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {Nullable} from 'Common/types';
import {IImage} from 'Image/models/IImage';

const STATE_NAME = 'adminOrganizations';

export interface IAdminOrganizationState {
  data: {
    adminOrganizations: IAdminOrganization[];
    adminOrganization: Nullable<IAdminOrganization>;
    adminOrganizationAvatar: Nullable<IImage>;
  };
  communications: {
    adminOrganizationsLoading: ICommunication;
    adminOrganizationLoading: ICommunication;
    adminOrganizationCreating: ICommunication;
    adminOrganizationUpdating: ICommunication;
    adminOrganizationStatusUpdating: ICommunication;
    adminOrganizationAvatarLoading: ICommunication;
    adminOrganizationAvatarUpdating: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getAdminOrganizationsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ADMIN_ORGANIZATIONS');
export type getAdminOrganizationsActions = MakeCommunicationActions<
  typeof getAdminOrganizationsActionTypes,
  {success: IListResponse<IAdminOrganization>}
>;

export const getAdminOrganizationActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ADMIN_ORGANIZATION');
export type getAdminOrganizationActions = MakeCommunicationActions<
  typeof getAdminOrganizationActionTypes,
  {success: IAdminOrganization}
>;

export const createAdminOrganizationActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_ADMIN_ORGANIZATION');
export type createAdminOrganizationActions = MakeCommunicationActions<typeof createAdminOrganizationActionTypes, {}>;

export const updateAdminOrganizationActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_ADMIN_ORGANIZATION');
export type updateAdminOrganizationActions = MakeCommunicationActions<typeof updateAdminOrganizationActionTypes, {}>;

export const changeAdminOrganizationStatusActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CHANGE_ADMIN_ORGANIZATION_STATUS'
);
export type changeAdminOrganizationStatusActions = MakeCommunicationActions<
  typeof changeAdminOrganizationStatusActionTypes,
  {success: {id: number}}
>;

export const getAdminOrganizationAvatarActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ADMIN_ORGANIZATION_AVATAR'
);
export type getAdminOrganizationAvatarActions = MakeCommunicationActions<
  typeof getAdminOrganizationAvatarActionTypes,
  {success: IImage}
>;

export const updateAdminOrganizationAvatarActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ADMIN_ORGANIZATION_AVATAR'
);
export type updateAdminOrganizationAvatarActions = MakeCommunicationActions<
  typeof updateAdminOrganizationAvatarActionTypes,
  {}
>;

export const resetAdminOrganizationActionTypes = makeResetCommunicationActionType(STATE_NAME, 'ADMIN_ORGANIZATION');
export type resetAdminOrganizationActions = MakeResetCommunicationActions<typeof resetAdminOrganizationActionTypes>;

export type AdminOrganizationsActions =
  | getAdminOrganizationsActions
  | getAdminOrganizationActions
  | createAdminOrganizationActions
  | updateAdminOrganizationActions
  | changeAdminOrganizationStatusActions
  | resetAdminOrganizationActions
  | getAdminOrganizationAvatarActions
  | updateAdminOrganizationAvatarActions;
