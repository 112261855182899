import React, {memo, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';

import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IAvailabilityMenuItem} from 'Common/components/DropDown/common/types';
import {DropDownMenu} from 'Common/components/DropDown/DropDownMenu';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {breakpoints} from 'Common/constants/Breakpoints';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import {VideoPreviewProps} from './VideoPreview';

const Root = styled.div<{isPreview?: boolean}>`
  z-index: 2;
  cursor: ${props => (props.isPreview ? 'pointer' : 'default')};
`;

const ButtonPanel = styled.div<{height: string; isHover?: boolean}>`
  height: ${({height}) => height};
  padding: 5px;
  opacity: ${props => (props.isHover ? 1 : 0)};
  background: ${hexToRgba(ColorPalette.black1, 0.6)};
  bottom: 0;
  cursor: default;

  transition: opacity 0.15s ease;

  @media ${breakpoints.md} {
    padding: 0;
  }
`;

const Button = styled(IconButton)`
  margin: 0 8px;
`;

const MoreIconWrapper = styled.div`
  margin-left: auto;
`;

const MoreIcon = styled(ColoredIcon)`
  cursor: pointer;
`;

interface IProps {
  isDesktop: boolean;
  showPreviewOnClick?: boolean;
}

type AllProps = IProps & Omit<VideoPreviewProps, 'width' | 'value'>;

const VideoPreviewActions = (props: AllProps) => {
  const {isDesktop, showPreviewOnClick, height, showEditButton, showDeleteButton, onDeleteVideo, onEditVideo} = props;

  const menuItems: IAvailabilityMenuItem[] = [
    {
      value: 'deleteHandler',
      label: 'Delete video',
      icon: {name: IconName.Garbage, size: isDesktop ? 24 : 18},
      id: 3,
      availabilityKey: showDeleteButton
    },
    {
      value: 'editHandler',
      label: 'Add video',
      icon: {name: IconName.Edit, size: isDesktop ? 24 : 18},
      id: 2,
      availabilityKey: showEditButton
    }
  ];

  const shownMenuItems = menuItems.filter(item => item.availabilityKey).sort((a, b) => sortByStringKey(a, b, 'id'));

  const actionsHandler = useMemo(
    () => ({
      editHandler: onEditVideo,
      deleteHandler: onDeleteVideo
    }),
    [onDeleteVideo, onEditVideo]
  );

  const onSelectHandler = useCallback(type => actionsHandler[type](), [actionsHandler]);

  const [hover, setHover] = useState<boolean>(false);

  const onMouseEnter = useCallback(() => setHover(true), [setHover]);
  const onMouseLeave = useCallback(() => setHover(false), [setHover]);
  const fakeEvent = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  if (!isDesktop) {
    return (
      <Root
        className="position-absolute"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isPreview={showPreviewOnClick}
      >
        <ButtonPanel
          className="d-flex align-items-start position-absolute w-100"
          height={height || '40px'}
          isHover={hover}
          onClick={fakeEvent}
        >
          <MoreIconWrapper>
            <DropDownMenu items={shownMenuItems} onSelect={onSelectHandler} alignRight={true}>
              {({isOpen}) => (
                <MoreIcon
                  name={IconName.More}
                  color={isOpen ? ColorPalette.white0 : ''}
                  hoverColor={ColorPalette.white0}
                  fill={true}
                  stroke={false}
                />
              )}
            </DropDownMenu>
          </MoreIconWrapper>
        </ButtonPanel>
      </Root>
    );
  }

  return (
    <Root
      className="position-absolute w-100 h-100"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isPreview={showPreviewOnClick}
    >
      <ButtonPanel
        className="d-flex align-items-center justify-content-center position-absolute w-100"
        isHover={hover}
        onClick={fakeEvent}
        height={height || '40px'}
      >
        {shownMenuItems.map((item, i) =>
          typeof item.label === 'string' ? (
            <Button
              key={i}
              name={item.icon!.name}
              color={ColorPalette.white0}
              fill={true}
              hoverColor={ColorPalette.gray20}
              size={item.icon?.size || 28}
              stroke={false}
              onClick={actionsHandler[item.value]}
            />
          ) : (
            item.label
          )
        )}
      </ButtonPanel>
    </Root>
  );
};

export default memo(VideoPreviewActions);
