import React, {memo, useEffect} from 'react';
import {Helmet} from 'react-helmet';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import {ITab} from 'Common/components/Tabs/ITab';
import AdminTabs from 'Admin/common/AdminTabs';
import AdminContent from 'Admin/common/AdminContent';
import Organizations from './Organizations/Organizations';
import Employees from './Employees/Employees';
import Horses from 'Admin/AdminAssociations/components/Horses/Horses';
import Owners from 'Admin/AdminAssociations/components/Owners/Owners';
import Orders from './Orders/Orders';
import Packages from 'Admin/AdminAssociations/components/Packages/Packages';
import PurchasableTests from 'Admin/AdminAssociations/components/PurchasableTest/PurchasableTests';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import useDistributorStrategy from 'Common/helpers/strategy/useDistributorStrategy';
import PackageGroups from 'Admin/AdminAssociations/components/PackageGroups/PackageGroups';

const basePath = '/admin-associations';

const adminData: ITab[] = [
  {section: 'orders', title: 'Orders', Component: Orders},
  {section: 'organizations', title: 'Organizations', Component: Organizations},
  {section: 'employees', title: 'Employees', Component: Employees},
  {section: 'horses', title: 'Horses', Component: Horses},
  {section: 'owners', title: 'Horse Owners', Component: Owners},
  {section: 'packages', title: 'Packages', Component: Packages},
  {section: 'package-groups', title: 'Package Groups', Component: PackageGroups},
  {section: 'tests', title: 'Purchasable Tests', Component: PurchasableTests},
];

const AdminAssociations = () => {
  const {setStrategy} = useDistributorStrategy();

  useEffect(() => {
    setStrategy(SelectedStrategy.AdminAssociation);
  }, [setStrategy]);

  return (
    <BaseLayout withoutPaddings={true} widthByContent={true} isBackButtonDenied={true}>
      <Helmet>
        <title>Associations</title>
      </Helmet>

      <AdminTabs tabs={adminData} basePath={basePath} />
      <AdminContent tabs={adminData} basePath={basePath} />
    </BaseLayout>
  );
};

export default memo(AdminAssociations);
