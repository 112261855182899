import React, {memo} from 'react';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';
import TraitsForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/Traits/TraitForm/TraitForm';
import {IAdminTrait} from 'Admin/AdminPhenotypes/models/IAdminTrait';

function Traits() {
  const {adminTrait: dictionary} = useDictionaries();

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={TraitsForm}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IAdminTrait> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
            <Cell<IAdminTrait> dataKey="name" width="15%" header="Name" render={({name}) => name} />
            <Cell<IAdminTrait> dataKey="state" width="10%" header="State" render={({state}) => state} />
            <Cell<IAdminTrait>
              dataKey="test"
              width="20%"
              header="Test"
              sortable={false}
              render={({test}) => test?.name}
            />
            <Cell<IAdminTrait>
              dataKey="description"
              width="35%"
              header="Description"
              sortable={false}
              render={({description}) => description?.value}
            />
            <Cell<IAdminTrait>
              dataKey="positionIndex"
              width="5%"
              header="Order in Report"
              sortable={false}
              render={({positionIndex}) => positionIndex}
            />
            <Cell<IAdminTrait>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(Traits);
