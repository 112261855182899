import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import ChangeSampleId from 'Admin/AdminDashboard/components/Horses/ChangeSampleIdForm/ChangeSampleIdForm';

interface IChangeSampleIdModalParams {
  onSuccess?(): void;
}

export function useChangeSampleIdModal(params: IChangeSampleIdModalParams) {
  const {onSuccess} = params;

  const [horseId, setHorseId] = useState(0);
  const [sampleId, setSampleId] = useState<number | undefined>(0);

  const [isOpen, setIsOpen] = useState(false);
  const openChangeSampleIdModal = useCallback((horseId: number, id?: number) => {
    setHorseId(horseId);
    setSampleId(id);
    setIsOpen(true);
  }, []);
  const closeChangeSampleIdModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeChangeSampleIdModal();
    onSuccess && onSuccess();
  }, [closeChangeSampleIdModal, onSuccess]);

  const changeSampleIdModal = (
    <ModalWindow isOpen={isOpen} onClose={closeChangeSampleIdModal}>
      <ChangeSampleId id={sampleId} horseId={horseId} onSuccess={handleSuccess} />
    </ModalWindow>
  );

  return {
    changeSampleIdModal,
    openChangeSampleIdModal
  };
}
