export function sortByStringKey<T>(a: T, b: T, key: keyof T) {
  if (a[key] < b[key]) {
    return -1;
  }

  if (a[key] > b[key]) {
    return 1;
  }

  return 0;
}
