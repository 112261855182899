import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {
  getFilterPropertyOperationsActions,
  getFilterPropertyOperationsActionTypes,
  getPackagesFiltersActions,
  getPackagesFiltersActionTypes,
} from './types';
import FilterPropertyDataService from 'FacetFilter/services/FacetFilterDataService';

export const getFilterPropertyOperations =
  (type: string): ActionResult<IAppState, void, getFilterPropertyOperationsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getFilterPropertyOperationsActionTypes.REQUEST));
      const res = await FilterPropertyDataService.getFilterPropertyOperations(type);
      dispatch(action(getFilterPropertyOperationsActionTypes.SUCCESS, {type, operations: res}));
    } catch (error) {
      dispatch(action(getFilterPropertyOperationsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getPackagesFilters = (): ActionResult<IAppState, void, getPackagesFiltersActions> => async (dispatch) => {
  try {
    dispatch(action(getPackagesFiltersActionTypes.REQUEST));
    const res = await FilterPropertyDataService.getPackagesFilters();
    dispatch(action(getPackagesFiltersActionTypes.SUCCESS, res));
  } catch (error) {
    dispatch(action(getPackagesFiltersActionTypes.FAILURE, getErrorMessage(error)));
  }
};
