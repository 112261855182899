import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IAdminPermissions} from 'Common/models/IAdminPermissions';
import {IAdminPermissionsRequest} from 'Admin/common/Permissions/services/models/IAdminPermissionsRequest';

const getCurrentAdminPermissions = async (): Promise<IObjectResponse<string[]>> => {
  const res = await axiosWrapper.get<IObjectResponse<string[]>>(`/AdminPermissions`);
  return res.data;
};

const getAdminPermissions = async (id: number): Promise<IListResponse<IAdminPermissions>> => {
  const res = await axiosWrapper.get<IListResponse<IAdminPermissions>>(`/AdminPermissionAdmin/${id}`);
  return res.data;
};

const setAdminPermissions = async (data: IAdminPermissionsRequest): Promise<IListResponse<IAdminPermissions>> => {
  const res = await axiosWrapper.post<IListResponse<IAdminPermissions>>(`/AdminPermissionUpdateAdmin`, data);
  return res.data;
};

export default {
  getCurrentAdminPermissions,
  getAdminPermissions,
  setAdminPermissions,
};
