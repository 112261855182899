import {AnyAction} from 'redux';

import initialCommunication from './initial';
import {ICommunication} from './types';

const makeResetCommunicationReducer = <T extends string>(actionType: T) => (
  state: ICommunication<any> | undefined = initialCommunication,
  action: AnyAction
) => (action.type === actionType ? initialCommunication : state);

export default makeResetCommunicationReducer;
