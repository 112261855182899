import React, {CSSProperties, memo, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import {IAncestryKinshipHorse, KinshipDegreestring} from 'OnlineReport/models/Ancestry/IAncestryKinshipHorse';
import ColumnContainer from '../common/ColumnContainer/ColumnContainer';
import CompositionPie, {PieSize} from '../CompositionChart/CompositionPie';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import BreakableSection from 'Common/components/BreakableSection/BreakableSection';
import {breakpoints} from 'Common/constants/Breakpoints';

const DEGREE_1_TOOLTIP =
  'These matches are likely to be immediate family such as parents, offspring and full siblings.';
const DEGREE_2_TOOLTIP =
  'These are matches likely to be half-siblings, uncles, aunts, nieces, nephews, or grandparents. ';
const DEGREE_3_TOOLTIP =
  'These matches are typically cousins, great grandparents, great grandchildren, and other near relatives.';

function getSizeOfDegree(degree: KinshipDegreestring, isHorseProfile: boolean) {
  if (isHorseProfile) {
    return degree === KinshipDegreestring.First
      ? 'medium'
      : degree === KinshipDegreestring.Second
      ? 'small'
      : 'smaller';
  }
  return degree === KinshipDegreestring.First ? 'big' : degree === KinshipDegreestring.Second ? 'medium' : 'small';
}

const MAX_ITEMS = 4;

// ToDo: Add animation for expanding
const CompositionPieWrapper = styled.div<{isVisible: boolean}>`
  padding: 0 2px;

  ${(props) =>
    props.isVisible
      ? `
      opacity: 1;
      max-height: 10000px;
      overflow: visible;
      transition: opacity 0.4s 0.2s;
      `
      : `
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.4s 0.2s max-height 0.1s;
    `};

  @media ${breakpoints.md} {
    padding: 0 8px;
  }
`;

const DiscoverMoreButton = styled(PrimaryButton)`
  margin-top: 32px;
  width: 176px;
`;

interface IAncestryKinshipHorseVisible extends IAncestryKinshipHorse {
  isVisible: boolean;
}

interface IProps {
  horses: IAncestryKinshipHorse[];
  isPrintable: boolean;
  isHorseProfile?: boolean;
}

function KinshipHorses(props: IProps) {
  const {horses, isPrintable, isHorseProfile = false} = props;

  const [isMore, setIsMore] = useState(!isPrintable);
  const [clonesState, setClonesState] = useState<IAncestryKinshipHorseVisible[]>([]);
  const [degree1State, setDegree1State] = useState<IAncestryKinshipHorseVisible[]>([]);
  const [degree2State, setDegree2State] = useState<IAncestryKinshipHorseVisible[]>([]);
  const [degree3State, setDegree3State] = useState<IAncestryKinshipHorseVisible[]>([]);

  const {currentVisitorType} = useVisitorTypeService();

  const sortedHorses = horses.sort((a, b) => {
    if (a.percent > b.percent) return -1;
    return 1;
  });

  const setInitialValues = useCallback(
    (degree: KinshipDegreestring) => {
      return (sortedHorses.filter((x) => x.degree === degree) as IAncestryKinshipHorseVisible[]).map((x) => ({
        ...x,
        isVisible: true,
      }));
    },
    [sortedHorses]
  );

  const clones = setInitialValues(KinshipDegreestring.Clone);
  const degree1 = setInitialValues(KinshipDegreestring.First);
  const degree2 = setInitialValues(KinshipDegreestring.Second);
  const degree3 = setInitialValues(KinshipDegreestring.Third);

  function setVisible(values: IAncestryKinshipHorseVisible[], countVisible: number) {
    values.forEach((value, index) => {
      value.isVisible = index < countVisible;
    });
    return values;
  }

  useEffect(() => {
    setClonesState(clones);
    setDegree1State(degree1);
    setDegree2State(degree2);
    setDegree3State(degree3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMore) {
      setClonesState(setVisible(clones, 0));
      setDegree1State(setVisible(degree1, MAX_ITEMS));
      setDegree2State(setVisible(degree2, MAX_ITEMS));
      setDegree3State(setVisible(degree3, MAX_ITEMS));
      return;
    }
    setClonesState(setVisible(clones, clones.length));
    setDegree1State(setVisible(degree1, degree1.length));
    setDegree2State(setVisible(degree2, degree2.length));
    setDegree3State(setVisible(degree3, degree3.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMore]);

  const isMoreEnable = useMemo(
    () => clones.length > 3 || degree1.length > 3 || degree2.length > 3 || degree3.length > 3,
    [clones.length, degree1.length, degree2.length, degree3.length]
  );

  const onButtonClick = useCallback(() => {
    setIsMore(!isMore);
  }, [isMore]);

  const renderKinship = useCallback(
    (horses: IAncestryKinshipHorseVisible[], columnName: string, tooltip?: string, style?: CSSProperties) => {
      return (
        <BreakableSection>
          {horses.length > 0 && (
            <ColumnContainer
              columnName={columnName}
              childrenClassName="justify-content-center align-items-start"
              tooltip={tooltip}
              childrenStyle={style}
            >
              {horses.map((horse, i) => {
                const size: PieSize = getSizeOfDegree(horse.degree, isHorseProfile);

                return (
                  <CompositionPieWrapper className="position-relative" key={i} isVisible={horse.isVisible}>
                    <CompositionPie
                      population={horse.breeds}
                      avatar={horse.horse?.avatar}
                      size={size}
                      title={horse.horse?.name || 'Private Account'}
                      profileUrl={
                        horse.horse?.id ? `${PREFIX_VISITOR_TYPE[currentVisitorType]}/horse/${horse.horse?.id}` : ''
                      }
                      isPrintable={isPrintable}
                      relatedHorse={horse}
                    />
                  </CompositionPieWrapper>
                );
              })}
            </ColumnContainer>
          )}
        </BreakableSection>
      );
    },
    [currentVisitorType, isHorseProfile, isPrintable]
  );

  return (
    <div className="d-flex flex-column align-items-center">
      {renderKinship(degree1State, '1st Degree Relation', DEGREE_1_TOOLTIP)}
      {renderKinship(degree2State, '2nd Degree Relation', DEGREE_2_TOOLTIP, {maxWidth: 870})}
      {renderKinship(degree3State, '3rd Degree Relation', DEGREE_3_TOOLTIP, {maxWidth: 820})}
      {(!isMore || isPrintable) && renderKinship(clonesState, 'Clone or Replicate')}

      {isMoreEnable && !isPrintable && (
        <DiscoverMoreButton onClick={onButtonClick}>{isMore ? 'Discover More' : 'Less'}</DiscoverMoreButton>
      )}
    </div>
  );
}

export default memo(KinshipHorses);
