import {IModule} from 'redux-dynamic-modules';

import {IHorseListState} from 'HorseProfile/store/horseList/types';
import {horsesReducer} from 'HorseProfile/store/horseList/reducer/index';

export interface IHorseListModuleState {
  horses: IHorseListState;
}

export const HorseListModule: IModule<IHorseListModuleState> = {
  id: 'horseList',
  reducerMap: {
    horses: horsesReducer
  }
};
