import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';

import {actions, selectors} from 'Admin/AdminSettings/stores/adminStaticResources/index';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import Loading from 'Loading/components/Loading';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {IStaticResource} from 'Admin/AdminSettings/models/IStaticResource';
import {AdminStaticResourcesModule} from 'Admin/AdminSettings/stores/adminStaticResources/adminStaticResourcesModule';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import Pagination from 'Common/components/Controls/Pagination/Pagination';
import {FormType} from 'Common/constants/FormType';
import {OrderingType} from 'Common/constants/OrderingType';
import StaticResourceForm from 'Admin/AdminSettings/components/StaticResources/StaticResourceForm';

const CONFIRM_DELETE_STATIC_RESOURCE = 'Are you sure you want to remove the static resource?';

const actionButtonProps = {color: ColorPalette.gray28, fill: true, stroke: false, size: 24};

const AddStaticResourceButton = styled(PrimaryButton)`
  width: 176px;
  height: 50px;
  margin-left: 55px;
`;

const StaticResourceTable = styled.div`
  margin-top: 50px;
`;

const ActionButton = styled(IconButton)`
  margin-left: 12px;
`;

interface IActionProps {
  id: number;
  editAction: (id: number) => void;
  deleteAction: (id: number) => void;
}

const Actions = React.memo((props: IActionProps) => {
  const {id, editAction, deleteAction} = props;

  const handleEditAction = useCallback(() => editAction(id), [id, editAction]);
  const handleDeleteAction = useCallback(() => deleteAction(id), [id, deleteAction]);

  return (
    <div className="d-flex align-items-center justify-content-end">
      <ActionButton name={IconName.Edit} {...actionButtonProps} onClick={handleEditAction} />
      <ActionButton name={IconName.Garbage} {...actionButtonProps} onClick={handleDeleteAction} />
    </div>
  );
});

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const StaticResources = (props: AllProps) => {
  const {
    getStaticResources,
    staticResourcesLoading,
    staticResources,
    deleteStaticResource,
    staticResourceDeleting,
    pagination,
  } = props;

  const {
    selectedId,
    setSelectedId,
    openDeleteModal,
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    deleteModal,
    reloadItems,
    searchBar,
  } = useCommonAdminPageData<IStaticResource>({
    sortingAfterFilteringParams: {orderBy: 'name', orderingType: OrderingType.Ascending},
    getItems: getStaticResources,
    deleteParams: {
      communication: staticResourceDeleting,
      confirmDescription: CONFIRM_DELETE_STATIC_RESOURCE,
      action: deleteStaticResource,
    },
  });

  const [isCreateOrEditStaticResourceOpen, setIsCreateOrEditStaticResourceOpen] = useState(false);
  const [formType, setFormType] = useState<FormType>(FormType.create);

  const isRequesting =
    staticResourcesLoading.isRequesting || staticResourcesLoading.isRequesting || staticResourceDeleting.isRequesting;

  const handleAddStaticResource = () => {
    setFormType(FormType.create);
    setSelectedId(null);
    setIsCreateOrEditStaticResourceOpen(true);
  };

  const handleEditStaticResource = (id: number) => {
    setFormType(FormType.edit);
    setSelectedId(id);
    setIsCreateOrEditStaticResourceOpen(true);
  };

  const closeCreateOrEditStaticResourceModal = useCallback(() => {
    setIsCreateOrEditStaticResourceOpen(false);
  }, []);

  const onSuccessCreateOrEditStaticResource = useCallback(() => {
    closeCreateOrEditStaticResourceModal();
    reloadItems();
  }, [closeCreateOrEditStaticResourceModal, reloadItems]);

  const isShowPagination = staticResources.length > 0;

  return (
    <AdminPageLayout>
      {deleteModal}
      <ModalWindow isOpen={isCreateOrEditStaticResourceOpen} onClose={closeCreateOrEditStaticResourceModal}>
        <StaticResourceForm
          staticResourceId={selectedId || undefined}
          type={formType}
          onSuccess={onSuccessCreateOrEditStaticResource}
        />
      </ModalWindow>

      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
        <AddStaticResourceButton onClick={handleAddStaticResource}>+ Add resource</AddStaticResourceButton>
      </div>
      <StaticResourceTable className="position-relative">
        {isRequesting && <Loading />}
        <Table<IStaticResource> data={staticResources} rowKey="id" sorting={sorting} onChangeSorting={changeSorting}>
          <Cell<IStaticResource> header="ID" dataKey="id" render={({id}) => `#${id}`} width="30%" />
          <Cell<IStaticResource> header="Name" dataKey="name" width="30%" render={({name}) => name} />
          <Cell<IStaticResource>
            header="Description"
            dataKey="description"
            render={({description}) => description}
            width="35%"
          />

          <Cell<IStaticResource>
            header="Actions"
            align={CellAlign.Right}
            width="5%"
            render={({id}) => <Actions id={id} editAction={handleEditStaticResource} deleteAction={openDeleteModal} />}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </StaticResourceTable>
    </AdminPageLayout>
  );
};

const mapStateToProps = (state: IAppState) => ({
  staticResources: selectors.selectStaticResources(state),
  staticResourcesLoading: selectors.selectCommunication(state, 'staticResourcesLoading'),
  staticResourceDeleting: selectors.selectCommunication(state, 'staticResourceDeleting'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getStaticResources: actions.getStaticResources,
  deleteStaticResource: actions.deleteStaticResource,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(StaticResources);

export default withDynamicModules(Connected, AdminStaticResourcesModule);
