import {combineReducers, Reducer} from 'redux';

import {IUserPermissionsState} from '../types';
import {communicationUserPermissionsReducer} from './communications';
import {dataUserPermissionsReducer} from './data';

export const userPermissionsReducer: Reducer<IUserPermissionsState> = combineReducers<IUserPermissionsState>({
  communications: communicationUserPermissionsReducer,
  data: dataUserPermissionsReducer
});
