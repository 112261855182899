import React, {useCallback, useState} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import ChangePasswordForm from 'Admin/AdminAssociations/components/Employees/ChangePasswordForm/ChangePasswordForm';
import {IAdminEmployee} from 'Admin/AdminAssociations/models/IAdminEmployee';

interface IProps {
  onSuccess?(): void;
}

export function useUpdateAdminEmployeePasswordModal(props: IProps) {
  const {onSuccess} = props;

  const [selectedId, setSelectedId] = useState(0);
  const [selectedAdminEmployee, setSelectedAdminEmployee] = useState<IAdminEmployee>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openUpdateAdminEmployeePasswordModal = useCallback(
    (employee: IAdminEmployee) => {
      setSelectedId(employee.id);
      setSelectedAdminEmployee(employee);
      setIsModalOpen(true);
    },
    [setSelectedId]
  );
  const closeUpdateAdminEmployeePasswordModal = useCallback(() => setIsModalOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeUpdateAdminEmployeePasswordModal();
    onSuccess && onSuccess();
  }, [closeUpdateAdminEmployeePasswordModal, onSuccess]);

  const updateAdminEmployeePasswordModal = (
    <ModalWindow isOpen={isModalOpen && !!selectedId} onClose={closeUpdateAdminEmployeePasswordModal}>
      <ChangePasswordForm employee={selectedAdminEmployee!} onSuccess={handleSuccess} />
    </ModalWindow>
  );

  return {
    openUpdateAdminEmployeePasswordModal,
    updateAdminEmployeePasswordModal,
  };
}
