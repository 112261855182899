import React, {memo} from 'react';
import styled from 'styled-components';

import Indicator from 'Common/components/Indicator/Indicator';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {IIndicator} from 'Common/models/IIndicator';
import {mapAbilityValues} from 'Common/helpers/abilities';
import {breakpoints} from 'Common/constants/Breakpoints';
import {AbilityType} from 'Common/constants/AbilityType';
import {parseToIndicatorParameters} from 'Common/helpers/parseToIndicatorParameters';
import {IShortAbility} from 'Dictionaries/models/IAbilities';
import Tooltip from 'Common/components/Tooltip/Tooltip';

const baseIndicator: Omit<IIndicator, 'segments'> = {
  height: 8,
  segmentColor: ColorPalette.red1
};

enum TestRequiredSize {
  Small,
  Medium,
  Large
}

const lineHeightBySize: Record<TestRequiredSize, number> = {
  [TestRequiredSize.Small]: 20,
  [TestRequiredSize.Medium]: 40,
  [TestRequiredSize.Large]: 65
};

const Value = styled.div`
  height: 25px;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 1px;

  @media ${breakpoints.md} {
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 8px;
  }
  :last-child {
    margin-bottom: 0;
  }
`;

const TextIndicator = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.green2};
  text-align: right;
`;

const TestRequired = styled.div<{size: TestRequiredSize}>`
  margin-top: 3px;
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: ${({size}) => `${lineHeightBySize[size]}px`};
  text-align: right;
  text-transform: uppercase;
  color: ${ColorPalette.gray44};
  justify-content: flex-end;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media ${breakpoints.sm} {
    justify-content: center;
  }
`;

const DnaLabel = styled.div<{disabled?: boolean}>`
  margin-bottom: 8px;
  width: 82px;
  text-align: right;
  font-family: ${Typography.family.ubuntu};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 25px;
  color: ${props => (props.disabled ? ColorPalette.white9 : ColorPalette.gray44)};
  text-align: left;

  @media ${breakpoints.md} {
    text-align: right;
    margin-bottom: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

interface IProps {
  temperament?: IShortAbility;
  gait?: IShortAbility;
  speed?: IShortAbility;
  showIndicators?: boolean;
}

function HorseAbilities(props: IProps) {
  const {showIndicators, temperament, gait, speed} = props;

  const tests = parseToIndicatorParameters(
    [
      {
        type: AbilityType.Temperament,
        state: temperament?.state || null,
        name: temperament?.name,
        description: temperament?.description
      },
      {
        type: AbilityType.Gait,
        state: gait?.state || null,
        name: gait?.name,
        description: gait?.description
      },
      {
        type: AbilityType.Speed,
        state: speed?.state || null,
        name: speed?.name,
        description: speed?.description
      }
    ],
    [ColorPalette.red7, ColorPalette.yellow9, ColorPalette.green2]
  );

  return (
    <div className="d-flex flex-row w-100">
      <div>
        <DnaLabel disabled={!temperament}>Temperament</DnaLabel>
        <DnaLabel disabled={!gait}>DMRT3</DnaLabel>
        <DnaLabel disabled={!speed}>Speed</DnaLabel>
      </div>
      <div className="w-100">
        {tests.map((item, i) => {
          if (item.isExist) {
            if (showIndicators) {
              return (
                <Value className="d-flex" key={i}>
                  <Indicator
                    indicator={{
                      ...baseIndicator,
                      value: mapAbilityValues[item.value],
                      segmentColor: item.color,
                      segmentsMaxCount: 3
                    }}
                    tooltip={item.description}
                  />
                </Value>
              );
            }

            return (
              <Value className="d-flex w-100" key={i}>
                <Tooltip content={item.description || ''}>
                  <TextIndicator className="w-100">{item.name}</TextIndicator>
                </Tooltip>
              </Value>
            );
          }

          const messageSize = (() => {
            if (item.size > 2) {
              return TestRequiredSize.Large;
            }
            return item.size > 1 ? TestRequiredSize.Medium : TestRequiredSize.Small;
          })();

          return (
            <TestRequired className="d-flex" key={i} size={messageSize}>
              NO DNA INFO
            </TestRequired>
          );
        })}
      </div>
    </div>
  );
}

export default memo(HorseAbilities);
