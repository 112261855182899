import {combineReducers, Reducer} from 'redux';

import {IAdminAssociationOwnerProfileState} from '../types';
import {communicationOwnerProfileReducer} from './communications';
import {dataOwnerProfileReducer} from './data';
import {uiReducer} from './ui';

export const ownerProfileReducer: Reducer<IAdminAssociationOwnerProfileState> =
  combineReducers<IAdminAssociationOwnerProfileState>({
    communications: communicationOwnerProfileReducer,
    data: dataOwnerProfileReducer,
    ui: uiReducer,
  });
