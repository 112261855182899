import {combineReducers, Reducer} from 'redux';

import {communicationChangeEmailReducer} from './communications';
import {dataChangeEmailReducer} from './data';
import {IAdminChangeEmailState} from '../types';

export const changeEmailReducer: Reducer<IAdminChangeEmailState> = combineReducers<IAdminChangeEmailState>({
  communications: communicationChangeEmailReducer,
  data: dataChangeEmailReducer
});
