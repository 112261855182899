import {datadogLogs, StatusType, HandlerType} from '@datadog/browser-logs';
import {IModuleStore} from 'redux-dynamic-modules';

import {convertToLoggerContext} from './convertToLoggerContext';
import {IAppState} from 'Common/store/IAppState';
import {getEnvParams} from './getEnvParams';

let reduxStore: IModuleStore<IAppState>;

export function createActivityLogger(store?: IModuleStore<unknown>) {
  if (store) {
    reduxStore = store as IModuleStore<IAppState>;
  }

  const {dataDogDestination} = getEnvParams();
  datadogLogs.createLogger('activityLogger', {
    level: StatusType.info,
    handler: dataDogDestination && HandlerType[dataDogDestination]
  });
}

export function getActivityLoggerContext(body?: any) {
  const user_id = reduxStore?.getState().currentUser.data.user?.id || '';
  return convertToLoggerContext({user_id, ...body});
}
