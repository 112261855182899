import React, {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';

import NavLinks from 'Common/components/Header/HeaderButtons/Links/NavLinks';
import NavMenu from 'Common/components/Header/HeaderButtons/Links/NavMenu';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import LoginLinks from 'Common/components/Header/HeaderButtons/Links/LoginLinks';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {IAppState} from 'Common/store/IAppState';
import {selectors as userSelectors} from 'UserProfile/store/currentUser/index';
import {selectors as currentPermissionsSelectors} from 'Permissions/store/index';
import {selectors as currentAdminPermissionsSelectors} from 'Admin/common/Permissions/store/index';

const buttonProps = {fill: true, stroke: false, color: ColorPalette.white0, hoverColor: ColorPalette.white3};

const Button = styled(IconButton)`
  margin-right: 20px;
`;

interface IExternalProps {
  openUserMenu?(): void;
  closeUserMenu?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalProps & RouteComponentProps;

const HeaderButtons = (props: AllProps) => {
  const {
    currentUser,
    currentAdmin,
    location,
    currentUserPermissions,
    currentAdminPermissions,
    currentAssociationEmployee,
    openUserMenu,
    closeUserMenu,
  } = props;

  const {isMobile} = useMediaQuery();

  const [isActiveCloseMenuIcon, setIsActiveCloseMenuIcon] = useState(false);
  const openMenu = useCallback(() => {
    setIsActiveCloseMenuIcon(true);
    openUserMenu && openUserMenu();
  }, [setIsActiveCloseMenuIcon, openUserMenu]);
  const closeMenu = useCallback(() => {
    setIsActiveCloseMenuIcon(false);
    closeUserMenu && closeUserMenu();
  }, [setIsActiveCloseMenuIcon, closeUserMenu]);

  useEffect(() => {
    setIsActiveCloseMenuIcon(false);
  }, [location]);

  if (!currentUser && !currentAdmin && !currentAssociationEmployee) {
    return <LoginLinks />;
  }

  if (isMobile) {
    return isActiveCloseMenuIcon ? (
      <Button size={16} name={IconName.Close} onClick={closeMenu} {...buttonProps} style={{marginLeft: 4}} />
    ) : (
      <Button size={20} name={IconName.Sandwich} onClick={openMenu} {...buttonProps} />
    );
  }

  if (currentUser) {
    const visitor = {
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      avatar: currentUser?.avatar?.url,
    };

    return (
      <>
        <NavLinks user={currentUser} userPermissions={currentUserPermissions} />
        <NavMenu visitor={visitor} userPermissions={currentUserPermissions} currentUserId={currentUser.id} />
      </>
    );
  }

  const visitor = {
    name: `${currentAdmin?.name}`,
    avatar: currentAdmin?.avatar?.url,
  };

  return <NavMenu visitor={visitor} userPermissions={currentAdminPermissions} />;
};

const mapStateToProps = (state: IAppState) => ({
  currentUser: userSelectors.selectCurrentUser(state),
  currentAdmin: userSelectors.selectCurrentAdmin(state),
  currentUserPermissions: currentPermissionsSelectors.selectCurrentPermissions(state),
  currentAdminPermissions: currentAdminPermissionsSelectors.selectCurrentAdminPermissions(state),
  currentAssociationEmployee: userSelectors.selectCurrentAssociationEmployee(state),
});

const connector = connect(mapStateToProps);
export default connector(memo(withRouter(HeaderButtons)));
