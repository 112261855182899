import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';
import {Nullable} from 'Common/types';
import {IAssociationEmployee} from 'UserProfile/models/IAssociationEmployee';

function selectState(state: IAppState) {
  return state.currentUser;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectCurrentUserProfileType(state: IAppState) {
  return selectState(state).data.userProfileType;
}

export function selectCurrentUser(state: IAppState) {
  return selectState(state).data.user;
}

export function selectCurrentAdmin(state: IAppState) {
  return selectState(state).data.admin;
}

export function selectCurrentUserId(state: IAppState): number | null {
  return selectState(state).data?.user?.id || null;
}

export function selectCurrentAssociationEmployee(state: IAppState): Nullable<IAssociationEmployee> {
  return selectState(state).data.associationEmployee;
}

export function selectCurrentAssociationId(state: IAppState): Nullable<number> {
  return selectState(state).data?.associationEmployee?.organizationId || null;
}
