import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IObjectResponse} from 'Common/models/IResponse';
import {IAdminNotifications} from '../models/Admins/IAdminNotifications';
import {IAdminNotificationRequest} from './types/notification';

const getAdminNotifications = async (adminId: number): Promise<IAdminNotifications> => {
  const response = await axiosWrapper.get<IObjectResponse<IAdminNotifications>>(`/AdminNotificationSettingAdmin`, {
    params: {adminId},
  });
  return response.data.data;
};

const updateAdminNotifications = async (data: IAdminNotificationRequest): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AdminNotificationSettingUpdateAdmin`, data);
};

export default {
  getAdminNotifications,
  updateAdminNotifications,
};
