import {IModule} from 'redux-dynamic-modules';

import {IBusinessPortalOwnerProfileState} from './types';
import {ownerProfileReducer} from './reducer';

export interface BusinessPortalOwnerProfileModuleState {
  businessPortalOwnerProfile: IBusinessPortalOwnerProfileState;
}

export const BusinessPortalOwnerProfileModule: IModule<BusinessPortalOwnerProfileModuleState> = {
  id: 'businessPortalOwnerProfile',
  reducerMap: {
    businessPortalOwnerProfile: ownerProfileReducer,
  },
};
