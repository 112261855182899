import styled from 'styled-components';
import {IHeaderCellProps} from 'Common/components/Table/view/HeaderCell';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

const HeaderCellContent = styled.span<{cellAlign?: CellAlign}>`
  color: ${Theme.color.gray};
  background-color: ${ColorPalette.gray49};
  width: 100%;

  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  padding: 8px 16px;

  justify-content: ${p => {
    switch (p.cellAlign) {
      case CellAlign.Right:
        return 'flex-end';
      case CellAlign.Center:
        return 'center';
      default:
        return;
    }
  }};
`;

const HeaderCellRow = styled.th`
  padding: 0;

  &:first-child ${HeaderCellContent} {
    border-radius: 5px 0 0 5px;
  }

  &:last-child ${HeaderCellContent} {
    border-radius: 0 5px 5px 0;
  }
`;

export const BusinessPortalHeaderCell = <T extends {}>(props: IHeaderCellProps<T>) => {
  const {header, align} = props;

  return (
    <HeaderCellRow>
      <HeaderCellContent cellAlign={align} className="d-inline-flex align-items-center">
        {header}
      </HeaderCellContent>
    </HeaderCellRow>
  );
};
