import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router-dom';
import {FormikBag, FormikProps, withFormik} from 'formik';

import {InjectedStripeProps} from 'Common/helpers/withStripe';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Payment/store/orderPayment';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import Loading from 'Loading/components/Loading';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import {breakpoints} from 'Common/constants/Breakpoints';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {getCommonErrors, getErrorCode} from 'Common/helpers/ErrorHelper';
import {parseUrlParams} from 'Common/helpers/url';
import Summary from 'Order/components/Summary';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {OrderPaymentModule} from 'Payment/store/orderPayment/orderPaymentModule';
import {Payment} from 'Order/components/CreateOrder/parts';
import {SummaryBackground, SummaryContainer} from 'Order/shared/StyledComponents';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import {PayPalButton, StripeForm} from 'Payment/components';
import {PaymentMethod, PaymentMethodAdditional} from 'Common/constants/PaymentMethod';
import {IFormValues, initialValues} from './validation';
import {scrollToTop} from 'Common/helpers/scrollToTop';
import {PaymentError} from 'Payment/constants/PaymentError';

const PAYMENT_LOADING_ERROR = 'Error on loading data for payment';
const SUMMARY_ERROR = 'Error on getting order summary';

const loadingStyle: React.CSSProperties = {zIndex: 1000};

const Root = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${breakpoints.md} {
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
  @media ${breakpoints.sm} {
    height: auto;
  }
`;

const PaymentForm = styled.div`
  width: 100%;
  margin: 24px 16px;
  display: flex;
  flex-direction: column;
  max-width: 448px;

  @media ${breakpoints.sm} {
    margin: 40px 72px;
  }
`;

const Header = styled.div`
  color: ${Theme.color.black};
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size32};
  line-height: 56px;

  @media ${breakpoints.sm} {
    margin-bottom: 8px;
  }
`;

const PayButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 448px;
  margin-top: 32px;
`;

const SubmitErrorMessage = styled(ErrorMessage)`
  margin-top: 64px;
  text-align: center;
`;

const PayPalButtonWrapper = styled.div<{hidden?: boolean}>`
  display: ${(prop) => (prop.hidden ? 'none' : 'block')};
  height: 110px;
  margin-top: 32px;
`;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & InjectedStripeProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function OrderPayment(props: AllProps) {
  const {summary, summaryLoading, getOrderSummaryByToken} = props;

  const {values} = props;

  const history = useHistory();
  const location = useLocation();
  const {isMobile} = useMediaQuery();

  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isOpenPaymentForm, setIsOpenPaymentForm] = useState(false);
  const [isPayPalWorking, setIsPayPalWorking] = useState(false);
  const [payPalError, setPayPalError] = useState('');

  const {token} = parseUrlParams<{token?: string}>(location.search);

  const isLoading = isPayPalWorking || summaryLoading.isRequesting;
  const isPayPalHidden =
    values.paymentMethod !== PaymentMethod.PayPal && values.paymentMethod !== PaymentMethodAdditional.PayPalCard;

  const invalidTokenError = isInvalidToken && PAYMENT_LOADING_ERROR;
  const summaryError = summaryLoading.error && (getCommonErrors(summaryLoading.error) || SUMMARY_ERROR);
  const orderPaidError =
    summaryLoading.error &&
    getErrorCode(summaryLoading.error) === PaymentError.OrderPaid &&
    getCommonErrors(summaryLoading.error);
  const error = invalidTokenError || orderPaidError || payPalError;

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (!token) {
      setIsInvalidToken(true);
      return;
    }

    getOrderSummaryByToken(token as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (summary) {
      setAmount(summary.testsSummary.total);
    }
  }, [summary]);

  const openPaymentForm = useCallback(async () => {
    if (!token) {
      setIsInvalidToken(true);
      return;
    }

    setIsOpenPaymentForm(true);
  }, [token]);

  const closePaymentForm = useCallback(() => {
    setIsOpenPaymentForm(false);
  }, []);

  const redirectToSuccessPayment = useCallback(
    (customToken?: number) => {
      setIsPayPalWorking(false);
      history.replace(`/order-placed/${token}/payment`);
    },
    [history, token]
  );

  const onPayPalClick = useCallback(() => {
    setIsPayPalWorking(true);
  }, []);

  const handlePayPalCancel = useCallback(() => {
    setIsPayPalWorking(false);
  }, []);

  const handlePayPalError = useCallback((error: string) => {
    setIsPayPalWorking(false);
    setPayPalError(error);
  }, []);

  const onPayPalSuccess = useCallback(
    async (details: any, data: any) => {
      redirectToSuccessPayment(details.token);
    },
    [redirectToSuccessPayment]
  );

  const orderToken = useMemo(() => {
    if (!token) {
      return '';
    }
    return token;
  }, [token]);

  const layoutProps = {isBackButtonDenied: true, withoutPaddings: true, hideFooter: isMobile, fixedHeight: isMobile};

  if (error) {
    return (
      <BaseLayout {...layoutProps}>
        <SubmitErrorMessage>{error || PAYMENT_LOADING_ERROR}</SubmitErrorMessage>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout {...layoutProps}>
      <ModalWindow isOpen={isOpenPaymentForm} onClose={closePaymentForm} maxWidth="500px">
        <StripeForm token={token} amount={amount} onSuccess={redirectToSuccessPayment} />
      </ModalWindow>

      <Root>
        {isLoading && <Loading style={loadingStyle} />}

        <PaymentForm>
          <Header>Secure order payment</Header>
          <Payment excludeInvoice={true} />
          <PayButton onClick={openPaymentForm} hidden={!isPayPalHidden}>
            Pay now
          </PayButton>

          <PayPalButtonWrapper hidden={isPayPalHidden}>
            <PayPalButton
              onSuccess={onPayPalSuccess}
              onClick={onPayPalClick}
              onCancel={handlePayPalCancel}
              onError={handlePayPalError}
              hidden={isPayPalHidden}
              token={orderToken}
            />
          </PayPalButtonWrapper>
        </PaymentForm>

        <SummaryContainer>
          <SummaryBackground />
          <Summary
            packagesPrice={summary?.testsSummary.packagesPrice || undefined}
            couponsAmountSum={summary?.testsSummary.couponDiscount || 0}
            total={summary?.testsSummary.total || 0}
            gift={summary?.testsSummary.giftCardPayment || 0}
            error={summaryError}
            paymentMethod={values.paymentMethod}
            isSummaryLoading={summaryLoading.isRequesting}
            horsesSummary={summary?.horses}
          />
        </SummaryContainer>
      </Root>
    </BaseLayout>
  );
}

const handleSubmit = async (values: IFormValues, formikBag: FormikBag<OuterProps, IFormValues>) => {
  try {
    formikBag.setSubmitting(true);
  } finally {
    formikBag.setSubmitting(false);
  }
};

const OrderPaymentWithFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValues,
  handleSubmit,
  enableReinitialize: true,
})(memo(OrderPayment));

const mapStateToProps = (state: IAppState) => ({
  summary: selectors.selectOrderSummary(state),
  summaryLoading: selectors.selectCommunication(state, 'summaryLoading'),
});

const mapDispatchToProps = {
  getOrderSummaryByToken: actions.getOrderSummaryByToken,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(OrderPaymentWithFormik), OrderPaymentModule);
