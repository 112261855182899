import {IModule} from 'redux-dynamic-modules';

import {galleryReducer} from './reducer';
import {IGalleryState} from './types';

export interface IGalleryModuleState {
  gallery: IGalleryState;
}

export const GalleryModule: IModule<IGalleryModuleState> = {
  id: 'gallery',
  reducerMap: {
    gallery: galleryReducer
  }
};
