import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';

import {InputField, SelectField, TextAreaField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {castToOption} from 'Common/helpers/OptionHelper';
import {RequiredBy} from 'Common/types';
import {Combination} from 'Common/constants/Combination';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';
import {IAdminHealthVariantRule} from 'Admin/AdminPhenotypes/models/IAdminHealthVariantRule';
import {IUpdateHealthVariantRuleRequest} from 'Admin/AdminPhenotypes/service/models/IServerHealthVariantRule';

import {
  convertToServer,
  convertToServerUpdating,
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/HealthVariantRules/HealthVariantRuleForm/validation';
import {ITraitSequence} from 'Admin/AdminPhenotypes/models/ITraitSequence';
import {useTraitSequenceEditor} from 'SequenceEditor/hooks/useTraitSequenceEditor';
import {IAggregatedHealthVariant} from 'Dictionaries/models/IAggregatedHealthVariant';
import {IAffectionType} from 'Dictionaries/models/IAffectionType';
import {IHealthVariantGroupAdvanced} from 'Dictionaries/models/IHealthVariantGroupAdvanced';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

interface IOwnProps extends IPhenotypeFormExternalProps<IAdminHealthVariantRule, IUpdateHealthVariantRuleRequest> {
  healthVariantGroupDictionary: ISimpleDictionary<IHealthVariantGroupAdvanced>;
  aggregatedHealthVariantDictionary: ISimpleDictionary<IAggregatedHealthVariant>;
  affectionTypeDictionary: ISimpleDictionary<IAffectionType>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function HealthVariantRuleForm(props: Props) {
  const {
    itemId,
    item,
    dictionary,
    healthVariantGroupDictionary,
    healthVariantGroups,
    healthVariantGroupsLoading,
    aggregatedHealthVariantDictionary,
    aggregatedHealthVariants,
    aggregatedHealthVariantsLoading,
    affectionTypeDictionary,
    affectionTypes,
    affectionTypesLoading,
    ...rest
  } = props;

  const isLoading = healthVariantGroupsLoading.isRequesting || aggregatedHealthVariantsLoading.isRequesting;

  const {setFieldValue, values} = props;
  const {getItem} = dictionary.actions;
  const {
    actions: {getItems: getHealthIssueGroups},
  } = healthVariantGroupDictionary;
  const {
    actions: {getItems: getAggregatedHealthVariants},
  } = aggregatedHealthVariantDictionary;
  const {
    actions: {getItems: getAffectionTypes},
  } = affectionTypeDictionary;

  useEffect(() => {
    getHealthIssueGroups();
    getAggregatedHealthVariants();
    getAffectionTypes();
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem, getHealthIssueGroups, getAggregatedHealthVariants, getAffectionTypes]);

  const onSuccessSequenceEditor = useCallback(
    (type: Combination, sequences: ITraitSequence[]) => {
      setFieldValue('type', type);
      setFieldValue('sequences', sequences);
    },
    [setFieldValue]
  );

  const {renderRuleField, renderSequenceEditorModal} = useTraitSequenceEditor(
    values.type,
    values?.sequences ?? [],
    onSuccessSequenceEditor
  );

  return (
    <DictionaryForm {...rest} itemPreloading={isLoading}>
      {renderSequenceEditorModal()}

      <InputField name="name" label="Name" placeholder="Name" />
      <InputField
        name="positionIndex"
        label="Order in Report"
        placeholder="Order in Report"
        className="w-50"
        isRequired
      />
      <SelectField name="groupId" label="Group" options={castToOption(healthVariantGroups)} />
      <SelectField
        name="affectionTypeId"
        label="Affection type"
        placeholder="Affection type"
        options={castToOption(affectionTypes)}
      />
      <SelectField
        name="aggregatorId"
        label="Health variant"
        placeholder="Health variant"
        options={castToOption(aggregatedHealthVariants)}
      />
      <TextAreaField name="description.value" label="Description" placeholder="Description" />
      <InputField name="externalDescriptionUrl" label="URL" placeholder="External URL" />

      {renderRuleField()}
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  const {healthVariantGroupDictionary, aggregatedHealthVariantDictionary, affectionTypeDictionary} = ownProps;

  return {
    healthVariantGroups: healthVariantGroupDictionary.selectors.selectItems(state),
    healthVariantGroupsLoading: healthVariantGroupDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    aggregatedHealthVariants: aggregatedHealthVariantDictionary.selectors.selectItems(state),
    aggregatedHealthVariantsLoading: aggregatedHealthVariantDictionary.selectors.selectCommunication(
      state,
      'itemsLoading'
    ),
    affectionTypes: affectionTypeDictionary.selectors.selectItems(state),
    affectionTypesLoading: affectionTypeDictionary.selectors.selectCommunication(state, 'itemsLoading'),
    ...makePhenotypeFormSelectors(state, ownProps.dictionary),
  };
};

const HealthVariantRuleFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemId, itemLoading}) =>
    itemId && item && !itemLoading.isRequesting
      ? {
          ...item,
          affectionTypeId: item.affectionType?.id || null,
          aggregatorId: item.aggregator?.id || null,
          groupId: item.group?.id || null,
        }
      : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(HealthVariantRuleForm);

const connector = connect(mapStateToProps);
export default connector(memo(HealthVariantRuleFormFormik));
