import React, {memo} from 'react';

import BusinessPortalLayout from 'BusinessPortal/components/common/BusinessPortalLayout/BusinessPortalLayout';
import GeneralInformation from './parts/GeneralInformation/GeneralInformation';
import ContactInformation from './parts/ContactInformation/ContactInformation';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {BusinessPortalAssociationPageModule} from 'BusinessPortal/store/associationPage';
import AssociationAvatar from './parts/AssociationAvatar/AssociationAvatar';
import {PageContainer, PageTitle} from '../common/styled';

const BusinessPortalAssociationPage = () => {
  return (
    <BusinessPortalLayout>
      <PageTitle>Association profile</PageTitle>

      <PageContainer className="h-100">
        <AssociationAvatar />
        <GeneralInformation />
        <ContactInformation />
      </PageContainer>
    </BusinessPortalLayout>
  );
};

export default withDynamicModules(memo(BusinessPortalAssociationPage), BusinessPortalAssociationPageModule);
