import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.adminAssociationHorse;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export const selectHorses = (state: IAppState) => selectState(state).data.horses;
export const selectPagination = (state: IAppState) => selectState(state).ui.pagination;
export const selectHorseDetails = (state: IAppState) => selectState(state).data.horseDetails;
export const selectHorseMedia = (state: IAppState) => selectState(state).data.horseMedia;
export const selectHorseOwner = (state: IAppState) => selectState(state).data.horseOwner;
export const selectHorseParentage = (state: IAppState) => selectState(state).data.horseParentage;
export const selectHorseRegistries = (state: IAppState) => selectState(state).data.horseRegistries;
export const selectHorseHealthIssues = (state: IAppState) => selectState(state).data.horseHealthIssues;
export const selectHorseSample = (state: IAppState) => selectState(state).data.horseSample;
