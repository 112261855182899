import {combineReducers} from 'redux';

import {IBusinessPortalOrderState} from '../types';
import {communicationBusinessPortalOrderReducer} from './communications';
import {dataBusinessPortalOrderReducer} from './data';
import {uiBusinessPortalOrderReducer} from './ui';

export const businessPortalOrderReducer = combineReducers<IBusinessPortalOrderState>({
  communications: communicationBusinessPortalOrderReducer,
  data: dataBusinessPortalOrderReducer,
  ui: uiBusinessPortalOrderReducer,
});
