import {IModule, IModuleStore} from 'redux-dynamic-modules';
import {createAsyncAction, getType, PayloadAction} from 'typesafe-actions';
import {bindActionCreators, combineReducers, Reducer} from 'redux';
import {AxiosResponse} from 'axios';

import {makeCommunicationReducer, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {createActionThunk, createResetAction} from './createActionThunk';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IEntity} from 'Common/models/IEntity';
import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';

import {
  DictionaryApiMethods,
  DictionaryModuleState,
  IApiParamsConverterByMethod,
  IDictionary,
  IDictionaryActions,
  IDictionaryCommunicationState,
  IDictionaryDataState,
  IDictionaryReduxActions,
  IDictionarySelectors,
  IDictionaryState,
  IUIDictionaryState,
} from './types/dictionary';
import {
  CommunicationReducer,
  ICommunicationAction,
  ICommunicationResetAction,
  IFactoryParameters,
} from './types/shared';
import composeReducers from 'Common/store/composeReducers';
import {baseApiParamsConverterByMethod} from './shared/apiParamsConverter';

class DictionaryFactory {
  private isInitialized: Promise<void>;
  private completeInitialize = () => {};
  private reduxStore?: IModuleStore<unknown>;

  constructor() {
    this.isInitialized = new Promise<void>((resolve) => (this.completeInitialize = resolve));
  }

  public init(store: IModuleStore<unknown>) {
    this.reduxStore = store;
    this.completeInitialize();
  }

  public async create<Item extends IEntity, ServerModel, UpdateModel>(
    params: IFactoryParameters<Item, ServerModel>
  ): Promise<IDictionary<Item, UpdateModel>> {
    await this.isInitialized;
    return this.createStore<Item, ServerModel, UpdateModel>(params);
  }

  private createStore<Item, ServerModel, UpdateModel>({
    rootType,
    apiPath,
    convertToClient,
    customApiParamsConverter,
  }: IFactoryParameters<Item, ServerModel>): IDictionary<Item, UpdateModel> {
    const {reducer, actions, selectors} = this.createModule<Item, ServerModel, UpdateModel>(
      rootType,
      apiPath,
      convertToClient,
      customApiParamsConverter
    );
    const dictionary: IModule<{[key in typeof rootType]: IDictionaryState<Item>}> = {
      id: rootType,
      reducerMap: {
        [rootType]: reducer,
      } as any,
    };
    this.reduxStore!.addModule(dictionary);

    return {actions, selectors};
  }

  private createModule<T, S, U>(
    rootType: string,
    apiPath: string,
    convertToClient: (serverModel: S) => T,
    customApiParamsConverter?: IApiParamsConverterByMethod
  ) {
    const reduxActions = this.createActions<T>(rootType);
    const reducer = this.createReducer<T>(reduxActions);
    const selectors = this.createSelectors<T>(rootType);
    const actions = this.createThunks<T, S, U>(apiPath, reduxActions, convertToClient, customApiParamsConverter);
    return {reducer, actions, selectors};
  }

  private createActions<T>(rootType: string): IDictionaryReduxActions<T> {
    const makeAsyncAction = (type: string) =>
      createAsyncAction(
        [`${type}_REQUEST`, () => undefined],
        [`${type}_SUCCESS`, () => undefined],
        [`${type}_FAILURE`, (err: string) => err]
      )();
    const makeAsyncPayloadAction = <P>(type: string) =>
      createAsyncAction(
        [`${type}_REQUEST`, () => undefined],
        [`${type}_SUCCESS`, (res: P) => res],
        [`${type}_FAILURE`, (err: string) => err]
      )();
    const getItems = makeAsyncPayloadAction<IListResponse<T>>(`${rootType}_GET_ITEMS`);
    const getItem = makeAsyncPayloadAction<T>(`${rootType}_GET_ITEM`) as unknown as ICommunicationAction<T>;
    const createItem = makeAsyncAction(`${rootType}_CREATE_ITEM`);
    const updateItem = makeAsyncAction(`${rootType}_UPDATE_ITEM`);
    const deleteItem = makeAsyncAction(`${rootType}_DELETE_ITEM`);
    const resetItem: ICommunicationResetAction = {reset: () => ({type: `${rootType}_RESET_ITEM`})};
    return {getItems, getItem, createItem, updateItem, deleteItem, resetItem};
  }

  private createReducer<T>(actions: IDictionaryReduxActions<T>): Reducer<IDictionaryState<T>> {
    const initialDataState: IDictionaryDataState<T> = {
      items: [],
      itemDetails: null,
    };
    const dataReducer: Reducer<IDictionaryDataState<T>, PayloadAction<string, T | IListResponse<T>>> = (
      state = initialDataState,
      action
    ) => {
      switch (action.type) {
        case getType(actions.getItems.success): {
          return {...state, items: (action.payload as IListResponse<T>).data};
        }
        case getType(actions.getItem.success): {
          return {...state, itemDetails: action.payload as T};
        }
        case actions.resetItem.reset().type: {
          return {...state, itemDetails: null};
        }
        default: {
          return state;
        }
      }
    };

    const getReducer = (
      action: ICommunicationAction | ICommunicationAction<T> | ICommunicationAction<IListResponse<T>>
    ) =>
      makeCommunicationReducer<CommunicationReducer<T>>({
        requestType: getType(action.request),
        successType: getType(action.success),
        failureType: getType(action.failure),
      });

    const getResetReducer = (action: ICommunicationResetAction) => makeResetCommunicationReducer(action.reset().type);

    const communicationReducer = combineReducers<IDictionaryCommunicationState>({
      itemsLoading: getReducer(actions.getItems),
      itemLoading: composeReducers([getReducer(actions.getItem), getResetReducer(actions.resetItem)]),
      itemUpdating: composeReducers([getReducer(actions.updateItem), getResetReducer(actions.resetItem)]),
      itemCreating: composeReducers([getReducer(actions.createItem), getResetReducer(actions.resetItem)]),
      itemDeleting: getReducer(actions.deleteItem),
    });

    const initialState: IUIDictionaryState = {
      pagination: {countPerPage: MIN_COUNT_PER_PAGE, currentPage: 1, pageCount: 1, totalItemsCount: 1},
    };

    const uiReducer: Reducer<IUIDictionaryState, PayloadAction<string, IListResponse<T>>> = (
      state = initialState,
      action
    ) => {
      switch (action.type) {
        case getType(actions.getItems.success): {
          return {...state, pagination: action.payload.pagination};
        }
        default: {
          return state;
        }
      }
    };
    return combineReducers({
      data: dataReducer,
      communication: communicationReducer,
      ui: uiReducer,
    });
  }

  private createSelectors<T>(rootType: string): IDictionarySelectors<T> {
    const selectItems = (state: DictionaryModuleState<T>) =>
      (state[rootType] as {data: IDictionaryDataState<T>}).data.items;

    const selectItemDetails = (state: DictionaryModuleState<T>) =>
      (state[rootType] as {data: IDictionaryDataState<T>}).data.itemDetails;

    const selectCommunication = (state: DictionaryModuleState<T>, key: keyof IDictionaryCommunicationState) =>
      (state[rootType] as {communication: IDictionaryCommunicationState}).communication[key];

    const selectPagination = (state: DictionaryModuleState<T>) =>
      (state[rootType] as {ui: IUIDictionaryState}).ui.pagination;

    return {selectItems, selectItemDetails, selectCommunication, selectPagination};
  }

  private createThunks<T, S, U>(
    apiPath: string,
    actions: IDictionaryReduxActions<T>,
    converter: (serverModel: S) => T,
    customApiParamsConverter?: IApiParamsConverterByMethod
  ): IDictionaryActions<T, U> {
    const apiParamsConverter = customApiParamsConverter || baseApiParamsConverterByMethod;

    const getItemsMethod = this.createApiMethod<IRequestParams, S, T>(apiPath, 'get', apiParamsConverter, converter);
    const getItemMethod = this.createApiMethod<S, T>(apiPath + 'Details', 'get', apiParamsConverter, converter, true);
    const createMethod = this.createApiMethod<U>(apiPath + 'Create', 'post', apiParamsConverter);
    const updateMethod = this.createApiMethod<U>(apiPath + 'Update', 'put', apiParamsConverter);
    const deleteMethod = this.createApiMethod(apiPath + 'Delete', 'delete', apiParamsConverter);

    const {dispatch} = this.reduxStore!;
    return bindActionCreators(
      {
        getItems: createActionThunk(getItemsMethod, actions.getItems),
        getItem: createActionThunk(getItemMethod, actions.getItem),
        createItem: createActionThunk(createMethod, actions.createItem),
        updateItem: createActionThunk(updateMethod, actions.updateItem),
        deleteItem: createActionThunk(deleteMethod, actions.deleteItem),
        resetItem: createResetAction(actions.resetItem),
      },
      dispatch
    );
  }

  private createApiMethod<P, S, R>(
    path: string,
    method: 'get',
    apiParamsConverterByMethod: IApiParamsConverterByMethod,
    converter: (serverModel: S) => R
  ): (args: P) => Promise<IListResponse<R>>;
  private createApiMethod<S, R>(
    path: string,
    method: 'get',
    apiParamsConverterByMethod: IApiParamsConverterByMethod,
    converter: (serverModel: S) => R,
    isEntityDetails: true
  ): (id: IEntity['id']) => Promise<R>;
  private createApiMethod<P>(
    path: string,
    method: 'post' | 'put',
    apiParamsConverterByMethod: IApiParamsConverterByMethod
  ): (args: P) => Promise<void>;
  private createApiMethod(
    path: string,
    method: 'delete',
    apiParamsConverterByMethod: IApiParamsConverterByMethod
  ): (id: IEntity['id']) => Promise<void>;
  private createApiMethod<P, S, R>(
    path: string,
    method: DictionaryApiMethods,
    apiParamsConverterByMethod: IApiParamsConverterByMethod,
    converter?: (serverModel: S) => R,
    isEntityDetails?: boolean
  ) {
    const axiosMethod: (path: string, params?: P) => Promise<AxiosResponse<IObjectResponse<S> | IListResponse<S>>> =
      axiosWrapper[method];
    return async (params: P): Promise<IListResponse<R> | R | void> => {
      const response = await (isEntityDetails
        ? axiosMethod(`${path}/${params as unknown as IEntity['id']}`)
        : axiosMethod(path, apiParamsConverterByMethod[method](params)));

      if (!converter || method !== 'get') {
        return;
      }
      if (isEntityDetails) {
        return converter(response.data.data as S);
      }

      return {...(response.data as IListResponse<S>), data: (response.data.data as S[]).map(converter)};
    };
  }
}

export default new DictionaryFactory();
