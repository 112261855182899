import React, {memo, useCallback} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import {IconName} from 'Icon/components/Icon';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IOnSuccessedEntity} from 'Common/models/IOnSuccessedEntity';
import withFavoriteHorseActions, {
  getWithFavoriteHorseActionsModules,
  IWithFavoriteHorseActionsProps,
} from 'Common/helpers/withFavoriteHorseActions';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import TwoActionButton, {ButtonPosition} from 'Common/components/Controls/Buttons/TwoActionButton';

const Root = styled.div<{position: string}>`
  padding: ${({position}) => (position === 'Left' ? '0 0 0 8px' : '0 8px 0 0')};
  flex-direction: ${({position}) => (position === 'Left' ? 'row' : 'row-reverse')};
`;

type FavoriteHorseType = {id: number; isFavorite: boolean};

interface IExternalProps extends IOnSuccessedEntity {
  horse: FavoriteHorseType;
  position: ButtonPosition;
  isShowLabel?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

type AllProps = IExternalProps & IWithFavoriteHorseActionsProps;

const FavoriteHorseIconButton = (props: AllProps) => {
  const {
    horse: {id, isFavorite},
    onSuccess,
    position,
    isShowLabel = true,
    addFavoriteHorse,
    deleteFavoriteHorse,
    favoriteHorseAdding,
    favoriteHorseDeleting,
    style,
    className,
  } = props;

  useErrorCommunicationToToast(favoriteHorseAdding, undefined, 1);
  useErrorCommunicationToToast(favoriteHorseDeleting, undefined, 2);

  const handlerActiveAction = useCallback(
    async (e) => {
      e.stopPropagation();
      await deleteFavoriteHorse(id);
    },
    [deleteFavoriteHorse, id]
  );

  const handlerInactiveAction = useCallback(
    async (e) => {
      e.stopPropagation();
      await addFavoriteHorse(id);
    },
    [addFavoriteHorse, id]
  );

  return (
    <Root
      style={style}
      className={classNames('d-flex align-items-center justify-content-start', className)}
      position={position}
    >
      <TwoActionButton
        isActive={isFavorite}
        labelActive="Favorite"
        labelInactive="Add to favorites"
        isShowLabel={isShowLabel}
        position={position}
        onSuccess={onSuccess}
        onActiveAction={handlerActiveAction}
        onInactiveAction={handlerInactiveAction}
        iconNameActive={IconName.DeleteFromFavorites}
        iconNameActiveHover={IconName.DeleteFromFavoritesHover}
        iconNameInactive={IconName.AddToFavorites}
        iconNameInactiveHover={IconName.AddToFavoritesHover}
      />
    </Root>
  );
};

const Connected = withFavoriteHorseActions(memo(FavoriteHorseIconButton));

export default withDynamicModules(Connected, getWithFavoriteHorseActionsModules());
