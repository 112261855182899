import {Reducer} from 'redux';

import {
  AdminOnlineReportActions,
  deleteGeneticNoteActionTypes,
  getAbilitiesActionTypes,
  getAbilityDetailedActionTypes,
  getCoatColorActionTypes,
  getCoatColorDetailedActionTypes,
  getGeneticVariantsActionTypes,
  getHealthVariantDetailedActionTypes,
  getHealthVariantsActionTypes,
  getHorseBreedPanelsActionTypes,
  getHorseDetailsActionTypes,
  getHorseInfoActionTypes,
  getHorseOwnerActionTypes,
  getRequiredTestsActionTypes,
  getReviewSummaryAbilitiesActionTypes,
  getReviewSummaryColorsActionTypes,
  getReviewSummaryHealthIssuesActionTypes,
  getSummaryAbilitiesActionTypes,
  getSummaryColorsActionTypes,
  getSummaryHealthIssuesActionTypes,
  IAdminOnlineReportState,
  resetOnlineReportDataActionTypes,
  resetRequiredTestsActionTypes,
  updateGeneticNoteActionTypes,
} from '../types';
import {GeneticNoteType} from 'OnlineReport/models/Summary/IGeneticNote';

const initialState: IAdminOnlineReportState['data'] = {
  horseInfo: null,
  horseOwner: null,
  horseDetails: null,
  summaryAbilities: null,
  summaryColors: null,
  summaryHealthIssues: null,
  requiredTests: [],
  coatColors: null,
  coatColorsDetailed: null,
  healthVariants: null,
  healthVariantsDetailed: null,
  abilities: null,
  abilityDetailed: null,
  geneticVariants: null,
  horseBreedPanels: [],
};

type requiredGeneticNoteTypes =
  | GeneticNoteType.Abilities
  | GeneticNoteType.Genotypes
  | GeneticNoteType.Coat
  | GeneticNoteType.HealthIssues;

const mapStateByType: Record<requiredGeneticNoteTypes, keyof IAdminOnlineReportState['data']> = {
  [GeneticNoteType.Abilities]: 'summaryAbilities',
  [GeneticNoteType.Genotypes]: 'geneticVariants',
  [GeneticNoteType.Coat]: 'summaryColors',
  [GeneticNoteType.HealthIssues]: 'summaryHealthIssues',
};

export const dataReducer: Reducer<IAdminOnlineReportState['data'], AdminOnlineReportActions> = (
  state = initialState,
  action: AdminOnlineReportActions
) => {
  switch (action.type) {
    case getHorseInfoActionTypes.REQUEST: {
      return {...state, horseInfo: null};
    }
    case getHorseInfoActionTypes.SUCCESS: {
      return {...state, horseInfo: action.payload};
    }

    case getHorseOwnerActionTypes.REQUEST: {
      return {...state, horseOwner: null};
    }
    case getHorseOwnerActionTypes.SUCCESS: {
      return {...state, horseOwner: action.payload};
    }

    case getHorseDetailsActionTypes.REQUEST: {
      return {...state, horseDetails: null};
    }
    case getHorseDetailsActionTypes.SUCCESS: {
      return {...state, horseDetails: action.payload};
    }

    case getSummaryAbilitiesActionTypes.REQUEST:
    case getReviewSummaryAbilitiesActionTypes.REQUEST: {
      return {...state, summaryAbilities: null};
    }
    case getSummaryAbilitiesActionTypes.SUCCESS:
    case getReviewSummaryAbilitiesActionTypes.SUCCESS: {
      return {...state, summaryAbilities: action.payload};
    }

    case getSummaryColorsActionTypes.REQUEST:
    case getReviewSummaryColorsActionTypes.REQUEST: {
      return {...state, summaryColors: null};
    }
    case getSummaryColorsActionTypes.SUCCESS:
    case getReviewSummaryColorsActionTypes.SUCCESS: {
      return {...state, summaryColors: action.payload};
    }

    case getSummaryHealthIssuesActionTypes.REQUEST:
    case getReviewSummaryHealthIssuesActionTypes.REQUEST: {
      return {...state, summaryHealthIssues: null};
    }
    case getSummaryHealthIssuesActionTypes.SUCCESS:
    case getReviewSummaryHealthIssuesActionTypes.SUCCESS: {
      return {...state, summaryHealthIssues: action.payload};
    }

    case getGeneticVariantsActionTypes.REQUEST: {
      return {...state, geneticVariants: initialState.geneticVariants};
    }
    case getGeneticVariantsActionTypes.SUCCESS: {
      return {...state, geneticVariants: action.payload};
    }

    case getCoatColorActionTypes.REQUEST: {
      return {...state, coatColors: null};
    }
    case getCoatColorActionTypes.SUCCESS: {
      return {...state, coatColors: action.payload};
    }
    case getCoatColorDetailedActionTypes.REQUEST: {
      return {...state, coatColorsDetailed: state.coatColorsDetailed};
    }
    case getCoatColorDetailedActionTypes.SUCCESS: {
      return {
        ...state,
        coatColorsDetailed:
          !state.coatColorsDetailed || state.coatColorsDetailed.length < 1
            ? [action.payload]
            : [...state.coatColorsDetailed, action.payload],
      };
    }

    case getHealthVariantsActionTypes.REQUEST: {
      return {...state, healthVariants: null};
    }
    case getHealthVariantsActionTypes.SUCCESS: {
      return {...state, healthVariants: action.payload};
    }
    case getHealthVariantDetailedActionTypes.SUCCESS: {
      return {
        ...state,
        healthVariantsDetailed:
          !state.healthVariantsDetailed || state.healthVariantsDetailed.length < 1
            ? [action.payload]
            : [...state.healthVariantsDetailed, action.payload],
      };
    }

    case getAbilitiesActionTypes.REQUEST: {
      return {...state, abilities: null};
    }
    case getAbilitiesActionTypes.SUCCESS: {
      return {...state, abilities: action.payload};
    }
    case getAbilityDetailedActionTypes.SUCCESS: {
      return {
        ...state,
        abilityDetailed:
          !state.abilityDetailed || state.abilityDetailed.length < 1
            ? [action.payload]
            : [...state.abilityDetailed, action.payload],
      };
    }

    case getRequiredTestsActionTypes.REQUEST: {
      return {...state, requiredTests: initialState.requiredTests};
    }
    case getRequiredTestsActionTypes.SUCCESS: {
      return {...state, requiredTests: action.payload};
    }

    case updateGeneticNoteActionTypes.SUCCESS: {
      if (action.payload.type) {
        const stateFieldName = mapStateByType[action.payload.type];
        return {
          ...state,
          [stateFieldName]: {
            ...state[stateFieldName],
            note: {...state[stateFieldName].note, value: action.payload.value, isPinned: action.payload.isPinned},
          },
        };
      }
      return {...state};
    }

    case deleteGeneticNoteActionTypes.SUCCESS: {
      if (action.payload.type) {
        const stateFieldName = mapStateByType[action.payload.type];
        return {
          ...state,
          [stateFieldName]: {...state[stateFieldName], note: null},
        };
      }
      return {...state};
    }

    case getHorseBreedPanelsActionTypes.REQUEST: {
      return {...state, horseBreedPanels: initialState.horseBreedPanels};
    }
    case getHorseBreedPanelsActionTypes.SUCCESS: {
      return {...state, horseBreedPanels: action.payload};
    }

    case resetRequiredTestsActionTypes.RESET: {
      return {...state, requiredTests: initialState.requiredTests};
    }

    case resetOnlineReportDataActionTypes.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
