import moment from 'moment';

import {IServerDemographicsReport} from '../types/report';
import {convertToServerDate} from 'Common/helpers/DateHelper';
import {BACKEND_DATE_TIME, FRONTEND_DATE_TIME} from 'Common/constants/Date';

export function convertDemographicsToRequest(value: {from: string; to: string}): IServerDemographicsReport {
  const currentDate = new Date();
  currentDate.setHours(23);
  currentDate.setMinutes(59);
  currentDate.setSeconds(59);
  const serverCurrentDate = moment(currentDate, BACKEND_DATE_TIME);

  const toDate = new Date(moment(value.to, FRONTEND_DATE_TIME).toString());
  toDate.setHours(23);
  toDate.setMinutes(59);
  toDate.setSeconds(59);

  return {
    from: convertToServerDate(value.from, true) || moment().toString(),
    to: convertToServerDate(toDate, true) || serverCurrentDate.toString()
  };
}
