import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminOrders/index';
import Loading from 'Loading/components/Loading';
import PackagesPrice, {PackageType} from 'Admin/AdminDashboard/components/shared/PackagesPrice/PackagesPrice';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {OrderStatusField} from 'Order/models/IOrderStatusFields';

const ShowRequiredTestsSection = styled.div`
  margin-top: 16px;
`;

const Hint = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  color: ${ColorPalette.gray44};
  margin-bottom: 8px;
`;

const ShowRequiredTestsButton = styled(PrimaryButton)`
  width: 80%;
`;

interface IOwnProps {
  orderId: number;
  openRequiredTestsModal(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IOwnProps;

function PackageDetails(props: Props) {
  const {orderId, getOrderTestsDetails, orderTestsDetails, orderTestsDetailsLoading, openRequiredTestsModal} = props;

  useEffect(() => {
    if (orderId) {
      getOrderTestsDetails(orderId);
    }
  }, [getOrderTestsDetails, orderId]);

  const isLoading = orderTestsDetailsLoading.isRequesting;

  const prices = orderTestsDetails.packages
    .map((x) => ({...x, type: PackageType.Package}))
    .concat(orderTestsDetails.tests.map((x) => ({...x, type: PackageType.Test})));

  const isButtonShow = orderTestsDetails.status === OrderStatusField.Waiting;

  return (
    <div className="position-relative">
      {isLoading && <Loading />}
      <PackagesPrice total={orderTestsDetails.totalPrice} packagesPrices={prices} isAssociation={true} />
      {isButtonShow && (
        <ShowRequiredTestsSection className="d-flex flex-column align-items-center">
          <Hint>Incomplete Tests</Hint>
          <ShowRequiredTestsButton size="small" variant="outlined" onClick={openRequiredTestsModal}>
            Show required tests list
          </ShowRequiredTestsButton>
        </ShowRequiredTestsSection>
      )}
    </div>
  );
}

const mapStateToProps = (state: IAppState) => ({
  orderTestsDetails: selectors.selectOrderTestsDetails(state),
  orderTestsDetailsLoading: selectors.selectCommunication(state, 'orderTestsDetailsLoading'),
});

const mapDispatchToProps = {
  getOrderTestsDetails: actions.getOrderTestsDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default memo(connector(PackageDetails));
