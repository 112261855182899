import React, {memo, useEffect} from 'react';
import styled from 'styled-components';
import {FieldArray, Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {SearchHorseInput} from 'Horse/components';
import IFoundExcludeHorse from 'ExcludeHorse/models/IFoundExcludeHorse';
import ExcludeListItem from './ExcludeListItem';
import {convertFoundHorsesToFormValues, IFormValues, validationSchema} from './validation';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {IAppState} from 'Common/store/IAppState';
import {ExcludeHorseModule} from 'ExcludeHorse/store/excludeHorseModule';
import {actions, selectors} from 'ExcludeHorse/store';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import Loading from 'Loading/components/Loading';

const Root = styled.div`
  margin-right: 24px;
`;

const SearchWrapper = styled.div`
  margin-bottom: 8px;
`;

interface IOwnProps {
  onApply(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function ExcludeList(props: Props) {
  const {
    getExcludedHorses,
    resetSimpleExcludeHorses,
    excludedHorsesLoading,
    addExcludeHorse,
    deleteExcludeHorse,
    excludeHorseAdding,
    excludeHorseDeleting,
  } = props;
  const {values} = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetSimpleExcludeHorses, []);

  useEffect(() => {
    getExcludedHorses();
  }, [getExcludedHorses]);

  const isLoading = [excludedHorsesLoading, excludeHorseAdding, excludeHorseDeleting].some((x) => x.isRequesting);

  return (
    <>
      <ModalWindowHeader>Excluded horses list</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <Root>
          <ModalWindowFormContent scrollable={true}>
            {isLoading && <Loading />}

            <FieldControlContainer>
              <FieldArray
                name="excludedHorses"
                render={(arrayHelper) => {
                  const onSelectHorse = (horse: IFoundExcludeHorse) => {
                    if (!values.excludedHorses.some((x) => x.id === horse.id)) {
                      arrayHelper.push(horse);
                      addExcludeHorse(horse.id);
                    }
                  };

                  return (
                    <>
                      <SearchWrapper>
                        <SearchHorseInput onSelectHorse={onSelectHorse} isClearInputAfterSelect={true} />
                      </SearchWrapper>
                      {values.excludedHorses.map((horse, i) => (
                        <ExcludeListItem
                          horse={horse}
                          key={i}
                          onDelete={deleteExcludeHorse}
                          onReload={addExcludeHorse}
                        />
                      ))}
                    </>
                  );
                }}
              />
            </FieldControlContainer>
          </ModalWindowFormContent>
        </Root>
        <ModalWindowFooter>
          <ModalWindowButton type="submit">Close</ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const ExcludeListFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({excludedHorses}) => convertFoundHorsesToFormValues(excludedHorses),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {
      props: {onApply},
    } = formikBag;

    onApply();
  },
  enableReinitialize: true,
})(ExcludeList);

const mapStateToProps = (state: IAppState) => ({
  excludedHorses: selectors.selectSimpleExcludeHorses(state),
  excludedHorsesLoading: selectors.selectCommunication(state, 'simpleExcludeHorsesLoading'),
  excludeHorseAdding: selectors.selectCommunication(state, 'excludeHorseAdding'),
  excludeHorseDeleting: selectors.selectCommunication(state, 'excludeHorseDeleting'),
});

const mapDispatchToProps = {
  getExcludedHorses: actions.getSimpleExcludeHorses,
  addExcludeHorse: actions.addExcludeHorse,
  deleteExcludeHorse: actions.deleteExcludeHorse,
  resetSimpleExcludeHorses: actions.resetSimpleExcludeHorses,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(memo(ExcludeListFormik)), ExcludeHorseModule);
