import React, {memo} from 'react';

import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {NoData} from 'BusinessPortal/components/common/NoData/NoData';
import {Label, Value, Wrapper} from 'BusinessPortal/components/shared/StyledComponents';

interface IProps {
  email?: string;
}

const ChangeEmailShow = (props: IEditableSection<IProps>) => {
  const {data} = props;

  if (!data?.email) {
    return <NoData />;
  }

  const {email} = data;

  return (
    <div>
      <Wrapper>
        <Label>Email:</Label>
        <Value>{email}</Value>
      </Wrapper>
    </div>
  );
};

export default memo(ChangeEmailShow);
