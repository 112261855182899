import {Reducer} from 'redux';

import {
  addFavoriteHorseActionTypes,
  deleteFavoriteHorseActionTypes,
  FavoriteHorsesActions,
  getFavoriteHorsesActionTypes,
  IFavoriteHorseState,
} from '../types';

export const initialStateList: IFavoriteHorseState['data'] = {
  favoriteHorses: [],
};

export const dataReducer: Reducer<IFavoriteHorseState['data'], FavoriteHorsesActions> = (
  state = initialStateList,
  action: FavoriteHorsesActions
) => {
  switch (action.type) {
    case getFavoriteHorsesActionTypes.SUCCESS: {
      return {...state, favoriteHorses: action.payload.data};
    }
    case addFavoriteHorseActionTypes.SUCCESS:
      return {
        ...state,
        favoriteHorses: state.favoriteHorses.map((horse) => ({
          ...horse,
          isFavorite: horse.id === action.payload ? true : horse.isFavorite,
        })),
      };
    case deleteFavoriteHorseActionTypes.SUCCESS:
      return {
        ...state,
        favoriteHorses: state.favoriteHorses.map((horse) => ({
          ...horse,
          isFavorite: horse.id === action.payload ? false : horse.isFavorite,
        })),
      };
    default: {
      return state;
    }
  }
};
