import {IModule} from 'redux-dynamic-modules';

import {horseBreedPanelsReducer} from './reducer';
import {IHorseBreedPanelState} from './types';

export interface IHorseBreedPanelsModuleState {
  horseBreedPanels: IHorseBreedPanelState;
}

export const HorseBreedPanelsModule: IModule<IHorseBreedPanelsModuleState> = {
  id: 'horseBreedPanels',
  reducerMap: {
    horseBreedPanels: horseBreedPanelsReducer
  }
};
