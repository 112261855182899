import React from 'react';

import {useRejectHorseTransferModal} from './parts/useRejectHorseTransferModal';
import {useHorseTransferDetailsModal} from './parts/useHorseTransferDetailsModal';
import {useAceptTransferModal} from './parts/useAceptTransferModal';

interface IProps {
  onSuccess(): void;
}

export function useHorseTransferActions(props: IProps) {
  const {onSuccess} = props;

  const {acceptTransferModal, openAcceptTransferModal} = useAceptTransferModal({
    onSuccess,
  });
  const {openRejectHorseTransferModal, rejectHorseTransferModal} = useRejectHorseTransferModal({onSuccess});
  const {horseTransferDetailsModal, openHorseTransferDetailsModal} = useHorseTransferDetailsModal({});

  const horseActionsModal = (
    <>
      {acceptTransferModal}
      {rejectHorseTransferModal}
      {horseTransferDetailsModal}
    </>
  );

  return {
    openAcceptTransferModal,
    acceptTransferModal,
    openRejectHorseTransferModal,
    rejectHorseTransferModal,
    horseActionsModal,
    openHorseTransferDetailsModal,
  };
}
