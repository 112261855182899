import React, {useEffect} from 'react';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps} from 'formik/dist/types';
import styled from 'styled-components';

import {ImageUploaderField, InputField} from 'Common/components/FormFields';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IFormValues, initialValue, validationSchema} from './validation';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminOwners/index';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {
  convertToClient,
  convertToServer,
  convertToServerCreate,
} from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerFormEdit/parts/OwnerGeneralInfoSection/converters';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {MutableField, MutableFieldsGroup} from 'Common/components/StyledComponents/StyledComponents';
import {IconName} from 'Icon/components/Icon';
import {FormType} from 'Common/constants/FormType';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {ModalWindowFooter, ModalWindowFormContent, ModalWindowHeader} from 'Common/components/Modal/shared';
import OrganizationSearchField from 'Admin/AdminAssociations/components/Employees/EmployeeForm/parts/OrganizationSearchField';

const OwnerSection = styled.div`
  margin-bottom: 16px;
`;

interface IExternalProps {
  ownerId?: number;
  onSuccess?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const OwnerForm = (props: AllProps) => {
  const {isValid, isSubmitting, values} = props;
  const {
    resetOwnerDetails,
    getOwner,
    ownerId,
    ownerLoading,
    ownerUpdating,
    ownerCreating,
    ownerAvatarUpdating,
    ownerAvatarDeleting,
  } = props;

  const isRequesting =
    ownerLoading.isRequesting ||
    ownerUpdating.isRequesting ||
    ownerCreating.isRequesting ||
    ownerAvatarUpdating.isRequesting ||
    ownerAvatarDeleting.isRequesting;
  const formType = values.id ? FormType.edit : FormType.create;
  const header = formType === FormType.edit ? 'Edit owner' : 'Create owner';
  const saveButtonHeader = formType === FormType.edit ? 'Save changes' : 'Create an Owner';

  useEffect(() => {
    ownerId && getOwner(ownerId);
    return resetOwnerDetails;
  }, [getOwner, ownerId, resetOwnerDetails]);

  useErrorCommunicationToToast(ownerLoading);
  useErrorCommunicationToToast(ownerUpdating);
  useErrorCommunicationToToast(ownerCreating);
  useErrorCommunicationToToast(ownerAvatarUpdating);
  useErrorCommunicationToToast(ownerAvatarDeleting);

  return (
    <Form>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <ModalWindowFormContent>
        {isRequesting && <Loading />}
        <ImageUploaderField
          name="avatar"
          memoized={true}
          width="104px"
          height="104px"
          isRound={true}
          actionButtonsContainerHeight="100%"
          iconProps={{name: IconName.OwnerDefaultAvatar, width: 100, height: 100}}
          inputStyle={{marginBottom: 24}}
        />
        {formType === FormType.create && (
          <OwnerSection>
            <OrganizationSearchField name="organizationId" label="Organization" isRequired />
          </OwnerSection>
        )}
        <FormControlContainer title="General Information">
          <InputField
            isRequired={true}
            name="firstName"
            type="text"
            label="First Name"
            placeholder="First name"
            autoComplete="off"
            memoized={true}
          />
          <InputField
            isRequired={true}
            name="lastName"
            type="text"
            label="Last Name"
            placeholder="Last name"
            autoComplete="off"
            memoized={true}
          />
          <InputField
            name="email"
            type="email"
            label="Email"
            placeholder="Email"
            autoComplete="off"
            memoized={true}
            isRequired
          />
          <InputField name="phone" type="text" label="Phone" placeholder="Phone" autoComplete="off" memoized={true} />
        </FormControlContainer>
        <FormControlContainer title="Postal Address">
          <InputField name="address.street" label="Address" placeholder="Enter your address" />
          <MutableFieldsGroup indent="16px">
            <MutableField width={50}>
              <InputField name="address.city" label="City" placeholder="City" />
            </MutableField>
            <MutableField width={50}>
              <InputField name="address.state" label="State" placeholder="State" />
            </MutableField>
          </MutableFieldsGroup>
          <MutableFieldsGroup indent="16px">
            <MutableField width={50}>
              <InputField name="address.zip" label="ZIP / POSTAL" placeholder="ZIP / POSTAL" />
            </MutableField>
            <MutableField width={50}>
              <InputField name="address.country" label="Country" placeholder="Country" />
            </MutableField>
          </MutableFieldsGroup>
        </FormControlContainer>
      </ModalWindowFormContent>
      <ModalWindowFooter>
        <PrimaryButton className="w-100" size="medium" type="submit" disabled={!isValid} isLoading={isSubmitting}>
          {saveButtonHeader}
        </PrimaryButton>
      </ModalWindowFooter>
    </Form>
  );
};

const OwnerFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({owner}) => (owner ? convertToClient(owner) : initialValue),
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {createOwner, updateOwner, updateOwnerAvatar, deleteOwnerAvatar, onSuccess} = formikBag.props;

    const avatarHandler = async (ownerId: number) => {
      const currentAvatar = values.avatar;

      if (!currentAvatar) {
        await deleteOwnerAvatar(ownerId);
      }
      if (currentAvatar?.isLocal && currentAvatar?.file) {
        await updateOwnerAvatar(ownerId, currentAvatar.file);
      }
    };

    try {
      if (values.id) {
        await updateOwner(convertToServer(values));
        await avatarHandler(values.id);
      } else {
        const ownerId = await createOwner(convertToServerCreate(values));
        await avatarHandler(ownerId);
      }
    } catch (e) {
      return e;
    }

    onSuccess && onSuccess();
  },
  enableReinitialize: true,
})(OwnerForm);

const mapStateToProps = (state: IAppState) => ({
  owner: selectors.selectOwnerDetails(state),
  ownerLoading: selectors.selectCommunication(state, 'ownerDetailsLoading'),
  ownerUpdating: selectors.selectCommunication(state, 'ownerUpdating'),
  ownerCreating: selectors.selectCommunication(state, 'ownerCreating'),
  ownerAvatarUpdating: selectors.selectCommunication(state, 'ownerAvatarUpdating'),
  ownerAvatarDeleting: selectors.selectCommunication(state, 'ownerAvatarDeleting'),
});

const mapDispatchToProps = {
  getOwner: actions.getOwnerDetails,
  updateOwner: actions.updateOwner,
  createOwner: actions.createOwner,
  updateOwnerAvatar: actions.updateOwnerAvatar,
  deleteOwnerAvatar: actions.deleteOwnerAvatar,
  resetOwnerDetails: actions.resetOwnerDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(OwnerFormFormik);
