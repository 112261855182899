import React from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';

const NebulaContainer = styled.div`
  opacity: 0.8;
  background: ${ColorPalette.white0};
  pointer-events: none;
`;

interface IProps {
  active?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

type AllProps = React.PropsWithChildren<IProps>;

const Nebula = ({children, active, style, className}: AllProps) => {
  return active ? (
    <NebulaContainer style={{...style}} className={className}>
      {children}
    </NebulaContainer>
  ) : (
    <>{children}</>
  );
};

export default Nebula;
