import {IModule} from 'redux-dynamic-modules';

import {ICreateOrderState} from './types';
import {ordersReducer} from './reducer';

export interface ICreateOrderModuleState {
  createOrder: ICreateOrderState;
}

export const CreateOrderModule: IModule<ICreateOrderModuleState> = {
  id: 'createOrder',
  reducerMap: {
    createOrder: ordersReducer
  }
};
