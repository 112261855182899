import React, {memo} from 'react';
import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {IInconclusiveHealthIssue} from 'Admin/AdminPhenotypes/models/IInconclusiveHealthIssue';

import HealthIssuesFormFrom from './InconclusiveHealthIssueForm/InconclusiveHealthIssueForm';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';

function InconclusiveHealthIssues() {
  const {adminInconclusiveHealthIssue: dictionary} = useDictionaries();

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={HealthIssuesFormFrom}>
        {({onDelete, onEdit}) => (
          <>
            <Cell<IInconclusiveHealthIssue> dataKey="id" width="10%" header="ID" render={({id}) => `#${id}`} />
            <Cell<IInconclusiveHealthIssue> dataKey="name" width="35%" header="Name" render={({name}) => name} />
            <Cell<IInconclusiveHealthIssue>
              dataKey="description"
              sortable={false}
              width="50%"
              header="Description"
              render={({description}) => description?.value}
            />
            <Cell<IInconclusiveHealthIssue>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} deleteAction={onDelete} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(InconclusiveHealthIssues);
