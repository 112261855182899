import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';

import {IListResponse, IPagination} from 'Common/models/IResponse';
import {IAdminPurchasableTest} from 'Admin/AdminAssociations/models/IAdminPurchasableTest';
import {Nullable} from 'Common/types';

const STATE_NAME = 'adminPurchasableTests';

export interface IAdminPurchasableTestState {
  data: {
    purchasableTests: IAdminPurchasableTest[];
    purchasableTestDetail: Nullable<IAdminPurchasableTest>;
  };
  communications: {
    purchasableTestsLoading: ICommunication;
    purchasableTestCreating: ICommunication;
    purchasableTestUpdating: ICommunication;
    purchasableTestDetailsLoading: ICommunication;
    changePurchasableTestStatusRequesting: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getPurchasableTestsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_PURCHASABLE_TESTS');

export const getPurchasableTestByIdActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_PURCHASABLE_TEST_BY_ID');

export const createPurchasableTestActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_PURCHASABLE_TEST');

export const updatePurchasableTestActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_PURCHASABLE_TEST');

export const changePurchasableTestStatusActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CHANGE_PURCHASABLE_TEST_STATUS'
);

export const resetPurchasableTestDetailsActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'PURCHASABLE_TEST_DETAIL'
);

export type resetPurchasableTestDetailsActions = MakeResetCommunicationActions<
  typeof resetPurchasableTestDetailsActionTypes
>;

export type getPurchasableTestsActions = MakeCommunicationActions<
  typeof getPurchasableTestsActionTypes,
  {success: IListResponse<IAdminPurchasableTest>}
>;

export type getPurchasableTestByIdActions = MakeCommunicationActions<
  typeof getPurchasableTestByIdActionTypes,
  {success: IAdminPurchasableTest}
>;

export type changePurchasableTestStatusActions = MakeCommunicationActions<
  typeof changePurchasableTestStatusActionTypes,
  {success: {purchasableTestId: number}}
>;

export type createPurchasableTestActions = MakeCommunicationActions<typeof createPurchasableTestActionTypes, {}>;

export type updatePurchasableTestActions = MakeCommunicationActions<typeof updatePurchasableTestActionTypes, {}>;

export type AdminPurchasableTestsActions =
  | getPurchasableTestsActions
  | getPurchasableTestByIdActions
  | createPurchasableTestActions
  | updatePurchasableTestActions
  | changePurchasableTestStatusActions
  | resetPurchasableTestDetailsActions;
