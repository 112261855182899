import {IAppState} from 'Common/store/IAppState';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {ICommunication} from 'Common/store/utils/communication';
import {HorseListModule} from 'HorseProfile/store/horseList/horseListModule';
import {actions as horseProfileActions, selectors as horseProfileSelectors} from 'Horse/store/horse';
import {
  actions as adminAssociationHorseProfileActions,
  AdminAssociationHorseProfileModule,
  selectors as adminAssociationHorseProfileSelectors,
} from 'Admin/AdminAssociations/store/adminHorseProfile';
import {
  actions as businessPortalHorseProfileActions,
  BusinessPortalHorseProfileModule,
  selectors as businessPortalHorseProfileSelectors,
} from 'BusinessPortal/store/horseProfile';
import {VisitorType} from 'Common/constants/VisitorType';
import {IHorseOwner} from 'Horse/models/IHorseOwner';
import {Nullable} from 'Common/types';
import {IOwnerHorse} from 'Shared/models/IOwnerHorse';

export interface IState {
  horseOwner: Nullable<IHorseOwner>;
  horses: IOwnerHorse[];

  horsesLoading: ICommunication;
  horseOwnerLoading: ICommunication;
}

export interface IDispatch {
  getHorseOwner(horseId: number): void;
  getOwnerHorses(horseId: number): void;
}

const userDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    horseOwner: horseProfileSelectors.selectHorseOwner(state),
    horses: horseProfileSelectors.selectOwnerHorses(state),
    horseOwnerLoading: horseProfileSelectors.selectCommunication(state, 'horseOwnerLoading'),
    horsesLoading: horseProfileSelectors.selectCommunication(state, 'ownerHorsesLoading'),
  }),
  dispatch: {
    getHorseOwner: horseProfileActions.getHorseOwner,
    getOwnerHorses: horseProfileActions.getOwnerHorses,
  },
  modules: [HorseListModule],
};

const associationEmployeeDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    horseOwner: businessPortalHorseProfileSelectors.selectHorseOwner(state),
    horses: businessPortalHorseProfileSelectors.selectOwnerHorses(state),
    horseOwnerLoading: businessPortalHorseProfileSelectors.selectCommunication(state, 'horseOwnerLoading'),
    horsesLoading: businessPortalHorseProfileSelectors.selectCommunication(state, 'ownerHorsesLoading'),
  }),
  dispatch: {
    getHorseOwner: businessPortalHorseProfileActions.getHorseOwner,
    getOwnerHorses: businessPortalHorseProfileActions.getOwnerHorses,
  },
  modules: [BusinessPortalHorseProfileModule],
};

const adminDistributor: IDistributor<any, any> = {
  ...userDistributor,
};

const associationAdminEmployeeDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    horseOwner: adminAssociationHorseProfileSelectors.selectHorseOwner(state),
    horses: adminAssociationHorseProfileSelectors.selectOwnerHorses(state),
    horseOwnerLoading: adminAssociationHorseProfileSelectors.selectCommunication(state, 'horseOwnerLoading'),
    horsesLoading: adminAssociationHorseProfileSelectors.selectCommunication(state, 'ownerHorsesLoading'),
  }),
  dispatch: {
    getHorseOwner: adminAssociationHorseProfileActions.getHorseOwner,
    getOwnerHorses: adminAssociationHorseProfileActions.getOwnerHorses,
  },
  modules: [AdminAssociationHorseProfileModule],
};

export const horseProfileOwnerVisitorDistributor: Record<VisitorType, IDistributor<IState, IDispatch>> = {
  [VisitorType.Admin]: adminDistributor,
  [VisitorType.User]: userDistributor,
  [VisitorType.AssociationAdmin]: associationAdminEmployeeDistributor,
  [VisitorType.AssociationEmployee]: associationEmployeeDistributor,
};
