import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {IIndexed} from 'Common/models/IIndexed';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {IImaged} from 'Common/models/IImaged';

export interface IFormValues extends IIndexed, IImaged {
  id?: IEntity['id'];
  name: string;
  isShowVariantSummary: boolean;
}

export const initialValue: IFormValues = {
  name: '',
  image: null,
  isShowVariantSummary: false,
  positionIndex: 0,
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  positionIndex: Yup.number().required(REQUIRED_FIELD),
});
