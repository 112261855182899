import {withImageBaseUrl} from 'Common/helpers/withImageBaseUrl';
import {IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IImage} from 'Image/models/IImage';

const getPhenotypeGroupImage = async (id: number): Promise<IObjectResponse<IImage>> => {
  const response = await axiosWrapper.get<IObjectResponse<IImage>>(`/PhenotypeGroupImage/${id}`);
  return {...response.data, data: withImageBaseUrl(response.data.data)};
};

const uploadPhenotypeGroupImage = async (image: FormData): Promise<number> => {
  const response = await axiosWrapper.post<IObjectResponse<number>>(`/PhenotypeGroupImageCreate`, image);
  return response.data.data;
};

const deletePhenotypeGroupImage = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/PhenotypeGroupImageDelete`, {data: [id]});
};

export default {
  getPhenotypeGroupImage,
  uploadPhenotypeGroupImage,
  deletePhenotypeGroupImage,
};
