import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminStaticResources;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectStaticResources(state: IAppState) {
  return selectState(state).data.staticResources;
}

export function selectStaticResource(state: IAppState) {
  return selectState(state).data.staticResource;
}
