import React, {useCallback, useState} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import {Nullable} from 'Common/types';
import {FormType} from 'Common/constants/FormType';
import EmployeeForm from 'Admin/AdminAssociations/components/Employees/EmployeeForm/EmployeeForm';

interface IUseModalParams {
  onSuccess?(): void;
}

export function useAdminEmployeeModal(params: IUseModalParams) {
  const {onSuccess} = params;

  const [updatedId, setUpdatedId] = useState<Nullable<number>>();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const openUpdateAdminEmployeeModal = useCallback(
    (id?: Nullable<number>) => {
      setIsUpdateModalOpen(true);
      setUpdatedId(id);
    },
    [setIsUpdateModalOpen]
  );

  const openCreateAdminEmployeeModal = useCallback(() => {
    setIsCreateModalOpen(true);
  }, []);

  const closeModals = useCallback(() => {
    setIsUpdateModalOpen(false);
    setIsCreateModalOpen(false);
  }, []);

  const onSuccessHandler = useCallback(() => {
    closeModals();
    onSuccess && onSuccess();
  }, [onSuccess, closeModals]);

  const updateAdminEmployeeModal = (
    <ModalWindow isOpen={isUpdateModalOpen} onClose={closeModals}>
      <EmployeeForm id={updatedId!} onSuccess={onSuccessHandler} type={FormType.edit} />
    </ModalWindow>
  );

  const createAdminEmployeeModal = (
    <ModalWindow isOpen={isCreateModalOpen} onClose={closeModals}>
      <EmployeeForm onSuccess={onSuccessHandler} type={FormType.create} />
    </ModalWindow>
  );

  return {
    openUpdateAdminEmployeeModal,
    openCreateAdminEmployeeModal,
    updateAdminEmployeeModal,
    createAdminEmployeeModal,
    updatedId,
  };
}
