import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Form, FormikProps, withFormik} from 'formik';
import styled from 'styled-components';

import {CheckboxField, ImageUploaderField, InputField, TextAreaField} from 'Common/components/FormFields';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {RequiredBy} from 'Common/types';
import {IFormValues, initialValue, validationSchema} from './validation';
import {convertToServerCreating, convertToServerUpdating} from './converters';
import {FieldHint} from 'Common/components/StyledComponents/StyledComponents';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminOrganization';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import Loading from 'Loading/components/Loading';
import {IconName} from 'Icon/components/Icon';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';

const formHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add organization',
  edit: 'Update organization',
};

const buttonHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add',
  edit: 'Save',
};

const CheckboxWrapper = styled.div`
  margin-bottom: 16px;
`;

interface IExternalProps {
  id?: number;
  type: FormType;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;
type OwnerFormEditProps = IConnected & IExternalProps;
type OuterProps = IExternalProps & OwnerFormEditProps;
type Props = FormikProps<IFormValues> & OuterProps;

function OrganizationForm(props: Props) {
  const {id, onSuccess, type} = props;
  const {
    getAdminOrganization,
    resetAdminOrganization,
    getAdminOrganizationAvatar,
    adminOrganizationLoading,
    adminOrganizationCreating,
    adminOrganizationUpdating,
    adminOrganizationAvatarLoading,
    adminOrganizationAvatarUpdating,
  } = props;

  useEffect(() => {
    if (id) {
      getAdminOrganization(id);
      getAdminOrganizationAvatar(id);
    }

    return resetAdminOrganization;
  }, [id, getAdminOrganization, resetAdminOrganization, getAdminOrganizationAvatar]);

  useOnSuccessCommunication(adminOrganizationCreating, onSuccess);
  useOnSuccessCommunication(adminOrganizationUpdating, onSuccess);

  useErrorCommunicationToToast(adminOrganizationLoading);
  useErrorCommunicationToToast(adminOrganizationCreating);
  useErrorCommunicationToToast(adminOrganizationUpdating);
  useErrorCommunicationToToast(adminOrganizationAvatarLoading);
  useErrorCommunicationToToast(adminOrganizationAvatarUpdating);

  const isRequesting = [adminOrganizationLoading, adminOrganizationCreating, adminOrganizationUpdating].some(
    (i) => i.isRequesting
  );

  const header = formHeaderByType[type];
  const saveButtonHeader = buttonHeaderByType[type];

  const isCreate = type === FormType.create;

  return (
    <>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent minHeight={isCreate ? 500 : undefined}>
          {isRequesting && <Loading />}
          <ImageUploaderField
            name="avatar"
            memoized={true}
            width="104px"
            height="104px"
            isRound={true}
            actionButtonsContainerHeight="100%"
            iconProps={{name: IconName.OwnerDefaultAvatar, width: 100, height: 100}}
            inputStyle={{marginBottom: 24}}
            showDeleteButton={false}
          />
          <InputField name="name" label="Name" placeholder="Name" isRequired />
          <InputField name="abbreviation" label="Abbreviation" placeholder="Abbreviation" />
          <InputField name="email" label="Email" placeholder="Email" isRequired />
          <InputField name="phoneNumber" label="Phone Number" placeholder="Phone Number" />
          <InputField name="address" label="Address" placeholder="Address" />
          <InputField name="country" label="Country" placeholder="Country" />
          <InputField name="websiteUrl" label="Website Url" placeholder="Website Url" />
          <TextAreaField name="description" label="Description" placeholder="Description" />

          {isCreate && (
            <CheckboxWrapper className="d-flex flex-column justify-content-start align-items-start">
              <CheckboxField name="isActive" label="Active organization" />
              <FieldHint>Enabling this option will allow the organization to be active</FieldHint>
            </CheckboxWrapper>
          )}
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ModalWindowButton type="submit" isLoading={props.isSubmitting}>
            {saveButtonHeader}
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const OrganizationFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({adminOrganization, id, adminOrganizationLoading, avatar, adminOrganizationAvatarLoading}) => {
    return id &&
      adminOrganization &&
      !adminOrganizationLoading.isRequesting &&
      !adminOrganizationAvatarLoading.isRequesting
      ? {...adminOrganization, avatar}
      : initialValue;
  },
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, createAdminOrganization, updateAdminOrganization, updateOrganizationAvatar} = formikBag.props;
    formikBag.setSubmitting(true);

    const avatarHandler = async (organizationId: number) => {
      const currentAvatar = values.avatar;

      if (currentAvatar?.isLocal && currentAvatar?.file) {
        await updateOrganizationAvatar(organizationId, currentAvatar.file);
      }
    };
    try {
      if (type === FormType.create) {
        const organizationId = await createAdminOrganization(convertToServerCreating(values));
        await avatarHandler(organizationId);
      }

      if (type === FormType.edit) {
        await updateAdminOrganization(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
        if (values.id) {
          await avatarHandler(values.id);
        }
      }
    } catch (e) {
      return e;
    } finally {
      formikBag.setSubmitting(false);
    }
  },
  enableReinitialize: true,
})(OrganizationForm);

const mapStateToProps = (state: IAppState) => ({
  adminOrganization: selectors.selectAdminOrganization(state),
  adminOrganizationLoading: selectors.selectCommunication(state, 'adminOrganizationLoading'),
  adminOrganizationCreating: selectors.selectCommunication(state, 'adminOrganizationCreating'),
  adminOrganizationUpdating: selectors.selectCommunication(state, 'adminOrganizationUpdating'),
  avatar: selectors.selectAdminOrganizationAvatar(state),
  adminOrganizationAvatarLoading: selectors.selectCommunication(state, 'adminOrganizationAvatarLoading'),
  adminOrganizationAvatarUpdating: selectors.selectCommunication(state, 'adminOrganizationAvatarUpdating'),
});

const mapDispatchToProps = {
  getAdminOrganization: actions.getAdminOrganization,
  createAdminOrganization: actions.createAdminOrganization,
  updateAdminOrganization: actions.updateAdminOrganization,
  resetAdminOrganization: actions.resetAdminOrganization,
  getAdminOrganizationAvatar: actions.getAdminOrganizationAvatar,
  updateOrganizationAvatar: actions.updateOrganizationAvatar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(OrganizationFormFormik));
export default Connected;
