import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Form, FormikProps, withFormik} from 'formik';

import {FormType} from 'Common/constants/FormType';
import {Nullable} from 'Common/types';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {InputField} from 'Common/components/FormFields';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {IAppState} from 'Common/store/IAppState';
import {IErrorDescription} from 'Admin/AdminSettings/models/IErrorDescription';
import Loading from 'Loading/components/Loading';
import {actions, selectors} from '../../../stores/adminErrorDescriptions';
import {initialValue, validationSchema} from './validation';

const formHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add item',
  edit: 'Edit item',
};

const buttonHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add',
  edit: 'Save',
};

interface IExternalProps {
  errorDescriptionId?: Nullable<number>;
  type: FormType;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IErrorDescription> & OuterProps;

const ErrorDescriptionForm = (props: AllProps) => {
  const {status, setStatus} = props;
  const {errorDescriptionId, type, onSuccess} = props;
  const {
    getErrorDescription,
    resetErrorDescriptionDetails,
    errorDescriptionLoading,
    errorDescriptionCreating,
    errorDescriptionUpdating,
  } = props;

  const errorInfo = errorDescriptionLoading.error || errorDescriptionCreating.error || errorDescriptionUpdating.error;
  const isRequesting =
    errorDescriptionLoading.isRequesting ||
    errorDescriptionCreating.isRequesting ||
    errorDescriptionUpdating.isRequesting;

  const header = formHeaderByType[type];
  const saveButtonHeader = buttonHeaderByType[type];

  useOnSuccessCommunication(errorDescriptionCreating, onSuccess);
  useOnSuccessCommunication(errorDescriptionUpdating, onSuccess);

  useEffect(() => {
    const commonError = getCommonErrors(errorInfo);
    commonError && setStatus(commonError);
  }, [errorInfo, setStatus]);

  useEffect(() => {
    if (errorDescriptionId) {
      getErrorDescription(errorDescriptionId);
    }

    return resetErrorDescriptionDetails;
  }, [errorDescriptionId, getErrorDescription, resetErrorDescriptionDetails]);

  return (
    <>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {isRequesting && <Loading />}
          <InputField
            isRequired={true}
            name="code"
            type="text"
            label="Code"
            placeholder="Code"
            className="w-50"
            autoComplete="off"
          />
          <InputField
            isRequired={true}
            name="value"
            type="text"
            label="Value"
            placeholder="Value"
            className="w-50"
            autoComplete="off"
          />
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" isLoading={props.isSubmitting}>
            {saveButtonHeader}
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const ErrorDescriptionFormWithFormik = withFormik<OuterProps, IErrorDescription>({
  mapPropsToValues: ({errorDescription}) => errorDescription || initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const request =
      formikBag.props.type === FormType.create
        ? formikBag.props.createErrorDescription
        : formikBag.props.updateErrorDescription;

    formikBag.setSubmitting(true);
    await request(values);
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(ErrorDescriptionForm);

const mapStateToProps = (state: IAppState) => ({
  errorDescription: selectors.selectErrorDescription(state),
  errorDescriptionLoading: selectors.selectCommunication(state, 'errorDescriptionLoading'),
  errorDescriptionCreating: selectors.selectCommunication(state, 'errorDescriptionCreating'),
  errorDescriptionUpdating: selectors.selectCommunication(state, 'errorDescriptionUpdating'),
});

const mapDispatchToProps = {
  getErrorDescription: actions.getErrorDescription,
  createErrorDescription: actions.createErrorDescription,
  updateErrorDescription: actions.updateErrorDescription,
  resetErrorDescriptionDetails: actions.resetErrorDescriptionResults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(ErrorDescriptionFormWithFormik));
