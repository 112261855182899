import * as React from 'react';
import {omit} from 'lodash';

const withoutProps = (omitProps: string[], isAddRef: boolean = false) => {
  if (!isAddRef) {
    return (WrappedComponent: React.ComponentType<any> | string) => {
      const WithoutPropsComponent = (props: any) => {
        const {children, ...otherProps} = props;
        return React.createElement(WrappedComponent, omit(otherProps, omitProps), children);
      };
      return WithoutPropsComponent;
    };
  }

  return (WrappedComponent: React.ComponentType<any> | string) => {
    const WithoutPropsComponent = React.forwardRef((props: any, ref) => {
      const {children, ...otherProps} = props;
      return React.createElement(WrappedComponent, {...omit(otherProps, omitProps), ref}, children);
    });
    return WithoutPropsComponent;
  };
};

export default withoutProps;
