import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {breakpoints} from 'Common/constants/Breakpoints';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';

export const SubscriptionContainer = styled.div`
  margin-bottom: 32px;
`;

export const TitleBase = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size14};
  line-height: 24px;
  letter-spacing: 0.5px;
`;

export const PlanTitle = styled(TitleBase)`
  color: ${Theme.color.black};
`;

export const ValueTitle = styled(TitleBase)`
  min-width: 123px;
  margin-right: 24px;
  color: ${Theme.color.gray};
`;

export const Value = styled.div`
  min-width: 160px;
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;

export const InfoContainer = styled.div`
  width: 100%;
`;

export const Link = styled(TitleBase)`
  cursor: pointer;
  color: ${Theme.color.primary};
`;

export const ButtonWrapper = styled(PrimaryButton)`
  width: 100%;

  @media ${breakpoints.sm} {
    width: fit-content;
  }
`;
