import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {
  IUserPrintableReportPreferencesState,
  getUserPrintableReportPreferencesActions,
  getUserPrintableReportPreferencesActionTypes,
  updateUserPrintableReportSummaryVisibilityActions,
  updateUserPrintableReportSummaryVisibilityActionTypes,
  hideUserPrintableReportPreferencesActions,
  hideUserPrintableReportPreferencesActionTypes,
  showUserPrintableReportPreferencesActions,
  showUserPrintableReportPreferencesActionTypes,
} from './types';
import OnlineReportPrintableService from 'OnlineReportPrintable/services/OnlineReportPrintableService';
import {IUserReportSummaryVisbility} from 'OnlineReportPrintable/models/IUserReportSummaryVisibility';
import {IUserPrintableReportPreferencesPreferences} from 'OnlineReportPrintable/models/IUserPrintableReportPreferencesPreferences';

export const getUserPrintableReportPreferences =
  (): ActionResult<IUserPrintableReportPreferencesState, void, getUserPrintableReportPreferencesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getUserPrintableReportPreferencesActionTypes.REQUEST));
      const res = await OnlineReportPrintableService.getAllPreferences();
      dispatch(action(getUserPrintableReportPreferencesActionTypes.SUCCESS, res.data.data));
    } catch (error) {
      dispatch(action(getUserPrintableReportPreferencesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateUserPrintableReportSummaryVisibility =
  (
    data: IUserReportSummaryVisbility
  ): ActionResult<IUserPrintableReportPreferencesState, void, updateUserPrintableReportSummaryVisibilityActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateUserPrintableReportSummaryVisibilityActionTypes.REQUEST));
      await OnlineReportPrintableService.updateSummaryVisibility(data);
      dispatch(action(updateUserPrintableReportSummaryVisibilityActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateUserPrintableReportSummaryVisibilityActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const hideUserPrintableReportPreferences =
  (
    data: IUserPrintableReportPreferencesPreferences
  ): ActionResult<IUserPrintableReportPreferencesState, void, hideUserPrintableReportPreferencesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(hideUserPrintableReportPreferencesActionTypes.REQUEST));

      // API only needs to be called if there exists at least one new preference.
      if (Object.values(data).some((x: any[]) => x.length > 0))
        await OnlineReportPrintableService.hidePreferences(data);

      dispatch(action(hideUserPrintableReportPreferencesActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(hideUserPrintableReportPreferencesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const showUserPrintableReportPreferences =
  (
    data: IUserPrintableReportPreferencesPreferences
  ): ActionResult<IUserPrintableReportPreferencesState, void, showUserPrintableReportPreferencesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(showUserPrintableReportPreferencesActionTypes.REQUEST));

      // API only needs to be called if there exists at least one new preference.
      if (Object.values(data).some((x: any[]) => x.length > 0))
        await OnlineReportPrintableService.showPreferences(data);

      dispatch(action(showUserPrintableReportPreferencesActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(showUserPrintableReportPreferencesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
