import {VisitorType} from 'Common/constants/VisitorType';
import VisitorTypeService from './VisitorTypeService';

export default function useVisitorTypeService() {
  const currentVisitorType = VisitorTypeService.getVisitorType();

  const setVisitorType = (visitorType: VisitorType) => {
    VisitorTypeService.setVisitorType(visitorType);
  };

  const isVisitorType = (visitorType: VisitorType) => {
    if (currentVisitorType === visitorType) {
      return true;
    }
    return false;
  };

  const isVisitorAdmin = [VisitorType.Admin, VisitorType.AssociationAdmin].some((x) => x === currentVisitorType);

  return {currentVisitorType, setVisitorType, isVisitorType, isVisitorAdmin};
}
