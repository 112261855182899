import {Nullable} from 'Common/types';
import {IImage} from 'Image/models/IImage';
import {withImageBaseUrl} from './withImageBaseUrl';

export function withAvatar<T extends {avatar?: Nullable<IImage>}>(model: T) {
  const {avatar} = model;

  if (!avatar) {
    return model;
  }

  return {
    ...model,
    avatar: {...withImageBaseUrl(avatar)},
  };
}
