import {combineReducers} from 'redux';

import {IAdminUsersState} from '../types';
import {dataUsersReducer} from './data';
import {communicationUsersReducer} from './communications';
import {uiUsersReducer} from './ui';

export const usersReducer = combineReducers<IAdminUsersState>({
  communications: communicationUsersReducer,
  data: dataUsersReducer,
  ui: uiUsersReducer,
});
