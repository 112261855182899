import {IModule} from 'redux-dynamic-modules';

import {IBuildHorseState} from './types';
import {reducer} from './reducer';

export interface IBuildHorseModuleState {
  buildHorse: IBuildHorseState;
}

export const BuildHorseModule: IModule<IBuildHorseModuleState> = {
  id: 'buildHorse',
  reducerMap: {
    buildHorse: reducer
  }
};
