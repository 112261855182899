import {Reducer} from 'redux';

import {
  IOrderPaymentState,
  Actions,
  getPaymentIntentIdActionTypes,
  getOrderSummaryActionTypes,
  getPayPalOrderActionTypes
} from '../types';

const initialState: IOrderPaymentState['data'] = {
  paymentIntentId: null,
  summary: null,
  payPalOrder: null
};

export const dataReducer: Reducer<IOrderPaymentState['data'], Actions> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case getPaymentIntentIdActionTypes.REQUEST: {
      return {...state, paymentIntentId: null};
    }
    case getPaymentIntentIdActionTypes.SUCCESS: {
      return {...state, paymentIntentId: action.payload};
    }
    case getOrderSummaryActionTypes.SUCCESS: {
      return {...state, summary: action.payload};
    }
    case getPayPalOrderActionTypes.SUCCESS: {
      return {...state, payPalOrder: action.payload};
    }
    default: {
      return state;
    }
  }
};
