import {IModule} from 'redux-dynamic-modules';

import {IAncestryReportState} from './types';
import {ancestryReportReducer} from './reducer';

export interface IAncestryReportModuleState {
  ancestryReport: IAncestryReportState;
}

export const AncestryReportModule: IModule<IAncestryReportModuleState> = {
  id: 'ancestryReport',
  reducerMap: {
    ancestryReport: ancestryReportReducer,
  },
};
