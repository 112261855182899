import React, {memo, useMemo} from 'react';

import {IMenuItem} from 'Common/components/DropDown';
import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import AdminActions from 'Admin/AdminDashboard/components/shared/AdminActions/AdminActions';

const iconProps = {color: ColorPalette.red7};

const commonMenuItems: IMenuItem[] = [
  {value: 'cancelSubscription', label: 'Cancel subscription', icon: {name: IconName.Delete, ...iconProps}},
  {value: 'syncStripeSubscription', label: 'Sync Stripe subscriptions', icon: {name: IconName.Reload, ...iconProps}}
];

interface IExternalProps {
  id: number;
  userId: number;
  isActive: boolean;
  cancelSubscriptionHandler(userId: number, subscriptionId: number): void;
  syncStripeSubscriptionHandler(userId: number): void;
}

function SubscriptionActions(props: IExternalProps) {
  const {id, isActive, userId, cancelSubscriptionHandler, syncStripeSubscriptionHandler} = props;

  const actionsHandler = {
    cancelSubscription: () => cancelSubscriptionHandler(userId, id),
    syncStripeSubscription: () => syncStripeSubscriptionHandler(userId)
  };

  const onSelectHandler = (type: keyof typeof actionsHandler) => actionsHandler[type]();

  const mapAvailableByMenuItem = useMemo(() => {
    return {
      cancelSubscription: isActive,
      syncStripeSubscription: !!syncStripeSubscriptionHandler
    };
  }, [isActive, syncStripeSubscriptionHandler]);

  const shownMenuItems = useMemo(
    () =>
      commonMenuItems
        .map(item => ({...item, availabilityKey: mapAvailableByMenuItem[item.value]}))
        .filter(item => item.availabilityKey),
    [mapAvailableByMenuItem]
  );

  return <AdminActions menuItems={shownMenuItems} onSelectHandler={onSelectHandler} />;
}

export default memo(SubscriptionActions);
