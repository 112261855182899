import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import RejectHorseTransferForm from 'Admin/AdminDashboard/components/HorseTransfer/RejectHorseTransferForm/RejectHorseTransferForm';

interface IRejectHorseTransferModalParams {
  onSuccess?(): void;
}

export function useRejectHorseTransferModal(params: IRejectHorseTransferModalParams) {
  const {onSuccess} = params;

  const [transferId, setTransferId] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const openRejectHorseTransferModal = useCallback((transferId: number) => {
    setTransferId(transferId);
    setIsOpen(true);
  }, []);
  const closeRejectHorseTransferModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeRejectHorseTransferModal();
    onSuccess && onSuccess();
  }, [closeRejectHorseTransferModal, onSuccess]);

  const rejectHorseTransferModal = (
    <ModalWindow isOpen={isOpen} onClose={closeRejectHorseTransferModal}>
      <RejectHorseTransferForm
        transferId={transferId}
        onSuccess={handleSuccess}
        onClose={closeRejectHorseTransferModal}
      />
    </ModalWindow>
  );

  return {
    rejectHorseTransferModal,
    openRejectHorseTransferModal,
  };
}
