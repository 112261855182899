import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';

const STATE_NAME = 'adminReport';

export interface IAdminReportState {
  communications: {
    downloadDemographicsRequesting: ICommunication;
    downloadPaidOrdersRequesting: ICommunication;
    downloadOrdersRequesting: ICommunication;
  };
}

export const downloadDemographicsReportActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DOWNLOAD_DEMOGRAPHICS_REPORT'
);
export type downloadDemographicsReportActions = MakeCommunicationActions<
  typeof downloadDemographicsReportActionTypes,
  {}
>;

export const downloadPaidOrdersReportActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DOWNLOAD_PAID_ORDERS_REPORT'
);
export type downloadPaidOrdersReportActions = MakeCommunicationActions<typeof downloadPaidOrdersReportActionTypes, {}>;

export const downloadOrdersReportActionTypes = makeCommunicationActionType(STATE_NAME, 'DOWNLOAD_ORDERS_REPORT');
export type downloadOrdersReportActions = MakeCommunicationActions<typeof downloadOrdersReportActionTypes, {}>;

export type adminReportActions =
  | downloadDemographicsReportActions
  | downloadPaidOrdersReportActions
  | downloadOrdersReportActions;
