import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import CreateOrderDataService from 'Order/services/CreateOrderDataService';
import {ITestSummaryRequest} from 'Order/services/types/summary';
import {ICheckCouponRequest} from 'Order/services/types/coupon';

import {
  createUserOrderActions,
  createUserOrderActionTypes,
  getOrderPackagesActions,
  getOrderPackagesActionTypes,
  getUserCouponActions,
  getUserCouponActionTypes,
  getUserHorsesActions,
  getUserHorsesActionTypes,
  getUserSummaryActions,
  getUserSummaryActionTypes,
  IAdminCreateUserOrderState,
  resetAdminUserOrderActions,
  resetAdminUserOrderActionTypes,
  resetUserCouponActionTypes,
  resetUserSummaryActionTypes,
} from './types';
import CreateUserOrderService from 'Admin/AdminDashboard/services/CreateUserOrderService';
import {IAdminCreateUserOrderRequest} from 'Admin/AdminDashboard/models/Order/IAdminCreateUserOrderRequest';

export const getUserHorses =
  (userId: number): ActionResult<IAppState, Promise<void>, getUserHorsesActions> =>
  async (dispatch) => {
    dispatch(action(getUserHorsesActionTypes.REQUEST));
    try {
      const res = await CreateUserOrderService.getUserHorses(userId);
      dispatch(action(getUserHorsesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getUserHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserSummary =
  (request: ITestSummaryRequest, userId?: number): ActionResult<IAppState, Promise<void>, getUserSummaryActions> =>
  async (dispatch) => {
    dispatch(action(getUserSummaryActionTypes.REQUEST));
    try {
      const res = await CreateOrderDataService.getSummary(request, userId);
      dispatch(action(getUserSummaryActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getUserSummaryActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserCoupon =
  (request: ICheckCouponRequest, userId: number): ActionResult<IAppState, Promise<void>, getUserCouponActions> =>
  async (dispatch) => {
    dispatch(action(getUserCouponActionTypes.REQUEST));
    try {
      const res = await CreateOrderDataService.getCoupon(request, userId);
      dispatch(action(getUserCouponActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getUserCouponActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createUserOrder =
  (request: IAdminCreateUserOrderRequest): ActionResult<IAppState, Promise<void>, createUserOrderActions> =>
  async (dispatch) => {
    dispatch(action(createUserOrderActionTypes.REQUEST));
    try {
      await CreateUserOrderService.createUserOrder(request);
      dispatch(action(createUserOrderActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createUserOrderActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrderPackages =
  (): ActionResult<IAppState, Promise<void>, getOrderPackagesActions> => async (dispatch) => {
    dispatch(action(getOrderPackagesActionTypes.REQUEST));
    try {
      const res = await CreateUserOrderService.getOrderPackages();
      dispatch(action(getOrderPackagesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getOrderPackagesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetUserOrder =
  (): ActionResult<IAdminCreateUserOrderState, void, resetAdminUserOrderActions> => async (dispatch) => {
    dispatch(action(resetAdminUserOrderActionTypes.RESET));
  };

export const resetUserCoupon = () => action(resetUserCouponActionTypes.RESET);
export const resetUserSummary = () => action(resetUserSummaryActionTypes.RESET);
