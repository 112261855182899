import React, {memo, useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {FieldArray, Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import {DynamicModuleLoader} from 'redux-dynamic-modules';

import {
  convertAdminNotificationToFormValues,
  convertFormValuesToRequest,
  IFormValues,
  initialValue,
  validationSchema,
} from './validation';
import {actions, selectors} from 'Admin/AdminAdvancedDashboard/store/adminAdministrators/notifications';
import {IAppState} from 'Common/store/IAppState';
import {AdminNotificationsModule} from 'Admin/AdminAdvancedDashboard/store/adminAdministrators/notifications/adminNotificationsModule';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {Field, FieldLabel, FieldValue} from 'Admin/common/styled/StyledComponents';
import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {INotificationType} from 'Dictionaries/models/INotificationType';
import {useDictionaries} from 'Common/store/useDictionaries';
import NotificationSwitcher from './NotificationSwitcher';
import {Divider} from 'Common/components/StyledComponents/StyledComponents';

const Root = styled.div`
  margin: 0 24px 40px 0;
`;

interface IExternalProps {
  adminId: number;
  notificationTypesDictionary: ISimpleDictionary<INotificationType>;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function NotificationForm(props: AllProps) {
  const {isSubmitting, values, setErrors, setFieldValue} = props;
  const {
    adminId,
    adminNotifications,
    adminNotificationsLoading,
    adminNotificationsUpdating,
    notificationTypesDictionary,
    onSuccess,
    getAdminNotifications,
  } = props;
  const {name, email} = adminNotifications;
  const {
    actions: {getItems: getNotificationTypes},
  } = notificationTypesDictionary;

  useEffect(() => {
    getNotificationTypes();
    getAdminNotifications(adminId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorInfo = adminNotificationsLoading.error || adminNotificationsUpdating.error;
  const isRequesting = adminNotificationsLoading.isRequesting;

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(adminNotificationsUpdating, onSuccess);
  useOnErrorCommunication(adminNotificationsUpdating, onError);
  useCommunicationToToast(adminNotificationsUpdating, 'Admin notifications has been changed');

  const handlerClickNotificationSwitcher = useCallback(
    (code: string, value: boolean) => {
      setFieldValue('notificationTypes', [
        ...values.notificationTypes.map((item) => ({...item, isEnable: item.code === code ? value : item.isEnable})),
      ]);
    },
    [setFieldValue, values.notificationTypes]
  );

  return (
    <>
      <ModalWindowHeader>Notifications</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <Root>
          <ModalWindowFormContent>
            {isRequesting && <Loading />}
            <div className="row">
              <Field className="col-4">
                <FieldLabel>User</FieldLabel>
                <FieldValue>{name}</FieldValue>
              </Field>
              <Field className="col-4">
                <FieldLabel>Email</FieldLabel>
                <FieldValue>{email}</FieldValue>
              </Field>
            </div>
            <Divider />

            <FieldArray
              name="notifications"
              render={() => {
                return (
                  <>
                    {values.notificationTypes?.map((notification, i) => (
                      <NotificationSwitcher
                        key={i}
                        notification={notification}
                        onClick={handlerClickNotificationSwitcher}
                      />
                    ))}
                  </>
                );
              }}
            />
          </ModalWindowFormContent>
        </Root>
        <ModalWindowFooter>
          <ModalWindowButton type="submit" isLoading={isSubmitting}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const NotificationFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({adminNotifications, notificationTypes}) =>
    notificationTypes ? convertAdminNotificationToFormValues(adminNotifications, notificationTypes) : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    await formikBag.props.updateAdminNotifications(convertFormValuesToRequest(values));
  },
  enableReinitialize: true,
})(NotificationForm);

const mapStateToProps = (state: IAppState, {notificationTypesDictionary}: IExternalProps) => ({
  notificationTypes: notificationTypesDictionary.selectors.selectItems(state),
  adminNotifications: selectors.selectAdminNotifications(state),
  adminNotificationsLoading: selectors.selectCommunication(state, 'adminNotificationsLoading'),
  adminNotificationsUpdating: selectors.selectCommunication(state, 'adminNotificationsUpdating'),
});

const mapDispatchToProps = {
  getAdminNotifications: actions.getAdminNotifications,
  updateAdminNotifications: actions.updateAdminNotifications,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(NotificationFormFormik));
const Exported = (externalProps: Omit<IExternalProps, 'notificationTypesDictionary'>) => {
  const {notificationTypes} = useDictionaries();

  return (
    <DynamicModuleLoader modules={[AdminNotificationsModule]}>
      <Connected notificationTypesDictionary={notificationTypes} {...externalProps} />
    </DynamicModuleLoader>
  );
};

export default Exported;
