import styled from 'styled-components';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {Icon} from 'Common/components/StyledComponents/StyledComponents';

export const ArchivedLabel = styled.span`
  text-align: right;
  text-transform: uppercase;
  color: ${ColorPalette.red7};
`;

export const titleStyle: React.CSSProperties = {
  fontFamily: Typography.family.openSans,
  fontSize: Typography.size.size16,
  lineHeight: '24px',
};

export const PriceIcon = styled(Icon)`
  top: 36px;
`;

export const Hint = styled.p`
  margin-top: -4px;
  font-family: ${Typography.family.ubuntu};
  font-size: ${Typography.size.size12};
  line-height: 18px;
  letter-spacing: 0.5px;
  color: ${ColorPalette.gray56};
`;
