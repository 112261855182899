import {IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IAdminServiceMode, IAdminServiceModeRequest} from '../models/IAdminServiceMode';
import {convertServiceModeToClient} from './converters/serviceMode';

const getOperatingModeControl = async (): Promise<IAdminServiceMode> => {
  const response = await axiosWrapper.get<IObjectResponse<IAdminServiceMode>>(`/OperatingModeControlAdmin`);
  return convertServiceModeToClient(response.data.data);
};

const setOperatingModeControl = async (request: IAdminServiceModeRequest): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/OperatingModeControlUpdateAdmin`, {...request});
};

export default {getOperatingModeControl, setOperatingModeControl};
