import {combineReducers} from 'redux';

import {IUserState, UserActions, getUserActionTypes} from '../types';
import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';
import {editProfileInfoCurrentUserActionTypes} from 'UserProfile/store/currentUser';

export const communicationUserReducer = combineReducers<IUserState['communications'], UserActions>({
  userUpdating: makeCommunicationReducerFromEnum(editProfileInfoCurrentUserActionTypes),
  userLoading: composeReducers([makeCommunicationReducerFromEnum(getUserActionTypes)])
});
