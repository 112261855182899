import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IPaginationRequest} from 'Common/models/IPaginationRequest';
import UserDataService from 'Admin/AdminDashboard/services/UserDataService';
import {
  deleteUserByIdActions,
  deleteUserByIdActionTypes,
  getUserByIdActions,
  getUserByIdActionTypes,
  getUsersActions,
  getUsersActionTypes,
  resetUserDetailsActionTypes,
  resetUserDetailsActions,
  createUserActions,
  createUserActionTypes,
} from './types';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IUserCreateAdmin} from 'Admin/AdminDashboard/models/User/IUserCreateAdmin';

export const getUsers =
  (params?: IPaginationRequest): ActionResult<IAppState, void, getUsersActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getUsersActionTypes.REQUEST));
      const result = await UserDataService.getUsers(params);
      dispatch(action(getUsersActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getUsersActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserById =
  (userId: number): ActionResult<IAppState, void, getUserByIdActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getUserByIdActionTypes.REQUEST));
      const result = await UserDataService.getUserDetails(userId);
      dispatch(action(getUserByIdActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getUserByIdActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteUserById =
  (userId: number): ActionResult<IAppState, void, deleteUserByIdActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteUserByIdActionTypes.REQUEST));
      const result = await UserDataService.deleteUser(userId);
      dispatch(action(deleteUserByIdActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(deleteUserByIdActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createUser =
  (user: IUserCreateAdmin): ActionResult<IAppState, void, createUserActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createUserActionTypes.REQUEST));
      await UserDataService.createUser(user);
      dispatch(action(createUserActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createUserActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetUserDetails = (): ActionResult<IAppState, void, resetUserDetailsActions> => (dispatch) => {
  dispatch(action(resetUserDetailsActionTypes.RESET));
};
