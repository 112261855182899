import {combineReducers, Reducer} from 'redux';

import {IBusinessPortalEmployeeProfileState} from '../types';
import {communicationReducer} from './communications';
import {dataReducer} from './data';

export const employeeReducer: Reducer<IBusinessPortalEmployeeProfileState> =
  combineReducers<IBusinessPortalEmployeeProfileState>({
    communications: communicationReducer,
    data: dataReducer,
  });
