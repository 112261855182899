import React, {memo} from 'react';
import styled from 'styled-components';

import Icon, {IconName} from 'Icon/components/Icon';
import {Nullable} from 'Common/types';
import {IOnlineReportHorseDetails} from 'OnlineReport/models/shared/IOnlineReportHorseDetails';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import moment from 'moment';

const IconWrapper = styled.div`
  width: 212px;
  height: 38px;
`;

const HeaderMainText = styled.div`
  margin-top: 14px;
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size32};
  line-height: 56px;
  align-items: center;
  color: ${Theme.color.black};
`;

const HeaderHorseInfoContainer = styled.div`
  max-width: 360px;
`;

const HeaderHorseInfoWrapper = styled.div`
  padding: 0 20px 20px 0;
`;

const HeaderHorseInfoLabel = styled.div`
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  align-items: center;
  text-transform: uppercase;
  color: ${Theme.color.black};
`;

const HeaderHorseInfoValue = styled.div`
  width: 100px;
  word-break: break-word;
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size10};
  line-height: 16px;
  align-items: center;
  text-transform: uppercase;
  color: ${Theme.color.black};
`;

interface IExternalProps {
  horseDetails: Nullable<IOnlineReportHorseDetails>;
  isShort?: boolean;
}

const Header = (props: IExternalProps) => {
  const {horseDetails, isShort} = props;

  const now = moment().format('MMMM D, YYYY');

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex flex-column">
        <IconWrapper>
          <Icon className="w-100 h-100" name={IconName.EtalonDiagnosticsHorseLogo} size={120} />
        </IconWrapper>
        {!isShort && <HeaderMainText>Genetic profile test results</HeaderMainText>}
      </div>
      <HeaderHorseInfoContainer className="d-flex justify-content-end flex-wrap">
        {!isShort && (
          <HeaderHorseInfoWrapper>
            <HeaderHorseInfoLabel>report date:</HeaderHorseInfoLabel>
            <HeaderHorseInfoValue>{now}</HeaderHorseInfoValue>
          </HeaderHorseInfoWrapper>
        )}
        <HeaderHorseInfoWrapper>
          <HeaderHorseInfoLabel>horse:</HeaderHorseInfoLabel>
          <HeaderHorseInfoValue>{horseDetails?.horseName}</HeaderHorseInfoValue>
        </HeaderHorseInfoWrapper>
        {!isShort && (
          <HeaderHorseInfoWrapper>
            <HeaderHorseInfoLabel>owner:</HeaderHorseInfoLabel>
            <HeaderHorseInfoValue>{horseDetails?.ownerName}</HeaderHorseInfoValue>
          </HeaderHorseInfoWrapper>
        )}
        <HeaderHorseInfoWrapper>
          <HeaderHorseInfoLabel>horse id:</HeaderHorseInfoLabel>
          <HeaderHorseInfoValue>{horseDetails?.labId}</HeaderHorseInfoValue>
        </HeaderHorseInfoWrapper>
        {!isShort && (
          <HeaderHorseInfoWrapper>
            <HeaderHorseInfoLabel>packages:</HeaderHorseInfoLabel>
            <HeaderHorseInfoValue>{horseDetails?.packageNames.join(', ')}</HeaderHorseInfoValue>
          </HeaderHorseInfoWrapper>
        )}
      </HeaderHorseInfoContainer>
    </div>
  );
};

export default memo(Header);
