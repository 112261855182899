import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.businessPortalEmployeeProfile;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectEmployeeProfile(state: IAppState) {
  return selectState(state).data.employeeProfile;
}

export function selectGeoLocation(state: IAppState) {
  return selectState(state).data.geoLocation;
}
