import {Reducer} from 'redux';

import {
  resetCurrentPermissionsActionTypes,
  getCurrentPermissionsActionTypes,
  IPermissionsState,
  PermissionsActions,
} from '../types';

const initialState: IPermissionsState['data'] = {
  currentPermissions: [],
};

export const dataAuthReducer: Reducer<IPermissionsState['data'], PermissionsActions> = (
  state = initialState,
  action: PermissionsActions
) => {
  switch (action.type) {
    case getCurrentPermissionsActionTypes.SUCCESS: {
      return {...state, currentPermissions: action.payload};
    }
    case resetCurrentPermissionsActionTypes.RESET: {
      return {...state, currentPermissions: initialState.currentPermissions};
    }
    default: {
      return state;
    }
  }
};
