import moment, {Moment} from 'moment';
import {BACKEND_DATE_TIME, FRONTEND_DATE, FRONTEND_DATE_TIME} from 'Common/constants/Date';
import {Nullable} from 'Common/types';

export function isValidDate(date: any, format: string, futureEnabled?: boolean) {
  const mainCheck = moment(date, format, true).isValid();
  const optionalCheck = mainCheck ? moment().isAfter(moment(date, format)) : false;
  return futureEnabled ? mainCheck : mainCheck && optionalCheck;
}

export function dateDiffInYearsOrMonth(date: string, format: string = FRONTEND_DATE): string {
  const parsedDate = parseDate(date, format);
  const years = moment().diff(parsedDate, 'years');
  if (years === 0) {
    return `${moment().diff(parsedDate, 'month')} m.o.`;
  }
  return `${years} y.o.`;
}

function parseDate(date: string, format: string = BACKEND_DATE_TIME) {
  const parsedDate = moment(date, format);

  if (parsedDate.isValid()) {
    return parsedDate;
  }

  return moment(new Date(date.replace(/-/g, '/')));
}

export function convertToClientDate(value: string | Moment, format?: string): string | null {
  const dateValue = value instanceof moment ? moment(value) : parseDate(value as string, format);

  return dateValue.format(format || FRONTEND_DATE);
}

/** Function converts the Date from local date-time.
 * isUtc indicates that Date will be saved in UTC.
 * Use it in converters from client models to server models to send them to backend */
export function convertToServerDate(value: any, isUtc?: boolean): Nullable<string> {
  if (!value) {
    return null;
  }

  let dateValue = value;
  if (value instanceof moment) {
    dateValue = moment(value);
  } else {
    dateValue = value ? moment(value, FRONTEND_DATE_TIME) : moment(new Date());
  }
  return isUtc ? moment.utc(dateValue).format(BACKEND_DATE_TIME) : dateValue.format(BACKEND_DATE_TIME);
}

/** Function converts the date from UTC to local date.
 *  Use it in converters from server model to client model */
export function convertUTCToClientDate(value: string | Moment, format?: string): Nullable<string> {
  if (!value) {
    return null;
  }

  const utcDate = (value instanceof moment ? moment.utc(value) : parseDate(value as string)).format(BACKEND_DATE_TIME);

  return moment
    .utc(utcDate)
    .local()
    .format(format || FRONTEND_DATE_TIME);
}
