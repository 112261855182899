export enum ElementPosition {
  Left,
  Right,
  Top,
  Bottom
}

export type HorizontalElementPosition = ElementPosition.Left | ElementPosition.Right;

export type VerticalElementPosition = ElementPosition.Top | ElementPosition.Bottom;

export default interface IElementPosition {
  position: ElementPosition;
}
