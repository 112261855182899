import React from 'react';
import {datadogLogs} from '@datadog/browser-logs';
import withScriptjs, {WithScriptjsProps} from 'react-google-maps/lib/withScriptjs';
import {compose, defaultProps, withProps} from 'recompose';

import Loading from 'Loading/components/Loading';
import {getEnvParams} from 'Common/helpers/getEnvParams';
import {convertToLoggerContext} from 'Common/helpers/convertToLoggerContext';

type IProps = Omit<WithScriptjsProps, 'googleMapURL'>;

export function withGScript<T>(Component: React.ComponentType<T & IProps>) {
  const wrapped = (props: T & IProps) => <Component {...props} />;

  wrapped.displayName = `withGoogleScript(${Component.displayName || Component.name || 'Component'})`;

  const googleKey = getEnvParams().googleMapKey;

  if (!googleKey) {
    datadogLogs.logger.error('Google script key error', convertToLoggerContext(`Key is empty`));
  }

  return compose<any, T & Partial<IProps>>(
    withProps({
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleKey}&v=3.exp&libraries=geometry,drawing,places`
    }),
    defaultProps({
      loadingElement: <Loading />
    }),
    withScriptjs
  )(wrapped);
}

export type IWithGoogleScriptProps = IProps;
