import {combineReducers} from 'redux';

import {IAdminAssociationHorseState} from '../types';
import {uiAdminAssociationHorseReducer} from './ui';
import {dataAdminAssociationHorseReducer} from './data';
import {communicationAdminAssociationHorseReducer} from './communications';

export const adminAssociationHorseReducer = combineReducers<IAdminAssociationHorseState>({
  communications: communicationAdminAssociationHorseReducer,
  data: dataAdminAssociationHorseReducer,
  ui: uiAdminAssociationHorseReducer,
});
