import React, {useMemo} from 'react';
import styled from 'styled-components';

import {getCommonErrors, getErrorCode} from 'Common/helpers/ErrorHelper';
import {IResponseError} from 'Common/models/IError';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {OrderReportType} from 'Common/constants/OrderReportType';

/** Possible errors:
 * Diagnostic:
 *    OrderInProceed
 *    ReportNotOrdered
 *
 * Ancestry:
 *    OrderInProceed
 *    ReportNotOrdered
 *    NoDataForReport
 *    AncestryReportIsHidden
 *    RelatedHorsesNotFound
 *    KinshipHorsesNotFound
 *
 * Other:
 *    SampleNotReceived
 */
enum OnlineReportError {
  OrderInProceed = 'OrderInProceed',
  NoDataForReport = 'NoDataForReport',
  SampleNotReceived = 'SampleNotReceived',
  ReportNotOrdered = 'ReportNotOrdered',
  AncestryReportIsHidden = 'AncestryReportIsHidden',
  RelatedHorsesNotFound = 'RelatedHorsesNotFound',
  KinshipHorsesNotFound = 'KinshipHorsesNotFound',
}

const UNKNOWN_ERROR = `Error on loading results`;

const NO_DIAGNOSTIC_ORDER = 'You haven’t ordered any diagnostic tests yet';
const NO_DIAGNOSTIC_ORDER_ADMIN = 'Owner has not ordered any diagnostic tests yet';
const NO_ANCESTRY_ORDER = 'You haven’t ordered any ancestry tests yet';
const NO_ANCESTRY_ORDER_ADMIN = 'Owner has not ordered any ancestry tests yet';
const NO_PARANTAGE_ORDER = 'Upgrade your order to view matches';
const NO_PARANTAGE_ORDER_ADMIN = 'Upgrade your order to view matches';

const SpecialErrorMessage = styled.div`
  text-align: center;
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size32};
  line-height: 56px;
  color: ${ColorPalette.gray56};
`;

const OrderLinkButton = styled(LinkButton)`
  width: 200px;
  margin-top: 48px;
`;

interface IProps {
  horseId: number;
  error?: IResponseError;
  reportType?: OrderReportType;
  messageStyle?: React.CSSProperties;
}

export function useOnlineReportErrors(props: IProps) {
  const {horseId, error, messageStyle, reportType} = props;

  const {isVisitorAdmin} = useVisitorTypeService();

  const isAncestry = reportType === OrderReportType.Ancestry;
  const isParantage = reportType === OrderReportType.Parentage;

  const NotOrdered = useMemo(() => {
    const noAncestry = isVisitorAdmin ? NO_ANCESTRY_ORDER_ADMIN : NO_ANCESTRY_ORDER;
    const noDiagnostic = isVisitorAdmin ? NO_DIAGNOSTIC_ORDER_ADMIN : NO_DIAGNOSTIC_ORDER;
    const noParantage = isVisitorAdmin ? NO_PARANTAGE_ORDER_ADMIN : NO_PARANTAGE_ORDER;

    return (
      <>
        <SpecialErrorMessage style={messageStyle}>
          {isAncestry ? noAncestry : isParantage ? noParantage : noDiagnostic}
        </SpecialErrorMessage>
        {!isVisitorAdmin && (
          <OrderLinkButton to={`/order-tests/${horseId}`} size="small">
            Order Testing
          </OrderLinkButton>
        )}
      </>
    );
  }, [horseId, isAncestry, isParantage, isVisitorAdmin, messageStyle]);

  const OrderInProcessing = useMemo(
    () => (
      <>
        <SpecialErrorMessage style={messageStyle}>
          Your DNA sample is being processed and your report is in progress.
        </SpecialErrorMessage>
        <SpecialErrorMessage style={messageStyle}>We will notify you when it is ready!</SpecialErrorMessage>
      </>
    ),
    [messageStyle]
  );

  const SampleNotReceived = useMemo(
    () => (
      <>
        <SpecialErrorMessage style={messageStyle}>
          Once your sample has been received in the lab, we will begin processing the DNA.
        </SpecialErrorMessage>
        <SpecialErrorMessage style={messageStyle}>
          You will receive an email when your results are ready.
        </SpecialErrorMessage>
      </>
    ),
    [messageStyle]
  );

  const NotUploaded = useMemo(
    () => <SpecialErrorMessage style={messageStyle}>Results are not uploaded yet</SpecialErrorMessage>,
    [messageStyle]
  );

  const HorsesNotFound = useMemo(
    () => (
      <SpecialErrorMessage style={messageStyle}>
        No matching horses found, please check in with us again in the future as more horses participate
      </SpecialErrorMessage>
    ),
    [messageStyle]
  );

  const mapErrorToMessage: Record<OnlineReportError, JSX.Element> = useMemo(
    () => ({
      [OnlineReportError.OrderInProceed]: OrderInProcessing,
      [OnlineReportError.ReportNotOrdered]: NotOrdered,
      [OnlineReportError.NoDataForReport]: NotUploaded,
      [OnlineReportError.SampleNotReceived]: SampleNotReceived,
      [OnlineReportError.AncestryReportIsHidden]: NotOrdered,
      [OnlineReportError.KinshipHorsesNotFound]: HorsesNotFound,
      [OnlineReportError.RelatedHorsesNotFound]: HorsesNotFound,
    }),
    [HorsesNotFound, NotOrdered, NotUploaded, OrderInProcessing, SampleNotReceived]
  );

  const errorCode = error && getErrorCode(error);
  const isIndividualError = errorCode && Object.values(OnlineReportError).find((value) => value === errorCode);
  const isCommonError = errorCode && !isIndividualError;
  const isOnlineReportError = !!isIndividualError || !!isCommonError;

  const onlineReportErrors = useMemo(() => {
    return (
      <div className="d-flex flex-column align-items-center">
        {isCommonError && <ErrorMessage>{getCommonErrors(error) || UNKNOWN_ERROR}</ErrorMessage>}
        {isIndividualError && mapErrorToMessage[errorCode!]}
      </div>
    );
  }, [isCommonError, error, isIndividualError, mapErrorToMessage, errorCode]);

  return {onlineReportErrors, isOnlineReportError};
}
