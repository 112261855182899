import React, {useCallback, useEffect, useState} from 'react';
import {GoogleMap, GoogleMapProps, Marker} from 'react-google-maps';

import {ILocation} from 'Common/models/ICoordinatedLocation';
import {withMap} from 'Common/helpers/Map/withMap';
import styled from 'styled-components';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';

const LinkWrapper = styled.div`
  margin: 5px 0 10px 0;
`;

const ExternalLink = styled.a.attrs({target: '_blank', rel: 'noopener noreferrer'})`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${ColorPalette.blue2};
`;

const defaultCoordinates: ILocation = {latitude: 39, longitude: -101};

const defaultGoogleMapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  zoomControl: false,
  fullscreenControl: false
};

interface IProps {
  position: ILocation;
}

type AllProps = GoogleMapProps & IProps;

const Map = (props: AllProps) => {
  const {position, ...rest} = props;
  const [map, setMap] = useState<google.maps.Map>();
  const [coordinates, setCoordinates] = useState<google.maps.LatLng>();
  const [zoom, setZoom] = useState(3);
  const onMapMounted = useCallback(ref => setMap(ref), [setMap]);

  const [address, setAddress] = useState('');

  const getAddressByPosition = useCallback(() => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({location: {lat: position.latitude, lng: position.longitude}}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          setAddress(results[0].formatted_address);
        } else {
          console.error('No results found');
        }
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  }, [position]);

  useEffect(() => {
    const lat = position.latitude === 0 ? defaultCoordinates.latitude : position.latitude;
    const lng = position.longitude === 0 ? defaultCoordinates.longitude : position.longitude;
    const marker = new google.maps.LatLng(lat, lng);
    setCoordinates(marker);
    map && map.panTo(marker);

    if (position.latitude !== 0 && position.longitude !== 0) {
      setZoom(15);
    }

    getAddressByPosition();
  }, [map, setCoordinates, position, getAddressByPosition]);

  const getViewGoogleMapsLink = useCallback(() => {
    if (address) {
      return `https://www.google.com/maps/search/${address}`;
    }

    return `https://www.google.com/maps/search/@${position.latitude},${position.longitude},${zoom}z`;
  }, [address, position, zoom]);

  return (
    <>
      <LinkWrapper>
        <ExternalLink href={getViewGoogleMapsLink()}>View on Google Maps</ExternalLink>
      </LinkWrapper>

      <GoogleMap {...rest} zoom={zoom} ref={onMapMounted} options={defaultGoogleMapOptions}>
        <Marker position={coordinates} />
      </GoogleMap>
    </>
  );
};

export default withMap(Map);
