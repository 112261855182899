import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {IAssociationPageGeneralInformation} from 'BusinessPortal/models/IAssociationPageGeneralInformation';
import {Nullable} from 'Common/types';
import {IAssociationPageContactInformation} from 'BusinessPortal/models/IAssociationPageContactInformation';
import {IImage} from 'Image/models/IImage';

const STATE_NAME = 'businessPortalAssociationPage';

export interface IBusinessPortalAssociationPageState {
  data: {
    generalInformation: Nullable<IAssociationPageGeneralInformation>;
    contactInformation: Nullable<IAssociationPageContactInformation>;
    organizationAvatar: Nullable<IImage>;
  };
  communications: {
    generalInformationLoading: ICommunication;
    generalInformationUpdating: ICommunication;
    contactInformationLoading: ICommunication;
    contactInformationUpdating: ICommunication;
    organizationAvatarLoading: ICommunication;
    organizationAvatarUpdating: ICommunication;
  };
}

export const getGeneralInformationActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_GENERAL_INFORMATION');
export type getGeneralInformationActions = MakeCommunicationActions<
  typeof getGeneralInformationActionTypes,
  {success: IAssociationPageGeneralInformation}
>;

export const updateGeneralInformationActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_GENERAL_INFORMATION'
);
export type updateGeneralInformationActions = MakeCommunicationActions<typeof updateGeneralInformationActionTypes, {}>;

export const getContactInformationActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_CONTACT_INFORMATION');
export type getContactInformationActions = MakeCommunicationActions<
  typeof getContactInformationActionTypes,
  {success: IAssociationPageContactInformation}
>;

export const updateContactInformationActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_CONTACT_INFORMATION'
);
export type updateContactInformationActions = MakeCommunicationActions<typeof updateContactInformationActionTypes, {}>;

export const getOrganizationAvatarActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORGANIZATION_AVATAR');
export type getOrganizationAvatarActions = MakeCommunicationActions<
  typeof getOrganizationAvatarActionTypes,
  {success: IImage}
>;

export const updateOrganizationAvatarActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_ORGANIZATION_AVATAR'
);
export type updateOrganizationAvatarActions = MakeCommunicationActions<typeof updateOrganizationAvatarActionTypes, {}>;

export const resetGeneralInformationActionTypes = makeResetCommunicationActionType(STATE_NAME, 'GENERAL_INFORMATION');
export type resetGeneralInformationActions = MakeResetCommunicationActions<typeof resetGeneralInformationActionTypes>;

export const resetContactInformationActionTypes = makeResetCommunicationActionType(STATE_NAME, 'CONTACT_INFORMATION');
export type resetContactInformationActions = MakeResetCommunicationActions<typeof resetContactInformationActionTypes>;

export type BusinessPortalAssociationPageActions =
  | getGeneralInformationActions
  | updateGeneralInformationActions
  | getContactInformationActions
  | updateContactInformationActions
  | resetGeneralInformationActions
  | resetContactInformationActions
  | updateOrganizationAvatarActions
  | getOrganizationAvatarActions;
