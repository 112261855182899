import {Reducer} from 'redux';

import {MIN_COUNT_PER_PAGE} from 'Common/constants/Pagination';

import {IAdminHorsesState, AdminHorsesActions, getHorsesActionTypes} from '../types';

const initialState: IAdminHorsesState['ui'] = {
  pagination: {countPerPage: MIN_COUNT_PER_PAGE, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiHorsesReducer: Reducer<IAdminHorsesState['ui'], AdminHorsesActions> = (
  state = initialState,
  action: AdminHorsesActions
) => {
  switch (action.type) {
    case getHorsesActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
