import {ICreateAdminEmployee, IUpdateAdminEmployee} from 'Admin/AdminAssociations/services/types/employee';
import {IFormValues} from './validation';

export const convertToServerCreating = (values: IFormValues): ICreateAdminEmployee => {
  const dataToSave = {
    ...values,
    isActive: values.isActive!,
    password: values.password!,
    organizationId: values.organizationId!,
  };

  delete dataToSave['avatar'];
  delete dataToSave['location'];
  delete dataToSave['locationAddress'];

  return dataToSave;
};

export const convertToServerUpdating = (values: IFormValues): IUpdateAdminEmployee => {
  const dataToSave = {
    ...values,
    id: values.id!,
  };

  delete dataToSave['avatar'];
  delete dataToSave['location'];
  delete dataToSave['locationAddress'];

  return dataToSave;
};
