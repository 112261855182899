import {useDictionaries} from 'Common/store/useDictionaries';
import {ITraitSequence} from 'Admin/AdminPhenotypes/models/ITraitSequence';
import {Combination} from 'Common/constants/Combination';
import {useSequenceEditor} from './useSequenceEditor';
import {IFormSequenceItem} from 'SequenceEditor/models/IFormSequenceItem';
import {ITrait} from 'Dictionaries/models/ITrait';
import {INamedEntity} from 'Common/models/INamedEntity';

export function useTraitSequenceEditor(
  type: Combination,
  sequences: ITraitSequence[],
  onSuccess: (type: Combination, sequences: ITraitSequence[]) => void
) {
  const {traits} = useDictionaries();

  const getStringifySequences = () => {
    return sequences.map(s => `${s.traits.map(x => x.name).join(` ${s.type} `)}`);
  };

  function convertSequenceToFormValues(): IFormSequenceItem[] {
    return sequences.map(value => ({type: value.type, sequenceItems: value.traits.map(x => x.id)}));
  }

  function convertFormValuesToTraitSequence(values: IFormSequenceItem[], traits: ITrait[]): ITraitSequence[] {
    const sequences = values.map(value => ({
      type: value.type,
      traits: value.sequenceItems.filter(x => x > 0).map(x => ({id: x, name: traits.find(t => t.id === x)?.name || ''}))
    }));

    return sequences.filter(x => x.traits.length > 0);
  }

  function handleSuccess(type: Combination, sequences: IFormSequenceItem[], dictionaryItems: INamedEntity[]) {
    const convertedSequences = convertFormValuesToTraitSequence(sequences, dictionaryItems);
    onSuccess(type, convertedSequences);
  }

  const {
    getSequencesAsFormattedText,
    openSequenceEditor,
    renderRuleField,
    renderSequenceEditorModal
  } = useSequenceEditor(
    type,
    convertSequenceToFormValues(),
    traits,
    handleSuccess,
    getStringifySequences,
    'Traits',
    'Trait state'
  );

  return {getSequencesAsFormattedText, openSequenceEditor, renderRuleField, renderSequenceEditorModal};
}
