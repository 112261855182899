import {AxiosResponse} from 'axios';

type DownloadExtension = '.jpg' | '.pdf';
const requestInit: RequestInit = {mode: 'cors', method: 'GET', cache: 'reload'};

export async function downloadByUrl(url: string, fileName: string, fileExtension?: DownloadExtension) {
  const a = document.createElement('a');
  a.href = await toDataURL(url);
  a.download = `${fileName}${url.match(/\.[^.]+$/) || fileExtension}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

async function toDataURL(url: string) {
  const response = await fetch(url, requestInit);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
}

export function downloadResponse(response: AxiosResponse, fileName: string, isExtensionByContentType: boolean = true) {
  const contentType = response.headers['content-type'];
  const file = new Blob([response.data], {type: contentType});

  const fileNameObj: {name: string; extension: string} = {name: fileName || 'file', extension: ''};

  if (isExtensionByContentType) {
    switch (contentType) {
      case 'application/pdf':
        fileNameObj.extension = 'pdf';
        break;
      case 'application/zip':
        fileNameObj.extension = 'zip';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        fileNameObj.extension = 'xlsx';
        break;
    }
  }

  const fullFileName = `${fileNameObj.name}.${fileNameObj.extension}`;
  const link = document.createElement('a');
  link.download = fullFileName;
  link.href = URL.createObjectURL(file);
  link.click();
}
