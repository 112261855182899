import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IPaginationRequest} from 'Common/models/IPaginationRequest';
import {
  changeAdminEmployeeStatusActions,
  changeAdminEmployeeStatusActionTypes,
  createAdminEmployeeActions,
  createAdminEmployeeActionTypes,
  deleteAdminEmployeeAvatarActions,
  deleteAdminEmployeeAvatarActionTypes,
  getAdminEmployeeActions,
  getAdminEmployeeActionTypes,
  getAdminEmployeesActions,
  getAdminEmployeesActionTypes,
  resetAdminEmployeeActions,
  resetAdminEmployeeActionTypes,
  updateAdminEmployeePasswordActions,
  updateAdminEmployeePasswordActionTypes,
  updateAdminEmployeeActions,
  updateAdminEmployeeActionTypes,
  uploadAdminEmployeeAvatarActions,
  uploadAdminEmployeeAvatarActionTypes,
  updateAdminEmployeeEmailActionTypes,
  updateAdminEmployeeEmailActions,
  updateAdminEmployeeGeoLocationActions,
  updateAdminEmployeeGeoLocationActionTypes,
  getAdminEmployeeGeoLocationActions,
  getAdminEmployeeGeoLocationActionTypes,
} from './types';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import AdminEmployeeDataService from 'Admin/AdminAssociations/services/AdminEmployeeDataService';
import {
  ICreateAdminEmployee,
  IUpdateAdminEmployee,
  IUpdateAdminEmployeeEmail,
  IUpdateAdminEmployeePassword,
} from 'Admin/AdminAssociations/services/types/employee';
import {ILocation} from 'Common/models/ICoordinatedLocation';

export const getAdminEmployees =
  (params?: IPaginationRequest): ActionResult<IAppState, void, getAdminEmployeesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminEmployeesActionTypes.REQUEST));
      const result = await AdminEmployeeDataService.getAdminEmployees(params);
      dispatch(action(getAdminEmployeesActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getAdminEmployeesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAdminEmployee =
  (id: number): ActionResult<IAppState, void, getAdminEmployeeActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminEmployeeActionTypes.REQUEST));
      const result = await AdminEmployeeDataService.getAdminEmployee(id);
      dispatch(action(getAdminEmployeeActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getAdminEmployeeActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createAdminEmployee =
  (data: ICreateAdminEmployee): ActionResult<IAppState, Promise<number>, createAdminEmployeeActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createAdminEmployeeActionTypes.REQUEST));
      const res = await AdminEmployeeDataService.createAdminEmployee(data);
      dispatch(action(createAdminEmployeeActionTypes.SUCCESS));
      return res;
    } catch (error) {
      const err = getErrorMessage(error);
      dispatch(action(createAdminEmployeeActionTypes.FAILURE, err));
      throw err;
    }
  };

export const updateAdminEmployee =
  (data: IUpdateAdminEmployee): ActionResult<IAppState, void, updateAdminEmployeeActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdminEmployeeActionTypes.REQUEST));
      await AdminEmployeeDataService.updateAdminEmployee(data);
      dispatch(action(updateAdminEmployeeActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateAdminEmployeeActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const uploadAdminEmployeeAvatar =
  (employeeId: number, file: File): ActionResult<IAppState, void, uploadAdminEmployeeAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadAdminEmployeeAvatarActionTypes.REQUEST));
      await AdminEmployeeDataService.uploadAdminEmployeeAvatar(employeeId, file);
      dispatch(action(uploadAdminEmployeeAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(uploadAdminEmployeeAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteAdminEmployeeAvatar =
  (id: number): ActionResult<IAppState, void, deleteAdminEmployeeAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteAdminEmployeeAvatarActionTypes.REQUEST));
      const result = await AdminEmployeeDataService.deleteAdminEmployeeAvatar(id);
      dispatch(action(deleteAdminEmployeeAvatarActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(deleteAdminEmployeeAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const changeAdminEmployeeStatus =
  (id: number, isActive: boolean): ActionResult<IAppState, void, changeAdminEmployeeStatusActions> =>
  async (dispatch) => {
    try {
      dispatch(action(changeAdminEmployeeStatusActionTypes.REQUEST));
      await AdminEmployeeDataService.changeAdminEmployeeStatus(id, isActive);
      dispatch(action(changeAdminEmployeeStatusActionTypes.SUCCESS, {employeeId: id}));
    } catch (error) {
      dispatch(action(changeAdminEmployeeStatusActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateAdminEmployeePassword =
  (data: IUpdateAdminEmployeePassword): ActionResult<IAppState, void, updateAdminEmployeePasswordActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdminEmployeePasswordActionTypes.REQUEST));
      const result = await AdminEmployeeDataService.updateAdminEmployeePassword(data);
      dispatch(action(updateAdminEmployeePasswordActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(updateAdminEmployeePasswordActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateAdminEmployeeEmail =
  (data: IUpdateAdminEmployeeEmail): ActionResult<IAppState, void, updateAdminEmployeeEmailActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdminEmployeeEmailActionTypes.REQUEST));
      const result = await AdminEmployeeDataService.updateAdminEmployeeEmail(data);
      dispatch(action(updateAdminEmployeeEmailActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(updateAdminEmployeeEmailActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAdminEmployeeGeoLocation =
  (employeeId: number): ActionResult<IAppState, void, getAdminEmployeeGeoLocationActions> =>
  async (dispatch) => {
    dispatch(action(getAdminEmployeeGeoLocationActionTypes.REQUEST));

    await AdminEmployeeDataService.getAdminEmployeeGeoLocation(employeeId)
      .then((res) => {
        dispatch(action(getAdminEmployeeGeoLocationActionTypes.SUCCESS, res.data.data));
      })
      .catch((error) => {
        dispatch(action(getAdminEmployeeGeoLocationActionTypes.FAILURE, getErrorMessage(error)));
      });
  };

export const updateAdminEmployeeGeoLocation =
  (
    employeeId: number,
    data: ILocation
  ): ActionResult<IAppState, Promise<void>, updateAdminEmployeeGeoLocationActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdminEmployeeGeoLocationActionTypes.REQUEST));
      await AdminEmployeeDataService.updateAdminEmployeeGeoLocation(employeeId, data);
      dispatch(action(updateAdminEmployeeGeoLocationActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateAdminEmployeeGeoLocationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetAdminEmployee = (): ActionResult<IAppState, void, resetAdminEmployeeActions> => async (dispatch) => {
  dispatch(action(resetAdminEmployeeActionTypes.RESET));
};
