import {IServerAssociationPackage, IServerAssociationPackageRequest} from '../types/package';
import {
  IAdminAssociationPackage,
  IAdminAssociationPackageRequest,
} from 'Admin/AdminAssociations/models/IAdminAssociationPackage';
import {EntityStatus} from 'Common/constants/EntityStatus';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {convertPriceToClient, convertPriceToServer} from 'Common/helpers/convertPrices';
import {convertNumberArrayToIEntityArray} from 'Common/helpers/convertNumberArrayToIEntityArray';

export function convertPackageToClient(value: IServerAssociationPackage): IAdminAssociationPackage {
  return {
    ...value,
    commonPrice: convertPriceToClient(value.commonPrice),
    isEnabled: value.status === EntityStatus.Active,
    isArchived: value.status === EntityStatus.Archived,
    specialPrices: value.specialPrices.map((x) => ({
      price: convertPriceToClient(x.price),
      organizations: x.organizations,
    })),
    statusChangeDate: value.statusChangeDate ? convertUTCToClientDate(value.statusChangeDate) : null,
  };
}

export function convertPackageToRequest(value: IAdminAssociationPackageRequest): IServerAssociationPackageRequest {
  const dataToSave: Omit<IAdminAssociationPackageRequest, 'isArchived' | 'isEnabled' | 'statusChangeDate'> = value;
  delete dataToSave['isArchived'];
  delete dataToSave['isEnabled'];
  delete dataToSave['statusChangeDate'];

  return {
    ...dataToSave,
    commonPrice: convertPriceToServer(dataToSave.commonPrice),
    specialPrices: dataToSave.specialPrices.map((x) => ({
      price: convertPriceToServer(x.price),
      organizations: convertNumberArrayToIEntityArray(x.organizations),
    })),
    groups: convertNumberArrayToIEntityArray(dataToSave.groups),
    tests: convertNumberArrayToIEntityArray(dataToSave.tests),
    reportTypes: convertNumberArrayToIEntityArray(dataToSave.reportTypes),
  };
}
