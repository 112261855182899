import {Reducer} from 'redux';

import {ServiceModeActions, getServiceModeActionTypes, IAdminServiceModeState} from '../types';
import {ServiceMode} from 'Maintain/models/IServiceMode';

const initialState: IAdminServiceModeState['data'] = {
  adminServiceMode: {adminId: 0, cause: '', mode: ServiceMode.On, modeSettingDate: '', adminEmail: ''}
};

export const dataServiceModeReducer: Reducer<IAdminServiceModeState['data'], ServiceModeActions> = (
  state = initialState,
  action: ServiceModeActions
) => {
  switch (action.type) {
    case getServiceModeActionTypes.SUCCESS: {
      return {...state, adminServiceMode: action.payload};
    }
    default: {
      return state;
    }
  }
};
