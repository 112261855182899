import React, {memo} from 'react';

import {Cell} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import Actions from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/Actions';
import DictionaryLayout, {
  DictionaryFormProps
} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryLayout';
import {useDictionaries} from 'Common/store/useDictionaries';
import {IDictionary} from 'DictionaryFactory/types';
import {IUpdateProductRequest} from 'Admin/AdminDashboard/services/models/IServerProduct';
import {IAdminProduct} from 'Admin/AdminDashboard/models/IAdminProduct';
import {withCurrency} from 'Common/helpers/withCurrency';
import ProductForm from './ProductForm/ProductForm';
import {HAS_RULE_ICON, NO_RULE_ICON} from 'Admin/AdminPhenotypes/components/share/StyledComponents';
import Tooltip from 'Common/components/Tooltip/Tooltip';

function Products() {
  const {adminProducts: dictionary} = useDictionaries();

  const wrappedProductForm = (props: DictionaryFormProps<IDictionary<IAdminProduct, IUpdateProductRequest>>) => {
    return <ProductForm {...props} />;
  };

  return (
    <div>
      <DictionaryLayout dictionary={dictionary} DictionaryForm={wrappedProductForm}>
        {({onEdit}) => (
          <>
            <Cell<IAdminProduct> dataKey="id" width="100" header="ID" render={({id}) => `#${id}`} />
            <Cell<IAdminProduct> dataKey="name" width="20%" header="Name" render={({name}) => name} />
            <Cell<IAdminProduct>
              dataKey="description"
              width="30%"
              header="Description"
              render={({description}) => description}
            />
            <Cell<IAdminProduct> dataKey="price" width="10%" header="Price" render={({price}) => withCurrency(price)} />
            <Cell<IAdminProduct>
              dataKey="billingInterval"
              width="10%"
              header="Interval"
              render={({billingInterval}) => billingInterval}
            />
            <Cell<IAdminProduct>
              dataKey="isActive"
              width="10%"
              header="Active"
              render={({isActive}) => (isActive ? HAS_RULE_ICON : NO_RULE_ICON)}
            />
            <Cell<IAdminProduct>
              dataKey="permissions"
              width="10%"
              header="Permissions"
              sortable={false}
              render={({permissions}) => (
                <div className="d-flex flex-wrap">
                  {permissions.map((x, ind) => {
                    const isLast = permissions.length - 1 === ind;
                    return (
                      <Tooltip key={ind} content={x.description}>
                        <div className="d-flex">
                          {x.code}
                          {!isLast ? <>,&nbsp;</> : ''}
                        </div>
                      </Tooltip>
                    );
                  })}
                </div>
              )}
            />

            <Cell<IAdminProduct>
              header="Actions"
              align={CellAlign.Right}
              width={100}
              render={({id}) => <Actions id={id} editAction={onEdit} />}
            />
          </>
        )}
      </DictionaryLayout>
    </div>
  );
}

export default memo(Products);
