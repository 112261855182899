import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication/index';

import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';
import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import {IOrderSummary} from 'BusinessPortal/models/order/createOrder/IOrderSummary';
import {Nullable} from 'Common/types';
import {IAssociationOrderEmployee} from 'Admin/AdminAssociations/models/Order/createOrder/IAssociationOrderEmployee';

const STATE_NAME = 'adminAssociationCreateUserOrder';

export interface IAdminAssociationCreateUserOrderState {
  data: {
    userHorses: IOrderHorse[];
    userSummary: Nullable<IOrderSummary>;
    userCoupon: Nullable<IOrderCoupon>;
    associationEmployees: IAssociationOrderEmployee[];
  };
  communications: {
    userHorsesLoading: ICommunication;
    userSummaryLoading: ICommunication;
    userCouponLoading: ICommunication;
    creatingUserOrder: ICommunication;
    associationEmployeesLoading: ICommunication;
  };
}

export const getUserHorsesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_HORSES');
export type getUserHorsesActions = MakeCommunicationActions<typeof getUserHorsesActionTypes, {success: IOrderHorse[]}>;

export const getUserSummaryActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_SUMMARY');
export type getUserSummaryActions = MakeCommunicationActions<
  typeof getUserSummaryActionTypes,
  {success: IOrderSummary}
>;

export const getUserCouponActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_COUPON');
export type getUserCouponActions = MakeCommunicationActions<typeof getUserCouponActionTypes, {success: IOrderCoupon}>;

export const getAssociationEmployeesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ASSOCIATION_EMPLOYEES');
export type getAssociationEmployeesActions = MakeCommunicationActions<
  typeof getAssociationEmployeesActionTypes,
  {success: IAssociationOrderEmployee[]}
>;

export const createUserOrderActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_USER_ORDER');
export type createUserOrderActions = MakeCommunicationActions<typeof createUserOrderActionTypes, {}>;

export const resetUserCouponActionTypes = makeResetCommunicationActionType(STATE_NAME, 'USER_COUPON');
export type resetUserCouponActions = MakeResetCommunicationActions<typeof resetUserCouponActionTypes>;

export const resetUserSummaryActionTypes = makeResetCommunicationActionType(STATE_NAME, 'USER_SUMMARY');
export type resetUserSummaryActions = MakeResetCommunicationActions<typeof resetUserSummaryActionTypes>;

export const resetAdminUserOrderActionTypes = makeResetCommunicationActionType(STATE_NAME, 'USER_ORDER');
export type resetAdminUserOrderActions = MakeResetCommunicationActions<typeof resetAdminUserOrderActionTypes>;

export type Actions =
  | getUserHorsesActions
  | getUserSummaryActions
  | getUserCouponActions
  | getAssociationEmployeesActions
  | createUserOrderActions
  | resetUserCouponActions
  | resetUserSummaryActions
  | resetAdminUserOrderActions;
