import {Reducer} from 'redux';

import {IAdminErrorDescriptionsState} from '../types';
import {
  getErrorDescriptionsActionTypes,
  ErrorDescriptionsActions
} from 'Admin/AdminSettings/stores/adminErrorDescriptions/types';

const initialState: IAdminErrorDescriptionsState['ui'] = {
  pagination: {countPerPage: 0, currentPage: 1, pageCount: 1, totalItemsCount: 1}
};

export const uiErrorDescriptionsReducer: Reducer<IAdminErrorDescriptionsState['ui'], ErrorDescriptionsActions> = (
  state = initialState,
  action: ErrorDescriptionsActions
) => {
  switch (action.type) {
    case getErrorDescriptionsActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination || initialState.pagination};
    }
    default: {
      return state;
    }
  }
};
