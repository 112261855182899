import {action} from 'typesafe-actions';

import {forgotPasswordActions, ForgotPasswordActionTypes, IForgotPasswordState} from 'ForgotPassword/store/types';
import {ActionResult} from 'Common/store/store';
import AuthService from 'Auth/services/AuthService';
import {IForgotPassword} from 'ForgotPassword/models/IForgotPassword';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const forgotPassword = (
  data: IForgotPassword
): ActionResult<IForgotPasswordState, void, forgotPasswordActions> => async dispatch => {
  dispatch(action(ForgotPasswordActionTypes.REQUEST));

  await AuthService.forgotPassword(data)
    .then(res => {
      dispatch(action(ForgotPasswordActionTypes.SUCCESS, res.data.data));
    })
    .catch(error => {
      dispatch(action(ForgotPasswordActionTypes.FAILURE, getErrorMessage(error)));
    });
};
