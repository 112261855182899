import * as Yup from 'yup';
import {IAssociationPageContactInformation} from 'BusinessPortal/models/IAssociationPageContactInformation';

export type IFormValues = IAssociationPageContactInformation;

export const initialValue: IFormValues = {
  id: 0,
  email: '',
  phoneNumber: '',
  websiteUrl: '',
};

export const validationSchema = Yup.object().shape<IFormValues>({
  id: Yup.number().required(),
  email: Yup.string(),
  phoneNumber: Yup.string().nullable(),
  websiteUrl: Yup.string().nullable(),
});
