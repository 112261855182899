import {IModule} from 'redux-dynamic-modules';
import {IAdminAssociationCreateUserOrderState} from './types';
import {adminAssociationCreateUserOrderReducer} from './reducer';

export interface IAdminCreateUserOrderModuleState {
  adminAssociationCreateUserOrder: IAdminAssociationCreateUserOrderState;
}

export const AdminAssociationCreateUserOrderModule: IModule<IAdminCreateUserOrderModuleState> = {
  id: 'adminAssociationCreateUserOrder',
  reducerMap: {
    adminAssociationCreateUserOrder: adminAssociationCreateUserOrderReducer,
  },
};
