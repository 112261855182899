import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {IShortDescriptionEntity} from 'Common/models/IDescriptionEntity';
import {Nullable} from 'Common/types';
import {IUpdateAbilityRequest} from 'Admin/AdminPhenotypes/service/models/IServerAbility';
import {IExternalUrlEntity} from 'Common/models/IExternalUrlEntity';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues extends IShortDescriptionEntity, IExternalUrlEntity {
  id?: IEntity['id'];
  name: string;
  groupId: Nullable<number>;
}

export const initialValue: IFormValues = {
  name: '',
  description: {value: ''},
  groupId: null,
  externalDescriptionUrl: ''
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  name: Yup.string().required(REQUIRED_FIELD),
  groupId: Yup.number()
    .nullable()
    .required(REQUIRED_FIELD)
});

export const convertToServer = (values: IFormValues): Omit<IUpdateAbilityRequest, 'id'> => {
  delete values['group'];
  return {
    ...values,
    description: {value: values.description?.value || ''},
    group: values.groupId ? {id: values.groupId} : null
  };
};

export const convertToServerUpdating = (values: IFormValues): IUpdateAbilityRequest => {
  return {
    ...convertToServer(values),
    id: values.id!
  };
};
