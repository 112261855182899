import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {FormType} from 'Common/constants/FormType';
import PackageInfo from 'Admin/AdminDashboard/components/Packages/PackageForm/PackageInfo';
import PackageForm from 'Admin/AdminDashboard/components/Packages/PackageForm/PackageForm';
import {IAppState} from 'Common/store/IAppState';
import {actions as packageActions, selectors as packageSelectors} from 'Admin/AdminDashboard/store/adminPackages';

interface IExternalProps {
  packageId?: number;
  type: FormType;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalProps;

const PackageLayout = (props: AllProps) => {
  const {packageId, type, onSuccess} = props;
  const {packageInfo, getPackageDetails, resetPackageDetails, packageLoading} = props;

  useEffect(() => {
    const fetchData = async () => {
      if (packageId) {
        await getPackageDetails(packageId);
      } else {
        await resetPackageDetails();
      }
    };

    fetchData();

    return () => resetPackageDetails();
  }, [packageId, getPackageDetails, resetPackageDetails]);

  const packageFormCommunication = packageLoading;

  return type === FormType.show ? (
    <PackageInfo packageInfo={packageInfo} externalCommunication={packageFormCommunication} />
  ) : (
    <PackageForm
      onSuccess={onSuccess}
      packageInfo={packageInfo}
      externalCommunication={packageFormCommunication}
      type={type}
    />
  );
};

const mapStateToProps = (state: IAppState, props: IExternalProps) => ({
  packageInfo: packageSelectors.selectPackageDetails(state),
  packageLoading: packageSelectors.selectCommunication(state, 'packageDetailsLoading'),
});

const mapDispatchToProps = {
  getPackageDetails: packageActions.getPackageById,
  resetPackageDetails: packageActions.resetPackageDetailResults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(PackageLayout);
