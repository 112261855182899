import {combineReducers, Reducer} from 'redux';

import {IMainPageHorsesState} from '../types';
import {communicationReducer} from './communications';
import {dataReducer} from './data';

export const mainPageHorsesReducer: Reducer<IMainPageHorsesState> = combineReducers<IMainPageHorsesState>({
  communications: communicationReducer,
  data: dataReducer
});
