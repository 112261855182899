import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {IScheduledSubscription, ISubscription} from 'StripeSubscription/models/ISubscription';

export function convertSubscriptionToClient(subscription: ISubscription): ISubscription {
  const {currentPeriodEnd, startDate} = subscription;
  return {
    ...subscription,
    startDate: convertUTCToClientDate(startDate) || '',
    currentPeriodEnd: convertUTCToClientDate(currentPeriodEnd) || ''
  };
}

export function convertScheduledSubscriptionToClient(subscription: IScheduledSubscription): IScheduledSubscription {
  const {startDate} = subscription;
  return {
    ...subscription,
    startDate: convertUTCToClientDate(startDate) || ''
  };
}
