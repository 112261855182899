import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {downloadSubmissionFormActionTypes, downloadSubmissionFormActions} from './types';
import SubmissionFormDataService from 'Order/services/SubmissionFormDataService';

export const downloadOrderSubmissionForm = (
  token: string
): ActionResult<IAppState, Promise<void>, downloadSubmissionFormActions> => async dispatch => {
  dispatch(action(downloadSubmissionFormActionTypes.REQUEST));
  try {
    await SubmissionFormDataService.downloadOrderSubmissionForm(token);
    dispatch(action(downloadSubmissionFormActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(downloadSubmissionFormActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const downloadHorseSubmissionForm = (
  orderId: number,
  horseId: number
): ActionResult<IAppState, Promise<void>, downloadSubmissionFormActions> => async dispatch => {
  dispatch(action(downloadSubmissionFormActionTypes.REQUEST));
  try {
    await SubmissionFormDataService.downloadHorseSubmissionForm(orderId, horseId);
    dispatch(action(downloadSubmissionFormActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(downloadSubmissionFormActionTypes.FAILURE, getErrorMessage(error)));
  }
};
