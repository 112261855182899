import {IAncestryUploadDetails} from 'Admin/AdminDashboard/models/Ancestry/IAncestryUploadDetails';
import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';

const STATE_NAME = 'adminAncestryUpload';

export interface IAdminAncestryUploadState {
  data: {
    ancestryUploadDetails: IAncestryUploadDetails;
  };
  communications: {
    ancestryUploading: ICommunication;
    ancestryUploadDetailsLoading: ICommunication;
    ancestryHorsesLikeMeUploading: ICommunication;
  };
}

export const uploadAncestryActionTypes = makeCommunicationActionType(STATE_NAME, 'ANCESTRY_UPLOAD_FILES');
export type uploadAncestryActions = MakeCommunicationActions<typeof uploadAncestryActionTypes, {}>;

export const getAncestryUploadDetailsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ANCESTRY_UPLOAD_DETAILS'
);
export type getAncestryUploadDetailsActions = MakeCommunicationActions<
  typeof getAncestryUploadDetailsActionTypes,
  {success: IAncestryUploadDetails}
>;

export const uploadAncestryHorsesLikeMeActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'ANCESTRY_HORSES_LIKE_ME_UPLOAD_FILES'
);
export type uploadAncestryHorsesLikeMeActions = MakeCommunicationActions<
  typeof uploadAncestryHorsesLikeMeActionTypes,
  {}
>;

export type AdminAncestryUploadActions =
  | uploadAncestryActions
  | getAncestryUploadDetailsActions
  | uploadAncestryHorsesLikeMeActions;
