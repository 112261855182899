import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import CreateOrderDataService from 'BusinessPortal/services/CreateOrderService';
import {ITestSummaryRequest} from 'BusinessPortal/services/types/summary';
import {ICreateOrderRequest} from 'BusinessPortal/models/order/createOrder/ICreateOrderRequest';

import {
  createOrderActions,
  createOrderActionTypes,
  getCouponActions,
  getCouponActionTypes,
  getHorsesActions,
  getHorsesActionTypes,
  getSummaryActions,
  getSummaryActionTypes,
  resetCouponActionTypes,
  resetSummaryActionTypes,
} from 'BusinessPortal/store/createOrder/types';
import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import {ICheckCouponRequest} from 'BusinessPortal/services/types/coupon';

export const getHorses = (): ActionResult<IAppState, Promise<void>, getHorsesActions> => async (dispatch) => {
  dispatch(action(getHorsesActionTypes.REQUEST));
  try {
    const res = await CreateOrderDataService.getHorses();
    dispatch(action(getHorsesActionTypes.SUCCESS, res));
  } catch (error) {
    dispatch(action(getHorsesActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const getSummary =
  (request: ITestSummaryRequest): ActionResult<IAppState, Promise<void>, getSummaryActions> =>
  async (dispatch) => {
    dispatch(action(getSummaryActionTypes.REQUEST));
    try {
      const res = await CreateOrderDataService.getSummary(request);
      dispatch(action(getSummaryActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getSummaryActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getCoupon =
  (request: ICheckCouponRequest): ActionResult<IAppState, Promise<IOrderCoupon>, getCouponActions> =>
  async (dispatch) => {
    dispatch(action(getCouponActionTypes.REQUEST));
    try {
      const res = await CreateOrderDataService.getCoupon(request);
      dispatch(action(getCouponActionTypes.SUCCESS, res));
      return res;
    } catch (err) {
      const error = getErrorMessage(err);
      dispatch(action(getCouponActionTypes.FAILURE, error));
      throw error;
    }
  };

export const createOrder =
  (request: ICreateOrderRequest): ActionResult<IAppState, Promise<void>, createOrderActions> =>
  async (dispatch) => {
    dispatch(action(createOrderActionTypes.REQUEST));
    try {
      const res = await CreateOrderDataService.createOrder(request);
      dispatch(action(createOrderActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(createOrderActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetCoupon = () => action(resetCouponActionTypes.RESET);
export const resetSummary = () => action(resetSummaryActionTypes.RESET);
