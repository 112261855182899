import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import HeaderBackground from './img/horse-background.svg';
import {breakpoints} from 'Common/constants/Breakpoints';
import MainPageHorses from 'Main/components/MainPageHorses/Horses';
import MainButtons from 'Main/components/MainPageButtons/MainButtons';
import RecentOrders from 'Main/components/MainPageRecentOrders/RecentOrders';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';

const MainRoot = styled.div`
  background-color: ${ColorPalette.gray38};
`;

const Top = styled.div`
  background: ${ColorPalette.black1};
  overflow: hidden;
`;

const HorseBackground = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${HeaderBackground});
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  right: -25%;
  bottom: -50px;

  @media ${breakpoints.md} {
    right: -10%;
  }
  @media ${breakpoints.xl} {
    right: -1%;
  }
`;

const Content = styled.div`
  margin: 0 16px 20px 16px;
  max-width: 1087px;

  @media ${breakpoints.sm} {
    margin: 0 auto 20px auto;
    width: 90%;
  }
`;

const Bottom = styled.div`
  background-color: ${ColorPalette.gray38};

  @media ${breakpoints.sm} {
    padding: 20px 144px 20px 152px;
  }
`;

function Main() {
  return (
    <BaseLayout withoutPaddings={true} isBackButtonDenied={true}>
      <MainRoot className="flex-grow-1 d-flex flex-column justify-content-center">
        <Top className="flex-grow-1 position-relative">
          <HorseBackground className="position-absolute" />

          <Content className="d-flex flex-column position-relative">
            <MainButtons />

            <MainPageHorses />
          </Content>
        </Top>
        <Bottom className="d-flex justify-content-center">
          <RecentOrders />
        </Bottom>
      </MainRoot>
    </BaseLayout>
  );
}

export default memo(Main);
