import {Reducer} from 'redux';
import {
  createSubscriptionActionTypes,
  getDefaultPaymentMethodActionTypes,
  IStripeSubscriptionState,
  stripeSubscriptionActions,
  getUserSubscriptionsActionTypes,
  getUserScheduledSubscriptionsActionTypes
} from 'StripeSubscription/store/types';

const initialState: IStripeSubscriptionState['data'] = {
  defaultPaymentMethod: null,
  createdSubscription: null,
  userSubscriptions: null,
  userScheduledSubscription: null
};

export const dataStripeSubscriptionReducer: Reducer<IStripeSubscriptionState['data'], stripeSubscriptionActions> = (
  state = initialState,
  action: stripeSubscriptionActions
) => {
  switch (action.type) {
    case getDefaultPaymentMethodActionTypes.REQUEST: {
      return {...state, defaultPaymentMethod: null};
    }
    case getDefaultPaymentMethodActionTypes.SUCCESS: {
      return {...state, defaultPaymentMethod: action.payload};
    }

    case createSubscriptionActionTypes.REQUEST: {
      return {...state, createdSubscription: null};
    }
    case createSubscriptionActionTypes.SUCCESS: {
      return {...state, createdSubscription: action.payload};
    }

    case getUserSubscriptionsActionTypes.REQUEST: {
      return {...state, userSubscriptions: null};
    }
    case getUserSubscriptionsActionTypes.SUCCESS: {
      return {...state, userSubscriptions: action.payload};
    }

    case getUserScheduledSubscriptionsActionTypes.REQUEST: {
      return {...state, userScheduledSubscription: null};
    }
    case getUserScheduledSubscriptionsActionTypes.SUCCESS: {
      return {...state, userScheduledSubscription: action.payload};
    }

    default: {
      return state;
    }
  }
};
