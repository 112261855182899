import {Link as RouterLink} from 'react-router-dom';
import styled from 'styled-components';

export default styled(RouterLink)`
  text-decoration: none !important;

  color: unset;

  :hover {
    color: unset;
  }
`;
