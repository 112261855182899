import {IServerOrderHistory, IServerOrderHistoryHorse} from 'Admin/AdminDashboard/services/types/common/orderHistory';
import {IOrderHistory, IOrderHistoryHorse} from 'Admin/AdminDashboard/components/shared/OrderHistory/parts/common';
import {parseOrderStatus} from 'Order/helpers/parseOrderStatus';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';

export function convertServerOrderHistoryToClient(orderHistory: IServerOrderHistory): IOrderHistory {
  return {
    ...orderHistory,
    createDate: convertUTCToClientDate(orderHistory.createDate) || '',
    horses: orderHistory.horses.map(convertServerOrderHistoryHorsesToClient)
  };
}

export function convertServerOrderHistoryHorsesToClient(horse: IServerOrderHistoryHorse): IOrderHistoryHorse {
  return {
    ...horse,
    ...parseOrderStatus(horse.statuses)
  };
}
