import {IDefaultPaymentMethod} from 'StripeSubscription/models/IDefaultPaymentMethod';
import {SubscriptionStatus} from 'Common/constants/SubscriptionStatus';

export const getDefaultPaymentMethodString = (defaultPaymentMethod: IDefaultPaymentMethod) => {
  if (defaultPaymentMethod) {
    return `${defaultPaymentMethod?.cardBrand} ... ${defaultPaymentMethod?.last4}`;
  }

  return '';
};

export const finishedSubscriptionStatuses = [SubscriptionStatus.Incomplete_expired, SubscriptionStatus.Canceled];

export const hasPaymentProblemStatuses = [
  SubscriptionStatus.Incomplete,
  SubscriptionStatus.Past_due,
  SubscriptionStatus.Unpaid,
];
