import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
} from 'Common/store/utils/communication/index';

const STATE_NAME = 'contactRequest';

export interface IContactRequestState {
  communications: {
    sendContactRequesting: ICommunication;
  };
}

export const contactRequestActionTypes = makeCommunicationActionType(STATE_NAME, 'CONTACT_REQUEST');

export type contactRequestActions = MakeCommunicationActions<typeof contactRequestActionTypes, {}>;

export type ContactRequestsActions = contactRequestActions;
