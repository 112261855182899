import * as Yup from 'yup';
import {IUpdateAdministratorPassword} from 'Admin/AdminAdvancedDashboard/models/Admins/IAdministrator';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export const initialValue: IUpdateAdministratorPassword = {
  id: 0,
  password: '',
  passwordConfirm: ''
};

export const validationSchema = Yup.object().shape<IUpdateAdministratorPassword>({
  id: Yup.number().required(REQUIRED_FIELD),
  password: Yup.string()
    .required('New password is required')
    .oneOf([Yup.ref('passwordConfirm')], 'Passwords must match'),
  passwordConfirm: Yup.string()
    .required('Repeat new password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match')
});
