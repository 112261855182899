import styled from 'styled-components';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';

export const StatusLabel = styled.div<{color?: string}>`
  margin: 0 12px 0 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${Typography.size.size18};
  font-family: ${Typography.family.openSans};
  color: ${({color}) => color || ColorPalette.black1};
`;
