import {BreedType, SORT_BREED_NAME} from 'OnlineReport/constants/BreedType';

export function sortBreeds<T extends {type: BreedType}>(breeds: T[]): T[] {
  return breeds.sort((a, b) => {
    if (SORT_BREED_NAME[a.type] > SORT_BREED_NAME[b.type]) {
      return 1;
    }
    return -1;
  });
}
