import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IPackage} from 'Dictionaries/models/IPackage';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {
  IPackageCartSettingsRequest,
  IPackageCreateRequest,
  IPackageUpdateRequest,
} from 'Admin/AdminDashboard/models/Package/IPackageAdmin';
import {convertPackageToClient, convertPackageToRequest} from 'Admin/AdminDashboard/services/converters/package';
import {IServerPackage} from './types/package';

const getPackages = async (params?: IRequestParams): Promise<IListResponse<IPackage>> => {
  const response = await axiosWrapper.get<IListResponse<IServerPackage>>(`/PackagesTable`, {params});
  return {
    ...response.data,
    data: response.data.data.map(convertPackageToClient),
  };
};

const getPackagesByFilter = async (params?: IRequestParams): Promise<IListResponse<IPackage>> => {
  const response = await axiosWrapper.post<IListResponse<IServerPackage>>(`/PackagesReadByFiltersAdmin`, params);
  return {
    ...response.data,
    data: response.data.data.map(convertPackageToClient),
  };
};

const getPackage = async (id: number): Promise<IPackage> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerPackage>>(`/PackagesTableDetails/${id}`);
  return convertPackageToClient(response.data.data);
};

const createPackage = async (packageInfo: IPackageCreateRequest): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/PackagesTableCreate`, convertPackageToRequest(packageInfo));
};

const updatePackage = async (packageInfo: IPackageUpdateRequest): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(`/PackagesTableUpdate`, convertPackageToRequest(packageInfo));
};

const deletePackage = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/PackagesTableDelete`, {data: {id}});
};

const changePackageStatus = async (id: number, isEnabled: boolean): Promise<void> => {
  await axiosWrapper.patch<IObjectResponse<IPackage>>(`/PackagesStatusUpdateAdmin`, {id, isEnabled});
};

const updatePackageCartSettings = async (packageCartSettings: IPackageCartSettingsRequest): Promise<void> => {
  await axiosWrapper.patch<IObjectResponse<null>>(`/PackagesBundleUpdateAdmin`, packageCartSettings);
};

export default {
  getPackages,
  getPackagesByFilter,
  getPackage,
  createPackage,
  updatePackage,
  deletePackage,
  changePackageStatus,
  updatePackageCartSettings,
};
