import React, {memo} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'FavoriteHorse/store/';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {searchBarStyle} from 'BusinessPortal/components/common/styled';
import {IUserHorse} from 'Horse/models/IUserHorse';
import {
  BusinessPortalCellContent,
  BusinessPortalHeaderCell,
  BusinessPortalSearch,
  BusinessPortalTableWrapper,
} from 'BusinessPortal/components/common/BusinessPortalTable';
import Loading from 'Loading/components/Loading';
import {Cell, Table} from 'Common/components/Table/Table';
import Typography from 'Common/constants/Typography';
import {AvatarCell} from 'Admin/AdminDashboard/components/shared';
import {Pagination} from 'Common/components/Controls';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import withFavoriteHorseActions, {
  getWithFavoriteHorseActionsModules,
  IWithFavoriteHorseActionsProps,
} from 'Common/helpers/withFavoriteHorseActions';
import FavoriteHorseButtons from 'FavoriteHorse/components/FavoriteHorseButtons';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IWithFavoriteHorseActionsProps;

function Favorites(props: AllProps) {
  const {
    getFavoriteHorses,
    favoriteHorses,
    favoriteHorsesLoading,
    pagination,
    addFavoriteHorse,
    deleteFavoriteHorse,
    favoriteHorseAdding,
    favoriteHorseDeleting,
  } = props;

  const {changeSorting, sorting, handlePageSelect, handleCountPerPage, searchBar} = useCommonAdminPageData<IUserHorse>({
    getItems: getFavoriteHorses,
    searchBarPlaceholder: 'Search for horses by any keyword',
    searchBarStyle: searchBarStyle,
  });

  const isLoading = [favoriteHorsesLoading].some((x) => x.isRequesting);

  const hasHorses = favoriteHorses.length > 0 && !isLoading;

  useErrorCommunicationToToast(favoriteHorseAdding, undefined, 1);
  useErrorCommunicationToToast(favoriteHorseDeleting, undefined, 2);

  const {isMobile, isTablet} = useMediaQuery();

  return (
    <>
      <BusinessPortalSearch>{searchBar}</BusinessPortalSearch>
      <BusinessPortalTableWrapper>
        {isLoading && <Loading />}
        <>
          <Table<IUserHorse>
            data={favoriteHorses}
            rowKey="id"
            sorting={sorting}
            onChangeSorting={changeSorting}
            expandable={false}
            disableRow={() => false}
            customHeaderCell={BusinessPortalHeaderCell}
            // not needed for expanded styles but also affect models row
            expandedRowStyles={{expandedRow: {height: 'auto'}}}
            tableStyles={{fontSize: Typography.size.size16, fontFamily: Typography.family.ubuntu}}
            isMobile={isMobile || isTablet}
          >
            <Cell<IUserHorse>
              header="Horse"
              dataKey="name"
              sortable={false}
              render={({name, id, avatar}) => (
                <BusinessPortalCellContent>
                  <AvatarCell
                    type="horse"
                    label={name}
                    avatarUrl={avatar?.url}
                    profileUrl={`/business-portal/horse/${id}`}
                  />
                </BusinessPortalCellContent>
              )}
              width="60%"
            />
            <Cell<IUserHorse>
              header="Actions"
              sortable={false}
              align={CellAlign.Right}
              width="20%"
              render={({id, isFavorite}) => (
                <BusinessPortalCellContent className="d-flex justify-content-end">
                  <FavoriteHorseButtons
                    isOwnFavorite={!!isFavorite}
                    addFavoriteHorseHandler={() => addFavoriteHorse(id)}
                    deleteFavoriteHorseHandler={() => deleteFavoriteHorse(id)}
                  />
                </BusinessPortalCellContent>
              )}
            />
          </Table>

          {hasHorses && (
            <Pagination
              pagination={pagination}
              onPageSelect={handlePageSelect}
              onChangeCountPerPage={handleCountPerPage}
            />
          )}
        </>
      </BusinessPortalTableWrapper>
    </>
  );
}

const mapStateToProps = (state: IAppState) => ({
  pagination: selectors.selectPagination(state),
  favoriteHorses: selectors.selectHorses(state),
  favoriteHorsesLoading: selectors.selectCommunication(state, 'favoriteHorsesLoading'),
});

const mapDispatchToProps = {
  getFavoriteHorses: actions.getFavoriteHorses,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const Connected = withFavoriteHorseActions(connector(memo(Favorites)));

export default withDynamicModules(Connected, getWithFavoriteHorseActionsModules());
