import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  deleteImageActionTypes,
  getImageActionTypes,
  IAdminPhenotypeGroupImageState,
  AdminPhenotypeGroupImageActions,
  uploadImageActionTypes
} from 'Admin/AdminPhenotypes/store/adminPhenotypeGroupImage/types';

export const communicationPhenotypeGroupImageReducer = combineReducers<
  IAdminPhenotypeGroupImageState['communications'],
  AdminPhenotypeGroupImageActions
>({
  imageLoading: makeCommunicationReducerFromEnum(getImageActionTypes),
  imageUploading: makeCommunicationReducerFromEnum(uploadImageActionTypes),
  imageDeleting: makeCommunicationReducerFromEnum(deleteImageActionTypes)
});
