import {HeightUnits} from 'HorseProfile/constants/HeightUnits';

const inchCoefficient: Record<HeightUnits, number> = {
  Centimeters: 2.54,
  Inch: 1,
  Meters: 0.0254,
  Hands: 4
};

export function convertHeight(value: number, unitFrom: HeightUnits, unitTo: HeightUnits) {
  let valueInInch = value / inchCoefficient[unitFrom];

  if (unitFrom === HeightUnits.Hands) {
    const remainderPart = Math.round((value % 1) * 10);
    const integerPart = Math.round(value);
    valueInInch = integerPart * inchCoefficient[unitFrom] + remainderPart;
  }

  if (unitTo === HeightUnits.Hands) {
    const remainderPart = Math.round(valueInInch % inchCoefficient[unitTo]);
    const integerPart = Math.round((valueInInch - remainderPart) / inchCoefficient[unitTo]);
    const convertedValue = integerPart + remainderPart / 10;

    return convertedValue;
  }

  const valueFromInch = valueInInch * inchCoefficient[unitTo];
  return valueFromInch;
}

export function roundHeight(value: number, unit: HeightUnits) {
  if (unit === HeightUnits.Centimeters) {
    return Math.round(value);
  }
  return Math.round(value * 100) / 100;
}
