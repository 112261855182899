import React, {memo, useMemo} from 'react';
import styled from 'styled-components';

import {Nullable} from 'Common/types';
import {IOnlineReportHorseDetails} from 'OnlineReport/models/shared/IOnlineReportHorseDetails';
import Icon, {IconName} from 'Icon/components/Icon';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {Header, Label, Section, SectionContent, SectionHeader} from 'OnlineReportPrintable/common/styled';
import {convertToClientDate, dateDiffInYearsOrMonth} from 'Common/helpers/DateHelper';
import {breakpoints} from 'Common/constants/Breakpoints';

const Value = styled.div`
  word-break: break-word;
  margin-bottom: 22px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  align-items: center;
  color: ${Theme.color.black};
`;

const Registry = styled.div`
  margin-bottom: 12px;
`;

const ContentContainer = styled.div<{isLeft?: boolean}>`
  min-width: 350px;
  @media ${breakpoints.sm} {
    padding-left: ${({isLeft}) => (isLeft ? 0 : 10)}px;
    padding-right: ${({isLeft}) => (isLeft ? 10 : 0)}px;
  }
`;

const SectionPrintable = styled(SectionContent)`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.sm} {
    flex-direction: row;
  }

  @media print {
    flex-direction: row;
  }
`;

interface IExternalProps {
  horseDetails: Nullable<IOnlineReportHorseDetails>;
}

const HorseDetails = (props: IExternalProps) => {
  const {horseDetails} = props;

  const leftContent = useMemo(() => {
    if (!horseDetails) {
      return null;
    }

    return [
      {label: 'Horse', value: horseDetails.horseName},
      {label: 'Breed', value: horseDetails.breeds.join(', ')},
      {label: 'Color', value: horseDetails.colors.join(', ')},
      {label: 'Discipline', value: horseDetails.disciplines.join(', ')},
      {label: 'Registry', value: horseDetails.horseRegistries.map((item, i) => <div key={i}>{item.name}</div>)},
      {label: 'Sire', value: horseDetails.sirName},
      {
        label: 'Sire Reg & No.',
        value: horseDetails.sirRegistries.map((item, i) => (
          <Registry key={i}>
            <div>{item.name}</div>
            <div>{item.number}</div>
          </Registry>
        )),
      },
      {label: 'Owner', value: horseDetails.ownerName},
      {label: 'Phone', value: horseDetails.phone},
      {label: 'Email', value: horseDetails.email},
    ];
  }, [horseDetails]);

  const rightContent = useMemo(() => {
    if (!horseDetails) {
      return null;
    }

    const dob = horseDetails.dob ? convertToClientDate(horseDetails.dob) : null;

    return [
      {label: 'Date of birth', value: dob},
      {label: 'Age', value: dob ? dateDiffInYearsOrMonth(dob) : null},
      {label: 'Sex', value: horseDetails.sex},
      {label: 'Height', value: horseDetails.height && `${horseDetails.height} ${horseDetails.heightUnit}`},
      {label: 'Reg number', value: horseDetails.horseRegistries.map((item, i) => <div key={i}>{item.number}</div>)},
      {label: 'Dam', value: horseDetails.damName},
      {
        label: 'Dam Reg & No.',
        value: horseDetails.damRegistries.map((item, i) => (
          <Registry key={i}>
            <div>{item.name}</div>
            <div>{item.number}</div>
          </Registry>
        )),
      },
      {label: 'Address', value: horseDetails.ownerAddress},
      {
        label: 'City, State',
        value: horseDetails.city || horseDetails.state ? [horseDetails.city, horseDetails.state].join(', ') : null,
      },
      {label: 'Postal code', value: horseDetails.zip},
    ];
  }, [horseDetails]);

  return (
    <Section>
      <SectionHeader className="d-flex w-100 align-items-center">
        <Icon name={IconName.Notebook2} />
        <Header>Horse and owner information</Header>
      </SectionHeader>
      <SectionPrintable className="d-flex justify-content-between">
        <ContentContainer isLeft={true}>
          {leftContent?.map((item, i) => (
            <div className="d-flex flex-column" key={i}>
              <Label>{item.label}</Label>
              <Value>{item.value || '-'}</Value>
            </div>
          ))}
        </ContentContainer>
        <div>
          {rightContent?.map((item, i) => (
            <ContentContainer className="d-flex flex-column" key={i}>
              <Label>{item.label}</Label>
              <Value>{item.value || '-'}</Value>
            </ContentContainer>
          ))}
        </div>
      </SectionPrintable>
    </Section>
  );
};

export default memo(HorseDetails);
