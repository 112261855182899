import {Reducer} from 'redux';

import {
  GalleryActions,
  IGalleryState,
  getGalleryActionTypes,
  deleteMediaFromGalleryActionTypes,
  addImagesToGalleryActionTypes,
  addVideoToGalleryActionTypes
} from '../types';

const initialImageState: IGalleryState['data'] = {gallery: []};

export const dataReducer: Reducer<IGalleryState['data'], GalleryActions> = (
  state = initialImageState,
  action: GalleryActions
) => {
  switch (action.type) {
    case getGalleryActionTypes.SUCCESS: {
      return {...state, gallery: action.payload};
    }

    case deleteMediaFromGalleryActionTypes.SUCCESS: {
      const {mediaIds} = action.payload;
      if (!state.gallery) {
        return state;
      }
      return {
        ...state,
        gallery: state.gallery.filter(media => !mediaIds.includes(media.id))
      };
    }

    case addImagesToGalleryActionTypes.SUCCESS: {
      const horseGallery = state.gallery;
      if (!horseGallery) {
        return state;
      }
      return {...state, gallery: state.gallery.concat(action.payload.map(x => ({...x, isLocal: false})))};
    }

    case addVideoToGalleryActionTypes.SUCCESS: {
      const horseGallery = state.gallery;
      if (!horseGallery) {
        return state;
      }
      return {...state, gallery: state.gallery.concat(action.payload)};
    }
    default: {
      return state;
    }
  }
};
