import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Indicator from 'Common/components/Indicator/Indicator';
import {IIndicator} from 'Common/models/IIndicator';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const Header = styled.div`
  display: flex;

  @media ${breakpoints.sm} {
    display: block;
    text-align: center;
  }
`;

const Label = styled.div`
  font-weight: ${Typography.weight.medium500};
  color: ${ColorPalette.gray3};
  padding-right: 8px;
  line-height: 24px;

  @media ${breakpoints.sm} {
    position: absolute;
    padding-right: 0;
  }
`;

const Title = styled.div<{isDisabled?: boolean}>`
  font-weight: ${Typography.weight.bold700};
  color: ${({isDisabled}) => (isDisabled ? ColorPalette.gray2 : 'unset')};
`;

const IndicatorWrapper = styled.div`
  margin-top: 8px;
`;

interface IProps {
  value: number;
  max: number;
  color: string;
  label: string;
  title: string;
  isEmpty?: boolean;
  description?: string;
}

function Characteristic(props: IProps) {
  const {value, max, color, label, title, isEmpty, description} = props;
  const {isMobile} = useMediaQuery();

  const indicator: IIndicator = {
    height: isMobile ? 9 : 16,
    segmentColor: color,
    segmentsMaxCount: max,
    value: value,
  };

  return (
    <div>
      <Header>
        <Label>{label}</Label>
        <Title isDisabled={isEmpty}>{title}</Title>
      </Header>
      <IndicatorWrapper>
        <Indicator indicator={indicator} tooltip={description} />
      </IndicatorWrapper>
    </div>
  );
}

export default memo(Characteristic);
