import {
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {IListResponse} from 'Common/models/IResponse';

import {IOwnerBP} from 'BusinessPortal/models/owner/IOwnerBP';
import {IOwnerDetailsBP} from 'BusinessPortal/models/owner/IOwnerDetailsBP';
import {IAssociationOwnerState} from 'Shared/components/Association/models/IAssociationOwnerState';

const STATE_NAME = 'businessPortalOwner';

export type IBusinessPortalOwnerState = IAssociationOwnerState<IOwnerBP>;

export const getOwnersActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_OWNERS');
export type getOwnersActions = MakeCommunicationActions<
  typeof getOwnersActionTypes,
  {success: IListResponse<IOwnerBP>}
>;

export const getOwnerDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_OWNER_DETAILS');
export type getOwnerDetailsActions = MakeCommunicationActions<
  typeof getOwnerDetailsActionTypes,
  {success: IOwnerDetailsBP}
>;

export const createOwnerActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_OWNER');
export type createOwnerActions = MakeCommunicationActions<typeof createOwnerActionTypes, {}>;

export const updateOwnerActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_OWNER');
export type updateOwnerActions = MakeCommunicationActions<typeof updateOwnerActionTypes, {}>;

export const updateOwnerAvatarActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_OWNER_AVATAR');
export type updateOwnerAvatarActions = MakeCommunicationActions<typeof updateOwnerAvatarActionTypes, {}>;

export const deleteOwnerActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_OWNER');
export type deleteOwnerActions = MakeCommunicationActions<typeof deleteOwnerActionTypes, {}>;

export const deleteOwnerAvatarActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_OWNER_AVATAR');
export type deleteOwnerAvatarActions = MakeCommunicationActions<typeof deleteOwnerAvatarActionTypes, {}>;

export const resetOwnersActionTypes = makeResetCommunicationActionType(STATE_NAME, 'CLEAR_OWNERS');
export type resetOwnersActions = MakeResetCommunicationActions<typeof resetOwnersActionTypes>;

export const resetOwnerDetailsActionTypes = makeResetCommunicationActionType(STATE_NAME, 'CLEAR_OWNER_DETAILS');
export type resetOwnerDetailsActions = MakeResetCommunicationActions<typeof resetOwnerDetailsActionTypes>;

export type BusinessPortalOwnerActions =
  | getOwnersActions
  | getOwnerDetailsActions
  | createOwnerActions
  | updateOwnerActions
  | updateOwnerAvatarActions
  | deleteOwnerActions
  | deleteOwnerAvatarActions
  | resetOwnersActions
  | resetOwnerDetailsActions;
