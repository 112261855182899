import {combineReducers, Reducer} from 'redux';

import {IFavoriteHorseState} from '../types';
import {communicationReducer} from './communications';
import {dataReducer} from './data';
import {uiReducer} from './ui';

export const favoriteHorsesReducer: Reducer<IFavoriteHorseState> = combineReducers<IFavoriteHorseState>({
  communications: communicationReducer,
  data: dataReducer,
  ui: uiReducer,
});
