import {memo, useCallback} from 'react';
import styled from 'styled-components';

import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {IAncestryRelatedHorse} from 'OnlineReport/models/Ancestry/IAncestryRelatedHorse';
import CompositionPie from '../CompositionChart/CompositionPie';

const CircleGraph = styled.div<{angle: number}>`
  ${(props) => `transform: rotate(${props.angle}deg) translate(0, 270px) rotate(-${props.angle}deg);`}

  margin-left: -48px;
  margin-top: 18px;

  @media ${breakpoints.sm} {
    margin-left: 25px;
    margin-top: 18px;
  }

  @media ${breakpoints.md} {
    margin-left: 150px;
  }

  @media print {
    margin-left: 48px;
  }
`;

const CompositionPieWrapper = styled.div`
  @media ${breakpoints.sm} {
    padding: 0 4px;
  }
`;

interface IProps {
  horses: IAncestryRelatedHorse[];
  isPrintable: boolean;
}

function RelatedHorses(props: IProps) {
  const {horses, isPrintable} = props;

  const {currentVisitorType} = useVisitorTypeService();
  const {isMobile} = useMediaQuery();

  const sortedHorses = horses.sort((a, b) => {
    if (a.percent > b.percent) return -1;
    return 1;
  });

  // Place in circle graph. Thanks to: https://stackoverflow.com/questions/12813573/position-icons-into-circle
  let angle = 135;
  const dangle = 360 / 8;

  const renderRelatedHorse = useCallback(
    (related: IAncestryRelatedHorse, index: number) => {
      return (
        <CompositionPieWrapper className="position-relative">
          <CompositionPie
            population={related.breeds}
            avatar={related.horse?.avatar}
            size="small"
            title={related.horse?.name || 'Private Account'}
            isNumerationShow={true}
            currentNumber={index + 1}
            profileUrl={
              related.horse?.id ? `${PREFIX_VISITOR_TYPE[currentVisitorType]}/horse/${related.horse?.id}` : ''
            }
            isPrintable={isPrintable}
            relatedHorse={related}
            titleSize={'small'}
          />
        </CompositionPieWrapper>
      );
    },
    [currentVisitorType, isPrintable]
  );

  return (
    <>
      {sortedHorses.map((related, i) => {
        if (isMobile) {
          return renderRelatedHorse(related, i);
        }

        angle += dangle;

        return (
          <CircleGraph className="position-absolute" key={i} angle={angle}>
            {renderRelatedHorse(related, i)}
          </CircleGraph>
        );
      })}
    </>
  );
}

export default memo(RelatedHorses);
