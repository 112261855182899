import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IHtmlTemplate} from '../models/IHtmlTemplate';
import {convertHtmlTemplateToClient} from './converters/htmlTemplate';
import {IUploadHtmlTemplateRequest} from '../models/IUploadHtmlTemplateRequest';
import {convertToFormData} from 'Common/helpers/convertToFormData';
import {IRequestParams} from 'Common/models/IRequestParams';
import {HtmlTemplateType} from '../constants/HtmlTemplateType';

const getHtmlTemplates = async (params?: IRequestParams): Promise<IListResponse<IHtmlTemplate>> => {
  const response = await axiosWrapper.get<IListResponse<IHtmlTemplate>>(`/HtmlTemplatesAdmin`, {params});
  return {
    ...response.data,
    data: response.data.data.map(convertHtmlTemplateToClient),
  };
};

const getHtmlTemplate = async (type: HtmlTemplateType, name?: string) => {
  const response = await axiosWrapper.get<string>(`/HtmlTemplatesByTypeAdmin`, {
    params: {type, name},
    responseType: 'text',
  });

  return response;
};

const uploadHtmlTemplate = async (params: IUploadHtmlTemplateRequest): Promise<void> => {
  const {name, type, uploadedFile} = params;

  await axiosWrapper.post<IObjectResponse<null>>('/HtmlTemplatesUpdateAdmin', convertToFormData(uploadedFile), {
    params: {type, name},
  });
};

const deleteHtmlTemplate = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/HtmlTemplatesDeleteAdmin`, {data: {ids: [id]}});
};

export default {
  getHtmlTemplates,
  getHtmlTemplate,
  uploadHtmlTemplate,
  deleteHtmlTemplate,
};
