import React, {memo} from 'react';
import styled, {css} from 'styled-components';
import {CardCVCElement, CardExpiryElement, CardNumberElement} from 'react-stripe-elements';

import Typography from 'Common/constants/Typography';
import {inputCss} from 'Common/components/Controls/styles';

import './StripeFields.css';

const formElementStyle = {
  base: {
    fontSize: '14px'
  },
  invalid: {
    color: '#181B26'
  }
};

const FieldContainer = styled.div`
  margin-top: 16px;
`;

const FieldContainersRow = styled.div`
  ${FieldContainer}:first-child {
    margin-right: 8px;
  }

  ${FieldContainer}:last-child {
    margin-left: 8px;
  }
`;

const Subtitle = styled.label`
  font-weight: ${Typography.weight.medium500};
  line-height: 24px;
`;

const formInput = css`
  margin-top: 8px;
  padding: 10px;
`;

const CardNumber = styled(CardNumberElement)`
  ${inputCss}
  ${formInput}
`;

const CardExpiry = styled(CardExpiryElement)`
  ${inputCss};
  ${formInput}
`;

const CardCVC = styled(CardCVCElement)`
  ${inputCss};
  ${formInput};
`;

interface IProps {
  onCardNumberChange?(event: stripe.elements.ElementChangeResponse): void;
  onCardExpiryChange?(event: stripe.elements.ElementChangeResponse): void;
  onCardCVCChange?(event: stripe.elements.ElementChangeResponse): void;
}

function StripeFields(props: IProps) {
  const {onCardNumberChange, onCardCVCChange, onCardExpiryChange} = props;

  return (
    <div>
      <FieldContainer>
        <Subtitle>Card number</Subtitle>
        <CardNumber style={formElementStyle} onChange={onCardNumberChange} />
      </FieldContainer>
      <FieldContainersRow className="d-flex ">
        <FieldContainer className="flex-grow-1">
          <Subtitle>Expire date</Subtitle>
          <CardExpiry style={formElementStyle} onChange={onCardExpiryChange} />
        </FieldContainer>
        <FieldContainer className="flex-grow-1">
          <Subtitle>CVC2/CVV2</Subtitle>
          <CardCVC placeholder="CVC2/CVV2" style={formElementStyle} onChange={onCardCVCChange} />
        </FieldContainer>
      </FieldContainersRow>
    </div>
  );
}

export default memo(StripeFields);
