import React, {memo} from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

const ButtonAsLinkStyle = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${Theme.color.black};
  cursor: pointer;
  color: ${Theme.color.primary} !important;
`;

function ButtonAsLink(props: React.PropsWithChildren<React.ButtonHTMLAttributes<any>>) {
  const {children, ...rest} = props;

  return <ButtonAsLinkStyle {...rest}>{children}</ButtonAsLinkStyle>;
}

export default memo(ButtonAsLink);
