import React from 'react';
import styled from 'styled-components';

import {IAvailabilityCriteria} from 'Common/models/IAvailabilityCriteria';
import {isAvailableForBah} from 'Common/helpers/isAvailableForBah';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import ColoredIcon from 'Icon/components/ColoredIcon';
import withoutProps from 'Common/helpers/WithoutProps';
import BahAccessCriteria from 'Common/components/BahAccessCriteria/BahAccessCriteria';
import {Value} from 'Admin/common/styled/StyledComponents';
import HintIcon from 'Common/components/HintIcont/HintIcon';

const iconProps = {color: ColorPalette.black0, size: 24, fill: false, stroke: false};

const Icon = styled(withoutProps(['hoverCursor'])(ColoredIcon))<{hoverCursor?: boolean}>`
  margin-right: 8px;

  :hover {
    cursor: ${({hoverCursor}) => (hoverCursor ? 'pointer' : 'default')};
  }
`;

const Text = styled(Value)`
  margin-right: 12px;
`;

interface IProps {
  criteria: IAvailabilityCriteria;
}

const HorseReadinessToBah = (props: IProps) => {
  const {criteria} = props;

  if (isAvailableForBah(criteria)) {
    return (
      <div className="d-flex align-items-center">
        <Icon {...iconProps} name={IconName.HorseGrantedBlack} />
        <Text>Granted</Text>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center">
      <Icon {...iconProps} name={IconName.HorseNotGranted} />
      <Text>Not granted</Text>
      <HintIcon tooltip={<BahAccessCriteria criteria={criteria} />} style={{marginLeft: 0}} />
    </div>
  );
};

export default HorseReadinessToBah;
export type HorseReadinessToBahProps = IProps;
