import qs from 'qs';

/**
 * Converts an object to a flattened query parameter string.
 *
 * @example
 * ```ts
 * const data = {
 *   hello: {
 *      goodbye: true,
 *      numbers: [1, 2, 3, 4],
 *      empty: []
 *   },
 *   name: 'bob'
 * };
 *
 * objectToQueryParams(data) === '?hello[goodbye]=true&hello[numbers]=1%2C2%2C3%2C4&name=bob'
 * ```
 */
export const objectToQueryParams = (input: Record<string, any>): string => {
  return qs.stringify(input, {
    // Arrays will be formated like `?array=1,2,3`
    arrayFormat: 'comma',
  });
};

/**
 * Converts a query string to an unflattened object.
 *
 * @example
 * ```ts
 * const input = '?hello[goodbye]=true&hello[numbers]=1%2C2%2C3%2C4&name=bob';
 * const output = {
 *   hello: {
 *      goodbye: true,
 *      numbers: [1, 2, 3, 4],
 *      empty: []
 *   },
 *   name: 'bob'
 * };
 *
 * queryParamsToObject(input) === output
 * ```
 */
export const queryParamsToObject = <T extends {}>(input: string): T => {
  // Chop `?` from query string.
  if (input[0] === '?') input = input.substr(1);

  input = decodeURIComponent(input);

  return qs.parse(input, {
    // Arrays will be formated like `?array=1,2,3`
    comma: true,
    decoder: (str, _decoder, charset) => {
      // Thanks to https://github.com/ljharb/qs/issues/91#issuecomment-864680091
      const strWithoutPlus = str.replace(/\+/g, ' ');
      if (charset === 'iso-8859-1') {
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
      }

      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str);
      }

      const keywords = {
        true: true,
        false: false,
        null: null,
        undefined,
      };
      if (str in keywords) {
        return keywords[str];
      }

      try {
        return decodeURIComponent(strWithoutPlus);
      } catch (e) {
        return strWithoutPlus;
      }
    },
  }) as T;
};
