import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {actions, selectors} from 'Admin/AdminDashboard/store/adminHorseTransfer';
import {IAppState} from 'Common/store/IAppState';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {IHorseTransferAdmin} from 'Admin/AdminDashboard/models/Transfer/IHorseTransferAdmin';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {Cell, Table} from 'Common/components/Table/Table';
import Loading from 'Loading/components/Loading';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {Pagination} from 'Common/components/Controls';
import {AvatarCell} from '../shared';
import withDate from 'Common/helpers/withDate';
import HorseTransferActions from './HorseTransferActions';
import {useHorseTransferActions} from 'Admin/AdminDashboard/helpers/hooks/useHorseTransferActions/useHorseTransferActions';
import withHorseTransferActions, {
  IWithHorseTransferActionsProps,
  getWithHorseTransferActionsModules,
} from 'Admin/AdminDashboard/helpers/withHorseTransferActions';

const HorseTransferTable = styled.div`
  margin-top: 50px;
`;

type IConnected = ConnectedProps<typeof connector>;

type IProps = IConnected & IWithHorseTransferActionsProps;

function HorseTransfer(props: IProps) {
  const {getHorseTransfers, horseTransfers, horseTransfersLoading, pagination} = props;

  const {changeSorting, sorting, handlePageSelect, handleCountPerPage, searchBar, params} =
    useCommonAdminPageData<IHorseTransferAdmin>({
      getItems: getHorseTransfers,
    });

  const updateHorseTransfers = useCallback(() => {
    getHorseTransfers(params);
  }, [getHorseTransfers, params]);

  const {openAcceptTransferModal, horseActionsModal, openRejectHorseTransferModal, openHorseTransferDetailsModal} =
    useHorseTransferActions({
      onSuccess: updateHorseTransfers,
    });

  const isLoading = horseTransfersLoading.isRequesting;
  const isShowPagination = horseTransfers.length > 0;

  return (
    <AdminPageLayout>
      {horseActionsModal}
      <div>{searchBar}</div>
      <HorseTransferTable className="position-relative">
        {isLoading && <Loading />}
        <Table<IHorseTransferAdmin> data={horseTransfers} rowKey="id" sorting={sorting} onChangeSorting={changeSorting}>
          <Cell<IHorseTransferAdmin>
            header="ID"
            dataKey="id"
            render={({id}) => `#${id}`}
            expandOnClick={true}
            width={100}
          />
          <Cell<IHorseTransferAdmin>
            header="Date"
            dataKey="createDate"
            render={({createDate}) => withDate(createDate)}
            width="8%"
          />
          <Cell<IHorseTransferAdmin>
            header="Horse"
            dataKey="horse"
            render={({horse: {name, id, avatar}}) => (
              <AvatarCell type="horse" label={name} avatarUrl={avatar?.url} profileUrl={`/admin/horse/${id}`} />
            )}
            sortable={false}
            width="16%"
          />
          <Cell<IHorseTransferAdmin>
            header="Owner"
            dataKey="owner"
            render={({owner}) => (
              <AvatarCell
                type="owner"
                label={owner.name}
                avatarUrl={owner.avatar?.url}
                profileUrl={`/admin/user/user-profile/${owner.id}`}
              />
            )}
            sortable={false}
            width="16%"
          />
          <Cell<IHorseTransferAdmin> header="Status" dataKey="status" render={({status}) => status} width="7%" />
          <Cell<IHorseTransferAdmin>
            header="Client name"
            dataKey="providedName"
            render={({providedName}) => providedName}
            width="16%"
          />
          <Cell<IHorseTransferAdmin>
            header="Client email"
            dataKey="providedEmail"
            render={({providedEmail}) => providedEmail}
            width="15%"
          />
          <Cell<IHorseTransferAdmin>
            header="Client Phone"
            dataKey="providedPhone"
            render={({providedPhone}) => providedPhone}
            width="12%"
          />

          <Cell<IHorseTransferAdmin>
            header="Actions"
            align={CellAlign.Right}
            render={(horseTransfer) => (
              <HorseTransferActions
                horseTransfer={horseTransfer}
                changeOwnerHandler={openAcceptTransferModal}
                rejectHorseTransferHandler={openRejectHorseTransferModal}
                showHorseTransferDetailsHandler={openHorseTransferDetailsModal}
              />
            )}
            width={100}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </HorseTransferTable>
    </AdminPageLayout>
  );
}

const mapStateToProps = (state: IAppState) => ({
  pagination: selectors.selectPagination(state),
  horseTransfers: selectors.selectHorseTransfers(state),
  horseTransfersLoading: selectors.selectCommunication(state, 'horseTransfersLoading'),
});

const mapDispatchToProps = {
  getHorseTransfers: actions.getHorseTransfers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
// export default withDynamicModules(connector(HorseTransfer), AdminHorseTransferModule);
export default withDynamicModules(
  connector(withHorseTransferActions(HorseTransfer)),
  getWithHorseTransferActionsModules()
);
