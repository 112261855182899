import * as React from 'react';
import {Redirect, Switch, useLocation} from 'react-router-dom';

import AppRoute from './AppRoute/AppRoute';
import {BUSINESS_PORTAL_NAVIGATION_ROUTES} from 'Common/components/Navigation';
import {ILocationState} from 'Common/models/ILocationState';

function AppAuthRoutingAssociationEmployee() {
  const location = useLocation<ILocationState>();
  const {from} = location.state || {from: {pathname: '/business-portal'}};

  return (
    <Switch>
      {BUSINESS_PORTAL_NAVIGATION_ROUTES.map((route, i) => (
        <AppRoute key={i} path={route.value} component={route.Component} {...route} />
      ))}
      <Redirect to={from} />
    </Switch>
  );
}

export default AppAuthRoutingAssociationEmployee;
