import {Reducer} from 'redux';

import {IHorseTransferState, Actions, getHorseTransferDetailsActionTypes} from '../types';

const initialState: IHorseTransferState['data'] = {
  horseTransferDetails: null,
};

export const dataHorseTransferReducer: Reducer<IHorseTransferState['data'], Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case getHorseTransferDetailsActionTypes.SUCCESS: {
      return {...state, horseTransferDetails: action.payload};
    }

    default: {
      return state;
    }
  }
};
