import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IRequestParams} from 'Common/models/IRequestParams';
import {ICreateHorseBP, IHorseBP} from 'BusinessPortal/models/horse/IHorseBP';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IHorseDetailsBP, IUpdateHorseDetailsBP} from 'BusinessPortal/models/horse/IHorseDetailsBP';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {IHorseOwnerBP} from 'BusinessPortal/models/horse/IHorseOwnerBP';
import {IHorseParentageBP, IUpdateHorseParentageBP} from 'BusinessPortal/models/horse/IHorseParentageBP';
import {IInconclusiveHealthIssue} from 'Dictionaries/models/InconclusiveHealthIssues';
import {IRegistry} from 'Horse/models/IRegistry';
import {IUpdateHorseRegistriesBP} from 'BusinessPortal/models/horse/IHorseRegistriesBP';
import {IUpdateHorseInconclusiveHealthIssuesBP} from 'BusinessPortal/models/horse/IInconclusiveHealthIssuesBP';
import {imagesGalleryRequestConverter, videoGalleryRequestConverter} from 'Gallery/services/converters/gallery';
import {IVideo} from 'Video/models/IVideo';
import {IMediaResource} from 'Common/models/IMediaResource';
import {IHorseProfileTypeRequest} from 'BusinessPortal/store/horse';
import {ProfileType} from 'Common/constants/ProfileType';
import {withImageBaseUrl} from 'Common/helpers/withImageBaseUrl';
import {withAvatar} from 'Common/helpers/withAvatar';
import {convertHorsesToClient} from './converters/horse';
import {IServerHorseBP} from './types/horse';

const getHorses = async (params?: IRequestParams, isAdmin?: boolean): Promise<IListResponse<IHorseBP>> => {
  const response = await axiosWrapper.get<IListResponse<IServerHorseBP>>(
    isAdmin ? '/AssociationHorseAdmin' : '/AssociationHorses',
    {params}
  );
  return {...response.data, data: response.data.data.map(convertHorsesToClient)};
};

const createHorse = async (data: ICreateHorseBP, isAdmin?: boolean): Promise<number> => {
  const response = await axiosWrapper.post<IObjectResponse<number>>(
    isAdmin ? '/AssociationHorseAdminCreate' : '/AssociationHorseCreate',
    data
  );
  return response.data.data;
};

const getHorseDetails = async (horseId: number, isAdmin?: boolean): Promise<IHorseDetailsBP> => {
  const response = await axiosWrapper.get<IObjectResponse<IHorseDetailsBP>>(
    (isAdmin ? '/AssociationHorseDetailsAdmin' : `/AssociationHorseDetails`) + `/${horseId}`
  );
  return withAvatar(withBirthDate(response.data.data));
};

const getHorseOwner = async (horseId: number, isAdmin?: boolean): Promise<IHorseOwnerBP> => {
  const response = await axiosWrapper.get<IObjectResponse<IHorseOwnerBP>>(
    (isAdmin ? '/AssociationHorseOwnerByHorseIdAdmin' : `/AssociationHorseOwnerByHorseId`) + `/${horseId}`
  );
  return withAvatar(response.data.data);
};

const getHorseParentage = async (horseId: number, isAdmin?: boolean): Promise<IHorseParentageBP[]> => {
  const response = await axiosWrapper.get<IListResponse<IHorseParentageBP>>(
    (isAdmin ? '/AssociationHorseParentageAdmin' : `/AssociationHorseParentage`) + `/${horseId}`
  );
  return response.data.data;
};

const getHorseRegistries = async (horseId: number, isAdmin?: boolean): Promise<IRegistry[]> => {
  const response = await axiosWrapper.get<IListResponse<IRegistry>>(
    (isAdmin ? '/AssociationHorseRegistriesAdmin' : `/AssociationHorseRegistries`) + `/${horseId}`
  );
  return response.data.data;
};

const getHorseHealthIssues = async (horseId: number, isAdmin?: boolean): Promise<IInconclusiveHealthIssue[]> => {
  const response = await axiosWrapper.get<IListResponse<IInconclusiveHealthIssue>>(
    (isAdmin ? '/AssociationHorseInconclusiveHealthIssuesAdmin' : `/AssociationHorseInconclusiveHealthIssues`) +
      `/${horseId}`
  );
  return response.data.data;
};

const updateHorseDetails = async (data: IUpdateHorseDetailsBP, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(
    isAdmin ? '/AssociationHorseAdminUpdate' : `/AssociationHorseUpdate`,
    data
  );
};

const setHorseOwner = async (horseId: number, horseOwnerId: number, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(
    isAdmin ? '/AssociationHorseHorseOwnerAdminUpdate' : `/AssociationHorseHorseOwnerUpdate`,
    {horseId, horseOwnerId}
  );
};

const updateHorseParentage = async (data: IUpdateHorseParentageBP, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(
    isAdmin ? '/AssociationHorseParentageAdminUpdate' : `/AssociationHorseParentageUpdate`,
    data
  );
};

const updateHorseRegistries = async (data: IUpdateHorseRegistriesBP, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(
    isAdmin ? '/AssociationHorseRegistriesAdminUpdate' : `/AssociationHorseRegistriesUpdate`,
    data
  );
};

const updateHorseHealthIssues = async (
  data: IUpdateHorseInconclusiveHealthIssuesBP,
  isAdmin?: boolean
): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(
    isAdmin
      ? '/AssociationHorseInconclusiveHealthIssuesAdminUpdate'
      : `/AssociationHorseInconclusiveHealthIssuesUpdate`,
    data
  );
};

const updateHorseAvatar = async (horseId: number, uploadedFile: File, isAdmin?: boolean): Promise<void> => {
  const formData = new FormData();
  formData.append('file', uploadedFile);
  formData.append('horseId', `${horseId}`);

  await axiosWrapper.post<IObjectResponse<null>>(
    isAdmin ? '/AssociationHorseAvatarUpdateAdmin' : `/AssociationHorseAvatarUpdate`,
    formData
  );
};

const deleteHorse = async (horseId: number, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(
    (isAdmin ? '/AssociationHorseDeleteAdmin' : `/AssociationHorseDelete`) + `/${horseId}`
  );
};

const deleteHorseAvatar = async (horseId: number, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(
    (isAdmin ? '/AssociationHorseAvatarDeleteAdmin' : `/AssociationHorseAvatarDelete`) + `/${horseId}`
  );
};

const getHorseMedia = async (horseId: number, isAdmin?: boolean): Promise<IMediaResource[]> => {
  const response = await axiosWrapper.get<IObjectResponse<IMediaResource[]>>(
    (isAdmin ? '/AssociationHorseMediaAdmin' : `/AssociationHorseMedia`) + `/${horseId}`
  );
  return response.data.data.map(withImageBaseUrl);
};

const createHorseImageMedia = async (horseId: number, uploadedFile: File[], isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(
    isAdmin ? '/AssociationHorseImagesCreateAdmin' : `/AssociationHorseMediaImages`,
    imagesGalleryRequestConverter(horseId, uploadedFile)
  );
};

const createHorseVideoMedia = async (horseId: number, video: IVideo, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(
    isAdmin ? '/AssociationHorseVideosUploadAdmin' : `/AssociationHorseVideosUpload`,
    videoGalleryRequestConverter(horseId, video)
  );
};

const deleteHorseMedia = async (horseId: number, mediaIds: number[], isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(
    isAdmin ? '/AssociationHorseMediaDeleteAdmin' : `/AssociationHorseMediaDelete`,
    {
      data: {horseId, mediaIds},
    }
  );
};

const updateHorseProfileType = async (request: IHorseProfileTypeRequest): Promise<ProfileType> => {
  const response = await axiosWrapper.patch<IObjectResponse<ProfileType>>('/HorseProfileTypesAssociation', {
    horseId: request.horseId,
    profileType: request.profileType,
  });

  return response.data.data;
};

const updateHorseAncestryProfileType = async (request: IHorseProfileTypeRequest): Promise<ProfileType> => {
  const response = await axiosWrapper.patch<IObjectResponse<ProfileType>>(`/HorseAncestryProfileTypesAssociation`, {
    horseId: request.horseId,
    profileType: request.profileType,
  });

  return response.data.data;
};

export default {
  getHorses,
  createHorse,
  setHorseOwner,
  getHorseDetails,
  getHorseOwner,
  getHorseParentage,
  getHorseRegistries,
  getHorseHealthIssues,
  updateHorseDetails,
  updateHorseParentage,
  updateHorseRegistries,
  updateHorseHealthIssues,
  updateHorseAvatar,
  deleteHorse,
  deleteHorseAvatar,
  getHorseMedia,
  createHorseImageMedia,
  createHorseVideoMedia,
  deleteHorseMedia,
  updateHorseProfileType,
  updateHorseAncestryProfileType,
};
