import React, {memo} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IconName} from 'Icon/components/Icon';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {IAppState} from 'Common/store/IAppState';
import withDynamicModules from 'Common/helpers/withDynamicModules';

import {Divider, ItemContainer, Title} from 'Admin/AdminAdvancedDashboard/components/Emergencies/common/styled';
import {actions, selectors} from 'Admin/AdminAdvancedDashboard/store/adminEmergencies/rulesBackup';
import {AdminRulesBackupModule} from 'Admin/AdminAdvancedDashboard/store/adminEmergencies/rulesBackup/adminRulesBackupModule';

const BackupWrapper = styled.div`
  margin: 0 40px;
`;
const Hint = styled.div`
  margin-top: 24px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 24px;
  color: ${Theme.color.black};
  text-align: center;
  letter-spacing: 0.2px;
`;

const DividerWrapper = styled(Divider)`
  margin: 30px 0 20px 0;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const RulesBackup = (props: AllProps) => {
  const {downloadRulesBackup, adminRulesBackupDownloading} = props;

  return (
    <BackupWrapper>
      <Title>Rules backup</Title>
      <ItemContainer className="d-flex flex-column justify-content-center align-items-center">
        <Hint>
          You can download the latest created backup of rules. It’s archive file which contains all the data for the
          moment
        </Hint>
        <DividerWrapper />
        <PrimaryButton
          className="w-100"
          size="small"
          icon={{name: IconName.ArrowDownload, color: Theme.color.white}}
          isLoading={adminRulesBackupDownloading.isRequesting}
          onClick={downloadRulesBackup}
        >
          Download
        </PrimaryButton>
      </ItemContainer>
    </BackupWrapper>
  );
};

const mapStateToProps = (state: IAppState) => ({
  adminRulesBackupDownloading: selectors.selectCommunication(state, 'adminRulesBackupDownloading'),
});

const mapDispatchToProps = {
  downloadRulesBackup: actions.downloadRulesBackup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(RulesBackup));

export default withDynamicModules(Connected, AdminRulesBackupModule);
