import React, {ReactNode} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1440px;
`;

interface IProps {
  children?: ReactNode;
  className?: string;
}

const ContainerWrapper = ({children, className}: IProps) => {
  return <Container className={classnames('container', 'flex-grow-1', className)}>{children}</Container>;
};

export default ContainerWrapper;
