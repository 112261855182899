import React, {CSSProperties, memo} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {Divider as DividerComoponent} from 'OnlineReport/shared/styles';
import {Direction} from 'Common/constants/Direction';
import {breakpoints} from 'Common/constants/Breakpoints';
import HintIcon from 'Common/components/HintIcont/HintIcon';

const Root = styled.div<{direction: Direction}>`
  padding: 0 8px;
  min-width: 180px;
  ${(props) => props.direction === Direction.Column && 'flex-direction: column;'};
`;

const ChildrenContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  min-width: 300px;

  @media print {
    justify-content: center;
  }
`;

const ColumnName = styled.div`
  width: 100%;
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size16};
  font-weight: ${Typography.weight.medium500};
  color: ${Theme.color.gray};
  text-align: center;
`;

const ColumnNameWrapper = styled.div`
  margin: 8px 0;
`;

const Divider = styled(DividerComoponent)`
  width: 50%;

  @media ${breakpoints.sm} {
    width: 100%;
  }
`;

interface IProps {
  columnName?: string;
  className?: string;
  childrenClassName?: string;
  style?: CSSProperties;
  childrenStyle?: CSSProperties;
  direction?: Direction;
  tooltip?: React.ReactChild | React.ReactChild[] | undefined;
}

function ColumnContainer(props: React.PropsWithChildren<IProps>) {
  const {
    children,
    columnName,
    className,
    childrenClassName,
    style,
    childrenStyle,
    direction = Direction.Column,
    tooltip,
  } = props;

  return (
    <Root className={classNames('d-flex align-self-stretch', className)} style={style} direction={direction}>
      <ColumnNameWrapper className="d-flex align-items-center justify-content-center w-100">
        {columnName && direction === Direction.Column && <Divider />}
        <ColumnName className="d-flex align-items-center justify-content-center">
          {columnName}
          {tooltip && <HintIcon tooltip={tooltip} />}
        </ColumnName>
        {columnName && direction === Direction.Column && <Divider />}
      </ColumnNameWrapper>

      <ChildrenContainer
        className={classNames('flex-grow-1 align-self-center d-flex flex-wrap flex-row', childrenClassName)}
        style={childrenStyle}
      >
        {children}
      </ChildrenContainer>
    </Root>
  );
}

export default memo(ColumnContainer);
