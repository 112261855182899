import {action} from 'typesafe-actions';

import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {
  getAncestryHorsePrivacyActions,
  getAncestryHorsePrivacyActionTypes,
  getAncestryKinshipHorsesActions,
  getAncestryKinshipHorsesActionTypes,
  getAncestryProbabilitiesActions,
  getAncestryProbabilitiesActionTypes,
  getAncestryRelatedHorsesActions,
  getAncestryRelatedHorsesActionTypes,
  getPopulationActions,
  getPopulationActionTypes,
  getPopulationComparisonActions,
  getPopulationComparisonActionTypes,
  sendHorsePublicityRequestActions,
  sendHorsePublicityRequestActionTypes,
} from './types';
import AncestryReportService from 'OnlineReport/services/AncestryReportService';

export const getPopulation =
  (horseId: number): ActionResult<IAppState, void, getPopulationActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getPopulationActionTypes.REQUEST));
      const res = await AncestryReportService.getPopulation(horseId);
      dispatch(action(getPopulationActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getPopulationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getPopulationComparison =
  (horseId: number): ActionResult<IAppState, void, getPopulationComparisonActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getPopulationComparisonActionTypes.REQUEST));
      const res = await AncestryReportService.getPopulationComparison(horseId);
      dispatch(action(getPopulationComparisonActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getPopulationComparisonActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAncestryProbabilities =
  (horseId: number): ActionResult<IAppState, void, getAncestryProbabilitiesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAncestryProbabilitiesActionTypes.REQUEST));
      const res = await AncestryReportService.getAncestryProbabilities(horseId);
      dispatch(action(getAncestryProbabilitiesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAncestryProbabilitiesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAncestryRelatedHorses =
  (horseId: number): ActionResult<IAppState, void, getAncestryRelatedHorsesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAncestryRelatedHorsesActionTypes.REQUEST));
      const res = await AncestryReportService.getAncestryRelatedHorses(horseId);
      dispatch(action(getAncestryRelatedHorsesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAncestryRelatedHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAncestryKinshipHorses =
  (horseId: number): ActionResult<IAppState, void, getAncestryKinshipHorsesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAncestryKinshipHorsesActionTypes.REQUEST));
      const res = await AncestryReportService.getAncestryKinshipHorses(horseId);
      dispatch(action(getAncestryKinshipHorsesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAncestryKinshipHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAncestryHorsePrivacy =
  (privateId: string): ActionResult<IAppState, void, getAncestryHorsePrivacyActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAncestryHorsePrivacyActionTypes.REQUEST));
      const res = await AncestryReportService.getAncestryHorsePrivacy(privateId);
      dispatch(action(getAncestryHorsePrivacyActionTypes.SUCCESS, {privateId, ...res}));
    } catch (error) {
      dispatch(action(getAncestryHorsePrivacyActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const sendHorsePublicityRequest =
  (privateId: string, message: string): ActionResult<IAppState, void, sendHorsePublicityRequestActions> =>
  async (dispatch) => {
    try {
      dispatch(action(sendHorsePublicityRequestActionTypes.REQUEST));
      await AncestryReportService.sendHorsePublicityRequest(privateId, message);
      dispatch(action(sendHorsePublicityRequestActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(sendHorsePublicityRequestActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
