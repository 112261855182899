import {memo, useCallback, useEffect, useMemo} from 'react';

import {findStatusByOrderIdHorseId} from 'Admin/AdminDashboard/helpers/findStatusByOrderIdHorseId';
import withOrderDetailsActions, {
  IWithOrderDetailsActionsProps,
} from 'Admin/AdminDashboard/helpers/withOrderDetailsActions';
import {IOrderHorseStatuses} from 'Admin/AdminDashboard/models/Order/IOrderHorseStatuses';
import {OrderStatusDropdown} from 'Admin/shared/components/OrderStatus';
import {EventName} from 'Common/constants/EventName';
import {OrderStatus} from 'Common/constants/OrderStatus';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import Loading from 'Loading/components/Loading';

interface IOwnProps {
  orderId: number;
  horseId: number;
  onChange(): void;
  isClearStatuses?: boolean;
}

type Props = IWithOrderDetailsActionsProps & IOwnProps;

function OrderStatusDetails(props: Props) {
  const {
    getOrderStatusesDetails,
    orderId,
    horseId,
    orderStatusesDetails,
    orderStatusesDetailsLoading,
    onChange,
    resetOrderStatusDetails,
    isClearStatuses,
  } = props;

  const {emit} = useEventBus();

  const orderStatuses = findStatusByOrderIdHorseId(horseId, orderId, orderStatusesDetails);

  const getDetails = useCallback(() => {
    if (!!orderId && !!horseId) {
      getOrderStatusesDetails(orderId, horseId);
    }
  }, [getOrderStatusesDetails, horseId, orderId]);

  useEffect(() => {
    return () => {
      if (isClearStatuses) {
        resetOrderStatusDetails();
      }
    };
  }, [isClearStatuses, resetOrderStatusDetails]);

  useEffect(() => {
    if (!orderStatuses) {
      getDetails();
    }
  }, [getDetails, orderStatuses]);

  useEffect(() => {
    if (orderStatuses) {
      emit<IOrderHorseStatuses>(EventName.OrderStatusChanged, {
        orderId,
        horseId,
        statuses: orderStatuses.statuses,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatuses]);

  const isLoading = orderStatusesDetailsLoading.isRequesting;

  const unCompletedStatuses = useMemo(() => {
    const uncompleted = [];
    if (orderStatuses?.statuses.hasNotResults) {
      uncompleted.push(OrderStatus.resultsReady);
    }
    if (!orderStatuses?.statuses.hasSample) {
      uncompleted.push(OrderStatus.sampleReceived);
    }
    return uncompleted;
  }, [orderStatuses]);

  return (
    <div className="position-relative">
      {isLoading && <Loading />}
      {orderStatuses && (
        <OrderStatusDropdown
          status={orderStatuses.statuses.status}
          orderId={orderId}
          horseId={horseId}
          onChange={onChange}
          completedStatuses={orderStatuses.statuses.hasSample ? OrderStatus.sampleReceived : undefined}
          uncompletedStatuses={unCompletedStatuses}
        />
      )}
    </div>
  );
}

export default memo(withOrderDetailsActions(OrderStatusDetails));
