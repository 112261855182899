import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';

import {IChangeEmail} from 'Admin/AdminDashboard/services/types/user';

const STATE_NAME = 'adminChangeEmail';

export interface IAdminChangeEmailState {
  data: {
    message: string | null;
  };
  communications: {
    changeUserEmailUpdating: ICommunication<IChangeEmail>;
  };
}

export const changeEmailActionTypes = makeCommunicationActionType(STATE_NAME, 'CHANGE_EMAIL');

export type changeEmailActions = MakeCommunicationActions<typeof changeEmailActionTypes, {}>;
