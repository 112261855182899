import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';

import {InputField, SelectField, TextAreaField} from 'Common/components/FormFields/index';
import {IAppState} from 'Common/store/IAppState';
import {FormType} from 'Common/constants/FormType';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {RequiredBy} from 'Common/types';

import DictionaryForm from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/components/DictionaryForm';
import {makePhenotypeFormSelectors} from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/shared/helpers/store/makePhenotypeFormSelectors';

import {castToOption} from 'Common/helpers/OptionHelper';
import {IGene} from 'Dictionaries/models/IGene';
import {ITest} from 'Dictionaries/models/ITest';
import {convertToServer, convertToServerUpdating, IFormValues, initialValue, validationSchema} from './validation';
import {IPhenotypeFormExternalProps} from 'Admin/AdminPhenotypes/models/common/IPhenotypeFormExternalProps';

interface IOwnProps extends IPhenotypeFormExternalProps<ITest, ITest> {
  geneDictionary: ISimpleDictionary<IGene>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IOwnProps;

type Props = FormikProps<IFormValues> & OuterProps;

function TestForm(props: Props) {
  const {itemId, genes, genesLoading, dictionary, geneDictionary, ...rest} = props;
  const {getItem} = dictionary.actions;
  const {
    actions: {getItems: getGenes},
  } = geneDictionary;

  useEffect(() => {
    getGenes();
    if (itemId) {
      getItem(itemId);
    }
  }, [itemId, getItem, getGenes]);

  return (
    <DictionaryForm {...rest} itemPreloading={genesLoading.isRequesting}>
      <InputField isRequired={true} name="name" type="text" label="Name" placeholder="Name" className="w-50" />
      <InputField name="title" label="Title" placeholder="Title" />
      <SelectField name="gene" label="Gene" options={castToOption(genes)} />
      <InputField
        name="mutationAllele"
        type="text"
        label="Mutation Variant"
        placeholder="Mutation Variant"
        className="w-50"
      />
      <InputField
        name="referenceAllele"
        type="text"
        label="Reference Variant"
        placeholder="Reference Variant"
        className="w-50"
      />
      <TextAreaField
        name="description.value"
        label="Description"
        placeholder="Add description to notice some facts about test"
      />
    </DictionaryForm>
  );
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
  const {geneDictionary} = ownProps;

  return {
    ...makePhenotypeFormSelectors(state, ownProps.dictionary),
    genes: geneDictionary.selectors.selectItems(state),
    genesLoading: geneDictionary.selectors.selectCommunication(state, 'itemsLoading'),
  };
};

const TestFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({item, itemLoading, itemId}) =>
    itemId && item && !itemLoading.isRequesting ? {...item, gene: item?.gene?.id || null} : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, dictionary} = formikBag.props;
    formikBag.setSubmitting(true);

    const {createItem, updateItem} = dictionary.actions;

    if (type === FormType.create) {
      await createItem(convertToServer(values));
    }

    if (type === FormType.edit) {
      await updateItem(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }

    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(TestForm);

const connector = connect(mapStateToProps);
export default connect(mapStateToProps)(memo(TestFormFormik));
