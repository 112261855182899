import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import {IFilterPropertyOperations} from 'FacetFilter/models/IFilterPropertyOperator';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {DropDownMenu, IOrderedMenuItem} from 'Common/components/DropDown';
import ColorPalette from 'Common/constants/ColorPalette';

const OperationPanel = styled.div`
  padding: 4px 8px;
  border: 1px solid ${ColorPalette.gray18};
`;

interface IProps {
  filterOperations: IFilterPropertyOperations;
  defaultOperation?: string;
  onSelect(operation: string): void;
}

export function OperationDropDown(props: IProps) {
  const {filterOperations, defaultOperation, onSelect} = props;
  const {operations} = filterOperations;

  const [selectedOperator, setSelectedOperator] = useState(defaultOperation || operations[0].operation);

  useEffect(() => {
    onSelect(selectedOperator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperator]);

  const menuItems = useMemo(() => {
    const items: IOrderedMenuItem[] = operations.map((x, ind) => ({
      id: ind,
      value: x.operation,
      label: x.designation,
    }));

    const shownMenuItems = items.sort((a, b) => sortByStringKey(a, b, 'id'));

    return shownMenuItems;
  }, [operations]);

  const onSelectHandler = useCallback((operation: string) => {
    setSelectedOperator(operation);
  }, []);

  const getOperatorName = useCallback(
    (operation: string) => {
      return operations.find((x) => x.operation === operation)?.designation;
    },
    [operations]
  );

  return (
    <DropDownMenu items={menuItems} onSelect={onSelectHandler}>
      {() => <OperationPanel className="d-flex align-items-center">{getOperatorName(selectedOperator)}</OperationPanel>}
    </DropDownMenu>
  );
}
