import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';

import LocalStorageService from 'Common/services/LocalStorageService';
import {Token} from 'Common/constants/Token';
import {getEnvParams} from 'Common/helpers/getEnvParams';

const axiosRequestConfig: AxiosRequestConfig = {
  baseURL: `${getEnvParams().baseUrl}/api`,
  responseType: 'json',
  headers: {
    Authorization: `Bearer ${LocalStorageService.getItem(Token.AccessToken)}`,
    'Content-Type': 'application/json;charset=UTF-8'
  }
};

export const axiosWrapper: AxiosInstance = axios.create(axiosRequestConfig);
