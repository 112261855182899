import React, {memo, useCallback} from 'react';
import {ErrorMessage} from 'formik';
import styled from 'styled-components';
import Typography from 'Common/constants/Typography';
import {breakpoints} from 'Common/constants/Breakpoints';

// this margin is needed because almost all form components have FieldControlContainer, which have own margin-bottom
const MARGIN_TOP = -10;
const MARGIN_BOTTOM = 7;

const ErrorLayout = styled.div`
  font-size: ${Typography.size.size10};

  @media ${breakpoints.sm} {
    margin-left: 10px;
  }
`;

interface IProps {
  name: string;
  withoutLifting?: boolean;
}

function ValidationError(props: IProps) {
  const {name, withoutLifting} = props;

  const renderMessage = useCallback(
    (errorMessage: string) => {
      return (
        <div className="text-danger" style={{marginTop: withoutLifting ? 0 : MARGIN_TOP, marginBottom: MARGIN_BOTTOM}}>
          {errorMessage}
        </div>
      );
    },
    [withoutLifting]
  );
  return (
    <ErrorLayout>
      <ErrorMessage name={name} render={renderMessage} />
    </ErrorLayout>
  );
}

export default memo(ValidationError);
