export interface IOrderStatusUpdateDateField {
  ancestryResultsStatusUpdateDate: string;
  diagnosticResultsStatusUpdateDate: string;
  releaseStatusUpdateDate: string;
  reviewStatusUpdateDate: string;
  relativesResultsStatusUpdateDate: string;
  parentageResultsStatusUpdateDate: string;
}

export enum OrderStatusUpdateDate {
  AncestryResultsStatusUpdateDate = 'ancestryResultsStatusUpdateDate',
  DiagnosticResultsStatusUpdateDate = 'diagnosticResultsStatusUpdateDate',
  ReleaseStatusUpdateDate = 'releaseStatusUpdateDate',
  ReviewStatusUpdateDate = 'reviewStatusUpdateDate',
  RelativesResultsStatusUpdateDate = 'relativesResultsStatusUpdateDate',
  ParentageResultsStatusUpdateDate = 'parentageResultsStatusUpdateDate',
}

export const statusUpdateDateLabelByType: Record<OrderStatusUpdateDate, string> = {
  [OrderStatusUpdateDate.AncestryResultsStatusUpdateDate]: 'Ancestry',
  [OrderStatusUpdateDate.DiagnosticResultsStatusUpdateDate]: 'Diagnostic',
  [OrderStatusUpdateDate.ReleaseStatusUpdateDate]: '',
  [OrderStatusUpdateDate.ReviewStatusUpdateDate]: '',
  [OrderStatusUpdateDate.RelativesResultsStatusUpdateDate]: 'Relatives',
  [OrderStatusUpdateDate.ParentageResultsStatusUpdateDate]: 'Parentage',
};
