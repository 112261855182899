import {IUser} from 'UserProfile/models/IUser';
import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';

const STATE_NAME = 'user';

export interface IUserState {
  data: {
    user: IUser | null;
  };
  communications: {
    userUpdating: ICommunication;
    userLoading: ICommunication;
  };
}

export const getUserActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER');

export type getUserActions = MakeCommunicationActions<typeof getUserActionTypes, {success: IUser}>;

export type UserActions = getUserActions;
