import * as Yup from 'yup';

export interface IFormValues {
  transferId: number;
  comment: string;
  reason: string;
}

export const initialValue: IFormValues = {
  transferId: 0,
  comment: '',
  reason: '',
};

export const validationSchema = Yup.object().shape<IFormValues>({
  transferId: Yup.number(),
  comment: Yup.string(),
  reason: Yup.string(),
});
