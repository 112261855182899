import {IModule} from 'redux-dynamic-modules';

import {IAdminPackagesState} from './types';
import {adminPackagesReducer} from './reducer';

export interface IAdminPackagesModuleState {
  adminPackages: IAdminPackagesState;
}

export const AdminPackagesModule: IModule<IAdminPackagesModuleState> = {
  id: 'adminPackages',
  reducerMap: {
    adminPackages: adminPackagesReducer
  }
};
