import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import {Direction} from 'Common/constants/Direction';
import {IFieldProps} from 'Common/models/IFieldProps';

import {controlLayout, errorLayout} from '../Controls/styles';
import withField from './withField';

const ErrorLayout = styled.div`
  ${errorLayout}
  bottom: -10px;
`;

const ComponentLayout = styled.div`
  position: relative;
  ${controlLayout}
`;

interface IProps {
  className?: string;
  direction?: Direction;
}

type Props = IProps & IFieldProps;

function RadiobuttonGroup(props: React.PropsWithChildren<Props>) {
  const {
    form: {errors},
    field: {name}
  } = props;
  const hasError = errors[name];

  const classDirection = classnames('d-flex', props.direction === Direction.Row ? 'flex-row' : 'flex-column');

  return (
    <ComponentLayout className={props.className}>
      <div className={classDirection}>{props.children}</div>
      {hasError && (
        <ErrorLayout>
          <div className="text-danger">{errors[name]}</div>
        </ErrorLayout>
      )}
    </ComponentLayout>
  );
}

export default withField(RadiobuttonGroup);
