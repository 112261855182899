import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IServerPurchasableTest} from './types/purchasableTest';
import {
  IAdminPurchasableTest,
  IAdminPurchasableTestRequest,
} from 'Admin/AdminAssociations/models/IAdminPurchasableTest';
import {convertPurchasableTestToClient, convertPurchasableTestToRequest} from './converters/purchasableTest';
import {convertStatus} from './converters/organization';

const getPurchasableTests = async (params?: IRequestParams): Promise<IListResponse<IAdminPurchasableTest>> => {
  const response = await axiosWrapper.get<IListResponse<IServerPurchasableTest>>(`/PurchasableTestAdmin`, {params});
  return {
    ...response.data,
    data: response.data.data.map(convertPurchasableTestToClient),
  };
};

const getPurchasableTest = async (id: number): Promise<IAdminPurchasableTest> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerPurchasableTest>>(
    `/PurchasableTestAdminDetails/${id}`
  );
  return convertPurchasableTestToClient(response.data.data);
};

const createPurchasableTest = async (value: IAdminPurchasableTestRequest): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/PurchasableTestAdminCreate`, convertPurchasableTestToRequest(value));
};

const updatePurchasableTest = async (value: IAdminPurchasableTestRequest): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(`/PurchasableTestAdminUpdate`, convertPurchasableTestToRequest(value));
};

const changePurchasableTestStatus = async (id: number, isEnabled: boolean): Promise<void> => {
  await axiosWrapper.patch<IObjectResponse<IAdminPurchasableTest>>(`/PurchasableTestSetStatusAdmin`, {
    id,
    status: convertStatus(isEnabled),
  });
};

export default {
  getPurchasableTests,
  getPurchasableTest,
  createPurchasableTest,
  updatePurchasableTest,
  changePurchasableTestStatus,
};
