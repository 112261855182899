import {AbilityState} from 'Common/constants/AbilityState';

export function convertAbilityToServer(clientAbility: number | undefined): AbilityState | undefined {
  switch (clientAbility) {
    case 1:
      return AbilityState.Low;
    case 2:
      return AbilityState.Medium;
    case 3:
      return AbilityState.High;
  }

  return undefined;
}

export function convertAbilityToClient(serverAbilityValue: AbilityState): number {
  switch (serverAbilityValue) {
    case AbilityState.Low:
      return 0;
    case AbilityState.Medium:
      return 1;
    case AbilityState.High:
      return 2;
    default:
      return 0;
  }
}
