import React from 'react';
import styled from 'styled-components';
import {withSize, SizeMeProps} from 'react-sizeme';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {BorderedBlock, Label} from 'HorseProfile/components/shared/StyledComponents';
import {IProps as IconProps} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';

const FONT_SIZE_RATIO: number = 14;
const ICON_MARGIN: number = 60;

const Wrapper = styled.div<{isHalfWidth: boolean}>`
  width: ${({isHalfWidth}) => (isHalfWidth ? 'calc(50% - 10px)' : '100%')};
  margin-top: 24px;
`;

const ContentValue = styled(BorderedBlock)<{needSmallFont: boolean}>`
  display: flex;
  height: 88px;
  width: 100%;
  overflow: hidden;
  font-size: ${Typography.size.size24};
  font-size: ${({needSmallFont}) => (needSmallFont ? `${Typography.size.size16}` : `${Typography.size.size24}`)};
  font-weight: ${Typography.weight.medium500};
  color: ${ColorPalette.black1};
  text-align: center;
`;

const Icon = styled(ColoredIcon)`
  margin-right: ${ICON_MARGIN}px;
`;

interface IProps {
  label: string;
  value: string;
  isHalfWidth: boolean;
  icon?: IconProps;
}

type AllProps = IProps & SizeMeProps;

const HorseValueTile = (props: AllProps) => {
  let tileWidth = props.size.width;
  const {icon} = props;
  if (icon && tileWidth) {
    tileWidth = tileWidth - ICON_MARGIN - (icon.size ? icon.size : 0);
  }

  let needSmallFont = false;
  if (tileWidth && tileWidth / props.value.length < FONT_SIZE_RATIO) {
    needSmallFont = true;
  }

  return (
    <Wrapper isHalfWidth={props.isHalfWidth}>
      <Label>{props.label}</Label>
      <ContentValue needSmallFont={needSmallFont} className="d-flex align-items-center justify-content-center">
        {icon && (
          <Icon
            name={icon?.name}
            color={ColorPalette.black1}
            hoverColor={ColorPalette.black1}
            fill={true}
            stroke={false}
            size={icon.size}
          />
        )}
        <div>{props.value}</div>
      </ContentValue>
    </Wrapper>
  );
};

export default withSize()(HorseValueTile);
