import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import MailConfirmationService from 'MailConfirmation/services/MailConfirmationService';

import {Action, IRequestNewMailConfirmationState, RequestNewMailConfirmationActionTypes} from './types';

export const requestNewMailConfirmation = (
  email: string
): ActionResult<IRequestNewMailConfirmationState, void, Action> => async dispatch => {
  try {
    dispatch(action(RequestNewMailConfirmationActionTypes.REQUEST));
    await MailConfirmationService.requestNewMailConfirmation(email);
    dispatch(action(RequestNewMailConfirmationActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(RequestNewMailConfirmationActionTypes.FAILURE, getErrorMessage(error)));
  }
};
