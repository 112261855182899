import React from 'react';

import MailNotConfirmed from 'MailConfirmation/components/MailNotConfirmed/MailNotConfirmed';
import MailConfirmation from 'MailConfirmation/components/MailConfirmation';
import AppRoute from 'Common/components/AppRoute/AppRoute';

export default [
  <AppRoute key="/confirm" path="/confirm" component={MailConfirmation} />,
  <AppRoute
    key="/email-not-confirmed"
    path="/email-not-confirmed/:email"
    render={routeProps => <MailNotConfirmed type="notConfirmed" {...routeProps} />}
  />,
  <AppRoute
    key="/email-link-expired"
    path="/email-link-expired/:email"
    render={routeProps => <MailNotConfirmed type="expiredLink" {...routeProps} />}
  />
];
