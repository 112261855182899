import {IAppState} from 'Common/store/IAppState';
import {ICommunication} from 'Common/store/utils/communication';
import {
  actions as adminActions,
  selectors as adminSelectors,
  TestReportModule,
} from 'Admin/AdminDashboard/store/common/testReport/index';
import {
  actions as adminAssociationsActions,
  selectors as adminAssociationsSelectors,
} from 'Admin/AdminAssociations/store/adminOrders';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import {IUploadTestReportRequest} from 'Admin/shared/models/IUploadTestReportRequest';

export interface IState {
  horseTestResultUploading: ICommunication;
}

export interface IDispatch {
  uploadReport(data: IUploadTestReportRequest): void;
}

const adminDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    horseTestResultUploading: adminSelectors.selectCommunication(state, 'reportUploading'),
  }),
  dispatch: {
    uploadReport: adminActions.uploadReport,
  },
  modules: [TestReportModule],
};

const adminAssociationDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    horseTestResultUploading: adminAssociationsSelectors.selectCommunication(state, 'reportUploading'),
  }),
  dispatch: {
    uploadReport: adminAssociationsActions.uploadReport,
  },
};

export const uploadReportFormDistributor: Record<SelectedStrategy, IDistributor<IState, IDispatch>> = {
  [SelectedStrategy.Admin]: adminDistributor,
  [SelectedStrategy.AdminAssociation]: adminAssociationDistributor,
};
