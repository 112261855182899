import {combineReducers} from 'redux';

import composeReducers from 'Common/store/composeReducers';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication/index';
import {
  AdminOrdersActions,
  changeSampleActionTypes,
  chooseAnotherSampleActionTypes,
  deleteOrderActionTypes,
  downloadReportsActionTypes,
  downloadSubmissionFormActionTypes,
  getHorseSamplesActionTypes,
  getOrderDetailsActionTypes,
  getOrdersActionTypes,
  getOrderStatusesDetailsActionTypes,
  IAdminOrdersState,
  releaseOrderHorseActionTypes,
  requestNewSampleActionTypes,
  resetChangeSampleCommunicationActionTypes,
  resetChooseAnotherSampleCommunicationActionTypes,
  resetOrderDetailsActionTypes,
  setSampleStatusActionTypes,
} from '../types';

export const communicationOrdersReducer = combineReducers<IAdminOrdersState['communications'], AdminOrdersActions>({
  ordersLoading: makeCommunicationReducerFromEnum(getOrdersActionTypes),
  horseSamplesLoading: makeCommunicationReducerFromEnum(getHorseSamplesActionTypes),
  orderDetailsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getOrderDetailsActionTypes),
    makeResetCommunicationReducer(resetOrderDetailsActionTypes.RESET),
  ]),
  orderHorseReleaseRequesting: makeCommunicationReducerFromEnum(releaseOrderHorseActionTypes),
  orderDeleting: makeCommunicationReducerFromEnum(deleteOrderActionTypes),
  newSampleRequesting: makeCommunicationReducerFromEnum(requestNewSampleActionTypes),
  setSampleStatusRequesting: makeCommunicationReducerFromEnum(setSampleStatusActionTypes),
  downloadReportsRequesting: makeCommunicationReducerFromEnum(downloadReportsActionTypes),
  downloadSubmissionFormRequesting: makeCommunicationReducerFromEnum(downloadSubmissionFormActionTypes),
  changeSampleRequesting: composeReducers([
    makeCommunicationReducerFromEnum(changeSampleActionTypes),
    makeResetCommunicationReducer(resetChangeSampleCommunicationActionTypes.RESET),
  ]),
  chooseAnotherSampleRequesting: composeReducers([
    makeCommunicationReducerFromEnum(chooseAnotherSampleActionTypes),
    makeResetCommunicationReducer(resetChooseAnotherSampleCommunicationActionTypes.RESET),
  ]),
  orderStatusesDetailsLoading: makeCommunicationReducerFromEnum(getOrderStatusesDetailsActionTypes),
});
