import {Reducer} from 'redux';

import {
  HtmlTemplatesActions,
  getHtmlTemplatesActionTypes,
  IAdminHtmlTemplateState,
  getHtmlTemplateActionTypes,
  resetTemplateActionTypes,
} from '../types';

const initialState: IAdminHtmlTemplateState['data'] = {
  templates: [],
  template: '',
};

export const dataHtmlTemplatesReducer: Reducer<IAdminHtmlTemplateState['data'], HtmlTemplatesActions> = (
  state = initialState,
  action: HtmlTemplatesActions
) => {
  switch (action.type) {
    case getHtmlTemplatesActionTypes.SUCCESS: {
      return {...state, templates: action.payload.data};
    }
    case getHtmlTemplateActionTypes.SUCCESS: {
      return {...state, template: action.payload};
    }
    case resetTemplateActionTypes.RESET: {
      return {...state, template: initialState.template};
    }
    default: {
      return state;
    }
  }
};
