import {Reducer} from 'redux';

import {
  IAdminPhenotypeGroupImageState,
  AdminPhenotypeGroupImageActions,
  getImageActionTypes
} from 'Admin/AdminPhenotypes/store/adminPhenotypeGroupImage/types';

const initialImageState: IAdminPhenotypeGroupImageState['data'] = {image: null};

export const dataPhenotypeGroupImageReducer: Reducer<
  IAdminPhenotypeGroupImageState['data'],
  AdminPhenotypeGroupImageActions
> = (state = initialImageState, action: AdminPhenotypeGroupImageActions) => {
  switch (action.type) {
    case getImageActionTypes.SUCCESS: {
      return {...state, image: action.payload};
    }
    default: {
      return state;
    }
  }
};
