import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';

import {
  getAdminNotificationsActionTypes,
  IAdminNotificationState,
  updateAdminNotificationsActionTypes,
  AdminNotificationsActions
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import NotificationService from 'Admin/AdminAdvancedDashboard/services/NotificationService';
import {IAdminNotificationRequest} from 'Admin/AdminAdvancedDashboard/services/types/notification';

export const getAdminNotifications = (
  adminId: number
): ActionResult<IAdminNotificationState, void, AdminNotificationsActions> => async dispatch => {
  dispatch(action(getAdminNotificationsActionTypes.REQUEST));
  try {
    const res = await NotificationService.getAdminNotifications(adminId);
    dispatch(action(getAdminNotificationsActionTypes.SUCCESS, res));
  } catch (error) {
    dispatch(action(getAdminNotificationsActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const updateAdminNotifications = (
  data: IAdminNotificationRequest
): ActionResult<IAdminNotificationState, void, AdminNotificationsActions> => async dispatch => {
  dispatch(action(updateAdminNotificationsActionTypes.REQUEST));
  try {
    await NotificationService.updateAdminNotifications(data);
    dispatch(action(updateAdminNotificationsActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(updateAdminNotificationsActionTypes.FAILURE, getErrorMessage(error)));
  }
};
