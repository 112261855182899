import {Reducer} from 'redux';

import {IAdminAssociationOrderState, AdminAssociationOrdersActions, getOrdersActionTypes} from '../types';

const initialState: IAdminAssociationOrderState['ui'] = {
  pagination: {countPerPage: 0, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiOrdersReducer: Reducer<IAdminAssociationOrderState['ui'], AdminAssociationOrdersActions> = (
  state = initialState,
  action: AdminAssociationOrdersActions
) => {
  switch (action.type) {
    case getOrdersActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
