import {action} from 'typesafe-actions';
import {ActionResult} from 'Common/store/store';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {
  cancelScheduledSubscriptionActions,
  cancelScheduledSubscriptionActionTypes,
  cancelSubscriptionActions,
  cancelSubscriptionActionTypes,
  changeDefaultPaymentMethodActions,
  changeDefaultPaymentMethodActionTypes,
  changeSubscriptionPlanActions,
  changeSubscriptionPlanActionTypes,
  createSubscriptionActions,
  createSubscriptionActionTypes,
  deleteSubscriptionActions,
  deleteSubscriptionActionTypes,
  ensureCustomerCreatedActions,
  ensureCustomerCreatedActionTypes,
  getDefaultPaymentMethodActions,
  getDefaultPaymentMethodActionTypes,
  getSetupIntentClientSecretActions,
  getSetupIntentClientSecretActionTypes,
  getUserScheduledSubscriptionsActions,
  getUserScheduledSubscriptionsActionTypes,
  getUserSubscriptionsActions,
  getUserSubscriptionsActionTypes,
  isCustomerExistActions,
  isCustomerExistActionTypes,
  IStripeSubscriptionState,
  refreshSubscriptionFromStripeActions,
  refreshSubscriptionFromStripeActionTypes,
  renewSubscriptionActions,
  renewSubscriptionActionTypes,
  retryInvoiceActions,
  retryInvoiceActionTypes
} from 'StripeSubscription/store/types';
import StripeSubscriptionService from 'StripeSubscription/service/StripeSubscriptionService';
import {ISubscriptionRequest, ISubscriptionResponse} from 'StripeSubscription/models/ISubscription';
import {IRetryInvoiceResponse} from 'StripeSubscription/models/IRetryInvoiceResponse';

export const createSubscription = (
  data: ISubscriptionRequest
): ActionResult<
  IStripeSubscriptionState,
  Promise<ISubscriptionResponse>,
  createSubscriptionActions
> => async dispatch => {
  dispatch(action(createSubscriptionActionTypes.REQUEST));
  try {
    const result = await StripeSubscriptionService.createSubscription(data);
    dispatch(action(createSubscriptionActionTypes.SUCCESS, result));
    return result;
  } catch (error) {
    const err = getErrorMessage(error);
    dispatch(action(createSubscriptionActionTypes.FAILURE, getErrorMessage(error)));
    throw err;
  }
};

export const getUserSubscriptions = (): ActionResult<
  IStripeSubscriptionState,
  void,
  getUserSubscriptionsActions
> => async dispatch => {
  dispatch(action(getUserSubscriptionsActionTypes.REQUEST));
  try {
    const result = await StripeSubscriptionService.getUserSubscriptions();
    dispatch(action(getUserSubscriptionsActionTypes.SUCCESS, result));
  } catch (error) {
    dispatch(action(getUserSubscriptionsActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const getUserScheduledSubscriptions = (): ActionResult<
  IStripeSubscriptionState,
  void,
  getUserScheduledSubscriptionsActions
> => async dispatch => {
  dispatch(action(getUserScheduledSubscriptionsActionTypes.REQUEST));
  try {
    const result = await StripeSubscriptionService.getUserScheduledSubscriptions();
    dispatch(action(getUserScheduledSubscriptionsActionTypes.SUCCESS, result));
  } catch (error) {
    dispatch(action(getUserScheduledSubscriptionsActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const deleteSubscription = (
  id: number
): ActionResult<IStripeSubscriptionState, void, deleteSubscriptionActions> => async dispatch => {
  dispatch(action(deleteSubscriptionActionTypes.REQUEST));
  try {
    await StripeSubscriptionService.deleteSubscription(id);
    dispatch(action(deleteSubscriptionActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(deleteSubscriptionActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const cancelSubscription = (
  id: number
): ActionResult<IStripeSubscriptionState, void, cancelSubscriptionActions> => async dispatch => {
  dispatch(action(cancelSubscriptionActionTypes.REQUEST));
  try {
    await StripeSubscriptionService.cancelSubscription(id);
    dispatch(action(cancelSubscriptionActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(cancelSubscriptionActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const cancelScheduledSubscription = (
  id: number
): ActionResult<IStripeSubscriptionState, void, cancelScheduledSubscriptionActions> => async dispatch => {
  dispatch(action(cancelScheduledSubscriptionActionTypes.REQUEST));
  try {
    await StripeSubscriptionService.cancelScheduledSubscription(id);
    dispatch(action(cancelScheduledSubscriptionActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(cancelScheduledSubscriptionActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const changeSubscriptionPlan = (
  productId: number
): ActionResult<IStripeSubscriptionState, void, changeSubscriptionPlanActions> => async dispatch => {
  dispatch(action(changeSubscriptionPlanActionTypes.REQUEST));
  try {
    await StripeSubscriptionService.changeSubscriptionPlan(productId);
    dispatch(action(changeSubscriptionPlanActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(changeSubscriptionPlanActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const renewSubscription = (
  id: number
): ActionResult<IStripeSubscriptionState, void, renewSubscriptionActions> => async dispatch => {
  dispatch(action(renewSubscriptionActionTypes.REQUEST));
  try {
    await StripeSubscriptionService.renewSubscription(id);
    dispatch(action(renewSubscriptionActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(renewSubscriptionActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const ensureCustomerCreated = (): ActionResult<
  IStripeSubscriptionState,
  void,
  ensureCustomerCreatedActions
> => async dispatch => {
  dispatch(action(ensureCustomerCreatedActionTypes.REQUEST));
  try {
    await StripeSubscriptionService.ensureCustomerCreated();
    dispatch(action(ensureCustomerCreatedActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(ensureCustomerCreatedActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const isCustomerExist = (): ActionResult<
  IStripeSubscriptionState,
  Promise<boolean>,
  isCustomerExistActions
> => async dispatch => {
  dispatch(action(isCustomerExistActionTypes.REQUEST));
  try {
    const result = await StripeSubscriptionService.isCustomerExist();
    dispatch(action(isCustomerExistActionTypes.SUCCESS));
    return result;
  } catch (error) {
    const err = getErrorMessage(error);
    dispatch(action(isCustomerExistActionTypes.FAILURE, getErrorMessage(error)));
    throw err;
  }
};

export const getDefaultPaymentMethod = (): ActionResult<
  IStripeSubscriptionState,
  void,
  getDefaultPaymentMethodActions
> => async dispatch => {
  dispatch(action(getDefaultPaymentMethodActionTypes.REQUEST));
  try {
    const result = await StripeSubscriptionService.getDefaultPaymentMethod();
    dispatch(action(getDefaultPaymentMethodActionTypes.SUCCESS, result));
  } catch (error) {
    dispatch(action(getDefaultPaymentMethodActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const changeDefaultPaymentMethod = (
  paymentMethodId: string
): ActionResult<IStripeSubscriptionState, void, changeDefaultPaymentMethodActions> => async dispatch => {
  dispatch(action(changeDefaultPaymentMethodActionTypes.REQUEST));
  try {
    await StripeSubscriptionService.changeDefaultPaymentMethod(paymentMethodId);
    dispatch(action(changeDefaultPaymentMethodActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(changeDefaultPaymentMethodActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const retryInvoice = (
  paymentMethodId: string,
  subscriptionId: number
): ActionResult<IStripeSubscriptionState, Promise<IRetryInvoiceResponse>, retryInvoiceActions> => async dispatch => {
  dispatch(action(retryInvoiceActionTypes.REQUEST));
  try {
    const result = await StripeSubscriptionService.retryInvoice(paymentMethodId, subscriptionId);
    dispatch(action(retryInvoiceActionTypes.SUCCESS));
    return result;
  } catch (error) {
    const err = getErrorMessage(error);
    dispatch(action(retryInvoiceActionTypes.FAILURE, getErrorMessage(error)));
    throw err;
  }
};

export const getSetupIntentClientSecret = (): ActionResult<
  IStripeSubscriptionState,
  Promise<string>,
  getSetupIntentClientSecretActions
> => async dispatch => {
  dispatch(action(getSetupIntentClientSecretActionTypes.REQUEST));
  try {
    const result = await StripeSubscriptionService.getSetupIntentClientSecret();
    dispatch(action(getSetupIntentClientSecretActionTypes.SUCCESS));
    return result;
  } catch (error) {
    const err = getErrorMessage(error);
    dispatch(action(getSetupIntentClientSecretActionTypes.FAILURE, getErrorMessage(error)));
    throw err;
  }
};

export const refreshSubscriptionFromStripe = (
  subscriptionId: number
): ActionResult<IStripeSubscriptionState, void, refreshSubscriptionFromStripeActions> => async dispatch => {
  dispatch(action(refreshSubscriptionFromStripeActionTypes.REQUEST));
  try {
    await StripeSubscriptionService.refreshSubscriptionFromStripe(subscriptionId);
    dispatch(action(refreshSubscriptionFromStripeActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(refreshSubscriptionFromStripeActionTypes.FAILURE, getErrorMessage(error)));
  }
};
