import {combineReducers} from 'redux';

import {
  changePurchasableTestStatusActionTypes,
  createPurchasableTestActionTypes,
  AdminPurchasableTestsActions,
  getPurchasableTestByIdActionTypes,
  getPurchasableTestsActionTypes,
  IAdminPurchasableTestState,
  resetPurchasableTestDetailsActionTypes,
  updatePurchasableTestActionTypes,
} from '../types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import composeReducers from 'Common/store/composeReducers';

export const communicationAdminPurchasableTestsReducer = combineReducers<
  IAdminPurchasableTestState['communications'],
  AdminPurchasableTestsActions
>({
  purchasableTestsLoading: makeCommunicationReducerFromEnum(getPurchasableTestsActionTypes),
  purchasableTestCreating: makeCommunicationReducerFromEnum(createPurchasableTestActionTypes),
  purchasableTestUpdating: makeCommunicationReducerFromEnum(updatePurchasableTestActionTypes),
  purchasableTestDetailsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getPurchasableTestByIdActionTypes),
    makeResetCommunicationReducer(resetPurchasableTestDetailsActionTypes.RESET),
  ]),
  changePurchasableTestStatusRequesting: makeCommunicationReducerFromEnum(changePurchasableTestStatusActionTypes),
});
