import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IPaginationRequest} from 'Common/models/IPaginationRequest';
import {
  changeAdminOrganizationStatusActions,
  changeAdminOrganizationStatusActionTypes,
  createAdminOrganizationActions,
  createAdminOrganizationActionTypes,
  getAdminOrganizationActions,
  getAdminOrganizationActionTypes,
  getAdminOrganizationAvatarActions,
  getAdminOrganizationAvatarActionTypes,
  getAdminOrganizationsActions,
  getAdminOrganizationsActionTypes,
  resetAdminOrganizationActions,
  resetAdminOrganizationActionTypes,
  updateAdminOrganizationActions,
  updateAdminOrganizationActionTypes,
  updateAdminOrganizationAvatarActions,
  updateAdminOrganizationAvatarActionTypes,
} from './types';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import AdminOrganizationDataService from 'Admin/AdminAssociations/services/AdminOrganizationDataService';
import {ICreateAdminOrganization, IUpdateAdminOrganization} from 'Admin/AdminAssociations/services/types/organizations';

export const getAdminOrganizations =
  (params?: IPaginationRequest): ActionResult<IAppState, void, getAdminOrganizationsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminOrganizationsActionTypes.REQUEST));
      const result = await AdminOrganizationDataService.getAdminOrganizations(params);
      dispatch(action(getAdminOrganizationsActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getAdminOrganizationsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAdminOrganization =
  (id: number): ActionResult<IAppState, void, getAdminOrganizationActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminOrganizationActionTypes.REQUEST));
      const result = await AdminOrganizationDataService.getAdminOrganization(id);
      dispatch(action(getAdminOrganizationActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getAdminOrganizationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createAdminOrganization =
  (data: ICreateAdminOrganization): ActionResult<IAppState, Promise<number>, createAdminOrganizationActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createAdminOrganizationActionTypes.REQUEST));
      const res = await AdminOrganizationDataService.createAdminOrganization(data);
      dispatch(action(createAdminOrganizationActionTypes.SUCCESS));
      return res;
    } catch (error) {
      const err = getErrorMessage(error);
      dispatch(action(createAdminOrganizationActionTypes.FAILURE, err));
      throw err;
    }
  };

export const updateAdminOrganization =
  (data: IUpdateAdminOrganization): ActionResult<IAppState, void, updateAdminOrganizationActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdminOrganizationActionTypes.REQUEST));
      await AdminOrganizationDataService.updateAdminOrganization(data);
      dispatch(action(updateAdminOrganizationActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateAdminOrganizationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const changeAdminOrganizationStatus =
  (id: number, isActive: boolean): ActionResult<IAppState, void, changeAdminOrganizationStatusActions> =>
  async (dispatch) => {
    try {
      dispatch(action(changeAdminOrganizationStatusActionTypes.REQUEST));
      await AdminOrganizationDataService.changeAdminOrganizationStatus(id, isActive);
      dispatch(action(changeAdminOrganizationStatusActionTypes.SUCCESS, {id}));
    } catch (error) {
      dispatch(action(changeAdminOrganizationStatusActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAdminOrganizationAvatar =
  (organizationId: number): ActionResult<IAppState, Promise<void>, getAdminOrganizationAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminOrganizationAvatarActionTypes.REQUEST));
      const data = await AdminOrganizationDataService.getAdminOrganizationAvatar(organizationId);
      dispatch(action(getAdminOrganizationAvatarActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getAdminOrganizationAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateOrganizationAvatar =
  (
    organizationId: number,
    uploadedFile: File
  ): ActionResult<IAppState, Promise<void>, updateAdminOrganizationAvatarActions> =>
  async (dispatch) => {
    try {
      dispatch(action(updateAdminOrganizationAvatarActionTypes.REQUEST));
      await AdminOrganizationDataService.updateAdminOrganizationAvatar(organizationId, uploadedFile);
      dispatch(action(updateAdminOrganizationAvatarActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateAdminOrganizationAvatarActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetAdminOrganization =
  (): ActionResult<IAppState, void, resetAdminOrganizationActions> => async (dispatch) => {
    dispatch(action(resetAdminOrganizationActionTypes.RESET));
  };
