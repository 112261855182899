import React, {useState, useCallback} from 'react';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import UserFullInfoUpdate from 'Admin/AdminDashboard/components/Users/UserFullInfoUpdate/UserFullInfoUpdate';
import {Nullable} from 'Common/types';

interface IUpdateUserModalParams {
  onSuccess?(): void;
  userId?: Nullable<number>;
}

export function useUpdateUserModal(params: IUpdateUserModalParams) {
  const {onSuccess} = params;
  const [selectedUserId, setSelectedUserId] = useState<number | null>();
  const [isOpen, setIsOpen] = useState(false);

  const userId = params.userId || selectedUserId;

  const openUpdateUserModal = useCallback((userId?: Nullable<number>) => {
    setIsOpen(true);
    setSelectedUserId(userId);
  }, []);
  const closeUpdateUserModal = useCallback(() => {
    setIsOpen(false);
    setSelectedUserId(null);
  }, []);

  const handleSuccess = useCallback(() => {
    closeUpdateUserModal();
    onSuccess && onSuccess();
  }, [closeUpdateUserModal, onSuccess]);

  const updateUserModal = (
    <ModalWindow isOpen={isOpen && !!userId} onClose={closeUpdateUserModal}>
      {userId && <UserFullInfoUpdate userId={userId} onSuccess={handleSuccess} />}
    </ModalWindow>
  );

  return {
    updateUserModal,
    openUpdateUserModal
  };
}
