import React, {memo} from 'react';
import styled from 'styled-components';

import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {InputField} from 'Common/components/FormFields';
import {IAssociation} from 'Dictionaries/models/IAssociation';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import RegistriesList from './RegistriesList/RegistriesList';
import {IRegistryRequest} from 'Common/models/IRegistryRequest';

interface IProps {
  associations: IAssociation[];
  sirRegistries: IRegistryRequest[];
  damRegistries: IRegistryRequest[];
}

const Parent = styled.div`
  :not(:first-child) {
    margin-top: 24px;
  }
`;

const Subtitle = styled.label`
  font-weight: ${Typography.weight.medium500};
  line-height: 16px;
  font-size: ${Typography.size.size12};
  color: ${ColorPalette.black1};
`;

function Family(props: IProps) {
  const {associations, sirRegistries, damRegistries} = props;
  return (
    <FormControlContainer title="Parentage">
      <Parent>
        <Subtitle>Sire</Subtitle>
        <InputField name="parentage.sire.name" label="Horse name" placeholder="Horse name" memoized={true} />
        <RegistriesList fieldName="parentage.sire.registries" associations={associations} registries={sirRegistries} />
      </Parent>
      <Parent>
        <Subtitle>Dam</Subtitle>
        <InputField name="parentage.dam.name" label="Horse name" placeholder="Horse name" memoized={true} />
        <RegistriesList fieldName="parentage.dam.registries" associations={associations} registries={damRegistries} />
      </Parent>
    </FormControlContainer>
  );
}

export default memo(Family);
